import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import SVG from "../svgs";
import { useAjax, useInitialDataFetching, useLang } from "../../utils/hooks";

function SocialButton({ type, loading, setLoading }) {
  const router = useHistory();
  const lang = useLang();
  const ajax = useAjax();
  const initialDataFetching = useInitialDataFetching();
  const { fingerprint } = useSelector((state) => ({
    fingerprint: state.settings.fingerprint,
  }));

  const responseFacebook = async (response) => {
    if (response.status === "unknown") {
      setLoading(false);
      return;
    }

    handleSocialLogin(response, "facebook");
  };

  const responseGoogle = async (response) => {
    if (response.error === "popup_closed_by_user") {
      setLoading(false);
      return;
    }

    handleSocialLogin(response, "google");
  };

  const handleSocialLoginRequest = () => {
    setLoading(true);
  };

  const handleSocialLogin = async (response, social) => {
    if (!loading && social !== "google") {
      return;
    }

    if (typeof response.accessToken !== "string") {
      setLoading(false);
      return;
    }

    try {
      response.fingerprint = fingerprint;

      const data = await ajax(`/${social}/login`, response);

      if (data.result === "success") {
        if (!data.userID) {
          setLoading(false);
          return router.push(`/${lang}/two-factor-authentication`);
        }

        if (await initialDataFetching(true)) {
          router.push(`/${lang || "en"}/my-cloud/servers`);
        }
      } else if (data.result === "confirm-password") {
        router.push(
          `/${lang}/confirm-password?token=${encodeURIComponent(data.token)}`
        );
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  if (type === "facebook") {
    return (
      <FacebookLogin
        appId="500294617483923"
        fields="first_name,last_name,email,picture"
        callback={responseFacebook}
        autoLoad={false}
        onClick={handleSocialLoginRequest}
        isDisabled={loading}
        render={(renderProps) => (
          <Button
            className={styles.socialButton}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <SVG icon="facebook-mini" />
            FACEBOOK
          </Button>
        )}
      />
    );
  }

  if (type === "google") {
    return (
      <GoogleLogin
        clientId="1044206978494-0lqns7uvqpsi8u9p1coopenakco1hojf.apps.googleusercontent.com"
        onRequest={handleSocialLoginRequest}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy="single_host_origin"
        autoLoad={false}
        disabled={loading}
        render={(renderProps) => (
          <Button
            className={styles.socialButton}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <SVG icon="google-mini" />
            GOOGLE
          </Button>
        )}
      />
    );
  }

  return null;
}

SocialButton.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default SocialButton;
