import styles from "./index.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import CustomReactSelect from "../../custom-react-select";
import { useAjax, useRoles, useServer, useUser } from "../../../utils/hooks";
import {
  currencySymbols,
  getSymbolsForReactSelect,
} from "../../../utils/billing";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";
import Checkbox from "../../checkbox";
import { setDate } from "date-fns";

const currencySymbolsMapped = getSymbolsForReactSelect();

function CreateAddonModal({ isOpen, onClose, editAddon, whitelabel }) {
  const intl = useIntl();
  const ajax = useAjax();
  const user = useUser();
  const { isAllowed } = useRoles();
  const server = useServer();

  const payEveryOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    24,
    36,
    48,
    60,
  ].map((num) => ({
    label: `${num} ${
      num === 1
        ? intl.formatMessage({ id: "general.month" })
        : intl.formatMessage({ id: "general.months" })
    }`,
    value: num,
  }));
  const payEveryOptionsRef = useRef(payEveryOptions);

  const payDayOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
  ].map((num) => ({
    label: num,
    value: num,
  }));
  const payDayOptionsRef = useRef(payDayOptions);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [description, setDescription] = useState("");
  const [adminDescription, setAdminDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [payEvery, setPayEvery] = useState(payEveryOptions[0]);
  const [payDay, setPayDay] = useState(payDayOptions[0]);
  const [nextPayDay, setNextPayDay] = useState(new Date());
  const [isFirstMonth, setIsFirstMonth] = useState(false);
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState(currencySymbolsMapped[0]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll");

    if (data.result === "success") {
      const products = data.products
        .filter((product) => product.type !== "product")
        .map((product) => ({
          label: product.name,
          value: product,
        }));

      setProducts(products);
    }
  }, [ajax]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (!product || editAddon) {
      return;
    }

    setDescription(product.value.description || "");
    setAdminDescription(product.value.admin_description || "");
    setPrice(product.value.price);
    setCurrency(
      currencySymbolsMapped.find((c) => c.value === product.value.currency)
    );
  }, [product, editAddon]);

  useEffect(() => {
    if (isOpen && products) {
      if (editAddon) {
        setProduct(products.find((p) => p.value.name === editAddon.name));
        setDescription(editAddon.description || "");
        setAdminDescription(editAddon.admin_description || "");
        setQuantity(editAddon.quantity.toString());
        setPayEvery(
          payEveryOptionsRef.current.find(
            (item) => item.value === editAddon.payEvery
          )
        );
        setPayDay(
          payDayOptionsRef.current.find(
            (item) => item.value === editAddon.payDay
          )
        );
        setNextPayDay(new Date(editAddon.next_pay_day));
        setIsFirstMonth(editAddon.is_first_month);
        setPrice(editAddon.price.toString());
        setCurrency(
          currencySymbolsMapped.find(
            (item) => item.value === editAddon.currency
          )
        );
      } else {
        setProduct(null);
        setDescription("");
        setAdminDescription("");
        setPrice("");
        setCurrency(currencySymbolsMapped[1]);
        setQuantity("");
        setPayEvery(payEveryOptionsRef.current[0]);

        setPayDay(
          payDayOptionsRef.current.find(
            (payDayOption) => payDayOption.value === server.userPayDay
          )
        );

        setNextPayDay(setDate(new Date(), server.userPayDay));
        setIsFirstMonth(false);
      }

      setError(false);
      setLoading(false);
    }
  }, [isOpen, editAddon, products, server]);

  async function handleAddClicked() {
    const _description = description?.trim();
    const _adminDescription = adminDescription?.trim();
    const _quantity = parseInt(quantity);
    const _price = parseFloat(price);

    setError(false);

    if (!product) {
      return setError(
        intl.formatMessage({ id: "create-addon-modal.wrong-addon" })
      );
    } else if (!Number.isInteger(_quantity)) {
      return setError(
        intl.formatMessage({ id: "create-addon-modal.wrong-quantity" })
      );
    } else if (!_price && _price !== 0) {
      return setError(
        intl.formatMessage({ id: "create-addon-modal.wrong-price" })
      );
    } else if (!payEvery) {
      return setError(
        intl.formatMessage({ id: "create-addon-modal.wrong-pay-every" })
      );
    } else if (!payDay) {
      return setError(
        intl.formatMessage({ id: "create-addon-modal.wrong-pay-day" })
      );
    } else if (!nextPayDay) {
      return setError(
        intl.formatMessage({ id: "create-addon-modal.wrong-next-pay-day" })
      );
    }

    setLoading(true);
    await ajax(
      editAddon ? "/servers/editServerAddon" : "/servers/addServerAddon",
      {
        name: product.value.name,
        currency: currency.value,
        description: _description,
        adminDescription: _adminDescription,
        nextPayDay,
        payDay: payDay.value,
        payEvery: payEvery.value,
        isFirstMonth,
        price: _price,
        serverID: server._id,
        quantity: _quantity,
        addonType: product.value.type,
        selectedServerAddonID: editAddon?._id,
        addonID: product.value._id,
        whitelabelToUse: whitelabel,
      }
    );
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editAddon
              ? "create-addon-modal.title-edit"
              : "create-addon-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.addon-name" />
          </span>
          <CustomReactSelect
            options={products}
            value={product}
            onChange={(item) => setProduct(item)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.addon-price" />
          </span>
          <CustomText
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />

          {product && [SUPER_ADMIN, WHITELABEL].includes(user.role) && (
            <div className={styles.prices}>
              {product.value.price && product.value.currency && (
                <div>
                  <FormattedMessage
                    id="create-other-product-modal.original-price"
                    values={{
                      price: `${currencySymbols[product.value.currency]}${
                        product.value.price
                      }`,
                    }}
                  />
                </div>
              )}
              {isAllowed("admin.products.our-prices") &&
                product.value.our_price &&
                product.value.our_currency && (
                  <div>
                    <FormattedMessage
                      id="create-other-product-modal.our-price"
                      values={{
                        price: `${currencySymbols[product.value.our_currency]}${
                          product.value.our_price
                        }`,
                      }}
                    />
                  </div>
                )}
            </div>
          )}
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.price-currency" />
          </span>
          <CustomReactSelect
            instanceId="create-addon-modal-price-currency"
            options={currencySymbolsMapped}
            value={currency}
            onChange={(item) => setCurrency(item)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.addon-description" />
          </span>
          <CustomText
            type="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={editAddon?.addon_type === "additional-ipv4"}
          />
        </div>
        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <div className={styles.row}>
            <span>
              <FormattedMessage id="create-addon-modal.addon-admin-description" />
            </span>
            <CustomText
              type="textarea"
              value={adminDescription}
              onChange={(e) => setAdminDescription(e.target.value)}
            />
          </div>
        )}

        <br />

        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.addon-quantity" />
          </span>
          <CustomText
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.pay-every" />
          </span>
          <CustomReactSelect
            instanceId="create-addon-modal-pay-every"
            options={payEveryOptions}
            value={payEvery}
            onChange={(item) => setPayEvery(item)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.pay-day" />
          </span>
          <CustomReactSelect
            instanceId="create-addon-modal-pay-day"
            options={payDayOptions}
            value={payDay}
            onChange={(item) => setPayDay(item)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-addon-modal.next-pay-day" />
          </span>
          <DatePicker
            wrapperClassName="select"
            selected={nextPayDay}
            onChange={(date) => setNextPayDay(date)}
            placeholderText={`${intl.formatMessage({
              id: "general.select",
            })}...`}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className={styles.row}>
          <Checkbox
            label="create-addon-modal.is-first-month"
            checked={isFirstMonth}
            onChange={(e) => setIsFirstMonth(e.target.checked)}
          />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAddClicked}
        >
          <FormattedMessage id={editAddon ? "general.edit" : "general.add"} />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

CreateAddonModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editAddon: PropTypes.object,
  whitelabel: PropTypes.string,
};

export default CreateAddonModal;
