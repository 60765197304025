import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";

function BasicTable({ children, className, seperatorColor }) {
  return (
    <div className={styles.responsive}>
      <Table
        className={`${styles.table} ${
          seperatorColor && styles[`color-${seperatorColor}`]
        } ${className || ""}`}
      >
        {children}
      </Table>
    </div>
  );
}

BasicTable.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  seperatorColor: PropTypes.string,
  layout: PropTypes.string,
};

export default BasicTable;
