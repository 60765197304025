import styles from "./nodes-status.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ReactCountryFlag from "react-country-flag";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import Box from "../../../components/box";
import { useAjax, useConfirm } from "../../../utils/hooks";
import {} from "../../../utils";
import { getSocket } from "../../../utils/globals";
import BasicTable from "../../../components/basic-table";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";

export default function ManagementNodesStatus() {
  const ajax = useAjax();
  const intl = useIntl();
  const socket = getSocket();
  const confirm = useConfirm();

  const [nodesIL, setNodesIL] = useState(null);
  const [nodesNL, setNodesNL] = useState(null);
  const [nodesUS, setNodesUS] = useState(null);

  const [isNodeDropdownOpen, setIsNodeDropdownOpen] = useState({});

  function handleNodesStatusRecieved(data) {
    if (data.nodesIL) {
      data.nodesIL.data.forEach((node) => {
        node.maintenance = !!data.maintenanceNodes[node.node];
      });

      setNodesIL(
        data.nodesIL.data.sort((a, b) => a.node.localeCompare(b.node))
      );
    }

    if (data.nodesNL) {
      data.nodesNL.data.forEach((node) => {
        node.maintenance = !!data.maintenanceNodes[node.node];
      });

      setNodesNL(
        data.nodesNL.data.sort((a, b) => a.node.localeCompare(b.node))
      );
    }

    if (data.nodesUS) {
      data.nodesUS.data.forEach((node) => {
        node.maintenance = !!data.maintenanceNodes[node.node];
      });

      setNodesUS(
        data.nodesUS.data.sort((a, b) => a.node.localeCompare(b.node))
      );
    }
  }

  const socketListen = useCallback(async () => {
    if (!socket) {
      return;
    }

    await ajax("/socket/listen", {
      method: "nodes-status",
      params: {},
    });

    socket.on("nodes-status", handleNodesStatusRecieved);
  }, [ajax, socket]);

  const socketStop = useCallback(async () => {
    if (!socket) {
      return;
    }

    await ajax("/socket/stop", {
      method: "nodes-status",
      params: {},
    });

    socket.off("nodes-status", handleNodesStatusRecieved);
  }, [ajax, socket]);

  useEffect(() => {
    socketListen();

    return () => {
      socketStop();
    };
  }, [socketListen, socketStop]);

  function handleNodeDropdownToggle(node) {
    isNodeDropdownOpen[node] = !isNodeDropdownOpen[node];
    setIsNodeDropdownOpen({ ...isNodeDropdownOpen });
  }

  async function handleToggleMaintenance(node, newState) {
    const state = await confirm({
      title: intl.formatMessage({ id: "nodes-status.set-maintenance.title" }),
      message: intl.formatMessage(
        {
          id: newState
            ? "nodes-status.set-maintenance.content"
            : "nodes-status.remove-maintenance.content",
        },
        { node, b: (val) => `<b>${val}</b>` }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/proxmox/toggleMaintenance", { node, state: newState });
  }

  function renderTable(dataCenter) {
    let nodes;

    switch (dataCenter) {
      case "IL": {
        nodes = nodesIL;
        break;
      }
      case "NL": {
        nodes = nodesNL;
        break;
      }
      case "US": {
        nodes = nodesUS;
        break;
      }
    }

    return (
      <Box title={dataCenter} className={styles.box}>
        <BasicTable>
          <thead>
            <tr>
              <th>Node</th>
              <th>CPU</th>
              <th>MEM</th>
              <th>Disk</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {nodes?.map((node, key) => (
              <tr key={key}>
                <td>
                  <div className={styles.node}>
                    <ReactCountryFlag countryCode={dataCenter} />
                    {node.node}{" "}
                  </div>
                </td>
                <td>
                  {(node.cpu * 100).toFixed(1)}% of {node.maxcpu} CPUs
                </td>
                <td>{((node.mem / node.maxmem) * 100).toFixed(1)}%</td>
                <td>{((node.disk / node.maxdisk) * 100).toFixed(1)}%</td>
                <td>{node.maintenance ? "Maintenance" : node.status}</td>
                <td>
                  {!node.node.includes("backup") && (
                    <CustomMenu
                      isOpen={isNodeDropdownOpen[node.node]}
                      toggle={() => handleNodeDropdownToggle(node.node)}
                    >
                      {!node.maintenance && (
                        <CustomMenuItem
                          onClick={() =>
                            handleToggleMaintenance(node.node, true)
                          }
                        >
                          Set {node.node} at maintenance mode
                        </CustomMenuItem>
                      )}
                      {node.maintenance && (
                        <CustomMenuItem
                          onClick={() =>
                            handleToggleMaintenance(node.node, false)
                          }
                        >
                          Remove {node.node} from maintenance mode
                        </CustomMenuItem>
                      )}
                    </CustomMenu>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </Box>
    );
  }

  return (
    <WithRole permission="super-admin.nodes-status">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "nodes-status.main-title",
        })}
      >
        <div className={styles.wrapper}>
          {renderTable("IL")}

          {renderTable("NL")}

          {renderTable("US")}
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
