import styles from "./addons-admin.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { format } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../../../../../components/box";
import BasicTable from "../../../../../../../components/basic-table";
import {
  useAjax,
  useConfirm,
  useRoles,
  useServer,
  useUser,
  useWLDVPS,
} from "../../../../../../../utils/hooks";
import CreateAddonModal from "../../../../../../../components/modals/create-addon";
import IconButton from "../../../../../../../components/icon-button";
import { currencySymbols } from "../../../../../../../utils/billing";
import CustomMenu from "../../../../../../../components/custom-menu";
import CustomMenuItem from "../../../../../../../components/custom-menu/item";
import { WithRole } from "../../../../../../../components/with-role";
import {} from "../../../../../../../utils";
import { WINDOWS10_IMAGE } from "../../../../../../../utils/servers";
import Spinner from "../../../../../../../components/spinner";
import { SUPER_ADMIN, WHITELABEL } from "../../../../../../../utils/user";

export default function ServerAddonsAdmin() {
  const server = useServer();
  const intl = useIntl();
  const ajax = useAjax();
  const confirm = useConfirm();
  const wldvps = useWLDVPS();
  const user = useUser();
  const { isAllowed } = useRoles();

  const [addons, setAddons] = useState(null);
  const [filteredAddons, setFilteredAddons] = useState(null);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [isCreateAddonModalOpen, setIsCreateAddonModalOpen] = useState(false);
  const [addonsToShow, setAddonsToShow] = useState("self");
  const [isWhitelabelDropdownOpen, setIsWhitelabelDropdownOpen] = useState(
    false
  );

  const getServerAddons = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax("/servers/getServerAddons", {
      serverID: server._id,
    });

    setAddons(data.addons);
  }, [ajax, server]);

  useEffect(() => {
    getServerAddons();
  }, [getServerAddons]);

  useEffect(() => {
    if (!addons || !server) {
      return;
    }

    let addonsToSet;
    if (
      addonsToShow === "self" &&
      ([SUPER_ADMIN, WHITELABEL].includes(user.role) || !server.userWhitelabel)
    ) {
      addonsToSet = addons.filter((addon) => !addon.whitelabel);
    } else {
      addonsToSet = addons.filter(
        (addon) =>
          addon.whitelabel && addon.whitelabel === server.userWhitelabel
      );
    }

    setFilteredAddons(addonsToSet);
  }, [wldvps, addons, addonsToShow, server, user.role]);

  function handleCreateNewAddonClicked() {
    setSelectedAddon(null);
    setIsCreateAddonModalOpen(true);
  }

  function handleEditAddonClicked(addon) {
    setSelectedAddon(addon);
    setIsCreateAddonModalOpen(true);
  }

  async function handleCreateAddonModalClosed(state) {
    if (state) {
      await getServerAddons();
    }

    setIsCreateAddonModalOpen(false);
  }

  function handleAddonDropdownToggle(addon) {
    addon.isDropdownOpen = !addon.isDropdownOpen;
    setAddons([...addons]);
  }

  async function handleRemoveAddonClicked(addon) {
    const state = await confirm({
      title: intl.formatMessage({ id: "server-addons.remove.title" }),
      message: intl.formatMessage({ id: "server-addons.remove.content" }),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/servers/deleteServerAddon", {
      serverID: server._id,
      serverAddonID: addon._id,
    });

    await getServerAddons();
  }

  function handleWhitelabelDropdownToggle() {
    setIsWhitelabelDropdownOpen(!isWhitelabelDropdownOpen);
  }

  function renderAddonPrice(addon) {
    let retText;

    if (server.payment.payment_type === "hourly") {
      retText = `${currencySymbols[addon.currency]}${(
        addon.price / 730
      ).toFixed(3)}/h`;

      if (server.image === WINDOWS10_IMAGE) {
        retText += " (When server ON)";
      }
    } else {
      retText = `${currencySymbols[addon.currency]}${addon.price.toFixed(2)}`;
    }

    return retText;
  }

  return (
    <WithRole permission="servers.addons">
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {user.role === SUPER_ADMIN && server?.userWhitelabel && (
            <div>
              <FormattedMessage id="general.addons-to-show" />
              <ButtonDropdown
                className={styles.ghost}
                isOpen={isWhitelabelDropdownOpen}
                toggle={handleWhitelabelDropdownToggle}
              >
                <DropdownToggle caret>{addonsToShow}</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => setAddonsToShow("self")}>
                    <FormattedMessage id="general.self" />
                  </DropdownItem>
                  <DropdownItem onClick={() => setAddonsToShow("whitelabel")}>
                    <FormattedMessage id="general.whitelabel" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          )}
        </div>

        <Box>
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-addons.table.addon-name" />
                </th>
                <th>
                  <FormattedMessage id="server-addons.table.addon-description" />
                </th>
                <th>
                  <FormattedMessage id="server-addons.table.addon-quantity" />
                </th>
                <th>
                  <FormattedMessage id="server-addons.table.pay-every" />
                </th>
                <th>
                  <FormattedMessage id="server-addons.table.pay-day" />
                </th>
                <th>
                  <FormattedMessage id="server-addons.table.next-pay-day" />
                </th>
                <th>
                  <FormattedMessage id="server-addons.table.addon-price" />
                </th>
                {isAllowed("admin.addons.create") && <th></th>}
              </tr>
            </thead>
            <tbody>
              {!filteredAddons && (
                <tr>
                  <td colSpan={9}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {filteredAddons?.length === 0 && (
                <tr>
                  <td colSpan={9}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {filteredAddons?.map((addon, key) => (
                <tr key={key}>
                  <td>{addon.name}</td>
                  <td>{addon.description}</td>
                  <td>{addon.quantity}</td>
                  <td>
                    {addon.payEvery}{" "}
                    {addon.payEvery > 1
                      ? intl.formatMessage({ id: "general.months" })
                      : intl.formatMessage({ id: "general.month" })}
                  </td>
                  <td>{addon.payDay}</td>
                  <td>{format(new Date(addon.next_pay_day), "dd/MM/yyyy")}</td>
                  <td>{renderAddonPrice(addon)}</td>
                  {isAllowed("admin.addons.create") && (
                    <td>
                      <CustomMenu
                        isOpen={addon.isDropdownOpen}
                        toggle={() => handleAddonDropdownToggle(addon)}
                      >
                        <CustomMenuItem
                          onClick={() => handleEditAddonClicked(addon)}
                        >
                          <FormattedMessage id="general.edit" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleRemoveAddonClicked(addon)}
                        >
                          <FormattedMessage id="general.remove" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </BasicTable>

          {isAllowed("admin.addons.create") && (
            <IconButton
              color="light-purple"
              onClick={handleCreateNewAddonClicked}
            >
              <FormattedMessage id="server-addons.table.create-new-addon" />
            </IconButton>
          )}
        </Box>
      </div>

      <CreateAddonModal
        isOpen={isCreateAddonModalOpen}
        onClose={handleCreateAddonModalClosed}
        editAddon={selectedAddon}
        whitelabel={
          [SUPER_ADMIN, WHITELABEL].includes(user.role) ||
          !server?.userWhitelabel
            ? null
            : server.userWhitelabel
        }
      />
    </WithRole>
  );
}
