import styles from "./login-sms.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useAjax, useInitialDataFetching, useLang } from "../../utils/hooks";
import LoginLayout from "../../components/login-layout";
import TextField from "../../components/textfield";
import IconButton from "../../components/icon-button";
import KeyDetector from "../../components/key-detector";

export default function LoginWithSMS() {
  const ajax = useAjax();
  const router = useHistory();
  const intl = useIntl();
  const lang = useLang();
  const initialDataFetching = useInitialDataFetching();

  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!window.p1 || !window.p2) {
      delete window.p1;
      delete window.p2;

      setTimeout(() => {
        router.push(`/${lang}/login`);
      }, 1);

      return;
    }

    setEmail(window.p1);
    setPassword(window.p2);

    delete window.p1;
    delete window.p2;
  }, [lang, router]);

  const sendSMS = useCallback(async () => {
    if (!email || !password) {
      return;
    }

    const data = await ajax(`/users/sendSMS`, {
      email,
      password,
    });

    if (data.result === "success") {
      setPhoneNumber(data.number);
    }
  }, [ajax, email, password]);

  useEffect(() => {
    sendSMS();
  }, [sendSMS]);

  async function handleVerifyClicked() {
    const _code = code.trim();

    setError(false);

    if (!_code) {
      return setError("general.empty-code");
    }

    setLoading(true);
    const data = await ajax(`/users/verifySMS`, {
      code,
      email,
      password,
      action: "login-2fa",
    });

    if (data.result === "success") {
      if (await initialDataFetching(true)) {
        router.push(`/${lang || "en"}/my-cloud/servers`);
      }
    } else {
      setError("general.wrong-code");
      setLoading(false);
    }
  }

  return (
    <LoginLayout>
      <div className={styles.wrapper}>
        <div className={`mb8 ${styles.bold}`}>
          <FormattedMessage id="login-with-sms.title" />
        </div>
        <div className={`mb28 ${styles.normal}`}>
          <FormattedMessage
            id="login-with-sms.content"
            values={{ phoneNumber }}
          />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleVerifyClicked}>
          <TextField
            icon="lock"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={intl.formatMessage({
              id: "login-with-sms.code-placeholder",
            })}
          />
        </KeyDetector>
        {error && (
          <div className={`mb28 ${styles.errorWrapper}`}>
            <FormattedMessage id={error} />
          </div>
        )}
        <div className="mb28">
          <IconButton
            disabled={loading}
            icon="arrow"
            onClick={handleVerifyClicked}
            color="purple"
            className={styles.verifyButton}
          >
            <FormattedMessage id="general.verify" />
          </IconButton>
        </div>

        <div className={styles.backWrapper}>
          <Link href={`/[lang]/login`} to={`/${lang}/login`}>
            <FormattedMessage id="forgot-password.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
