import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useAjax, useLang } from "../../utils/hooks";
import { getFullName } from "../../utils/user";
import LayoutMainAction from "../layout-main-action";
import Navigator from "../navigator";
import { setServersFetchingFilter } from "../../store/settings";
import { getServerUrl } from "../../utils/wldvps";

function AdminUserLayout({ user, children }) {
  const intl = useIntl();
  const ajax = useAjax();
  const router = useHistory();
  const lang = useLang();
  const dispatch = useDispatch();

  const { whitelabelSettings } = useSelector((state) => ({
    whitelabelSettings: state.user.whitelabelSettings,
  }));

  const [items, setItems] = useState([]);

  const [loginByLink, setLoginByLink] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      return setItems([]);
    }

    const arr = [
      {
        href: "/[lang]/clients/clients-list/[id]/summary",
        as: `/${lang}/clients/clients-list/${user._id}/summary`,
        selected: router.location.pathname.endsWith("/summary"),
        title: intl.formatMessage({ id: "admin-user-layout.summary" }),
      },
      {
        href: "/[lang]/clients/clients-list/[id]/credits-management",
        as: `/${lang}/clients/clients-list/${user._id}/credits-management`,
        selected: router.location.pathname.endsWith("/credits-management"),
        title: intl.formatMessage({
          id: "admin-user-layout.credits-management",
        }),
      },
      {
        href: "/[lang]/clients/clients-list/[id]/servers",
        as: `/${lang}/clients/clients-list/${user._id}/servers`,
        selected: router.location.pathname.endsWith("/servers"),
        title: intl.formatMessage({ id: "admin-user-layout.servers" }),
      },
      {
        href: "/[lang]/clients/clients-list/[id]/other-products",
        as: `/${lang}/clients/clients-list/${user._id}/other-products`,
        selected: router.location.pathname.endsWith("/other-products"),
        title: intl.formatMessage({ id: "admin-user-layout.other-products" }),
      },
      {
        href: "/[lang]/clients/clients-list/[id]/documents",
        as: `/${lang}/clients/clients-list/${user._id}/documents`,
        selected: router.location.pathname.endsWith("/documents"),
        title: intl.formatMessage({ id: "admin-user-layout.documents" }),
      },
      {
        href: "/[lang]/clients/clients-list/[id]/tickets",
        as: `/${lang}/clients/clients-list/${user._id}/tickets`,
        selected: router.location.pathname.endsWith("/tickets"),
        title: intl.formatMessage({ id: "admin-user-layout.tickets" }),
      },
      {
        title: intl.formatMessage({ id: "admin-user-layout.show-servers" }),
        onClick: () => {
          dispatch(setServersFetchingFilter(user.email));
          router.push(`/${lang}/my-cloud/servers?backToClient=${user._id}`);
        },
      },
      {
        title: intl.formatMessage({ id: "admin-user-layout.show-invoices" }),
        href: "/[lang]/billing/invoices",
        as: `/${lang}/billing/invoices?filter=${user.email}`,
      },
    ];

    setItems(arr);
  }, [dispatch, intl, lang, router.location.pathname, router, user]);

  async function handleCreateLoginClickClicked() {
    setLoading(true);
    const data = await ajax("/admin/createLoginLink", { userID: user._id });
    setLoading(false);

    if (data.result === "success") {
      let url;

      if (process.env.NODE_ENV === "development") {
        url = getServerUrl();
      } else {
        url = `https://${
          whitelabelSettings.find(
            (ws) => ws.whitelabel === user.whitelabel && ws.key === "CLOUD_URL"
          ).value
        }/api`;
      }

      setLoginByLink(
        `${url}/login_by_token?token=${encodeURIComponent(data.token)}`
      );

      setTimeout(() => {
        setLoginByLink(false);
        setLinkCopied(false);
      }, 5000);
    }
  }

  function handleCopyLoginClickClicked() {
    copy(loginByLink);
    setLinkCopied(true);
  }

  function getButtons() {
    if (loginByLink) {
      return [
        {
          title: intl.formatMessage({
            id: linkCopied
              ? "admin-user-layout.login-link-copied"
              : "admin-user-layout.copy-login-link",
          }),
          color: "purple",
          onClick: handleCopyLoginClickClicked,
        },
      ];
    }

    return [
      {
        title: intl.formatMessage({
          id: "admin-user-layout.create-login-link",
        }),
        onClick: handleCreateLoginClickClicked,
        disabled: loading,
      },
    ];
  }

  return (
    <LayoutMainAction
      title={user && `${getFullName(user)}, ${user.email}`}
      buttons={getButtons()}
      smallButton={{
        title: intl.formatMessage({ id: "admin-user-layout.back-button" }),
        href: "/[lang]/clients/clients-list",
        as: `/${lang}/clients/clients-list`,
      }}
    >
      <Navigator
        className={styles.navigatorWrapper}
        items={items}
        styleMode={2}
      />

      {children}
    </LayoutMainAction>
  );
}

AdminUserLayout.propTypes = {
  user: PropTypes.object,
  children: PropTypes.any,
};

export default AdminUserLayout;
