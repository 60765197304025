import styles from "./snapshots.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import { format, fromUnixTime } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as SnapSvg } from "../../../../../components/svgs/snap.svg";
import Box from "../../../../../components/box";
import CustomMenu from "../../../../../components/custom-menu";
import IconButton from "../../../../../components/icon-button";
import BasicTable from "../../../../../components/basic-table";
import TakeSnapshotModal from "../../../../../components/modals/take-snapshot";
import EditSnapshotModal from "../../../../../components/modals/edit-snapshot";
import RollbackSnapshotModal from "../../../../../components/modals/rollback-snapshot";
import CreateServerFromSnapshotModal from "../../../../../components/modals/create-server-from-snapshot";
import CustomMenuItem from "../../../../../components/custom-menu/item";
import {
  useAjax,
  useServer,
  useConfirm,
  useRoles,
} from "../../../../../utils/hooks";
import {} from "../../../../../utils";
import { getSocket } from "../../../../../utils/globals";
import Spinner from "../../../../../components/spinner";

export default function ServerSnapshots() {
  const server = useServer();
  const ajax = useAjax();
  const intl = useIntl();
  const confirm = useConfirm();
  const { isAllowed } = useRoles();
  const socket = getSocket();

  const [snapshots, setSnapshots] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(false);
  const [isTakeSnapshotModalOpen, setIsTakeSnapshotModalOpen] = useState(false);
  const [isEditSnapshotModalOpen, setIsEditSnapshotModalOpen] = useState(false);
  const [
    isRollbackSnapshotModalOpen,
    setIsRollbackSnapshotModalOpen,
  ] = useState(false);
  const [
    isCreateServerFromSnapshotModalOpen,
    setIsCreateServerFromSnapshotModalOpen,
  ] = useState(false);

  const listSnapshots = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax("/snapshots/list", { serverID: server._id });

    if (data.result === "success") {
      data.data.sort((a, b) => {
        if (a.snaptime === b.snaptime) {
          return 0;
        }
        return a.snaptime > b.snaptime ? 1 : -1;
      });

      setSnapshots(data.data);
    }
  }, [ajax, server]);

  const updateSnapshots = useCallback(() => {
    listSnapshots();
  }, [listSnapshots]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    listSnapshots();

    socket.on("update-snapshots", updateSnapshots);

    return () => {
      socket.off("update-snapshots", updateSnapshots);
    };
  }, [listSnapshots, socket, updateSnapshots]);

  function handleTakeClicked() {
    setIsTakeSnapshotModalOpen(true);
  }

  function handleTakeSnapshotModalClosed() {
    setIsTakeSnapshotModalOpen(false);
  }

  async function handleRemoveAllClicked() {
    const state = await confirm({
      title: intl.formatMessage({
        id: "server-snapshots.remove-all-snapshots.title",
      }),
      message: intl.formatMessage({
        id: "server-snapshots.remove-all-snapshots.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/snapshots/removeAll", { serverID: server._id });
  }

  function handleEditClicked(snapshot) {
    setSelectedSnapshot(snapshot);
    setIsEditSnapshotModalOpen(true);
  }

  async function handleEditSnapshotModalClosed(state) {
    if (state) {
      await listSnapshots();
    }

    setIsEditSnapshotModalOpen(false);
  }

  function handleRollbackClicked(snapshot) {
    setSelectedSnapshot(snapshot);
    setIsRollbackSnapshotModalOpen(true);
  }

  function handleRollbackSnapshotModalClosed() {
    setIsRollbackSnapshotModalOpen(false);
  }

  function handleCreateServerFromSnapshotClicked(snapshot) {
    setSelectedSnapshot(snapshot);
    setIsCreateServerFromSnapshotModalOpen(true);
  }

  function handleCreateServerFromSnapshotModalClosed() {
    setIsCreateServerFromSnapshotModalOpen(false);
  }

  function handleSnapshotDropdownToggle(snapshot) {
    snapshot.isDropdownOpen = !snapshot.isDropdownOpen;
    setSnapshots([...snapshots]);
  }

  async function handleRemoveSnapshotClicked(snapshot) {
    const state = await confirm({
      title: intl.formatMessage({ id: "server-snapshots.remove" }),
      message: intl.formatMessage(
        { id: "server-snapshots.remove-content" },
        { name: snapshot.name }
      ),
    });

    if (state === "button2") {
      await ajax("/snapshots/remove", {
        serverID: server._id,
        name: snapshot.name,
      });
      await listSnapshots();
    }
  }

  return (
    <>
      <Box className={styles.wrapper}>
        <div className={styles.title}>
          <FormattedMessage id="server-snapshots.title" />
        </div>
        <div className={styles.description}>
          <div className={styles.svgWrapper}>
            <SnapSvg />
          </div>
          <div>
            <FormattedMessage id="server-snapshots.description" />
          </div>
        </div>
        <div className={styles.table}>
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-snapshots.name" />
                </th>
                <th>
                  <FormattedMessage id="server-snapshots.date" />
                </th>
                <th>
                  <FormattedMessage id="server-snapshots.ram" />
                </th>
                <th>
                  <FormattedMessage id="server-snapshots.table-description" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!snapshots && (
                <tr>
                  <td colSpan={5}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {snapshots?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {snapshots?.map((snapshot, idx) => (
                <tr key={idx}>
                  <td>{snapshot.name}</td>
                  <td>
                    {format(
                      fromUnixTime(snapshot.snaptime),
                      "dd/MM/yyyy HH:mm:ss"
                    )}
                  </td>
                  <td>
                    {snapshot.vmstate
                      ? intl.formatMessage({
                          id: "server-snapshots.yes",
                        })
                      : intl.formatMessage({
                          id: "server-snapshots.no",
                        })}
                  </td>
                  <td>{snapshot.description}</td>
                  <td>
                    <CustomMenu
                      isOpen={snapshot.isDropdownOpen}
                      toggle={() => handleSnapshotDropdownToggle(snapshot)}
                    >
                      {isAllowed("servers.rollback-snapshot") && (
                        <CustomMenuItem
                          onClick={() => handleRollbackClicked(snapshot)}
                        >
                          <FormattedMessage id="server-snapshots.rollback" />
                        </CustomMenuItem>
                      )}

                      {isAllowed("servers.create-delete-snapshots") && (
                        <>
                          <CustomMenuItem
                            onClick={() =>
                              handleCreateServerFromSnapshotClicked(snapshot)
                            }
                          >
                            <FormattedMessage id="server-snapshots.create-server" />
                          </CustomMenuItem>
                          <CustomMenuItem
                            onClick={() => handleEditClicked(snapshot)}
                          >
                            <FormattedMessage id="server-snapshots.edit" />
                          </CustomMenuItem>
                          <CustomMenuItem
                            onClick={() =>
                              handleRemoveSnapshotClicked(snapshot)
                            }
                          >
                            <FormattedMessage id="server-snapshots.remove" />
                          </CustomMenuItem>
                        </>
                      )}
                    </CustomMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </div>
        {isAllowed("servers.create-delete-snapshots") && (
          <div className={styles.buttonWrapper}>
            <IconButton
              disabled={server?.isWorking}
              color="light-purple"
              onClick={handleTakeClicked}
            >
              <FormattedMessage id="server-snapshots.take-snapshot" />
            </IconButton>
            <IconButton
              disabled={server?.isWorking}
              color="light-purple"
              onClick={handleRemoveAllClicked}
            >
              <FormattedMessage id="server-snapshots.remove-all-snapshots" />
            </IconButton>
          </div>
        )}
      </Box>

      <TakeSnapshotModal
        isOpen={isTakeSnapshotModalOpen}
        onClose={handleTakeSnapshotModalClosed}
      />

      <EditSnapshotModal
        isOpen={isEditSnapshotModalOpen}
        onClose={handleEditSnapshotModalClosed}
        snapshot={selectedSnapshot}
      />

      <RollbackSnapshotModal
        isOpen={isRollbackSnapshotModalOpen}
        onClose={handleRollbackSnapshotModalClosed}
        snapshot={selectedSnapshot}
      />

      <CreateServerFromSnapshotModal
        isOpen={isCreateServerFromSnapshotModalOpen}
        onClose={handleCreateServerFromSnapshotModalClosed}
        snapshot={selectedSnapshot}
      />
    </>
  );
}
