import styles from "./index.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PasswordStrengthBar from "react-password-strength-bar";
import { useAjax, useAlert, useWLDVPS } from "../../../utils/hooks";
import { proxmoxStringToObject } from "../../../utils";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import CustomReactSelect from "../../custom-react-select";

const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Terminated", value: "Terminated" },
];

const paymentTypeOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Hourly", value: "hourly" },
];

const locationOptions = [
  {
    label: "Israel",
    value: "IL",
  },
  {
    label: "Netherlands",
    value: "NL",
  },
  {
    label: "United State",
    value: "US",
  },
];

function CreateNewServerModal({ isOpen, onClose, user, editServer }) {
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();
  const wldvps = useWLDVPS();

  const boolOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "general.yes" }),
        value: true,
      },
      {
        label: intl.formatMessage({ id: "general.no" }),
        value: false,
      },
    ],
    [intl]
  );

  const [imageOptions, setImageOptions] = useState([]);
  const [cpuOptions, setCpuOptions] = useState([]);
  const [ramOptions, setRamOptions] = useState([]);
  const [ssdOptions, setSsdOptions] = useState([]);
  const [additionalSsdOptions, setAdditionalSsdOptions] = useState([]);

  const [domain, setDomain] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [status, setStatus] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [image, setImage] = useState(null);
  const [node, setNode] = useState("");
  const [vmid, setVmid] = useState("");
  const [serverIP, setServerIP] = useState("");
  const [location, setLocation] = useState(null);
  const [cpu, setCpu] = useState(null);
  const [ram, setRam] = useState(null);
  const [ssd, setSsd] = useState(null);
  const [additionalSsd, setAdditionalSsd] = useState(null);
  const [backup, setBackup] = useState(null);
  const [tag, setTag] = useState("");

  const [syncEnabled, setSyncEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchProductsAndPrices = useCallback(async () => {
    const data = await ajax(`/admin/getProductsAndPrices`, { forCloud: true });

    if (data.result === "error") {
      return;
    }

    const prices = data.productsAndPrices.prices;

    setImageOptions(
      prices.image.map((i) => ({
        label: i.value,
        value: i.value,
      }))
    );

    setCpuOptions(prices.cpu.map((c) => ({ label: c.value, value: c.value })));

    setRamOptions(
      prices.ram_mb.map((r) => ({
        label: r.value,
        value: r.value,
      }))
    );

    setSsdOptions(
      prices.ssd_gb.map((s) => ({
        label: s.value,
        value: s.value,
      }))
    );

    setAdditionalSsdOptions(
      [
        {
          label: intl.formatMessage({ id: "general.none" }),
          value: false,
        },
      ].concat(
        prices.additional_ssd_gb.map((as, idx) => ({
          label: as.value,
          value: as.value,
          key: idx,
        }))
      )
    );
  }, [ajax, intl]);

  useEffect(() => {
    fetchProductsAndPrices();
  }, [fetchProductsAndPrices]);

  useEffect(() => {
    setSyncEnabled(node && vmid ? false : true);
  }, [node, vmid]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (editServer) {
      setDomain(editServer.hostname);
      setPassword("");
      setStatus(statusOptions.find((s) => s.value === editServer.status));
      setPaymentType(
        paymentTypeOptions.find(
          (p) => p.value === editServer.payment.payment_type
        )
      );
      setLocation(locationOptions.find((l) => l.value === editServer.location));
      setImage(imageOptions.find((i) => i.value === editServer.image));
      setCpu(cpuOptions.find((c) => c.value === editServer.cpu));
      setRam(ramOptions.find((r) => r.value === editServer.ram_mb));
      setSsd(ssdOptions.find((s) => s.value === editServer.ssd_gb));

      if (!editServer.additional_ssd_gb || editServer.additional_ssd_gb === 0) {
        setAdditionalSsd(additionalSsdOptions[0]);
      } else {
        setAdditionalSsd(
          additionalSsdOptions.find(
            (as) => as.value === editServer.additional_ssd_gb
          )
        );
      }

      setBackup(boolOptions.find((b) => b.value === editServer.backup));
      setTag(editServer.tag);
      setNode(editServer.node.toString());
      setVmid(editServer.vmid.toString());

      if (editServer.ip) {
        setServerIP(editServer.ip.ip);
      }
    } else {
      setDomain("");
      setPassword("");
      setStatus(null);
      setPaymentType(null);
      setImage(null);
      setNode("");
      setVmid("");
      setServerIP("");
      setLocation(null);
      setCpu(null);
      setRam(null);
      setSsd(null);
      setAdditionalSsd(null);
      setBackup(null);
      setTag("");
      setSyncEnabled(false);
      setError(false);
      setLoading(false);
    }
  }, [
    additionalSsdOptions,
    boolOptions,
    cpuOptions,
    editServer,
    imageOptions,
    isOpen,
    ramOptions,
    ssdOptions,
  ]);

  async function handleSyncClicked() {
    setLoading(true);
    const data = await ajax(`/proxmox/nodes/qemu/status/getStatus`, {
      node,
      vmid,
    });
    setLoading(false);

    if (data.result === "success") {
      setLocation(locationOptions.find((l) => l.value === data.dataCenter));
      setCpu(cpuOptions.find((c) => c.value === data.status.cpus));
      setRam(
        ramOptions.find(
          (r) => r.value === data.status.maxmem / Math.pow(1024, 2)
        )
      );
      setSsd(
        ssdOptions.find(
          (s) => s.value === data.status.maxdisk / Math.pow(1024, 3)
        )
      );

      if (data.config.ipconfig0) {
        const ipObj = proxmoxStringToObject(data.config.ipconfig0);
        if (ipObj) {
          const ip = ipObj.ip.split("/")[0];
          setServerIP(ip);
        }
      }
    } else {
      await alert(
        intl.formatMessage({
          id: "create-new-server-modal.sync-failed.title",
        }),
        intl.formatMessage({
          id: "create-new-server-modal.sync-failed.content",
        })
      );
    }
  }

  async function handleCreateClicked() {
    setError(false);

    const _domain = domain?.trim();
    const _password = password?.trim();
    const _node = node?.trim();
    const _vmid = vmid?.trim();
    const _serverIP = serverIP?.trim();
    const _tag = tag?.trim();

    if (!_domain) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-domain",
        })
      );
    } else if (_password && passwordScore <= 1) {
      return setError(
        intl.formatMessage({
          id: "general.weak-password",
        })
      );
    } else if (!status) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-status",
        })
      );
    } else if (!paymentType) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-payment-type",
        })
      );
    } else if (!image) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-image",
        })
      );
    } else if (!_node) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-node",
        })
      );
    } else if (!_vmid) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-vmid",
        })
      );
    } else if (!location) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-location",
        })
      );
    } else if (!cpu) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-cpu",
        })
      );
    } else if (!ram) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-ram",
        })
      );
    } else if (!ssd) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-ssd",
        })
      );
    } else if (!additionalSsd) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-additional-ssd",
        })
      );
    } else if (!backup) {
      return setError(
        intl.formatMessage({
          id: "create-new-server-modal.create.wrong-backup",
        })
      );
    }

    setLoading(true);
    const data = await ajax(
      editServer ? "/admin/editServer" : "/admin/createServer",
      {
        serverID: editServer?._id,
        userID: user._id,
        domain: _domain,
        password: _password,
        status: status.value,
        paymentType: paymentType.value,
        image: image.value,
        node: _node,
        vmid: _vmid,
        serverIP: _serverIP,
        location: location.value,
        cpu: cpu.value,
        ram: ram.value,
        ssd: ssd.value,
        additionalSsd: additionalSsd.value,
        backup: backup.value,
        tag: _tag,
      }
    );
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      await alert(
        intl.formatMessage({
          id: editServer
            ? "create-new-server-modal.title-edit"
            : "create-new-server-modal.title",
        }),
        intl.formatMessage({ id: `create-new-server-modal.${data.message}` })
      );
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editServer
              ? "create-new-server-modal.title-edit"
              : "create-new-server-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <div className={styles.layout}>
          <div className={styles.layoutContent}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.domain" />
              </span>
              <CustomText
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-server-modal.password" />
              </span>
              <CustomText
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={intl.formatMessage({ id: "general.leave-blank" })}
              />
            </div>
            {password && (
              <div className={styles.row}>
                <span></span>
                <PasswordStrengthBar
                  className={styles.passwordStrengthBarWrapper}
                  password={password}
                  onChangeScore={setPasswordScore}
                />
              </div>
            )}
            {!wldvps && (
              <>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.status" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-status"
                    options={statusOptions}
                    value={status}
                    onChange={(item) => setStatus(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.payment-type" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-payment-type"
                    options={paymentTypeOptions}
                    value={paymentType}
                    onChange={(item) => setPaymentType(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.tag" />
                  </span>
                  <CustomText
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.image" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-image"
                    options={imageOptions}
                    value={image}
                    onChange={(item) => setImage(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.node" />
                  </span>
                  <CustomText
                    value={node}
                    onChange={(e) => setNode(e.target.value)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.vmid" />
                  </span>
                  <CustomText
                    value={vmid}
                    onChange={(e) => setVmid(e.target.value)}
                  />
                  <IconButton
                    className={styles.syncButton}
                    disabled={syncEnabled || loading}
                    color="light-purple"
                    onClick={handleSyncClicked}
                  >
                    <FormattedMessage id="general.sync" />
                  </IconButton>
                </div>
              </>
            )}
          </div>
          <div className={styles.layoutContent}>
            {!wldvps && (
              <>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.server-ip" />
                  </span>
                  <CustomText
                    value={serverIP}
                    onChange={(e) => setServerIP(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: "create-new-server-modal.server-ip-blank",
                    })}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.location" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-location"
                    options={locationOptions}
                    value={location}
                    onChange={(item) => setLocation(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.cpu" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-cpu"
                    options={cpuOptions}
                    value={cpu}
                    onChange={(item) => setCpu(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.ram" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-ram"
                    options={ramOptions}
                    value={ram}
                    onChange={(item) => setRam(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.disk" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-disk"
                    options={ssdOptions}
                    value={ssd}
                    onChange={(item) => setSsd(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.additional-disk" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-additional-disk"
                    options={additionalSsdOptions}
                    value={additionalSsd}
                    onChange={(item) => setAdditionalSsd(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-server-modal.backup" />
                  </span>
                  <CustomReactSelect
                    instanceId="create-new-server-modal-backup"
                    options={boolOptions}
                    value={backup}
                    onChange={(item) => setBackup(item)}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleCreateClicked}
        >
          <FormattedMessage
            id={editServer ? "general.save" : "general.create"}
          />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

CreateNewServerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  editServer: PropTypes.object,
};

export default CreateNewServerModal;
