import styles from "./plan.module.scss";

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../icon-button";
import { ReactComponent as CpuSvg } from "../svgs/cpu.svg";
import { ReactComponent as RamSvg } from "../svgs/ram.svg";
import { ReactComponent as SSDSvg } from "../svgs/ssd.svg";
import { ReactComponent as MinusRedSvg } from "../svgs/minus-red.svg";
import Counter from "../counter";
import SwitchSelector from "../switch-selector";
import { useUser } from "../../utils/hooks";
import { getClosestPrice } from "../../utils/billing";

function Plan({
  withTitle = true,
  image,
  cpu,
  setCpu,
  ram_mb,
  setRamMB,
  ssd_gb,
  setSsdGB,
  additional_ssd_gb,
  setAdditionalSsdGb,
  paymentType,
  setPaymentType,
  hidePaymentType = false,
  setIsNextButtonDisabled,
  ssd_gbMin = false,
  additional_ssd_gbMin = false,
  withDeals = true,
}) {
  const intl = useIntl();
  const user = useUser();

  useEffect(() => {
    setIsNextButtonDisabled(
      image.includes("Windows") && (ram_mb < 2048 || ssd_gb < 30)
    );
  }, [setIsNextButtonDisabled, image, ram_mb, ssd_gb]);

  useEffect(() => {
    if (ssd_gbMin && ssd_gb < ssd_gbMin) {
      setSsdGB(ssd_gbMin);
    }
  }, [setSsdGB, ssd_gb, ssd_gbMin]);

  useEffect(() => {
    if (
      additional_ssd_gbMin &&
      additional_ssd_gb > 0 &&
      additional_ssd_gb < additional_ssd_gbMin
    ) {
      setAdditionalSsdGb(additional_ssd_gbMin);
    }
  }, [additional_ssd_gb, additional_ssd_gbMin, setAdditionalSsdGb]);

  useEffect(() => {
    if (image.includes("Windows")) {
      if (ram_mb < 2048) {
        setRamMB(2048);
      }

      if (ssd_gb < 30) {
        setSsdGB(30);
      }
    }
  }, [image, setRamMB, setSsdGB, ram_mb, ssd_gb]);

  function getSwitchSelectorItems() {
    return [
      {
        title: intl.formatMessage({ id: "general.hourly" }),
        selected: paymentType === "hourly",
        value: "hourly",
      },
      {
        title: intl.formatMessage({ id: "general.monthly" }),
        selected: paymentType === "monthly",
        value: "monthly",
      },
    ];
  }

  function handleDealClicked(type) {
    switch (type) {
      case "lite":
        setCpu(1);
        setRamMB(1024);
        setSsdGB(20);
        setAdditionalSsdGb(0);
        break;
      case "standard":
        setCpu(2);
        setRamMB(4096);
        setSsdGB(60);
        setAdditionalSsdGb(0);
        break;
      case "premium":
        setCpu(4);
        setRamMB(4096);
        setSsdGB(100);
        setAdditionalSsdGb(0);
        break;
    }
  }

  function isDealSelected(type) {
    if (
      type === "lite" &&
      cpu === 1 &&
      ram_mb === 1024 &&
      ssd_gb === 20 &&
      additional_ssd_gb === 0
    ) {
      return styles.selected;
    }

    if (
      type === "standard" &&
      cpu === 2 &&
      ram_mb === 4096 &&
      ssd_gb === 60 &&
      additional_ssd_gb === 0
    ) {
      return styles.selected;
    }

    if (
      type === "premium" &&
      cpu === 4 &&
      ram_mb === 4096 &&
      ssd_gb === 100 &&
      additional_ssd_gb === 0
    ) {
      return styles.selected;
    }

    return "";
  }

  function handleCounterClicked(type, oldValue, setValue, newValue) {
    const price = getClosestPrice(
      newValue,
      user.productPrices[type],
      newValue > oldValue ? "up" : "down"
    );

    setValue(price.value);
  }

  return (
    <div className={`${styles.planWrapper} ${withTitle && styles.padding}`}>
      {withTitle && (
        <div className={styles.title}>
          <FormattedMessage id="plan.title" />
        </div>
      )}
      <div className={styles.packages}>
        {withDeals && (
          <div className={styles.deals}>
            <div
              className={`${styles.deal} ${isDealSelected("lite")}`}
              onClick={() => handleDealClicked("lite")}
            >
              <div className={styles.dealTitle}>Lite</div>
              <div className={styles.dealText}>
                Great for small business with up to 1k a month of traffic
              </div>
              <div className={styles.dealStartingAt}>Starting at 11.00$</div>
            </div>

            <div
              className={`${styles.deal} ${isDealSelected("standard")}`}
              onClick={() => handleDealClicked("standard")}
            >
              <div className={styles.dealTitle}>Standard</div>
              <div className={styles.dealText}>
                Great for medium business with up to 12-30k a month of traffic
              </div>
              <div className={styles.dealStartingAt}>Starting at 16.50$</div>
            </div>

            <div
              className={`${styles.deal} ${isDealSelected("premium")}`}
              onClick={() => handleDealClicked("premium")}
            >
              <div className={styles.dealTitle}>Premium</div>
              <div className={styles.dealText}>
                Great for large business with up to 200k+ a month of traffic
              </div>
              <div className={styles.dealStartingAt}>Starting at 22.75$</div>
            </div>
          </div>
        )}

        <div className={styles.hardwareBox}>
          <div className={styles.title}>
            <FormattedMessage id="plan.hardware-box.title" />
          </div>
          <div className={styles.hardware}>
            <div className={styles.hardwareTitleWrapper}>
              <CpuSvg />
              <span className={styles.hardwareTitle}>CPU</span>
            </div>
            <div className={styles.counterSection}>
              <Counter
                count={cpu}
                setCount={(value) =>
                  handleCounterClicked("cpu", cpu, setCpu, value)
                }
              />
              <MinusRedSvg className={styles.invisible} />
            </div>
          </div>
          <div className={styles.hardware}>
            <div className={styles.hardwareTitleWrapper}>
              <RamSvg />
              <span className={styles.hardwareTitle}>RAM</span>
            </div>
            <div className={styles.counterSection}>
              <Counter
                render={ram_mb / 1024}
                count={ram_mb}
                setCount={(value) =>
                  handleCounterClicked("ram_mb", ram_mb, setRamMB, value)
                }
                delta={1024}
                extraText="GB"
              />
              <MinusRedSvg className={styles.invisible} />
            </div>
          </div>
          <div className={styles.hardware}>
            <div className={styles.hardwareTitleWrapper}>
              <SSDSvg />
              <span className={styles.hardwareTitle}>SSD NVMe #1</span>
            </div>
            <div className={styles.counterSection}>
              <Counter
                count={ssd_gb}
                setCount={(value) =>
                  handleCounterClicked("ssd_gb", ssd_gb, setSsdGB, value)
                }
                extraText="GB"
              />
              <MinusRedSvg className={styles.invisible} />
            </div>
          </div>
          {additional_ssd_gb > 0 && (
            <div className={styles.hardware}>
              <div className={styles.hardwareTitleWrapper}>
                <SSDSvg />
                <span className={styles.hardwareTitle}>SSD NVMe #2</span>
              </div>
              <div className={styles.counterSection}>
                <Counter
                  count={additional_ssd_gb}
                  setCount={(value) =>
                    handleCounterClicked(
                      "additional_ssd_gb",
                      additional_ssd_gb,
                      setAdditionalSsdGb,
                      value
                    )
                  }
                  extraText="GB"
                />
                <MinusRedSvg onClick={() => setAdditionalSsdGb(0)} />
              </div>
            </div>
          )}
          {additional_ssd_gb === 0 && (
            <div className={styles.addButton}>
              <IconButton
                color="white"
                icon="plus"
                onClick={() => setAdditionalSsdGb(10)}
              >
                <FormattedMessage id="plan.hardware-box.add-additional-disk" />
              </IconButton>
            </div>
          )}

          {!hidePaymentType && (
            <>
              <div className={styles.hr}>
                <hr />
              </div>
              <div className={styles.paymentType}>
                <div className={styles.playmentTypeText}>
                  <FormattedMessage id="general.payment-type" />
                </div>
                <div>
                  <SwitchSelector
                    items={getSwitchSelectorItems()}
                    onChange={(value) => setPaymentType(value)}
                  />
                </div>
              </div>

              {paymentType === "monthly" && (
                <div
                  className={styles.explanations}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "plan.hardware-box.payment-type-explanations-monthly",
                    }),
                  }}
                ></div>
              )}
              {paymentType === "hourly" && (
                <div
                  className={styles.explanations}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "plan.hardware-box.payment-type-explanations-hourly",
                    }),
                  }}
                ></div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

Plan.propTypes = {
  withTitle: PropTypes.bool,
  image: PropTypes.string,
  cpu: PropTypes.number,
  setCpu: PropTypes.func,
  ram_mb: PropTypes.number,
  setRamMB: PropTypes.func,
  ssd_gb: PropTypes.number,
  setSsdGB: PropTypes.func,
  additional_ssd_gb: PropTypes.number,
  setAdditionalSsdGb: PropTypes.func,
  paymentType: PropTypes.string,
  setPaymentType: PropTypes.func,
  hidePaymentType: PropTypes.bool,
  setIsNextButtonDisabled: PropTypes.func,
  ssd_gbMin: PropTypes.number,
  additional_ssd_gbMin: PropTypes.number,
  withDeals: PropTypes.bool,
};

export default Plan;
