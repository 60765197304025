import styles from "./index.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { format } from "date-fns";
import { setDir } from "../../../../utils";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  useUser,
  useWLDVPS,
} from "../../../../utils/hooks";
import Box from "../../../../components/box";
import BasicTable from "../../../../components/basic-table";
import LayoutMainAction from "../../../../components/layout-main-action";
import {
  getFullName,
  SUPER_ADMIN,
  USER,
  WHITELABEL,
} from "../../../../utils/user";
import { WithRole } from "../../../../components/with-role";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Checkbox from "../../../../components/checkbox";
import { getAllWLDVPS } from "../../../../utils/wldvps";
import { Link } from "react-router-dom";
import Spinner from "../../../../components/spinner";

function SupportMyTickets({ onlyUser, compactMode = false }) {
  const ajax = useAjax();
  const intl = useIntl();
  const user = useUser();
  const lang = useLang();
  const alert = useAlert();
  const confirm = useConfirm();
  const wldvps = useWLDVPS();

  const [search, setSearch] = useState("");
  const [priority, setPriority] = useState("all");
  const [department, setDepartment] = useState("all");
  const [departments, setDepartments] = useState([]);
  const [whitelabel, setWhitelabel] = useState(null);
  const [whitelabels, setWhitelabels] = useState([]);
  const [sort, setSort] = useState("updated_at");
  const [sorts, setSorts] = useState([]);
  const [statusToShow, setStatusToShow] = useState("");

  const [selectedTickets, setSelectedTickets] = useState({});

  const [isShowDropdownOpen, setIsShowDropdownOpen] = useState(false);
  const [isPriorityDropdownOpen, setIsPriorityDropdownOpen] = useState(false);
  const [isDepartmentDropdownOpen, setIsDepartmentDropdownOpen] = useState(
    false
  );
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [isWhitelabelDropdownOpen, setIsWhitelabelDropdownOpen] = useState(
    false
  );
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);

  const [tickets, setTickets] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState([]);

  const getTickets = useCallback(async () => {
    const data = await ajax("/tickets/getTickets");

    if (data.result === "success") {
      if (onlyUser) {
        data.tickets = data.tickets.filter(
          (ticket) => ticket.user_id === onlyUser._id
        );
      }

      let departments = new Set();

      data.tickets.forEach((ticket) => {
        if (ticket.priority === "high") {
          ticket.priority = 3;
          ticket.priorityText = intl.formatMessage({
            id: "general.high",
          });
        }
        if (ticket.priority === "medium") {
          ticket.priority = 2;
          ticket.priorityText = intl.formatMessage({
            id: "general.medium",
          });
        }
        if (ticket.priority === "low") {
          ticket.priority = 1;
          ticket.priorityText = intl.formatMessage({
            id: "general.low",
          });
        }

        departments.add(ticket.department);
      });

      departments = [...departments];

      setDepartments(departments);

      setTickets(data.tickets);
    }
  }, [ajax, intl, onlyUser]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  useEffect(() => {
    setStatusToShow(user.role === USER ? "open" : "open-tickets");
  }, [user.role]);

  useEffect(() => {
    let sorts = ["updated_at", "ticket_number", "priority"];

    if (user.role !== USER) {
      sorts.push("submitter");
    }

    setSorts(sorts);
    setSort(sorts[0]);
  }, [user.role]);

  useEffect(() => {
    if (!tickets) {
      return;
    }

    const _search = search.toLowerCase();

    const filteredTickets = tickets.filter(
      (ticket) =>
        // priority
        (priority === "all" ||
          ticket.priorityText.toLowerCase() === priority) &&
        // department
        (department === "all" || ticket.department === department) &&
        // status
        (compactMode ||
          (statusToShow === "follow-up" &&
            ticket.reminder &&
            ticket.status === "open") ||
          ticket.status === statusToShow ||
          (statusToShow === "open-tickets" &&
            ticket.status !== "closed" &&
            !ticket.reminder) ||
          (statusToShow === "my-tickets" &&
            ticket.status !== "closed" &&
            ticket.assigned_user_id === user._id) ||
          (statusToShow === "snoozed" &&
            ticket.status !== "closed" &&
            ticket.reminder)) &&
        // whitelabel
        (wldvps || whitelabel === "all" || ticket.whitelabel === whitelabel) &&
        // search
        (ticket.subject.includes(_search) ||
          ticket.ticket_number.toString().includes(_search) ||
          (ticket.user.firstName &&
            ticket.user.firstName.toLowerCase().includes(_search)) ||
          (ticket.user.lastName &&
            ticket.user.lastName.toLowerCase().includes(_search)) ||
          `${ticket.user.firstName} ${ticket.user.lastName}`
            .toLowerCase()
            .includes(_search))
    );

    filteredTickets.sort((a, b) => {
      if (["updated_at"].includes(sort)) {
        if (new Date(a[sort]) === new Date(b[sort])) {
          return 0;
        }

        return new Date(a[sort]) > new Date(b[sort]) ? -1 : 1;
      }

      if (["submitter"].includes(sort)) {
        if (a.firstName === b.firstName) {
          return 0;
        }

        return a.firstName > b.firstName ? -1 : 1;
      }

      if (["updated_at", "priority"].includes(sort)) {
        if (a[sort] === b[sort]) {
          return 0;
        }

        return a[sort] > b[sort] ? -1 : 1;
      }

      if (parseInt(a[sort]) === parseInt(b[sort])) {
        return 0;
      }

      return parseInt(a[sort]) > parseInt(b[sort]) ? -1 : 1;
    });

    setFilteredTickets(filteredTickets);
  }, [
    department,
    priority,
    search,
    sort,
    tickets,
    user._id,
    whitelabel,
    statusToShow,
    wldvps,
    compactMode,
  ]);

  useEffect(() => {
    let whitelabels;

    if (user.role === SUPER_ADMIN) {
      whitelabels = getAllWLDVPS(user.whitelabelSettings);

      setWhitelabels(["all"].concat(whitelabels));
    } else {
      whitelabels = ["all"];

      setWhitelabels(whitelabels);
    }
  }, [intl, user.whitelabelSettings, user.role]);

  function getStatusColor(status) {
    switch (status) {
      case "open":
        return styles.green;

      default:
        return styles.red;
    }
  }

  function handleCheckboxChanged(ticket) {
    if (ticket) {
      selectedTickets[ticket._id] = !selectedTickets[ticket._id];
      setSelectedTickets({ ...selectedTickets });
    } else {
      const allCheckboxChecked = isAllCheckboxChecked();

      setSelectedTickets(
        filteredTickets.reduce(
          (obj, item) => ({ ...obj, [item._id]: !allCheckboxChecked }),
          {}
        )
      );
    }
  }

  function isAllCheckboxChecked() {
    return (
      Object.keys(selectedTickets).filter((key) => selectedTickets[key])
        .length === filteredTickets.length
    );
  }

  async function handleMergeTicketsMenuClicked() {
    const selectedTicketsKeys = Object.keys(selectedTickets).filter(
      (k) => selectedTickets[k]
    );

    const selected = filteredTickets.filter((t) =>
      selectedTicketsKeys.includes(t._id)
    );

    if (selected.length <= 1) {
      await alert(
        intl.formatMessage({ id: "support-my-tickets.merge-tickets" }),
        intl.formatMessage({
          id: "support-my-tickets.merge-tickets.one-selected",
        })
      );

      return;
    }

    const users = [...new Set(selected.map((ticket) => ticket.user_id))];

    if (users.length > 1) {
      await alert(
        intl.formatMessage({ id: "support-my-tickets.merge-tickets" }),
        intl.formatMessage({
          id: "support-my-tickets.merge-tickets.different-users",
        })
      );

      return;
    }

    const state = await confirm({
      title: intl.formatMessage({ id: "support-my-tickets.merge-tickets" }),
      message: intl.formatMessage(
        { id: "support-my-tickets.merge-tickets.confirm" },
        { total: selected.length }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax(`/tickets/merge`, {
      ids: selected.map((ticket) => ticket._id),
    });

    await getTickets();
  }

  async function handleCloseTicketsMenuClicked() {
    const selectedTicketsKeys = Object.keys(selectedTickets).filter(
      (k) => selectedTickets[k]
    );

    const selected = filteredTickets.filter((t) =>
      selectedTicketsKeys.includes(t._id)
    );

    if (selected.length < 1) {
      await alert(
        intl.formatMessage({ id: "support-my-tickets.close-tickets" }),
        intl.formatMessage({
          id: "support-my-tickets.close-tickets.no-selected",
        })
      );

      return;
    }

    const state = await confirm({
      title: intl.formatMessage({ id: "support-my-tickets.close-tickets" }),
      message: intl.formatMessage(
        { id: "support-my-tickets.close-tickets.confirm" },
        { total: selected.length }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax(`/tickets/closeMany`, {
      ids: selected.map((ticket) => ticket._id),
    });

    await getTickets();
  }

  function renderExtraTitle(ticket) {
    if ([SUPER_ADMIN, WHITELABEL].includes(user.role) && ticket.reminder) {
      return (
        <b>
          {" - "}
          {intl.formatMessage({
            id: "support-my-tickets.snoozed-to",
          })}{" "}
          {format(new Date(ticket.reminder), "d/M/y HH:mm")}
        </b>
      );
    }

    if (!ticket.assigned_user_name) {
      return (
        <b>
          {" - "}
          <FormattedMessage tagName="b" id="support-my-tickets.unassigned" />
        </b>
      );
    }

    return null;
  }

  const layoutButtons = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "support-my-tickets.open-new-ticket" }),
        href: "/[lang]/support/open-new-ticket",
        as: `/${lang}/support/open-new-ticket`,
      },
    ],
    [intl, lang]
  );

  return (
    <WithRole permission="tickets.see">
      <LayoutMainAction
        title={
          onlyUser
            ? null
            : intl.formatMessage({ id: "support-my-tickets.main-title" })
        }
        buttons={onlyUser ? null : layoutButtons}
      >
        <div className={styles.wrapper}>
          {!onlyUser && tickets?.length > 0 && (
            <>
              <div className={styles.header}>
                <div>
                  <FormattedMessage id="support-my-tickets.show" />
                  <ButtonDropdown
                    className={styles.ghost}
                    isOpen={isShowDropdownOpen}
                    toggle={() => setIsShowDropdownOpen(!isShowDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      <FormattedMessage
                        id={`support-my-tickets.${statusToShow}`}
                      />
                    </DropdownToggle>
                    <DropdownMenu right>
                      {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                        <DropdownItem onClick={() => setStatusToShow("open")}>
                          <FormattedMessage id="support-my-tickets.open" />
                        </DropdownItem>
                      )}
                      {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                        <>
                          <DropdownItem
                            onClick={() => setStatusToShow("open-tickets")}
                          >
                            <FormattedMessage id="support-my-tickets.open-tickets" />
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => setStatusToShow("my-tickets")}
                          >
                            <FormattedMessage id="support-my-tickets.my-tickets" />
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => setStatusToShow("snoozed")}
                          >
                            <FormattedMessage id="support-my-tickets.snoozed" />
                          </DropdownItem>
                        </>
                      )}
                      <DropdownItem onClick={() => setStatusToShow("closed")}>
                        <FormattedMessage id="support-my-tickets.closed" />
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>

                <div>
                  <FormattedMessage id="support-my-tickets.priority" />
                  <ButtonDropdown
                    className={styles.ghost}
                    isOpen={isPriorityDropdownOpen}
                    toggle={() =>
                      setIsPriorityDropdownOpen(!isPriorityDropdownOpen)
                    }
                  >
                    <DropdownToggle caret>
                      <FormattedMessage id={`general.${priority}`} />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => setPriority("all")}>
                        <FormattedMessage id="general.all" />
                      </DropdownItem>
                      <DropdownItem onClick={() => setPriority("high")}>
                        <FormattedMessage id="general.high" />
                      </DropdownItem>
                      <DropdownItem onClick={() => setPriority("medium")}>
                        <FormattedMessage id="general.medium" />
                      </DropdownItem>
                      <DropdownItem onClick={() => setPriority("low")}>
                        <FormattedMessage id="general.low" />
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>

                <div>
                  <FormattedMessage id="support-my-tickets.department" />
                  <ButtonDropdown
                    className={styles.ghost}
                    isOpen={isDepartmentDropdownOpen}
                    toggle={() =>
                      setIsDepartmentDropdownOpen(!isDepartmentDropdownOpen)
                    }
                  >
                    <DropdownToggle caret>
                      {department === "all"
                        ? intl.formatMessage({ id: "general.all" })
                        : department}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => setDepartment("all")}>
                        {intl.formatMessage({ id: "general.all" })}
                      </DropdownItem>
                      {departments.map((dep, key) => (
                        <DropdownItem
                          key={key}
                          onClick={() => setDepartment(dep)}
                        >
                          {dep}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>

                <div>
                  <FormattedMessage id="support-my-tickets.sort" />
                  <ButtonDropdown
                    className={styles.ghost}
                    isOpen={isSortDropdownOpen}
                    toggle={() => setIsSortDropdownOpen(!isSortDropdownOpen)}
                  >
                    <DropdownToggle caret>
                      <FormattedMessage
                        id={`support-my-tickets.sort.${sort}`}
                      />
                    </DropdownToggle>
                    <DropdownMenu right>
                      {sorts.map((s, key) => (
                        <DropdownItem key={key} onClick={() => setSort(s)}>
                          <FormattedMessage
                            id={`support-my-tickets.sort.${s}`}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>

                {!wldvps && user.role === SUPER_ADMIN && (
                  <div>
                    <FormattedMessage id="support-my-tickets.whitelabel" />
                    <ButtonDropdown
                      className={styles.ghost}
                      isOpen={isWhitelabelDropdownOpen}
                      toggle={() =>
                        setIsWhitelabelDropdownOpen(!isWhitelabelDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {whitelabel || "DreamVPS"}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {whitelabels.map((wl, key) => (
                          <DropdownItem
                            key={key}
                            onClick={() => setWhitelabel(wl)}
                          >
                            {wl || "DreamVPS"}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                )}
              </div>

              <div className={styles.header}>
                <div>
                  <input
                    className={styles.filterInput}
                    placeholder={intl.formatMessage({
                      id: "general.filter...",
                    })}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                <div className={styles.header}>
                  <ButtonDropdown
                    className={styles.actionButton}
                    isOpen={isActionDropdownOpen}
                    toggle={() =>
                      setIsActionDropdownOpen(!isActionDropdownOpen)
                    }
                  >
                    <DropdownToggle caret>
                      <FormattedMessage id="general.select-action" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={handleMergeTicketsMenuClicked}>
                        <FormattedMessage id="support-my-tickets.merge-tickets" />
                      </DropdownItem>
                      <DropdownItem onClick={handleCloseTicketsMenuClicked}>
                        <FormattedMessage id="support-my-tickets.close-tickets" />
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              )}
            </>
          )}

          {!tickets && (
            <Box>
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            </Box>
          )}

          {tickets?.length === 0 && (
            <Box>
              <Link
                href="/[lang]/support/open-new-ticket"
                to={`/${lang}/support/open-new-ticket`}
                target={compactMode ? "_blank" : ""}
              >
                <FormattedMessage id="support-my-tickets.no-tickets" />
              </Link>
            </Box>
          )}

          {tickets?.length > 0 && (
            <Box style={{ padding: "22px 0 0 0" }}>
              <div>
                <BasicTable className={styles.tableWrapper}>
                  <thead>
                    <tr>
                      <th className={styles.checkbox} style={{ width: "70px" }}>
                        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                          <Checkbox
                            checked={isAllCheckboxChecked()}
                            onChange={() => handleCheckboxChanged()}
                          />
                        )}
                      </th>
                      <th style={{ width: "80px" }}>
                        <FormattedMessage id="support-my-tickets.status" />
                      </th>
                      <th style={{ width: "100px" }}>
                        <FormattedMessage id="support-my-tickets.department" />
                      </th>
                      <th>
                        <FormattedMessage id="support-my-tickets.subject" />
                      </th>
                      {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                        <th style={{ width: "150px" }}>
                          <FormattedMessage id="support-my-tickets.submitter" />
                        </th>
                      )}
                      <th style={{ width: "100px" }}>
                        <FormattedMessage id="support-my-tickets.priority" />
                      </th>
                      <th style={{ width: "150px" }}>
                        <FormattedMessage id="support-my-tickets.last-reply" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTickets.length === 0 && (
                      <tr>
                        <td></td>
                        <td colSpan={6}>
                          <FormattedMessage id="general.no-rows" />
                        </td>
                      </tr>
                    )}

                    {filteredTickets.map((ticket, key) => (
                      <tr key={key}>
                        <td
                          className={styles.checkbox}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                            <Checkbox
                              checked={selectedTickets[ticket._id] || false}
                              onChange={() => handleCheckboxChanged(ticket)}
                            />
                          )}
                        </td>
                        <td>
                          <Link
                            href="/[lang]/support/my-tickets/ticket_number"
                            to={`/${lang}/support/my-tickets/${ticket._id}`}
                            target={compactMode ? "_blank" : ""}
                          >
                            <div
                              className={`${styles.status} ${getStatusColor(
                                ticket.status
                              )}`}
                            >
                              {ticket.status}
                            </div>
                          </Link>
                        </td>
                        <td>
                          <Link
                            href="/[lang]/support/my-tickets/ticket_number"
                            to={`/${lang}/support/my-tickets/${ticket._id}`}
                            target={compactMode ? "_blank" : ""}
                          >
                            {ticket.department.replace(/_/g, " ")}
                          </Link>
                        </td>
                        <td>
                          <Link
                            href="/[lang]/support/my-tickets/ticket_number"
                            to={`/${lang}/support/my-tickets/${ticket._id}`}
                            target={compactMode ? "_blank" : ""}
                          >
                            <div className={styles.ticketNumber}>
                              #{ticket.ticket_number}
                              {renderExtraTitle(ticket)}
                            </div>
                            {setDir(ticket.subject)}
                          </Link>
                        </td>

                        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                          <td
                            className={styles.submitter}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div>
                              <a
                                href={`/${lang}/clients/clients-list/${ticket.user._id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {getFullName(ticket.user)}
                                {[SUPER_ADMIN].includes(user.role) &&
                                  ticket.whitelabel &&
                                  ` (${ticket.whitelabel})`}
                              </a>
                            </div>
                            {ticket.assigned_user_name && (
                              <>
                                <hr />
                                <div>
                                  <FormattedMessage id="support-my-tickets.assignee" />{" "}
                                  <a
                                    href={`/${lang}/clients/clients-list/${ticket.assigned_user_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {ticket.assigned_user_name.split(" ")[0]}
                                  </a>
                                </div>
                              </>
                            )}
                          </td>
                        )}

                        <td>
                          <Link
                            href="/[lang]/support/my-tickets/ticket_number"
                            to={`/${lang}/support/my-tickets/${ticket._id}`}
                            target={compactMode ? "_blank" : ""}
                          >
                            <div className={styles.priority}>
                              {ticket.priorityText}
                            </div>
                          </Link>
                        </td>
                        <td>
                          <Link
                            href="/[lang]/support/my-tickets/ticket_number"
                            to={`/${lang}/support/my-tickets/${ticket._id}`}
                            target={compactMode ? "_blank" : ""}
                          >
                            {format(new Date(ticket.updated_at), "d/M/y HH:mm")}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </BasicTable>
              </div>
            </Box>
          )}
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}

SupportMyTickets.propTypes = {
  onlyUser: PropTypes.object,
  compactMode: PropTypes.bool,
};

export default SupportMyTickets;
