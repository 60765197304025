import React from "react";
import PropTypes from "prop-types";

function KeyDetector({ children, onKeyDetected, keyCode = 13, className }) {
  function handleKeyDown(e) {
    if (e.keyCode === keyCode) {
      onKeyDetected();
    }
  }

  return (
    <div className={className} onKeyDown={handleKeyDown}>
      {children}
    </div>
  );
}

KeyDetector.propTypes = {
  children: PropTypes.any,
  onKeyDetected: PropTypes.func,
  keyCode: PropTypes.number,
  className: PropTypes.string,
};

export default KeyDetector;
