import styles from "./iso.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import CustomText from "../../../components/custom-text";
import IconButton from "../../../components/icon-button";
import { useAjax, useAlert } from "../../../utils/hooks";
import BasicTable from "../../../components/basic-table";
import Spinner from "../../../components/spinner";
import Checkbox from "../../../components/checkbox";
import {} from "../../../utils";

export default function ManagementISO() {
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();

  const [storageName, setStorageName] = useState("");
  const [isoList, setIsoList] = useState(null);

  const [loading, setLoading] = useState(false);

  const fetchBlacklist = useCallback(async () => {
    const data = await ajax(`/proxmox/nodes/storage/getCustomIsoList`);

    setIsoList(data.isoList);
    setStorageName(data.storageName);
  }, [ajax]);

  useEffect(() => {
    fetchBlacklist();
  }, [fetchBlacklist]);

  async function handleSaveClicked() {
    setLoading(true);
    await ajax(`/proxmox/nodes/storage/updateCustomIsoList`, {
      storageName,
      isoList,
    });
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "management-iso.save.title" }),
      intl.formatMessage({ id: "management-iso.save.content" })
    );
  }

  function handleTextChanged(e, item, field) {
    item[field] = e.target.value;
    setIsoList([...isoList]);
  }

  function handleCheckboxToggle(e, item, checkboxName) {
    item[checkboxName] = !item[checkboxName];
    setIsoList([...isoList]);
  }

  return (
    <WithRole permission="super-admin.iso-page">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-iso.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box className={styles.boxWithMargin}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="management-iso.storage-name" />
              </span>
              <CustomText
                value={storageName}
                onChange={(e) => setStorageName(e.target.value)}
              />
            </div>
          </Box>

          <Box>
            <BasicTable layout="auto">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="management-iso.custom-name" />
                  </th>
                  <th>
                    <FormattedMessage id="management-iso.description" />
                  </th>
                  <th>
                    <FormattedMessage id="management-iso.enable-in-user" />
                  </th>
                  <th>
                    <FormattedMessage id="management-iso.enable-in-admin" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isoList && (
                  <tr>
                    <td colSpan={4}>
                      <div className="spinner-wrapper">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}

                {isoList?.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <CustomText
                        value={item.custom_name}
                        onChange={(e) =>
                          handleTextChanged(e, item, "custom_name")
                        }
                      />
                    </td>
                    <td>
                      <CustomText
                        value={item.description}
                        onChange={(e) =>
                          handleTextChanged(e, item, "description")
                        }
                      />
                    </td>
                    <td>
                      <div className="flex-center">
                        <Checkbox
                          checked={item.enabled_in_user}
                          onChange={(e) =>
                            handleCheckboxToggle(e, item, "enabled_in_user")
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex-center">
                        <Checkbox
                          checked={item.enabled_in_admin}
                          onChange={(e) =>
                            handleCheckboxToggle(e, item, "enabled_in_admin")
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>

            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleSaveClicked}
            >
              <FormattedMessage id="general.save" />
            </IconButton>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
