import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { ReactComponent as LongArrowRight } from "../../svgs/long-arrow-right.svg";

function ServerLogModal({ date, log, isOpen, onClose }) {
  const intl = useIntl();

  const [logToRender, setLogToRender] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setLogToRender(log);
    }
  }, [isOpen, log]);

  if (!logToRender) {
    return null;
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="server-log-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.infoRow}>
          <span className={styles.date}>{date}</span>
        </div>

        <div className={styles.diffWrapper}>
          {logToRender.prev && <LongArrowRight className={styles.arrow} />}

          {["before", "after"].map((type, key) => {
            let data;

            if (type === "before") {
              data = logToRender.prev?.data;
            } else {
              data = logToRender.data;
            }

            if (!data) {
              return null;
            }

            return (
              <div key={key}>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="server-log-modal.cpu" />:{" "}
                  </span>
                  {data.cpu}
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="server-log-modal.ram" />:{" "}
                  </span>
                  {data.ram_mb}MB
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="server-log-modal.disk" />:{" "}
                  </span>
                  {data.ssd_gb}GB
                </div>
                {!!data.additional_ssd_gb && (
                  <div className={styles.row}>
                    <span>
                      <FormattedMessage id="server-log-modal.additional_disk" />
                      :{" "}
                    </span>
                    {data.additional_ssd_gb}GB
                  </div>
                )}
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="server-log-modal.backup" />:{" "}
                  </span>
                  {intl.formatMessage({
                    id: data.backup ? "general.yes" : "general.no",
                  })}
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="server-log-modal.payment_type" />:{" "}
                  </span>
                  {data.payment_type}
                </div>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

ServerLogModal.propTypes = {
  date: PropTypes.string,
  log: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ServerLogModal;
