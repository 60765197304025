import React from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";

function LayoutWithStripe({ stripePromise, children }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

LayoutWithStripe.propTypes = {
  stripePromise: PropTypes.object,
  children: PropTypes.any,
};

export default LayoutWithStripe;
