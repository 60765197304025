import styles from "./create-new-server.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  useLang,
  useUser,
  useAjax,
  useAlert,
  useLimits,
  usePrevious,
} from "../../../utils/hooks";
import CreateNewServerLayout from "../../../components/create-new-server-layout";
import Location from "../../../components/create-new-server/location";
import ServerImage from "../../../components/create-new-server/image";
import Plan from "../../../components/create-new-server/plan";
import AdditionalServices from "../../../components/create-new-server/additional-services";
import CpanelLicense from "../../../components/create-new-server/cpanel-license";
import WindowsServerExtras from "../../../components/create-new-server/windows-server-extras";
import ServerHostnameLabel from "../../../components/create-new-server/server-hostname-label";
import TagsGroups from "../../../components/create-new-server/tags-groups";
import CompleteRegistrationModal from "../../../components/modals/complete-registration";
import {} from "../../../utils";
import Box from "../../../components/box";
import { differenceInDays, endOfMonth, getDaysInMonth } from "date-fns";
import { Link } from "react-router-dom";

function CreateNewServer() {
  const intl = useIntl();
  const router = useHistory();
  const lang = useLang();
  const user = useUser();
  const ajax = useAjax();
  const { serversLimit } = useLimits();

  const alert = useAlert();
  const alertRef = useRef(alert);

  const [step, setStep] = useState(1);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const [location, setLocation] = useState(false);

  const [image, setImage] = useState(false);
  const prevImage = usePrevious(image);

  const [cpu, setCpu] = useState(1);
  const [ram_mb, setRamMB] = useState(Math.pow(1024, 1));
  const [ssd_gb, setSsdGB] = useState(20);
  const [additional_ssd_gb, setAdditionalSsdGb] = useState(0);
  const [backup, setBackup] = useState(true);
  const [privateNetworking, setPrivateNetworking] = useState(false);
  const [paymentType, setPaymentType] = useState("monthly");
  const [hostnames, setHostnames] = useState([""]);
  const [password, setPassword] = useState("");
  const [tag, setTag] = useState("");
  const [group, setGroup] = useState(null);
  const [ipType, setIpType] = useState(4);
  const [bypassPayment, setBypassPayment] = useState(false);
  const [customIP, setCustomIP] = useState(null);
  const [attachServerToClient, setAttachServerToClient] = useState(false);
  const [selectedFreeDays, setSelectedFreeDays] = useState(null);
  const [publicNetworking, setPublicNetworking] = useState(true);
  const [promotionCode, setPromotionCode] = useState("");
  const [securityGroup, setSecurityGroup] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState({});

  const [priceMonthly, setPriceMonthly] = useState(null);
  const [monthlyBackupPrice, setMonthlyBackupPrice] = useState(null);
  const [priceHourlyOn, setPriceHourlyOn] = useState(null);
  const [priceHourlyOff, setPriceHourlyOff] = useState(null);
  const [priceHourlyBackup, setPriceHourlyBackup] = useState(null);
  const [firstMonthPayment, setFirstMonthPayment] = useState(null);
  const [addonsPriceMonthly, setAddonsPriceMonthly] = useState(null);
  const [addonsFirstMonthPayment, setAddonsFirstMonthPayment] = useState(null);
  const [addonsPriceHourlyOn, setAddonsPriceHourlyOn] = useState(null);
  const [addonsPriceHourlyOff, setAddonsPriceHourlyOff] = useState(null);

  const [showPrivateNetworking, setShowPrivateNetworking] = useState(false);
  const [vlans, setVlans] = useState([]);
  const [selectedVlan, setSelectedVlan] = useState(null);
  const [vlanName, setVlanName] = useState("");
  const [ipAddressScope, setIpAddressScope] = useState(null);
  const [ipAddressScopeCustom, setIpAddressScopeCustom] = useState("");
  const [netmask, setNetmask] = useState(null);
  const [gateway, setGateway] = useState(null);
  const [selectedIP, setSelectedIP] = useState(null);

  const [createServerNow, setCreateServerNow] = useState(false);

  const [loading, setLoading] = useState(false);

  const [
    isCompleteRegistrationModalOpen,
    setIsCompleteRegistrationModalOpen,
  ] = useState(false);

  const createTheServer = useCallback(async () => {
    const _selectedAddons = Object.keys(selectedAddons).filter(
      (k) => selectedAddons[k]
    );

    let privateNetworking = null;

    if (showPrivateNetworking) {
      privateNetworking = {
        selectedLan: selectedVlan.value,
        selectedCIDRBlock: ipAddressScope.value,
        selectedCIDRBlockCustom: ipAddressScopeCustom,
        selectedNetmask: netmask.value,
        vlanName,
        selectedIP: selectedIP.value,
        selectedGateway: gateway.value,
      };

      if (privateNetworking.selectedLan !== "NEW LAN") {
        privateNetworking.selectedLan = privateNetworking.vlanName;
        privateNetworking.vlanName = "";
      }
    }

    setLoading(true);
    const data = await ajax(`/servers/create`, {
      location,
      image: image.value,
      cpu,
      ram_mb,
      ssd_gb,
      additional_ssd_gb,
      backup,
      privateNetworking,
      ipType,
      password,
      hostnames,
      tag,
      groupID: group && group.value,
      attachServerToClient: attachServerToClient && attachServerToClient.value,
      selectedFreeDays: selectedFreeDays && selectedFreeDays.value,
      publicNetworking,
      paymentType,
      promotionCode,
      securityGroup,
      selectedAddons: _selectedAddons,
      bypassPayment,
      customIP,
    });

    if (data.result === "success") {
      router.push(`/${lang}/my-cloud/servers`);
    } else {
      alertRef.current(
        intl.formatMessage({ id: "general.error" }),
        `${intl.formatMessage({ id: "general.general-error" })} (${
          data.message
        })`
      );

      setLoading(false);
    }
  }, [
    additional_ssd_gb,
    ajax,
    attachServerToClient,
    backup,
    bypassPayment,
    cpu,
    group,
    hostnames,
    image.value,
    intl,
    ipType,
    lang,
    location,
    password,
    paymentType,
    promotionCode,
    publicNetworking,
    ram_mb,
    router,
    securityGroup,
    selectedFreeDays,
    ssd_gb,
    tag,
    gateway,
    ipAddressScope,
    ipAddressScopeCustom,
    netmask,
    selectedIP,
    selectedVlan,
    showPrivateNetworking,
    vlanName,
    selectedAddons,
    customIP,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    let params = urlParams.get("params");

    if (params) {
      params = JSON.parse(decodeURIComponent(params));

      window.history.replaceState(null, null, window.location.pathname);

      setLocation(params.location);
      setImage(params.image);
      setCpu(params.cpu);
      setRamMB(params.ram_mb);
      setSsdGB(params.ssd_gb);
      setAdditionalSsdGb(params.additional_ssd_gb);
      setBackup(params.backup);
      setPrivateNetworking(params.privateNetworking);
      setPaymentType(params.paymentType);
      setHostnames(params.hostnames);
      setPassword(params.password);
      setTag(params.tag);
      setGroup(params.group);
      setIpType(params.ipType);
      setBypassPayment(params.bypassPayment);
      setAttachServerToClient(params.attachServerToClient);
      setSelectedFreeDays(params.selectedFreeDays);
      setPublicNetworking(params.publicNetworking);
      setPromotionCode(params.promotionCode);
      setSecurityGroup(params.securityGroup);

      setCreateServerNow(true);
    }
  }, []);

  useEffect(() => {
    if (createServerNow) {
      createTheServer();
    }
  }, [createServerNow, createTheServer]);

  const getServerPrice = useCallback(async () => {
    const data = await ajax("/billing/getServerPrice", {
      serverData: {
        additional_ssd_gb,
        backup,
        cpu,
        image: image.value,
        ram_mb,
        ssd_gb,
        new: true,
      },
      addons: Object.keys(selectedAddons).filter((k) => selectedAddons[k]),
      userID: user._id,
    });

    const curDate = new Date();
    const daysInMonth = getDaysInMonth(curDate);
    const daysLeft = differenceInDays(endOfMonth(curDate), curDate) + 1;

    setPriceMonthly(data.monthlyPrice * hostnames.length);
    setMonthlyBackupPrice(data.monthlyBackupPrice);
    setPriceHourlyOn(data.hourlyPriceOn * hostnames.length);
    setPriceHourlyOff(data.hourlyPriceOff * hostnames.length);
    setPriceHourlyBackup(data.hourlyBackupPrice);

    setFirstMonthPayment(
      (data.monthlyPrice / daysInMonth) * daysLeft * hostnames.length
    );

    setAddonsPriceMonthly(data.addonsPrice * hostnames.length);
    setAddonsFirstMonthPayment((data.addonsPrice / daysInMonth) * daysLeft);
    setAddonsPriceHourlyOn(data.addonsHourlyPriceOn);
    setAddonsPriceHourlyOff(data.addonsHourlyPriceOff);
  }, [
    additional_ssd_gb,
    ajax,
    backup,
    cpu,
    hostnames.length,
    image,
    ram_mb,
    ssd_gb,
    selectedAddons,
    user._id,
  ]);

  useEffect(() => {
    getServerPrice();
  }, [getServerPrice]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setBypassPayment(true);
    }
  }, []);

  function getPageTitle() {
    switch (step) {
      case 1:
        return intl.formatMessage({ id: "create-new-server.location" });
      case 2:
        return intl.formatMessage({ id: "create-new-server.image" });
      case 3:
        return intl.formatMessage({ id: "create-new-server.plan" });
      case 4:
        return intl.formatMessage({
          id: "create-new-server.additional-services",
        });
      case 5:
        return intl.formatMessage({
          id: "create-new-server.cpanel-license",
        });
      case 6:
        return intl.formatMessage({
          id: "create-new-server.windows-server-extra",
        });
      case 7:
        return intl.formatMessage({
          id: "create-new-server.server-hostname-label",
        });
      case 8:
        return intl.formatMessage({
          id: "create-new-server.tags-groups",
        });
      default:
        return null;
    }
  }

  function getExtraText() {
    switch (step) {
      case 3:
        return intl.formatMessage({ id: "create-new-server.plan.extra-text" });
      case 4:
        return intl.formatMessage({
          id: "create-new-server.additional-services.extra-text",
        });
      case 5:
        return intl.formatMessage({
          id: "create-new-server.cpanel-license.extra-text",
        });
      case 6:
        return intl.formatMessage({
          id: "create-new-server.windows-server-extra.extra-text",
        });
      case 7:
        return intl.formatMessage({
          id: "create-new-server.server-hostname-label.extra-text",
        });
      case 8:
        return intl.formatMessage({
          id: "create-new-server.tags-groups.extra-text",
        });
      default:
        return null;
    }
  }

  function getGreenText() {
    return step >= 3
      ? intl.formatMessage({ id: "create-new-server.plan.green-text" })
      : null;
  }

  function getBottomBar() {
    return step >= 3;
  }

  async function incStep() {
    let newStep = step + 1;

    if (newStep === 5) {
      if (!image.value.toLowerCase().includes("cpanel")) {
        newStep = 7;
      }
    }

    if (newStep === 6) {
      newStep = 7;
    }

    if (newStep >= 9) {
      if (!user.current_parent && user.registerStep !== -1) {
        setIsCompleteRegistrationModalOpen(true);
      } else {
        await createTheServer();
      }

      return;
    }

    setStep(newStep);
  }

  function handleBackToSiteClicked() {
    let newStep = step - 1;

    if (newStep === 6) {
      if (image.value.toLowerCase().includes("cpanel")) {
        newStep = 5;
      } else {
        newStep = 4;
      }
    }

    if (newStep === 0) {
      router.push(`/${lang}/my-cloud/servers`);
    }

    setStep(newStep);
  }

  function handleCompleteRegistrationModalClosed(status) {
    setIsCompleteRegistrationModalOpen(false);

    if (status) {
      setTimeout(() => {
        incStep();
      }, 1);
    }
  }

  if (typeof serversLimit === "number" && serversLimit <= 0) {
    return (
      <CreateNewServerLayout>
        <Box className={styles.reachedLimitWrapper}>
          <Link
            href="/[lang]/support/open-new-ticket"
            to={`/${lang}/support/open-new-ticket`}
          >
            <FormattedMessage id="create-new-server.reached-limit" />
          </Link>
        </Box>
      </CreateNewServerLayout>
    );
  }

  return (
    <CreateNewServerLayout
      pageTitle={getPageTitle()}
      extraText={getExtraText()}
      greenText={getGreenText()}
      bottomBar={getBottomBar()}
      step={step}
      backToSiteClicked={handleBackToSiteClicked}
      handleNextButtonClicked={incStep}
      hostnames={hostnames}
      setHostnames={setHostnames}
      isNextButtonDisabled={isNextButtonDisabled}
      loading={loading}
      location={location}
      image={image}
      cpu={cpu}
      ram_mb={ram_mb}
      ssd_gb={ssd_gb}
      additional_ssd_gb={additional_ssd_gb}
      backup={backup}
      paymentType={paymentType}
      bypassPayment={bypassPayment}
      setBypassPayment={setBypassPayment}
      priceMonthly={priceMonthly}
      setPriceMonthly={setPriceMonthly}
      monthlyBackupPrice={monthlyBackupPrice}
      setMonthlyBackupPrice={setMonthlyBackupPrice}
      priceHourlyOn={priceHourlyOn}
      setPriceHourlyOn={setPriceHourlyOn}
      priceHourlyOff={priceHourlyOff}
      setPriceHourlyOff={setPriceHourlyOff}
      priceHourlyBackup={priceHourlyBackup}
      setPriceHourlyBackup={setPriceHourlyBackup}
      firstMonthPayment={firstMonthPayment}
      setFirstMonthPayment={setFirstMonthPayment}
      selectedAddons={selectedAddons}
      addonsPriceMonthly={addonsPriceMonthly}
      addonsFirstMonthPayment={addonsFirstMonthPayment}
      addonsPriceHourlyOn={addonsPriceHourlyOn}
      addonsPriceHourlyOff={addonsPriceHourlyOff}
    >
      {step === 1 && <Location location={location} setLocation={setLocation} />}

      {step === 2 && (
        <ServerImage
          image={image}
          prevImage={prevImage}
          setImage={setImage}
          selectedAddons={selectedAddons}
          setSelectedAddons={setSelectedAddons}
          setCpu={setCpu}
          setRamMB={setRamMB}
          setSsdGB={setSsdGB}
          setAdditionalSsdGb={setAdditionalSsdGb}
          setBackup={setBackup}
        />
      )}

      {step === 3 && (
        <Plan
          image={image.os}
          incStep={incStep}
          cpu={cpu}
          setCpu={setCpu}
          ram_mb={ram_mb}
          setRamMB={setRamMB}
          ssd_gb={ssd_gb}
          setSsdGB={setSsdGB}
          additional_ssd_gb={additional_ssd_gb}
          setAdditionalSsdGb={setAdditionalSsdGb}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
        />
      )}

      {step === 4 && (
        <AdditionalServices
          incStep={incStep}
          location={location}
          image={image}
          backup={backup}
          setBackup={setBackup}
          privateNetworking={privateNetworking}
          setPrivateNetworking={setPrivateNetworking}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          showPrivateNetworking={showPrivateNetworking}
          setShowPrivateNetworking={setShowPrivateNetworking}
          vlans={vlans}
          setVlans={setVlans}
          selectedVlan={selectedVlan}
          setSelectedVlan={setSelectedVlan}
          vlanName={vlanName}
          setVlanName={setVlanName}
          ipAddressScope={ipAddressScope}
          setIpAddressScope={setIpAddressScope}
          ipAddressScopeCustom={ipAddressScopeCustom}
          setIpAddressScopeCustom={setIpAddressScopeCustom}
          netmask={netmask}
          setNetmask={setNetmask}
          gateway={gateway}
          setGateway={setGateway}
          selectedIP={selectedIP}
          setSelectedIP={setSelectedIP}
          publicNetworking={publicNetworking}
          setPublicNetworking={setPublicNetworking}
          paymentType={paymentType}
          monthlyBackupPrice={monthlyBackupPrice}
          priceHourlyBackup={priceHourlyBackup}
          selectedAddons={selectedAddons}
          setSelectedAddons={setSelectedAddons}
          customIP={customIP}
          setCustomIP={setCustomIP}
        />
      )}

      {step === 5 && (
        <CpanelLicense
          incStep={incStep}
          selectedAddons={selectedAddons}
          setSelectedAddons={setSelectedAddons}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
        />
      )}

      {step === 6 && <WindowsServerExtras incStep={incStep} />}

      {step === 7 && (
        <ServerHostnameLabel
          incStep={incStep}
          image={image}
          hostnames={hostnames}
          setHostnames={setHostnames}
          password={password}
          setPassword={setPassword}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
        />
      )}

      {step === 8 && (
        <TagsGroups
          incStep={incStep}
          tag={tag}
          setTag={setTag}
          group={group}
          setGroup={setGroup}
          attachServerToClient={attachServerToClient}
          setAttachServerToClient={setAttachServerToClient}
          selectedFreeDays={selectedFreeDays}
          setSelectedFreeDays={setSelectedFreeDays}
        />
      )}

      <CompleteRegistrationModal
        isOpen={isCompleteRegistrationModalOpen}
        onClose={handleCompleteRegistrationModalClosed}
        location={location}
        image={image}
        cpu={cpu}
        ram_mb={ram_mb}
        ssd_gb={ssd_gb}
        additional_ssd_gb={additional_ssd_gb}
        backup={backup}
        privateNetworking={privateNetworking}
        paymentType={paymentType}
        hostnames={hostnames}
        password={password}
        tag={tag}
        group={group}
        ipType={ipType}
        bypassPayment={bypassPayment}
        attachServerToClient={attachServerToClient}
        selectedFreeDays={selectedFreeDays}
        publicNetworking={publicNetworking}
        promotionCode={promotionCode}
        securityGroup={securityGroup}
      />
    </CreateNewServerLayout>
  );
}

export default CreateNewServer;
