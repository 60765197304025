import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax } from "../../../utils/hooks";
import CustomReactSelect from "../../custom-react-select";

function DeleteDepartmentsModal({ isOpen, onClose, department }) {
  const ajax = useAjax();
  const intl = useIntl();

  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchDepartments = useCallback(async () => {
    if (!department) {
      return;
    }

    const data = await ajax(`/tickets/getDepartments`);

    const departments = data.departments.map((department) => ({
      label: department.name,
      value: department.name,
    }));

    setDepartments(departments.filter((d) => d.value !== department.name));
  }, [ajax, department]);

  useEffect(() => {
    if (isOpen) {
      fetchDepartments();
    }
  }, [isOpen, fetchDepartments]);

  async function handleDeleteClicked() {
    setLoading(true);
    await ajax(`/tickets/removeDepartment`, {
      name: department.name,
      move: newDepartment.value,
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="delete-departments-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "delete-departments-modal.content",
              },
              { name: department?.name, b: (arr) => `<b>${arr[0]}</b>` }
            ),
          }}
        ></div>
        <div>
          <CustomReactSelect
            instanceId="delete-departments-modal.select"
            options={departments}
            value={newDepartment}
            onChange={(option) => setNewDepartment(option)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading || !newDepartment}
          color="purple"
          onClick={handleDeleteClicked}
        >
          <FormattedMessage id="delete-departments-modal.move-and-delete" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

DeleteDepartmentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  department: PropTypes.object,
};

export default DeleteDepartmentsModal;
