import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { ReactComponent as OptionsSvg } from "../svgs/options.svg";

function CustomMenu({
  isOpen,
  toggle,
  color = "purple",
  children,
  className,
  direction = null,
  showSvg = true,
}) {
  return (
    <Dropdown
      direction={direction}
      className={`${styles.wrapper} ${styles[color]} ${
        className ? className : ""
      }`}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle nav>{showSvg && <OptionsSvg />}</DropdownToggle>
      <DropdownMenu right>{children}</DropdownMenu>
    </Dropdown>
  );
}

CustomMenu.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  color: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  direction: PropTypes.oneOf(["up", "down", "left", "right"]),
  showSvg: PropTypes.bool,
};

export default CustomMenu;
