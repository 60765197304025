import styles from "./whitelabel.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import CustomReactSelect from "../../../components/custom-react-select";
import { useAjax, useAlert } from "../../../utils/hooks";
import BasicTable from "../../../components/basic-table";
import CustomText from "../../../components/custom-text";
import IconButton from "../../../components/icon-button";
import Checkbox from "../../../components/checkbox";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import { getAllWLDVPSForSelect } from "../../../utils/wldvps";
import {} from "../../../utils";

export default function ManagementWhitelabel() {
  const ajax = useAjax();
  const alert = useAlert();
  const intl = useIntl();

  const [whitelabels, setWhitelabels] = useState([]);
  const [settings, setSettings] = useState(false);
  const [selectedWhitelabel, setSelectedWhitelabel] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchWhitelabels = useCallback(async () => {
    const data = await ajax(`/whitelabel/getAllWhitelabelSettings`);

    if (data.result === "error") {
      return;
    }

    const whitelabels = getAllWLDVPSForSelect(data.whitelabelSettings);

    setWhitelabels(whitelabels);

    setSettings(data.whitelabelSettings);
  }, [ajax]);

  useEffect(() => {
    fetchWhitelabels();
  }, [fetchWhitelabels]);

  function handleAddNewSettingClicked() {
    settings.push({
      whitelabel: selectedWhitelabel.value,
      key: "",
      value: "",
      secured: false,
    });
  }

  function handleSettingKeyChanged(e, setting) {
    setting.key = e.target.value;
    setSettings([...settings]);
  }

  function handleSettingValueChanged(e, setting) {
    if (typeof setting.value === "object") {
      const obj = {};
      e.target.value.split("\n").forEach((row) => {
        const item = row.split("=");
        obj[item[0]] = item[1];
      });
      setting.value = obj;
    } else {
      setting.value = e.target.value;
    }

    setSettings([...settings]);
  }

  function handleSettingSecuredClicked(setting) {
    setting.secured = !setting.secured;

    setSettings([...settings]);
  }

  function handleDropdownToggle(setting) {
    setting.isDropdownOpen = !setting.isDropdownOpen;
    setSettings([...settings]);
  }

  function handleRemoveItemClicked(setting) {
    for (let i = 0; i < settings.length; i++) {
      if (
        settings[i].whitelabel === setting.whitelabel &&
        settings[i].key === setting.key
      ) {
        settings.splice(i, 1);
        setSettings([...settings]);
        return;
      }
    }
  }

  async function handleSaveClicked() {
    settings.sort((a, b) => {
      if (a.key === b.key) {
        return 0;
      }

      return a.key > b.key ? 1 : -1;
    });

    setLoading(true);
    const data = await ajax(`/whitelabel/setAllWhitelabelSettings`, {
      settings,
    });
    setLoading(false);

    if (data.result === "success") {
      await alert(
        intl.formatMessage({ id: "management-whitelabel.save.title" }),
        intl.formatMessage({ id: "management-whitelabel.save.content" })
      );
    } else {
      await alert(
        intl.formatMessage({ id: "management-whitelabel.save.title" }),
        intl.formatMessage({ id: "management-whitelabel.save.error" })
      );
    }
  }

  function handleConvertTo(setting, convertTo) {
    if (convertTo === "object") {
      setting.value = {};
    } else {
      setting.value = "";
    }

    setSettings([...settings]);
  }

  function renderTable() {
    if (!selectedWhitelabel) {
      return null;
    }

    return (
      <>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="general.key" />
              </th>
              <th>
                <FormattedMessage id="general.value" />
              </th>
              <th style={{ width: "100px" }}>
                <FormattedMessage id="management-whitelabel.secured" />
              </th>
              <th style={{ width: "50px" }}></th>
            </tr>
          </thead>
          <tbody>
            {settings
              .filter(
                (setting) => setting.whitelabel === selectedWhitelabel.value
              )
              .map((setting, idx) => {
                const convertTo =
                  typeof setting.value === "string" ? "object" : "string";

                return (
                  <tr key={idx}>
                    <td>
                      <CustomText
                        value={setting.key}
                        onChange={(e) => handleSettingKeyChanged(e, setting)}
                      />
                    </td>
                    <td>
                      {typeof setting.value === "string" && (
                        <CustomText
                          value={setting.value}
                          onChange={(e) =>
                            handleSettingValueChanged(e, setting)
                          }
                        />
                      )}
                      {typeof setting.value === "object" && (
                        <CustomText
                          type="textarea"
                          value={Object.keys(setting.value)
                            .map((k) => `${k}=${setting.value[k]}`)
                            .join("\n")}
                          onChange={(e) =>
                            handleSettingValueChanged(e, setting)
                          }
                        />
                      )}
                    </td>
                    <td>
                      <Checkbox
                        label="management-whitelabel.secured"
                        checked={setting.secured}
                        onClick={() => handleSettingSecuredClicked(setting)}
                        readOnly
                      />
                    </td>
                    <td>
                      <CustomMenu
                        isOpen={setting.isDropdownOpen}
                        toggle={() => handleDropdownToggle(setting)}
                      >
                        <CustomMenuItem
                          onClick={() => handleConvertTo(setting, convertTo)}
                        >
                          <FormattedMessage
                            id={`management-whitelabel.convert-to-${convertTo}`}
                          />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleRemoveItemClicked(setting)}
                        >
                          <FormattedMessage id="general.remove" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </BasicTable>

        <div className={styles.buttons}>
          <IconButton color="light-purple" onClick={handleAddNewSettingClicked}>
            <FormattedMessage id="general.add" />
          </IconButton>
          <IconButton
            disabled={loading}
            color="light-purple"
            onClick={handleSaveClicked}
          >
            <FormattedMessage id="general.save" />
          </IconButton>
        </div>
      </>
    );
  }

  return (
    <WithRole permission="super-admin.whitelabel-settings">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-whitelabel.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="management-whitelabel.select-whitelabel" />
              </span>
              <CustomReactSelect
                instanceId="management-whitelabel-select-whitelabel"
                options={whitelabels}
                value={selectedWhitelabel}
                onChange={(option) => setSelectedWhitelabel(option)}
              />
            </div>

            {renderTable()}
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
