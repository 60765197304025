import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import validator from "validator";
import { FormattedMessage, useIntl } from "react-intl";
import { WithRole } from "../../components/with-role";
import Box from "../../components/box";
import BasicTable from "../../components/basic-table";
import { useAjax, usePrompt, useUser } from "../../utils/hooks";
import IconButton from "../../components/icon-button";
import Spinner from "../../components/spinner";
import CustomMenu from "../../components/custom-menu";
import CustomMenuItem from "../../components/custom-menu/item";
import UserSelectorModal from "../../components/modals/user-selector";
import { SUPER_ADMIN, WHITELABEL } from "../../utils/user";

function InvoicesEmails({ userEmail }) {
  const intl = useIntl();
  const prompt = usePrompt();
  const ajax = useAjax();
  const user = useUser();

  const [userIDToWork, setUserIDToWork] = useState(null);
  const [emailsForInvoices, setEmailsForInvoices] = useState(null);
  const [isEmailDropdownOpen, setIsEmailDropdownOpen] = useState({});

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);

  const getSendingEmails = useCallback(async () => {
    const data = await ajax("/billing/getSendingEmails", {
      userIDToWork: userIDToWork?.value,
    });

    if (data.result == "success") {
      setEmailsForInvoices(data.emails_for_invoices);
    }
  }, [ajax, userIDToWork?.value]);

  useEffect(() => {
    getSendingEmails();
  }, [getSendingEmails]);

  const getUserByEmail = useCallback(
    async (userEmail) => {
      const data = await ajax("/users/getUserByEmail", { email: userEmail });

      if (data.result === "success") {
        setUserIDToWork({ label: data.user.email, value: data.user._id });
      }
    },
    [ajax]
  );

  useEffect(() => {
    if (userEmail) {
      getUserByEmail(userEmail);
    }
  }, [userEmail, getUserByEmail]);

  async function handleAddEmailClicked() {
    const email = await prompt({
      title: intl.formatMessage({ id: "emails.add.title" }),
      message: intl.formatMessage({ id: "emails.add.content" }),
      acceptOnlyValue: (text) => {
        let status = true;
        let reason;

        if (!validator.isEmail(text)) {
          status = false;
          reason = intl.formatMessage({ id: "general.wrong-email" });
        } else if (emailsForInvoices.includes(text)) {
          status = false;
          reason = intl.formatMessage({ id: "emails.add.error" });
        }

        return {
          status,
          reason,
        };
      },
    });

    if (email) {
      await ajax("/billing/addNewSendingEmail", {
        userIDToWork: userIDToWork?.value,
        email,
      });
      await getSendingEmails();
    }
  }

  function handleEmailDropdownToggle(key) {
    isEmailDropdownOpen[key] = !isEmailDropdownOpen[key];
    setIsEmailDropdownOpen({ ...isEmailDropdownOpen });
  }

  async function handleRemoveClicked(email) {
    await ajax("/billing/removeSendingEmail", {
      userIDToWork: userIDToWork?.value,
      email,
    });
    await getSendingEmails();
  }

  function handleUserSelectorModalOpen() {
    setIsUserSelectorModalOpen(true);
  }

  function handleUserSelectorModalClosed(user) {
    setUserIDToWork(user);
    setIsUserSelectorModalOpen(false);
  }

  return (
    <WithRole permission="billing.invoices">
      <div className={styles.wrapper}>
        <hr />

        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <Box className={styles.box}>
            <IconButton
              color="light-purple"
              onClick={handleUserSelectorModalOpen}
            >
              {userIDToWork?.label || user.email}
            </IconButton>
          </Box>
        )}

        <Box className={styles.box}>
          <div>
            <FormattedMessage id="emails.descriptions" />
          </div>

          <BasicTable>
            <tbody>
              {!emailsForInvoices && (
                <tr>
                  <td colSpan={2}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {emailsForInvoices?.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {emailsForInvoices?.map((email, key) => (
                <tr key={key}>
                  <td>{email}</td>
                  <td>
                    <CustomMenu
                      isOpen={isEmailDropdownOpen[key]}
                      toggle={() => handleEmailDropdownToggle(key)}
                    >
                      <CustomMenuItem
                        onClick={() => handleRemoveClicked(email)}
                      >
                        <FormattedMessage id="general.remove" />
                      </CustomMenuItem>
                    </CustomMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>

          <IconButton color="light-purple" onClick={handleAddEmailClicked}>
            <FormattedMessage id="general.add" />
          </IconButton>
        </Box>
      </div>

      <UserSelectorModal
        isOpen={isUserSelectorModalOpen}
        onClose={handleUserSelectorModalClosed}
      />
    </WithRole>
  );
}

InvoicesEmails.propTypes = {
  userEmail: PropTypes.string,
};

export default InvoicesEmails;
