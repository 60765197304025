import styles from "./index.module.scss";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax, useAlert } from "../../../utils/hooks";
import CustomText from "../../custom-text";
import CustomReactSelect from "../../custom-react-select";

function CreateNewCouponModal({ isOpen, onClose, editCoupon }) {
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();

  const couponTypes = [
    {
      label: intl.formatMessage({ id: "create-new-coupon-modal.free-days" }),
      value: "free-days",
    },
    {
      label: intl.formatMessage({ id: "create-new-coupon-modal.add-credits" }),
      value: "add-credits",
    },
  ];
  const couponTypesRef = useRef(couponTypes);
  couponTypesRef.current = couponTypes;

  const [couponCode, setCouponCode] = useState("");
  const [couponLimit, setCouponLimit] = useState(0);
  const [couponType, setCouponType] = useState(false);
  const [couponFreeDays, setCouponFreeDays] = useState(0);
  const [couponAmountLimitation, setCouponAmountLimitation] = useState(0);
  const [couponAmountToAdd, setCouponAmountToAdd] = useState(0);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCouponCode(editCoupon?.code || "");
    setCouponLimit(editCoupon?.limit || 0);
    setCouponType(
      editCoupon
        ? couponTypesRef.current.find((type) => type.value === editCoupon.type)
        : false
    );
    setCouponFreeDays(editCoupon?.rules?.freeDays || 0);
    setCouponAmountLimitation(editCoupon?.rules?.amountLimitation || 0);
    setCouponAmountToAdd(editCoupon?.rules?.amountToAdd || 0);
    setError(false);
  }, [isOpen, editCoupon]);

  async function handleCreateEditClicked() {
    setError(false);

    if (couponCode === "") {
      return setError(
        intl.formatMessage({ id: "create-new-coupon-modal.error-code" })
      );
    } else if (!couponType) {
      return setError(
        intl.formatMessage({ id: "create-new-coupon-modal.error-type" })
      );
    } else if (couponType.value === "free-days" && couponFreeDays <= 0) {
      return setError(
        intl.formatMessage({ id: "create-new-coupon-modal.error-free-days" })
      );
    } else if (
      couponType.value === "free-days" &&
      couponAmountLimitation <= 0
    ) {
      return setError(
        intl.formatMessage({
          id: "create-new-coupon-modal.error-amount-limitation",
        })
      );
    } else if (couponType.value === "add-credits" && couponAmountToAdd <= 0) {
      return setError(
        intl.formatMessage({
          id: "create-new-coupon-modal.error-amount-to-add",
        })
      );
    }

    setLoading(true);
    const data = await ajax(editCoupon ? `/coupons/edit` : `/coupons/create`, {
      couponID: editCoupon ? editCoupon._id : null,
      couponCode,
      couponLimit,
      couponType: couponType.value,
      couponFreeDays,
      couponAmountLimitation,
      couponAmountToAdd,
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      await alert(
        intl.formatMessage({ id: "create-new-coupon-modal.title" }),
        intl.formatMessage({
          id: editCoupon
            ? "create-new-coupon-modal.coupon-edit-error"
            : "create-new-coupon-modal.coupon-created-error",
        })
      );
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editCoupon
              ? "create-new-coupon-modal.title-edit"
              : "create-new-coupon-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-new-coupon-modal.coupon-code" />
          </span>
          <CustomText
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-new-coupon-modal.limit" />
          </span>
          <CustomText
            placeholder={intl.formatMessage({
              id: "create-new-coupon-modal.limit-description",
            })}
            value={couponLimit}
            onChange={(e) => setCouponLimit(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-new-coupon-modal.type" />
          </span>
          <CustomReactSelect
            options={couponTypes}
            value={couponType}
            onChange={(item) => setCouponType(item)}
          />
        </div>

        {couponType.value === "free-days" && (
          <>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-coupon-modal.total-free-days" />
              </span>
              <CustomText
                value={couponFreeDays}
                onChange={(e) => setCouponFreeDays(e.target.value)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-coupon-modal.amount-limitation" />
              </span>
              <CustomText
                placeholder={intl.formatMessage({
                  id: "create-new-coupon-modal.amount-limitation-description",
                })}
                value={couponAmountLimitation}
                onChange={(e) => setCouponAmountLimitation(e.target.value)}
              />
            </div>
          </>
        )}

        {couponType.value === "add-credits" && (
          <div className={styles.row}>
            <span>
              <FormattedMessage id="create-new-coupon-modal.amount-to-add" />
            </span>
            <CustomText
              value={couponAmountToAdd}
              onChange={(e) => setCouponAmountToAdd(e.target.value)}
            />
          </div>
        )}

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleCreateEditClicked}
        >
          <FormattedMessage
            id={editCoupon ? "general.save" : "general.create"}
          />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

CreateNewCouponModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editCoupon: PropTypes.object,
};

export default CreateNewCouponModal;
