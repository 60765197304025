import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format, getDate, startOfDay } from "date-fns";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax, useLang, useWLDVPS } from "../../../utils/hooks";
import BasicTable from "../../basic-table";
import Spinner from "../../spinner";
import Checkbox from "../../checkbox";
import { currencySymbols } from "../../../utils/billing";

function AddAddonsToInvoiceModal({ isOpen, onClose, user }) {
  const ajax = useAjax();
  const lang = useLang();
  const wldvps = useWLDVPS();

  const [addons, setAddons] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [selectedIsFirstMonth, setSelectedIsFirstMonth] = useState({});

  const getServers = useCallback(async () => {
    const data = await ajax(`/admin/getServers`, {
      userID: user.value,
      withoutTerminated: true,
    });

    if (data.result === "success") {
      const addons = [];

      data.servers.forEach((server) => {
        server.addons
          .filter((addon) => addon.whitelabel === wldvps)
          .forEach((addon) => {
            if (
              (server.payment.payment_type === "monthly" &&
                server.status === "Active") ||
              (server.payment.payment_type === "hourly" &&
                (!server.terminated_at ||
                  startOfDay(new Date(addon.next_pay_day)) <
                    startOfDay(new Date(server.terminated_at))))
            ) {
              addon.server = server;
              addons.push(addon);
            }
          });
      });

      setAddons(addons);

      const selectedIsFirstMonth = {};
      const selectedAddons = {};
      const currentDay = getDate(new Date());

      addons.forEach((addon) => {
        if (addon.is_first_month) {
          selectedIsFirstMonth[addon._id] = true;
          selectedAddons[addon._id] = addon;
        }

        if (
          addon.payDay === currentDay &&
          startOfDay(new Date()) >= startOfDay(new Date(addon.next_pay_day))
        ) {
          selectedAddons[addon._id] = addon;
        }
      });

      setSelectedAddons(selectedAddons);
      setSelectedIsFirstMonth(selectedIsFirstMonth);
    }
  }, [ajax, user, wldvps]);

  useEffect(() => {
    if (isOpen) {
      setAddons(null);
      setSelectedAddons({});
      setSelectedIsFirstMonth({});

      getServers();
    }
  }, [isOpen, getServers]);

  function handleServerCheckboxChanged(server) {
    if (server) {
      if (selectedAddons[server._id]) {
        delete selectedAddons[server._id];
      } else {
        selectedAddons[server._id] = server;
      }

      return setSelectedAddons({ ...selectedAddons });
    }

    if (Object.keys(selectedAddons).length === addons.length) {
      setSelectedAddons({});
    } else {
      addons.forEach((server) => {
        selectedAddons[server._id] = server;
      });

      setSelectedAddons({ ...selectedAddons });
    }
  }

  function handleIsFirstMonthCheckboxChanged(server) {
    if (server) {
      if (selectedIsFirstMonth[server._id]) {
        delete selectedIsFirstMonth[server._id];
      } else {
        selectedIsFirstMonth[server._id] = server;
      }

      return setSelectedIsFirstMonth({ ...selectedIsFirstMonth });
    }

    if (Object.keys(selectedIsFirstMonth).length === addons.length) {
      setSelectedIsFirstMonth({});
    } else {
      addons.forEach((server) => {
        selectedIsFirstMonth[server._id] = server;
      });

      setSelectedIsFirstMonth({ ...selectedIsFirstMonth });
    }
  }

  function handleAddClicked() {
    onClose({ selectedAddons, selectedIsFirstMonth });
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-addons-to-invoice-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div>
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th style={{ width: "50px" }}>
                  <Checkbox
                    checked={
                      Object.keys(selectedAddons).length === addons?.length
                    }
                    onChange={() => handleServerCheckboxChanged()}
                  />
                </th>
                <th>
                  <FormattedMessage id="add-addons-to-invoice-modal.name" />
                </th>
                <th>
                  <FormattedMessage id="add-addons-to-invoice-modal.belongs-to-server" />
                </th>
                <th>
                  <FormattedMessage id="add-addons-to-invoice-modal.quantity" />
                </th>
                <th>
                  <FormattedMessage id="add-addons-to-invoice-modal.price" />
                </th>
                <th>
                  <Checkbox
                    checked={
                      Object.keys(selectedIsFirstMonth).length ===
                      addons?.length
                    }
                    onChange={() => handleIsFirstMonthCheckboxChanged()}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {!addons && (
                <tr>
                  <td colSpan={9}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {addons?.length === 0 && (
                <tr>
                  <td colSpan={9}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {addons?.map((addon, key) => (
                <tr key={key}>
                  <td>
                    <Checkbox
                      checked={!!selectedAddons[addon._id]}
                      onChange={() => handleServerCheckboxChanged(addon)}
                    />
                  </td>
                  <td>
                    <a
                      href={`/${lang}/my-cloud/servers/${addon.server._id}/server-settings/addons`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {addon.name}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`/${lang}/my-cloud/servers/${addon.server._id}/overview`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {addon.server.hostname}
                    </a>
                    {addon.server.status === "Terminated" && " (Terminated)"}
                  </td>
                  <td>{addon.quantity}</td>
                  <td style={{ padding: "10px 0" }}>
                    <div>
                      {currencySymbols[addon.currency]}
                      {addon.price} - {addon.server.payment.payment_type}
                    </div>

                    {addon.server.payment.payment_type === "monthly" && (
                      <>
                        <div>
                          pay every: {addon.payEvery}{" "}
                          <FormattedMessage id="general.months" />
                        </div>
                        <div>pay day: {addon.payDay}th</div>
                      </>
                    )}

                    <div>
                      {addon.server.payment.payment_type === "monthly"
                        ? "next pay day"
                        : "last pay day"}
                      :{" "}
                      {addon.next_pay_day
                        ? format(
                            startOfDay(new Date(addon.next_pay_day)),
                            "d/M/y"
                          )
                        : "EMPTY VALUE"}
                    </div>
                  </td>
                  <td>
                    {addon.server.payment.payment_type === "monthly" && (
                      <Checkbox
                        checked={!!selectedIsFirstMonth[addon._id]}
                        onChange={() =>
                          handleIsFirstMonthCheckboxChanged(addon)
                        }
                        label="add-other-products-to-invoice-modal.is-first-month"
                      />
                    )}
                    {addon.server.payment.payment_type === "hourly" && "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={handleAddClicked}>
          <FormattedMessage id="general.add" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddAddonsToInvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default AddAddonsToInvoiceModal;
