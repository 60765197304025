import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import WithServers from "../../../../components/servers/with-servers";
import { useServers, useUser } from "../../../../utils/hooks";
import { WithRole } from "../../../../components/with-role";
import {} from "../../../../utils";
import CompleteRegistrationModalStep1 from "../../../../components/modals/complete-registration/step1";
import CompleteRegistrationModalStep2 from "../../../../components/modals/complete-registration/step2";
import CompleteRegistrationModalStep3 from "../../../../components/modals/complete-registration/step3";
import Box from "../../../../components/box";

export default function MyCloudServers() {
  const servers = useServers();
  const user = useUser();

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (user && user._id) {
      setStep(user.registerStep);
    }
  }, [user]);

  function handleBackClicked() {
    setStep(step - 1);
  }

  function handleNextClicked() {
    const newStep = step + 1;

    if (newStep > 3) {
      console.log(111);
    }

    setStep(newStep);
  }

  if (!user._id) {
    return null;
  }

  if (!user.current_parent && user.registerStep !== -1) {
    return (
      <div className={styles.boxes}>
        <Box className={styles.box1}>
          <FormattedMessage id="my-cloud-servers.complete-registration" />
        </Box>

        <Box className={styles.box2}>
          <div className={styles.wrapper}>
            <div className={styles.steps}>
              <div
                className={`${styles.step} ${step === 1 && styles.selected}`}
              >
                <div className={styles.number}>1</div>
                <div className={styles.text}>
                  <FormattedMessage id="complete-registration-modal.personal-information" />
                </div>
              </div>
              <div
                className={`${styles.step} ${step === 2 && styles.selected}`}
              >
                <div className={styles.number}>2</div>
                <div className={styles.text}>
                  <FormattedMessage id="complete-registration-modal.phone-verification" />
                </div>
              </div>
              <div
                className={`${styles.step} ${step === 3 && styles.selected}`}
              >
                <div className={styles.number}>3</div>
                <div className={styles.text}>
                  <FormattedMessage id="complete-registration-modal.payment-details" />
                </div>
              </div>
            </div>

            {step === 1 && (
              <CompleteRegistrationModalStep1 onNext={handleNextClicked} />
            )}

            {step === 2 && (
              <CompleteRegistrationModalStep2
                onNext={handleNextClicked}
                onBack={handleBackClicked}
              />
            )}

            {step === 3 && (
              <CompleteRegistrationModalStep3
                onNext={handleNextClicked}
                location={location}
              />
            )}
          </div>
        </Box>
      </div>
    );
  }

  return (
    <WithRole permission="servers.list">
      {user._id && servers && (
        <div className={styles.serversWrapper}>
          <WithServers />
        </div>
      )}
    </WithRole>
  );
}
