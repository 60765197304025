import styles from "./index.module.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax, useServer } from "../../../utils/hooks";

function EditSnapshotModal({ snapshot, isOpen, onClose }) {
  const intl = useIntl();
  const server = useServer();
  const ajax = useAjax();

  const [description, setDescription] = useState("");

  useEffect(() => {
    if (snapshot.description) {
      setDescription(snapshot.description);
    }
  }, [snapshot.description]);

  async function handleEditClicked() {
    await ajax("/snapshots/edit", {
      serverID: server._id,
      name: snapshot.name,
      description,
    });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="edit-snapshot-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div>
          <CustomText
            placeholder={intl.formatMessage({ id: "general.description" })}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={handleEditClicked}>
          <FormattedMessage id="general.save-changes" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

EditSnapshotModal.propTypes = {
  snapshot: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditSnapshotModal;
