import styles from "./sidebar.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import SVG from "../svgs";
import { ReactComponent as LogoMini } from "../svgs/logo-mini.svg";
import Widgets from "./widgets";
import { useLang, useRoles, useUser, useWLDVPS } from "../../utils/hooks";
import getSidebar from "./sidebar-json";

function Sidebar() {
  const intl = useIntl();
  const lang = useLang();
  const router = useHistory();
  const user = useUser();
  const { isAllowed } = useRoles();
  const wldvps = useWLDVPS();

  const [selectedItem, setSelectedItem] = useState(null);

  const sidebar = useMemo(() => getSidebar(user, lang, intl), [
    intl,
    lang,
    user,
  ]);

  useEffect(() => {
    const selectedItem =
      sidebar.find((item) =>
        item.links.find((l) => router.location.pathname.includes(l.as))
      ) || sidebar[0];

    setSelectedItem(selectedItem);
  }, [router.location.pathname, sidebar]);

  function isNavSelected(link) {
    if (
      user.registerStep !== -1 &&
      (link.icon === "billing" || link.icon === "account")
    ) {
      return styles.disabled;
    }

    return link.links.find((l) => router.location.pathname.includes(l.as))
      ? styles.selected
      : null;
  }

  function isSubNavSelected(link) {
    if (
      user.registerStep !== -1 &&
      link.id !== "servers" &&
      link.id !== "my-tickets" &&
      link.id !== "open-new-ticket"
    ) {
      return styles.disabled;
    }

    if (link?.href === "/[lang]/my-cloud/networks") {
      return link.as === router.location.pathname ? styles.selected : null;
    }

    return link.as && router.location.pathname.includes(link.as)
      ? styles.selected
      : null;
  }

  function handleSidebarFilter(item) {
    if (item.id === "create-new-server" && !isAllowed("servers.create")) {
      return false;
    }

    return true;
  }

  function renderSubItem() {
    let selectedItem = sidebar.find((item) =>
      item.links.find((l) => router.location.pathname.includes(l.as))
    );

    if (!selectedItem) {
      selectedItem = sidebar[0];
    }

    return (
      <div className={styles.items}>
        <div className={styles.currentPage}>
          <SVG icon={selectedItem.icon} />
          <span>{selectedItem.title}</span>
        </div>
        <div className={styles.line}>
          <hr />
        </div>
        <div className={`mt24 ${styles.subItems}`}>
          {selectedItem.links.filter(handleSidebarFilter).map((link, idx) => (
            <Link
              href={link.href}
              to={link.as}
              key={idx}
              className={`mb16 ${styles.subItem} ${isSubNavSelected(link)}`}
            >
              {link.title}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.sidebarWrapper} ${selectedItem ? "" : styles.short}`}
    >
      <div className={styles.sidebarFloat}>
        <div className={styles.mainNav}>
          {wldvps && <div className={`${styles.miniLogo} mt28 mb28`} />}
          {!wldvps && <LogoMini className={`${styles.miniLogo} mt28 mb28`} />}

          {sidebar.filter(handleSidebarFilter).map((item, idx) => (
            <Link
              key={idx}
              href={item.links[0].href}
              to={item.links[0].as}
              className={`${styles.box} ${isNavSelected(item)}`}
            >
              <SVG icon={item.icon} />
              {item.title}
            </Link>
          ))}
        </div>
        <div className={styles.subNav}>
          {renderSubItem()}

          {!wldvps && (
            <div className={styles.widgetsWrapper}>
              <Widgets />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Sidebar);
