import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

function CustomChart({ chartType, data }) {
  const dataMemo = useMemo(() => {
    let counter = 0;

    for (let i = 1; i < data.length; i++) {
      if (counter > 0) {
        data[i][0] = "";
      }

      counter++;
      if (counter > 4) {
        counter = 0;
      }
    }

    return data;
  }, [data]);

  return (
    <Chart
      options={{
        legend: "none",
        hAxis: {
          slantedText: false,
        },
      }}
      chartType={chartType}
      data={dataMemo}
      width="100%"
      height="176px"
    />
  );
}

CustomChart.propTypes = {
  chartType: PropTypes.string,
  data: PropTypes.array,
};

export default React.memo(CustomChart, (prevProps, nextProps) => {
  for (let i = 0; i < nextProps.data.length; i++) {
    if (!prevProps.data[i] || nextProps.data[i][0] !== prevProps.data[i][0]) {
      return false;
    }
  }

  return true;
});
