import styles from "./online-users.module.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { useAjax, useUser } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import BasicTable from "../../../components/basic-table";
import { differenceInMinutes, format } from "date-fns";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import {} from "../../../utils";
import Pagination from "../../../components/pagination";
import { getFullName } from "../../../utils/user";

let timerID, token, cancel;
const maxRowsInPage = 10;

export default function AccountOnlineUser() {
  const ajax = useAjax();
  const user = useUser();

  const [lastSeenClients, setLastSeenClients] = useState([]);
  const [realTimeClients, setRealTimeClients] = useState([]);
  const [page, setPage] = useState(0);

  const preventUpdate = useRef(false);

  const getRealTimeClients = useCallback(async () => {
    if (!user._id) {
      return;
    }

    const data = await ajax(
      `/admin/getRealTimeClients`,
      {},
      { cancelToken: token }
    );

    if (data.result === "error") {
      return;
    }

    const lastSeenClients = [];
    const realTimeClients = [];

    for (let i = 0; i < data.clients.length; i++) {
      if (data.clients[i]._id.toString() === user._id.toString()) {
        continue;
      }

      if (
        differenceInMinutes(new Date(), new Date(data.clients[i].lastSeen)) < 1
      ) {
        realTimeClients.push(data.clients[i]);
      } else {
        lastSeenClients.push(data.clients[i]);
      }
    }

    if (!preventUpdate.current) {
      realTimeClients.sort((a, b) => {
        if (a.email === b.email) {
          return 0;
        }

        return a.email > b.email ? 1 : -1;
      });
      setRealTimeClients(realTimeClients);
    }

    lastSeenClients.sort((a, b) => {
      if (a.lastSeen === b.lastSeen) {
        return 0;
      }
      return a.lastSeen < b.lastSeen ? 1 : -1;
    });
    setLastSeenClients(lastSeenClients);

    timerID = setTimeout(() => getRealTimeClients(), 1000);
  }, [ajax, user]);

  useEffect(() => {
    getRealTimeClients();
  }, [getRealTimeClients]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    token = source.token;
    cancel = source.cancel;

    return () => {
      clearTimeout(timerID);
      cancel();
    };
  }, []);

  const filteredLastSeenClients = useMemo(
    () =>
      lastSeenClients.slice(
        page * maxRowsInPage,
        page * maxRowsInPage + maxRowsInPage
      ),
    [lastSeenClients, page]
  );

  return (
    <WithRole permission="clients.real-time-list">
      <div className={styles.wrapper}>
        <h2 className={styles.mainTitle}>
          <FormattedMessage id="account-online-user.real-time" />
        </h2>

        <hr />

        <Box className={styles.boxWrapper}>
          <BasicTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="account-online-user.email" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.full-name" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.ip" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.current-page" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.ping-time" />
                </th>
              </tr>
            </thead>
            <tbody>
              {realTimeClients.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}
              {realTimeClients.map((client, idx) => (
                <tr key={idx}>
                  <td>{client.email}</td>
                  <td>{getFullName(client)}</td>
                  <td>{client.ip}</td>
                  <td>{client.url}</td>
                  <td>
                    {client.lastSeen &&
                      format(new Date(client.lastSeen), "d/M/y HH:mm:ss")}
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </Box>

        <h2 className={styles.mainTitle}>
          <FormattedMessage id="account-online-user.last-seen" />
        </h2>

        <hr />

        <Box>
          <BasicTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="account-online-user.email" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.full-name" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.ip" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.last-page" />
                </th>
                <th>
                  <FormattedMessage id="account-online-user.last-seen" />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredLastSeenClients.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}
              {filteredLastSeenClients.map((client, idx) => (
                <tr key={idx}>
                  <td>{client.email}</td>
                  <td>{getFullName(client)}</td>
                  <td>{client.ip}</td>
                  <td>{client.url}</td>
                  <td>
                    {client.lastSeen &&
                      format(new Date(client.lastSeen), "d/M/y HH:mm:ss")}
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </Box>

        <Pagination
          page={page}
          setPage={setPage}
          totalRows={filteredLastSeenClients.length}
          maxRowsInPage={maxRowsInPage}
        />
      </div>
    </WithRole>
  );
}
