import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IconButton from "../../icon-button";
import { useIntl } from "react-intl";
import Spinner from "../../spinner";
import { sanitize } from "../../../utils";

function ConfirmModal({
  isOpen,
  onClose,
  title,
  message,
  button1 = {},
  button2 = {},
  beforeClose = null,
}) {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  async function handleCloseClicked(region) {
    if (typeof beforeClose === "function") {
      setLoading(true);
      await beforeClose(region);
      setLoading(false);
    }

    onClose(region);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => handleCloseClicked("modal")}
      size="lg"
      style={{ maxWidth: "552px", width: "80%" }}
    >
      <ModalHeader
        className={styles.header}
        toggle={() => handleCloseClicked("modal")}
      >
        {title}
      </ModalHeader>
      <ModalBody
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: sanitize(message) }}
      ></ModalBody>
      <ModalFooter>
        <div className={styles.buttons}>
          <IconButton
            disabled={loading}
            icon={button1.icon || false}
            color={button1.color || "green"}
            onClick={() => handleCloseClicked("button1")}
            textColor={button1.textColor || false}
          >
            {button1.text || intl.formatMessage({ id: "general.close" })}
          </IconButton>
          <IconButton
            disabled={loading}
            icon={button2.icon || false}
            color={button2.color || "green"}
            onClick={() => handleCloseClicked("button2")}
            textColor={button2.textColor || false}
            href={button2.href}
            as={button2.as}
            target={button2.target}
          >
            {!loading &&
              (button2.text || intl.formatMessage({ id: "general.close" }))}
            {loading && <Spinner />}
          </IconButton>
        </div>
      </ModalFooter>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  button1: PropTypes.object,
  button2: PropTypes.object,
  beforeClose: PropTypes.func,
};

export default ConfirmModal;
