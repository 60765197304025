import styles from "./index.module.scss";

import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import IconButton from "../../icon-button";
import { useAjax, useServer } from "../../../utils/hooks";
import Checkbox from "../../checkbox";
import CustomReactSelect from "../../custom-react-select";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const daysToKeepSnapshotsOptions = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
];

function ScheduleNewTaskModal({ isOpen, onClose, tasks, editTask }) {
  const server = useServer();
  const ajax = useAjax();
  const intl = useIntl();

  const [action, setAction] = useState(false);
  const [frequency, setFrequency] = useState(false);
  const [at, setAt] = useState({});
  const [time, setTime] = useState(new Date());
  const [daysToKeepSnapshots, setDaysToKeepSnapshots] = useState(null);
  const [once, setOnce] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const frequencyOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "schedule-new-task-modal.daily" }),
        value: "daily",
      },
      {
        label: intl.formatMessage({ id: "schedule-new-task-modal.weekly" }),
        value: "weekly",
      },
      {
        label: intl.formatMessage({ id: "schedule-new-task-modal.monthly" }),
        value: "monthly",
      },
    ],
    [intl]
  );

  const actionsList = useMemo(() => {
    let list = [
      {
        label: intl.formatMessage({
          id: "schedule-new-task-modal.action-start",
        }),
        value: "start-server",
      },
      {
        label: intl.formatMessage({
          id: "schedule-new-task-modal.action-stop-soft",
        }),
        value: "stop-server-soft",
      },
      {
        label: intl.formatMessage({
          id: "schedule-new-task-modal.action-stop-hard",
        }),
        value: "stop-server-hard",
      },
      {
        label: intl.formatMessage({
          id: "schedule-new-task-modal.action-create-snapshot",
        }),
        value: "create-snapshot",
      },
    ];

    if (server && !server.ping) {
      list = list.filter((action) => action.value !== "stop-server-soft");
    }

    return list;
  }, [intl, server]);

  useEffect(() => {
    if (isOpen) {
      if (editTask) {
        const frequency = frequencyOptions.find(
          (option) => option.value === editTask.frequency
        );
        setFrequency(frequency);

        setAction(
          actionsList.find((action) => action.value === editTask.action)
        );

        setOnce(editTask.once);

        setDaysToKeepSnapshots(
          daysToKeepSnapshotsOptions.find(
            (item) =>
              item.value === editTask?.extra_data?.days_to_keep_snapshots
          )
        );

        setTimeout(() => {
          setTime(moment(editTask.time));

          const at = {};
          editTask.at.forEach((item) => {
            at[item] = true;
          });

          setAt(at);
        }, 1);
      } else {
        setFrequency(false);
        setAt({});
        setTime(null);
        setAction(false);
        setOnce(false);
        setDaysToKeepSnapshots(null);
      }
    }
  }, [isOpen, editTask, frequencyOptions, actionsList]);

  useEffect(() => {
    setTime(null);
    setAt({});
  }, [frequency]);

  async function handleScheduleTaskClicked() {
    setError(false);

    if (!action) {
      return setError(
        intl.formatMessage({ id: "schedule-new-task-modal.empty-action" })
      );
    } else if (!frequency) {
      return setError(
        intl.formatMessage({ id: "schedule-new-task-modal.empty-frequency" })
      );
    } else if (!time) {
      return setError(
        intl.formatMessage({ id: "schedule-new-task-modal.empty-time" })
      );
    } else if (action.value === "create-snapshot" && !daysToKeepSnapshots) {
      return setError(
        intl.formatMessage({
          id: "schedule-new-task-modal.empty-days-to-keep-snapshots",
        })
      );
    }

    if (frequency.value !== "daily" && Object.keys(at).length === 0) {
      return setError(
        intl.formatMessage({
          id:
            frequency.value === "weekly"
              ? "schedule-new-task-modal.empty-at-weekly"
              : "schedule-new-task-modal.empty-at-monthly",
        })
      );
    }

    const existsTask = tasks.find(
      (task) =>
        task.every === frequency.value &&
        task.at === time.value &&
        task.action === action.value
    );

    if (existsTask && existsTask._id !== editTask?._id) {
      return setError(
        intl.formatMessage({ id: "schedule-new-task-modal.task-exists" })
      );
    }

    setLoading(true);
    await ajax("/scheduler/scheduleTask", {
      serverID: server._id,
      action: action.value,
      frequency: frequency.value,
      at: Object.keys(at).filter((k) => at[k]),
      time: time.toDate(),
      daysToKeepSnapshots: daysToKeepSnapshots?.value,
      once,
      editTaskID: editTask?._id,
    });
    setLoading(false);

    onClose(true);
  }

  function handleDayClicked(day) {
    at[day] = !at[day];
    setAt({ ...at });
  }

  function handleWeekClicked(week) {
    const _at = {};
    _at[week] = true;
    setAt(_at);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editTask
              ? "schedule-new-task-modal.title-edit"
              : "schedule-new-task-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <div className={styles.text}>
            <FormattedMessage id="schedule-new-task-modal.action" />
          </div>
          <div className={styles.select}>
            <Select
              value={action}
              onChange={(value) => setAction(value)}
              options={actionsList}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.text}>
            <FormattedMessage id="schedule-new-task-modal.frequency" />
          </div>
          <div className={styles.select}>
            <Select
              value={frequency}
              onChange={(value) => setFrequency(value)}
              options={frequencyOptions}
            />
          </div>
        </div>

        {frequency?.value === "weekly" && (
          <div className={`${styles.buttonsWrapper} ${styles.spaceBetween}`}>
            {days.map((day, key) => (
              <div
                key={key}
                className={at[key] ? styles.selected : ""}
                onClick={() => handleDayClicked(key)}
              >
                {day[0]}
              </div>
            ))}
          </div>
        )}

        {frequency?.value === "monthly" && (
          <div className={styles.buttonsWrapper}>
            <div
              className={at[1] ? styles.selected : ""}
              onClick={() => handleWeekClicked(1)}
            >
              1st
            </div>
            <div
              className={at[15] ? styles.selected : ""}
              onClick={() => handleWeekClicked(15)}
            >
              15st
            </div>
          </div>
        )}

        {frequency && (
          <div className={styles.row}>
            <div className={styles.text}>
              <FormattedMessage id="schedule-new-task-modal.time" />
            </div>
            <div className={styles.select}>
              <TimePicker
                value={time}
                onChange={(value) => setTime(value)}
                showSecond={false}
                allowEmpty={false}
              />
            </div>
          </div>
        )}

        {action.value === "create-snapshot" && (
          <div className={styles.row}>
            <div className={`${styles.text} ${styles.long}`}>
              <FormattedMessage id="schedule-new-task-modal.keep-snapshots-1" />
            </div>
            <CustomReactSelect
              className={styles.daysToKeepSnapshotsSelect}
              options={daysToKeepSnapshotsOptions}
              value={daysToKeepSnapshots}
              onChange={(item) => setDaysToKeepSnapshots(item)}
            />
            <div className={`${styles.text} ${styles.long2}`}>
              <FormattedMessage id="schedule-new-task-modal.keep-snapshots-2" />
            </div>
          </div>
        )}

        <div className={styles.row}>
          <Checkbox
            label="schedule-new-task-modal.once"
            checked={once}
            onChange={(e) => setOnce(e.target.checked)}
          />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleScheduleTaskClicked}
        >
          <FormattedMessage
            id={editTask ? "general.edit" : "general.schedule"}
          />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}
ScheduleNewTaskModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  tasks: PropTypes.array,
  editTask: PropTypes.object,
};

export default ScheduleNewTaskModal;
