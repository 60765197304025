import styles from "./custom-iso.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../../../../components/box";
import { WithRole } from "../../../../../../components/with-role";
import {
  useAjax,
  useAlert,
  useConfirm,
  useServer,
  useUser,
} from "../../../../../../utils/hooks";
import IconButton from "../../../../../../components/icon-button";
import { SUPER_ADMIN } from "../../../../../../utils/user";
import { asyncTimeout, proxmoxStringToObject } from "../../../../../../utils";
import UploadIsoModal from "../../../../../../components/modals/upload-iso";
import CustomReactSelect from "../../../../../../components/custom-react-select";

export default function ServerSettingsCustomISO() {
  const ajax = useAjax();
  const user = useUser();
  const server = useServer();
  const confirm = useConfirm();
  const alert = useAlert();
  const intl = useIntl();

  const [iso, setISO] = useState(null);
  const [customIsoList, setCustomIsoList] = useState(null);
  const [config, setConfig] = useState(null);
  const [image, setImage] = useState({});
  const [currentImage, setCurrentImage] = useState(null);
  const [isUploadIsoModalOpen, setIsUploadIsoModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const getCustomIsoList = useCallback(async () => {
    if (!server) {
      return;
    }

    const [listData, configData] = await Promise.all([
      ajax("/proxmox/nodes/storage/getCustomIsoList"),
      ajax("/proxmox/nodes/qemu/getConfig", {
        serverID: server._id,
      }),
    ]);

    setCustomIsoList(listData.isoList);
    setConfig(configData.data);

    if (configData.data.ide0) {
      const currentImage = listData.isoList.find((item) =>
        configData.data.ide0.includes(item.volid)
      );

      if (currentImage) {
        setCurrentImage(currentImage.volid);
        setImage({ value: currentImage.volid, custom: true });

        setISO({
          label: `${currentImage.custom_name}${
            currentImage.description ? ` - ${currentImage.description}` : ""
          }`,
          value: currentImage,
        });
      } else {
        setCurrentImage(null);
        setImage({});
        setISO(null);
      }
    }
  }, [ajax, server]);

  useEffect(() => {
    getCustomIsoList();
  }, [getCustomIsoList]);

  function handleCustomIsoChanged(item) {
    setISO(item);
    setImage({ value: item.value.volid, custom: true });
  }

  async function askIfChangeBootOrder(boots) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "server-settings-custom-iso.change-boot.title",
      }),
      message: intl.formatMessage({
        id: "server-settings-custom-iso.change-boot.content",
      }),
    });

    if (state === "button2") {
      for (let i = 0; i < boots.length; i++) {
        if (boots[i] === "ide0") {
          boots.splice(i, 1);
          break;
        }
      }

      boots.unshift("ide0");

      await ajax("/servers/updateBootOrder", {
        serverID: server._id,
        boot: `order=${boots.join(";")}`,
      });

      await asyncTimeout(1);
      return true;
    }

    await asyncTimeout(1);
    return false;
  }

  async function handleUpdateClicked() {
    setLoading(true);
    await ajax("/servers/changeIsoImage", {
      serverID: server._id,
      isoName: image.value === currentImage ? null : image.value,
    });

    setCurrentImage(image.value);

    let hasCD = false;
    let changedBoot = false;
    let boots = null;
    Object.keys(config).forEach((key) => {
      if (key.startsWith("ide") && !config[key].includes("cloudinit")) {
        hasCD = true;
      }

      if (key === "boot") {
        boots = proxmoxStringToObject(config[key]).order.split(";");
      }
    });

    if (boots && boots[0] !== "ide0" && image.value !== currentImage) {
      changedBoot = await askIfChangeBootOrder(boots);
    }

    if ((server.qemuStatus.status === "running" && !hasCD) || changedBoot) {
      const state = await confirm({
        title: intl.formatMessage({
          id: "server-settings-custom-iso.update.title",
        }),
        message: intl.formatMessage({
          id: "server-settings-custom-iso.update.confirm-content",
        }),
        button1: {
          text: intl.formatMessage({ id: "general.no" }),
          color: "white",
        },
        button2: {
          text: intl.formatMessage({
            id: "server-settings-custom-iso.update.yes-restart",
          }),
          color: "white",
        },
      });

      if (state === "button2") {
        await ajax("/servers/action", {
          serversIds: [server._id],
          actionType: "reset",
          isSoft: false,
        });
      }
    } else {
      await alert(
        intl.formatMessage({ id: "server-settings-custom-iso.update.title" }),
        intl.formatMessage({
          id: "server-settings-custom-iso.update.alert-content",
        })
      );
    }

    await getCustomIsoList();

    setLoading(false);
  }

  function handleUploadIsoClicked() {
    setIsUploadIsoModalOpen(true);
  }

  async function handleUploadIsoModalClosed(state) {
    setIsUploadIsoModalOpen(false);

    if (state) {
      await alert(
        intl.formatMessage({ id: "general.upload-iso" }),
        intl.formatMessage({ id: "general.upload-iso.success" })
      );
    }
  }

  const customIsoListOptions = useMemo(
    () =>
      customIsoList
        ? customIsoList
            .filter(
              (iso) =>
                (user.role === SUPER_ADMIN && iso.enabled_in_admin) ||
                (user.role !== SUPER_ADMIN && iso.enabled_in_user)
            )
            .map((iso) => ({
              label: `${iso.custom_name}${
                iso.description ? ` - ${iso.description}` : ""
              }`,
              value: iso,
            }))
        : [],
    [customIsoList, user.role]
  );

  return (
    <WithRole permission="servers.change-os">
      <div className={styles.wrapper}>
        <Box className={styles.box}>
          <div className={styles.mainTitle}>
            <FormattedMessage id="server-settings-custom-iso.title" />
          </div>

          <div className={styles.row}>
            <CustomReactSelect
              options={customIsoListOptions}
              value={iso}
              onChange={handleCustomIsoChanged}
            />
          </div>

          <div className={styles.description}>
            <FormattedMessage id="server-settings-custom-iso.description" />
          </div>

          <div className={styles.buttons}>
            <IconButton
              disabled={loading || !image.value || server?.isWorking}
              color="light-purple"
              onClick={handleUpdateClicked}
            >
              <FormattedMessage
                id={
                  image.value === currentImage
                    ? "general.remove"
                    : "general.insert"
                }
              />
            </IconButton>
          </div>

          <div className={styles.uploadIsoCantFind}>
            <FormattedMessage id="general.upload-iso-cant-find" />

            <IconButton color="light-purple" onClick={handleUploadIsoClicked}>
              <FormattedMessage id="general.upload-iso" />
            </IconButton>
          </div>
        </Box>
      </div>

      <UploadIsoModal
        isOpen={isUploadIsoModalOpen}
        onClose={handleUploadIsoModalClosed}
      />
    </WithRole>
  );
}
