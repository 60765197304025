import styles from "./index.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import CustomTag from "../../custom-tag";

function AddNewIPModal({ isOpen, onClose, ipsets, edit, userIDToWork }) {
  const intl = useIntl();
  const ajax = useAjax();

  const [name, setName] = useState("");
  const [aliases, setAliases] = useState([]);

  const [ips, setIps] = useState([]);
  const ipsRef = useRef(ips);
  ipsRef.current = ips;

  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAliases = useCallback(async () => {
    const data = await ajax("/firewall/getAliases", {
      userIDToWork: userIDToWork?.value,
    });

    setAliases(data.aliases);
  }, [ajax, userIDToWork?.value]);

  useEffect(() => {
    if (isOpen) {
      getAliases();

      setName(edit ? edit.comment.split("_")[0] : "");
      setIps(edit ? edit.ips.split("<br />") : []);
      setAddress("");
      setSuggestions(false);
      setError(false);
      setLoading(false);
    }
  }, [getAliases, isOpen, edit]);

  useEffect(() => {
    for (let i = 0; i < ipsRef.current.length; i++) {
      const alias = aliases.find((a) => a._id === ipsRef.current[i]);

      if (alias) {
        ipsRef.current[i] = alias;
      }
    }

    setIps(ipsRef.current);
  }, [aliases]);

  useEffect(() => {
    let suggestions = [];

    if (address) {
      aliases.forEach((alias) => {
        if (alias.name.toLowerCase().includes(address.toLowerCase())) {
          suggestions.push({ name: alias.name, _id: alias._id });
        }
      });

      if (suggestions.length > 0) {
        return setSuggestions(suggestions);
      }

      let addressArr = address.split(".");

      for (let i = 0; i < addressArr.length; i++) {
        if (!/^\d+$/.test(addressArr[i])) {
          return setSuggestions(false);
        }

        let num = parseInt(addressArr[i]);
        if (isNaN(num) || num < 0 || num > 255) {
          return setSuggestions(false);
        }
      }

      if (addressArr.length === 1 && !addressArr[1]) {
        suggestions = [
          `${addressArr[0]}.0.0.0/8`,
          `${addressArr[0]}.0.0.0/16`,
          `${addressArr[0]}.0.0.0/24`,
          `${addressArr[0]}.0.0.0/32`,
          `${addressArr[0]}.1.0.0/16`,
        ];
      } else if (addressArr.length === 2 && !addressArr[2]) {
        suggestions = [
          `${addressArr[0]}.${addressArr[1]}.0.0/16`,
          `${addressArr[0]}.${addressArr[1]}.0.0/24`,
          `${addressArr[0]}.${addressArr[1]}.0.0/32`,
          `${addressArr[0]}.${addressArr[1]}.1.0/24`,
          `${addressArr[0]}.${addressArr[1]}.1.0/32`,
          `${addressArr[0]}.${addressArr[1]}.2.0/24`,
          `${addressArr[0]}.${addressArr[1]}.2.0/32`,
          `${addressArr[0]}.${addressArr[1]}.3.0/24`,
          `${addressArr[0]}.${addressArr[1]}.3.0/32`,
          `${addressArr[0]}.${addressArr[1]}.4.0/24`,
        ];
      } else if (addressArr.length === 3 && !addressArr[3]) {
        suggestions = [
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.0/24`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.0/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.1/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.2/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.3/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.4/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.5/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.6/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.7/32`,
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.8/32`,
        ];
      } else if (addressArr.length === 4 && !addressArr[4]) {
        suggestions = [
          `${addressArr[0]}.${addressArr[1]}.${addressArr[2]}.${addressArr[3]}/32`,
        ];
      }
    }

    setSuggestions(suggestions.length > 0 ? suggestions : false);
  }, [address, aliases]);

  function handleSuggestionClicked(suggestion) {
    ips.push(suggestion);
    setIps([...ips]);
    setAddress("");
    setSuggestions(false);
  }

  async function handleAddIpSetClicked() {
    setError(false);

    const _name = name.trim();

    if (!_name.match(/^[A-Za-z]+$/)) {
      return setError(
        intl.formatMessage({ id: "add-new-ip-modal.invalid-name" })
      );
    } else if (ips.length === 0) {
      return setError(
        intl.formatMessage({ id: "add-new-ip-modal.missing-ips" })
      );
    } else if (!edit && ipsets.find((ipset) => ipset.comment.includes(_name))) {
      return setError(
        intl.formatMessage({ id: "add-new-ip-modal.duplicate-name" })
      );
    }

    const finalIps = ips.map((ip) => (typeof ip === "object" ? ip._id : ip));

    setLoading(true);
    let data;
    if (edit) {
      data = await ajax("/firewall/editIPSetName", {
        ipsetID: edit.name,
        name: _name,
        userIDToWork: userIDToWork?.value,
      });

      data = await ajax("/firewall/editIPSetIPs", {
        ips: finalIps.join("\n"),
        ipsetID: edit.name,
        userIDToWork: userIDToWork?.value,
      });
    } else {
      data = await ajax("/firewall/addIPSet", {
        ips: finalIps.join("\n"),
        name: _name,
        userIDToWork: userIDToWork?.value,
      });
    }

    if (data.result === "success") {
      onClose(true);
    } else {
      setError(intl.formatMessage({ id: "general.general-error" }));
      setLoading(false);
    }
  }

  function handleTagRemoveClicked(key) {
    ips.splice(key, 1);
    setIps([...ips]);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-new-ip-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-new-ip-modal.ipset-name" />
          </span>
          <CustomText value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-new-ip-modal.ipset-address" />
          </span>
          <CustomText
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />

          {suggestions && (
            <div className={styles.suggestionsWrapper}>
              {suggestions.map((suggestion, key) => (
                <div
                  key={key}
                  onClick={() => handleSuggestionClicked(suggestion)}
                >
                  {typeof suggestion === "object" && suggestion.name}
                  {typeof suggestion !== "object" && suggestion}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={styles.tagsWrapper}>
          {ips.map((ip, key) => (
            <CustomTag
              key={key}
              withRemove
              onRemove={() => handleTagRemoveClicked(key)}
            >
              {typeof ip === "object" && ip.name}
              {typeof ip !== "object" && ip}
            </CustomTag>
          ))}
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAddIpSetClicked}
        >
          <FormattedMessage id="general.save" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddNewIPModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  ipsets: PropTypes.array,
  userIDToWork: PropTypes.object,
};

export default AddNewIPModal;
