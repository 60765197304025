import styles from "./calculator.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { useAjax } from "../../../utils/hooks";
import Box from "../../../components/box";
import CustomReactSelect from "../../../components/custom-react-select";
import { getFullName } from "../../../utils/user";
import Checkbox from "../../../components/checkbox";
import { FormattedMessage } from "react-intl";
import IconButton from "../../../components/icon-button";
import {} from "../../../utils";
import { WINDOWS10_IMAGE } from "../../../utils/servers";

export default function Calculator() {
  const ajax = useAjax();

  const [users, setUsers] = useState([]);
  const [images, setImages] = useState([]);
  const [cpus, setCpus] = useState([]);
  const [rams, setRams] = useState([]);
  const [ssds, setSsds] = useState([]);
  const [additional_ssds, setAdditional_ssds] = useState([]);
  const [monitors, setMonitors] = useState([]);
  const [managedHostings, setManagedHostings] = useState([]);
  const [cpanelLicenses, setCpanelLicenses] = useState([]);
  const [windowsLicenseDesktop, setWindowsLicenseDesktop] = useState(null);
  const [windowsLicenseServer, setWindowsLicenseServer] = useState(null);

  const [user, setUser] = useState(null);
  const [image, setImage] = useState(null);
  const [cpu, setCpu] = useState(null);
  const [ram_mb, setRam_mb] = useState(null);
  const [ssd_gb, setSsd_gb] = useState(null);
  const [additional_ssd_gb, setAdditional_ssd_gb] = useState(null);
  const [backup, setBackup] = useState(true);
  const [monitor, setMonitor] = useState(null);
  const [managedHosting, setManagedHosting] = useState(null);
  const [cpanelLicense, setCpanelLicense] = useState(null);

  const [prices, setPrices] = useState(null);

  const getAllUsers = useCallback(async () => {
    const data = await ajax("/users/getAllUsers", {
      filter: { email: 1, firstName: 1, lastName: 1 },
    });

    if (data.result === "success") {
      const users = data.users.map((user) => ({
        label: `${user.email} | ${getFullName(user)}`,
        value: user._id,
      }));
      setUsers(users);

      const testUser = users.find(
        (user) => user.value === "5ff31a9cebc556403ddd6fed"
      );
      if (testUser) {
        setUser(testUser);
      }
    }
  }, [ajax]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const getSystemProducts = useCallback(async () => {
    const data = await ajax("/admin/getSystemProduct");

    const images = data.systemProduct.prices.image.map((image) => ({
      label: image.value,
      value: image.value,
    }));
    setImages(images);

    const cpus = data.systemProduct.prices.cpu.map((cpu) => ({
      label: cpu.value,
      value: cpu.value,
    }));
    setCpus(cpus);

    const rams = data.systemProduct.prices.ram_mb.map((ram_mb) => ({
      label: ram_mb.value,
      value: ram_mb.value,
    }));
    setRams(rams);

    const ssds = data.systemProduct.prices.ssd_gb.map((ssd_gb) => ({
      label: ssd_gb.value,
      value: ssd_gb.value,
    }));
    setSsds(ssds);

    const additionalSsds = data.systemProduct.prices.additional_ssd_gb.map(
      (additional_ssd_gb) => ({
        label: additional_ssd_gb.value,
        value: additional_ssd_gb.value,
      })
    );
    additionalSsds.unshift({ label: "None", value: 0 });
    setAdditional_ssds(additionalSsds);

    setImage(images[0]);
    setCpu(cpus[0]);
    setRam_mb(rams[0]);
    setSsd_gb(ssds[0]);
    setAdditional_ssd_gb(additionalSsds[0]);
  }, [ajax]);

  useEffect(() => {
    getSystemProducts();
  }, [getSystemProducts]);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll", {
      type: [
        "server-monitor",
        "managed-hosting",
        "cpanel-license",
        "windows-license",
      ],
    });

    const monitors = data.products
      .filter((product) => product.type === "server-monitor")
      .map((product) => ({
        label: `${product.name} - ${product.price}$`,
        value: product._id,
      }));
    monitors.unshift({ label: "None", value: null });
    setMonitors(monitors);
    setMonitor(monitors[0]);

    const managedHosting = data.products
      .filter((product) => product.type === "managed-hosting")
      .map((product) => ({
        label: `${product.name} - ${product.price}$`,
        value: product._id,
      }));
    managedHosting.unshift({ label: "None", value: null });
    setManagedHostings(managedHosting);
    setManagedHosting(managedHosting[0]);

    const cpanelLicense = data.products
      .filter((product) => product.type === "cpanel-license")
      .map((product) => ({
        label: `${product.name} - ${product.price}$`,
        value: product._id,
      }));
    setCpanelLicenses(cpanelLicense);
    setCpanelLicense(cpanelLicense[0]);

    data.products
      .filter((product) => product.type === "windows-license")
      .forEach((product) => {
        if (product.name === "Windows Desktop License") {
          setWindowsLicenseDesktop(product);
        }
        if (product.name === "Windows Server License") {
          setWindowsLicenseServer(product);
        }
      });
  }, [ajax]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const getServerPrice = useCallback(async () => {
    if (
      !user ||
      !image ||
      !cpu ||
      !ram_mb ||
      !ssd_gb ||
      !monitor ||
      !managedHosting
    ) {
      return;
    }

    const addons = [];
    if (monitor.value) {
      addons.push(monitor.value);
    }
    if (managedHosting.value) {
      addons.push(managedHosting.value);
    }
    if (image.value.toLowerCase().includes("cpanel")) {
      addons.push(cpanelLicense.value);
    }
    if (image.value.toLowerCase().includes("windows")) {
      if (image.value === WINDOWS10_IMAGE) {
        addons.push(windowsLicenseDesktop._id);
      } else {
        addons.push(windowsLicenseServer._id);
      }
    }

    const data = await ajax("/billing/getServerPrice", {
      userID: user.value,
      serverData: {
        image: image.value,
        cpu: cpu.value,
        ram_mb: ram_mb.value,
        ssd_gb: ssd_gb.value,
        additional_ssd_gb: additional_ssd_gb.value,
        backup,
        new: true,
      },
      addons,
    });

    setPrices(data);
  }, [
    ajax,
    image,
    cpu,
    ram_mb,
    ssd_gb,
    additional_ssd_gb,
    backup,
    user,
    monitor,
    managedHosting,
    cpanelLicense,
    windowsLicenseDesktop,
    windowsLicenseServer,
  ]);

  useEffect(() => {
    getServerPrice();
  }, [getServerPrice]);

  return (
    <div className={styles.wrapper}>
      <Box title="Calculator" className={styles.options}>
        <div className={styles.row}>
          <span>User</span>
          <CustomReactSelect
            value={user}
            onChange={(item) => setUser(item)}
            options={users}
          />
        </div>
        <div className={styles.row}>
          <span>Image</span>
          <CustomReactSelect
            value={image}
            onChange={(item) => setImage(item)}
            options={images}
          />
        </div>
        <div className={styles.row}>
          <span>CPU</span>
          <CustomReactSelect
            value={cpu}
            onChange={(item) => setCpu(item)}
            options={cpus}
          />
        </div>
        <div className={styles.row}>
          <span>RAM</span>
          <CustomReactSelect
            value={ram_mb}
            onChange={(item) => setRam_mb(item)}
            options={rams}
          />
        </div>
        <div className={styles.row}>
          <span>Disk</span>
          <CustomReactSelect
            value={ssd_gb}
            onChange={(item) => setSsd_gb(item)}
            options={ssds}
          />
        </div>
        <div className={styles.row}>
          <span>A.Disk</span>
          <CustomReactSelect
            value={additional_ssd_gb}
            onChange={(item) => setAdditional_ssd_gb(item)}
            options={additional_ssds}
          />
        </div>
        <div>
          <Checkbox
            label="clients-list-servers.backup"
            checked={backup}
            onChange={(e) => setBackup(e.target.checked)}
          />
        </div>
        <div className={styles.row}>
          <span>Monitor</span>
          <CustomReactSelect
            value={monitor}
            onChange={(item) => setMonitor(item)}
            options={monitors}
          />
        </div>
        <div className={styles.row}>
          <span>Managed Hosting</span>
          <CustomReactSelect
            value={managedHosting}
            onChange={(item) => setManagedHosting(item)}
            options={managedHostings}
          />
        </div>
        {image?.value.toLowerCase().includes("cpanel") && (
          <div className={styles.row}>
            <span>cPanel License</span>
            <CustomReactSelect
              value={cpanelLicense}
              onChange={(item) => setCpanelLicense(item)}
              options={cpanelLicenses}
            />
          </div>
        )}

        <IconButton color="light-purple" onClick={getServerPrice}>
          <FormattedMessage id="general.refresh" />
        </IconButton>
      </Box>

      <Box title="Prices" className={styles.prices}>
        {!prices && <div className={styles.row}>Loading price...</div>}

        {prices && (
          <>
            <div className={styles.row}>
              <span>Monthly Price</span>
              {(prices.monthlyPrice + prices.addonsPrice).toFixed(2)}$
            </div>
            <hr />
            <div className={styles.row}>
              <span>Hourly Price On</span>
              {(prices.hourlyPriceOn + prices.addonsHourlyPriceOn).toFixed(3)}$
            </div>
            <div className={styles.row}>
              <span>Hourly Price Off</span>
              {(prices.hourlyPriceOff + prices.addonsHourlyPriceOff).toFixed(3)}
              $
            </div>
          </>
        )}
      </Box>
    </div>
  );
}
