import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { format, parse } from "date-fns";
import IconButton from "../../icon-button";
import BasicTable from "../../basic-table";
import { ReactComponent as EditSvg } from "../../svgs/edit-purple.svg";
import { useAjax, usePrompt, useRoles } from "../../../utils/hooks";

function BandwidthInfoModal({ isOpen, onClose, server, onUpdate }) {
  const prompt = usePrompt();
  const intl = useIntl();
  const ajax = useAjax();
  const { isAllowed } = useRoles();

  const [loading, setLoading] = useState(false);

  async function handleEditClicked(sampling) {
    const num = await prompt({
      title: intl.formatMessage({ id: "traffic-info.update-paid.title" }),
      message: intl.formatMessage(
        { id: "traffic-info.update-paid.content" },
        { total: (sampling.bandwidth / Math.pow(1024, 3)).toFixed(2) }
      ),
      defaultText: (sampling.paid_bandwidth / Math.pow(1024, 3)).toFixed(2),
      acceptOnlyValue: (text) => {
        const f = parseFloat(text);

        if (isNaN(f) || f < 0) {
          return {
            status: false,
            reason: intl.formatMessage({
              id: "traffic-info.update-paid.error-nan",
            }),
          };
        }

        return { status: true };
      },
    });

    if (num === null) {
      return;
    }

    const newPaid = parseFloat(num) * Math.pow(1024, 3);

    setLoading(true);
    await ajax("/bandwidth-form/updatePaidBandwidth", {
      serverID: server._id,
      samplingID: sampling._id,
      newPaid: Math.min(newPaid, sampling.bandwidth),
    });

    await onUpdate();
    setLoading(false);
  }

  function renderDate(monthYear) {
    const dt = parse(monthYear, "M_yyyy", new Date());

    return format(dt, "MM/yyyy");
  }

  if (!server) {
    return null;
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id="traffic-info.title"
          values={{ server: server.hostname }}
        />
      </ModalHeader>
      <ModalBody>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="traffic-info.date" />
              </th>
              <th>
                <FormattedMessage id="traffic-info.total-traffic" />
              </th>
              <th>
                <FormattedMessage id="traffic-info.paid-traffic" />
              </th>
              <th>
                <FormattedMessage id="traffic-info.unpaid-traffic" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {server.samplings.map((sampling, key) => (
              <tr key={key}>
                <td>{renderDate(sampling.month_year)}</td>
                <td>{(sampling.bandwidth / Math.pow(1024, 3)).toFixed(2)}GB</td>
                <td>
                  {(sampling.paid_bandwidth / Math.pow(1024, 3)).toFixed(2)}GB
                </td>
                <td>
                  {(
                    (sampling.bandwidth - sampling.paid_bandwidth) /
                    Math.pow(1024, 3)
                  ).toFixed(2)}
                  GB
                </td>
                <td>
                  {isAllowed("admin.bandwidth-form.update") && (
                    <EditSvg
                      onClick={() => handleEditClicked(sampling)}
                      className={styles.edit}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </ModalBody>
      <ModalFooter>
        {isAllowed("admin.bandwidth-form.update") && (
          <>
            <IconButton disabled={loading} color="purple">
              <FormattedMessage id="general.save" />
            </IconButton>
            <IconButton
              disabled={loading}
              color="text"
              onClick={() => onClose(false)}
            >
              <FormattedMessage id="general.cancel" />
            </IconButton>
          </>
        )}

        {!isAllowed("admin.bandwidth-form.update") && (
          <>
            <IconButton
              disabled={loading}
              color="text"
              onClick={() => onClose(false)}
            >
              <FormattedMessage id="general.close" />
            </IconButton>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

BandwidthInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  server: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default BandwidthInfoModal;
