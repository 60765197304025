import React from "react";
import PropTypes from "prop-types";
import {} from "../../../../../utils";
import SupportMyTickets from "../../../support/my-tickets";

function ClientsListTickets({ user }) {
  return <>{user && <SupportMyTickets onlyUser={user} compactMode />}</>;
}

ClientsListTickets.propTypes = {
  user: PropTypes.object,
};

export default ClientsListTickets;
