import styles from "./ipv4.module.scss";

import React, { useEffect, useCallback, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import isValidDomain from "is-valid-domain";
import { ReactComponent as CancelSvg } from "../../../../../../components/svgs/cancel.svg";
import { ReactComponent as CheckSvg } from "../../../../../../components/svgs/check.svg";
import { ReactComponent as InfoSvg } from "../../../../../../components/svgs/info.svg";
import Box from "../../../../../../components/box";
import CustomMenu from "../../../../../../components/custom-menu";
import IconButton from "../../../../../../components/icon-button";
import BasicTable from "../../../../../../components/basic-table";
import {
  useAjax,
  usePrompt,
  useConfirm,
  useRoles,
  useAlert,
  useServer,
  useUser,
} from "../../../../../../utils/hooks";
import { parseNets } from "../../../../../../utils/networks";
import CustomMenuItem from "../../../../../../components/custom-menu/item";
import AddAdditionalIpv4Modal from "../../../../../../components/modals/add-additional-ipv4";
import { currencySymbols } from "../../../../../../utils/billing";
import PrivateNetworkingModal from "../../../../../../components/modals/private-networking";
import ChangeServerGatewayModal from "../../../../../../components/modals/change-server-gateway";
import { proxmoxStringToObject } from "../../../../../../utils";
import { Tooltip } from "reactstrap";
import { SUPER_ADMIN } from "../../../../../../utils/user";
import validator from "validator";

export default function ServerSettingsIPv4() {
  const intl = useIntl();
  const ajax = useAjax();
  const { id } = useParams();
  const alert = useAlert();
  const prompt = usePrompt();
  const confirm = useConfirm();
  const { isAllowed } = useRoles();
  const server = useServer();
  const user = useUser();

  const [config, setConfig] = useState(false);
  const [wans, setWans] = useState({});
  const [lans, setLans] = useState({});
  const [ipconfigs, setIpconfigs] = useState({});
  const [dns, setDNS] = useState(false);
  const [gateway, setGateway] = useState(null);
  const [serverIps, setServerIps] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [isIpv4DropdownOpen, setIsIpv4DropdownOpen] = useState({});
  const [isChangeGatewayTooltipOpen, setIsChangeGatewayTooltipOpen] = useState(
    false
  );
  const [isChangeDnsTooltipOpen, setIsChangeDnsTooltipOpen] = useState(false);

  const [
    isPrivateNetworkingModalOpen,
    setIsPrivateNetworkingModalOpen,
  ] = useState(false);
  const [
    isAddAdditionalIpv4ModalOpen,
    setIsAddAdditionalIpv4ModalOpen,
  ] = useState(false);
  const [
    isChangeServerGatewayModalOpen,
    setIsChangeServerGatewayModalOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchConfig = useCallback(async () => {
    const data = await ajax("/proxmox/nodes/qemu/getConfig", {
      serverID: id,
    });

    if (data.result === "success" && data.data) {
      const { wans, lans, ipconfigs, dns, gateway } = parseNets(data.data);

      const cloudinitArr = data.cloudinit?.split("\n");

      if (!cloudinitArr) {
        return;
      }

      let eth;
      cloudinitArr.forEach((line) => {
        if (line.includes("eth")) {
          eth = line.match(/[0-9]/g);
        }

        if (eth && line.includes("netmask")) {
          ipconfigs[eth[0]] += `,netmask=${line.match(
            /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/g
          )}`;

          eth = null;
        }
      });

      setWans(wans);
      setLans(lans);
      setIpconfigs(ipconfigs);
      setConfig(data.data);
      setDNS(dns);
      setGateway(gateway);
    }
  }, [ajax, id]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const getIpsOfServer = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax("/network/getIpsOfServer", {
      serverID: server._id,
    });

    if (!data.ips) {
      return;
    }

    data.ips.forEach((ip) => {
      ip.addon = server.addons.find((a) => a.description === ip.ip);
    });

    setServerIps(data.ips);
  }, [ajax, server]);

  useEffect(() => {
    getIpsOfServer();
  }, [getIpsOfServer]);

  async function handleChangeDnsClicked() {
    const dns = await prompt({
      title: intl.formatMessage({ id: "server-settings-ipv4.change-dns" }),
      message: intl.formatMessage({
        id: "server-settings-ipv4.change-dns-desc",
      }),
      defaultText: config.nameserver,
    });

    if (!dns) {
      return;
    }

    setLoading(true);
    await ajax("/proxmox/nodes/qemu/setNameserver", {
      dns,
      serverID: server._id,
    });
    await fetchConfig();
    setLoading(false);
  }

  async function setNetData(netNum, dataToSet) {
    setLoading(true);
    await ajax("/proxmox/nodes/qemu/setNetData", {
      dataToSet,
      netNum,
      serverID: server._id,
    });
    await fetchConfig();
    setLoading(false);
  }

  function handleMenuToggle(type, key) {
    openDropdowns[`${type}_${key}`] = !openDropdowns[`${type}_${key}`];
    setOpenDropdowns({ ...openDropdowns });
  }

  async function handlePrivateNetworkingModalClosed(state) {
    if (state) {
      await fetchConfig();
    }

    setIsPrivateNetworkingModalOpen(false);
  }

  function handleAddAdditionalIpv4ModalOpen() {
    setIsAddAdditionalIpv4ModalOpen(true);
  }

  async function handleAddAdditionalIpv4ModalClosed() {
    setIsAddAdditionalIpv4ModalOpen(false);
  }

  function handleIpv4DropdownOpenToggle(ipObj) {
    isIpv4DropdownOpen[ipObj.ip] = !isIpv4DropdownOpen[ipObj.ip];
    setIsIpv4DropdownOpen({ ...isIpv4DropdownOpen });
  }

  async function handleRemoveIpv4Clicked(ipv4) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "server-settings-ipv4.remove-additional-ipv4.title",
      }),
      message: intl.formatMessage(
        { id: "server-settings-ipv4.remove-additional-ipv4.content" },
        {
          ip: ipv4.description,
          b: (arr) => `<b>${arr[0]}</b>`,
        }
      ),
    });

    if (state === "button2") {
      setLoading(true);
      await ajax(`/servers/removeAdditionalIpv4`, {
        userID: server.user_id,
        serverID: server._id,
        addonID: ipv4._id,
      });
      setLoading(false);
    }
  }

  async function handleAddNicClicked() {
    const mainIP = serverIps.find(
      (ipObj) => ipObj.type === "wan" && !ipObj.addon
    );

    setLoading(true);

    await ajax("/network/addNic", {
      serverID: server._id,
      type: "wan",
      ip: mainIP.ip,
      netmask: mainIP.netmask,
    });

    await fetchConfig();

    setLoading(false);
  }

  async function handleRemoveNicClicked(netNum, type) {
    const state = await confirm({
      title: intl.formatMessage({ id: "server-settings-ipv4.remove-nic" }),
      message: intl.formatMessage({
        id:
          type === "wan"
            ? "server-settings-ipv4.remove-nic.confirm-public"
            : "server-settings-ipv4.remove-nic.confirm-private",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax("/network/removeNic", { serverID: server._id, netNum });
    await fetchConfig();
    setLoading(false);
  }

  async function handleAssignToNicClicked(ip, netmask, type) {
    setLoading(true);

    if (wansKeys.length > 0) {
      await ajax("/network/removeNic", {
        serverID: server._id,
        netNum: wansKeys[0],
      });
    }

    await ajax("/network/addNic", { serverID: server._id, type, ip, netmask });
    await fetchConfig();
    setLoading(false);
  }

  function handleChangeServerGatewayModalOpen() {
    setIsChangeServerGatewayModalOpen(true);
  }

  async function handleSetRDnsMenuItemClicked(key, ip) {
    if (key) {
      const obj = proxmoxStringToObject(config[`ipconfig${key}`]);
      ip = obj.ip.split("/")[0];
    }

    await prompt({
      title: intl.formatMessage({ id: "server-settings-ipv4.set-rdns" }),
      message: intl.formatMessage(
        { id: "server-settings-ipv4.set-rdns.content" },
        { ip }
      ),
      acceptOnlyValue: (text) => ({
        status: isValidDomain(text),
        reason: intl.formatMessage({
          id: "server-settings-ipv4.set-rdns.error-domain",
        }),
      }),
      placeholder: "example.com",
      beforeClose: async (recordName) => {
        if (!recordName) {
          return;
        }

        const data = await ajax("/dns/addZoneRecord", {
          serverID: server._id,
          ip,
          recordName,
          recordType: "PTR",
        });

        if (data.result === "success") {
          //
        } else {
          await alert(
            intl.formatMessage({ id: "server-settings-ipv4.set-rdns" }),
            data.message
          );
        }
      },
    });
  }

  async function handleRemoveRDnsMenuItemClicked(key, ip) {
    const state = await confirm({
      title: intl.formatMessage({ id: "server-settings-ipv4.remove-rdns" }),
      message: intl.formatMessage({
        id: "server-settings-ipv4.remove-rdns.confirm",
      }),
    });

    if (state !== "button2") {
      return;
    }

    if (key) {
      const obj = proxmoxStringToObject(config[`ipconfig${key}`]);
      ip = obj.ip.split("/")[0];
    }

    const data = await ajax("/dns/removeZoneRecord", {
      serverID: server._id,
      ip,
    });

    if (data.result === "success") {
      //
    } else {
      await alert(
        intl.formatMessage({ id: "server-settings-ipv4.remove-rdns" }),
        data.message
      );
    }
  }

  async function handleChangeServerGatewayModalClosed(state) {
    if (state) {
      await fetchConfig();
    }

    setIsChangeServerGatewayModalOpen(false);
  }

  async function handleChangeVlanIdClicked(key) {
    await prompt({
      title: intl.formatMessage({
        id: "server-settings-ipv4.change-vlan-id",
      }),
      message: intl.formatMessage({
        id: "server-settings-ipv4.change-vlan-id.content",
      }),
      textType: "number",
      beforeClose: async (tag) => {
        if (!validator.isNumeric(tag)) {
          return;
        }

        await ajax("/proxmox/nodes/qemu/setLanTag", {
          serverID: server._id,
          lanNum: key,
          tag,
        });

        await fetchConfig();
      },
    });
  }

  function renderCustomMenu(type, key) {
    const arr = type === "wan" ? wans : lans;

    return (
      <CustomMenu
        isOpen={openDropdowns[`${type}_${key}`]}
        toggle={() => handleMenuToggle(type, key)}
      >
        {server && isAllowed("servers.rdns") && type === "wan" && (
          <>
            {(!server.rdns || !server.rdns[renderAddress(key)]) && (
              <CustomMenuItem
                disabled={server?.isWorking}
                onClick={() => handleSetRDnsMenuItemClicked(key)}
              >
                <FormattedMessage id="server-settings-ipv4.set-rdns" />
              </CustomMenuItem>
            )}
            {server.rdns && server.rdns[renderAddress(key)] && (
              <CustomMenuItem
                disabled={server?.isWorking}
                onClick={() => handleRemoveRDnsMenuItemClicked(key)}
              >
                <FormattedMessage id="server-settings-ipv4.remove-rdns" />
              </CustomMenuItem>
            )}
          </>
        )}

        {isAllowed("servers.firewall") && (
          <>
            {!arr[key].includes("firewall=1") && (
              <CustomMenuItem
                disabled={server?.isWorking}
                onClick={() => setNetData(key, { firewall: "1" })}
              >
                <FormattedMessage id="server-settings-ipv4.enable-firewall" />
              </CustomMenuItem>
            )}
            {arr[key].includes("firewall=1") && (
              <CustomMenuItem
                disabled={server?.isWorking}
                onClick={() => setNetData(key, { firewall: "0" })}
              >
                <FormattedMessage id="server-settings-ipv4.disable-firewall" />
              </CustomMenuItem>
            )}
          </>
        )}

        {arr[key].includes("link_down=1") && (
          <CustomMenuItem
            disabled={server?.isWorking}
            onClick={() => setNetData(key, { link_down: "0" })}
          >
            <FormattedMessage id="server-settings-ipv4.connect-nic" />
          </CustomMenuItem>
        )}
        {!arr[key].includes("link_down=1") && (
          <CustomMenuItem
            disabled={server?.isWorking}
            onClick={() => setNetData(key, { link_down: "1" })}
          >
            <FormattedMessage id="server-settings-ipv4.disconnect-nic" />
          </CustomMenuItem>
        )}

        {isAllowed("networks.vlan") &&
          isAllowed("networks.subnet") &&
          isAllowed("networks.ips") && (
            <>
              <CustomMenuItem
                disabled={server?.isWorking}
                onClick={() => handleRemoveNicClicked(key, type)}
              >
                <FormattedMessage id="server-settings-ipv4.remove-nic" />
              </CustomMenuItem>

              {user.role === SUPER_ADMIN &&
                isAllowed("super-admin.servers.manage") && (
                  <CustomMenuItem
                    disabled={server?.isWorking}
                    onClick={() => handleChangeVlanIdClicked(key, type)}
                  >
                    <FormattedMessage id="server-settings-ipv4.change-vlan-id" />
                  </CustomMenuItem>
                )}
            </>
          )}
      </CustomMenu>
    );
  }

  function renderAddress(wanKey) {
    return ipconfigs[wanKey]
      ? ipconfigs[wanKey].match(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/g)[0]
      : "";
  }

  function renderNetmask(key) {
    const obj = proxmoxStringToObject(ipconfigs[key]);

    return obj ? obj.netmask : "-";
  }

  function renderTag(str) {
    const obj = proxmoxStringToObject(str);

    return obj.tag;
  }

  const lansKeys = useMemo(() => Object.keys(lans), [lans]);

  const wansKeys = useMemo(() => Object.keys(wans), [wans]);

  const mainIP = useMemo(() => {
    const obj = proxmoxStringToObject(config[`ipconfig${wansKeys[0]}`]);

    return obj?.ip.split("/")[0];
  }, [config, wansKeys]);

  const serverIpsFiltered = useMemo(
    () =>
      serverIps.filter((ipObj) => ipObj.type === "wan" && ipObj.ip !== mainIP),
    [mainIP, serverIps]
  );

  return (
    <div className={styles.wrapper}>
      <Box className={styles.box}>
        <div className={styles.title}>
          <FormattedMessage id="server-settings-ipv4.public-network" />
        </div>
        <div>
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-settings-ipv4.address" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.firewall" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.link-status" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.netmask" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.reverse-dns" />
                </th>
                <th style={{ width: "45px" }}></th>
              </tr>
            </thead>
            <tbody>
              {(!config || wansKeys.length === 0) && (
                <tr>
                  <td>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {config &&
                wansKeys.map((k) => (
                  <tr key={k}>
                    <td>
                      <div className={styles.ip}>
                        <div>{renderAddress(k)}</div>
                        <div className={styles.ipBadge}>Main IP</div>
                      </div>
                    </td>
                    <td>
                      {wans[k].includes("firewall=1") ? (
                        <CheckSvg className={styles.check} />
                      ) : (
                        <CancelSvg />
                      )}
                    </td>
                    <td>
                      {wans[k].includes("link_down=1") ? (
                        <CancelSvg />
                      ) : (
                        <CheckSvg className={styles.check} />
                      )}
                    </td>
                    <td>{renderNetmask(k)}</td>
                    <td>
                      {server?.rdns ? server.rdns[renderAddress(k)] : "-"}
                    </td>
                    <td>{renderCustomMenu("wan", k)}</td>
                  </tr>
                ))}
            </tbody>
          </BasicTable>
        </div>

        {isAllowed("networks.vlan") &&
          isAllowed("networks.subnet") &&
          isAllowed("networks.ips") && (
            <div className={styles.buttonsWrapper}>
              {wansKeys.length === 0 && (
                <IconButton
                  disabled={loading}
                  color="light-purple"
                  onClick={() => handleAddNicClicked()}
                >
                  <FormattedMessage id="server-settings-ipv4.add-public-network-card" />
                </IconButton>
              )}
            </div>
          )}
      </Box>

      <Box className={styles.box}>
        <div className={styles.title}>
          <FormattedMessage id="server-settings-ipv4.private-network" />
        </div>

        {lansKeys.length > 0 && (
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-settings-ipv4.address" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.firewall" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.link-status" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.netmask" />
                </th>
                {user.role === SUPER_ADMIN && (
                  <th>
                    <FormattedMessage id="server-settings-ipv4.vlan-id" />
                  </th>
                )}
                <th style={{ width: "45px" }}></th>
              </tr>
            </thead>
            <tbody>
              {!config ||
                (lansKeys.length === 0 && (
                  <tr>
                    <td>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                ))}

              {config &&
                lansKeys.map((k) => (
                  <tr key={k}>
                    <td>{renderAddress(k)}</td>
                    <td>
                      {lans[k].includes("firewall=1") ? (
                        <CheckSvg className={styles.check} />
                      ) : (
                        <CancelSvg />
                      )}
                    </td>
                    <td>
                      {lans[k].includes("link_down=1") ? (
                        <CancelSvg />
                      ) : (
                        <CheckSvg className={styles.check} />
                      )}
                    </td>
                    <td>{renderNetmask(k)}</td>
                    {user.role === SUPER_ADMIN && <td>{renderTag(lans[k])}</td>}
                    <td>{renderCustomMenu("lan", k)}</td>
                  </tr>
                ))}
            </tbody>
          </BasicTable>
        )}

        {lansKeys.length === 0 && (
          <div className={styles.gray}>
            <FormattedMessage id="server-settings-ipv4.no-network" />
          </div>
        )}

        {isAllowed("networks.vlan") &&
          isAllowed("networks.subnet") &&
          isAllowed("networks.ips") && (
            <div className={styles.buttonsWrapper}>
              {lansKeys.length < 10 && (
                <IconButton
                  disabled={loading || server?.isWorking}
                  color="light-purple"
                  onClick={() => setIsPrivateNetworkingModalOpen(true)}
                >
                  <FormattedMessage id="server-settings-ipv4.add-private-network-card" />
                </IconButton>
              )}
            </div>
          )}
      </Box>

      <Box className={styles.box}>
        <div className={styles.row}>
          <div className={styles.infoSvg} id="tooltip-change-gateway">
            <InfoSvg />
          </div>

          <Tooltip
            placement="bottom"
            isOpen={isChangeGatewayTooltipOpen}
            target="tooltip-change-gateway"
            toggle={() =>
              setIsChangeGatewayTooltipOpen(!isChangeGatewayTooltipOpen)
            }
          >
            <div>
              <FormattedMessage id="server-settings-ipv4.gateway-info" />
            </div>
          </Tooltip>

          <span>
            <FormattedMessage id="server-settings-ipv4.gateway" />
          </span>
          {gateway || "-"}

          {isAllowed("servers.change-gateway") && (
            <IconButton
              disabled={server?.isWorking}
              color="text"
              onClick={handleChangeServerGatewayModalOpen}
            >
              <FormattedMessage id="general.change" />
            </IconButton>
          )}
        </div>
        <div className={styles.row}>
          <div className={styles.infoSvg} id="tooltip-change-dns">
            <InfoSvg />
          </div>

          <Tooltip
            placement="bottom"
            isOpen={isChangeDnsTooltipOpen}
            target="tooltip-change-dns"
            toggle={() => setIsChangeDnsTooltipOpen(!isChangeDnsTooltipOpen)}
          >
            <div>
              <FormattedMessage id="server-settings-ipv4.dns-info" />
            </div>
          </Tooltip>

          <span>
            <FormattedMessage id="server-settings-ipv4.dns" />
          </span>
          {dns}

          {isAllowed("servers.change-dns") && (
            <IconButton
              disabled={server?.isWorking}
              color="text"
              onClick={handleChangeDnsClicked}
            >
              <FormattedMessage id="general.change" />
            </IconButton>
          )}
        </div>
      </Box>

      <Box className={styles.box}>
        <div className={styles.title}>
          <FormattedMessage id="server-settings-ipv4.unassigned-ipv4" />
        </div>
        <div>
          <BasicTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-settings-ipv4.ip" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.monthly-cost" />
                </th>
                <th>
                  <FormattedMessage id="server-settings-ipv4.reverse-dns" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {serverIpsFiltered.length === 0 && (
                <tr>
                  <td colSpan={4}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {serverIpsFiltered
                .filter((ipObj) => ipObj.type === "wan" && ipObj.ip !== mainIP)
                .map((ipObj, key) => (
                  <tr key={key}>
                    <td>{ipObj.ip}</td>
                    <td>
                      {ipObj.addon
                        ? `${
                            currencySymbols[ipObj.addon.currency]
                          }${ipObj.addon.price.toFixed(2)}`
                        : `0$ (${intl.formatMessage({
                            id: "general.main-ip",
                          })})`}
                    </td>
                    <td>{server?.rdns ? server.rdns[ipObj.ip] : "-"}</td>
                    <td>
                      <CustomMenu
                        isOpen={!!isIpv4DropdownOpen[ipObj.ip]}
                        toggle={() => handleIpv4DropdownOpenToggle(ipObj)}
                      >
                        <CustomMenuItem
                          onClick={() =>
                            handleAssignToNicClicked(
                              ipObj.ip,
                              ipObj.netmask,
                              "wan"
                            )
                          }
                        >
                          <FormattedMessage
                            id={
                              wansKeys.length > 0
                                ? "server-settings-ipv4.swap-with-nic"
                                : "server-settings-ipv4.assign-to-nic"
                            }
                          />
                        </CustomMenuItem>
                        {server && isAllowed("servers.rdns") && (
                          <>
                            {(!server.rdns || !server.rdns[ipObj.ip]) && (
                              <CustomMenuItem
                                onClick={() =>
                                  handleSetRDnsMenuItemClicked(null, ipObj.ip)
                                }
                              >
                                <FormattedMessage id="server-settings-ipv4.set-rdns" />
                              </CustomMenuItem>
                            )}
                            {server.rdns && server.rdns[ipObj.ip] && (
                              <CustomMenuItem
                                onClick={() =>
                                  handleRemoveRDnsMenuItemClicked(
                                    null,
                                    ipObj.ip
                                  )
                                }
                              >
                                <FormattedMessage id="server-settings-ipv4.remove-rdns" />
                              </CustomMenuItem>
                            )}
                          </>
                        )}
                        {ipObj.addon && (
                          <CustomMenuItem
                            onClick={() => handleRemoveIpv4Clicked(ipObj.addon)}
                          >
                            <FormattedMessage id="general.remove" />
                          </CustomMenuItem>
                        )}
                      </CustomMenu>
                    </td>
                  </tr>
                ))}
            </tbody>
          </BasicTable>
        </div>
        <div className={styles.additionalIpsInfoBox}>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "server-settings-ipv4.row1",
              }),
            }}
          ></div>
          <div>
            <FormattedMessage id="server-settings-ipv4.row2" />
          </div>
        </div>
        {isAllowed("servers.add-additional-ipv4") && (
          <div>
            <IconButton
              disabled={loading || server?.isWorking}
              color="light-purple"
              onClick={handleAddAdditionalIpv4ModalOpen}
            >
              <FormattedMessage id="server-settings-ipv4.add-public-ipv4" />
            </IconButton>
          </div>
        )}
      </Box>

      <PrivateNetworkingModal
        server={server}
        isOpen={isPrivateNetworkingModalOpen}
        onClose={handlePrivateNetworkingModalClosed}
      />

      <AddAdditionalIpv4Modal
        isOpen={isAddAdditionalIpv4ModalOpen}
        onClose={handleAddAdditionalIpv4ModalClosed}
        server={server}
      />

      <ChangeServerGatewayModal
        isOpen={isChangeServerGatewayModalOpen}
        onClose={handleChangeServerGatewayModalClosed}
        server={server}
        gateway={gateway}
      />
    </div>
  );
}
