import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useUser } from "../../utils/hooks";
import { SUPER_ADMIN, WHITELABEL } from "../../utils/user";
import Box from "../box";
import IconButton from "../icon-button";
import UserSelectorModal from "../modals/user-selector";

function UserSelectorLayout({
  className,
  userIDToWork,
  setUserIDToWork,
  children,
}) {
  const user = useUser();

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const label = urlSearchParams.get("userlabel");
    const value = urlSearchParams.get("userid");

    if (!label || !value) {
      return;
    }

    setUserIDToWork({ label, value });
  }, [setUserIDToWork]);

  function handleUserSelectorModalOpen() {
    setIsUserSelectorModalOpen(true);
  }

  function handleUserSelectorModalClosed(user) {
    setUserIDToWork(user);
    setIsUserSelectorModalOpen(false);
  }

  if (![SUPER_ADMIN, WHITELABEL].includes(user.role)) {
    return children;
  }

  return (
    <>
      <Box className={className}>
        <IconButton color="light-purple" onClick={handleUserSelectorModalOpen}>
          {userIDToWork?.label || user.email}
        </IconButton>
      </Box>

      {children}

      <UserSelectorModal
        isOpen={isUserSelectorModalOpen}
        onClose={handleUserSelectorModalClosed}
      />
    </>
  );
}

UserSelectorLayout.propTypes = {
  className: PropTypes.string,
  userIDToWork: PropTypes.object,
  setUserIDToWork: PropTypes.func,
  children: PropTypes.any,
};

export default UserSelectorLayout;
