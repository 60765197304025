import io from "socket.io-client";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertModalData,
  setConfirmModalData,
  setPromptModalData,
  setTotalServers,
} from "../store/settings";
import { useCallback, useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { updateServers } from "../store/servers";
import { updateUserDetails } from "../store/user";
import { SUPER_ADMIN, USER, WHITELABEL } from "./user";
import { getTotalActiveServers } from "./servers";
import { addSeconds } from "date-fns";
import { getHostOfWLDVPS, getServerUrl, getWebSocketUrl } from "./wldvps";
import { getSocket, setSocket } from "./globals";
import { UPDATE_TASKS } from "../store/tasks";
import { SET_NOTIFICATIONS } from "../store/notifications";

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useLang = () => {
  return "en";
};

export const useLogout = () => {
  const lang = useLang();
  const ajax = useAjax();

  return async () => {
    await ajax(`/users/logout`, {});

    location.href = `/${lang}/login`;
  };
};

export const useAlert = () => {
  const dispatch = useDispatch();

  return (title, message, button) => {
    return new Promise((resolve) => {
      dispatch(
        setAlertModalData({
          isOpen: true,
          title,
          message,
          button,
          resolve,
        })
      );
    });
  };
};

export const useConfirm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return ({
    title,
    message,
    button1 = {
      text: intl.formatMessage({ id: "general.cancel" }),
      color: "white",
    },
    button2 = {
      text: intl.formatMessage({ id: "general.yes" }),
      color: "white",
    },
    beforeClose = null,
  }) => {
    return new Promise((resolve) => {
      dispatch(
        setConfirmModalData({
          isOpen: true,
          title,
          message,
          button1,
          button2,
          resolve,
          beforeClose,
        })
      );
    });
  };
};

export const usePrompt = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return ({
    title,
    message,
    defaultText = "",
    button1 = {
      text: intl.formatMessage({ id: "general.cancel" }),
      color: "white",
    },
    button2 = {
      text: intl.formatMessage({ id: "general.ok" }),
      color: "white",
    },
    acceptOnlyValue,
    textType = "text",
    beforeClose = null,
    placeholder = null,
  }) => {
    return new Promise((resolve) => {
      dispatch(
        setPromptModalData({
          isOpen: true,
          title,
          message,
          defaultText,
          button1,
          button2,
          resolve,
          acceptOnlyValue,
          textType,
          beforeClose,
          placeholder,
        })
      );
    });
  };
};

export const useAjax = () => {
  const ajax = useCallback(async (url, params = {}, config = {}) => {
    try {
      const { data } = await axios.post(`${getServerUrl()}${url}`, params, {
        ...config,
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return {
        result: "error",
        message: "server-connection-failed",
        err,
      };
    }
  }, []);

  return ajax;
};

const serversCache = {};

export const useServer = () => {
  const { id } = useParams();
  const ajax = useAjax();
  const socket = getSocket();

  const [server, setServer] = useState(null);

  const isUnmounted = useRef(false);

  const getServer = useCallback(async () => {
    if (!id || isUnmounted.current) {
      return;
    }

    let data;
    if (serversCache[id] && new Date() <= serversCache[id].expire) {
      setServer(serversCache[id].server);
    } else {
      data = await ajax("/servers/get", {
        serverID: id,
      });

      if (data.server?.rdns) {
        Object.keys(data.server.rdns).forEach((key) => {
          data.server.rdns[key.replace(/_/g, ".")] = data.server.rdns[key];
        });
      }

      serversCache[id] = {
        server: data.server,
        expire: addSeconds(new Date(), 1),
      };

      setServer(data.server);
    }
  }, [ajax, id]);

  const handleServerUpdated = useCallback(() => {
    getServer();
  }, [getServer]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("update-server", handleServerUpdated);

    getServer();

    return () => {
      isUnmounted.current = true;

      socket.off("update-server", handleServerUpdated);
    };
  }, [getServer, socket, handleServerUpdated]);

  return server;
};

export const useServerStatus = (serverID) => {
  const ajax = useAjax();

  const [serverStatus, setServerStatus] = useState(null);

  const socketListen = useCallback(async () => {
    if (!serverID) {
      return;
    }

    await ajax("/socket/listen", {
      method: "server-status",
      params: { serverID },
    });
  }, [ajax, serverID]);

  const socketStop = useCallback(async () => {
    if (!serverID) {
      return;
    }

    await ajax("/socket/stop", {
      method: "server-status",
      params: { serverID },
    });
  }, [ajax, serverID]);

  useEffect(() => {
    if (!serverID) {
      return;
    }

    const socket = getSocket();

    socketListen(serverID);

    socket.on("server-status", (data) => {
      setServerStatus(data.status);
    });

    return () => {
      socketStop(serverID);

      socket.removeAllListeners("server-status");
    };
  }, [serverID, socketListen, socketStop]);

  return { serverStatus };
};

export const useServers = () => {
  const { servers } = useSelector(
    (state) => ({
      servers: state.servers,
    }),
    (prev, next) =>
      JSON.stringify(prev.servers) === JSON.stringify(next.servers)
  );

  return servers;
};

export const useTasks = () => {
  const { tasks } = useSelector((state) => ({
    tasks: state.tasks,
  }));

  return tasks;
};

export const useUser = () => {
  const { user } = useSelector(
    (state) => ({ user: state.user }),
    (prev, next) => prev.user._id === next.user._id
  );

  return user;
};

export const useUrls = () => {
  const wldvps = useWLDVPS();

  const url = getHostOfWLDVPS(wldvps);

  return {
    IL_CONSOLE_URL: `https://ilconsole.${url}`,
    NL_CONSOLE_URL: `https://nlconsole.${url}`,
    US_CONSOLE_URL: `https://usconsole.${url}`,
  };
};

export const useInitialDataFetching = () => {
  const ajax = useAjax();
  const dispatch = useDispatch();
  const { connect } = useWebSocket();

  return async (connectToSocket = false) => {
    const data = await ajax(`/initialData`);

    if (data.result === "success") {
      dispatch(updateUserDetails(data));

      if (connectToSocket) {
        connect();
      }

      return true;
    }

    return false;
  };
};

export const useRoles = () => {
  const { user } = useSelector(
    (state) => ({ user: state.user }),
    (prev, next) => prev.user._id === next.user._id
  );

  const isAllowed = (role) => {
    if (!user.current_parent) {
      if (user.role === WHITELABEL && role && role.startsWith("super-admin.")) {
        return false;
      }

      if (
        user.role === USER &&
        role &&
        (role.startsWith("super-admin.") || role.startsWith("admin."))
      ) {
        return false;
      }

      return true;
    }

    const roles = user.roles.find(
      (r) => r.parent_user_id === user.current_parent
    );

    if (!roles) {
      return true;
    }

    return roles.roles[role];
  };

  return { isAllowed };
};

export const useLimits = () => {
  const user = useUser();
  const servers = useServers();

  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  const [serversLimit, setServersLimit] = useState(false);

  useEffect(() => {
    if ([SUPER_ADMIN, WHITELABEL].includes(user.role)) {
      setServersLimit(Number.MAX_SAFE_INTEGER);
    } else if (servers) {
      setServersLimit(
        user.serversLimit - getTotalActiveServers(servers, tasks)
      );
    }
  }, [servers, tasks, user.serversLimit, user.role]);

  return { serversLimit };
};

export const useTraceUpdate = (props) => {
  const prev = useRef(props);

  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});

    if (Object.keys(changedProps).length > 0) {
      console.info("Changed props:", changedProps);
    }

    prev.current = props;
  });
};

export const useNotifications = () => {
  const { notifications } = useSelector(
    (state) => ({
      notifications: state.notifications,
    }),
    (prev, next) =>
      JSON.stringify(prev.notifications) === JSON.stringify(next.notifications)
  );

  return notifications;
};

export const useWLDVPS = () => {
  const { wldvps } = useSelector((state) => ({
    wldvps: state.settings.wldvps,
  }));

  return wldvps;
};

export const useWebSocket = () => {
  const dispatch = useDispatch();
  const { serversFetching } = useSelector((state) => ({
    serversFetching: state.settings.serversFetching,
  }));

  const logout = useLogout();
  const logoutRef = useRef(logout);

  let timerID = useRef();
  let lastNotificationID = useRef();

  const connect = useCallback(() => {
    const socket = io(getWebSocketUrl(), {
      withCredentials: true,
    });

    socket.on("servers-update", (data) => {
      dispatch(updateServers(data.servers));
      dispatch(setTotalServers(data.totalServers));
    });

    socket.on("tasks-update", (data) => {
      data.tasks.forEach((task) => {
        if (task.custom_prec > 0) {
          task.step = task.custom_prec;
          task.totalSteps = 100;
        }
      });

      dispatch({
        type: UPDATE_TASKS,
        payload: { data: data.tasks, lastRequest: +new Date() },
      });

      if (
        data.notifications &&
        data.notifications[0] &&
        lastNotificationID.current !== data.notifications[0]._id
      ) {
        lastNotificationID.current = data.notifications[0]._id;

        dispatch({
          type: SET_NOTIFICATIONS,
          payload: data.notifications,
        });
      }
    });

    socket.on("auto-logout", () => {
      logoutRef.current();
    });

    setSocket(socket);
  }, [dispatch]);

  useEffect(() => {
    if (!serversFetching) {
      return;
    }

    const socket = getSocket();

    if (socket) {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }

      timerID.current = setTimeout(() => {
        socket.emit("update-filters", {
          page: serversFetching.page,
          maxServersToShow: serversFetching.maxServersToShow,
          totalServers: serversFetching.totalServers,
          filter: serversFetching.filter,
          showOnly: serversFetching.showOnly,
          group: serversFetching.group,
        });
      }, 100);
    }
  }, [serversFetching]);

  return { connect };
};
