import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import SVG from "../svgs";
import styles from "./index.module.scss";

function TextField({ icon, value, onChange, type, placeholder }) {
  return (
    <div className={styles.textFieldWrapper}>
      <SVG icon={icon} />
      <Input
        className={styles.textField}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
      />
    </div>
  );
}

TextField.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextField;
