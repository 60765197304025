import styles from "./two-factor-authentication.module.scss";

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useAjax,
  useAlert,
  useConfirm,
  useInitialDataFetching,
  useUser,
} from "../../utils/hooks";
import IconButton from "../icon-button";
import TwoFactorAuthenticationModal from "../modals/two-factor-authentication";

export default function TwoFactorAuthentication() {
  const user = useUser();
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();
  const confirm = useConfirm();
  const initialDataFetching = useInitialDataFetching();

  const [
    isTwoFactorAuthenticationModalOpen,
    setIsTwoFactorAuthenticationModalOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  async function handleDisableClicked() {
    const state = await confirm({
      title: intl.formatMessage({
        id: "two-factor-authentication.disable.title",
      }),
      message: intl.formatMessage({
        id: "two-factor-authentication.disable.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax("/users/disable2FA");

    await initialDataFetching();
    setLoading(false);
  }

  function handleTwoFactorAuthenticationModalOpen() {
    setIsTwoFactorAuthenticationModalOpen(true);
  }

  async function handleTwoFactorAuthenticationModalClosed(state) {
    if (state) {
      await alert(
        intl.formatMessage({ id: "two-factor-authentication.add-new-device" }),
        intl.formatMessage({
          id: "two-factor-authentication.add-new-device-successfully",
        })
      );
    }

    setIsTwoFactorAuthenticationModalOpen(false);
  }

  if (!user._id) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {user.twoFA.activated ? (
        <>
          <div className={styles.buttonsWrapper}>
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleDisableClicked}
            >
              <FormattedMessage id="general.disable" />
            </IconButton>
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleTwoFactorAuthenticationModalOpen}
            >
              <FormattedMessage id="two-factor-authentication.add-new-device" />
            </IconButton>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: "two-factor-authentication.total-devices",
                },
                {
                  number: user.twoFA.activated_devices,
                  b: (val) => `<b>${val}</b>`,
                }
              ),
            }}
          ></div>
        </>
      ) : (
        <IconButton
          disabled={loading}
          color="light-purple"
          onClick={handleTwoFactorAuthenticationModalOpen}
        >
          <FormattedMessage id="general.enable" />
        </IconButton>
      )}

      <TwoFactorAuthenticationModal
        isOpen={isTwoFactorAuthenticationModalOpen}
        onClose={handleTwoFactorAuthenticationModalClosed}
      />
    </div>
  );
}
