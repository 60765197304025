import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax } from "../../../utils/hooks";
import CustomReactSelect from "../../custom-react-select";
import Checkbox from "../../checkbox";
import CustomText from "../../custom-text";

function EditIpAddressModal({
  subnet,
  ipObject,
  isOpen,
  onClose,
  userIDToWork,
  type,
}) {
  const ajax = useAjax();
  const intl = useIntl();

  const [domains, setDomains] = useState([]);
  const [serverID, setServerID] = useState(false);
  const [applyInProxmox, setApplyInProxmox] = useState(false);
  const [removeOtherConnectedIps, setRemoveOtherConnectedIps] = useState(false);
  const [reserveName, setReserveName] = useState("");

  useEffect(() => {
    if (isOpen) {
      if (ipObject && domains) {
        if (ipObject.reserve_name) {
          setServerID(domains[0]);
          setReserveName(ipObject.reserve_name);
        } else {
          const domain = domains.find((d) => d.value === ipObject.server_id);

          if (domain) {
            setServerID(domain);
          } else {
            setServerID(null);
          }

          setReserveName("");
        }
      }

      setApplyInProxmox(false);
      setRemoveOtherConnectedIps(false);
    }
  }, [isOpen, ipObject, domains]);

  const getAllDomains = useCallback(async () => {
    const data = await ajax("/servers/getAllDomainsUserToWork", {
      userIDToWork: userIDToWork?.value,
      onlyActive: true,
    });

    if (data.result === "success") {
      const domains = data.domains.map((domain) => ({
        label: domain.hostname,
        value: domain._id,
      }));

      if (type === "wan") {
        domains.unshift({
          label: intl.formatMessage({ id: "edit-ip-address-modal.reserve" }),
          value: -2,
        });
      }

      setDomains(domains);
    }
  }, [ajax, userIDToWork?.value, type, intl]);

  useEffect(() => {
    getAllDomains();
  }, [getAllDomains]);

  async function handleUpdateClicked() {
    if (!serverID) {
      return;
    }

    const _removeOtherConnectedIps =
      serverID.value === -2 ? true : removeOtherConnectedIps;

    await ajax("/network/attachIpToServer", {
      applyInProxmox,
      ip: ipObject.ip,
      serverID: serverID.value,
      removeOtherConnectedIps: _removeOtherConnectedIps,
      reserveName: serverID.value === -2 ? reserveName : null,
      subnetID: subnet._id,
      type,
      userIDToWork: userIDToWork?.value,
    });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="edit-ip-address-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <label>
            <span>
              <FormattedMessage id="edit-ip-address-modal.ip-address" />
            </span>
            {ipObject?.ip}
          </label>
        </div>

        <div className={styles.row}>
          <label>
            <span>
              <FormattedMessage id="edit-ip-address-modal.server" />
            </span>
            <CustomReactSelect
              value={serverID}
              onChange={(item) => setServerID(item)}
              options={domains}
            />
          </label>
        </div>

        {serverID?.value === -2 && (
          <div className={styles.row}>
            <label>
              <span>
                <FormattedMessage id="edit-ip-address-modal.reserve-name" />
              </span>
              <CustomText
                value={reserveName}
                onChange={(e) => setReserveName(e.target.value)}
              />
            </label>
          </div>
        )}

        {type === "wan" && serverID?.value !== -2 && (
          <>
            <div className={styles.row}>
              <label>
                <span>
                  <FormattedMessage id="edit-ip-address-modal.apply-in-proxmox" />
                </span>
                <Checkbox
                  checked={applyInProxmox}
                  onChange={(e) => setApplyInProxmox(e.target.checked)}
                />
              </label>
            </div>
            <div className={styles.row}>
              <label>
                <span>
                  <FormattedMessage id="edit-ip-address-modal.remove-other-connected-ip" />
                </span>
                <Checkbox
                  checked={removeOtherConnectedIps}
                  onChange={(e) => setRemoveOtherConnectedIps(e.target.checked)}
                />
              </label>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={handleUpdateClicked}>
          <FormattedMessage id="general.update" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

EditIpAddressModal.propTypes = {
  subnet: PropTypes.object,
  ipObject: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userIDToWork: PropTypes.object,
  type: PropTypes.oneOf(["lan", "wan"]),
};

export default EditIpAddressModal;
