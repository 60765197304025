import styles from "./index.module.scss";

import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import { useLang, useAjax } from "../../../utils/hooks";
import LoginLayout from "../../../components/login-layout";
import TextField from "../../../components/textfield";
import IconButton from "../../../components/icon-button";
import KeyDetector from "../../../components/key-detector";
import {} from "../../../utils";

export default function ResetPasswordToken() {
  const intl = useIntl();
  const lang = useLang();
  const router = useHistory();
  const ajax = useAjax();

  const [token, setToken] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");

    if (token) {
      setToken(token);
    } else {
      router.push(`/${lang}/login`);
    }
  }, [lang, router]);

  async function handleResetClicked() {
    const _password = password.trim();
    const _passwordConfirm = passwordConfirm.trim();

    setError(false);

    if (passwordScore <= 1) {
      return setError("general.weak-password");
    } else if (_password !== _passwordConfirm) {
      return setError("general.password-mismatch");
    }

    setLoading(true);
    const data = await ajax(`/users/setPassword`, {
      resetPasswordCode: token,
      newPassword: password,
    });
    setLoading(false);

    if (data.result === "success") {
      router.push(`/${lang}/login?after_set_password=1`);
    } else {
      setError("general.invalid-token");
    }
  }

  return (
    <LoginLayout>
      <div className={styles.forgotPassword}>
        <div className={`mb16 ${styles.bold}`}>
          <FormattedMessage id="general.reset-password" />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleResetClicked}>
          <TextField
            type="password"
            icon="lock"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={intl.formatMessage({
              id: "reset-password.new-password",
            })}
          />
        </KeyDetector>
        <KeyDetector className="mb28" onKeyDetected={handleResetClicked}>
          <TextField
            type="password"
            icon="lock"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            placeholder={intl.formatMessage({
              id: "reset-password.confirm-new-password",
            })}
          />
        </KeyDetector>
        <div>
          <PasswordStrengthBar
            password={password}
            onChangeScore={setPasswordScore}
          />
        </div>
        {error && (
          <div className={`mb28 ${styles.errorWrapper}`}>
            <FormattedMessage id={error} />
          </div>
        )}
        <div className="mb28">
          <IconButton
            disabled={loading}
            icon="arrow"
            onClick={handleResetClicked}
            color="purple"
            className={styles.forgotPasswordButton}
          >
            <FormattedMessage id="reset-password.reset" />
          </IconButton>
        </div>
        <div className={styles.backWrapper}>
          <Link href={`/[lang]/login`} to={`/${lang}/login`}>
            <FormattedMessage id="reset-password.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
