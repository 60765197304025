import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import { validateIPaddress } from "../../../utils/networks";
import CustomReactSelect from "../../custom-react-select";

function AssignIpToServerModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const intl = useIntl();

  const [domains, setDomains] = useState([]);
  const [ip, setIp] = useState("");
  const [domain, setDomain] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIp("");
      setDomain(false);
      setError(false);
      setLoading(false);
    }
  }, [isOpen]);

  const getAllServers = useCallback(async () => {
    const data = await ajax(`/servers/getAllDomains`);

    if (data.result === "success") {
      setDomains(
        data.domains.map((d, idx) => ({
          label: d.hostname,
          value: d._id,
          key: idx,
        }))
      );
    }
  }, [ajax]);

  useEffect(() => {
    getAllServers();
  }, [getAllServers]);

  async function handleAssignClicked() {
    setError(false);

    if (!validateIPaddress(ip)) {
      return setError(intl.formatMessage({ id: "externals-ips.wrong-ip" }));
    } else if (!domain) {
      return setError(intl.formatMessage({ id: "externals-ips.no-server" }));
    }

    setLoading(true);
    const data = await ajax(`/network/assignExternalIp`, {
      serverID: domain.value,
      ip,
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      setError(intl.formatMessage({ id: "externals-ips.assign.error" }));
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="assign-ip-to-server-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="assign-ip-to-server-modal.ip" />
          </span>
          <CustomText value={ip} onChange={(e) => setIp(e.target.value)} />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="assign-ip-to-server-modal.server" />
          </span>
          <CustomReactSelect
            value={domain}
            onChange={(item) => setDomain(item)}
            options={domains}
          />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAssignClicked}
        >
          <FormattedMessage id="general.assign" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AssignIpToServerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AssignIpToServerModal;
