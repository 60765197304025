import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchSvg } from "../svgs/search.svg";

function CustomText({
  className,
  type = "text",
  value,
  onChange,
  placeholder,
  disabled,
  icon = false,
  iconPosition = "end",
  maxLength = null,
  readOnly = false,
  autoComplete = null,
  autoCorrect = null,
  spellCheck = null,
  max = null,
  min = null,
}) {
  function renderIcon() {
    switch (icon) {
      case "search":
        return (
          <SearchSvg
            className={`${styles.floatingIcon} ${styles[iconPosition]}`}
          />
        );
      default:
        return null;
    }
  }

  if (type === "textarea") {
    return (
      <div className={styles.wrapper}>
        <textarea
          disabled={disabled}
          placeholder={placeholder}
          className={`${styles.customTextWrapper} ${styles[type]}`}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          readOnly={readOnly}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.wrapper} ${className || ""}`}>
      <input
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        className={`${styles.customTextWrapper} ${styles[type]} ${styles[iconPosition]}`}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        readOnly={readOnly}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        spellCheck={spellCheck}
        min={min}
        max={max}
      />

      {renderIcon()}
    </div>
  );
}

CustomText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "textarea", "number"]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  maxLength: PropTypes.number,
  iconPosition: PropTypes.oneOf(["start", "end"]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  spellCheck: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default CustomText;
