import styles from "./groups.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import HeaderWithNavs from "../../../components/cloud-layout/headers-with-navs";
import Box from "../../../components/box";
import BasicTable from "../../../components/basic-table";
import IconButton from "../../../components/icon-button";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import { useAjax, useConfirm, usePrompt, useUser } from "../../../utils/hooks";
import ServersSelectorModal from "../../../components/modals/servers-selector";
import Spinner from "../../../components/spinner";
import { WithRole } from "../../../components/with-role";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";
import UserSelectorModal from "../../../components/modals/user-selector";
import {} from "../../../utils";

function MyCloudGroups({ groupsInitial }) {
  const intl = useIntl();
  const prompt = usePrompt();
  const confirm = useConfirm();
  const ajax = useAjax();
  const user = useUser();

  const [groups, setGroups] = useState(groupsInitial);
  const [selectedGroup, setSelectedGroup] = useState(false);
  const [selectedServers, setSelectedServers] = useState({});
  const [isServersSelectorModalOpen, setIsServersSelectorModalOpen] = useState(
    false
  );

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);
  const [userIDToWork, setUserIDToWork] = useState(null);

  const getGroups = useCallback(async () => {
    const data = await ajax("/groups/getAll", {
      userIDToWork: userIDToWork?.value,
    });

    if (data.result === "success") {
      setGroups(data.groups);
    }
  }, [ajax, userIDToWork?.value]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  function handleMenuClicked(item) {
    item.isMenuOpen = !item.isMenuOpen;
    setGroups([...groups]);
  }

  async function handleAddGroupClicked() {
    let name = await prompt({
      title: intl.formatMessage({ id: "groups.add.title" }),
      message: intl.formatMessage({ id: "groups.add.content" }),
    });

    name = name ? name.trim() : false;

    if (!name) {
      return;
    }

    await ajax("/groups/create", { userIDToWork: userIDToWork?.value, name });

    await getGroups();
  }

  async function handleRemoveGroupClicked(group) {
    const state = await confirm({
      title: intl.formatMessage({ id: "groups.remove.title" }),
      message: intl.formatMessage(
        { id: "groups.remove.content" },
        { groupName: group.name }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/groups/remove", {
      userIDToWork: userIDToWork?.value,
      _id: group._id,
    });

    await getGroups();
  }

  async function handleEditGroupClicked(group) {
    let name = await prompt({
      title: intl.formatMessage({ id: "groups.edit.title" }),
      message: intl.formatMessage(
        { id: "groups.edit.content" },
        { groupName: group.name }
      ),
    });

    name = name ? name.trim() : false;

    if (!name) {
      return;
    }

    await ajax("/groups/edit", {
      userIDToWork: userIDToWork?.value,
      _id: group._id,
      name,
    });

    await getGroups();
  }

  function handleServersSelectorModalOpen(group) {
    const selectedServers = {};

    group.assigned_servers.forEach((s) => {
      selectedServers[s] = true;
    });

    setSelectedServers(selectedServers);

    setSelectedGroup(group);

    setIsServersSelectorModalOpen(true);
  }

  async function handleServersSelectorModalClosed(state) {
    if (state) {
      await ajax("/groups/assignServers", {
        userIDToWork: userIDToWork?.value,
        _id: selectedGroup._id,
        servers: Object.keys(selectedServers).filter((k) => selectedServers[k]),
      });

      await getGroups();
    }

    setIsServersSelectorModalOpen(false);
  }

  function handleUserSelectorModalOpen() {
    setIsUserSelectorModalOpen(true);
  }

  function handleUserSelectorModalClosed(user) {
    setUserIDToWork(user);
    setIsUserSelectorModalOpen(false);
  }

  return (
    <WithRole permission="servers.manage-groups">
      <HeaderWithNavs title={intl.formatMessage({ id: "groups.title" })}>
        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <Box className={styles.box}>
            <IconButton
              color="light-purple"
              onClick={handleUserSelectorModalOpen}
            >
              {userIDToWork?.label || user.email}
            </IconButton>
          </Box>
        )}

        <Box>
          {!groups && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}

          {groups && (
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="groups.table.name" />
                  </th>
                  <th>
                    <FormattedMessage id="groups.table.assigned-servers" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groups.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {groups.map((group, idx) => (
                  <tr key={idx}>
                    <td>{group.name}</td>
                    <td>{group.assigned_servers.length}</td>
                    <td>
                      <CustomMenu
                        isOpen={group.isMenuOpen}
                        toggle={() => handleMenuClicked(group)}
                      >
                        <CustomMenuItem
                          onClick={() => handleServersSelectorModalOpen(group)}
                        >
                          <FormattedMessage id="groups.dropdown.assign-servers" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleEditGroupClicked(group)}
                        >
                          <FormattedMessage id="general.edit" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleRemoveGroupClicked(group)}
                        >
                          <FormattedMessage id="general.remove" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          )}

          <div>
            <IconButton color="light-purple" onClick={handleAddGroupClicked}>
              <FormattedMessage id="groups.add-group" />
            </IconButton>
          </div>
        </Box>

        <ServersSelectorModal
          title={intl.formatMessage({ id: "groups.servers-selector.title" })}
          selectedServers={selectedServers}
          setSelectedServers={setSelectedServers}
          isOpen={isServersSelectorModalOpen}
          onClose={handleServersSelectorModalClosed}
          onlyActive
        />

        <UserSelectorModal
          isOpen={isUserSelectorModalOpen}
          onClose={handleUserSelectorModalClosed}
        />
      </HeaderWithNavs>
    </WithRole>
  );
}

MyCloudGroups.propTypes = {
  groupsInitial: PropTypes.array,
};

export default MyCloudGroups;
