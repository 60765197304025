import styles from "./register.module.scss";

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import { useLang, useAjax, useInitialDataFetching } from "../../utils/hooks";
import LoginLayout from "../../components/login-layout";
import TextField from "../../components/textfield";
import IconButton from "../../components/icon-button";
import SocialButton from "../../components/social-button";
import Checkbox from "../../components/checkbox";
import KeyDetector from "../../components/key-detector";

export default function Register() {
  const router = useHistory();
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const initialDataFetching = useInitialDataFetching();
  const { fingerprint } = useSelector((state) => ({
    fingerprint: state.settings.fingerprint,
  }));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [recaptchaToken, setRecaptchaToken] = useState(false);
  const [agree, setAgree] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handleRecaptchaChanged(recaptcha) {
    setRecaptchaToken(recaptcha);
  }

  async function handleRegisterClicked() {
    const _email = email ? email.trim().toLowerCase() : "";
    const _password = password.trim();

    setError(false);

    if (!validator.isEmail(_email)) {
      return setError("general.wrong-email");
    } else if (passwordScore <= 1) {
      return setError("general.weak-password");
    } else if (!agree) {
      return setError("general.must-agree");
    }

    setLoading(true);
    const data = await ajax("/users/register", {
      email: _email,
      password: _password,
      recaptchaToken,
      fingerprint,
    });

    if (data.result === "success") {
      const data = await ajax(`/users/login`, {
        email: _email,
        password: _password,
        fingerprint,
      });

      if (data.result === "success") {
        if (await initialDataFetching(true)) {
          router.push(`/${lang || "en"}/my-cloud/servers`);
        }
      } else {
        setError(data.message);
        setLoading(false);
      }
    } else {
      setError(data.message);
      setLoading(false);
    }
  }

  return (
    <LoginLayout>
      <div className={styles.register}>
        <div className={`mb8 ${styles.bold}`}>
          <FormattedMessage id="general.sign-up" />
        </div>
        <div className={`mb28 ${styles.normal}`}>
          <FormattedMessage id="login.subtitle" />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleRegisterClicked}>
          <TextField
            icon="user"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.email-address" })}
          />
        </KeyDetector>
        <KeyDetector className="mb16" onKeyDetected={handleRegisterClicked}>
          <TextField
            icon="lock"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.password" })}
          />
        </KeyDetector>
        <div>
          <PasswordStrengthBar
            password={password}
            onChangeScore={setPasswordScore}
          />
        </div>
        <div className={styles.iagree}>
          <Checkbox checked={agree} onChange={() => setAgree(!agree)} />{" "}
          <span
            onClick={() => setAgree(!agree)}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "register.i-agree" }),
            }}
          ></span>
        </div>
        {error && (
          <div className={`mb28 ${styles.errorWrapper}`}>
            <FormattedMessage id={error} />
          </div>
        )}
        <div className={`mb16 ${styles.recaptchaWrapper}`}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
            onChange={handleRecaptchaChanged}
            onExpired={() => setRecaptchaToken(false)}
            onErrored={() => setRecaptchaToken(false)}
          />
        </div>
        <div className="mb28">
          <IconButton
            disabled={loading}
            icon="arrow"
            onClick={handleRegisterClicked}
            color="purple"
            className={styles.registerButton}
          >
            <FormattedMessage id="register.create-button" />
          </IconButton>
        </div>
        <div className={`mb28 ${styles.orWrapper}`}>
          <hr />
          <div className={`ml16 mr16 ${styles.text}`}>
            <FormattedMessage id="general.or" />
          </div>
          <hr />
        </div>
        <div className={`mb28 ${styles.socialWrapper}`}>
          <SocialButton type="facebook" setLoading={setLoading} />
          <SocialButton type="google" setLoading={setLoading} />
        </div>
        <div className={styles.haveAccountWrapper}>
          <FormattedMessage id="register.have-account" />{" "}
          <Link href={`/[lang]/login`} to={`/${lang}/login`}>
            <FormattedMessage id="general.sign-in" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
