import styles from "./change-password.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import PasswordStrengthBar from "react-password-strength-bar";
import CustomText from "../../../../../../components/custom-text";
import Checkbox from "../../../../../../components/checkbox";
import Box from "../../../../../../components/box";
import IconButton from "../../../../../../components/icon-button";
import { FormattedMessage, useIntl } from "react-intl";
import GeneratePasswordModal from "../../../../../../components/modals/generate-password";
import { useAjax, useServer } from "../../../../../../utils/hooks";
import {} from "../../../../../../utils";
import { WithRole } from "../../../../../../components/with-role";

function ServerSettingsChangePassword({
  serverToUse = null,
  onlyContent = false,
}) {
  const intl = useIntl();
  const ajax = useAjax();
  const server = useServer();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [restartServer, setRestartServer] = useState(false);
  const [
    isGeneratePasswordModalOpen,
    setIsGeneratePasswordModalOpen,
  ] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleGeneratePasswordModalOpen() {
    setIsGeneratePasswordModalOpen(true);
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
      setPasswordConfirm(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  async function handleChangePasswordClicked() {
    setError(false);

    if (passwordScore <= 1) {
      return setError(intl.formatMessage({ id: "general.weak-password" }));
    } else if (password !== passwordConfirm) {
      return setError(intl.formatMessage({ id: "general.password-mismatch" }));
    }

    setLoading(true);
    const data = await ajax("/servers/setPassword", {
      password,
      restartServer,
      serverID: serverToUse ? serverToUse._id : server._id,
    });
    setLoading(false);

    if (data.result === "success") {
      setPassword("");
      setPasswordConfirm("");

      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }

  function renderContent() {
    return (
      <div className={styles.wrapper}>
        <Box className={onlyContent ? styles.onlyContent : ""}>
          {!onlyContent && (
            <div className={styles.title}>
              <FormattedMessage id="server-settings-change-password.title" />
            </div>
          )}
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "server-settings-change-password.description",
              }),
            }}
          ></div>
          <div>
            <CustomText
              type="password"
              placeholder={intl.formatMessage({
                id: "server-settings-change-password.new-password",
              })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <CustomText
              type="password"
              placeholder={intl.formatMessage({
                id: "server-settings-change-password.repeat-password",
              })}
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>
          <div className={styles.passwordStrengthBarWrapper}>
            <PasswordStrengthBar
              password={password}
              onChangeScore={setPasswordScore}
            />
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              label="server-settings-change-password.restart-server"
              checked={restartServer}
              onChange={(e) => setRestartServer(e.target.checked)}
            />
          </div>

          {error && <div className={`error ${styles.error}`}>{error}</div>}

          {success && (
            <div className={`success ${styles.error}`}>
              <FormattedMessage id="server-settings-change-password.password-changed" />
            </div>
          )}

          <div className={styles.buttons}>
            <IconButton
              disabled={loading}
              color="white"
              onClick={handleGeneratePasswordModalOpen}
            >
              <FormattedMessage id="server-settings-change-password.generate-password" />
            </IconButton>
            <IconButton
              disabled={loading || server?.isWorking}
              color="purple"
              onClick={handleChangePasswordClicked}
            >
              <FormattedMessage id="server-settings-change-password.save-change" />
            </IconButton>
          </div>
        </Box>

        <GeneratePasswordModal
          isOpen={isGeneratePasswordModalOpen}
          onClose={handleGeneratePasswordModalClosed}
        />
      </div>
    );
  }

  if (onlyContent) {
    return renderContent();
  }

  return (
    <WithRole permission="servers.change-password">{renderContent()}</WithRole>
  );
}

ServerSettingsChangePassword.propTypes = {
  serverToUse: PropTypes.object,
  onlyContent: PropTypes.bool,
};

export default ServerSettingsChangePassword;
