import styles from "./ticket_number.module.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import Box from "../../../../components/box";
import LayoutMainAction from "../../../../components/layout-main-action";
import {
  useAjax,
  useAlert,
  useLang,
  useRoles,
  useUser,
} from "../../../../utils/hooks";
import LetterAvatar from "../../../../components/letter-avatar";
import TinyEditor from "../../../../components/tiny-editor";
import IconButton from "../../../../components/icon-button";
import { WithRole } from "../../../../components/with-role";
import { getFullName, SUPER_ADMIN, WHITELABEL } from "../../../../utils/user";
import ItemWithHeader from "../../../../components/item-with-header";
import CustomReactSelect from "../../../../components/custom-react-select";
import Checkbox from "../../../../components/checkbox";
import { getDirection, sanitize } from "../../../../utils";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import isValidDomain from "is-valid-domain";
import validator from "validator";

export const priorities = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];

export default function SupportViewTicket() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const router = useHistory();
  const { ticket_number } = useParams();
  const user = useUser();
  const alert = useAlert();
  const { isAllowed } = useRoles();
  const isAllowedRef = useRef(isAllowed);
  isAllowedRef.current = isAllowed;

  const [ticket, setTicket] = useState(null);
  const [replyText, setReplyText] = useState("");
  const [priority, setPriority] = useState(priorities[0]);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [doSnooze, setDoSnooze] = useState(false);
  const [closeAfterSnooze, setCloseAfterSnooze] = useState(false);
  const [snooze, setSnooze] = useState(null);
  const [assignee, setAssignee] = useState(false);
  const [supporters, setSupporters] = useState([]);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [gmailBlacklist, setGmailBlacklist] = useState(false);
  const [inGmailBlacklist, setInGmailBlacklist] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getTicket = useCallback(async () => {
    const data = await ajax("/tickets/getTicket", {
      ticketID: ticket_number,
    });

    if (data.result === "success") {
      data.ticket.messages.forEach((message) => {
        if (
          message.name.includes("[no name]") &&
          data.ticket.source === "gmail"
        ) {
          message.name = "Gmail";
        }
      });

      setTicket(data.ticket);

      if (data.ticket.reminder) {
        setDoSnooze(true);
        setCloseAfterSnooze(data.ticket.reminder_close_after);
        setSnooze(new Date(data.ticket.reminder));
      }
    } else {
      router.push(`/[${lang}]/support/my-tickets`);
    }
  }, [ajax, lang, router, ticket_number]);

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  useEffect(() => {
    if (ticket) {
      setPriority(
        priorities.find((priority) => priority.value === ticket.priority)
      );
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket && supporters) {
      if ([SUPER_ADMIN, WHITELABEL].includes(user.role)) {
        setAssignee(
          supporters.find(
            (supporter) => supporter.value === ticket.assigned_user_id
          )
        );
      } else {
        setAssignee(null);
      }
    }
  }, [supporters, ticket, user.role]);

  useEffect(() => {
    if (ticket && departments) {
      setDepartment(
        departments.find((department) => department.value === ticket.department)
      );
    }
  }, [departments, ticket]);

  const fetchDepartments = useCallback(async () => {
    const data = await ajax(`/tickets/getDepartments`);

    const departments = data.departments.map((department, idx) => ({
      label: department.name,
      value: department.name,
      key: idx,
      supporters: department.supporters,
    }));

    setDepartments(departments);
    setDepartment(departments[0]);
  }, [ajax]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  useEffect(() => {
    if (department && department.supporters) {
      const supporters = [
        {
          label: intl.formatMessage({ id: "general.none" }),
          value: null,
          key: -1,
        },
        ...department.supporters.map((supporter, idx) => ({
          label: `${supporter.email} - ${supporter.firstName} ${supporter.lastName}`,
          value: supporter._id,
          key: idx,
        })),
      ];

      setSupporters(supporters);

      if ([SUPER_ADMIN, WHITELABEL].includes(user.role)) {
        setAssignee(supporters[0]);
      } else {
        setAssignee(null);
      }
    }
  }, [department, intl, user.role]);

  const getGmailBlacklist = useCallback(async () => {
    if (!ticket) {
      return;
    }

    setInGmailBlacklist(false);

    const data = await ajax("/tickets/getGmailBlacklist");

    setGmailBlacklist(data.list);

    for (let i = 0; i < data.list.length; i++) {
      const item = data.list[i].toLowerCase();

      if (
        isValidDomain(item) &&
        ticket.user.email.toLowerCase().includes(item)
      ) {
        return setInGmailBlacklist(item);
      } else if (
        validator.isEmail(item) &&
        ticket.user.email.toLowerCase() === item
      ) {
        return setInGmailBlacklist(item);
      }
    }
  }, [ajax, ticket]);

  useEffect(() => {
    if (isAllowedRef.current("super-admin.gmail-blacklist")) {
      getGmailBlacklist();
    }
  }, [getGmailBlacklist]);

  async function handleSubmitClicked() {
    setError(false);

    const _replyText = replyText.trim();

    if (!_replyText && ![SUPER_ADMIN, WHITELABEL].includes(user.role)) {
      return setError(intl.formatMessage({ id: "view-ticket.no-content" }));
    } else if (doSnooze && snooze <= new Date()) {
      return setError(intl.formatMessage({ id: "view-ticket.invalid-snooze" }));
    }

    setLoading(true);
    const data = await ajax("/tickets/addTicketReply", {
      assignee: assignee?.value,
      department: department?.value,
      markAsResolved: false,
      priority: priority?.value,
      replyText: _replyText,
      snooze: doSnooze ? snooze : null,
      closeAfterSnooze,
      ticketID: ticket._id,
    });
    setLoading(false);

    if (data.result === "success") {
      if (!replyTextTrimmed) {
        await alert(
          intl.formatMessage({ id: "view-ticket.update-ticket" }),
          intl.formatMessage({ id: "view-ticket.update-ticket.content" })
        );
      }

      setReplyText("");
      window.tinyMCE.activeEditor.setContent("");

      await getTicket();
    } else {
      setError(intl.formatMessage({ id: "general.general-error" }));
    }
  }

  async function handleToggleTicketClicked() {
    setLoading(true);
    await ajax("/tickets/addTicketReply", {
      markAsResolved: ticket.status === "open" ? true : false,
      ticketID: ticket._id,
      replyText: `[Ticket status changed to ${
        ticket.status === "open" ? "<b>Closed</b>" : "<b>Open</b>"
      }]`,
    });
    setLoading(false);

    await getTicket();
  }

  async function handleAddGmailBlacklistClicked(record) {
    const list = [...gmailBlacklist];

    list.push(record);

    setLoading(true);
    await ajax("/tickets/setGmailBlacklist", {
      list,
    });

    await getGmailBlacklist();
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "view-ticket.gmail-blacklist.add.title" }),
      intl.formatMessage(
        { id: "view-ticket.gmail-blacklist.add.content" },
        { record }
      )
    );
  }

  async function handleRemoveGmailBlacklistClicked(record) {
    const list = gmailBlacklist.filter((item) => item !== record);

    setLoading(true);
    await ajax("/tickets/setGmailBlacklist", {
      list,
    });

    await getGmailBlacklist();
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "view-ticket.gmail-blacklist.remove.title" }),
      intl.formatMessage(
        { id: "view-ticket.gmail-blacklist.remove.content" },
        { record }
      )
    );
  }

  function getStatusColor(status) {
    switch (status) {
      case "open":
        return styles.green;

      default:
        return styles.red;
    }
  }

  function renderAvatarName(name) {
    const nameArr = name.split(" ");

    return `${nameArr[0][0]}${nameArr[1] ? nameArr[1][0] : ""}`.trim();
  }

  const layoutButton = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "view-ticket.open-new-ticket" }),
        href: "/[lang]/support/open-new-ticket",
        as: `/${lang}/support/open-new-ticket`,
        color: "green",
      },
    ],
    [intl, lang]
  );

  const smallButton = useMemo(
    () => ({
      title: intl.formatMessage({ id: "view-ticket.back" }),
      href: "/[lang]/support/my-tickets",
      as: `/${lang}/support/my-tickets`,
    }),
    [intl, lang]
  );

  const replyTextTrimmed = useMemo(() => replyText.trim(), [replyText]);

  return (
    <WithRole permission="tickets.submit">
      <LayoutMainAction
        title={intl.formatMessage({ id: "view-ticket.main-title" })}
        buttons={layoutButton}
        smallButton={smallButton}
      >
        {ticket && (
          <div className={styles.wrapper}>
            <Box className={styles.ticketInformationBox}>
              <div className={styles.header}>
                <FormattedMessage id="view-ticket.ticket-information" />
              </div>
              <div>
                <div className={styles.title}>
                  <FormattedMessage id="view-ticket.status" />
                </div>
                <div className={styles.statusWrapper}>
                  <div
                    className={`${styles.status} ${getStatusColor(
                      ticket.status
                    )}`}
                  >
                    {ticket.status}
                  </div>

                  <IconButton color="text" onClick={handleToggleTicketClicked}>
                    <FormattedMessage
                      id={
                        ticket.status === "open"
                          ? "view-ticket.close-ticket"
                          : "view-ticket.open-ticket"
                      }
                    />
                  </IconButton>
                </div>
              </div>

              {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                <>
                  <hr />

                  <div>
                    <div className={styles.title}>
                      <FormattedMessage id="view-ticket.client" />
                    </div>
                    <div className={styles.content}>
                      <a
                        href={`/${lang}/clients/clients-list/${ticket.user._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getFullName(ticket.user)}
                      </a>
                    </div>
                  </div>
                </>
              )}

              <hr />

              <div>
                <div className={styles.title}>
                  <FormattedMessage id="view-ticket.last-update" />
                </div>
                <div className={styles.content}>
                  {format(new Date(ticket.updated_at), "MMMM d,yyyy (HH:mm)")}
                </div>
              </div>

              {ticket.related_service && (
                <>
                  <hr />
                  <div>
                    <div className={styles.title}>
                      <FormattedMessage id="view-ticket.related-service" />
                    </div>
                    <div className={styles.content}>
                      {!ticket.related_service.value &&
                        ticket.related_service.label}
                      {ticket.related_service.value && (
                        <a
                          href={`/${lang}/my-cloud/servers/${ticket.related_service.value}/overview`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {ticket.related_service.label}
                        </a>
                      )}
                    </div>
                  </div>
                </>
              )}

              <hr />
              <div>
                <div className={styles.title}>
                  <FormattedMessage id="view-ticket.department" />
                </div>
                <div className={styles.content}>{ticket.department}</div>
              </div>

              <hr />
              <div>
                <div className={styles.title}>
                  <FormattedMessage id="view-ticket.priority" />
                </div>
                <div className={styles.content}>{ticket.priority}</div>
              </div>

              <hr />

              <div>
                <div className={styles.title}>
                  <FormattedMessage id="view-ticket.submitted" />
                </div>
                <div className={styles.content}>
                  {format(new Date(ticket.created_at), "MMMM d,yyyy (HH:mm)")}
                </div>
              </div>
            </Box>

            <Box className={styles.ticketBox}>
              <div
                className={styles.header}
                style={getDirection(ticket.subject)}
              >
                <span className={styles.number}>#{ticket.ticket_number} -</span>
                <span className={styles.flex1}>{ticket.subject}</span>

                {isAllowed("super-admin.gmail-blacklist") && (
                  <Dropdown
                    isOpen={adminDropdownOpen}
                    toggle={() => setAdminDropdownOpen(!adminDropdownOpen)}
                  >
                    <DropdownToggle nav>
                      <FormattedMessage id="general.actions" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      {!inGmailBlacklist && (
                        <DropdownItem
                          onClick={() =>
                            handleAddGmailBlacklistClicked(ticket.user.email)
                          }
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage(
                              {
                                id: "view-ticket.gmail-blacklist-email",
                              },
                              {
                                email: ticket.user.email,
                                b: (arr) => `<b>${arr[0]}</b>`,
                              }
                            ),
                          }}
                        ></DropdownItem>
                      )}
                      {!inGmailBlacklist && (
                        <DropdownItem
                          onClick={() =>
                            handleAddGmailBlacklistClicked(
                              ticket.user.email.split("@")[1]
                            )
                          }
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage(
                              {
                                id: "view-ticket.gmail-blacklist-domain",
                              },
                              {
                                domain: ticket.user.email.split("@")[1],
                                b: (arr) => `<b>${arr[0]}</b>`,
                              }
                            ),
                          }}
                        ></DropdownItem>
                      )}
                      {inGmailBlacklist && (
                        <DropdownItem
                          onClick={() =>
                            handleRemoveGmailBlacklistClicked(inGmailBlacklist)
                          }
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage(
                              {
                                id: "view-ticket.gmail-blacklist-remove",
                              },
                              {
                                record: inGmailBlacklist,
                                b: (arr) => `<b>${arr[0]}</b>`,
                              }
                            ),
                          }}
                        ></DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>

              {ticket.messages.map((message, key) => (
                <div className={styles.message} key={key}>
                  <div className={styles.headerBar}>
                    <LetterAvatar fullName={message.name}>
                      {renderAvatarName(message.name)}
                    </LetterAvatar>

                    <div className={styles.name}>{message.name}</div>

                    <div className={styles.date}>
                      {format(
                        new Date(message.created_at),
                        "d MMM yyyy, HH:mm"
                      )}
                    </div>
                  </div>
                  <div
                    className={styles.content}
                    style={getDirection(message.message)}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(message.message),
                    }}
                  ></div>

                  {key < ticket.messages.length - 1 && <hr />}
                </div>
              ))}

              {isAllowed("tickets.reply") && ticket.status !== "closed" && (
                <>
                  <hr />

                  <div className={styles.editor}>
                    <div className={styles.reply}>
                      <FormattedMessage id="view-ticket.reply" />
                    </div>

                    <TinyEditor
                      onChange={(value) => setReplyText(value)}
                      height={300}
                    />

                    {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                      <>
                        <div className={styles.adminActions}>
                          <ItemWithHeader
                            header={
                              <div className={styles.snoozeWrapper}>
                                <Checkbox
                                  label="view-ticket.snooze"
                                  checked={doSnooze}
                                  onChange={(e) =>
                                    setDoSnooze(e.target.checked)
                                  }
                                />
                                {doSnooze && (
                                  <Checkbox
                                    label="view-ticket.snooze-close-after"
                                    checked={closeAfterSnooze}
                                    onChange={(e) =>
                                      setCloseAfterSnooze(e.target.checked)
                                    }
                                  />
                                )}
                              </div>
                            }
                          >
                            <DatePicker
                              wrapperClassName="select"
                              disabled={!doSnooze}
                              selected={snooze}
                              onChange={(date) => setSnooze(date)}
                              showTimeSelect
                              dateFormat="dd/MM/yyyy HH:mm"
                            />
                          </ItemWithHeader>
                          <ItemWithHeader
                            header={intl.formatMessage({
                              id: "view-ticket.priority",
                            })}
                          >
                            <CustomReactSelect
                              instanceId="view-ticket-priority"
                              options={priorities}
                              value={priority}
                              onChange={(item) => setPriority(item)}
                            />
                          </ItemWithHeader>
                        </div>

                        <div className={styles.adminActions}>
                          <ItemWithHeader
                            header={intl.formatMessage({
                              id: "view-ticket.department",
                            })}
                          >
                            <CustomReactSelect
                              instanceId="view-ticket.department"
                              options={departments}
                              value={department}
                              onChange={(item) => setDepartment(item)}
                            />
                          </ItemWithHeader>
                          <ItemWithHeader
                            header={intl.formatMessage({
                              id: "view-ticket.assignee",
                            })}
                          >
                            <CustomReactSelect
                              instanceId="view-ticket-assignee"
                              options={supporters}
                              value={assignee}
                              onChange={(option) => setAssignee(option)}
                            />
                          </ItemWithHeader>
                        </div>
                      </>
                    )}

                    <div className={styles.button}>
                      <IconButton
                        disabled={loading}
                        color="light-purple"
                        onClick={handleSubmitClicked}
                      >
                        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                          <>
                            {!replyTextTrimmed && (
                              <FormattedMessage id="view-ticket.update-ticket" />
                            )}
                            {replyTextTrimmed && (
                              <FormattedMessage id="view-ticket.submit-reply" />
                            )}
                          </>
                        )}
                        {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                          <FormattedMessage id="view-ticket.submit-reply" />
                        )}
                      </IconButton>
                    </div>

                    {error && <div className="error">{error}</div>}
                  </div>
                </>
              )}
            </Box>
          </div>
        )}
      </LayoutMainAction>
    </WithRole>
  );
}
