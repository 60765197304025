import React from "react";
import { differenceInDays, endOfMonth } from "date-fns";
import { ReactComponent as VisaSvg } from "../components/svgs/visa.svg";
import { ReactComponent as AmericanExpressSvg } from "../components/svgs/american-express.svg";
import { ReactComponent as MasterCardSvg } from "../components/svgs/master-card.svg";
import { ReactComponent as DiscoverSvg } from "../components/svgs/discover.svg";
import { ReactComponent as DinersSvg } from "../components/svgs/diners.svg";
import { ReactComponent as NoCardSvg } from "../components/svgs/nocard.svg";

export const currencySymbols = {
  EUR: "€",
  USD: "$",
  ILS: "₪",
  GBP: "£",

  // AFN: "؋",
  // ARS: "$",
  // AWG: "ƒ",
  // AUD: "$",
  // AZN: "₼",
  // BSD: "$",
  // BBD: "$",
  // BYN: "Br",
  // BZD: "BZ$",
  // BMD: "$",
  // BOB: "$b",
  // BAM: "KM",
  // BWP: "P",
  // BGN: "лв",
  // BRL: "R$",
  // BND: "$",
  // KHR: "៛",
  // CAD: "$",
  // KYD: "$",
  // CLP: "$",
  // CNY: "¥",
  // COP: "$",
  // CRC: "₡",
  // HRK: "kn",
  // CUP: "₱",
  // CZK: "Kč",
  // DKK: "kr",
  // DOP: "RD$",
  // XCD: "$",
  // EGP: "£",
  // SVC: "$",
  // FKP: "£",
  // FJD: "$",
  // GHS: "¢",
  // GIP: "£",
  // GTQ: "Q",
  // GGP: "£",
  // GYD: "$",
  // HNL: "L",
  // HKD: "$",
  // HUF: "Ft",
  // ISK: "kr",
  // INR: "",
  // IDR: "Rp",
  // IRR: "﷼",
  // IMP: "£",
  // JMD: "J$",
  // JPY: "¥",
  // JEP: "£",
  // KZT: "лв",
  // KPW: "₩",
  // KRW: "₩",
  // KGS: "лв",
  // LAK: "₭",
  // LBP: "£",
  // LRD: "$",
  // MKD: "ден",
  // MYR: "RM",
  // MUR: "₨",
  // MXN: "$",
  // MNT: "₮",
  // MZN: "MT",
  // NAD: "$",
  // NPR: "₨",
  // ANG: "ƒ",
  // NZD: "$",
  // NIO: "C$",
  // NGN: "₦",
  // NOK: "kr",
  // OMR: "﷼",
  // PKR: "₨",
  // PAB: "B/.",
  // PYG: "Gs",
  // PEN: "S/.",
  // PHP: "₱",
  // PLN: "zł",
  // QAR: "﷼",
  // RON: "lei",
  // RUB: "₽",
  // SHP: "£",
  // SAR: "﷼",
  // RSD: "Дин.",
  // SCR: "₨",
  // SGD: "$",
  // SBD: "$",
  // SOS: "S",
  // ZAR: "R",
  // LKR: "₨",
  // SEK: "kr",
  // CHF: "CHF",
  // SRD: "$",
  // SYP: "£",
  // TWD: "NT$",
  // THB: "฿",
  // TTD: "TT$",
  // TRY: "",
  // TVD: "$",
  // UAH: "₴",
  // UYU: "$U",
  // UZS: "лв",
  // VEF: "Bs",
  // VND: "₫",
  // YER: "﷼",
  // ZWD: "Z$"
};

export const getSymbolsForReactSelect = () => {
  return Object.keys(currencySymbols).map((currency, idx) => ({
    label: `${currency} (${currencySymbols[currency]})`,
    value: currency,
    key: idx,
  }));
};

export const paymentMethodToText = (paymentMethod) => {
  switch (paymentMethod) {
    case "smartpayplus_redirect":
      return "Secured Credit Card";
    default:
      return "-";
  }
};

export const convertCurrency = (number, from, to, rates, fixed) => {
  let ratesTo = rates[to];

  if (from === "USD" && to === "ILS" && fixed[from] > 0) {
    ratesTo = fixed[from];
  }

  return (number / rates[from]) * ratesTo;
};

export const paymentGateways = [
  {
    label: "icount",
    value: "icount",
  },
  {
    label: "payplus",
    value: "payplus",
  },
  {
    label: "stripe",
    value: "stripe",
  },
];

export const billingGateways = [
  {
    label: "icount",
    value: "icount",
  },
  {
    label: "green-invoice",
    value: "green-invoice",
  },
  {
    label: "dreamvps",
    value: "dreamvps",
  },
];

export const getVatPrec = (user) => {
  const country = user.country ? user.country.toUpperCase().trim() : "";
  const city = user.city ? user.city.toLowerCase().trim() : "";

  if (country !== "NL" && country !== "IL") {
    return 1;
  }

  if (country === "IL" && (city.includes("eilat") || city.includes("אילת"))) {
    return 1;
  }

  let vat;

  switch (country) {
    case "IL":
      vat = process.env.REACT_APP_CURRENT_VAT_IL;
      break;
    case "NL":
      vat = process.env.REACT_APP_CURRENT_VAT_NL;
      break;
  }

  return 1 + vat / 100;
};

export const getRestOfMonthDays = () => {
  const curDate = new Date();

  return differenceInDays(endOfMonth(curDate), curDate) + 1;
};

export const getClosestPrice = (value, pricingArray, direction) => {
  const min = pricingArray[0].value;
  const max = pricingArray[pricingArray.length - 1].value;

  let checker = pricingArray.find((price) => price.value === value);
  if (checker) {
    return checker;
  }

  let searchValue = value;

  do {
    searchValue += direction === "up" ? 1 : -1;

    if (searchValue > max) {
      return { value: max };
    }
    if (searchValue < min) {
      return { value: min };
    }

    checker = pricingArray.find((price) => price.value === searchValue);
    if (checker) {
      return checker;
    }
  } while (true);
};

export const getCreditCardSvg = (cardType) => {
  switch (cardType) {
    case "visa":
      return <VisaSvg />;
    case "mastercard":
      return <MasterCardSvg />;
    case "american-express":
      return <AmericanExpressSvg />;
    case "diners-club":
      return <DinersSvg />;
    case "discover":
      return <DiscoverSvg />;
    case "isracard":
      return <NoCardSvg />;
    case "jcb":
      return <NoCardSvg />;
    case "unionpay":
      return <NoCardSvg />;
    case "maestro":
      return <NoCardSvg />;
    case "mir":
      return <NoCardSvg />;
    case "elo":
      return <NoCardSvg />;
    case "hiper":
      return <NoCardSvg />;
    case "hipercard":
      return <NoCardSvg />;
    default:
      return <NoCardSvg />;
  }
};

export const isracardValidation = (number) => {
  if (number.length < 8 || number.length > 9) {
    return false;
  }

  number = number.padStart(9, "0");
  let sum = 0;

  for (let i = 0; i <= 8; i++) {
    sum += (9 - i) * number[i];
  }

  return sum % 11 === 0;
};

export const getInvoiceAmount = (invoice) => {
  if (!invoice) {
    return "";
  }

  let amount = invoice.items.reduce(
    (total, curItem) => total + curItem.amount * curItem.quantity,
    0
  );

  amount = amount * (1 + invoice.vat / 100);

  return amount.toFixed(2);
};

export const getInvoiceAmountFormatted = (invoice) => {
  if (!invoice) {
    return "";
  }

  return `${currencySymbols[invoice.currency]}${getInvoiceAmount(invoice)}`;
};

export function getPrice(server, asObject, wldvps) {
  const serverPayment = wldvps ? server.whitelabel_payment : server.payment;

  if (asObject) {
    return {
      currency: serverPayment.currency,
      price:
        serverPayment.fixed_price > 0
          ? serverPayment.fixed_price
          : server.price,
    };
  }

  if (typeof server.price === "object") {
    return (
      <>
        {currencySymbols[serverPayment.currency]}
        {server.price.hourlyPriceOn.toFixed(3)}/on{" "}
        {server.price.hourlyPriceOff.toFixed(3)}/off
      </>
    );
  }

  return (
    <>
      {currencySymbols[serverPayment.currency]}
      {serverPayment.fixed_price > 0
        ? serverPayment.fixed_price
        : server.price.toFixed(2)}
    </>
  );
}

export const calculateServerPriceHourlyOn = (
  user,
  serverData,
  productPrices
) => {
  const discount = 1 - user.discount_hourly_on / 100;

  const { cpu, ram_mb, ssd_gb, additional_ssd_gb, backup } = serverData;

  const hourlyExtraPrice = parseFloat(process.env.REACT_APP_HOURLY_EXTRA_PRICE);

  let price = productPrices.basePrice * hourlyExtraPrice * discount;
  let backupPrice = 0;

  let ssdBackupPrice = 0,
    additionalSSDbackupPrice = 0;

  if (cpu) {
    const item = productPrices.cpu.find((c) => c.value === cpu);
    if (item) {
      price += item.price * hourlyExtraPrice * discount;
    }
  }

  if (ram_mb) {
    const item = productPrices.ram_mb.find((r) => r.value === ram_mb);
    if (item) {
      price += item.price * hourlyExtraPrice * discount;
    }
  }

  if (ssd_gb) {
    const item = productPrices.ssd_gb.find((s) => s.value === ssd_gb);
    if (item) {
      price += item.price * hourlyExtraPrice * discount;
      ssdBackupPrice = item.price * hourlyExtraPrice * discount;
    }
  }

  if (typeof additional_ssd_gb === "number" && additional_ssd_gb > 0) {
    const item = productPrices.additional_ssd_gb.find(
      (as) => as.value === additional_ssd_gb
    );
    if (item) {
      price += item.price * hourlyExtraPrice * discount;
      additionalSSDbackupPrice = item.price * hourlyExtraPrice * discount;
    }
  }

  if (backup && !user.free_backups) {
    backupPrice =
      (2.5 + ssdBackupPrice + additionalSSDbackupPrice) *
      productPrices.backup *
      discount;
    price += backupPrice;
  }

  return { price: price / 730, backup: backupPrice / 730 };
};

export const calculateServerPriceHourlyOff = (
  user,
  serverData,
  productPrices
) => {
  const discount = 1 - user.discount_hourly_off / 100;

  const { ssd_gb, additional_ssd_gb, backup } = serverData;

  const hourlyExtraPrice = parseFloat(process.env.REACT_APP_HOURLY_EXTRA_PRICE);

  let price = 0;
  let backupPrice = 0;
  let ssdBackupPrice = 0,
    additionalSSDbackupPrice = 0;

  if (ssd_gb && !user.free_backups) {
    ssdBackupPrice =
      (0.008 + ((ssd_gb - 20) / 10) * 0.0007) * hourlyExtraPrice * discount;
    price += ssdBackupPrice;
  }

  if (
    typeof additional_ssd_gb === "number" &&
    additional_ssd_gb > 0 &&
    !user.free_backups
  ) {
    additionalSSDbackupPrice =
      (0.008 + ((additional_ssd_gb - 20) / 10) * 0.0007) *
      hourlyExtraPrice *
      discount;
    price += additionalSSDbackupPrice;
  }

  if (backup && !user.free_backups) {
    backupPrice =
      (0.0035 + ssdBackupPrice + additionalSSDbackupPrice) *
      productPrices.backup *
      discount;
    price += backupPrice;
  }

  return { price, backup: backupPrice };
};

export const getRelevantAddons = (server, wldvps) => {
  return server.addons
    .filter((addon) => addon.whitelabel === wldvps)
    .map((addon) => addon._id);
};

export const getRelevantOtherProducts = (user, wldvps) => {
  return user.otherProducts
    .filter((op) => op.whitelabel === wldvps)
    .map((op) => op._id);
};
