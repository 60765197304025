import styles from "./index.module.scss";

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { DropdownItem, Tooltip } from "reactstrap";
import CustomMenu from "../custom-menu";
import CustomMenuItem from "../custom-menu/item";
import Navigator from "../navigator";
import {
  useLang,
  useConfirm,
  usePrompt,
  useAjax,
  useRoles,
  useUser,
  useServer,
} from "../../utils/hooks";
import CloneServerModal from "../modals/clone-server";
import { getCountryByCode } from "../../utils/countries";
import { handleServerActionClicked } from "./server-actions";
import { getOsLogo, getImageName } from "../../utils/servers";
import { SUPER_ADMIN, WHITELABEL } from "../../utils/user";
import { ReactComponent as InfoSvg } from "../svgs/info.svg";
import { ReactComponent as LongArrowLeft } from "../svgs/long-arrow-left.svg";
import { ReactComponent as PlaySvg } from "../svgs/play.svg";
import { useDispatch } from "react-redux";
import { setServersFetchingFilter } from "../../store/settings";
import CreateNewServerModal from "../modals/create-new-product";
import { Link } from "react-router-dom";
import { sanitize } from "../../utils";
import Spinner from "../spinner";

function ServerLayout({ children }) {
  const intl = useIntl();
  const user = useUser();
  const router = useHistory();
  const { id } = useParams();
  const lang = useLang();
  const confirm = useConfirm();
  const prompt = usePrompt();
  const ajax = useAjax();
  const { isAllowed } = useRoles();
  const dispatch = useDispatch();
  const server = useServer();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCloneServerModalOpen, setIsCloneServerModalOpen] = useState(false);
  const [
    isRebootRequiredTooltipOpen,
    setIsRebootRequiredTooltipOpen,
  ] = useState(false);
  const [isCreateNewServerModalOpen, setIsCreateNewServerModalOpen] = useState(
    false
  );
  const [selectedServer, setSelectedServer] = useState(null);
  const [unlocking, setUnlocking] = useState(false);

  const items = useMemo(() => {
    if (!server) {
      return [];
    }

    const ret = [
      {
        id: "overview",
        title: intl.formatMessage({ id: "server-layout.overview" }),
        href: `/[lang]/my-cloud/servers/[id]/overview`,
        as: `/${lang}/my-cloud/servers/${id}/overview`,
        selected: router.location.pathname.endsWith("/overview"),
      },
      {
        id: "usage-graphs",
        title: intl.formatMessage({ id: "server-layout.usage-graphs" }),
        href: `/[lang]/my-cloud/servers/[id]/usage-graphs`,
        as: `/${lang}/my-cloud/servers/${id}/usage-graphs`,
        selected: router.location.pathname.endsWith("/usage-graphs"),
      },
      {
        id: "ipv4",
        title: intl.formatMessage({ id: "server-layout.server-settings" }),
        href: `/[lang]/my-cloud/servers/[id]/server-settings/ipv4`,
        as: `/${lang}/my-cloud/servers/${id}/server-settings/ipv4`,
        selected: router.location.pathname.includes("/server-settings/"),
      },
      {
        id: "snapshots",
        title: intl.formatMessage({ id: "server-layout.snapshots" }),
        href: `/[lang]/my-cloud/servers/[id]/snapshots`,
        as: `/${lang}/my-cloud/servers/${id}/snapshots`,
        selected: router.location.pathname.endsWith("/snapshots"),
      },
      {
        id: "backups",
        title: intl.formatMessage({ id: "server-layout.backups" }),
        href: `/[lang]/my-cloud/servers/[id]/backups`,
        as: `/${lang}/my-cloud/servers/${id}/backups`,
        selected: router.location.pathname.endsWith("/backups"),
      },
      {
        id: "tasks",
        title: intl.formatMessage({ id: "server-layout.tasks" }),
        href: `/[lang]/my-cloud/servers/[id]/tasks`,
        as: `/${lang}/my-cloud/servers/${id}/tasks`,
        selected: router.location.pathname.endsWith("/tasks"),
      },
      {
        id: "server-logs",
        title: intl.formatMessage({ id: "server-layout.server-logs" }),
        href: `/[lang]/my-cloud/servers/[id]/server-logs`,
        as: `/${lang}/my-cloud/servers/${id}/server-logs`,
        selected: router.location.pathname.endsWith("/server-logs"),
      },
      {
        id: "console",
        title: intl.formatMessage({ id: "server-layout.console" }),
        href: `/[lang]/my-cloud/servers/[id]/console`,
        as: `/${lang}/my-cloud/servers/${id}/console`,
        selected: router.location.pathname.endsWith("/console"),
      },
      {
        id: "payment",
        title: intl.formatMessage({ id: "server-layout.payment" }),
        href: `/[lang]/my-cloud/servers/[id]/payment`,
        as: `/${lang}/my-cloud/servers/${id}/payment`,
        selected: router.location.pathname.endsWith("/payment"),
      },
      {
        id: "notes",
        title: intl.formatMessage({ id: "server-layout.notes" }),
        href: `/[lang]/my-cloud/servers/[id]/notes`,
        as: `/${lang}/my-cloud/servers/${id}/notes`,
        selected: router.location.pathname.endsWith("/notes"),
      },
      {
        id: "show-client",
        title: intl.formatMessage({ id: "server-layout.show-client" }),
        href: "/[lang]/clients/clients-list/[id]",
        as: `/${lang}/clients/clients-list/${server.user_id}`,
      },
      {
        id: "show-client-servers",
        title: intl.formatMessage({ id: "server-layout.show-client-servers" }),
        onClick: () => {
          dispatch(setServersFetchingFilter(server.userEmail));
          router.push(`/${lang}/my-cloud/servers`);
        },
      },
    ].filter(
      (item) =>
        (item.id !== "addons" || isAllowed("servers.addons")) &&
        (item.id !== "payment" || isAllowed("admin.servers.payment")) &&
        (item.id !== "notes" || isAllowed("admin.servers.notes")) &&
        (item.id !== "show-client" ||
          [SUPER_ADMIN, WHITELABEL].includes(user.role)) &&
        (item.id !== "show-client-servers" ||
          [SUPER_ADMIN, WHITELABEL].includes(user.role)) &&
        ((server.status === "Active" &&
          (item.id !== "tasks" || isAllowed("servers.scheduler")) &&
          (item.id !== "console" || isAllowed("servers.console"))) ||
          item.id === "overview" ||
          item.id === "usage-graphs" ||
          item.id === "server-logs" ||
          item.id === "ipv4" ||
          item.id === "payment" ||
          item.id === "notes" ||
          item.id === "show-client" ||
          item.id === "show-client-servers")
    );

    if (server.status === "Terminated") {
      ret[2].href = `/[lang]/my-cloud/servers/[id]/server-settings/addons`;
      ret[2].as = `/${lang}/my-cloud/servers/${id}/server-settings/addons`;
    }

    return ret;
  }, [dispatch, intl, isAllowed, lang, router, id, server, user.role]);

  function handleCloneServerModalClosed() {
    setIsCloneServerModalOpen(false);
  }

  function handleBackToServersOfClientClicked() {
    dispatch(setServersFetchingFilter(server.userEmail));
    router.push(`/${lang}/my-cloud/servers?backToClient=${server.user_id}`);
  }

  async function handleUnlockClicked() {
    const state = await confirm({
      title: intl.formatMessage({ id: "overview.server-unlock" }),
      message: intl.formatMessage({ id: "overview.server-unlock-confirm" }),
    });

    if (state !== "button2") {
      return;
    }

    setUnlocking(true);
    await ajax("/servers/unlock", { serverID: server._id });
  }

  function handleCreateNewServerModalClosed() {
    setIsCreateNewServerModalOpen(false);
  }

  const managedHosting = useMemo(() => {
    if (!server || !server.addons) {
      return false;
    }

    const addon = server.addons.find(
      (addon) => addon.addon_type === "managed-hosting"
    );

    return addon ? addon.name : false;
  }, [server]);

  const cpanelLicense = useMemo(() => {
    if (!server || !server.addons) {
      return false;
    }

    const addon = server.addons.find(
      (addon) => addon.addon_type === "cpanel-license"
    );

    return addon ? addon.name : false;
  }, [server]);

  const rebootRequired = useMemo(() => {
    if (!server) {
      return false;
    }

    let messages = [];

    if (server.reboot_required === "password") {
      messages.push(
        intl.formatMessage({
          id: `reboot_required.password`,
        })
      );
    }

    if (server.reboot_required === "network") {
      messages.push(
        intl.formatMessage({
          id: `reboot_required.network`,
        })
      );
    }

    if (server.configDiff) {
      Object.keys(server.configDiff)
        .sort()
        .forEach((key) => {
          if (["cores", "boot", "nameserver"].includes(key)) {
            messages.push(
              intl.formatMessage({
                id: `reboot_required.${key}`,
              })
            );
          } else if (key.match(/ipconfig[0-9][\d]*/g)) {
            messages.push(intl.formatMessage({ id: `reboot_required.ip` }));
          } else if (!["vcpus"].includes(key)) {
            messages.push(key);
          }
        });
    }

    messages = [...new Set(messages)];

    return messages.length > 0 ? messages.join("<br />") : "";
  }, [server, intl]);

  function renderStatusSvg() {
    if (server.isWorking) {
      return <Spinner />;
    }

    return server.running && <PlaySvg />;
  }

  if (!server) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backArrows}>
        <Link
          href="/[lang]/my-cloud/servers"
          to={`/${lang}/my-cloud/servers`}
          className={styles.backArrow}
        >
          <LongArrowLeft />
          <FormattedMessage id="server-layout.back-to-servers" />
        </Link>

        {[WHITELABEL, SUPER_ADMIN].includes(user.role) && (
          <div
            className={styles.backArrow}
            onClick={handleBackToServersOfClientClicked}
          >
            <LongArrowLeft />
            <FormattedMessage
              id="server-layout.back-to-servers-of-client"
              values={{ email: server.userEmail }}
            />
          </div>
        )}
      </div>

      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <div className={styles.os}>
            <img
              src={`/img/${getOsLogo(server.image)}`}
              width={48}
              height={48}
            />
          </div>
          <div>
            <div className={styles.bold}>
              {server.hostname}
              {renderStatusSvg()}
            </div>
            <div className={styles.normal}>
              {`${server.dedicatedip ? `${server.dedicatedip} · ` : ""}`}
              {getImageName(server.image)} · {getCountryByCode(server.location)}
            </div>

            <div className={styles.tags}>
              {server.readOnlyMode && (
                <div className={styles.tag}>
                  <FormattedMessage id="general.read-only" />
                </div>
              )}
              {server.lockMode && (
                <div className={styles.tag}>
                  <FormattedMessage id="general.locked" />
                  <span
                    className={`${styles.unlockButton} ${
                      unlocking ? styles.disabled : ""
                    }`}
                    onClick={handleUnlockClicked}
                  >
                    <FormattedMessage id="general.unlock" />
                  </span>
                </div>
              )}
              {managedHosting && (
                <div className={styles.tag}>{managedHosting}</div>
              )}
              {cpanelLicense && (
                <div className={styles.tag}>{cpanelLicense}</div>
              )}
            </div>
          </div>
        </div>

        {rebootRequired && (
          <>
            <div
              id="tooltip-reboot_required"
              className={styles.rebootRequiredWrapper}
            >
              <div>
                <FormattedMessage id="general.reboot_required" />
                <InfoSvg />
              </div>
            </div>

            <Tooltip
              placement="bottom"
              isOpen={isRebootRequiredTooltipOpen}
              target="tooltip-reboot_required"
              toggle={() =>
                setIsRebootRequiredTooltipOpen(!isRebootRequiredTooltipOpen)
              }
            >
              <div
                dangerouslySetInnerHTML={{ __html: sanitize(rebootRequired) }}
              ></div>
            </Tooltip>
          </>
        )}

        <div className={styles.menuText}>
          <FormattedMessage id="general.actions" />
        </div>

        <div className={styles.menu}>
          <CustomMenu
            isOpen={isMenuOpen}
            toggle={() => setIsMenuOpen(!isMenuOpen)}
          >
            {server.status === "Active" && isAllowed("servers.change-plan") && (
              <CustomMenuItem
                href="/[lang]/my-cloud/servers/[id]/server-settings/change-plan"
                as={`/${lang}/my-cloud/servers/${server._id}/server-settings/change-plan`}
              >
                <FormattedMessage id="create-new-server-layout.change-plan" />
              </CustomMenuItem>
            )}

            {server.status === "Active" &&
              isAllowed("servers.start-stop-reset") &&
              !server.running && (
                <CustomMenuItem
                  disabled={server.isWorking}
                  onClick={() =>
                    handleServerActionClicked(
                      "start-server",
                      server,
                      setIsCloneServerModalOpen,
                      ajax,
                      intl,
                      router,
                      lang,
                      confirm,
                      prompt,
                      dispatch
                    )
                  }
                >
                  <FormattedMessage id="create-new-server-layout.start-server" />
                </CustomMenuItem>
              )}

            {server.status === "Active" &&
              isAllowed("servers.start-stop-reset") &&
              server.running && (
                <>
                  {server.ping && (
                    <>
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "soft-shutdown",
                            server,
                            setIsCloneServerModalOpen,
                            ajax,
                            intl,
                            router,
                            lang,
                            confirm,
                            prompt,
                            dispatch
                          )
                        }
                      >
                        <FormattedMessage id="create-new-server-layout.soft-shutdown-server" />
                      </CustomMenuItem>
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "soft-reboot",
                            server,
                            setIsCloneServerModalOpen,
                            ajax,
                            intl,
                            router,
                            lang,
                            confirm,
                            prompt,
                            dispatch
                          )
                        }
                      >
                        <FormattedMessage id="create-new-server-layout.soft-reboot-server" />
                      </CustomMenuItem>
                    </>
                  )}

                  <>
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() =>
                        handleServerActionClicked(
                          "hard-shutdown",
                          server,
                          setIsCloneServerModalOpen,
                          ajax,
                          intl,
                          router,
                          lang,
                          confirm,
                          prompt,
                          dispatch
                        )
                      }
                    >
                      <FormattedMessage id="create-new-server-layout.hard-shutdown-server" />
                    </CustomMenuItem>
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() =>
                        handleServerActionClicked(
                          "hard-reboot",
                          server,
                          setIsCloneServerModalOpen,
                          ajax,
                          intl,
                          router,
                          lang,
                          confirm,
                          prompt,
                          dispatch
                        )
                      }
                    >
                      <FormattedMessage id="create-new-server-layout.hard-reboot-server" />
                    </CustomMenuItem>
                  </>
                </>
              )}

            {isAllowed("servers.reinstall-destroy") && (
              <CustomMenuItem
                disabled={server.isWorking}
                onClick={() =>
                  handleServerActionClicked(
                    "reinstall",
                    server,
                    setIsCloneServerModalOpen,
                    ajax,
                    intl,
                    router,
                    lang,
                    confirm,
                    prompt,
                    dispatch
                  )
                }
              >
                <FormattedMessage id="create-new-server-layout.reinstall-server" />
              </CustomMenuItem>
            )}

            {server.status === "Active" && isAllowed("servers.clone") && (
              <CustomMenuItem
                disabled={server.isWorking}
                onClick={() =>
                  handleServerActionClicked(
                    "clone",
                    server,
                    setIsCloneServerModalOpen,
                    ajax,
                    intl,
                    router,
                    lang,
                    confirm,
                    prompt,
                    dispatch
                  )
                }
              >
                <FormattedMessage id="create-new-server-layout.clone-server" />
              </CustomMenuItem>
            )}

            {server.status === "Active" && isAllowed("servers.edit-details") && (
              <CustomMenuItem
                onClick={() =>
                  handleServerActionClicked(
                    "manage-tags",
                    server,
                    setIsCloneServerModalOpen,
                    ajax,
                    intl,
                    router,
                    lang,
                    confirm,
                    prompt,
                    dispatch
                  )
                }
              >
                <FormattedMessage id="create-new-server-layout.manage-tags" />
              </CustomMenuItem>
            )}

            {server.status === "Active" &&
              isAllowed("super-admin.servers.manage") && (
                <CustomMenuItem
                  onClick={() => {
                    setSelectedServer(server);
                    setIsCreateNewServerModalOpen(true);
                  }}
                >
                  <FormattedMessage id="create-new-server-layout.manage-server" />
                </CustomMenuItem>
              )}

            {server.status === "Active" &&
              isAllowed("servers.reinstall-destroy") && (
                <>
                  <DropdownItem divider />

                  <CustomMenuItem
                    disabled={server.isWorking}
                    color="red"
                    onClick={() =>
                      handleServerActionClicked(
                        "destroy",
                        server,
                        setIsCloneServerModalOpen,
                        ajax,
                        intl,
                        router,
                        lang,
                        confirm,
                        prompt,
                        dispatch
                      )
                    }
                  >
                    <FormattedMessage id="create-new-server-layout.destroy" />
                  </CustomMenuItem>
                </>
              )}
          </CustomMenu>
        </div>
      </div>
      <div className={styles.navigatorWrapper}>
        <Navigator items={items} styleMode={2} />
      </div>
      <div className={styles.hr}>
        <hr />
      </div>
      <div>{children}</div>

      <CloneServerModal
        server={server}
        isOpen={isCloneServerModalOpen}
        onClose={handleCloneServerModalClosed}
      />

      <CreateNewServerModal
        isOpen={isCreateNewServerModalOpen}
        onClose={handleCreateNewServerModalClosed}
        user={user}
        editServer={selectedServer}
      />
    </div>
  );
}

ServerLayout.propTypes = {
  children: PropTypes.any,
};

export default ServerLayout;
