import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { Tooltip } from "reactstrap";

function LetterAvatar({ fullName, children }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [id, setID] = useState(null);

  useEffect(() => {
    setID(shortid.generate());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div id={`tooltip-${id}`}>{children}</div>

      {id && (
        <Tooltip
          placement="bottom"
          isOpen={isTooltipOpen}
          target={`tooltip-${id}`}
          toggle={() => setIsTooltipOpen(!isTooltipOpen)}
        >
          {fullName}
        </Tooltip>
      )}
    </div>
  );
}

LetterAvatar.propTypes = {
  fullName: PropTypes.string,
  children: PropTypes.any,
};
export default LetterAvatar;
