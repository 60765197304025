import styles from "./reset-all-servers-password.module.scss";

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../icon-button";
import SmsVerificationModal from "../modals/sms-verification";

export default function ResetAllServersPassword() {
  const intl = useIntl();

  const [isSmsVerificationModalOpen, setIsSmsVerificationModalOpen] = useState(
    false
  );

  function handleSmsVerificationModalOpen() {
    setIsSmsVerificationModalOpen(true);
  }

  function handleSmsVerificationModalClosed() {
    setIsSmsVerificationModalOpen(false);
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: "reset-all-servers-password.description",
          }),
        }}
      ></div>

      <div>
        <IconButton
          color="light-purple"
          onClick={handleSmsVerificationModalOpen}
        >
          <FormattedMessage id="reset-all-servers-password.reset" />
        </IconButton>
      </div>

      <SmsVerificationModal
        title={intl.formatMessage({
          id: "reset-all-servers-password.sms-modal-title",
        })}
        content={intl.formatMessage({
          id: "reset-all-servers-password.sms-modal-content",
        })}
        isOpen={isSmsVerificationModalOpen}
        onClose={handleSmsVerificationModalClosed}
        action="reset-all-servers-passwords"
      />
    </div>
  );
}
