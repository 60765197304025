import styles from "./cpanel-license.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import RadioSelector from "../radio-selector";
import { useAjax } from "../../utils/hooks";
import { currencySymbols } from "../../utils/billing";
import CheckboxSelector from "../checkbox-selector";

function CpanelLicense({
  incStep,
  selectedAddons,
  setSelectedAddons,
  setIsNextButtonDisabled = () => {},
  defaultSelected = null,
}) {
  const ajax = useAjax();
  const intl = useIntl();

  const [cpanelItems, setCpanelItems] = useState([]);
  const [addons, setAddons] = useState([]);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll", {
      type: ["cpanel-addon", "cpanel-license"],
    });

    if (data.result === "success") {
      const cpanelItems = data.products
        .filter(
          (product) =>
            product.type === "cpanel-license" && !product.name.includes("Solo")
        )
        .map((product) => ({
          _id: product._id,
          title: product.name,
          value: product._id,
          selected: false,
          price: {
            num: product.price,
            currency: currencySymbols[product.currency],
          },
          product,
        }));

      if (cpanelItems.length === 0) {
        return incStep();
      }

      setCpanelItems(cpanelItems);

      if (defaultSelected) {
        const cpanelItem = cpanelItems.find(
          (item) => item._id === defaultSelected
        );

        if (cpanelItem) {
          setSelectedAddons({ [cpanelItem._id]: cpanelItem });
        }
      }

      setAddons(
        data.products
          .filter((product) => product.type === "cpanel-addon")
          .map((product) => ({
            _id: product._id,
            title: `<b>${product.name}</b>`,
            description: product.description,
            selected: false,
            price: {
              num: product.price,
              currency: currencySymbols[product.currency],
            },
          }))
      );
    }
  }, [ajax, incStep, defaultSelected, setSelectedAddons]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    const keys = Object.keys(selectedAddons);

    for (let i = 0; i < keys.length; i++) {
      const cpanelItem = cpanelItems.find((item) => item.value === keys[i]);

      if (cpanelItem) {
        return setIsNextButtonDisabled(false);
      }
    }

    setIsNextButtonDisabled(true);
  }, [cpanelItems, selectedAddons, setIsNextButtonDisabled]);

  function handleSelectorChanged(item) {
    if (selectedAddons[item._id]) {
      delete selectedAddons[item._id];
    } else {
      selectedAddons[item._id] = item;
    }

    setSelectedAddons({ ...selectedAddons });
  }

  function handleLicenseChanged(value) {
    const keys = Object.keys(selectedAddons);

    for (let i = 0; i < keys.length; i++) {
      const cpanelItem = cpanelItems.find((item) => item.value === keys[i]);

      if (cpanelItem) {
        delete selectedAddons[cpanelItem._id];
      }
    }

    if (selectedAddons[value]) {
      delete selectedAddons[value];
    } else {
      const cpanelItem = cpanelItems.find((item) => item.value === value);

      selectedAddons[value] = cpanelItem.product;
    }

    setSelectedAddons({ ...selectedAddons });
  }

  function getCurrentLicenseValue() {
    const keys = Object.keys(selectedAddons);

    for (let i = 0; i < keys.length; i++) {
      const cpanelItem = cpanelItems.find((item) => item.value === keys[i]);

      if (cpanelItem) {
        return cpanelItem.value;
      }
    }

    return null;
  }

  return (
    <div className={styles.wrapper}>
      {cpanelItems.length > 0 && (
        <>
          <div className={styles.title}>
            <FormattedMessage id="cpanel-license.select-license" />
          </div>
          <RadioSelector
            className={styles.selector}
            value={getCurrentLicenseValue()}
            onChange={handleLicenseChanged}
            items={cpanelItems}
          />
        </>
      )}

      {addons.length > 0 && (
        <>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "cpanel-license.cpanel-additional-services",
              }),
            }}
          ></div>
          <CheckboxSelector
            className={styles.selector}
            onChange={handleSelectorChanged}
            selectedItems={selectedAddons}
            items={addons}
          />
        </>
      )}
    </div>
  );
}

CpanelLicense.propTypes = {
  incStep: PropTypes.func,
  cpanelLicense: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setCpanelLicense: PropTypes.func,
  selectedAddons: PropTypes.object,
  setSelectedAddons: PropTypes.func,
  setIsNextButtonDisabled: PropTypes.func,
  defaultSelected: PropTypes.string,
};

export default CpanelLicense;
