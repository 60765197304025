import styles from "./step2.module.scss";

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "../../phone-input";
import IconButton from "../../icon-button";
import { useAjax, useInitialDataFetching, useUser } from "../../../utils/hooks";
import { countriesPhoneCodes } from "../../../utils/countries";
import CustomText from "../../custom-text";

const RESEND_TIMER_INIT = 120;
let timerID;

function CompleteRegistrationModalStep2({ onNext, onBack }) {
  const ajax = useAjax();
  const user = useUser();
  const intl = useIntl();
  const initialDataFetching = useInitialDataFetching();

  const [mode, setMode] = useState("before");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(RESEND_TIMER_INIT);
  const counterRef = useRef(counter);
  counterRef.current = counter;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    timerID = setInterval(() => {
      if (counterRef.current > 0) {
        setCounter(counterRef.current - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  async function handleSendClicked() {
    setError(false);

    if (phoneNumber.trim() === "" || isNaN(phoneNumber)) {
      return;
    }

    setLoading(true);
    const data = await ajax(`/users/sendSMS`, {
      phoneCode: countriesPhoneCodes[user.country],
      phoneNumber,
    });
    setLoading(false);

    if (data.result === "success") {
      setMode("after");

      setCounter(RESEND_TIMER_INIT);
    } else {
      setError(data.message);
    }
  }

  async function handleNextClicked() {
    setError(false);

    if (code.trim() === "") {
      return;
    }

    setLoading(true);
    const data = await ajax(`/users/verifySMS`, {
      code,
      action: "registration-step",
    });

    await initialDataFetching();
    setLoading(false);

    if (data.result === "success") {
      onNext();
    } else {
      setError("complete-registration-modal.wrong-code");
    }
  }

  function handleResendClicked() {
    setCounter(RESEND_TIMER_INIT);
    handleSendClicked();
  }

  function onClose() {
    setError(false);

    if (mode === "before") {
      onBack();
    } else {
      setMode("before");
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <FormattedMessage id="complete-registration-modal.verify-account" />
      </div>

      {mode === "before" && (
        <>
          <div className={styles.text}>
            <FormattedMessage id="complete-registration-modal.verify-account-text" />
          </div>
          <div>
            <PhoneInput
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              countryCode={user.country}
            />
          </div>
        </>
      )}

      {mode === "after" && (
        <>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: "complete-registration-modal.after-sms",
                },
                {
                  number: `(+${
                    countriesPhoneCodes[user.country]
                  })${phoneNumber}`,
                }
              ),
            }}
          ></div>
          <div>
            <CustomText
              placeholder={intl.formatMessage({
                id: "complete-registration-modal.enter-code",
              })}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
        </>
      )}

      {error && (
        <div className={`error ${styles.error}`}>
          <FormattedMessage id={error} />
        </div>
      )}

      <div className={styles.buttons}>
        {mode === "before" && (
          <IconButton
            disabled={loading}
            color="purple"
            onClick={handleSendClicked}
          >
            <FormattedMessage id="general.send" />
          </IconButton>
        )}

        {mode === "after" && (
          <div className={styles.afterButtons}>
            <IconButton
              disabled={loading}
              color="purple"
              onClick={handleNextClicked}
            >
              <FormattedMessage id="general.next" />
            </IconButton>
            <IconButton
              disabled={loading || counterRef.current > 0}
              color="purple"
              onClick={handleResendClicked}
            >
              {counterRef.current > 0 ? (
                <FormattedMessage
                  id="general.resend-code-counter"
                  values={{ counter: counterRef.current }}
                />
              ) : (
                <FormattedMessage id="general.resend-code" />
              )}
            </IconButton>
          </div>
        )}

        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.back" />
        </IconButton>
      </div>
    </div>
  );
}

CompleteRegistrationModalStep2.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default CompleteRegistrationModalStep2;
