import styles from "./private-networking.module.scss";

import React, { useEffect, useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import IpSubnetCalculator from "ip-subnet-calculator";
import CustomText from "../custom-text";
import { useAjax, useUser } from "../../utils/hooks";
import { validateIPaddress } from "../../utils/networks";
import CustomReactSelect from "../custom-react-select";

function PrivateNetworking({
  vlans,
  setVlans,
  selectedVlan,
  setSelectedVlan,
  location,
  vlanName,
  setVlanName,
  ipAddressScope,
  setIpAddressScope,
  ipAddressScopeCustom,
  setIpAddressScopeCustom,
  netmask,
  setNetmask,
  gateway,
  setGateway,
  selectedIP,
  setSelectedIP,
  server,
}) {
  const ajax = useAjax();
  const intl = useIntl();
  const user = useUser();
  const serverRef = useRef(server);

  const [lansOptions, setLansOptions] = useState([]);
  const [cidrOptions, setCidrOptions] = useState([]);
  const [netmasksOptions, setNetmasksOptions] = useState([]);
  const [ipsOptions, setIpsOptions] = useState([]);
  const [gatewayOptions, setGatewayOptions] = useState([]);

  const getVLANs = useCallback(async () => {
    const data = await ajax("/network/getVLANs", {
      userIDToWork: serverRef.current ? serverRef.current.user_id : user._id,
      location,
      type: "lan",
    });

    setVlans(data.vlans);
  }, [ajax, location, setVlans, user]);

  useEffect(() => {
    getVLANs();
  }, [getVLANs]);

  useEffect(() => {
    setLansOptions([
      {
        value: "NEW LAN",
        label: intl.formatMessage({ id: "private-networking.create-new-lan" }),
      },
      ...vlans.map((vlan) => ({
        value: vlan._id,
        label: vlan.vlanName,
      })),
    ]);
  }, [intl, vlans]);

  useEffect(() => {
    if (!selectedVlan) {
      return;
    }

    if (selectedVlan.value === "NEW LAN") {
      setCidrOptions([
        { value: "172.16.0.0", label: "172.16.0.0" },
        { value: "192.168.0.0", label: "192.168.0.0" },
        { value: "10.0.0.0", label: "10.0.0.0" },
        {
          value: "custom",
          label: intl.formatMessage({ id: "general.custom..." }),
        },
      ]);
    } else {
      const vlan = vlans.find((vlan) => vlan._id === selectedVlan.value);

      setVlanName(vlan.vlanName);
      setCidrOptions(
        vlan.subnets.map((subnet) => ({
          value: subnet.ipAddress,
          label: subnet.ipAddress,
        }))
      );
    }
  }, [intl, selectedVlan, vlans, setVlanName]);

  useEffect(() => {
    if (!selectedVlan) {
      return;
    }

    if (selectedVlan.value === "NEW LAN") {
      setNetmasksOptions(
        [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((number) => ({
          value: number,
          label: number,
        }))
      );
    } else if (ipAddressScope) {
      const vlan = vlans.find((vlan) => vlan._id === selectedVlan.value);
      const subnet = vlan.subnets.find(
        (subnet) => subnet.ipAddress === ipAddressScope.value
      );

      setNetmasksOptions([{ value: subnet.netmask, label: subnet.netmask }]);
    }
  }, [intl, ipAddressScope, selectedVlan, vlans]);

  useEffect(() => {
    if (!selectedVlan || !ipAddressScope || !netmask) {
      return;
    }

    const ipSubnetCalc = IpSubnetCalculator.calculateSubnetMask(
      ipAddressScope.value === "custom"
        ? ipAddressScopeCustom
        : ipAddressScope.value,
      netmask.value
    );

    if (!ipSubnetCalc) {
      return;
    }

    const ips = [];

    for (let ip = ipSubnetCalc.ipLow; ip <= ipSubnetCalc.ipHigh; ip++) {
      const ipStr = IpSubnetCalculator.toString(ip);

      if (ipStr.endsWith(".0") || ipStr.endsWith(".255")) {
        continue;
      }

      ips.push({ label: ipStr, value: ipStr });
    }

    setIpsOptions(ips);

    if (selectedVlan.value === "NEW LAN") {
      setGatewayOptions([
        { value: "", label: intl.formatMessage({ id: "general.none" }) },
        ...ips,
      ]);
    } else {
      const vlan = vlans.find((vlan) => vlan._id === selectedVlan.value);
      const subnet = vlan.subnets.find(
        (subnet) => subnet.ipAddress === ipAddressScope.value
      );

      setGatewayOptions([
        {
          value: subnet.gateway,
          label: subnet.gateway || intl.formatMessage({ id: "general.none" }),
        },
      ]);
    }
  }, [
    intl,
    ipAddressScope,
    ipAddressScopeCustom,
    netmask,
    selectedVlan,
    vlans,
  ]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.privateNetworkingWrapper}
    >
      <div className={styles.box}>
        <FormattedMessage id="private-networking.nic" tagName="span" />
        <CustomReactSelect
          instanceId="private-networking-nic"
          options={lansOptions}
          value={selectedVlan}
          onChange={(item) => setSelectedVlan(item)}
        />
      </div>

      {selectedVlan && (
        <>
          <div className={styles.box}>
            <FormattedMessage
              id="private-networking.cidr-block"
              tagName="span"
            />

            <CustomReactSelect
              options={cidrOptions}
              value={ipAddressScope}
              onChange={(item) => setIpAddressScope(item)}
            />
          </div>

          {ipAddressScope && ipAddressScope.value === "custom" && (
            <div className={styles.box}>
              <span className={styles.text}>
                <FormattedMessage id="private-networking.ip-address" />
              </span>
              <CustomText
                value={ipAddressScopeCustom}
                onChange={(e) => setIpAddressScopeCustom(e.target.value)}
              />
            </div>
          )}

          <div className={styles.box}>
            <FormattedMessage id="private-networking.netmask" tagName="span" />
            <CustomReactSelect
              options={netmasksOptions}
              value={netmask}
              onChange={(item) => setNetmask(item)}
            />
          </div>

          {ipAddressScope &&
            (ipAddressScope.value !== "custom" ||
              validateIPaddress(ipAddressScopeCustom)) &&
            netmask &&
            netmask.value && (
              <>
                <div className={styles.hr}>
                  <hr />
                </div>
                <div className={styles.box}>
                  <span className={styles.text}>
                    <FormattedMessage id="private-networking.vlan-name" />
                  </span>

                  <CustomText
                    disabled={selectedVlan.value !== "NEW LAN"}
                    value={vlanName}
                    onChange={(e) => setVlanName(e.target.value)}
                  />
                </div>
                <div className={styles.box}>
                  <FormattedMessage id="private-networking.ip" tagName="span" />
                  <CustomReactSelect
                    options={ipsOptions}
                    value={selectedIP}
                    onChange={(item) => setSelectedIP(item)}
                  />
                </div>
                <div className={styles.box}>
                  <FormattedMessage
                    id="private-networking.gateway"
                    tagName="span"
                  />
                  <CustomReactSelect
                    options={gatewayOptions}
                    value={gateway}
                    onChange={(item) => setGateway(item)}
                  />
                </div>
              </>
            )}
        </>
      )}
    </div>
  );
}

PrivateNetworking.propTypes = {
  vlans: PropTypes.array,
  setVlans: PropTypes.func,
  selectedVlan: PropTypes.object,
  setSelectedVlan: PropTypes.func,
  location: PropTypes.string,
  vlanName: PropTypes.string,
  setVlanName: PropTypes.func,
  ipAddressScope: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setIpAddressScope: PropTypes.func,
  ipAddressScopeCustom: PropTypes.string,
  setIpAddressScopeCustom: PropTypes.func,
  netmask: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setNetmask: PropTypes.func,
  gateway: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setGateway: PropTypes.func,
  selectedIP: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setSelectedIP: PropTypes.func,
  server: PropTypes.object,
};

export default PrivateNetworking;
