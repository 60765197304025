import styles from "./unpaid-customers.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useLang, useWLDVPS } from "../../../utils/hooks";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import {} from "../../../utils";
import Spinner from "../../../components/spinner";
import BasicTable from "../../../components/basic-table";
import { format } from "date-fns";

export default function BillingUnpaidCustomers() {
  const ajax = useAjax();
  const intl = useIntl();
  const lang = useLang();
  const wldvps = useWLDVPS();

  const [users, setUsers] = useState(null);

  const getUnpaidCustomers = useCallback(async () => {
    const data = await ajax("/billing/getUnpaidCustomers");

    setUsers(data.users);
  }, [ajax]);

  useEffect(() => {
    getUnpaidCustomers();
  }, [getUnpaidCustomers]);

  const usersKeys = useMemo(() => users && Object.keys(users), [users]);

  return (
    <WithRole permission="super-admin.unpaid-customers">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "billing-unpaid-customers.main-title",
        })}
      >
        <div className={styles.wrapper}>
          {!users && (
            <Box>
              <Spinner />
            </Box>
          )}

          {usersKeys?.length === 0 && (
            <Box>
              <FormattedMessage id="general.no-rows" />
            </Box>
          )}

          {usersKeys?.map((userKey) => (
            <Box title={users[userKey].email} key={userKey}>
              <BasicTable>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="billing-unpaid-customers.name" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-unpaid-customers.type" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-unpaid-customers.is-first-month" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-unpaid-customers.pay-day" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-unpaid-customers.pay-every" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-unpaid-customers.next-pay-day" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users[userKey].servers.map((server, key) => {
                    const serverPayment = wldvps
                      ? server.whitelabel_payment
                      : server.payment;

                    return (
                      <tr key={key}>
                        <td>
                          <a
                            href={`/${lang}/my-cloud/servers/${server._id}/overview`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {server.hostname}
                          </a>
                        </td>
                        <td>Server</td>
                        <td>
                          {serverPayment.is_first_month ? (
                            <FormattedMessage id="general.yes" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{serverPayment.payDay}</td>
                        <td>{serverPayment.payEvery}</td>
                        <td>
                          {format(
                            new Date(serverPayment.next_pay_day),
                            "d/M/y"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {users[userKey].addons.map((addon, key) => (
                    <tr key={key}>
                      <td>
                        <a
                          href={`/${lang}/my-cloud/servers/${addon.server_id}/server-settings/addons`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {addon.name}
                        </a>
                      </td>
                      <td>Addon</td>
                      <td>
                        {addon.is_first_month ? (
                          <FormattedMessage id="general.yes" />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{addon.payDay}</td>
                      <td>{addon.payEvery}</td>
                      <td>{format(new Date(addon.next_pay_day), "d/M/y")}</td>
                    </tr>
                  ))}
                  {users[userKey].otherProducts.map((product, key) => (
                    <tr key={key}>
                      <td>
                        <a
                          href={`/${lang}/clients/clients-list/${userKey}/other-products`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {product.name}
                        </a>
                      </td>
                      <td>Other product</td>
                      <td>
                        {product.is_first_month ? (
                          <FormattedMessage id="general.yes" />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{product.payDay}</td>
                      <td>{product.payEvery}</td>
                      <td>{format(new Date(product.next_pay_day), "d/M/y")}</td>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>
            </Box>
          ))}
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
