import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";

function ShowEmailContentModal({ log, isOpen, onClose }) {
  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>{log?.subject}</ModalHeader>
      <ModalBody>
        {log && <div dangerouslySetInnerHTML={{ __html: log.content }}></div>}
      </ModalBody>
      <ModalFooter>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

ShowEmailContentModal.propTypes = {
  log: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ShowEmailContentModal;
