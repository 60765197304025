import styles from "./headers-with-navs.module.scss";

import React from "react";
import PropTypes from "prop-types";
import Navigator from "../navigator";
import UserSelectorLayout from "../user-selector-layout";

function HeaderWithNavs({
  title,
  navItems = [],
  withUserSelector = null,
  children,
}) {
  function render() {
    if (withUserSelector) {
      return (
        <UserSelectorLayout
          className={withUserSelector.className}
          userIDToWork={withUserSelector.userIDToWork}
          setUserIDToWork={withUserSelector.setUserIDToWork}
        >
          {children}
        </UserSelectorLayout>
      );
    }

    return children;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>

      {navItems.length > 0 && (
        <>
          <div className={styles.navigatorWrapper}>
            <Navigator items={navItems} styleMode={2} />
          </div>
          <div className={styles.hr}>
            <hr />
          </div>
        </>
      )}
      <div>{render()}</div>
    </div>
  );
}

HeaderWithNavs.propTypes = {
  title: PropTypes.string,
  navItems: PropTypes.array,
  buttons: PropTypes.array,
  withUserSelector: PropTypes.object,
  children: PropTypes.any,
};

export default HeaderWithNavs;
