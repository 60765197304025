import styles from "./bandwidth-form.module.scss";

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getAllWLDVPS } from "../../../utils/wldvps";
import { useAjax, useUser, useWLDVPS } from "../../../utils/hooks";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import BasicTable from "../../../components/basic-table";
import BandwidthInfoModal from "../../../components/modals/bandwidth-info";
import { ReactComponent as InfoSvg } from "../../../components/svgs/info.svg";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {} from "../../../utils";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";

export default function BandwidthForm() {
  const ajax = useAjax();
  const intl = useIntl();
  const user = useUser();
  const wldvps = useWLDVPS();

  const [servers, setServers] = useState(null);
  const [filteredServers, setFilteredServers] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null);

  const [filter, setFilter] = useState("");
  const [serversType, setServersType] = useState("all");
  const [whitelabel, setWhitelabel] = useState("all");
  const [whitelabels, setWhitelabels] = useState([]);
  const [isWhitelabelDropdownOpen, setIsWhitelabelDropdownOpen] = useState(
    false
  );
  const [isServersTypeDropdownOpen, setIsServersTypeDropdownOpen] = useState(
    false
  );

  const [isBandwidthInfoModalOpen, setIsBandwidthInfoModalOpen] = useState(
    false
  );

  const [loading, setLoading] = useState(false);

  const getAllBandwidthData = useCallback(async () => {
    const data = await ajax("/bandwidth-form/getAll");

    setServers(data.servers);

    return data.servers;
  }, [ajax]);

  useEffect(() => {
    getAllBandwidthData();
  }, [getAllBandwidthData]);

  useEffect(() => {
    if (user.whitelabelSettings) {
      const whitelabels = getAllWLDVPS(user.whitelabelSettings);
      setWhitelabels(whitelabels);
      setWhitelabel(whitelabels[0]);
    }
  }, [user.whitelabelSettings]);

  useEffect(() => {
    if (!servers) {
      return;
    }

    setFilteredServers(
      servers.filter(
        (server) =>
          (!filter ||
            server.hostname.toLowerCase().includes(filter.toLowerCase())) &&
          (whitelabel === "all" || server.user.whitelabel === whitelabel) &&
          (serversType === "all" || server.payment.payment_type === serversType)
      )
    );
  }, [filter, servers, whitelabel, serversType]);

  function handleBandwidthInfoModalOpen(server) {
    setSelectedServer(server);
    setIsBandwidthInfoModalOpen(true);
  }

  function handleBandwidthInfoModalClose() {
    setIsBandwidthInfoModalOpen(false);
  }

  function handleWhitelabelDropdownToggle() {
    setIsWhitelabelDropdownOpen(!isWhitelabelDropdownOpen);
  }

  async function handleBandwidthInfoModalUpdate() {
    const servers = await getAllBandwidthData();

    setSelectedServer(
      servers.find((s) => s._id.toString() === selectedServer._id.toString())
    );
  }

  function handleServersTypeDropdownToggle() {
    setIsServersTypeDropdownOpen(!isServersTypeDropdownOpen);
  }

  const handleRunCronClicked = useCallback(async () => {
    setLoading(true);
    await ajax("/billing/runBandwidthCron");
    setLoading(false);
  }, [ajax]);

  const handleRunCronFakeClicked = useCallback(async () => {
    setLoading(true);
    await ajax("/billing/runBandwidthCron", { fake: true });
    setLoading(false);
  }, [ajax]);

  const menuButtons = useMemo(
    () =>
      [SUPER_ADMIN].includes(user.role)
        ? [
            {
              title: intl.formatMessage({
                id: "traffic-form.run-cron-for-fake",
              }),
              onClick: handleRunCronFakeClicked,
              disabled: loading,
            },
            {
              title: intl.formatMessage({
                id: "traffic-form.run-cron-for-real",
              }),
              onClick: handleRunCronClicked,
              disabled: loading,
            },
          ]
        : null,
    [intl, handleRunCronClicked, handleRunCronFakeClicked, loading, user.role]
  );

  return (
    <WithRole permission="billing.bandwidth-form">
      <LayoutMainAction
        title={intl.formatMessage({ id: "traffic-form.main-title" })}
        buttons={menuButtons}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            {[SUPER_ADMIN, WHITELABEL].includes(user.role) && !wldvps && (
              <div>
                <FormattedMessage id="general.whitelabel" />
                <ButtonDropdown
                  className={styles.ghost}
                  isOpen={isWhitelabelDropdownOpen}
                  toggle={handleWhitelabelDropdownToggle}
                >
                  <DropdownToggle caret>
                    {whitelabel === "all" ? (
                      <FormattedMessage id="general.all" />
                    ) : (
                      whitelabel || "DreamVPS"
                    )}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => setWhitelabel("all")}>
                      <FormattedMessage id="general.all" />
                    </DropdownItem>
                    {whitelabels.map((whitelabel, key) => (
                      <DropdownItem
                        key={key}
                        onClick={() => setWhitelabel(whitelabel)}
                      >
                        {whitelabel || "DreamVPS"}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            )}

            <div>
              <FormattedMessage id="traffic-form.server-type" />
              <ButtonDropdown
                className={styles.ghost}
                isOpen={isServersTypeDropdownOpen}
                toggle={handleServersTypeDropdownToggle}
              >
                <DropdownToggle caret>{serversType}</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => setServersType("all")}>
                    <FormattedMessage id="general.all" />
                  </DropdownItem>
                  <DropdownItem onClick={() => setServersType("monthly")}>
                    <FormattedMessage id="general.monthly" />
                  </DropdownItem>
                  <DropdownItem onClick={() => setServersType("hourly")}>
                    <FormattedMessage id="general.hourly" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>

            <div className={styles.filterInputWrapper}>
              <input
                className={styles.filterInput}
                placeholder={intl.formatMessage({ id: "general.filter..." })}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          </div>

          <Box>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="traffic-form.server" />
                  </th>
                  <th>
                    <FormattedMessage id="traffic-form.server-type" />
                  </th>
                  <th>
                    <FormattedMessage id="traffic-form.unpaid-traffic" />
                  </th>
                  {[SUPER_ADMIN, WHITELABEL].includes(user) && !wldvps && (
                    <th>
                      <FormattedMessage id="traffic-form.whitelabel" />
                    </th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredServers?.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {filteredServers
                  ?.sort((a, b) => b.unpaidBandwidth - a.unpaidBandwidth)
                  .map((server, key) => (
                    <tr key={key}>
                      <td>{server.hostname}</td>
                      <td>{server.payment.payment_type}</td>
                      <td>
                        {(server.unpaidBandwidth / Math.pow(1024, 3)).toFixed(
                          2
                        )}
                        GB
                      </td>
                      {[SUPER_ADMIN, WHITELABEL].includes(user) && !wldvps && (
                        <td>{server.user.whitelabel}</td>
                      )}
                      <td>
                        <InfoSvg
                          onClick={() => handleBandwidthInfoModalOpen(server)}
                          className={styles.info}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </BasicTable>
          </Box>
        </div>

        <BandwidthInfoModal
          isOpen={isBandwidthInfoModalOpen}
          onClose={handleBandwidthInfoModalClose}
          server={selectedServer}
          onUpdate={handleBandwidthInfoModalUpdate}
        />
      </LayoutMainAction>
    </WithRole>
  );
}
