import styles from "./vat-report.module.scss";

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import Box from "../../../components/box";
import ItemWithHeader from "../../../components/item-with-header";
import CustomReactSelect from "../../../components/custom-react-select";
import { addMonths, format } from "date-fns";
import IconButton from "../../../components/icon-button";
import {} from "../../../utils";
import { getServerUrl } from "../../../utils/wldvps";

const invoiceMonths = [];
for (let i = -36; i <= 0; i++) {
  const dt = addMonths(new Date(), i);
  invoiceMonths.push({ label: format(dt, "M/y"), value: dt, key: i });
}

export default function BillingVatReport() {
  const intl = useIntl();

  const [monthFrom, setMonthFrom] = useState(
    invoiceMonths[invoiceMonths.length - 1]
  );
  const [monthTo, setMonthTo] = useState(
    invoiceMonths[invoiceMonths.length - 1]
  );

  return (
    <WithRole permission="super-admin.vat-report">
      <LayoutMainAction
        title={intl.formatMessage({ id: "billing-vat-report.main-title" })}
      >
        <div className={styles.wrapper}>
          <Box>
            <ItemWithHeader
              header={intl.formatMessage({
                id: "billing-vat-report.from-to",
              })}
            >
              <div className={styles.items}>
                <CustomReactSelect
                  instanceId="billing-vat-report-from"
                  options={invoiceMonths}
                  value={monthFrom}
                  onChange={(option) => setMonthFrom(option)}
                />

                <CustomReactSelect
                  instanceId="billing-vat-report-to"
                  options={invoiceMonths}
                  value={monthTo}
                  onChange={(option) => setMonthTo(option)}
                />

                <IconButton
                  color="light-purple"
                  href={`${getServerUrl()}/billing/generateVatReport?from=${
                    monthFrom.value
                  }&to=${monthTo.value}`}
                  target="_blank"
                >
                  <FormattedMessage id="billing-vat-report.generate-report" />
                </IconButton>
              </div>
            </ItemWithHeader>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
