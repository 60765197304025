import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax } from "../../../utils/hooks";
import Checkbox from "../../checkbox";

function ServersSelectorModal({
  title,
  selectedServers,
  setSelectedServers,
  isOpen,
  onClose,
  onlyActive,
}) {
  const ajax = useAjax();

  const [domains, setDomains] = useState([]);

  const getAllDomains = useCallback(async () => {
    const data = await ajax(`/servers/getAllDomains`, { onlyActive });

    if (data.result === "success") {
      setDomains(data.domains);
    }
  }, [ajax, onlyActive]);

  useEffect(() => {
    if (isOpen) {
      getAllDomains();
    }
  }, [isOpen, getAllDomains]);

  function handleCheckboxChanged(e, domain) {
    selectedServers[domain._id] = !selectedServers[domain._id];
    setSelectedServers({ ...selectedServers });
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>{title}</ModalHeader>
      <ModalBody>
        {domains.length === 0 && (
          <div>
            <FormattedMessage id="servers-selector-modal.no-servers" />
          </div>
        )}

        {domains.map((domain, key) => (
          <div key={key} className={styles.checkboxWrapper}>
            <Checkbox
              label={<span>{domain.hostname}</span>}
              checked={selectedServers[domain._id] || false}
              onChange={(e) => handleCheckboxChanged(e, domain)}
            />
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={() => onClose(true)}>
          <FormattedMessage id="general.save" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

ServersSelectorModal.propTypes = {
  title: PropTypes.string,
  selectedServers: PropTypes.object,
  setSelectedServers: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onlyActive: PropTypes.bool,
};

export default ServersSelectorModal;
