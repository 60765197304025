import styles from "./other-products.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import BasicTable from "../../../../../components/basic-table";
import Box from "../../../../../components/box";
import CustomMenu from "../../../../../components/custom-menu";
import CustomMenuItem from "../../../../../components/custom-menu/item";
import IconButton from "../../../../../components/icon-button";
import CreateOtherProductModal from "../../../../../components/modals/create-other-product";
import Spinner from "../../../../../components/spinner";
import { useAjax, useConfirm, useUser } from "../../../../../utils/hooks";
import {} from "../../../../../utils";
import { SUPER_ADMIN, WHITELABEL } from "../../../../../utils/user";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

function ClientsListOtherProducts({ user, reloadUser }) {
  const ajax = useAjax();
  const intl = useIntl();
  const confirm = useConfirm();
  const currentUser = useUser();

  const [filteredOtherProducts, setFilteredOtherProducts] = useState(null);
  const [
    isOtherProductsDropDownOpen,
    setIsOtherProductsDropDownOpen,
  ] = useState({});
  const [selectedOtherProduct, setSelectedOtherProduct] = useState(null);
  const [
    isCreateOtherProductModalOpen,
    setIsCreateOtherProductModalOpen,
  ] = useState(false);

  const [
    isOtherProductsToShowDropdownOpen,
    setIsOtherProductsToShowDropdownOpen,
  ] = useState(false);
  const [otherProductsToShow, setOtherProductsToShow] = useState("self");

  useEffect(() => {
    if (!user?.otherProducts) {
      return;
    }

    let otherProductsToSet;
    if (
      otherProductsToShow === "self" &&
      ([SUPER_ADMIN, WHITELABEL].includes(user.role) || !user.whitelabel)
    ) {
      otherProductsToSet = user.otherProducts.filter((op) => !op.whitelabel);
    } else {
      otherProductsToSet = user.otherProducts.filter(
        (op) => op.whitelabel && op.whitelabel === user.whitelabel
      );
    }

    setFilteredOtherProducts(otherProductsToSet);
  }, [currentUser.whitelabel, user, otherProductsToShow]);

  function handleCreateClicked() {
    setSelectedOtherProduct(null);
    setIsCreateOtherProductModalOpen(true);
  }

  function handleCreateOtherProductModalClosed(status) {
    setIsCreateOtherProductModalOpen(false);

    if (status) {
      setTimeout(() => {
        reloadUser.do();
      }, 1);
    }
  }

  function handleDropDownToggle(otherProduct) {
    isOtherProductsDropDownOpen[
      otherProduct._id
    ] = !isOtherProductsDropDownOpen[otherProduct._id];
    setIsOtherProductsDropDownOpen({ ...isOtherProductsDropDownOpen });
  }

  function handleEditMenuClicked(otherProduct) {
    setSelectedOtherProduct(otherProduct);
    setIsCreateOtherProductModalOpen(true);
  }

  async function handleDeleteMenuClicked(otherProduct) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "clients-list-other-products.delete.title",
      }),
      message: intl.formatMessage(
        { id: "clients-list-other-products.delete.content" },
        { name: otherProduct.name }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax(`/users/deleteOtherProduct`, {
      userID: user._id,
      otherProductID: otherProduct._id,
    });

    reloadUser.do();
  }

  function handleOtherProductsToShowDropdownToggle() {
    setIsOtherProductsToShowDropdownOpen(!isOtherProductsToShowDropdownOpen);
  }

  return (
    <div className={styles.wrapper}>
      {currentUser.role === SUPER_ADMIN && user?.whitelabel && (
        <div className={styles.header}>
          <div>
            <FormattedMessage id="general.other-products-to-show" />
            <ButtonDropdown
              className={styles.ghost}
              isOpen={isOtherProductsToShowDropdownOpen}
              toggle={handleOtherProductsToShowDropdownToggle}
            >
              <DropdownToggle caret>{otherProductsToShow}</DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => setOtherProductsToShow("self")}>
                  <FormattedMessage id="general.self" />
                </DropdownItem>
                <DropdownItem
                  onClick={() => setOtherProductsToShow("whitelabel")}
                >
                  <FormattedMessage id="general.whitelabel" />
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
      )}

      <Box>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="clients-list-other-products.product-name" />
              </th>
              <th>
                <FormattedMessage id="clients-list-other-products.product-description" />
              </th>
              <th>
                <FormattedMessage id="clients-list-other-products.product-quantity" />
              </th>
              <th>
                <FormattedMessage id="clients-list-other-products.pay-every" />
              </th>
              <th>
                <FormattedMessage id="clients-list-other-products.pay-day" />
              </th>
              <th>
                <FormattedMessage id="clients-list-other-products.next-pay-day" />
              </th>
              <th>
                <FormattedMessage id="clients-list-other-products.product-price" />
              </th>
              <th>
                <FormattedMessage id="clients-list-other-products.product-currency" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!filteredOtherProducts && (
              <tr>
                <td colSpan={9}>
                  <div className="spinner-wrapper">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}

            {filteredOtherProducts?.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <FormattedMessage id="general.no-rows" />
                </td>
              </tr>
            )}
            {filteredOtherProducts?.map((otherProduct, idx) => (
              <tr key={idx}>
                <td>{otherProduct.name}</td>
                <td>{otherProduct.description}</td>
                <td>{otherProduct.quantity}</td>
                <td>{`${otherProduct.payEvery} ${intl.formatMessage({
                  id: `general.${
                    otherProduct.payEvery === 1 ? "month" : "months"
                  }`,
                })}`}</td>
                <td>{otherProduct.payDay}</td>
                <td>{format(new Date(otherProduct.next_pay_day), "d/M/y")}</td>
                <td>{otherProduct.price}</td>
                <td>{otherProduct.currency}</td>
                <td>
                  <CustomMenu
                    isOpen={isOtherProductsDropDownOpen[otherProduct._id]}
                    toggle={() => handleDropDownToggle(otherProduct)}
                  >
                    <CustomMenuItem
                      onClick={() => handleEditMenuClicked(otherProduct)}
                    >
                      <FormattedMessage id="general.edit" />
                    </CustomMenuItem>
                    <CustomMenuItem
                      onClick={() => handleDeleteMenuClicked(otherProduct)}
                    >
                      <FormattedMessage id="general.remove" />
                    </CustomMenuItem>
                  </CustomMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>

        <IconButton color="light-purple" onClick={handleCreateClicked}>
          <FormattedMessage id="clients-list-other-products.create" />
        </IconButton>

        <CreateOtherProductModal
          isOpen={isCreateOtherProductModalOpen}
          onClose={handleCreateOtherProductModalClosed}
          user={user}
          editOtherProduct={selectedOtherProduct}
          whitelabel={
            otherProductsToShow === "self" &&
            ([SUPER_ADMIN, WHITELABEL].includes(user.role) || !user.whitelabel)
              ? null
              : user.whitelabel
          }
        />
      </Box>
    </div>
  );
}

ClientsListOtherProducts.propTypes = {
  user: PropTypes.object,
  reloadUser: PropTypes.object,
};

export default ClientsListOtherProducts;
