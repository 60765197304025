import styles from "./index.module.scss";

import React from "react";
import Box from "../box";
import CloudLayout from "../cloud-layout";
import Spinner from "../spinner";

export default function LoadingPage() {
  return (
    <CloudLayout>
      <div className={styles.wrapper}>
        <Box>
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        </Box>
      </div>
    </CloudLayout>
  );
}
