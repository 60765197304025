import styles from "./overview.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import BasicTable from "../../../../../components/basic-table";
import { ReactComponent as TrashSvg } from "../../../../../components/svgs/trash.svg";
import IconButton from "../../../../../components/icon-button";
import {
  useLang,
  useServer,
  useAjax,
  useConfirm,
} from "../../../../../utils/hooks";
import {} from "../../../../../utils";
import { renderSchedulerAction } from "../../../../../utils/tasks";
import ScheduleNewTaskModal from "../../../../../components/modals/schedule-new-task";

export default function ScheduledTasks() {
  const lang = useLang();
  const server = useServer();
  const ajax = useAjax();
  const intl = useIntl();
  const confirm = useConfirm();

  const [tasks, setTasks] = useState([]);
  const [isScheduleNewTaskModalOpen, setIsScheduleNewTaskModalOpen] = useState(
    false
  );

  const getScheduledTasks = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax(`/scheduler/getScheduledTasks`, {
      serverID: server._id,
    });

    setTasks(data.tasks);
  }, [ajax, server]);

  useEffect(() => {
    getScheduledTasks();
  }, [getScheduledTasks]);

  function handleScheduleTaskClicked() {
    setIsScheduleNewTaskModalOpen(true);
  }

  async function handleScheduleNewTaskModalClosed(status) {
    if (status) {
      await getScheduledTasks();
    }

    setIsScheduleNewTaskModalOpen(false);
  }

  async function handleRemoveSnapshotClicked(task) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "server-tasks.remove-scheduled-task.title",
      }),
      message: intl.formatMessage({
        id: "server-tasks.remove-scheduled-task.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/scheduler/removeScheduledTask", {
      serverID: server._id,
      schedulerID: task._id,
    });
    await getScheduledTasks();
  }

  if (!server) {
    return null;
  }

  return (
    <>
      <div className={styles.title}>
        <div className={styles.bold}>
          <FormattedMessage id="overview.schedule-tasks" />
        </div>
        <div className={styles.link}>
          <Link
            href="/[lang]/my-cloud/servers/[id]/tasks"
            to={`/${lang}/my-cloud/servers/${server._id}/tasks`}
          >
            <FormattedMessage id="overview.view-all" />
          </Link>
        </div>
      </div>
      <div className={styles.table}>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="overview.action" />
              </th>
              <th>
                <FormattedMessage id="overview.frequency" />
              </th>
              <th>
                <FormattedMessage id="overview.next-run" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tasks.length === 0 && (
              <tr>
                <td colSpan={4}>
                  <FormattedMessage id="overview.no-tasks" />
                </td>
              </tr>
            )}

            {tasks.length > 0 &&
              tasks.map((task, key) => (
                <tr key={key}>
                  <td>{renderSchedulerAction(task.action, intl)}</td>
                  <td className="capitalize">{task.frequency}</td>
                  <td>{format(new Date(task.next_run), "d/M/y HH:mm")}</td>
                  <td>
                    <TrashSvg
                      className="pointer"
                      onClick={() => handleRemoveSnapshotClicked(task)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </BasicTable>
      </div>
      {server.status === "Active" && (
        <div className={styles.button}>
          <IconButton
            disabled={server.isWorking}
            color="light-purple"
            onClick={handleScheduleTaskClicked}
          >
            <FormattedMessage id="general.add" />
          </IconButton>
        </div>
      )}

      <ScheduleNewTaskModal
        isOpen={isScheduleNewTaskModalOpen}
        onClose={handleScheduleNewTaskModalClosed}
        tasks={tasks}
      />
    </>
  );
}
