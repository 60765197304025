import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { getTypeOfTask } from "../../../utils/tasks";
import Spinner from "../../spinner";
import { useSelector } from "react-redux";

function TasksLogModal({ isOpen, onClose, taskID }) {
  const intl = useIntl();
  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  const [task, setTask] = useState(null);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (!tasks || !tasks.data) {
      return;
    }

    const task = tasks.data.find((t) => t.id === taskID);

    setTask(task);
  }, [taskID, tasks]);

  useEffect(() => {
    if (task) {
      setLogs(task.logs.reverse());
    }
  }, [task]);

  if (!task) {
    return null;
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        {getTypeOfTask(task, intl)}
        {!task.finishedAt && task.step > -1 && <Spinner />}
      </ModalHeader>
      <ModalBody>
        <div>
          <FormattedMessage id="tasks.executed-by-user" /> -{" "}
          <b>{task.created_by}</b>{" "}
          <FormattedMessage id="tasks.executed-by-user-ip" /> -{" "}
          <b>{task.user_ip}</b>
        </div>

        <br />

        {logs.map((log, key) => (
          <div key={key} className={styles.infoRow}>
            <b>{format(new Date(log.created_at), "HH:mm:ss")}</b> -{" "}
            {typeof log.text === "string" ? log.text : JSON.stringify(log.text)}
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

TasksLogModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  taskID: PropTypes.string,
};

export default TasksLogModal;
