import styles from "./index.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { getDaysInMonth } from "date-fns";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import Counter from "../../counter";
import { convertCurrency, getRestOfMonthDays } from "../../../utils/billing";
import { useUser, useAjax, useRoles, useWLDVPS } from "../../../utils/hooks";
import { userCurrency } from "../../../utils/user";
import { dataLayerPush } from "../../../utils";
import Checkbox from "../../checkbox";

function AddAdditionalIpv4Modal({ server, isOpen, onClose }) {
  const intl = useIntl();
  const { isAllowed } = useRoles();

  const user = useUser();
  const ajax = useAjax();
  const wldvps = useWLDVPS();

  const [additionalIpv4Product, setAdditionalIpv4Product] = useState(null);
  const [additionalIpv4ExtraProduct, setAdditionalIpv4ExtraProduct] = useState(
    null
  );

  const [count, setCount] = useState(1);
  const [monthlyPrice, setMonthlyPrice] = useState(false);
  const [firstMonthPayment, setFirstMonthPayment] = useState(false);
  const [bypassPayment, setBypassPayment] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll", {
      type: ["additional-ipv4"],
    });

    const sorted = data.products.sort((a, b) => a.price - b.price);

    setAdditionalIpv4Product(sorted[0]);
    setAdditionalIpv4ExtraProduct(sorted[1]);
  }, [ajax]);

  useEffect(() => {
    if (isOpen) {
      {
        getProducts();
      }
    }
  }, [getProducts, isOpen]);

  const calculatePrice = useCallback(() => {
    if (!server || !additionalIpv4ExtraProduct || !additionalIpv4Product) {
      return;
    }

    const ipv4Addons = server.addons.filter(
      (addon) =>
        addon.addon_type === "additional-ipv4" && addon.whitelabel === wldvps
    );

    const currentCount = ipv4Addons.length;
    const newCount = currentCount + count;

    const oldPrice = ipv4Addons.reduce(
      (total, item) =>
        total +
        convertCurrency(item.price, item.currency, "USD", user.rates, {
          USD: user.fixed_usd,
        }) *
          item.quantity,
      0
    );

    const newPrice =
      newCount *
      (newCount >= parseInt(process.env.REACT_APP_MAX_ADDITIONAL_IPV4)
        ? additionalIpv4ExtraProduct.price
        : additionalIpv4Product.price);

    const priceToPay = newPrice - oldPrice;

    const invoicePrice = convertCurrency(
      priceToPay,
      "USD",
      user.currency,
      user.rates,
      {
        USD: user.fixed_usd,
      }
    );

    let firstMonthPayment =
      invoicePrice * (getRestOfMonthDays() / getDaysInMonth(new Date()));

    return { firstMonthPayment, monthlyPrice: newPrice };
  }, [
    count,
    server,
    user.currency,
    user.fixed_usd,
    additionalIpv4ExtraProduct,
    additionalIpv4Product,
    user.rates,
    wldvps,
  ]);

  useEffect(() => {
    const prices = calculatePrice();

    if (prices) {
      setFirstMonthPayment(prices.firstMonthPayment);
      setMonthlyPrice(prices.monthlyPrice);
    }
  }, [calculatePrice]);

  async function handleAddClicked() {
    setError(false);

    setLoading(true);
    const data = await ajax(`/servers/addAdditionalIpv4`, {
      userID: server.user_id,
      serverID: server._id,
      count,
      bypassPayment,
    });
    setLoading(false);

    if (data.result === "success") {
      dataLayerPush({
        event: "add-additional-ipv4",
        transactionId: data.tid,
        transactionTotal: parseFloat(firstMonthPayment.toFixed(3)),
      });
      onClose(true);
    } else {
      setError(intl.formatMessage({ id: "add-additional-ipv4-modal.error" }));
    }
  }

  return (
    <Modal size="lg" isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <FormattedMessage id="add-additional-ipv4-modal.title" />
      </ModalHeader>
      <ModalBody className={styles.wrapper}>
        <div className={styles.text}>
          <FormattedMessage id="add-additional-ipv4-modal.content1" />
        </div>
        <br />
        <div className={styles.counterWrapper}>
          <Counter count={count} setCount={setCount} max={50} />
        </div>
        <br />
        {firstMonthPayment && monthlyPrice && (
          <div className={styles.text}>
            <FormattedMessage
              id="add-additional-ipv4-modal.content2"
              values={{
                firstMonthPayment: (
                  <b>
                    {userCurrency(user)}
                    {firstMonthPayment.toFixed(2)}
                  </b>
                ),
                monthlyPrice: (
                  <b>
                    {userCurrency(user)}
                    {monthlyPrice.toFixed(2)}
                  </b>
                ),
                b: (arr) => `${(<b>{arr[0]}</b>)}`,
              }}
            />
          </div>
        )}
        {error && (
          <div className="error">
            <br />
            {error}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {isAllowed("super-admin.can-bypass-payment") && (
          <Checkbox
            label="general.bypass-payment"
            checked={bypassPayment}
            onChange={() => setBypassPayment(!bypassPayment)}
          />
        )}
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAddClicked}
        >
          <FormattedMessage id="general.add" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddAdditionalIpv4Modal.propTypes = {
  server: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddAdditionalIpv4Modal;
