export const citiesAddresses_IL = {
  "אבו ג'ווייעד (שבט)": ["אבו ג'ווייעד (שבט)"],
  "אבו גוש": [
    "אבו גוש",
    "אל רשיד",
    "אל-חנאא'",
    "אלעבד עלי",
    "בית הבד",
    "ג'בר",
    "דאוד עבדאללה",
    "דולב",
    "דרך הגוש",
    "דרך השלום",
    "דרך מעלה החמשה",
    "האגוז",
    "האורנים",
    "האיריס",
    "האלה",
    "הארזים",
    "הארמונות",
    "הבושם",
    "הברוש",
    "הגורן",
    "הגנים",
    "הגפנים",
    "הדובדבנים",
    "הוואדי",
    "הורד",
    "הזיתים",
    "החווה",
    "החומה",
    "החרוב",
    "הלימון",
    "המאפיה הישנה",
    "המעברה",
    "המפעל",
    "המצפה",
    "המשתלה",
    "הנענע",
    "הנרקיס",
    "העוזרד",
    "הפול",
    "הפרחים",
    "הרימונים",
    "השדות",
    "השושן",
    "השזיף",
    "השיכון",
    "השקדים",
    "התאנים",
    "התות",
    "התמר",
    "חאג' עותמאן",
    "חג' מוסא",
    "יסמין",
    "מחמוד רשיד",
    "מרכז הכפר",
    "נוטרדאם",
    "נטאף",
    "עבדאל עזיז",
    "עבדאל רחמן",
    "עמאוס",
    "ענבר",
    "ציפורן",
    "שייח איסמעיל",
  ],
  "אבו סנאן": [
    "אבו סנאן",
    "אבו-אלחסן",
    "אזור תעשייה",
    "אל שוקפאן",
    "אלג'ורה",
    "אלהדאפה",
    "אלוחדה",
    "אלח'אנוקה",
    "אלח'לה",
    "אלחלקאת",
    "אלחרוב ש חייל החדשה",
    "אלחרוב ש חייל הישנה",
    "אלמדארס",
    "אלמלה",
    "אלמפצלה",
    "אלמראח",
    "אלמרחין",
    "אלנביא זכריא",
    "אלנסים",
    "אלע'וזה",
    "אלעקבה",
    "אלתואנה",
    "דרב אלתין",
    "הואדי",
    "החללים",
    "השכונה הדרומית",
    "כרם אלרג'ומה",
    "מרכז הכפר",
    "נג'מת אלסבוח",
    "סולטאן באשא אלאטרש",
    "עמדאן סעד",
    "עשואן",
    "קריית החינוך",
    "ראס אלכרם",
    "רח 100",
    "רח 10",
    "רח 110",
    "רח 20",
    "רח 30",
    "רח 40",
    "רח 50",
    "רח 60",
    "רח 80",
    "רח 90",
    "רח 1",
    "רח 101",
    "רח 11",
    "רח 21",
    "רח 31",
    "רח 41",
    "רח 51",
    "רח 61",
    "רח 71",
    "רח 81",
    "רח 91",
    "רח 2",
    "רח 102",
    "רח 12",
    "רח 22",
    "רח 32",
    "רח 42",
    "רח 52",
    "רח 62",
    "רח 72",
    "רח 82",
    "רח 92",
    "רח 3",
    "רח 103",
    "רח 13",
    "רח 23",
    "רח 33",
    "רח 43",
    "רח 53",
    "רח 63",
    "רח 73",
    "רח 83",
    "רח 93",
    "רח 4",
    "רח 104",
    "רח 14",
    "רח 24",
    "רח 34",
    "רח 54",
    "רח 64",
    "רח 74",
    "רח 84",
    "רח 94",
    "רח 5",
    "רח 15",
    "רח 25",
    "רח 35",
    "רח 45",
    "רח 55",
    "רח 65",
    "רח 75",
    "רח 85",
    "רח 95",
    "רח 6",
    "רח 106",
    "רח 16",
    "רח 26",
    "רח 36",
    "רח 46",
    "רח 56",
    "רח 66",
    "רח 76",
    "רח 86",
    "רח 96",
    "רח 7",
    "רח 107",
    "רח 17",
    "רח 27",
    "רח 37",
    "רח 47",
    "רח 57",
    "רח 67",
    "רח 77",
    "רח 87",
    "רח 97",
    "רח 8",
    "רח 108",
    "רח 28",
    "רח 38",
    "רח 48",
    "רח 68",
    "רח 78",
    "רח 88",
    "רח 98",
    "רח 9",
    "רח 109",
    "רח 19",
    "רח 29",
    "רח 49",
    "רח 69",
    "רח 79",
    "רח 89",
    "רח 99",
    "שכ הכנסייה",
    "שכונת המסגד",
    "שכונת השוק",
  ],
  "אבו סריחאן (שבט)": ["אבו סריחאן (שבט)"],
  "אבו עבדון (שבט)": ["אבו עבדון (שבט)"],
  "אבו עמאר (שבט)": ["אבו עמאר (שבט)"],
  "אבו עמרה (שבט)": ["אבו עמרה (שבט)"],
  "אבו קורינאת (שבט)": ["אבו קורינאת (שבט)"],
  "אבו קרינאת (יישוב)": ["אבו קרינאת (יישוב)"],
  "אבו רובייעה (שבט)": ["אבו רובייעה (שבט)", "אל קרעאן"],
  "אבו רוקייק (שבט)": ["אבו כף", "אבו סבית", "אבו רוקייק (שבט)", "טרשאן"],
  "אבו תלול": ["אבו תלול"],
  אבטין: ["אבטין"],
  אבטליון: ["אבטליון"],
  אביאל: ["אביאל"],
  אביבים: ["אביבים"],
  אביגדור: ["אביגדור"],
  אביחיל: [
    "אביחיל",
    "בן צבי",
    "דרך הים",
    "דרך פרחים",
    "הבנים",
    "הגדוד העברי",
    "הדקל",
    "ההגנה",
    "ההדרים",
    "החורשה",
    'הלח"י',
    "העצמאות",
    'הפלמ"ח',
    "הפרדס",
    "הצבעוני",
    "הרותם",
    "השומר",
    "לוחמי גליפולי",
    "נהגי הפרדות",
    "עין העובד",
    'צה"ל',
    "שד בן גוריון",
    "שוורצברד",
    "תל חי",
  ],
  אביטל: ["אביטל"],
  אביעזר: ["אביעזר"],
  אבירים: ["אבירים"],
  "אבן יהודה": [
    "אביבית",
    "אבן יהודה",
    "אגמון",
    "אדם",
    "אדמונית",
    "אהבת ציון",
    "אזור תעשייה",
    "אחוזת מישרים",
    "אילת השחר",
    "אירוס הארגמן",
    "אלמוגן",
    "אמנון ותמר",
    "אנפה",
    "אסותא",
    "ארז",
    "אשל",
    "באר גנים",
    "בושמת",
    "בית השואבה",
    "בני בנימין",
    "ברקן",
    "גבעונית",
    "גבעת רימון",
    "דגן",
    "דודאים",
    "דולב",
    "דן",
    "דניאל",
    "דפנה",
    "דרור",
    "דרך בין השדות",
    "דרך רבין",
    "האגוז",
    "האגס",
    "האודם",
    "האוכמניות",
    "האורן",
    "האיזמרגד",
    "האילנות",
    "האירוסים",
    "האלה",
    "האלון",
    "האתרוג",
    "הבאר",
    "הבנים",
    "הברוש",
    "הגבעה",
    "הגולן",
    "הגליל",
    "הגפן",
    "הגת",
    "הדובדבן",
    "הדליות",
    "הדס",
    "הדקל",
    "ההדרים",
    "ההסתדרות",
    "ההרדופים",
    "הורד",
    "הורדים",
    "הזית",
    "החרוב",
    "היבולים",
    "היונה",
    "היצירה",
    "הלוחמים",
    "המחתרת",
    "המיסדים",
    "המעפילים",
    "המתמיד",
    "הנגב",
    "הנוטע",
    "הנוטר",
    "הנופר",
    "הנורית",
    "הנרקיסים",
    "הסוף",
    "הסחלב",
    "הסייפן",
    "העירית",
    "העצמאות",
    "הערבה",
    "הפטל",
    'הפלמ"ח',
    "הפרגים",
    "הצאלון",
    "הקשת",
    "הרותם",
    "הרימונים",
    "הרקפות",
    "השומר",
    "השומרון",
    "השושן",
    "השיח",
    "השיטה",
    "השלום",
    "השלושה",
    "השקמה",
    "השרון",
    "התאנה",
    "התומר",
    "התות",
    "התעשיה",
    "התפוז",
    "התקומה",
    "ותיקים",
    "זויתן",
    "חבצלת",
    "חבצלת השרון",
    "חלמונית",
    "חמנית",
    "חן",
    "חצב",
    "חרצית",
    "יסמין",
    "יערה",
    "כליל החורש",
    "כלנית",
    "כרכום",
    "לוטם",
    "לוע הארי",
    "לילך",
    "מורן",
    "מכבי",
    "מנטה",
    "מעיין",
    "מעלה הארגמן",
    "מעלה הלבונה",
    "מעלה הרתמים",
    "מרבד הקסמים",
    "מרגנית",
    "מרווה",
    "נווה הדר",
    "נחל אל-על",
    "נחל סער",
    "נחל פולג",
    "ניצן",
    "נר הלילה",
    "סביון",
    "סהרון",
    "סיני",
    "סמ האלה",
    "סמ החרמון",
    "סמ הלוטוס",
    "סמ הניצנים",
    "סמ הקולנוע",
    "סמ השושנה",
    "סמ השישה",
    "סמ יעקובי",
    "סמ יערה",
    "סמטת ברקן",
    "סמטת גומא",
    "סמטת דן",
    "סנונית",
    "סתוונית",
    "עגור",
    "עדעד",
    "עופרית",
    "עליה",
    "עממי",
    "עפרוני",
    "עציון",
    "פעמונית",
    "צבעוני",
    "צבר",
    'צה"ל',
    "צורית",
    "ציפורן",
    "קבוץ גלויות",
    "קחון",
    "קינמון",
    "קקטוס",
    "קרן היסוד",
    "רוזמרין",
    "ריחן",
    "רמת בן גוריון",
    "רמת היובל",
    "ש אסבסטונים",
    "ש הותיקים",
    "ש חן",
    "ש יבנאי",
    "ש מכבי",
    "ש עובדים",
    "ש עין יעקב",
    "ש עממי",
    "ש שכטר",
    "שבזי",
    "שביל הנוטע",
    "שדמה",
    "שומשום",
    "שחף",
    "שיזף",
    "שכ הדסים",
    "שכ פאר גנים",
    "שכ קרוונים",
    "שלדג",
    "שלהבת",
    "שן הארי",
    "שנהב",
    "שקד",
    "תדהר",
    "תורמוס",
    "תירוש",
    "תל צור",
    "תפוח",
  ],
  "אבן מנחם": ["אבן מנחם"],
  "אבן ספיר": [
    "אבן ספיר",
    "אגט",
    "אודם",
    "ברקת",
    "גביש",
    "גרניט",
    "החרסית",
    "חלמיש",
    "טופז",
    "יהלום",
    "לשם",
    "צור",
    "שוהם",
  ],
  "אבן שמואל": ["אבן שמואל"],
  "אבני איתן": ["אבני איתן"],
  "אבני חפץ": [
    "אבני חפץ",
    "אודם",
    "איבי הנחל",
    "ברקת",
    "גינת אגוז",
    "גלעד",
    'דרך הרא"ה',
    "האודם",
    "החרמון",
    "הירדן",
    "המור",
    "חושן",
    "יהלום",
    "כרמל",
    "לבונה",
    "לשם",
    "מצפה חפץ",
    "ספיר",
    "שחר",
    "שכ מעלה יעקב",
    "שניר",
  ],
  אבנת: ["אבנת"],
  אבשלום: ["אבשלום", "שכ קרוונים"],
  אדורה: ["אדורה", "האורן", "האלה", "האלון", "הברוש"],
  אדירים: ["אדירים"],
  אדמית: ["אדמית"],
  אדרת: ["אדרת"],
  אודים: [
    "אודים",
    "האורן",
    "האזדרכת",
    "האיריס",
    "הברוש",
    "הגבעה",
    "הדגן",
    "הדקל",
    "ההדרים",
    "החצב",
    "החרוב",
    "היקינטון",
    "הכלנית",
    "הנרקיס",
    "העירית",
    "הפקאן",
    "הרימון",
    "הרקפת",
    "השומר",
    "השיטה",
    "התלתן",
    "התמר",
    "סמ המגדל",
    "צאלון",
  ],
  אודם: ["אודם"],
  אוהד: ["אוהד"],
  "אום אל-פחם": [
    "אבו בכר",
    "אבו ד'ר אלע'פארי",
    "אבו הורירה",
    "אבו חניפה",
    "אבו נופול",
    "אבו סברי",
    "אבו עבידה",
    "אבן אלאתיר",
    "אבן אלג'אוזי",
    "אבן אלהיתם",
    "אבן אלנפיס",
    "אבן אלקיים",
    "אבן בטוטה",
    "אבן זיידון",
    "אבן ח'לדון",
    "אבן חזם",
    "אבן כת'יר",
    "אבן מאגה",
    "אבן מסעוד",
    "אבן סינא",
    "אבן סירין",
    "אבן עבאס",
    "אבן רשד",
    "אבן תימיה",
    "אום אל-פחם",
    "אום אלעבהר",
    "אומייה",
    "אחמד אבן חנבל",
    "איוב",
    "אל-גזאלי",
    "אל-זביר",
    "אל-ח'ליל",
    "אל-חמה",
    "אל-מוארדי",
    "אל-מעתצם",
    "אל-סיוטי",
    "אל-ראזי",
    "אל-ראשדון",
    "אל-רבאט",
    "אל-ריאץ'",
    "אל-שאם",
    "אלאג'באריה",
    "אלאח'ווה",
    "אלאמל",
    "אלאנדלוס",
    "אלאנואר",
    "אלאסלאח",
    "אלאקואס",
    "אלאקסא",
    "אלארג'ואן",
    "אלארז",
    "אלארשאד",
    "אלאשערי",
    "אלבאטן",
    "אלבהגה",
    "אלבוסתאן",
    "אלבח'ארי",
    "אלבחרה",
    "אלבחתורי",
    "אלביאדר",
    "אלביאר",
    "אלבידר",
    "אלביר",
    "אלבירוני",
    "אלבלוט",
    "אלבנאא",
    "אלברכאת",
    "אלג'בארין",
    "אלג'זאר",
    "אלג'נאן",
    "אלגדוע אלספלי",
    "אלגדוע אלעלוי",
    "אלד'הר",
    "אלד'הרה",
    "אלהג'רה",
    "אלהדאיה",
    "אלהודא",
    "אלואדי",
    "אלוורוד",
    "אלוחדה",
    "אלופאא",
    "אלזהראא",
    "אלזיתון",
    "אלזנבק",
    "אלח'וארזמי",
    "אלח'יאם",
    "אלח'נסאא",
    "אלח'רוב",
    "אלחאמדון",
    "אלחוריה",
    "אלחכמה",
    "אלטברי",
    "אלטיבאת",
    "אליאסמין",
    "אלירמוק",
    "אלכולייה",
    "אלכות'ר",
    "אלכנדק",
    "אלכראמה",
    "אללוז",
    "אללימון",
    "אלמאמון",
    "אלמג'ד",
    "אלמדינה",
    "אלמונתזה",
    "אלמועלקה",
    "אלמותנבי",
    "אלמחאג'נה",
    "אלמחאמיד",
    "אלמחג'ר",
    "אלמלסאא",
    "אלמנאר",
    "אלמנארה",
    "אלמערפה",
    "אלמרווה",
    "אלמרמאלה",
    "אלמרתפעה",
    "אלמשאעל",
    "אלנוזהה",
    "אלנור",
    "אלנח'יל",
    "אלנסאיי",
    "אלנענאע",
    "אלנרג'ס",
    "אלסבר",
    "אלסולטאנה",
    "אלסועדאא",
    "אלסופסאף",
    "אלסוק",
    "אלסינדיאן",
    "אלסלאם",
    "אלספא",
    "אלע'זלאת",
    "אלעג'מי",
    "אלעזה",
    "אלעטאא",
    "אלעיון",
    "אלעין",
    "אלפאתח",
    "אלפדאא",
    "אלפול",
    "אלפח'ר",
    "אלפנאר",
    "אלפראבי",
    "אלפרדוס",
    "אלקאדי",
    "אלקאדסייה",
    "אלקאהרה",
    "אלקודס",
    "אלקנדיל",
    "אלקרנפול",
    "אלראפדין",
    "אלראפה",
    "אלרבזה",
    "אלרביע",
    "אלרודה",
    "אלרוחה",
    "אלרומאן",
    "אלרחמה",
    "אלריאחין",
    "אלריחאן",
    "אלריף",
    "אלשאכרון",
    "אלשאע'ור",
    "אלשועלה",
    "אלשורוק",
    "אלשיח' חסין",
    "אלשרפה",
    "אלת'נאא",
    "אלתואסול",
    "אלתופיק",
    "אלתוריה",
    "אלתחריר",
    "אלתסאמוח",
    "אלתעמיר",
    "אפאק",
    "אריחא",
    "אשבליה",
    "בג'דאד",
    "בדר",
    "ביברז",
    "בידר דבאלה",
    "בירות",
    "בית אל-מקדס",
    "בלאל",
    "ג'אבר אבן חיאן",
    "ג'נין",
    "ג'רנאטה",
    "דמשק",
    "האג'ר",
    "ואדי אלנסור",
    "ואדי ג'סאל",
    "ואדי מלחם",
    "זמזם",
    "ח'אלד אבן אלוליד",
    "ח'דיג'ה בנת ח'וילד",
    "חד'יפה",
    "חטין",
    "חיפא",
    "חסאן אבן ת'אבת",
    "טאהא חסין",
    "טארק אבן זיאד",
    "טיבה",
    "טלעת אלמידאן",
    "טראבלס",
    "יאפא",
    "מאלכ אבן אנס",
    "מוסעב בן עומיר",
    "מועאויה",
    "מכה",
    "מרכז-אגבריה ומחג'נה",
    "מרכז-ג'בארין ומחמיד",
    "נאבלס",
    "נוח",
    "ניסבור",
    "סיבויה",
    "סלאח אלדין",
    "סלמאן אלפארסי",
    "סעד אבן אבי וקאץ",
    "ספד",
    "סרפנד",
    "ע'זה",
    "עאישה",
    "עדן",
    "עומר אלמוח'תאר",
    "עות'מאן בן עפאן",
    "עין אבראהים",
    "עין אלד'רווה",
    "עין אלדאליה",
    "עין אלוסטא",
    "עין אלמע'ארה",
    "עין אלנבי",
    "עין אלתינה",
    "עין ג'אלוט",
    "עין גראר",
    "עין ח'אלד",
    "עכא",
    "עלי בן אבי טאלב",
    "עמאן",
    "ערפה",
    "קבאא",
    "קורטבה",
    "קטאין אלשומר",
    "קטר אלנדא",
    "קיסאריה",
    "ש מסגד אבו עובדיה",
    "שכ אבו לאחם",
    "שכ אבו נופל",
    "שכ אל סולטאנה",
    "שכ אלבאטן",
    "שכ אלביאדר",
    "שכ אלביאר",
    "שכ אלביר",
    "שכ אלגזלאת",
    "שכ אלדהר",
    "שכ אלכאנוק",
    "שכ אלכדור",
    "שכ אלכינא",
    "שכ אלכלאיל",
    "שכ אלמועלקה",
    "שכ אלמחג'ר",
    "שכ אלמידאן",
    "שכ אלמלסא",
    "שכ אלמסאיאת",
    "שכ אלעיון",
    "שכ אלעראיש",
    "שכ אלקואס",
    "שכ אלשאג'ור",
    "שכ אסכנדר",
    "שכ בורגול",
    "שכ בידר דבאלה",
    "שכ ג'דוע",
    "שכ ואדי מלחם",
    "שכ ואדי נסור",
    "שכ ואדי עסאן",
    "שכ מסגד אבובכר",
    "שכ מסגד ג'בארין",
    "שכ סויסה",
    "שכ סתכיזראן",
    "שכ ע'רבי",
    "שכ עומר בן אלחטאב",
    "שכ עין איברהים",
    "שכ עין אל זיתונה",
    "שכ עין אלד'רווה",
    "שכ עין אלוסטא",
    "שכ עין אלמעארה",
    "שכ עין אלנבי",
    "שכ עין אלשערה",
    "שכ עין אלתינה",
    "שכ עין ג'ראר",
    "שכ עין כאלד",
    "שכ עקאדה",
    "שכ עראק אלשבאב",
    "שכ קחאוש",
    "שכ ראס אלהיש",
    "שכ רובזה",
    "שכ שרפה",
  ],
  "אום אל-קוטוף": ["אום אל-קוטוף"],
  "אום בטין": ["אום בטין"],
  אומן: ["אומן"],
  אומץ: ["אומץ"],
  אופקים: [
    "אבנר בן נר",
    "אגוז",
    "אדמונית",
    "אודם",
    "אופקים",
    "אור החיים",
    "אורים",
    "אזור התעשיה והמלאכה",
    "אטד",
    "אירוס",
    "אלון יגאל",
    "אלי כהן",
    "אליהו התשבי",
    "אלישע",
    "אסא",
    "אסיף",
    "אסתר המלכה",
    "אפיקים בנגב",
    'אצ"ל',
    "אקליפטוס",
    "ארבעת המינים",
    "אשחר",
    "אתרוג",
    "בארי",
    "בוזגלו דוד",
    'ביל"ו',
    "בן איש חי",
    "בן באר יהושע",
    "בן בארי הושע",
    "בן שמן",
    "בניהו",
    "בצלאל",
    "ברוש",
    "ברזילי",
    "ברק",
    "ברקן",
    "ברקת",
    "בשמת",
    "גבורי ישראל",
    "גד",
    "גדעון",
    "גולומב",
    "גומא",
    "גיבסנית",
    "גלאון",
    "גלגל",
    "גלעד",
    "דבורה הנביאה",
    "דגן",
    "דגנית",
    "דדו",
    "דובדבן",
    "דוד המלך",
    "דולב",
    "דניאל",
    "דפנה",
    "דקל",
    "דקל הדום",
    "דקלה",
    "דרך אילת",
    "דרך בית וגן",
    "דרך הבשמים",
    "דרך הטייסים",
    "דרך הנביאים",
    "דרך ישרים",
    "דרך סיירת שקד",
    "האורן",
    "האלה",
    "האלון",
    'האר"י',
    "הארזים",
    "הבורסקאי",
    "הבנאים",
    "הבציר",
    "הגורן",
    "הדס",
    "הדר",
    "ההגנה",
    "הורד",
    "הורדים",
    "הזורע",
    "הזית",
    "הזמורה",
    "החבצלת",
    "החורש",
    'החיד"א',
    "החיטה",
    "החרוב",
    "החרש",
    "היוגב",
    "היוזמה",
    "היוצר",
    "הכלנית",
    "הכנסת הגדולה",
    "הכרמים",
    "הכשרת הישוב",
    "הלל",
    "המלאכה",
    "המסגר",
    "הנגב",
    "הנפח",
    "הנשיא",
    "העינב",
    "העצמאות",
    "הפלדה",
    'הפלמ"ח',
    "הר חורשה",
    "הר חלמיש",
    "הר יעלון",
    "הר כרכום",
    "הר רתמים",
    "הר שגיא",
    "הרב אבוחצירה",
    "הרב אלנקווה רפאל",
    "הרב אלעזר אבוחצירה",
    "הרב הרצוג",
    "הרב חורי",
    "הרב חיים קמיל",
    "הרב יעקב הורוביץ",
    "הרב מרדכי חורי",
    "הרב עובדיה יוסף",
    "הרב עוזיאל",
    "הרב פינקוס",
    "הרב קוק",
    "הרי אילת",
    "הרימון",
    'הרמב"ם',
    "הרתך",
    "השילוח",
    "השקד",
    "התאנה",
    "התבור",
    "התירוש",
    "התמר",
    "התעשיה",
    'ותיקי מלחה"ע השניה',
    "ז'בוטינסקי",
    "זכריה",
    "חגי",
    "חורב",
    "חזון איש",
    "חט אלכסנדרוני",
    "חט גבעתי",
    "חט גולני",
    "חט הנגב",
    "חט כרמלי",
    "חט עציוני",
    "חלבלוב",
    "חמנייה",
    "חנניה",
    "חפץ חיים",
    "חצב",
    "חצרים",
    "חרצית",
    "טרומפלדור",
    "יהדות דרום אפריקה",
    "יהודה הלוי",
    "יהודה מרציאנו",
    "יהונתן",
    "יהוצדק",
    "יהושע בן נון",
    "יהושפט",
    "יהלום",
    "יואב",
    "יואל",
    "יונה",
    "יוני",
    "יחזקאל",
    "יסמין",
    "יעקב הכהן",
    "יערה",
    "יפתח",
    "יצהר",
    "יקינטון",
    "ירושלים",
    "ירמיהו",
    "ישי",
    "ישעיהו",
    "כיכר ברטי לובנר",
    "ככר גבעתי",
    "כליל החורש",
    "כפר דרום",
    "כצנלסון",
    "כרכום",
    "כרמל",
    "לוטוס",
    "לוטם",
    'לח"י',
    "ליבנה",
    "לילך",
    "מבצע דקל",
    "מבצע הראל",
    "מבצע חורב",
    "מבצע חירם",
    "מבצע יואב",
    "מבצע יפתח",
    "מבצע משה",
    "מבצע נחשון",
    "מבצע עובדה",
    "מורן",
    "מכתש רמון",
    "מלאכי",
    "מסיקה יעקב",
    "מרבד הקסמים",
    "מרגנית",
    "מרדכי",
    "מרווה",
    "משמר הנגב",
    "משעול האיריס",
    "משעול הגפן",
    "משעול הנרקיס",
    "משעול הרקפת",
    "משעול השקמה",
    "נבטים",
    "נחושתן",
    "נחל אופקים",
    "נחל באר שבע",
    "נחל גרר",
    "נחל דוד",
    "נחל הבשור",
    "נחל כיסופים",
    "נחל נטפים",
    "נחל ערוגות",
    "נחל פטיש",
    "נחל צאלים",
    "נחל צין",
    "נחל רמון",
    "נחל שקמה",
    "נחמיה",
    "נירים",
    "נירית",
    "נתן",
    "סביון",
    "סחלב",
    "סיגלון",
    "סייפן",
    "סיתוונית",
    "סמדר",
    "ספיר",
    "עדעד",
    "עוזיהו",
    "עוזרד",
    "עולי הגרדום",
    "עזרא",
    "עידו הנביא",
    "עין גדי",
    "עירית",
    "עמוס",
    "עמרם ביתן",
    "עמרם בן דיואן",
    "עמשא",
    "ערבה",
    "פטל",
    "פינטו חיים",
    "פיקוס",
    "פעמונית",
    "פרי מגדים",
    "צאלה",
    "צאלון",
    "צבעוני",
    "צבר",
    "צדקיהו",
    'צה"ל',
    "ציפור גן עדן",
    "ציפורן",
    "צפצפה",
    "קארו יוסף",
    "קבוץ גלויות",
    "קדמה",
    "קדש",
    "קהילות בן יעקב",
    "קהילת מטרו ווסט",
    "קורנית",
    "קיקוס חנית",
    "קציר",
    "קרית ספר",
    "קרן היסוד",
    "רבי דוד דהן",
    "רבי עקיבא",
    "רוזמרין",
    "רותם",
    "רזיאל דוד",
    "רחבת אביר",
    "רחבת אדר",
    "ריחן",
    "רמת שקד",
    'רש"י',
    "ש משטרה",
    "שאול המלך",
    "שבזי",
    "שד הציונות",
    "שד הרצל",
    "שד' השיטה",
    "שובל",
    "שוהם",
    "שושן",
    "שיבולים",
    "שיזף",
    "שילה",
    "שכ בן גוריון",
    "שכ בנה ביתך",
    "שכ הארזים",
    'שכ הרי"ף',
    "שכ מישור הגפן",
    "שכ שפירא",
    "שכונת דדו",
    "שכונת יוני",
    "שלמה המלך",
    "שמואל הרואה",
    "שמשון",
    "שרת",
    "תוצרת הארץ",
    "תורמוס",
    "תות",
    "תלתן",
    "תקומה",
    "תרשיש",
  ],
  "אור הגנוז": ["אור הגנוז"],
  "אור הנר": ["אור הנר"],
  "אור יהודה": [
    "אבי ואביב",
    "אביב משה",
    "אבנר",
    "אדם יקותיאל",
    "אודם",
    "אופירה",
    "אור יהודה",
    "אורן",
    "אזור תעשיה ספיר",
    "אזור תעשייה א",
    "אזור תעשייה אלמוג",
    "אזור תעשייה ב",
    "אזור תעשייה יהלום",
    "אטד",
    "איילת השחר",
    "איריס",
    "אלמוג",
    "אלעזר דוד",
    "אסירי ציון",
    'אצ"ל',
    "ארבל",
    "אשכול לוי",
    "אשל",
    "בן איש חי",
    "בר יוחאי",
    "בר לב חיים",
    "ברוש",
    "ברנר",
    "ברקת",
    "גולדה מאיר",
    "גורדון",
    "דגניה",
    "דה וינצי",
    "דולב",
    "דיין משה",
    "דקל",
    "דקלה",
    "האגוז",
    "האוניברסיטה",
    "האלה",
    "הארז",
    "האתרוג",
    "הגלבוע",
    "הגליל",
    "הדדי משה",
    "ההגנה",
    "ההסתדרות",
    "הוז דב",
    "הורד",
    "החבצלת",
    "החלוצים",
    "החצב",
    "החרושת",
    "היוזמים",
    "היוצרים",
    "היסמין",
    "היצירה",
    "הירדן",
    "הכלנית",
    'הל"ה',
    'הלח"י',
    "המכבים",
    "המלאכה",
    "המסגר",
    "המעפילים",
    "המפעל",
    "הנרקיס רמת פנקס",
    "הסדנא",
    "הסוכנות היהודית",
    "הסייפן",
    "העבודה",
    "העצמאות",
    "הערמון",
    "הפלדה",
    'הפלמ"ח',
    "הראשונים",
    "הרב הרצוג",
    "הרב חי טייב",
    "הרב כמוס עגיב",
    "הרב פלח כמוס",
    "הרדוף",
    "הרותם",
    "הרימון",
    'הרמ"א רמת פנקס',
    "הרצל",
    "הרקפת",
    "השומר",
    "השושן",
    "השיטה",
    "השלהבת",
    "השקמה",
    "התאנה",
    "התעשיה",
    "ז'בוטינסקי",
    "זית",
    "חטיבת אלכסנדרוני",
    "חייק משה",
    "חרוב",
    "חרמון",
    "טופז",
    "יהדות קנדה",
    "יהלום",
    "יוסף חיים",
    "יצחק רבין",
    "ירושלים",
    "כביש לוד",
    "כדורי יצחק",
    "כהן דוד",
    "כיכר איינשטיין אריק",
    "כיכר גאון יהורם",
    "כיכר דמארי שושנה",
    "כיכר הירש נורית",
    "כיכר הספורטאים",
    "כיכר חיטמן עוזי",
    "כיכר חפר חיים",
    "כיכר טהר לב יורם",
    "כיכר שיר סמדר",
    "כיכר שמר נעמי",
    "ככר 11 בספטמבר",
    "ככר המורשת",
    "ככר הנשיא",
    'ככר י"א חללי מינכן',
    "כליל החורש",
    "כנען",
    "כנרת",
    "כצנלסון",
    "כרמל",
    "ליבנה",
    "לנדאו רמת פנקס",
    "לשם",
    "מונטיפיורי",
    "מועלם יצחק",
    "מיכאל",
    "מירון",
    "מעלה החומה",
    "מעלה המגדל",
    "מצדה",
    "מרכז העיר",
    "משה גניש",
    "נווה הדרים",
    "נווה סביון",
    "ניצן",
    "נתניהו יהונתן",
    "סביון",
    "סגלון",
    "סיני",
    "סמ בית אל",
    "סמ גואטה אפרים",
    'סמ הרמב"ן',
    "סמ חכם שמעון",
    "סמ מחולה",
    'סמ ניל"י',
    "סמטת במעלה",
    "סמטת דוכיפת",
    "סמטת דרור",
    "סמטת הבשן",
    "סמטת הגן",
    "סמטת הלל",
    "סמטת השחר",
    "סמטת זמיר",
    "סמטת חברון",
    "סמטת נחליאלי",
    "סמטת סנונית",
    "סמטת עולי הגרדום",
    "סמטת עפרוני",
    "סמטת פשוש",
    "סמטת קליה",
    "סמטת שחף",
    "סמטת שמאי",
    "סעדון אליהו",
    "ספיר",
    "סקלאר",
    "עבודי יחזקאל",
    "עגיב כמוס רמת פנקס",
    "עזרא הסופר",
    "עין גדי",
    "עין יהב",
    "ענבר",
    "ערבה",
    "פנקס צבי",
    "פרייב ישראל",
    "צאלון",
    "צפריר",
    "קארו יוסף",
    "קזז יחזקאל",
    "קרית גיורא",
    "רח ו",
    "רח יח",
    "רמז דוד",
    "רמת פנקס",
    "ש אזבסטונים",
    "ש המרכז",
    "ש ממשלתי",
    "ש עמידר",
    "ש עממי",
    "שד אלון יגאל",
    "שד בגין מנחם",
    "שד בן גוריון",
    "שד בן פורת מרדכי",
    "שד הבנים",
    "שדרות שמעון פרס",
    "שוהם",
    "שטרן יאיר",
    "שיזף",
    "שירת הזמיר",
    "שכ ההסתדרות",
    "שכ הפרדס",
    "שכ כפר ענה א",
    "שכ כפר ענה ב",
    "שכ נווה רבין",
    "שכ סקיה א",
    "שכ סקיה ב",
    "שלום עליכם",
    "שלמה",
    "שפרינצק",
    "שקד",
    "שרון אריאל",
    "תבור",
    "תדהר",
    "תפארת ישראל",
    "תרשיש",
  ],
  "אור עקיבא": [
    "אבן גבירול",
    "אור עקיבא",
    "אזור התעשייה",
    "אזור תעשייה צפון",
    "אטלס יהודה",
    "איחוד העם",
    "איסר הראל",
    "אלעזר דוד",
    "ביאליק",
    "בלפור",
    "בן יהודה",
    "גלפנשטיין מיכאל",
    "גני אור",
    "דרדשתי",
    "האילן",
    "האירוס",
    "האלה",
    "האלון",
    'האצ"ל',
    "הארבל",
    "הארי",
    "הברוש",
    "הגולן",
    "הגיבורים",
    "הגלבוע",
    "הגפן",
    "הדפנה",
    "ההגנה",
    "ההדס",
    "הורד",
    "הזז חיים",
    "הזית",
    "החבצלת",
    "החרוב",
    "החרמון",
    "היסמין",
    "הכלנית",
    "הכרמל",
    'הלח"י',
    "הלילך",
    "הלל",
    "המרגלית",
    "הנביאים",
    "הנרקיס",
    "הסביונים",
    "הסיגלית",
    "העליה",
    "העצמאות",
    "הערבה",
    'הפלמ"ח',
    "הקסטל",
    "הר המוריה",
    "הר הצופים",
    "הר מירון",
    "הר נבו",
    "הר סיני",
    "הר שלמה",
    "הרב הרצוג",
    "הרב חי טייב",
    "הרב כהנא",
    "הרב מלול יוסף",
    "הרב קוק",
    "הרותם",
    "הרי אפרים",
    "הרי יהודה",
    "הרי מנשה",
    "הרימון",
    "הרצל",
    "השיטה",
    "השיקמים",
    "השקד",
    "התאנה",
    "התבור",
    "התדהר",
    "התמר",
    "ויסנברג יחיאל",
    "ז'בוטינסקי",
    "זך יהודה",
    "חכם מנחם",
    "חללי דקר",
    "י.ל.פרץ",
    "יאנסן עליזה",
    "יהודה הלוי",
    "ינוש קורצ'ק",
    "כהן אלי",
    "מאיר דגן",
    "מאיר עמית",
    "מגד אהרון",
    "מוסינזון יגאל",
    "מעברה",
    "מרכז קליטה",
    'ניל"י',
    "סטנלי מאיר",
    "סמ חואטו סייג",
    "ספינת האגוז",
    "עשרת הרוגי מלכות",
    "פארק תעשיה צפון",
    "ציוני מנחם",
    "קופלוביץ",
    "רבי נחמן מברסלב",
    "רוטשילד",
    'רמב"ם',
    'רש"י',
    "ש בן גוריון",
    "ש קנדי",
    "שבזי",
    "שד המלך דוד",
    "שד הנשיא וייצמן",
    "שד ירושלים",
    "שד שידלובסקי",
    "שיכון דו משפחתי",
    "שכ אורות",
    "שכ בנה ביתך",
    "שכ גני רבין",
    "שכ היובל",
    "שכ נוה אלון",
    "שכ קרוונים",
    'שכונת שז"ר',
    "שלום עליכם",
    "שפירא אברהם",
  ],
  אורה: [
    "אורה",
    "אורן",
    "אלה",
    "ארז",
    "גפן",
    "דולב",
    "דקל",
    "זית",
    "חצב",
    "יסמין",
    "לוטם",
    "נורית",
    "נרקיס",
    "ערבה",
    "רימון",
    "רקפת",
    "תאנה",
    "תלתן",
  ],
  אורות: ["אורות", "האילן", "הדקל", "החרוב", "הצבר", "שכ קרוונים"],
  אורטל: ["אורטל"],
  אורים: ["אורים", "בית ראשון במולדת"],
  אורנים: ["אורנים"],
  אורנית: [
    "אורנית",
    "איילון",
    "אירוס",
    "אלכסנדר",
    "אמנון ותמר",
    "בוקק",
    "דובדבן",
    "דן",
    "דרגות",
    "דרך אודם",
    "דרך החורש",
    "האגוז",
    "האורן",
    "האלה",
    "האלון",
    "הארבל",
    "הארגמן",
    "הארז",
    "האשל",
    "האתרוג",
    "הברוש",
    "הגלבוע",
    "הגעתון",
    "הגפן",
    "הדגנית",
    "הדולב",
    "הדקל",
    "ההדס",
    "ההרדוף",
    "הורד",
    "הזית",
    "החבצלת",
    "החיטה",
    "החצב",
    "החצבני",
    "החרוב",
    "החרמון",
    "החרצית",
    "הטבק",
    "היסמין",
    "היקינטון",
    "הירדן",
    "הירקון",
    "הכלנית",
    "הכרכום",
    "הכרמל",
    "הלוטוס",
    "הלוטם",
    "הליבנה",
    "הלילך",
    "המורן",
    "המרגנית",
    "הנורית",
    "הנרקיס",
    "הסביון",
    "הסחלב",
    "הסיגלית",
    "הסייפן",
    "הערבה",
    "הפיקוס",
    "הפעמונית",
    "הפרג",
    "הצאלון",
    "הצבעוני",
    "הצבר",
    "הקישון",
    "הר הצופים",
    "הר כנען",
    "הרותם",
    "הרימון",
    "הרקפת",
    "השיזף",
    "השיטה",
    "השקד",
    "התאנה",
    "התבור",
    "התדהר",
    "התלתן",
    "זיו",
    "לוע הארי",
    "מבוא הזית",
    "מירון",
    "נעמן",
    "סמטת הארבל",
    "עצמון",
    "ערוגות",
    "פארן",
    "פולג",
    "פרצים",
    "צין",
    "רביבים",
    "שורק",
    "שכ בית וגן",
    "שכ צמרות",
    "שניר",
    "תות",
    "תנינים",
  ],
  אושה: ["אושה"],
  אזור: [
    "אזור",
    "אזור התעשייה",
    "אחד במאי",
    "אחד העם",
    "אלי כהן",
    "אנילביץ",
    "ארלוזורוב",
    "בורוכוב",
    "ביאליק",
    'ביל"ו',
    "בן צבי יצחק",
    "בנבנישתי אברהם",
    "ברזילי",
    "גני אזור",
    "דרך אריאל שרון",
    "דרך השבעה",
    "האיכרים",
    "האקליפטוס",
    "הבנים",
    "הברוש",
    "ההגנה",
    "ההסתדרות",
    "החצב",
    "החשמונאים",
    "היסמין",
    "המצודה",
    "העליה השניה",
    'הפלמ"ח',
    "הפרדס",
    "הצבר",
    "הצנחנים",
    "הרימון",
    "הרצוג",
    "הרצל",
    "השבעה",
    "השלום",
    "השקמה",
    "ויצמן",
    "ז'בוטינסקי",
    "ז'ז'ק דוד",
    "חסין חיים",
    "יוסף מגנזי",
    "יצחק שדה",
    "ירושלים",
    "כצנלסון",
    "ליכט",
    "מדורגי המצודה",
    "מכבים",
    "משעול יעקב",
    "נורדאו",
    "סוקולוב",
    "סמטה אלמונית",
    "סנש חנה",
    "ספיר פנחס",
    "עורקבי צדוק",
    "פינסקר",
    "פרץ",
    "קפלן",
    'רמב"ם',
    "רמז",
    "ש גג",
    "ש החקלאים",
    "ש חדש",
    "ש עולים",
    "ש צריפונים",
    "ש רסקו",
    "שבזי",
    "שד בן גוריון",
    "שוחט מניה",
    "שכ בן גוריון",
    "שכ גן ורבורג",
    "שכ יצחק שדה",
    "שכ שבענה",
    "שפינוזה",
    "שפירא",
    "שפרינצק",
    "שרת משה",
  ],
  אחווה: [
    "אחווה",
    "הגפן",
    "זית",
    "חיטה",
    "נאות אחווה",
    "רימון",
    "שעורה",
    "תמר",
  ],
  אחוזם: ["אחוזם"],
  "אחוזת ברק": [
    "אחוזת ברק",
    "אירוסים",
    "אלה",
    "אלון",
    "בית ראשון במולדת",
    "דבורנית",
    "הארז",
    "הדקל",
    "התאנה",
    "זית",
    "חבצלת",
    "חצב",
    "כלניות",
    "כרכום",
    "לילך",
    "מורן",
    "נוריות",
    "נרקיסים",
    "סחלב",
    "סייפן",
    "סיתוונית",
    "רותם",
    "רימונים",
    "רקפות",
    "שושנים",
    "תורמוס",
  ],
  אחיהוד: ["אחיהוד"],
  אחיטוב: [
    "אחיטוב",
    "אלמוג",
    "דקלים",
    "האורן",
    "האלה",
    "הארז",
    "הגפן",
    "הזית",
    "הלוטם",
    "הרחבה",
    "הרימון",
    "ככר הבנים",
    "ככר המייסדים",
    "תאנה",
  ],
  אחיסמך: ["אחיסמך", "הגפן", "הדקל", "הזית", "התאנה", "שבעת המינים"],
  אחיעזר: [
    "אחיעזר",
    "האלון",
    "האתרוג",
    "הגפן",
    "הדקל",
    "הזית",
    "הרימון",
    "התאנה",
    "יסמין",
  ],
  "אטרש (שבט)": ["אטרש (שבט)"],
  איבים: ["איבים"],
  אייל: ["אייל"],
  "איילת השחר": ["איילת השחר", "איילת השחר (מ ק)"],
  אילון: ["אילון"],
  אילות: ["אילות"],
  אילניה: ["אילניה", "חות השומר"],
  אילת: [
    "אביב",
    "אבני החושן",
    "אברהם אבינו",
    "אגמונים",
    "אדום",
    "אדמון",
    "אדמונית",
    "אדר",
    "אודם",
    "אוכם",
    "אופירה",
    "אוריון",
    "אזוב",
    "אזור המלונות",
    "אזור התעשיה",
    'אח"י אילת',
    "אטד",
    "איזמרגד",
    "אייר",
    "אילות",
    "אילת",
    "אכסניית הנסיך הקטן",
    "אכסנית אביב",
    "אכסנית אל ארצי",
    "אכסנית בית הערבה",
    "אכסנית הבית הלבן",
    "אכסנית המקלט",
    "אכסנית הנוער",
    "אכסנית הרי אדום",
    "אכסנית טאבה",
    "אכסנית לב העיר",
    "אלוורה",
    "אלול",
    "אנטיב",
    "אננס",
    "אנפה",
    "אסתר המלכה",
    "אפון",
    "אפעה",
    "אריקה",
    "ארנבת",
    "ארנון",
    "ביח יוספטל",
    "בית אורן",
    "בית אמיתי",
    "בית החייל",
    "בית הכורה",
    "בית הממשלה",
    "בית עופר",
    "בנה ביתך",
    "בני ישראל",
    "בקיה",
    "ברנע",
    "ברקן",
    "ברקת",
    "בשמת",
    "בשן",
    "בתרן",
    "גד השדה",
    "גדילן",
    "גומא",
    "גיטרן",
    "גן-בנימין",
    "גנים",
    "גרביל",
    "גרגיר הנחלים",
    "גרופית",
    "גרניט",
    "גשרון",
    "דבורה הנביאה",
    "דגנית",
    "דובדבן",
    "דודאים",
    "דוחן",
    "דולב",
    "דולפין",
    "דום",
    "דורבן",
    "די זהב",
    "דפדוף",
    "דפנה",
    "דקר",
    "דרבן",
    "דרדר",
    "דרור",
    "דרך ארץ החיים",
    "דרך באר שבע",
    "דרך הבשמים",
    "דרך הגיא",
    "דרך המטעים",
    "דרך המלכים",
    "דרך הערבה",
    "דרך הרים",
    "דרך השיירות",
    "דרך התבלינים",
    "דרך יותם",
    "דרך מצריים",
    "דרךהציפורים הנודדות",
    "האדומים",
    "האדריכל",
    "האומן",
    "האורגים",
    "האחות",
    "האיצטדיון",
    "האירוס",
    "האכדים",
    "האלה",
    "האלמוגים",
    "האמורים",
    "האסיף",
    "האפרסק",
    "האשל",
    "הבורסקאי",
    "הביכורים",
    "הבלן",
    "הבנאי",
    "הברדלס",
    "הברוש",
    "הגופרית",
    "הגיר",
    "הגלב",
    "הגלעדים",
    "הגמלים",
    "הגנן",
    "הגפן",
    "הדייג",
    "הדס",
    "ההשכלה",
    "הזגג",
    "החוצב",
    "החורב",
    "החותרים",
    "החיתים",
    "החרצית",
    "החרש",
    "החשמונאים",
    "היוצק",
    "הים",
    "הירח",
    "הכוזרים",
    "הכנענים",
    "המדיינים",
    "המואבים",
    "המוצר",
    "המיילדת",
    "המים",
    "המכבים",
    "המלאכה",
    "המלחה",
    "המסגר",
    "המערב",
    "המרינה",
    "הנבטים",
    "הנגר",
    "הנהג",
    "הנווט",
    "הנחתום",
    "הנמל",
    "הנפח",
    "הנץ",
    "הנשר",
    "הסוור",
    "הסוללים",
    "הסיגלית",
    "הסנדלר",
    "הספורטאים",
    "הספן",
    "הסתת",
    "העמונים",
    "העפרוני",
    "העצמאות",
    "הפחח",
    'הפלמ"ח',
    "הפרטיזנים",
    "הצאלון",
    "הצבאים",
    "הצדפים",
    "הצלחה",
    "הצפצפה",
    "הקציר",
    "הרנוג",
    "הרצף",
    "הרתך",
    "השומרונים",
    "השומרים",
    "השחם",
    "השיטה",
    "השמאי",
    "השרברב",
    "השרטט",
    "התבונה",
    "התוכנה",
    "התופרת",
    "התושיה",
    "ונוס",
    "זהבית",
    "זהורית",
    "זהרון",
    "זוגן",
    "זיתן",
    "זנבן",
    "זעת אפיך",
    "זרזיר",
    "חג האילנות",
    "חג החנוכה",
    "חג הסוכות",
    "חג השבועות",
    "חודשי השנה",
    "חוות רודד",
    "חוף אלמוג",
    "חטיבת גולני",
    "חטיבת הנגב",
    "חיטת הבר",
    "חיל ההנדסה",
    "חיל הים",
    "חיננית",
    "חכליל",
    "חלמית",
    "חניתן",
    "חרדל",
    "חשון",
    "חתול הבר",
    'ט"ו באב',
    "טבלן",
    "טבת",
    "טווסון",
    "טופז",
    "טוריים",
    "טורקיז",
    "טיילת רחבעם זאבי",
    "טל ומטר",
    "יהודה",
    "יהושפט",
    "יהל",
    "יהלום",
    "יוזמה",
    "יולית ים סוף",
    "יום אילת",
    "ים סוף",
    "יסמין",
    "יעקב אבינו",
    "יערה",
    "יפה זנב",
    "יצחק אבינו",
    "ירבוע",
    "ירדן",
    "ירושלים השלמה",
    "כוכב",
    "כיכר אדן",
    "כיכר אנטיב",
    "כיכר בגין",
    "כיכר גולדה מאיר",
    "כיכר האוניברסיטה",
    "כיכר הג'אז",
    "כיכר הים האדום",
    "כיכר הרי אילת",
    "כיכר הרמזורים",
    "כיכר השחפים",
    "כיכר השלושה",
    'כיכר ויצ"ו',
    "כיכר יאלטה",
    "כיכר יגאל אלון",
    "כיכר ים סוף",
    "כיכר יצחק נבון",
    "כיכר יצחק שמיר",
    "כיכר לוי אשכול",
    "כיכר משה שרת",
    "ככר אילים",
    "ככר אריות",
    "ככר גרוף",
    "ככר דוד בן גוריון",
    "ככר הבונים החופשיים",
    "ככר הדולפינים",
    "ככר הזאבים",
    "ככר המייסדים",
    "ככר הנופלים",
    "ככר הנמרים",
    "ככר הנשים",
    "ככר ורד",
    "ככר זעמן",
    'ככר חב"ד',
    "ככר חורשף",
    "ככר חצרות",
    "ככר טורונטו",
    "ככר יצחק מודעי",
    "ככר ירושלים",
    "ככר ליבנה",
    "ככר מגדלי התאומים",
    "ככר מלכי יהודה",
    "ככר מלכי ישראל",
    "ככר מקהלות",
    "ככר נטפים",
    "ככר צנינית",
    "ככר ראשי העיר",
    "ככר רבין",
    "ככר רוטרי",
    "ככר רפידים",
    "ככר תל אביב",
    "כליל החורש",
    "כנרת",
    "כסלו",
    "כפות תמרים",
    "כפר הנופש",
    "כריש",
    "כרמל",
    "כרפס",
    "לבונה",
    "לבנדר",
    "להבית",
    "לואיזה",
    "לוחמי הגיטאות",
    "לוטוס",
    "לוס אנג'לס",
    "לוף ארץ ישראלי",
    "לילך",
    "לימונית",
    "לשם",
    "מגדל חי",
    "מגדלי אילת",
    "מדיין",
    "מועדון הים התיכון",
    "מועדון צלילה סנובה",
    "מועדי ישראל",
    "מור",
    "מח סוב",
    "מחנה יוכבד",
    "מידברון",
    "מיכל בת שאול",
    "מישר",
    "מלון אמבסדור",
    "מלון אמריקנה",
    "מלון בית אשל",
    "מלון בל",
    "מלון בקעת הירדן",
    "מלון בקעת הירח",
    "מלון גולדן טוליפ",
    "מלון גלי אילת",
    "מלון גני שולמית",
    "מלון דורטל סול",
    "מלון דליה",
    "מלון דן",
    "מלון דן פנורמה",
    "מלון דקל",
    "מלון הולידיי אין",
    "מלון הים האדום",
    "מלון המלך שלמה",
    "מלון הני-מון",
    "מלון הסלע האדום",
    "מלון הקונכיה",
    "מלון הרודס",
    "מלון הרי אדום",
    "מלון הריף",
    "מלון ויסטה",
    "מלון זוהר",
    "מלון טופז",
    "מלון לב אילת",
    "מלון לב פתאל",
    "מלון לגונה",
    "מלון לויס טאואר",
    "מלון מוריה פלאזה",
    "מלון מיראג'",
    "מלון מלכת שבא",
    "מלון מפרץ השמש",
    "מלון מרדיאן",
    "מלון מרידיאן",
    "מלון נובה",
    "מלון נוף המפרץ",
    "מלון נפטון",
    "מלון סיני",
    "מלון סנטר",
    "מלון סנפיר",
    "מלון ספורט",
    "מלון עדי",
    "מלון עציון",
    "מלון פטיו",
    "מלון פטרה",
    "מלון פייר",
    "מלון פלמירה",
    "מלון פנורמה",
    "מלון פרדייז גארדן",
    "מלון צברה",
    "מלון קיסר",
    "מלון קלאב אין",
    "מלון קלאב הוטל",
    "מלון קלאב מד",
    "מלון קראון פלאזה",
    "מלון קרוון",
    "מלון רד סי ספורט",
    "מלון רויאל ביץ'",
    "מלון רויאל גארדן",
    "מלון רויאל פארק",
    "מלון ריביירה",
    "מלון שלום פלאזה",
    "מלון שלמה",
    "מלון תמר",
    "מלון תרשיש",
    "מליסה",
    "מלכת שבא",
    "מעוג",
    "מעונות מכבי",
    "מערב 1",
    "מערב 2",
    "מצפה נוף",
    "מקור החסידה",
    "מרגנית",
    "מרווה",
    "מריון",
    "מרינה",
    "מרכז קליטה",
    "מרכז רזין",
    "מרכז רכטר",
    "מרכז תיירות",
    "מש' חצוצרן",
    "מש' לבנית ים סוף",
    "מש' סבכי",
    "מש' קורא מדברי",
    "מש' תמירון",
    "משעול אבובן",
    "משעול אבוקדו",
    "משעול אבנון",
    "משעול אגוז",
    "משעול אהרון הכהן",
    "משעול אירית",
    "משעול אלון",
    "משעול אנונה",
    "משעול אפרסמון",
    "משעול אקליפטוס",
    "משעול אשכולית",
    "משעול אתרוג",
    "משעול בננה",
    "משעול גוייבה",
    "משעול האגס",
    "משעול האמוראים",
    "משעול הבז",
    "משעול הגאונים",
    "משעול הגירית",
    "משעול החמנית",
    "משעול הלויים",
    "משעול העורב",
    "משעול הקנה",
    "משעול הר נבו",
    "משעול התורן",
    "משעול התלמוד",
    "משעול התנאים",
    "משעול זית",
    "משעול חבושים",
    "משעול חלבלוב",
    "משעול חלילן",
    "משעול חצב",
    "משעול חרוב",
    "משעול כלנית",
    "משעול כרמים",
    "משעול לופית",
    "משעול לימון",
    "משעול מחצבים",
    "משעול מילחית",
    "משעול מישמש",
    "משעול מנגו",
    "משעול מנדרינה",
    "משעול משה רבנו",
    "משעול נורית",
    "משעול נרקיס",
    "משעול סהרון",
    "משעול סמר",
    "משעול סנפיר",
    "משעול עדעד",
    "משעול עינב",
    "משעול ערמון",
    "משעול פומלה",
    "משעול פלגית",
    "משעול פקאן",
    "משעול צבעוני",
    "משעול צופר",
    "משעול צלף",
    "משעול צניפים",
    "משעול רביב",
    "משעול רוגן",
    "משעול רחל אימנו",
    "משעול ריחן",
    "משעול ריסן",
    "משעול שגיא",
    "משעול שזיף",
    "משעול שיקמה",
    "משעול שקד",
    "משעול תאנה",
    "משעול תות",
    "משעול תפוז",
    "משעול תפוח",
    "מתן תורה",
    "נביעות",
    "נווה מדבר",
    "נוף מדבר",
    "נופר",
    "נחל גרעינית",
    "נחל זרחן",
    "נחל חביון",
    "נחל חיון",
    "נחל כתובות",
    "נחל ממשית",
    "נחל ניצוץ",
    "נחל עמרם",
    "נחל רחם",
    "נחל שחרות",
    "נחשון",
    "ניסן",
    "ניצרן",
    "נירית",
    "נמל אילת",
    "נמנמן",
    "נעמה",
    "נענע משולבת",
    "נפטון",
    "נציץ",
    "סביון",
    "סולית",
    "סוסיתא",
    "סופה",
    "סחלב",
    "סיון",
    "סייפן",
    "סילון",
    "סיני",
    "סירה קוצנית",
    "סמ אזורית",
    "סמ אשליל",
    "סמ ברק",
    "סמ גבים",
    "סמ דיה",
    "סמ דישונים",
    "סמ האשלג",
    "סמ המכונאים",
    "סמ המכרות",
    "סמ המלך אחז",
    "סמ המנגן",
    "סמ המנהרות",
    "סמ המסילות",
    "סמ המערות",
    "סמ הנגרים",
    "סמ הנחושת",
    "סמ הצוק",
    "סמ הקורא",
    "סמ הר גשור",
    "סמ הר דלעת",
    "סמ הר חלוקים",
    "סמ הר יעלון",
    "סמ הר לוץ",
    "סמ הר סיני",
    "סמ הר סעד",
    "סמ הר שלמה",
    "סמ הרדוף",
    "סמ התנינים",
    "סמ ורדית",
    "סמ זיק",
    "סמ חבצלת",
    "סמ חלמיש",
    "סמ טירן",
    "סמ יסעור",
    "סמ יענים",
    "סמ כרכום",
    "סמ לילית",
    "סמ לענה",
    "סמ מואב",
    "סמ מולית",
    "סמ מלכית",
    "סמ נחושתן",
    "סמ נחל אורה",
    "סמ נחל חימר",
    "סמ נחל חמדה",
    "סמ נחל חרוז",
    "סמ נחל מנוחה",
    "סמ נחל ציחור",
    "סמ נחל קטורה",
    "סמ נחל שני",
    "סמ ניצנה",
    "סמ סיגים",
    "סמ סלעית",
    "סמ סנונית",
    "סמ עומר",
    "סמ עופרים",
    "סמ עיט",
    "סמ עירית",
    "סמ ערד",
    "סמ עשוש",
    "סמ צוקים",
    "סמ ראמים",
    "סמ רביבים",
    "סמ רימון",
    "סמ שדמית",
    "סמ שיזר",
    "סמ שני",
    "סמ שעיר",
    "סמ שפיפון",
    "סמ שפריר",
    "סמולין",
    "סמור",
    "סמטת ציפחה",
    "ספיר",
    "סרפד",
    "סתונית",
    "סתיו",
    "עגור",
    "עונות שנה",
    "עטלפים",
    "עין בוקק",
    "עין גדי",
    "עין דור",
    "עין החתול",
    "עין יהב",
    "עין מור",
    "עין נטפים",
    "עין עקב",
    "עין עקרבים",
    "ערוד",
    "ערער",
    "פארן",
    "פארק תמנע",
    "פולטי טאור",
    "פזית",
    "פטישן",
    "פטל",
    "פיקוס",
    "פלמינגו",
    "פסון",
    "פסח",
    "פסמון",
    "פרג",
    "פרס",
    "פרפרון",
    "פרקרק",
    "פתן",
    "צבעון",
    "צדק",
    "צופית עלית",
    "צופית תחתית",
    "צור",
    "צין",
    "צפחות",
    "צפע",
    "קאמן",
    "קאמפן",
    "קבוץ המאוחד",
    "קדש",
    "קוצן זהוב",
    "קורנית",
    "קטורה",
    "קטלב מצוי",
    "קידה",
    "קידר",
    "קינמון",
    "קיסוס",
    "קיסר הדור",
    "קיפוד",
    "קיפודג",
    "קרלובי-וארי",
    "קרקל",
    "קשת",
    "ראש השנה",
    "ראש חודש",
    "רובע האבות",
    "רובע האמהות",
    "רובע הבשמים",
    "רובע המעיינות",
    "רובע הנחלים",
    "רובע העונות",
    "רובע הפירות",
    "רובע שבטי ישראל",
    "רודד",
    "רוזמרין",
    "רות המואביה",
    "רותם",
    "רמת יותם",
    "רקפת",
    "רתמים",
    "ש אופיר",
    "ש אטונגים",
    "ש בתי ברמן",
    "ש האשל",
    "ש סנפיר",
    "ש פטיו",
    "ש צופית",
    "שבט",
    "שביל בוקר",
    "שביל הצבי",
    "שביל השמש",
    "שביל התור",
    "שביל יחמורים",
    "שביל יטבתה",
    "שביל לוטם",
    "שביל סוסים",
    "שביל עברונה",
    "שביל עידן",
    "שביל עתק",
    "שביל פעמי השלום",
    "שביל פראים",
    "שביל צבר",
    "שביל שונית",
    "שביל שועלים",
    "שביל שיזף",
    "שד ארגמן",
    "שד התמרים",
    "שד טורונטו",
    "שד יעלים",
    "שד עובדה",
    "שד ששת הימים",
    "שדה התעופה",
    "שובל",
    "שוהם",
    "שומר",
    "שופרון",
    "שור",
    "שושן",
    "שחור זנב",
    "שחורת",
    "שחמון",
    "שחף",
    "שיבולים",
    "שכ אורים",
    "שכ אמדר",
    "שכ גנים",
    "שכ גנים ב",
    "שכ הדקל",
    "שכ המלחה",
    "שכ העמים",
    "שכ יעלים",
    "שכ יפה נוף",
    "שכ מצפה ים",
    "שכ נווה מדבר",
    "שכ עידן",
    "שכ ערבה",
    "שכ צאלים",
    "שכ שחמון",
    "שכ שקמונה",
    "שלדג",
    "שמיני עצרת",
    "שן הארי",
    "שפן סלעים",
    "שקמונה",
    "שרביטן",
    "שרה אימנו",
    "שרקרק",
    "תוכינון",
    "תורמוס",
    "תלתן",
    "תמוז",
    "תמר",
    "תפאר",
    "תפוח סדום",
    "תרשיש",
    "תשרי",
  ],
  אירוס: [
    "אירוס",
    "דוכיפת",
    "הבזלת",
    "הגרניט",
    "הדולומיט",
    "החצב",
    "הכורכר",
    "הכלנית",
    "הנופר",
    "הנורית",
    "הנחליאלי",
    "הנרקיס",
    "הסביון",
    "הסחלב",
    "הסייפן",
    "העגור",
    "העירית",
    "העפרוני",
    "הפרדס",
    "הפשוש",
    "הצבעוני",
    "הרותם",
    "הרקפת",
    "השחף",
    "כיכר יהדות בולגריה",
    "מאדים",
    "נגה",
    "נחל איילון",
    "נחל ברקן",
    "נחל גלים",
    "נחל דרגות",
    "נחל האלה",
    "נחל הבשור",
    "נחל הירדן",
    "נחל הירקון",
    "נחל לכיש",
    "נחל פארן",
    "נחל ציפורי",
    "נחל שורק",
    "שבתאי",
    "שדרות גן רווה",
  ],
  איתמר: ["איתמר"],
  איתן: ["איתן"],
  איתנים: ["איתנים"],
  אכסאל: [
    "אזור בתי הספר",
    "אכסאל",
    "דנאנה",
    "הזיתים",
    "זייתון אלשמאלי",
    "מרכז-מדרום למסגד",
    "מרכז-מצפון למסגד",
    "סעד וסעיד",
    "רח 100",
    "רח 200",
    "רח 500",
    "רח 10",
    "רח 110",
    "רח 510",
    "רח 20",
    "רח 120",
    "רח 30",
    "רח 40",
    "רח 50",
    "רח 150",
    "רח 60",
    "רח 160",
    "רח 80",
    "רח 90",
    "רח 1",
    "רח 101",
    "רח 201",
    "רח 11",
    "רח 111",
    "רח 21",
    "רח 121",
    "רח 31",
    "רח 41",
    "רח 51",
    "רח 151",
    "רח 61",
    "רח 161",
    "רח 71",
    "רח 81",
    "רח 91",
    "רח 2",
    "רח 102",
    "רח 202",
    "רח 502",
    "רח 12",
    "רח 112",
    "רח 512",
    "רח 22",
    "רח 122",
    "רח 32",
    "רח 42",
    "רח 52",
    "רח 62",
    "רח 162",
    "רח 82",
    "רח 92",
    "רח 3",
    "רח 203",
    "רח 503",
    "רח 13",
    "רח 113",
    "רח 23",
    "רח 123",
    "רח 33",
    "רח 43",
    "רח 53",
    "רח 153",
    "רח 63",
    "רח 163",
    "רח 73",
    "רח 83",
    "רח 93",
    "רח 4",
    "רח 104",
    "רח 504",
    "רח 14",
    "רח 114",
    "רח 24",
    "רח 124",
    "רח 34",
    "רח 54",
    "רח 154",
    "רח 64",
    "רח 164",
    "רח 74",
    "רח 84",
    "רח 94",
    "רח 5",
    "רח 105",
    "רח 505",
    "רח 15",
    "רח 115",
    "רח 25",
    "רח 35",
    "רח 45",
    "רח 55",
    "רח 155",
    "רח 65",
    "רח 75",
    "רח 95",
    "רח 195",
    "רח 6",
    "רח 106",
    "רח 16",
    "רח 116",
    "רח 26",
    "רח 36",
    "רח 46",
    "רח 56",
    "רח 156",
    "רח 66",
    "רח 76",
    "רח 86",
    "רח 96",
    "רח 107",
    "רח 507",
    "רח 17",
    "רח 117",
    "רח 27",
    "רח 37",
    "רח 47",
    "רח 57",
    "רח 157",
    "רח 67",
    "רח 77",
    "רח 87",
    "רח 97",
    "רח 8",
    "רח 108",
    "רח 18",
    "רח 118",
    "רח 28",
    "רח 38",
    "רח 48",
    "רח 58",
    "רח 158",
    "רח 68",
    "רח 78",
    "רח 88",
    "רח 98",
    "רח 9",
    "רח 109",
    "רח 19",
    "רח 119",
    "רח 29",
    "רח 39",
    "רח 49",
    "רח 59",
    "רח 159",
    "רח 79",
    "רח 89",
    "רח 99",
    "שכונה חדשה",
    "שכונה מזרחית",
    "שכונה מערבית",
    "שכונה צפונית",
  ],
  "אל סייד": ["אל סייד"],
  "אל-עזי": ["אל-עזי"],
  "אל-עריאן": ["אל-עריאן"],
  "אל-רום": ["אל-רום"],
  אלומה: ["אלומה", "חזון יחזקאל"],
  אלומות: ["אלומות"],
  "אלון הגליל": [
    "אלון הגליל",
    "דרך הרקפות",
    "האלונים",
    "הגת",
    "החורש",
    "החצבים",
    "החקלאים",
    "המוביל",
    "הנחל",
    "השלף",
  ],
  "אלון מורה": [
    "אלון מורה",
    "ארץ חמדה",
    "בשן",
    "גלעד",
    "דרך שמונה עליות",
    "חרמון",
    "כנסת ישראל",
    "מוריה",
    "משעול הכרכום",
    "משעול המור",
    "משעול הציפורן",
    "משעול הצרי",
    "נווה תרצה",
    "נצח ישראל",
    "סיני",
    "שכ רמי",
    "שניר",
  ],
  "אלון שבות": [
    "אל ההר",
    "אלון שבות",
    'גבעת החי"ש',
    "האגוז",
    "האלה",
    "האלון",
    "הארז",
    "הזית",
    "החורש",
    "הישיבה",
    'הל"ה',
    "הרימון",
    "הרקפת",
    "השיירות",
    "התאנה",
    "התמר",
    "ישיבת הר עציון",
    'כ"ח באייר',
    "מגדל עדר",
    "מגיני הגוש",
    "מעלה מיכאל",
    "משעול הגפן",
    "משעול הדקלים",
    "משעול הורד",
    "נצח ירושלים",
    "קבוצת אברהם",
    "שכ גבעות",
    "שכ גבעת הברכה",
    "שכ גבעת העץ",
    "שכ משתלות",
  ],
  "אלוני אבא": [
    "אלוני אבא",
    "האגוז",
    "האלה",
    "האלונים",
    "הגפן",
    "הדולב",
    "ההדרים",
    "הזית",
    "החוה",
    "החורש",
    "החרוב",
    "הכלניות",
    "הכרמים",
    "הסמטה הירוקה",
    "העמל",
    "העמק",
    "הרמון",
    "הרקפות",
    "השקד",
    "סמטת הדקלים",
    "סמטת הצבר",
    "שדרת הברושים",
  ],
  "אלוני הבשן": ["אלוני הבשן"],
  "אלוני יצחק": ["אלוני יצחק", "כפר הנוער"],
  אלונים: ["אלונים"],
  "אלי-עד": ["אלי-עד"],
  אליאב: [
    "אליאב",
    "אשחר",
    "האטד",
    "האשל",
    "הגפן",
    "הזית",
    "הלוטם",
    "הליבנה",
    "הצבר",
    "השיטה",
    "השקד",
    "חרוב",
  ],
  אליכין: [
    "אליכין",
    "בשמת",
    "גבעת הבריכה",
    "האיה",
    "האירוס",
    "האנפה",
    "הגפן",
    "הדרור",
    "הורד",
    "הזמיר",
    "החוגלה",
    "החוחית",
    "החופית",
    "החצב",
    "החרצית",
    "היסמין",
    "היסעור",
    "הכלנית",
    "הלילך",
    "הנורית",
    "הנחליאלי",
    "הנרקיס",
    "הסביון",
    "הסחלב",
    "הסייפן",
    "הסלעית",
    "הסנונית",
    "העפרוני",
    "הפשוש",
    "הצבעוני",
    "הצופית",
    "הצפורן",
    "הרימון",
    "הרקפת",
    'הרש"ש',
    "השחף",
    "השלדג",
    "התאנה",
    "התמר",
    "חבצלת השרון",
    "כנפי נשרים",
    "מרבד הקסמים",
    "סיגלית",
    'רמב"ם',
    "שבזי",
    "שכ האלונים",
    "שקד",
  ],
  אליפז: ["אליפז"],
  אליפלט: ["אליפלט"],
  אליקים: ["אליקים"],
  אלישיב: [
    "אלישיב",
    "הבאר",
    "הגבעה",
    "הגורן",
    "הגפן",
    "הזית",
    "החיטה",
    "החרוב",
    "הכלנית",
    "הראשונים",
    "הרימון",
    "השעורה",
    "התאנה",
    "התמר",
  ],
  אלישמע: [
    "אלישמע",
    "הדקל",
    "הורדים",
    "הנרקיס",
    "הרחבה",
    "הרקפת",
    "כלנית",
    "סמ אורן",
    "סמ אלה",
    "סמ אלון",
    "סמ אקליפטוס",
    "סמ ברוש",
    "סמ זית",
    "סמ חרוב",
    "סמ צאלון",
    "סמ תמר",
  ],
  אלמגור: ["אלמגור"],
  אלמוג: ["אלמוג"],
  אלעד: [
    "אבטליון",
    "אבן גבירול",
    "אבן עזרא",
    "אלעד",
    "בעלי התוספות",
    'הב"ח',
    "הזמיר",
    "הלל",
    "המאירי",
    'המהרש"ל',
    'הר"ן',
    'הרא"ש',
    'הרי"ף',
    "התאנה",
    "התור",
    "חוני המעגל",
    "סעדיה גאון",
    "עובדיה מברטנורה",
    'ראב"ד',
    "רבי חייא",
    "רבי טרפון",
    "רבי יהודה הלוי",
    "רבי יהודה הנשיא",
    "רבי יהושע",
    "רבי יונתן בן עוזיאל",
    "רבי יוסי בן קיסמא",
    "רבי יוסף קארו",
    "רבי מאיר",
    "רבי עקיבא",
    "רבי פנחס בן יאיר",
    "רבי שמואל הנגיד",
    "רבינו בחיי",
    "רבינו גרשום",
    "רבינו חננאל",
    "רבן גמליאל",
    "רבן יוחנן בן זכאי",
    "רבנו ניסים גאון",
    "רבנו תם",
    'רד"ק',
    "רובע A",
    "רובע B",
    "רובע C",
    "רובע D",
    "רובע E",
    "רובע F",
    "רובע G",
    "רובע H",
    'ריטב"א',
    'רמ"ה',
    'רמב"ם',
    'רמב"ן',
    'רש"י',
    'רשב"י',
    'רשב"ם',
    "שכ מרומי אלעד",
    "שמאי",
    "שמעון בן שטח",
    "שמעון הצדיק",
    "שמעיה",
  ],
  אלעזר: [
    "אלעזר",
    "בית זכריה",
    "בית צור",
    "גופנה",
    "גוש קטיף",
    "גן אור",
    "גני טל",
    "דרך נתיב האבות",
    "חשמונאים",
    "יהודה",
    "יהודית",
    "יהונתן",
    "יוחנן",
    "מודיעין",
    "מעלה בית חורון",
    "מתתיהו",
    "נווה דקלים",
    "ניסנית",
    "נצר חזני",
    "נצרים",
    "שכ נתיב האבות",
    "שלומציון",
    "שמעון",
  ],
  "אלפי מנשה": [
    "אדיר",
    "אודם",
    "אזור תעשייה",
    "אילון",
    "אירוס",
    "אלפי מנשה",
    "אמנון ותמר",
    "ארבל",
    "בשור",
    "גבעת טל",
    "גולן",
    "גלבוע",
    "גליל",
    "גלעד",
    "גפן",
    "גרניט",
    "דגנית",
    "דוכיפת",
    "דם המכבים",
    "דן",
    "דרור",
    "דרך חסדאי אליעזר",
    "הזית",
    "החרוב",
    "העמק",
    "הרדוף",
    "השפלה",
    "התאנה",
    "חבצלת",
    "חוחית",
    "חלוצי התעשיה",
    "חרמון",
    "חרצית",
    "טופז",
    "יהודה",
    "יסמין",
    "ירדן",
    "כלנית",
    "כנען",
    "כנרת",
    "כרכום",
    "כרמל",
    "לבנה",
    "לוטם",
    "לכיש",
    "מירון",
    "מנור",
    "מרגנית",
    "משעול סייפן",
    "נגב",
    "נרקיס",
    "סביון",
    "סייפן",
    "סלעית",
    "סמ תבור",
    "סנונית",
    "עפרוני",
    "עצמון",
    "ערבה",
    "פולג",
    "פשוש",
    "צבעוני",
    "צופית",
    "קדרון",
    "רביד",
    "רותם",
    "רקפת",
    "שגיא",
    "שובל",
    "שומרון",
    "שורק",
    "שכ יובלים",
    "שכ כפיר יוסף",
    "שכ נופיה",
    "שלדג",
    "שניר",
    "תבור",
  ],
  אלקוש: ["אלקוש"],
  אלקנה: [
    "אדני פז",
    "אהלי קידר",
    "איבי הנחל",
    "אילות השדה",
    "אלף המגן",
    "אלקנה",
    "אעלה בתמר",
    "אפיקי מים",
    "אשכולות הגפן",
    "בנות ירושלים",
    "גבורי ישראל",
    "גבעת הלבונה",
    "גינת אגוז",
    "הר גלעד",
    "הר המור",
    "הרי בשמים",
    "חבצלת השרון",
    "חגוי הסלע",
    "חוט השני",
    "חרמון",
    "טורי זהב",
    "כרכום",
    "כרמל",
    "מגדל דוד",
    "מגדל הלבנון",
    "מגן דן",
    "מחניים",
    "מים חיים",
    "מכללת אורות",
    "מעין גנים",
    "מעלה ארגמן",
    "משכנות הרועים",
    "משעול ספיר",
    "משעול תרשיש",
    "סמדר",
    "עורי צפון",
    "עין גדי",
    "עמודי שיש",
    "עקבי הצאן",
    "ערוגות הבושם",
    "עת הזמיר",
    "פלח הרימון",
    "פרדס רימונים",
    "פרי מגדים",
    "צרור המור",
    "קול התור",
    "קינמון",
    "קרית חינוך",
    "רשפי אש",
    "שומרי החומות",
    "שושנת העמקים",
    "שיר השירים",
    "שלטי גבורים",
    "שניר",
    "תאנה",
    "תלפיות",
    "תפוח",
  ],
  אמונים: ["אמונים", "הדס", "הדקל", "הזית", "הרימון", "השקד", "התאנה"],
  אמירים: ["אמירים"],
  אמנון: ["אמנון"],
  אמציה: ["אמציה"],
  אניעם: ["אניעם"],
  "אסד (שבט)": ["אסד (שבט)"],
  אספר: ["אספר", "שכ פני קדם"],
  אעבלין: [
    "אבו ח'רביש",
    "אבו נח'לה",
    "אל רג'ום",
    "אלביאדר",
    "אלג'בל",
    "אלג'למה",
    "אלח'לה",
    "אלכרום",
    "אלפס",
    "אלשמאלי",
    "אם אלשתויה",
    "אעבלין",
    "בח'ית",
    "בין אלג'לאים",
    "בלישה",
    "גרעין הכפר",
    "ואדי אלסהילה",
    "מקתול",
    "סירת אלעג'אל",
    "עין אלתינה",
    "ראס נעמה",
    "שכונת מגרש כדורגל",
  ],
  "אעצם (שבט)": ["אעצם (שבט)"],
  "אפיניש (שבט)": ["אפיניש (שבט)"],
  אפיק: ["אפיק"],
  אפיקים: ["אפיקים"],
  אפק: ["אפק"],
  אפרת: [
    "אלה",
    "אפרת",
    "אריאל",
    "בועז",
    "בית הבחירה",
    "גבעת הזית",
    "דביר",
    "האלומה",
    "הגואל",
    "הגורן",
    "הגפן",
    "הדבש",
    "הדגן",
    "הדקל",
    "הזורעים",
    "החריש",
    "הכרמים",
    'הל"ה',
    "המנורה",
    "המעיין",
    "העומר",
    "הציפורן",
    "הקציר",
    "השיירות",
    "השקמה",
    "התאנה",
    "זית שמן",
    "זכריה",
    "זרובבל",
    "חגי",
    "חזקיהו המלך",
    "חנה",
    "יהודה המכבי",
    "יונתן החשמונאי",
    "כורש",
    "מבוא אלון",
    "מבוא הדס",
    "מבוא הדר",
    "מבוא ההר הטוב",
    "מבוא חרוב",
    "מבוא יצהר",
    "מבוא ניקנור",
    "מבוא ערבה",
    "מגדל עדר",
    "מוריה",
    "מורית",
    "מעלה אביב",
    "מעלה אורן",
    "מעלה ברוש",
    "מעלה גבעול",
    "מעלה הביכורים",
    "מעלה הזית",
    "מעלה הלקט",
    "מעלה המגל",
    "מעלה המורג",
    "מעלה המנחה",
    "מעלה הסלע",
    "מעלה השקד",
    "מעלה התלם",
    "מעלה יערה",
    "מעלה כפר עציון",
    "מעלה כרמל",
    "מעלה לוטם",
    "מעלה משואות יצחק",
    "מעלה עין צורים",
    "מעלה קטלב",
    "מעלה רבדים",
    "מעלה שיבולת",
    "מעלה תדהר",
    "מתתיהו הכהן",
    "נחל אשכול",
    "נחמיה",
    "נטף",
    "נעמי",
    "נצח ירושלים",
    "נצר ישי",
    "סמדר",
    "עוזיהו המלך",
    "עזרא",
    "עץ החיים",
    "פיטום הקטורת",
    "קרית המבתר",
    "רות",
    "רחל אמנו",
    "רימון",
    "שאלתיאל",
    "שבות ישראל",
    "שד המלך דוד",
    "שד המלך שלמה",
    "שיבת ציון",
    "שכ גבעת רימון",
    "שכ דקל",
    "שכ הגפן",
    "שכ הדגן",
    "שכ תאנה",
    "שכ תמר",
    "שלם",
    "תירוש",
    "תלפיות",
    "תמר",
  ],
  ארבל: [
    "ארבל",
    "האלה",
    "האלונים",
    "הגפן",
    "הזית",
    "החרוב",
    "המייסדים",
    "הנוטעים",
    "הראשונים",
    "הרימון",
    "השיזף",
    "השיטה",
    "השקד",
    "התאנה",
    "כיכר האילנות",
    "כיכר המייסדים",
    "מורד הצבר",
    "שדרת הברושים",
  ],
  ארגמן: ["ארגמן"],
  ארז: ["ארז"],
  אריאל: [
    "אבנר",
    "אדום",
    "אזור תעשיה מערב",
    "אילות",
    "אירוס",
    "אריאל",
    "בית אל",
    "בית לחם",
    "בקעת הירדן",
    "בראון אורי",
    "ברק",
    "גדעון",
    "גילה",
    "גנים",
    "דבורה",
    "דן",
    "דרך אפרתה",
    "דרך הנחשונים",
    "דרך הציונות",
    "דרך עפרון",
    "דרך רמת הגולן",
    "האלון",
    'האצ"ל',
    "הארז",
    "הבנאי",
    "הבשן",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הדקל",
    "ההגנה",
    "הורדים",
    "החרוב",
    "החרמון",
    "הירדן",
    "המלאכה",
    "המסגר",
    "הנגב",
    "הסביונים",
    "העבודה",
    "העמל",
    "העצמאות",
    "הערבה",
    "הפסגה",
    "הקוממיות",
    "הרדוף",
    "השומרון",
    "השקד",
    "השקמה",
    "התבור",
    "חברון",
    "חופית",
    "יהודה",
    "יואב",
    "יודפת",
    "ימית",
    "יסמין",
    "יפה נוף",
    "יפתח",
    "כביש 101",
    "כביש 31",
    "כביש 32",
    "כביש 33",
    "כביש 34",
    "כביש 35",
    "כביש 36",
    "כלילת יופי",
    "כלניות",
    "מבוא חמה",
    "מבצע דני",
    "מבצע הראל",
    "מבצע חירם",
    "מבצע עובדה",
    "מבצע קדש",
    "מואב",
    "מוריה",
    "מכללת אריאל",
    "מעלה הגבעה",
    "מפרץ אילת",
    "מפרץ שלמה",
    "מצדה",
    "משעול א",
    "משעול ב",
    "משעול ברכה",
    "משעול ג",
    "משעול ד",
    "משעול ה",
    "משעול הזית",
    "משעול ו",
    "משעול ז",
    "משעול רימון",
    "משעול תאנה",
    "משעול תמר",
    "נוה שאנן",
    "נורית",
    "סחלב",
    "עיר דוד",
    "עיר היונה",
    "עצמון",
    'צה"ל',
    "ציון",
    "קרית המדע",
    "שד ירושלים",
    "שכ ארבל",
    "שכ המור",
    "שכ הפרחים",
    "שכ הראשונים",
    "שכ יובלים",
    "שכ עצמה",
    "שכ קרוונים",
    "שכ רמים",
    "שכ שגיא",
    "שניר",
    "שער הגיא",
    "ששת הימים",
    "תאשור",
    "תדהר",
  ],
  ארסוף: ["אלמוג", "ארסוף", "הגלים", "מבוא הים", "מול הים", "נחשול", "צדף ים"],
  אשבול: ["אשבול"],
  אשבל: ["אשבל"],
  אשדוד: [
    "אב",
    "אבות ישורון",
    "אבטליון",
    "אביי",
    "אבן גבירול",
    "אבן עזרא",
    "אבני החושן",
    "אבנר בן נר",
    "אגדיר",
    "אגוז",
    "אגס",
    "אגת-שבו",
    "אדוננו ברוך",
    "אדיסון",
    'אדמו"ר מפיטסבורג',
    "אדר",
    "אהוד מנור",
    "אהרונסון שרה",
    "אודם",
    "אוניון",
    "אוסישקין מנחם",
    "אופל",
    "אור החיים",
    "אור לציון",
    "אורט",
    "אוריה החיתי",
    "אזור בקמה",
    "אזור המרינה",
    "אזור התעשיה",
    "אזור יא",
    "אזור תעשייה 1",
    "אזור תעשייה 2",
    "אזור תעשייה 3",
    "אזורים",
    'אח"י אילת',
    "אחד העם",
    "אחוזת בית",
    "אחימאיר אבא",
    "אחיעזר",
    "אחלמה",
    "אטלי",
    "אידלסון בבה",
    "אייל",
    "איינשטיין",
    "אייר",
    "אילון",
    "איתמר",
    "אל הציפור",
    "אלבז נתן",
    "אלול",
    "אלון יגאל",
    "אליאב לובה",
    "אליאשוילי",
    "אליעזר בן הורקנוס",
    "אלתרמן נתן",
    "אמרלד",
    "אנילביץ מרדכי",
    "אסבסטונים",
    "אסתר המלכה",
    "אפרסמון",
    "אפרסק",
    "אפרתי אליהו",
    "אקסודוס",
    "ארבע האמהות",
    "אריק איינשטיין",
    "ארלוזורוב",
    "ארן זלמן",
    "אשדוד",
    "אשכולית",
    "בארי",
    "בבלי",
    "בגין עליזה",
    "בובר מרטין",
    "בוסקילה יצחק",
    "בורוכוב",
    "בורחס חורחה",
    "בורלא יהודה",
    "בז",
    "בטהובן",
    "ביאליק",
    'ביל"ו',
    "בית קנדה",
    "בלפור",
    "בן אליעזר אריה",
    "בן ברוך אורי",
    "בן דיוואן עמרם",
    "בן הכט",
    "בן יוסף שלמה",
    "בן עמי עובד",
    "בעלי מלאכה",
    "בקעת הירח",
    "ברהמס",
    "ברוריה",
    "ברנר",
    "ברק בן אבינועם",
    "ברקת",
    "ג'ו עמר",
    "גאפונוב",
    "גביש",
    "גבעת יונה",
    "גבעת עדה",
    "גדרה",
    "גוגול",
    "גולדברג לאה",
    "גור דב",
    "גור מרדכי",
    "גטה",
    "גיא אוני",
    "גלעד",
    "גלעדי ישראל",
    "גרונר דב",
    "גרינברג אורי צבי",
    "גשר עד הלום",
    "דבורה הנביאה",
    "דבורה עומר",
    "דגניה",
    "דה וינצ'י",
    "דוכיפת",
    "דולומיט",
    "דיין משה",
    "דקר",
    "דרור",
    "דרך אריאל שרון",
    "דרך ארץ",
    "דרך בגין מנחם",
    "דרך המכס",
    "דרך הרכבת",
    "דרך לסקוב",
    "דרך ניר גלים",
    "דרך תל מור",
    "ה' באייר",
    'האדמו"ר מבעלז',
    'האדמו"ר מגור',
    "האדמו\"ר מויז'ניץ",
    "האומן",
    "האוניה",
    "האופים",
    "האורגים",
    "האורן",
    "האיריס",
    "האלמוגים",
    'האצ"ל',
    'האר"י הקדוש',
    "הארז",
    "האשכול",
    "האשל",
    "האשלג",
    "האתרוג",
    "הבושם",
    "הבטיחות",
    "הבנאים",
    "הבנים",
    "הבעל שם טוב",
    "הבשור",
    "הגדוד העברי",
    "הגולשים",
    "הגוררת",
    "הגלים",
    "הגלימה",
    "הגפן",
    'הגר"א',
    "הדוברה",
    "הדולפין",
    "הדס",
    "הדקל",
    "ההגנה",
    "ההגשמה",
    "ההדרים",
    "ההסתדרות",
    "ההפלגה",
    "ההשכלה",
    "הוז דב",
    "הוסטל שלום",
    "הורדים",
    "הזית",
    "החבצלות",
    "החובלים",
    "החולה",
    "החותרים",
    'החיד"א',
    "החלוצים",
    "החניכים",
    "החרושת",
    "החרצית",
    "החשמל",
    "הטיילת",
    "היהלומים",
    "היוצר",
    "היזמה",
    "היידן",
    "הימאים",
    "היסמין",
    "היציקה",
    "הכלנית",
    "הכתר",
    "הלביאה",
    "הלל",
    "הלפרין ירמיהו",
    "המאבק",
    "המברק",
    "המגדלור",
    "המגיד",
    "המדע",
    "המחקר",
    "המליץ",
    "המלך אליקים",
    "המלך אמציה",
    "המלך אסא",
    "המלך דוד",
    "המלך הדד",
    "המלך חזקיה",
    "המלך יהוא",
    "המלך יהואחז",
    "המלך יהויכין",
    "המלך יהושפט",
    "המלך יובב",
    "המלך יותם",
    "המלך ירבעם",
    "המלך עזריה",
    "המלך עמרי",
    "המלך רחבעם",
    "המלך שאול",
    "המלך שלמה",
    "המלכים",
    "המסגר",
    "המסחר",
    "המעבורת",
    "המעגן",
    "המעפילים",
    "המפרץ",
    "המפרש",
    "המצפן",
    "המרי",
    "המשקיף",
    "המתכת",
    "המתמיד",
    "המתנדבים",
    "הנביא אליהו",
    "הנביא אלישע",
    "הנביא גד",
    "הנביא דניאל",
    "הנביא הושע",
    "הנביא זכריה",
    "הנביא חבקוק",
    "הנביא חגי",
    "הנביא יואל",
    "הנביא יחזקאל",
    "הנביא ירמיהו",
    "הנביא ישעיהו",
    "הנביא מיכה",
    "הנביא מלאכי",
    "הנביא נחום",
    "הנביא נחמיה",
    "הנביא נתן",
    "הנביא עובדיה",
    "הנביא עמוס",
    "הנביא צפניה",
    "הנביא שמואל",
    "הנביאים",
    "הנגר",
    "הנורית",
    "הנחושת",
    "הנחשול",
    "הנפט",
    "הנרקיס",
    "הנשיא ויצמן",
    "הסביון",
    "הסחלב",
    "הסייפן",
    "הסיפון",
    "הספנות",
    "העבודה",
    "העוגן",
    "העופרים",
    "העופרת",
    "העליה",
    "העמל",
    "העצמאות",
    "העשרים ואחד",
    "הפלדה",
    'הפלמ"ח',
    "הפריון",
    "הצבי",
    "הצבעוני",
    "הצוללים",
    "הצורפים",
    "הציונות",
    "הצפירה",
    "הקדמה",
    "הקיטור",
    "הקליטה",
    "הקריה-מגורים",
    'הקריה-מע"ר',
    "הר אביטל",
    "הר גלבוע",
    "הר הצופים",
    "הר כנען",
    "הר כרמל",
    "הר מירון",
    "הר נבו",
    "הר ציון",
    "הר תבור",
    'הרא"ש',
    "הראם",
    "הראשונים",
    "הרב אבוחצירה",
    "הרב אבן דנן",
    "הרב אברמסקי",
    "הרב בוזגלו דוד",
    "הרב בלולו",
    "הרב הגר",
    "הרב הרצוג",
    "הרב חיים שושנה",
    "הרב טייב",
    "הרב מימון",
    "הרב משאש יוסף",
    "הרב ניסים",
    "הרב קוק",
    "הרב קלישר",
    "הרב רוזובסקי",
    "הרב ריינס",
    "הרב שאולי",
    "הרותם",
    "הרי גולן",
    'הרי"ם לוין',
    'הרי"ף',
    "הרימון",
    'הרמ"א',
    "הרפואה",
    "הרציף",
    "הרקפת",
    "השיטה",
    "השייטים",
    "השקמה",
    "התאנה",
    "התורן",
    "התלמוד",
    "התעסוקה",
    "התקוה",
    "התקומה",
    "התקשורת",
    "ואלוס",
    "ואן גוך",
    "וולפסון דוד",
    "ויקטור הוגו",
    "ויתקין יוסף",
    "ורדימון יצחק",
    "ז'בוטינסקי",
    "זולה אמיל",
    "זייד אלכסנדר",
    "זינגר בשביס",
    "זכרון יעקב",
    "זלמן דוד לבונטין",
    "זמיר",
    "זנגביל",
    "חדרה",
    "חוחית",
    "חולדה הנביאה",
    "חוף באר שבע",
    "חוף הקשתות",
    "חוף לידו",
    "חוף מי עמי",
    "חורב",
    "חורי חיים",
    "חזנוב עמרם",
    "חטיבת גבעתי",
    "חטיבת גולני",
    "חטיבת הנגב",
    "חטיבת יפתח",
    "חטיבת כרמלי",
    "חיבת ציון",
    "חלוצי התעשיה",
    "חנינא בן דוסא",
    "חנקין יהושע",
    "חסידי אומות העולם",
    "חסן ובן גרה",
    "חפץ חיים",
    "חרמון",
    "חשון",
    "טבת",
    "טולסטוי",
    "טופ סנטר",
    "טרומפלדור",
    "טשרניחובסקי",
    "יאיר",
    "יבנאל",
    "יד העפלה ממרוקו",
    "יהודה הלוי",
    "יהודה המכבי",
    "יהושע בן נון",
    "יואב בן צרויה",
    "יואל משה סלומון",
    "יוהנה זבוטינסקי",
    "יוחנן בן זכאי",
    "יום טוב צמח",
    "יונה הנביא",
    "יוסי בנאי",
    "יוסי בר חלפתא",
    "יוספטל",
    "יורדי הסירה",
    "ילין דוד",
    "יסוד המעלה",
    "יעל",
    "יפה ירקוני",
    "יצחק הנשיא",
    "יצחק שדה",
    "יצחק שמיר",
    "יקינטון",
    "ירמוך",
    "ירקון",
    "ירקן",
    "ישפה",
    'כ"ט בנובמבר',
    "כהן אלי ככר",
    "כוכב הדרום",
    "כוכב הים",
    "כוכב הצפון",
    "כוכב השחר",
    "כיכר הדייגים",
    "כינור",
    "ככר 12 השבטים",
    "ככר אלתא",
    "ככר הבונים החופשיים",
    "ככר המדינה",
    "ככר העונות",
    "ככר סיגל",
    "ככר ציון",
    "ככר קהילת סלוניקי",
    "ככר שרת משה",
    "כנרת",
    "כסלו",
    "כפתור החולות",
    "לוחמי האש",
    "לוי שלמה",
    "לויתן",
    "לילי",
    "למרחב",
    "לשם",
    "מאיר בעל הנס",
    "מבוא האמוראים",
    "מבוא הגומא",
    "מבוא הדגנית",
    "מבוא הדודאים",
    "מבוא הדמומית",
    "מבוא החמציץ",
    "מבוא הכרכום",
    "מבוא הספנים",
    "מבוא העירית",
    "מבוא הקריה",
    "מבוא הרב אלקלעי",
    "מבוא השרביטן",
    "מבוא התנאים",
    "מבוא מבצע חורב",
    "מבוא מבצע נחשון",
    "מבצע ברק",
    "מדרחוב הנביאים",
    "מורדי הגטאות",
    "מוריה",
    "מזכרת בתיה",
    "מטולה",
    "מיכשוילי",
    "מילמן",
    "מיסטרל",
    "מכאל אנג'לו",
    "מכולות",
    "מלכת שבא",
    "מנגו",
    "מעגל השמחה",
    "מפקורה",
    "מצדה",
    "מקוה ישראל",
    "מקלנבורג",
    "מרזוק ושמואל",
    "מרים הנביאה",
    "מרכז קליטה",
    "מרכז קליטה שלום",
    "משה לוי(מוישה וחצי)",
    "משמר הירדן",
    "משמש",
    "משנה",
    "משעול האירוסים",
    "מתחם אלתא",
    "נאות ספיר",
    "נגרו אהרן",
    "נהלל",
    "נוה דניה",
    "נוה יהונתן",
    "נוה ים",
    "נופך",
    "נורדאו",
    "נחל דן",
    "נחל הליטני",
    "נחל לכיש",
    "נחל ערוגות",
    "נחל שורק",
    "נחל שניר",
    "נחליאלי",
    "נחמן מברסלב",
    "ניסן",
    "נמל אשדוד",
    "נמלי ישראל",
    "נס ציונה",
    "נעמי",
    "נעמן",
    "נצח ישראל",
    "סאלק יונה",
    "סולד הנריטה",
    "סופר אברהם",
    "סטרומה",
    "סיון",
    "סיטרין",
    "סילבר אבא הלל",
    "סיני",
    "סירקין נחמן",
    "סלבדור",
    "סמ אל הים",
    "סמ יהואש",
    "סמטה א",
    "סמטה ב",
    "סמי אלמגריבי",
    "סנפיר",
    "סנש חנה",
    "ספיח",
    "ספיר",
    "עגנון שי",
    "עובדי הנמל",
    "עוזי חיטמן",
    "עופר אברהם",
    "עזרא הסופר",
    "עינבר",
    "ענבים",
    "עקביא בן מהללאל",
    "פאנצו",
    "פארן",
    "פארק השיטה המלבינה",
    "פורצי הדרך",
    "פטדה",
    "פיינברג יוסף",
    "פינטו חיים",
    "פן אלכסנדר",
    "פנינה",
    "פסטר לואי",
    "פרנק אנה",
    "פתח תקווה",
    "צאלים",
    "צדקת יוסף",
    'צה"ל',
    "צור הדסה",
    "ציזלינג",
    "צפת",
    "קארו יוסף",
    "קבוץ גלויות",
    "קדושי בלזן",
    "קדרון",
    "קדש",
    "קורל",
    "קורצ'ק יאנוש",
    "קישון",
    "קמי אלברט",
    'קק"ל',
    "קרית חרדים",
    "קרית פוניבש",
    "קרן היסוד",
    "ראש פינה",
    "רב אשי",
    "רב חיסדא",
    "רב ינאי",
    "רבא",
    "רבי דוד אלקיים",
    "רבי דוד בן חסין",
    "רבי טרפון",
    "רבי יהודה הנשיא",
    "רבי עקיבא",
    "רבין יצחק",
    "רבינא",
    "רבנו גרשום",
    "רבנו וידל הצרפתי",
    "רבנו תם",
    "רובינא חנה",
    "רובע א",
    "רובע ב",
    "רובע ג",
    "רובע ה",
    "רובע ו",
    "רובע ז",
    "רובע ח",
    "רובע ט",
    'רובע ט"ו',
    'רובע ט"ז',
    "רובע י",
    'רובע י"ב',
    'רובע י"ד',
    "רובע יג",
    "רובע יז",
    "רוגוזין",
    "רוסולד",
    "רופין ארתור",
    "רות המואביה",
    "רזיאל דוד",
    "רחל אמנו",
    "רחל ינאית",
    'רמב"ם',
    "רמברנדט",
    "רנטגן",
    "רפאל איתן (רפול)",
    'רשב"י',
    "ש אגש",
    "ש אזור ד",
    "ש אשטרום",
    "ש חב חשמל",
    "ש חסכון",
    "ש רסקו",
    "שאגל מארק",
    "שבזי",
    "שבט",
    "שבט אשר",
    "שבט בנימין",
    "שבט גד",
    "שבט דן",
    "שבט זבולון",
    "שבט יהודה",
    "שבט יוסף",
    "שבט יששכר",
    "שבט לוי",
    "שבט נפתלי",
    "שבט ראובן",
    "שבט שמעון",
    "שבי ציון",
    "שביל יא",
    "שגב",
    "שד אלטלנה",
    "שד בן גוריון",
    "שד בני ברית",
    "שד גורדון",
    "שד המיסדים",
    "שד הפרחים",
    "שד הרצל",
    "שד יפה נוף",
    "שד ירושלים",
    "שד מונטיפיורי",
    "שד סנה משה",
    "שד עוזיהו",
    "שד רוטשילד",
    "שד תל חי",
    "שדרות הים התיכון",
    "שדרות נתיבי הים",
    "שדרות ראשון לציון",
    "שדרות רחובות",
    'שדרות רש"י',
    "שהם",
    "שובר הגלים",
    "שוחט מניה",
    "שומרון",
    "שועלי שמשון",
    "שושנה דמארי",
    "שושנת הרוחות",
    "שחם יואב",
    "שילר",
    "שיר השירים",
    "שכ ונטורה",
    "שלדג",
    "שלומציון המלכה",
    "שלונסקי אברהם",
    "שלזינגר ארווין",
    "שמאי",
    "שמואל הנגיד",
    "שמעון בן שטח",
    "שמעיה",
    "שמר",
    "שער האריות",
    "שערי יושר",
    "שפיה",
    "שפירא אברהם",
    "שפירא משה חיים",
    "שרה אמנו",
    "תחנת הכח",
    "תמוז",
    "תנין",
    "תפוז",
    "תפוח",
    "תרשיש",
    'תש"ח',
    "תשרי",
  ],
  "אשדות יעקב  (איחוד)": ["אשדות יעקב  (איחוד)"],
  "אשדות יעקב  (מאוחד)": ["אשדות יעקב  (מאוחד)"],
  אשחר: ["אשחר"],
  אשכולות: ["אשכולות", "סנסנה"],
  "אשל הנשיא": ["אשל הנשיא"],
  אשלים: ["אשלים"],
  אשקלון: [
    "אב",
    "אבוקדו",
    "אביבית",
    "אבימלך",
    "אבישג",
    "אבן עזרא",
    "אבני החושן",
    "אבני חן",
    "אבנר בן נר",
    "אבצן",
    "אברבנאל",
    "אברהם ברזילי",
    "אבשלום",
    "אגם דלתון",
    "אגם זוהר",
    "אגמית",
    "אגס",
    "אדלר חנה",
    "אדם יקותיאל",
    "אדמונד דה רוטשילד",
    "אדר",
    "אהבת שלום",
    "אהוד",
    "אהרונסון שרה",
    "אודם",
    "אוסישקין",
    "אופירה",
    "אור החיים",
    "אורה ושמחה",
    "אורט",
    "אז תעשיה גבעת ציון",
    "אזור הסיטי",
    "אזור תעשייה דרום",
    "אזור תעשייה צפון",
    "אחד העם",
    "אחווה",
    "אחוזת בית",
    'אחימאיר אב"א',
    "אחישר אריה",
    "אחלמה",
    "איגר עקיבא",
    "איילה",
    "אייר",
    "אילים",
    "אילנה שפיר",
    "אילת",
    "אירוס",
    "איתן רפאל",
    "אלבז",
    "אלול",
    "אלון יעקב",
    "אלי כהן",
    "אלי סיני",
    "אליאב אורי",
    "אליהו גניש",
    "אליהו הנביא",
    "אלישע",
    "אלכסנדרוני",
    "אלמגריבי סמי",
    "אלמוג",
    "אלעד",
    "אלפסיה זוהרה",
    "אלקלעי יהודה",
    "אלקנה",
    "אמנון",
    "אנה פרנק",
    "אנחל סאנס בריס",
    "אנייה",
    "אנילביץ מרדכי",
    "אננס",
    "אנפה",
    "אסף הרופא",
    "אסתר המלכה",
    "אפל דוד וצילה",
    "אפרים",
    "אפרסמון",
    "אפרסק",
    "אקסודוס",
    "ארד",
    "אריאל",
    "אריאל שרון",
    "אריה",
    "אשדוד",
    "אשכולי פז",
    "אשכולית",
    "אשקלון",
    "אשר",
    "אשתאול",
    "אתם",
    "בדולח",
    "בהט",
    "בוטראשווילי אהרון",
    "בועז",
    "בוקרה רחמים",
    "בורוכוב",
    "ביאליק",
    "ביכורי ענבים",
    'ביל"ו',
    "בית אבות",
    "בית אל",
    "בית המשפט",
    "בית השואבה",
    "בית זולוטוב חדש",
    "בית זולוטוב ישן",
    'בית"ר',
    "בלסבלג אברהם",
    "בלפור",
    "בן אליעזר אריה",
    "בן דוד",
    "בן זומא",
    "בן יהודה אליעזר",
    "בן עוזאי",
    "בן עטר",
    "בן עמר",
    "בן צבי יצחק",
    "בן שטח",
    "בני זמירו",
    "בני עקיבא",
    "בנימין",
    "בעל שם טוב",
    "בקעת סיירים",
    "בקעת רימון",
    "בר יוחאי",
    "בר כוכבא",
    "ברבור",
    "ברגסון",
    "ברגר דוד",
    "ברוריה",
    "ברטנורא",
    "ברייר דב",
    "בריכת המשושים",
    "בריכת רם",
    "ברנשטיין פרץ",
    "ברק",
    "ברקת",
    "בת גלים",
    "בת חן",
    "בתי ברמן",
    "בתי לוינסון",
    "בתי רסקו",
    "ג'ו וסטנלי כהן",
    "גבע",
    "גבעון",
    "גבעת המים",
    "גבעת הפרחים",
    "גבעת ציון",
    "גבעתי",
    "גד",
    "גדוד הפורצים",
    "גדעון",
    "גדעון בן יואש",
    "גובר רבקה",
    "גולני",
    "גורדון",
    "גורן יונה",
    "גוש 1250",
    "גוש 1252",
    "גוש 1243",
    "גוש 1253",
    "גוש 1234",
    "גוש 1244",
    "גוש 1254",
    "גוש 1246",
    "גוש 1257",
    "גוש 1218",
    "גוש 1228",
    "גוש 1248",
    "גוש עציון",
    "גושן",
    "גילה",
    "גינוסר",
    "גלמן פולינה",
    "גלעד",
    "גמלא",
    "גן הורדים",
    "גני ברנע",
    "גרופית",
    "גרופר יעקב",
    "גרשון",
    "גשר החיים",
    'ד"ר בוטוויניק מיכאל',
    "דבורה הנביאה",
    "דובב",
    "דוגית",
    "דולב",
    "דולפין",
    "דורות משה",
    "דותן",
    "דיבולט",
    "דיזראלי",
    "דיין משה",
    "דיצה",
    "דליות",
    "דמארי שושנה",
    "דן",
    "דניאל",
    "דפנה",
    "דרך היין",
    'דרכי אי"ש',
    "דרכי נועם",
    "האבץ",
    "האגוז",
    "האדווה",
    "האומן",
    "האומץ",
    "האופה",
    "האופן",
    "האוקיינוס",
    "האורג",
    "האורים",
    "האילן",
    "האלון",
    "האמהות",
    "האפיק",
    'האר"י',
    "הארז",
    "האשד",
    "האשוח",
    "האשל",
    "האתרוג",
    "הבדיל",
    "הבוסתן",
    "הבנאי",
    "הבנים",
    "הבציר",
    "הברוש",
    "הברזל",
    "הבשן",
    "הגאות",
    "הגבורה",
    "הגביש",
    "הגדוד העברי",
    'הגדנ"ע',
    "הגולן",
    "הגולשים",
    "הגלגל",
    "הגליל",
    "הגלשן",
    "הגפן",
    'הגר"א',
    "הדגל",
    "הדובדבן",
    "הדייגים",
    "הדישון",
    "הדקל",
    "הדרור",
    "ההגנה",
    "ההדרים",
    "ההסתדרות",
    "ההרדוף",
    "הוורדים",
    "הולצברג שמחה",
    "הושע",
    "הזגג",
    "הזית",
    "הזכוכית",
    "הזמיר",
    "הזריחה",
    "החבצלת",
    "החובלים",
    "החולה",
    "החיטה",
    "החלוץ",
    "החרוב",
    "החרט",
    "החרש",
    "החשמונאים",
    "החשמל",
    "הטוחן",
    "הטייסים",
    "היובל",
    "הים התיכון",
    "היצול",
    "היקב",
    "הירדן",
    "הירמוך",
    "הירקון",
    "הכדים",
    "הכישור",
    "הכלנית",
    "הכרם",
    "הלוז",
    "הלוח העברי",
    "הלוחם",
    "הלולב",
    "הלילך",
    "הלל",
    "המופת",
    "המורה מאיר",
    "המחוגה",
    "המחרשה",
    "המלגזה",
    "המלחים",
    "המסגר",
    "המסלול",
    "המעיין",
    "המפל",
    "המציל היהודי",
    "המרכבה",
    "המשנה",
    "המתכנן",
    "המתמיד",
    "הנביאים",
    "הנביעות",
    "הנגב",
    "הנגר",
    "הנוטרים",
    "הנוער העובד",
    "הנחשול",
    "הנמל",
    "הנפח",
    "הנרקיס",
    "הנשיא",
    "הסוחר",
    "הסוכה",
    "הסוכנות היהודית",
    "הסופרים היהודים",
    "הסנה",
    "הסנונית",
    "הספנים",
    "הסרגל",
    "העבודה",
    "העגלה",
    "העוגן",
    "העונות",
    "העופרת",
    "העליה",
    "העמל",
    "הענב",
    "העצמאות",
    "הערמון",
    "הפלג",
    "הפלדה",
    "הפלטינה",
    'הפלמ"ח',
    "הפנינים",
    "הפרג",
    "הפרדס",
    "הצאלה",
    "הצבר",
    "הצדפים",
    "הצופים",
    "הצוק",
    "הצורף",
    "הצנחנים",
    "הצפצפה",
    "הקדר",
    "הקוממיות",
    "הקונכייה",
    "הקיקיון",
    "הר ארבל",
    "הר חצור",
    "הר חרמון",
    "הר כנען",
    "הר מירון",
    "הר תבור",
    "הראל",
    "הרב אונטרמן",
    "הרב בוזגלו דוד",
    "הרב בן דיוואן עמרם",
    "הרב בן חמו יעקב",
    "הרב גניש חואטו",
    "הרב דוד קדוש",
    "הרב הרצוג",
    "הרב חיים שושנה",
    "הרב טולדנו",
    "הרב יצחק ברזני",
    "הרב כהנא חיים",
    "הרב כלפון משה הכהן",
    "הרב לוין אריה",
    "הרב מימון",
    "הרב מנצור בן שמעון",
    "הרב משאש שלום",
    "הרב משה צבי נריה",
    "הרב נבון גד",
    "הרב נורוק",
    "הרב נסים יצחק",
    "הרב סבג ברוך",
    "הרב ספו",
    "הרב עוזיאל",
    "הרב ערוסי יחיא",
    "הרב פינטו",
    "הרב פרג'ון",
    "הרב קארו יוסף",
    "הרב קוק",
    "הרב רקח יעקב",
    "הרב שלמה גורן",
    "הרבנית ברכה קפאח",
    'הרי"ף',
    "הרימון",
    "הרכבת",
    "הרפד",
    "הרצל",
    "הרקפת",
    "הרתך",
    "השבטים",
    "השוטר",
    "השומר הצעיר",
    "השומרון",
    "השונית",
    "השופטים",
    "השושנים",
    "השחיינים",
    "השיטה",
    "השייטת",
    "השעורה",
    "השפלה",
    "השקד",
    "השקיעה",
    "השקמה",
    "השרון",
    "השריונאים",
    "התור",
    "התותחנים",
    "התחיה",
    "התלמוד",
    "התמר",
    "התפוז",
    "התפוח",
    "התקווה",
    "ובר משה",
    "וייס יעקב",
    "ויצמן חיים",
    "וקסנר בלה",
    "ורון יורם",
    "ז'בוטינסקי",
    "זאבי רחבעם",
    "זבולון",
    "זהבון",
    "זונאבנד יחזקאל",
    "זכרון יעקב",
    "זכריה",
    "זמורה",
    "זרובבל",
    "זרעית",
    'ח"ן',
    'חב"ד',
    "חביב אבשלום",
    "חבקוק",
    "חגי",
    "חדווה",
    "חובבי ציון",
    "חוגלה",
    "חודשי השנה",
    "חוחית",
    "חולדה הנביאה",
    "חוני המעגל",
    "חופית",
    "חוצות היוצר",
    "חות רסקו",
    "חזון איש",
    "חזקיהו",
    "חטיבת עציוני",
    'חי"ל',
    'חיד"א',
    "חייבי אהרון",
    "חיל הים",
    "חכימי יצחק",
    "חכם שמעון",
    "חכמי איטליה",
    "חלמונית",
    "חנה ושבעת בניה",
    "חניתה",
    "חננאל",
    "חנניה",
    "חסידה",
    "חפני",
    "חצב",
    "חצבה",
    "חרצית",
    "חשמונה",
    'חת"ם סופר',
    "חתן סופר",
    "טבועי אגוז",
    "טבועי סטרומה",
    "טבלן",
    "טבריה",
    "טבת",
    "טדי קולק",
    "טופז",
    "טורקיז",
    "טיילת שמשון ודלילה",
    "טיכו אנה",
    "טירן",
    "טרומפלדור",
    "טריווש יוסף אליהו",
    "טשרניחובסקי",
    'י"ח משפחות',
    'י"ל פרץ',
    "יבנה",
    "ידידות",
    "יהדות אמריקה הלטנ",
    "יהדות בריטניה",
    "יהדות ספרד",
    "יהואש",
    "יהודה הלוי",
    "יהודה עמיחי",
    "יהודית",
    "יהושע",
    "יהושפט",
    "יהלום",
    "יואב",
    "יואל",
    "יודפת",
    "יוהנסבורג",
    "יוטבתה",
    "יוכבד",
    "יוליש משה",
    "יונה",
    "יוספטל",
    "יורדי הים",
    "יותם",
    "יחזקאל לוין",
    "יחיעם",
    "יכין",
    "ילין",
    "ילן-שטקליס מרים",
    "ילתא",
    "ים המלח",
    "ים סוף",
    "ימית",
    "ינאי המלך",
    "ינוקא",
    "יסודי עזרא",
    "יסמין",
    "יסעור",
    "יעל",
    "יעקב עמיצור",
    "יפה נוף",
    "יפתח",
    "יפתח הגלעדי",
    "יצחק שמיר",
    "יריחו",
    "ירמיהו",
    "ישא ברכה",
    "ישי",
    "ישעיהו",
    "ישעיהו ארנפלד",
    "ישפה",
    "יששכר",
    'כ"ט בנובמבר',
    "כהן שלמה",
    "כוכב הצפון",
    "כוכב ים",
    "כורש",
    "כיכר אמונה",
    "כיכר אמסלם שלום",
    "כיכר אריס חיים",
    "כיכר ברק",
    "כיכר גולומב",
    "כיכר גוש קטיף",
    "כיכר גרוס מרדכי",
    "כיכר דוד המלך",
    "כיכר דקטור מקס",
    "כיכר המשטרה",
    "כיכר הנרקיס",
    "כיכר העצמאות",
    "כיכר הרבי מלובביץ",
    "כיכר הרופא",
    'כיכר ויצ"ו',
    "כיכר זנזורי שמעון",
    "כיכר חזקיהו",
    "כיכר חיימסון",
    "כיכר לוי",
    'כיכר לח"י',
    "כיכר מיכואלס שלמה",
    "כיכר מנגר",
    "כיכר מרגלית משה",
    "כיכר ניו יורק",
    'כיכר נעמ"ת',
    "כיכר סטרייג' הנרי",
    "כיכר סמואל",
    "כיכר עתניאל",
    "כיכר רוטרי",
    "כיכר שאול המלך",
    "כיכר שושן",
    "כיכר שושנה יוסף",
    "כיכר שלמה המלך",
    "כיכר שרמן ארצ'י",
    "ככר פעילי גבעת ציון",
    "כל ישראל חברים",
    "כליל החורש",
    "כנפי נשרים",
    "כנרת",
    "כסלו",
    "כפר גולף",
    "כפר דרום",
    "כפר הנופש הצרפתי",
    "כפר הנופש לחיילים",
    "כפרי הנופש",
    "כצנלסון",
    "כרכום",
    "כרמי ציון",
    "כרמל",
    "כתב סופר",
    "לביאה",
    "לבנה",
    "לוחמי הגטאות",
    "לוטוס",
    "לויתן",
    "לוע הארי",
    "ליבוביץ נחמה",
    "לייפר- מילר רחל",
    "לכיש",
    "לנדאו לב",
    "לשם",
    "מאדים",
    "מבצע אסף",
    "מבצע חורב",
    "מבצע חירם",
    "מבצע משה",
    "מגדל",
    "מגדלור",
    "מגידו",
    "מדבר סין",
    "מודיעין",
    "מוסרות",
    "מועדים לשמחה",
    "מור",
    "מור משולם",
    "מורג",
    'מח"ל',
    "מחניים",
    "מיטיב בני",
    "מיכה",
    "מילרוד אליעזר",
    "מישאל",
    "מיתר",
    "מכמורת",
    "מלאכי",
    "מלון החוף",
    "מלכי ישראל",
    "מלכיט",
    "מנגו",
    "מנדלי מוכר ספרים",
    "מנשה",
    "מסעי בני ישראל",
    "מעגן",
    "מעון אקדמאים",
    "מעון קשישים",
    "מעלה הגת",
    "מעלות אשר",
    "מפל התנור",
    "מפרץ",
    "מפרשית",
    "מצדה",
    "מצפה ברנע",
    "מקהלות",
    "מקור הברכה",
    "מרגלית",
    "מרדכי ברששת",
    "מרחשוון",
    "מרידור יעקב",
    "מרים",
    "מרים בן פורת",
    "מרינה",
    "מרכוס",
    "מרכז נפתי",
    "מרכז קליטה בית קנדה",
    "מרכז קליטה כלנית",
    "מרכז רפואי ברזילי",
    "משה לנדוי",
    "משכית",
    "מתקה",
    "נאות אשקלון",
    "נאות ברנע",
    "נאות גנים",
    "נבון אופירה",
    "נהרדעא",
    "נוגה",
    "נוה אילן",
    "נוה הדקלים",
    "נוה ההדרים",
    "נווה אלונים",
    "נווה שלום",
    "נועה",
    "נורדאו",
    "נורית",
    'נח"ל',
    "נחושתן",
    "נחל דרגות",
    "נחל חלילים",
    "נחל לוטם",
    "נחל מורן",
    "נחל נטפים",
    "נחל נעמן",
    "נחל נקרות",
    "נחל נריה",
    "נחל עמוד",
    "נחל פרת",
    "נחל צאלים",
    "נחל צלמון",
    "נחל קשת",
    "נחל שני",
    "נחל שפירים",
    "נחל תנינים",
    "נחליאלי",
    "נחמיה",
    "נחשון",
    "נטעים",
    'ניל"י',
    "ניסן",
    "ניסנית",
    "ניצנה",
    "ניצנים",
    "נמר",
    "נעמי",
    "נפתלי",
    "נתן הנביא",
    "נתניהו יונתן",
    "סאלד הנרייטה",
    "סביון",
    "סגל צבי",
    "סחלב",
    "סטרייג' הנרי",
    "סיגל",
    "סיון",
    "סייפן",
    "סיני",
    "סירה",
    "סלע חיים",
    "סלעית",
    "סמ חמד",
    "סמ כרמלי",
    "סמ מילכה",
    "סמ שמגר",
    "סמ תיסלית",
    "סמ' איילון",
    "סמדר",
    "סמולנסקין",
    "סנפיר",
    "סנש חנה",
    "סעדון אילן",
    "סעדיה גאון",
    "ספינה",
    "ספיר",
    'עגנון ש"י',
    "עדולם",
    "עובדיה",
    "עודד",
    "עוזר אריה",
    "עולי הגרדום",
    "עזרא",
    "עזריה",
    "עין הים",
    "עין כפיר",
    "עין נטפים",
    "עין עבדת",
    "עין עברונה",
    "עין שוקק",
    "עינות צוקים",
    "עירית",
    "עלי",
    "עלית הנוער",
    "עמוס",
    "עמיעד",
    "עמיעוז",
    "עמישב",
    "עמנואל",
    "עמנואל מורנו",
    "עמר ג'ו",
    "ענבר",
    "עפרוני",
    "עצי פרי",
    "ערבות מואב",
    "עשרת השבטים",
    "עתניאל",
    "פארק אשקלון",
    "פטדה",
    "פינחס",
    "פינס יוסף",
    "פלוגות",
    "פנינת ים",
    "פסטרנק בוריס",
    "פעמונית",
    "פקאן",
    "פקיעין",
    "פרוספר אלמקיאס",
    "פרידן יונתן",
    "צבעוני",
    "צדוק דורון",
    "צדק",
    'צה"ל',
    "צהלה",
    "צוללת",
    "צופית",
    "צור אפרים",
    "צוריאל",
    "צין",
    "ציפורי",
    "ציפורן",
    "צלופחד",
    "צלמונה",
    "צלף",
    "קאפח אהרון",
    "קדומים",
    "קדושי קהיר",
    "קול משה",
    "קופת חולים",
    "קורצ'אק",
    "קיבוץ גלויות",
    "קיסריה",
    "קפסטט",
    "קצרין",
    'קק"ל',
    "קרייתי",
    "קרית גולדה מאיר",
    "קרית חינוך דרום",
    "קרית חינוך צפון",
    "קרן היסוד",
    "ראובן",
    "ראשון לציון",
    "רבי מאיר",
    "רבי עקיבא",
    "רבי רפאל אנקווה",
    "רבקה",
    "רובינא חנה",
    "רות",
    "רותם",
    "רזיאל דוד",
    "רחבת הרב אבהר שלום",
    "רחבת ווסק",
    "רחל ינאית בן צבי",
    "רינה",
    'רמב"ם',
    "רמז דוד",
    "רמת בן גוריון",
    "רמת חן",
    "רמת כרמים",
    "רנבסקיה פאינה",
    "רעות",
    "רפידים",
    "רפסודה",
    "רצועת החוף והמלונות",
    'רש"י',
    "ש אגש שמשון",
    "ש בנה ביתך",
    "ש ברנע",
    "ש דרומי",
    "ש הצכים",
    "ש וילות",
    "ש זוגות צעירים",
    "ש חסכון א",
    "ש חסכון ב",
    "ש יובל גד",
    "ש מגדל המים",
    "ש מפונים",
    "ש נוה רז",
    "ש צפון ב",
    "ש קרוונים",
    "ש רמז",
    "ש רמת אשכול",
    "ש שוטרים",
    "ש שיקמים",
    "ש שמשון",
    "ש.ב.ישעיה",
    "שאר ישוב",
    "שבו",
    "שבזי",
    "שבט",
    "שבט סופר",
    "שביט",
    'שביל ד"ר פיינגולד ג',
    "שביל דינור ברוך",
    "שביל החייט",
    "שביל הלימון",
    "שביל הסנדלר",
    "שביל העוז",
    "שביל חכם יעקב",
    "שביל נווה חוף",
    "שביל שבע",
    "שבתאי",
    'שד אצ"ל',
    "שד בן גוריון",
    "שד בר-לב חיים",
    "שד דרום אפריקה",
    "שד התעשיה",
    "שד ירושלים",
    "שד מונטיפיורי",
    "שד עמק האלה",
    "שד עמק חפר",
    "שד עמק יזרעאל",
    "שד צפניה",
    "שד קדש",
    "שד שפירא",
    "שד' בגין מנחם",
    "שד' הציונות",
    "שד' עופר",
    "שד' רבין יצחק",
    "שוהם",
    "שורק",
    "שורשים",
    "שזיף",
    "שחף",
    "שטיפטל מרק",
    "שטרן אברהם",
    "שיבת ציון",
    "שילה",
    "שכ אפרידר",
    "שכ אשכולי פז",
    "שכ ברנע ב'",
    "שכ ברנע ג'",
    "שכ האגמים",
    "שכ הנשיא הרצוג",
    "שכ הצפון א",
    "שכ ז'בוטינסקי",
    "שכ נוה חוף",
    "שכ נוה ים",
    "שכ קדש ברנע",
    "שכ שמשון ה",
    "שכון ופתוח",
    "שלווה",
    "שלום עליכם",
    "שלומציון",
    "שמאי",
    "שמואל",
    "שמחת עולם",
    "שמר נעמי",
    "שמש יוסף",
    "שני אליהו",
    "שניר",
    "שסק",
    "שפינוזה",
    "שפירא יוסף",
    "שקנאי",
    "שרה",
    "שריג",
    "שרמן ארצ'י",
    "שרת משה",
    "שתילים",
    "תאנה",
    "תגר אריה",
    "תגר עליזה",
    "תורמוס",
    "תות",
    "תירוש",
    "תל חי",
    "תלתן",
    "תמוז",
    "תמנע",
    "תנובת השדה",
    "תענך",
    "תרצה",
    "תרשיש",
    "תשרי",
  ],
  אשרת: ["אשרת"],
  אשתאול: ["אשתאול"],
  אתגר: ["אתגר", "ש קרוונים"],
  "באקה אל-גרביה": [
    "אבו אלהאוה",
    "אבו קביר",
    "אזור התעשייה",
    "אל קביליה",
    "אלבסה",
    "אלברייה",
    "אלג'באל",
    "אלדרדס",
    "אלח'רבה",
    "אלמוארס",
    "אלנמלה",
    "אלשבאסיה ג'ת",
    "אלשוקפאן",
    "אלשמיה",
    "אם אלוואוויאת",
    "אם אלרקאקי",
    "באקה אל-גרביה",
    "המרכז (העיר הישנה)",
    "ואדי אלח'רובי",
    "ח'לת אלמרדאוי",
    "ח'לת זיתא",
    "למריבעה",
    "מרג'ת אלע'וזלאן",
    "סמ 20 1",
    "סמ 1 1",
    "סמ 1 2",
    "סמ 12 2",
    "סמ 15 1",
    "סמ 6 1",
    "סמ 19 1",
    "רח 10",
    "רח 20",
    "רח 30",
    "רח 1",
    "רח 11",
    "רח 21",
    "רח 31",
    "רח 2",
    "רח 12",
    "רח 22",
    "רח 32",
    "רח 3",
    "רח 13",
    "רח 23",
    "רח 4",
    "רח 14",
    "רח 24",
    "רח 5",
    "רח 15",
    "רח 25",
    "רח 6",
    "רח 16",
    "רח 26",
    "רח 7",
    "רח 17",
    "רח 27",
    "רח 8",
    "רח 18",
    "רח 28",
    "רח 9",
    "רח 19",
    "רח 29",
  ],
  "באר אורה": [
    "אורה",
    "באר אורה",
    "משעול אוכם",
    "משעול דוחן",
    "משעול דרדר",
    "משעול חכליל",
    "משעול כוכב",
    "משעול לענה",
    "משעול מכרות",
    "משעול נשף",
    "משעול סהרון",
    "משעול סיתוונית",
    "משעול עמרם",
    "משעול פיקוס",
    "משעול צלף",
    "משעול רחם",
    "משעול שגיא",
    "משעול שחורת",
    "משעול שנהבית",
    "סמ דקל דום",
    "סמ האשל",
    "סמ הר כרכום",
    "סמ הר צניפים",
    "סמ הר שני",
    "סמ השיטה",
    "סמ שיזף",
    "עמודי שלמה",
    "רותם המדבר",
    "תמנע",
  ],
  "באר גנים": [
    "אדווה",
    "איבי הנחל",
    "אלמוג",
    "אפיקי מים",
    "באר גנים",
    "גפן",
    "דרך האור",
    "דרך הגן",
    "דרך הים",
    "האסיף",
    "הבציר",
    "החריש",
    "הסביון",
    "זית",
    "חבצלת החוף",
    "טופז",
    "ישפה",
    "לשם",
    "מלכית",
    "נוף ים",
    "ספיר",
    "צדף",
    "רותם המדבר",
    "רימון",
    "שוהם",
    "שונית",
  ],
  "באר טוביה": ["באר טוביה", "שכ קרוונים"],
  "באר יעקב": [
    "אבא הלל",
    "אביב",
    "אגמיה",
    "אודם",
    "אזור תעשייה",
    "איילה",
    "אלות",
    "אליהו",
    "באר יעקב",
    'בי"ח אסף הרופא',
    'בי"ח מלבן',
    'בי"ח שמואל הרופא',
    "בן יוסף שלמה",
    "ברקת",
    "גבעת חוטר",
    "גולדברג לאה",
    "גני מנחם",
    "דוד הכהן",
    "דוכיפת",
    "דיזנגוף",
    "דן",
    "דרך חיים",
    "דרך סטון יוסף",
    'דרך צה"ל',
    "דרך רון ארד",
    "האופן",
    "האורן",
    "האיריס",
    "האלה",
    "האלון",
    "האנפה",
    "הארז",
    "האתרוג",
    "הבנים",
    "הברוש",
    "הבשור",
    "הגולן",
    "הגפן",
    "הדובדבן",
    "הדקל",
    "הדר",
    "הדרור",
    "הולצברג שמחה",
    "הורד",
    "הזית",
    "הזמיר",
    "החבצלת",
    "החצב",
    "החרמון",
    "היוזמה",
    "היוצר",
    "הירדן",
    "הירמוך",
    "הירקון",
    "הכלנית",
    "המושבה",
    "הנקר",
    "הנרקיס",
    "הסנונית",
    "העפרוני",
    "הערבה",
    "הפרדס",
    "הצמיחה",
    "הקישון",
    "הרב עוזיאל",
    "הרב קוק",
    "הרב שפירא",
    "השושנים",
    "השיטה",
    "השניים",
    "השקד",
    "התאנה",
    "התבור",
    "התמר",
    "התנופה",
    "התפוז",
    "ורדית",
    "ז'בוטינסקי",
    "חופית",
    "חושן",
    "חזון איש",
    "חרוד",
    "חתני פרס ישראל",
    "טופז",
    "יהודה",
    "יהלום",
    "יסמין",
    "יסעור",
    "ישיבת באר יעקב",
    "ישיבת שארית יוסף",
    "ישפה",
    "ככר חנה",
    "ככר קפלן",
    "ככר רוטרי",
    "כפר יוהנה זבוטינסקי",
    "ליבנית",
    "ליכטנשטיין אברהם",
    "לשם",
    "מאיר בעל הנס",
    "מנור אהוד",
    "נוה ספיר",
    "נווה דורון",
    "נופך",
    "נחום",
    "נחליאלי",
    "סביון",
    "סלעית",
    "סמ האנקור",
    "סמ האשל",
    "סמ הצאלון",
    "סמ הרימון",
    "סמ הרקפת",
    "סמ השיקמה",
    "סמ התפוח",
    "ספיר",
    "סרפנד אל עמר",
    "עודד",
    "ערוגות",
    'פארק תל"מ',
    "פלמינגו",
    "פשוש",
    "צופית",
    "צוקית",
    "צריפין",
    "קישון אפרים",
    "קרן היסוד",
    "ש אגש",
    "ש טוניס",
    "ש נוה עובד",
    "ש עובדי מלבן",
    "שא נס",
    "שבו",
    "שד האלונים",
    "שד רבין יצחק",
    "שד שמר נעמי",
    "שוהם",
    "שחף",
    "שכ הרצל",
    "שכ חתני פרס ישראל",
    "שכ רמבם",
    "שכ תלמי מנשה",
    "שלדג",
  ],
  "באר מילכה": ["באר מילכה"],
  "באר שבע": [
    "אבוחצירא",
    "אבטליון",
    "אביבית",
    "אביגד",
    "אביגדור המאירי",
    "אביגור שאול",
    "אביגיל",
    "אבידוב צבי",
    "אבידום מנחם",
    "אבידן שמעון",
    "אביה השופט",
    "אביטל",
    "אבישג",
    "אבישי",
    "אביתר הכהן",
    "אבן ארי מיכאל",
    "אבן גבירול",
    "אבן עזרא",
    "אבן שפרוט",
    "אבן-שושן אברהם",
    "אבנר",
    "אברבנאל",
    "אברהם אבינו",
    "אברמסון שרגא",
    "אבשלום",
    'אג"ן',
    "אגוז",
    "אגס",
    "אגרנט שמעון",
    "אדלה עאזר (כדן)",
    "אדלר",
    "אדמון ידידיה",
    "אהוד בן גרא",
    "אהרוני יוחנן",
    "אהרונסון",
    "אהרליך פאול",
    "אוהלי קידר",
    "אוכמנית",
    "אולגה שמיר",
    "אוליצקי אריה",
    "אולנדורף פרנץ",
    "אולשן יצחק",
    "אוניברס בן גוריון",
    "אוניברסיטת ב גוריון",
    "אוסטרובסקי גרשון",
    "אוסישקין",
    "אורבך אפרים",
    "אורות ישראל",
    "אורי צבי",
    "אוריה החיתי",
    "אורלנד יעקב",
    "אורן",
    "אזור התעשיה",
    "אחאב",
    "אחד העם",
    'אחימאיר אב"א',
    "אחינועם",
    "אטד",
    "אידה בוקסבוים",
    "אייזנשטדט",
    "איילת השחר",
    "אימבר",
    "אינשטיין",
    "אירוס הנגב",
    "אלבק חנוך",
    "אלדד",
    "אלה",
    "אלון יגאל",
    "אלוף אדם יקותיאל",
    "אלוף יריב אהרון",
    "אלוף מגן קלמן",
    "אלוף מנדלר אלברט",
    "אלחריזי",
    "אליאונור ברנהיים",
    "אליהו הנביא",
    "אלישבע",
    "אלישע",
    "אלישר",
    "אלכסנדר ינאי",
    "אלמוג",
    "אלעזר בן יאיר",
    "אלפסי",
    "אלקחי מרדכי",
    "אלקיים ניסים",
    "אלקלעי יהודה",
    "אלרואי דוד",
    "אלתרמן",
    "אמיר אברהם",
    "אמירה בלייברג",
    "אמנון",
    "אנילביץ",
    "אנפה",
    "אנקור",
    "אסא",
    "אסבסטונים",
    "אסירי ציון",
    "אסנת",
    "אסנת ברזאני",
    "אסף הרופא",
    "אסר טוביאס",
    "אסתר המלכה",
    "אפיקים בנגב",
    "אפק",
    "אפרים",
    "אפרסמון",
    "אפרת יעקב",
    "אפשטיין ישראל",
    'אצ"ג',
    'אצ"ל',
    "אקצין בנימין",
    "ארבע הגיבורות",
    "ארגוב אלכסנדר",
    "ארדון מרדכי",
    "אריסטובולוס",
    "ארלוזורוב",
    "ארליך שמחה",
    "ארלנגר ג'וזף",
    "ארן זלמן",
    "ארנון יצחק",
    "ארניה אסוולדו",
    "ארץ חמדה",
    "ארתור ווסרמיל",
    "אש שלום",
    "אשבל מיכאל",
    "אשכולית",
    "אשלים",
    "אשר",
    "באר שבע",
    "בארון דבורה",
    "בגין מנחם",
    "בדר יוחנן",
    "בובר מרטין",
    "בודנהיימר",
    "בונה אויערבך",
    "בוסקוביץ אלכסנדר",
    "בוסתנאי",
    "בורג יוסף",
    "בורוכוב",
    "בורלא יהודה",
    "בורן מכס",
    "בושמת",
    "בז",
    "בזל",
    'בי"ס חקלאי',
    "ביאליק",
    "ביח הדסה",
    'ביל"ו',
    "בינט מקס",
    "בית אל",
    "בית אשל",
    "בית החייל",
    "בית יציב",
    "בית לחם",
    "בית צורי אליהו",
    "בית רמט",
    "בית שאן",
    "בלהה",
    "בלוך רודולף",
    "בלפור",
    "בלקינד נעמן",
    "בן איש חי",
    "בן אשר",
    "בן דוד יוסף",
    "בן חביב",
    "בן חיים פאול",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן יעקב אהרון",
    "בן יעקב צבי",
    "בן לברט",
    "בן סרוק מנחם",
    "בן עדי הרברט",
    "בן עמי אברהם",
    "בן צבי יצחק",
    "בן שטח שמעון",
    "בן-אסא בנימין",
    "בן-חור אליהו",
    "בנגלה יונה",
    "בנה ביתך רמות",
    "בנטואיץ יוסף",
    "בנטוב מרדכי",
    "בני אור",
    "בנימין",
    "בנימין מטודלה",
    "בסיס חצרים",
    "בסקין צבי",
    "בעלי התוספות",
    "בער יצחק",
    "בצלאל",
    "בקי רוברטו",
    "בר אילן",
    "בר הלל יהושע",
    "בר יוחאי",
    "בר כוכבא",
    "בר ניסן",
    "בר-לב חיים",
    "ברבור",
    "ברגסון אנרי",
    "ברדיצ'ב אבא",
    "ברדיצ'בסקי",
    "ברוד מקס",
    "ברוור אברהם",
    "ברוידס אברהם",
    "ברזילי ישראל",
    "ברזני משה",
    "ברטונוב יהושע",
    "ברית כהונה",
    "ברכה צפירה",
    "ברני רוברט",
    "ברנע דב",
    "ברנפלד שמעון",
    "ברנר",
    "ברנשטיין לאונרד",
    "ברנשטיין פרץ",
    "ברק",
    "ברקת ראובן",
    "ברש אשר",
    "בת שבע",
    "בת שבע בן אליהו",
    "גבולות",
    "גבעתי",
    "גבריהו חיים",
    "גבתי חיים",
    "גד",
    "גדליהו",
    "גוזלן",
    "גוטמן נחום",
    "גויטיין דוד",
    "גולדברג לאה",
    "גולדמן נחום",
    "גולדשטיין פרץ",
    "גולומב",
    "גולי קניה",
    "גולני",
    "גומא",
    "גור יהודה",
    "גורדון א ד",
    "גוש עציון",
    "גיבור דניס",
    "גיבתון חנוך",
    "גינצבורג דוד",
    "גירית",
    "גלזמן יוסף",
    "גלילי ישראל",
    "גלנטי",
    "גלעד",
    "גמל",
    "גרגר הנחלים",
    "גרונר דב",
    "גרוסמן מאיר",
    "גרינבוים יצחק",
    "גרינפלד",
    "גרינשפן הרשל",
    "גרץ",
    "גרר",
    "גרשון",
    "גרשם שלום",
    "גרשפלד",
    "גת",
    "ד\"ר ג'ין קלוס-פישמן",
    'ד"ר וילהלמינה כהן',
    'ד"ר טיכו',
    'ד"ר קריסטינה סיקורה',
    "דב יוסף",
    "דבורה",
    "דביר",
    "דה הוושי גאורג",
    "דה-בוטון",
    "דה-שליט עמוס",
    "דהאן מישאל",
    "דובדבן",
    "דובנוב",
    "דוד אלעזר",
    "דוד המלך",
    "דוד הראובני",
    "דודאים",
    "דוידוביץ לב",
    "דוכיפת",
    "דולב",
    "דולפין",
    "דולצ'ין אריה",
    "דונה גרציה נשיא",
    "דונקלבלום מנחם",
    "דורי יעקב",
    "דורפמן",
    "דותן",
    "די זהב אפרים",
    "דידנר מנחם",
    "דיה",
    "דיין משה",
    "דינה",
    "דינור בן-ציון",
    "דליה",
    "דליה רביקוביץ",
    "דן",
    "דנציגר יצחק",
    "דפנה",
    "דקל",
    "דקלה",
    "דקר",
    "דר ניסתר",
    "דרבן",
    "דרויאנוב אברהם",
    "דרור",
    "דרזנר יחיאל",
    "דרך אביטל אביגדור",
    "דרך אילן רמון",
    "דרך אילת",
    "דרך אליהו נאוי",
    "דרך ג'ו אלון",
    "דרך המשחררים",
    "דרך חברון",
    "דרך מצדה",
    "דרך שמשון",
    "האבות",
    'האדר"ת',
    "האוזנר גדעון",
    "האורגים",
    "האז גיאורג",
    "האיילה",
    "האיסיים",
    "האמוראים",
    "האנרגיה",
    "הבדיל",
    "הבונים",
    "הברזל",
    "הבשור",
    "הגאולים",
    "הגאונים",
    "הגדודים",
    "הגלבוע",
    "הגנן",
    "הגפן",
    "הגר",
    "הדגן",
    "הדסה",
    "הדעת",
    "הדר",
    "הדרי זאב (ויניה)",
    "ההגנה",
    "ההסתדרות",
    "הוברמן",
    "הוגו שמואל",
    "הוכברג נתנאל",
    "הולצברג שמחה",
    "הושע",
    "הזוהר",
    "הזורע",
    "הזז חיים",
    "החדשנות",
    "החורש",
    'החיד"א',
    "החלוץ",
    "החרט",
    "החשמונאים",
    "החשמלאי",
    "הטחנה",
    "היוצרים",
    "היזה פאול",
    "היילפרין ליפמן",
    'הכ"ג',
    "הכוזרי",
    "הכותל המערבי",
    "הכט ראובן",
    "הכנסת",
    "הכשרת הישוב",
    'הל"ה',
    "הלל הזקן",
    "הלמן אברהם",
    "הלפרין",
    "המאירי מנחם",
    'המבי"ט',
    "המדע",
    'המהר"ל',
    "המוכתר",
    "המוסכים",
    "המייסדים",
    "המכבים",
    "המכונאי",
    "המכתשים",
    "המלאכה",
    "המלכים",
    "המנוף",
    "המסגר",
    "המסילה",
    "המעפילים",
    "המר זבולון",
    "המרד",
    "המשביר",
    "המשק",
    "הנבטים",
    "הנגבי",
    "הנגרים",
    "הנדיב",
    "הנוטר",
    "הנוקם",
    "הנחושת",
    "הנפח",
    "הס משה",
    "הסופרים",
    'הע"ל',
    "העיר העתיקה",
    "העליה",
    "העצמאות",
    "הפועלים",
    "הפחח",
    "הפלדה",
    'הפלמ"ח',
    "הפלס",
    "הפרטיזנים",
    "הצבי",
    "הצדיק מירושלים",
    "הציונות",
    "הצנחנים",
    "הקוצר",
    "הקנאים",
    'הקריה ע"ש א ברגמן',
    "הר בוקר",
    "הר בקע",
    "הר חרמון",
    "הר כנען",
    "הר מירון",
    "הר נס",
    "הר עודד",
    "הר עצמון",
    "הר רמון",
    "הר תבור",
    'הר"ן',
    'הרא"ש',
    "הרב אונטרמן",
    "הרב אלעזר קליין",
    "הרב אסף שמחה",
    "הרב גבאי מנחם",
    "הרב גורן שלמה",
    "הרב הרצוג",
    "הרב חביב חיים",
    "הרב חורי חיים",
    "הרב טייב חי",
    "הרב טנא שלמה",
    'הרב כ"ץ אליהו',
    "הרב מימון",
    "הרב מרקדו",
    "הרב סוסו יוסף",
    "הרב סילברט",
    "הרב עדני",
    "הרב פרדס",
    "הרב פרנק",
    "הרב קפאח",
    "הרב ריינס",
    "הרב רצאבי",
    "הרב שושנה",
    "הרבבה",
    "הרבי מלובביטש",
    'הרדב"ז',
    "הרדוף",
    "הרמלין יוסף",
    "הרצל",
    "הרצפלד אברהם",
    "הרתך",
    "השבטים",
    "השווקים",
    "השומר",
    "השופט אלקיים",
    "השופטים",
    "השלום",
    "התבונה",
    "התורה הגואלת",
    "התיבונים",
    "התלמוד",
    "התנאים",
    "התקוה",
    "וולפסון דוד",
    "ויטנברג יצחק",
    "וייס יעקב",
    "וילנאי זאב",
    "וילנסקי משה",
    "וילנר אריה",
    "וינגייט",
    "ויניק זאב",
    "ויצמן",
    "וירשובסקי חיים",
    "ויתקון אלפרד",
    "ולבר יצחק",
    "ונטורה",
    "וקסמן זלמן",
    "ורבורג",
    "ורד",
    "ורדימון יצחק",
    "ורטהיימר חיים",
    "ז'בוטינסקי זאב",
    "ז'קלין כהנוב",
    "זבולון",
    "זהרי מיכאל",
    "זוהרה אלפסיה",
    "זוסמן יואל",
    "זילברג משה",
    "זינדר חמדה וצבי",
    "זיסו",
    "זכות משה",
    "זכריה",
    "זלמן כספי",
    "זלפה",
    "זמורה משה",
    "זמיר ישעיהו",
    "זמנהוף",
    "זנגויל ישראל",
    'זק"ש נלי',
    "זרובבל",
    "זרזיר",
    "זרחין אלכסנדר",
    "זריצקי יוסף",
    'ח"ן',
    "חביב אבשלום",
    "חבס ברכה",
    "חבצלת הנגב",
    "חבקוק",
    "חגי",
    "חגלה",
    "חובב משה",
    "חוה",
    "חוחית",
    "חומה ומגדל",
    "חופית",
    'חז"ל',
    "חזמה יעקב",
    "חזן יעקב",
    "חזני מיכאל",
    "חזקיהו",
    "חטיבה שמונה",
    "חטיבת הנגב",
    "חיבת ציון",
    "חיטה",
    "חיים סנה",
    "חכים אליהו",
    "חלילי",
    "חלקיקי האור",
    "חמיאל יצחק",
    "חן ארנסט",
    "חנה",
    "חנה אבנור",
    "חנני",
    "חנני חיים",
    "חנקין",
    "חסידה",
    "חסמן מרק",
    "חצב",
    "חצור",
    "חרדון",
    "חרוב",
    "חרצית",
    "חשין",
    'חת"ם סופר',
    "טאוב",
    "טביוב",
    "טבנקין",
    "טבריה",
    "טדסקי גד",
    "טוב משה",
    "טובה ברץ",
    "טובה סנהדראי",
    "טווס",
    "טולידנו",
    "טור סיני נפתלי",
    "טיומקין זאב",
    "טל חרמון",
    "טלמון יעקב",
    "טננבוים מרדכי",
    "טרודי קוזלובסקי",
    "טרומפלדור",
    "טרק גבריאל",
    "טשרניחובסקי",
    "יא' הספורטאים",
    "יאיר",
    "יאשיהו",
    "יד ושם",
    "יהוא",
    "יהואש",
    "יהודה הלוי",
    "יהודה הנחתום",
    "יהודה הנשיא",
    "יהודי סוריה",
    "יהויכין",
    "יהורם",
    "יהושע הצורף",
    "יהושפט",
    "יואל השופט",
    "יוהנה ז'בוטינסקי",
    "יוחנן בן זכאי",
    "יוחנן הורקנוס",
    "יוכבד בת מרים",
    "יונה הנביא",
    "יוסי הבורסקאי",
    "יוסף",
    "יוסף בן מתתיהו",
    "יוסף ומאיר",
    "יוספטל גיורא",
    "יוסקה",
    "יחזקאל",
    "יחיל חיים",
    "יטבתה",
    "ייבין יהושע",
    'יל"ג',
    "ילין דוד",
    "ילין-מור",
    "ילן-שטקליס מרים",
    "ינקו מרסל",
    "ינשוף",
    "יסמין",
    "יסעור",
    "יסקי",
    "יעבץ יעקב",
    "יעקב (בודה) עורב",
    "יעקב אבינו",
    "יעקב עורבי",
    "יערה",
    "יערי מאיר",
    "יפה אברהם",
    "יפה ירקוני",
    "יפה לייב",
    "יפתח הגלעדי",
    "יצחק אבינו",
    "יצחק נפחא",
    "יציאת אירופה",
    "ירבוע",
    "ירדן",
    "יריחו",
    "ירמיהו",
    "ירקוני עמוס",
    'יש"א ברכ"ה',
    "ישמעאל",
    "ישעיהו הנביא",
    "ישעיהו ישראל",
    "יששכר",
    "כהן אלי",
    "כהן יעקב",
    "כהן לאונרד",
    "כחול לבן",
    "כיכר שפרן אלכסנדר",
    "ככר אביר",
    "ככר אדיס אבבה",
    "ככר איפרגן",
    "ככר אקלום פרדה יזזא",
    "ככר ארגוני נשים",
    "ככר בני שמעון",
    "ככר הבונים החופשים",
    "ככר הברית",
    "ככר הגנים",
    "ככר המתנדבים",
    "ככר הקיני",
    "ככר הקניזי",
    "ככר התאומים",
    "ככר וופרטל",
    "ככר זיסו",
    "ככר ליון",
    "ככר מונטריאול",
    "ככר מעון",
    "ככר סגל שרגא",
    "ככר סימון",
    "ככר פרידמן",
    "ככר קפלן",
    "ככר רוטרי",
    "ככר רחבים",
    "כלנית",
    "כפר הדרום",
    "כפר רפאל",
    "כצנלסון",
    "כרכום",
    "כרמלי",
    "לאה",
    "לאה רבין",
    "לב משה",
    "לבון פנחס",
    "לברי מרק",
    "להב",
    "לוזון אפרים",
    "לוין שמריהו",
    "לויצקי יעקב",
    "לוריא צבי",
    "ליליאן",
    "לילך",
    "לימון",
    "לימונית",
    "לינדנר יוחנן",
    "ליפמן גבריאל",
    "לישנסקי יוסף",
    "לכיש",
    "למדן יצחק",
    "לנדאו חיים",
    "לסקר פרץ",
    "לרון אפרים",
    "מאפו אברהם",
    "מארי קירי",
    "מבצע אסף",
    "מבצע דני",
    "מבצע חורב",
    "מבצע חירם",
    "מבצע יואב",
    "מבצע לוט",
    "מבצע משה",
    "מבצע נחשון",
    "מבצע עובדה",
    "מגידו",
    "מודיעין",
    "מודעי יצחק",
    "מוטה גור",
    "מויאל אליהו",
    "מולכו שלמה",
    "מונטיפיורי",
    "מוצא",
    "מורדי הגטאות",
    "מוריה",
    "מזוז מצליח",
    "מזר בנימין",
    "מזרחי",
    "מי מרום",
    "מיכאלסון יצחק",
    "מיכה",
    "מיכל",
    "מינץ",
    "מכללת קיי",
    "מלאכי",
    "מלון ערבה",
    "מלון פרדייז",
    "מלל סעדיה",
    "ממשית",
    "מנדלי מוכר ספרים",
    "מנדלסון",
    "מנצ'ל פועה ד\"ר",
    "מנשה",
    "מסקין אהרון",
    "מעון עולים",
    "מעלה החטיבה",
    "מעלה עקרבים",
    "מקור חיים",
    "מקלף מרדכי",
    "מרבד הקסמים",
    "מרגולין",
    "מרגיט להמן",
    "מרגלית מאיר",
    "מרווה",
    "מרטון יחזקאל",
    "מרידור",
    "מרים בן פורת",
    "מרים הנביאה",
    "מרכוס",
    "מרכז אזרחי",
    "מרכז ביג",
    "מרכז הנגב",
    "מרכז קליטה א",
    "מרכז קליטה ג",
    "מרכז קליטה חרוב",
    "מרכז קליטה יעלים",
    "מרכז קליטה קלישר",
    "מרכז רפואי סורוקה",
    "מרלא",
    "משה אדר",
    "משה יצחק",
    "משה לוי",
    'משעול בית"ר',
    "משעול גבעון",
    "משעול הערבה",
    "משעול חפר",
    "משעול טם איגור",
    "משעול יודפת",
    "משעול לוי",
    "משעול מבצע יונתן",
    "משעול מצפה",
    "משעול ניצנה",
    "משעול סוסיה",
    "משעול עקרון",
    "משעול פנקס דוד",
    "מתתיהו",
    "נאות אילן",
    "נאות הככר",
    "נאות חצרים",
    "נאות לון",
    "נאות מדבר",
    "נגבה",
    "נדב",
    "נהרים",
    "נווה זאב",
    "נווה מנחם",
    "נויפלד הנרי",
    "נורדאו מקס",
    "נורית",
    "נחום",
    "נחל בוקק",
    "נחל גוב",
    "נחל חבר",
    "נחל חיון",
    "נחל יבניאל",
    "נחל יהל",
    "נחל ימין",
    "נחל לבן",
    "נחל משמר",
    "נחל נטיפים",
    "נחל נקרות",
    "נחל עמוד",
    "נחל ערוגות",
    "נחל עשן",
    "נחל פרס",
    "נחל פרת",
    "נחל צלמון",
    "נחל קדרון",
    "נחל שילה",
    "נחליאל",
    "נטר",
    "ניב דוד",
    'ניל"י',
    "ניסנמן",
    "ניצני עזריאל",
    "ניר נחום",
    "נמיר מרדכי",
    "נעמי",
    "נעמי שמר",
    "נפתלי",
    "נקר מאיר",
    "נרבוני",
    "נרקיס",
    "נרקיס עוזי",
    "נשר",
    "סבידור מנחם",
    "סביון",
    "סגל צבי",
    "סדן דב",
    "סדנא",
    "סוד השבועה",
    "סוזן דניאל-נטף",
    "סוכות",
    "סולד הנריאטה",
    "סוקולוב",
    "סטולר שמואל",
    "סטרומה",
    "סיגלון",
    "סייפן",
    "סילמן יהודה",
    "סימון ארנסט דוד",
    "סיני",
    "סירני",
    "סלמה",
    "סלנט שמואל",
    "סלעית",
    "סמ הגדם",
    "סמ יהונתן",
    "סמ ערד",
    "סמ צקלג",
    "סמ קטורה",
    "סמ ראומה",
    "סמ רפידים",
    "סמבורסקי שמואל",
    "סמולנסקין",
    "סמטס",
    "סמילן רות",
    "סמילנסקי",
    "סנה משה",
    "סנה נחום",
    "סנהדרין",
    "סנש חנה",
    "סעדיה גאון",
    "סקולסקי שלמה",
    "סרלין יוסף",
    "סרן דב",
    "סרנה יחזקאל",
    "סתוי משה",
    "סתונית",
    "עבדת",
    "עגור",
    "עגנון",
    "עדה גלר",
    "עוזיה המלך",
    "עולי הגרדום",
    "עופרה חזה",
    "עורב",
    "עיט",
    "עין גדי",
    "עירית",
    "עכו",
    "עליאש מרדכי",
    "עליזה בגין",
    "עליזה בירון",
    "עמוס",
    "עמיעז",
    "עמיצור שמשון",
    "עמיר אנדה",
    "עמל",
    "עמק שרה",
    "עמרי",
    "עפרוני",
    "עפרים",
    "פאבל",
    "פאני מוגרבי",
    "פארן",
    "פולה בן גוריון",
    "פועה",
    "פורת לאה",
    "פורת קלמן",
    "פטל",
    "פטנקין דן",
    "פטריה",
    "פיארברג",
    "פייגנבאום אריה",
    "פיינברג אבשלום",
    "פיינשטיין מאיר",
    "פיכמן יעקב",
    "פילדרמן",
    "פינסקר",
    "פינקל שמעון",
    "פיקרד",
    "פלדברג שמואל",
    "פלוסר דוד",
    "פלורי אשר",
    "פליקס",
    "פלפלון",
    "פנחס החוצב",
    "פני משה",
    "פנינה",
    "פסמון",
    "פעמונית",
    "פקועה",
    "פראוור יהושע",
    "פרויד",
    "פרומקין גד",
    "פרופ' מחרז אברהם",
    "פרופ' עלי דיוויס",
    "פרופ' רוזן נתן",
    "פרופ' שיבא",
    "פרטוש עדן",
    "פרי חדש",
    "פריאר רחה",
    "פריבס משה",
    "פריד אלפרד",
    "פריץ אלברט",
    "פרישמן דוד",
    "פרנק ג'ימס",
    "פרסיץ שושנה",
    "פרץ י ל",
    "פשוש",
    "צ'לנוב יחיאל",
    "צאלון",
    "צאלים",
    "צבי-רן שלמה",
    "צביה המלכה",
    "צביה ויצחק",
    "צבעוני",
    "צבר",
    "צדקיהו",
    "צונדק ברנרד והרמן",
    "צוער",
    "צוקית",
    "ציזלינג",
    "צין",
    "ציר הזמן",
    "צפורה",
    "צפורי",
    "צפניה",
    "צפת",
    "קדושי בגדד",
    "קדושי יאסי",
    "קדושי סלוניקי",
    "קדיש לוז",
    "קדש",
    "קהירי",
    "קובנר אבא",
    "קוטגים",
    "קויפמן",
    "קול התור",
    "קול מבשר",
    "קורא",
    "קורא הדורות",
    "קורבן העדה",
    "קורנית",
    "קטינקא",
    "קינמון",
    "קיסריה",
    "קיפוד",
    "קיפניס לוין",
    "קישון",
    "קלאוזנר",
    "קלישר",
    "קלצ'קין רפאל",
    "קנדל הנרי",
    "קניון הנגב",
    "קפריסין",
    "קציר אהרון",
    "קראוס גרטרוד",
    "קרויצר מנחם",
    "קרונמן",
    "קרוק גלעד דורותיה",
    "קרייתי",
    "קרית וולפסון",
    "קרית יהודית",
    "קרן היסוד",
    "קרן קיימת לישראל",
    "קשאני אליעזר",
    "ראובן",
    "רב האי גאון",
    "רבי טרפון",
    "רבי מאיר בעל הנס",
    "רבי עקיבא",
    "רבן גמליאל",
    "רבנו תם",
    "רבקה",
    'רד"ק',
    "רובין דב",
    "רובין ראובן",
    "רובינא חנה",
    "רובק",
    "רודנסקי שמואל",
    "רוזה גינצברג גינוסר",
    "רוזמרין",
    "רוזן פנחס",
    "רוזנבלום הרצל",
    "רוט אגון",
    "רוטנברג",
    "רוטנברג יוליה ושמוא",
    "רוטנשטרייך",
    "רונאל דב",
    "רוסי אלפרד",
    "רופין ארתור",
    "רוקח ישראל",
    "רות",
    "רות הילמן",
    "רותם",
    "רזיאל",
    "רחבעם זאבי",
    "רחבת אופירה",
    "רחבת אחזיה",
    "רחבת איבצן",
    "רחבת אלכסנדרוני",
    "רחבת אמציה",
    "רחבת באר אברהם",
    "רחבת בן נון",
    "רחבת בן עטר",
    "רחבת גדעון",
    "רחבת המרי העברי",
    "רחבת הראל",
    "רחבת הרב עוזיאל",
    "רחבת הרב קוק",
    'רחבת השל"ה',
    'רחבת חי"ל',
    "רחבת יבנה",
    "רחבת יותם",
    "רחבת ימית",
    "רחבת יעל",
    'רחבת לח"י',
    "רחבת מבצע ארז",
    "רחבת מבצע ברוש",
    "רחבת מבצע יקב",
    "רחבת מבצע קדם",
    "רחבת מבצע קילשון",
    "רחבת מולדת",
    "רחבת מילוס",
    "רחבת עשהאל",
    "רחבת פל-ים",
    'רחבת רמ"א',
    "רחבת שלומציון",
    "רחבת שמגר השופט",
    "רחל אמנו",
    "רחל המשוררת",
    "רחל ינאית",
    "רחל צברי",
    "ריבי איזידור",
    "ריחן",
    "רייס רפאל",
    "רייק חביבה",
    "רימון",
    "רימלט אלימלך",
    "רינגלבלום",
    "רינר מרכוס",
    'רמב"ם',
    'רמב"ן',
    "רמות באר שבע",
    'רמח"ל',
    "רמלה",
    "רנה קסן",
    "רפאל איתן (רפול)",
    "רקח יואל",
    "רקפת",
    'רש"י',
    'רש"ל',
    'רשב"א',
    "ש ביח רוקיה",
    "ש בתי אבן",
    "ש דרום חדש",
    "ש חצרים",
    "ש יא",
    "ש משכנות",
    "ש סולל בונה",
    "ש עובדים ב",
    "ש עממי א",
    "ש עממי ג",
    "ש רסקו",
    "שאול המלך",
    "שאול ואליהו",
    "שאר ישוב",
    "שבזי",
    "שד אשכול",
    "שד בן גוריון",
    'שד בנ"צ כרמל',
    "שד גולדה מאיר",
    "שד האנדרטה",
    "שד הנשיא הרצוג חיים",
    "שד חכם דוד",
    "שד טוביהו דוד",
    "שד ידין יגאל",
    "שד יעלים",
    "שד ירושלים",
    "שד מרש יעקב",
    'שד צה"ל',
    "שד רגר יצחק",
    "שד רוטשילד",
    "שדה יצחק",
    "שדרת היחסות",
    "שדרת הערים התאומות",
    "שדרת חיל הנדסה",
    "שוחט ישראל",
    "שולמית",
    "שומרון",
    "שועלי שמשון",
    "שופט אברהם",
    "שופמן גרשון",
    "שורק",
    "שושנה דמארי",
    "שושנה שבבו",
    'שז"ר זלמן',
    'שח"ל',
    "שחף",
    "שטה",
    "שטיין ריכרד",
    "שטיינברג יהודה",
    "שטרוק",
    "שטרייכמן יחזקאל",
    "שטרן יוסף",
    "שיבטה",
    "שיזף",
    "שיטרית",
    "שינדלר אוסקר",
    "שיפון",
    "שיפר יצחק",
    "שירמן חיים",
    "שכ א",
    "שכ ב",
    "שכ ג",
    "שכ ד",
    "שכ ה",
    "שכ ו",
    "שכ ט",
    "שכ כלניות",
    "שכ נוה נוי",
    "שכ נחל בקע",
    "שכ סיגליות",
    "שכ רמות ב'",
    "שכ רמות ד'",
    "שכם",
    "שלדג",
    "שלום עליכם",
    "שלונסקי",
    "שלושת המצפים",
    "שליו",
    "שלמה המלך",
    "שלשת בני עין חרוד",
    "שמאי",
    "שמאלי אליעזר",
    "שמואל הנביא",
    "שמחה",
    "שמחוני אסף",
    "שמחת כהן",
    "שממית",
    "שמעון בר גיורא",
    "שמעוני",
    "שמעיה",
    "שנונית",
    "שניאור",
    "שניידרוביץ",
    "שסק",
    "שעורה",
    "שער הגיא",
    "שפירא",
    "שפרה",
    "שפרינצק",
    "שקד",
    "שקמה",
    "שרה אמנו",
    "שרה לוי-תנאי",
    "שרה עזריהו",
    "שריג נחום",
    "שרירא גאון",
    "שרעבי",
    "שרשבסקי",
    "שרת משה",
    "ששון אליהו",
    "ששת הימים",
    "שתל יצחק",
    "תאנה",
    "תבורי חיים",
    "תוצרת הארץ",
    "תור",
    "תורן יהושע",
    "תל חי",
    "תלמה",
    "תלמי מאיר",
    "תמיר שמואל",
    "תמנע",
    "תמר",
    "תמר שחר",
    "תנובה",
    "תפוז",
    "תפוח",
    "תקוע יוסף",
  ],
  "בארות יצחק": ["אולפן", "בארות יצחק"],
  בארותיים: ["בארותיים", "האפיק", "היובלים", "הנחלים", "הפלג"],
  בארי: ["בארי"],
  "בוסתן הגליל": [
    "בוסתן הגליל",
    "דרך הגנים",
    "דרך הזית",
    "דרך הים",
    "כפר הנוער נירים",
    "שד האקליפטוס",
  ],
  "בועיינה-נוג'ידאת": ["בועיינה-נוג'ידאת", "בועינה", "נוג'ידאת"],
  בוקעאתא: ["בוקעאתא"],
  בורגתה: ["בורגתה", "הארז", "הברוש", "הגפן", "הדקל", "ההדס"],
  בחן: [
    "אגוז",
    "אורן",
    "אלה",
    "אלון",
    "אלמוגן",
    "ארז",
    "אשל",
    "בחן",
    "ברוש",
    "דולב",
    "דקל",
    "זית",
    "יד חנה מאוחד",
    "מורן",
    "ערבה",
    "צאלון",
    "שיזף",
    "שקד",
    "שקמה",
    "תאנה",
    "תמר",
  ],
  בטחה: ["בטחה"],
  ביצרון: [
    "ביצרון",
    "דרך הפיקוסים",
    "הותיקים",
    "החיילים",
    "המייסדים",
    "הנוריות",
    "הנרקיסים",
    "העולים",
    "העקרונים",
    "שכ החיילים",
  ],
  "ביר אל-מכסור": ["ביר אל-מכסור"],
  "ביר הדאג'": ["ביר הדאג'"],
  ביריה: ["ביריה", "כהן איציק", "מדר חיים"],
  "בית אורן": ["בית אורן", "בית סוהר דמון", "חוות בית אורן"],
  "בית אל": [
    "אבשלום",
    "אופירה",
    "אלון",
    "אלעזר",
    "אסא המלך",
    "אעלה בתמר",
    "אפרים",
    'אצ"ל',
    "ארץ חמדה",
    "בועז",
    'ביל"ו',
    "בית אל",
    "בית אל ב'",
    "בנימין",
    "ברוש",
    "ברקת",
    "גינת אגוז",
    "גפן",
    "דקלה",
    "דרור",
    "דרך ביטחון",
    "דרך בית אל",
    "דרך האמהות",
    "דרך החיים",
    "דרך החלוצים",
    "דרך העמק",
    "דרך שבטי ישראל",
    "דרך שבעת המינים",
    "האולפנא",
    "האחוזה",
    "הגנה",
    "המסילה",
    "המעפילים",
    "המפעל",
    "הנזיר",
    'הנצי"ב',
    "התחיה",
    "זבולון",
    "זית",
    "חזקיהו המלך",
    "חיבת ציון",
    "חיטה",
    "חלמיש",
    "חצר אדר",
    "חרובית",
    "יהודה המכבי",
    "יהושפט",
    "יהלום",
    "יוחנן",
    "יונתן",
    "יותם",
    "יכין",
    "ימית",
    "יפה נוף",
    "כ' בחשון",
    "כינור דוד",
    "כנפי נשרים",
    "לאה",
    "לבי במזרח",
    "לוי",
    'לח"י',
    "מבוא אבני החושן",
    "מבוא אתרוג",
    "מבוא האורנים",
    "מבוא הדס",
    "מבוא הישיבה",
    "מבוא התקומה",
    "מבוא ליבנה",
    "מבצע שלמה",
    "מי מרום",
    "מנשה",
    'מעגלי הרא"ה',
    "מעוז צור",
    "מעלה האמונה",
    "מעלה שופר",
    "מרבד הקסמים",
    "מתתיהו",
    "נריה",
    "סולם יעקב",
    "סופה",
    "ספיר",
    "ערבה",
    "ערוגות הבושם",
    "ערמון",
    "פרי-גן",
    "פריאל",
    "קול התור",
    "קטלב",
    "קיבוץ גלויות",
    "קרית הישיבה",
    "ראובן",
    "רחל",
    "רימון",
    "שאול המלך",
    "שבות סיני",
    "שביל האמונה",
    "שדות",
    "שדרות לוז",
    "שוהם",
    "שיטה",
    "שכ אבן פינה",
    "שכ בן הודה",
    "שכ גבעת האולפנה",
    "שכ גינות בית אל",
    "שכ דוד",
    "שכ הואדי",
    "שכ נוף",
    "שכ ניצני בית אל",
    "שכ ניצנית",
    "שכ פסגת יעקב",
    "שכ קדם",
    "שכ ראשית",
    "שכונה א",
    "שכונה ב",
    "שלמה המלך",
    "שמעון",
    "שקד",
    "תאנה",
    "תדהר",
    "תורה ועבודה",
    "תל חיים",
    "תמר",
    "תפוח",
    "תרשיש",
  ],
  "בית אלעזרי": [
    "בית אלעזרי",
    "הגפן",
    "הזית",
    "המייסדים",
    "הרימון",
    "התאנה",
    "התמר",
  ],
  "בית אלפא": ["אולפן", "בית אלפא", "מרכז קליטה גלבוע"],
  "בית אריה": [
    "אחירותם",
    "איבן גבירול",
    "אירוס",
    "אלתרמן נתן",
    "אריאל מאיר",
    "אריק איינשטיין",
    "אשחר",
    "בית אריה",
    "גבעת הבריכה",
    "דבורנית",
    "דולב",
    "האלה",
    "האלון",
    "האשל",
    "הברוש",
    "הברושים",
    "הגדי",
    "הגפן",
    "הדקל",
    "הזית",
    "הלוי יהודה",
    "העופר",
    "הרב שבזי",
    "הרימון",
    "השיטה",
    "השירה העברית",
    "השקד עופרים",
    "התאנה",
    "התמר",
    "חוטמית",
    "חזה עופרה",
    "חיננית",
    "חצב",
    "חרצית",
    "טיכו אנה",
    "כוכבית",
    "כליל החורש",
    "כרכום",
    "כרמים",
    "לבונה",
    "לביא אריק",
    "לוטם",
    "מעלה הזית",
    "מרווה",
    "ניסנית",
    "נירית",
    "עופרים",
    "עירית",
    "ערבה",
    "פן אלכסנדר",
    "פעמונית",
    "פריחת השקד",
    "צורית",
    "צלף",
    "קורנית",
    "קטלב",
    "קיסוסית",
    "רחל המשוררת",
    "רקפת",
    "שד הניצנים",
    "שטרן יאיר",
    "שיטים",
    "שמר נעמי",
    "שקמה",
    "שריג",
    "תירוש",
  ],
  "בית ברל": ["בית ברל", "קלמניה"],
  "בית ג'ן": [
    "אלאוח'וה",
    "אלאחלאם",
    "אלאמיר אלסייד",
    "אלאמל",
    "אלאנביא",
    "אלאס",
    "אלאסד",
    "אלבאבור",
    "אלבאשיק",
    "אלבוטום",
    "אלבורטוקאל",
    "אלבט",
    "אלביאדה",
    "אלביאדי",
    "אלבירק",
    "אלבירקי",
    "אלבית אלעתיק",
    "אלבלאן",
    "אלבלוט",
    "אלג'וז",
    "אלג'רמק",
    "אלד'הר",
    "אלדואר",
    "אלדוירי",
    "אלדוראק",
    "אלדורה",
    "אלדידבה",
    "אלדראג'",
    "אלדרג'ה",
    "אלהודהוד",
    "אלהיל",
    "אלהנבל",
    "אלוארד אלג'ורי",
    "אלוורוד",
    "אלועל",
    "אלזאבוד",
    "אלזאן",
    "אלזאתוני",
    "אלזוהור",
    "אלזית",
    "אלזיתון",
    "אלזנבק",
    "אלזערור",
    "אלזעתר",
    "אלזריקי",
    "אלח'יט",
    "אלח'ס",
    "אלח'רוב",
    "אלח'תאף",
    "אלחבק",
    "אלחג'ל",
    "אלחדארי",
    "אלחופחאף",
    "אלחינדאג'",
    "אלחלוה",
    "אלחמאם",
    "אלחמל",
    "אלחסון",
    "אלטואלי",
    "אלטיון",
    "אלימאם",
    "אלינסון",
    "אלכאפור",
    "אלכיווי",
    "אלכינא",
    "אלכמון",
    "אלכנאר",
    "אלכרז",
    "אלכרמה",
    "אלכרק",
    "אללוז",
    "אללוטוס",
    "אללומאם",
    "אללימון",
    "אללקלק",
    "אלמדרסה",
    "אלמהא",
    "אלמוברה",
    "אלמוסתראח",
    "אלמחלב",
    "אלמטל",
    "אלמידאן",
    "אלמיס",
    "אלמיסק",
    "אלמל",
    "אלמלעב",
    "אלמנארה",
    "אלמנבר",
    "אלמנטרה",
    "אלמסירב",
    "אלמספה",
    "אלמע'ארה",
    "אלמעסרה",
    "אלמקלע",
    "אלמרואני",
    "אלמרשקה",
    "אלמשמש",
    "אלנוקרה",
    "אלנורס",
    "אלנח'יל",
    "אלנימר",
    "אלנסיבה",
    "אלנסר",
    "אלנעאמה",
    "אלנענע",
    "אלנעסה",
    "אלנרגיס",
    "אלסאוסן",
    "אלסבע",
    "אלסבר",
    "אלסדיר",
    "אלסוואנה",
    "אלסוויד",
    "אלסולם",
    "אלסומאק",
    "אלסומן",
    "אלסופסאף",
    "אלסלוא",
    "אלסמרמר",
    "אלסנאבל",
    "אלסנאפרה",
    "אלסנובר",
    "אלסנונו",
    "אלסעבניה",
    "אלספאיה ועין לג'רון",
    "אלסרוה",
    "אלסריס",
    "אלע'אר",
    "אלע'ורדא",
    "אלע'זאל",
    "אלע'טאס",
    "אלעבהר",
    "אלעוטרה",
    "אלעוינה",
    "אלעוליק",
    "אלעוסג'",
    "אלעזמיה",
    "אלעלם",
    "אלעמשקה",
    "אלענב",
    "אלעסל",
    "אלעקאב",
    "אלעקבאת",
    "אלערישה",
    "אלערער",
    "אלפהד",
    "אלפוקרה",
    "אלפיג'ין",
    "אלפרס",
    "אלקאתל",
    "אלקבריש",
    "אלקדח",
    "אלקובע",
    "אלקורונפול",
    "אלקורטאס",
    "אלקותון",
    "אלקירקיס",
    "אלקמח",
    "אלקנדול",
    "אלקסב",
    "אלקעדה",
    "אלקרקשאני",
    "אלראיה",
    "אלרביסה",
    "אלרויס",
    "אלרומאן",
    "אלריאן",
    "אלריחאן",
    "אלרים",
    "אלרע'בה",
    "אלרפראף",
    "אלשאחוט",
    "אלשברק",
    "אלשג'רה",
    "אלשוחמאני",
    "אלשומר",
    "אלשחרור",
    "אלשיח",
    "אלשיתוי",
    "אלשמיה",
    "אלתופאחי",
    "אלתורכמאן",
    "אלתות",
    "אלתין",
    "אלתמר",
    "אלתנור",
    "אמין טריף",
    "אנאנאס",
    "אסאבע אלערוס",
    "באבונג'",
    "בהאא אלדין",
    "בוזריק",
    "בונדוק",
    "בוקראטי",
    "ביאדר אלסמאט",
    "ביאדר אלע'ירז",
    "ביאדר אלרביעה",
    "בית ג'ן",
    "בית ג'ן עין אלאסד",
    "ברזא",
    "ברכת חאמד",
    "ג'וז אלהנד",
    "ג'וזת אלטיב",
    "ג'ומז",
    "ג'זאר",
    "ג'עסאס",
    "דבוקי",
    "דולפין",
    "דורדאר",
    "דפלה",
    "דרב אלתבאן",
    "הגלעין הישן",
    "הזאר",
    "הליון",
    "זיזאפון",
    "זנג'ביל",
    "זראפה",
    "זרזור",
    "ח'וזאמה",
    "ח'וך",
    "ח'ולנג'אן",
    "ח'לת אלג'ב",
    "ח'לת אלזע'לול",
    "ח'לת אלענק",
    "ח'לת למסאק",
    "ח'לת עיאד",
    "חבק",
    "חבת אלברכה",
    "חיידר",
    "חלואני",
    "חמזה דרויש",
    "כובת אלעג'וז",
    "כולוניא",
    "כומטרה",
    "כוסא",
    "כוסברה",
    "כוראת'",
    "כורכור",
    "כילך",
    "כמאל ג'ונבלאט",
    "כסטנה",
    "כף אלדב",
    "כרום אלחסנה",
    "כרכדאן",
    "כרם סואר",
    "כרם עיד",
    "לואיזה",
    "לובלאב",
    "ליילאק",
    "מאלק אלחזין",
    "מדאואר",
    "מוחמד אבו שקרא",
    "מוסתכא",
    "מוע'ר אלעיד",
    "מיאל אלשמס",
    "מלפוף",
    "מרג' אלע'וזלאן",
    "מרדקוש",
    "משפחת קבלאן",
    "נבעת אלחור",
    "נבעת סידו",
    "נג'אס",
    "נואף ע'זאלי",
    "נכתרין",
    "נפלה",
    "סולטאן אלאטרש",
    "סופרג'ל",
    "סוק אלד'איע",
    "סחלב",
    "סידרה",
    "סימסים",
    "סינדיאן",
    "סנג'אב",
    "סנטארוזה",
    "סרטבאני",
    "עג'לאן",
    "עדס",
    "עין אלנום",
    "עכוב",
    "ענאב",
    "ענבר",
    "עסא אלראעי",
    "פירי",
    "פלפל",
    "פם אלסמקה",
    "קורס ענה",
    "קחואן",
    "קנדול",
    "קראסייה",
    "קרפה",
    "ראס אלעקבה",
    "ראס טלעה וחלת עמירה",
    "רקאטי",
    "רשאד",
    "שאהין אלע'זאל",
    "שי פארסי",
    "שכ אלע'דיר וסרטבה",
    "שכ חיילים משוחררים",
    "שכיב ארסלאן",
    "שניר",
    "שרקרק",
    "תל אלפוח'אר",
    "תלאל אלריח",
    "תנתול",
  ],
  "בית גוברין": ["בית גוברין"],
  "בית גמליאל": [
    "בית גמליאל",
    "הגפן",
    "ההדר",
    "הזית",
    "הרימון",
    "התאנה",
    "התמר",
  ],
  "בית דגן": [
    "אברהם אבינו",
    "איריס",
    "ארבעת המינים",
    "בגין מנחם",
    "בית דגן",
    "בנה ביתך",
    'דרך המ"ג',
    "האשכולית",
    "האתרוג",
    "הבנים",
    "הגולן",
    "הגליל",
    "הגפן",
    "הדס",
    "הזית",
    "החיטה",
    "החרמון",
    "היובל",
    "הכרמל",
    "הלולב",
    "הנגב",
    "העצמאות",
    "הערבה",
    "הרימון",
    "הרצל",
    "השעורה",
    "השפלה",
    "השרון",
    "התאנה",
    "התמר",
    "התפוז",
    "ורד",
    "ז'בוטינסקי",
    "חבצלת",
    "טרומפלדור",
    "יהודה",
    "יסמין",
    "כלנית",
    "כצנלסון",
    "לילך",
    "נווה הדרים",
    "נרקיס",
    "סביון",
    'צה"ל',
    "קבוץ גלויות",
    "קפלן",
    'קק"ל',
    "רח ט",
    "רח י",
    'רמב"ן',
    "רמז",
    "רקפת",
    "ש נווה גנים",
    "ש נקודת חן",
    "שבזי",
    "שבעת המינים",
    "שומרון",
  ],
  "בית הגדי": ["בית הגדי"],
  "בית הלוי": ["בית הלוי", "הרחבה"],
  "בית הלל": ["בית הלל"],
  "בית העמק": ["בית העמק"],
  "בית הערבה": ["בית הערבה"],
  "בית השיטה": ["בית השיטה"],
  "בית זיד": ["בית זיד", "כפר תקווה"],
  "בית זית": [
    "בית זית",
    "בריכות היער",
    "גבעת ברושים",
    "דרך הגבעה",
    "דרך הזיתים",
    "דרך הצבר",
    "דרך השקד",
    "האגם",
    "האלה",
    "האלון",
    "הברוש",
    "הגפן",
    "הפסגה",
    "מעלה הערבה",
    "מעלה השיטה",
    "נתיב האורנים",
    "נתיב האפרסק",
    "עין כרם",
  ],
  "בית זרע": ["בית זרע", "בית ראשון במולדת"],
  "בית חורון": [
    "אבני אלגביש",
    "איחוד העם",
    "בית חורון",
    "דרך אוהד",
    "דרך הנוף",
    "דרך יהודה המכבי",
    "המייסדים",
    "הסובלנות",
    "זאבי רחבעם",
    "יהושע בן נון",
    'כ"א בכסלו',
    "מבוא דור ההמשך",
    "מבוא המיצפור",
    "מעלה בית חורון",
    "נחלת אפרים",
    "סיני",
    "רקפת",
    "שארה",
  ],
  "בית חירות": [
    "בית חירות",
    "דרך ההדרים",
    "דרך הים",
    "האגוז",
    "האורן",
    "הדקל",
    "הכיכר",
    "המדגרה",
    "הראשונים",
    "הרימון",
    "השונית",
    "התמר",
    "כוכב הים",
    "מורד ההדרים",
    "מעלה הפיקוס",
    "סמ האגוז",
    "סמ הראשונים",
  ],
  "בית חלקיה": ["בית חלקיה"],
  "בית חנן": [
    "בית חנן",
    "גבעת האילנות",
    "גבעת ההתיישבות",
    "דרך הברושים",
    "דרך המגשימים",
    "דרך המיסדים",
    "דרך הצבר",
  ],
  "בית חנניה": [
    "אודם",
    "אלמוג",
    "בהט",
    "בזלת",
    "בית חנניה",
    "ברקת",
    "גרניט",
    "דרך אמת המים",
    "דרך הים",
    "דרך הכרמל",
    "חלמיש",
    "טופז",
    "טורקיז",
    "ספיר",
    "ענבר",
  ],
  "בית חשמונאי": [
    "אלעזר החורני",
    "אשכול",
    "בית חשמונאי",
    "דרך היין",
    "דרך הכרמים",
    "דרך המכבים",
    "הבציר",
    "הגפן",
    "הכרם",
    "יהודה המכבי",
    "יוחנן הגדי",
    "יונתן הוופסי",
    "עינב",
    "שכ הכרמים",
    "שמעון התרסי",
    "שריג",
  ],
  "בית יהושע": [
    "אודם",
    "ארגמן",
    "בית יהושע",
    "ברקת",
    "הגפן",
    "הזית",
    "החרוב",
    "הרחבה",
    "הרימון",
    "התאנה",
    "התמר",
    "יהלום",
    "ספיר",
    "שהם",
    "שני",
    "תכלת",
  ],
  "בית יוסף": ["בית יוסף"],
  "בית ינאי": [
    "אלמוג",
    "בית ינאי",
    "דולפין",
    "דקר",
    "החבצלת",
    "הים",
    "הצוק",
    "הרדוף",
    "השיטה",
    "מצפה ים",
    "נר הלילה",
    "קלר",
  ],
  "בית יצחק-שער חפר": [
    "בית יצחק-שער חפר",
    "דרך האילנות",
    "דרך הרימונים",
    "דרך השדות",
    "דרך השרון",
    "דרך חפר",
    "דרך נירה",
    "האורנים",
    "האלה",
    "האלונים",
    "הבוסתן",
    "הגנים",
    "הגפן",
    "הורדים",
    "הזית",
    "החבצלת",
    "החצב",
    "החרוב",
    "הכלנית",
    "הלילך",
    "המגדל",
    "הנרקיס",
    "הסביון",
    "העירית",
    "העליה",
    "הראשונים",
    "הרותם",
    "הרימון",
    "הרקפת",
    "השיטה",
    "השקד",
    "השקמה",
    "התאנה",
    "התמרים",
    "משעול האילנות",
    "סמטת 778",
    "סמטת האקליפטוס",
    "סמטת ההדרים",
    "סמטת החרובים",
    "סמטת הצבר",
    "סמטת התות",
    "רחבת הבנים",
    "שער הכפר",
  ],
  "בית לחם הגלילית": ["בית לחם הגלילית"],
  "בית מאיר": ["בית מאיר", "רמות שפירא"],
  "בית נחמיה": [
    "בית נחמיה",
    "דרך הורד",
    "דרך החצב",
    "דרך החרצית",
    "דרך היסמין",
    "דרך היקינטון",
    "דרך הכלנית",
  ],
  "בית ניר": ["בית ניר"],
  "בית נקופה": [
    "בית נקופה",
    "האגוז",
    "האורן",
    "האלה",
    "האלון",
    "הברוש",
    "הזית",
    "המייסדים",
    "הערבה",
    "השקד",
    "סמ התמר",
  ],
  "בית עובד": ["בית עובד"],
  "בית עוזיאל": ["בית עוזיאל"],
  "בית עזרא": ["בית עזרא"],
  "בית עריף": ["בית עריף"],
  "בית צבי": ["בית צבי"],
  "בית קמה": ["בית קמה"],
  "בית קשת": ["בית קשת", "בית ראשון במולדת"],
  "בית רבן": ["בית רבן"],
  "בית רימון": ["בית רימון"],
  "בית שאן": [
    "אביטן יהושע",
    "אביר יעקב",
    "אבן שושן אברהם",
    "אהבת ישראל",
    "אהבת ציון",
    "אהוד מנור",
    "אורן",
    "אזור תעשיה מזרחי",
    "אזור תעשיה צפוני",
    'אח"י אילת',
    'אח"י דקר',
    "אחד העם",
    "אירוס",
    "איתן רפאל",
    "אלה",
    "אלון",
    "אמנון ותמר",
    "אמנון ליפקין שחק",
    'אצ"ל',
    "ארז",
    "אריק איינשטיין",
    "אשל",
    "אשתורי הפרחי",
    "בויאר אווה",
    "בורוכוב",
    "ביאליק",
    "בית שאן",
    "בלפור",
    "בן גוריון",
    "בן יהודה",
    "בן לולו",
    "בן צבי",
    "בר כוכבא",
    "בר-לב חיים",
    "ברוש",
    "ברנר",
    "גולדה מאיר",
    "גולומב",
    "דבורה הנביאה",
    "דובדבן",
    "דוד אלעזר",
    "דולב",
    "דיין משה",
    "דקל",
    "דרך הבנים",
    "דרך השלום",
    "דרך מאיר",
    "האסיף",
    "הבונים",
    "הבוסתן",
    "הבציר",
    "הבקעה",
    "הגביש",
    "הגולן",
    "הגלבוע",
    "הגפן",
    "הגת",
    "הדגן",
    "ההדר",
    "החופית",
    "החורש",
    "החלמיש",
    "החרמש",
    "החרש",
    "החשמל",
    "היוזמה",
    "היצירה",
    "הירקון",
    "היתד",
    "הכרמל",
    'הל"ה',
    "המייסדים",
    "המירון",
    "המעפילים",
    "המשטרה",
    "הנגב",
    "הנעמן",
    "הסדנא",
    "העבודה",
    "העיר העתיקה",
    "העמל",
    "העמק",
    "העצמון",
    'הפלמ"ח',
    "הצנחנים",
    "הקציר",
    "הראל",
    "הרב יצחק הרצוג",
    "הרב לסרי",
    "הרב מרדכי אליהו",
    "הרב סויסה דוד",
    "הרב קוק",
    'הרב ש"ך',
    "הרופאים",
    'הרמב"ם',
    "הרצל",
    "הרתך",
    "השומרון",
    "השחם",
    "השייטת",
    "השפלה",
    "השרון",
    "התומר",
    "התירוש",
    "התנופה",
    "ויצמן חיים",
    "זאבי רחבעם",
    "זית",
    "חבצלת",
    "חומה ומגדל",
    "חטיבת גבעתי",
    "חטיבת גולני",
    'חטיבת הנח"ל',
    "חטיבת קרייתי",
    "חצב",
    "חרוב",
    "טולידנו",
    "טיילת המלך חסן השני",
    "טרומפלדור",
    "ידין יגאל",
    "יהודה הלוי",
    "יונתן",
    "יוסי בנאי",
    "יסמין",
    "יעקב ג'קיטו מאסטרו",
    "יפה ירקוני",
    "יפתח",
    "ירושלים הבירה",
    "ישראל פוליאקוב",
    "כליל החורש",
    "כנסת ישראל",
    "כנרת",
    "לאה גולדברג",
    "לוי אשכול",
    "לוי משה",
    'לח"י',
    "ליטני",
    "ללוש אנדרה",
    "לסקוב חיים",
    "מאיר בעל הנס",
    "מוטה גור",
    "מיזוג גלויות",
    "מכלוף יעקב",
    "מעון עולים",
    "מעפילי אגוז",
    "מצדה",
    "מקלף מרדכי",
    "מרקוס",
    "נהריים",
    "נוף הגלבוע",
    "נוף הירדן",
    "נופר",
    "נורדאו",
    "נורית",
    "נח מרדינגר",
    "נחום היימן",
    "נחשון",
    "נרקיס עוזי",
    "סחלב",
    "סנש חנה",
    "עדולם",
    "עוזי חיטמן",
    "עופרה חזה",
    "עזריה אלון",
    "עליה",
    "פיקוס",
    "צבעוני",
    'צה"ל',
    "צור צבי",
    "ציפורן",
    "צפצפה",
    "קדש",
    "קהילת יהדות סלוניקי",
    "קפלן",
    "קרית רבין",
    "רבינוביץ חנן",
    "רוגובסקי",
    "רועי קליין",
    "רותם",
    "רח א",
    "רח ד",
    "רח ה",
    "רח ו",
    "רח ח",
    "רח ט",
    "רחל המשוררת",
    "רימון",
    "רמון אילן",
    "רמז",
    "רקפת",
    "ש בנה ביתך",
    "ש ז'בוטינסקי",
    "ש עממי",
    "ש צריפים אדומים",
    "ש קדמת עדן",
    "שאול המלך",
    "שד יצחק רבין",
    "שד מנחם בגין",
    "שדרות הארבעה",
    "שומרון דן",
    "שושנה דמארי",
    'שז"ר זלמן',
    "שטרן יאיר",
    "שיטה",
    "שיקמה",
    "שכ אליהו",
    "שכ יפה נוף",
    "שכ יצחק נבון",
    "שכ יצחק שמיר",
    "שכ מרכז",
    "שכ נווה הדקל",
    "שכ נווה הנחל",
    "שכ נוף גלעד",
    "שכ נוף גנים",
    "שכ נוף קדומים",
    "שכ נוף שאן",
    "שכ צמרת",
    'שלד"ג',
    "שלום הגליל",
    "שמעון ויזנטל",
    "שמר נעמי",
    "שמש יצחק",
    "שמשון",
    "שקד",
    "שרת משה",
    "ששת הימים",
    "תאנה",
    "תדהר",
    "תוצרת הארץ",
    "תמיר מרדכי",
  ],
  "בית שמש": [
    "אב",
    "אבא נעמת",
    "אביב",
    "אביטל",
    "אביי",
    "אבן גבירול",
    "אדם יקותיאל",
    "אדר",
    "אהבת ישראל",
    "אהבת שלום",
    "אוהל יהושע",
    "אולפן המשטרה",
    "אור החיים",
    "אור שמח",
    "אורטל",
    "אזור התעשיה צפון",
    "אזור תעשיה מערבי",
    "אחיה השילוני",
    "אחיעזר",
    "אייר",
    "אלול",
    "אלון יגאל",
    "אליהו הנביא",
    "אליכין",
    "אלישע הנביא",
    "אלנקוה רפאל",
    "אלעזרי",
    "אמרי אמת",
    "אמרי נועם",
    "אסתר",
    "ארלוזורוב",
    "אשכול לוי",
    "אשר",
    "באר שבע",
    "בוסקילה יוסף",
    "ביאליק",
    "ביחר שמשון",
    "בית ג'מאל",
    "בית זולוטוב",
    "בית ישראל",
    "בית שמש",
    "בית שמש הותיקה",
    "בן איש חי",
    "בן אליעזר",
    "בן גוריון פולה",
    "בן גרא",
    "בן זאב יהודה",
    "בן עזאי",
    "בן צבי",
    "בן ציון אבא שאול",
    "בנאי יוסי",
    "בני דן",
    "בעל התניא",
    "בר אילן",
    "בר קפרא",
    "ברכת אברהם",
    "גבעת בן צבי",
    "גבעת ויצמן",
    "גבעת סביון",
    'גבעת שז"ר',
    "גבעת שרת",
    "גד",
    "גונן שמואל",
    "גור אריה",
    "גלעד",
    "גני יהודית",
    "גני שמש",
    "דבורה הנביאה",
    "דובר שלום",
    "דורי יעקב",
    "דליה",
    "דם המכבים",
    "דניאל",
    "דרך החיים",
    "דרך רבין יצחק",
    "דרכי איש",
    "דרכי אליעזר",
    "האגס",
    'האדמו"ר מבעלזא',
    "האדמור מרוז'ין",
    'האדר"ת',
    "האומן",
    "האורן",
    "האיריס",
    "האלה",
    "האפרסק",
    'האצ"ל',
    "הארבל",
    "הארז",
    "הארזים",
    "האתרוג",
    "הבנאי",
    "הבעל שם טוב",
    "הברוש",
    "הגאון מוילנא",
    "הגולן",
    "הגליל",
    "הגפן",
    "הדובדבן",
    "הדולב",
    "ההדס",
    "ההדר",
    "ההכשרה",
    "הושע הנביא",
    "הותיקים",
    "החבצלת",
    "החוזה מלובלין",
    "החלוצים",
    "החפץ חיים",
    "החצב",
    "החרובים",
    "החרוצים",
    "החרמון",
    "החרצית",
    "החרש",
    "היסמין",
    "היצירה",
    "הכלנית",
    "הלל",
    "המגיד ממעזריטש",
    'המהרי"ל דיסקין',
    "המורה יעקב",
    "המכתש",
    "המלאכה",
    "המלך דוד",
    "המסגר",
    "המעפילים",
    "המשלט",
    "הנביא צפניה",
    "הנורית",
    'הנצ"יב',
    "הנרקיס",
    "הנשיא",
    "הסביון",
    "הסוללה",
    "הסיגלית",
    "העונות",
    "העליה",
    "העמל",
    "הערבה",
    "הערמון",
    "הצאלה",
    "הצבע",
    "הצדיק משטפנשט",
    "הציפורן",
    "הר בשן",
    "הר גבנונים",
    "הר הכרמל",
    "הר המוריה",
    "הר מירון",
    "הר נבו",
    "הרב אברהם שפירא",
    "הרב איידלשטיין",
    "הרב אלישיב",
    "הרב ברוק",
    "הרב גריינימן",
    'הרב האדמו"ר החלוץ',
    "הרב הרצוג",
    "הרב יוסף עזרן",
    "הרב יעקב יוסף",
    "הרב יצחק כדורי",
    "הרב ישראל גרוסמן",
    "הרב ליפקוביץ",
    "הרב מבריסק",
    "הרב מנחם פרוש",
    "הרב מרדכי צמח אליהו",
    "הרב קוק",
    "הרב שלמה זלמן",
    "הרב שלמה לורניץ",
    "הרטוב",
    "הרצל",
    "הרקפת",
    'הרש"ר הירש',
    "השבעה",
    "השושן",
    "השיזף",
    "השיטה",
    "השקד",
    "התאנה",
    "התבור",
    "התעשיה",
    "התפוח",
    "וירג'יניה",
    "ורד",
    "ז'בוטינסקי",
    "זכרון יעקב",
    "זכריה הנביא",
    "חבקוק הנביא",
    "חברון",
    "חגי הנביא",
    "חזון איש",
    'חיד"א',
    "חלקת יהושע",
    "חצרות ערבי הנחל",
    "חשוון",
    "טבריה",
    "טבת",
    "יבנה",
    "ידין יגאל",
    "יהודה הלוי",
    "יהודה המכבי",
    "יואל הנביא",
    "יוכבד",
    "יונה בן אמיתי",
    "יונתן בן עוזיאל",
    "יחזקאל הנביא",
    "יצחק שדה",
    "ירושלים",
    "ירמיהו הנביא",
    "ישעיהו הנביא",
    "כביש 38",
    "ככר נוימן",
    "כסליו",
    "לב שמחה",
    "לוין אריה",
    "לנר דן",
    "לסקוב חיים",
    "מגן קלמן",
    "מורדי הגטאות",
    "מורי יעקב",
    "מחזיקי הדת",
    "מיטל",
    "מלאכי הנביא",
    "מנדלר אלברט",
    "מנוח",
    "מנור אהוד",
    "מנחת יצחק",
    "מעלה דוד",
    "מעלה הסיירים",
    "מעלה הצופים",
    "מעלה יאיר",
    'מעלות הרמ"א',
    "מעפילי אגוז",
    "מערבא",
    "מעשי חייא",
    "מצדה",
    'מצפה הרש"פ',
    "מקלף מרדכי",
    "מר עוקבא",
    "מרים הנביאה",
    "מרכז קליטה",
    "משה רבנו",
    "משטרת הרטוב",
    "משעול המתמיד",
    "נהר הירדן",
    "נהר הירקון",
    "נהרדעא",
    "נוה ספיר",
    "נועם אלימלך",
    "נופי אביב",
    "נופי הדר",
    "נחום הנביא",
    "נחל אוריה",
    "נחל איילון",
    "נחל אכזיב",
    "נחל אל-על",
    "נחל אלכסנדר",
    "נחל גילה",
    "נחל דולב",
    "נחל הבשור",
    "נחל הקישון",
    "נחל זוהר",
    "נחל זויתן",
    "נחל חבר",
    "נחל יעלה",
    "נחל לוז",
    "נחל לכיש",
    "נחל מאור",
    "נחל מטע",
    "נחל מיכה",
    "נחל משמר",
    "נחל נועם",
    "נחל נחשון",
    "נחל ניצנים",
    "נחל סנסן",
    "נחל עין גדי",
    "נחל ערוגות",
    "נחל פולג",
    "נחל פרת",
    "נחל קדרון",
    "נחל קטלב",
    "נחל רביבים",
    "נחל רזיאל",
    "נחל רמות",
    "נחל רפאים",
    "נחל שורק",
    "נחל שחם",
    "נחל שמשון",
    "נחל שניר",
    "נחל תמנע",
    "נחל תמר",
    "נחמיה",
    "ניסן",
    "נעמי שמר",
    "נפתלי",
    "נריה הנביא",
    "נתיב מאיר",
    "סולם יעקב",
    "סורא",
    "סחלב",
    "סיוון",
    "סיני",
    "סיתוונית",
    "סמ בן אליעזר",
    "סמ האלון",
    "סמ הגפן",
    'סמ ויצ"ו',
    "סמ עלית הנוער",
    "סמדר",
    "סתיו",
    "עזרא",
    "עידו הנביא",
    "עלית הנוער",
    "עמק הזיתים",
    "עקביא בן מהללאל",
    "פומפדיתא",
    "פסגות השבע",
    "פעמי משיח",
    "פרס ראובן",
    "צפת",
    "צריפים אדומים",
    "קארו יוסף",
    "קדושת אהרון",
    "קהילת יעקב",
    "קריית ארבע",
    "קרית אריה",
    "קרית בן אליעזר",
    "קרית החינוך",
    "קרית הרב ניסים",
    'קרית הרמ"א',
    "קרן היסוד",
    "ראובן",
    "רב אבדימי",
    "רב אבהו",
    "רב אמי",
    "רב אמימר",
    "רב אשי",
    "רב המנונא",
    "רב זביד",
    "רב זירא",
    "רב חנן",
    "רב כהנא",
    "רב מנשיא",
    "רב מרי",
    "רב עולא",
    "רב עמרם",
    "רב פפא",
    "רב שילא",
    "רב ששת",
    "רב תנחומא",
    "רבא",
    "רבי אלעזר",
    "רבי טרפון",
    "רבי יהודה הנשיא",
    "רבי יהושע",
    "רבי יוסי בן קיסמא",
    "רבי ינאי",
    "רבי ישמעאל",
    "רבי לויטס",
    "רבי מאיר בעל הנס",
    "רבי עקיבא",
    "רבי פנחס בן יאיר",
    "רבי צדוק",
    "רבי צדקה יהודה",
    "רבינא",
    "רביצקי",
    "רבן יוחנן בן זכאי",
    "רבנו חיים הלוי",
    "רויטל",
    "רות",
    "רזיאל דוד",
    "רחל המשוררת",
    'ריב"ל',
    "רימון",
    "ריש לקיש",
    'רמב"ם',
    "רמת בית שמש",
    "רמת בית שמש ב",
    'רמת לח"י',
    'רש"י',
    'רשב"ג',
    'רשב"י',
    "ש 160",
    "ש 144",
    "ש 116",
    "ש ותיקים",
    "ש חצרות",
    "ש משק עזר",
    "ש קוטג'",
    'ש רמת לח"י',
    "שאול המלך",
    "שבט",
    "שבטי ישראל",
    "שביל האשל",
    "שביל הגיחון",
    'שביל הל"ה',
    "שביל הנחש",
    "שביל התמרים",
    "שד בן גוריון",
    "שד הדקל",
    "שד נהר הדן",
    "שד נחל נובע",
    "שד נחל צאלים",
    "שד' האמוראים",
    "שועלי שמשון",
    "שכ הגפן",
    "שכ השחר",
    "שכ מחסיה",
    "שכ קרית חזון עובדיה",
    "שכ רמת התנאים",
    "שלמה המלך",
    "שמואל הנביא",
    "שמעון",
    "שער השמיים",
    "שפת אמת",
    "שקמה",
    "תאשור",
    "תדהר",
    "תחנת הרכבת",
    "תכלת מרדכי",
    "תלמוד בבלי",
    "תלמוד ירושלמי",
    "תמוז",
    "תמר",
    "תפארת משה",
    "תפארת נפתלי",
    "תשרי",
  ],
  "בית שערים": [
    "בית שערים",
    "העמק",
    "העץ הגדול",
    "הפרסה",
    "משעול הגנים",
    "שביל החלב",
    "שכ גבעת הכלניות",
    "שכ הגורן",
    "שכ הפרדס",
  ],
  "בית שקמה": ["בית שקמה"],
  "ביתן אהרן": [
    "בית יערי",
    "ביתן אהרן",
    "בת חן",
    "דרך האילנות",
    "דרך הבאר",
    "דרך המשתלה",
    "האשל",
    "ההדרים",
    "הצבר",
    "התפוז",
    "ככר המייסדים",
    "סמ הבנים",
    "שדרת הפיקוסים",
  ],
  "ביתר עילית": [
    "אבן עזרא",
    'אדמו"רי באיאן',
    "אדמור\"י ויז'ניץ",
    "אוהב ישראל",
    "אוהל שרה",
    "אור אברהם",
    "אור החיים",
    "אורחות אהרון",
    "אזור תעשיה הערבה",
    "אזור תעשייה הערבה",
    "איגרות משה",
    "אלעזר המודעי",
    "אם הבנים",
    "אמרי סופר",
    "אש קודש",
    "באבא אלעזר",
    "באר יעקב",
    "ביתר עילית",
    "בן איש חי",
    "בנין דוד",
    "בעל התניא",
    "גבעה A",
    "דברי חיים",
    "דון יצחק אברבנאל",
    "דרך בנימין הצדיק",
    'דרך הרמ"ז',
    "דרכי איש",
    'האדמו"ר מבוסטון',
    'האדמו"ר מלעלוב',
    'האדמו"ר מסלונים',
    "האדמו\"ר מרוז'ין",
    "הבבא סאלי",
    "הבעל שם טוב",
    'הגר"א',
    "הדף היומי",
    "הדר ביתר",
    "החוזה מלובלין",
    'החיד"א',
    "הטוב והמטיב",
    'המבי"ט',
    "המגיד ממעזריטש",
    'המהרי"ץ',
    'הר"ן',
    "הרב אבא שאול",
    "הרב אוירבך",
    "הרב אטון",
    "הרב אלישיב",
    "הרב אריאלי יצחק",
    "הרב בנדר",
    "הרב ברוידא",
    "הרב ברוק בן-ציון",
    "הרב ברים",
    "הרב כדורי",
    "הרב מוצפי יעקב",
    "הרב מסלנט",
    "הרב נאה חיים",
    "הרב עטיה",
    "הרב צדקא יהודה",
    "הרב קנייבסקי",
    "הרב קעניג",
    "הרב רוזובסקי",
    "הרב רייטפארט יצחק",
    "הרב רפול",
    "הרב שבדרון שלום",
    "הרב שך",
    "הרבי מזוועהיל",
    "הרבי מליובאוויטש",
    "הרבי מנדבורנה",
    "הרבי מקרעטשניף",
    'הרמ"ק',
    'הרמב"ן',
    "התעוררות תשובה",
    'חב"ד',
    "חושן משפט",
    "חזון איש",
    "חיי יצחק",
    "חכמת אליעזר",
    "חכמת חיים",
    "חלקת יואב",
    "חפץ חיים",
    'חת"ם סופר',
    "טוב דעת",
    "יביע אומר",
    "יוחנן בן זכאי",
    "יורה דעה",
    "יסוד העבודה",
    "ישועות משה",
    "ישיבת מיר",
    "ישמח ישראל",
    "כנסת יחזקאל",
    "כנסת מרדכי",
    "כף החיים",
    "מאור עיניים",
    "מבוא מרגלית",
    "מעלות בת שבע",
    "מעלות גאוני בריסק",
    "מעלות דמשק אליעזר",
    "מעלות הרב ליפקוביץ'",
    "מעלות הרב מטשכנוב",
    "מעלות הרב צדוק",
    "מעלות הרי יהודה",
    "מעלות חיה מושקא",
    "מעלות חקר ועיון",
    "מעלות יעל",
    "מעלות ירושלים",
    "מעלות כתר תורה",
    "מעלות מנחת יהודה",
    'מעלות פאר הריב"ש',
    "מעלות רבי אהרון",
    "מעלות רבי הלל",
    "מעלות שושנה",
    'מפעל הש"ס',
    "נעם אלימלך",
    "נתיבות שלום",
    "עקבי אבירים",
    "פחד יצחק",
    "פנים מאירים",
    "צמח צדק",
    "צפנת פענח",
    "קארו יוסף",
    "קדושת לוי",
    "קדושת ציון",
    "רבי נחמן מברסלב",
    "רבי עקיבא",
    "רבי שבזי שלום",
    "שבט הלוי",
    'שד דרך האר"י',
    "שדה חמד",
    "שכ האתרוג",
    "שכ הגפן",
    "שכ הדס",
    "שכ הדקל",
    "שכ התמר",
    "שלום רב",
    "שמן למאור",
    "שער לוי",
    "שפע חיים",
    "תומכי תמימים",
  ],
  בלפוריה: [
    "בית ראשון במולדת",
    "בלפוריה",
    "הגפן",
    "הזית",
    "החיטה",
    "הרימון",
    "השעורה",
    "התאנה",
    "התמר",
    "שבעת המינים",
  ],
  "בן זכאי": [
    "בן זכאי",
    "האורן",
    "האלה",
    "האתרוג",
    "הברוש",
    "הגפן",
    "ההדס",
    "הזית",
    "החרוב",
    "הצאלון",
    "הרותם",
    "הרימון",
    "התאנה",
    "התמר",
  ],
  "בן עמי": [
    "בן עמי",
    "דרך פרחי הבר",
    "שביל החרציות",
    "שביל הנרקיסים",
    "שביל הסביונים",
    "שביל הרקפות",
  ],
  "בן שמן (כפר נוער)": ["בן שמן (כפר נוער)"],
  "בן שמן (מושב)": [
    "בן שמן (מושב)",
    "דרך הגפן",
    "דרך הזית",
    "דרך הרימון",
    "דרך התמר",
    "החיטה",
    "השעורה",
    "התאנה",
  ],
  "בני ברק": [
    "אבו חצירא ישראל",
    "אבטליון",
    "אביעד",
    "אבן גבירול",
    "אבן עזרא",
    "אבן שפרוט",
    "אבני נזר",
    "אברבנאל",
    "אדמורי אלכסנדר",
    "אדמורי שאץ",
    "אהבת שלום",
    "אהרונוביץ ראובן",
    "אהרונסון",
    "אונקלוס",
    "אוסישקין",
    "אור החיים",
    "אורליאן",
    "אזור תעשיה צפון",
    "אחיה השילוני",
    "אחיעזר",
    "אלחריזי",
    "אליהו הנביא",
    "אלישע",
    "אלקבץ",
    "אלקלעי",
    "אלרום",
    "אלשיך",
    "אמרי ברוך",
    "אמרי חיים",
    "אנילביץ",
    "אסתר המלכה",
    "אפשטיין פנחס",
    'אצ"ל',
    "אשל אברהם",
    "באר מים חיים",
    "בארי",
    "בגין מנחם",
    "בגנו משה",
    "בורוכוב",
    'בי"ח מעיני הישועה',
    "ביאליק",
    'ביל"ו',
    "ביריה",
    "בירנבוים",
    "בית הלל",
    "בית חורון",
    "בית יוסף",
    "בית שמאי",
    "בן גוריון דוד",
    "בן דוד אברהם",
    "בן זומא",
    "בן זכאי",
    "בן יוסף שלמה",
    "בן נון",
    "בן נריה ברוך",
    "בן סירא",
    "בן עזאי",
    "בן פתחיה",
    "בן שטח",
    "בני ברק",
    "בנימין אברהם",
    "בעל התניא",
    "בעל שם טוב",
    "בר אילן",
    "בר יוחאי",
    "בר כוכבא",
    "ברויאר",
    "ברוריה",
    "ברטנורה",
    "ברנדשטטר",
    "בתי אבות",
    "בתי גולדשטין",
    "גאוני בריסק",
    "גבורי ישראל",
    "גבעת סוקולוב",
    "גבעת פנחס",
    "גבעת רוקח",
    "גוטמכר",
    "גולומב",
    "גורדון",
    "גן העצמאות",
    "גניחובסקי",
    "גרונר",
    "גרינברג",
    "גרשטנקורן",
    'ד"ר ברט',
    "דבורה הנביאה",
    "דוד המלך",
    "דון יוסף הנשיא",
    "דונולו",
    "דמשק אליעזר",
    "דנגור",
    "דניאל",
    "דקר",
    "דרך אם המושבות",
    "דרך הנשיא הראשון",
    'האדמו"ר מבלז',
    'האדמו"ר מגור',
    "האדמו\"ר מויז'ניץ",
    'האדמו"ר מוישוי',
    'האדמו"ר מצנז',
    'האדמו"ר מקוצק',
    'האדמו"ר מראדזמין',
    "האדמו\"ר מרוז'ין",
    "האדמור מנדבורנא",
    'האדמורי"ם מספינקא',
    "הבנים",
    "הגליל",
    'הגר"א',
    "הדסים",
    "ההגנה",
    "הוז דב",
    "הושע",
    "החלוצים",
    "החרמון",
    "החשמונאים",
    "הילדסהיימר",
    "הירדן",
    "הירקון",
    "הירש ברוך",
    "הלוחמים",
    "הלפרין",
    'המהר"ל',
    "המכבים",
    "המר זבולון",
    "הנגב",
    "העליה השניה",
    "הפלוגה הדתית",
    'הפלמ"ח',
    "הצנחנים",
    "הקישון",
    "הר הבית",
    "הר נבו",
    "הר סיני",
    "הראשונים",
    "הרב אברמסקי",
    "הרב בארון קלמן",
    "הרב בלוי",
    "הרב בן יעקב",
    "הרב ברוט",
    "הרב גולד",
    "הרב גרוסברד",
    "הרב דסלר",
    "הרב הירש",
    "הרב וסרמן אלחנן",
    "הרב ורנר",
    "הרב זוננפלד יוסף",
    "הרב זיטניצקי",
    "הרב זמבא מנחם",
    "הרב חדד שאול",
    "הרב טולידנו",
    "הרב יברוב משה",
    "הרב יוסף חיים",
    "הרב כהן שלמה",
    "הרב כהנמן",
    "הרב לנדא יעקב",
    "הרב מיודסר",
    "הרב מימון",
    "הרב מלצר",
    "הרב מנצור בן שמעון",
    "הרב מצליח",
    "הרב משה זאב פלדמן",
    "הרב נסים יצחק",
    "הרב סורוצקין",
    "הרב עוזיאל",
    "הרב עטיה עזרה",
    "הרב פוברסקי",
    "הרב פרדו משה",
    "הרב פתאיה",
    "הרב קוטלר",
    "הרב קוק",
    "הרב קליש",
    "הרב ראט משולם",
    "הרב רבינוב",
    "הרב רבינוביץ אריה",
    "הרב רוזובסקי",
    "הרב שטרסר",
    "הרב שך",
    "הרב שלום שלום",
    "הרב שמידמן יצחק",
    "הרב שמש דוד",
    "הרב שפירא",
    "הרב שר",
    "הרי גולן",
    "הרצוג",
    "הרצל",
    "השומר",
    "השלושה",
    "השניים",
    "השר אלון יגאל",
    "השריונאים",
    "התנאים",
    "וגמן מ ב",
    "וולפסון",
    "וילקומירר",
    "וינברג שמואל",
    "וינרב",
    "ועד ארבע ארצות",
    "ז'בוטינסקי",
    "זכריה",
    "זרובבל",
    "חבקוק",
    "חברון",
    "חגי",
    "חולדה הנביאה",
    "חזון איש",
    "חי טייב",
    'חיד"א',
    'חידושי הרי"ם',
    "חנקין",
    "חפץ חיים",
    'חרל"פ',
    "חשב סופר",
    'חת"ם סופר',
    "טבריה",
    "טכורש",
    "טל חיים",
    "טרומפלדור",
    "יגאל",
    "יהודה הלוי",
    "יהודה הנשיא",
    "יהודית",
    "יהושע",
    "יואל",
    "יוחנן הסנדלר",
    "יונה הנביא",
    "יונתן",
    "יחזקאל",
    "יעבץ",
    "יעל",
    "יפה",
    "יצחק מאיר הכהן",
    "יצחק נפחא",
    "ירושלים",
    "ירמיהו",
    "ישיבת בית מאיר",
    "ישיבת ויז'ניץ",
    "ישמח משה",
    "ישעיהו",
    "ישראל מסלנט",
    "ככר האחד עשר",
    "ככר האחים מאיר",
    "ככר התעשיה",
    "כנרת",
    "לוי יצחק",
    "לוין מרישא",
    'לח"י',
    "ליפשיץ",
    "לנדאו חיים",
    "מבצע קדש",
    'מהרש"ל',
    "מוהליבר",
    "מחזיקי הדת",
    "מחנה דב",
    "מיכה",
    "מינץ",
    "מירון",
    "מכלוף דוד",
    "מלאכי",
    "מלבן",
    "מנחם",
    "מעיינה של תורה",
    "מעלות הדף היומי",
    "מעלות זלמן מילשטיין",
    "מעלות יהודית וויל",
    "מעלות מאיר",
    "מעלות מרגלית",
    "מפרץ שלמה",
    "מצדה",
    "מרים הנביאה",
    "מרכז בעלי מלאכה",
    "משה אריה",
    "משפחת מקובר",
    "מתחם הרכבת",
    "מתתיהו",
    "נויפלד",
    "נועם אלימלך",
    "נורדאו",
    "נורוק",
    "נחום",
    "נחל שורק",
    "נחמיה",
    "נחמן מברסלב",
    "נחשוני יהודה",
    "נידוני בגדד",
    'ניל"י',
    "ניסנבוים",
    "נתן הנביא",
    "סומך",
    "סוקולוב",
    "סירוקה שמעון",
    "סמ אפרים",
    "סמ במברגר",
    "סמ גוטליב",
    "סמ הבנים",
    "סמ החייל",
    "סמ יבנה",
    "סמ מנשה",
    "סמ משה יוסף",
    "סמ סירקין",
    "סמ רחל",
    'סמטת אז"ר',
    'סמטת האר"י',
    "סנש",
    "סעדיה גאון",
    "סרור דנה",
    "עבודת ישראל",
    "עובדיה",
    "עזרא",
    "עלי הכהן",
    "עמוס",
    "עמי",
    "עמיאל",
    "עמק יזרעאל",
    "עמרם גאון",
    "פדרמן דוזיה",
    "פוקס אהרון דב",
    "פורצי כלא עכו",
    "פנקס",
    "פרדס כץ",
    "פרל חיים",
    "צבי",
    "צייטלין",
    "צירלסון",
    "צמח צדיק",
    "צפניה",
    "צפת",
    "קבוץ גלויות",
    "קדושי גורליץ",
    "קדושי לוב",
    "קדושת יום טוב",
    "קהילות יעקב",
    "קובלסקי",
    "קושניר",
    "קלישר",
    "קפלנסקי",
    "קרית הרצוג",
    "קרית יואל",
    "קרית נדבורנא",
    'ראב"ד',
    "רב אמי",
    "רב אסי",
    "רב אשי",
    "רב האי גאון",
    "רבי אבא",
    "רבי אליעזר",
    "רבי חייא",
    "רבי יוסי",
    "רבי ישמעאל",
    "רבי מאיר",
    "רבי עקיבא",
    "רבינא",
    "רבן גמליאל",
    "רבנו אשר",
    "רבנו בחיי",
    "רבנו תם",
    'רד"ק',
    "רוזנהיים",
    "רזיאל",
    "רח 282",
    "רח 333",
    "רח 293",
    "רח 294",
    "רח 335",
    "רח 435",
    "רח 295",
    "רח 338",
    "רח 288",
    "רחביה",
    "רחבת קלמר",
    "רייך אריה",
    "ריינס",
    "רימון",
    'רלב"ג',
    'רמב"ם',
    'רמב"ן',
    'רמח"ל',
    "רמת אהרון",
    "רמת אלחנן",
    "רמת דוד",
    'רש"י',
    'רשב"ם',
    "ש אגי",
    "ש גורדון",
    "ש גני גד",
    "ש הפומז",
    "ש חזון איש",
    "ש חסכון",
    "ש טישלר",
    "ש לומיר",
    "ש משכנות",
    "ש נוה אחיעזר",
    "ש סגל",
    "ש עובדים",
    "ש עממי",
    "ש פוניבז'",
    "ש צהל",
    "ש ק הישיבה",
    "ש רוגוזין",
    "ש תל גבורים",
    "שאול המלך",
    "שבזי",
    "שבטי ישראל",
    "שד אביי ורבא",
    "שד אינשטיין",
    'שד האדמו"ר מטאלנא',
    'שד הנצי"ב',
    "שד הרב לוין אריה",
    "שד כפר עציון",
    "שד רבי טרפון",
    "שד רמז",
    'שד"ל',
    "שדה יצחק",
    'שח"ל',
    "שכ אבו לבן",
    "שכ ה",
    "שכ הפועל המזרחי א'",
    "שכ הפועל המזרחי ב'",
    "שכ הפועל המזרחי ג'",
    "שכ הר שלום",
    "שכ ויז'ניץ",
    "שכ יסודות",
    "שכ נווה און",
    "שכ פסגת רחל",
    "שכון ו",
    'של"ה',
    "שלוש השעות",
    "שלמה המלך",
    "שמואל הנביא",
    "שמחוני",
    "שמעון הצדיק",
    "שמעיה",
    "שמשון הגבור",
    "שניר",
    "שפת אמת",
    "ששת הימים",
    "תורה ומנוחה",
    "תורת חיים",
    "תל גיבורים",
    'תרפ"ד',
  ],
  "בני דקלים": [
    "אהבת ישראל",
    "בני דקלים",
    "גאולה וישועה",
    "גפן",
    "דרך גוש קטיף",
    "דרך הדרום",
    "האגם",
    "האמונה",
    "האשכול",
    "הבציר",
    "הזמורה",
    "הכלנית",
    "הכרם",
    "העינב",
    "הצבעוני",
    "הרקפת",
    "השקד",
    "התירוש",
    "התמר",
    "חיי עולם",
    "יפה נוף",
    "כיסופים",
    "נחלת אבות",
    "נצח ישראל",
    "סמטת הכותל",
    "סמטת ציון",
    "קריה נאמנה",
    "שדרות נווה דקלים",
    "שירת הים",
    "תורת חסד",
  ],
  "בני דרום": [
    "איתנים",
    "בני דרום",
    "בעלי מלאכה",
    "דרך הים",
    "האורן",
    "האלה",
    "האלון",
    "האסיף",
    "הבציר",
    "הברוש",
    "הגדיד",
    "הגפן",
    "הדקליה",
    "הזית",
    "החצב",
    "החריש",
    "החרצית",
    "הכלנית",
    "המסיק",
    "הצבעוני",
    "הקטיף",
    "הראשונים",
    "הרימון",
    "השדה",
    "השחר",
    "השיטה",
    "השיקמה",
    "השקד",
    "התמר",
    "התקוה",
    'יובל הכ"ה',
    'כ"ב באדר',
    "מורשה",
    "מעלה החאן",
    "משעול התאנים",
    "נתיבות",
    "שדרת האשלים",
    "שיר המעלות",
  ],
  "בני דרור": [
    "אודם",
    "אלון",
    "אלמוגן",
    "ארבל",
    "בני דרור",
    "גלבוע",
    "דרך הדורות",
    "דרך הדרור",
    "האורן",
    "האקליפטוס",
    "האשל",
    "הברוש",
    "הדס",
    "הדר",
    "הרדוף",
    "השיטה",
    "זית",
    "חרוב",
    "כרמל",
    "מתחם קניון דרורים",
    "קרית חינוך דרור",
    "רימון",
    "שניר",
    "תאנה",
    "תבור",
    "תמנע",
  ],
  "בני יהודה": ["בני יהודה"],
  "בני נצרים": ["בני נצרים"],
  "בני עטרות": [
    "בני עטרות",
    "דרך האקליפטוס",
    "דרך הבארות",
    "דרך הכרמים",
    "דרך הפרחים",
    "דרך הראשונים",
    "הגפן",
    "הזית",
    "החיטה",
    "הרימון",
    "התאנה",
    "התמר",
  ],
  'בני עי"ש': [
    "בגין מנחם",
    'בני עי"ש',
    "גבורי ישראל",
    "גני הכפר",
    "גני טל",
    "דרך הנצחון",
    "האורן",
    "האיריסים",
    "האלה",
    "האלון",
    "הגפן",
    "הדס",
    "הדקל",
    "הורד",
    "הזית",
    "החיטה",
    "החצב",
    "הכהנים",
    "הכלניות",
    "העליה",
    "הצלף",
    "הרב אהרון דנין",
    "הרב יחיא אלקשמה",
    "הרימון",
    "הרקפת",
    "השעורה",
    "השקד",
    "השקמה",
    "התאנה",
    "התפוח",
    "זנדני משה",
    "כיכר יחיא ענאקי",
    "לילך",
    "נאות דליה",
    "נווה אורנים",
    "סמ בגין",
    "סמ דגן",
    "סמ זנדני",
    "סמ סיגלית",
    "סמ קוסמת",
    "סמ שיבולת",
    "סמ שיפון",
    "סמ תבואה",
    "סמ תמר",
    "סמטת תירוש",
    "ערבי נחל",
    "פנינת טל",
    "צאלה",
    "רבין יצחק",
    "תמר",
  ],
  "בני ציון": [
    "אגוז",
    "אנשי בראשית",
    "בני ציון",
    "דרך השדות",
    "דרך השלולית",
    "הלימון",
    "המחרשה",
    "המנים",
    "הנחל",
    "התלתן",
    "חורשת האורנים",
    "חורשת האקליפטוס",
    "חמציץ",
    "חצב",
    "חרמש",
    "חרצית",
    "לוטוס",
    "לוטם",
    "מגל",
    "מרווה",
    "נענע",
    "סחלב",
    "סייפן",
    "פרג",
    "צאלון",
    "קורנית",
    "רימונים",
    "שביל קליפות התפוזים",
    "שיזף",
    "תורמוס",
  ],
  "בני ראם": [
    "אורן",
    "בני ראם",
    "האירוס",
    "הברוש",
    "הגפן",
    "הדקל",
    "ההדס",
    "הורד",
    "הזית",
    "החרוב",
    "הטופח",
    "היסמין",
    "הכלנית",
    "הלילך",
    "הנורית",
    "הסיגלית",
    "העירית",
    "הפרג",
    "הצאלון",
    "הקורנית",
    "הרקפת",
    "השיטה",
    "כליל החורש",
    "מרווה",
  ],
  בניה: ["בניה"],
  "בנימינה-גבעת עדה": [
    "אדמונית החורש",
    "אזור תעשיה רכבת",
    "בית האריזה",
    "בנימינה",
    "בנימינה-גבעת עדה",
    "בריכת המים",
    "גבעת עדה",
    "גבעת עדה הרחבה",
    "גני בנימינה",
    'דרך בנימינה ג"ע',
    'דרך הברושים ג"ע',
    "דרך המסילה",
    "דרך העצמאות",
    'דרך ניל"י',
    'דרך פיק"א',
    "דרך רגבים גבעת עדה",
    "האגוז",
    "האגס גבעת עדה",
    "האגסים",
    "האופק",
    "האורן",
    "האורנים גבעת עדה",
    "האיכר",
    "האירוס",
    "האירית",
    "האלה גבעת עדה",
    "האלון",
    "האמן",
    "האסיף",
    "האפיק",
    "הארז",
    "הארזים גבעת עדה",
    "האשכול גבעת עדה",
    "האשל",
    "הבוסתן",
    "הבושם",
    "הבזלת",
    "הבציר",
    "הברוש",
    "הגבורים",
    "הגביע",
    "הגבעה גבעת עדה",
    "הגדיש",
    'הגדנ"ע',
    "הגומא גבעת עדה",
    "הגורן",
    "הגזית",
    "הגיא",
    "הגנים",
    "הגפן",
    "הגפנים",
    "הגת",
    "הדגן",
    "הדולב",
    "הדפנה גבעת עדה",
    "הדקל",
    "הדקלים",
    "הדר גבעת עדה",
    "הדרור",
    "ההגנה",
    "ההדס",
    "ההרדופים",
    "הזית",
    "החבצלת",
    "החוטמית",
    "החורש",
    "החורשים",
    "החיטה",
    "החיטים",
    "החלמוניות",
    "החרוב",
    "החרובים",
    "החרצית",
    "החרצית גבעת עדה",
    "החרש",
    "הטחנה",
    "היובל",
    "היוגב",
    "היין",
    "היסמין",
    "היער גבעת עדה",
    "היקב",
    "היקינטון גבעת עדה",
    "הככר",
    "הכלנית",
    "הכרכום",
    "הכרם",
    "הכרמל",
    "הלבונה גבעת עדה",
    "הלוטם",
    "הליבנה גבעת עדה",
    "המגל",
    "המורג",
    "המורה",
    "המיסדים",
    "המלאכה",
    "המעיין",
    "המרוה",
    "המרחב",
    "הנביאים",
    "הנדיב",
    "הנופר גבעת עדה",
    "הנורית",
    "הנחל",
    "הניצנים",
    "הנרקיס",
    "הנשיא",
    "הנשר",
    "הסביון",
    "הסביון גבעת עדה",
    "הסחלב",
    "הסיגלית",
    "הסמדר",
    "הספיר",
    "העבודה",
    "העומר גבעת עדה",
    "העינב",
    "הערבה",
    "הערבות",
    "הפועל",
    "הפלג",
    "הפרדס",
    "הצאלון",
    "הציפורן",
    "הקורל",
    "הקטיף",
    "הקמה גבעת עדה",
    "הקציר",
    "הראשונים גבעת עדה",
    "הרדוף",
    "הרופא",
    "הרימון",
    "הרימונים",
    "הרקפת",
    "השדה",
    "השושנה",
    "השחף",
    "השחר",
    "השיבולים",
    "השיבולת",
    "השיזף",
    "השיטה",
    "השיש",
    "השניים",
    "השסק גבעת עדה",
    "השעורה",
    "השעורים",
    "השקד",
    "השריג",
    "התאנה",
    "התבואה",
    "התירוש",
    "התלתן",
    "התמסח",
    "התמר",
    "התפוזים",
    'ח"י גבעת עדה',
    "חגי",
    "טיילת שוני",
    "ימי בנימינה",
    "יפה נוף גבעת עדה",
    "כליל החורש",
    "כרמי בנימינה",
    'מכון אור שמח ג"ע',
    "מע בנימינה",
    "מעלה הצבר גבעת עדה",
    "מצודת שוני",
    "מרכז קליטה גבעת עדה",
    'משעול האלומה ג"ע',
    'משעול החמנית ג"ע',
    "נחלת בנימינה",
    "נחלת ז'בוטינסקי",
    "סיני",
    "סמ האיריס גבעת עדה",
    "סמ הדגנים",
    "סמ הדגנית גבעת עדה",
    "סמ החבצלות",
    "סמ הכלניות",
    "סמ הכרמים",
    "סמ הנוריות",
    "סמ הנרקיסים",
    'סמ הסיתוונית ג"ע',
    "סמ העירית גבעת עדה",
    'סמ הפעמונית ג"ע',
    "סמ הצבעוני גבעת עדה",
    "סמ הרקפות",
    "סמ חצב גבעת עדה",
    "סמטת החרוב",
    "עץ האגוז",
    "עץ האלון",
    "עץ הזית",
    "עץ השקד",
    "עץ התאנה",
    "פירות ההדר",
    "פריחת ההדרים",
    "פריחת הסמדר",
    "קרן היסוד",
    "רויטל גבעת עדה",
    "רותם",
    "ש אילנות גבעת עדה",
    "ש גבעת חן",
    "ש חדש",
    "ש חדש גבעת עדה",
    "ש יוספטל גבעת עדה",
    "ש נוה עובד",
    "ש עובדים",
    "ש עמידר",
    'ש עמידר ג"ע',
    "ש עממי",
    "שביל אל הגן",
    "שביל האשכולית",
    "שביל האתרוג",
    "שביל החושחש",
    "שביל החלב",
    "שביל החלמונית",
    "שביל הכרמלית",
    "שביל הלימון",
    "שביל המשתלה",
    "שביל הנוטע",
    "שביל הנוער",
    "שביל הסתונית",
    "שביל העסיס",
    "שביל הפומלית",
    "שביל הקליפים",
    "שביל התורמוס",
    "שביל התפוז הסיני",
    "שביל ניחוח הפרי",
    "שביל קליפות התפוזים",
    "שביל תפוח הזהב",
    "שכ גב הפועל",
    'שכ הצמחונים ג"ע',
    "שכ הרכבת",
    "שכ ורבורג",
    "שכ ז'בוטינסקי",
    "שכ זרעוניה",
    "שכ יעקב",
    "שכ ישורון",
    "ששת הימים",
  ],
  'בסמ"ה': [
    "אבו מדור",
    "אבן אלהייתם",
    "אבן סינא",
    "אחמד שוקי",
    "אל מלעב",
    "אל עין",
    "אל תקוא",
    "אלאחוט",
    "אלאקסא",
    "אלבטימאת",
    "אלביאדר",
    "אלבסאתין",
    "אלבראק",
    "אלבריד",
    "אלברקאן",
    "אלג'אמע",
    "אלג'זירה",
    "אלגדראן",
    "אלגולאן",
    "אלואד",
    "אלופאא",
    "אלזיתון",
    "אלח'ליל",
    "אלחג'אז",
    "אלחף",
    "אלחרייה",
    "אלטוילה",
    "אליאסמין",
    "אלירמוק",
    "אלכוארזמי",
    "אלכלה",
    "אלכנייר",
    "אלכפרין",
    "אלמחבה",
    "אלמרוה",
    "אלנג'אח",
    "אלניל",
    "אלסדיק",
    "אלסדרי",
    "אלסלאם",
    "אלסלטאן",
    "אלסעאדה",
    "אלספאא",
    "אלעמאיר",
    "אלפארוק",
    "אלפרוש",
    "אלקאדסיה",
    "אלקדס",
    "אלקלעה",
    "אלרבידא",
    "אלרוחה",
    "אלרחמה",
    "אלשאם",
    "אלתות",
    "אלתסאמוח",
    "אם יונס",
    "בגדאד",
    "ביירות",
    "בלאל בן רבאח",
    'בסמ"ה',
    "ברטעה",
    "דהרת חמד",
    "דמשק",
    "חוראא",
    "חלב",
    "טארק בן זיאד",
    "כלת אלבסבאס",
    "כלת חדאד",
    "מועאוויה",
    "מכה",
    "מרים בנת עמראן",
    "סלאח אל דין",
    "עין א-סהלה",
    "עלי בן אבי טאלב",
    "עליאא",
    "ראס אלעין",
    "רח 50",
    "רח 41",
    "רח 51",
    "רח 42",
    "רח 52",
    "רח 43",
    "רח 53",
    "רח 63",
    "רח 93",
    "רח 44",
    "רח 64",
    "רח 25",
    "רח 45",
    "רח 65",
    "רח 46",
    "רח 76",
    "רח 86",
    "רח 96",
    "רח 47",
    "רח 87",
    "רח 97",
    "רח 18",
    "רח 28",
    "רח 48",
    "רח 88",
    "רח 98",
    "רח 49",
    "רח 99",
    "תאנים",
  ],
  "בסמת טבעון": [
    "אבו בכר אלצדיק",
    "איבן סינא",
    "אל אמל",
    "אל ואדי",
    "אלבוסתאן",
    "אלבחר",
    "אלביאדר",
    "אלביאן",
    "אלבסאתין",
    "אלג'אר",
    "אלג'בל",
    "אלזהור",
    "אלזיתון",
    "אלכרום",
    "אלכרמה",
    "אלנהדה",
    "אלנזאז",
    "אלנח'יל",
    "אלנרג'ס",
    "אלסנדיאן",
    "אלפולאת",
    "אלרביע",
    "אלרומאן",
    "אלרנד",
    "אלשיח' נימר",
    "אלתין",
    "בית סומא",
    "בסמת טבעון",
    "דרך יצחק רבין",
    "היסמין",
    "זובידאת",
    "ח'אלד בן אלואליד",
    "ח'ואלד",
    "ח'רבה",
    "חלף",
    "מועד בן ג'בל",
    "מחמוד אלשיך",
    "משאיח סעדיה",
    "עומר בן אלח'טאב",
    "עלי בן אבי טאלב",
    "ראס אלואדי",
    "רחיים",
  ],
  בענה: [
    "אבו ח'רובה",
    "אל לקאיא",
    "אלח'לאל",
    "אלח'נקה",
    "אלכרם אלחמר",
    "אלסוואעד",
    "אלעריד",
    "אלקלעה",
    "בידר אלשוק",
    "בענה",
    "הכפר הישן",
    "המגרשים",
    "כרם אלדאר",
    "כרם נחלה",
    "ע'דארה",
    "קטעת אלג'אמע",
  ],
  בצרה: [
    "בצרה",
    "האורנים",
    "הבאר",
    "הגן",
    "הנחלים",
    "הנרקיס",
    "העליה",
    "הפרדס",
    "הפרחים",
    "הצופית",
    "הראשונים",
    "יונק הדבש",
    "כלנית",
    "מגדל המים",
    "נורית",
    "קבוץ גלויות",
    "רקפת",
  ],
  בצת: ["בצת"],
  בקוע: ["בקוע"],
  בקעות: ["בקעות"],
  "בר גיורא": ["בר גיורא", "הגפן", "הדקל", "הערבה", "הקטלב", "הרימון", "התמר"],
  "בר יוחאי": ["בר יוחאי"],
  ברוכין: [
    "ברוכין",
    "האדרת",
    "ההדר",
    "ההוד",
    "הזוהר",
    "החיל",
    "הנוי",
    "הנצח",
    "העוז",
    "הצבי",
    "הרון",
    "השבח",
    "השגב",
    "השיר",
    "התהילה",
  ],
  "ברור חיל": ["ברור חיל"],
  ברוש: ["ברוש"],
  ברכה: [
    "אביר יעקב",
    "אבן ישראל",
    "איתן",
    "ברכה",
    "ברכת יוסף",
    "הגלעד",
    "הדר",
    "הר גריזים",
    "חברון",
    "ירושלים",
    "כרמים",
    "מגדים",
    "מצפה שלם",
    "עלי עין",
    "צפנת",
    "קדם",
    "קרית הישיבה",
    "קשתי",
    "שערי ברכה",
    "תבואות",
  ],
  ברכיה: ["ברכיה"],
  ברעם: ["ברעם"],
  ברק: ["ברק"],
  ברקאי: ["ברקאי"],
  ברקן: [
    "אודם",
    "אלמוג",
    "אמנון ותמר",
    "אננס",
    "ברקן",
    "ברקת",
    "דרך ברקן",
    "האגס",
    "האורן",
    "האלה",
    "האלון",
    "הארז",
    "האשל",
    "הברוש",
    "הדגנית",
    "הדקל",
    "הורדים",
    "הזית",
    "החצבים",
    "היקינטון",
    "הכלנית",
    "הנורית",
    "הנרקיס",
    "הסיגלית",
    "הסיוון",
    "העירית",
    "הערבה",
    "הרדוף",
    "הרותם",
    "השושנים",
    "השקד",
    "התאנה",
    "התפוח",
    "חלמיש",
    "יהלום",
    "יסמין",
    "יערה",
    "מורן",
    "מעלה ישראל",
    "ספיר",
    "ענבר",
    "רימון",
    "שוהם",
    "שכ קרוואנים",
    "שנהב",
  ],
  ברקת: [
    "אודם",
    "אור המזרח",
    "ברקת",
    "הגפן",
    "הזית",
    "היהלום",
    "הכלנית",
    "הלבונה",
    "הספיר",
    "הפיקוס",
    "הפרחים",
    "הרב שלום יצחק",
    "התאנה",
    "חנה",
  ],
  "בת הדר": ["בת הדר"],
  "בת חן": [
    "בת חן",
    "גפן",
    "הדס",
    "זית",
    "ערבה",
    "רותם",
    "רימון",
    "תאנה",
    "תמר",
  ],
  "בת חפר": [
    "אורן",
    "אלה",
    "אשל",
    "בארי",
    "בית השיטה",
    "בית זית",
    "בית ניר",
    "בית קמה",
    "בית קשת",
    "בצת",
    "בקעות",
    "ברקן",
    "בת חפר",
    "גולן",
    "גלבוע",
    "גלעד",
    "גמלא",
    "דוחל",
    "דוכיפת",
    "דיה",
    "דרור",
    "חבצלת",
    "חיטה",
    "חיננית",
    "חלבלוב",
    "חלמונית",
    "חמציץ",
    "חצב",
    "חרצית",
    "שד הגליל",
    "שד הנגב",
    "שד הערבה",
    "שד השומרון",
    "שד השפלה",
    "שד השרון",
    "שד עמק חפר",
  ],
  "בת חצור": ["בת חצור"],
  "בת ים": [
    "אוסישקין",
    "אופיר",
    "אורט ישראל",
    "אזור התעשיה",
    "אזור חוף הים",
    "אחד העם",
    "אילת",
    "אימבר",
    "אלמליח",
    "אלתרמן",
    "אנילביץ",
    "אסירי ציון",
    'אצ"ל',
    "ארזים",
    "ארלוזורוב",
    "אש שלום",
    "אתרוג",
    "בורוכוב",
    "בזל",
    'בי"ח ממשלתי',
    "ביאליק",
    'ביל"ו',
    "בית וגן",
    "בכר ניסים",
    "בלפור",
    "בן יוסף שלמה",
    "בר אילן",
    "בר יהודה",
    "ברדיצ'ב אבא",
    "ברנר",
    "ברץ",
    "בת ים",
    "גאולים",
    "גבעתי",
    "גולומב",
    "גוש עציון",
    "גלנטי",
    "גנוסר",
    "גנם מתתיהו",
    "גנרל קניג פייר",
    "גריזים",
    'ד"ר כהן משה',
    'ד"ר ניר נחום',
    "דגניה",
    "דוגית",
    "דוד המלך",
    "דולפין",
    "דליה",
    "דניאל",
    "דנין יחזקאל",
    "דקר",
    "דרך אלון יגאל",
    "דרך בן גוריון",
    "דרך מנחם בגין",
    'האדמו"ר מבאבוב',
    "האורגים",
    "הבנים",
    "הבריגדה",
    "הגבורים",
    "הגבעות",
    "הגדוד העברי",
    "הגולן",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הגפן",
    "הדדי ציון שאול",
    "הדקל",
    "ההגנה",
    "ההסתדרות",
    "הוז דב",
    "הולצברג שמחה",
    "הזית",
    "החלוצים",
    "החרושת",
    "החשמונאים",
    "החשמל",
    "הים",
    "הירש",
    "הכרמל",
    'הל"א',
    "הלפר",
    "המיסדים",
    "המלאכה",
    "המעפילים",
    "המרכז",
    "המשוררת רחל",
    "הנביאים",
    "הסבוראים",
    "הסוללים",
    "העמדות",
    "העמל",
    "העמק",
    "העצמון",
    "הפועל",
    "הפטמן",
    "הפלדה",
    'הפלמ"ח',
    "הצורפים",
    "הצנחנים",
    "הצפירה",
    "הקוממיות",
    "הראשונים",
    "הרב אביעד",
    "הרב בר שאול",
    "הרב גביזון",
    "הרב הרצוג",
    "הרב לוזון",
    "הרב לוי",
    "הרב מימון",
    "הרב ניסנבוים יצחק",
    "הרב עוזיאל",
    "הרב צבי יהודה",
    "הרב קוק",
    "הרב קוקיס",
    "הרותם",
    "הרצל",
    "הרשת",
    "השבטים",
    "השומר",
    "השומרון",
    "השלושה",
    "השקמה",
    "השר ברזילי",
    "השר פנקס",
    "השר שפירא",
    "התבור",
    "התותחנים",
    "התחיה",
    "ויצמן",
    "וישינגרד ישראל",
    "ז'בוטינסקי",
    "ז'ילבר",
    "זמנהוף",
    "זרובבל",
    "חוף הסלע",
    'חי"ש',
    "חלמית",
    "חלפון",
    "חצור",
    "טבנקין",
    "טרומפלדור",
    "טשרניחובסקי",
    "יהודה הלוי",
    "יוחנן הסנדלר",
    "יוסף הנשיא",
    "יטבתה",
    "יצחק נפחא",
    "יצחק שדה",
    "יקואל מנחם",
    "יקינטון",
    "ירושלים",
    "ירקוני",
    "ישראל בן ציון",
    'כ"ט בנובמבר',
    "כביש גבול חולון",
    "כהן אלי",
    "כובשי החרמון",
    "ככר המגינים",
    "ככר המדינה",
    "ככר העצמאות",
    "ככר וינגייט",
    "כנור דוד",
    "כנרת",
    "כצנלסון ברל",
    "לויתן",
    'לח"י',
    "ליבורנו",
    "ליבסקינד",
    "ליואי אברהם",
    "לכיש",
    'מ"ג',
    "מבצע סיני",
    "מגידו",
    "מודיליאני",
    "מודיעין",
    "מוהליבר",
    "מוכרי הסיגריות",
    "מחרוזת",
    "מייזל זלמן",
    "מלון מרינה",
    "מלון סאן טון",
    "מנדלי מוכר ספרים",
    "מסיקה",
    "מסריק",
    "מע בת ים",
    "מעבר ישי",
    "מעונות ים",
    "מעלה השחרור",
    "מצדה",
    'מצודת בית"ר',
    "מרבד הקסמים",
    "מרגנית",
    "משעול אייל",
    "נגבה",
    "נויקלן",
    "נורדאו מקס",
    "נחייסי אליהו",
    "ניצנה",
    "נרקיס",
    "סולד הנרייטה",
    "סוקולוב",
    "סטרומה",
    "סמ האילנות",
    "סמ הורדים",
    "סמ הצפורנים",
    "סמ הראשונים",
    "סמ השושנים",
    "סמ השרון",
    "סמ ישראלס",
    "סמ מיסיסטרנו",
    "סמ נייגו",
    'סמ ניל"י',
    "סנה משה",
    "סנש חנה",
    "עגנון",
    "עדולם",
    "פיינשטיין",
    "פרופ' יואלי",
    "פרלשטיין",
    "פרנק אנה",
    "פרץ",
    "צ'רניאקוב",
    'צה"ל',
    "קבוץ גלויות",
    "קדושי לוצק",
    "קדושי קהיר",
    "קהילת סלונים",
    "קורצ'ק",
    "קלויזנר",
    "קנמון אהוד",
    "קרית באבוב",
    "קריתי",
    "קרן היסוד",
    "קרן קימת לישראל",
    "רבי בנימין",
    "רבינוביץ'",
    "רהב",
    "רוטשילד",
    "רזיאל דוד",
    "רח 271",
    "רייק חביבה",
    "רימון",
    'רמב"ם',
    "רמז",
    "רמת הנשיא",
    "רמת לוז",
    "רקפת",
    "ש העיריה",
    'ש הפוהמ"ז',
    "ש ותיקים",
    "ש עולה חדש",
    "ש עמידר",
    "ש רמות ים",
    "ש רמת יוסף",
    "שאול המלך",
    "שבזי",
    "שד העצמאות",
    "שד וילרבאן",
    "שד יוספטל גיורא",
    "שטרן",
    "שיבת ציון",
    "שינדלר אוסקר",
    "שכ הקרוונים",
    "שכ סחנה ג",
    "שכ סחנה ד",
    "שלום עליכם",
    "שלמה המלך",
    "שמעון הבורסקי",
    "שפרבר חיים",
    "שרת משה",
    "ששת הימים",
    "תל חי",
    "תנין",
  ],
  "בת עין": ["בת עין", "ישיבת בת עין", "מדרשת בת עין"],
  "בת שלמה": [
    "בת שלמה",
    "האפרסק",
    "הגפן",
    "הזית",
    "המייסדים",
    "המשמש",
    "הרימון",
    "השזיף",
    "השסק",
    "התאנה",
    "משעול הלימון",
    "משעול הצבר",
    "משעול התפוח",
  ],
  "ג'דיידה-מכר": [
    "אזור התעשייה",
    "אל מרג'",
    "ג'דיידה",
    "ג'דיידה-מכר",
    "גרעין הכפר-ג'דיידה",
    "הגרעין הישן-מכר",
    "השכונה הדרומית-מכר",
    "השכונה המערבית-מכר",
    "השכונה הצפונית-מכר",
    "מכר",
    "רח 1100",
    "רח 1010",
    "רח 1110",
    "רח 1020",
    "רח 1120",
    "רח 1030",
    "רח 1130",
    "רח 1040",
    "רח 1140",
    "רח 1050",
    "רח 1060",
    "רח 1070",
    "רח 1080",
    "רח 1090",
    "רח 1001",
    "רח 1101",
    "רח 1011",
    "רח 1111",
    "רח 1021",
    "רח 1121",
    "רח 1031",
    "רח 1131",
    "רח 1041",
    "רח 1141",
    "רח 1051",
    "רח 1061",
    "רח 1071",
    "רח 1081",
    "רח 1091",
    "רח 1002",
    "רח 1102",
    "רח 1012",
    "רח 1112",
    "רח 1122",
    "רח 1032",
    "רח 1132",
    "רח 1042",
    "רח 1142",
    "רח 1052",
    "רח 1062",
    "רח 1072",
    "רח 1082",
    "רח 1092",
    "רח 1003",
    "רח 1103",
    "רח 1013",
    "רח 1113",
    "רח 1023",
    "רח 1123",
    "רח 1033",
    "רח 1133",
    "רח 1043",
    "רח 1053",
    "רח 1063",
    "רח 1073",
    "רח 1083",
    "רח 1093",
    "רח 1004",
    "רח 1104",
    "רח 1014",
    "רח 1114",
    "רח 1024",
    "רח 1124",
    "רח 1034",
    "רח 1044",
    "רח 1054",
    "רח 1064",
    "רח 1074",
    "רח 1084",
    "רח 1094",
    "רח 1005",
    "רח 1105",
    "רח 1015",
    "רח 1115",
    "רח 1025",
    "רח 1125",
    "רח 1035",
    "רח 1045",
    "רח 1055",
    "רח 1065",
    "רח 1075",
    "רח 1085",
    "רח 1095",
    "רח 1006",
    "רח 1106",
    "רח 1016",
    "רח 1116",
    "רח 1026",
    "רח 1126",
    "רח 1036",
    "רח 1136",
    "רח 1046",
    "רח 1056",
    "רח 1066",
    "רח 1076",
    "רח 1086",
    "רח 1096",
    "רח 1007",
    "רח 1107",
    "רח 1017",
    "רח 1117",
    "רח 1027",
    "רח 1127",
    "רח 1037",
    "רח 1137",
    "רח 1047",
    "רח 1057",
    "רח 1077",
    "רח 1087",
    "רח 1097",
    "רח 1008",
    "רח 1108",
    "רח 1018",
    "רח 1118",
    "רח 1028",
    "רח 1128",
    "רח 1038",
    "רח 1138",
    "רח 1048",
    "רח 1058",
    "רח 1068",
    "רח 1078",
    "רח 1088",
    "רח 1098",
    "רח 1009",
    "רח 1109",
    "רח 1019",
    "רח 1119",
    "רח 1029",
    "רח 1129",
    "רח 1039",
    "רח 1139",
    "רח 1049",
    "רח 1059",
    "רח 1069",
    "רח 1079",
    "רח 1089",
    "רח 1099",
    "שיכונים דרומיים-מכר",
    "שיכונים מזרחיים-מכר",
    "שכ ברבור",
    "שכ דרומית-ג'דיידה",
    "שכ מזרח דרום-ג'דידה",
    "שכ מזרח דרומית-מכר",
    "שכ מזרחית-ג'דיידה",
    "שכ מערבית-ג'דיידה",
    "שכ צפונית-ג'דיידה",
  ],
  "ג'ולס": [
    "אלאמל",
    "אלאס'יל",
    "אלאע'ניאא",
    "אלארז",
    "אלבאבור",
    "אלבאנזין",
    "אלבאניאס",
    "אלבאשא",
    "אלבוסתאן",
    "אלבטיח'ה",
    "אלביארה",
    "אלבידר",
    "אלביר",
    "אלבלח",
    "אלבלסם",
    "אלבנא",
    "אלבנפסג'",
    "אלברק",
    "אלג'באל",
    "אלג'בל",
    "אלג'ורי",
    "אלג'ליל",
    "אלדיואן",
    "אלואדי",
    "אלואדי אלשמאלי",
    "אלוזיאת",
    "אלורד",
    "אלורור",
    "אלזהור",
    "אלזהר",
    "אלזית",
    "אלזיתון",
    "אלזקאק",
    "אלח'זאן",
    "אלח'יזראן",
    "אלח'יל",
    "אלח'לא",
    "אלח'לאל",
    "אלח'לה",
    "אלח'רבה",
    "אלח'רובה",
    "אלחואר",
    "אלחור",
    "אלחורה",
    "אלחורש",
    "אלחיאת",
    "אלחס'יד",
    "אלכרום",
    "אלכרז",
    "אלכרם",
    "אלכרמל",
    "אלמג'ד",
    "אלמוהרה",
    "אלמוח'תארה",
    "אלמוסתקבל",
    "אלמורג'אן",
    "אלמזראב",
    "אלמטל",
    "אלמידאן",
    "אלמנזול",
    "אלמנקוש",
    "אלמע'איר",
    "אלמע'ארה",
    "אלמעס'רה",
    "אלנג'מה",
    "אלנואר",
    "אלנורס",
    "אלנח'יל",
    "אלנח'ל",
    "אלנחאס",
    "אלנקאטה",
    "אלנרג'ס",
    "אלס'באר",
    "אלס'בר",
    "אלס'פא",
    "אלסהל",
    "אלסומאק",
    "אלסיוף",
    "אלסלאם",
    "אלסמא",
    "אלסמרא",
    "אלסנדיאן",
    "אלספאח",
    "אלסריס",
    "אלע'אר",
    "אלע'ורבאל",
    "אלע'זאל",
    "אלעאלי",
    "אלעיון",
    "אלעלם",
    "אלענאב",
    "אלענב",
    "אלענברה",
    "אלקובה",
    "אלרומאנה",
    "אלרוע'ב",
    "אלריאד'ה",
    "אלרפיף",
    "אלרפראף",
    "אלשביבה",
    "אלשורה",
    "אלשיח'",
    "אלשיח' עלי פארס",
    "אלשמאל",
    "אלשקרא",
    "אלשרוק",
    "אלתמר",
    "אלתנור",
    "ג'ולס",
    "ואדי חמרא",
    "ירכא",
    "כרם אלעדס",
    "מארוש",
    "מג'יזל",
    "ראס ח'רוב",
    "ראס קונבר",
    "רומאן",
    "תל גאד",
    "תלה",
  ],
  "ג'לג'וליה": [
    "ג'לג'וליה",
    "רח 10",
    "רח 110",
    "רח 140",
    "רח 150",
    "רח 160",
    "רח 170",
    "רח 1",
    "רח 131",
    "רח 141",
    "רח 151",
    "רח 2",
    "רח 112",
    "רח 122",
    "רח 132",
    "רח 152",
    "רח 162",
    "רח 172",
    "רח 113",
    "רח 133",
    "רח 153",
    "רח 104",
    "רח 134",
    "רח 144",
    "רח 154",
    "רח 164",
    "רח 105",
    "רח 125",
    "רח 135",
    "רח 155",
    "רח 165",
    "רח 106",
    "רח 126",
    "רח 136",
    "רח 156",
    "רח 166",
    "רח 186",
    "רח 127",
    "רח 137",
    "רח 167",
    "רח 87",
    "רח 108",
    "רח 128",
    "רח 158",
    "רח 88",
    "רח 169",
  ],
  "ג'נאביב (שבט)": ["ג'נאביב (שבט)"],
  "ג'סר א-זרקא": [
    "אבו בקר אלסדיק",
    "אבן חלדון",
    "אבן סינא",
    "אזור הים",
    "אל בחר",
    "אל לוז",
    "אלאסד",
    "אלביאדר",
    "אלביארה",
    "אלבריד",
    "אלהודהוד",
    "אלואדי",
    "אלזוקאק",
    "אלזור",
    "אלזיתון",
    "אלחרוב",
    "אלטואחין",
    "אלטנטורה",
    "אליאסמין",
    "אלכוארזמי",
    "אלכנסאא'",
    "אלכרמל",
    "אלמוארס",
    "אלמחג'ר",
    "אלמנארה",
    "אלמתנבי",
    "אלנהדה",
    "אלסאחה",
    "אלסדרה",
    "אלסמאר",
    "אלסראג'",
    "אלעין",
    "אלקודס",
    "אלקייסר",
    "אלקנאה",
    "אלראוודאת",
    "אלרמאן",
    "אלרמל",
    "אלשאטיא",
    "אלשאפיעי",
    "אלשומריה",
    "אלתמאסיח",
    "אנס בן מאלק",
    "בגדאד",
    "בלאל בן רבאח",
    "ג'בראן ח'ליל ג'בראן",
    "ג'ורת אלסלמון",
    "ג'סר א-זרקא",
    "חטין",
    "מכה",
    "מרכז הכפר",
    "סלאח אלדין",
    "עומר בן אלכטאב",
    "עות'מאן בן עפאן",
    "עלי בן אבי טאלב",
    "קריית אלסיאדין",
    "שג'רת אלדור",
    "שכ אלמוארס",
    "שכ אלרמל",
    "שכ אלשומריה",
    "שכונה דרומית",
    "שכונה צפונית",
  ],
  "ג'ש (גוש חלב)": ["ג'ש (גוש חלב)"],
  "ג'ת": [
    "ג'ת",
    "ג'ת-שכונה דרומית",
    "גרעין הכפר",
    "רח 10",
    "רח 20",
    "רח 1",
    "רח 11",
    "רח 2",
    "רח 12",
    "רח 3",
    "רח 13",
    "רח 4",
    "רח 14",
    "רח 5",
    "רח 15",
    "רח 6",
    "רח 16",
    "רח 7",
    "רח 17",
    "רח 8",
    "רח 18",
    "רח 9",
    "רח 19",
    "ש ימה",
    "שכון א",
    "שכון ב",
    "שכון ג",
    "שכון ד",
    "שכונה מערבית",
    "שכונה מערבית א",
    "שכונה צפונית א",
    "שכונה צפונית ב",
  ],
  "גאולי תימן": [
    "גאולי תימן",
    "גאולי תימן(שיכון)",
    "האלון",
    "הגפן",
    "הזית",
    "החרוב",
    "המייסדים",
    "הרימון",
    "התאנה",
  ],
  גאולים: [
    "אעלה בתמר",
    "גאולים",
    "דרך השדות",
    "האורן",
    "האילן",
    "האילנות",
    "הגפן",
    "הדקל",
    "ההדס",
    "ההרחבה",
    "ההשלמה",
    "הותיקים",
    "הפרחים",
    "השמיניה",
    "השקד",
    "ככר הזיתים",
  ],
  גאליה: [
    "אגוז",
    "אלון",
    "ארז",
    "ברוש",
    "גאליה",
    "דולב",
    "דקל",
    "צאלון",
    "רימון",
    "תאנה",
  ],
  גבולות: ["גבולות"],
  גבים: ["גבים"],
  גבע: ["גבע"],
  "גבע בנימין": [
    "אירוס",
    "אמנון ותמר",
    "אתרוג",
    "בשמת",
    "גבע בנימין",
    "האגוז",
    "האורן",
    "האלה",
    "הארז",
    "הברוש",
    "הגפן",
    "הזית",
    "החצב",
    "החרצית",
    "הנרקיס",
    "הערבה",
    "הציפורן",
    "הרדוף הנחלים",
    "הרימון",
    "השקד",
    "התאנה",
    "התמר",
    "ורד",
    "חבצלת",
    "יסמין",
    "יקינתון",
    "כלנית",
    "כרכום",
    "לבונה",
    "לילך",
    "נופר",
    "נורית",
    "סביון",
    "סיגלית",
    "עירית",
    "פרג",
    "רוזמרין",
    "רקפת",
    "שד אדם",
  ],
  "גבע כרמל": [
    "גבע כרמל",
    "דרך האורן",
    "דרך האילנות",
    "דרך האלון",
    "דרך האשל",
    "דרך הדקל",
    "דרך הדרים",
    "דרך הערבה",
    "דרך הצאלון",
    "דרך הרימון",
    "משעול האגוז",
    "משעול האשכולית",
    "משעול האתרוג",
    "משעול הבוסתן",
    "משעול הפרדס",
    "משעול השקד",
    "משעול התמר",
    "משעול התפוז",
  ],
  גבעולים: ["גבעולים"],
  "גבעון החדשה": [
    "אגוז",
    "אילן",
    "אפרסמון",
    "ארז",
    "אשל",
    "גבעון החדשה",
    "דובדבן",
    "דולב",
    "דקל",
    "האורן",
    "האלה",
    "האלון",
    "הברוש",
    "הגפן",
    "הדס",
    "הצבר",
    "הרותם",
    "הרימון",
    "השקמה",
    "התאנה",
    "זית",
    "חרוב",
    "כליל החורש",
    "מבוא ליבנה",
    "שיטה",
    "שקד",
    "תומר",
  ],
  "גבעות בר": ["גבעות בר"],
  "גבעת אבני": [
    "אלון",
    "גבעת אבני",
    "האורנים",
    "האירוסים",
    "הגפן",
    "הדקלים",
    "היסמין",
    "הנרקיסים",
    "הסביונים",
    "כלניות",
  ],
  "גבעת אלה": [
    "אילון",
    "אלונים",
    "בית אורן",
    "גבעת אלה",
    "גנוסר",
    "דליה",
    "דן",
    "דפנה",
    "הגליל",
    "הגשר",
    "הזורעים",
    "הירדן",
    "הכרמל",
    "העמק",
    "חניתה",
    "מחניים",
    "מסדה",
    "מעיין צבי",
    "נווה איתן",
    "עין גב",
    "שער הגולן",
  ],
  "גבעת ברנר": [
    "גבעת ברנר",
    "האלה",
    "האשל",
    "הדקל",
    "השיזף",
    "השיטה",
    "השקד",
    "התאנה",
    "שכ יחד",
  ],
  "גבעת השלושה": ["גבעת השלושה"],
  "גבעת זאב": [
    "אבני החושן",
    "אגן האיילות",
    "אדמונית",
    "אודם",
    "אחלמה",
    "אירוס",
    "אמנון ותמר",
    "ארבעת המינים",
    "אתרוג",
    "בארות",
    "בן נון יהושע",
    "בנה ביתך",
    "ברקת",
    "גבע",
    "גבעון",
    "גבעת זאב",
    "דוכיפת",
    "דרגות",
    "דרור",
    "האיילות",
    "הארי",
    "הגדי",
    "הגיא",
    "הדודאים",
    "הדישון",
    "הזאב",
    "הזית",
    "החליל",
    "היחמור",
    "היעל",
    "הכינור",
    "הכפיר",
    "הלביא",
    "המכבים",
    "הנבל",
    "העופר",
    "הצבי",
    "הר שמואל",
    "הראם",
    "הרדוף",
    "השופר",
    "התומר",
    "התן",
    "זמיר",
    "חבר",
    "חי- בר",
    "חרצית",
    "חתול הבר",
    "יהלום",
    "ייטב",
    "יסמין",
    "יעלים",
    "יקינטון",
    "כלנית",
    "לבונה",
    "לוטם",
    "לולב",
    "לשם",
    "מבוא הגפן",
    "מבוא הדס",
    "מבוא הזיתים",
    "מבוא השמש",
    "מבוא השקמה",
    "מבוא תמר",
    "מורשת בנימין",
    "מכמש",
    "מצפה",
    "מרגנית",
    "מרכז הישוב",
    "משעול צוקים",
    "נווה מנחם",
    "נופך",
    "נחליאלי",
    "סביון",
    "סלעית",
    "ספיר",
    "עלמון",
    "עמק איילון",
    "ענתות",
    "עפרה",
    "עפרוני",
    "ערבה",
    "ערוגות",
    "פטדה",
    "פרחי הבר",
    "פרת",
    "צוקית",
    "קדרון",
    "קרית יערים",
    "רותם",
    "רימון",
    "רמה",
    "רקפת",
    "שאול המלך",
    "שבט בנימין",
    "שוהם",
    "שור הבר",
    "שיטה",
    "שכ הדר הגבעה",
    "שכ יהודה ויהודית",
    "שקד",
    "תאנה",
    "תדהר",
    "תור",
    "תרשיש",
  ],
  'גבעת ח"ן': [
    "ברכת עם",
    'גבעת ח"ן',
    "מעבר לים",
    "פלוני יש לו",
    "פעמי אביב",
    "שירתי",
    "תרזה יפה",
  ],
  "גבעת חיים (איחוד)": ["גבעת חיים (איחוד)"],
  "גבעת חיים (מאוחד)": ["גבעת חיים (מאוחד)"],
  "גבעת יואב": ["גבעת יואב"],
  "גבעת יערים": [
    "איילת חן",
    "אמלל שיר",
    "גבעת יערים",
    "דרך שבזי שלום",
    "היושבת בגנים",
    "הקדרה",
    "הר המור",
    "הריקמה",
    "לנר ולבשמים",
    "מרבד הקסמים",
    "על כנפי נשרים",
    "ענבל",
    "עת דודים",
    "פלח הרימון",
    "ריח הדס",
  ],
  "גבעת ישעיהו": ["גבעת ישעיהו"],
  'גבעת כ"ח': ['גבעת כ"ח'],
  'גבעת ניל"י': ['גבעת ניל"י'],
  "גבעת עוז": ["גבעת עוז"],
  "גבעת שמואל": [
    "אהרון",
    "אורי צבי גרינברג",
    "אורנים",
    "אלונים",
    "אלעזר דוד",
    "אפרים",
    "ארזים",
    "ארלוזורוב",
    "אתרוג",
    "בארי",
    "בגין מנחם",
    "ביאליק",
    "בן גוריון",
    "בן יוסף שלמה",
    "בר אילן",
    "בר כוכבא",
    "ברלב חיים",
    "ברנשטיין-כהן",
    "גבעת שמואל",
    "גולדה מאיר",
    "גוש עציון",
    "דיין משה",
    "דרך המלך",
    "דרך יצחק רבין",
    "הגפן",
    "הולצברג שמחה",
    "הזיתים",
    "הללס שלמה",
    "המכבים",
    "המר זבולון",
    "הנשיא",
    "הסיבים",
    "העבודה",
    "הערבה",
    'הפלמ"ח',
    "הציונות",
    "הרב סולם משה",
    "הרב שלמה גורן",
    "הרימון",
    "הרצוג",
    "הרצל",
    "השקד",
    "התאנה",
    "התומר",
    "התמר",
    "ויסמונסקי",
    "ויצמן",
    "ז'בוטינסקי",
    "זאבי רחבעם(גנדי)",
    "חיטמן עוזי",
    "טירן",
    "טרומפלדור",
    "יהודה הלוי",
    "יוני נתניהו",
    "יצחק שמיר",
    "ירושלים",
    "ישיבת בני עקיבא",
    "ככר אורן",
    "ככר אשלים",
    "ככר דן",
    "ככר הבשור",
    "ככר ירדן",
    "ככר פיכמן",
    "ככר שניר",
    "ככר תבור",
    "לוחמי הגטאות",
    "לוי אשכול",
    "ליפקין שחק אמנון",
    "לנדאו חיים",
    "מבצע קדש",
    "מודעי יצחק",
    "מוטה גור",
    "מעון אקדמאים",
    "נחום",
    "נימרובר",
    "סירני",
    "סמ בוני אברהם",
    "סמ הרב קוק",
    "סמ זינגר יהודה",
    "סמ יעקב",
    "סמ נח",
    "סמ נחום",
    "סמ קורדובה",
    "סמ רבקה",
    "סמ רחל",
    "סמטת הדס",
    "סנש חנה",
    'עגנון ש"י',
    "עוזיאל",
    "פיקסלר צבי",
    "פנינה קליין",
    "פרץ",
    "קרן היסוד",
    "קרפל ליפא",
    "רזיאל דוד",
    "רחבת אילן",
    "רחבת קרן היסוד",
    'רמב"ם',
    "רמת אילן",
    "רמת הדקלים",
    "רמת הדר",
    "שאול המלך",
    "שד הגבורים",
    "שד העצמאות",
    "שדה יצחק",
    "שטיינברג",
    "שכ גבעת יהודה",
    "שכ גיורא",
    "שכ השחר",
    "שכ מרכז",
    "שכ פרדס רובין",
    "שכ פרדס רוזנבלום",
    "שכ קרית ישראל",
    "שמר נעמי",
    "שפינדל יונה",
  ],
  "גבעת שמש": ["גבעת שמש"],
  "גבעת שפירא": ["גבעת שפירא"],
  גבעתי: ["גבעתי"],
  גבעתיים: [
    "אבני זכרון",
    "אחדות העבודה",
    "אידמית",
    "אילת",
    "אלומות",
    "אלוף שדה",
    "אפנר",
    "אפק",
    'אצ"ל',
    "אריאל",
    "אריאל שרון",
    "ארלוזורוב",
    "ארנון",
    "בורוכוב",
    "בלוך",
    "בן צבי שמעון",
    "בן ציון ישראלי",
    "בראשית",
    "ברדיצ'בסקי",
    "ברוך",
    "גבולות",
    "גבע",
    "גבעת קוזלובסקי",
    'גבעת רמב"ם',
    "גבעתיים",
    "גולדשטיין אהרון",
    "גולומב",
    "גונן",
    "גורדון",
    "גוש עציון",
    "גזית",
    "גלבוע",
    "גנסין",
    "דב",
    "דליה",
    "דן",
    "דרך בן גוריון דוד",
    "דרך השלום",
    "דרך יצחק רבין",
    "הגורן",
    "הדגנים",
    "ההגנה",
    "ההסתדרות",
    "הורדים",
    "הידיד",
    "הכנסת",
    "הלמד הא",
    "המאבק",
    "המאור",
    "המבוא",
    "המגדל",
    "המורד",
    "המלבן",
    "המעגל",
    "המעורר",
    "המעין",
    "המעלות",
    "המפנה",
    "המרי",
    "הנגבה",
    "הנדיב",
    "הפועל הצעיר",
    'הפלמ"ח',
    "הצנחנים",
    "הראל",
    "הרב צימבר",
    "הרצוג",
    "השומר",
    "השלבים",
    "השנים",
    'התע"ש',
    "ויצמן",
    "ז'בוטינסקי",
    'ח"ן',
    "חניתה",
    "טבנקין",
    "טיבר",
    "יבניאלי",
    "יד מרדכי",
    "ילדי טהרן",
    "ילין דוד",
    "יפה נוף",
    "יצחק",
    "יצחק שדה",
    "כורזין",
    "ככר נח",
    "כלנית",
    "כנרת",
    "כף גימל",
    "כצנלסון",
    "לביא",
    "לכיש",
    "מהרשק",
    "מורדי הגיטאות",
    "מנורה",
    "מנרה",
    "מסדה",
    "מעוז",
    "מעלה בועז",
    "מעלה יעקב",
    "מצולות ים",
    "מרכז עסקים דרום",
    "משגב עם",
    "משמר הירדן",
    "ניצנה",
    'נעמ"ת',
    "נרקיס",
    "סירני אנצו",
    "סירקין",
    "סמ אליהו",
    "סמ אסירי ציון",
    "סמטת נחל",
    "סמטת צור",
    "סנש",
    "עברונה",
    "עין גדי",
    "עלית הנוער",
    "עמישב",
    "עמל",
    "ערבי נחל",
    "ערד",
    "פועלי הרכבת",
    "פטאי",
    "פינברג יעקב",
    "צביה",
    'צה"ל',
    "צפרירים",
    "קבוץ גלויות",
    "קושניר",
    "קלעי",
    "קפלנסקי",
    "קרית יוסף",
    "קרן היסוד",
    "קרן קימת לישראל",
    "רביבים",
    "רודין אלישע",
    "רוטברג מאיר",
    "רותם",
    "רחל",
    "ריינס",
    "רייק",
    'רמב"ם',
    "רמה",
    "רמז",
    "רקפת",
    'ש עובדי קופ"ח',
    "ש קרת",
    "ש רוזנשטין",
    "ש שילר",
    "שד אפיקים",
    "שדה בוקר",
    "שיכוני דרום",
    "שילוב",
    "שינקין",
    "שכ ארלוזורוב",
    "שכ בורוכוב",
    "שכ עובדי חברת חשמל",
    "שכ פועלי הרכבת",
    "שכ קרית יוסף",
    "שכ שיינקין",
    "שפירא ישראל",
    "שר אהרן",
    "תפוצות ישראל",
  ],
  גברעם: ["גברעם"],
  גבת: ["גבת"],
  גדות: ["בית ראשון במולדת", "גדות"],
  גדיש: ["גדיש"],
  גדעונה: [
    "גדעונה",
    "האירוס",
    "האתרוג",
    "הגלבוע",
    "הגפן",
    "הזית",
    "החצב",
    "המייסדים",
    "המעיין",
    "הרימון",
    "השקד",
    "התאנה",
    "כלנית",
    "נרקיס",
    "רקפת",
  ],
  גדרה: [
    "אבא אחימאיר",
    "אבינדב ירוחם",
    "אגוז",
    "אודם",
    "אורן",
    "אזור תעשיה",
    "אחוזת גדרה",
    "אילות",
    "אירוס",
    "אלה",
    "אלון",
    "אלי כהן",
    "אלמוגן",
    "אלשייך פינק",
    "אם כל חי",
    "אעלה בתמר",
    "אפרים",
    'אצ"ג',
    'אצ"ל',
    "ארז",
    "ארקין צבי",
    "אשחר",
    "אשל",
    "אשר",
    "אתר חפץ חיים",
    "אתרוג",
    "ב אבות למושב",
    "בורג יוסף",
    'בי"ח הרצפלד',
    "ביאליק",
    "בן גפן",
    "בן צבי יצחק",
    "בעלי מלאכה",
    "ברוש",
    "ברקת",
    "ג'מיל דוד",
    "גדרה",
    "גדרה הצעירה",
    "גומברג שבתאי",
    'ד"ר הסנר הוגו',
    'ד"ר פון ויזל',
    "דהרי מנחם",
    "דוכיפת",
    "דולב",
    "דן",
    "דנציגר",
    "דפנה",
    "דקל",
    "דרור",
    "דרך ארץ",
    "דרך האילנות",
    "דרך הנחלים",
    "דרך הפרחים",
    "הארבל",
    "הבילוים",
    'הג"א',
    "הגולן",
    "הגורן",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הדרים",
    "ההגנה",
    "הולצברג שמחה",
    "הורביץ",
    "הזית",
    "החומה",
    "החרוב",
    "החרמון",
    "היקב",
    "הכורמים",
    "הכרמל",
    'המהרי"ץ',
    "המציל היהודי",
    "המר זבולון",
    "הנגב",
    "הנקין מרדכי",
    "הנשיא",
    "העופרים",
    "העליה",
    "העמקים",
    "העצמאות",
    "הפרדס",
    "הר מרון",
    "הרב גמליאל יוסף",
    "הרב צוברי מאיר",
    "הרב שייבה ישראל",
    "הרב תנעמי",
    "הרב תעיזי יחיא",
    "הרבי מליובאוויטש",
    "הרדוף",
    "הרי אדום",
    "הרי מואב",
    "הרימון",
    'הרמב"ם',
    "הרצוג חיים",
    "הרצל",
    "הרצפלד",
    "השומרון",
    "השפלה",
    "השרון",
    "ויינר ישראל",
    "וייס ולדר",
    "ויצמן",
    "ז'בוטינסקי",
    "זאוברמן זאב",
    "זבולון",
    "זיגמן",
    "זמיר",
    "זרקון",
    "חבקוק",
    "חבקין יצחק",
    "חגי הנביא",
    "חוחית",
    "חושן",
    "חזית הדרום",
    "חזנוב",
    "חצב",
    "חרצית",
    'י"א חללי מינכן',
    "יגאל אלון",
    "יהודה",
    "יהודה הגדרתי",
    "יהונתן",
    "יהלום",
    "יואל",
    "יונה הנביא",
    "יזרעאל",
    "יסמין",
    "יפה נוף",
    "יקינטון",
    "ירדן",
    "ישעיהו ישראל",
    "ישראל אילן הולצמן",
    "יששכר",
    "כהנוב אליעזר",
    "כחלית",
    "כלנית",
    "כנפי נשרים",
    "כפר אוריאל",
    "כפר אליהו",
    "כצנלסון",
    "כרכום",
    "לב גדרה",
    "לב המושבה",
    "לובצקי",
    "לוי",
    "לוינסון",
    "לוע הארי",
    "ליבוביץ",
    "ליבנה",
    "לילינבלום",
    "לילך",
    "לרנר מרדכי",
    "לשם",
    "מגינים",
    "מוסינזון יגאל",
    "מוצקין",
    "מורי אהרון צברי",
    "מחנה מרים",
    "מלר יוסף צבי",
    "מצדה",
    "מרבד הקסמים",
    "מרגנית",
    "משה ויהודית רוזנר",
    'משעול הרש"ש',
    "נאות גדרה",
    "נאות ראשונים",
    "נבט משה",
    "נהר שלום",
    "נווה אורנים",
    "נווה שולמית",
    "נווה שלמה",
    "נוטמן הנס",
    "נופך",
    "נורית",
    "נחום",
    "נחל איילון",
    "נחל ארנון",
    "נחל דן",
    "נחל הבשור",
    "נחל הירמוך",
    "נחל הירקון",
    "נחל ליטאני",
    "נחל לכיש",
    "נחל נעמן",
    "נחל עדה",
    "נחל ערוגות",
    "נחל פארן",
    "נחל צאלים",
    "נחל קדרון",
    "נחל שורק",
    "נחל שניר",
    "נחל תנינים",
    "נירנברג שניאור",
    "נפתלי",
    "נרקיס",
    "סביון",
    "סברדלוב",
    "סופר שלום",
    "סחלב",
    "סטרומה",
    "סיגלביץ שרה",
    "סמ דרך ארץ",
    'סמ הג"א',
    "סמ לובצקי",
    "סמדר",
    "סנונית",
    "סעידי שמחה",
    "ספיר",
    "עין גדי",
    "עמוס",
    "ערבה",
    "פוקס",
    "פטדה",
    "פייבל",
    "פיינברג",
    "פינס",
    "פיקוס",
    "פנינה",
    "פעמונית",
    "פרג",
    "פרחי בר",
    "פרנקל אליהו",
    "צאלה",
    "צבעוני",
    "צברי סעדיה",
    "צוקרמן",
    "ציפורן",
    "צמרת גדרה",
    "צפניה",
    "קבוץ גלויות",
    "קוסובסקי דניאל",
    "קטלב",
    "קפלינסקי משה",
    "קפלן יעקב מאיר הכהן",
    'קק"ל',
    "קרסיקוב אריה",
    "קרסנר אליעזר",
    "ראובן",
    "רבי יהודה",
    "רותם",
    "רז אריה",
    "רזיאל דוד",
    "רמז דוד",
    "ש הנופש",
    "ש נוה דוד",
    "ש נוה משה",
    "ש נוה ציון",
    "ש סעד",
    "ש צבא קבע",
    "ש קטרה",
    "שבו",
    "שבזי",
    "שבטי ישראל",
    "שביל מקורות",
    "שד בגין מנחם",
    "שד בן גוריון",
    "שד ציונה צרפת",
    "שד רבין יצחק",
    "שוהם",
    "שושן",
    "שיטה",
    "שכ אזורי גדרה",
    "שכ גבעת העיריות",
    "שכ המושבה הגרמנית",
    "שכ הפועלים",
    'שכ נווה אד"ר',
    "שכ נווה שולמית",
    "שכ עמק הנשר",
    "שכ רשפים",
    "שכביץ",
    "שלדג",
    "שלום עליכם",
    "שמעון",
    "שפירא",
    "שפק שלמה",
    "שפרינצק",
    "שקד",
    "שקמה",
    "שרעבי מנשה",
    'תרמ"ה',
  ],
  גונן: ["בית ראשון במולדת", "גונן"],
  גורן: ["גורן"],
  "גורנות הגליל": ["גורנות הגליל"],
  גזית: ["אודם", "ברקת", "גזית", "טופז", "לשם", "ענבר", "צור", "שוהם"],
  גזר: ["גזר", "ש קרוונים"],
  גיאה: ["גיאה"],
  גיבתון: [
    "בן ישראל",
    "ברזילי",
    "גיבתון",
    "האם ובניה",
    "המייסדים",
    "העמדות",
    "יהודית",
  ],
  גיזו: ["גיזו"],
  גילון: ["אודם", "ברקת", "גילון", "טופז", "לשם", "ספיר", "ענבר", "שוהם"],
  גילת: ["גילת", "שכ קרוונים"],
  גינוסר: ["גינוסר"],
  גיניגר: [
    "אל היער",
    "בית ראשון במולדת",
    "גיניגר",
    "דרך הגבעות",
    "דרך הדשאים",
    "דרך החשמל",
    "דרך הכרמים",
    "דרך המפעל",
    "דרך הראשונים",
    "דרך הרפת",
    "האשכולית",
    "החינוך המשותף",
    "הטבעונים",
    "היער המכושף",
    "המקומרים",
    "הפומלית",
    "הפסגה",
    "הצעירים",
    "השכונה הדרומית",
    "התפוז",
    "חלוצי דגניה ג'",
    "לינה כפרית",
    "משמר הוולגה",
    "עלי גבעה",
    "שביל האולפן",
    "שדרת הדקלים",
    "שדרת הזיתים",
    "שיכון ל",
    "שכונת הדר",
  ],
  גינתון: [
    "גינתון",
    "האגוז",
    "האורן",
    "האלה",
    "הארז",
    "הברוש",
    "הגנים",
    "הדולב",
    "הדקל",
    "הזית",
    "החרוב",
    "הפקאן",
    "הצאלון",
    "הרימון",
    "השקמה",
    "התאנה",
    "התמר",
  ],
  גיתה: ["גיתה"],
  גיתית: ["גיתית"],
  גלאון: ["גלאון"],
  גלגל: ["גלגל"],
  "גליל ים": ["גליל ים"],
  "גלעד (אבן יצחק)": ["גלעד (אבן יצחק)"],
  גמזו: ["גמזו", "האלון", "הברוש", "הגפן", "הדקל", "התאנה", "מעלות יהונתן"],
  "גן הדרום": ["גן הדרום", "הגפן", "הזית", "הרימון", "התאנה", "התמר"],
  "גן השומרון": ["גן השומרון"],
  "גן חיים": [
    "גן חיים",
    "דרך המלך",
    "האיריס",
    "האלה",
    "הגפן",
    "הזית",
    "החרוב",
    "הכלנית",
    "הנרקיס",
    "הערבה",
    "הראשונים",
    "הרימון",
    "הרקפת",
    "השושן",
    "התאנה",
    "התמר",
    "נורית",
  ],
  "גן יאשיה": [
    "גן יאשיה",
    "דרך הפרדס",
    "האנפה",
    "הגיא",
    "הגנים",
    "הגפן",
    "הזית",
    "החורשה",
    "החיטה",
    "המייסדים",
    "הרימון",
    "השדה",
    "השקד",
    "נחל ראובן",
    "שדרת האקליפטוס",
  ],
  "גן יבנה": [
    "א. צ. גרינברג",
    "אביר יעקב",
    "אגוז",
    "אדמונית",
    "אהוד בן גרא",
    "אודם",
    "אורנית",
    "אזור התעשיה",
    "אחד העם",
    "אחוזה",
    "איילון",
    "איריס",
    'אלופי צה"ל',
    "אלי כהן",
    "אלכסנדר",
    "אלעזר",
    "אלתרמן נתן",
    'אצ"ל',
    "ארבל",
    "ארגמן",
    "אשכול",
    "אתרוג",
    "בגין מנחם",
    "ביאליק",
    "בלנקשטיין מורלי",
    "בן גוריון",
    "בר יוחאי",
    "בר כוכבא",
    "ברקאי",
    "ברקת",
    "בשור",
    "גבעת הסביונים",
    "גולדברג לאה",
    "גולן",
    "גיבורים",
    "גלבוע",
    "גן יבנה",
    'ד"ר שמעון היימן',
    "דבורה הנביאה",
    "דליה",
    "דלתון",
    "דרך יצחק רבין",
    "דרך ירושלים",
    "דרך קנדה",
    "דרך שתולים",
    "האור",
    "האורן",
    "האלה",
    "האנפה",
    "האקליפטוס",
    "האשל",
    "הבר",
    "הברוש",
    "הגאולה",
    "הגפן",
    "הדגן",
    "הדס",
    "הדקל",
    "הדרים",
    "ההגנה",
    "הודיה",
    "הורד",
    "הזורע",
    "החיטה",
    "החמניה",
    "החצב",
    "החציר",
    "החרוב",
    "החרצית",
    "הטייסים",
    "הטל",
    "היורה",
    "הישועה",
    "הכד",
    "הכישור",
    "המגינים",
    "המגל",
    "המגן",
    "המורג",
    "המכבים",
    "המלך דוד",
    "המלך שלמה",
    "המלקוש",
    "המנור",
    "המפרש",
    "המשוט",
    "המשוררת רחל",
    "הנביאים",
    "הנס",
    "הנצחון",
    "הנרקיס",
    "הנשיא",
    "הסביון",
    "הסייפן",
    "הסיירים",
    "העוגן",
    "העמל",
    "העצמאות",
    "הערבה",
    "הערוגות",
    "הפלדה",
    "הפלך",
    'הפלמ"ח',
    "הצפורן",
    "הקמה",
    "הקציר",
    "הר הצופים",
    "הר סיני",
    "הר ציון",
    "הראשונים",
    "הרב הרצוג",
    "הרב קוק",
    "הרגב",
    'הרמב"ם',
    "הרצל",
    "הרקפת",
    "השומר",
    "השופטים",
    "השושן",
    "השיבולים",
    "השיטה",
    "השניים",
    "השעורה",
    "התבור",
    "התורן",
    "התלמים",
    "ויניפג",
    "זיו",
    "זמיר",
    "חבר",
    "חופית",
    "חלמיש",
    "חמדה",
    "חרמון",
    "חשמונאים",
    "יבולים",
    "יבנאי יוסף",
    "יהודה",
    "יהודה הלוי",
    "יהודית",
    "יהלום",
    "יוחנן",
    "יונתן",
    "ינאי",
    "יסמין",
    "יעלים",
    "יפתח",
    "יקותיאל אדם",
    "ירקון",
    "כיכר הבנים",
    "ככר הרצל",
    "ככר זמיר",
    "ככר מירון",
    "ככר מלכי ישראל",
    "ככר עצמאות",
    "כלנית",
    "כרכום",
    "כרמים",
    "כרמל",
    'לח"י',
    "לילך",
    "לימון",
    "לימור",
    "לפיד",
    "מגדים",
    "מודיעין",
    "מור",
    "מוריה",
    "מירון",
    "מצדה",
    "מרגנית",
    "מרים",
    "מתתיהו",
    "נאות אשכול",
    "נאות המכבים",
    "נאות הפרחים",
    "נאות יבנאי",
    "נווה לימור",
    "נורית",
    'ניל"י',
    "ניצנה",
    "סמ התור",
    "סמ התותחנים",
    "סמ חטיבת גולני",
    "ספיר",
    "עגנון",
    "עולי הגרדום",
    "עופרים",
    "עמיחי יהודה",
    "עפרוני",
    "פולג",
    "פעמונית",
    "צאלים",
    'צה"ל',
    "צוללים",
    "צור",
    "צנחנים",
    "קבוץ גלויות",
    "קדרון",
    "קישון",
    "רבי עקיבא",
    "רביבים",
    "רותם",
    "רימון",
    "ש חדש",
    "ש נווה גן",
    "ש נווה עובד",
    "ש עובדים",
    "ש עמידר",
    "שאול המלך",
    "שבו",
    "שבזי",
    "שוהם",
    "שורק",
    "שזפון",
    "שחף",
    "שייטת",
    "שכ ב סוכנות",
    "שכ גבעת שמשון",
    "שכ הותיקים",
    "שכ המושבה",
    "שכ מוטה גור",
    "שכ נאות הדרים",
    "שכ פועלים",
    "שכ שוטרים",
    "שלדג",
    "שלומציון",
    "שליין זלמן",
    "שמואל הנביא",
    "שמעון",
    "שמר נעמי",
    "שקמה",
    "שריון",
    "תאנה",
    "תורה ועבודה",
    "תל חי",
    "תמר",
    "תפוח",
    "תרשיש",
  ],
  "גן נר": [
    "אורן",
    "אירוס",
    "ברוש",
    "גן נר",
    "האלה",
    "הרב נורי דוד",
    "השקד",
    "חבצלת",
    "חצב",
    "חרצית",
    "יסמין",
    "יערה",
    "כלנית",
    "כרכום",
    "לוטם",
    "לילך",
    "נופר",
    "נורית",
    "נרקיס",
    "סביון",
    "סחלב",
    "סייפן",
    "צבעוני",
    "רקפת",
    "תמר",
  ],
  "גן שורק": ["גן שורק"],
  "גן שלמה": ["גן שלמה"],
  "גן שמואל": ["גן שמואל"],
  גנות: [
    "גנות",
    "האשל",
    "האתרוג",
    "הגפן",
    "ההדס",
    "הזית",
    "הערבה",
    "הפרדס",
    "הרימון",
    "התאנה",
    "התומר",
  ],
  "גנות הדר": [
    "אמנון ותמר",
    "גנות הדר",
    "האדמונית",
    "האירוסים",
    "הבסמת",
    "הדגניות",
    "ההדס",
    "ההרדופים",
    "החמניות",
    "החצב",
    "היסמין",
    "היקינטון",
    "הלילך",
    "הנורית",
    "הנרקיסים",
    "הסחלב",
    "הסיגליות",
    "הסייפן",
    "הסתוונית",
    "הצבעוני",
    "הרקפות",
    "השושנים",
  ],
  "גני הדר": ["גני הדר", "האורן"],
  "גני טל": [
    "אלמוג",
    "גני טל",
    "גפן",
    "האגם",
    "החווה",
    "המדרשה",
    "הפגודה",
    "ורד",
    "חוף הדקלים",
    "יסמין",
    "ככר המייסדים",
    "נאות קטיף",
    "נוריה",
    "תפוח",
  ],
  "גני יוחנן": [
    "גני יוחנן",
    "ההדס",
    "הורד",
    "היסמין",
    "הכלנית",
    "הנרקיס",
    "הסביון",
    "הצבעוני",
    "הרקפת",
  ],
  "גני מודיעין": [
    "אבי דוד",
    "אמרי בינה",
    "בית יוסף",
    "בן איש חי",
    "בני בינה",
    "גני מודיעין",
    "דברות משה",
    "דעת תבונה",
    'הרמב"ם',
    "טורי זהב",
    "יערות דבש",
    "כף חיים",
    'מהר"י קאפח',
    'מהרי"ץ',
    "נחל מודיעין",
    "פרי מגדים",
    "קדושת הלוי",
    "קול יהודה",
    'רמ"א',
    'רש"י',
    "שבזי",
    "שכ גני מודיעין",
    "שפת כהן",
    "שתילי זהב",
    "שתילי זיתים",
  ],
  "גני עם": [
    "אתרוג",
    "ביער",
    "בית העם",
    "גני עם",
    "דרך הים",
    "האקליפטוס",
    "הבנים",
    "הצבעוני",
    "התות",
    "יפה נוף",
    "ירוק עד",
    "סביונים",
    "שירת השחרור",
  ],
  "גני תקווה": [
    "בארי",
    "בית שאן",
    "בקעת הירדן",
    "גבעון",
    "גבעת סביון",
    "גבעת סביון החדשה",
    "גני תקווה",
    "דגניה",
    "דרך אילות",
    "דרך האגמים",
    "דרך הבשמים",
    "דרך ההדרים",
    "דרך הים",
    "דרך המלך",
    "דרך המשי",
    "דרך העמק",
    "דרך התמרים",
    "דרך התקווה",
    "האורנים",
    "הברוש",
    "הגולן",
    "הגליל",
    "הגלעד",
    "הגפן",
    "הדקל",
    "החרמון",
    "הכרמל",
    "המצפה",
    "הנגב",
    "הנחל",
    "הנשיא טרומן",
    "העמקים",
    "הערבה",
    "הפסגה",
    "הר נבו",
    "הר סיני",
    "הרי יהודה",
    "הרי ירושלים",
    "הרימון",
    "הרמה",
    "התאנה",
    "זאב",
    "יודפת",
    "ים המלח",
    "כנרת",
    "מרחביה",
    "נוף סביון",
    "סמ דביר",
    "סמ הלבונה",
    "סמ השרון",
    "סמ כינרת",
    "סמ עין גנים",
    "סמ עמק יזרעאל",
    "סמ קדש",
    "סמ תבור",
    "סמ תל חי",
    "סמטת יובלים",
    "עין גדי",
    "עין חנוך",
    "עין שמש",
    "עמק זבולון",
    "צאלון",
    "קדושי השואה",
    "קדושת יום טוב",
    "קישון",
    "קרית ישמח משה",
    "רבאון שלמה",
    "רחבת אלון",
    "רחבת גונן",
    "רחבת דולב",
    "ש חדש",
    "שדרת התקוות",
    "שכ בורוכוב",
    "שכ גנים",
    "שכ יפה נוף",
  ],
  געש: ["געש"],
  געתון: ["געתון"],
  גפן: ["גפן"],
  גרופית: ["גרופית"],
  גשור: ["גשור"],
  גשר: ["בית ראשון במולדת", "גשר"],
  "גשר הזיו": [
    "ביס שדה אכזיב",
    "גשר הזיו",
    "דרך הטבק",
    "דרך כליל החורש",
    "משעול הברוש",
    "נתיב הארז",
    "נתיב הגפן",
    "נתיב הדולב",
    "נתיב ההרדוף",
    "נתיב הזית",
    "נתיב החרוב",
    "נתיב היערה",
    "נתיב הלוטם",
    "נתיב המרגנית",
    "נתיב הנרקיס",
    "נתיב הסחלב",
    "נתיב הערבה",
    "סמ לוז",
    "סמ מוז",
    "שדרת האורנים",
  ],
  "גת (קיבוץ)": ["גת (קיבוץ)"],
  "גת רימון": ["אילנות", "גת רימון", "האקליפטוס", "הברוש", "התפוז"],
  "דאלית אל-כרמל": [
    "אדיסון",
    "אום כולתום",
    "אוסלו",
    "אוראנוס",
    "אורכיד",
    "אזור המסעדות",
    "אזור התעשיה",
    "אזור כביש עוקף",
    "איינשטיין",
    "אילת",
    "אל ג'ליל",
    "אל נזהא",
    "אל סת שהואנה",
    "אלאדאב",
    "אלאדיב",
    "אלאהאלי",
    "אלאהראם",
    "אלאול",
    "אלאוסתאד'",
    "אלאח'אא",
    "אלאח'לאס",
    "אלאחד",
    "אלאחסאן",
    "אלאמאנה",
    "אלאמיר",
    "אלאמל",
    "אלאנואר",
    "אלאנסאנייה",
    "אלאסראא",
    "אלארד'",
    "אלארז",
    "אלבאשא",
    "אלבדיע",
    "אלביאדה",
    "אלבלאן",
    "אלבסאתין",
    "אלברונז",
    "אלג'דור",
    "אלג'וז",
    "אלג'ולן",
    "אלג'ורי",
    "אלג'מאל",
    "אלג'עארה",
    "אלג'עסאס",
    "אלד'הב",
    "אלדוקטור",
    "אלדוראק",
    "אלהאדי",
    "אלואחד",
    "אלואלי",
    "אלוונסא",
    "אלוורוד",
    "אלותר",
    "אלזהור",
    "אלזיתון",
    "אלזכאה",
    "אלזנבק",
    "אלזעפראן",
    "אלזערור",
    "אלזעתר",
    "אלח'דמה",
    "אלח'ואלנד",
    "אלח'לואת",
    "אלחג'",
    "אלחדאד",
    "אלחואקיר",
    "אלחורש",
    "אלחזורי",
    "אלחכם",
    "אלחלאק'",
    "אלחלים",
    "אלחמאם",
    "אלחמיד",
    "אלחסון",
    "אלטאלב",
    "אלטוליפ",
    "אליאסמין",
    "אליאקות",
    "אליעפורי",
    "אלכאתב",
    "אלכביר",
    "אלכרים",
    "אללביב",
    "אללוז",
    "אלליימון",
    "אלמאס",
    "אלמודיר",
    "אלמוהנדס",
    "אלמוח'טארה",
    "אלמוחאמי",
    "אלמוסאוא",
    "אלמועלם",
    "אלמושתרי",
    "אלמותנבי",
    "אלמזרעה",
    "אלמחבה",
    "אלמחפרה",
    "אלמיזאן",
    "אלמלק",
    "אלמנארה",
    "אלמנזול",
    "אלמסארב",
    "אלמסק",
    "אלמעראג'",
    "אלמערי",
    "אלמקמאן",
    "אלמריך",
    "אלמשמש",
    "אלמתן",
    "אלנאי",
    "אלנג'אר",
    "אלנורס",
    "אלנזאזה",
    "אלנח'ווה",
    "אלנח'יל",
    "אלנחל",
    "אלנענע",
    "אלנעסה",
    "אלנק'אב",
    "אלנרג'ס",
    "אלסבאח",
    "אלסבאר",
    "אלסבור",
    "אלסדאקה",
    "אלסואניה",
    "אלסווידה",
    "אלסומאק",
    "אלסוסן",
    "אלסופסאף",
    "אלסופרג'ל",
    "אלסחאפי",
    "אלסטח",
    "אלסיאד",
    "אלסיידלי",
    "אלסלאם",
    "אלסנדיאן",
    "אלסנובר",
    "אלסקר",
    "אלסראט",
    "אלסרו",
    "אלסריס",
    "אלסת ח'ד'רה",
    "אלסת סארה",
    "אלע'אר",
    "אלע'זאל",
    "אלע'יידה",
    "אלעדל",
    "אלעוד",
    "אלעזיז",
    "אלעטור",
    "אלעלים",
    "אלעמאמה",
    "אלענאב",
    "אלענב",
    "אלענבר",
    "אלפהד",
    "אלפודה",
    "אלפיאנו",
    "אלפנון",
    "אלק'דאא",
    "אלק'נדול",
    "אלקאדי",
    "אלקורונפול",
    "אלקטלב",
    "אלקרואן",
    "אלקרז",
    "אלרבאבה",
    "אלרוחראח",
    "אלרומאן",
    "אלרוע'ב",
    "אלרזאק",
    "אלרחים",
    "אלרחמאן",
    "אלריאד",
    "אלריחאן",
    "אלרנדה",
    "אלרסאלה",
    "אלשאער",
    "אלשברק",
    "אלשוף",
    "אלשיח' אלפאדל",
    "אלשיך חמוד",
    "אלשלאלה",
    "אלתופאח",
    "אלתות",
    "אלתין",
    "אלתלמיד'",
    "אלתמר",
    "אלתסאמח",
    "אלתעאוון",
    "אלתעיוש",
    "אמסטרדם",
    "אסטנבול",
    "אפלטון",
    "אריסטו",
    "אשדוד",
    "אתונה",
    "באב אלהווא",
    "באר שבע",
    "בהאא אלדין",
    "בודפשט",
    "בייג'ין",
    "ביילסאן",
    "ביירות",
    "ביל גייטס",
    "ביר דובל",
    "בית אלדין",
    "בנו מערוף",
    "בריסל",
    "ברלין",
    "ג'ולנאר",
    "ג'ון לנון",
    "גלילאו",
    "גנדי",
    "גרעין הכפר הישן",
    "ד'הרת אלביר",
    "דאלית אל-כרמל",
    "דבלין",
    "דה וינצ'י",
    "דימונה",
    "דיר אלקמר",
    "דליה",
    'הרמב"ם',
    "הרצליה",
    "השכונה המזרחית",
    "ואדי אלפש",
    "ואדי אלתים",
    "ואדי עיסא",
    "וינה",
    "ורשה",
    "זוחל",
    "זקריא",
    "ח'אתם סלימאן",
    "ח'לת אלאעואר",
    "ח'לת אלאעור",
    "ח'לת אלג'מל",
    "ח'לת חמזה",
    "ח'לת עלי",
    "חאצביא",
    "חולון",
    "חטין",
    "חיילים משוחררים",
    "חיפה",
    "טבריה",
    "טוליב",
    "טוקיו",
    "יד לבנים",
    "יהודה",
    "יפו",
    "יצחק רבין",
    "ירושלים",
    "כיכר דואר אלח'רבה",
    "כמאל ג'ונבלאט",
    "כרמל",
    "לונדון",
    "לינקולן",
    "ליסבון",
    "מדריד",
    "מוסקוה",
    "מחמד עלי",
    "מלאת אלריש",
    "מנדלה",
    "מנחם בגין",
    "מרג' אלנג'אס",
    "מרכז הכפר",
    "נג'מת אלסובח",
    "נג'מת דוד",
    "נהריה",
    "נזאזת אלג'יידא",
    "נזזת אלע'יידא",
    "ניו דלהי",
    "ניו יורק",
    "ניוטון",
    "נפטון",
    "נקארה",
    "נרדין",
    "נרמין",
    "סבלאן",
    "סולטאן באשא אלאטרש",
    "סופיה",
    "סוקרטס",
    "סטיב ג'ובס",
    "סידנא אליא",
    "סידנא שמס",
    "סיח אלח'טיב",
    "סמ 2",
    "סמ 6",
    "סמאר",
    "סמטת אלביאדר",
    "עוטארד",
    "עזר וייצמן",
    "עין אלקבליה",
    "עין אלשמאליה",
    "עכו",
    "עמאן",
    "ערד",
    "עש אלע'וראב",
    "פח'ר אלדין אלמעני",
    "פיירוז",
    "פיקאסו",
    "פיתאע'ורס",
    "פלה",
    "פלוטו",
    "פנינת הכרמל",
    "פראג",
    "פרויד",
    "פריס",
    "צ'פלין",
    "צ'רצ'יל",
    "צומת המוחרקה",
    "צפת",
    "קהיר",
    "קיסריה",
    "קנדי",
    "רבאע עודה",
    "רבעת אלג'וזה",
    "רוז",
    "רומא",
    "רוקיטייה",
    "רח 20",
    "רח 30",
    "רח 11",
    "רח 31",
    "רח 12",
    "רח 22",
    "רח 32",
    "רח 33",
    "רח 34",
    "רח 5",
    "רח 15",
    "רח 35",
    "רח 16",
    "רח 26",
    "רח 36",
    "רח 17",
    "רח 27",
    "רח 18",
    "רח 28",
    "רח 19",
    "רח 29",
    "רחובות",
    "ש יד לבנים",
    "שאגאל",
    "שטוקהולם",
    "שיח' אמין טריף",
    "שיכונאת",
    "שכ אלבסאתין",
    "שכ אלוורוד",
    "שכ אלח'רבה",
    "שכ אלקשקוש",
    "שכ אלרנדה",
    "שכ אסואניה",
    "שכ ביר דוביל",
    "שכ ואדי אלפש",
    "שכ ח'לת אלג'מל",
    "שכ ח'לת נצאר",
    "שכ ח'לת עלי",
    "שכ ט'הרת אלביר",
    "שכ מרג' אלנג'אס",
    "שכ נג'מת אלצבאח",
    "שכ עין אלשמאליה",
    "שכונת המוסלמים",
    "שמעון פרס",
    "שקיב ארסלאן",
    "תל אביב",
  ],
  דבורה: ["דבורה"],
  דבוריה: ["דבוריה"],
  דבירה: ["דבירה"],
  דברת: ["דברת"],
  "דגניה א'": ["דגניה א'"],
  "דגניה ב'": ["דגניה ב'"],
  'דוב"ב': ['דוב"ב'],
  דולב: [
    "דולב",
    "הגפן",
    'הראי"ה',
    "הרב גורן",
    "הרב ישראלי",
    "הרב לוין",
    "הרב מרדכי אליהו",
    "הרב נריה",
    "הרב עוזיאל",
    "התירוש",
    "מעלה הכרמים",
  ],
  דור: ["דור"],
  דורות: ["דורות"],
  דחי: ["דחי"],
  "דייר אל-אסד": [
    "אבו בכר",
    "אבו תמאם",
    "אבן ח'לדון",
    "אבן חיאן",
    "אבן רושד",
    "אלאח'טל",
    "אלאנביאא",
    "אלביאדר",
    "אלביארה",
    "אלבידר",
    "אלברכה",
    "אלג'בל",
    "אלג'לסה",
    "אלדיר",
    "אלדראג'",
    "אלורוד",
    "אלזהור",
    "אלזיתון",
    "אלזנבק",
    "אלזערור",
    "אלח'אן",
    "אלח'ד'ר",
    "אלח'לה",
    "אלח'רוב",
    "אליאסמין",
    "אלכרום",
    "אלכרימה",
    "אלכרם אלאחמר",
    "אלמותנבי",
    "אלמערי",
    "אלמראח",
    "אלנוקעה",
    "אלנס'ב אלטויל",
    "אלנרג'ס",
    "אלסבאט",
    "אלסוכן",
    "אלעורש",
    "אלעין",
    "אלעריד'",
    "אלקוס'יבה",
    "אלקלעה",
    "אלריאף",
    "אלריחאן",
    "אלשאפעי",
    "אלשבאב",
    "אלשועראא",
    "אלשיח' אלאסד",
    "אלתות",
    "בילאל אבן רבאח",
    "ג'ורת ברק",
    "גרעין ישן",
    "דייר אל-אסד",
    "הארון אלרשיד",
    "ח'אלד אבן אלוליד",
    "טארק אבן זיאד",
    "טה חוסין",
    "כרם אלזאהר",
    "מגרשים",
    "נג'יב מחפוז",
    "ס'לאח אלדין",
    "עומר בן אלח'טאב",
    "עלי אבן אבי טאלב",
    "ראס אלחאכורה",
    "רבאע בשארה",
    "שכ אלביארה",
    "שכ אלברכה",
    "שכ אלח'דר",
    "שכ אלעין",
    "שכ אלעריד'",
    "שכ אלקוס'יבה",
    "שכ אלקלעה",
    "תופיק זיאד",
  ],
  "דייר חנא": ["דייר חנא"],
  "דייר ראפאת": ["דייר ראפאת"],
  דימונה: [
    "אבן גבירול",
    "אבן עזרא",
    "אדר",
    "אומן",
    "אזוב",
    "אזור התעשייה",
    "אחד העם",
    "אלה",
    "אלחריזי",
    "אמירים",
    "אמנון ותמר",
    "אסף",
    "אף על פי כן",
    "אפרסק",
    "אקליפטוס",
    "אקסודוס",
    "אשחר",
    "אשכול",
    "בבא סאלי",
    "בית שערים",
    "בנאי",
    "בר כוכבא",
    "ברזל",
    "ברנהיים",
    "ברקן",
    "בשמת",
    "בת חן",
    "גדיד",
    "גמלא",
    'ד"ר חיים בוגרשוב',
    "דבורה הנביאה",
    "דבורה עומר",
    "דובדבן",
    "דוגית",
    "דוד אלעזר",
    "דוכיפת",
    "דולב",
    "דיזינגוף",
    "דיין משה",
    "דימונה",
    "דלקן",
    "דני",
    "דפנה",
    "דרך בגין",
    "דרך ברלב",
    "דרך גדולי התורה",
    "דרך הצנחנים",
    "דרך השלום",
    "דרך חיים",
    "דרך יצחק שמיר",
    "דרך רבין",
    "האורן",
    "האיריס",
    "האלון",
    "האשל",
    "האתרוג",
    "הבעל שם טוב",
    "הברוש",
    "הגבעה",
    "הגיא",
    "הגפן",
    "הדרור",
    "ההגנה",
    "ההדס",
    "הזית",
    "הזמיר",
    "החיטה",
    "החיל האלמוני",
    "החשמונאים",
    "היונה",
    "היורה",
    "הכינור",
    "הכלנית",
    "הכרם",
    "המכבים",
    "המכתש",
    "המלאכה",
    "המלך אחז",
    "המלך דוד",
    "המלקוש",
    "המסגר",
    "המעלה",
    "המעפיל",
    "המרגנית",
    "הנבל",
    "הנרקיס",
    "הסדנה",
    "הסנהדרין",
    "העליה",
    "העמל",
    "העצמאות",
    "הפועלים",
    "הפסגה",
    "הפעמון",
    "הפרדס",
    "הצאלה",
    "הצבר",
    "הר אדיר",
    "הר ארבל",
    "הר ארגמן",
    "הר גבים",
    "הר גולן",
    "הר גילה",
    "הר גריזים",
    "הר דב",
    "הר הנגב",
    "הר הצופים",
    "הר חזון",
    "הר חרוז",
    "הר חרמון",
    "הר יהל",
    "הר כנען",
    "הר כפיר",
    "הר כרכום",
    "הר כרמל",
    "הר מוריה",
    "הר מירון",
    "הר נבו",
    "הר נס",
    "הר סיני",
    "הר עצמון",
    "הר רביד",
    "הר שגיא",
    "הר שניר",
    "הר תבור",
    "הרב אבוחצירה",
    "הרב אברהם קוק",
    "הרב אליעזר אבוחצירה",
    "הרב אלנקוה",
    "הרב טייב",
    "הרב מרדכי אליהו",
    "הרב עובדיה יוסף",
    "הרבי מלובאויטש",
    "הרדוף הנחלים",
    "הרודיון",
    "הריח",
    "הרימון",
    "הרכס",
    "הרמה",
    "הרצל",
    "השבלים",
    "השיא",
    "השיטה",
    "השיריון",
    "השלוה",
    "השעורה",
    "השקד",
    "התאנה",
    "התירוש",
    "התפוצות",
    "וילות טרומים",
    "ורדימון יצחק",
    "זוויתן",
    "חגי",
    "חוות הגמלים כורנוב",
    "חטיבת אלכסנדרוני",
    "חטיבת גבעתי",
    "חטיבת גולני",
    "חטיבת הנגב",
    "חטיבת הראל",
    "חמת גדר",
    "חנה סנש",
    "חצב",
    "חרוב",
    "חרט",
    "חרמש",
    "חרצית",
    "חרש",
    "טבנקין יצחק",
    "טופח",
    "טוראי דוד שירזי",
    "טיילת הספורט",
    "טיילת עמידר",
    "יגור",
    "יהודה הלוי",
    "יואל",
    "יודפת",
    "יצחק בשביס זינגר",
    "יציאת אירופה",
    "ירושלים",
    "ירמיה",
    "ישעיה",
    "כובשי אילת",
    "כורזין",
    "כיכר אבי שחר",
    "כיכר גבי סבג",
    "כיכר עוזי בלהקר",
    "ככר דנמרק",
    "ככר ז'בוטינסקי",
    "כליל החורש",
    "להב",
    "לובטקין צביה",
    "לום",
    "ליבנה",
    "לילינבלום",
    "לילך",
    'מ"ג המעפילים',
    "מאיר בעל הנס",
    "מבצע ברוש",
    "מבצע חורב",
    "מבצע יואב",
    "מבצע עובדה",
    "מבצר עכו",
    "מגל",
    "מגנט",
    "מדבר יהודה",
    "מדבר סיני",
    "מודיעין",
    "מולדה",
    "מונפור",
    "מורג",
    "מורן החורש",
    "מזור",
    "מחנה עדי",
    "מילרוד אליעזר",
    "מלאכי",
    "מלכה הנרי",
    "מסור",
    "מעברה",
    "מעון טללים",
    "מעלה ההר",
    "מצדה",
    "מרווה",
    "מרחבים",
    "מרים הנביאה",
    "מרכז קליטה",
    "מרצע",
    "משטרה",
    "משעול ארז",
    "משעול ההגנה",
    "משעול החירות",
    "משעול הסנונית",
    "משעול הרעות",
    "משעול התקוה",
    "משעול התקומה",
    "משעול חירם",
    "משעול רגבים",
    "משעול רוגל",
    "משעול שלדג",
    "מתתיהו הכהן",
    "נאות הללי",
    "נאות קטיף",
    "נבטים",
    "נגבה",
    "נוה אביב",
    "נוה חורש",
    "נוה מדבר",
    "נוה שלום",
    "נורית",
    "נחום",
    "נחלת בנימין",
    "נטעים",
    "ניצנה",
    "נפח",
    "נצנים",
    "נצרים",
    'נתיב הל"ה',
    "נתיב הנחש",
    "נתיב הצפורים",
    "נתיב יותם",
    "נתן הנביא",
    'סא"ל עמנואל מורנו',
    "סביון",
    "סדן",
    "סולם",
    "סחלב",
    "סחלבן החורש",
    "סטרומה",
    "סיגלון",
    "סיתונית",
    "סלא איירין",
    "סרן יובל נריה",
    "סרן עודד אמיר",
    "עבדת",
    "עולי הגרדום",
    "עופר אברהם",
    "עזרא ונחמיה",
    "עיינות",
    "עין גדי",
    "עלומים",
    "עלית הנוער",
    "עמוס",
    "ערוגות",
    "פ' שמואל יוסף עגנון",
    "פארק תעשיה רותם",
    "פונדק דרכים",
    "פטיש",
    "פטרה",
    "פינטו חיים",
    "פלדה",
    "פלס",
    "פקיעין",
    "פרג",
    "פרופ' אהרון צ'חנובר",
    "פרופ' דן שכטמן",
    "פרופ' דניאל כהנמן",
    "פרופ' ישראל אומן",
    "פרופ' עדה יונת",
    "פרחי ארצנו",
    "צבעוני",
    "צוקרמן יצחק רחבה",
    "צורית",
    "ציפורי",
    "ציפורן",
    "צפצפה",
    "קארו יוסף",
    "קדמה",
    "קדש",
    "קהילת בריטניה",
    "קהילת העבריים",
    "קומראן",
    "קטלב",
    "קיסריה",
    "קלעת נמרוד",
    "קלרינט",
    "קניון פרץ סנטר",
    "קציעות",
    "קצרין",
    "קריה למחקר גרעיני",
    "קרית המלאכה",
    "קרית העמל",
    "קרית התעשיה",
    "קרית חנוך",
    "קרליבך",
    "קרני חיטין",
    "רבי נחמן מאומן",
    "רוטשילד",
    "רזיאל דוד",
    "רחבת אורון",
    "רחבת האיסיים",
    'רחבת האצ"ל',
    "רחבת האשלג",
    "רחבת הבנים",
    'רחבת הלח"י',
    'רחבת הנח"ל',
    "רחבת הסוכנות",
    'רחבת הפלמ"ח',
    "רחבת יונתן",
    'רחבת רמב"ם',
    'רחבת רש"י',
    "רמת עופר",
    "רקפת",
    "ש 4 קומות",
    "ש אסבסט א ב",
    "ש אסבסט ג",
    "ש בתי אבן",
    "ש הנצחון",
    "ש טרומיים",
    "ש לדוגמה",
    "ש קומותיים",
    "ש קליפורניה",
    "ש רכבת",
    "שאול המלך",
    "שבזי שלום",
    "שבטי ישראל",
    "שד בן גוריון",
    "שד גולדה מאיר",
    "שד הנשיא",
    "שד יגאל אלון",
    "שדה יצחק",
    "שוק עירוני",
    "שושן צחור",
    "שושנים",
    "שטרן יאיר",
    "שיבטה",
    "שיזף",
    "שיקמה",
    "שכ בנה ביתך",
    "שכ בסט",
    "שכ הגבעה",
    "שכ הערבה",
    "שכ הר נוף",
    "שכ חכמי ישראל",
    "שכ יוספטל",
    "שכ כפר הסטודנטים",
    "שכ מלכי ישראל",
    "שכ ממשית",
    "שכ נוה גן",
    "שכ נווה דוד",
    "שכ נופי מדבר",
    "שכ פרחי ארצנו",
    "שכ קדמה",
    "שכ שבטי ישראל",
    "שכ שבעת המינים",
    "שכ' השחר",
    "שכון 3 קומות",
    "שמעון בר יוחאי",
    'תא"ל אביגדור קהלני',
    "תל אפק",
    "תל בית אל",
    "תל גבעון",
    "תל דן",
    "תל חמה",
    "תל לכיש",
    "תל מגידו",
    "תל מקנה",
    "תל ערד",
    "תל פורן",
    "תל ציפורי",
    "תל קיסון",
    "תל שילה",
  ],
  דישון: ["דישון"],
  דליה: ["דליה"],
  דלתון: ["דלתון"],
  דמיידה: ["דמיידה"],
  דן: ["דן"],
  דפנה: ["בית ראשון במולדת", "דפנה"],
  דקל: ["דקל", "ש קרוונים"],
  "דריג'את": [
    "דריג'את",
    "רח 10",
    "רח 20",
    "רח 30",
    "רח 1",
    "רח 2",
    "רח 12",
    "רח 22",
    "רח 32",
    "רח 3",
    "רח 4",
    "רח 14",
    "רח 24",
    "רח 34",
    "רח 5",
    "רח 6",
    "רח 16",
    "רח 26",
    "רח 36",
    "רח 7",
    "רח 8",
    "רח 18",
    "רח 28",
    "רח 38",
    "רח 9",
    "שכ ב",
  ],
  האון: ["האון"],
  הבונים: [
    "גלים",
    "דרך הים",
    "הבונים",
    "החרוב",
    "הצדפים",
    "כרמל וים",
    "נוף ים",
    "סמטת החצב",
    "סמטת הנרקיסים",
    "סמטת הרקפות",
    "סמטת נר הלילה",
    "שונית",
  ],
  הגושרים: ["בית ראשון", "הגושרים"],
  "הדר עם": [
    "האסיף",
    "האקליפטוס",
    "הבציר",
    "הברוש",
    "הדר עם",
    "ההדרים",
    "ההולנדים",
    "הזורע",
    "החריש",
    "היבול",
    "הלימון",
    "המסיק",
    "הנוטע",
    "הפרדס",
    "הקטיף",
    "הקציר",
    "הראשונים",
    "התירוש",
    "התפוז",
    "זו הדרך",
    "נבטים",
    "שדרת הכפר",
  ],
  "הוד השרון": [
    "אביב",
    "אדר",
    "אהבה",
    "אהוד בן גרא",
    "אהרונסון אהרון",
    "אזור תעשיה",
    "אזור תעשיה נוה נאמן",
    'אח"י דקר',
    "אחוה",
    "איזמרגד",
    "איילת השחר",
    "אייר",
    "אילת",
    "אלול",
    "אלוף הנצחון",
    "אלי כהן",
    "אלי מצד",
    "אליהו הנביא",
    "אלכסנדר הגדול",
    "אלעזר",
    "אלתרמן",
    "אם הקבוצות",
    "אנה פרנק",
    "אנצילביץ",
    "אנשי בראשית",
    "אנשי הכנסת הגדולה",
    "אסירי ציון",
    "אסף הרופא",
    "אפק",
    "ארבל",
    "ארלוזורוב",
    "אשכול",
    "בארי",
    "בורוכוב",
    "ביאליק",
    "ביח גן מגד",
    "ביכורים",
    "בית אורן",
    "בית קמה",
    'בן אב"י איתמר',
    "בן גוריון",
    "בן גמלא יהושע",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן צבי יצחק",
    "בני ברית",
    "בצת",
    "בר לב חיים",
    "ברקת",
    "בשביס זינגר",
    "בת חן",
    "גבעת התצפית",
    "גברעם",
    "גולדה מאיר",
    "גולומב",
    "גור מרדכי",
    "גורדון",
    "גזית",
    "גיתית",
    "גלבוע",
    "גלגל המזלות",
    "גני צבי",
    "גרונר דב",
    "גרניט",
    "גשמי ברכה",
    "דבורה",
    "דגניה",
    "דגנית",
    "דובדבן",
    "דוד אלעזר",
    "דוד המלך",
    "דוכיפת",
    "דור ההמשך",
    "דורי יעקב",
    "דיין משה",
    "דפנה",
    "דרך הים",
    "דרך כפר הדר",
    "דרך מגדיאל",
    "דרך רמתים",
    "האגודה",
    "האודם",
    "האוכמניות",
    "האורנים",
    "האיחוד",
    "האילנות",
    "האירוס",
    'האצ"ל',
    "האשל",
    "הבבלי",
    "הבוסתן",
    "הבנאי",
    "הבנים",
    "הברוש",
    "הבריח",
    "הגאולה",
    "הגולן",
    "הגיא",
    "הגיבור",
    "הגלעד",
    "הגן",
    "הגנים",
    "הגפן",
    "הגשר",
    "הדסים",
    "הדקלים",
    "הדרור",
    "הדרים",
    "ההגנה",
    "ההכשרות",
    "ההסתדרות",
    "הוד השרון",
    "הורד",
    "הזוהר",
    "החומש",
    "החייל",
    "החלוצים",
    "החקלאי",
    "החרמון",
    "החרש",
    "החשמונאים",
    "הידיד",
    "היובלים",
    "היער",
    "הירדן",
    "הכהנים",
    "הכלנית",
    "הכרם",
    "הכרמל",
    "הכשרת הישוב",
    "הלויים",
    'הלח"י',
    "המגן",
    "המדרגות",
    "המוסדות",
    "המחתרות",
    "המיסד",
    "המכבים",
    "המכונאי",
    "המנחם",
    "המסגר",
    "המעבר",
    "המעגל",
    "המעלה",
    "המעלות",
    "המעפיל",
    "המקשר",
    "המתנחלים",
    "הנגב",
    "הנגר",
    "הנוטרים",
    "הנחל",
    "הנרקיס",
    "הסביון",
    "הסדן",
    "הסוכנות",
    "הסרגל",
    "העבודה",
    "העובד הציוני",
    "העליות",
    "העמל",
    "העמק",
    "הערבה",
    "הפטיש",
    "הפטל",
    "הפרדס",
    "הפרחים",
    "הפרסה",
    "הצבי",
    "הצבעוני",
    "הצברים",
    "הציונות",
    "הצנחנים",
    "הר מירון",
    "הר שגיא",
    "הראשונים",
    "הרב ביטון",
    "הרב שלום נגר",
    "הרדוף",
    "הרמה",
    "הרצוג חיים",
    "הרצל",
    "הרצפלד אברהם",
    "הרקון",
    "הרשות",
    "השומר",
    "השושנים",
    "השחר",
    "השיטה",
    "השלום",
    "השלושה",
    "השקמים",
    "התחיה",
    "התכלת",
    "התלמיד",
    "התמר",
    "התפוח",
    "התקומה",
    "וייצמן חיים",
    "ז'בוטינסקי",
    "זוויתן",
    "זייד אלכסנדר",
    "זמיר",
    "זקיף",
    "חבצלת",
    "חודשי השנה",
    "חוחית",
    "חולות נודדים",
    "חטיבה שבע",
    "חטיבת גבעתי",
    "חטיבת גולני",
    "חטיבת הראל",
    "חטיבת יפתח",
    "חלוקי נחל",
    "חליל",
    "חלמיש",
    "חמנית",
    "חנקין",
    "חצב",
    "חצוצרה",
    "חרצית",
    "חשוון",
    "טבת",
    "טווס",
    "טופז",
    "טרומפלדור",
    "טשרניחובסקי",
    "י ל פרץ",
    "ידידות",
    "ידין יגאל",
    "יהודה",
    "יהונתן",
    "יהושע בן נון",
    "יהלום",
    "יוחנן",
    "יונק הדבש",
    "יורדי הים",
    "יחזקאל",
    "יסוד המעלה",
    "יסמין",
    "יצחק שדה",
    "יקינטון",
    "ירושלמי",
    "ירמוך",
    "ירמיהו",
    "ישורון",
    "ישעיהו",
    "ישפה",
    "ישראלי הרופא",
    "כוכבן",
    "כזיב",
    "כיכר ותיקי העיר",
    "כינור",
    "ככר אוסישקין",
    "ככר המגינים",
    "ככר טוביה",
    "כנרת",
    "כסלו",
    "כפר הדר",
    "כפר מנחם",
    "כרכום",
    "לב השכונה",
    "לוטם",
    "לילך",
    "לסקוב חיים",
    "לשם",
    "מאנגר איציק",
    "מבוא חמה",
    "מבוא קדם",
    "מבוי החרש",
    "מבצע משה",
    "מבצע קדש",
    "מבצע שלמה",
    "מגדיאל",
    "מודיעין",
    "מולדת",
    "מוסד מוסנזון",
    "מורדי הגטאות",
    "מורשת",
    "מזל אריה",
    "מזל בתולה",
    "מזל גדי",
    "מזל דגים",
    "מזל דלי",
    "מזל טלה",
    "מזל מאזניים",
    "מזל עקרב",
    "מזל קשת",
    "מזל שור",
    "מזל תאומים",
    "מיטל",
    "מיתר",
    "מנוחה ונחלה",
    "מנצח",
    "מעיני",
    "מפוחית",
    "מצדה",
    "מצובה",
    "מצילתיים",
    "מרגוע",
    "מרגלית",
    "מרחביה",
    "מרים החשמונאית",
    "משאבים",
    "משושים",
    "משעול הגפנים",
    "מתתיהו",
    "נופית",
    "נופך",
    "נורית",
    "נחל בשור",
    "נחל דליות",
    "נחל הדס",
    "נחל הדר",
    "נחל ירקון",
    "נחל ערוגות",
    "נחל פרצים",
    "נחל צין",
    "נחל קנה",
    "נחליאלי",
    "נחשון",
    "נטעים",
    "ניצנים",
    "נעורים",
    "נצח ישראל",
    "סאלד הנריאטה",
    "סוקולוב",
    "סיון",
    "סיני",
    "סיתונית",
    "סמ אבני דרך",
    "סמ אגוז",
    "סמ אדמונית",
    "סמ אילת",
    "סמ בית הבד",
    "סמ האלון",
    "סמ האקליפטוס",
    "סמ האתרוג",
    "סמ הדבש",
    "סמ הזית",
    "סמ החיטה",
    'סמ הי"ח',
    "סמ הירק",
    "סמ הלולב",
    "סמ הר גילה",
    "סמ הרימון",
    "סמ השעורה",
    "סמ השריג",
    "סמ התאנה",
    "סמ נוגה",
    'סמ ניל"י',
    "סמ תירוש",
    "סמטת אבן",
    "סמטת הרינה",
    "סנונית",
    "סנש חנה",
    "סעדיה גאון",
    "ספיר",
    "סקסופון",
    "סתיו",
    "עגנון",
    "עוגב",
    "עזר וייצמן",
    "עין דור",
    "עין חי",
    "עירית",
    "עלית הנוער",
    "עקלתון",
    "פדויים",
    "פיינברג אבשלום",
    'פלמ"ח',
    "פן אלכסנדר",
    "פסנתר",
    "פעמונית",
    "פרג",
    "פשוש",
    "צ'לו",
    'צה"ל',
    "צופית",
    "צור",
    "צורן",
    "צליל",
    "צלמון",
    "קבוץ גלויות",
    "קורצ'אק יאנוש",
    "קיבוץ דליה",
    "קיבוץ יסודות",
    "קישון",
    "קציר אפריים",
    "רותם",
    "רזיאל דוד",
    'רמב"ם',
    "רמות מנחם",
    "רמז",
    "רעות",
    "רקפת",
    'רש"י',
    "ש בלוקונים",
    "ש גיורא",
    "ש גיל עמל",
    "ש מזרחי",
    "ש נוה הדר",
    "ש נוה נאמן",
    "ש נוה עובד",
    "ש סולל בונה",
    "ש עמידר צריפים",
    "ש עממי",
    "ש פועלים א",
    "ש פועלים ב",
    "ש פתוח",
    "ש רמת הדר",
    "ש רסקו",
    "ש שרת",
    "ש תימנים מגדיאל",
    "שאול המלך",
    "שבו",
    "שבט",
    "שבי ציון",
    "שביל החלב",
    "שביל התיכון",
    "שביל התפוזים",
    "שבתאי",
    "שד האוטהקר",
    "שד הנשיאים",
    'שז"ר זלמן',
    "שחל",
    "שחם",
    "שחף",
    "שטיין",
    "שטרן אברהם",
    "שיזף",
    "שילה",
    "שכ הכשרת הקיבוצים",
    "שכ הנביאים",
    "שכ העובד הציוני",
    "שכ מלכים ושופטים",
    "שכ רמתיים",
    "שכון חדש",
    "שלדג",
    "שלום עליכם",
    "שלומציון",
    "שלמה המלך",
    "שמגר בן ענת",
    "שמואל הנביא",
    "שמיר",
    "שמעון הצדיק",
    "שניר",
    "שנת היובל",
    "שער העמקים",
    "שפרינצק",
    "שקנאי",
    "שרת משה",
    "ששת הימים",
    "תבור",
    "תווים",
    "תוף",
    "תור",
    "תל דן",
    "תל יצחק",
    "תמוז",
    'תרפ"ה',
    "תרשיש",
    "תשרי",
  ],
  הודיה: ["הודיה"],
  הודיות: ["הודיות"],
  "הוואשלה (שבט)": ["הוואשלה (שבט)"],
  "הוזייל (שבט)": [
    "אבו זאיד",
    "אל טורי",
    "הוזייל (שבט)",
    "סקר הזיל",
    "עי נחל גרר",
  ],
  הושעיה: ["הושעיה"],
  הזורע: ["הזורע"],
  הזורעים: ["הזורעים"],
  החותרים: ["החותרים"],
  היוגב: ["היוגב"],
  הילה: ["הילה"],
  המעפיל: ["המעפיל"],
  הסוללים: ["הסוללים"],
  העוגן: ["העוגן"],
  "הר אדר": [
    "האורן",
    "האלה",
    "האלון",
    "הברוש",
    "הגיא",
    "הערבה",
    "הר אדר",
    "השקד",
    "מבוא הארז",
    "מבוא הבוסתן",
    "מבוא הגפן",
    "מבוא ההדס",
    "מבוא הזית",
    "מבוא החורש",
    "מבוא החלילים",
    "מבוא החרוב",
    "מבוא היער",
    "מבוא הכפר",
    "מבוא הכרמים",
    "מבוא הלבונה",
    "מבוא המטע",
    "מבוא הנטף",
    "מבוא הפסגה",
    "מבוא הרועים",
    "מבוא הרימון",
    "מבוא הרכס",
    "מבוא התאנה",
    "מבוא התומר",
    "נוף הרים",
    "שד הראל",
  ],
  "הר גילה": [
    "דרך ההר",
    "האלה",
    "האלון",
    "הזית",
    "הליבנה",
    "הקטלב",
    "הר גילה",
    "הרימון",
    "יורם פיצ'י בן מאיר",
    "משעול החלמונית",
    "משעול החצב",
    "משעול המרווה",
    "משעול הסלעית",
    "משעול העיט הזהוב",
    "משעול העפרוני",
    "משעול הצופית",
    "משעול הקורנית",
    "משעול הקיכלי",
    "משעול כליל החורש",
  ],
  "הר עמשא": ["הר עמשא"],
  הראל: ["הראל"],
  הרדוף: ["הרדוף"],
  הרצליה: [
    "אבוקה",
    "אבטליון",
    "אביב",
    "אביגיל",
    "אבן אודם",
    "אבן גבירול",
    "אבן עזרא",
    "אבן שפרוט",
    "אבן תיבון",
    "אבניים",
    "אבנר",
    "אברבנאל",
    "אגדת דשא",
    "אגס",
    "אגרת תימן",
    "אהבת ציון",
    "אהרונסון דשה",
    "אונקלוס",
    "אוסישקין",
    "אופיר",
    "אופק אוריאל",
    "אוצר הצמחים",
    "אור",
    "אור החיים",
    'אז"ר',
    "אזור התעשיה",
    "אזור פתוח",
    "אזור שפת הים",
    "אזור תעשיה חדש",
    'אח"י אילת',
    'אח"י דקר',
    "אחד העם",
    "אחווה",
    "אייבי נתן",
    "איילת חן",
    "איינשטיין",
    "איסר הראל",
    "אירוסים",
    "אל על",
    "אלדד הדני",
    "אלומות",
    "אלוף אלון יגאל",
    "אלחריזי",
    "אלי כהן",
    "אלכסנדר ינאי",
    "אלן טיורינג",
    "אלפסי",
    "אלקלעי",
    "אלרואי דוד",
    "אלתרמן נתן",
    "אמירים",
    "אנדה",
    "אנה פרנק",
    "אנילביץ",
    "אנפה",
    "אסירי ציון",
    "אסתר המלכה",
    "אסתר ראב",
    "אף על פי כן",
    "אפרים",
    "אפרסמון",
    'אצ"ל',
    "ארבל",
    "ארגוב סשה",
    "אריאל",
    "אריאל שרון",
    "אריק איינשטיין",
    "אריק לביא",
    "ארלוזורוב",
    "ארן זלמן",
    "ארנסט וסוזי וודק",
    "בארי",
    "בבה אידלסון",
    "בובר מרטין",
    "בוסל",
    "בוקר",
    "בורוכוב",
    "בורלא יהודה",
    "בורר יהודה",
    "בזל",
    'ביל"ו',
    "בילינסון",
    "בית אבות סרינה",
    "בית חלומותי",
    'בית"ר',
    "בלפור",
    "בן אליעזר אריה",
    "בן גוריון",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן סרוק מנחם",
    "בן שלום מאיר",
    "בני בנימין",
    "בצלאל",
    "בר אילן",
    "בר יוחאי",
    "בר כוכבא",
    "בראון",
    "ברוך",
    "ברית עולם",
    "ברכה צפירה",
    "ברנר",
    "ברק",
    "ברקת",
    "ברש אשר",
    "בת שבע",
    "ג'ו עמר",
    "גבורי עציון",
    "גבעת החלומות",
    "גבעת הסופר",
    "גבעת הפרחים",
    "גבעת התחמושת",
    "גדעון האוזנר",
    "גדעון השופט",
    "גובר רבקה",
    "גולדברג לאה",
    "גולדה מאיר",
    "גולומב אליהו",
    "גורדון א ד",
    "גיל",
    "גינת רפול",
    "גיסין",
    "גיתית",
    "גלגל הפלדה",
    "גלי תכלת",
    "גלילות",
    "גני הרצליה",
    "גרונר דב",
    "גרינברג אורי צבי",
    "גרמה זכריה",
    "גת",
    "דבורה עומר",
    "דגניה",
    "דובדבן",
    "דובנוב",
    "דוד אלעזר",
    "דוד המלך",
    "דודאים",
    "דוכיפת",
    "דולב",
    "דון יוסף הנשיא",
    "דורי",
    "דיור עממי",
    "דינור בן ציון",
    "דיצה",
    "דליה",
    "דליה רביקוביץ",
    "דן",
    "דפנה",
    "דרור",
    "דרך אלטנוילנד",
    "דרך דיין משה",
    "דרך ירושלים",
    "דרך רמת השרון",
    "דרך תל אביב",
    "ה באייר",
    "האבקליפטוס",
    "האגוז",
    "האוניברסיטה",
    "האורנים",
    "האילנות",
    "האלה",
    "האלון",
    "האסיף",
    'האר"י',
    "הארז",
    "האשל",
    "האתרוג",
    "הבוסתן",
    "הבנים",
    "הבעל שם טוב",
    "הברוש",
    "הבריגדה היהודית",
    "הבשור",
    "הבשן",
    "הגאון מוילנה",
    "הגבורה",
    "הגדוד העברי",
    "הגלבוע",
    "הגליל",
    "הגפן",
    "הדסה",
    "הדקל",
    "הדר",
    "ההגנה",
    "ההדסים",
    "ההסתדרות",
    "הואדי",
    "הוז דב",
    "הולצברג שמחה",
    "הורדים",
    "הזוהר",
    "הזז חיים",
    "הזיתים",
    "הזמר העברי",
    "החורש",
    "החושלים",
    "החיצים",
    "החלום ופשרו",
    "החלוץ",
    "החנית",
    "החצב",
    "החרוב",
    "החרמון",
    "החרמש",
    "החרצית",
    "החשמונאים",
    "הטייסים",
    'הי"א',
    "הידיד",
    "היורה",
    "היסמין",
    "הירדן",
    "הירש",
    "היתד",
    'הכ"ג',
    "הכוזרי",
    "הכנסת",
    "הכרם",
    "הכרמל",
    "הליבנה",
    "הלילך",
    "הלל",
    "הלני המלכה",
    "המגינים",
    "המדע",
    "המדרון",
    "המלקוש",
    "המנהרה",
    "המנופים",
    "המסילה",
    "המעבר",
    "המעיין",
    "המעלות",
    "המעפילים",
    "המפרש",
    "המקובלים",
    "המרגנית",
    "המרי",
    "המתמיד",
    "הנביאים",
    "הנדיב",
    "הנוטרים",
    "הנוריות",
    "הניצנים",
    "הנעורים",
    "הנרקיסים",
    "הנשיא יצחק בן צבי",
    "הס",
    "הסדנאות",
    "הסיגליות",
    "הסייפנים",
    "העוגן",
    "העופר",
    "העירית",
    "העלומים",
    "העליה השניה",
    "העצמאות",
    "הערבה",
    "הפועל",
    "הפיוס",
    "הפרדסים",
    "הפרח בגני",
    "הפרחים",
    "הפרטיזנים",
    "הפרסה",
    "הצברים",
    "הצדף",
    "הצנחן אבא",
    "הצנחנים",
    "הצפצפות",
    "הקדמה",
    "הקוממיות",
    "הקונגרס הציוני",
    "הקוצרים",
    "הקטיף",
    "הקסם",
    "הקרן הקימת",
    "הר המוריה",
    "הר מירון",
    "הר נבו",
    "הר סיני",
    "הר עצמון",
    "הר שומרון",
    "הראובני",
    "הראשונים",
    "הרב גורן",
    "הרב הלפרין מרדכי",
    "הרב יוסף סאסי",
    "הרב יעקב אבו חצירא",
    "הרב מימון",
    "הרב ניסים יצחק",
    "הרב עובדיה יוסף",
    "הרב עוזיאל",
    "הרב פישמן",
    "הרב קוק",
    "הרדופים",
    "הרותם",
    "הרי גולן",
    "הרי גלעד",
    "הרכבת",
    "הרמונים",
    "הרצוג",
    "הרצליה",
    "הרצליה ב",
    "הרצפלד אברהם",
    "הרקפות",
    "השולמית",
    "השומר",
    "השונית",
    "השופטים",
    "השושנים",
    "השיטה",
    "השקדים",
    "השקמה",
    "השרון",
    "התאנה",
    "התבור",
    "התדהר",
    "התומר",
    "התנאים",
    "התקוה",
    "וולפסון",
    "ויזנטל שמעון",
    "ויינשנק יחזקאל",
    "וינגייט",
    "ויצמן",
    "ויתקין",
    "ורבורג אוטו",
    "ז'בוטינסקי",
    "זאב",
    "זבולון",
    "זהר טל",
    "זיסו",
    "זמנהוף",
    'חב"ד',
    "חבצלת השרון",
    "חברון",
    "חדוה",
    "חובבי ציון",
    "חובות הלבבות",
    "חוחית",
    "חומה ומגדל",
    "חוני המעגל",
    "חורב",
    "חזקיהו המלך",
    "חירם",
    "חנה מרון",
    "חנה רובינא",
    "חנקין",
    "חפצי בה",
    "חרש",
    'חת"ם סופר',
    "טאובר אמה",
    "טביב מרדכי",
    "טבנקין יצחק",
    "טיילת חיים הרצוג",
    "טיראן",
    "טנא",
    "טרומפלדור",
    "טשרניחובסקי",
    "יבנה",
    "יבניאלי",
    "יד חרוצים",
    "ידין יגאל",
    "יהדות הדממה",
    "יהודה הלוי",
    "יהודה המכבי",
    "יהודה הנשיא",
    "יהונתן",
    "יהושע בן נון",
    "יהושפט המלך",
    "יהלום",
    "יואב",
    "יודפת",
    "יוחנן הסנדלר",
    "יורדי ים",
    "יותם",
    "יחיעם",
    'יל"ג',
    "ילן",
    "ינאית",
    "יסוד המעלה",
    "יעבץ",
    "יעל",
    "יערה",
    "יפה אריה לייב",
    "יפה ירקוני",
    "יפהר פסח",
    "יפתח",
    "יצחק שדה",
    "יצחק שמיר",
    "יציאת אירופה",
    "יקינטון",
    "ירמיהו",
    "ישעיהו ישראל",
    'כ"ט בנובמבר',
    "כבוש העבודה",
    "כוכב",
    "ככר אובה אקלה",
    "ככר דגון יואב",
    "ככר דה שליט",
    "ככר האוזנר גדעון",
    "ככר המתנדבים",
    "ככר הנס פריץ",
    "ככר הנשיא",
    "ככר הציונות",
    "ככר יעל רום",
    "ככר יקירי העיר",
    "ככר לוחמות השואה",
    "ככר מודעי",
    "ככר מיכאלי",
    "ככר סחארוב",
    "ככר עובד בן עמי",
    "ככר פאבל פרנקל",
    "ככר רוטרי",
    "כלנית",
    "כנפי נשרים",
    "כנרת",
    "כסופים",
    "כפר הדייגים",
    "כצנלסון",
    "כרכום",
    "לביא שמעון",
    "לובטקין צביה",
    "לוטוס",
    "לוטם",
    "לוי אשכול",
    "לוין שמעון זאב",
    'לח"י',
    "ליבוביץ ישעיהו",
    "ליברמן ישראל",
    "ליל הגשרים",
    "ליפסקי",
    "לכיש",
    "למדן יצחק",
    "לסקוב חיים",
    "לשם",
    "מאיר אריאל",
    "מאנגר",
    "מאפו",
    "מארל",
    "מבצע קדש",
    "מגיני נגבה",
    "מגן דוד",
    "מדינת היהודים",
    'מהר"ל',
    "מובשוביץ בנימין",
    "מודיעין",
    "מוהליבר",
    "מולדת",
    "מולכו שלמה",
    "מונטיפיורי",
    "מוצקין",
    "מורדות ים",
    "מורדי הגטאות",
    "מורי עופרי",
    "מורן",
    'מזא"ה',
    'מיכ"ל',
    "מלכי יהודה",
    "מלכי ישראל",
    "מלכין שרה",
    "מנדלבלט צבי",
    "מנדלי מוכר ספרים",
    "מנורה",
    "מע גלילות",
    "מע נוף ים",
    "מעונות שרה",
    "מעלה חנוך",
    "מעלה יהודית",
    "מעלה נחמה",
    "מעפילי מרוקו",
    "מצדה",
    "מצפה ים",
    "מרבד הקסמים",
    "מרווה",
    "מרזוק",
    "מרחביה",
    "מרים החשמונאית",
    "מרינה הרצליה",
    "מרכוס דוד",
    "משה",
    "משכית",
    "משק עזרא",
    "מתתיהו",
    "נאות הדקלים",
    "נבו יוסף",
    "נג'ר יצחק",
    "נדבה יוסף",
    "נוגה",
    "נוה אמירים",
    "נוה נמרוד",
    "נוה פועלים",
    "נוף שדמות",
    "נופר",
    "נורדאו",
    "נורוק",
    "נחלת עדה",
    "נחמה הנדל",
    "נחמיה",
    "נחשון",
    'ניל"י',
    "ניר",
    "נצח ישראל",
    'נתיב הל"ה',
    "נתיבות",
    "נתן אלבז",
    "נתניהו יוני",
    "סביון",
    "סולץ אלכסנדר",
    "סומסום",
    "סוקולוב",
    "סטרומה",
    "סידני עלי",
    "סירני",
    "סירקין",
    "סמ בני ברית",
    "סמ ברנדיס",
    "סמ דגן",
    "סמ זיו אור",
    "סמ יפת בן זכריה מגד",
    "סמ מקדש מלך",
    "סמ נוף ילדות",
    "סמ ניסנוב",
    "סמ עינב",
    "סמ צמרות",
    "סמדר",
    "סמילנסקי משה",
    "סנה משה",
    "סנהדרין",
    "סנונית",
    "סנפיר",
    "סנש חנה",
    "סעדיה גאון",
    "ספיר",
    "עגנון",
    "עדה לאבלייס",
    "עדס",
    "עוזי חיטמן",
    "עוזיהו המלך",
    "עולי בבל",
    "עולי הגרדום",
    "עזרא הסופר",
    "עין גדי",
    "עינב",
    "עמיאל",
    "עמיר",
    "עפרוני",
    "ערוגות",
    "ערמונים",
    "עשרת הרוגי מלכות",
    "פולג",
    "פוסטר הרולד",
    "פועלי ציון",
    "פורצי הדרך",
    "פטריה",
    "פינס",
    "פינסקר",
    'פלמ"ח',
    "פנקס",
    "פנת חיים",
    "פסמן",
    "פעמונית",
    "פרגים",
    "פרץ",
    "פתח תקווה",
    "צבעוני",
    "צדק",
    'צה"ל',
    "צופית",
    "צוקרמן יצחק",
    "ציפורי",
    "צלע דרום",
    "צלע צפון",
    "צמרות",
    "צעירי ציון",
    "קארו יוסף",
    "קבוץ גלויות",
    "קדושי השואה",
    "קדימה",
    "קדיש לוז",
    "קהילת ציון",
    "קורן",
    "קורצ'אק",
    "קישון אפרים",
    "קלוזנר",
    "קלישר",
    "קמין ברוך",
    "קפלן",
    "קפלנסקי",
    "קציר אהרון",
    "קרית רבין",
    "קרן היסוד",
    "קרת",
    'רא"ל ליפקין שחק',
    'רא"ל משה לוי',
    "ראש פינה",
    "ראשון לציון",
    "רבי עקיבא",
    "רביבים",
    "רבן גמליאל",
    "רבנו תם",
    "רגבים",
    "רוזה גינוסר",
    "רוזן פנחס",
    "רוחמה",
    "רופין",
    "רות",
    "רזיאל דוד",
    "רחבעם זאבי",
    "רחל",
    "רחל המשוררת",
    "ריחן",
    "ריינס",
    "רייק חביבה",
    "רינה",
    "רכב ישראל",
    'רמב"ם',
    "רמז",
    'רמח"ל',
    "רמת אליהו",
    "רמת ים",
    'רנ"ק',
    "רפי וקנין",
    'רש"י',
    'רשב"ם',
    "ש אזבסטונים",
    "ש אחים",
    "ש אכדיה",
    "ש אמריקאים",
    "ש אקדמאים",
    "ש בטיש",
    "ש בלוקונים",
    "ש גן רשל",
    "ש הדרום",
    'ש הפומ"ז',
    "ש התימנים",
    "ש ויצמן",
    "ש חדש רמת השרון",
    "ש חיילים משוחררים",
    "ש יד התשעה",
    "ש לומיר",
    "ש מפדה אזרחי",
    "ש משהב",
    "ש משכנות",
    "ש נוה ישראל",
    "ש נוה עמל",
    "ש נוה עמל אזבסט",
    "ש נוה תמר",
    "ש נוף ים",
    "ש עולים",
    "ש עממי",
    "ש פרוגרסיבים",
    "ש ציונים כלליים",
    "ש צריפים שוודיים",
    "ש רוגובין",
    "ש רמת ים",
    "ש רסקו",
    "שאול המלך",
    "שארית הפליטה",
    "שבזי",
    "שבט מנשה",
    "שבטי ישראל",
    "שביל האדומית",
    "שביל היסמין",
    "שביל הרב שמן יוסף",
    "שביל השיטה",
    "שבעת הכוכבים",
    "שד אבא אבן",
    "שד אמנון ותמר",
    "שד בגין מנחם",
    'שד ח"ן',
    "שד לנצט יעקב",
    "שד מיכאלי בן ציון",
    "שד נוה עובד",
    'שד"ל',
    "שדה התעופה",
    "שדרות התשעים ושלש",
    "שוחט ישראל",
    "שוידלסון",
    "שומרון דן",
    "שושנה דמארי",
    'שז"ר',
    'שח"ל',
    "שחרית",
    "שטורמן",
    "שטרן אברהם",
    "שיר הרעות",
    "שירת גאולים",
    "שירת הזמיר",
    "שכ גבעת הסופר",
    "שכ הפועלים",
    "שכ הרצליה פיתוח",
    "שכ חילים משוחררים",
    "שכ כפר רשף",
    "שכ מזרחי",
    "שכ מרכז",
    "שכ נווה עובד",
    "שכ נחלת ישראל",
    "שכ צמרות",
    "שכ תשליב",
    "שכון גורדון",
    "שלוה",
    "שלום עליכם",
    "שלום רוזנפלד",
    "שלומציון המלכה",
    "שלונסקי",
    "שלמה המלך",
    "שמאי",
    "שמואל הנגיד",
    "שמיר משה",
    "שמעוני דוד",
    "שמעיה",
    "שמר נעמי",
    "שמשון הגבור",
    "שניאור זלמן",
    "שנקר אריה",
    "שער הים",
    "שפיים",
    "שפינוזה",
    "שפירא צבי",
    "שפרינצק",
    "שרה",
    "שרעבי",
    "שרת משה",
    "תור הזהב",
    "תל חי",
    "תמר",
    'תע"ש',
    "תפוח",
    "תפוח חינני",
    "תרשיש",
  ],
  הררית: ["הררית", "יחד"],
  "ורד יריחו": ["ורד יריחו"],
  ורדון: ["ורדון"],
  "זבארגה (שבט)": ["זבארגה (שבט)", "תל אל מלח", "תל ערד"],
  זבדיאל: ["זבדיאל"],
  זוהר: ["זוהר"],
  זיקים: ["זיקים"],
  זיתן: ["הגפן", "הדקל", "החרוב", "השלושה", "זיתן"],
  "זכרון יעקב": [
    "אגס",
    "אודם",
    'אח"י אילת',
    'אחוזת ניל"י',
    "איילת השחר",
    "אלומות",
    "אלון יגאל",
    "אלונים",
    "אלי כהן",
    "אנפה",
    "אף על פי כן",
    "אפרסק",
    'אצ"ל',
    "אקסודוס",
    "אשכול",
    "בוסתן",
    "בזלת",
    "ביאליק",
    "בית הבד",
    "בית צורי אליהו",
    "בית צידה",
    "ברק",
    "ברקת",
    "גאולים",
    "גבעת עדן",
    "גדיש",
    "גולומב אליהו",
    "גורן",
    "גזית",
    "דבורה",
    "דורות",
    "דרור",
    "דרך אבשלום",
    "דרך אהרן",
    "דרך הגרנות",
    "דרך היקב",
    "דרך הרכבת",
    "דרך השיטה",
    "דרך פינלס",
    "דרך שרה",
    "האורנים",
    "הארזים",
    "הבנים",
    "הברושים",
    "הגדוד העברי",
    "הגדעונים",
    "הגפן",
    "הגרניט",
    "הגת",
    "הדגן",
    "הדובדבן",
    "הדס",
    "הדרים",
    "ההגנה",
    "הזוהר",
    "הזית",
    'החי"ל',
    "החיטה",
    "החינוך העברי",
    "החלוצים",
    "החצב",
    "החרוב",
    "היין",
    "הכובשים",
    "הכלנית",
    "הכרכום",
    "הכרם",
    "הלל יפה",
    "המגינים",
    "המדע",
    "המיסדים",
    "המעפילים",
    "הנדיב",
    "הנוטרים",
    "הנרקיס",
    "הנשיא",
    "הסוכה",
    "הסתונית",
    "העבודה",
    "העליה",
    "העמל",
    "העצמאות",
    "הפדות",
    'הפלמ"ח',
    "הפנינה",
    "הפסגה",
    "הקונגרס הציוני",
    "הרב עזריאלי",
    "הרימון",
    'הרמב"ם',
    "הרצל",
    "הרקפת",
    "השומר",
    "השופטים",
    "השזיף",
    "השלושה",
    "השמורה",
    "השקד",
    "השקמה",
    "התאנה",
    "התומר",
    "התוף",
    "התזמורת",
    "התירוש",
    "התפוח",
    "התקומה",
    "וינגייט",
    "ז'בוטינסקי",
    "זייד אלכסנדר",
    "זכרון יעקב",
    "חוגלה",
    "חווה חקלאית",
    "חוות הברון",
    "חוחית",
    "חזון איש",
    "חכים אליהו",
    "חסידה",
    "חרצית",
    "טופז",
    "טיילת הברון",
    "טרומפלדור",
    "יאיר",
    "יהלום",
    "יסמין",
    "יפה נוף",
    "יצחק שדה",
    "יציאת אירופה",
    "כביש ארצי",
    "כט בנובמבר",
    "כינור",
    "ככר אלכסנדרוני",
    "ככר הבנים",
    "ככר הבריגדה",
    "ככר הראשונים",
    "ככר פלוגת עורב",
    "כנפי נשרים",
    "לאה",
    'לח"י',
    "לימון",
    "לשם",
    "מגל",
    "מולדת",
    "מייש",
    "מלכי יהודה",
    "מלכי ישראל",
    "מסיק",
    "מעלה הכרמל",
    "מעלה הראשונים",
    "מעלה מנחם",
    "מצדה",
    "מרבד הקסמים",
    "מרגוע",
    "מרכז המושבה",
    "משעול הגן",
    "משעול מתניה",
    "נגבה",
    "נוה הברון",
    "נוה רמז",
    "נוה שלו",
    "נוה שרת",
    "נורית",
    "סביון",
    "סטרומה",
    "סמ האלה",
    "סמ הבאר",
    "סמ הנרקיס",
    "סמ הרוקח",
    'סמ הרש"ש',
    "סמ השאיבה",
    "סמ השקד",
    "סמ חורשן",
    "סמ יעבץ",
    "סמ נוף ים",
    'סמ תרמ"ב',
    "סמ תרשיש",
    "סמטת אף על פי כן",
    "סמטת הגרנות",
    "סמטת סטרומה",
    "סנש חנה",
    "ספינת אגוז",
    "ספיר",
    "עגור",
    "עורבני",
    "ענבר",
    "עציון",
    "ערבה",
    "פעמי תימן",
    "פרנק אנה",
    "פשוש",
    'צה"ל',
    "צופית",
    "צור",
    "קבוץ גלויות",
    "קדושי השואה",
    "קדמה",
    "קדש",
    "קציר",
    "קרן היסוד",
    "קרן יער",
    "ראובן",
    "רבקה",
    "רחל",
    "רייק חביבה",
    "רמז",
    "רמת צבי",
    "ש צר עמידר",
    "שבזי",
    "שבי ציון",
    "שד בן גוריון",
    "שד יצחק רבין",
    'שד ניל"י',
    "שדרת מעוף הציפור",
    "שוהם",
    "שיבולת",
    "שירה",
    "שירת הים",
    "שכ גבעת זמארין",
    "שכ החורש",
    "שכ השמורה",
    "שכ יעקב",
    "שכ מול היקב",
    "שכטר אבא",
    "שלף",
    "שעורה",
    "ששת הימים",
    "תאשור",
    "תדהר",
    "תות",
    "תחנת הרכבת",
    "תמר",
    'תרמ"ב',
    'תרע"ב',
    'תש"ח',
  ],
  זכריה: ["הזית", "הרימון", "השקד", "התאנה", "זכריה"],
  זמר: ["אבתאן", "ביר א סכה", "זמר", "מרג'ה"],
  זמרת: ["זמרת"],
  זנוח: ["זנוח"],
  זרועה: ["זרועה"],
  זרזיר: [
    "אבו בכר אלסדיק",
    "אבן אלהית'ם",
    "אבן ח'לדון",
    "אחמד בן חנבל",
    "אחמד שאוקי",
    "אחסבאני",
    "אטלס",
    "איבן סינא",
    "איבן רושד",
    "אירוס",
    "אישראק",
    "אלאא",
    "אלאהראם",
    "אלאופוק",
    "אלאחנף בן קייס",
    "אלאיח'וה",
    "אלאימאן",
    "אלאמין",
    "אלאמל",
    "אלאמראא",
    "אלאנדלוס",
    "אלאנואר",
    "אלאסוד",
    "אלאסראא",
    "אלאסתקלאל",
    "אלאערב",
    "אלבואדי",
    "אלבוח'ארי",
    "אלבוראק",
    "אלבטום",
    "אלבידאא",
    "אלבלח",
    "אלבסאטין",
    "אלבתראא",
    "אלג'ולאן",
    "אלג'זירה",
    "אלג'ליל",
    "אלג'נאת",
    "אלג'נה",
    "אלדוחה",
    "אלהד'בה",
    "אלהייב",
    "אלואדי",
    "אלווטוס",
    "אלוורוד",
    "אלוערה",
    "אלופאא",
    "אלזהראא",
    "אלזוהרה",
    "אלזומורד",
    "אלזופה",
    "אלזיתון",
    "אלזמאן",
    "אלזנבק",
    "אלזעתר",
    "אלח'ד'ראא",
    "אלח'ואריזמי",
    "אלח'יראת",
    "אלח'ליל",
    "אלח'רוב",
    "אלחרמון",
    "אלטור",
    "אלירקון",
    "אלכות'ר",
    "אלכנסת",
    "אלכרום",
    "אלכרמה",
    "אללוז",
    "אללולו",
    "אלמאמון",
    "אלמדינה",
    "אלמדרסה",
    "אלמהא",
    "אלמוח'תאר",
    "אלמולוק",
    "אלמועלם",
    "אלמועתסם",
    "אלמות'נא",
    "אלמלול",
    "אלמנארה",
    "אלמראעי",
    "אלמרג' אלאח'ד'ר",
    "אלנאסרה",
    "אלנבי מוסא",
    "אלנביא סאלח",
    "אלנדים",
    "אלנואר",
    "אלנועמאן",
    "אלנור",
    "אלנורס",
    "אלניזק",
    "אלניל",
    "אלנימר",
    "אלנסמאת",
    "אלנענע",
    "אלנקב",
    "אלנרג'יס",
    "אלסאדה",
    "אלסאהרה",
    "אלסוקור",
    "אלסלאם",
    "אלסנדיאן",
    "אלספאא",
    "אלספוריה",
    "אלסרג'",
    "אלסרו",
    "אלסריס",
    "אלע'ד'נפר",
    "אלע'דיר",
    "אלע'פארי",
    "אלעאלי",
    "אלעולא",
    "אלעז",
    "אלעיין",
    "אלעיקאב",
    "אלעליק",
    "אלעקיק",
    "אלערב",
    "אלפוראת",
    "אלפיחאא",
    "אלפראבי",
    "אלקאדסיה",
    "אלקבס",
    "אלקודס",
    "אלקירואן",
    "אלקעקאע בן עמר",
    "אלראביה",
    "אלרד'ואן",
    "אלרואבי",
    "אלרואד",
    "אלריאד'",
    "אלריחאן",
    "אלרשיד",
    "אלשאטאא",
    "אלשאם",
    "אלשורוק",
    "אלת'וריא",
    "אלתאח'י",
    "אלתוראת'",
    "אלתות",
    "אלתין",
    "אלתקדום",
    "אנואר סאדאת",
    "אנס בן מאלק",
    "אנסאם",
    "אסיא",
    "אסיל",
    "אע'סאן",
    "אריאם",
    "אריג'",
    "אשדוד",
    "בדר",
    "בילאל בן רבאח",
    "בילסאן",
    "ביסאן",
    "בירות",
    "בית לחם",
    "בסמאן",
    "בר לב",
    "ג'ואמיס",
    "ג'ולאני",
    "ג'ומעה",
    "ג'נא",
    "ג'נאן",
    "גזאלין",
    "גריפאת",
    "ד'יאא",
    "דאפר",
    "דימה",
    "האבאנה",
    "האג'ר",
    "האדאס",
    "הגיפן",
    "היב אבו סיאח",
    "המוסך",
    "התעשיה",
    "ואאל",
    "זאיד",
    "זהראן",
    "זהרת אלמדאאן",
    "זמזם",
    "זרזיר",
    "ח'אלד בן אלוליד",
    "חאתם אלטאאי",
    "חדיג'ה בנת ח'וילד",
    "חיפה",
    "חסן בן ת'אבת",
    "חפסה",
    "טבעון",
    "יאמין",
    "יגאל אלון",
    "יוסף אלסדיק",
    "יזן",
    "יערה",
    "יפא",
    "יפעאת",
    "יצחק רבין",
    "ית'ריב",
    "כאוכב",
    "כנאנה",
    "לובנאן",
    "לוגיין",
    "לימאן",
    "מאאריב",
    "מאריה אלקבטיה",
    "מוטה גור",
    "מוסלים",
    "מוראד",
    "מזאריב",
    "מנחם בגין",
    "מנסור",
    "מסאדה",
    "מסג'ד אלהודא",
    "מרג' בן עאמר",
    "משה דיין",
    "נג'ד",
    "נהלאל",
    "ניסאן",
    "סארה",
    "סאריה",
    "סדים",
    "סהארה",
    "סולטאן באשא",
    "סונדוס",
    "סינאא",
    "סלים אלת'אני",
    "סמא",
    "סעד בן וקאס",
    "סעיד בן זייד",
    "סעסע",
    "ע'די",
    "עאמר בן אלג'ראח",
    "עאתכה",
    "עדן",
    "עוטרה",
    "עומר בן אלח'טאב",
    "עות'מאן בן עפאן",
    "עיאדאת",
    "עין גדי",
    "עלי בן אבי טאלב",
    "עמאר בן יאסר",
    "ערב אלמזאריב",
    "עתלית",
    "פואד בן אליעזר",
    'צה"ל',
    "קוביס",
    "קוטוף",
    "קות'באן",
    "רד'ואן",
    "ריאן",
    "רנד",
    "רע'דאן",
    "שמוס",
    "שמעון פרס",
    "שרון",
    "תאג'",
    "תימאא",
  ],
  זרחיה: ["זרחיה"],
  "ח'ואלד": ["ח'ואלד"],
  "ח'ואלד (שבט)": ["ח'ואלד (שבט)"],
  "חבצלת השרון": [
    "אולפן עקיבא",
    "דרך הבנים",
    "דרך הים",
    "הדקל",
    "הנרקיסים",
    "הפרדסים",
    "חבצלת השרון",
    "נוה הדר",
    "ש רסקו וילות",
  ],
  חבר: [
    "האנפה",
    "הבז",
    "הגלבוע",
    "הדוכיפת",
    "הדרור",
    "החוחית",
    "היונה",
    "הינשוף",
    "הכרמל",
    "המגלן",
    "העורב",
    "הצופית",
    "התבור",
    "חבר",
  ],
  חברון: [
    "אדמות ישי",
    "בית הדסה",
    "בית חסון",
    "בית קסטל",
    "בית רומנו",
    "בית שניאורסון",
    "הרובע היהודי",
    "חברון",
    "כולל אברהם אבינו",
    "תל רומיידה",
  ],
  חגור: [
    "אירוס",
    "דרור",
    "החבצלת",
    "הנורית",
    "הסולטן",
    "הרקפת",
    "השלדג",
    "חגור",
    "יסמין",
    "כלנית",
    "נחליאלי",
    "נרקיס",
    "סלעית",
    "סנונית",
  ],
  חגי: ["חגי"],
  חגלה: ["חגלה", "פרח בר"],
  "חד-נס": ["חד-נס"],
  חדיד: [
    "האירוס",
    "היסמין",
    "הכלנית",
    "הנרקיס",
    "הסביון",
    "הרקפת",
    "השושן",
    "חדיד",
  ],
  חדרה: [
    "אביאל",
    "אבשלום",
    "אדני פז",
    "אהרונוביץ",
    "אהרונסון",
    "אוסישקין",
    "אופירה",
    "אזבסט אליעזר",
    "אזור תעשייה",
    "אחד העם",
    "אחוזת בית",
    "אחוזת דניה",
    "אחימאיר אבא",
    "אידלווין",
    "אייל הכרמל",
    "איילון",
    "אילת",
    "אכזיב",
    "אלון יגאל",
    "אלונה",
    "אלוף יקותיאל אדם",
    "אלי כהן",
    "אלעזר דוד",
    "אסטוריה מאיר",
    "אסירי ציון",
    "אפיקי מים",
    "אפעל",
    "אפשטיין משה מרדכי",
    'אצ"ל',
    "ארזי הלבנון",
    "ארלוזורוב",
    "אשבל",
    "אשכול לוי",
    "באר אורה",
    "בארי",
    "בוארון ברוך",
    "בוטקובסקי",
    "בורוכוב",
    'בי"ח הלל יפה',
    "ביאליק",
    "בילינסון",
    "ביס חקלאי עירון",
    "בלקינד ישראל",
    "בן אליעזר אריה",
    "בן יהודה",
    "בן עמי",
    "בן צבי יצחק",
    "בניאס",
    "בעלי המלאכה",
    "בר אילן מאיר",
    "ברזילי",
    "ברכת עטה",
    "ברמן דוד",
    "ברנדיס",
    "ברנר",
    "גבעת בוסל",
    "גבעת בילו א",
    "גבעת בילו ב",
    "גבעת הלבונה",
    "גבעת המורה",
    "גדור",
    "גדות",
    "גולדה מאיר",
    "גולדנברג",
    "גולומב אליהו",
    "גורדון",
    "גורדון א ד",
    "גוש חלב",
    "גוש עציון",
    "גיבתון",
    "גיורא צחור",
    "גלוזגל",
    "גלוסקא",
    "גלי חוף",
    "גלעד",
    "גמלא",
    "גן שמואל",
    "גני ברנדיס",
    "גני חדרה",
    "גפנוביץ",
    "גרופית",
    "גרינבוים יצחק",
    "גרנות",
    'ד"ר אביאור',
    'ד"ר ברין',
    'ד"ר דן ויליאם',
    "דב לאוטמן",
    "דבורה משבץ",
    "דגניה",
    "דוד המלך",
    "דולינסקי",
    "דורי יעקב",
    "דורשי ציון",
    "דיין משה",
    "דינארד",
    "דלתון",
    "דקל הדום",
    "דרך בית אליעזר",
    "דרך הרכבת",
    "דרך נחל עירון",
    "ה באייר",
    "האבוקדו",
    "האגוז",
    "האגמון",
    "האדמה",
    "האדמונית",
    "האדר",
    "האומן",
    "האור",
    "האורן",
    "האושר",
    "האזוב",
    "האחו",
    "האחוה",
    "האילנות",
    "האירוס",
    "האלה",
    "האלומה",
    "האלון",
    "האלונים",
    "האלמוג",
    "האמוראים",
    "האנפה",
    "האסיף",
    "האפרסמון",
    "האקליפטוס",
    "האקציה",
    'האר"י',
    "הארבל",
    "הארגמן",
    "הארז",
    "האשד",
    "האשור",
    "האשל",
    "האתרוג",
    "הבאר",
    "הבונים",
    "הבוסתן",
    "הבזלת",
    "הבנים",
    "הבציר",
    "הבקעה",
    "הברוש",
    "הבריגדה היהודית",
    "הבשמים",
    "הבשמת",
    "הבשן",
    "הגבורים",
    "הגדוד העברי",
    "הגולן",
    "הגושרים",
    "הגלבוע",
    "הגליל",
    "הגפן",
    'הגר"א',
    "הגת",
    "הגתית",
    "הדביר",
    "הדבש",
    "הדגן",
    "הדודאים",
    "הדולב",
    "הדייגים",
    "הדליה",
    "הדפנה",
    "הדקל",
    "הדרדר",
    "הדרור",
    "ההגנה",
    "ההדס",
    "ההדר",
    "ההסתדרות",
    "ההרדוף",
    "הורד",
    "הזגג",
    "הזוגן",
    "הזוויתן",
    "הזית",
    "הזכוכית",
    "הזמיר",
    "החוחית",
    "החולית",
    "החופית",
    "החורש",
    'החיד"א',
    "החיטה",
    "החיננית",
    "החלוץ",
    "החליל",
    "החלמיש",
    "החסידה",
    "החצב",
    "החרש",
    "החשמונאים",
    "הטייסים",
    "היונה",
    "היוצרים",
    "היסמין",
    "היסעור",
    "היערה",
    "הירדן",
    "הירקון",
    "הכלנית",
    "הכנור",
    "הכנסת",
    "הכנרת",
    "הכף גימל",
    "הכרם",
    "הכרמל",
    'הל"ה',
    "הלבנית",
    "הלגונה",
    "הלוטם",
    "הלוי",
    "הליבנה",
    "הלילך",
    "הלימון",
    "הלל יפה",
    "הלשם",
    "המגינים",
    "המולדת",
    "המורן",
    "המושבה",
    "המכבים",
    "המכללה",
    "המכתשים",
    "המסגר",
    "המעפילים",
    "המקשר",
    "המתכת",
    "הנביאים",
    "הנבל",
    "הנגב",
    "הנגינות",
    "הנורית",
    "הנחלים",
    "הנעמן",
    'הנצי"ב',
    "הנקין",
    "הנרקיס",
    "הנשיא ויצמן",
    "הנשר",
    "הס משה",
    "הסהרון",
    "הסיגלית",
    "הסלע האדום",
    "העבודה",
    "העגור",
    "העוגב",
    "העיר העתיקה",
    "העליה הראשונה",
    "העליה השניה",
    "הענבר",
    "העצמאות",
    "הערבה",
    "הפיקוס",
    "הפלג",
    'הפלמ"ח',
    "הפקאן",
    "הפרדס",
    "הצאלון",
    "הצבעוני",
    "הצוקים",
    "הצנחנים",
    "הצפורן",
    "הצפצפה",
    "הק",
    "הקומונה החדרתית",
    "הקונגרס",
    "הקורמורן",
    "הקידה",
    "הקינמון",
    "הקמה",
    "הקנה",
    "הר אביטל",
    "הר בנטל",
    "הר הנגב",
    "הר חורשן",
    "הר מירון",
    "הר סיני",
    "הר צין",
    "הראשונים",
    "הרב אבו חצירה",
    "הרב אורנשטיין",
    "הרב בוזגלו",
    "הרב דוד משתה",
    "הרב הרצוג",
    "הרב טייב חי",
    "הרב מוהליבר",
    "הרב מימון יהודה",
    "הרב ניסים",
    "הרב עובדיה יוסף",
    "הרב עוזיאל",
    "הרב קוק",
    "הרבי מילובביץ",
    "הרברט סמואל",
    "הרותם",
    "הרי בשמים",
    "הרימונים",
    'הרמב"ן',
    "הרצל",
    "הרקפת",
    "הרשש",
    "השומר",
    "השושנים",
    "השחף",
    "השיזף",
    "השיטה",
    "השלדג",
    "השלום",
    "השלכת",
    "השמינית",
    "השעורה",
    "השקד",
    "השקמה",
    "השרון",
    "התאנים",
    "התבור",
    "התדהר",
    "התות",
    "התחיה",
    "התמסח",
    "התמרים",
    "התפוז",
    "התפוח",
    "התקוה",
    "וולפברג",
    "וידרקר יצחק",
    "וילנסקי",
    "ויתקין",
    "ז'בוטינסקי",
    "זודקביץ דב",
    "זולוטרוב",
    "חבצלת השרון",
    "חברת עזרא",
    "חגוי הסלע",
    "חדרה",
    "חדרה הירוקה",
    "חדרה הצעירה",
    "חדרה מזרח",
    "חובבי ציון",
    "חוט השני",
    "חולתה",
    "חומה ומגדל",
    "חוף הכרמל",
    "חורשת טל",
    "חזון איש",
    "חזני מיכאל",
    "חטיבת אלכסנדרוני",
    "חטיבת גבעתי",
    "חטיבת גולני",
    'חטיבת הנח"ל',
    "חטיבת הראל",
    "חטיבת כרמלי",
    "חטיבת עודד",
    "חטיבת עציוני",
    "חיפה",
    "חלוצה",
    "חניתה",
    "חנקין יהושע",
    "חרובים",
    "חרמון",
    "טביב",
    "טיומקין",
    "טרבלוס",
    "טרומפלדור",
    "טשרניחובסקי שאול",
    "יבניאלי",
    "יגאל",
    "ידין יגאל",
    "יהודה הלוי",
    "יהודי פקיעין",
    "יונת הסלע",
    "יוסף בוסקילה",
    "יחיעם",
    "ים המלח",
    "ים סוף",
    "ינאי המלך",
    "יעקב רבונה",
    "יער חדרה",
    "יפה נוף",
    "יפת בן יוסף",
    "יציאת אירופה",
    "ירושלים",
    'יש"י',
    "ישיבת בית שמואל",
    "ישעיהו",
    'כ"ח באייר',
    'כ"ט בנובמבר',
    "כביש מס' 2",
    "כביש מס' 4",
    "כהן אוסקר",
    "ככר עמל",
    "כליל החורש",
    "כספי",
    "כפר הים",
    "כפר יוליאנה",
    "כצנלסון ברל",
    "לבוצקין",
    "לבזובסקי",
    "לוברסקי",
    "לויטס",
    'לח"י',
    "ליובין",
    "לילינבלום",
    "לכה דודי",
    "לכיש",
    "לסקוב חיים",
    "לרמן",
    "מאפו אברהם",
    "מבצע דני",
    "מבצע חורב",
    "מבצע חירם",
    "מבצע יואב",
    "מבצע יונתן",
    "מבצע נחשון",
    "מבצע עובדה",
    "מבצע עזרא",
    "מבצע עשר המכות",
    "מבצע קדש",
    "מדורסקי",
    "מדעים ויהדות",
    'מהר"ל',
    'מהרי"ץ',
    "מוסד תלפיות",
    "מורדי הגטאות",
    "מייזר יוסף",
    "מילנר",
    "מלחמת יום הכפורים",
    "מלחמת ששת הימים",
    "מלכי ישראל",
    "ממשית",
    'מנא"י',
    "מנרה",
    "מעברת ברנדיס",
    "מצפה ים",
    "מקוה ישראל",
    "מקלף מרדכי",
    "מרבד הקסמים",
    "מרגלית",
    "מרדכי קוסטליץ",
    "מרכז הקליטה",
    "משאבי שדה",
    "משגב עם",
    "משה פסטרנק",
    "משמר הגבול",
    "משמר הים",
    "משק הפועלות",
    "מתחם העירייה",
    "נאות הכיכר",
    "נאות רם",
    "נאות שקד",
    "נהלל",
    "נהרונית",
    "נוב",
    "נוה ברנדיס",
    "נוה כפרי",
    "נווה הדרים",
    "נווה חורש",
    "נווה ים",
    "נורדאו",
    "נחומובסקי",
    "נחל כזיב",
    "נחל פולג",
    "נחל פרת",
    "נחל צין",
    "נחל רמון",
    "נחל שורק",
    "נחשונים",
    'ניל"י',
    "ניצנה",
    "ניצנים",
    "נערן",
    "נתיבות",
    "סגל",
    "סולד הנרייטה",
    "סוסקין",
    "סלוצקין",
    "סמ החאן",
    "סמ הרב רוקח",
    "סמ זבוטינסקי",
    "סמ יהודית",
    "סמ יובל",
    "סמ לוי שושנה",
    "סמ מיכאל",
    "סמדר",
    "סמולניק",
    "סמילנסקי",
    "סמסונוב",
    "סנש חנה",
    "ספיר יוסף",
    "עגנון",
    "עולי הגרדום",
    "עין ארובות",
    "עין בוקק",
    "עין גב",
    "עין גדי",
    "עין גנים",
    "עין הדבש",
    "עין הים",
    "עין זיוון",
    "עין עבדת",
    "עינב לוי",
    "עירית",
    "על כנפי נשרים",
    "עלית הנוער",
    "עמק יזרעאל",
    "עצמון",
    "ער אציל",
    "ערוגות הבושם",
    "פיינברג",
    "פייקרש",
    "פינסקר",
    'פיק"א',
    "פלד אשר",
    "פלטרין",
    "פרופ' אברהם הרשקו",
    "פרופ' אהרן צ'חנובר",
    "פרופ' דן שכטמן",
    "פרופ' משה אדד",
    "פרידלנדר",
    "פרנק",
    "פרנקל יצחק",
    "פרץ",
    "צאלים",
    "צבר",
    'צה"ל',
    "צידקוב",
    "ציפורי",
    "צעירי שלום",
    "צפרה",
    "צפת",
    "קארו יוסף",
    "קבוץ גלויות",
    "קדימה",
    "קוטלר",
    "קומבה",
    "קידמת ים",
    "קלז",
    "קנטרזי",
    "קניון לב חדרה",
    "קסטל",
    "קרן קיימת לישראל",
    "קשת",
    "ראובן הכהן",
    "ראש הנקרה",
    "רבין יצחק",
    "רבינוביץ",
    "רודין",
    "רוטמן",
    "רון",
    "רזיאל דוד",
    'רח"ש',
    "ריחן",
    "רם",
    'רמב"ם',
    'רמח"ל',
    'רש"י',
    'רשב"ם',
    "ש אזורים",
    "ש אזרחי",
    "ש אלי כהן",
    "ש בוטקובסקי",
    "ש בית אליעזר",
    "ש בני עקיבא",
    "ש האמוראים",
    "ש הזיתים",
    "ש ותיק רח ה",
    "ש ותיק רח ו",
    "ש ותיק רח ז",
    "ש חדש",
    "ש יד שמואל",
    "ש יוספטל",
    "ש לוינסון",
    "ש מאוריציוס",
    "ש נוה צבי",
    "ש סלע",
    "ש עובדים",
    "ש עולי סין",
    "ש עולים חדש",
    "ש עמל א",
    "ש עמל ב",
    "ש פאר",
    'ש רמב"ם',
    'ש רמב"ם חדש',
    "ש רסקו",
    "שאר ישוב",
    "שבזי שלום",
    "שבטי ישראל",
    "שגרגרוצקי",
    "שד אלכסנדר",
    "שד בגין מנחם",
    "שד בן גוריון",
    "שד האביב",
    "שד מינסוטה",
    "שד רוטשילד",
    "שד רחבעם זאבי",
    "שדה בוקר",
    "שדה יצחק",
    "שדרות כושי עפגין",
    "שומרי החומות",
    "שועלי שמשון",
    "שושנת העמקים",
    'שז"ר זלמן',
    'שח"ל',
    "שטרנין",
    "שיבטה",
    "שיבת ציון",
    "שכ אגרובנק",
    "שכ אולגה א",
    "שכ אולגה ב",
    "שכ אולגה ג",
    "שכ אולגה ד",
    "שכ אולגה צריפים",
    "שכ אלרם",
    "שכ אסבסט אולגה",
    "שכ אפרים",
    "שכ ביאליק",
    "שכ בן גוריון",
    "שכ ברנדיס",
    "שכ גני אלון",
    "שכ האוצר",
    "שכ הגורן",
    "שכ הפועל המזרחי",
    "שכ ויצמן",
    "שכ חופים",
    "שכ חפצי בה",
    "שכ יצחק",
    "שכ מרכז",
    "שכ נוה אליעזר",
    "שכ נוה חיים",
    "שכ נוה עובד",
    "שכ נוה עובד א",
    "שכ נוה עובד ב",
    "שכ נוה עובד ג",
    "שכ נווה חורש",
    "שכ נחליאל",
    "שכ ניסן",
    "שכ שלום",
    "שכ שלמה",
    "שכ שמשון",
    "שכון גזית",
    "שכון יוגוסלבי",
    "שכונת הפארק",
    "שלהבית",
    "שלמה",
    "שלמה המלך",
    "שמואלזון",
    "שמידט",
    "שמעון פרס",
    "שמעוני דוד",
    "שניאורסון",
    "שניר",
    "שפאק",
    "שפירא",
    "שפירא חיים משה",
    "שפרינצק יוסף",
    "שקמונה",
    "שקצר",
    "שרת משה",
    "תחנת הכח",
    "תחנת הרכבת מזרח",
    "תל דור",
    "תל דן",
    "תל חצור",
    "תמנע",
    "תנאים",
    "תעשייה",
    'תרמ"ב',
    'תרנ"א',
    'תרע"ב',
    "תרצה",
  ],
  "חוג'ייראת (ד'הרה)": ["חוג'ייראת (ד'הרה)"],
  חולדה: ["חולדה"],
  חולון: [
    "אביבים",
    "אבידן שמעון",
    "אבן גבירול",
    "אבן עזרא",
    "אבנר",
    "אברבנאל",
    "אבשלום",
    "אגוז",
    "אדם יקותיאל",
    "אהוד",
    "אהל שרה",
    "אהרונוביץ",
    "אוסישקין",
    "אופיר",
    "אורי אלמוג",
    "אורים",
    'אז"ר',
    "אזור תעשיה ב",
    "אזור תעשייה א",
    "אזור תעשייה רסקו ב",
    'אח"י דקר',
    "אחד במאי",
    "אחד העם",
    "אילון פנחס",
    "אילת",
    "אימבר",
    "איריס",
    'אלופי צה"ל',
    "אלחריזי",
    "אלי כהן",
    "אליהו הנביא",
    "אלישע",
    "אלעזר דוד",
    "אלקלעי",
    "אלרואי",
    "אנילביץ",
    "אסירי ציון",
    "אפעל",
    'אצ"ל',
    "ארבע ארצות",
    "ארגוב מאיר",
    "אריק אינשטיין",
    "אריק לביא",
    "ארלוזורוב",
    "ארליך שמחה",
    "ארן זלמן",
    "אשתאול",
    "בובר מרטין",
    "בוסל",
    "בוסתנאי",
    "בורוכוב",
    "בזל",
    'בי"ח וולפסון',
    "ביאליק",
    'ביל"ו',
    "בילינסון",
    "בית לחם",
    "בית שערים",
    "בלבן",
    "בלום לאון",
    "בן אליעזר אריה",
    "בן הלל",
    "בן יהודה",
    "בן עמרם",
    "בן-נון יוחאי",
    "בני משה",
    "בעל מחשבות",
    "בעל שם טוב",
    "בצלאל",
    "בר אילן",
    "בר כוכבא",
    "ברדיצ'בסקי",
    "ברודרזון",
    "ברזביל",
    "ברזילי ישראל",
    "ברטונוב דבורה",
    "ברלב חיים",
    "ברנדיס",
    "ברנר",
    "ברנשטיין פרץ",
    "ברץ יוסף",
    "ברק",
    "ברקאי מיכאל",
    "ברקן",
    "ברקת ראובן",
    "גאולים",
    "גבעה 69",
    "גבעת התחמושת",
    "גבעת תל גבורים",
    "גבעתי",
    "גדעון",
    "גולדה מאיר",
    "גולדשטיין פרץ",
    "גולומב",
    "גורדון",
    "גוש חלב",
    "גוש עציון",
    "גלילי ישראל",
    "גלעדי אהרון",
    "גנסין",
    "גרינבוים",
    "גרינברג אורי צבי",
    "גרץ צבי",
    'ד"ר כהן היינץ',
    'ד"ר פליקס זנדמן',
    'ד"ר קפלינסקי נח',
    "דבורה הנביאה",
    "דבורה עומר",
    "דגניה",
    "דה שליט עמוס",
    "דובנוב",
    "דולפין",
    "דורי יעקב",
    "דיין משה",
    "דליה",
    "דמארי שושנה",
    "דן שומרון",
    "דניאל",
    "דרורי אמיר",
    "דרך אלון יגאל",
    "דרך בית העלמין",
    "דרך הביטחון",
    "דרך השבעה",
    "דרך עזה",
    "ה' באייר",
    "האבות",
    "האורגים",
    "האחוה",
    "האיזמל",
    "האלוף אהרון יריב",
    "האלוף תמרי נחמיה",
    "האמוראים",
    "האנקור",
    'האר"י',
    "הארז",
    "האשל",
    "הבוכנות",
    "הבנאי",
    "הבנים",
    "הבקעה",
    "הברבור",
    "הברוש",
    "הגאונים",
    "הגביש",
    "הגבעול",
    "הגדוד העברי",
    "הגדנעים",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הגפן",
    'הגר"א',
    "הגת",
    "הדייה",
    "הדס",
    "הדקל",
    "הדר",
    "הדרור",
    "ההגנה",
    "ההסתדרות",
    "הוגו ארנסט",
    "הופיין",
    "הזורע",
    "הזית",
    "הזמיר",
    "החבצלת",
    "החגלה",
    "החוחית",
    "החופר",
    "החורטים",
    'החי"ל',
    'החי"ם',
    'החי"ש',
    "החיטה",
    "החלוץ",
    "החמניה",
    "החמריה",
    "החסידה",
    "החצב",
    "החרמון",
    "החרצית",
    "החרש",
    "החשמונאים",
    "הטווס",
    "הטייסים",
    'הי"ד',
    "היובל",
    "היוגב",
    "היוצר",
    "היסמין",
    "היתד",
    "הכחל",
    "הכישור",
    "הכנסת",
    "הכרם",
    "הלהב",
    "הלוחמים",
    "המגיד",
    "המכבים",
    "המכתש",
    "המלאכה",
    "המנור",
    "המעורר",
    "המעין",
    "המעפילים",
    "המצודה",
    "המרגנית",
    "המרכבה",
    "המשביר",
    "המשור",
    "המתמיד",
    "המתנדבים",
    "הנביאים",
    "הנגר",
    "הנוטרים",
    "הנחושת",
    "הנחליאלי",
    "הניצנים",
    "הנפח",
    "הנשיאים",
    "הנשר",
    "הס משה",
    "הסבוראים",
    "הסדן",
    "הסדנה",
    "הסולל",
    "הסיגלון",
    "הסיירת",
    "הסלען",
    "הסנהדרין",
    "הסנונית",
    "הסתת",
    "העבודה",
    "העגור",
    "העוגן",
    "העליה השניה",
    "העפרוני",
    "העצמון",
    "הערבה",
    "הפורצים",
    "הפלד",
    'הפלמ"ח',
    "הפרדס",
    "הצופית",
    "הצורף",
    "הציונות",
    "הצנחנים",
    "הצפורן",
    "הצפירה",
    "הקונגרס",
    "הקוקיה",
    "הקליר אלעזר",
    "הר הצופים",
    "הראובני",
    "הראל",
    "הרב באזוב דוד",
    "הרב הרצוג",
    "הרב ניסים יצחק",
    "הרב קוק",
    "הרדוף הנחלים",
    "הרוקמים",
    "הרותם",
    "הרימון",
    "הרצפלד",
    "הרקפת",
    "הררי",
    "השואבים",
    "השומר",
    "השומרון",
    "השופטים",
    "השחף",
    "השחר",
    "השיטה",
    "השילוח",
    "השמיר",
    "השסתומים",
    "השעורה",
    "השקמה",
    "השקנאי",
    "השרון",
    "השריון",
    "התאנה",
    "התבור",
    "התומר",
    "התור",
    "התותחנים",
    "התחיה",
    "התנאים",
    "התעלה",
    "התקומה",
    "וולפסון",
    "וולקני יצחק",
    "ויצמן",
    "ויתקין",
    "ולנברג ראול",
    "ורבורג",
    "ז'בוטינסקי",
    "ז'ן ז'ורס",
    "זאבי רחבעם",
    "זבולון",
    "זוהר מרים",
    "זכריה",
    "זכריה הרופא",
    "זמנהוף",
    "זרובבל",
    'חב"ד',
    "חבקוק",
    "חברון",
    "חגי",
    "חובב",
    "חובבי ציון",
    "חולון",
    "חולון הירוקה",
    "חולון הצעירה",
    "חומה ומגדל",
    "חזית חמש",
    "חיטמן עוזי",
    "חיים חפר",
    "חכמי ישראל",
    "חלץ",
    "חניון אוטובוסים",
    "חנניה",
    "חנקין",
    "חסדאי",
    "חצבה",
    "חצרים",
    "טבנקין יצחק",
    "טהון",
    "טרומפלדור",
    "טשרניחובסקי",
    'י"א באדר',
    'י"א הספורטאים',
    "יאיר הגלעדי",
    "יבנה",
    "יבניאלי שמואל",
    "יד לגולה",
    "ידין יגאל",
    "יהודה הלוי",
    "יהודה הנשיא",
    "יהודה מקוגן",
    "יהושפט",
    "יואב",
    "יואל",
    "יום הכיפורים",
    "יונה",
    "יוסי בנאי",
    "יוסף הנשיא",
    "יותם",
    "יחזקאל",
    "יטבתה",
    'יל"ג',
    "יסוד המעלה",
    "יעבץ",
    "יעל",
    "יפה אברהם",
    "יפה אליעזר",
    "יפו",
    "יפתח",
    "יצחק הלוי",
    "יריחו",
    "ירמיהו",
    "ירקוני יפה",
    "ישעיהו",
    "ישראלי בן ציון",
    "ככר בארי",
    "ככר הלוחמים",
    "ככר המוסדות",
    "ככר השבעה",
    "ככר השלושה",
    "ככר ויצמן",
    "ככר סטרומה",
    "ככר סירן",
    "כלנית",
    "כנרת",
    "כפר גלעדי",
    "לבון פנחס",
    "לוחמי הנגב",
    "לויתן",
    'לח"י',
    "ליבנה",
    "לילינבלום",
    "ליסין",
    "לכיש",
    "לנדאו חיים",
    "לסל",
    "לסקוב חיים",
    "מאפו",
    "מבצע סיני",
    "מגידו",
    "מודיעין",
    "מוהליבר",
    "מוזיאון רכב הסטורי",
    "מוטה גור",
    "מולכו שלמה",
    "מונטיפיורי",
    "מוצקין",
    "מורדי הגטאות",
    'מזא"ה',
    "מטולה",
    "מיכה",
    "מילגרום",
    "מימון",
    "מישאל",
    "מכס וג'סי כהן",
    "מלאכי",
    "מנדלי מוכר ספרים",
    "מנדלסון",
    "מנוח",
    "מנור אהוד",
    "מסילת העולים",
    "מעלות",
    "מפרץ שלמה",
    "מקוה ישראל",
    "מקלף מרדכי",
    "מרבד הקסמים",
    "מרדכי שפיגל",
    "מרים הנביאה",
    "מרכז רפואי וולפסון",
    "מרקובצקי",
    "משה רינת",
    "משמר הירדן",
    "משעול דר",
    "משעול הבדולח",
    "משעול הטופז",
    "משעול היהלום",
    "משעול הלשם",
    "משעול הנופך",
    "משעול הספירים",
    "משעול הפז",
    "משעול השוהם",
    "משעול השקד",
    "משעול התרשיש",
    "משעול יוסף יהונתן",
    "משעול ענבר",
    "מתחם חולות א",
    "מתחם חולות ב",
    "מתחם חולות ד",
    "נאות יהודית",
    "נאות שושנים",
    "נג'ארה ישראל",
    "נהריה",
    "נובידבור",
    "נוה ארזים",
    "נווה רמז",
    "נועה אשכול",
    "נורדאו",
    "נורית",
    "נחום",
    "נחלת שלום",
    "נחמיה",
    "נחשון",
    "נטר קרל",
    'ניל"י',
    "ניסנבאום",
    "נס ציונה",
    "נפחא",
    "נרקיס עוזי",
    "סאלק",
    "סבידור מנחם",
    "סביון",
    "סולד הנריטה",
    "סוקולוב",
    "סירני",
    "סירקין",
    "סמ הניסנית",
    "סמ הרוזמרין",
    "סמולנסקין",
    "סמטת הרעות",
    "סמטת השלום",
    "סנה משה",
    "סנש",
    "סעדיה גאון",
    "ספיר יוסף",
    "סרלין יוסף",
    'עגנון ש"י',
    "עובדיה",
    "עוזיאל",
    "עזרא",
    "עזריה",
    "עטרות",
    "עין גדי",
    "עין יהב",
    "עירית גדולה",
    "עמוס",
    "עמינדב",
    "עמל",
    "עמק אילון",
    "עמק דותן",
    "עמק יזרעאל",
    "פבריגט אנריקו",
    "פדויי השבי",
    "פהר אילונה",
    "פורת אורנה",
    "פיארברג",
    "פייר קניג",
    "פיכמן",
    "פילדלפיה",
    "פינסקר",
    "פנחס ספיר",
    "פרוג",
    "פרופ' שור",
    "פרישמן",
    "פרנק אנה",
    "פרץ",
    "פתח תקוה",
    "צ'לנוב",
    "צאלים",
    "צבי תדמור",
    "צדוק דוד",
    "צעירי מזרח",
    "צפניה",
    "צפת",
    "צרעה",
    "קבוץ גלויות",
    "קדושי בלזן",
    "קדושי קהיר",
    "קדיש לוז",
    "קדמן",
    "קהילת וילנה",
    "קהילת ורשה",
    "קוהלת",
    "קורצ'ק",
    "קישון אפרים",
    "קלויזנר",
    "קלומל",
    "קלישר",
    "קניג ליא",
    "קפאח",
    "קפלן",
    "קפלנסקי",
    "קציר אהרון",
    "קצנלסון יצחק",
    "קראוזה",
    "קרית יצחק רבין",
    "קרית מיכה",
    "קרית עבודה",
    "קרית פנחס אילון",
    "קרית שמונה",
    "קריתי",
    "קרן היסוד",
    "קרן קיימת",
    "קרסל גצל",
    "ראש פנה",
    "ראשון לציון",
    "רבי עקיבא",
    "רבין יצחק",
    "רבינוביץ יהושע",
    "רוזנבלום יאיר",
    "רוטשילד",
    "רום משה",
    "רופין",
    "רותר ולטר",
    "רח ג אזור",
    "רח ו תל גבורים",
    "רח ט",
    'רח כ"ג',
    "רח כד",
    "רחל",
    "ריינס",
    "רייק חביבה",
    "רינגלבלום",
    'רמב"ם',
    "רמז",
    'רמח"ל',
    "רמת הגולן",
    "רפאל איתן",
    'רש"י',
    "ש אביב",
    "ש אזבסטונים",
    "ש אקדמאים",
    "ש בטיש",
    "ש ג'סי כהן",
    "ש ההסתדרות",
    "ש השומרונים",
    "ש חסכון",
    "ש מומחים",
    "ש מפדה אזרחי",
    "ש נאות רחל",
    "ש עולה חדש",
    "ש עולים תל גבורים",
    "ש עמידר",
    "ש פרוגרסיבים",
    "ש קליפורניה",
    "ש רסקו ב",
    "ש רסקו ג",
    "ש תל גבורים",
    'ש"ץ צבי',
    "שאלתיאל דוד",
    "שבזי",
    "שבטה",
    "שבטי ישראל",
    "שביל א.ד.ג.",
    "שד בגין מנחם",
    "שד בן גוריון",
    "שד הוז דב",
    "שד יוספטל",
    "שד ירושלים",
    "שד לוי אשכול",
    "שד קוגל",
    'שד"ל',
    "שדה בוקר",
    "שדה יצחק",
    "שוחט מניה וישראל",
    "שטרוק",
    "שיבת ציון",
    "שיכון אגרובנק",
    "שיכון עממי",
    "שיפר",
    "שכ גבעת חולון",
    "שכ גרין",
    "שכ הקרוונים",
    "שכ מולדת",
    "שכ קרית בן גוריון",
    "שכ קרית שרת",
    "שכון עירוני ג",
    "שכונת עם",
    "שכטר",
    "שלום עליכם",
    "שמואל",
    "שמואל הנגיד",
    "שמורק",
    "שמעוני",
    "שמעיה",
    "שמר נעמי",
    "שמריהו לוין",
    "שמשון",
    "שנקר",
    "שער האריות",
    "שער הגיא",
    "שער הפרחים",
    "שער ציון",
    "שפירא חיים משה",
    "שפירא צבי",
    "שפרינצק",
    "שרה לוי תנאי",
    "שרת משה",
    "ששת הימים",
    "תל חי",
    "תמנע",
    "תמר",
    'תרצ"ו',
  ],
  חולית: ["חולית"],
  חולתה: ["בית ראשון במולדת", "חולתה"],
  חוסן: [
    'בית"ר',
    "האגם",
    "האורנים",
    "האלה",
    "האלונים",
    "הבנים",
    "הברושים",
    "הדולב",
    "הזיתים",
    "החקלאים",
    "החרוב",
    "המייסדים",
    "הנחל",
    "הערבות",
    "הראשונים",
    "השקד",
    "חוסן",
    "יהדות מרוקו",
    "כליל החורש",
    "מורן החורש",
    "עולי סין",
    "שביל חרות",
    'שביל קק"ל',
    "שלום שארלי שלוש",
    "שלמה בן יוסף",
  ],
  חוסנייה: ["חוסנייה"],
  חופית: [
    "אלמוג",
    "האקליפטוס",
    "הסנונית",
    "הצדף",
    "השלדג",
    "השקמים",
    "חופית",
    "כוכב הים",
    "מורד הנחל",
    "שונית",
    "שחף",
  ],
  חוקוק: ["בית ראשון במולדת", "חוקוק"],
  חורה: [
    "אבו בקר אלצדיק",
    "אבו חאמד אלע'זאלי",
    "אבו סמארה",
    "אבו עביידה",
    "אבן ח'לדון",
    "אבן סינא",
    "אחמד בן חנבל",
    "איבן תאימייה",
    "אל חוארזמי",
    "אלאבדאע",
    "אלאהראם",
    "אלאח'אא",
    "אלאח'וה",
    "אלאח'לאס",
    "אלאחסאן",
    "אלאמל",
    "אלאנדלוס",
    "אלאנואר",
    "אלאסראא",
    "אלבוראק",
    "אלביאדר",
    "אלבירוני",
    "אלג'אמע",
    "אלג'זירה",
    "אלג'מאמה",
    "אלדוחא",
    "אלהג'רה",
    "אלהודא",
    "אלואחה",
    "אלופאא",
    "אלזהור",
    "אלזהראא",
    "אלזיתון",
    "אלזעתר",
    "אלח'טאב",
    "אלח'ליל",
    "אלח'נסאא",
    "אלחג'",
    "אלחוסין בן עלי",
    "אלחיאה",
    "אלחכמה",
    "אלחריה",
    "אלחרם",
    "אלטואחין",
    "אלטויל",
    "אלטיבה",
    "אלירמוק",
    "אלכרם",
    "אלכרמה",
    "אלמג'ד",
    "אלמדינה",
    "אלמהדי",
    "אלמיזאן",
    "אלמנארה",
    "אלמנסור",
    "אלמעתסם",
    "אלמרוה",
    "אלמריך",
    "אלמתנבי",
    "אלנג'את",
    "אלנור",
    "אלנח'יל",
    "אלנעים",
    "אלסביל",
    "אלסבר",
    "אלסלאם",
    "אלסנאעה",
    "אלספאא",
    "אלעבאס",
    "אלעטאא",
    "אלעין",
    "אלעמאל",
    "אלעקאד",
    "אלעקבה",
    "אלערב",
    "אלפוראת",
    "אלפרוק",
    "אלפרקאן",
    "אלפתח",
    "אלקאהרה",
    "אלקדסייה",
    "אלקודס",
    "אלקירואן",
    "אלקלעה",
    "אלקסר",
    "אלראיה",
    "אלריבאט",
    "אלריחאן",
    "אלרמאן",
    "אלשאם",
    "אלשאפעי",
    "אלשלאלה",
    "אלשריף",
    "אלת'קאפה",
    "אלתין",
    "אלתעאון",
    "אלתקוא",
    "אם אלקורא",
    "בגדאד",
    "בדר",
    "ביסאן",
    "בית אלמקדס",
    "ג'בל אלזיתון",
    "דג'לה",
    "הארון אלרשיד",
    "זמזם",
    "חורה",
    "חטין",
    "חיפה",
    "חמזה",
    "חסן אלבצרי",
    "טארק בן זיאד",
    "טבריה",
    "יפו",
    "מאלק",
    "מוזדלפה",
    "מועד בן ג'בל",
    "מראקש",
    "סלאח אלדין",
    "סלמאן אלפארסי",
    "סעד בן אבי ווקאס",
    "עבדאלה בן רוחה",
    "עומר אל מוח'תאר",
    "עין סארה",
    "עלי בן אבי טאלב",
    "עסקלאן",
    "ערפה",
    "רג'ב",
    "שכ 1",
    "שכ 11",
    "שכ 2",
    "שכ 12",
    "שכ 3",
    "שכ 13",
    "שכ 4",
    "שכ 14",
    "שכ 5",
    "שכ 15",
    "שכ 6",
    "שכ 16",
    "שכ 7",
    "שכ 17",
    "שכ 8",
    "שכ 18",
    "שכ 9",
    "שכ 19",
    "שכ יסמין",
    "שמס",
  ],
  חורפיש: ["חורפיש"],
  חורשים: ["חורשים"],
  חזון: ["חזון"],
  "חיבת ציון": ["אודים", "דרור", "המגשימים", "הראשונים", "חיבת ציון"],
  חיננית: ["בית ראשון במולדת", "חיננית", "טל מנשה"],
  חיפה: [
    "א סלט",
    'א"ת מפרץ(צפון-מזרח)',
    'א"ת מפרץ(צפון-מערב)',
    "אבדימי",
    "אביגיל",
    "אביר מרק",
    "אבן אל אתיר",
    "אבן אל מוקפע",
    "אבן גבירול",
    "אבן סינא",
    "אבן עזרא",
    "אבן רושד",
    "אבנר",
    "אברבנאל",
    "אבשלום",
    "אגוז",
    "אגמון",
    'אד"ם הכהן',
    "אדיסון",
    "אדמונד פלג",
    "אדמות יגור",
    "אהבת ישראל",
    "אהבת ציון",
    "אהוד",
    "אהרון (רוזנפלד)",
    "אהרונוביץ יוסף",
    "אהרונסון",
    "אודם",
    "אוחנה נסים הרב",
    "אוליפנט",
    "אומיה",
    "אוניברסיטת חיפה",
    "אוסטרובסקי ברוך",
    "אופיר",
    "אור החיים",
    "אורבך אליהו",
    "אורט",
    "אורלנד יעקב",
    "אורן",
    "אושה",
    'אז"ר',
    "אזולאי יעקב השופט",
    'אזור בי"ח פלימן',
    "אזור מלאכה ומסחר",
    "אזור שמן",
    "אזור תעשייה",
    'אח"י אילת',
    "אחד העם",
    "אחוה",
    "אחז",
    "אטוגראי",
    "אידלסון בבה",
    "אידר",
    "איטליה",
    "אילון",
    "אילות",
    "אילנות",
    "אילת",
    "אימבר נפתלי",
    "אינטרנשיונל",
    "אינשטיין אלברט",
    "איצקוביטש",
    "איצקוביץ שמואל",
    "איריס",
    "אירן",
    "איתין יהודה",
    "איתנים",
    "אל אדריסי",
    "אל אספהני",
    "אל בוכתורי",
    "אל גזאלי",
    "אל הילאל",
    "אל חרירי",
    "אל כנסא",
    "אל מוהדי",
    "אל מורינה",
    "אל מותנבי",
    "אל מערי",
    "אל מרין",
    "אל עריש",
    "אל עתיקה",
    "אל פראבי",
    "אלבז נתן",
    "אלונים",
    "אלחנן יצחק",
    "אלי כהן",
    "אליהו הנביא",
    "אלישע",
    "אלכסנדר ינאי",
    "אלמוג",
    'אלעזר דוד רא"ל',
    "אלפסי",
    "אלקלעי הרב",
    "אלתר אליעזר",
    "אלתרמן נתן",
    "אמיר",
    "אמנון ותמר",
    "אמציה המלך",
    "אנגל יואל",
    'אנגל יונה ד"ר',
    "אנדה עמיר",
    "אנה פרנק",
    "אנטוורפן",
    "אני מאמין",
    "אנילביץ מרדכי",
    "אנצ'ו סירני",
    "אנקואה רפאל הרב",
    "אסא המלך",
    "אסף דוד הרב",
    "אספיס אברהם",
    "אסתר המלכה",
    'אצ"ל',
    "ארגמן",
    "ארזי יהודה",
    "אריה (לובה) אליאב",
    "ארלוזורוב",
    "ארליך דוד פרופ'",
    "ארמן נחום",
    "ארן זלמן",
    "ארנון",
    "ארץ הצבי",
    "אשכולות",
    "אשר",
    "באבל יצחק",
    "בהרב יקותיאל",
    "בובר מרטין",
    'בודנהיימר מקס ד"ר',
    "בונה אליעזר",
    "בוסל יוסף",
    "בועז",
    "בורוכוב",
    "בורלא יהודה",
    "ביאליק",
    "ביילהארץ פרידריך",
    "בייליס מנחם מנדל",
    "ביכורים",
    'ביל"ו',
    "בילינסון",
    "ביר אל יזק",
    'בירם ד"ר',
    "בית אל",
    "בית אלפא",
    "בית לחם",
    "בית עולים בת גלים",
    "בית שאן",
    'בית"ר',
    "בל אלכסנדר גרהם",
    "בלהה",
    "בלוך ארנסט",
    "בלום לאון",
    "בליטנטל משה",
    "בלפור",
    "במסילה",
    "בן ארצי",
    "בן בבא",
    "בן הרוש דוד",
    "בן זכאי",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן יעקב צבי",
    "בן עדיה שמואל",
    "בן עמי",
    "בן צבי יצחק",
    "בן צור שמואל",
    "בן ציון שמחה",
    "בן שבת שמואל",
    "בן שמן",
    "בני ברית",
    "בנין זאב",
    "בעל התניא",
    "בעל שם טוב",
    "בעלי מלאכה",
    "בצלאל",
    "בצרה",
    "בר אילן",
    "בר גיורא",
    "בר יהודה ישראל",
    "בר יוחאי",
    "בר כוכבא",
    "בר רב האי דוד",
    "ברגמן יעקב",
    "ברדיצב אבא",
    "ברודסקי זאב",
    "ברוואלד",
    "ברון הירש",
    "ברוריה",
    "ברזילי",
    "ברכת משה",
    "ברל כצנלסון",
    "ברלב חיים",
    "ברנדייס",
    "ברנר",
    "ברנשטיין פרץ",
    "ברקת",
    "בשור",
    "בת חן",
    "בתי הזיקוק",
    "ג'ובראן סלים",
    "ג'ורג' אליוט",
    "ג'למה",
    "גאולה",
    "גאולי תימן",
    "גבעון",
    "גבעת דאונס",
    "גבעת זמר",
    "גדוד 22",
    "גדליהו",
    'גדנ"ע',
    "גדעון",
    "גולדברג לאה",
    "גולדמן נחום",
    "גולדפאדן אברהם",
    "גולן שמחה",
    "גור יהודה",
    "גורדון",
    "גורדוניה",
    "גורן שלמה הרב",
    "גוש חלב",
    "גוש עציון",
    "גזאל",
    "גיבורי גיטו ורשה",
    "גירני",
    "גלאל אל דין",
    "גלבוע",
    "גלגל",
    "גלוברמן יהושע",
    "גן אלי כהן",
    "גן הבהאים",
    "גנסין",
    "גץ יצחק",
    "גרינבוים יצחק",
    "גרינברג אורי צבי",
    "גרינשפון הרצל",
    "גרנד קניון",
    "גשר פז",
    "גת",
    "דאהר אל עמר",
    "דבורה",
    "דובנוב",
    "דובר שלום",
    "דוברין נחום",
    "דוגית",
    "דוד המלך",
    "דוכיפת",
    "דולצ'ין אריה",
    "דונה גרציה",
    "דוסטרובסקי אליעזר",
    "דורות",
    'דורי יעקב רא"ל',
    "דישראלי",
    "דנה יוסף הרב",
    "דניאל",
    "דניה",
    "דנינו אברהם",
    "דפנה",
    "דרור",
    "דרייפוס",
    "דרך אלנבי",
    "דרך אריק שרון",
    "דרך דיין משה",
    "דרך הים",
    "דרך העמקים",
    "דרך העצמאות",
    "דרך חנקין",
    "דרך יד לבנים",
    "דרך ידין יגאל",
    "דרך יפו",
    "דרך כרמל משה",
    "דרך נחל הגיבורים",
    "דרך סטלה מריס",
    "דרך צייזל ירוחם",
    "דרך צרפת",
    "דרך רודיק שמעון",
    "דרך רופין",
    "האבות",
    "האג",
    "האומן",
    "האיטלקים",
    "האלה",
    "האלון",
    "האמהות",
    "האמונה",
    "האמוראים",
    "האסיף",
    "האצטדיון",
    'האר"י',
    "הארזים",
    "האשחר",
    "האשל",
    "האשלג",
    "הבונים החפשיים",
    "הבישוף חג'אר",
    "הבניה",
    "הבנים",
    "הבנקים",
    "הבסטיליה",
    "הבריכה",
    "הבשן",
    "הגאונים",
    "הגבורים",
    "הגביש",
    "הגבעטרון",
    "הגדוד העברי",
    "הגומא",
    "הגליל",
    "הגנים",
    "הגפן",
    "הדגן",
    "הדודאים",
    "הדולב",
    "הדייגים",
    "הדסה",
    "הדסים",
    "הדף היומי",
    "הדר",
    "הדר הכרמל",
    "הדשנים",
    "ההגה",
    "הואדי",
    "הוברמן ברוניסלב",
    "הוד",
    "הויסמנס קמיל",
    "הולנד",
    "הולצברג שמחה",
    "הורדים",
    "הותיקים",
    "הזז חיים",
    "הזית",
    "הזמיר",
    "החבצלת",
    "החוף הדרומי",
    "החותרים",
    'החי"ל',
    'החיד"א',
    "החיטה",
    "החיננית",
    "החלוץ",
    "החרושת",
    "החרמש",
    "החרש",
    "החשמונאים",
    "החשמל",
    "הטחנות הגדולות",
    "הטנא",
    "היובל",
    "היוצק",
    "הים התיכון",
    "הינדס מתתיהו",
    "היצירה",
    "הירדן",
    "הירוק",
    "הירמוך",
    "הירקון",
    'הכ"ג',
    "הכנסיות",
    "הכשרת היישוב",
    "הליבנה",
    "הלילך",
    "הלל",
    "הללויה",
    "הלפרן ירמיהו",
    "המאירי אביגדור",
    "המולדת",
    "המוסכים",
    "המחנך נפחא",
    "המים",
    "המימוני",
    "המכס",
    "המלאכה",
    "המסגר",
    "המסורת",
    "המסילה",
    "המעלות",
    "המעפילים",
    "המערות",
    "המפוח",
    "המפרי יוברט",
    "המרוניטים",
    "המשביר",
    "המשטרה",
    "הנביאים",
    "הנגב",
    "הנגיד",
    "הנוטר",
    "הנופר",
    "הנטקה",
    "הנמל",
    "הנפח",
    'הנצי"ב',
    "הנרקיסים",
    "הנשר",
    "הס",
    "הסביונים",
    "הסדנה",
    "הסולל",
    "הסחלב",
    "הסנה",
    "הסנונית",
    "הספורט",
    "הסראיה",
    "העבודה",
    "העוגן",
    "העיר התחתית",
    "העליה השניה",
    "העמלים",
    "העמק",
    "הערמונים",
    "העתיד",
    "הפוסקים",
    "הפועל",
    "הפלוגות",
    "הפלס",
    "הפרחים",
    "הפרטיזנים",
    "הפרסה",
    "הפרסים",
    "הפשוש",
    "הצדף",
    "הצוללת דקר",
    "הצופים",
    "הצלבנים",
    "הצלפים",
    "הצפירה",
    "הקבוצים",
    "הקונגרס",
    "הקורנס",
    "הקטר",
    "הקיטור",
    "הקישון",
    "הקלעים",
    "הקשת",
    "הראל",
    "הראשונים",
    "הרב הרצוג",
    "הרדוף",
    "הרון אל רשיד",
    "הרופא",
    "הרותם",
    'הרי"ף',
    "הרמן צבי",
    "הרץ היינריך",
    "הרצל",
    "הרצליה",
    "הרצפלד אברהם",
    "הרקפות",
    "השומר",
    "השופט שלום קסאן",
    "השחף",
    "השחר",
    "השיירה",
    "השיש",
    "השל יהושע פרופ'",
    "השלוח",
    "השלום",
    "השקד",
    "השרון",
    "התאנה",
    "התבור",
    "התור",
    "התורן",
    "התיכון",
    "התמר",
    "התנאים",
    "התקוה",
    "התקומה",
    "התשבי",
    "התשעה",
    "התשעים ושלוש",
    "ואדי סליב",
    "ואדי רושמיה",
    "וודג'ווד",
    "ווט ג'יימס",
    "ווטסון ליונל",
    "וולפסון",
    "וייז סטפן",
    'ויינשל אברהם ד"ר',
    "וילבוש נחום",
    "וילנאי זאב",
    "וילנסקי",
    "וינר יוסף",
    'ויצ"ו',
    "ויקטור הוגו",
    "ויתקין יוסף",
    "ולנברג ראול",
    "ורדיה",
    "ז'ורס",
    "זאב (זבוטינסקי)",
    "זאב הים",
    "זבולון",
    "זוהר",
    "זולא אמיל",
    "זורע מאיר (זרו)",
    "זיוונית",
    "זייד אלכסנדר",
    "זילפה",
    'זימנא נחום ד"ר',
    "זינגר מנדל",
    "זיסו א ל",
    "זמכשרי",
    "זמנהוף",
    "זרובבל",
    'חב"ד',
    "חבובה אליהו",
    "חביבה רייך",
    "חבס הכוהן ברכה",
    "חבקוק",
    "חברון",
    "חדאד",
    "חדרה",
    "חוחית",
    "חולדה",
    "חומה ומגדל",
    "חוסן",
    "חוף בת גלים",
    "חוף דדו",
    "חוף הכרמל",
    "חוף השנהב",
    "חוף זמיר",
    "חוף קרית חיים",
    "חוצות המפרץ",
    "חורב",
    "חורשה",
    "חזן יעקב",
    "חזקיהו המלך",
    "חטיבת גולני",
    "חטיבת כרמלי",
    "חטיבת עודד",
    "חיות פרץ",
    "חיים",
    "חיל הים",
    "חיפה",
    "חירם",
    "חכים אליהו",
    "חכמי ישראל",
    "חלוצי התעשיה",
    "חליסה רח א",
    "חלמיש",
    "חלץ",
    "חמאם אל פשה",
    "חמדה",
    "חנה",
    "חניתה",
    "חסידי אומות העולם",
    "חסן שוקרי",
    "חפצי בה",
    "חצוצרה בוואדי",
    "חצרות כרמל",
    "חרב לאת",
    "חרוב",
    "חרמון",
    "חשבון",
    "חת נחום",
    "טאריק",
    "טבנקין יצחק",
    "טבריה",
    "טברסקי ג'ניה",
    "טהון יעקב",
    "טובי תופיק",
    "טובים",
    "טולצ'ינסקי ברוך",
    "טוסקניני ארתור",
    "טופז",
    "טרומן",
    "טשרניחובסקי",
    'י"א הספורטאים',
    "יבנאלי שמואל",
    "יבנה",
    "יגאל אלון",
    "יגור",
    "יגיע כפיים",
    "יהואש המלך",
    "יהודה הלוי",
    "יהודה עמיחי",
    "יהודית",
    "יהושע",
    "יהושפט המלך",
    "יואב",
    "יואל",
    "יודפת",
    "יוחאי בן-נון",
    "יוחנן הקדוש",
    "יוכבד",
    "יוליוס סימון",
    "יונה",
    "יונתן",
    "יוסיפון",
    "יוסף (הגדם)",
    "יורדי הים",
    "יותם",
    "יחזקאל",
    "יחיאל",
    "יחיעם",
    'יל"ג',
    'יל"ג דרומית',
    'יל"ג צפונית',
    "ילדי טהרן",
    "ינקולוביץ חיים",
    "יעל",
    "יערות",
    "יערי מאיר",
    "יפה הלל",
    "יפה ושמואל שכטר",
    "יפה נוף",
    "יפעה",
    "יפתח",
    "יציאת אירופה",
    "יציב יצחק",
    "יקוט",
    "יקינתון",
    "ירושלים",
    'ירושלמי אליעזר ד"ר',
    "ישורון",
    "ישעיהו",
    "ישראלי בן ציון",
    'כ"ץ יאיר',
    'כ"ץ יעקב הרב',
    "כאהן יעקב",
    "כבירים",
    "כברי",
    "כגן רחל",
    "כהן ברוך",
    "כורי",
    "כורש",
    "כטיב",
    "כיאט",
    "כיכר 6002",
    "כיכר 6052",
    "כיכר 6075",
    "כיכר 6085",
    "כיכר 6098",
    "כיכר אורי זוהר",
    "כיכר ברוך רפפורט",
    "כיכר גיורא פישר",
    "כיכר דנמרק",
    "כיכר הבורג'",
    "כיכר הפיליפינים",
    "כיכר ואצלב האוול",
    "כיכר זאהי כרכבי",
    "כיכר זינגר יוסף",
    "כיכר חביב",
    "כיכר חתני פרס נובל",
    "כיכר יצחק חופי",
    "כיכר ליאו גולדהמר",
    "כיכר משה ורטמן",
    "כיכר נפתלי אילתי",
    "כיכר עמנואל זמיר",
    "כיכר פאפא",
    "כיכר פרימו לוי",
    "כיכר רובי",
    "כיכר שמואל טנקוס",
    "ככר אגמי אורי",
    'ככר אונסק"ו',
    "ככר אליזבט",
    "ככר אמיל גרינצוויג",
    "ככר אמיל חביבי",
    "ככר אמפא",
    "ככר אנואר סאדאת",
    "ככר בולגריה",
    "ככר בני אחוזה",
    "ככר ברנר מילה",
    "ככר ג'רלד פרדמן",
    "ככר דוד",
    "ככר דוד הכהן",
    "ככר האוניברסיטה",
    "ככר הגבורה",
    "ככר ההגנה",
    "ככר היינה",
    "ככר הכט ראובן",
    "ככר הלייט",
    "ככר המר זבולון",
    "ככר הנדריק סרברוב",
    "ככר הפיליפינים",
    "ככר הרברט סמואל",
    "ככר הרקפות",
    "ככר וידרא נפתלי",
    "ככר זיו",
    "ככר זכאי אברהם",
    "ככר זלטין",
    "ככר חיל הרפואה",
    "ככר חיפה - בוסטון",
    "ככר יד לבנים",
    "ככר ישעיהו לייבוביץ",
    "ככר כהן דוד",
    "ככר כיאט",
    "ככר מאירהוף",
    "ככר מוסינזון יגאל",
    "ככר מינה",
    "ככר מסריק",
    "ככר מעפילי קפריסין",
    "ככר סולל בונה",
    "ככר ספקטור",
    "ככר עולי הגרדום",
    "ככר עציוני משה",
    "ככר פדרמן יקותיאל",
    "ככר פול ומאי אריאלי",
    "ככר פייצל",
    "ככר פלומר",
    "ככר פריס",
    "ככר צייזל",
    "ככר צים",
    "ככר קפלנסקי",
    "ככר שבבו שושנה",
    "ככר שמידט אנטון",
    "ככר שפירא יצחק",
    "כל ישראל חברים",
    "כליל החורש",
    "כלניות",
    "כלפון הרב",
    "כנרת",
    "כספי יעקב",
    'כספרי ד"ר',
    "כפר כבביר",
    "כצמן משה",
    "כרוך אריה",
    "כרמי",
    "כרמיה",
    "כרמל מערבי",
    "כרמל מרכזי",
    "כרמל צפוני",
    "לאה",
    "לאונרדו דה וינצי",
    "לב המפרץ",
    "לבון פנחס",
    "לבונה",
    "לבונטין",
    "לביא שלמה",
    "לבנון ישראל",
    "לברי מרק",
    "לוד",
    "לוחמי הגטאות",
    "לוחמי חרות ישראל",
    "לוטוס",
    "לוי יוסף",
    "לוי יצחק",
    "לוין אריה הרב",
    "לוין גוטל",
    "לונץ",
    "לוץ שרל",
    "לוצטו",
    "לזרוס אמה",
    "ליבריה",
    "ליויק",
    "ליטאניס",
    "לייב יפה",
    "לינדנשטראוס אהרון",
    "לינקולן אברהם",
    "ליפשיץ יוסף",
    "לכיש",
    'לנ"י',
    'לנדאו מיכאל ד"ר',
    "לסין אברהם",
    'לסקוב חיים רא"ל',
    "לשם",
    "מאי",
    "מאיר (רוטברג)",
    "מאיר גולדה",
    "מאירוביץ צבי",
    "מאלי יעקב משה",
    "מאפו",
    "מאציני",
    "מבוא הכרמל",
    "מבוא חיפה",
    "מבצע יונתן",
    "מבצע קדש",
    "מג'דלאני ג'אן",
    "מגדל אור",
    "מגורשי ספרד",
    "מגידו",
    "מד אברהם",
    "מד אירביד",
    "מד אלגביש יוסף",
    "מד בוניאק ראובן",
    "מד בית שערים",
    "מד בני אור",
    "מד בת שבע",
    "מד גדרה",
    "מד גלעד",
    "מד גמלא",
    "מד דוניה טוביה",
    "מד דור",
    "מד החרצית",
    "מד הלפרט נפתלי",
    "מד המוכתר צבי",
    "מד הנביאים",
    "מד הקנאים",
    "מד הרצל",
    "מד השלוח צפון",
    "מד זכריה",
    "מד זרובבל",
    "מד חגית",
    "מד חגלה",
    "מד חצב",
    "מד חרות",
    "מד מודיעין",
    "מד מחנות",
    "מד מילר נחום",
    "מד מעלה הדקר",
    "מד מרים",
    "מד נוזהה ב",
    "מד נפתלי",
    "מד עגלון",
    "מד עמנואל",
    "מד פנחס כהן",
    "מד פקיעין",
    "מד צמח צדיק",
    "מד רשפון",
    "מד שוקרי",
    "מד שפינוזה",
    "מד תימן",
    "מוהליבר",
    "מונטיפיורי",
    "מור",
    "מורג",
    "מורד היסמין",
    "מורדות נוה שאנן",
    "מורן",
    "מושלי יעקב",
    "מושקא חיה",
    "מחלף גדוד 21",
    "מחלף ההסתדרות",
    "מחלף הקישון",
    "מחניים",
    "מייבלום דורון",
    'מיכ"ל המשורר',
    "מיכאל",
    "מיכואלס שלמה",
    "מילכה",
    "מילסון יוסף צבי",
    "מימון הרב",
    "מכון הטיהור",
    "מכון זואולוגי",
    "מכליס משה אהרון",
    'מל"ל',
    "מלון גלרי",
    "מלון מגדל חיפה",
    "מלון מרידיאן",
    "מלצ'ט",
    "מנגר איציק",
    "מנדלי מוכר ספרים",
    "מנדלסון דבורה",
    "מנהרות הכרמל",
    "מנחם",
    "מנילה",
    "מנצור",
    "מסדה",
    "מסילת הברזל",
    "מסילת ישרים",
    "מספנות ישראל",
    "מע אחוזה",
    "מע ק אליהו",
    "מע ק שמואל",
    "מעברות",
    "מעון אבא חושי",
    "מעון אקדמאים",
    "מעון קלמן לוין",
    "מעין",
    "מעלה השחרור",
    "מעלות אל עבאסי עסאם",
    "מעלות בוגלה יונה",
    "מעלות גרונר דב",
    "מעלות הבוצרים",
    'מעלות חת"ם סופר',
    "מעלות ישכיל",
    "מצובה",
    "מצפה",
    "מקור ברוך",
    'מקלף מרדכי רא"ל',
    "מרגולין פנחס",
    "מרגלית",
    "מרווה",
    "מרחביה",
    "מרכוס דוד",
    "מרכוס הרב",
    "מרכז הנופש",
    "מרכז קסטרא",
    "מרכז תעשיות מדע",
    "מרמרי",
    "מרסיי",
    "מרקוני גוליאלמו",
    "משאש הרב",
    "משה חיים שפירא",
    "משטרת קרית חיים",
    "משמר הגבול",
    "משמר העמק",
    "משעול יהודית ומאיר",
    "משעול תורת חיים",
    "מתחם האיצטדיון",
    "מתחם מיכלי הנפט",
    "נאמנים",
    "נג'ד",
    "נדב יצחק",
    "נהון יצחק",
    "נהלל",
    "נהריים",
    "נוגה",
    "נוה גנים",
    "נוה דוד",
    "נוי",
    "נועה",
    "נועם",
    "נוף המפרץ",
    "נורדאו",
    "נורווגיה",
    "נורית",
    'נח"ל',
    "נחליאלי",
    "נחמיאס יוסף",
    "נחמיה",
    "נחשון",
    "נטר קרל",
    "ניוטון אייזיק",
    'ניל"י',
    "ניסים יצחק הרב",
    "ניסנבוים",
    "ניצנים",
    "נמל הדיג",
    "נמל הקישון",
    "נמל חיפה",
    "נמרי דוד",
    'נעמ"ת',
    "נעמי",
    "נעמי שמר",
    "נתיב אליעזר",
    "נתיב אפקים",
    "נתיב האור",
    "נתיב האתרוג",
    "נתיב ההדס",
    "נתיב הישיבה",
    "נתיב הלולב",
    "נתיב העולים",
    "נתיב הערבה",
    "נתיב חן",
    'נתיב שד"ר',
    "נתיבי המפרץ",
    "נתנזון",
    "סבידור מנחם",
    "סביוני הכרמל",
    "סגליות",
    "סוטין חיים",
    "סוכות",
    "סולד הנרייטה",
    "סוקולוב",
    "סורוקה משה",
    "סחרוב אנדרה",
    "סטרומה",
    "סייפן",
    "סילבר אבא הלל",
    "סיליזיאן",
    "סירקין",
    "סכנין אברהם",
    "סלוודור",
    "סלומון יעקב",
    "סלמאן",
    "סלעית",
    "סמ אהרון",
    "סמ אוריין מאיר",
    "סמ אני מאמין",
    "סמ אשקלון",
    "סמ בנימין",
    "סמ ברק",
    "סמ דן",
    "סמ הבוסתן",
    "סמ הוז דב",
    "סמ הזגג",
    "סמ הירמוך",
    "סמ הכנסיה",
    "סמ הכרמים",
    "סמ הנביאים",
    "סמ העמים",
    "סמ העצמאות",
    "סמ הצנחנים",
    "סמ הרכבת",
    "סמ ואדי סליב",
    "סמ חג'אג'",
    "סמ יהודה",
    "סמ מונש",
    "סמ מוסול",
    "סמ מירון",
    "סמ נוזהה",
    "סמ ספרון",
    "סמ עופרים",
    "סמ עזה",
    "סמ עתלית",
    "סמ פליק",
    "סמ צור",
    "סמ צידון",
    "סמ רובין",
    "סמ שמואל",
    "סמדר",
    "סמולנסקין",
    "סמטת מטמון",
    "סמטת ממון",
    "סנה משה",
    "סנט לוקס",
    "סנש חנה",
    "סעדיה גאון",
    "ספיר",
    "סתוונית",
    "עבאס",
    'עגנון ש"י',
    "עדה",
    "עדולם",
    "עובדיה",
    "עודה נדה",
    "עוזיהו המלך",
    "עומר אל כיאם",
    "עומר אל כתאב",
    "עוספיה",
    "עזרא",
    "עין גדי",
    "עין דור",
    "עין הים",
    "עירם צילה",
    "עליית הנוער",
    "עמון",
    "עמוס",
    "עמיקם ישראל",
    "עמל",
    "עמק הזיתים",
    "ענבר",
    "ענתות",
    "עספור",
    'עפרון ד"ר',
    "עצמון",
    "עקבה",
    "עקרון",
    "ערד",
    "עשת אביגדור",
    "פאר",
    "פבזנר",
    "פולד ברכה",
    "פועה",
    "פורטונה עמנואל",
    "פז סעדיה",
    "פטל",
    "פטריה",
    "פיארברג",
    "פייגין צבי",
    'פייזר ד"ר',
    "פייצל",
    "פיכמן יעקב",
    "פינלנד",
    "פינסקי דוד",
    "פינסקר",
    "פינת גן",
    'פיק"א',
    "פישמן עדה (מיימון)",
    "פלג פרנק",
    'פלי"ם',
    "פלימן משה",
    'פלמ"ח',
    "פנינה",
    "פנת בן יהודה",
    "פנת בן שמן",
    "פנת הרצל",
    "פנת לונץ",
    "פנת שפירא",
    "פסטר לואי",
    "פקדון",
    "פרוג",
    "פרויד",
    "פרומר דב",
    'פרידמן שמעון ד"ר',
    "פרישמן",
    "פרנץ לוי",
    "פרץ י. ל.",
    "פרץ מרקיש",
    "פת יעקב",
    "צ'ק פוסט",
    "צ'רניאבסקי אהרון",
    "צביה ויצחק",
    "צבעוני",
    "צבר",
    "צדקיהו",
    'צה"ל',
    "צהיון",
    "צופית",
    "ציון",
    "ציזלינג אהרון",
    "צייטלין אהרון",
    "צילה",
    "ציפורי",
    "ציפר",
    "ציקלג",
    "צלמונה",
    "צפורה זייד",
    "צפרירים",
    "צפת",
    'קאודרס בן ציון ד"ר',
    "קארו יוסף הרב",
    "קבוץ גלויות",
    "קדושי בגדד",
    "קדושי דמשק",
    "קדושי השואה",
    "קדושי יאסי",
    "קדימה",
    "קדיש לוז",
    "קדרון",
    "קהילת סלוניקי",
    "קויפמן יחזקאל פרופ'",
    "קול מבשר",
    "קומוי נתן",
    "קוסטה ריקה",
    "קורנית",
    "קורצ'אק יאנוש",
    "קושניר יוסף",
    "קיזרמן נתן",
    "קיסרי אורי",
    "קיסריה",
    "קישלה",
    'קל"ח',
    "קליבנוב",
    "קלישר הרב",
    "קלר",
    "קניאל יהושע הרב",
    "קניג פייר גנרל",
    "קניון חיפה",
    "קסטרא",
    "קסל",
    "קפטן סטיב",
    "קפלן אלישבע",
    "קצנשטיין אריה",
    "קריב אברהם",
    "קריית ספר",
    "קרית אליעזר",
    "קרית הטכניון",
    "קרית חיים מזרחית",
    "קרית חיים מערבית",
    "קרית חשמל",
    "קרית רבין",
    "קרן היסוד",
    "קרן קיימת לישראל",
    'קרפל ד"ר',
    "קשטן מיכאל",
    "ראהבת",
    "ראם פנחס",
    "ראש מיה",
    "ראש פינה",
    "רבוה",
    "רבי יהודה הנשיא",
    "רבי יוחנן הסנדלר",
    "רבי עקיבא",
    'רבין אסתר ד"ר',
    "רביצקי אימרה",
    "רבנו תם",
    'רד"ק',
    "רובינא חנה",
    "רובינשטיין יצחק הרב",
    "רודן",
    "רודנר עקיבא הרב",
    "רוזוב שמואל",
    "רוזן פנחס",
    "רוטנברג פנחס ואברהם",
    "רות",
    "רזיאל דוד",
    "רח 2620",
    "רח 580",
    "רח 2611",
    "רח 202",
    "רח 2622",
    "רח 822",
    "רח 2632",
    "רח 2623",
    "רח 2653",
    "רח 2624",
    "רח 564",
    "רח 2625",
    "רח 2635",
    "רח 735",
    "רח 665",
    "רח 9016",
    "רח 666",
    "רח 586",
    "רח 2627",
    "רח 547",
    "רח 2647",
    "רח 2628",
    "רח 2648",
    "רח 2588",
    "רח 688",
    "רח 2649",
    "רח 2659",
    "רח 689",
    "רח לו",
    "רח לח",
    "רח מא",
    "רח מו",
    "רח מח",
    "רח נא",
    "רח נד",
    "רח נו",
    "רח נט",
    "רח סא",
    "רח סג",
    "רח סה",
    "רח סז",
    "רחבת הטמפלרים",
    "רחבת המחוללים",
    "רחבת כמיל שחאדה",
    "רחל",
    "רחף",
    "רטנר יוחנן פרופ'",
    "ריינס הרב",
    "רימונים",
    'רינ"ה',
    'רמב"ן',
    "רמות ספיר",
    "רמות רמז",
    "רמת אלון",
    "רמת אלמוגי",
    "רמת אשכול",
    "רמת בגין",
    "רמת בן גוריון",
    "רמת גולדה",
    "רמת גוראל",
    "רמת התשבי",
    "רמת חן",
    'רנ"ק',
    "רענן",
    'רש"י',
    "ש אנגלו סכסים",
    "ש אסבסט קרית חיים",
    "ש אקדמאים",
    "ש בולגרים חל",
    "ש הוד הכרמל",
    "ש ח משוח בת גלים",
    "ש חדש קרית שמואל",
    "ש חילים משוחררים",
    "ש משכנות ק שמואל",
    "ש נוה יוסף",
    "ש סוכנות ק שמואל",
    "ש ערוני מפרץ",
    'ש צה"ל',
    "ש קרית שמואל ב",
    "ש קרית שפרינצק",
    "ש רמת גאולה",
    "ש רמת הדר",
    "ש רמת ויזניץ",
    "ש רמת שאול",
    "שאגאל מארק",
    "שאול",
    "שאול המלך",
    "שאר ישוב",
    "שארית הפליטה",
    "שבדיה",
    "שבזי שלום",
    "שבטי ישראל",
    "שביל אהבת שלום",
    "שביל חנא נקארה",
    "שביל פומרוק משה",
    "שביל רבקה",
    "שבעת המינים",
    "שבתאי לוי",
    "שד אבא חושי",
    "שד בן גוריון",
    "שד בת גלים",
    "שד גולומב אליהו",
    "שד דגניה",
    "שד הברושים",
    "שד ההגנה",
    "שד ההסתדרות",
    'שד הל"ה',
    "שד המגינים",
    "שד המתנדבים",
    "שד הנדיב",
    "שד הנשיא",
    "שד הצבי",
    "שד הציונות",
    'שד הרא"ה',
    "שד וינגיט",
    "שד ורבורג",
    "שד טרומפלדור",
    "שד יצחק",
    "שד מוריה",
    'שד מח"ל',
    "שד סיני",
    "שד קיש",
    "שד קרית אליעזר",
    "שד רוז",
    "שד רוטשילד",
    "שד רות הכהן",
    "שד שיקגו",
    "שד' אפרים קציר",
    "שד' עזר וייצמן",
    "שדה בוקר",
    "שדה התעופה",
    "שדה יצחק",
    "שדרות הדקלים",
    "שהם צבי",
    "שוהם",
    'שוויצר אלברט ד"ר',
    "שומאכר גוטליב",
    "שומרון",
    "שונית",
    "שונמית",
    "שופמן גרשון",
    "שושנת הכרמל",
    'שז"ר זלמן',
    "שחאדה שלח",
    "שחם",
    "שטיין ליאון",
    "שטרן יאיר",
    "שטרן יוסף",
    "שיבת ציון",
    "שיטרית בכור",
    'שימקין ד"ר',
    "שינדלר אוסקר",
    "שכ אחוזה",
    "שכ גאולה",
    "שכ החוף השקט",
    "שכ המגינים",
    "שכ המושבה הגרמנית",
    "שכ הרכבת",
    "שכ הרצליה",
    "שכ ואדי טינה",
    "שכ ואדי ניסנאס",
    "שכ ואדי שיאח",
    "שכ ורדיה",
    "שכ זיו",
    "שכ חליסא",
    "שכ יזרעאליה",
    "שכ כפר סמיר",
    "שכ כרמל צרפתי",
    "שכ כרמליה",
    "שכ מרכז הדר",
    "שכ נאות פרס",
    "שכ נווה גנים",
    "שכ נווה שאנן",
    "שכ נחלה",
    "שכ קרית אליהו",
    "שכ קרית חיים",
    "שכ קרית שמואל",
    "שכ רוממה",
    "שכ שמבור",
    "שכ שער העליה",
    "שכ תל עמל",
    'שכטר יוסף ד"ר',
    'של"ג',
    "שלהוב ג'מיל",
    "שלום עליכם",
    "שלומציון המלכה",
    "שלון",
    "שלונסקי",
    'שלוסברג שמואל ד"ר',
    "שלמה המלך",
    "שמאי",
    "שמואל",
    "שמעוני לייב",
    "שמריהו לוין",
    "שמש",
    "שמשון",
    "שניאור זלמן",
    "שניים בנובמבר",
    "שנקר אריה",
    "שער הגיא",
    "שער הלבנון",
    "שער פלמר",
    "שפירא",
    "שפרה",
    "שפרעם",
    "שקמה",
    "שקמונה",
    "שרה",
    "שרעבי שלום רבי",
    "שרת משה",
    "ששת הימים",
    'תאד"ל',
    "תאשור",
    "תבואות הארץ",
    "תדהר",
    "תובל",
    "תומא אמיל",
    "תורה ועבודה",
    "תחנה מרכזית",
    "תחנת הכרמל",
    "תל אביב",
    "תל חי",
    "תל מאנה",
    "תל עמל",
    "תל שקמונה",
    "תענך",
    'תרי"ג',
    "תרצה",
    "תרשיש",
  ],
  חירות: [
    "בת-חן",
    "דרך המייסדים",
    "דרך השדות",
    "דרך השקמים",
    "האלון",
    "האנפה",
    "האתרוג",
    "הבאר",
    "הבנים",
    "ההרחבה",
    "המשתלה",
    "הפיקוס",
    "הצבעוני",
    "הצבר",
    "השיזף",
    "השקד",
    "חירות",
    "נוף",
    "סמ החצב",
    "סמ הכלנית",
    "סמ העירית",
    "עין התכלת",
    "שכ בנים",
  ],
  חלוץ: ["חלוץ"],
  חלץ: ["חלץ"],
  חמאם: ["חמאם"],
  חמד: [
    "הגפן",
    "הזית",
    "החיטה",
    "הנחלים",
    "העומר",
    "הרימון",
    "התאנה",
    "התמר",
    "חמד",
    "נחל איילון",
    "נחל ארבל",
    "נחל גבעות",
    "נחל דולב",
    "נחל דן",
    "נחל הבשור",
    "נחל הירדן",
    "נחל זרי הדפנה",
    "נחל חרמון",
    "נחל שפירים",
    "נחל תבור",
  ],
  חמדיה: ["בית ראשון במולדת", "חמדיה"],
  חמדת: ["חמדת"],
  חמרה: ["חמרה"],
  חניאל: [
    "האורן",
    "הגבעה",
    "הגיא",
    "החרוב",
    "הראשונים",
    "חניאל",
    "ככר אורנים",
    "סמ הבאר",
    "סמ הפרדס",
  ],
  חניתה: ["אולפן קבוץ", "חניתה"],
  חנתון: [
    "איבי הנחל",
    "אמת מים",
    "באר מרים",
    "גשמי ברכה",
    "דרך האגם",
    "דרך נאות מדבר",
    "חנתון",
    "טל ומטר",
    "יובל",
    "כמעיין המתגבר",
    "מעין",
    "משעול האפיק",
    "נווה גנים",
    "פלג",
    "שירת הים",
  ],
  חספין: ["חספין"],
  "חפץ חיים": ["חפץ חיים", "מרכז קליטה"],
  "חפצי-בה": ["חפצי-בה"],
  חצב: ["חצב"],
  חצבה: ['בי"ס שדה חצבה', "חצבה"],
  "חצור הגלילית": [
    "אבני נחל",
    "אזור התעשייה",
    "אלימלך נועם",
    "אתרוג",
    "בן גוריון",
    "בן יוסף שלמה",
    "בן ציון",
    "בנה ביתך",
    "דבורה הנביאה",
    "דרך הבנים",
    "דרך החולה",
    'דרך קק"ל',
    "דרך רבין",
    "האגס",
    "האומן",
    "האורג",
    "האורן",
    "האירוס",
    "האלה",
    "האקליפטוס",
    "הארז",
    "הארי",
    "האשחר",
    "האשל",
    "הבוסתן",
    "הבנאי",
    "הברוש",
    "הברושים",
    "הגפן",
    "הדובדבן",
    "הדולב",
    "הדקל",
    "הדרור",
    "ההדס",
    "הזגג",
    "הזית",
    "החסידה",
    "החרוב",
    "החרש",
    "היקינטון",
    "הכלנית",
    "המייסדים",
    "המסגר",
    "הנגר",
    "הנחליאלי",
    "הנפח",
    "הנשיא ויצמן",
    "הנשר",
    "הסחלב",
    "הסנונית",
    "הסתונית",
    "העינב",
    "הערבה",
    "הפיקוס",
    "הפשוש",
    "הצפצפה",
    "הקטלב",
    "הרב מורגנשטרן",
    "הרב פלדמן משה זאב",
    "הרותם",
    "הרימון",
    "הרקפת",
    "השזיף",
    "השחף",
    "השיטה",
    "השקד",
    "התאנה",
    "התפוח",
    "חוות ינאי",
    "חוני המעגל",
    "חזון ישראל",
    "חצור א",
    "חצור הגלילית",
    "טעם הצבי",
    "יונתן בן עוזיאל",
    "יצחק שמיר",
    "ירושלים",
    "יששכר בני",
    "כליל החורש",
    "לוי אשכול",
    "מבוא התנאים",
    "מבוא מצליח",
    "מנחם",
    "מנחם בגין",
    "מעלה הקרייה",
    "נוה הרים",
    "נוף המושבה",
    "נחמיה",
    "נרקיס",
    "נתיבות שלום",
    "סביון",
    "סולד הנריטה",
    "סמ הכרכום",
    "קדושת לוי",
    "קריה חסידית",
    "קרית בלוך",
    "קרית החנוך",
    "רבי טרפון",
    "רבי מאיר בעל הנס",
    "רבי עקיבא",
    "רובע מרכז",
    'רמב"ם',
    "רמון אילן",
    "ש עמידר",
    "שד הרב עזיזי דיעי",
    "שכ ארקיע",
    "שכ אשטרום",
    "שכ בנה ביתך",
    "שכ הבוסתן",
    "שכ הכרמים",
    "שכ זאב",
    "שם שמואל",
    "שער הרחמים",
    "שפירא מאיר",
    "שפרינצק",
    "תמר",
  ],
  "חצור-אשדוד": ["חצור-אשדוד"],
  "חצר בארותיים": ["חצר בארותיים"],
  "חצרות חולדה": ["חצרות חולדה"],
  "חצרות יסף": ["חצרות יסף"],
  'חצרות כ"ח': ['חצרות כ"ח'],
  חצרים: ["חצרים"],
  "חרב לאת": [
    "בית ספר תום",
    "האורן",
    "האלה",
    "האלון",
    "הארז",
    "הדולב",
    "הדקל",
    "הצאלון",
    "השיטה",
    "חרב לאת",
  ],
  חרוצים: [
    "הגפן",
    "ההדס",
    "הזית",
    "הערבה",
    "הרימון",
    "התאנה",
    "התמר",
    "חרוצים",
  ],
  חריש: [
    "אביטל",
    "אבני חן",
    "אדום",
    "אודם",
    "אורן",
    "אחדות",
    "אלה",
    "אלון",
    "אשל",
    "אתרוג",
    "ברוש",
    "ברקת",
    "גמלא",
    "גפן",
    "דולב",
    "דפנה",
    "דרך ארץ",
    "הגולן",
    "החורש",
    "החלומות",
    "הקהילה",
    "הר תבור",
    "השראה",
    "התחדשות",
    "התמדה",
    "זית",
    "חברותא",
    "חושן",
    "חריש",
    "טופז",
    "טורקיז",
    "יחד",
    "יסמין",
    "יקינטון",
    "כלנית",
    "לוטם",
    "לשם",
    "מרגלית",
    "נרקיס",
    "סביון",
    "סחלב",
    "ספיר",
    "ענבר",
    "ערבה",
    "ערבות",
    "צאלון",
    "צבעוני",
    "קידמה",
    "רובין",
    "רימון",
    "רעות",
    "רקפת",
    "שדרות האחווה",
    "שדרות ההגשמה",
    "שוהם",
    "שותפות",
    "שכונת בצוותא",
    "שכונת הפרחים",
    'שכונת מעו"ף',
    "שקד",
    "תאנה",
    "תמנע",
    "תמר",
    "תנופה",
  ],
  חרמש: ["חרמש"],
  חרשים: ["חרשים"],
  חשמונאים: [
    "ברקת",
    "הארבל",
    "הגפן",
    "הדגן",
    "הדקל",
    "הזית",
    "החרמון",
    "היצהר",
    "הכרמל",
    "הלבונה",
    "המור",
    "הצור",
    "הר נבו",
    "הרימון",
    "התאנה",
    "התבור",
    "התירוש",
    "התמר",
    "חשמונאים",
    "יהלום",
    "מוריה",
    "מעלה הגלבוע",
    "מעלה מירון",
    "משעול הכרמים",
    "משעול השקמים",
    "נטף",
    "עץ הדר",
    "עצמון",
    "ערבי נחל",
    "רמת מודיעין",
    "שוהם",
    "שיאון",
    "שניר",
    "תרשיש",
  ],
  טבריה: [
    "אבו חצירה",
    "אבולעפיה",
    "אדרעי מאיר",
    "אהבת ציון",
    "אהל יעקב",
    "אוסישקין",
    "אור חיים",
    "אורנים",
    "אזור תעשיה ומלאכה",
    "אזור תעשיה רמת אגוז",
    "אחד העם",
    "אחוה",
    "אחוזת בית",
    "איחוד הצלה",
    "איילה",
    "אילת",
    "אירוסים",
    "אלה",
    "אלון יגאל",
    "אלונים",
    "אלחדיף",
    "אלעזר דוד",
    "אלפסי",
    "אסתר המלכה",
    'אצ"ל',
    "ארז",
    "ארזים",
    "ארליך",
    "בהלול ציון",
    "ביאליק",
    "ביבאס",
    "בית הלל",
    "בית חולים",
    "בן אליעזר אריה",
    "בן זכאי",
    "בן צבי יצחק",
    "בר גיורא",
    "בר כוכבא",
    "ברוריה",
    "ברנר",
    "ברץ",
    "גולדה מאיר",
    "גולומב",
    "גולני",
    "גורדון",
    "גוש חלב",
    "גוש עציון",
    "גינוסר",
    "דגניה",
    "דהאן שמעון",
    "דובדבן",
    "דוד דנילוף",
    "דוד המלך",
    "דון יוסף",
    "דונה גרציה",
    "דרך גדוד ברק",
    "דרך הגבורה",
    "דרך הציונות",
    "דרך מנחם בגין",
    'דרך מר"ן',
    "דרך רונן",
    "האבות",
    "האמהות",
    'האר"י',
    "הבורג",
    "הבנים",
    "הגדוד העברי",
    "הגיבורים",
    "הגלבוע",
    "הגליל",
    "הגפן",
    "הדס",
    "הדקל",
    "הוז דב",
    "הופיין",
    "הורדוס",
    "הזית",
    "החרושת",
    "החרמון",
    "החשמונאים",
    "החשמל",
    "הים",
    "הירדן",
    "הירקון",
    "המאור",
    "המברג",
    "המגינים",
    "המכבים",
    "המלאכה",
    "המשור",
    "המשטרה",
    "המשיח",
    "הנביאים",
    "הנוטר",
    "הנוצרים",
    "הניאון",
    "הנצחון",
    "הנרקיס",
    "הנשיא ויצמן",
    "העיר העתיקה",
    "העמק",
    "העמקים",
    'הפלמ"ח',
    "הפנס",
    "הצנחנים",
    "הקדושים",
    "הקישון",
    "הקרן",
    "הר השקדים",
    "הרב אליישיב",
    "הרב ועקנין",
    "הרב חיים סמדג'א",
    "הרב חיים שפר",
    "הרב כהן משה",
    "הרב עוזיאל",
    "הרב קוק",
    "הרב שך",
    "הרצוג",
    "השומר",
    "השופטים",
    "השילוח",
    'השל"ה',
    "השלום",
    "התבור",
    "התנא נחום",
    "התנאים",
    "התעשיה",
    "וינגייט",
    "ורנר",
    "ז'בוטינסקי",
    "זיידל",
    "חברון",
    "חוף גיא",
    "חזון איש",
    "חייא ובניו",
    "חלמונית",
    "חצב",
    "חרצית",
    "טבור הארץ",
    "טבריה",
    "טבריה עילית",
    "טולידנו",
    "טיילת יגאל אלון",
    "טמסות חיים",
    "טרומפלדור",
    "יהודה הלוי",
    "יהודה הנשיא",
    "יוסיפוס",
    "יערה",
    "יפה נוף",
    "יפתח הגלעדי",
    "ירושלים",
    "כהנא",
    "כוכב",
    'כי"ח',
    "ככר העצמאות",
    "ככר ז'בוטינסקי",
    "כליל החורש",
    "כלנית",
    "כרכום",
    "לבנה",
    "לוטם",
    "לוי אשכול",
    'לח"י',
    "מבצע צוק איתן",
    "מודיעין",
    "מורדות טבריה",
    "מורן",
    "מיכאל",
    "מינו",
    "מלון ארנון",
    "מלון וושינגטון",
    "מלון טבריה",
    "מלון רסיטל",
    "מסדה",
    'מעלה קק"ל',
    "מצדה",
    "מצפה כנרת",
    "מרווה",
    "מרכז בעלי מלאכה",
    "מרכז קליטה",
    "נבון יצחק",
    "נבות הכרמלי",
    "נוף כנרת",
    "נורית",
    "נחמיה",
    "נחמני",
    "ניברג",
    "סביונים",
    "סייפן",
    "סיני",
    "סמ ציפורי",
    "סנש חנה",
    "עזרא",
    "עטר חיים",
    "עלית",
    "עצמון",
    "פועלי ציון",
    'פיק"א',
    "צאלון",
    "צבעונית",
    "ציפורי",
    "צפת",
    "קיסוס",
    "קפלנסקי",
    "קרית משה",
    "קרית צאנז",
    "רבי מאיר",
    "רוטשילד",
    "רותם",
    "רזיאל דוד",
    "רח 110",
    "רח 411",
    "רח 522",
    "רח 503",
    "רח 115",
    "רח 545",
    "רח 526",
    "רח 539",
    "רחל",
    'רמח"ל',
    "רמת אגוז",
    "רמת בן גוריון",
    "רמת טבריה",
    "רמת כנרת",
    "רקפת",
    'רש"י',
    "ש הרמבם",
    "ש ותיקים",
    "ש חסכון ד",
    "ש מווארס",
    "ש נוה חן",
    "ש סולל בונה",
    "ש עובדים",
    "ש עממי",
    "ש עץ חיים",
    "ש פלוס 200",
    "ש ציונים כלליים",
    "ש קליפורניה",
    "ש רמז",
    "שביט",
    "שביל הכפיר",
    "שביל הלביא",
    "שד אלנטאון",
    "שד הרצל",
    "שד ספיר",
    "שד קפלן אליעזר",
    'שד קק"ל',
    "שדה יצחק",
    'שז"ר זלמן',
    "שיזף",
    "שיטה",
    "שיטרית",
    "שכ אחווה",
    "שכ אחוזת כנרת",
    "שכ גני חמת",
    "שכ דון יוסף הנשיא",
    "שכ ההר",
    "שכ מימוניה",
    "שכ מנורה",
    "שכ נוה הדר",
    "שכ קרית שמואל",
    "שכ רבי עקיבא",
    "שכ רווקיה",
    "שכ רמת טבריה א'",
    "שכ רמת טבריה ג'",
    "שכ שרת",
    "שכ' בית וגן",
    "שכון א",
    "שכון ג",
    "שכם",
    "שלמה המלך",
    "שפירא משה",
    "שפע חיים",
    "שפת הים",
    "שקד",
    "שרת משה",
    "תג'ר",
    "תהילה",
    "תל חי",
    "תלתן",
    "תמר",
  ],
  "טובא-זנגריה": [
    "אבו בקר אלסדיק",
    "אבן ח'לדון",
    "אבן סינא",
    "אל-ח'נסאא",
    "אל-נח'ל",
    "אל-רביע",
    "אל-רומאן",
    "אלאמל",
    "אלאנדלוס",
    "אלאסיל",
    "אלאקסא",
    "אלביאדר",
    "אלג'ולאן",
    "אלג'ליל",
    "אלגוזלאן",
    "אלהודא",
    "אלהייב",
    "אלוורוד",
    "אלזוהור",
    "אלזייתון",
    "אלח'טאב",
    "אלח'ליל",
    "אלח'רוב",
    "אלחולה",
    "אלחסיד",
    "אלכרום",
    "אלמג'ד",
    "אלמותנבי",
    "אלמנארה",
    "אלנוורס",
    "אלנור",
    "אלנרג'ס",
    "אלסלאם",
    "אלסנדיאן",
    "אלספא",
    "אלעין",
    "אלפארוק",
    "אלקודס",
    "אלרחמה",
    "אלריחאנה",
    "אלרשיד",
    "אלשאם",
    "אלשאפעי",
    "אלשייך",
    "בילאל",
    "בניאס",
    "ג'בראן ח'ליל ג'בראן",
    "ח'אלד בן אלוליד",
    "חאתם אלטאאי",
    "חמזה",
    "טובא-זנגריה",
    "יגאל אלון",
    "מורג'אן",
    "מכה",
    "נהר אלאורדן",
    "סלאח אלדין",
    "עומר אלמוכתאר",
    "עות'מאן",
    "עין טובא",
  ],
  טורעאן: [
    "אבו בכר",
    "אבן סינא",
    "אבן תימייה",
    "אלאמל",
    "אלאנדלוס",
    "אלאנואר",
    "אלביאדר",
    "אלבלאט",
    "אלבלוט",
    "אלבסאתין",
    "אלג'זאלי",
    "אלהודא",
    "אלואדי",
    "אלווקף",
    "אלזהראא",
    "אלזיתון",
    "אלזקאק",
    "אלח'רוב",
    "אלחאקורה",
    "אלחדיקה",
    "אלטבראני",
    "אלכרם",
    "אלמותנבי",
    "אלמחבה",
    "אלמטל",
    "אלמלק",
    "אלמנאשר",
    "אלמנזול",
    "אלמסאקב",
    "אלמעסרה",
    "אלמקיל",
    "אלמתנזה",
    "אלנרג'ס",
    "אלסוק",
    "אלסנדיאן",
    "אלספא",
    "אלסריס",
    "אלעודה",
    "אלעולא",
    "אלעסיליאת",
    "אלפאראבי",
    "אלפארוק",
    "אלפרדוס",
    "אלפרח",
    "אלרביע",
    "אלרוודה",
    "אלרום",
    "אלריחאן",
    "אלרמאנה",
    "אלרשיד",
    "אלשאפעי",
    "אלשיח' פרג'",
    "אלתותה",
    "אסלאם",
    "בירות",
    "דואר אלפלק",
    "דרב אלברג'",
    "דרב אלכניסה",
    "דרב אלמסרב",
    "דרב אלנאסרה",
    "דרב אלסראר",
    "דרב עכא",
    "טורעאן",
    "מחמוד דרויש",
    "סלאח אלדין",
    "עוטרא",
    "שכ אלדלהם",
    "שכ אלחמראא'",
    "שכ אלטבראני",
    "שכ אלכרום אלג'רביה",
    "שכ אלמליק",
    "שכ אלמנאשר",
    "שכ אלמנזלה",
    "שכ אלסראר",
    "שכ אלעיסיליאת",
    "שכ אלעמארה",
    "שכ אלרמאנה",
    "שכ אלתותי",
    "שכ בית אלרסיף",
    "שכ ד'הר אלשעירה",
    "שכ ואדי אלעין",
    "שכ ווסט אלבלד",
    "שכ קבר אלעבד",
  ],
  טייבה: [
    "אבו אל עקארב",
    "אזור הקאנטרי",
    "אזור תעשייה",
    "אלבאטן",
    "אלח'לה אלשמאליה",
    "אלסלילמה",
    "אלסמכה",
    "אלקינה-שכונה מזרחית",
    "אלראס",
    "אלשל",
    "אתר אשפה מזרחי",
    "ג'למה",
    "גרעין-העיר העתיקה",
    "ואד אלאסד",
    "ואד חמדאן",
    "טייבה",
    "כרם אלג'באלי המזרחי",
    "כרם אלג'באלי המערבי",
    "פרדיסיה",
    "רוק",
    "שכונת הבדואים",
  ],
  "טייבה (בעמק)": ["טייבה (בעמק)"],
  טירה: [
    "א- זהרא",
    "א-נדא",
    "א-נור",
    "א-סלאם",
    "א-ראזי",
    "א-רביע",
    "א-שהיד מחמוד א-שארה",
    "א-שוהדא",
    "א-תופאח",
    "אבו בכר",
    "אבו סלאח",
    "אבו עובידה",
    "אבן בטוטה",
    "אבן חאן",
    "אבן חלדון",
    "אבן רושד",
    "אל -מערי",
    "אל חפאיר",
    "אל-אוסראא'",
    "אל-אעמאריה",
    "אל-ג'ומעה",
    "אל-חמיס",
    "אל-מוראבטון",
    "אל-משרוע",
    "אל-קודס",
    "אלמשכוע",
    "באב אל-חאן",
    "בילאל",
    "ג'ורת קשוע",
    "דאוד ראזק",
    "דאמו",
    "דרך א-סולטאני",
    "דרך אל-בסה",
    "דרך אל-עאבה",
    "דרך יפו",
    "העיר העתיקה",
    "חור אבו דקר",
    "חור אלנדא",
    "חור לס",
    "חמזה",
    "טארק עבד אל-חי",
    "טירה",
    "יום אל ארד",
    "כרם אלזיתון",
    "מקורות",
    "מרכז העיר",
    "עומר בן אל-חטאב",
    "עלי",
    "עקפה",
    "קלקיליה",
    "שכונה מזרחית",
    "שרחביל",
  ],
  "טירת יהודה": ["טירת יהודה"],
  "טירת כרמל": [
    "אזור התעשיה",
    "איילת הכרמל",
    "אלבז נתן",
    "אליאס כרמל",
    "אלמוג",
    'אצ"ל',
    "אריאל שרון",
    "אתגר",
    "ביאליק",
    "בן צבי",
    "גורדון",
    "גיורא",
    "דולפין",
    "דקר",
    "דרך נחל גלים",
    "האלה",
    "האלון",
    "הארז",
    "הברוש",
    "הדקל",
    "ההגנה",
    "הורדים",
    "הזית",
    "החרוב",
    "היצירה",
    "הכלניות",
    "המלאכה",
    "המעפילים",
    "המרכז לבריאות הנפש",
    "הנרקיסים",
    "הסדנא",
    "העצמאות",
    "הפטיש",
    'הפלמ"ח',
    "הצנחנים",
    "הרב ברזני סולימן",
    "הרב וינרוב",
    "הרב זרביב",
    "הרב מרדכי משה",
    "הרב קוק",
    "הרדוף",
    "הרימון",
    "הרצל",
    "השחרור",
    "השריון",
    "ויצמן",
    "ז'בוטינסקי",
    "חצב",
    "חרצית",
    "חת נחום",
    "טירת כרמל",
    'י"א הספורטאים',
    "יוזמה",
    "יצחק שמיר",
    "ירושלים",
    'כ"ג יורדי הסירה',
    "ככר דקר",
    "ככר השריון",
    'ככר י"א הספורטאים',
    "ככר ירושלים",
    "כפר טירה",
    "כרמלים",
    "לוי אשכול",
    'לח"י',
    "לניאדו עזרא",
    "מוצקין",
    "מלחמת ששת הימים",
    "מע דרומית",
    "מע צפונית א",
    "מע צפונית ג",
    "מעלה נוף",
    "מפרש",
    "נאות כרמל",
    "נוה אליאס",
    "נווה גלים",
    "נורי דוד",
    "נורית",
    "סחלב",
    "סיגלית",
    "סיני",
    "סמ ההגנה 2",
    "סמ המעפיל 10",
    "סמ המעפיל 1",
    "סמ המעפיל 4",
    "סמ המעפיל 7",
    "סמ המעפיל 8",
    "סמ המעפיל 9",
    "סמ הפלמח 1",
    "סמ הפלמח 5",
    "סמ הפלמח 6",
    "סמ הפלמח 8",
    "סמ הרדוף",
    "סמ כרמל",
    "סמ עליה ב 2",
    "סמ שיטרית",
    'עגנון ש"י',
    "עוגן",
    "עוצמה",
    "עליה ב",
    "צדף",
    "קדמה",
    "קרן היסוד",
    "רזיאל",
    'רמב"ם',
    "רמת בגין",
    "ש אגש",
    "ש אסבסטונים",
    "ש בן צבי",
    "ש גיורא",
    "ש ויצמן",
    "ש כהן",
    "ש ממשלתי",
    "ש עמידר",
    "ש שיטרית",
    "ש שרת",
    "שונית",
    "שכ ביאליק",
    "שכ ברנר",
    "שכ גלי כרמל",
    "שכ הפרחים",
    "שכ כלניות",
    "שכ מעלה נוף",
    'שכ רמב"ם',
    'שכ שז"ר',
    "שכונת אשכול",
    "שרת משה",
    "תורן",
    "תנופה",
  ],
  "טירת צבי": ["אולפן קיבוץ", "טירת צבי"],
  "טל שחר": [
    "הכנרת",
    "טל שחר",
    "נהר הירדן",
    "נחל איילון",
    "נחל דן",
    "נחל הבשור",
    "נחל קדרון",
    "נחל שורק",
  ],
  "טל-אל": [
    "האורן",
    "האילן",
    "הברוש",
    "הגפן",
    "הדס",
    "הדקל",
    "הזית",
    "החצב",
    "החרוב",
    "הרימון",
    "התאנה",
    "טל-אל",
    "כליל החורש",
    "מורן",
  ],
  טללים: ["טללים", "מרכז קליטה"],
  טלמון: [
    "אורן",
    "אלה",
    "אלון",
    "אריאל",
    "ארץ ימיני",
    "ארץ צוף",
    "ארץ שלישה",
    "ארץ שעלים",
    "אשכול",
    "אשת לפידות",
    "ברוש",
    "ברכת הבנים",
    "ברכת שמים",
    "גפן",
    "דולב",
    "דרך הרי בשמים",
    "דרך שילה",
    "האופניים",
    "הגולן",
    "הגליל",
    "הגלעד",
    "היער",
    "הירדן",
    "הערבה",
    "הר אפרים",
    "הר המור",
    "הר כרכום",
    "הראל",
    "הרואה",
    "הרימון",
    "התאנה",
    "התבור",
    "התמר",
    "זית",
    "חצב",
    "טלמון",
    "יהושע בן נון",
    "ירושלים",
    "לבנה",
    "לבנון",
    "לוז",
    "לוטם",
    "מוריה",
    "מים חיים",
    "מרוה",
    "משעול הכרמים",
    "משעול צרור המור",
    "נווה",
    "נחל פרת",
    "נחל צין",
    "נחל שניר",
    "נחלי געש",
    "נריה",
    "סיני",
    "עירית",
    "ערמון",
    "פרי מגדים",
    "קידה",
    "רמתיים צופים",
    "שאול המלך",
    "שיר השירים",
    "שכונת חרשה",
    "שכונת טלמון א'",
    "שכונת כרם רעים",
    "שכונת נוה טלמון",
    "שקד",
    "תומר דבורה",
    "תמנה",
  ],
  טמרה: [
    "אבו רומאן",
    "אלביק",
    "אלג'בל",
    "אלהרובה",
    "אלורוד",
    "אלחג'ארה",
    "אלחמרה",
    "אלמסלוקה",
    "אלפאראבי",
    "באב אלנסב",
    "דריג'את",
    "המסגד הישן",
    "השכונה העליונה",
    "ואדי אלעין",
    "ואדי המאם",
    "זיאדנה",
    "ח'לאיל אלנוואר",
    "ח'לת אלסבעה",
    "ח'לת אלע'זאל",
    "ח'לת שריף",
    "טמרה",
    "מטיראן",
    "מרכז העיר",
    "עירייה",
    "שכונה צפונית",
  ],
  "טמרה (יזרעאל)": ["טמרה (יזרעאל)"],
  טנא: [
    "אזוב",
    "הגבעה",
    "הנחל",
    "הפסגה",
    "חצב",
    "טנא",
    "מרווה",
    "עומרים",
    "עירית",
    "קורנית",
    "רכסים",
  ],
  טפחות: ["טפחות"],
  "יאנוח-ג'ת": ["ג'ת (בגליל)", "יאנוח", "יאנוח-ג'ת"],
  יבול: ["יבול"],
  יבנאל: [
    "בית אבות יוקרה",
    "בית גן",
    "בן גוריון",
    "ההסתדרות",
    "המיסדים",
    "הנדיב",
    "יבנאל",
    "מעברת יבנאל",
    "משמר השלשה",
    "נפתלי",
    "ש הגורן",
    "ש סמדר",
    "ש עולים",
    "ש עמידר",
    "שכ התימנים",
    "שכ ישראל שרפמן",
  ],
  יבנה: [
    "אבוקדו",
    "אביבית",
    "אגוז",
    "אגמון",
    "אגס",
    "אהובה עוזרי",
    "אודם",
    "אזוב",
    "אזור המלאכה",
    "אזור התעשיה",
    "אטד",
    "אירוס",
    "אירוס הארגמן",
    "אלה",
    "אלעזר דוד",
    "אפרסמון",
    'אצ"ל',
    "ארז",
    "אשכולית",
    "אתרוג",
    "ברקן",
    "ברקת",
    "גבורי ישראל",
    "גבעה",
    "גומא",
    "גזית",
    "גיבורי החיל",
    "גלית",
    "גרנית",
    "דבורה עומר",
    "דוכיפת",
    "דולב",
    "דולפין",
    "דותן",
    "דליה רביקוביץ'",
    "דם המכבים",
    "דן",
    "דפנה",
    "דרך חג'ג' אהרון",
    "האומן",
    "האורגים",
    "האורן",
    "האיריס",
    "האלון",
    "האלמוג",
    "האמוראים",
    "האקליפטוס",
    "הארבל",
    "האשל",
    "הברוש",
    "הבשן",
    "הגולן",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הגפן",
    "הגרי זכריה",
    "הדוגית",
    "הדייגים",
    "הדס",
    "הדקל",
    "הדרור",
    "הזית",
    "הזמיר",
    "החבצלת",
    "החרש",
    "היסמין",
    "הירדן",
    "הירמוך",
    "הירקון",
    "הכלנית",
    "הכרמל",
    "הלילך",
    "המיסב",
    "המלחים",
    "המסילה",
    "המפרש",
    "המשוט",
    "הנביאים",
    "הנגב",
    "הנחל",
    "הנחשול",
    "הנמל",
    "הנרקיס",
    "הנשיאים",
    "הנשר",
    "הסביון",
    "הסופרים",
    "הסיפון",
    "הסירה",
    "העבודה",
    "העגור",
    "העוגן",
    "העמל",
    "העצמאות",
    "הערבה",
    "הפרת",
    "הצבעוני",
    "הצדף",
    "הקישון",
    "הר הזיתים",
    "הר מירון",
    "הר נבו",
    "הר סיני",
    "הראשונים",
    "הרב אבוחצירא",
    "הרב בוכריס",
    "הרדוף",
    "הרצל",
    "הרשת",
    "השומרון",
    "השונית",
    "השופטים",
    "השושנים",
    "השחף",
    "השייטים",
    "השפלה",
    "השקד",
    "השקמה",
    "השרון",
    "התאנה",
    "התבור",
    "התורן",
    "התנאים",
    "ז'בוטינסקי",
    "זהבית",
    "חבלבל",
    "חבצלת החוף",
    "חטיבת גבעתי",
    "חידקל",
    "חירות",
    "חלמונית",
    "חמנית",
    "חצב",
    "חרוב",
    "חרמון",
    "חרצית",
    "יבנה",
    "יהלום",
    "יערה",
    "יקינטון",
    "כוכבית",
    "כחלית",
    "לויתן",
    "לוע הארי",
    "לימון",
    "לשם",
    "מבצע דני",
    "מבצע חירם",
    "מבצע יואב",
    "מבצע יונתן",
    "מבצע עובדה",
    "מבצע קדש",
    'מבצע תש"ח',
    "מוריה",
    "מורן",
    "מלכי יהודה",
    "מלכי ישראל",
    "מרגנית",
    "מרכז מסחרי",
    "משעול בוהיניה",
    "משעול האדר",
    "משעול האלמוגן",
    "משעול האפרסק",
    "משעול הגויאבה",
    "משעול ההדרים",
    "משעול החבוש",
    "משעול הכרמים",
    "משעול הלבנה",
    "משעול המנגו",
    "משעול הפיקוס",
    "משעול הפקאן",
    "משעול הצבר",
    "משעול הצפצפה",
    "משעול הקוקוס",
    "משעול התדהר",
    "משעול התות",
    "משעול התפוז",
    "משעול התפוח",
    "משעול כליל החורש",
    "משעול סהרון",
    "משעול סיגלון",
    "משעול קטלב",
    "משעול תאשור",
    "נאות אשכול",
    "נאות בגין",
    "נאות בן גוריון",
    "נאות רבין",
    "נאות שזר",
    "נהרונית",
    "נופר",
    "נורית",
    "נזמית",
    "נחל חריף",
    "נחל ערוגות",
    "נחל פולג",
    "נחל שניר",
    "נחליאלי",
    "ניצנית",
    "נעמי שמר",
    "נתיב הספורט",
    "נתן אלתרמן",
    "סחלב",
    "סיגל",
    "סייפן",
    "סנפיר",
    "ספיר",
    "סתוונית",
    "עדעד",
    "עוזי חיטמן",
    "עולי הגרדום",
    "עולש",
    "עירית",
    "ענבל",
    "ערמון",
    "ערער",
    "פארן",
    "פטל",
    "פעמונית",
    "פרג",
    "צאלון",
    "צורית",
    "צלף",
    "צפורן",
    "קדושי קהיר",
    "קורנית",
    "קינמון",
    "רבי יהודה הלוי",
    "רוזמרין",
    "רותם",
    "רח ד",
    "רח ה",
    "רח יב",
    "רח יד",
    "רח יז",
    "רחל המשוררת",
    "ריחן",
    "רימון",
    "רמות בן צבי",
    "רמות ויצמן",
    "רקפת",
    "ש הפועלים",
    "ש חולות",
    "ש טרומיים",
    "ש יבנית",
    "ש נוה עובד",
    'ש"י עגנון',
    "שבזי",
    "שד גן רווה",
    "שד דואני",
    "שד הסנהדרין",
    "שד הציונות",
    "שד ירושלים",
    "שוהם",
    "שזיף",
    "שידלובסקי",
    "שיטה",
    "שכ הארבע מאות",
    "שכ נוה אילן",
    "שכ צהל",
    "שכ רכבת",
    "שנף",
    "תורמוס",
    "תלתן",
    "תמר",
    "תנועות הנוער",
  ],
  יגור: [
    "אולפן קיבוץ",
    "בית ראשון במולדת",
    "דרך אחווה",
    "דרך הבאר",
    "דרך הטווס",
    "דרך הכרכום",
    "דרך הכרם",
    "דרך הכרמל",
    "דרך הלולים",
    "דרך הלימון",
    "דרך הפרדס",
    "דרך הקישון",
    "דרך הראשונים",
    "דרך השדות",
    "דרך התעשייה",
    "דרך חדר האוכל",
    "דרך יד למגינים",
    "דרך עמק זבולון",
    "דרך שומר המסילה",
    "יגור",
    "כביש ההר הירוק",
    "כביש השלום",
    "כביש כפר חסידים",
    "מעלה הוואדי",
    "מעלה המצוקים",
    "סמ' ההר הקירח",
    "סמ' השקדיה",
    "שביל האגוז",
    "שביל האיזדרכת",
    "שביל האנפה",
    "שביל האקליפטוס",
    "שביל הברושים",
    "שביל הגבעות",
    "שביל הדוכיפת",
    "שביל החיטה",
    "שביל החלב",
    "שביל הכלנית",
    "שביל הכתר",
    "שביל הנחליאלי",
    "שביל הסילו",
    "שביל הסכר",
    "שביל הסליק",
    "שביל הסנדלריה",
    "שביל הסתוונית",
    "שביל העפרוני",
    "שביל הפיקוסים",
    "שביל הצאלון",
    "שביל השיקמה",
    "שביל השלדג",
    "שביל התאנה",
    "שביל התלתן",
    "שביל מגדל המים",
    "שכ' גבעת המכוורת",
    "שכונת בלוקונים",
    "שכונת האיזדרכת",
    "שכונת ההר",
    "שכונת הכביש",
    "שכונת הכרם",
    "שכונת הכרמל",
    "שכונת הלול",
    "שכונת המשתלה",
    'שכונת הנה"ח',
    "שכונת הסילו",
  ],
  יגל: ["הגפן", "הדקל", "הלימון", "יגל", "ככר חוני"],
  "יד בנימין": [
    "אנפה",
    "אפיקים",
    "ארבל",
    "גדרון",
    "דיה",
    "דרור",
    "דרך האבות",
    "דרך מיכה",
    "האשל",
    "הגפן",
    "הושע",
    "הנחלים",
    "הראל",
    "הרימון",
    "השחף",
    "השלו",
    "התאנה",
    "ורדית",
    "זמיר",
    "חוחית",
    "יד בנימין",
    "יסעור",
    "יעלה",
    "כחל",
    "כרמל",
    "מגלן",
    "מוריה",
    "מירון",
    "מעלה עמוס",
    "מצדה",
    "נחליאלי",
    "נשר",
    "סמ גלבוע",
    "סמ חגי",
    "סמ יואל",
    "סמ יונה",
    "סמ עמשא",
    "סמ עצמון",
    "סמ שקד",
    "סנונית",
    "סער",
    "עגור",
    "צופית",
    "שכ נירים",
    "שכ נתיבה",
    "שכ עידית",
    "שכ שדות",
    "שכ שקמים",
    "שכ תמנה",
    "שכ תמר",
    "שניר",
    "תבור",
  ],
  "יד השמונה": ["יד השמונה"],
  "יד חנה": ["יד חנה"],
  "יד מרדכי": ["יד מרדכי"],
  "יד נתן": [
    "הבנים",
    "הוורד",
    "המייסדים",
    "הפרדס",
    "יד נתן",
    "משעול הגורן",
    "שדרת האקליפטוסים",
  ],
  'יד רמב"ם': ['יד רמב"ם'],
  ידידה: ["ידידה"],
  "יהוד-מונוסון": [
    "אבן גבירול",
    "אברבנאל",
    "אגוז נוה אפרים",
    "אור יצחק ישיבה",
    "אזור תעשייה",
    "אלטלף אברהם",
    "אלי כהן",
    "אלעזר דוד",
    "אלפרט",
    "אנילביץ",
    "ארבעת המינים",
    "אריה בן אליעזר",
    "ארלוזורוב",
    "אשכול",
    "אשכנזי",
    "ביאליק",
    "ביאקובסקי",
    'ביל"ו',
    "בן גוריון דוד",
    "בן צבי",
    "בר סימנטוב יעקב",
    "ברנר",
    "ברקת נוה אפרים",
    "גולדה מאיר",
    "גורדון",
    "גירון אברהם",
    'ד"ר שישקש חיים',
    "דרך דיין משה",
    "דרך החורש",
    "דרך העצמאות",
    "האגוז",
    "האלמוג נוה אפרים",
    'האצ"ל',
    "האתרוג נוה אפרים",
    "הגדוד העברי",
    "הגפן נוה אפרים",
    "הדגנים",
    "הדר נוה אפרים",
    "ההגנה",
    "הורדים",
    "הזית",
    "החרוב",
    "החרושת",
    "היובל",
    "המייסדים",
    "המלאכה",
    "המעפילים",
    "המר זבולון",
    "הנרקיס",
    "הפנינים נוה אפרים",
    "הקונגרס הציוני",
    "הרב חיים סינואני",
    "הרואה",
    "הרימון נוה אפרים",
    "הרצוג חיים",
    "הרצל",
    "השלום",
    "השלושה",
    "התעשייה",
    "וינהויז זאב",
    "ויצמן",
    "ז'בוטינסקי",
    "חזנוביץ",
    "חיטה",
    "חסיד משה",
    "חתוכה סעדיה",
    "טורצ'ין ניסן",
    "טלבי יוסף",
    "טננבוים",
    "טרומפלדור",
    "יהוד-מונוסון",
    "יהודה הלוי",
    "יהלום נוה אפרים",
    "יונה",
    "יוספטל גיורא",
    "יצחק שדה",
    "כהן רם",
    "ככר יצחק שדה",
    "כצנלסון",
    "לוחמי הגיטאות",
    "מבוא אלה",
    "מבוא אתרוג",
    "מבוא ברוניקה",
    "מבוא ברוש",
    "מבוא גומא",
    "מבוא דולב",
    "מבוא דליה",
    "מבוא האירוס",
    "מבוא הדגנים",
    "מבוא הדס",
    "מבוא הרדוף",
    "מבוא ורבנה",
    "מבוא זהבית",
    "מבוא חבצלת",
    "מבוא טופח",
    "מבוא יסמין",
    "מבוא לולב",
    "מבוא שיבולת",
    "מוהליבר",
    "מוצקין",
    "מלון אויה",
    "מלמד",
    "מקלב אורי",
    "מרבד הקסמים",
    "מרכוס דוד",
    "נבון",
    "נווה מונוסון",
    "נורדאו",
    'ניל"י',
    "נתניהו יונתן",
    "סוקניק",
    "סירקין",
    "סמ אגוז נוה אפרים",
    "סמ הפנינים נוהאפרים",
    "סמ ויצמן",
    "סנש",
    "ספיר נוה אפרים",
    "עינבר נוה אפרים",
    "עץ האפרסק",
    "ערבה",
    "פרחי הבר",
    "צבי ישי",
    'צה"ל',
    "צוקרמן צביה ויצחק",
    "צנובר נוה אפרים",
    "קדושי מצרים",
    "קפלן",
    "קפלנסקי",
    "קרית ביאליסטוק",
    "קרית הסביונים",
    'רמב"ם',
    "רמז",
    "ש בית שמואל",
    "ש נוה עובד",
    "ש רסקו",
    "שבזי",
    "שד בגין",
    "שוהם נוה אפרים",
    'שז"ר',
    "שיבת ציון",
    "שיטרית בכור",
    "שיפון",
    "שנהב נוה אפרים",
    "שעורה",
    "שפירא משה חיים",
    "שצ'ופק מרים",
    "שקד נוה אפרים",
    "ששת הימים",
    "תאנה",
    "תאשור",
    "תדהר",
    "תמר נוה אפרים",
    "תרשיש נוה אפרים",
  ],
  יהל: ["בית ראשון במולדת", "יהל"],
  יובל: ["יובל"],
  יובלים: ["יובלים"],
  יודפת: ["יודפת"],
  יונתן: ["יונתן"],
  יושיביה: ["יושיביה"],
  יזרעאל: ["יזרעאל"],
  יחיעם: ["יחיעם"],
  יטבתה: ["חי בר", "יטבתה"],
  'ייט"ב': ['ייט"ב'],
  יכיני: ["יכיני"],
  ינוב: [
    "דרך החיטה",
    "דרך השעורה",
    "האורן",
    "הארז",
    "הדקל",
    "הורדים",
    "הלוטם",
    "הצאלון",
    "השיטה",
    "ינוב",
    "מבוא הגפן",
    "מורד התאנה",
    "מעלה הרימון",
    "נתיב הזית",
    "שד התמר",
  ],
  ינון: ["ינון"],
  "יסוד המעלה": [
    "דרך השמורה",
    "האגם",
    "האילנות",
    "האלונים",
    "האשכולית",
    "הבוסתן",
    "הגפן",
    "הורדים",
    "הזית",
    "החולה",
    "החלוצים",
    "הירדן",
    "המגדל",
    "המזח",
    "המייסדים",
    "הפרדסים",
    "הרימונים",
    "השזיף",
    "השקד",
    "התאנה",
    "התמר",
    "התעשיה",
    "יסוד המעלה",
    "ש האגם",
  ],
  יסודות: ["יסודות"],
  יסעור: ["יסעור"],
  יעד: [
    "אלת המסטיק",
    "המייסדים",
    "יעד",
    "מלא כרימון",
    "נוף יבור",
    "נחל חילזון",
    "קו הרכס",
    "ראש זית",
    "רוח ים",
    "רוח מזרחית",
    "שירת הנוער",
    "תריסר ברושים",
  ],
  יעל: ["יעל"],
  יעף: ["דרור", "ינשוף", "יעף", "נחליאלי", "עגור", "עפרוני", "שחף"],
  יערה: ["יערה"],
  יפיע: [
    "אלאלמאני",
    "אלאמל",
    "אלביאדר",
    "אלבלד",
    "אלג'בל",
    "אלחבאא'ל",
    "אללקלום",
    "אלמדארס",
    "אלסירה",
    "אלעין",
    "אלעמאא'ר",
    "אלשמאלי",
    "אם אלעד'אם",
    "ואדי אלח'ארג'י",
    "יפיע",
    "מאר יעקוב",
    "מנזל אלנור",
    "מראח אלע'זלאן",
    "ראס אסמאעיל",
    "רח 300",
    "רח 110",
    "רח 210",
    "רח 310",
    "רח 410",
    "רח 510",
    "רח 610",
    "רח 710",
    "רח 120",
    "רח 220",
    "רח 320",
    "רח 420",
    "רח 520",
    "רח 620",
    "רח 130",
    "רח 230",
    "רח 330",
    "רח 240",
    "רח 101",
    "רח 201",
    "רח 301",
    "רח 401",
    "רח 501",
    "רח 601",
    "רח 701",
    "רח 111",
    "רח 211",
    "רח 311",
    "רח 411",
    "רח 511",
    "רח 611",
    "רח 711",
    "רח 121",
    "רח 221",
    "רח 321",
    "רח 421",
    "רח 521",
    "רח 621",
    "רח 131",
    "רח 231",
    "רח 331",
    "רח 102",
    "רח 202",
    "רח 302",
    "רח 402",
    "רח 502",
    "רח 602",
    "רח 702",
    "רח 112",
    "רח 212",
    "רח 312",
    "רח 412",
    "רח 512",
    "רח 612",
    "רח 712",
    "רח 122",
    "רח 222",
    "רח 322",
    "רח 422",
    "רח 522",
    "רח 622",
    "רח 132",
    "רח 232",
    "רח 332",
    "רח 103",
    "רח 203",
    "רח 303",
    "רח 403",
    "רח 503",
    "רח 603",
    "רח 703",
    "רח 113",
    "רח 213",
    "רח 313",
    "רח 413",
    "רח 513",
    "רח 613",
    "רח 713",
    "רח 123",
    "רח 223",
    "רח 323",
    "רח 423",
    "רח 523",
    "רח 623",
    "רח 133",
    "רח 233",
    "רח 333",
    "רח 104",
    "רח 204",
    "רח 304",
    "רח 404",
    "רח 504",
    "רח 604",
    "רח 704",
    "רח 114",
    "רח 214",
    "רח 314",
    "רח 414",
    "רח 514",
    "רח 614",
    "רח 124",
    "רח 224",
    "רח 324",
    "רח 424",
    "רח 524",
    "רח 624",
    "רח 234",
    "רח 334",
    "רח 105",
    "רח 205",
    "רח 305",
    "רח 405",
    "רח 505",
    "רח 605",
    "רח 705",
    "רח 115",
    "רח 215",
    "רח 315",
    "רח 515",
    "רח 615",
    "רח 125",
    "רח 225",
    "רח 325",
    "רח 425",
    "רח 625",
    "רח 235",
    "רח 335",
    "רח 106",
    "רח 206",
    "רח 306",
    "רח 406",
    "רח 506",
    "רח 606",
    "רח 706",
    "רח 116",
    "רח 216",
    "רח 316",
    "רח 416",
    "רח 516",
    "רח 616",
    "רח 126",
    "רח 226",
    "רח 326",
    "רח 426",
    "רח 626",
    "רח 236",
    "רח 336",
    "רח 107",
    "רח 207",
    "רח 307",
    "רח 507",
    "רח 607",
    "רח 707",
    "רח 117",
    "רח 217",
    "רח 317",
    "רח 417",
    "רח 517",
    "רח 617",
    "רח 127",
    "רח 227",
    "רח 327",
    "רח 427",
    "רח 627",
    "רח 237",
    "רח 108",
    "רח 208",
    "רח 308",
    "רח 408",
    "רח 508",
    "רח 608",
    "רח 708",
    "רח 118",
    "רח 218",
    "רח 318",
    "רח 418",
    "רח 518",
    "רח 618",
    "רח 128",
    "רח 228",
    "רח 328",
    "רח 428",
    "רח 628",
    "רח 238",
    "רח 109",
    "רח 209",
    "רח 309",
    "רח 409",
    "רח 509",
    "רח 609",
    "רח 709",
    "רח 119",
    "רח 319",
    "רח 419",
    "רח 519",
    "רח 619",
    "רח 129",
    "רח 229",
    "רח 329",
    "רח 429",
    "רח 239",
  ],
  יפית: ["יפית"],
  יפעת: [
    "בית ראשון במולדת",
    "דרך החצר",
    "דרך המשק",
    "דרך ראשית ההתיישבות",
    "הגיתות",
    "הגנים",
    "הכרם",
    "הסלע",
    "העמק",
    "הפרדס",
    "הצריפים",
    "הראשונים",
    "השדה",
    "השרון-גבת",
    "יפעת",
    "מעלה הגבעה",
    "שביל החלב",
    "שלושת הברושים",
  ],
  יפתח: ["בית ראשון במולדת", "יפתח"],
  יצהר: ["יצהר"],
  יציץ: [
    "האלון",
    "הגפן",
    "הזית",
    "הרימון",
    "התאנה",
    "התמר",
    "יציץ",
    "סמ הדקל",
    "סמ התפוז",
  ],
  יקום: ["יקום"],
  יקיר: [
    "דרך הצבי",
    "הגיתית",
    "הגפן",
    "הדרור",
    "הזית",
    "החליל",
    "הכינור",
    "הנבל",
    "הפעמון",
    "התאנה",
    "התמר",
    "יקיר",
    "מבוא חמה",
    "מעלה השחר",
    "משעול החורש",
    "משעול התקוה",
    "נהר הירדן",
    "נחל דולב",
    "נחל דותן",
    "נחל הבשור",
    "נחל עוז",
    "נחל צין",
    "נחל שילה",
    "נחל תרצה",
    "שד ראשונים",
  ],
  "יקנעם (מושבה)": [
    "אגרא",
    "גבעת המגדל",
    "דרך העמק",
    "הגורן",
    "הגיא",
    "הגנים",
    "יפה נוף",
    "יקנעם (מושבה)",
    "כליל החורש",
    "מעלה הבנים",
    "מעלה הגבעה",
    "מעלה הרקפות",
    "נוף יזרעאל",
    "נחל קרת",
    "נתיב הנוטרים",
    "שביל החלב",
    "שד הראשונים",
  ],
  "יקנעם עילית": [
    "אביטל",
    "אורטל",
    "אזור המלאכה",
    "אכזיב",
    "אלון",
    "אמנון ותמר",
    "אפון",
    "אפק",
    "ארבל",
    "בנטל",
    "גבעת האלונים",
    "גבעת יערה",
    "גבעת כלניות",
    "גולן",
    "גונן",
    "גלבוע",
    "גני תעשיה",
    "דליות",
    "דן",
    "דקל",
    "דרך הארגמן",
    "דרך היער",
    "דרך העמק",
    "האגם",
    "האורן",
    "האורנים",
    "האילה",
    "האילנות",
    "האיריסים",
    "האלה",
    "האלונים",
    "האקליפטוס",
    "הארזים",
    "האשל",
    "הבוסתן",
    "הבניאס",
    "הברושים",
    "הבשן",
    "הגבורים",
    "הגיא",
    "הגלעד",
    "הגפן",
    "הדודאים",
    "הדס",
    "ההרדופים",
    "הורדים",
    "הזית",
    "החורש",
    "החצב",
    "החצבני",
    "החרמון",
    "היזמה",
    "היסמין",
    "היצירה",
    "היקינטון",
    "הכלניות",
    "הכרמל",
    "המדע",
    "המיצר",
    "המלאכה",
    "המפל",
    "המצוק",
    "הנוריות",
    "הנרקיסים",
    "הסחלבים",
    "העוצמה",
    "הערבה",
    "העתיד",
    "הפטל",
    "הצופים",
    "הצמיחה",
    "הצפורנים",
    "הקידמה",
    "הרימון",
    "הרקפות",
    "השיטה",
    "השקד",
    "השקמה",
    "התאנה",
    "התבור",
    "התמר",
    "התנופה",
    "התעשיה",
    "ורד העמקים",
    "חורשת טל",
    "חרצית",
    "יודפת",
    "יער אודם",
    "יפה נוף",
    "יקנעם עילית",
    "ירדן",
    "ירמוך",
    "כליל החורש",
    "ליטאני",
    "לילך",
    "מורן",
    "מירון",
    "מעיין",
    "מעלה הבנים",
    "מרגנית",
    "נופר",
    "נחל הבשור",
    "נחל השניים",
    "נחל ערוגות",
    "נחל שורק",
    "סביון",
    "סיגלית",
    "סיני",
    "עמק השלום",
    'פארק תעשיה ית"מ',
    "פעמונית",
    "צאלים",
    "קרית חינוך",
    "רותם",
    "רמת יקנעם",
    "ש וילות",
    "ש חדש מערבי",
    "ש נוף העמק",
    "ש עמידר",
    "ש קוטג'",
    "שד יצחק רבין",
    "שד נשיאי ישראל",
    "שדרות היובל",
    "שומרון",
    "שיאון",
    "שכ גבעת השיטה",
    "שכ המושבה החדשה",
    "שכ מרכז",
    "שכ שער הגיא",
    "שכון א דרום",
    "שכון א צפון",
    "שניר",
  ],
  יראון: ["בית ראשון במולדת", "יראון"],
  ירדנה: ["ירדנה"],
  ירוחם: [
    "אבו חצירה",
    "אבן עזרא",
    "אבן-שפרוט",
    "אברהם",
    "אגמית",
    "אהוד מנור",
    "אזור תעשייה זעירה",
    "אזור תעשייה מזרח",
    "אזור תעשייה מערב",
    "אל-חריזי",
    "אליהו הנביא",
    "אלכחייל",
    "אמא שלום",
    "אנפה",
    "אסימוב",
    "אשר",
    "בגין",
    "בורנשטיין צבי",
    "בית אשל",
    "בן לברט",
    "בן-סרוק",
    "בן-עטר",
    "בנה ביתך",
    "בנימין",
    "בר כוכבא",
    "בר-יוחאי",
    "ברוריה",
    "גבולות",
    "גד",
    "גולדברג לאה",
    "גולדה מאיר",
    "גולן",
    "גוש עציון",
    "גיבתון",
    "דוכיפת",
    "דונה גרציה",
    "דינה",
    "דן",
    "האופק",
    'הביל"ויים',
    "הברק",
    "הדקל",
    "ההגנה",
    "ההדסים",
    "ההדרים",
    "ההסתדרות",
    "הי הכהן",
    "הלבנה",
    "המעפילים",
    "המרום",
    "המשוררת",
    "הנביאים",
    "הנרייטה סאלד",
    "הסהר",
    'הפלמ"ח',
    "הפסגה",
    "הקשת",
    "הרב עוזיאל",
    "הרב קוק",
    'הרמב"ם',
    "הרצל",
    "השחר",
    "התמר",
    "ויקי שירן",
    "זבולון",
    "זוהרה אלפסייה",
    "זלדה",
    "חבקוק הנביא",
    "חובבי ציון",
    "חטיבת הנגב",
    'חיד"א',
    "חמה",
    "חשמונאים",
    "יאנוש קורצ'אק",
    "יהודה",
    "יהודה עמיחי",
    "יוסף",
    "יסמין",
    "יעקב",
    "יצחק",
    "יצחק בשביס זינגר",
    "יצחק שדה",
    "ירוחם",
    "יששכר",
    "לאה",
    "לוי",
    "מאדים",
    "מבוא נחל מטמון",
    "מנדל",
    "מעגל האירוס",
    "מעגל האתרוג",
    "מעגל הגפן",
    "מעגל הזוגן",
    "מעגל החלמונית",
    "מעגל החצב",
    "מעגל היפרוק",
    "מעגל הכלנית",
    "מעגל הנורית",
    "מעגל הסתוונית",
    "מעגל העירית",
    "מעגל הערבה",
    "מעגל הפרג",
    "מעגל הצבעוני",
    "מעגל הרותם",
    "מעגל הרימון",
    "נאות הדר",
    "נגה",
    "נווה נוף",
    "נווה עמק",
    "נוף מדבר",
    "נחל אבנון",
    "נחל דוד",
    "נחל הבשור",
    "נחל חתירה",
    "נחל יעלים",
    "נחל עין בוקק",
    "נחל ערוגות",
    "נחל צאלים",
    "נחל צין",
    "נחל שועלים",
    "נלי זקס",
    "נעמי שמר",
    "נפתלי",
    "סוליקה חגואל",
    "סיני",
    "סלעית",
    "סמ נחל חביון",
    "סמ נחל ממשית",
    "סמטת הנמר",
    "סמטת הצבוע",
    "סמטת הקרקל",
    "סמטת השועל",
    "סמטת התן",
    "סנקר אשר",
    "עגנון",
    "עזרא הסופר",
    "עמוס הנביא",
    "עפרוני",
    "פנחס נבון",
    "פרס",
    "קנית",
    "קרית אגיס",
    "ראובן",
    "רבי דוד בוזגלו",
    "רבי דוד מלול",
    "רבי כדיר בוכריס",
    "רבין",
    "רבקה",
    "רהב",
    "רוזלין זוסמן יאלו",
    "רחל",
    'ריה"ל',
    "רכסים",
    'רס"ג',
    'רש"י',
    'רשב"ג',
    "שבתאי",
    "שד ורדימון",
    "שולמית",
    "שכ אלי כהן",
    "שכ בן גוריון",
    "שכ יונתן",
    "שכ שקד",
    "שלומציון",
    "שמואל הנגיד",
    "שמחוני אסף",
    "שמעון",
    "שרה",
    "תמירון",
  ],
  ירושלים: [
    "א דרג'י",
    "א זוהור",
    "א טברי",
    "א טברי סמ2",
    "א טור",
    "א נהאדה",
    "א נח'יל",
    "א נתר",
    "א נתר סמ2",
    "א נתר סמ4",
    "א נתר סמ8",
    "א סאפא סמ11",
    "א סיוטי",
    "א סלייב",
    "א סעדייה",
    "א רוסול",
    "א שאבי",
    "א שאבי סמ1",
    "א שארף",
    "א שיפא סמ2",
    "א שמאעה",
    "א-זאויה אלהינדיה",
    "אאל אל-בית",
    "אאל אל-בית סמ10",
    "אאל אל-בית סמ1",
    "אאל אל-בית סמ2",
    "אאל אל-בית סמ4",
    "אאל אל-בית סמ6",
    "אאל אל-בית סמ8",
    "אאל טלב",
    "אאל טלב סמ1",
    "אבא אבן",
    "אבא אחימאיר",
    "אבא ברדיצב",
    "אבא חלקיה",
    "אבו אל-ליל",
    "אבו אלעלא אל מערי",
    "אבו אלקסם אשאבי",
    "אבו בכר א סדיק",
    "אבו בכר א סדיק סמ1",
    "אבו גנאם",
    "אבו ד'ר אלע'פארי",
    "אבו ד'ר ע'פארי סמ2",
    "אבו ד'ר ע'פארי סמ3",
    "אבו ד'ר ע'פארי סמ5",
    "אבו ד'ר ע'פארי סמ7",
    "אבו דוויח",
    "אבו דוויח סמ2",
    "אבו דוויח סמ4",
    "אבו דוויח סמ6",
    "אבו הוריירה סמ2",
    "אבו הוריירה סמ4",
    "אבו זיד אל-הילאל",
    "אבו חאמד",
    "אבו חאמד אל-ע'זאלי",
    "אבו חאמד סמ1",
    "אבו מאדי",
    "אבו מאדי סמ1",
    "אבו מאדי סמ3",
    "אבו ע'נים",
    "אבו ע'נים סמ10",
    "אבו ע'נים סמ1",
    "אבו ע'נים סמ3",
    "אבו ע'נים סמ5",
    "אבו ע'נים סמ7",
    "אבו ע'נים סמ8",
    "אבו עוביידה",
    "אבו עוביידה סמ10",
    "אבו עוביידה סמ20",
    "אבו עוביידה סמ21",
    "אבו עוביידה סמ2",
    "אבו עוביידה סמ12",
    "אבו עוביידה סמ22",
    "אבו עוביידה סמ4",
    "אבו עוביידה סמ24",
    "אבו עוביידה סמ26",
    "אבו עוביידה סמ17",
    "אבו עוביידה סמ19",
    "אבו פירס אל חמדני",
    "אבו פלאח",
    "אבו צוואנה",
    "אבו רביע",
    "אבו רוזאמה",
    "אבו ריאלה",
    "אבו ריאלה סמ2",
    "אבו ריאלה סמ4",
    "אבו תמאם",
    "אבולעפיה",
    "אבטליון",
    "אבי יונה מיכאל",
    "אביגד נחמן",
    "אביגדורי",
    "אביגור שאול",
    "אביגיל",
    "אבידע",
    "אבידר יוסף",
    "אביזוהר",
    "אביטל",
    "אבינדב",
    "אבינועם ילין",
    "אביעד",
    "אביעזר",
    "אביר יעקב",
    "אבישי",
    "אבישר",
    "אביתר הכהן",
    "אבל פן",
    "אבלס ולטר",
    "אבן אבו טאלב סמ1",
    "אבן בטוטה",
    "אבן ג'ובייר",
    "אבן ג'ראח",
    "אבן גבירול",
    "אבן האזל",
    "אבן ח'לדון",
    "אבן חארת'ה",
    "אבן חארת'ה סמ10",
    "אבן חארת'ה סמ1",
    "אבן חארת'ה סמ2",
    "אבן חארת'ה סמ12",
    "אבן חארת'ה סמ3",
    "אבן חארת'ה סמ5",
    "אבן חארת'ה סמ6",
    "אבן חארת'ה סמ7",
    "אבן חארת'ה סמ8",
    "אבן חווקל",
    "אבן חמאם",
    "אבן חן יעקב",
    "אבן טולון",
    "אבן יהושע",
    "אבן ישראל",
    "אבן מסעוד",
    "אבן מרואן",
    "אבן סינא",
    "אבן ספיר",
    "אבן עזרא",
    "אבן רושד",
    "אבן רושד סמ1",
    "אבן שמואל",
    "אבן שפרוט",
    "אבנר בן נר",
    "אבנר גרשון",
    "אבראהים בן אדהם",
    "אברבנאל",
    "אברהם כחילה",
    "אברהם מסלונים",
    "אג'יאל",
    "אגודת ספורט אליצור",
    'אגודת ספורט אס"א',
    'אגודת ספורט בית"ר',
    "אגודת ספורט הפועל",
    "אגודת ספורט מכבי",
    "אגמון",
    "אגמון אברהם",
    "אגסי שמעון",
    "אגרון",
    "אגרות משה",
    "אגריפס",
    "אדהאן יחייא",
    "אדיב אסעאף",
    "אדלמן",
    "אדלר שאול",
    "אדם",
    "אדמון",
    "אדמונד סחייק",
    "אדמוני",
    "אדניהו הכהן",
    "אהוד",
    "אהל משה",
    "אהל שלמה",
    "אהלי יוסף",
    "אהליאב",
    "אהרון (שעשוע) עזרא",
    "אהרון כהן",
    "אהרונוב",
    "אהרוני",
    "אהרנפלד נחום",
    "אודם",
    "אוהל יהושע",
    "אולברייט ויליאם",
    "אוליפנט",
    "אוליצור",
    "אולשוונגר",
    "אולשן יצחק",
    "אום אל דראג",
    "אום אל עמד",
    "אום אל עצאפיר",
    "אום אל עצאפיר סמ1",
    "אום אל עצאפיר סמ2",
    "אום אל עצאפיר סמ3",
    "אום אל צאמד",
    "אום אלמואמנין",
    "אום הרון",
    "אום טובא",
    "אום כולתום",
    "אום ליסון",
    "אום ליסון סמ1",
    "אום ליסון סמ2",
    "אום ליסון סמ3",
    "אום ליסון סמ4",
    "אום ליסון סמ6",
    "אום ליסון סמ8",
    "אונקלוס",
    "אוסישקין",
    "אופירה",
    "אוצר הגאונים",
    "אוצר הספרים",
    "אור אלחנן",
    "אור החיים",
    "אורבך אפרים",
    "אורוגואי",
    "אורי אורבך",
    "אורי בן ארי",
    "אוריאל",
    "אוריאלי זכריהו",
    "אורנשטיין",
    "אושא",
    "אז תעש הר החוצבים",
    "אז תעשיה גב שאול",
    "אז תעשיה ואדי גוז",
    "אזולאי עקיבא",
    "אזור תעשיה עטרות",
    "אזור תעשיה רוממה",
    "אזור תעשיה תלפיות",
    "אזור תעשייה ענתות",
    "אזור תעשייה שעפט",
    "אח'ואן אל צפא",
    "אחד העם",
    "אחוזת נוף",
    "אחינעם",
    "אחלמה",
    "אחמד אבן חנבל",
    "אחמד אבן חנבל סמ 1",
    "אחמד אבן חנבל סמ2",
    "אחמד אבן חנבל סמ3",
    "אחמד אסעד אל דארי",
    "אחמד אסעד דארי סמ1",
    "אחמד אסעד דארי סמ3",
    "אחמד אסעד דארי סמ4",
    "אחמד אסעד דארי סמ5",
    "אחמד אסעד דארי סמ6",
    "אחמד אסעד דארי סמ7",
    "אחמד אסעד דארי סמ8",
    "אחמד אסעד דארי סמ9",
    "אחמד עדילה",
    "אחמד עלי",
    "אחמד עלי סמ1",
    "אחמד עלי סמ2",
    "אחמד עלי סמ4",
    "אחמד עלי סמ6",
    "אחמד עלי סמ8",
    "אחמד שאוקי",
    "אחמד שאוקי סמ3",
    "אחמד שאוקי סמ5",
    "אחמד שאוקי סמ8",
    "אטינגר",
    "איג אלכסנדר",
    "אידלזון אברהם",
    "אידר",
    "איחוד הכפר",
    "איילון דוד",
    "אילה",
    "אילן מרדכי צבי",
    "אילת",
    "אימרו אל קיס",
    "אימרו אל קיס סמ2",
    "אימרו אל קיס סמ6",
    "אינשטיין",
    "איסחאק אל מווסילי",
    "איסלייב",
    "איסלנד",
    "איסעאף נשאשיבי",
    "איצטדיון טדי",
    "איש מצליח",
    "איש שלום מרדכי",
    "איתמר בן אבי",
    "איתן",
    "אל אדריסי",
    "אל אזאעה",
    "אל אזאעה סמ2",
    "אל אח'טל",
    "אל אחיאא",
    "אל אחילאס",
    "אל אחתראם",
    "אל אחתראם סמ1",
    "אל אחתראם סמ5",
    "אל אחתראם סמ15",
    "אל אחתראם סמ17",
    "אל אחתראם סמ9",
    "אל איאם",
    "אל איאם סמ5",
    "אל איאם סמ6",
    "אל איאם סמ7",
    "אל איאם סמ8",
    "אל אימאב מלק",
    "אל אימאם מוסלים",
    "אל אימאם מוסלים סמ4",
    "אל אימאם מוסלים סמ6",
    "אל אעואר",
    "אל אפדלייה",
    "אל אצמעי",
    "אל אצמעי סמ10",
    "אל אצמעי סמ4",
    "אל אצמעי סמ6",
    "אל אצמעי סמ8",
    "אל אקרוב",
    "אל ארדשיר",
    "אל ארז",
    "אל ארז סמ2",
    "אל ארמן",
    "אל באסי",
    "אל בארודי",
    "אל בארודי סמ4",
    "אל בארודי סמ6",
    "אל בארודי סמ8",
    "אל בדריה",
    "אל בדריה סמ2",
    "אל בובריה",
    "אל בוח'ארי",
    "אל בוחתורי",
    "אל בוטום",
    "אל בוטמה",
    "אל בוטמה סמ1",
    "אל בוסטמי",
    "אל בוסתאן",
    "אל בוסתאן סמ10",
    "אל בוסתאן סמ11",
    "אל בוסתאן סמ2",
    "אל בוסתאן סמ12",
    "אל בוסתאן סמ3",
    "אל בוסתאן סמ13",
    "אל בוסתאן סמ4",
    "אל בוסתאן סמ5",
    "אל בוסתאן סמ15",
    "אל בוסתאן סמ6",
    "אל בוסתאן סמ7",
    "אל בוסתאן סמ17",
    "אל בוסתאן סמ8",
    "אל בוסתאן סמ9",
    "אל בורק",
    "אל בורתקאל",
    "אל בורתקאל סמ3",
    "אל בטן",
    "אל ביאדר",
    "אל ביאדר סמ1",
    "אל ביידר",
    "אל ביידר סמ1",
    "אל בלוטה סמ2",
    "אל בלח",
    "אל בללוט",
    "אל בללוט סמ1",
    "אל בניאס",
    "אל בניאס סמ2",
    "אל בראעם",
    "אל ג'בל אל ג'דיד",
    "אל ג'דיד",
    "אל ג'דיד סמ1",
    "אל ג'דיד סמ2",
    "אל ג'דיד סמ3",
    "אל ג'דיד סמ4",
    "אל ג'דיד סמ5",
    "אל ג'דיד סמ6",
    "אל ג'דיד סמ7",
    "אל ג'דיד סמ9",
    "אל ג'וזה",
    "אל ג'ורון",
    "אל ג'מעיה",
    "אל ג'מעיה אל תחתא",
    "אל ג'סר",
    "אל גאמע",
    "אל גבשה",
    "אל גדוד",
    "אל גדוד סמ3",
    "אל גדוד סמ4",
    "אל גדוד סמ7",
    "אל גול",
    "אל דאליה",
    "אל דהר",
    "אל דהר סמ3",
    "אל דוואר אל אוול",
    "אל דמיר",
    "אל דמיר סמ2",
    "אל דמיר סמ3",
    "אל דמיר סמ5",
    "אל דמיר סמ7",
    "אל דמיר סמ8",
    "אל דרראקה",
    "אל דרראקה סמ2",
    "אל דרראקה סמ3",
    "אל הדבה",
    "אל הודא",
    "אל הודא סמ11",
    "אל הודא סמ3",
    "אל הודא סמ7",
    "אל הודא סמ8",
    "אל הילאל",
    "אל ואד",
    "אל ווחדה",
    "אל ווחדה סמ2",
    "אל ווחדה סמ3",
    "אל וולאג'ה",
    "אל ווער",
    "אל ורד",
    "אל זהור",
    "אל זהור סמ1",
    "אל זהור סמ2",
    "אל זיתון סמ2",
    "אל זמבק",
    "אל ח'נסה",
    "אל חאלסה",
    "אל חארג'ה",
    "אל חג'אג' בן יוסף",
    "אל חג'אג' סמ10",
    "אל חג'אג' סמ4",
    "אל חג'אג' סמ5",
    "אל חגאג בן יוסף",
    "אל חיג'אר",
    "אל חיג'אר סמ2",
    "אל חיג'אר סמ4",
    "אל חכמה",
    "אל חמווי",
    "אל חניה",
    "אל חסבה",
    "אל חקארי",
    "אל חרדוב",
    "אל חרדוב סמ10",
    "אל חרדוב סמ1",
    "אל חרדוב סמ2",
    "אל חרדוב סמ12",
    "אל חרדוב סמ3",
    "אל חרדוב סמ13",
    "אל חרדוב סמ4",
    "אל חרדוב סמ5",
    "אל חרדוב סמ6",
    "אל חרדוב סמ16",
    "אל חרדוב סמ7",
    "אל חרדוב סמ8",
    "אל חרדוב סמ9",
    "אל חרמי שוקרי",
    "אל טאחונה",
    "אל טואל",
    "אל טואל סמ1",
    "אל טנטור סמ1",
    "אל כאלה",
    "אל כאלה סמ1",
    "אל כאלה סמ2",
    "אל כאלה סמ3",
    "אל כאלה סמ4",
    "אל כאלה סמ5",
    "אל כאלה סמ7",
    "אל כאלה סמ9",
    "אל כליף הישאם",
    "אל מאמון",
    "אל מאמון סמ10",
    "אל מאמון סמ4",
    "אל מאמון סמ6",
    "אל מדארס סמ4",
    "אל מדוורה",
    "אל מדרסה",
    "אל מוגהידין",
    "אל מוגראר",
    "אל מוודה",
    "אל מוטרן גבריל",
    "אל מונאדילין",
    "אל מונזר",
    "אל מונזר סמ6",
    "אל מונטאר",
    "אל מונטאר סמ2",
    "אל מונטאר סמ4",
    "אל מוסתשפא",
    "אל מועזמיה",
    "אל מועתסם",
    "אל מוקדסי",
    "אל מוקדסי סמ10",
    "אל מוקדסי סמ1",
    "אל מוקדסי סמ2",
    "אל מוקדסי סמ12",
    "אל מוקדסי סמ4",
    "אל מוקדסי סמ6",
    "אל מוקדסי סמ8",
    "אל מותנבי",
    "אל מחבה",
    "אל מיד'רא",
    "אל מישמיש",
    "אל מלך",
    "אל מנארה",
    "אל מנארה סמ1",
    "אל מנסוריה",
    "אל מסגיד",
    "אל מצאנע",
    "אל מקברה",
    "אל מקפה",
    "אל נאדר",
    "אל נג'אמה",
    "אל נג'אמה סמ3",
    "אל נג'אמה סמ5",
    "אל נג'אמה סמ7",
    "אל נרג'ס",
    "אל נרג'ס סמ1",
    "אל סאפח",
    "אל סאפח סמ3",
    "אל סאפח סמ4",
    "אל סהל",
    "אל סיידה",
    "אל סיל",
    "אל סילם",
    "אל סלאח",
    "אל סלוודור",
    "אל סלחייה",
    "אל סלעה",
    "אל סמאח",
    "אל סמאח סמ2",
    "אל ספדי אחמד",
    "אל ע'רבי",
    "אל ע'רבי סמ1",
    "אל ע'רבי סמ5",
    "אל עאזי איברהים",
    "אל עבאס",
    "אל עבאסייה",
    "אל עבאסייה סמ2",
    "אל עבאסייה סמ4",
    "אל עומרי",
    "אל עזזה",
    "אל עטאא",
    "אל עין",
    "אל עמר דהאר",
    "אל פאראבי",
    "אל פאראבי סמ1",
    "אל פאראבי סמ3",
    "אל קאע",
    "אל קדיסיה",
    "אל קובטיה מרים",
    "אל קובסא",
    "אל קוליה",
    "אל קינדי",
    "אל קירמי",
    "אל קמר",
    "אל קמר סמ4",
    "אל קמר סמ6",
    "אל קמר סמ8",
    "אל קסילה",
    "אל קסר",
    "אל קצאיל",
    "אל קרמי אבו סלמה",
    "אל ראוידה",
    "אל ראס",
    "אל שאפעיה זיינב",
    "אל שוקר",
    "אל שיפא",
    "אל תעאוון",
    "אל-אאמה",
    "אל-אאמה סמ1",
    "אל-אאמה סמ2",
    "אל-אאמה סמ3",
    "אל-אביאד",
    "אל-אביאד סמ11",
    "אל-אביאד סמ13",
    "אל-אביאד סמ5",
    "אל-אביאד סמ15",
    "אל-אביאד סמ7",
    "אל-אביאד סמ9",
    "אל-אוסתאד' תמאם",
    "אל-אוסתאד' תמאם סמ2",
    "אל-אוסתאד' תמאם סמ3",
    "אל-אוסתאד' תמאם סמ4",
    "אל-אוסתאד' תמאם סמ5",
    "אל-אוסתאד' תמאם סמ6",
    "אל-אוסתאד' תמאם סמ7",
    "אל-אוסתאד' תמאם סמ9",
    "אל-אמג'אד",
    "אל-אמג'אד סמ2",
    "אל-אמג'אד סמ4",
    "אל-אמג'אד סמ6",
    "אל-אמג'אד סמ8",
    "אל-אמינה",
    "אל-אנסאר",
    "אל-אנסאר סמ3",
    "אל-אנסאר סמ4",
    "אל-אנסאר סמ6",
    "אל-אספהאני",
    "אל-ביר",
    "אל-ביר אל-שרקי",
    "אל-ביר אל-שרקי סמ4",
    "אל-ביר אל-שרקי סמ5",
    "אל-ביר אל-שרקי סמ7",
    "אל-ביר אל-שרקי סמ9",
    "אל-בירכה",
    "אל-בית אל-עתיק",
    "אל-בלוטה",
    "אל-ברא'",
    "אל-ברא' סמ1",
    "אל-ברא' סמ3",
    "אל-ברא' סמ4",
    "אל-ג'בל",
    "אל-ג'בל סמ3",
    "אל-היל",
    "אל-היל סמ1",
    "אל-היל סמ3",
    "אל-היל סמ5",
    "אל-היל סמ6",
    "אל-היל סמ7",
    "אל-הרם",
    "אל-הרם סמ1",
    "אל-הרם סמ2",
    "אל-וועד",
    "אל-ופא'",
    "אל-ופא' סמ1",
    "אל-ופא' סמ4",
    "אל-זאוויה",
    "אל-זהרא'",
    "אל-זיתון",
    "אל-זנבק",
    "אל-חאמד",
    "אל-חארה שרקייה",
    "אל-חארה שרקייה סמ1",
    "אל-חארה שרקייה סמ2",
    "אל-חארה שרקייה סמ3",
    "אל-חארה שרקייה סמ4",
    "אל-חארה שרקייה סמ5",
    "אל-חארה שרקייה סמ6",
    "אל-חג'אב",
    "אל-חווא",
    "אל-חווא סמ1",
    "אל-חווארה",
    "אל-חיאה",
    "אל-חיאה סמ10",
    "אל-חיאה סמ1",
    "אל-חיאה סמ2",
    "אל-חיאה סמ4",
    "אל-חיאה סמ6",
    "אל-חיאה סמ8",
    "אל-חראיק",
    "אל-חראיק סמ1",
    "אל-חראיק סמ3",
    "אל-חרירי",
    "אל-חרירי סמ1",
    "אל-ט'הרה",
    "אל-ט'הרה סמ1",
    "אל-ט'הרה סמ2",
    "אל-ט'הרה סמ3",
    "אל-ט'הרה סמ4",
    "אל-ט'הרה סמ7",
    "אל-טנטור",
    "אל-טנטור סמ1",
    "אל-טפטאף",
    "אל-יעקובי",
    "אל-כורמא'",
    "אל-כורמא' סמ10",
    "אל-כורמא' סמ1",
    "אל-כורמא' סמ2",
    "אל-כורמא' סמ3",
    "אל-כורמא' סמ4",
    "אל-כורמא' סמ5",
    "אל-כורמא' סמ6",
    "אל-כורמא' סמ7",
    "אל-כורמא' סמ8",
    "אל-כורמא' סמ9",
    "אל-לימון",
    "אל-מא'ד'ון",
    "אל-מא'ד'ון סמ10",
    "אל-מא'ד'ון סמ12",
    "אל-מא'ד'ון סמ3",
    "אל-מא'ד'ון סמ5",
    "אל-מא'ד'ון סמ6",
    "אל-מא'ד'ון סמ8",
    "אל-מג'ד",
    "אל-מג'לס",
    "אל-מג'לס סמ1",
    "אל-מג'לס סמ2",
    "אל-מג'לס סמ4",
    "אל-מהאג'רין",
    "אל-מהאג'רין סמ2",
    "אל-מוולוויה",
    "אל-מוח'תאר",
    "אל-מוטלה",
    "אל-מונתזה",
    "אל-מונתזה סמ10",
    "אל-מונתזה סמ1",
    "אל-מונתזה סמ2",
    "אל-מונתזה סמ12",
    "אל-מונתזה סמ4",
    "אל-מונתזה סמ6",
    "אל-מונתזה סמ8",
    "אל-מוסתווסיף",
    "אל-מזאר",
    "אל-מחאג'ר",
    "אל-מחאג'ר סמ1",
    "אל-מחאג'ר סמ3",
    "אל-מחאג'ר סמ4",
    "אל-מחאג'ר סמ5",
    "אל-מחאג'ר סמ6",
    "אל-מטאר",
    "אל-מטאר סמ1",
    "אל-מטאר סמ3",
    "אל-מטאר סמ5",
    "אל-מיישה",
    "אל-מיעראג'",
    "אל-מיעראג' סמ1",
    "אל-מיעראג' סמ3",
    "אל-מיעראג' סמ4",
    "אל-מיעראג' סמ6",
    "אל-מיעראג' סמ7",
    "אל-מנג'ל",
    "אל-מסעודי",
    "אל-מסעודי סמ1",
    "אל-מעאני",
    "אל-מעהד",
    "אל-מעהד סמ1",
    "אל-מעהד סמ11",
    "אל-מעהד סמ3",
    "אל-מעהד סמ5",
    "אל-מעהד סמ7",
    "אל-מעהד סמ9",
    "אל-מעני סמ1",
    "אל-מקפה סמ10",
    "אל-מקפה סמ1",
    "אל-מקפה סמ12",
    "אל-מקפה סמ14",
    "אל-מקפה סמ5",
    "אל-מקפה סמ6",
    "אל-מקפה סמ16",
    "אל-מקפה סמ8",
    "אל-מקפה סמ9",
    "אל-מראבטין",
    "אל-מראבטין סמ1",
    "אל-מראבטין סמ2",
    "אל-מראבטין סמ3",
    "אל-מראבטין סמ4",
    "אל-מראבטין סמ5",
    "אל-מראבטין סמ6",
    "אל-מראבטין סמ7",
    "אל-מרוג'",
    "אל-מרוג' סמ1",
    "אל-מרוג' סמ2",
    "אל-מרוג' סמ3",
    "אל-מרוג' סמ4",
    "אל-מרוג' סמ5",
    "אל-מרוג' סמ6",
    "אל-מרכז אל-ג'מאהירי",
    "אל-משאהד",
    "אל-משאהד סמ1",
    "אל-משאהד סמ2",
    "אל-משאהד סמ3",
    "אל-משאהד סמ4",
    "אל-משאהד סמ5",
    "אל-משאהד סמ6",
    "אל-משאהד סמ7",
    "אל-משאהד סמ8",
    "אל-משרק",
    "אל-משרק סמ1",
    "אל-משרק סמ2",
    "אל-נאדי",
    "אל-נאדי סמ1",
    "אל-נאדי סמ3",
    "אל-נאדי סמ5",
    "אל-נבי יונס",
    "אל-נג'ום",
    "אל-נובלא'",
    "אל-נובלא' סמ2",
    "אל-נוורס",
    "אל-נפל",
    "אל-נפל סמ1",
    "אל-נפל סמ2",
    "אל-נת'יר סמ2",
    "אל-נת'יר סמ4",
    "אל-נת'יר סמ6",
    "אל-סאלחין",
    "אל-סאלחין סמ2",
    "אל-סאלחין סמ3",
    "אל-סאלחין סמ5",
    "אל-סביל",
    "אל-סביל סמ2",
    "אל-סביל סמ3",
    "אל-סביל סמ4",
    "אל-סביל סמ5",
    "אל-סביל סמ6",
    "אל-סהל אל-ג'דיד",
    "אל-סהל אל-ג'דיד סמ2",
    "אל-סהל אל-ג'דיד סמ4",
    "אל-סהל אל-ג'דיד סמ5",
    "אל-סהל אל-ג'דיד סמ6",
    "אל-סהל אל-ג'דיד סמ7",
    "אל-סהל אל-ג'דיד סמ8",
    "אל-סהל אל-ג'דידסמ10",
    "אל-סוויח",
    "אל-סוויח סמ3",
    "אל-סווסנה",
    "אל-סולטאן",
    "אל-סולטאן סמ1",
    "אל-סולטאן סמ2",
    "אל-סולטאן סמ4",
    "אל-סולטאן סמ5",
    "אל-סולטאן סמ6",
    "אל-סולם",
    "אל-סידר",
    "אל-סלאם",
    "אל-עדל",
    "אל-עדל סמ1",
    "אל-עדל סמ5",
    "אל-עדל סמ7",
    "אל-עהד",
    "אל-עהד סמ2",
    "אל-עולמא",
    "אל-עומרי",
    "אל-עומרי סמ3",
    "אל-עומריין",
    "אל-עומריין סמ1",
    "אל-עומריין סמ2",
    "אל-עשרק",
    "אל-עשרק סמ1",
    "אל-עשרק סמ3",
    "אל-פארוק",
    "אל-פארוק סמ1",
    "אל-פארוק סמ2",
    "אל-פארוק סמ3",
    "אל-פאתח",
    "אל-פורקאן",
    "אל-פרסאן",
    "אל-פרסאן סמ2",
    "אל-פרסאן סמ3",
    "אל-פרסאן סמ4",
    "אל-פרסאן סמ6",
    "אל-פרסאן סמ8",
    "אל-צלעה",
    "אל-צלעה סמ1",
    "אל-צלעה סמ2",
    "אל-צלעה סמ3",
    "אל-צלעה סמ4",
    "אל-צלעה סמ6",
    "אל-צלעה סמ8",
    "אל-צפא",
    "אל-צפא סמ2",
    "אל-צפא סמ4",
    "אל-קאדי אל-פאדל",
    "אל-קאעה",
    "אל-קאעה סמ1",
    "אל-קימה",
    "אל-קלעה",
    "אל-קצר",
    "אל-קש",
    "אל-ראביא",
    "אל-ראביא סמ2",
    "אל-רוא'יה",
    "אל-רוא'יה סמ1",
    "אל-רוא'יה סמ3",
    "אל-רוא'יה סמ4",
    "אל-רוא'יה סמ5",
    "אל-רחמה",
    "אל-רחמה סמ2",
    "אל-ריחאן",
    "אל-רסם",
    "אל-רסם סמ2",
    "אל-רסם סמ4",
    "אל-שהד",
    "אל-שהד סמ1",
    "אל-שיאח",
    "אל-שיח' ענבר",
    "אל-שיח' ענבר סמ10",
    "אל-שיח' ענבר סמ11",
    "אל-שיח' ענבר סמ2",
    "אל-שיח' ענבר סמ12",
    "אל-שיח' ענבר סמ6",
    "אל-שיח' ענבר סמ7",
    "אל-שיח' ענבר סמ8",
    "אל-שיח' ענבר סמ9",
    "אל-ת'ורי",
    "אל-ת'ורי סמ10",
    "אל-ת'ורי סמ2",
    "אל-ת'ורי סמ3",
    "אל-ת'ורי סמ4",
    "אל-ת'ורי סמ5",
    "אל-ת'ורי סמ6",
    "אל-ת'ורי סמ7",
    "אל-ת'ורי סמ8",
    "אל-ת'ורי סמ9",
    "אל-תווחיד סמ1",
    "אל-תווחיד סמ2",
    "אל-תין אל-ע'רבי",
    "אל-תסאמח",
    "אל-תסאמח סמ2",
    "אל-תסאמח סמ4",
    "אל-תראב",
    "אלאימאן",
    "אלאסראא",
    "אלבו יוסף",
    "אלביאן",
    "אלבירוני",
    "אלבירוני סמ10",
    "אלבירוני סמ4",
    "אלבירוני סמ8",
    "אלבק חנוך",
    "אלגזי",
    "אלגניד",
    "אלהג'רה",
    "אלהיג'רה סמ1",
    "אלוני שחר",
    "אלוף יוחאי בן נון",
    "אלוף שאלתיאל",
    "אלוף שמחוני",
    "אלזבייר בן אלעוואם",
    "אלזה",
    "אלזיתונה",
    "אלזיתונה סמ10",
    "אלזיתונה סמ11",
    "אלזיתונה סמ12",
    "אלזיתונה סמ5",
    "אלזיתונה סמ7",
    "אלזיתונה סמ8",
    "אלזיתונה סמ9",
    "אלח'יר",
    "אלחארה אלווסטא",
    "אלחארה אלווסטא סמ2",
    "אלחארה אלווסטא סמ4",
    "אלחארה אלווסטא סמ8",
    "אלחדיביה",
    "אלחדיביה סמ3",
    "אלחדיביה סמ5",
    "אלחריזי",
    "אלטמן אריה",
    "אלי כהן",
    "אליאב יעקב",
    "אליאך יוסף",
    "אליאל דרור",
    "אליאשברג",
    "אליהו איקו חוצ'ה",
    "אליהו יעקב בנאי",
    "אליוט",
    "אלימלך מלז'ינסק",
    "אליעזר הגדול",
    "אליעזר שילוני",
    "אליעזרוב",
    "אליפז",
    "אליצור יהודה",
    "אלירמוך",
    "אלישיב",
    "אלישע",
    "אלישר",
    "אלכארמה",
    "אלכסנדר אל ח'ורי",
    "אלכסנדריון",
    "אלמדינה מונורה",
    "אלמדינה מונורה סמ5",
    "אלמדינה מונורה סמ15",
    "אלמדינה מונורה סמ6",
    "אלמדינה מונורה סמ7",
    "אלמדינה מונורה סמ8",
    "אלמדינה מונורה סמ9",
    "אלמוג",
    "אלמוח'תאר סמ1",
    "אלמות'ינא בן חארת'א",
    "אלמזדלפה",
    "אלמליח אברהם",
    "אלמלכ אלמועט'ם עיסא",
    "אלמסק",
    "אלמרוחה",
    "אלמרוחה סמ10",
    "אלמרוחה סמ11",
    "אלמרוחה סמ12",
    "אלמרוחה סמ3",
    "אלמרוחה סמ13",
    "אלמרוחה סמ14",
    "אלמרוחה סמ5",
    "אלמרוחה סמ15",
    "אלמרוחה סמ7",
    "אלמרוחה סמ8",
    "אלמרוחה סמ9",
    "אלמרכז",
    "אלנור",
    "אלנור סמ4",
    "אלסבר",
    "אלסדאקה",
    "אלסדאקה סמ10",
    "אלסדאקה סמ12",
    "אלסדאקה סמ5",
    "אלסהל אלאח'דר",
    "אלסהל אלאח'דר סמ10",
    "אלסהל אלאח'דר סמ2",
    "אלסהל אלאח'דר סמ12",
    "אלסהל אלאח'דר סמ4",
    "אלסהל אלאח'דר סמ6",
    "אלסהל אלאח'דר סמ7",
    "אלסנאבל",
    "אלסעאדה",
    "אלעזר דוד",
    "אלעזר המודעי",
    "אלעזר המכבי",
    "אלעלם",
    "אלעלם סמ3",
    "אלעקבה",
    "אלעקבה סמ2",
    "אלעקבה סמ5",
    "אלעקבה סמ7",
    "אלעקבה סמ8",
    "אלעשה",
    "אלפג'ר",
    "אלפנדרי",
    "אלפסי",
    "אלפרדוס",
    "אלקבץ",
    "אלקות'ר",
    "אלקחי מרדכי",
    "אלקלעי",
    "אלקנה",
    "אלקעקאע",
    "אלראזי",
    "אלרדואן",
    "אלרדואן סמ3",
    "אלרואי דוד",
    "אלרסאלה",
    "אלרסאלה סמ3",
    "אלשיך",
    "אלשימאא",
    "אלתון",
    "אלתון סמ2",
    "אלתון סמ4",
    "אלתון סמ5",
    "אלתון סמ7",
    "אלתין",
    "אלתקווא",
    "אם הבנים",
    "אמא שלום",
    "אמאם אבו חניפה סמ1",
    "אמאם אלבוח'ארי",
    "אמאם אלבוח'ארי סמ3",
    "אמדורסקי ירחמיאל",
    "אמיל בוטה",
    "אמיל זולא",
    "אמיר",
    "אמיר דרורי",
    "אמנון ליפקין-שחק",
    "אמציה",
    "אמרי בינה",
    "אמרי חיים",
    "אמרי נועם",
    "אמרי קלמן",
    "אמת המים",
    "אמת ליעקב",
    "אנוסי משהד",
    "אנטון שוקרי לורנס",
    "אנטוניה",
    "אנטוקולסקי מרדכי",
    "אנטיגנוס",
    "אנילביץ",
    "אנסארי",
    "אנסארי חסן בן ת'אבת",
    "אסא",
    "אסבעקאת",
    "אסטורה",
    "אסירי ציון",
    "אסתר בר חיים",
    "אסתר המלכה",
    "אע'ביתה",
    "אע'ביתה סמ2",
    "אע'זייל",
    "אע'זייל סמ1",
    "אע'זייל סמ3",
    "אע'זייל סמ6",
    "אעלה בתמר",
    "אפודי",
    "אפרים",
    "אפרסמון",
    "אפרתה",
    "אפשטיין יעקב נחום",
    "אפשטיין יצחק",
    'אצ"ל',
    "ארבל",
    "ארבעת המינים",
    "ארגוב אליעזר",
    "ארד א סמר",
    "ארד אל עקבה",
    "ארונה היבוסי",
    "ארזי הבירה",
    "ארזי ראובן",
    "ארי במסתרים",
    "אריאל",
    "אריאל שרון",
    "אריסטובלוס",
    "אריסטיאס",
    "ארלוזורוב",
    "ארנון יעקב",
    "ארנונה הצעירה",
    "ארסט אברהם",
    "ארץ חפץ",
    "ארציאלי חוה",
    "אררט",
    "אשכולי אהרון",
    "אשכנזי",
    "אשר",
    "אשתורי הפרחי",
    "אתיופיה",
    "ב אונגר מאה שערים",
    "ב חנינה קלנדיה",
    "ב טנוס מרכז מסחרי",
    "ב מילנר שכ ב",
    "ב ניטין מאה שערים",
    "ב סטואר מאה שערים",
    "ב רוט מאה שערים",
    "באב א זהרה",
    "באב א מגרבה",
    "באב א סיפה",
    "באב אל חדיד",
    "באב אל עמוד",
    "באום שלמה",
    "באזוב דוד",
    "באכר זאב",
    "באר מים חיים",
    "באר שבע",
    "בארות יצחק",
    "בארי",
    "בב חוטה",
    "בבלי חנה",
    "בג'יו חיים",
    "בדור",
    "בדר",
    "בדר סמ4",
    "בהא אל-דין",
    "בהרן שלמה זלמן",
    "בובליק גדליה",
    "בובר מרטין",
    "בודנהימר",
    "בוזגלו אשריאל",
    "בוליביה",
    "בונהונטורה אנצ'ו",
    "בוני החומה",
    "בוסתנאי",
    "בועז",
    "בועז הבבלי",
    "בוקסבאום מרדכי",
    "בורג אל פדי",
    "בורג לקלק",
    "בורוכוב",
    "בורלא יהודה",
    "בושם",
    "בזל",
    "בזק בצלאל",
    "בטיש שמעון",
    "בטן אל-הווא",
    "בטן אל-הווא סמ10",
    "בטן אל-הווא סמ3",
    "בטן אל-הווא סמ4",
    "בטן אל-הווא סמ6",
    "בטן אל-הווא סמ7",
    "בטן אל-הווא סמ8",
    "בי רב יעקב",
    'בי"ח חמדת הרים',
    'בי"ח עזרת נשים',
    'בי"ח שערי צדק',
    'בי"ס למדעים ואומנוי',
    "ביאליק",
    "ביאנקיני",
    "ביבאס",
    "ביבארס",
    "בידון",
    "ביח אלין",
    "ביח כפר שאול",
    "ביח סנט גון",
    "בייט שמואל",
    "בייליס מנדל",
    'ביל"ו',
    "בינת יששכר",
    "ביסאן",
    "ביצ'אצ'ו אברהם",
    "ביצור יהושע",
    "ביר אבו ח'שבה",
    "ביר אבו ח'שבה סמ1",
    "ביר אבו ח'שבה סמ3",
    "ביר איוב",
    "ביר אל אסביל",
    "ביר אל סביל",
    "ביר אל-חמראה'",
    "ביר אל-כורום",
    "ביר אל-כורום סמ1",
    "ביר אל-כורום סמ2",
    "ביר אל-כורום סמ3",
    "ביר אל-כורום סמ5",
    "ביר אל-עקבה",
    "ביר אל-עקבה סמ1",
    "ביר אלזחלוק",
    "ביר אלזחלוק סמ2",
    "ביר מנסור",
    "ביר סאלם",
    "ביר סאלם סמ11",
    "ביר סאלם סמ4",
    "ביר סאלם סמ6",
    "ביר סאלם סמ7",
    "ביר סאלם סמ8",
    "ביר סאלם סמ9",
    "ביר עונה",
    "ביר עמדך",
    "בירן שושנה",
    "בירנבאום",
    "בית אל",
    "בית אשל",
    "בית גאלה",
    "בית גיורא",
    "בית האבן",
    "בית הדפוס",
    "בית הטירה",
    "בית היוצר",
    "בית הכרם",
    "בית הכרמל",
    "בית הלחמי",
    "בית המעיין",
    "בית הערבה",
    "בית הצופה",
    "בית הקרן",
    "בית הקשתות",
    "בית השואבה",
    "בית וגן",
    "בית חגלה",
    "בית חנינא החדשה",
    "בית טובי העיר",
    "בית יעקב",
    "בית יצחק",
    "בית ישראל",
    "בית פאג'י",
    "בית פאג'י סמ2",
    "בית צור",
    "בית צורי אליהו",
    "בית צפפה",
    "בית קנדה",
    "בית שערים",
    'בית"ר',
    "בכר נסים",
    "בלבן מאיר",
    "בלד אל קדימה",
    "בלדיה",
    "בלומנפלד",
    "בלזר יצחק",
    "בלילוס",
    "בלכר נחום",
    "בלפור",
    "בן אליעזר אריה",
    "בן אלעס עמר",
    "בן בבא",
    "בן גבריאל",
    "בן גוביל מעאדי",
    "בן גמלא יהושע",
    "בן דב יעקב",
    "בן דוסא",
    "בן דור יצחק",
    "בן הלל",
    "בן זאב ישראל",
    "בן חמו שמעון",
    "בן חנן מיכאל",
    "בן חפץ טוביה",
    "בן טבאי",
    "בן יאיר אלעזר",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן יחזקאל מרדכי",
    "בן יפונה",
    "בן ישראל מנשה",
    "בן לברט",
    "בן נריה ברוך",
    "בן סירא",
    "בן סרוק",
    "בן עדיה שמואל",
    "בן עדיה שמואל סמ6",
    "בן עוזיאל",
    "בן עזאי",
    "בן עטר",
    "בן עמרם",
    "בן ציון",
    "בן ציון נתניהו",
    "בן קהת עמרם",
    "בן רבאח בילאל",
    "בן שדאד ענטרה",
    "בן שמעון דוד",
    "בנאת אבו בכר",
    "בנאת אבו בכר סמ1",
    "בנאת אבו בכר סמ2",
    "בנאת אבו בכר סמ4",
    "בנאת אבו בכר סמ6",
    "בנאת אבו בכר סמ8",
    "בנה ביתך גילה",
    "בנה ביתך רמות",
    "בנטויץ",
    "בני אומייה",
    "בני אומייה סמ1",
    "בני אומייה סמ3",
    "בני ברית",
    "בני בתירא",
    "בניהו",
    "בנימין",
    "בנימין מטודלה",
    "בנק ישראל",
    "בסאן יצחק",
    "בעהם אריה",
    "בעל אור שמח",
    "בעל השאילתות",
    "בעל התניא",
    "בעלי מלאכה",
    "בצלאל",
    "בצראווי בצלאל",
    "בק",
    "בקור חולים",
    "בקי",
    "בר און אורי",
    "בר אילן",
    "בר גיורא",
    "בר זכאי ישעיה",
    "בר יוחאי",
    "בר יקר דב",
    "בר כוכבא",
    "בר לביא רפאל",
    "בר ניסן",
    "בראון אליהו",
    "בראנד אהרון",
    "בראשי זלמן",
    "ברגמן אליעזר",
    "ברגר",
    "ברדנוב ירמיהו",
    "ברודצקי",
    "ברוזה שמואל",
    "ברויאר יצחק",
    "ברוכוף משיח",
    "ברוכי יהושע",
    "ברוכים שמואל",
    "ברוסקינה מאשה",
    "ברוריה",
    "ברזיל",
    "ברזילי",
    "ברזני משה",
    "ברכיהו",
    "ברכת אברהם",
    "ברל לוקר",
    "ברנדויין שמואל",
    "ברנדיס",
    "ברנט זרח",
    "ברניקי",
    "ברנר",
    "ברנשטיין פרץ",
    "ברק",
    "ברקאי",
    "ברקוק",
    "ברקלי שאול",
    "ברקת",
    "ברש מנחם רועי",
    "בת שבע",
    "בתי ברוידה",
    "בתי הורנשטין",
    "בתי ויטנברג",
    "בתי ורשא",
    "בתי מונקץ",
    "בתי מחסה",
    "בתי מינסק",
    "בתי פרבשטין",
    "בתי רנד סלנט",
    "בתיר",
    "ג'אבר בן חייאן",
    "ג'אבר בן חייאן סמ1",
    "ג'אבר בן חייאן סמ2",
    "ג'אבר בן חייאן סמ4",
    "ג'אמע אל-זאויה",
    "ג'אמע אל-זאויה סמ1",
    "ג'אמע אל-זאויה סמ2",
    "ג'אמע אל-זאויה סמ4",
    "ג'אמע אל-זאויה סמ6",
    "ג'בל אל ג'דיד",
    "ג'בל מוכבר",
    "ג'דה",
    "ג'דה סמ1",
    "ג'ובראן ח'ליל",
    "ג'ובראן ח'ליל סמ5",
    "ג'ובראן ח'ליל סמ6",
    "ג'ורג' אדם סמית",
    "ג'ינאו מאיר",
    "ג'עביץ",
    "ג'עביץ סמ1",
    "ג'עביץ סמ2",
    "ג'עפר א-סאדק",
    "גאון",
    "גאוני יעקב",
    "גאמע עבדין",
    "גבירצמן משה",
    "גבל א טור",
    "גבל אטרש",
    "גבל אל רויס",
    "גבל קטן",
    "גבע",
    "גבעון",
    "גבעת אורן",
    "גבעת אורנים",
    "גבעת בית הכרם",
    "גבעת בנימין",
    "גבעת הארבעה",
    "גבעת היונים",
    "גבעת המבתר",
    "גבעת המטוס",
    "גבעת התחמושת",
    "גבעת חנניה",
    "גבעת משואה",
    "גבעת עומר",
    "גבעת קנדה",
    "גבעת שאול",
    "גבעת שהין",
    "גבעת שפירא",
    "גבריהו חיים",
    "גד",
    "גדוד החמישי",
    "גדוד חרמש",
    "גדוד מכמש",
    "גדירי",
    "גדליה",
    "גדליהו אלון",
    "גדעון",
    "גדרה",
    "גואטמאלה",
    "גואל צדק",
    "גוטליב מאוריציו",
    "גוייטין דוד",
    "גול בן ציון",
    "גולאק אשר",
    "גולד",
    "גולדברג לאה",
    "גולומב",
    "גולי קניה",
    "גונן א",
    "גונן ב",
    "גונן ג",
    "גונן ד",
    "גונן ה",
    "גונן ו",
    "גונן ח",
    "גונן ט",
    "גורדון",
    "גוש 60 מוסררה",
    "גוש 51 מוסררה",
    "גוש 33 מרכז מסחרי",
    "גוש 53 מוסררה",
    "גוש 54 מוסררה",
    "גוש 59 מוסררה",
    "גזר",
    "גיא בן הינום",
    "גידין",
    "גיזה",
    "גיחון",
    "גילה-צפון מערב",
    "גינת אגוז",
    "גל",
    "גלבר",
    "גליק נלסון",
    "גלעד",
    "גלעדי",
    "גלעינית",
    "גלרנטר מנחם",
    "גמול",
    "גמזון ראובן",
    "גמע אל קדים",
    "גן החיות התנכי",
    "גן הכוזרי",
    "גן העשרים",
    "גני קטמון",
    "גנרל קניג פייר",
    "גסטר משה",
    "גסר א-נסף",
    "גפני שמחה",
    "גראללה חוסם א דין",
    "גראנאדוס",
    "גרוזנברג",
    "גרון אל חומוס",
    "גרונר דב",
    "גרוס זאב",
    "גרוסמן",
    "גרייבסקי פנחס",
    "גרינברג חיים",
    "גרינולד",
    "גריניגר פאול",
    "גרינשפן הרשל",
    "גריק פטריאכט",
    "גרמי ציון",
    "גרנות",
    "גרץ צבי",
    "גרשון מאיר",
    "גשר החיים",
    "גת",
    "גת שמנים",
    'ד"ר גבי אשכר',
    'ד"ר יוסף בורג',
    'ד"ר מלכה שפיגל',
    "ד'את אל-נטאקין",
    "ד'את אל-נטאקין סמ1",
    "ד'ו אלנוריין",
    "ד'ו אלנוריין סמ2",
    "ד'ו אלנוריין סמ3",
    "דאחית אל מועלמין",
    "דאר א סלאם",
    "דאר חמד",
    "דב הוז",
    "דבה",
    "דבה סמ2",
    "דבה סמ4",
    "דבורה הנביאה",
    "דביר",
    "דברי חיים",
    "דברי ירוחם",
    "דבש",
    "דגל ראובן",
    "דגן",
    "דגניה",
    "דה הז יעקב",
    "דה לימה נחמיה",
    "דה-רוסי",
    "דהומיי",
    "דהרה",
    "דובב מישרים",
    "דובדבני ברוך",
    "דובנוב",
    "דובר שלום",
    "דוגה שלמה",
    "דוד",
    "דוד (העיר העתיקה)",
    "דוד החזן",
    "דוד המלך",
    "דוד מרדכי מאיר",
    "דוואר אל-נח'יל",
    "דוויאת",
    "דוידסון",
    "דולצ'ין",
    "דונה גרציה",
    "דונואס",
    "דוסטרובסקי אריה",
    "דוסתאי",
    "דור ודורשיו",
    "דורות ראשונים",
    "דורש טוב",
    "דינה",
    "דינוביץ גיטל",
    "דינור בן ציון",
    "דיניץ שמחה",
    "דיסקין",
    "דיר אבו טור",
    "דיר אל טפל",
    "דיר אל סיריאן",
    "דיר אל עמוד סמ10",
    "דיר אל-סנה",
    "דיר אל-סנה סמ1",
    "דיר אל-סנה סמ2",
    "דיר אל-סנה סמ3",
    "דיר אל-סנה סמ4",
    "דיר אל-עאמוד",
    "דיר אל-עאמוד סמ2",
    "דיר אל-עאמוד סמ4",
    "דיר אל-עאמוד סמ6",
    "דיר טנטורה",
    "דיר כרמיזן",
    "דיר קירמיזן",
    "דישראלי",
    "דלה פרגולה",
    "דלמן",
    "דלתון",
    "דמיטריוס הקדוש",
    "דן",
    "דני אנג'ל",
    "דניאל",
    "דסקל",
    "דרג א טבוני",
    "דרום",
    "דרוק שלמה זלמן",
    "דרזנר יחיאל",
    "דרך א זעים",
    "דרך אבו-ת'ור",
    "דרך אבו-ת'ור סמ2",
    "דרך אבו-ת'ור סמ4",
    "דרך אל-צוואנה",
    "דרך אל-צוואנה סמ1",
    "דרך אל-צוואנה סמ2",
    "דרך אל-צוואנה סמ3",
    "דרך אל-צוואנה סמ4",
    "דרך אל-צוואנה סמ5",
    "דרך אל-צוואנה סמ7",
    "דרך אל-שיאח",
    "דרך אל-שיאח סמ1",
    "דרך אל-שיאח סמ2",
    "דרך אל-שיאח סמ3",
    "דרך אל-שיאח סמ6",
    "דרך אל-שיאח סמ7",
    "דרך אלישר",
    "דרך בגין",
    "דרך ביר נבאלה",
    "דרך בית חורון",
    "דרך בית חנינא",
    "דרך בית לחם",
    "דרך בנבנישתי דוד",
    "דרך בנימינה",
    "דרך בר-לב",
    "דרך ג'בל מוכבר",
    "דרך ג'בל מוכבר סמ1",
    "דרך ג'בל מוכבר סמ11",
    "דרך ג'בל מוכבר סמ3",
    "דרך ג'בל מוכבר סמ5",
    "דרך ג'בל מוכבר סמ6",
    "דרך ג'בל מוכבר סמ9",
    "דרך גדוד 68",
    "דרך האחיות",
    "דרך האילנות",
    "דרך הבוצר",
    "דרך הגן",
    "דרך הגן סמ2",
    "דרך הגן סמ3",
    "דרך החורש",
    "דרך הטיילת",
    "דרך הטרסות",
    "דרך הכדים",
    "דרך הסלע",
    "דרך העופל",
    "דרך הפטרול",
    "דרך הר הזיתים",
    "דרך השומרה",
    "דרך השילוח",
    "דרך השלוה",
    "דרך התצפית",
    "דרך ואדי אל ג'וז",
    "דרך וולפסון דוד",
    "דרך זלאטע כהן",
    "דרך חברון",
    "דרך חיזמא סמ3",
    "דרך חיזמא סמ4",
    "דרך חיזמא סמ7",
    "דרך חיזמא סמ9",
    "דרך חיזמה",
    "דרך יריחו",
    "דרך יריחו סמ12",
    "דרך יריחו סמ6",
    "דרך כפר עקב",
    "דרך כפר עקב סמ1",
    "דרך כפר עקב סמ2",
    "דרך כפר עקב סמ3",
    "דרך כפר עקב סמ4",
    "דרך כפר עקב סמ5",
    "דרך כרמית",
    "דרך מודעי יצחק",
    "דרך מן קלמן",
    "דרך מנחת",
    "דרך מעלה אדמים",
    "דרך משה ברעם",
    "דרך נבי סמואל",
    "דרך ע'אבה פוקא",
    "דרך עזמות",
    "דרך ענתות",
    "דרך צור באהר",
    "דרך קוליץ",
    "דרך קרית יובל",
    "דרך ראמאללה",
    "דרך ראמאללה סמ100",
    "דרך ראמאללה סמ120",
    "דרך ראמאללה סמ1",
    "דרך ראמאללה סמ111",
    "דרך ראמאללה סמ2",
    "דרך ראמאללה סמ112",
    "דרך ראמאללה סמ4",
    "דרך ראמאללה סמ116",
    "דרך רבין",
    "דרך רופין",
    "דרך שולוב אהרון",
    "דרך שועפאט",
    "דרך שועפאט סמ10",
    "דרך שועפאט סמ2",
    "דרך שועפאט סמ4",
    "דרך שועפאט סמ5",
    "דרך שועפאט סמ6",
    "דרך שועפאט סמ9",
    "דרך שורק",
    "דרך שכם",
    "דרך שכם סמ5",
    "דרך שעפאט סמ3",
    "דרך שעפאט סמ14",
    "דרך שעפאט סמ8",
    "האביבית",
    "האגוז",
    'האדמו"ר מבויאן',
    'האדמו"ר מבוסטון',
    'האדמו"ר מבעלז',
    'האדמו"ר מגור',
    "האדמו\"ר מויז'ניץ",
    'האדמו"ר מלובביץ',
    'האדמו"ר מסדיגורה',
    "האדמו\"ר מרוז'ין",
    'האדמורי"ם ליינער',
    "האדריכל",
    "האדרת",
    "האוזנר גדעון",
    "האומן",
    "האופה",
    "האור",
    "האורן",
    "האזוב",
    "האחוה",
    "האחות זלמה",
    "האחות יהודית",
    "האחים",
    "האחים להרן",
    "האייל",
    "האירית",
    "האלוף יקותיאל אדם",
    "האלוף עוזי נרקיס",
    "האמאם אבו הוריירה",
    "האמאם אבו חניפה",
    "האמאם אלחנבלי",
    "האמאם אלחנבלי סמ1",
    "האמאם אלחנבלי סמ2",
    "האמאם אלמלכי",
    "האמאם אלמלכי סמ1",
    "האמאם אלמלכי סמ2",
    "האמאם אלמלכי סמ3",
    "האמאם שאפעי",
    "האמאם שאפעי סמ3",
    "האמונה",
    "האמוראים",
    "האמרכלים",
    "האנפה",
    "האפרסק",
    'האר"ז',
    'האר"י',
    "הארבעה",
    "הארגמן",
    "הארון אל-רשיד",
    "הארזים",
    "הארנון",
    "האשכול",
    "האשל",
    "הבבא סאלי",
    "הבורסקאים",
    "הביכורים",
    "הבנאי",
    'הבעש"ט',
    "הברון הירש",
    "הבריכה",
    "הגאון מטורדא",
    "הגאונים",
    "הגדוד העברי",
    'הגדנ"ע',
    "הגומא",
    "הגיא",
    "הגלבוע",
    "הגלגל",
    "הגליל",
    "הגן הטכנולוגי",
    "הגננת",
    "הגפן",
    'הגר"א',
    "הגשר החי",
    "הגתית",
    "הדאיה",
    "הדב",
    "הדולב",
    "הדישון",
    "הדמומית",
    "הדף היומי",
    "הדפנה",
    "הדקל",
    "הדרך האמריקאית",
    "הדרך האמריקאית סמ10",
    "הדרך האמריקאית סמ20",
    "הדרך האמריקאית סמ1",
    "הדרך האמריקאית סמ11",
    "הדרך האמריקאית סמ2",
    "הדרך האמריקאית סמ12",
    "הדרך האמריקאית סמ3",
    "הדרך האמריקאית סמ13",
    "הדרך האמריקאית סמ4",
    "הדרך האמריקאית סמ14",
    "הדרך האמריקאית סמ5",
    "הדרך האמריקאית סמ6",
    "הדרך האמריקאית סמ16",
    "הדרך האמריקאית סמ7",
    "הדרך האמריקאית סמ8",
    "הדרך האמריקאית סמ18",
    "הדרך האמריקאית סמ9",
    "ההבטחה",
    "ההגנה",
    "ההדסים",
    "ההסתדרות",
    "ההעברה",
    "ההרדוף",
    "ההתנדבויות",
    "הולצברג שמחה",
    "הועד הלאומי",
    "הופיין אליעזר",
    "הורביץ",
    "הורקנוס יוחנן",
    "הורקניה",
    "הושע",
    "הותיקן",
    "הזהבית",
    "הזית",
    "הזכרון",
    "הזמיר",
    "החבוש",
    "החבצלת",
    'החו"ש',
    "החומה השלישית",
    "החוצב",
    'החי"ל',
    'החי"ם',
    'החי"ש',
    'החיד"א',
    "החלוץ",
    "החלמית",
    "החמניה",
    "החנויות",
    "החסידה",
    "החצוצרות",
    "החרוב",
    "החרושת",
    "החרמון",
    "החרצית",
    "החרש",
    "החשמונאים",
    "הטורים",
    "הטחנה",
    "הטייסים",
    "היבוק",
    "היהודים",
    "היוצק",
    "היזמה",
    "היינריך היינה",
    "היכל המשפט",
    "הילדסהיימר עזריאל",
    "הינד אל חוסייני",
    "הינד אל חוסייני סמ2",
    "היען",
    "היצירה",
    "הירדן",
    "הירמוך",
    "הירשנברג",
    "הישיבה",
    "הכביש להר חומה",
    "הכורכים",
    "הכותל",
    "הכותל הקטן",
    "הכלדיים",
    "הכלנית",
    "הכנור",
    "הכנסיות",
    "הכנסת אורחים",
    "הכפיר",
    "הכרי",
    "הכרכום",
    "הכרמל",
    'הל"ה',
    "הלב העברי",
    "הלבנון",
    "הלוט",
    "הלוי אליעזר",
    "הלוי שלמה (מומו)",
    "הלטינים",
    "הלילך",
    "הלל",
    "הלמן",
    "הלני המלכה",
    "הלסה",
    "הלסה סמ1",
    "הלסה סמ3",
    "הלסה סמ4",
    "הלסה סמ5",
    "הלסה סמ6",
    "הלפרין ישראל",
    "הלר חיים",
    'המ"ג',
    "המאסף",
    "המביט",
    "המבריא",
    "המבשר",
    "המגדל",
    "המגיד",
    "המדפיסים",
    "המדרגות",
    'המהר"ל מפראג',
    'המהר"מ מרוטנבורג',
    "המובילים",
    "המון",
    "המוסכים",
    "המור",
    "המורה",
    "המזרחן",
    "המחנכת",
    "המחתרות",
    "המיסדים",
    "המישור",
    "המלאך",
    "המלאך בלבן",
    "המלאכה",
    "המלבים",
    "המליץ",
    "המלך ג'ורג'",
    "המלך פייצל",
    "המלמד",
    "הממונה",
    "הממציא",
    "המנזר האתיופי",
    "המנזר היווני",
    "המנזר הסורי",
    "המסגד",
    "המסגר",
    "המעיין",
    "המעלות",
    "המעפילים",
    "המערבים",
    "המערות",
    "המפקד",
    "המפקד סמ1",
    "המפקד סמ3",
    "המפקד סמ5",
    "המפקד סמ7",
    "המצודה",
    "המצור",
    "המצילתיים",
    "המקובלים",
    "המר זבולון",
    "המרפא",
    "המשורינים",
    'המשורר אצ"ג',
    "המשוררים",
    "המשוררת",
    "המשוררת זלדה",
    "המשלט",
    "המשעול האחרון",
    "המתנחלים בהר",
    "הנביאים",
    "הנבל",
    "הנגבי שבתאי",
    "הנגר",
    "הנוטרים",
    "הנוצרים",
    "הנורית",
    "הנחושת",
    "הנחל",
    "הנטקה",
    "הניידות",
    "הניצן",
    "הנמר",
    "הנציב",
    "הנרד",
    "הנשיא",
    "הנשיא השישי",
    "הס משה",
    "הסביון",
    "הסדנא",
    "הסהר",
    "הסוללים",
    "הסופר",
    "הסורג",
    "הסחלב",
    "הסיגלית",
    "הסייר",
    "הסיירת הירושלמית",
    "הסיפן",
    "הסלח",
    "הסנהדרין",
    "הסתונית",
    "הסתת",
    'הע"ח',
    "העוגב",
    "העומר",
    "העופרת",
    "העטלף",
    "העיר העתיקה",
    "העירית",
    "העלוי",
    "העליה",
    "העמלים",
    "העסקן",
    "הערמונים",
    "העשור",
    "הפורצים",
    "הפטרארכיה הארמנית",
    "הפטריארכיה הקופטית",
    "הפלאה",
    'הפלמ"ח',
    "הפסגה",
    "הפרטיזנים",
    "הפרסה",
    "הצבי",
    "הצבעוני",
    "הצבר",
    "הצדיק מטשכנוב",
    "הצדיק משטפנשט",
    "הצוף",
    "הציונות",
    "הצייר",
    "הצייר יוסי",
    "הצלם רחמים",
    "הצלף",
    "הצנחנים",
    "הצפורן",
    "הצפירה",
    "הצפצפה",
    "הקבלן",
    "הקונגרס הציוני",
    "הקופטים",
    "הקורא",
    "הקליר",
    "הקמרונות",
    "הקנה",
    "הקצין סילבר",
    "הקראים",
    "הקרדו",
    "הקריה רוממה",
    "הקרן הקיימת",
    "הר הבית",
    "הר הזיתים",
    "הר הזכרון",
    "הר המשחה",
    "הר הצופים",
    "הר הצופים אידלסון",
    "הר הצופים רזניק",
    "הר הצופים ש אסבסט",
    "הר הרצל",
    "הר חרת",
    "הר צופים גולדסמיט",
    "הר ציון",
    "הר שלמון",
    'הרא"ם',
    "הראובני דוד",
    "הרב אבא שאול",
    "הרב אבוהב",
    "הרב אבן דנן",
    "הרב אברהם זכות",
    "הרב אברהם חזן",
    "הרב אברהם רביץ",
    "הרב אברהם שפירא",
    "הרב אברמסקי",
    "הרב אגן",
    "הרב אונטרמן",
    "הרב אור שרגא",
    "הרב אטון בן ציון",
    "הרב אלבוחר חיים",
    "הרב אלטשולר דוד",
    "הרב אליעזר דיאבילה",
    "הרב אליעזרי",
    "הרב אלמושנינו",
    "הרב אלנקווה",
    "הרב אנגל וידאל",
    "הרב אשלג",
    "הרב בוקשפן",
    "הרב בינה אריה",
    "הרב בלוי",
    "הרב בנימין צביאלי",
    "הרב ברודי",
    "הרב בריכטא מנדל",
    "הרב ברים",
    "הרב ברלין",
    "הרב ברמן יעקב",
    "הרב גולדקנופף",
    "הרב גורן שלמה",
    "הרב גרוסברג חנוך",
    "הרב דוד פוברסקי",
    "הרב דוד שניאורסון",
    "הרב דילרוזה חיים",
    "הרב הומינר",
    "הרב הירשלר",
    "הרב הרץ יוסף צבי",
    "הרב הרצוג",
    "הרב והרבנית קאפח",
    "הרב ויינגרטן אברהם",
    "הרב וילהלם",
    "הרב וינוגרד",
    "הרב ז'ולטי בצלאל",
    "הרב זווין",
    "הרב חדש מאיר",
    "הרב חוויתה כהן רחמי",
    "הרב חיים שאול דוויק",
    "הרב חכם שמעון",
    "הרב חן",
    "הרב חסדאי קרשקש",
    "הרב טולדנו",
    "הרב טייב חי",
    "הרב יאדלר בן ציון",
    "הרב יהודה עמיטל",
    "הרב יוסף גבאי",
    "הרב יצחק בראשי",
    "הרב ישעיהו דולגין",
    "הרב כדורי יצחק",
    "הרב כהנמן",
    "הרב כלפון משה הכהן",
    "הרב לופיאן",
    "הרב מוהליבר שמואל",
    "הרב מוצפי יעקב",
    "הרב מוצפי סלמן",
    "הרב מימון",
    "הרב מן ההר",
    "הרב מנחם בצרי",
    "הרב מניטו",
    "הרב נאה",
    "הרב נורוק",
    "הרב ניסים יצחק",
    "הרב ננס אליעזר",
    "הרב סלנט",
    "הרב עדני",
    "הרב עוזיאל",
    "הרב עזרן ניסים",
    "הרב עלוואן שבתי",
    "הרב עמיאל",
    "הרב עראקי חיים",
    "הרב פורת ישראל",
    "הרב פטאל",
    "הרב פלקסר יצחק",
    "הרב פניז'ל",
    "הרב פרנק",
    "הרב צבי יהודה",
    "הרב צדקה חוצ'ין",
    "הרב קהן חיים",
    "הרב קוסובסקי",
    "הרב קוק",
    "הרב קלונימוס",
    "הרב קלכהיים עוזי",
    "הרב קצנלבוגן רפאל",
    "הרב רבין חזקיהו",
    "הרב רובין",
    "הרב רוזנטל דוד",
    "הרב ריינס",
    "הרב שאג",
    "הרב שאולזון",
    "הרב שאולי",
    "הרב שבתאי חזקיה",
    "הרב שיף אברהם",
    "הרב שלום משאש",
    "הרב שלמה וורטהיימר",
    "הרב שמואל ברוך",
    "הרב שמואל נתן",
    "הרב שפרבר דוד",
    "הרב שפרן",
    "הרב שרייבר ישראל",
    "הרב שרים יוסף",
    "הרב שרירא",
    'הרדב"ז',
    "הרואה",
    "הרובע היהודי",
    "הרובע הנוצרי",
    "הרוסמרין",
    "הרחמים",
    "הרטום",
    "הרכב",
    "הרכבי",
    "הרכבים",
    "הרכבת",
    "הרליץ יוסף",
    "הרן",
    "הרפוב הדומיניקנית",
    "הרפז ניסן",
    "הרקח",
    "הרקמה",
    "הרקפת",
    "הררי רפול",
    'הרשד"ם',
    "השגריר ארגוב שלמה",
    "השומר",
    "השועל",
    "השוערים",
    "השופט בנימין",
    "השופט חיים כהן",
    "השופר",
    "השזיף",
    "השיירות",
    "השילוח",
    "השיריונאי",
    "השיש",
    "השישה עשר",
    'השל"ה',
    "השלום והאחדות",
    "השליו",
    "השליחים",
    "השלכת",
    "השלשלת",
    "השמינית",
    "השער החדש",
    "השקד",
    "השקמה",
    "התאנה",
    "התבור",
    "התבלין",
    "התומר",
    "התופים",
    "התות",
    "התותחן",
    "התיבונים",
    "התכלת",
    "התלתן",
    "התמיד",
    "התמרוקים",
    "התנאים",
    "התנופה",
    "התנחלות מוריה",
    "התעשיה",
    "התפוח",
    "התקוה",
    "התקופה",
    "ו.אום אל עמיד סמ1",
    "ו.אום אל עמיד סמ2",
    "ו.אום אל עמיד סמ5",
    "ו.אום אל עמיד סמ6",
    "ואדי אום אל עמיד",
    "ואדי אל ג'וז",
    "ואדי אל ח'לף",
    "ואדי אל ח'לף סמ2",
    "ואדי אל חומוס",
    "ואדי אל נאר",
    "ואדי אל רבאבה",
    "ואדי התאומים",
    "ואדי חילווה",
    "ואדי חילווה סמ1",
    "ואדי חילווה סמ2",
    "ואדי חילווה סמ4",
    "ואדי חילווה סמ6",
    "ואדי יצול",
    "ואדי יצול סמ1",
    "ואדי יצול סמ2",
    "ואדי יצול סמ3",
    "ואדי יצול סמ4",
    "ואדי יצול סמ5",
    "ואדי יצול סמ6",
    "ואדי יצול סמ7",
    "ואדי יצול סמ8",
    "ואדי יצול סמ9",
    "ואדי מואזפין",
    "ואדי עבדאללה",
    "ואדי עבדאללה סמ1",
    "ואדי עבדאללה סמ3",
    "ואדי עבדאללה סמ4",
    "ואדי עבדאללה סמ5",
    "ואדי עבדאללה סמ6",
    "ואדי קדום",
    "ואדי קדום סמ10",
    "ואדי קדום סמ2",
    "ואדי קדום סמ3",
    "ואדי קדום סמ4",
    "ואדי קדום סמ5",
    "ואדי קדום סמ6",
    "ואדי קדום סמ8",
    "ואדי קדרון",
    "וודגווד",
    "וולטה העלית",
    "וולך",
    "וורן צארלס",
    "ויה דולורוזה",
    "ויטל חיים",
    "וייז שמואל",
    "וייסברג חיים",
    "וייץ יוסף",
    "וילנאי זאב",
    "וינדהם דידס",
    "וינר אשר",
    "ויס יעקב",
    "ויסמן ליאו",
    "ויקטור ויוליוס",
    "ויתקון אלפרד",
    "ולוס",
    "ולירו",
    "ולנברג ראול",
    "ולנשטיין",
    "ונטורה משה",
    "ונסן לואי",
    "וסרמן פנחס",
    "ועד ארבע הארצות",
    "ורבורג",
    "ורדינון",
    "ורהפטיג זרח",
    "ורשבסקי אריה",
    "ושינגטון",
    "ושיץ",
    "ז'בוטינסקי",
    "זאב לב",
    "זבולון",
    "זהורית",
    "זהרת אל-צבאח",
    "זהרת אל-צבאח סמ1",
    "זהרת אל-צבאח סמ2",
    "זהרת אל-צבאח סמ4",
    "זוטא",
    "זויה א הונוד",
    "זוננפלד",
    "זוסמן",
    "זוקוק א חילל",
    "זיו יוסף",
    "זייד בן חארת'ה",
    "זייד בן חארת'ה סמ2",
    "זייד בן חארת'ה סמ4",
    "זייד בן חארת'ה סמ6",
    "זייד ת'אבת",
    "זילברג משה",
    "זילברשטיין",
    "זית רענן",
    "זכלה",
    "זכרון טוביה",
    "זכרון יעקב",
    "זכריה",
    "זכריה הרופא",
    "זלמן צורף",
    "זלץ נתן",
    "זלצברגר מכבי",
    "זמורה",
    "זמזם",
    "זמנהוף",
    "זנגויל",
    "זעיין",
    "זעקוקה",
    "זעקוקה סמ1",
    "זעקוקה סמ2",
    "זעקוקה סמ3",
    "זעקוקה סמ4",
    "זעקיקה",
    "זערורה",
    "זערורה סמ1",
    "זערורה סמ3",
    "זקס משה",
    "זר מרדכי",
    "זרובבל",
    "זרחי ישראל",
    "זריצקי דוד",
    "ח'אלד אבן אל ואליד",
    "ח'ולפא' ראשדין",
    "ח'ולפא' ראשדין סמ1",
    "ח'ולפא' ראשדין סמ2",
    "ח'ילת אל עין",
    "ח'ליל א סכאכיני",
    "ח'ליל אלח'אלדי",
    "ח'ליל סכאכיני סמ11",
    "ח'ליל סכאכיני סמ3",
    "ח'ליל סכאכיני סמ5",
    "ח'ליל סכאכיני סמ7",
    "ח'ליל סכאכיני סמ9",
    "ח'לת אל עין סמ1",
    "ח'לת אל עין סמ2",
    "ח'לת אל עין סמ3",
    "ח'לת אל עין סמ4",
    "ח'לת אל עין סמ5",
    "ח'לת אל עין סמ7",
    "ח'לת משעל",
    "ח'לת משעל סמ10",
    "ח'לת משעל סמ8",
    "ח'לת משעל סמ9",
    "ח'לת סינאד",
    "ח'לת סינאד סמ4",
    "ח'לת סינאד סמ6",
    "ח'רובה",
    "ח'רובה סמ5",
    "חאלת אל לוזה",
    "חאן אלסולטאן",
    "חאפז איבראהים",
    "חאפז איבראהים סמ3",
    "חארת אל-נצארה",
    "חארת אל-נצארה סמ1",
    "חארת אל-נצארה סמ2",
    "חאתם א טאי",
    'חב"ד',
    "חבאיל אל ערב",
    "חבאיל אל ערב סמ2",
    "חביב אבשלום",
    "חביב חיים",
    "חבקוק",
    "חבר",
    "חברת משניות",
    'חברת ש"ס',
    "חברת תהילים",
    "חבשוש",
    "חגאזי",
    "חגי",
    "חגיז משה",
    "חובב משה",
    "חובבי ציון",
    "חוואש",
    "חוות הלמוד",
    "חולדה הנביאה",
    "חומת הצלפים",
    "חומת נחמיה",
    "חומת שמואל",
    "חוני המעגל",
    "חוש א שוויש",
    "חוש אבו פרחה",
    "חוש אל-אעור",
    "חוש אל-אעור סמ1",
    "חוש אל-אעור סמ3",
    "חוש אל-אעור סמ4",
    "חוש אלבאן",
    "חוש אלבחארי",
    "חוש אלבטש",
    "חוש אלבסטי",
    "חוש אלוארי",
    "חוש אלזארה",
    "חוש אלזבאדי",
    "חוש אלחטיב",
    "חוש אלחילו",
    "חוש אלמוקת",
    "חוש אלמסעודי",
    "חוש אלנחלה",
    "חוש אלעג'לוני",
    "חוש אלעדס",
    "חוש אלקרקי",
    "חוש אנצארי",
    "חוש דבש",
    "חוש דודו",
    "חוש דקידק",
    "חוש כרמי",
    "חוש מונה",
    "חוש מראע'ה",
    "חוש משעל",
    "חוש סריחאן",
    "חוש שאהין",
    "חות הנוער הציוני",
    "חזון איש",
    "חזון ציון",
    "חזנוביץ",
    "חזקיהו המלך",
    "חטיבת גבעתי",
    "חטיבת הראל",
    "חטיבת ירושלים",
    "חטיבת עציוני",
    "חי אל נעמאן",
    "חי אל נעמאן סמ3",
    "חי אל-מדארס",
    "חי אל-מדארס סמ10",
    "חי אל-מדארס סמ1",
    "חי אל-מדארס סמ11",
    "חי אל-מדארס סמ3",
    "חי אל-מדארס סמ13",
    "חי אל-מדארס סמ4",
    "חי אל-מדארס סמ5",
    "חי אל-מדארס סמ15",
    "חי אל-מדארס סמ6",
    "חי אל-מדארס סמ7",
    "חי אל-מדארס סמ8",
    "חי אל-מדארס סמ9",
    "חי אלנעמאן סמ4",
    "חי אלנעמאן סמ5",
    "חי אלנעמאן סמ7",
    "חי בשיר",
    "חי בשיר סמ1",
    "חי בשיר סמ2",
    "חי בשיר סמ3",
    "חי בשיר סמ4",
    "חי בשיר סמ6",
    "חי בשיר סמ8",
    "חי זע'ייר",
    "חי זע'ייר סמ1",
    "חי זע'ייר סמ2",
    "חי זע'ייר סמ3",
    "חי זע'ייר סמ4",
    "חי זע'ייר סמ6",
    "חי ציאם",
    "חי קראעין",
    "חיבר",
    "חיי אדם",
    "חיים דוד הלוי",
    "חיים עוזר",
    "חיל האויר",
    "חיל המודיעין",
    "חיל הנדסה",
    "חיל הקשר",
    "חיל חמוש",
    "חיל נשים",
    "חירם",
    "חכים אליהו",
    "חכם אברהם",
    "חכם עטיה עזרא",
    "חכם שלום",
    "חכמי יוסף",
    "חכמי לובלין",
    "חלאת חממה",
    "חלחול",
    "חלימה אל-סעדייה",
    "חלימה אל-סעדייה סמ1",
    "חלימה אל-סעדייה סמ2",
    "חלץ",
    "חלקת מחוקק",
    "חלת גיריס",
    "חמדאן עאיד",
    "חמוש",
    "חמוש סמ1",
    "חמוש סמ3",
    "חמוש סמ5",
    "חנה",
    "חנה רחל מלודמיר",
    "חנינא שיף",
    "חנן",
    "חננאל",
    "חנניה",
    "חסד לאברהם",
    "חסדא",
    "חסון עוזי",
    "חסידוב",
    "חסידי קרלין",
    "חפץ חיים",
    "חפצדי נחום",
    "חצור",
    "חצר חלבי",
    "חצרון",
    "חקלאי זאב",
    "חרבת ב סחור",
    "חרבת סאלח",
    'חרל"פ',
    "חרשי ברזל",
    "חרת א בוס",
    "חרת א תחתה",
    "חשין",
    "חתם סופר",
    "טאבליא",
    "טאלוק",
    "טבאליא",
    "טבי",
    "טבנקין יצחק",
    "טבריה",
    "טדסקי גד",
    "טהא חוסיין",
    "טהא חוסיין סמ3",
    "טהון",
    "טוב משה",
    "טוביה",
    "טובלר",
    "טוניק יצחק",
    "טופז",
    "טור סיני",
    "טור סיני זאב",
    "טורא",
    "טחורש כתריאל",
    "טיומקין מרדכי",
    "טיילת גבריאל",
    "טיילת גיא בן הינום",
    "טיילת האז",
    "טיילת עיר שלם",
    "טיילת עמק צורים",
    "טיכו",
    "טלחה",
    "טללים",
    "טלעת אלמשהאד",
    "טרודי בירגר",
    "טרומן הרי",
    "טרומפלדור",
    "טריק אל-מדארס",
    "טריק אל-מדארס סמ1",
    "טריק אל-מדארס סמ2",
    "טשרניחובסקי",
    "יאיר",
    "יאקות אל חמווי",
    "יגאל",
    "יגיע כפים",
    "יד אבשלום",
    "יד ושם",
    "יד חרוצים",
    "יד מרדכי",
    "יד שלמה",
    "ידין יגאל",
    "יהואש",
    "יהודה",
    "יהודה הלוי",
    "יהודה המכבי",
    "יהודה הנשיא",
    "יהודה שפירא",
    "יהודית",
    "יהויריב",
    "יהושע בן נון",
    "יהושע יעקב",
    "יהושפט",
    "יהלום",
    "יואב",
    "יואל",
    "יובב",
    "יובל נאמן",
    "יוחנן בן זכאי",
    "יוחנן הסנדלר",
    "יוחנן מגוש חלב",
    "יוכבד",
    "יוליאנוס קיסר",
    "יונה",
    "יונתן",
    "יוסטמן משה",
    "יוסי בן יועזר",
    "יוסף בן מתתיהו",
    "יוסף הנשיא",
    "יוסף ויטלזון",
    "יוסף חיים",
    "יוסף פתיחה",
    "יוסף פתיחה סמ1",
    "יוסף פתיחה סמ2",
    "יוספטל גיורא",
    "יורדי הסירה",
    "יורם ורונה קרן",
    "יורם רונן",
    "יושע מאיר",
    "יותם",
    "יזרעאל",
    "יחזקאל",
    "יחיל חיים",
    "ייבין יהושע",
    "ילון חנוך",
    "ילין דוד",
    "ים המלח",
    "ים סוף",
    "ימימה",
    "ימין אבות",
    "ימין משה",
    "ינאי",
    "ינובסקי דניאל",
    "יסקי חיים",
    "יעבץ",
    "יעל",
    "יעקב אלעזר",
    "יעקב מאיר",
    "יעקבזון",
    "יער ירושלים",
    "יערי אברהם",
    "יפה נוף",
    "יפה עינים",
    "יפה רום",
    "יפה שמואל",
    "יפהן אברהם",
    "יפו",
    "יפתח",
    "יצהר",
    "יצחק אלחנן",
    "יצחק בן נחום",
    "יצחק הנדיב",
    "יצחק שמיר",
    "יציאת אירופה",
    "יציב",
    "יקים",
    "יקינטון",
    "יראון",
    "ירושלים",
    "ירמיהו",
    "ישא ברכה",
    "ישועת יעקב",
    "ישי",
    "ישיבת איתרי",
    "ישיבת בעלז",
    "ישיבת הכותל",
    "ישיבת מרכז הרב",
    "ישמח מלך",
    "ישעיהו",
    "ישעיהו פרס",
    "ישראל אלדד",
    "ישראלס",
    "יששכר",
    "יתרב",
    "יתרב סמ1",
    "יתרון",
    'כ"ח באייר',
    "כביש מס 7",
    "כביש מס' 60",
    "כהן אפרים",
    "כהנוב",
    "כובשי קטמון",
    "כורזים",
    "כורש",
    "כט' בנובמבר",
    "כי טוב",
    'כי"ח',
    "כיכר אליקים בן מנחם",
    "כיכר אנדרה שוראקי",
    "כיכר ארצות הברית",
    "כיכר גוש קטיף",
    "כיכר גינטר פרידלנדר",
    "כיכר גפן",
    "כיכר דב שפרלינג",
    "כיכר הלוחם הדרוזי",
    "כיכר הרב יהודה צדקה",
    "כיכר זית",
    "כיכר חיטה",
    "כיכר חנדלי",
    "כיכר יהודה קיל",
    "כיכר יהושע זלטר",
    "כיכר יוסי בנאי",
    "כיכר יעקב גיל",
    "כיכר יעקב כץ",
    "כיכר מאיר מדן",
    "כיכר מארק שאגאל",
    "כיכר מרדכי חאיק",
    "כיכר נח(הנריק)פלוג",
    "כיכר סוזה מנדס",
    "כיכר סוללי הבירה",
    "כיכר פאול רויטמן",
    "כיכר רימון",
    "כיכר שעורה",
    "כיכר שרה קמינקר",
    "כיכר תאנה",
    "כיכר תמר",
    "ככר אברהם אבן שושן",
    "ככר אברהם יצחק הל-א",
    "ככר אהבת ישראל",
    "ככר אהרון ליפשיץ",
    "ככר אוסטר",
    "ככר אורד ויינגייט",
    "ככר אזרח מור",
    "ככר אל גזאלי",
    "ככר אל מנטאר",
    "ככר אליהו אלישר",
    "ככר אליהו ארמוזה",
    "ככר אלכסנדר היידן",
    "ככר אלנבי",
    "ככר אמרי שפר",
    "ככר אנה טיכו",
    "ככר אפרים די זהב",
    "ככר אקצין בנימין",
    "ככר ארוין שאול שומר",
    "ככר אריה ברוכיאלי",
    "ככר אריה זקס",
    "ככר ארמין שוורץ",
    "ככר ארן זלמן",
    "ככר ארצי שרמן",
    "ככר אשבל דב",
    "ככר בולגריה",
    "ככר בוסטון",
    "ככר בית החיים",
    "ככר בלומנטל",
    "ככר בן ציון גיני",
    "ככר בנימין שחור",
    "ככר בעלזא",
    "ככר בר כוכבא",
    "ככר ברוך שוחטמן",
    "ככר בתי מחסה",
    "ככר ג'ורג' מיני",
    "ככר גאלה גליקסיון",
    "ככר גדוד בית חורון",
    "ככר גדוד מוריה",
    "ככר גולי קפריסין",
    "ככר גיורא לוטן",
    "ככר גייקובסון אדי",
    "ככר דוד הנבי",
    "ככר דוד מרדכי מאיר",
    "ככר דוד(דאצא)אלקלעי",
    "ככר דניה",
    "ככר הארמני",
    "ככר החורבה",
    "ככר הרב מאיר יהודה",
    "ככר הרב קוק",
    "ככר מגנס",
    "ככר נורדאו",
    "ככר סיירת אגוז",
    "ככר סיירת חרוב",
    "ככר סיירת שקד",
    "ככר ספרא",
    "ככר ע'זיל",
    "ככר עומר כתב",
    "ככר פלומר",
    "ככר פרופ' עלי דיויס",
    'ככר צה"ל',
    "ככר ציון",
    "ככר צימוקי אריה",
    "ככר קליינברגר יצחק",
    "ככר ראובן רובין",
    "ככר רמז",
    "ככראברהם הלפרין",
    "ככרולירו חיים אהרון",
    "כמון",
    "כנסת מרדכי",
    "כנפי נשרים",
    "כנרת",
    "כסא רחמים",
    "כספי מרדכי",
    "כף החיים",
    "כף מרים",
    "כפר ברעם",
    "כפר דוד",
    "כפר השלוח",
    "כפר חסאן",
    "כפר נחום",
    "כפר עברי",
    "כפר עציון",
    "כפר שלמא",
    "כץ מיכל",
    "כרם אל מופתי",
    "כרם אל עלמי",
    "כרם אל-הדרה",
    "כרם אל-הדרה סמ5",
    "כרם אנג'אץ",
    "כרם ארסאס",
    "כרם וולי",
    "כרם זיתים",
    "כרם חלה",
    "כרם כאלף",
    "כרם כניף",
    "כרם קמר",
    "כרמון",
    "כרמיה יצחק",
    "כרפס",
    "כתב סופר",
    "לב שטרן",
    "לבונה",
    "לבוקר רנה",
    "לבוש מרדכי",
    "לביא תיאודור",
    "לבנה אליעזר",
    "לבנון צבי",
    "לודביג בלום",
    "לוחמי הגטאות",
    'לוחמי הרובע בתש"ח',
    "לוטה כהן",
    "לוי",
    "לוי יצחק מברדיצב",
    "לויד גורג",
    "לוין שמריהו",
    "לונץ משה",
    "לופו שמואל",
    "לופס אליהו",
    "לוץ צארלס",
    "לוצאטי לואג'י",
    "לוריא",
    "לורך נתנאל",
    'לח"י',
    "לטין פטריאכט",
    "ליאון בן ציון",
    "ליבני איתן",
    "לייב דיין",
    "לייב הורביץ לומזה",
    "לייב יפה",
    "לייבוביץ",
    "ליכטמן אברהם דוד",
    "ליכטנשטיין",
    "ליליאן",
    "לינקולן",
    "ליפסקי לואי",
    "ליפצ'ין סול",
    "ליפשיץ נחום",
    "ליש",
    "לכיש",
    "למפל הדסה",
    "למפרונטי",
    "לנגר יחיאל",
    "לנדנר",
    "לנקין אליהו",
    "לפידות",
    "לשם",
    "מאה שערים",
    "מאי זיאדה",
    "מאיר אבנר",
    "מאיר וייס",
    "מאפו",
    "מבוא אורי אבא",
    "מבוא אחישר",
    "מבוא בייניש סלנט",
    "מבוא בית דוד",
    "מבוא בית הכנסת",
    "מבוא בן ברוך",
    "מבוא בנין",
    "מבוא בשמת",
    "מבוא דוד רזניק",
    "מבוא די זהב",
    "מבוא דקר",
    "מבוא האתרוג",
    "מבוא הברכה",
    "מבוא הדודאים",
    "מבוא החוסן",
    "מבוא החיטה",
    "מבוא הלולב",
    "מבוא המאבק",
    "מבוא המתמיד",
    "מבוא העולה",
    "מבוא העשרה",
    "מבוא הצרי",
    "מבוא הקנמון",
    "מבוא הר שפר",
    "מבוא הררי",
    "מבוא השעורה",
    "מבוא השער",
    "מבוא חיות פרץ",
    "מבוא חיי עולם",
    "מבוא חיים מקובנה",
    "מבוא חצרות",
    "מבוא יורם",
    "מבוא יזהר",
    "מבוא יטבתה",
    "מבוא ילין יהושע",
    "מבוא יסמין",
    "מבוא יצחק",
    "מבוא לבנה",
    "מבוא מידב",
    "מבוא נחל צין",
    "מבוא נחליאל",
    "מבוא נטף",
    "מבוא סם שפיגל",
    "מבוא עברונה",
    "מבוא פל ים",
    "מבוא פלג אדמונד",
    "מבוא פרי האדמה",
    "מבוא פרי העץ",
    "מבוא פרנקל שלמה",
    "מבוא צלמונה",
    "מבוא קטורה",
    "מבוא קידה",
    "מבוא קציעה",
    "מבוא רוזנטל אברהם",
    "מבוא רוזנשטיין דב",
    "מבוא רפידים",
    "מבוא שלום בנימין",
    "מבוא שרף",
    "מבוא תמנע",
    "מבצע קדש",
    "מבקר המדינה",
    "מגדים",
    "מגדל נופים",
    "מגדלי נחום",
    "מגיני ירושלים",
    "מגן האלף",
    "מגרש הרוסים",
    "מדבר סיני",
    "מדרגות גן עדן",
    "מדרגות האסיף",
    "מדרגות הביקור",
    "מדרגות הבציר",
    "מדרגות הכפר",
    "מדרגות המסיק",
    "מדרגות הרומאים",
    "מדרגות מיץ פטל",
    "מדרשה לתזונה",
    "מוג'יר א דין",
    "מודיעין",
    "מוזיאון ישראל",
    "מוזיאון רוקפלר",
    "מוזס זיגפריד",
    "מוחמד אל פאתח",
    "מוחמד אל פאתח סמ1",
    "מוחמד אל פאתח סמ2",
    "מוטה",
    "מולכו",
    "מונבז",
    "מוניר חסאן",
    "מוניר חסאן סמ1",
    "מוניר חסאן סמ3",
    "מוניר חסאן סמ4",
    "מוסא סייק",
    "מוסד אילין",
    "מוסיוף שלמה",
    "מוסעב בן עומיר סמ1",
    "מוסקוביה",
    "מועדון הרכיבה",
    "מועלם שמעון נסים",
    "מוצא תחתית",
    "מוצעב בן עומייר",
    "מוצעב בן עומייר סמ3",
    "מוצקין אריה",
    "מוצרי מכבי",
    "מורגנטאו הנרי",
    "מורדות בית וגן",
    "מוריה",
    "מוריסטן",
    "מושבה אמריקנית",
    "מזור",
    "מזיא אהרן",
    "מזכרת משה",
    "מזל אריה",
    "מזל גדי",
    "מזל דלי",
    "מזל טלה",
    "מזל מאזנים",
    "מזל קשת",
    "מזל שור",
    "מזל תאומים",
    "מזר בנימין",
    "מזרחי יוסף",
    "מזרחי מישאל",
    'מח"ל',
    "מחג'רת עתמה",
    "מחג'רת עתמה סמ2",
    "מחג'רת עתמה סמ3",
    "מחוזא",
    "מחיסן",
    "מחיסן סמ1",
    "מחיסן סמ2",
    "מחיסן סמ3",
    "מחיסן סמ5",
    "מחלקי המים",
    "מחמדיה",
    "מחמוד ברבר",
    "מחנה יהודה",
    "מחנה יצחק",
    "מחנה פליטים א ראם",
    "מחנה פליטים קלנדיה",
    "מחנה פליטים שעפט",
    "מחנה שנלר",
    "מחניים",
    "מיוחס",
    "מיטיב נגן",
    "מיכאלסון",
    "מיכה",
    "מיכל",
    "מיכל הכהן",
    "מיכלין",
    "מינץ",
    "מינצברג",
    "מיס לנדאו",
    "מיצרי טירן",
    "מירון",
    "מירסקי יצחק",
    "מישאל",
    "מכה",
    "מכה סמ1",
    "מכון לב",
    "מכור",
    "מכלוף עידן",
    "מכללת ירושלים לבנות",
    "מכסיקו",
    "מלאכי",
    "מלון גני יהודה",
    "מלון דיפלומט",
    "מלון הייאט ריג'נסי",
    "מלון זוהר",
    "מלון טירת בת שבע",
    "מלון מגדל העיר",
    "מלון רם",
    "מלון רמת תמיר",
    "מלון שער יפו",
    "מלכי",
    "מלכי ישראל",
    "מלכי צדק",
    "מלל",
    "מלצר פיבל",
    "ממילא",
    "מן",
    "מנדלי מוכר ספרים",
    "מנדלסון אריק",
    "מנה",
    "מנורה",
    "מנורי",
    "מנזר המארונים",
    "מנזר סן סימון",
    "מנחם",
    "מנחם ירושלים",
    "מנחם מנדל משקלוב",
    "מנחת יצחק",
    "מנטקה א חמרה",
    "מני אליהו",
    "מנשה",
    "מס ראובן",
    "מסורת אל-מיה",
    "מסורת אל-מיה סמ1",
    "מסילת ישרים",
    "מסליאנסקי צבי",
    "מסעדה",
    "מסעדה סמ1",
    "מסעדה סמ2",
    "מסעדה סמ3",
    "מסעדה סמ4",
    "מסריק",
    "מע תלפיות",
    "מעבר אהרן אליאס",
    "מעבר המיתלה",
    "מעבר הראבד",
    "מעבר מזרחי עזרא",
    "מעגל בית המדרש",
    "מעגל הניקבה",
    "מעגלות הרב פרדס",
    'מעגלי הרי"ם לוין',
    "מעגלי יבנה",
    "מעוז",
    "מעוז עובד א",
    "מעון",
    "מעון אנגלסקי",
    "מעון דליה",
    "מעון תלפיות",
    "מעונות בזק",
    "מעונות ברונפמן",
    "מעלה אהרון ראובני",
    "מעלה אורנים",
    "מעלה אלחנקה",
    "מעלה דרגה",
    "מעלה האכסניה",
    "מעלה הבוסתן",
    "מעלה הברושים",
    "מעלה החאלבים",
    "מעלה הילמן",
    "מעלה המוגרבים",
    "מעלה המועצה",
    "מעלה הצליינים",
    "מעלה הצניר",
    "מעלה הקיסוס",
    "מעלה השבשבת",
    "מעלה השלום",
    "מעלה השלום סמ1",
    "מעלה זאב",
    "מעלה זיתים",
    "מעלה כגן הלנה",
    "מעלה מוזס",
    "מעלה תימן",
    "מעלופיה",
    "מעלות אהל שלמה",
    "מעלות אלפיה",
    "מעלות אלרם",
    "מעלות אפרים זלמן",
    "מעלות בדאהב יצחק",
    "מעלות בני",
    "מעלות דושינסקי",
    "מעלות דפנה",
    "מעלות המדרשה",
    "מעלות המחנך",
    "מעלות הנזירות",
    "מעלות הרב אטינגר",
    "מעלות הרב גץ",
    "מעלות הרב מרודניק",
    "מעלות הרב נוריאל",
    "מעלות זע'ל",
    "מעלות חדוה",
    "מעלות חנן פורת",
    "מעלות כיסופים",
    "מעלות לגרלף סלמה",
    "מעלות מועדים",
    "מעלות מוריה",
    "מעלות מי השילוח",
    "מעלות מעיין הגיחון",
    "מעלות סימון",
    "מעלות סמרין",
    "מעלות סנש חנה",
    "מעלות עבאדי יצחק",
    "מעלות עדילה",
    "מעלות עיר דוד",
    "מעלות פדויים",
    "מעלות פרנקל אליהו",
    "מעלות צבי לצדיק",
    "מעלות ריבלין משה",
    "מעלות רננים",
    "מעלות שנהר",
    "מעלות שער הפרחים",
    "מעלות שר שלום",
    "מעמדות ישראל",
    "מעמל אל תלג'",
    "מענה שימחה",
    "מערת הנביאים",
    "מעש",
    "מעשה חושב",
    "מפעלות",
    "מפרץ שלמה",
    "מצדה",
    "מצפה",
    "מצפה גילה",
    "מצפה מנחם",
    "מקדם לעיר",
    "מקוה ישראל",
    "מקור חיים",
    "מקלף אריה",
    "מקסימוס צאייג",
    "מר אליאס",
    "מרג' אבן עאמר",
    "מרג' אבן עאמר סמ4",
    "מרג' אל מהור",
    "מרג' אל מהור סמ5",
    "מרג' אל מהור סמ7",
    "מרגולין",
    "מרגיל מחוור",
    "מרגלית",
    "מרדכי ג'נאשוילי",
    "מרדכי היהודי",
    "מרה",
    "מרוה",
    "מרום ציון",
    "מרזוק משה",
    "מרטון יחזקאל",
    "מרטין לותר קינג",
    "מרידור אליהו",
    "מרים החשמונאית",
    "מרינוב חיים",
    "מרכוס",
    "מרכז (מגרש הרוסים)",
    "מרכז (מרכז כלל)",
    "מרכז העיר",
    "מרכז מסחרי גילה",
    "מרכז מסחרי ק יובל",
    "מרכז ספיר",
    "מרכז קליטה גילה",
    'מרן הגרי"ש אלישיב',
    "מרן הרב עובדיה יוסף",
    "מרץ דוד",
    "מרקו ברוך",
    "מרתה במברגר",
    "משאריף",
    "משאריף סמ5",
    "משאת משה",
    "משגב לדך",
    "משה (מוסא) פלד",
    "משה ואסתר לעזער",
    "משה קראוס",
    "משה ששון",
    "משואות יצחק",
    "משטרת הישובים",
    "משיב מנחם",
    "משכן נשיאי ישראל",
    "משכן שילה",
    "משכנות",
    "משכנות השלום",
    "משכנות שאננים",
    "משמיע שלום",
    "משמר הגבול",
    "משמר העם",
    "משמרות",
    "משמרות הכהונה",
    "משעול הגבורה",
    "משעול הדקלים",
    "משעול ההדס",
    "משעול היערה",
    "משעול הכתרון",
    "משעול הלוחמת אסתר",
    "משעול המגלית",
    "משעול הקורטם",
    "משעול הקורנית",
    "משעול הרותם",
    "משעול זהב מרדכי",
    "משעול מורן",
    "משעול עוזרד",
    "משפט דרייפוס",
    "מתחם הולילנד",
    'מתחם ימק"א',
    "מתתיהו",
    "נאות דוד",
    "נאות דשא",
    "נבו",
    "נבון",
    "נבי שועייב",
    "נג'ארה",
    "נגבה",
    "נדבה יוסף",
    "נהגי הפרדות",
    "נהוראי",
    "נהר פרת",
    "נוב",
    "נובומיסקי משה",
    "נוה גרנות",
    "נוה צדק",
    "נוה שכטר",
    "נווה שאנן",
    "נונייז בנג'מין",
    "נוסייבה",
    "נוף גילה",
    "נוף הרים",
    "נוף ירושלים",
    "נוף רמות",
    "נופי מדבר",
    "נופך",
    "נור אל הודא",
    "נור אל-דין",
    "נוראל הוודא",
    "נורדאו",
    "נזלת אבו סוואי",
    "נזלת אבו סוואי סמ2",
    "נזלת אבו-סוואי סמ2",
    "נזר דוד",
    "נחום",
    "נחום איש גמזו",
    "נחלת יצחק",
    "נחלת צבי",
    "נחלת צדוק",
    "נחלת שבעה",
    "נחמה",
    "נחמו",
    "נחמיה",
    "נחמיה מנשה",
    "נחשון",
    "נטר",
    "נטרונאי גאון",
    "ניב דוד",
    "ניהאד אבו ע'רבייה",
    "ניות",
    "ניידיץ",
    "ניימן שמואל",
    'ניל"י',
    "ניסן אברהם",
    "ניסנבאום",
    "ניקובה רינה",
    "ניקנור",
    "ניקרגואה",
    "נכון שלמה",
    "נעים זמירות",
    "נעמאן",
    "נעמי",
    "נפחא",
    "נפתלי",
    "נציבין",
    "נקדימון",
    "נקר מאיר",
    "נרבתה",
    "נרות שבת",
    "נרקיס",
    "נשר",
    "נתאי הארבלי",
    "נתיב המזלות",
    "נתיב זהרה",
    "נתיב יצחק",
    "נתיבות משנה",
    "נתיבי עם",
    "נתן הנביא",
    "נתנזון איסר",
    "סאחת אל בלד",
    "סאלם אל-ראס",
    "סאלם אל-ראס סמ1",
    "סאלם אל-ראס סמ2",
    "סגולת ישראל",
    "סגל משה צבי",
    "סגן כהן מאיר ומיכאל",
    "סדן דב",
    "סהל אל עבדאן",
    "סוואחירה",
    "סוויקאת עלון",
    "סוזן דניאל-נטף",
    "סוכת שלום",
    "סולד הנריטה",
    "סולובייציק",
    "סולטאן קוטוז",
    "סולטן סולימאן",
    "סולטן קוטז",
    "סולם יעקב",
    "סומך עובדיה",
    "סופר מרדכי",
    "סוק אבטימוס",
    "סוק אל בשורה",
    "סוק אל חוסור",
    "סוק אל לחמין",
    "סוק אל עטרין",
    "סוק אל קטנין",
    "סוק חן א זית",
    "סוקולוב",
    "סוקניק אליעזר",
    "סורא",
    "סורוצקין",
    "סטוינובסקי רבקה",
    "סיוון עמירם",
    "סיון שלום",
    "סיימון אריה",
    "סיירת גולני",
    "סילבר אבא הלל",
    "סילוואן",
    "סילמן",
    "סימן טוב יעקב",
    "סירני",
    "סירקיס דניאל",
    "סירת אל חג'ה",
    "סירת אל חג'ה סמ1",
    "סירת אל חג'ה סמ2",
    "סית אמינה",
    "סכת אל-חדיד",
    "סלאח אל-דין",
    "סלאח אל-דין סמ1",
    "סלאח אל-דין סמ3",
    "סלומון אלפרד",
    "סלומון יואל משה",
    "סלמאן אל פרסי",
    "סלמאן אל-פארסי",
    "סלמאן אל-פארסי סמ1",
    "סלמאן אל-פארסי סמ2",
    "סלמאן אל-פרסי סמ1",
    "סלמאן אל-פרסי סמ3",
    "סלמאן אל-פרסי סמ4",
    "סלמאן אל-פרסי סמ6",
    "סלמאן אל-פרסי סמ8",
    "סלמן אליהו",
    "סלמן יעקב",
    "סמ ברלין",
    "סמ בתי גורל",
    "סמ הבאר",
    "סמ הגבעה",
    "סמ היין",
    "סמ היקב",
    "סמ העורב",
    "סמ צלאחיה",
    "סמאטס",
    "סמבוסקי",
    "סמדר",
    "סמואל",
    "סמולנסקין",
    "סמטת הפיקוס",
    "סמטת נהר שלום",
    "סמיר אמיס",
    "סמיר אמיס סמ1",
    "סמיר אמיס סמ2",
    "סמיר אמיס סמ3",
    "סמיר אמיס סמ7",
    "סן מרטין",
    "סן פול",
    "סנה משה",
    "סנהדריה מורחבת",
    "סנונית",
    "סנט ג'ורג'",
    "סנט גורגס",
    "סנט גיימס",
    "סנט הלנה",
    "סנט מרקס",
    "סנט פטר",
    "סנט פרנציס",
    "סעדיה גאון",
    "ספח",
    "סראחן",
    "סרור",
    "סרור סמ1",
    "סרנא יחזקאל",
    "סתר המדרגה",
    "ע'סאן",
    "עאוגנה",
    "עאידה",
    "עבאדה בן אלסאמת",
    "עבאסיה",
    "עבד א-רחמאן דג'אני",
    "עבד אל חמיד שומאן",
    "עבד אל-עזיז",
    "עבד אל-עזיז סמ1",
    "עבדאללה בן עבאס",
    "עבדאללה בן עבאס סמ2",
    "עבדאללה בן עבאס סמ3",
    "עבדאללה בן עבאס סמ4",
    "עבדאללה בן עבאס סמ7",
    "עבדו",
    "עבדו סמ1",
    "עבדיה",
    "עבדלחמיד שומאן סמ5",
    "עבדלחמיד שומאן סמ7",
    "עבודת ישראל",
    "עדו הנביא",
    "עדן שמואל",
    'עו"ד יעקב רובין',
    "עובד",
    "עובדיה",
    "עובדיה מברטנורה",
    "עודה",
    "עוויס",
    "עוויס סמ2",
    "עוויסת",
    "עוזיה",
    "עולי הגרדום",
    "עולי הרגל",
    "עומר אבן אל-עאס",
    "עומר אבן אלעאס סמ1",
    "עומר אבן אלעאס סמ11",
    "עומר אבן אלעאס סמ3",
    "עומר אבן אלעאס סמ13",
    "עומר אבן אלעאס סמ4",
    "עומר אבן אלעאס סמ5",
    "עומר אבן אלעאס סמ15",
    "עומר אבן אלעאס סמ7",
    "עומר אבן אלעאס סמ9",
    "עומר אבן כתב",
    "עומר אל ח'יאם",
    "עומר אל-פארוק",
    "עומר אל-פארוק סמ2",
    "עומר אל-פארוק סמ3",
    "עונג שבת",
    "עוקבה בן נאפע",
    "עות'מן בן עפאן",
    "עות'מן בן עפאן סמ6",
    "עזאר שמואל",
    "עזה",
    "עזר יולדות",
    "עזרא",
    "עזריאל",
    "עזריה",
    "עזרת ישראל",
    "עזרת תורה",
    "עטון",
    "עטרות",
    "עין אום שריט",
    "עין אל-לוזה",
    "עין אל-לוזה סמ1",
    "עין אל-לוזה סמ3",
    "עין אל-לוזה סמ4",
    "עין אל-לוזה סמ5",
    "עין אל-לוזה סמ7",
    "עין אל-לוזה סמ9",
    "עין גדי",
    "עין חנטש",
    "עין יעקב",
    "עין כרם",
    "עין רוגל",
    "עין רוגל סמ1",
    "עינים למשפט",
    "עיסוויה",
    "עיר דוד",
    "עכרמה בן אבי ג'הל",
    "עכרמה בן ג'הל סמ1",
    'על"ר',
    "עלא דין",
    "עלי אבן אבו טאלב",
    "עלי הכהן",
    "עלי חג'אזי",
    "עלי משעל",
    "עליאש",
    "עלית הנוער",
    "עלמא",
    "עם ועולמו",
    "עמוס",
    "עמינדב",
    "עמל",
    "עמנואל זיסמן",
    "עמנואל נח",
    "עמק הארזים",
    "עמק התימנים",
    "עמק ציון",
    "עמק רפאים",
    "עמרם גאון",
    "עמשא",
    "ענאתה החדשה",
    "ענתבי",
    "ענתות",
    "עץ הדר",
    "עץ חיים",
    "עציון גבר",
    "עצמה",
    "עק אבו מדין",
    "עק אל אסאלה",
    "עק אל חלידיה",
    "עק דאר בריק",
    "עק חן א עקבת",
    "עק שיח ריחאן",
    "עקבת א בטיח",
    "עקבת א חנקה",
    "עקבת א מופתי",
    "עקבת א נאצר",
    "עקבת א סראיה",
    "עקבת א תות",
    "עקבת אל-ג'בל",
    "עקבת דרוויש",
    "עקבת חב רומן",
    "עקבת סוואנה",
    "עקבת ע נים",
    "עקבת ריסאס",
    "עקבת שאדד",
    "עקבת שיח חסן",
    "עקבת שיח לולו",
    "עקבת תקיה",
    "עקיבא כוס בינה",
    "עראק אל טאירה",
    "עראק אל טאירה סמ2",
    "ערד",
    "ערוגות הבושם",
    "ערפה",
    "ערפה סמ3",
    "עשהאל",
    "עתניאל",
    "פאגלין עמיחי",
    "פאול פרנקל",
    "פאטמה אל-זהרא'",
    "פארן",
    "פאת השולחן",
    "פגיס דן",
    "פדרמן דוד",
    "פולונסקי",
    "פולוצקי יעקב",
    "פומבדיתא",
    "פונדק ההודים",
    "פונט ישראל",
    "פועה",
    "פועלי צדק",
    "פורת לאה",
    "פזנר חיים",
    "פטרושקה שבתאי",
    "פטריה",
    "פטרסון",
    "פייבל",
    "פיידי אל עלמי",
    "פיידי אל עלמי סמ1",
    "פיידי אל עלמי סמ3",
    "פיידי אל עלמי סמ5",
    "פיינשטיין",
    "פייר ואן פאסן",
    "פיירוטי",
    "פיכמן",
    "פין גיימס",
    "פינלס שמואל",
    "פינס",
    "פינסקר",
    "פינת סעדיה מרציאנו",
    "פיק חיים",
    "פיקרד לאו",
    "פישל אהרן",
    "פישר מוריס",
    "פלא יועץ",
    "פלג שניאור",
    "פלוגת הטנקים",
    "פלוגת הכותל",
    "פלומבו",
    "פלוסר דוד",
    "פלורנטין",
    "פלט",
    "פלק זאב",
    "פנורמה",
    "פנים מאירות",
    "פנימית כרמית",
    "פנינה",
    "פנמה",
    "פנקס",
    "פסגת זאב",
    "פסגת זאב מזרח",
    "פסגת זאב מערב",
    "פסגת זאב צפון",
    "פסגת רמות",
    "פסח חברוני",
    "פקוד מרכז",
    "פקיעין",
    "פראג יצחק",
    "פראוור יהושע",
    "פרבשטיין",
    "פרדס רימונים",
    "פרומקין",
    "פרופ רקח",
    "פרופ' אנריקה פברגט",
    "פרופ' גרשון שקד",
    "פרופ' יום טוב עסיס",
    "פרופ' מנדי רודן",
    "פרופ' מנשה הראל",
    "פרופ' עזרה זהר",
    "פרופ' צבי נוימן",
    "פרופ' רענן ויץ",
    "פרופס",
    "פרוש",
    "פרחי חיים",
    "פרחי חן",
    "פרי חדש",
    "פרי עמל",
    "פרידלר יואל",
    "פרידמן",
    "פרימו לוי",
    "פריר רחה",
    "פרישמן דוד",
    "פרל וויליאם",
    "פרלשטיין",
    "פרנס",
    "פרנקפורטר פליקס",
    "פרץ",
    "פררה אברהם",
    "פת יעקב",
    "פתוחי חותם",
    "פתח תקוה",
    "פתחיה",
    "פתיחה",
    "פתייה יהודה",
    "צ'ילה",
    "צ'לנוב",
    "צאייג מקסימום",
    "צאלים",
    "צבאח אל-מג'ד",
    "צבאח אל-מג'ד סמ1",
    "צביה ויצחק",
    "צדקיהו",
    "צהרי יחיא",
    "צונדק",
    "צוער",
    "צוקי הישועה",
    "צוקרמן ברוך",
    "צור בחר",
    "צור יעקב",
    "צורי",
    "צורי סמ1",
    "צייטלין",
    "צמח שלמה",
    "צמרת אלנבי",
    "צפון תלפיות",
    "צפורה",
    "צפורי",
    "צפירה ברכה",
    "צפניה",
    "צפרירים",
    "צפת",
    "צרויה",
    "צרת",
    "קאסוטו",
    "קאעב בן זוהיר",
    "קאפח",
    "קארו יוסף",
    "קב ונקי",
    "קבוץ גלויות",
    "קבק",
    "קדושי בבל",
    "קדושי סטרומה",
    "קדושי סלוניקי",
    "קדושת אהרון",
    "קדימה",
    "קדיש לוז",
    "קדמת ציון",
    "קהילות יעקב",
    'קהילות שו"ם',
    "קהילת אונגוואר",
    "קהתי פנחס",
    "קואנקה",
    "קובובי",
    "קוביבה",
    "קוברסקי חיים",
    "קוטגים גונן ט",
    "קוטלר",
    "קוטשר יחזקאל",
    "קויפמן יחזקאל",
    "קול משה",
    "קולומביה",
    "קונדר",
    "קוסטה ריקה",
    "קורא הדורות",
    "קורדובירו",
    "קורות העיתים",
    "קורן אליהו",
    "קורץ",
    "קורצאק",
    "קושניר ניסן",
    "קזה נובה",
    "קיס נעמי",
    "קיסאן",
    "קיסאן סמ1",
    "קיסאן סמ11",
    "קיסאן סמ3",
    "קיסאן סמ13",
    "קיסאן סמ4",
    "קיסאן סמ5",
    "קיסאן סמ6",
    "קיסאן סמ7",
    "קיסאן סמ8",
    "קיסאן סמ9",
    "קיר אל פאר",
    "קלוזנר יוסף",
    "קליין",
    "קליינמן",
    "קלימקר דב",
    "קלנר אריה",
    "קלעי חנוך",
    "קלרמון גנו",
    "קמזון יעקב דוד",
    "קמחי דב",
    "קמפוס גבעת רם",
    "קמפוס הר הצופים",
    "קנאי הגליל",
    "קנבר",
    "קנבר סמ1",
    "קנבר סמ2",
    "קנבר סמ3",
    "קנבר סמ4",
    "קנטר חדאיר",
    "קניון מלחה",
    "קסילה",
    "קע אל חארה",
    "קפלן",
    "קצה אל רצול",
    "קצילה",
    "קציר אהרון",
    "קצנלסון",
    "קראוס גרטרוד",
    "קרטבה",
    "קרטבה סמ1",
    "קריב יצחק",
    "קרית אגי",
    "קרית איתרי",
    "קרית אריה",
    "קרית בן גוריון",
    "קרית בעלזא",
    "קרית האוניברסיטה",
    "קרית הדסה",
    "קרית היובל",
    "קרית המוסכים",
    "קרית הממשלה ש גרח",
    "קרית וולפסון",
    "קרית ויזניץ",
    "קרית מדע",
    "קרית מוריה",
    "קרית מטרסדורף",
    "קרית מנחם",
    "קרית משה",
    "קרית קמניץ",
    "קרליבך",
    "קרמינצקי",
    "קרן היסוד",
    "קרני יהודה",
    "קרניבד רפאל",
    "קרקי",
    "קשאני אליעזר",
    'רא"ל משה לוי',
    "ראבעה אל עדוויה",
    "ראבעה אל עדוויה סמ8",
    "ראבעה אל-עדוויה סמ2",
    "ראבעה אל-עדוויה סמ4",
    "ראבעה אל-עדוויה סמ6",
    "ראבעה אל-עדוויה סמ7",
    "ראובן",
    "ראובן מנשה רביע",
    "ראט משולם",
    "ראם אליהו",
    "ראס אל בושתן",
    "ראס אל ג'אמע",
    "ראס אל ג'אמע סמ3",
    "ראס אל גאמע",
    "ראס אל טוויל",
    "ראס אל טוויל סמ5",
    "ראס אל טלעה",
    "ראס אל נתר",
    "ראס אל עמוד",
    "ראס אל עקבה",
    "ראס אל-ג'בל",
    "ראס אל-טלעה",
    "ראס חמיס",
    "ראס כובסא",
    "ראס כובסא סמ2",
    "ראס סאלם",
    "ראס סאלם סמ1",
    "ראס סאלם סמ3",
    "ראס סאלם סמ4",
    "ראס סאלם סמ5",
    "ראס סאלם סמ7",
    "ראס שחאדה",
    "ראע'ב נשאשיבי",
    "ראע'ב נשאשיבי סמ2",
    "ראע'ב נשאשיבי סמ4",
    "ראש אמיר",
    "ראש פנה",
    "ראשון לציון",
    "ראשית חכמה",
    "רב אלוף לסקוב",
    "רב אשי",
    "רב האי גאון",
    "רב החובל",
    "רב עבוד חיים שאול",
    "רב צעיר",
    "רבאיעה",
    "רבאיעה סמ10",
    "רבאיעה סמ2",
    "רבאיעה סמ12",
    "רבאיעה סמ3",
    "רבאיעה סמ4",
    "רבאיעה סמ6",
    "רבאיעה סמ8",
    "רבדים",
    "רבי אברהם נאה",
    "רבי אריה",
    "רבי בנימין",
    "רבי זעירא",
    "רבי חייא",
    "רבי חלפתא",
    "רבי חנינא",
    "רבי טרפון",
    "רבי מאיר",
    "רבי נחמן מברסלב",
    "רבי עקיבא",
    "רבי צדוק",
    "רבי שלמה",
    "רבי תנחומא",
    "רביבים",
    "רבינוביץ אהרון",
    "רבן זאב",
    "רבנו גרשום",
    "רבנו פוליטי",
    "רבנו תם",
    "רבקה",
    "רג'ואן גוגה",
    'רד"ק',
    "רובוביץ אלכסנדר",
    "רובינשטיין",
    "רובע מוסלמי",
    "רוזאניס",
    "רוזן פנחס",
    "רוזנבלט",
    "רוזנהים יעקב",
    "רוזנפלד",
    "רוטנברג",
    "רוטשילד",
    "רוממה",
    "רוממה עילית",
    "רומני",
    "רועה צאן",
    "רוקח שמעון",
    "רות",
    "רז יעקב",
    "רזיאל דוד",
    "רזיאל נאור אסתר",
    "רח 8070",
    "רח 8080",
    "רחביה",
    "רחבת הטירונים",
    "רחבת חביב שמעוני",
    "רחבת חיים שלום הלוי",
    "רחבת יהודה חסיד",
    "רחבת רבינוביץ",
    "רחובות הבוכרים",
    "רחל אמנו",
    "רחמילביץ משה",
    "ריבלין אליעזר",
    "ריבלין יוסף",
    "רייכמן",
    "רייק חביבה",
    "רינגלבלום",
    "ריש לקיש",
    "רכס לבן",
    'רלב"ח',
    'רמב"ן',
    "רמבה אייזיק",
    "רמדאן",
    "רמדאן סמ1",
    "רמה",
    "רמון יוסף צבי",
    "רמות אלון",
    "רמות ב",
    "רמות ג",
    "רמות ד",
    "רמות פולין",
    "רמות רחל",
    "רמז דוד",
    "רמת איילות",
    "רמת בית הכרם",
    "רמת הגולן",
    "רמת מוצא",
    "רמת שלמה",
    "רמת שרת",
    "רפאל יצחק",
    "רפאל עזרא",
    "רפאלי",
    "רפפורט",
    "רצאבי",
    "רקם",
    "רקנטי אברהם",
    'רש"י',
    'רשב"א',
    'רשב"ג',
    'רשב"ם',
    "ש אוריאל",
    "ש אזב ק יובל",
    "ש אזב תלפיות",
    "ש אחווה",
    "ש אלחנני",
    "ש אלף גב רם",
    "ש ברוצקוס",
    "ש בתי אבן",
    "ש דיסקין",
    "ש דיסקין רוממה",
    "ש הגנה טלביה",
    "ש הריאל",
    "ש ותיק ק משה",
    "ש ותיקים ק יובל",
    "ש זוג ק יובל",
    "ש זוג תלפיות",
    "ש ח משוחררים",
    "ש חבד",
    "ש חסדוף",
    "ש חסכון בית וגן",
    "ש חסכון פלמח",
    "ש יוספטל",
    "ש מומחים ק יובל",
    "ש מורים בית הכרם",
    "ש מורשה",
    "ש מי נפתוח",
    "ש מימון",
    "ש מפונים ק יובל",
    "ש מפונים ש הנביא",
    "ש משרד החוץ",
    "ש נוה הבירה",
    "ש נוה הקריה",
    "ש נוה יעקב",
    "ש נוה עוזר",
    "ש נוה שאנן",
    "ש סטודנט גבעת רם",
    "ש סטודנטים אלנבי",
    "ש סטודנטים ק יובל",
    "ש סטון גונן",
    "ש סלע ק יובל",
    "ש סן סימון",
    "ש עובד ק יבל",
    "ש עובדי המכס",
    "ש עובדי מדינה",
    "ש עובדים רוממה",
    "ש עובדים תלפיות",
    "ש עולים רוממה",
    "ש עמידר רוממה",
    "ש עממי אורגו",
    "ש עממי ב כרם",
    "ש עממי גואטמלה",
    "ש עממי חברון",
    "ש עממי קרית יובל",
    "ש עממי תלפיות",
    "ש פאגי בית וגן",
    "ש פאגי סנהדריה",
    "ש פגי ג שאול",
    "ש פומז בצלאל",
    "ש פת",
    "ש צבא קבע אלנבי",
    "ש צמרת הבירה",
    "ש קואפרטיבים",
    "ש קוטג גילה",
    "ש קוממיות",
    "ש קרית צאנז",
    "ש רבנים",
    "ש רמת אשכול",
    "ש רמת דניה",
    "ש רמת מוצא",
    "ש רסקו",
    "ש רסקו קרית מנחם",
    "ש שמואל הנביא",
    "ש שערי אפרים",
    "שאדיקר נחום",
    "שאול המלך",
    "שאול ליברמן",
    "שאקר באדר",
    "שאר ישוב",
    "שבו",
    "שבות",
    "שבזי",
    "שבטי ישראל",
    "שביבי אש",
    "שביל האריה",
    "שביל החומה",
    "שביל החסידות",
    "שביל הטובה",
    "שביל היוגב",
    "שביל הצופית",
    "שביל הצוקים",
    "שביל השיבולים",
    "שבע קהלות",
    "שבת צדק",
    "שג'רת אל דור",
    "שד בן גוריון",
    "שד בן מימון",
    "שד בן צבי יצחק",
    "שד גבעת משה",
    "שד גולדה מאיר",
    "שד דב יוסף",
    "שד דיין משה",
    "שד האומה",
    "שד האוניברסיטה",
    "שד הזז חיים",
    "שד המאירי",
    "שד הרצל",
    "שד ויצמן",
    "שד לוי אשכול",
    "שד מאיר שמואל",
    "שד מגנס",
    "שד נוה יעקב",
    "שד סיירת דוכיפת",
    "שד עין צורים",
    "שד פניגר יצחק",
    "שד צ'רצ'יל",
    "שד רותי ברעם",
    'שד ש"י עגנון',
    'שד שז"ר',
    "שד שיירת הר הצופים",
    "שד שרת משה",
    'שד"ל',
    "שדאד בן אוס",
    "שדאד בן אוס סמ1",
    "שדה חמד",
    "שדה יצחק",
    "שדמי נחום",
    "שדרת בלומפילד",
    "שדרת המוזיאונים",
    "שדרת רמות",
    "שוהם",
    "שוורצברד שלום",
    "שוחט מניה",
    "שוכמן ישראל",
    "שולאל יצחק",
    "שומרון",
    "שומרי אמונים",
    "שומרי ההר",
    "שוני הלכות",
    "שועפאט",
    "שופן",
    "שוק הצבעים",
    "שוק הצורפים",
    "שוקן זלמן",
    "שור משה",
    "שורץ",
    "שושן",
    "שושנה",
    "שושנים",
    "שותא רוסתאבלי",
    'שז"כ',
    'שח"ל',
    "שחם מאיר",
    "שחר",
    "שחראי",
    "שטיינברג יחיאל",
    "שטנר",
    "שטראוס",
    "שטרוק",
    "שטרן אברהם",
    "שטרן יורי",
    "שי",
    "שיבת ציון",
    "שיח אחמד א ס",
    "שיח מוחמד א-צאלח",
    "שיח סואן",
    "שיח סעדוסעיד",
    "שייח אל כאלדי",
    "שייח' ג'אבר",
    "שייח' ג'ראח",
    "שייח' מחמוד",
    "שיירת עטרות",
    "שילה",
    "שילר",
    "שיק קונרד",
    "שיר למעלות",
    "שיר לשלמה",
    "שיריזלי",
    "שירת אהרן",
    "שירת הים",
    "שירת חנה",
    "שכ א טור 19",
    "שכ אבו תור גוש 18",
    "שכ אוהל משה",
    "שכ אוהל שלמה",
    "שכ ארזי הבירה",
    "שכ ארנונה",
    "שכ בטאנה",
    "שכ בית הכרם",
    "שכ בית וגן",
    "שכ בית ישראל",
    "שכ בית צפפה",
    "שכ בקעה",
    "שכ בתי ברוידא",
    "שכ גאולה",
    "שכ גאולים",
    "שכ גב מרדכי",
    "שכ גב שאול א",
    "שכ גב שאול ב",
    "שכ גבעת הורדים",
    "שכ גבעת רם",
    "שכ גבעת שאול",
    "שכ הבוכרים",
    "שכ הגיחון",
    "שכ המושבה הגרמנית",
    "שכ המושבה היונית",
    "שכ המקשר א",
    "שכ המקשר ב",
    "שכ המקשר ג",
    "שכ הפועלים",
    "שכ הר נוף",
    "שכ וולג'ה",
    "שכ זכרון טוביה",
    "שכ זכרון יוסף",
    "שכ זכרון משה",
    "שכ ח'רבת עטארה",
    "שכ חבאיל חמדן",
    "שכ יגיע כפיים",
    "שכ ימין משה",
    "שכ יפה נוף",
    "שכ כנסת א",
    "שכ כנסת ב",
    "שכ כנסת ג",
    "שכ כפר עקב",
    "שכ כפר שאול",
    "שכ כרם",
    "שכ כרם אברהם",
    "שכ ליפתא תחתית",
    "שכ מאה שערים",
    "שכ מזכרת משה",
    "שכ מחג'רת עתמה",
    "שכ מחנה ישראל",
    "שכ מחניים",
    "שכ מנחת",
    "שכ מסעודיה",
    "שכ מצפה מנחם",
    "שכ מקור ברוך",
    "שכ מקור חיים",
    "שכ נוף ציון",
    "שכ נחלאות",
    "שכ נחלת אחים",
    "שכ נחלת ישראל",
    "שכ נחלת צדוק",
    "שכ נחלת ציון",
    "שכ נחלת שבעה",
    "שכ נחלת שמעון",
    "שכ סנהדריה",
    "שכ עזרת תורה",
    "שכ עין כרם",
    "שכ עין כרם א",
    "שכ עין כרם ב",
    "שכ עין כרם ד",
    "שכ עיר גנים א",
    "שכ עיר גנים ב",
    "שכ עיר גנים ג",
    "שכ עמק רפאים",
    "שכ עץ חיים",
    "שכ עקבת סוואנה",
    "שכ קומונה",
    "שכ קטמון הישנה",
    "שכ קרית משה",
    "שכ קרית שמואל",
    "שכ רוחמה",
    "שכ רוממה",
    "שכ רמת הדסה",
    "שכ שבת צדק",
    "שכ שדה תעופה עטרות",
    "שכ שיך באדר",
    "שכ שמעה",
    "שכ שערי חסד",
    "שכ שערי ים",
    "שכ שערי פינה",
    "שכ תל ארזה",
    "שכ תלפיות",
    "שכון גילה",
    "שכוני נוסייבה",
    "שכונת השלום",
    "שכונת התימנים",
    "שכטמן יוסף",
    "שלאין",
    "שלו יצחק",
    "שלום הגליל",
    "שלום וצדק",
    "שלום יהודה",
    "שלום עליכם",
    "שלומציון",
    "שלוש",
    "שלם נתן",
    "שלמה",
    "שלמה בן פתחיה הלוי",
    "שלמה המלך",
    "שמאי",
    "שמאע אליהו",
    "שמגר",
    "שמואל אבן אור",
    "שמואל הנביא",
    "שמואל הנגיד",
    "שמורק",
    "שמחת כהן",
    "שמי יצחק",
    "שמיר",
    "שמיר משה",
    "שמס א דין אסיוטי",
    "שמס אדין אסיוטי סמ1",
    "שמס אדין אסיוטי סמ3",
    "שמעון",
    "שמעון בן שטח",
    "שמעון המכבי",
    "שמעון הצדיק",
    "שמעוני",
    "שמעיה",
    "שמריה",
    "שמשון",
    "שמשון רפאל הירש",
    "שנברג יוסף",
    "שני יוסף",
    "שניאור",
    "שנירר שרה",
    "שסקין יעקב",
    "שעאבאן",
    "שעאבאן סמ3",
    "שעאבאן סמ4",
    "שער האריות",
    "שער הע'ואנמה",
    "שער ציון",
    "שער שכם",
    "שערי חסד",
    "שערי ירושלים",
    "שערי משה",
    "שערי משפט",
    "שערי פנה",
    "שערי צדק",
    "שערי שמיים",
    "שערי תורה",
    "שפיצר",
    "שפירא צבי",
    "שפע חיים",
    "שפרה",
    "שפרעם",
    "שפת אמת",
    "שץ",
    "שקאאיק אל-נעמאן",
    "שקאאיק אל-נעמאן סמ1",
    "שקאאיק אל-נעמאן סמ3",
    "שקאאיק אל-נעמאן סמ5",
    "שקי אינו",
    "שקיראת",
    "שרבני יהושע",
    "שרגאי זלמן",
    "שרה הקטנה (לבני)",
    "שרה לוי תנאי",
    "שרה עזריהו",
    "שרה קנוט",
    "שרון",
    "שרי ישראל",
    "שרי ראובן",
    "שריון יצחק",
    "שרייבום יעקב",
    "שרעבי שלום",
    "שרף זאב",
    "שרפאת",
    "שרפאת סמ3",
    "שרפאת סמ6",
    "שרפאת סמ8",
    "שרקיה אל בלד",
    "ששת הימים",
    "תבין אלי",
    "תג'ר שלמה ומשה",
    "תדהר",
    "תובל",
    "תוסיה כהן",
    "תוספות יום טוב",
    "תוצרת",
    "תורה ועבודה",
    "תורה מציון",
    "תורן חיים",
    "תורת חיים",
    "תורת חסד",
    "תחכמוני",
    "תחנה מרכזית",
    "תימא",
    "תירוש",
    "תכלת מרדכי",
    "תל אל פול",
    "תל חי",
    "תלמה ילין",
    "תלמודי אברהם",
    "תלפיות מזרח",
    "תמים אל-דארי",
    "תמים אל-דארי סמ3",
    "תמים אל-דארי סמ5",
    "תמים אל-דארי סמ7",
    "תמים אל-דארי סמ9",
    "תמיר שמואל",
    "תפארת ירושלים",
    "תפארת ישראל",
    "תקוע",
    "תקי אל-דין",
    "תקי אל-דין סמ1",
    'תרמ"ב',
    "תרצה",
    "תרשיש",
    'תשב"ץ',
  ],
  ירחיב: [
    "אנפה",
    "דוכיפת",
    "הדרור",
    "השחף",
    "זמיר",
    "חסידה",
    "ירחיב",
    "נחליאלי",
    "סמ חופית",
    "סמ צופית",
    "סנונית",
    "עפרוני",
    "פשוש",
    "שקנאי",
  ],
  ירכא: [
    "אזור התעשייה",
    "אל-תיג'ארה",
    "אלאח'וה",
    "אלאמל",
    "אלאסיל",
    "אלאשלול",
    "אלבאבור",
    "אלבאט",
    "אלבוסתאן",
    "אלביאדה",
    "אלביאדר",
    "אלביר אלגארבי",
    "אלברקה",
    "אלברקוק",
    "אלג'וז",
    "אלג'ורי",
    "אלג'ליל",
    "אלדבה",
    "אלדידבה",
    "אלהדאף",
    "אלהלאל",
    "אלהרם",
    "אלודיאן",
    "אלורוד",
    "אלזוהור",
    "אלזיתון",
    "אלזקאק",
    "אלח'לוה",
    "אלח'רבה",
    "אלח'רוב",
    "אלחארה",
    "אלחדב",
    "אלחדיקה",
    "אלחדית'ה",
    "אלחמאם",
    "אלחרארי",
    "אלחריקה",
    "אלחשנה",
    "אליאסמין",
    "אלכרום",
    "אלכרם",
    "אלמבסלה",
    "אלמדאורה",
    "אלמובארכה",
    "אלמזאריב",
    "אלמזארע",
    "אלמעון",
    "אלנאפורה",
    "אלנהדה",
    "אלנח'יל",
    "אלנסבי",
    "אלנסיג'",
    "אלנרג'ס",
    "אלסדאקה",
    "אלסהלאת",
    "אלסואדייה",
    "אלסינאעה",
    "אלסראיס",
    "אלסרו",
    "אלסריסה",
    "אלעאמר",
    "אלעלום",
    "אלעמארייה",
    "אלענקור",
    "אלעקדה",
    "אלפיג'ן",
    "אלקריה אלריאדיה",
    "אלרביע",
    "אלרהואת",
    "אלרודאת",
    "אלרוייס",
    "אלשארע אשמאלי",
    "אלשכונאת",
    "אלתאח'ים",
    "ג'ורן אלסרסור",
    "ג'ורן אלצרצור",
    "דרב אבו סנאן",
    "דרב סאפאד",
    "ואדי אום עאמר",
    "ואדי סמאק",
    "ח'אלית אלעדס",
    "ח'אלית אלתות",
    "ח'לואת אלרוג'אב",
    "ח'לת אלמשמש",
    "חכורת חרב",
    "יוסף אלצדיק",
    "ירכא",
    "כרם חנא",
    "מראח זיאן",
    "מרכז הכפר",
    "משפחת קדמאני",
    "נביא אלח'דר",
    "סידנא אבו אסראיא",
    "סנובר אלג'או",
    "עריד אלשאמס",
    "ראס אלאע'בר",
    "ראס אלמטל",
    "רח 100",
    "רח 200",
    "רח 110",
    "רח 210",
    "רח 120",
    "רח 220",
    "רח 130",
    "רח 230",
    "רח 140",
    "רח 240",
    "רח 150",
    "רח 250",
    "רח 160",
    "רח 260",
    "רח 170",
    "רח 180",
    "רח 190",
    "רח 101",
    "רח 201",
    "רח 111",
    "רח 211",
    "רח 121",
    "רח 221",
    "רח 131",
    "רח 231",
    "רח 141",
    "רח 241",
    "רח 151",
    "רח 251",
    "רח 161",
    "רח 261",
    "רח 171",
    "רח 181",
    "רח 191",
    "רח 102",
    "רח 202",
    "רח 112",
    "רח 212",
    "רח 122",
    "רח 222",
    "רח 132",
    "רח 232",
    "רח 142",
    "רח 242",
    "רח 152",
    "רח 252",
    "רח 162",
    "רח 262",
    "רח 172",
    "רח 182",
    "רח 192",
    "רח 103",
    "רח 203",
    "רח 113",
    "רח 213",
    "רח 123",
    "רח 223",
    "רח 133",
    "רח 233",
    "רח 143",
    "רח 243",
    "רח 153",
    "רח 253",
    "רח 163",
    "רח 263",
    "רח 173",
    "רח 183",
    "רח 193",
    "רח 104",
    "רח 204",
    "רח 114",
    "רח 214",
    "רח 124",
    "רח 224",
    "רח 134",
    "רח 234",
    "רח 144",
    "רח 244",
    "רח 154",
    "רח 254",
    "רח 164",
    "רח 264",
    "רח 174",
    "רח 184",
    "רח 194",
    "רח 105",
    "רח 205",
    "רח 115",
    "רח 215",
    "רח 125",
    "רח 225",
    "רח 135",
    "רח 235",
    "רח 145",
    "רח 245",
    "רח 155",
    "רח 255",
    "רח 165",
    "רח 265",
    "רח 175",
    "רח 185",
    "רח 195",
    "רח 106",
    "רח 206",
    "רח 116",
    "רח 216",
    "רח 126",
    "רח 226",
    "רח 136",
    "רח 236",
    "רח 146",
    "רח 246",
    "רח 156",
    "רח 256",
    "רח 166",
    "רח 266",
    "רח 176",
    "רח 186",
    "רח 196",
    "רח 107",
    "רח 207",
    "רח 117",
    "רח 217",
    "רח 127",
    "רח 227",
    "רח 137",
    "רח 237",
    "רח 147",
    "רח 247",
    "רח 157",
    "רח 257",
    "רח 167",
    "רח 267",
    "רח 177",
    "רח 187",
    "רח 197",
    "רח 108",
    "רח 208",
    "רח 118",
    "רח 218",
    "רח 128",
    "רח 228",
    "רח 138",
    "רח 238",
    "רח 248",
    "רח 158",
    "רח 258",
    "רח 168",
    "רח 178",
    "רח 188",
    "רח 198",
    "רח 109",
    "רח 209",
    "רח 119",
    "רח 219",
    "רח 129",
    "רח 229",
    "רח 139",
    "רח 239",
    "רח 149",
    "רח 249",
    "רח 159",
    "רח 259",
    "רח 169",
    "רח 179",
    "רח 189",
    "רח 199",
    "שארע אלבלאט",
    "שארע אלעין",
    "שארע אלשואחיט",
    "שארע אסבר",
    "שיח' יוסף גדבאן",
    "שיח' מחמד מעדי",
    "שיח' עלי אלפארס",
    "תלת אסקנדר",
  ],
  ירקונה: [
    "בית העם",
    "דרך הברוש",
    "דרך הפקאן",
    "דרך מייסדי ירקונה",
    "האלה",
    "האקליפטוס",
    "הדקל",
    "הערבה",
    "הפרדס",
    "הצאלון",
    "יפה נוף",
    "ירוק עד",
    "ירקונה",
    "סביונים",
    "שירת השחרור",
  ],
  ישע: ["ישע"],
  ישעי: ["ישעי"],
  ישרש: [
    "אורן",
    "ברק",
    "דן",
    "חרוד",
    "ירקון",
    "ישרש",
    "לכיש",
    "נ. הירדן",
    "נעמן",
    "ערבה",
    "פולג",
    "קישון",
  ],
  יתד: ["ארזים", "הדסים", "יתד", "שיטה"],
  יתיר: ["חוות יתיר", "יתיר"],
  כאבול: ["כאבול"],
  "כאוכב אבו אל-היג'א": ["כאוכב אבו אל-היג'א"],
  כברי: ["כברי"],
  כדורי: ["אלון התבור", "כדורי"],
  כדיתה: ["כדיתה"],
  "כוכב השחר": [
    "בית הבד",
    "דרך המשואות",
    "דרך יריחו",
    'האחזות נח"ל',
    "הביכורים",
    "הבציר",
    "הגת",
    "הזית",
    "היין",
    "היקב",
    "הכרם",
    "המנורה",
    "המסיק",
    "הנסכים",
    "הר חצור",
    "התירוש",
    'י"ב באלול',
    "יצהר",
    "כוכב השחר",
    "כתית",
    "מול נבו",
    "מנחות",
    "משכן שילה",
    "נוף הירדן",
    'נחל ייט"ב',
    "נחלת אפרים",
    "נר התמיד",
    "ענבי הגפן",
    "עת הזמיר",
  ],
  "כוכב יאיר": [
    "אבני החושן צור יגאל",
    "אבני חן צור יגאל",
    "אגוז",
    "אגמית צור יגאל",
    "אודם צור יגאל",
    "אורן",
    "אלה",
    "אלון",
    "אנקור צור יגאל",
    "אפק",
    "ארבל צור יגאל",
    "ארז",
    "אשל",
    "בארותיים",
    "בארי",
    "בזלת צור יגאל",
    "בזק צור יגאל",
    "בירנית",
    "בית אל",
    "בית אלפא",
    "ברוש צור יגאל",
    "ברעם",
    "ברק",
    "ברקאי",
    "ברקת צור יגאל",
    "בשור צור יגאל",
    "בת שלמה",
    "גבעון צור יגאל",
    "גולן",
    "גזר",
    "גילה",
    "גלבוע",
    "גלגל צור יגאל",
    "גלעד",
    "גמלא",
    "גריזים",
    "גרניט צור יגאל",
    "דבורה",
    "דביר",
    "דולב",
    "דור",
    "דותן",
    "דיה",
    "דישון",
    "דליה",
    "דליות",
    "דלתון",
    "דן",
    "דפנה",
    "דפנה צור יגאל",
    "דקלה",
    "דרגות",
    "דרור צור יגאל",
    "דרך הגבעה",
    "דרך הפרחים",
    "האילנות צור יגאל",
    "האירוס צור יגאל",
    "האיריס",
    "הברקן",
    "הגומא",
    "הדודאים",
    "הדס",
    "ההר",
    "הורד",
    "הים",
    "היסמין",
    "הכלנית",
    "הכנרות",
    "הכרכום",
    "הלוטם",
    "המדבר",
    "המישר",
    "המכתש",
    "הנגב",
    "הנורית",
    "הנרקיס",
    "העמק",
    "הערבה",
    "הר נבו צור יגאל",
    "הרדוף צור יגאל",
    "הרודיון",
    "הרכסים צור יגאל",
    "השומרון",
    "השחף צור יגאל",
    "השפלה",
    "השרון",
    "זויתן צור יגאל",
    "זמיר צור יגאל",
    "חלוקי נחל צור יגאל",
    "חלמיש צור יגאל",
    "חצור",
    "חרוב צור יגאל",
    "טופז צור יגאל",
    "יהלום צור יגאל",
    "יסמין צור יגאל",
    "יערה צור יגאל",
    "ירושלים",
    "כרמל צור יגאל",
    "לב הישוב",
    "ליבנים צור יגאל",
    "לכיש",
    "לשם צור יגאל",
    "מורן צור יגאל",
    "מצדה",
    "מצפה ספיר צור יגאל",
    "מרגלית צור יגאל",
    "נוף הרים צור יגאל",
    "נופר צור יגאל",
    "נחשון צור יגאל",
    "נטפים צור יגאל",
    "ניצנה",
    "סוסיא",
    "סייפן צור יגאל",
    "סיתוונית צור יגאל",
    "סנונית צור יגאל",
    "ספיר צור יגאל",
    "עגור צור יגאל",
    "עובדת צור יגאל",
    "עמק דותן צור יגאל",
    "עמק האלה צור יגאל",
    "ענתות צור יגאל",
    "עפרוני צור יגאל",
    "עצמון צור יגאל",
    "פסגות צור יגאל",
    "צור יגאל",
    "צור צור יגאל",
    "ציפורי",
    "צרעה צור יגאל",
    "קדרון צור יגאל",
    "קורנית צור יגאל",
    "קיסריה",
    "רותם צור יגאל",
    "רקפת צור יגאל",
    "שד הדקלים צור יגאל",
    "שדרות הארץ",
    "שוהם צור יגאל",
    "שורק צור יגאל",
    "שושן צחור צור יגאל",
    "שיטה צור יגאל",
    "שניר צור יגאל",
    "שקד צור יגאל",
    "תבור צור יגאל",
  ],
  "כוכב יעקב": [
    "אבני חפץ",
    "אגן הסהר",
    'אדמור"י בעלז',
    'אדמור"י צאנז',
    "אהבת אמת",
    "אהבת ישראל",
    "אהבת שלום",
    "אוהל דוד",
    "אור חדש",
    "אמרי חיים",
    "אפיקי מים",
    "בית יוסף",
    "גבעת הברכה",
    "גינת אגוז",
    "דבש וחלב",
    "דרך כוכב יעקב",
    "הר המור",
    'הרמב"ן',
    "חגווי הסלע",
    "חזון איש",
    "חמדת יצחק",
    "חסדי אבות",
    "חפץ חיים",
    'חת"ם סופר',
    "טיילת ערוגות הבושם",
    "טירת כסף",
    "טל אורות",
    "יפה נוף",
    "כוכב יעקב",
    "לב בנים",
    "מאור השמש",
    "מבשר טוב",
    "מים חיים",
    "מנחת אהרון",
    "מעייני הישועה",
    "מעלה בית אל",
    "מעלות החוכמה",
    "מקור ברוך",
    "משכנות הרועים",
    "נהר שלום",
    "נתיב אלעזר",
    "עטרת צבי",
    "עצי הלבנון",
    "פרדס רימונים",
    "פרי מגדים",
    "צמח צדיק",
    "צמח צדק",
    "קדושת לוי",
    "קהילות יעקב",
    "קול התור",
    "שד אביר יעקב",
    "שיבת ציון",
    "שירה חדשה",
    "שכ שומר אמונים",
    "שכ שיר השירים",
    "שלום בנייך",
    "שמחת עולם",
    "תורה מציון",
    "תל ציון",
  ],
  "כוכב מיכאל": [
    "הגפן",
    "הדקל",
    "הזית",
    "הכרם",
    "העינב",
    "הצבר",
    "הרימון",
    "השקד",
    "התאנה",
    "התבואה",
    "התמר",
    "זמורה",
    "כוכב מיכאל",
    "כיכר הראשונים",
    "סמדר",
    "שכ הגפן",
    "שכ הרימון",
    "שכ התאנה",
    "שריג",
  ],
  כורזים: ["חות ורד הגליל", "כורזים", "מעוף"],
  כחל: ["כחל"],
  כחלה: ["כחלה"],
  כיסופים: ["בית ראשון במולדת", "כיסופים"],
  כישור: ["כישור", "כישורית"],
  כליל: ["כליל"],
  כלנית: ["כלנית"],
  כמאנה: ["כמאנה"],
  כמהין: ["בית ראשון במולדת", "כמהין"],
  כמון: [
    "הגבעה הצפונית",
    "הגת",
    "הזריחה",
    "החורש",
    "המצוק",
    "המצפור",
    "המרעה",
    "הראשונים",
    "כמון",
    "כרמי זיתים",
    "מגדל המים",
    "נוף ים",
    "פרשת מים",
    "שעון השמש",
  ],
  כנות: ["כנות"],
  כנף: ["כנף"],
  "כנרת (מושבה)": [
    "גבעת הזיתים",
    "האומנים",
    "הבזלת",
    "הגורן",
    "הכלניות",
    "המייסדים",
    "הראשונים",
    "חוף הירדן",
    "כנרת (מושבה)",
    "נוף הים",
    "רחל",
  ],
  "כנרת (קבוצה)": ["כנרת (קבוצה)"],
  כסיפה: [
    "כסיפה",
    "שכ 20",
    "שכ 30",
    "שכ 40",
    "שכ 80",
    "שכ 11",
    "שכ 21",
    "שכ 31",
    "שכ 41",
    "שכ 12",
    "שכ 22",
    "שכ 32",
    "שכ 42",
    "שכ 13",
    "שכ 23",
    "שכ 33",
    "שכ 43",
    "שכ 14",
    "שכ 24",
    "שכ 15",
    "שכ 25",
    "שכ 45",
    "שכ 16",
    "שכ 36",
    "שכ 46",
    "שכ 17",
    "שכ 18",
    "שכ 19",
  ],
  כסלון: ["כסלון"],
  "כסרא-סמיע": [
    "כסרא",
    "כסרא-סמיע",
    "כפר סמיע",
    "רח 10",
    "רח 1310",
    "רח 1410",
    "רח 710",
    "רח 1910",
    "רח 1",
    "רח 1001",
    "רח 2001",
    "רח 101",
    "רח 201",
    "רח 1201",
    "רח 401",
    "רח 501",
    "רח 1501",
    "רח 601",
    "רח 1701",
    "רח 1801",
    "רח 1901",
    "רח 11",
    "רח 1311",
    "רח 1411",
    "רח 611",
    "רח 1911",
    "רח 21",
    "רח 19121",
    "רח 191221",
    "רח 51721",
    "רח 19131",
    "רח 5041",
    "רח 17041",
    "רח 51641",
    "רח 86551",
    "רח 5161",
    "רח 5171",
    "רח 2091",
    "רח 19091",
    "רח 2",
    "רח 1002",
    "רח 2002",
    "רח 102",
    "רח 1102",
    "רח 202",
    "רח 402",
    "רח 602",
    "רח 702",
    "רח 1702",
    "רח 1802",
    "רח 1902",
    "רח 12",
    "רח 1312",
    "רח 1912",
    "רח 19122",
    "רח 1032",
    "רח 19132",
    "רח 51642",
    "רח 86552",
    "רח 5162",
    "רח 5172",
    "רח 3",
    "רח 1003",
    "רח 2003",
    "רח 103",
    "רח 1203",
    "רח 403",
    "רח 1503",
    "רח 703",
    "רח 1703",
    "רח 1803",
    "רח 1903",
    "רח 13",
    "רח 713",
    "רח 1913",
    "רח 19133",
    "רח 86553",
    "רח 5163",
    "רח 4",
    "רח 104",
    "רח 1104",
    "רח 204",
    "רח 1204",
    "רח 504",
    "רח 1504",
    "רח 604",
    "רח 1704",
    "רח 1904",
    "רח 14",
    "רח 514",
    "רח 86554",
    "רח 5164",
    "רח 5",
    "רח 105",
    "רח 1105",
    "רח 205",
    "רח 1205",
    "רח 405",
    "רח 1405",
    "רח 505",
    "רח 1705",
    "רח 1805",
    "רח 1905",
    "רח 15",
    "רח 1315",
    "רח 515",
    "רח 615",
    "רח 86555",
    "רח 6",
    "רח 1006",
    "רח 106",
    "רח 1106",
    "רח 206",
    "רח 1206",
    "רח 1406",
    "רח 606",
    "רח 1906",
    "רח 516",
    "רח 86556",
    "רח 1007",
    "רח 207",
    "רח 1207",
    "רח 407",
    "רח 1407",
    "רח 707",
    "רח 1907",
    "רח 17",
    "רח 1008",
    "רח 208",
    "רח 1208",
    "רח 1308",
    "רח 1408",
    "רח 508",
    "רח 708",
    "רח 1908",
    "רח 18",
    "רח 209",
    "רח 1409",
    "רח 509",
    "רח 609",
    "רח 709",
    "רח 1909",
    "רח 19",
  ],
  "כעביה-טבאש-חג'אג'רה": [
    "אבו בכר אלסדיק",
    "אבו-נסור",
    "אלבוטמה",
    "אלג'אמע",
    "אלזהור",
    "אלזייתון",
    "אלחנוה",
    "אלטואחין",
    "אלטף",
    "אליאסמין",
    "אלכלה",
    "אלמלעב",
    "אלנח'יל",
    "אלנרג'ס",
    "אלסדרה",
    "אלסודאני",
    "אלסנובר",
    "אלסריס",
    "אלעאסי",
    "אלעין",
    "אלראס",
    "אלרחמה",
    "אלתלה",
    "ח'ראמה",
    "חג'אג'רה",
    "טבאש",
    "כעביה",
    "כעביה-טבאש-חג'אג'רה",
    "עין אם עלי",
    "רח 100",
    "רח 10",
    "רח 110",
    "רח 20",
    "רח 120",
    "רח 130",
    "רח 140",
    "רח 50",
    "רח 150",
    "רח 60",
    "רח 160",
    "רח 70",
    "רח 270",
    "רח 80",
    "רח 180",
    "רח 1",
    "רח 101",
    "רח 11",
    "רח 111",
    "רח 21",
    "רח 121",
    "רח 131",
    "רח 141",
    "רח 51",
    "רח 151",
    "רח 61",
    "רח 161",
    "רח 71",
    "רח 171",
    "רח 81",
    "רח 181",
    "רח 2",
    "רח 102",
    "רח 12",
    "רח 112",
    "רח 22",
    "רח 122",
    "רח 132",
    "רח 142",
    "רח 52",
    "רח 152",
    "רח 62",
    "רח 72",
    "רח 172",
    "רח 82",
    "רח 182",
    "רח 3",
    "רח 103",
    "רח 13",
    "רח 113",
    "רח 123",
    "רח 133",
    "רח 143",
    "רח 53",
    "רח 153",
    "רח 163",
    "רח 73",
    "רח 173",
    "רח 83",
    "רח 4",
    "רח 104",
    "רח 14",
    "רח 114",
    "רח 124",
    "רח 134",
    "רח 154",
    "רח 64",
    "רח 164",
    "רח 74",
    "רח 174",
    "רח 84",
    "רח 105",
    "רח 15",
    "רח 115",
    "רח 125",
    "רח 135",
    "רח 145",
    "רח 55",
    "רח 65",
    "רח 165",
    "רח 75",
    "רח 175",
    "רח 106",
    "רח 16",
    "רח 116",
    "רח 126",
    "רח 136",
    "רח 146",
    "רח 56",
    "רח 156",
    "רח 66",
    "רח 76",
    "רח 176",
    "רח 86",
    "רח 7",
    "רח 107",
    "רח 17",
    "רח 117",
    "רח 127",
    "רח 137",
    "רח 147",
    "רח 57",
    "רח 157",
    "רח 67",
    "רח 167",
    "רח 77",
    "רח 177",
    "רח 8",
    "רח 108",
    "רח 18",
    "רח 118",
    "רח 128",
    "רח 138",
    "רח 148",
    "רח 58",
    "רח 158",
    "רח 68",
    "רח 168",
    "רח 78",
    "רח 178",
    "רח 9",
    "רח 109",
    "רח 19",
    "רח 119",
    "רח 129",
    "רח 139",
    "רח 149",
    "רח 59",
    "רח 159",
    "רח 69",
    "רח 169",
    "רח 79",
    "רח 179",
    "רח 199",
  ],
  "כפר אביב": ["הגפן", "המייסדים", "התאנה", "התמר", "כפר אביב"],
  "כפר אדומים": [
    "ארץ בנימין",
    "ארץ הצבי",
    "ארץ חיטה",
    "בורות המים",
    "גבול יהודה",
    "דבש התאנה",
    "דרך אלון",
    "דרך החיים",
    "דרך המדבר",
    "דרך המשמר",
    "דרך הצופים",
    "דרך פרת",
    "האוג",
    "האופק",
    "האשל",
    "הבקעה",
    "הירדן",
    "המייסדים",
    "המעיין",
    "המצוק",
    "הנחל",
    "הסלע",
    "הצבאים",
    "הצור",
    "הקשת",
    "הרותם",
    "השיזף",
    "השיטה",
    "חגווי הסלע",
    "חמדת השקד",
    "כפות תמרים",
    "כפר אדומים",
    "מבוא ירושלים",
    "מי מבוע",
    "מרכז קליטה",
    "משכנות הרועים",
    "משעול החלמיש",
    "מתק חרוב",
    "נוף בראשית",
    "נופי פרת",
    "נחל אשלים",
    "נחל ערוגות",
    "נחל צאלים",
    "סתר המדרגה",
    "עם אחד",
    "ערבות מואב",
    "פלח הרימון",
    "פרי הגפן",
    "צמח בר",
    "שירת העשבים",
    "שכ אלון",
    "שתילי הזית",
  ],
  "כפר אוריה": [
    "ארץ קדם",
    "דרך שמשון",
    "הבז האדום",
    "הגתות",
    "החיוואי",
    "הירגזי",
    "הכחל",
    "יערת הדבש",
    "כפיר אריה",
    "כפר אוריה",
    "כרם השועלים",
    "כרמי תמנתה",
    "מעלה אוריה",
    "סלע עיטם",
    "קציר חיטים",
    "שבט דן",
  ],
  "כפר אחים": ["כפר אחים", "שכ קרוונים"],
  "כפר ביאליק": [
    "אושה",
    "ביאליק",
    "החקלאים",
    "הכלניות",
    "כפר ביאליק",
    "לנדאור",
    "מוסד אהבה",
    "מיכאל",
    "סולד הנריאטה",
    "עמיהוד",
    "שד ירושלים",
  ],
  'כפר ביל"ו': [
    "הגבעה",
    "הדבש",
    "הדקל",
    "ההדרים",
    "הזית",
    "המחרשה",
    "המייסדים",
    "הפעמון",
    "הפקאן",
    "הפרדסים",
    "הפרחים",
    "השעורה",
    "התאנה",
    "חבורת הדרום",
    'כפר ביל"ו',
    "סמ גפן",
    "סמ חיטה",
    "סמ רימון",
    "סמ תמר",
    "שיכון בנים",
    "תלמים",
  ],
  "כפר בלום": ["כפר בלום"],
  "כפר בן נון": ["הגפן", "הרימון", "התאנה", "התמר", "כפר בן נון"],
  "כפר ברא": [
    "אבו עובדיה",
    "אבן ח'לדון",
    "אבן סינא",
    "אבן רושד",
    "אלאקסא",
    "אלבוסתאן",
    "אלביאן",
    "אלג'זאלי",
    "אלהודא",
    "אלוואדי",
    "אלוויאם",
    "אלזהרא",
    "אלזיתון",
    "אלחרם",
    "אלירמוק",
    "אלמדינה",
    "אלמנארה",
    "אלנור",
    "אלסלאם",
    "אלקובה",
    "אלקודס",
    "אלראזי",
    "אלרבאט",
    "אלרוודה",
    "אלרחמה",
    "אלשאם",
    "בגדאד",
    "בדר",
    "חטין",
    "חיפה",
    "יפו",
    "כפר ברא",
    "מכה",
    "עומר אלמוכתאר",
    "עכו",
    "צלאח אלדין",
  ],
  "כפר ברוך": [
    "אגמית",
    "חופית",
    "כפר ברוך",
    "לבנית",
    "מגלן",
    "סייפן",
    "סיקסק",
    "סלעית",
    "עיטם",
    "שקנאי",
  ],
  "כפר גדעון": ["בית ראשון במולדת", "כפר גדעון"],
  "כפר גלים": ["כפר גלים"],
  "כפר גליקסון": ["כפר גליקסון"],
  "כפר גלעדי": ["בית ראשון", "כפר גלעדי", "תל חי"],
  "כפר דניאל": ["כפר דניאל"],
  "כפר האורנים": [
    "אהוד",
    "האופק",
    "האורנים",
    "הגבעה",
    "החורש",
    "היער",
    "המנורה",
    "המעיין",
    "העמק",
    "הפסגה",
    "הרכס",
    "יהודה",
    "יפה נוף",
    "כפר האורנים",
    "נחל מודיעין",
  ],
  "כפר החורש": [
    "אילה",
    "אפיק",
    "אשחר",
    "גורדוניה",
    "דקל",
    "זמיר",
    "חצב",
    "כלנית",
    "כפר החורש",
    "כרכום",
    "לאן",
    "מגורונים",
    "מהלך",
    "מכבי הצעיר",
    "סנונית",
    "עופר",
    "עמיעז",
    "ענבר",
    "צבר",
    "קינן",
    "רימון",
    "שש",
    "תוכי נשר",
    "תרצה",
  ],
  "כפר המכבי": ["כפר המכבי"],
  "כפר הנגיד": [
    "האלה",
    "האלון",
    "הארז",
    "הדולב",
    "הזית",
    "החרוב",
    "הרותם",
    "השיזף",
    "השיקמה",
    "כפר הנגיד",
  ],
  "כפר הנוער הדתי": ["כפר הנוער הדתי"],
  "כפר הנשיא": ["כפר הנשיא"],
  "כפר הס": [
    "ארגון יזרעאל",
    "דרך ההרחבה",
    "דרך ההתיישבות",
    "דרך השדות",
    "האסיף",
    "הבוסתן",
    "הבציר",
    "הבריכה",
    "הגורן",
    "ההדרים",
    "הזיתים",
    "הכרם",
    "המחרשה",
    "העמדה",
    "הקוצר",
    "הקטיף",
    "השדרה",
    "כפר הס",
  ],
  'כפר הרא"ה': [
    "בית חזון",
    "דרך תורה ועבודה",
    "הישיבה",
    "העמק",
    "הראשונים",
    "הרב צוקרמן",
    "חזון",
    "ישיבת בני עקיבא",
    'כפר הרא"ה',
    "משעול הגבעה",
  ],
  'כפר הרי"ף': [
    "האלון",
    "הזית",
    "הרחבה",
    "הרימון",
    "השקד",
    "התמר",
    'כפר הרי"ף',
    "סמטת החרוב",
    "סמטת התאנה",
    "שדרת האילנות",
  ],
  "כפר ויתקין": [
    "אכסנית נוער",
    "דרך בית הראשונים",
    "דרך האי",
    "דרך הבאר",
    "דרך החול",
    "דרך הכפר",
    "דרך הנחל",
    "דרך נוף הים",
    "הדואר",
    "הדוגית",
    "הזנב",
    "הלגונה",
    "המגדלור",
    "המזח",
    "המחלבה",
    "המפרש",
    "הסיפון",
    "הסירה",
    "העוגן",
    "הצדף",
    "הקונכיה",
    "השונית",
    "כביש עוקף דרום",
    "כפר ויתקין",
    "משעול גנים",
    "משעול הפרדסים",
    'סמ הכ"ג',
    "סמ המרפאה",
  ],
  "כפר ורבורג": [
    "הבאר",
    "הבנים",
    "הגן",
    "ההרחבה",
    "הזורעים",
    "המחלבה",
    "המחרשה",
    "המייסדים",
    "הממשיכים",
    "העליה",
    "כפר ורבורג",
    "סמ האפרסמון",
    "סמ האפרסק",
    "סמ הגפן",
    "סמ הדגן",
    "סמ הזית",
    "סמ החיטה",
    "סמ החרוב",
    "סמ הרימון",
    "סמ השיבולת",
    "סמ השעורה",
    "סמ התאנה",
    "סמ התבואה",
    "סמ התפוח",
    "שכ קרוונים",
  ],
  "כפר ורדים": [
    "אדיר",
    "אירוס",
    "אלמוג",
    "ארבל",
    "אשכר",
    "בשור",
    "גומא",
    "גל",
    "גלבוע",
    "געתון",
    "גפן",
    "דישון",
    "דפנה",
    "דרור",
    "דרך הגליל",
    "דרך ההר",
    "דרך הורדים",
    "האורן",
    "האלה",
    "האלון",
    "הארז",
    "הדולב",
    "הדס",
    "החצב",
    "החרוב",
    "הרדוף",
    "זויתן",
    "זית",
    "זמיר",
    "חבצלת",
    "חוחית",
    "חיטה",
    "חירם",
    "חרמון",
    "חרצית",
    "יערה",
    "יקינטון",
    "ירדן",
    "כזיב",
    "כפר ורדים",
    "כרמל",
    "לבונה",
    "לוטם",
    "מור",
    "מורן",
    "מירון",
    "מרווה",
    "משעול הכלנית",
    "משעול הכרכום",
    "משעול הנורית",
    "משעול הנרקיס",
    "משעול הסחלב",
    "משעול הרקפת",
    "משעול ורד",
    "נעמן",
    "סער",
    "עין גדי",
    "עפרוני",
    "ערבה",
    "ערוגות",
    "פארן",
    "צאלים",
    "צין",
    "קורנית",
    "רותם",
    "ריחן",
    "רימון",
    "שונית",
    "שמיר",
    "שניר",
    "שקד",
    "תאנה",
    "תבור",
    "תמר",
  ],
  "כפר זוהרים": ["כפר זוהרים"],
  "כפר זיתים": ["כפר זיתים"],
  'כפר חב"ד': [
    "אגרות קודש",
    'אדמו"ר האמצעי',
    'אדמו"ר הזקן',
    'אדמו"ר הצמח-צדק',
    'אדמו"ר הריי"צ',
    'אדמו"ר הרש"ב',
    'אדמו"ר מהר"ש',
    "אור התורה",
    "אמרי בינה",
    "באתי לגני",
    "דבר מלכות",
    "דידן נצח",
    "דרך חיים",
    "דרך מצותיך",
    "הבעל שם טוב",
    "הגאולה",
    "המגיד ממעזריטש",
    "הרב מרדכי ש. אשכנזי",
    "הרב נחום טרבניק",
    "הרב שניאור ז גורליק",
    "הרבי מליובאוויטש",
    "הרבנית חיה מושקא",
    "הרבנית חנה",
    "השליחות",
    "חנוך לנער",
    "כיכר מבצע חינוך",
    "כיכר מבצע צדקה",
    "כיכר מבצע תורה",
    "כיכר מבצע תפילין",
    "כיכר נרות שבת קודש",
    'כפר חב"ד',
    "לוח היום יום",
    "לימוד החסידות",
    "לקוטי דיבורים",
    "לקוטי שיחות",
    "לקוטי תורה",
    "מעלה 770",
    'ס"ו',
    "ספר הזכרונות",
    "ספר התניא",
    'ע"ב',
    "עטרת ראש",
    "עץ החיים",
    'צעירי חב"ד',
    "רבי לוי יצחק",
    "שיכונים חדשים",
    "שכ לוי יצחק",
    "שערי אורה",
    "שערי תשובה",
    "תוחלת",
    "תורת חיים",
    "תורת מנחם",
    "תורת שלום",
    "תורת שמואל",
  ],
  "כפר חושן": ["כפר חושן"],
  "כפר חיטים": [
    "גפן",
    "דובדבן",
    "האגוז",
    "האלה",
    "האסיף",
    "הדקל",
    "הזית",
    "החיטה",
    "החמניה",
    "המגל",
    "הקוצר",
    "השדה",
    "השיזף",
    "חרוב",
    "כפר חיטים",
    "מגדל המים",
    "נבטים",
    "נביעות",
    "רימונים",
    "שיבולים",
    "שעורה",
    "שקד",
    "שקדיה",
    "תאנה",
    "תמר",
    "תנובת השדה",
  ],
  "כפר חיים": ["כפר חיים"],
  "כפר חנניה": ["כפר חנניה"],
  "כפר חסידים א'": [
    "בית קמה",
    "גבורי התהילה",
    "המייסדים",
    "הפועל המזרחי",
    "התמר",
    "כפר חסידים א'",
    "נחל קדומים",
    "נחלת יעקב",
    "עבודת ישראל",
    "עלית הנוער",
    "ש המזרחי",
    "ש משכנות",
    "ש עמידר",
    "ש רסקו",
    "שערי תורה",
    "תל רגב",
  ],
  "כפר חסידים ב'": ["כפר חסידים ב'"],
  "כפר חרוב": ["כפר חרוב"],
  "כפר טרומן": [
    "אקליפטוס",
    "האלה",
    "האלון",
    "הבציר",
    "הברוש",
    "הגפן",
    "הזית",
    "החרוב",
    "העינב",
    "הרחבה",
    "הרימון",
    "התירוש",
    "זמורות",
    "כפר טרומן",
    "שריגים",
  ],
  "כפר יאסיף": ["כפר יאסיף"],
  "כפר ידידיה": [
    "אביחיל",
    "בית האריזה",
    "בני גאולים",
    "דרך המשולש",
    "הבנים",
    "ההדרים",
    "הזורע",
    "המייסדים",
    "הפיקוסים",
    "הפרחים",
    "הפרסה",
    "הרימון",
    "חישתיל",
    "כפר ידידיה",
    "מול הים",
    "על עבודת האדמה",
    "עמק חפר",
    "צבעוני השרון",
    "שער הכפר",
  ],
  "כפר יהושע": ["כפר יהושע"],
  "כפר יונה": [
    "אגוז",
    "אהבת אדם",
    "אהוד מנור",
    "אודם",
    'אח"י אילת',
    "איזמרגד",
    "אילות",
    "אלי כהן",
    "אלמוגן",
    "אלפסי",
    "ארבל",
    "ארגמן",
    "אריאל שרון",
    "אשוח",
    "אשל",
    "בזלת",
    "בית סוכנות",
    "בנה ביתך א",
    "בנטל",
    "ברקת",
    "גבורי ישראל",
    "גבעת האלונים",
    "גבעתי",
    "גולני",
    "גורדון",
    "גזית",
    "גילה",
    "גן",
    "גרניט",
    "דוד בן גוריון",
    "דולב",
    "דן",
    "דפנה",
    "האהבה",
    "האומץ",
    "האור",
    "האורן",
    "האושר",
    "האחדות",
    "האחווה",
    "האיריס",
    "האלה",
    "האלון",
    "הארזים",
    "האתרוג",
    "הברוש",
    "הבשן",
    "הגולן",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הגפן",
    "הדודאים",
    "הדקל",
    "ההגנה",
    "ההדס",
    "ההדרים",
    "ההודיה",
    "הורדים",
    "הזית",
    "החבצלת",
    "החוסן",
    "החצב",
    "החרוב",
    "החרצית",
    "הידידות",
    "היסמין",
    "הירדן",
    "הירמוך",
    "הירקון",
    "הכלנית",
    "הכרמל",
    'הל"ה',
    "הלוטם",
    "המייסדים",
    "המרגנית",
    "המשאלות",
    "הנגב",
    "הנרקיס",
    "הסביון",
    "העוצמה",
    "העליה",
    "העמק",
    "העצמאות",
    "הערבה",
    "הפקאן",
    "הפריחה",
    "הצבר",
    "הרב קוק",
    "הרדוף",
    "הרוגע",
    "הרימון",
    "הרעות",
    "הרצל",
    "הרקפת",
    "השומרון",
    "השושנים",
    "השיטה",
    "השלווה",
    "השמחה",
    "השפע",
    "השקד",
    "השרון",
    "התאנה",
    "התמר",
    "התעשיה",
    "ויצמן",
    "חושן",
    "חנה רובינא",
    "חרמון",
    "טופז",
    "יהודה הלוי",
    "יחזקאל",
    "יפתח",
    "יקינטון",
    "ככר בר כוכבא",
    "כנען",
    "כפר יונה",
    "לב הכפר",
    "מוצקין",
    "מוריה",
    "מורן",
    "מירון",
    "מרכז קליטה",
    "נבו",
    "נווה ניצנים",
    "נחל בשור",
    "נחל גלים",
    "נחל געתון",
    "נחל דליות",
    "נחל דלתון",
    "נחל זוויתן",
    "נחל חלמיש",
    "נחל חרוד",
    "נחל כזיב",
    "נחל מישר",
    "נחל משושים",
    "נחל נקרות",
    "נחל ערוגות",
    "נחל פולג",
    "נחל צין",
    "נחל קישון",
    "נחל קמה",
    "נחל רודד",
    "נחל שגיא",
    "נחל שובל",
    "נחל שורק",
    "נחל תנינים",
    "נעמי שמר",
    "סוקולוב",
    "סיגלון",
    "סייפן",
    "סמ דן",
    "סמ נורית",
    "ספיר",
    "עולי הגרדום",
    'פלמ"ח',
    "צאלון",
    "צבעוני",
    "צור",
    "צלף",
    "צמרות השרון",
    "צנחנים",
    "רבי אלימלך",
    "רותם",
    "רזיאל",
    'רמב"ם',
    "ש חדש",
    "שד הדקלים",
    "שד מנחם בגין",
    "שד פישר מוריס",
    "שד רבין",
    "שהם",
    "שושנה דמארי",
    "שיזף",
    "שייקה אופיר",
    "שכ אלי כהן",
    "שכ המושבה",
    "שכ קרן היסוד",
    "שכון א",
    "שכון ב",
    "שכון טרומי",
    "שקמה",
    "שרת",
    "תבור",
    "תורמוס",
    "תותחנים",
  ],
  "כפר יחזקאל": ["העיגול", "הרחבה", "הרכבת", "כפר יחזקאל", "מנחם"],
  "כפר יעבץ": [
    "דרך הגבעה",
    "דרך הגת",
    "דרך הנירים",
    "האשל",
    "הבאר",
    "הבוסתן",
    "הגורן",
    "החורש",
    "הכרמים",
    "הנחל",
    "הפרדסים",
    "הפרחים",
    "הראשונים",
    "כפר יעבץ",
  ],
  "כפר כמא": [
    "אבזאך",
    "אדיגה",
    "אה נאפה",
    "אושחה מאפ",
    "אזאת",
    "אזה",
    "אלברוס",
    "אנה",
    "אפאס",
    "אפיפס",
    "אפץ'",
    "באשתאש",
    "באתר",
    "בז'אדור'",
    "בלאנה",
    "בלם",
    "בסלאנאי",
    "ג'אמיה",
    "גואשה",
    "גומאף",
    "גונס",
    "גורת",
    "דאה",
    "דאנה",
    "דאריי",
    "דה'ווס",
    "וואר'ה",
    "ווביח",
    "ווס",
    "ווספס",
    "וופש",
    "זאפאצה",
    "זאפה",
    "חאם רוג",
    "חאן רוג",
    "חאנל'אשה",
    "חאצ'ה",
    "חאקוץ",
    "חתוקאי",
    "טוואפסה",
    "יג'ירקואי",
    "כיכר ביברס",
    "כיכר קטז",
    "כפר כמא",
    "ל'אמיג'",
    "לאר'ה נאק'ה",
    "מאז דוג",
    "מאזה",
    "מאזה נף",
    "מאיקופ",
    "מאמחיש",
    "מאמר",
    "מאפה",
    "מארסה",
    "מארקאווא",
    "מזנף",
    "מזפס",
    "נאבזה",
    "נאואס",
    "נאלמס",
    "נאלצ'יק",
    "נאף",
    "נאפה",
    "נארת",
    "נאשה",
    "נגוף",
    "נסה",
    "נפנה",
    "נרזאן",
    "נשאן",
    "נתכואי",
    "סאיה",
    "סאנה",
    "סווסרק'וואה",
    "ספסף",
    "פאסה",
    "פאשה",
    "פחאצ'אצ'",
    "פכאח רוג",
    "פכה צ'אסה",
    "פסא",
    "פסארש",
    "פסוור",
    "פסנה",
    "פסנפש",
    "פשא",
    "פשינה",
    "צ'אם רוג",
    "צ'ארקה",
    "צ'מגוי",
    "ציה",
    "קברטאי",
    "קפצ'ה",
    "רוגזה",
    "שאבזה",
    "שאוו",
    "שאפסור'",
    "שוו",
    "תאמה",
    "תאמה רידז",
    "תיר'ה",
    "תמר",
  ],
  "כפר כנא": [
    "אזור המועצה",
    "אזור התעשייה",
    "אל ביאדה",
    "אל ג'מאג'יה",
    "אלבסאתין",
    "אלח'לה",
    "אלח'מריה",
    "אלמחדר",
    "אלמסאלה",
    "אלמשוח",
    "אלנקארה",
    "אלעין",
    "אם דלהם",
    "בי\"ס יסודי ג'",
    "ג'בל ח'ויח'ה",
    "ג'פתא",
    "העיר העתיקה(אלבלד)",
    "ואדי זיד",
    "וערת סעד",
    "כפר כנא",
    "כרום אלרישה",
    "כרם אראס",
    "ענטר",
    "פוק אל עין",
    "שכונת בנה ביתך",
  ],
  "כפר מונש": ["כפר מונש"],
  "כפר מימון": ["כפר מימון"],
  'כפר מל"ל': [
    "בן גמלא יהושע",
    "דרך השרון",
    "האלונים",
    "הבנים",
    "הדרים",
    "הזית",
    "הלימון",
    "המיסדים",
    "המנצח",
    "הפרחים",
    "הצנחנים",
    'כפר מל"ל',
    "מוסד יד אסא",
    "עין חי",
    "שרת",
  ],
  "כפר מנדא": [
    "אלבלדה אלקדימה",
    "אלבסאתין",
    "אלח'לאילה",
    "אלחואשנה",
    "אלמאר",
    "אלמג'לס אל קדים",
    "אלמע'ור",
    "אלמעברה",
    "אלמעסרה",
    "אלנבעה",
    "אלע'רביה",
    "אלעוזיר",
    "אלשאריע אלראיסי",
    "אלתין",
    "זיתון מזרחית",
    "זערורה",
    "טריק עראבה",
    "כפר מנדא",
  ],
  "כפר מנחם": ["כפר מנחם", "צפית"],
  "כפר מסריק": ["כפר מסריק"],
  "כפר מצר": ["כפר מצר"],
  "כפר מרדכי": [
    "דפנה",
    "הבוסתן",
    "הבריכה",
    "הדס",
    "הכרם",
    "הלילך",
    "המטע",
    "הפרדס",
    "השדה",
    "כפר מרדכי",
    "לבונה",
    "מעלה המייסדים",
    "מרווה",
    "קורנית",
    "ריחן",
  ],
  "כפר נטר": [
    "האורן",
    "האלה",
    "האלון",
    "ההדרים",
    "החרוב",
    "הרותם",
    "השיטה",
    "השלום",
    "כפר נטר",
  ],
  "כפר סאלד": ["בית ראשון במולדת", "כפר סאלד"],
  "כפר סבא": [
    "אביגיל",
    "אבידן דוד",
    "אבן גבירול",
    "אבן עזרא",
    "אבן שפרוט",
    "אברבנאל",
    "אברוצקי",
    "אגרון",
    "אדם יקותיאל",
    "אהרונוביץ",
    "אוסטרובסקי",
    "אוסטשינסקי",
    "אורט",
    'אז"ר',
    "אזור המלאכה והתעשיה",
    "אזור התעשיה",
    "אזור מלאכה",
    'אח"י אילת',
    'אח"י דקר',
    "אחד העם",
    "אחוה",
    "איינשטיין",
    "אימבר",
    "אירגוני המתנדבים",
    "אירוס",
    "אלחריזי",
    "אליהו התשבי",
    "אלישבע",
    "אלישע",
    "אלעזר דוד",
    "אלקלעי",
    "אלתרמן",
    "אמנון ותמר",
    "אנגל",
    "אנה פרנק",
    "אנטק",
    "אנילביץ",
    "אנציקובסקי",
    "אסירי ציון",
    'אצ"ג',
    "ארבל",
    "ארגוב סשה",
    "ארלוזורוב",
    "אש שלום",
    "אשכול לוי",
    "בוסל",
    "בוסתן אבו סניני",
    "בורוכוב",
    'בי"ח מאיר',
    "ביאליק",
    'ביל"ו',
    "בילינסון",
    "בית ונוף",
    "בלבן",
    "בלום ליאון",
    "בלפור",
    "בלפר זאב",
    'בן אב"י איתמר',
    "בן גוריון",
    "בן יהודה",
    "בנאי יוסי",
    "בני ברית",
    "בקעת באר שבע",
    "בקעת בית נטופה",
    "בקעת הירח",
    "בקעת זוהר",
    "בקעת יבנאל",
    "בקעת יריחו",
    "בקעת סיירים",
    "בקעת עובדה",
    "בקעת צין",
    "בקעת רמון",
    "בר אילן",
    "ברדיצ'ב אבא",
    "ברוש",
    "ברנר",
    "ברק",
    "ברקן",
    "ברש אשר",
    "בת יפתח",
    "בת שבע",
    "בתי חבס",
    "ג'ו עמר",
    "גאולה",
    "גבורות",
    "גבורי ישראל",
    "גביש",
    "גבעולים",
    "גבעת אשכול",
    "גבעתי",
    "גד הנביא",
    'גדנ"ע',
    "גדעון",
    "גובר רבקה",
    "גולד זאב",
    "גולדברג לאה",
    "גולדשטין",
    "גולומב",
    "גורדון",
    "גיסות השריון",
    "גלבוע",
    "גלגלי פלדה",
    "גלעד",
    "גלר זאב",
    "גן השרון",
    "גני השרון",
    "גרוסמן חייקה",
    "גרץ",
    "דב לאוטמן",
    "דבורה הנביאה",
    "דוד המלך",
    "דודאים",
    "דוכיפת",
    "דולב",
    "דיין משה",
    "דינור",
    "דליה",
    "דמארי שושנה",
    "דניאל",
    "דקל",
    "דרך אפולוניה",
    "דרך אפק",
    "דרך הים",
    "דרך המוביל",
    "דרך המלך",
    "דרך הפועל",
    "דרך השרון",
    "דרך קדומים",
    "האגוז",
    "האורנים",
    "האחדות",
    "האילנות",
    "האלה",
    "האמוראים",
    "האמת",
    "האקליפטוס",
    "הארזים",
    "האשכולית",
    "האשל",
    "האתרוג",
    "הבנים",
    "הבריגדה",
    "הגדוד העברי",
    "הגולן",
    "הגליל",
    "הגנה",
    "הגפן",
    'הגר"א',
    "הדר",
    "ההסתדרות",
    "ההשכלה",
    "הוז דב",
    "הורביץ אלי",
    "הושע",
    "הזית",
    "הזמר",
    "החורשה",
    'החי"ל',
    'החי"ש',
    "החן",
    "החרוב",
    "החרושת",
    "החרמון",
    "הטחנה",
    "הידיד",
    "הידידות",
    "היוזמה",
    "היוצרים",
    "הירדן",
    "הכובשים",
    "הככר",
    "הכלנית",
    "הכנסת",
    "הכרם",
    "הכרמל",
    'הל"ה',
    "הלימון",
    "הלפיד",
    "המגשימים",
    "המחתרות",
    "המיסדים",
    "המלכים",
    "המנופים",
    "המסגר",
    "המעלות",
    "המעפילים",
    "המפלס",
    "המשורר יצחק",
    "המשטרה",
    "הנביאים",
    "הנגב",
    "הנגר",
    "הנדל נחמה",
    "הס",
    "הסדנא",
    "הסנהדרין",
    "העבודה",
    "העגורים",
    "העליה השניה",
    "העמק",
    "העצמאות",
    "הפועל הצעיר",
    "הפורצים",
    "הפרדס",
    "הפרחים",
    "הפרסה",
    "הצבעוני",
    "הצבר",
    "הצדק",
    "הצומת",
    "הצנחנים",
    "הקשת",
    "הר אדום",
    "הר אדיר",
    "הר בוקר",
    "הר גריזים",
    "הר חזון",
    "הר חריף",
    "הר כמון",
    "הר כנען",
    "הר כרכום",
    "הר מירון",
    "הר נבו",
    "הר עצמון",
    "הר צין",
    "הר רביד",
    "הר רותם",
    "הר שוקף",
    "הר תבור",
    "הראשונים",
    "הרב אסף שמחה",
    "הרב עמיאל",
    "הרב קוק",
    "הרדוף",
    "הרימון",
    "הרמה",
    "הרעות",
    "הרצוג",
    "הרצל",
    "הרצפלד אברהם",
    "הרקפת",
    "השומר",
    "השופטים",
    "השחר",
    "השיטה",
    "השילוני",
    "השיר",
    "השלום",
    "השפלה",
    "השקדים",
    "השקמה",
    "השרון",
    "התאנה",
    "התוכנה",
    "התותחנים",
    "התחיה",
    "התמר",
    "התנאים",
    'התע"ש',
    "התפוז",
    "וולך יונה",
    "ויינברג",
    "וילנסקי משה",
    "ויצמן",
    "וישני",
    "ויתקין",
    "ז'בוטינסקי",
    "זהבי דוד",
    "זטלר",
    "זכריה הנביא",
    "זלדה המשוררת",
    "זליוונסקי",
    "זמיר",
    "זמנהוף",
    'חב"ד',
    "חבצלת השרון",
    "חבקוק",
    "חברון",
    "חגי",
    "חובבי ציון",
    "חוחית",
    "חולדה הנביאה",
    "חומה ומגדל",
    "חורגין",
    "חזה עפרה",
    "חטיבת אלכסנדרוני",
    "חטיבת גולני",
    "חטיבת הבקעה",
    'חטיבת הנח"ל',
    "חטיבת הראל",
    "חטיבת יפתח",
    "חיטמן עוזי",
    "חיים",
    "חיימוביץ",
    "חנה",
    "חניתה",
    "חנני יוסף",
    "חסידה",
    "חצרות הדר",
    "חרצית",
    "טהון",
    "טירת צבי",
    "טרומן",
    "טרומפלדור",
    "טשרניחובסקי",
    'י"א הספורטאים',
    "יבנה",
    "יבניאלי",
    "יד חרוצים",
    "ידין יגאל",
    "יהודה",
    "יהודה הלוי",
    "יהודה המכבי",
    "יהודה הנשיא",
    "יהלום",
    "יואל",
    "יוחנן הסנדלר",
    "יונה",
    "יוסף הדר",
    "יחזקאל",
    "יחיעם",
    'יל"ג',
    "ינאי המלך",
    "יסמין",
    "יעל",
    "יערה",
    "יצחק זיקו גרציאני",
    "יציאת אירופה",
    "ירושלים",
    "ירמיהו",
    "ישעיהו",
    "ישראל ישעיהו",
    "כהן",
    "כנפי נשרים",
    "כנרת",
    "כפר סבא",
    "כצנלסון ברל",
    "לב הכפר",
    "לבון פנחס",
    "לבונה",
    "לביא אריק",
    "לובטקין צביה",
    "לוונברג",
    "לוי",
    "לוינסקי",
    "לופבן",
    "לילנבלום",
    "לסל",
    "מאיר אריאל",
    "מאפו",
    "מבצע יואב",
    "מבצע יונתן",
    "מגדלי ים התיכון",
    "מוהר עלי",
    "מוטה גור",
    "מור",
    "מורדי הגטאות",
    "מורן",
    "מזור",
    "מזרחי",
    "מיכה",
    "מיכל",
    "מנור אהוד",
    "מעונות עממים",
    "מעלה האורנים",
    "מענית",
    'מפא"י',
    "מצדה",
    "מרבד הקסמים",
    "מרדכי",
    "מרים",
    "מרכוס",
    "מרכז קליטה ספיר",
    "משעול בית הבד",
    "משעול גיל",
    "משעול האהבה",
    'משעול האר"י',
    "משעול החיים",
    "משעול הסובלנות",
    "משעול הקלע",
    "משעול רשף",
    "משק האוצר",
    "מתחם התחנה המרכזית",
    "נאות דשא",
    "נווה הדרים",
    "נווה סורקיס",
    "נורדאו",
    "נורית",
    "נחום",
    "נחל הדס",
    "נחל קנה",
    "נחליאלי",
    "נחמיה",
    "נחשון",
    "נטר קרל",
    'ניל"י',
    "ניצנים",
    "ניר",
    "נלקין",
    "נעורים",
    "נעמי",
    "נרקיס עוזי",
    "נשר",
    "נתיב האבות",
    "נתן הנביא",
    "סביון",
    "סביוני הכפר",
    "סולד",
    "סוסנובסקי",
    "סוקולוב",
    "סיגלית",
    "סייפן",
    "סיני",
    "סירני",
    "סירקין",
    "סלור",
    "סמ אביבים",
    "סמ אנוש",
    "סמ בז",
    "סמ דיה",
    "סמ הדרור",
    "סמ החושן",
    "סמ מעון",
    "סמ רון",
    "סמ שחף",
    "סמטת אברהם",
    "סמילנסקי",
    "סנה משה",
    "סנש",
    "ספיר",
    "סקיבין",
    "עגנון",
    "עובדיה הנביא",
    "עוגן",
    "עוזיאל",
    "עזרא",
    "עיט",
    "עין גדי",
    "עליה",
    "עמוס",
    "עמל",
    "עמנואל זמיר",
    "עמק אילון",
    "עמק בית שאן",
    "עמק דותן",
    "עמק האלה",
    "עמק החולה",
    "עמק זבולון",
    "עמק חורון",
    "עמק חפר",
    "עמק חרוד",
    "עמק יזרעאל",
    "עמק עירון",
    "עמרמי",
    "עפרוני",
    "עציון",
    "ערבה",
    "ערוגות הבושם",
    "עתירי ידע",
    "פועלי ציון",
    "פיארברג",
    "פינס",
    'פלמ"ח',
    "פנקס",
    "פרוג שמעון",
    "פריבר",
    "פרישמן",
    "פרנקל פאבל",
    "פרץ",
    "פררה אברהם",
    "פשוש",
    "צאלון",
    "צדקיהו",
    'צה"ל',
    "צייטלין",
    "צפניה",
    "קארלינסקי",
    "קבוץ גלויות",
    'קבוצת ל"ג',
    "קדושי בלזן",
    "קדושי קהיר",
    "קדיש לוז",
    "קובנר אבא",
    "קורצ'ק",
    "קלישר",
    "קמינסקי",
    "קפלנסקי",
    "קרית הרצוג",
    "קרית ספיר",
    "קרן אברהם",
    "קרן היסוד",
    "קרן המוסיקה",
    "רוזנבלום יאיר",
    "רוטשילד",
    "רופין",
    "רות",
    "רזיאל דוד",
    "רחבת מיכאל",
    "רחל המשוררת",
    "ריזנבך",
    "ריינס",
    "רייק חביבה",
    "רינגלבלום",
    'רמב"ם',
    "רמז דוד",
    'רמח"ל',
    "רעם",
    "רפפורט",
    'רש"י',
    "ש אלי כהן",
    "ש אליעזר",
    "ש אסבסט קפלן",
    "ש אקרמן",
    "ש גרין",
    "ש דגניה",
    "ש הדרים",
    "ש ותיקים",
    "ש יוספטל",
    "ש כסופים",
    "ש למפרט",
    "ש מאוריציוס",
    "ש מוצקין",
    "ש מזרחי",
    "ש מלל",
    "ש מעוז",
    "ש משכנות",
    "ש עוב ב ציון",
    "ש עולים",
    "ש עליה",
    "ש עמידר",
    "ש עממי",
    "ש פרוגרסיבים",
    "ש קפלן",
    "ש תקומה",
    "שאול המלך",
    'שב"ד',
    "שבזי",
    "שד בגין מנחם",
    "שד גאולים",
    'שד"ל',
    "שדה יצחק",
    "שדרת המסוק",
    "שולמית",
    "שומרון",
    "שועלי שמשון",
    'שח"ל',
    "שטרן יאיר",
    "שיבת ציון",
    "שיזף",
    "שיינפיין",
    "שילר",
    "שיפר",
    "שכ גאולים",
    "שכ הזמר העברי",
    "שכ החלוצים",
    "שכ הפארק",
    "שכ חצרות הדר",
    "שכ ירוקה",
    "שכ כפר סבא הצעירה",
    "שכ עלומים",
    "שכ פעמוני גליל",
    "שכ רגבים",
    "שכון העובד",
    "שלום עליכם",
    "שלונסקי",
    "שלמה המלך",
    "שמחה הולצברג",
    "שמחוני אסף",
    "שמעוני",
    "שמעיה הנביא",
    "שמר נעמי",
    "שמריהו לוין",
    "שניר",
    "שפרינצק",
    "שרה",
    "שרת משה",
    "ששת הימים",
    "תאשור",
    "תבור",
    "תדהר",
    "תור",
    "תורה ועבודה",
    "תל אביב-יפו",
    "תל חי",
    "תלם",
    "תרי עשר",
  ],
  "כפר סילבר": ["כפר סילבר"],
  "כפר סירקין": [
    "דרך אפק",
    "דרך השדות",
    "האילנות",
    "האירוסים",
    "הבנים",
    "הגפנים",
    "הדקלים",
    "ההרדופים",
    "הורדים",
    "הכלניות",
    "המרגניות",
    "הנרקיסים",
    "הסביונים",
    "הקציר",
    "כפר סירקין",
  ],
  "כפר עבודה": ["כפר עבודה"],
  "כפר עזה": ["כפר עזה"],
  "כפר עציון": ["ישיבת כפר עציון", "כפר עציון"],
  "כפר פינס": ["כפר פינס"],
  "כפר קאסם": [
    "א זהרא",
    "א ראזי",
    "א רחמה",
    "א שאפי",
    "א שוהדא",
    "א- זיתון",
    "א- נוזהה",
    "א- סולטאני",
    "א- רדואן",
    "א- רמלה",
    "א-נור",
    "א-נסר",
    "א-ספא",
    "א-רבאט",
    "א-רוודא",
    "א-רשיד",
    "א-תוובה",
    "אבו בכר",
    "אבו חניפה",
    "אבו עמרן",
    "אבן חזם",
    "אבן חלדון",
    "אבן רושד",
    "אבן תיימייה",
    "אוחוד",
    "אזור שרובו בדואים",
    "אחמד שוויקי",
    "אל אמל",
    "אל אנדלוס",
    "אל אנסאר",
    "אל אסרא",
    "אל אקצא",
    "אל ארד",
    "אל בורק",
    "אל ביאדר",
    "אל בלאד",
    "אל ג'לאני",
    "אל הודא",
    "אל היג'רה",
    "אל וחדה",
    "אל וטן",
    "אל חליל",
    "אל חנדק",
    "אל חנסא",
    "אל חק",
    "אל חרם",
    "אל ירמוכ",
    "אל כראמה",
    "אל מג'דל",
    "אל מג'זרה",
    "אל מדינה",
    "אל מוהאריגון",
    "אל מורוג",
    "אל מחפור",
    "אל מיזאן",
    "אל מנאר",
    "אל מנשייה",
    "אל מרווה",
    "אל ע'זאלי",
    "אל עוודה",
    "אל עקאד",
    "אל פתח",
    "אל קודס",
    "אל קסאם",
    "בדר",
    "בילאל",
    "דיר יאסין",
    "הגרעין הישן",
    "השכונה החדשה",
    "חאלד בן אל וליד",
    "חטין",
    "חיטין",
    "חמזה",
    "חסאן בן תאבת",
    "טאהא",
    "יאפא",
    "כפר קאסם",
    "מאלכ בן אנס",
    "מכה",
    "משפחת עיסא",
    "סלאח א-דין",
    "עבד אל כרים קאסם",
    "עומר בן אל חטאב",
    "עומר בן עבד אל עזיז",
    "עותמן אל עפאן",
    "עלי בן אבו טאלב",
    "צרצור",
    "שכ הבדואים הדרומית",
    "שכ הבדואים המזרחית",
    "תבוכ",
    "תמרינדי",
  ],
  "כפר קיש": [
    "האלה",
    "האלון",
    "הברושים",
    "הגפן",
    "הזית",
    "החורשה",
    "החרוב",
    "המייסדים",
    "השדות",
    "השיזף",
    "השקד",
    "התאנה",
    "התמר",
    "כליל החורש",
    "כפר קיש",
    "נוף תבור",
    "סימטת הגנים",
  ],
  "כפר קרע": [
    "אאיאת",
    "אאל עמראן",
    "אבו אלקאסם אלשאבי",
    "אבו בכר אלסאדיק",
    "אבו הוריירה",
    "אבו זינה",
    "אבו ח'ושום",
    "אבו חניפה",
    "אבו סופיאן",
    "אבו עוביידה",
    "אבו תמאם",
    "אבן ח'לדון",
    "אבן סינאא",
    "אבן רושד",
    "אבן תימייה",
    "אוחוד",
    "אחמד שווקי",
    "אלאבראר",
    "אלאדיב",
    "אלאזדהאר",
    "אלאזהר",
    "אלאח'טל",
    "אלאח'לאס",
    "אלאחבה",
    "אלאחראש",
    "אלאמיר",
    "אלאמירה",
    "אלאמל",
    "אלאנדלס",
    "אלאנואר",
    "אלאסראא",
    "אלאציל",
    "אלאקצה",
    "אלאת'יר",
    "אלאתאר",
    "אלבאבור",
    "אלבאר",
    "אלבדאיה",
    "אלבוסתאן",
    "אלבח'ארי",
    "אלביאדה",
    "אלביאדר",
    "אלביר",
    "אלבלסם",
    "אלבנין",
    "אלבראא",
    "אלברג",
    "אלבשרא",
    "אלג'אמע",
    "אלג'ולאן",
    "אלג'נה",
    "אלגאר",
    "אלגדאוול",
    "אלגדיר",
    "אלגוז",
    "אלגוזלאן",
    "אלגזאלי",
    "אלגנאת",
    "אלדאחיה",
    "אלדחא",
    "אלדסת",
    "אלהודהד",
    "אלואסל",
    "אלוואדי",
    "אלווסיה",
    "אלוורוד",
    "אלוותאק",
    "אלוחדה",
    "אלוסילה",
    "אלוסים",
    "אלועד",
    "אלזהור",
    "אלזהרא",
    "אלזהראא",
    "אלזהרייה",
    "אלזובייר בן אלעואם",
    "אלזינה",
    "אלזיתון",
    "אלזנבק",
    "אלזנג'ביל",
    "אלזעפראני",
    "אלח'דר",
    "אלח'לוד",
    "אלח'רוב",
    "אלחווארנה",
    "אלחור",
    "אלחוריה",
    "אלחכים",
    "אלחכמה",
    "אלחלווה",
    "אלחמאמה",
    "אלחמה",
    "אלחנאן",
    "אלחנין",
    "אלחנסאא",
    "אלחסאד",
    "אלחסון",
    "אלחסנאת",
    "אלט'הר",
    "אלטיוור",
    "אלטלה",
    "אליאסמין",
    "אליקין",
    "אלירמוק",
    "אלכוות'ר",
    "אלכרם",
    "אלכתאב",
    "אללוטוס",
    "אלליל",
    "אלמדארס",
    "אלמהד",
    "אלמוודה",
    "אלמותנבי",
    "אלמזאר",
    "אלמזארע",
    "אלמזע'מתה",
    "אלמח'תאר",
    "אלמטלה",
    "אלמיזאן",
    "אלמיעאד",
    "אלמית'אק",
    "אלמנסור",
    "אלמנסורה",
    "אלמערפה",
    "אלמפאח'ר",
    "אלמקאייל",
    "אלמראעי",
    "אלמרג",
    "אלמרג'אן",
    "אלמרווה",
    "אלמשמש",
    "אלנגאח",
    "אלנגוא",
    "אלנדאא",
    "אלנהאר",
    "אלנהר",
    "אלנוורס",
    "אלנור",
    "אלנזהה",
    "אלנזלה",
    "אלנח'יל",
    "אלנח'לה",
    "אלנסים",
    "אלנסר",
    "אלנעים",
    "אלנעמאן",
    "אלנרג'ס",
    "אלסאלחייה",
    "אלסאפי",
    "אלסבאח",
    "אלסבע",
    "אלסד",
    "אלסד אלעאלי",
    "אלסדאקה",
    "אלסדרה",
    "אלסואניה",
    "אלסונדס",
    "אלסוסן",
    "אלסופסאף",
    "אלסחאבה",
    "אלסחווה",
    "אלסידה אמנה",
    "אלסידה האגר",
    "אלסידה זינב",
    "אלסידה סארה",
    "אלסידה סומייה",
    "אלסכה",
    "אלסלאם",
    "אלסלוא",
    "אלסלמה",
    "אלסנאבל",
    "אלסנדיאני",
    "אלסנובר",
    "אלסקר",
    "אלסראג",
    "אלסרווה",
    "אלסריס",
    "אלעבאס",
    "אלעדאסה",
    "אלעוד",
    "אלעוודה",
    "אלעזבה",
    "אלעין",
    "אלעירק",
    "אלעלם",
    "אלעמור",
    "אלענב",
    "אלענדליב",
    "אלעסר",
    "אלערק",
    "אלפארוק",
    "אלפג'ר",
    "אלפוראת",
    "אלפראבי",
    "אלפרדוס",
    "אלפרזדק",
    "אלפרח",
    "אלפתח",
    "אלצדיק",
    "אלצפא",
    "אלקאדי",
    "אלקבארה",
    "אלקודס",
    "אלקורנפול",
    "אלקטאין",
    "אלקמר",
    "אלראזי",
    "אלרבאט",
    "אלרג'ד",
    "אלרוחה",
    "אלרחיק",
    "אלרחמה",
    "אלריאן",
    "אלריחאן",
    "אלרמאן",
    "אלרסאם",
    "אלרשאד",
    "אלשאם",
    "אלשאפעי",
    "אלשוגעאן",
    "אלשיח'",
    "אלשמס",
    "אלתין",
    "אלתל",
    "אלתנסים",
    "אלתקוא",
    "אם אלגמאל",
    "אם אלנור",
    "אם ח'אלד",
    "אם סליטי",
    "אסמאא בנת אבו בכר",
    "בדר",
    "בורג' אלחמאם",
    "בורקה",
    "ביאדרנא",
    "בין אלדרב",
    "ביסאן",
    "בלאל בן רבאח",
    "ג'ודי",
    "ג'נא",
    "ג'נאת",
    "גאר חראא",
    "גמאל אלדין אלאפגאני",
    "דבת אלמוע'ור",
    "דהרת גבר",
    "דחדאח אבו מורא",
    "הארון אלרשיד",
    "ואדי אלחמאם",
    "וורדה",
    "זריף אלטול",
    "ח'אלד בן אלוליד",
    "ח'ביזה",
    "ח'לאיל אלעביד",
    "ח'ליל אלסכאכיני",
    "ח'לת אלחיזווה",
    "ח'לת אלסקיע",
    "ח'לת זבדה",
    "ח'לת שלח",
    "חאן אלחלילי",
    "חאקורת אלעסלי",
    "חאתם אלטאאי",
    "חוש ברדק",
    "חמזה בן עבד אלמוטלב",
    "חסאן בן תאבת",
    "חסנאא",
    "ט'אהר יאסין",
    "ט'הרת אבו סלימאן",
    "ט'הרת אל סנדחאווי",
    "ט'הרת נח'לה",
    "טאהא חוסיין",
    "טארק בן זיאד",
    "טיבה",
    "יאסמינה",
    "יזיד בן מועאוויה",
    "יפה",
    "כפר קרע",
    "ליאלינא",
    "מוחמד אלפאתח",
    "מוסא בן נוסייר",
    "מכה אלמכרמה",
    "מקטל אבו אללימון",
    "מרג' בן עאמר",
    "מרג'אנה",
    "מרכז הכפר",
    "נדאא אלחק",
    "נוסרין",
    "סג'א",
    "סדר אבו אחמד",
    "סדר אלערוס",
    "סולימאן אלפרסי",
    "סונבולה",
    "סונדס",
    "סוק עוקאד",
    "סטלין",
    "סירין",
    "סלמא",
    "סלסביל",
    "סנאבל",
    "סת אלחבאיב",
    "עאבר אלנזאזה",
    "עאישה בנת אבו בכר",
    "עאר תור",
    "עומר אלמכתאר",
    "עומר בן אלח'טאב",
    "עותמאן בן עפאן",
    "עין אלחלווה",
    "עין ג'אלות",
    "עין וורדה",
    "עלי בן אבי טאלב",
    "עלי חידר זחאלקה",
    "עמאר בן יאסר",
    "עמר בן אלעאס",
    "עספור",
    "עספור אלג'נה",
    "פאטמה אלזהראא",
    "צור",
    "צידא",
    "קבאא אלדאירי",
    "קעקור אלעבסה",
    "קרית החינוך",
    "ראבעה אלעדויה",
    "ראמה",
    "ריחאנה",
    "רפידה",
    "שט אלערב",
    "שקיף זאייד",
    "תופיק אלחכים",
  ],
  "כפר ראש הנקרה": ["כפר ראש הנקרה", "מרכז קליטה"],
  "כפר רוזנואלד (זרעית)": ["כפר רוזנואלד"],
  "כפר רופין": ["כפר רופין"],
  "כפר רות": ["כפר רות"],
  "כפר שמאי": ["כפר שמאי"],
  "כפר שמואל": ["כפר שמואל"],
  "כפר שמריהו": [
    "אגס",
    "ארז",
    "אשל",
    "דולב",
    "דרך אביב",
    "דרך הבאר",
    "דרך הגנים",
    "דרך השדות",
    "דרך רגבים",
    "האורנים",
    "האילנות",
    "הדס",
    "הדקלים",
    "הורדים",
    "הזורע",
    "הזית",
    "החבצלת",
    "החורש",
    "המעפילים",
    "הנוטע",
    "הנוריות",
    "הנרקיסים",
    "הסביון",
    "הצבעונים",
    "הקוצר",
    "הרקפות",
    "התלמים",
    "התמר",
    "כיכר הראשונים",
    "כפר שמריהו",
    "לבנה",
    "נוה אביב",
    "נורדאו",
    "סמ אביב",
    "סמ האורנים",
    "סמ האלון",
    "סמ הברושים",
    "סמ הזורע",
    "סמ החרצית",
    "קרן היסוד",
    "שביל הבנים",
    "שביל הדגנים",
    "שביל החיטה",
    "שביל החצבים",
    "שביל החרוב",
    "שביל היוגב",
    "שביל היסמין",
    "שביל הכורם",
    "שביל הכלניות",
    "שביל המעלות",
    "שביל המערות",
    "שביל הפקאן",
    "שביל הרימונים",
    "שביל השעורה",
    "שביל השקד",
    "שביל התאנים",
    "שביל התפוזים",
    "שיטה",
  ],
  "כפר תבור": [
    "אגוז",
    "אדר",
    "אודם",
    "אורן",
    "אירוס",
    "אלה",
    "אלון",
    "ארז",
    "אשל",
    "בזלת",
    "ביס שדה",
    "בר גיורא",
    "ברוש",
    "ברקן",
    "ברקת",
    "גביש",
    "גדוד העבודה",
    "גזית",
    "גפן",
    "גרניט",
    "דובדבן",
    "דולב",
    "דפנה",
    'האצ"ל',
    "הבריגדה היהודית",
    "הגדוד העברי",
    "הדס",
    "הדר",
    "ההגנה",
    "הזית",
    "החלוץ",
    "החקלאי",
    "היוצר",
    "המגן",
    "המושבה",
    "המייסדים",
    "המעיין",
    "הנוטר",
    "העליה",
    "הפלוגות",
    'הפלמ"ח',
    "הרועה",
    "השומר",
    "התקומה",
    "ורד",
    "זמורה",
    "חובבי ציון",
    "חושן",
    "חיטה",
    "חלמיש",
    "חצב",
    "חרוב",
    "חרושת",
    "חרצית",
    "טופז",
    "יהלום",
    "יערה",
    "כיכר אופיר מקס",
    "כיכר אופירה",
    "כיכר ארליך אשר",
    "כיכר גולדמן דוד",
    "כיכר גנדי",
    "כיכר האלונים",
    "כיכר הברון הירש",
    "כיכר הברון רוטשילד",
    "כיכר הברושים דרום",
    "כיכר הברושים צפון",
    "כיכר הגפנים",
    "כיכר הזיתים",
    "כיכר המועצה",
    "כיכר המורה ענתבי",
    "כיכר הערמונים",
    "כיכר השומר",
    "כיכר השזיפים",
    "כיכר התבור",
    "כיכר זמורה",
    "כיכר ירחן אהרון",
    "כיכר כהן יעקב משה",
    "ככר הברונית רוטשילד",
    "כליל החורש",
    "כלנית",
    "כפר תבור",
    "כרמים",
    "לוטם",
    'לח"י',
    "ליבנה",
    "מורן",
    "מלאכה",
    "מעפילים",
    "מקווה ישראל",
    "מרגנית",
    "מרווה",
    "נוף התבור",
    "נורית",
    "נרקיס",
    "סיגלית",
    "סייפן",
    "סמ היקב",
    "ספיר",
    "עינב",
    "עירית",
    "ענבר",
    "ערבה",
    "פקאן",
    "צאלון",
    "צבעוני",
    "צור",
    "צפצפה",
    "קורנית",
    "רותם",
    "ריחן",
    "רימון",
    "רקפת",
    'שדרות קק"ל',
    "שוהם",
    "שחם",
    "שיזף",
    "שיטה",
    "שיקמה",
    "שכ האלונים",
    "שכ הגפנים",
    "שכ הזיתים",
    "שכ החרובים",
    "שכ המיסדים",
    "שכ הקוטגים",
    "שכ הרימונים",
    "שכ השזיפים",
    "שכ השקדים",
    "שכ התאנים",
    "שכ זמורה",
    "שעורה",
    "שקד",
    "תאנה",
    "תבור",
    "תות",
    "תכלת",
    "תלתן",
  ],
  "כפר תפוח": [
    "אזור תעשייה רימון",
    "האלון",
    "הארז",
    "הדס",
    "הדר",
    "הזית",
    "התמר",
    "יסמין",
    "כפר תפוח",
    "לבונה",
    "רחלים",
    "שבילי הגפן",
  ],
  "כרי דשא": ["כרי דשא"],
  כרכום: ["כרכום"],
  "כרם בן זמרה": [
    "אשכול אלנבי",
    "בציר",
    "גפן",
    "זמורה",
    "כרם בן זמרה",
    "מעלה הכרם",
    "סמדר",
    "עוללות",
    "תירוש",
  ],
  "כרם בן שמן": ["כרם בן שמן"],
  "כרם יבנה (ישיבה)": ["כרם יבנה (ישיבה)"],
  'כרם מהר"ל': ['כרם מהר"ל'],
  "כרם שלום": ["כרם שלום"],
  "כרמי יוסף": [
    "אורן",
    "אלון",
    "ארז",
    "הברוש",
    "הגפן",
    "הורד",
    "הזית",
    "היסמין",
    "הנרקיס",
    "הרימון",
    "התאנה",
    "התמר",
    "כלנית",
    "כרמי יוסף",
    "סיגלית",
    "סמדר",
    "רקפת",
  ],
  "כרמי צור": [
    "אשכול",
    "דרך האבות",
    "דרך שורק",
    "הגפן",
    "הגת",
    "כרמי צור",
    "סמדר",
    "עינב",
    "שכ צור שלם",
    "שריג",
    "תירוש",
  ],
  "כרמי קטיף": [
    "הגפן",
    "הזמורה",
    "היקב",
    "הכרם",
    "הנצר",
    "הסמדר",
    "העינב",
    "השריג",
    "התירוש",
    "כרמי קטיף",
  ],
  כרמיאל: [
    "אביב",
    "אדום החזה",
    "אזור התעשייה",
    "אטד",
    "איה",
    "אילנות",
    "איריס",
    "אלה",
    "אנפה",
    "אנקור",
    "אסיף",
    "ארבל",
    "אשור",
    "אשחר",
    "אשכולות",
    "אשל",
    "בז",
    "בזלת",
    "ביכורים",
    "בירנית",
    "בציר",
    "בשמים",
    "גביש",
    "גבעת מכוש",
    "גבעת רם",
    "גדיד",
    "גדרון",
    "גזית",
    "גמלא",
    "גרניט",
    "גת",
    "דגנית",
    "דוכיפת",
    "דלית",
    "דרור",
    "דרך התעשייה",
    "האומן",
    "האופן",
    "האורג",
    "האורן",
    "הארזים",
    "הבנאי",
    "הברושים",
    "הגורן",
    "הגליל",
    "הגפן",
    "הדס",
    "הדקל",
    "ההגנה",
    "הזית",
    "החבצלת",
    "החורש",
    "החרוב",
    "החרושת",
    "החרמש",
    "החשמל",
    "היוצרים",
    "היזם",
    "הכבאי",
    "המגל",
    "המלאכה",
    "המסגר",
    "המסילה",
    "המצודה",
    "הנגר",
    "הנפח",
    "הסדן",
    "העמק",
    "העסקים",
    "הפלמח",
    "הפסגה",
    "הפרחים",
    "הצפצפות",
    "הר הצופים",
    "הרדוף",
    "הרימון",
    "השוק",
    "השושנים",
    "השותלים",
    "השיש",
    "התאנה",
    "התמר",
    "התעשיה",
    "ורד",
    "זמורה",
    "זמיר",
    "חוחית",
    "חטיבת גבעתי",
    "חטיבת גולני",
    "חטיבת הנגב",
    "חטיבת הראל",
    "חטיבת יפתח",
    "חטיבת כרמלי",
    "חטיבת עציוני",
    "חלמיש",
    "חן",
    "חצור",
    "חריש",
    "חרמון",
    "חרצית",
    "טוחן",
    "טיילת בולטימור",
    "יודפת",
    "יונק הדבש",
    "יונת הבר",
    "יחיעם",
    "יסמין",
    "יסעור",
    "יערה",
    "יקב",
    "יקינתון",
    "כדורי",
    "כלנית",
    "כפר הילדים",
    "כרכום",
    "כרמיאל",
    "לבונה",
    "לבנה",
    "להב",
    "לוטם",
    "לטרון",
    "לילך",
    "מבצע אסף",
    "מבצע בן עמי",
    "מבצע חורב",
    "מבצע יבוסי",
    "מבצע יואב",
    "מבצע מכבי",
    "מבצע נחשון",
    "מבצע עובדה",
    "מגידו",
    "מור",
    "מורד הגיא",
    "מירון",
    "מעלה אורט",
    "מעלה הכרמים",
    "מעלה כמון",
    "מצפה נוף",
    "מרגנית",
    "מרווה",
    "מרכז קליטה",
    "משגב",
    "משעול אנפה",
    "משעול ארז",
    "משעול דולב",
    "משעול היין",
    "משעול הכורם",
    "משעול הסביונים",
    "משעול הסיפן",
    "משעול הרקפות",
    "משעול חצב",
    "משעול יגאל אלון",
    "משעול מורן",
    "משעול נורית",
    "משעול שדמה",
    "משעול שקד",
    "משעול שקנאי",
    "משעול תפן",
    "מתכת",
    "נהר הירדן",
    "נחושת",
    "נחל אל-על",
    "נחל בצת",
    "נחל געתון",
    "נחל דישון",
    "נחל דליה",
    "נחל דלתון",
    "נחל דן",
    "נחל חרוד",
    "נחל יגור",
    "נחל כזיב",
    "נחל נעמן",
    "נחל סער",
    "נחל עמוד",
    "נחל צבעון",
    "נחל צלמון",
    "נחל קלע",
    "נחל שגור",
    "נחל שניר",
    "נחליאלי",
    "נקר",
    "נרקיס",
    "נשר",
    'נתיב הל"ה',
    "נתיב הלוטוס",
    "נתיב הסיירים",
    "סיתוונית",
    "סלעית",
    "סמ נחליאלי",
    "סמדר",
    "סמטת צבעוני",
    "סנונית",
    "עגור",
    "עומר",
    "עין כמונים",
    "עינב",
    "עירית",
    "עפרוני",
    "עצמון",
    "ערבה",
    "פארק אלון",
    "פארק הנוער",
    "פל-ים",
    "פשוש",
    "צאלון",
    'צה"ל',
    "צופית",
    "צור",
    "ציפורי",
    "קטיף",
    "קציר",
    "קרן היסוד",
    "רותם",
    "רמים",
    "רמת רבין",
    "שביל אייר",
    "שביל אלול",
    "שביל הזורעים",
    "שביל חשון",
    "שביל טבת",
    "שביל כסלו",
    "שביל ניסן",
    "שביל סיון",
    "שביל שבט",
    "שביל תמוז",
    "שביל תשרי",
    "שגיא",
    "שד בית הכרם",
    "שד השלום",
    "שד נשיאי ישראל",
    'שד קק"ל',
    "שדמה",
    "שורק",
    "שחם",
    "שחף",
    "שיזף",
    "שיטה",
    "שכ אשכול",
    "שכ האירוסים",
    "שכ הגליל",
    "שכ הדר",
    "שכ המייסדים",
    "שכ הערבה",
    "שכ טנה",
    "שכ מגדים",
    "שכ שגיא",
    "שלדג",
    "שער הגיא",
    "שקנאי",
    "שריג",
    "ששת הימים",
    "תבור",
    "תדהר",
    "תור",
    "תירוש",
  ],
  כרמיה: ["האשכול", "הבציר", "הגפן", "היין", "התירוש", "כרמיה"],
  כרמים: ["כרמים", "מרכז קליטה"],
  כרמל: ["כרמל"],
  לבון: [
    "אודם",
    "אלמוג",
    "ברקת",
    "גביש",
    "הפנינה",
    "טופז",
    "לבון",
    "לשם",
    "ספיר",
    "ענבר",
    "שוהם",
    "שחם",
  ],
  לביא: ["אולפן", "בית ראשון במולדת", "לביא"],
  לבנים: ["לבנים"],
  להב: ["להב"],
  "להבות הבשן": ["אולפן", "בית ראשון במולדת", "להבות הבשן"],
  "להבות חביבה": ["להבות חביבה"],
  להבים: [
    "אגמית",
    "אודם",
    "איריס",
    "אנפה",
    "בארית",
    "בז",
    "בצנית",
    "ברבור",
    "ברכיה",
    "ברקן",
    "ברקת",
    "גבתון",
    "גדרון",
    "גומא",
    "גפן",
    "דגנית",
    "דוכיפת",
    "דרור",
    "דרך אבני החושן",
    "הודן",
    "הרדוף",
    "ורד",
    "ורדית",
    "זוגן",
    "זית",
    "זמיר",
    "זרזיר",
    "חגלה",
    "חוחית",
    "חנקן",
    "חסידה",
    "חרצית",
    "טבלן",
    "טופח",
    "טסית",
    "ינשוף",
    "יסעור",
    "יערה",
    "כוכבן",
    "כוכית",
    "כחול זנב",
    "כחל",
    "לבנית",
    "להבים",
    "לוטם",
    "לילית",
    "מגלן",
    "מדברון",
    "מורן",
    "מרומית",
    "נחל אדוריים",
    "נחל אילון",
    "נחל אפיקים",
    "נחל בוקק",
    "נחל בצת",
    "נחל ברק",
    "נחל בשור",
    "נחל גוברין",
    "נחל גלים",
    "נחל געתון",
    "נחל גרר",
    "נחל דולב",
    "נחל דישון",
    "נחל האלה",
    "נחל הד",
    "נחל זוהר",
    "נחל זויתן",
    "נחל זרחן",
    "נחל חביבה",
    "נחל חברון",
    "נחל חדרה",
    "נחל חצבה",
    "נחל חרמון",
    "נחל טוב",
    "נחל טלה",
    "נחל יבוק",
    "נחל יגור",
    "נחל יהודייה",
    "נחל יסף",
    "נחל יעלים",
    "נחל ירוחם",
    "נחל ירמוך",
    "נחל ירקון",
    "נחל יתיר",
    "נחל כזיב",
    "נחל כלח",
    "נחל כרכום",
    "נחל לכיש",
    "נחל מגדים",
    "נחל מחניים",
    "נחליאלי",
    "נץ",
    "נרקיס",
    "נשר",
    "סבכי",
    "סופית",
    "סייפן",
    "סלעית",
    "סנונית",
    "ספיר",
    "עגור",
    "עדעד",
    "עיט",
    "עין הבשור",
    "עין זיק",
    "עין מור",
    "עין סהרונים",
    "עין עבדת",
    "עין עופרים",
    "עין עקב",
    "עין צין",
    "עין שחק",
    "עפרוני",
    "פנינית",
    "פעמונית",
    "פרפור",
    "פשוש",
    "צבעוני",
    "צלף",
    "קידה",
    "רימון",
    "רקפת",
    "שד תאנה",
    "שד תמר",
    "שדרות חיטה",
    "שדרות שעורה",
    "שוהם",
    "שיטה",
    "שכ המניפה",
    "שכ המרכז",
    "תפוח",
    "תרשיש",
  ],
  לוד: [
    "אבו בקר אל צדיק",
    "אבו חצירא",
    "אברהם אבינו",
    "אברמוביץ שמעון",
    "אגוז",
    "אגס",
    "אדיסון",
    "אדמונית",
    "אוסישקין",
    "אזור תעשיה צפון",
    "אחד העם",
    "אחד עשר ביולי",
    "אחוה",
    "אחימאיר אבא",
    "איבן סינא",
    "איבן תימיה",
    "אילת",
    "אינשטיין",
    "איסמאילוב",
    "איסקוב",
    "אל מוקדסי",
    "אלבז נתן",
    "אליעזר יעקב",
    "אלכסנדר",
    "אלפעל",
    "אלשוילי אבישי",
    "אלתר רחל",
    "אמנון ותמר",
    "אנדלוס",
    "אנילביץ",
    "אפטושו",
    "אפרסק",
    "אקסודוס",
    "ארבע העונות",
    "ארז",
    "ארלוזורוב",
    "ארן זלמן",
    "אשכול אלישבע",
    "אשכולית",
    "אשל",
    "אתרוג",
    "בורוכוב",
    "ביאליק",
    "ביבארס",
    'ביל"ו',
    'בית"ר',
    "בלום לאון",
    "בלפור",
    "בן אליעזר אריה",
    "בן ארי לירן",
    "בן הורקנוס אליעזר",
    "בן חמו אהרן",
    "בן חקון",
    "בן יהודה",
    "בן יוסף אהרן",
    "בן שמן",
    "בעל התניא",
    "בר אילן",
    "בר כוכבא",
    "בר-לב חיים",
    "ברנר",
    "ברק",
    "בת שבע",
    "גבורי ישראל",
    "גבעת הזיתים",
    "גבריאל",
    "גוטמן אריה",
    "גולדברג לאה",
    "גולומב",
    "גולן",
    "גורדון א ד",
    "גושן",
    "גלבוע",
    "גלעד",
    "גני אביב",
    "גני יער",
    "גנרל לקלרק",
    "גרונר דב",
    "גרטבול",
    "גרינבוים",
    "גרץ",
    "גשר לוד",
    "דבורה הנביאה",
    "דביקאר יחזקאל",
    "דובדבן",
    "דובנוב",
    "דוד ברוך",
    "דיזרעאלי",
    "דיין דבורה",
    "דם המכבים",
    "דן",
    "דעי יואב",
    "דקר",
    "דרך בית הדין",
    "דרך לוד הירוקה",
    "דרך משמר נוף",
    "דרך נופי חמד",
    "דרכי משה",
    'האדמו"ר האמצעי',
    "האורנים",
    "האיילון",
    "האילנות",
    "האיריס",
    "האלה",
    "האקליפטוס",
    "הארבעים ושלושה",
    "הבטיחות",
    "הבעל שם טוב",
    "הברדלסים",
    "הברושים",
    "הבשור",
    "הגדוד העברי",
    "הגיא",
    "הגר",
    "הדס",
    "הדקל",
    "הדרים",
    "ההסתדרות",
    "הוז דב",
    "הורדים",
    "החלוץ",
    "החשמונאים",
    "היוצרים",
    "הכובשים",
    "הלני המלכה",
    "המחקר",
    "המלאכה",
    "המסגר",
    "המפרי יוברט",
    "המצביאים",
    "הנביאים",
    "הנילוס",
    "הנשיא",
    "הס משה",
    "הסיירים",
    "העיר העתיקה",
    "העמלים",
    "העצמאות",
    "הפירמידות",
    "הפרחים",
    "הצורפים",
    "הצייר ראובן",
    "הצנחנים",
    "הרב אלישיב",
    "הרב חורי חיים",
    "הרב טייב חי",
    "הרב מרדכי אליהו",
    "הרב עובדיה יוסף",
    "הרב עוזיאל",
    "הרב עזריה יעקב",
    "הרב קוק",
    "הרדוף",
    "הרימון",
    "הרץ הנרי",
    "הרצוג",
    "הרצל",
    "הרקפות",
    "השוטרים",
    "השומרונים",
    "השחרור",
    "השייטת",
    "התבור",
    "התקוה",
    "וגמן יששכר",
    "ויגודסקי",
    "ז'בוטינסקי",
    "זויתן",
    "זיגלבוים יואל",
    "זית",
    "זפרני",
    "חבצלת",
    "חדיד ואונו",
    "חזני סולומון",
    "חטיבת הנגב",
    "חטיבת הראל",
    "חטיבת יפתח",
    "חידקל",
    "חיל הים",
    "חלאד אבן אל ואליד",
    "חללי אגדיר",
    "חללי אגוז",
    "חרוב",
    "חרמון",
    "חרצית",
    "טויטו מרדכי",
    "טולדנו ניסים",
    "טולידאנו",
    "טרומפלדור",
    "טשרניחובסקי",
    'י"א חללי מינכן',
    "יודפת",
    "יוספטל גיורא",
    "יחד שבטי ישראל",
    "יסמין",
    "יעקב אבינו",
    "יצחק אבינו",
    "יצחק שמיר",
    "יקינטון",
    "יריחו",
    "ירמוך",
    'ישיבת חב"ד',
    "כהן אלי",
    "כהן יעקב",
    "כהן מיכאל",
    "ככר העצמאות",
    'ככר הפלמ"ח',
    "ככר השריון",
    "ככר קומנדו",
    "כליל החורש",
    "כלניות",
    "כצנלסון",
    "כרכום",
    "כרמל",
    "לאה אימנו",
    "לב פסח",
    "להמן",
    "לובלין אהרון",
    "לוד",
    "לוד הירוקה",
    "לוד הצעירה",
    "לוזון אפרים",
    'לח"י',
    "ליבנה",
    "ליטאני",
    "לילך",
    "לימון",
    "לכיש",
    "מבצע יהונתן",
    "מודיעין",
    "מוחמד רג'ב חסונה",
    "מועאויה אבו ספיאן",
    "מורדי הגטאות",
    "מורן",
    "מזרחי אברהם",
    "מזרחי דוד",
    "מחנות קפריסין",
    "מידנק אריה",
    "ממן יוסף",
    "מנהרת העיר",
    "מנחם בגין",
    "מס דני",
    "מסדה",
    "מעון א",
    "מעון אקדמאים",
    "מעון ב",
    "מעון עולים",
    "מרגנית",
    "מרזוק משה",
    "משה רבינו",
    "משושים",
    "נאות יצחק",
    "נדבה יוסף",
    "נהר הירדן",
    "נוישטיין פנחס",
    "נורדאו",
    "נורית",
    "נחושתן אורי",
    'ניל"י',
    "נימרוב",
    "ניצנים",
    "נירים",
    "נרקיס",
    "נתניהו יהונתן",
    "סביון",
    "סביוני לוד",
    "סג'רה",
    "סולד",
    "סוקולוב",
    "סחלב",
    "סטרומה",
    "סיגלון",
    "סיגלית",
    "סייפן",
    "סילבר אבא הלל",
    "סיתונית",
    "סלמה שלמה",
    "סלניקיו",
    "סמטת שלמה המלך",
    "סן מרטין",
    "סנש חנה",
    "סער עקיבא",
    "ספרן מאיר",
    "עולי הגרדום",
    "עומר אבן אלכטאב",
    "עומר עבד אלעזיז",
    "עותמן אבן עפאן",
    "עלי אבי טאלב",
    "עלי כותרת",
    "עליה ב",
    "עמוד",
    "עמנואל",
    "ערבה",
    "ערוגות",
    "עשרת הרוגי מלכות",
    "פוגה",
    "פינלס",
    "פינסקר",
    "פינשטיין משה",
    "פיקוס",
    "פלדמן",
    "פלומניק יעקב",
    "פנקס דוד",
    "פרימן מרדכי וחוה",
    "פרנק אנה",
    "פרת",
    "צאלון",
    "צאלח א דין",
    "צבר",
    "צוללת דקר",
    "ציפורן",
    "צמח צדק",
    "צמרות",
    "קדושי קהילת מזריץ",
    "קדרון",
    "קהילת ניו יורק",
    "קוטנר אברהם",
    "קישון",
    "קלויזנר",
    "קלר הלן",
    "קני סוף",
    "קנמון",
    "קפלן",
    "קרית הספורט",
    "קרית חינוך",
    "רבי טרפון",
    "רבי עקיבא",
    "רבין יצחק",
    "רבקה אימנו",
    "רגבים",
    "רובין אריה",
    "רוזנבלום",
    "רוזנוולד ויליאם",
    "רוקח",
    "רותם",
    "רזיאל",
    "רח כו",
    "רח לג",
    "רח לל",
    "רח מז",
    "רח מח",
    "רח ממ",
    "רח מנ",
    "רח מס",
    "רח נדא",
    "רח נו",
    "רח נז",
    "רח נח",
    "רח נט",
    "רח סא",
    "רח סב",
    "רח סג",
    "רח סד",
    "רח סה",
    "רח סו",
    "רח סז",
    "רח סט",
    "רח פא",
    "רח פב",
    "רח פג",
    "רח פד",
    "רח פה",
    "רח פת",
    "רח צא",
    "רח צב",
    "רח צג",
    "רח קד",
    "רח ש",
    "רח שר",
    "רח תג",
    "רח תד",
    "רח תה",
    "רח תו",
    "רח תח",
    "רחל אימנו",
    "רחל המשוררת",
    'רמב"ם',
    "רמז",
    "רמת אשכול",
    "רפאל",
    "רפאל הכהן",
    "ש אסבסט צפון",
    "ש אסבסטונים",
    "ש בנית",
    "ש זוגות צעירים",
    "ש חבד",
    "ש חסכון",
    "ש חסכון ג",
    "ש ממשלתי",
    "ש מפונים",
    "ש נוה זית",
    "ש נוה ירק",
    "ש נווה שלום",
    "ש עמידר",
    "ש עממי",
    "ש פתוח",
    "ש צפון",
    "ש רמט",
    "ש רסטרום",
    "ש רסקו",
    "ש שרת",
    "שבט אפרים",
    "שבט אשר",
    "שבט גד",
    "שבט זבולון",
    "שבט יהודה",
    "שבט יששכר",
    "שבט מנשה",
    "שבט נפתלי",
    "שבט ראובן",
    "שבט שמעון",
    "שד בן גוריון",
    "שד דוד המלך",
    "שד הציונות",
    "שד ירושלים",
    'שד צה"ל',
    "שד ריסר מיכה",
    "שד שורץ",
    "שדה יצחק",
    "שורץ נתן",
    "שורק",
    "שושן",
    "שזיף",
    "שטיינברג",
    "שיטה",
    "שכ בן גוריון",
    "שכ ורדה",
    "שכ יד אברהם",
    "שכ יסמין",
    "שכ משמר נוף",
    "שכ נוה אלון",
    "שכ נוה נוף",
    "שכ סחנה",
    "שכ שבזי",
    "שכ שניר",
    "שלום עליכם",
    "שלמה המלך",
    "שמואל",
    "שמחוני אסף",
    "שמעון בר יוחאי",
    "שניאור זלמן",
    "שניר",
    "שפירא חיים משה",
    "שפרינצק",
    "שקד",
    "שקמה",
    "שרה אימנו",
    "תאנה",
    "תות",
    "תילתן",
    "תלמים",
    "תמר",
    "תפוז",
    "תפוח",
  ],
  לוזית: [
    "אורן",
    "אלה",
    "אלון",
    "ארז",
    "אשל",
    "ברוש",
    "דקל",
    "זית",
    "חרוב",
    "לוזית",
    "ערבה",
    "צפצפה",
    "רימון",
    "שקד",
    "תאנה",
  ],
  "לוחמי הגיטאות": ["לוחמי הגיטאות"],
  לוטם: ["לוטם"],
  לוטן: ["לוטן"],
  לימן: ["לימן"],
  לכיש: [
    "אשכול",
    "בציר",
    "גפן",
    "זמורה",
    "כרמים",
    "לכיש",
    "סמדר",
    "עינב",
    "שורק",
    "שריג",
  ],
  לפיד: [
    "אגוז",
    "אורנים",
    "אלונים",
    "ארזים",
    "הגולן",
    "הגליל",
    "הדקל",
    "הזית",
    "הירדן",
    "הרי ירושלים",
    "השקמה",
    "ים המלח",
    "ים כנרת",
    "לפיד",
  ],
  לפידות: ["לפידות"],
  לקיה: [
    "לקיה",
    "שכ 10",
    "שכ 20",
    "שכ 1",
    "שכ 11",
    "שכ 2",
    "שכ 12",
    "שכ 3",
    "שכ 13",
    "שכ 4",
    "שכ 14",
    "שכ 5",
    "שכ 15",
    "שכ 6",
    "שכ 16",
    "שכ 7",
    "שכ 17",
    "שכ 8",
    "שכ 9",
  ],
  מאור: ["מאור"],
  "מאיר שפיה": ["מאיר שפיה"],
  "מבוא ביתר": [
    "אילות",
    "אשל",
    "האלה",
    "הוותיקים",
    "החורשים",
    "היער",
    "המייסדים",
    "המעין",
    "הנוטעים",
    "הנחל",
    "חד נס",
    "מבוא ביתר",
    "נילי",
    "ציפורי",
    "רחף",
    "שחק",
    "שיאון",
    "שעל",
  ],
  "מבוא דותן": ["מבוא דותן"],
  "מבוא חורון": [
    "אשכול בדולח",
    "אשכול גדיד",
    "אשכול גן אור",
    "אשכול גני טל",
    "אשכול דוגית",
    "אשכול כדים",
    "אשכול כפר ים",
    "אשכול מורג",
    "אשכול נוה דקלים",
    "אשכול ניסנית",
    "אשכול נצר חזני",
    "אשכול פאת שדה",
    "אשכול קטיף",
    "אשכול רפיח ים",
    "אשכול תל קטיפא",
    "אשר",
    "בנימין",
    "גד",
    "גנים",
    "דן",
    "דרך אלי סיני",
    "דרך נצרים",
    "דרך שלו",
    "דרכי נועם",
    "הושע",
    "הנביאים",
    "הרב צבי יהודה",
    "השופטים",
    "התעשיה",
    "זבולון",
    "זכריה",
    "חבקוק",
    "חגי",
    "חומש",
    "יהודה",
    "יואל",
    "יונה",
    "יוסף",
    "יפה נוף",
    "יששכר",
    "כפר דרום",
    "לוי",
    "מבוא חורון",
    "מיכה",
    "מלאכי",
    "מסילת ישרים",
    "נחום",
    "נפתלי",
    "עובדיה",
    "עמוס",
    "צפניה",
    "ראובן",
    "רחבת הגיבורים",
    "שאנור",
    "שדרות אילון",
    "שירת הים",
    "שמעון",
  ],
  "מבוא חמה": ["מבוא חמה"],
  "מבוא מודיעים": ["מבוא מודיעים"],
  "מבואות ים": ["מבואות ים"],
  "מבואות יריחו": ["מבואות יריחו"],
  מבועים: [
    "איריס",
    "ברקן",
    "הגפן",
    "הורד",
    "הרדוף",
    "חרצית",
    "כלנית",
    "מבועים",
    "מרכז אזורי",
    "נרקיס",
    "סייפן",
    "רקפת",
  ],
  מבטחים: ["מבטחים"],
  מבקיעים: ["מבקיעים"],
  "מבשרת ציון": [
    "אגוז",
    "אופיר",
    "אופל",
    "אורנית",
    "אזור תעשייה",
    "אחלמה",
    "אירוס",
    "ארבל",
    'ביל"ו',
    "ברקת",
    "ברקת ראובן",
    "גלבוע",
    "גן לאומי הקסטל",
    "דוכיפת",
    "דם המכבים",
    "דר",
    "האודם",
    "האורן",
    "האילנות",
    "האלה",
    "האפרסק",
    "הארזים",
    "האשכול",
    "הבוסתנים",
    "הבושם",
    "הברוש",
    "הגפן",
    "הדודאים",
    "הדס",
    "הדרור",
    "הזית",
    "הזמיר",
    "החרוב",
    "היצירה",
    "הכלנית",
    "הכרכום",
    "הכרמים",
    "הלוטם",
    "המעפילים",
    "המרגנית",
    "המשוריינים",
    "המשלט",
    "הנורית",
    "הנרקיס",
    "הסיפן",
    "הסנונית",
    "העפרוני",
    'הפלמ"ח',
    "הפנינה",
    "הר כנען",
    "הראל",
    "הראשונים",
    "הרב יוסף חיים",
    "הרב ניסים יצחק",
    'הרמב"ם',
    "השורק",
    "השחרור",
    "השלום",
    "השקמה",
    "התאנה",
    "ורד",
    "זהבית",
    "זית רענן",
    "חבצלת",
    "חלמית",
    "חצב",
    "חרובית",
    "חרמון",
    "טופז",
    "יהודה הלוי",
    "יסמין",
    "ישפה",
    "ככר אבני חן",
    "ככר הבדולח",
    "ככר הפסגות",
    "ככר חלילים",
    "ככר קדמוניות",
    "כרמל",
    "לבונה",
    "לשם",
    "מבוא א",
    "מבוא בושמת",
    "מבוא הדובדבן",
    "מבוא הדקל",
    "מבוא הורד",
    "מבוא הקינמון",
    "מבוא הראל",
    "מבוא השזיף",
    "מבוא חוגלה",
    "מבוא טללים",
    "מבוא יהלום",
    "מבוא יערה",
    "מבוא יערים",
    "מבוא לבנה",
    "מבוא נעמה",
    "מבוא סיירים",
    "מבוא ספיר",
    "מבוא סתוונית",
    "מבוא ערמון",
    "מבוא קטורה",
    "מבוא רימון",
    "מבוא תדהר",
    "מבצע נחשון",
    "מבצע קדש",
    "מבשרת ירושלים",
    "מבשרת ציון",
    "מבשרת ציון א",
    "מבשרת ציון ב",
    "מוצא הקטנה",
    "מור",
    "מחצבת אבן סיד",
    "מירון",
    "מע קסטל",
    "מעלה הקסטל",
    "מצפה הבירה",
    "מרגלית",
    "מרכז קליטה",
    "נהר פרת",
    "נוף הרים",
    "נפך",
    "נצח ישראל",
    "נתיב הפורצים",
    "סמ עינב",
    "סמדר",
    "סעדיה גאון",
    "עין חמד",
    "ענבר",
    "עצמון",
    "ערבה",
    "פטדה",
    "פרג",
    "פרי מגדים",
    "צבר",
    "קבוץ גלויות",
    "קניון הראל",
    "קרית חנוך",
    "קרן היסוד",
    "קרן קיימת",
    "רחבת העצמאות",
    "רקפת",
    "ש אלרם",
    "ש זוגות צעירים",
    "ש רסקו",
    "שבו",
    "שבטי ישראל",
    "שבי ציון",
    "שביל א",
    "שביל ב",
    "שביל ג",
    "שביל ד",
    "שבע אחיות",
    "שד החוצבים",
    "שד החושן",
    "שדה חמד",
    "שוהם",
    "שושנה",
    "שכ ג",
    "שכ ה",
    "שכ ו",
    "שכ ז",
    "שכ ט",
    "שכ יא",
    "שכ יג",
    "שכ מוצא הקטנה",
    "שכ נוף הרים",
    "שכונה ד",
    "שכונה ח",
    "שמעון",
    "שעורה",
    "ששת הימים",
    "תבור",
    "תירוש",
    "תמרים",
    "תרשיש",
  ],
  "מג'ד אל-כרום": [
    "אלעין-מג'ד אלכרום",
    "דרומית-מג'ד אלכרום",
    "כפר הישן-מגד אלכרום",
    "מג'ד אל-כרום",
    "מזרחית חדשה",
    "מזרחית-מג'ד אלכרום",
    "מערבית-מג'ד אלכרום",
    'מתחם בי"ס תיכון',
    "צפונית חדשה",
    "צפונית-מג'ד אלכרום",
    "ש מזרחי",
  ],
  "מג'דל שמס": ["מג'דל שמס"],
  מגאר: [
    "אזור תעשייה",
    "אלבס",
    "אלבסבאס",
    "אלמזירה",
    "בואב אלהוא",
    "בלטאת עד'ימה",
    "בקיר",
    "ג'ורת אבו כמרה",
    "דבת אלזעתר",
    "השכונה הותיקה",
    "זתון אלתין",
    "ח'לת אלשריף",
    "חיילים בדווים",
    "כביש אבו אלנמל",
    "מגאר",
    "מסגד צפוני",
    "עין מנסורה ותיקה",
    "ראס אלח'אביה דרום",
    "ראס אלחביה",
    "שכ אלתריק",
    "שכ ג'משה",
    "שכ מערבית",
    "שכונה מזרחית",
    "שכונה נוצרית",
  ],
  מגדים: [
    "דרך אנשי הדממה",
    "דרך נתיב הים",
    "דרך שועלי החוף",
    "האלמוג",
    "הגלים",
    "החופים",
    "המעגן",
    "המפרץ",
    "העוגן",
    "השונית",
    "השחף",
    "התורן",
    "כוכב הים",
    "מגדים",
  ],
  מגדל: [
    "האירוסים",
    "האלה",
    "האלון",
    "האשכולית",
    "הברוש",
    "הגפן",
    "הדר",
    "ההרדופים",
    "הזית",
    "החצב",
    "החרוב",
    "החרצית",
    "היערה",
    "היקינטון",
    "הכלניות",
    "הכרכום",
    "המייסדים",
    "המנגו",
    "המעיין",
    "הנורית",
    "הסביון",
    "הסחלב",
    "הערבה",
    "הצאלון",
    "הצבעוני",
    "הראשונים",
    "הרותם",
    "הרימון",
    "הרקפת",
    "השיזף",
    "השיטה",
    "התאנה",
    "התמר",
    "וילה מלצט",
    "חרמון",
    "טבחה",
    "כפר נופש חוף הואי",
    "כרי דשא",
    "מגדל",
    "מעון נופים",
    "נוף הארבל",
    "נוף הגליל",
    "נוף כנרת",
    "עירית",
  ],
  "מגדל העמק": [
    "אזור התעשיה צפון",
    "אזור תעשיה דרום",
    "אחד העם",
    "איילון",
    "אלי כהן",
    "ארבל",
    "בגד עור",
    "גבעתי",
    "גולן",
    "גליל",
    "גלעד",
    "דרך העמק",
    "דרך העצמאות",
    "דרך חטיבת גולני",
    "האביב",
    "האומן",
    "האורנים",
    "האילן",
    "האיצטדיון",
    "האיריס",
    "האלה",
    "האלומות",
    "האלונים",
    "הארזים",
    "האריג",
    "האריזה",
    "הבנאי",
    "הברושים",
    "הבשן",
    "הגביש",
    "הגיא",
    "הדברת",
    "הדובדבן",
    "הדפנה",
    "ההגנה",
    "ההרדוף",
    "הורד",
    "הזית",
    "החבצלת",
    "החורש",
    "החורשה",
    "החימר",
    "החצב",
    "החרוב",
    "החרושת",
    "החרצית",
    "החשמל",
    "היסמין",
    "היערה",
    "היצירה",
    "היקינטון",
    "הכלנית",
    "הכרמל",
    "הלווין",
    "המגל",
    "המגנית",
    "המהנדס",
    "המחקר",
    "המטע",
    "המלאכה",
    "המסגר",
    "המצפה",
    "המתכת",
    "הנגב",
    "הנגר",
    "הנדיב",
    "הנופר",
    "הנורית",
    "הניצנים",
    "הנפח",
    "הנשיאים",
    "הסביון",
    "הסחלב",
    "הסיגלית",
    "הסתונית",
    "העליה",
    "העמל",
    "הערבה",
    "הפעמונית",
    "הפרג",
    "הפרדס",
    "הצאלון",
    "הצבר",
    "הצורן",
    "הצורף",
    "הצפורן",
    "הקיצנית",
    "הראשונים",
    "הרב בוסקילה רפאל",
    "הרב מלכה יוסף",
    "הרב עובדיה יוסף",
    "הרימון",
    "הרקפת",
    "השוהם",
    "השונמית",
    "השזרית",
    "השיטים",
    "השיקמה",
    "השיש",
    "השקד",
    "השרון",
    "התאנה",
    "התדהר",
    "התימורים",
    "התמר",
    "התעשיה",
    "התקשורת",
    "זבולון",
    'חב"ד',
    "חיים הרצוג",
    "חיים ויצמן",
    "חיל האוויר",
    "חרמון",
    "יודפת",
    "יפה נוף",
    "יצחק בן צבי",
    "ירדן",
    "ירקון",
    "כליל החורש",
    "לוי יוסף",
    "לוי מאוריציו",
    "לוע הארי",
    'לח"י',
    "מגדל העמק",
    "מירון",
    "משעול האגמון",
    "משעול האילנות",
    "משעול האתרוג",
    "משעול הגדי",
    "משעול הדולב",
    "משעול הדקלים",
    "משעול הדרור",
    "משעול ההדס",
    "משעול ההדרים",
    "משעול הזמיר",
    "משעול היעלים",
    "משעול הכנרית",
    "משעול הכפיר",
    "משעול הכרמים",
    "משעול הלוטם",
    "משעול הלולב",
    "משעול הליבנה",
    "משעול הנחליאלי",
    "משעול העפרוני",
    "משעול הצבעוני",
    "משעול הצופית",
    "משעול הרותם",
    "משעול התור",
    "משעול התירוש",
    "נוף העמק",
    "נחל הצבי",
    'ניל"י',
    "נתיב הלילך",
    "סטרומה",
    "סיני",
    "סמ האיריסים",
    "סמ החבצלות",
    "סמ המרגניות",
    "סמ השושנים",
    "סמוכה יוסף",
    "עצמון",
    "פנינת העמק",
    "ציפורי",
    "קדש",
    "קוממיות",
    "קישון",
    "קריית חינוך",
    "קרית רבין",
    "קרן היסוד",
    "רח מס' 3",
    "רמת אשכול",
    "רמת בלפור",
    "רמת גבריאל",
    "רמת יזרעאל",
    "שביל הנרקיס",
    "שד הבנים",
    "שד עמור שאול",
    "שומרון",
    "שושנת העמקים",
    "שיאון",
    "שכ גן ישראל",
    "שכ חימר",
    "שכ יסמין",
    "שכ יערת העמק",
    "שכ יפה נוף",
    "שכ מגדל אור",
    "שכ מערבית",
    "שכ מצפה העמק",
    "שכ נוף העמק",
    "שכ ספיר",
    "שכ פנינת העמק",
    "שכ רסקו",
    "שכ שלום",
    "שלום עליכם",
    "שניר",
    "שפירא ישראל",
    "ששת הימים",
    "תבור",
    "תובל",
    'תשי"ג',
  ],
  "מגדל עוז": ["גבעת המבתר", "מגדל עוז"],
  מגדלים: ["מגדלים"],
  מגידו: ["מגידו"],
  מגל: ["מגל"],
  מגן: ["מגן"],
  "מגן שאול": ["מגן שאול"],
  מגשימים: [
    "אורן",
    "דקל",
    "דרך המייסדים",
    "הברוש",
    "הגפן",
    "הכלנית",
    "הערבה",
    "הרדוף",
    "התאנה",
    "זית",
    "יסמין",
    "מגשימים",
    "נבון דוד",
    "נרקיס",
    "סביון",
    "סייפן",
    "פעמונית",
    "רותם",
    "תמר",
  ],
  "מדרך עוז": ["בית ראשון במולדת", "הרחבה", "מדרך עוז"],
  "מדרשת בן גוריון": [
    "אגי",
    "אוח",
    "אפרוח",
    "אשטרום",
    "גדרון",
    "גוזל",
    "האוניברסיטה",
    "הבשמים",
    "הנבטים",
    "הר אבנון",
    "הר צרור",
    "הרי אדום",
    "השבלולים",
    "ורדית",
    "זנבן",
    "חוברה",
    "חוד עקב",
    "יהודה עמיחי",
    "יהושע כהן",
    "ינשוף",
    "לאה גולדברג",
    "לילית",
    "מדברון",
    "מדרשת בן גוריון",
    "מיכאל גל",
    "מצוק הצינים",
    "מרכז מיטרני",
    "משעול חצב",
    "נווה צין",
    "נחל דרוך",
    "נחל הרועה",
    "נחל חוארים",
    "נחל חצץ",
    "נחל נוקד",
    "נחל צפית",
    "נחל קרקש",
    "נחל שועלים",
    "נחל תלול",
    "סלעית",
    "ע. הלל",
    "עין אורחות",
    "עין זיק",
    "עין מור",
    "עין שביב",
    "עין שרב",
    "עפרוני",
    "צופית",
    "רחל המשוררת",
    "רכס חלוקים",
    "רמת דבשון",
    "שדה צין",
    "שעיר",
    "תנשמת",
    "תרצה אתר",
  ],
  "מדרשת רופין": ["מדרשת רופין"],
  "מודיעין עילית": [
    "אבי עזרי",
    "אבני נזר",
    "אור החיים",
    "אורחות צדיקים",
    "אשר לשלמה",
    "בעל הטורים",
    'בעש"ט',
    "דרך קרית ספר",
    'הגר"א',
    "המאירי",
    "הסבא מסלבודקא",
    "הרב אלישיב",
    'השל"ה',
    "זכרון שמואל",
    "חובת הלבבות",
    "חזון איש",
    "חזון דוד",
    "חפץ חיים",
    'חת"ם סופר',
    "ישועת דוד",
    'מהרי"ל',
    "מודיעין עילית",
    "מנחת חינוך",
    "מנחת יהודה",
    "מנחת שלמה",
    "מסילת יוסף",
    "מסילת ישרים",
    "מעלות שמחה",
    "מרומי שדה",
    "משך חכמה",
    "משעול רבי אמי",
    "משעול רבי טרפון",
    "נודע ביהודה",
    "נתיבות המשפט",
    "נתיבות שלום",
    "סמטת אחיעזר",
    "עיון התלמוד",
    "פרי חדש",
    "קהילות יעקב",
    "קצות החושן",
    "קרית דגל התורה",
    'ראב"ד',
    "ראשית חכמה",
    "רב ושמואל",
    "רבי יהודה הנשיא",
    "רבי נתן צבי",
    "רבי עקיבא",
    "רבי שמעון בר יוחאי",
    "רבינו תם",
    'ריטב"א',
    'רמב"ם',
    'רמב"ן',
    'רש"י',
    'רשב"א',
    'רשב"ם',
    "שאגת אריה",
    "שד אביי ורבא",
    "שד בית שמאי",
    "שד הרב מפוניבז'",
    "שדי חמד",
    "שדרות הלל ושמאי",
    "שדרות יחזקאל",
    "שכ אחוזת ברכפלד",
    "שכ נאות הפסגה",
    "שכ נחלת חפציבה",
    "שכ קרית מלך",
    "שכ קרית ספר",
    "שער המלך",
    "שערי תשובה",
  ],
  "מודיעין-מכבים-רעות": [
    "אביטל מכבים -רעות",
    "אבני החושן",
    "אגוז מכבים רעות",
    "אגס",
    "אדיר מכבים-רעות",
    "אדמונית",
    "אדר",
    "אודם",
    "אורן מכבים-רעות",
    "אזור התעשיה",
    "אחלמה",
    "איזדרכת",
    "אייר",
    "אירוסים מכבים רעות",
    'אכסנית מכבים מ"ר',
    "אלה מכבים רעות",
    "אלול",
    "אלי כהן",
    "אליהו הנביא",
    "אלישע הנביא",
    "אלמוג מכבים רעות",
    "אלמוגן",
    'אמנון ותמר מ"ר',
    "אנפה מכבים רעות",
    "אסתר המלכה",
    "אפיקי מים",
    "אפרים",
    "ארבל מכבים רעות",
    "ארז מכבים רעות",
    "אשחר",
    "אשכול מכבים רעות",
    "אשל מכבים רעות",
    "אשר",
    "בדולח",
    "בוסתן מכבים רעות",
    "בנימין",
    "ברוש מכבים רעות",
    "ברקנית מכבים רעות",
    "ברקת מכבים רעות",
    "בשן מכבים רעות",
    'גבעת הלבונה מ"ר',
    "גד",
    "גדי מכבים רעות",
    "גולן מכבים רעות",
    "גינת אגוז",
    "גינת איילון",
    "גינת בית שאן",
    "גינת דותן",
    "גינת האלה",
    "גינת החולה",
    "גינת זבולון",
    "גינת יצחק רבין",
    "גינת מגדל עוז",
    "גיתית מכבים רעות",
    "גלבוע מכבים רעות",
    "גליל מכבים רעות",
    "גלעד מכבים רעות",
    "גפן מכבים רעות",
    "דבורה הנביאה",
    "דבורנית",
    "דובדבן מכבים רעות",
    "דוד בן גוריון",
    "דוכיפת",
    "דולב מכבים רעות",
    "דינה",
    "דם המכבים",
    "דן",
    "דפנה",
    "דקל מכבים רעות",
    "דרך אלון יגאל",
    'דרך ד"ר פלד ישראל',
    'דרך המייסדים מ"ר',
    'דרך הראשונים מ"ר',
    "דרך פרופ' פרג יאיר",
    "האופה",
    "האורג",
    "הבנאי",
    "הגנן",
    "הדודאים",
    "הדורית מכבים רעות",
    "הדס מכבים רעות",
    "הדרור מכבים רעות",
    "הדרים מכבים רעות",
    "הורד מכבים רעות",
    "הזמיר מכבים רעות",
    "הזריחה",
    "הזרימה",
    "הזריעה",
    "החורש",
    "החרט",
    "היער",
    "הכרמים",
    "הלוח העברי",
    "הלוטוס מכבים רעות",
    "המכונאי",
    "המעיין",
    "הנביאים",
    "הנביטה",
    "הנגר",
    "הנטיעה",
    "הניצנים",
    "הנפח",
    "הסוחר",
    "הסתת",
    "הצורף",
    "הצמיחה",
    "הקדר",
    "הקידמה",
    "הר אמיר מכבים רעות",
    "הר ארגמן מכבים רעות",
    "הר בוקר מכבים רעות",
    'הר גוונים מ"ר',
    "הר דלתון מכבים רעות",
    "הר הילה מכבים רעות",
    "הר ורדה מכבים רעות",
    "הר זיו מכבים רעות",
    "הר חזון מכבים רעות",
    "הר טללים מכבים רעות",
    "הר יודפת מכבים רעות",
    "הר כנען מכבים רעות",
    "הר להבים מכבים רעות",
    "הר מעוז מכבים רעות",
    "הר נטופה מכבים רעות",
    "הר סנה מכבים רעות",
    'הר עמינדב מ"ר',
    "הר פורת מכבים רעות",
    "הר צפריר מכבים רעות",
    "הר קדרים מכבים רעות",
    "הר רביד מכבים רעות",
    "הר רמון מכבים רעות",
    "הר שאול מכבים רעות",
    "הר שחר מכבים רעות",
    "הר תורען מכבים רעות",
    "הר תמנע מכבים רעות",
    "הרדוף מכבים רעות",
    "הרימון מכבים רעות",
    "הרכבת",
    "השדרה המרכזית",
    "השולמית",
    "ורדית מכבים רעות",
    "זית מכבים רעות",
    "זכריה הנביא",
    "חבצלת השרון",
    "חבצלת מכבים רעות",
    "חגווי הסלע",
    "חגי הנביא",
    "חגי ישראל",
    "חוחית מכבים רעות",
    "חוט השני",
    "חטיבה שבע",
    "חטיבת אלכסנדרוני",
    "חטיבת גבעתי",
    "חטיבת גולני",
    'חטיבת הנח"ל',
    "חטיבת הצנחנים",
    "חטיבת הראל",
    "חטיבת יפתח",
    "חיננית",
    "חלמונית",
    "חסידה",
    "חצב מכבים רעות",
    "חרוב מכבים רעות",
    "חרמון מכבים רעות",
    "חרצית",
    "חשון",
    "טבלן מכבים רעות",
    "טבק מכבים רעות",
    "טבת",
    "טופז מכבים רעות",
    "טיילת אבני חן",
    "טיילת הציפורים",
    "טללים מכבים רעות",
    "יהודה",
    "יהודה המכבי",
    "יהלום",
    "יואל הנביא",
    "יובלים מכבים רעות",
    "יונה הנביא",
    "יוסף",
    "יחזקאל הנביא",
    "יסעור מכבים רעות",
    "יעל הגיבורה",
    "יער אלונים",
    "יער אשתאול",
    "יער בארי",
    "יער בן שמן",
    "יער ברעם",
    "יער הזורע",
    "יער חניתה",
    "יער חצרים",
    "יער חרובית",
    "יער ירושלים",
    "יער יתיר",
    "יערה מכבים רעות",
    "יערות ישראל",
    "יפה נוף מכבים רעות",
    "יקינטון מכבים רעות",
    "ירח-אב",
    "ירחים",
    "ירמיהו הנביא",
    "ישעיהו הנביא",
    "ישפה",
    "יששכר",
    "כחלית ההרים",
    "ככר אבוקה",
    "ככר האלופים",
    "ככר הגבורה",
    "ככר החטיבות",
    "ככר הלווים",
    "ככר המגינים",
    "ככר המתנדבים",
    "ככר הנביאים",
    "ככר הפסגה",
    "ככר השומרה",
    "ככר השכנות",
    "ככר חטיבת הנגב",
    "ככר חטיבת הראל",
    "ככר יקותיאלי",
    "ככר לב רעות",
    "ככר מלאכי הנביא",
    "ככר משואה",
    "ככר עמוס הנביא",
    "ככר רבין",
    "ככר שלהבת",
    "ככר תנועת הנוער",
    "כליל החורש",
    "כלנית מכבים רעות",
    "כנרית מכבים רעות",
    "כסלו",
    "כפתור ופרח",
    "כרכום",
    "כרמל מכבים רעות",
    "כתם פז",
    "לאה אמנו",
    "לב העיר",
    "לבונה",
    "לביא מכבים רעות",
    "לוי",
    "לוע הארי",
    "לילך מכבים רעות",
    "לימון",
    "לשם",
    'מבוא רעות מ"ר',
    "מבצע דני",
    "מבצע דקל",
    "מבצע חורב",
    "מבצע חירם",
    "מבצע יואב",
    "מבצע יונתן",
    "מבצע לוט",
    "מבצע נחשון",
    "מבצע עובדה",
    "מבצע קדש",
    "מבצע קלשון",
    "מגדל דוד",
    "מגדל הלבנון",
    "מגדל המנורה",
    "מגדל ים",
    "מגדל עוז",
    "מודיעין",
    "מודיעין-מכבים-רעות",
    "מיכה הנביא",
    "מירון מכבים רעות",
    "מיתר מכבים רעות",
    'מכבים-רעות מ"ר',
    "מנשה",
    "מנתור",
    "מעלה אבנר",
    "מעלה ברוך מזרחי",
    "מעלה גדעון",
    "מעלה דורון",
    'מעלה המור מ"ר',
    "מעלה נמר",
    "מעלה עמיעז",
    'מצפה רעות מ"ר',
    "מרגנית מכבים רעות",
    "מרווה",
    "מרום מכבים רעות",
    "מרומית מכבים רעות",
    "מרים החשמונאית",
    "משעול אמיר בן אריה",
    "משעול יניב בר און",
    "משעול עוז צמח",
    "משעול צבי זימן",
    "מתתיהו הכהן",
    "נאות דשא",
    "נהר הירדן",
    'נוף קדומים מ"ר',
    "נופך",
    "נופר מכבים רעות",
    "נורית מכבים רעות",
    "נחל אביב מכבים רעות",
    "נחל אלעל",
    "נחל ארנון",
    "נחל בוקק",
    "נחל בזק",
    "נחל בצת",
    "נחל ברק מכבים רעות",
    "נחל גלים",
    "נחל געתון",
    'נחל גרופית מ"ר',
    "נחל דליה",
    "נחל דן מכבים רעות",
    "נחל הבשור",
    "נחל הירמוך",
    "נחל הירקון",
    'נחל המעיינות מ"ר',
    "נחל זוהר",
    'נחל זויתן מ"ר',
    "נחל חבר",
    'נחל חרות מ"ר',
    "נחל טף מכבים רעות",
    "נחל יבנאל",
    "נחל יגור",
    'נחל יעלים מ"ר',
    'נחל כיסופים מ"ר',
    "נחל לכיש מכבים רעות",
    "נחל מירון",
    'נחל משושים מ"ר',
    "נחל נטוף מכבים רעות",
    "נחל נטפים",
    "נחל נעמן",
    "נחל סער מכבים רעות",
    "נחל עוז מכבים רעות",
    "נחל עיון",
    "נחל עמוד",
    "נחל ענבה",
    'נחל ערוגות מ"ר',
    "נחל פארן",
    "נחל פולג מכבים רעות",
    'נחל צאלים מ"ר',
    "נחל צופר",
    "נחל צין",
    "נחל ציפורי",
    "נחל צלמון",
    "נחל קטלב",
    "נחל קידרון",
    'נחל קישון מ"ר',
    "נחל שורק",
    "נחל שיקמה",
    "נחל שניר",
    "נחל תבור",
    "נחל תנינים",
    "נחליאלי",
    "ניסן",
    "ניצן מכבים רעות",
    "נפתלי",
    "נץ החלב",
    "נקר מכבים רעות",
    "נרקיס מכבים רעות",
    "סביון",
    "סביונים מכבים רעות",
    "סגן נתן כהן",
    "סחלב מכבים רעות",
    "סיגלון",
    "סיון",
    "סייפן מכבים רעות",
    "סלעית",
    "סמדר",
    "סנה משה",
    "סנונית מכבים רעות",
    "ספיר מכבים רעות",
    "סתוונית",
    "עגור מכבים רעות",
    "עובדיה הנביא",
    "עופר האיילים",
    "עמק איילון",
    "עמק בית שאן",
    "עמק דותן",
    "עמק האלה",
    "עמק החולה",
    "עמק זבולון",
    "עמק חפר",
    "עמק חרוד",
    "עמק יזרעאל",
    "ענבר",
    "עפרוני",
    "עצי היער",
    "עצמון מכבים רעות",
    "ערבה מכבים רעות",
    "ערבי נחל מכבים רעות",
    "ערוגות הבושם",
    "ערער",
    "עת הזמיר",
    "פז",
    "פטדה",
    "פיקוס",
    "פלח הרימון",
    "פעמונית מכבים רעות",
    "פרי מגדים",
    "פשוש מכבים רעות",
    "צאלה מכבים רעות",
    "צאלון",
    "צבעוני מכבים רעות",
    "צוקית",
    "ציפורנית",
    "צלע ההר",
    "צרור המור",
    "קול דודי",
    "קול התור",
    "קורל מכבים רעות",
    "קידה",
    "קיסוס מכבים רעות",
    "קיפודן",
    "קרן מכבים רעות",
    "קשת מכבים רעות",
    'רא"ל דוד אלעזר',
    'רא"ל חיים ברלב',
    'רא"ל חיים לסקוב',
    'רא"ל יגאל ידין',
    'רא"ל יעקב דורי',
    'רא"ל מרדכי גור',
    'רא"ל מרדכי מקלף',
    'רא"ל משה דיין',
    "ראובן",
    "רבקה אמנו",
    "רותם מכבים רעות",
    "רחל אמנו",
    "רכסים מכבים רעות",
    'רס"ן אייל גלר',
    'רעות מ"ר',
    "רקפת מכבים רעות",
    "שבו",
    "שבט",
    "שבטי ישראל",
    "שגיא מכבים רעות",
    'שד אורנים מ"ר',
    "שד החמאם",
    "שד המלאכות",
    "שד המעלה",
    "שד המקצועות",
    "שד הרכס",
    "שד התעשיות",
    "שד יצחק רבין",
    "שד מנחם בגין",
    "שד ענבה",
    "שדרות החשמונאים",
    'שדרת המכבים מ"ר',
    'שדרת הפרחים מ"ר',
    'שדרת עומרים מ"ר',
    "שוהם מכבים רעות",
    "שומרי החומות",
    "שושן צחור",
    "שושנת העמקים",
    "שחף",
    "שיזף מכבים רעות",
    "שיר השירים",
    "שלדג",
    "שלומציון המלכה",
    "שמואל הנביא",
    "שמעון",
    "שני",
    "שקד מכבים רעות",
    "שקמה מכבים רעות",
    "שרה אמנו",
    "שש משזר",
    "תאנה מכבים רעות",
    "תבור מכבים רעות",
    "תומר מכבים רעות",
    "תכלת",
    "תלתן",
    "תמוז",
    'תנ"צ אפריים ברכה',
    "תפוח",
    "תרשיש",
    "תשרי",
  ],
  מולדת: [
    "אחוזת שושנה",
    "בני ברית",
    "גלבוע",
    "גלעד",
    "גנים",
    "האסם",
    "הבזלת",
    "הרדוף",
    "חומה ומגדל",
    "חצב",
    "חקלאים",
    "יששכר",
    "כוכב הירדן",
    "מגדל מים",
    "מולדת",
    "פיקוסים",
    "פרדס",
    "צבאים",
    "ראשונים",
    "שומרים",
    "תבור",
  ],
  "מוצא עילית": [
    "ארזה",
    "בית החלמה ארזה",
    "האורן",
    "האלה",
    "האלונים",
    "האקליפטוס",
    "הארזים",
    "הברוש",
    "הזית",
    "הראשונים",
    "הרימון",
    "השקד",
    "התפוח",
    "מבוא הגפן",
    "מבוא הלילך",
    "מוצא עילית",
    "מעון הרופא",
    "מעלה הארז",
    "סמ החרוב",
    "ש רסקו",
  ],
  מוקייבלה: ["מוקייבלה"],
  מורן: ["אלה", "אלון", "גפן", "זית", "מורן", "רימון", "תאנה", "תמר"],
  מורשת: [
    "אריאל",
    "בית אל",
    "השילוח",
    "יפה נוף",
    "ירושלים",
    "מוריה",
    "מורשת",
    "ציון",
  ],
  מזור: [
    "הגפן",
    "הדקל",
    "היוגב",
    "היסמין",
    "הכלנית",
    "המייסדים",
    "הנוטע",
    "הנרקיס",
    "הפרדס",
    "הצבעוני",
    "הרימון",
    "הרקפות",
    "השחר",
    "מזור",
    "סמ האירוס",
    "סמ הבוקרים",
  ],
  "מזכרת בתיה": [
    "אברהם מרדכי לוין",
    "אהרונסון שרה",
    "אורן",
    "אידלסון בבה",
    "איזדורה דנקן",
    "אלה",
    "אלול רינה",
    "אלון יגאל",
    "אלוני דב",
    "אנפה",
    "ארז",
    "ארקין אליעזר",
    "אשבל חיה",
    "אשכול",
    "אשל",
    "אתר תרצה",
    "בארון דבורה",
    "בבילה אליהו",
    "בית און",
    "בן גוריון",
    "בנודיז רחל",
    "ברוך צבי ברנשטיין",
    "ברוש",
    'ברי"ל יחיאל',
    "גבעתי",
    "גולד יעקב",
    "גולדברג לאה",
    "גולדה מאיר",
    "גור בתיה",
    "גלמן אברהם יעקב",
    "גפן",
    "גרוסמן חייקה",
    "דב",
    "דובדבן",
    "דולב",
    "דמארי שושנה",
    "דקל",
    "דרך היין",
    "האחים ארקין",
    "האסיף",
    "הבציר",
    "הגורן",
    "הדרים",
    "ההגנה",
    "הכנסת אורחים",
    "הלזנר עמנואל",
    "הלן קלר",
    "המגן",
    "המייסדים",
    "המסיק",
    "הנוטרים",
    "הניצחון",
    'הנשיא שז"ר',
    "העצמאות",
    "הצבי",
    "הצנחנים",
    "הקטיף",
    "הקציר",
    "הרב מוהליבר",
    "הרשקוביץ",
    "השוטרים",
    "התאנה",
    "וויסברג שלמה",
    "וולך יונה",
    "וולפסון מילכה",
    "ורד",
    "זטוצקי יעקב",
    "זייד ציפורה",
    "זית",
    "זמיר",
    "חיטה",
    "חיים משה פרס",
    "חסיה המיילדת",
    "חרוב",
    "חרצית",
    "טיכו אנה",
    "יואל דב פרס",
    "יחזקאל לוין",
    "ילין תלמה",
    "יסמין",
    "כיכר האיכר",
    "כפות תמרים",
    "לוי חיים",
    "לוי תנאי שרה",
    "לויטה אהרון זליג",
    "לילך",
    "לסקובסקי יעקב",
    "מארי קירי",
    "מבצע יכין",
    "מזכרת בתיה",
    "מידון",
    "מלר חיים",
    "מרתה גרהאם",
    "משה לוין",
    "משה מלר",
    "משעול דיין משה",
    "משעול ספיר פנחס",
    "משעול שלמה",
    "משעול שרת משה",
    "נאות הראשונים",
    "נאמן נחמה",
    "נודל אידה",
    "נופר",
    "נורית",
    "ניימן מרדכי",
    "נתיב השיירות",
    "סבידור מנחם",
    "סולד הנרייטה",
    "סיגלית",
    "סמ הבאר",
    'סמ ש"י',
    "סנש חנה",
    'פלמ"ח',
    "קאלגרי",
    "קדמן גורית",
    "קרן היסוד",
    "רביקוביץ דליה",
    "רובינא חנה",
    "רובינשטיין יהושע",
    "רודבסקי יוסף",
    "רוטשילד",
    "רום יעל",
    "רותם",
    "רחל המשוררת",
    "רחל ינאית בן צבי",
    "רימון",
    "רקפת",
    "ש וילות",
    "שאול",
    "שביל התפוזים",
    "שד אליהו",
    "שד בגין מנחם",
    "שד ויצמן",
    "שד מוטה גור",
    "שד רפאל סויסה",
    "שווילי הדסה",
    "שוחט מניה",
    "שחם יואב",
    "שכ בר לב",
    "שמיר דב",
    "שמר נעמי",
    "שקד",
    "שקולניק אפרים",
    "שרשבסקי גרשון",
    "תמר",
    'תרמ"ג',
  ],
  מזרע: ["מזרע"],
  מזרעה: [
    "אבו בקר אלסדיק",
    "אבן סינא",
    "אבן רושד",
    "אלאודבאא",
    "אלאזהאר",
    "אלאנביאא",
    "אלאנסאר",
    "אלאסבאט",
    "אלאסמעי",
    "אלאשג'אר",
    "אלבאשא",
    "אלבוחתורי",
    "אלג'אחז",
    "אלואדי",
    "אלוורוד",
    "אלזהראוי",
    "אלזיתון",
    "אלח'וארזמי",
    "אלח'נסאא",
    "אלחואריין",
    "אלטאחון",
    "אליאסמין",
    "אלמואמנין",
    "אלמוהאג'רין",
    "אלמותנביא",
    "אלמנארה",
    "אלמעסרה",
    "אלמקאטע",
    "אלנח'יל",
    "אלנרג'ס",
    "אלסבאר",
    "אלסחאבה",
    "אלעולמאא",
    "אלפוקהאא",
    "אלפרזדק",
    "אלקורטבי",
    "אלקנאטר",
    "אלראזי",
    "אלריאחין",
    "אלשאחוט",
    "אלשועראא",
    "אלתין",
    "דאר אלווקף",
    "מזרעה",
    "עומר בן אלח'טאב",
    "עותמאן בן עפאן",
    "עלי בן אבו טאלב",
    "ראס אלמקסר",
  ],
  מחולה: ["מחולה"],
  "מחנה הילה": ["מחנה הילה"],
  "מחנה טלי": ["מחנה טלי"],
  "מחנה יהודית": ["מחנה יהודית"],
  "מחנה יוכבד": ["מחנה יוכבד"],
  "מחנה יפה": ["מחנה יפה"],
  "מחנה יתיר": ["מחנה יתיר"],
  "מחנה מרים": ["מחנה מרים"],
  "מחנה תל נוף": ["מחנה תל נוף"],
  מחניים: ["בית ראשון במולדת", "מחניים"],
  מחסיה: ["מחסיה"],
  מטולה: [
    "אזור תעשייה",
    "האיכר",
    "האירוס",
    "הארזים",
    "האשד",
    "הבלוע",
    "הגולן",
    "הגורן",
    "הגפן",
    "הדובדבן",
    "הזית",
    "החורשה",
    "החצבים",
    "החקורה",
    "החרמון",
    "הטחנה",
    "הכלנית",
    "הלבנון",
    "המורג",
    "המעיין",
    "המפל",
    "הנדיב",
    "הנורית",
    "הנרקיס",
    "הסביון",
    "הסחלב",
    "הערבה",
    "הצבעונים",
    "הר הצפייה",
    "הראשונים",
    "הרחבה חדשה",
    "הרימון",
    "הרקפת",
    "השמורה",
    "התאנה",
    "התנור",
    "מטולה",
    "מעלה ארז",
    "מעלה דדו",
    "מעלה הצפיה",
    "מצפה החולה",
    "מצפה נפתלי",
    "סמ הבוסתן",
    "עיון",
    "ש דרום",
    "ש עמידר",
    "שכ הזיתים",
    "שכ הרחבה ג",
  ],
  מטע: ["מטע"],
  "מי עמי": ["מי עמי"],
  מיטב: ["מיטב"],
  מייסר: ["מייסר"],
  מיצר: ["מיצר"],
  מירב: ["מירב"],
  מירון: [
    "בר יוחאי",
    "הבנים",
    "הגפן",
    "הזוהר",
    "הזית",
    "החרוב",
    "המייסדים",
    "המעיין",
    "הרב צבי נריה",
    "התנאים",
    "חטיבה שבע",
    "מירון",
    'מעלה הרשב"י',
    "משחררי מירון",
  ],
  מישר: [
    "האנפה",
    "הדוכיפת",
    "הדרור",
    "הזמיר",
    "העפרוני",
    "הצופית",
    "השחף",
    "השלדג",
    "מישר",
    "שיכון בנים",
  ],
  מיתר: [
    "אורן",
    "איה",
    "אילות",
    "אירוס",
    "אלה",
    "אלון",
    "אנפה",
    "ארז",
    "אשכול",
    "אשל",
    "בארי",
    "בית אשל",
    "ברבור",
    "בשמת",
    "גאון הירדן",
    "גבולות",
    "גבתון",
    "גפן",
    "גרופית",
    "גרר",
    "דוכיפת",
    "דיה",
    "דן",
    "דפנה",
    "דרורית",
    "דרך הארץ",
    "דרך הבשור",
    "דרך הדרור",
    "דרך הראל",
    "דרך יתיר",
    "דרך מיתר",
    "הדס",
    "זהבן",
    "זוהר",
    "זית",
    "חוגלה",
    "חוחית",
    "חופית",
    "חלוצה",
    "חלמונית",
    "חלמיש",
    "חסידה",
    "חצב",
    "חצבה",
    "חצוצרן",
    "חצרים",
    "ינשוף",
    "יסעור",
    "יעל",
    "יעלים",
    "כובשים",
    "כחל",
    "כיסופים",
    "כלנית",
    "כרכום",
    "לבנית",
    "מגלן",
    "מגן",
    "מורג",
    "מיתר",
    "ממשית",
    "מצדה",
    "משאבים",
    "משמר",
    "משעול יפתח",
    "משעול לאה",
    'משעול פלמ"ח',
    "נגבה",
    "נועם",
    "נורית",
    "נחליאלי",
    "ניצנה",
    "נירים",
    "נשר",
    "סלעית",
    "סנונית",
    "סעד",
    "עבדת",
    "עגור",
    "עופרים",
    "עורבני",
    "עיט",
    "עיטם",
    "עין בוקק",
    "עין זיק",
    "עין מור",
    "עין נטפים",
    "עירית",
    "עלומים",
    "עפרוני",
    "עציון גבר",
    "עצמון",
    "עקרב",
    "ערוגות",
    "ערוד",
    "פארן",
    "פרג",
    "פשוש",
    "צאלה",
    "צאלים",
    "צבאים",
    "צבי",
    "צבעוני",
    "צופית",
    "צופר",
    "צוקית",
    "צין",
    "צניפים",
    "צקלג",
    "קדש ברנע",
    "קורא",
    "קטה",
    "קטורה",
    "קנאים",
    "קציעות",
    "קרקל",
    "ראם",
    "רביבים",
    "רימון",
    "רמים",
    "רעים",
    "רקפת",
    "שבטה",
    "שדמית",
    "שדרות הזמיר",
    "שדרות היונה",
    "שדרות המייסדים",
    "שדרות השלום",
    "שדרות חברון",
    "שדרות כרמית",
    "שדרות עומרים",
    "שובל",
    "שחף",
    "שיזף",
    "שיטה",
    "שכ הדרור",
    "שכ הכלניות",
    "שכ רבין",
    "שלדג",
    "שלו",
    "שקד",
    "שקמה",
    "שקנאי",
    "תאנה",
    "תור",
    "תמירון",
    "תמנע",
    "תמר",
  ],
  מכורה: ["מכורה"],
  מכחול: ["מכחול"],
  מכמורת: [
    "אלמוג",
    "דולפין",
    "דרך החוף",
    "דרך הים",
    "הדולב",
    "המפתח",
    "העוגן",
    "הפרדס",
    "השחף",
    "כוכב הים",
    "מכמורת",
    "מעלה האשלים",
    "סמ הדייגים",
    "שביל השקיעה",
    "שונית",
    "שלדג",
  ],
  מכמנים: [
    "איפה הילד",
    "זמר עברי",
    "להקת הנחל",
    "להקת כוורת",
    "להקת משינה",
    "להקת צלילי הכרם",
    "להקת תיסלם",
    "להקת תמוז",
    "מכמנים",
    "שלישיית גשר הירקון",
  ],
  מלאה: ["הגפן", "הזית", "מלאה", "רימון", "תאנה"],
  מלילות: ["מלילות"],
  מלכיה: ["מלכיה"],
  מלכישוע: ["מלכישוע"],
  מנוחה: ["הבאר", "הבנים", "הכפר", "המגל", "המייסדים", "מנוחה"],
  מנוף: ["מנוף"],
  מנות: ["מנות"],
  מנחמיה: [
    "הבנים",
    "הגפן",
    "הדוכיפת",
    "הדקל",
    "ההדרים",
    "הזיתים",
    "החקלאי",
    "הירדן",
    "הכלנית",
    "המייסדים",
    "הרימון",
    "הרקפת",
    "התאנה",
    "מנחמיה",
    "משעול הדרור",
    "משעול הזמיר",
    "משעול הסנונית",
    "משעול העפרוני",
    "משעול כנרית",
    "משעול שלדג",
    "עמק הירדן",
    "קרן היסוד",
    "ש וילות",
    "שבעת הבתים",
    "שכ עופר",
  ],
  מנרה: ["בית ראשון במולדת", "מנרה"],
  "מנשית זבדה": [
    "האלון",
    "הורדים",
    "הזית",
    "הטווס",
    "היונה",
    "המלאכה",
    "העם",
    "השלום",
    "התקווה",
    "מנשית זבדה",
  ],
  מסד: ["מסד"],
  מסדה: ["בית ראשון במולדת", "מסדה"],
  מסילות: ["מסילות"],
  "מסילת ציון": [
    "הר יעלה",
    "הר סנסן",
    "הר עוזרר",
    "מלבר",
    "מסילת ציון",
    "סמ מאלה",
    "קוצ'ין",
    "קרלה",
  ],
  מסלול: ["מסלול"],
  מסעדה: ["מסעדה"],
  "מסעודין אל-עזאזמה": ["מסעודין אל-עזאזמה"],
  מעברות: ["מעברות"],
  מעגלים: ["החבצלת", "הנרקיס", "הערבה", "השיטה", "מעגלים"],
  מעגן: ["בית ראשון במולדת", "מעגן"],
  "מעגן מיכאל": ["אולפן קיבוץ", "ביס שדה", "מעגן מיכאל"],
  "מעוז חיים": ["בית ראשון במולדת", "מעוז חיים"],
  מעון: ["מעון"],
  מעונה: [
    "אלון",
    "הברוש",
    "הגפן",
    "הדולב",
    "הזית",
    "הכרמל",
    "כליל החורש",
    "מעונה",
    "תמר",
  ],
  מעיליא: ["מעיליא"],
  "מעין ברוך": ["מעין ברוך"],
  "מעין צבי": ["מעין צבי"],
  "מעלה אדומים": [
    "אבני החושן",
    "אגן הסהר",
    "אוגדה",
    "אופירה",
    "איבי הנחל",
    "בית בלתין",
    "בת נדיב",
    "גבעת המיסדים",
    "דקלה",
    "דרך הגיא",
    "דרך הר הבית",
    "דרך הר הצופים",
    "דרך ימית",
    "דרך מדבר יהודה",
    "דרך מצפה נבו",
    "דרך קדם",
    "האבן",
    "האורן",
    "האזוב",
    "האלה",
    "האלמוג",
    "הארד",
    "האשל",
    "הבדולח",
    "הבזלת",
    "הגביש",
    "הגומא",
    "הגזית",
    "הגילגל",
    "הגיר",
    "הגעש",
    "הגרניט",
    "הגתית",
    "הדקל",
    "הזוגן",
    "הזיתים",
    "החומה",
    "החורן",
    "החליל",
    "החלמונית",
    "החלמיש",
    "החצוצרה",
    "החרסית",
    "הכורכר",
    "הכינור",
    "הכרכום",
    "המצדים",
    "המצוק",
    "המצילתים",
    "המרווה",
    "הנבל",
    "הנחושת",
    "הנחלים",
    "הנטיף",
    "הניקרה",
    "העוגב",
    "העירית",
    "הענבל",
    "הערבה",
    "העשור",
    "הפעמון",
    "הפרג",
    "הצוק",
    "הצור",
    "הצורן",
    "הציפחה",
    "הצלף",
    "הצלצל",
    "הצפצפה",
    "הקירטון",
    "הקנה",
    "הקרן",
    "הקתרוס",
    "הר גדור",
    "הר מיכוור",
    "הר סרטבה",
    "הרותם",
    "הרכס",
    "השונית",
    "השופר",
    "השחם",
    "השיזף",
    "השיטה",
    "השיש",
    "השמינית",
    "השקמה",
    "השרון",
    "השרך",
    "התוף",
    "התלתן",
    "חגוי הסלע",
    "חוט השני",
    "חולית",
    "חלוקי הנחל",
    "חרובית",
    "יהלום",
    "כוכב הירדן",
    "ככר יהלום",
    "מבוא בורות המים",
    "מבוא הגבים",
    "מבוא הורקניה",
    "מבוא המיתנן",
    "מבוא המלוח",
    "מבוא הסוף",
    "מבוא הרודיון",
    "מדליקי המשואות",
    "מישור אדמים",
    "מעלה אדומים",
    "נאות סיני",
    "נביעות",
    "נופי הסלע",
    "נתיב אפיקים",
    "נתיב האפוד",
    "נתיב העשרה",
    "נתיב הקרונית",
    "נתיב משואה",
    "סופה",
    "סמ אודם",
    "סמ ברקת",
    "סמ לשם",
    "סמ נופך",
    "סמ נחל אוג",
    "סמ נחל בוקק",
    "סמ נחל גורפן",
    "סמ נחל דרגות",
    "סמ נחל הרדוף",
    "סמ נחל ורדית",
    "סמ נחל זוהר",
    "סמ נחל חבר",
    "סמ נחל טור",
    "סמ נחל יעלים",
    "סמ ספיר",
    "סמ פטדה",
    "סמ שוהם",
    "סמ תרשיש",
    "עצמונה",
    "עת הזמיר",
    "פרי גן",
    "פרי מגדים",
    "פריאל",
    "צמח השדה",
    "קדש ברנע",
    "קול התור",
    "שד החברה הכלכלית",
    "שדות",
    "שכ אבני החושן",
    "שכ המצדים",
    "שכ הנחלים",
    "שכ כלי שיר",
    "שכ מגדים",
    "שכ מצפה נבו",
    "שכ משואה",
    "שכ נופי הסלע",
    "שכ צמח השדה",
    "שלהבת",
    "תלמי יוסף",
  ],
  "מעלה אפרים": [
    "אזור התעשיה",
    'בי"ס שדה',
    "גאון הירדן",
    "דרך בני אפרים",
    "דרך הגלעד",
    "דרך מנשה",
    "דרך משואה",
    "מבוא הבושם",
    "מגורונים",
    "מעלה אפרים",
  ],
  "מעלה גלבוע": ["מעלה גלבוע"],
  "מעלה גמלא": ["מעלה גמלא"],
  "מעלה החמישה": ["מעלה החמישה"],
  "מעלה לבונה": ["הכרכום", "המור", "הצורי", "הקטורת", "הקינמון", "מעלה לבונה"],
  "מעלה מכמש": [
    "אריאל",
    "דרך השלום",
    "האורן",
    "האחווה",
    "הגפן",
    "הדביר",
    "הראל",
    "ידידיה",
    "יפה נוף",
    "כליל החורש",
    "כלילת יופי",
    "מוריה",
    "מעלה מכמש",
    "סלעית",
    "שדרות ירושלים",
    "שדרות ציון",
    "שלם",
  ],
  "מעלה עירון": [
    "אבו בכר-סאלם",
    "אבו שהאב-מוסמוס",
    "אגבריה-מוסמוס",
    "אגבריה-מושירפה",
    "אזור התעשייה-סאלם",
    "אלמורתפעה-זלפה",
    "אלמנשיה-מושירפה",
    "ביאדה",
    "ג'בארין-מושירפה",
    "דרויש-סאלם",
    "הורדים-מוסמוס",
    "הכפר הישן-מושירפה",
    "זלפה",
    "מוסמוס-דרום מזרח",
    "מוצמוץ",
    "מושיירפה",
    "מחאג'נה-מוסמוס",
    "מעלה עירון",
    "סאלם",
    "סביחאת-סאלם",
    "עיאש-סאלם",
    "רפאעי-סאלם",
    "שכ ביאדה",
    "שכונה מזרחית-זלפה",
    "שכונה מערבית-זלפה",
    "שרקאווי-מוסמוס",
  ],
  "מעלה עמוס": [
    "אבן עזרא",
    "יונתן בן עוזיאל",
    "מעלה עמוס",
    "סמטת אונקלוס",
    "רבנו בחיי",
    'רמב"ן',
    'רש"י',
    'רשב"ם',
    "שכ איבי הנחל",
  ],
  "מעלה שומרון": [
    "אפיקים",
    "ארנון",
    "דן",
    "דפנה",
    "דרגות",
    "הבשור",
    "הירדן",
    "יסמין",
    "יעלים",
    "כיתן",
    "לבונה",
    "מעלה שומרון",
    "נעמן",
    "סמדר",
    "צאלים",
    "שיאון",
    "שניר",
    "שקמה",
    "תרצה",
  ],
  "מעלות-תרשיחא": [
    "אבו אלעלא אלמערי",
    "אבן סינא",
    "אודם",
    "אזור התעשייה",
    "אחוות עמים",
    "אחמד שאוקי",
    "אל-זיתון",
    "אלון",
    "אליא אבו מאדי",
    "אלמוג",
    "אלמותנבי",
    "אסיף",
    "אסתר המלכה",
    'אצ"ל',
    "ארבל",
    "אשחר",
    "אשכולות",
    "אתרוג",
    "בלסם",
    "בן גוריון",
    "בציר",
    "ברקת",
    "גבעת האירוס",
    "גבעת הזיתים",
    "גבעת הסביונים",
    "גבעת הרקפות",
    "גבעת רבין",
    'גבעת תע"ש',
    "גולן",
    "גזית",
    "דאבשה",
    "דובדבן",
    "דוד המלך",
    "דולב",
    "דפנה",
    "דרך יעקב חזן",
    "האומנים",
    "האורנים",
    "האיריס",
    "האלה",
    "הארזים",
    "הגבעה",
    "הגליל",
    "הדסים",
    "הדרים",
    "ההגנה",
    "הורד",
    "הורדים",
    "הזית",
    "החצב",
    "החרוב",
    "החרצית",
    "החשמונאים",
    "החשמל",
    "הכלנית",
    "הכנסיות",
    "המלאכה",
    "המסגר",
    "המעפילים",
    "הסחלב",
    "הסיגליות",
    'הפלמ"ח',
    "הצבעוני",
    "הרב משאש",
    "הרב קוק",
    "הרדוף",
    "הרותם",
    "הרצל",
    "הרקפת",
    "השומר",
    "השוק",
    "השכונה",
    "השלושה",
    "השקדים",
    "התאנה",
    "התומר",
    "התעשיינים",
    "ז'בוטינסקי",
    "חבצלת",
    "חליל ג'ובראן",
    "חרושת",
    "חרמון",
    "טוליפ",
    "יסמין",
    "יקינטון",
    "ירדן",
    "כזיב",
    "כיכר אלי בניטה",
    "כיכר אפריים קישון",
    "כיכר הדקל",
    "כיכר הוטרנים",
    "כיכר הכרמים",
    "כיכר הרב יוסף גבאי",
    "כיכר חנה סנש",
    "כיכר יצחק שמיר",
    "כיכר תרשיחא",
    "ככר אגוז",
    "ככר איתנים",
    "ככר גרס",
    "ככר היצירה",
    "ככר המזרקה",
    "ככר המייסדים",
    "ככר המרכז",
    "ככר הראשונים",
    "ככר ירושלים",
    "ככר נחשון",
    "ככר נשיאי ישראל",
    "ככר פרפיניון",
    "ככר קרן היסוד",
    "ככר רוטרי",
    "ככר רחבעם זאבי",
    "כליל החורש",
    "כרכום",
    "כרמל",
    "לוטוס",
    "לוי אשכול",
    'לח"י',
    "ליבנה",
    "מורד הגיא",
    "מורן",
    "מירון",
    "מעלה אל מג'אהד",
    "מעלה גולני",
    "מעלה האלון",
    "מעלה הבנים",
    "מעלה הברוש",
    "מעלה הגפן",
    "מעלה הדליה",
    "מעלה ההר",
    "מעלה הכרמים",
    "מעלה המגדל",
    "מעלה המדרגות",
    "מעלה המסגד",
    "מעלה המעיין",
    "מעלה הפרח",
    "מעלה הרב ניסים",
    "מעלה התאנה",
    "מעלה ורד",
    "מעלה חסון",
    "מעלה יפה נוף",
    "מעלות-תרשיחא",
    "מצדה",
    "מרווה",
    "משעול אדמונית החורש",
    "משעול אל-ביאדר",
    "משעול אלומות",
    "משעול בכור האביב",
    "משעול גבעונית",
    "משעול דם המכבים",
    "משעול האסיף",
    "משעול האשל",
    "משעול הבוסתן",
    "משעול הביכורים",
    "משעול הברוש",
    "משעול הברקן",
    "משעול הגומא",
    "משעול הגורן",
    "משעול הגעתון",
    "משעול הדקל",
    "משעול היביסקוס",
    "משעול הידידות",
    "משעול הכרמים",
    "משעול הלוטם",
    "משעול המוסיקה",
    "משעול המרגנית",
    "משעול הנעמן",
    "משעול הסנונית",
    "משעול הערבה",
    "משעול הערמונים",
    "משעול הפעמונים",
    "משעול הקישון",
    "משעול השחף",
    "משעול השיטה",
    "משעול השלדג",
    "משעול השקד",
    "משעול השקמה",
    "משעול התאנים",
    "משעול התמרים",
    "משעול וינקה",
    "משעול זמורה",
    "משעול חלמונית",
    "משעול טהא חוסיין",
    "משעול טפח",
    "משעול יערה",
    "משעול כרמלית",
    "משעול לילך",
    "משעול מקור החסידה",
    "משעול נץ החלב",
    "משעול סביון",
    "משעול סייפן",
    "משעול סתונית",
    "משעול ערמונית",
    "משעול פעמונית",
    "משעול צבר",
    "משעול קטלב",
    "משעול רמונית",
    "משעול שיזף",
    "משקי עזר",
    "נג'יב מחפוז",
    "נוף האגם",
    "נורית",
    "ניו יורק",
    "ניזאר קבאני",
    "ניירוז",
    "נרקיס",
    "נרקיס עוזי",
    "ספיר",
    "עדעד",
    "עירית",
    "עלית הנוער",
    "עמוס לסקוב",
    "פקיעין",
    "צורית",
    "ציפורן",
    "קהילת יהדות צרפת",
    "קורנית",
    "קטיף",
    "קציר",
    'קק"ל',
    "קרית חנוך",
    "קרן היסוד",
    'רא"ל לסקוב חיים',
    "רוזמרין",
    "רימון",
    "רמת הנשיא שמעון פרס",
    "שאול המלך",
    "שביל אלחרירי",
    "שביל אלסוסן",
    "שביל האחווה",
    "שביל האיריס",
    "שביל המסגדים",
    "שביל הפרחים",
    "שביל התקווה",
    "שביל רימונים",
    "שד הנשיא הרצוג",
    "שד ירושלים",
    "שוהם",
    'שז"ר זלמן',
    "שיבולת",
    "שיבת ציון",
    "שכ בן גוריון",
    "שכ בנה ביתך א",
    "שכ בנה ביתך ב",
    "שכ גלעד",
    "שכ דדו",
    "שכ האילנות",
    "שכ הורדים",
    "שכ הסיגליות",
    "שכ הראשונים",
    "שכ הרב קוק",
    "שכ הרצל",
    "שכ ואדי קורן",
    "שכ יפה נוף",
    "שכ מעלה חסון",
    "שכ קרן היסוד",
    "שכונת כהן",
    "שכונת עמית",
    "שלהבית",
    "שלמה המלך",
    "שניר",
    "שרירא שלמה",
    "תבור",
    "תדהר",
    "תובל",
    "תופיק אלחכים",
    "תמר",
    "תעשייה",
    "תרשיחא",
  ],
  מענית: ["גבעת חביבה", "מענית"],
  מעש: [
    "אילת",
    "האורנים",
    "האילנות",
    "הברוש",
    "ההדרים",
    "הכלנית",
    "הצבעוני",
    "השלושה",
    "זרובבל",
    "מעש",
  ],
  מפלסים: ["מפלסים"],
  "מצדות יהודה": ["מצדות יהודה"],
  מצובה: ["מצובה"],
  מצליח: [
    "הגפן",
    "הדס",
    "הרימון",
    "התאנה",
    "התמר",
    "מצליח",
    "סמטת האילן",
    "סמטת הדקל",
    "סמטת הערבה",
    "עופרה",
  ],
  מצפה: ["מצפה"],
  'מצפה אבי"ב': ['מצפה אבי"ב'],
  "מצפה אילן": ["מצפה אילן", "מצפה עירון"],
  "מצפה יריחו": [
    "אבני מילואים",
    "אודם",
    "אורן",
    "אלה",
    "ביכורים",
    "ברוש",
    "ברקת",
    "גבעת ברקאי",
    "גלעד",
    "גפן",
    "דביר",
    "דקל",
    "דרך חיים",
    "האפוד",
    "הדס",
    "החושן",
    "המוריה",
    "הקטורת",
    "יהלום",
    "כיפת הירדן",
    "מבוא ישפה",
    "מבוא לשם",
    "מעלה אריאל",
    "מצפה יריחו",
    "נחל ארנון",
    "נחל פרת",
    "עולי רגלים",
    "עומר",
    "שדרות ירושלים",
    "שוהם",
    "שירת הלויים",
    "שתי הלחם",
    "תרשיש",
  ],
  "מצפה נטופה": ["מצפה נטופה"],
  "מצפה רמון": [
    "אזור התעשיה",
    'בי"ס שדה הר הנגב',
    "גנדי (רחבעם זאבי)",
    "דרך בראשית",
    "דרך מצפה הכוכבים",
    "דרך צלילי מדבר",
    "דרך רמון",
    "דרך שפת המדבר",
    "האירוס",
    "החצב",
    "הצבעוני",
    "הר אלדד",
    "הר ארדון",
    "הר בוקר",
    "הר חמת",
    "הר נפחא",
    "הר עודד",
    "הר עריף",
    "הר צין",
    "הר קטום",
    "הר קירטון",
    "הר שגיא",
    "השיטה",
    "חוות האלפקות",
    "טיילת אלברט כץ",
    "כלא נפחא",
    "מושבת האומנויות",
    "מחנה טלי",
    "מעלה בן תור",
    "מעלה הדקל",
    "מעלה מחמל",
    "מצפה רמון",
    "נחל אילות",
    "נחל ברק",
    "נחל גוונים",
    "נחל גרופית",
    "נחל האלה",
    "נחל ורדית",
    "נחל חוה",
    "נחל חולית",
    "נחל חמדה",
    "נחל טרשים",
    "נחל כרכום",
    "נחל מישר",
    "נחל מנוחה",
    "נחל ניצנה",
    "נחל נקרות",
    "נחל סירה",
    "נחל סלעית",
    "נחל סרפד",
    "נחל ערבה",
    "נחל ערוד",
    "נחל פארן",
    "נחל ציה",
    "נחל ציחור",
    "נחל צניפים",
    "נחל רעים",
    "נחל רעף",
    "סוכה במדבר",
    "סמטת קרני רמון",
    "עין המערה",
    "עין זיק",
    "עין מור",
    "עין משק",
    "עין סהרונים",
    "עין עבדת",
    "עין עופרים",
    "עין עקב",
    "עין שביב",
    "עין שחק",
    "רמת ספיר",
    "ש צבא קבע",
    "שד בן גוריון",
    "שד הר הגמל",
    'שדרות קק"ל',
    "שכון ד",
  ],
  "מצפה שלם": ["מצפה שלם"],
  מצר: ["מצר"],
  "מקווה ישראל": [
    "דרך הבנים",
    "דרך הכרמים",
    "כביש המאה",
    "מעלה הדקלים",
    "מעלה נטר",
    "מקווה ישראל",
    'משעול תר"ל',
    "ש מורים",
    "שד הדוידקה",
    "שד הותיקים",
    'שד כי"ח',
    "שד קראוזה",
  ],
  מרגליות: ["מרגליות"],
  "מרום גולן": ["מרום גולן"],
  "מרחב עם": ["מרחב עם"],
  "מרחביה (מושב)": [
    "בתי הראשונים",
    "הברוש",
    "הגורן",
    "הגפן",
    "הזיתים",
    "החיטה",
    "המושבה",
    "השעורה",
    "יפה נוף",
    "מול ההר",
    "מול העמק",
    "מרחביה (מושב)",
    "סמטת הדגן",
  ],
  "מרחביה (קיבוץ)": ["אולפן", "בית ראשון במולדת", "מרחביה (קיבוץ)"],
  "מרכז שפירא": [
    "אחוזת אתרוג",
    "דגנים",
    "האתרוג",
    "הגפן",
    "הזית",
    "החיטה",
    "המייסדים",
    "הרימון",
    "השעורה",
    "התאנה",
    "התמר",
    "לבונה",
    "מגדים",
    "מרכז שפירא",
    "עציון",
    "רננים",
    "שבעת המינים",
  ],
  "משאבי שדה": ["בית ראשון במולדת", "משאבי שדה"],
  "משגב דב": [
    "הבוצר",
    "הבוקר",
    "החורש",
    "היוגב",
    "הכורם",
    "המוסק",
    "הנוטע",
    "הרועה",
    "השותל",
    "משגב דב",
  ],
  "משגב עם": ["בית ראשון במולדת", "משגב עם"],
  משהד: [
    "אבו בכר אלסדיק",
    "אבו זערור",
    "אבו סלמא",
    "אבן - זיידון",
    "אבן - ח'ולד",
    "אבן - ח'לדון",
    "אבן - סינא",
    "אבן - עפאן",
    "אבן - רושד",
    "אבראהים טוקאן",
    "אוקחואן",
    "אל - אזהר",
    "אל - אח'טל",
    "אל - אקסא",
    "אל - ארז",
    "אל - באז",
    "אל - באשק",
    "אל - בוח'ארי",
    "אל - בטוף",
    "אל - ביארק",
    "אל - ג'ליל",
    "אל - דבה",
    "אל - הודהוד",
    "אל - הייס",
    "אל - וחדה",
    "אל - זנבק",
    "אל - ח'וזאם",
    "אל - ח'ולח'אל",
    "אל - ח'טאף",
    "אל - ח'נסאא",
    "אל - ח'רוב",
    "אל - חבק",
    "אל - חג'ל",
    "אל - חדת'ה",
    "אל - חסון",
    "אל - חרירי",
    "אל - טאא'י",
    "אל - טאבון",
    "אל - טברי",
    "אל - ימאמה",
    "אל - ירמוק",
    "אל - כרמל",
    "אל - מג'ויז",
    "אל - מדינה",
    "אל - מחג'ר",
    "אל - מטל",
    "אל - מנארה",
    "אל - מערי",
    "אל - נח'לה",
    "אל - נסרה",
    "אל - נעאמה",
    "אל - סבר",
    "אל - סואר",
    "אל - סוחסלייה",
    "אל - סנאבל",
    "אל - סקאקיני",
    "אל - סקר",
    "אל - עג'מה",
    "אל - עוליקה",
    "אל - עקד",
    "אל - פאנוס",
    "אל - פאראבי",
    "אל - פרזדק",
    "אל - קאדסיה",
    "אל - קאהרה",
    "אל - קודס",
    "אל - קלעה",
    "אל - קסטל",
    "אל - ראיה",
    "אל - שאפעי",
    "אל - שג'רה",
    "אל - שהיד",
    "אל - תמר",
    "אל-ג'אמע אל-קדים",
    "אל-חמאים",
    "אלביאדר",
    "אלביארה",
    "אלברקוק",
    "אלדהור",
    "אלדורה",
    "אלורוד",
    "אלזיתון",
    "אלחארה אלפוקא",
    "אלחג'ה",
    "אלחור",
    "אלטיון",
    "אלמהא",
    "אלמוכתאר",
    "אלמעסרה",
    "אלנבעה",
    "אלספחה",
    "אלעמאיר",
    "אלרים",
    "אלרינה",
    "אלשיך",
    "אלשמאליה",
    "אלתאנויה",
    "בג'דאד",
    "בדר",
    "בטריק",
    "ביילסאן",
    "ביירות",
    "ביסאן",
    "בלאבל",
    "בלאל",
    "בלוט",
    "בנו - הלאל",
    "ג'אמע אלנור",
    "ג'בל סיח'",
    "ג'ובראן",
    "ג'ורי",
    "ג'רג'יר",
    "ג'ריר",
    "גת חאפר",
    "ד'י יזן",
    "דאהר אל עומר",
    "דום",
    "דלבוז",
    "דמשק",
    "דרויש מחמוד",
    "הליון",
    "ואדי סאלם",
    "ואדי סמעאן",
    "זאן",
    "זופה",
    "זעתר",
    "זרזור",
    "ח'מיס",
    "חטין",
    "חיפה",
    "טוקאן פדוא",
    "יאסמין",
    "כמון",
    "כנא",
    "כנארי",
    "כסתנא",
    "כרואן",
    "כרז",
    "כרכדן",
    "לובנאן",
    "לוז",
    "למון",
    "מג'ידל",
    "מיי זיאדה",
    "מכה",
    "מנדלינא",
    "מנת'ור",
    "מעלול",
    "מרג' אבן עאמר",
    "משהד",
    "נענע",
    "סאוסאן",
    "סדר",
    "סייד דרויש",
    "סיידנא עומר",
    "סיידנא עלי",
    "סירין",
    "סלאח אל דין",
    "סנדיאן",
    "סנונו",
    "סעד - זג'לול",
    "ספורייה",
    "עבד - אלוהאב",
    "עבד - אלנאסר",
    "עבד אללה מוסטפא",
    "עולם",
    "עומר אלמח'תאר",
    "עיון - ג'נאן",
    "עין - דבאש",
    "עין אלבידה",
    "עכא",
    "עמאן",
    "ענבר",
    "פיירוז",
    "פלסטין",
    "קורונפול",
    "קראסיה",
    "קרבונה",
    "ראג'ב",
    "ראס אלמנטאר",
    "רומאן",
    "ריחאן",
    "שחרור",
    "ת'ריא",
    "תוניס",
    "תות",
    "תין",
  ],
  משואה: ["משואה"],
  "משואות יצחק": [
    "האורן",
    "האשל",
    "הדקל",
    "הורד",
    "הזית",
    "החצב",
    "החרוב",
    "החרצית",
    "היקינטון",
    "הנורית",
    "הנרקיס",
    "הסתוונית",
    "הצבעוני",
    "הרימון",
    "התות",
    "משואות יצחק",
    "סמטת האיריס",
    "סמטת החבצלת",
    "סמטת השושן",
    "סמטת כלנית",
    "סמטת רקפת",
  ],
  משכיות: ["משכיות"],
  "משמר איילון": ["משמר איילון"],
  "משמר דוד": ["משמר דוד"],
  "משמר הירדן": ["משמר הירדן"],
  "משמר הנגב": ["אולפן", "בית ראשון במולדת", "משמר הנגב"],
  "משמר העמק": ["מרכז קליטה", "משמר העמק"],
  "משמר השבעה": [
    "אבטיחי מנחם",
    "איבניצקי דוד",
    "דולב",
    "הגפן",
    "הדס",
    "ההדרים",
    "הורד",
    "הזית",
    'המ"ג',
    "השיטה",
    "זעירא",
    "משמר השבעה",
    "סמ יצחק",
    "עולמי יעקב",
    "קורדובה יצחק",
    "רוזנבלום קלמן",
    "שד הדקלים",
    "שיכון הבנים",
    "שמיר אליהו",
    "שקד",
    "תמר",
  ],
  "משמר השרון": ["אולפן", "משמר השרון"],
  משמרות: ["משמרות"],
  משמרת: ["משמרת"],
  משען: ["משען"],
  מתן: [
    "אורן",
    "איריס",
    "אלון",
    "הגפן",
    "הדס",
    "הדר",
    "הזית",
    "התאנה",
    "ורד",
    "חבצלת",
    "חלמיש",
    "חצב",
    "כלנית",
    "מור",
    "מתן",
    "נוף השרון",
    "נורית",
    "נרקיס",
    "סביון",
    "צבעוני",
    "צור",
    "רותם",
    "רימון",
    "רקפת",
    "שושן",
  ],
  מתת: ["מתת"],
  מתתיהו: [
    'האדמו"ר מצאנז',
    "הרב אברמסקי יחזקאל",
    "הרב גיפטר מרדכי",
    "הרב דסלר",
    "הרב הולנדר יצחק",
    "הרב ווסרמן",
    "הרב מאלין",
    "הרב מילר אביגדור",
    "הרב סגל",
    "הרב סילבר אליעזר",
    "הרב פיינשטיין משה",
    "הרב קוטלר אהרון",
    "הרב קמניצקי יעקב",
    "מתתיהו",
  ],
  "נאות גולן": ["נאות גולן"],
  "נאות הכיכר": ["נאות הכיכר"],
  "נאות מרדכי": ["בית ראשון", "נאות מרדכי"],
  "נאות סמדר": ["נאות סמדר"],
  נאעורה: ["נאעורה"],
  נבטים: ["אולפן", "נבטים"],
  נגבה: ["נגבה"],
  נגוהות: ["נגוהות"],
  נהורה: [
    "דרך הפרחים",
    "האילנות",
    "הברוש",
    "הגפן",
    "הדס",
    "הדקל",
    "הזית",
    "החיטה",
    "היסמין",
    "הנרקיס",
    "הרימון",
    "השושנה",
    "השעורה",
    "השקד",
    "התאנה",
    "התמר",
    "כלנית",
    "לילך",
    "נהורה",
    "צבעוני",
    "רקפת",
    "שכ קרוונים",
  ],
  נהלל: ["ביס חקלאי תיכון", "נהלל"],
  נהריה: [
    "אביר יעקב",
    "אדווה",
    "אדמית",
    "אהוד מנור",
    "אזור בנייה צפון",
    "אזור תעשיה צפון",
    "אחד העם",
    "איסי לה מולינו",
    "איריס",
    "אירית",
    "אלי כהן",
    "אלי עמיר",
    "אליאס קלימיאן",
    "אלמוג",
    "אמנון",
    "אנילביץ",
    "ארז ביטון",
    "ארלוזורוב",
    "בוסתן ספרדי",
    "בורוכוב",
    'בי"ח הממשלתי',
    "ביאליק",
    "בילפלד",
    "בלפור",
    "בק ליאו",
    "בר יהודה",
    "בר כוכבא",
    "גבעת אוסישקין",
    "גבעת כצנלסון",
    "גבעת רגום",
    "גבעת שרת",
    "גדוד 21",
    "גדולי האומה",
    "גולומב",
    "גורדון",
    "גוש עציון",
    "גרינבוים",
    "גרניום",
    "דוגית",
    "דוד אלעזר",
    "דוד בן גאון",
    "דולב",
    "דרך העצמאות",
    "דרך השומר",
    "דרך השיירה",
    "דרך השלום",
    "דרך יחיעם",
    "דרך סטרומה",
    "דרך שלמה",
    "האדמונית",
    "האורן",
    "האיזדרכת",
    "האלון",
    "הארז",
    "הארנון",
    "האשל",
    "האתרוג",
    "הברוש",
    "הברקן",
    "הגאולה",
    "הגדוד העברי",
    "הגולן",
    "הגלעד",
    "הגמא",
    "הגנים",
    "הגפן",
    "הדבורנית",
    "הדולפין",
    "הדליה",
    "הדרור",
    "ההגנה",
    "ההדס",
    "ההדר",
    "הורד",
    "הזיתים",
    "הזמיר",
    "החזון",
    "החייל",
    "החלוץ",
    "החמנית",
    "החשמונאים",
    "הטפח",
    "היוצרים",
    "הירדן",
    "הירקון",
    "הכלנית",
    "הכרם",
    'הל"ה',
    "הליטים",
    "המגדלור",
    "המגינים",
    "המיסדים",
    "המלך דוד",
    "המעגן",
    "המעפילים",
    "המפרש",
    "המרוה",
    "הנגב",
    "הנורית",
    "הניסנית",
    "הנרקיס",
    "הנשיא",
    "הס משה",
    "הסביון",
    "הסיגלית",
    "הסיפן",
    "הסיתונית",
    "העליה",
    "הערבה",
    'הפלמ"ח',
    "הפנינה",
    "הפעמונית",
    "הפרג",
    "הפרטיזנים",
    "הצדף",
    "הציפורן",
    "הצלף",
    "הצנחנים",
    "הקישון",
    "הר מירון",
    "הר תבור",
    "הרב מימון",
    "הרב קוק",
    "הרדוף",
    "הרימון",
    "הרצוג",
    "הרצל",
    "הרקפת",
    "השושנה",
    "השיקמה",
    "השרון",
    "התאנה",
    "התורן",
    "התות",
    "התלתן",
    "וולפסון",
    "וייסבורג",
    "ויצמן",
    "ז'בוטינסקי",
    "זיסו",
    "זמנהוף",
    "חבצלת החוף",
    "חומה ומגדל",
    "חניתה",
    "חרמון",
    "חרצית",
    "טיילת אופירה נבון",
    "טרומפלדור",
    'י"ד ליל הגשרים',
    "יבנה",
    "יהודה המכבי",
    "יחידה 101",
    "ימבור יוסף",
    "יסמין",
    "יפה נוף",
    "יקינטון",
    "ירושלים",
    "ישורון",
    'כ"ג יורדי הסירה',
    "כוכב הים",
    "ככר הדקל",
    "ככר העיריה",
    "כליל החורש",
    "לבון פנחס",
    "לוחמי הגטאות",
    "לוטוס",
    "לוטם",
    "לוי יוסף",
    "לוין שמריהו",
    "ליבנה",
    "מבצע אנטבה",
    "מבצע חירם",
    "מבצע יונתן",
    "מבצע קדש",
    "מוריה",
    "מלון פלס אתנה",
    "מלון תפוז",
    "מלחמת יום הכיפורים",
    "מנביץ",
    "מסריק",
    "מעוז הים",
    "מצדה",
    "מרגנית",
    "מרכז הקליטה",
    "נאות אלה",
    "נאות ים",
    "נהריה",
    "נווה אלון",
    "נווה יצחק רבין",
    "נווה מנחם",
    "נופר",
    "נורדאו",
    "נחליאלי",
    "נחלת אשר",
    "נחמיה",
    "נחשול",
    "נימרובר",
    "נעמי שמר",
    "נר הלילה",
    "נשר",
    "סולד",
    "סוסקין",
    "סוקולוב",
    "סחלב",
    "סילבר",
    "סירקין נחמן",
    'סמ ביל"ו',
    'סמ ניל"י',
    "סמטת סיני",
    "סמי מיכאל",
    "סנה משה",
    "סנפיר",
    "סנש חנה",
    "עגנון",
    "עדעד",
    "עוזי חיטמן",
    "עזרא",
    "עין התכלת",
    "עמק יזרעאל",
    "פולד ברכה",
    "פורצי כלא עכו",
    "פטוניה",
    "פינסקר",
    "צבעוני",
    "צלופח",
    "קבוץ גלויות",
    "קורצ'ק ינוש",
    "קפלן",
    'קק"ל',
    "קרית בן גוריון",
    "קרית גרשון טץ",
    "קרית החינוך",
    "קרן היסוד",
    "רבי עקיבא",
    "רותם",
    "רייך שמעון",
    "רייצ'ק עזריאל",
    "רייק חביבה",
    "ריף",
    'רמב"ם',
    "רמז",
    "ש ממשלתי",
    "ש מעון אקדמאים",
    "ש עמידר",
    "ש קרית חוף",
    "ש קרית יוספטל",
    "ש קרית עשור",
    "ש רסקו",
    "שאול המלך",
    "שבזי",
    "שביל דבורה",
    "שביל דדון דוד",
    "שביל האלה",
    "שביל הימיה",
    "שביל הכרם",
    "שביל הלל",
    "שביל המעונה",
    "שביל הפרדס",
    "שביל הצבר",
    "שביל השיטה",
    "שביל התמר",
    "שביל חנה",
    "שביל יוחנן",
    "שביל יונתן",
    "שביל יעל",
    "שביל מצובה",
    "שביל מרים",
    "שביל מתתיהו",
    "שביל שמאי",
    "שביל שמעון",
    "שד אשכול",
    "שד בן צבי",
    "שד הגעתון",
    "שד קיי אברהם",
    "שד' הבאבא סאלי",
    "שד' ששת הימים",
    "שדה יצחק",
    "שדרות ורה",
    'שדרת שז"ר',
    "שונית",
    "שחף",
    "שטיינהרט",
    "שטינמץ מקס",
    "שטראוס הילדה",
    "שכ גבעת טרומפלדור",
    "שכ מרכז",
    "שכ עין שרה",
    "שכ' אריק שרון",
    "שכ' יצחק נבון",
    "שכ' יצחק שמיר",
    "שכ' סם בויימל",
    "שכ' שמעון פרס",
    "שלום הגליל",
    "שלמה המלך",
    "שפירא",
    "שפרינצק",
    "שקד",
    "שריר אפרים",
    "תל חי",
  ],
  נוב: ["נוב"],
  נוגה: [
    "הגפן",
    "הוורד",
    "הזית",
    "הכלנית",
    "הסיגלית",
    "הרימון",
    "התאנה",
    "התמר",
    "נוגה",
    "רותם",
    "שדרות הרקפת",
  ],
  "נוה צוף": [
    "אגוז",
    "אורן",
    "אלון",
    'אצ"ל',
    "ארז",
    "אתרוג",
    "ברוש",
    "גפן",
    "דבש",
    "דגן",
    "דקל",
    "האלה",
    "הבוסתן",
    "הביכורים",
    "הדס",
    "ההגנה",
    "החרוב",
    "המייסדים",
    "המצודה",
    "השומר",
    "זית שמן",
    "חיטה",
    "יצהר",
    "לבונה",
    "לוחמי המחתרות",
    'לח"י',
    "מעלה החורש",
    "נוה צוף",
    'ניל"י',
    "ערבי נחל",
    "רימון",
    "שבעת המינים",
    "שעורה",
    "שקד",
    "תאנה",
    "תורה ועבודה",
    "תירוש",
    "תמר",
  ],
  נווה: ["נווה"],
  "נווה אבות": ["נווה אבות"],
  "נווה אור": ["בית ראשון במולדת", "נווה אור"],
  'נווה אטי"ב': ['נווה אטי"ב'],
  "נווה אילן": [
    "אגדת דשא",
    "אל גינת אגוז",
    "באר בשדה",
    "גן השקמים",
    "דרך התקוה",
    "ההר הירוק",
    "הפרח בגני",
    "הרעות",
    "השיירה",
    "חופים",
    "ירח",
    "ככר ים השיבולים",
    "ככר כלניות",
    "ככר רקפת",
    "נווה אילן",
    "נוף ילדות",
    "סימני דרך",
    "עץ הרימון",
    "עץ השדה",
    "פרפר נחמד",
    "רוח סתיו",
    "שבולת בשדה",
    "שיר לשלום",
    "שני שושנים",
  ],
  "נווה איתן": ["נווה איתן"],
  "נווה דניאל": [
    "אלוני ממרא",
    "בריכות שלמה",
    "דרך אפרתה",
    "דרך האבות",
    "המוריה",
    "הרי יהודה",
    "מגדל עדר",
    "נווה דניאל",
    "נוף הרודיון",
    "נחל אשכול",
    "נחל המעיינות",
    "עמק ברכה",
    "שדה המכפלה",
    "שיבת רחל",
  ],
  "נווה זוהר": ["נווה זוהר", "סדום", "עין בוקק"],
  "נווה זיו": [
    "דרך התמר",
    "הכלנית",
    "חצב",
    "יסמין",
    "כרכום",
    "מרגלית",
    "מרווה",
    "נווה זיו",
    "נרקיס",
    "סביון",
    "סחלב",
    "צבעוני",
    "רקפת",
  ],
  "נווה חריף": ["נווה חריף"],
  "נווה ים": ["נווה ים"],
  "נווה ימין": [
    "האגוז",
    "האורן",
    "הברוש",
    "הגפן",
    "הדקל",
    "הדר",
    "הזית",
    "הערבה",
    "הפיקוס",
    "הרימון",
    "התמר",
    "נווה ימין",
    "שיכון הבנים",
  ],
  "נווה ירק": [
    "האגוז",
    "הברוש",
    "הגפן",
    "הזית",
    "הפרדס",
    "הרימון",
    "השיזף",
    "השקד",
    "נווה ירק",
  ],
  "נווה מבטח": [
    "האלומות",
    "האסיף",
    "הדגן",
    "החריש",
    "הקציר",
    "מגוב",
    "מורג",
    "נווה מבטח",
    "שיבולים",
  ],
  "נווה מיכאל": ["נווה מיכאל"],
  "נווה שלום": ["נווה שלום"],
  נועם: ["נועם"],
  "נוף איילון": [
    "אריאל",
    "ברקת",
    "דביר",
    "החושן",
    "יהלום",
    "לשם",
    "מוריה",
    "נוף איילון",
    "ספיר",
    "שוהם",
  ],
  "נוף הגליל": [
    "אגוז",
    "אגמון",
    "אדמון",
    "אורה",
    "אורנים",
    "אזור תעש ציפורית",
    "אזור תעשיה הר יונה",
    "אזור תעשיה ומלאכה א",
    "אזור תעשיה מרכז",
    "איריס",
    "אלומה",
    "אלמוג",
    "ארבל",
    "ארז",
    "ארזי הלבנון",
    "אשוח",
    "בינת יששכר",
    "ברוש",
    "גבעת ברק",
    "גולן",
    "גלעד",
    "גנות",
    "דגנית",
    "דובר שלום",
    "דולב",
    "דליה",
    "דפנה",
    "דרך אריאל שרון",
    "דרך הגליל",
    "דרך החטיבות",
    "דרך הציונות",
    "דרך קרית הממשלה",
    'האדמו"ר מבעלזא',
    "האחווה",
    "האלה",
    "האלונים",
    "האשור",
    "האשל",
    "הבוסתן",
    "הגביש",
    "הגבעה",
    "הגומא",
    "הגיא",
    "הגפן",
    "הדסים",
    "הדר",
    "הזית",
    "החבצלת",
    "החיטה",
    "החצב",
    "החרושת",
    "הידידות",
    "היונה",
    "היוצר",
    "היצירה",
    "היתד",
    "הלהב",
    "המוביל",
    "המוכתר מוהנא",
    "המורד",
    "המלאכה",
    "המעיין",
    "המרכבה",
    "המתכת",
    "הסדנא",
    "העבודה",
    "העליה",
    "העמל",
    "הפסגות",
    "הפריון",
    "הצבר",
    "הצורף",
    "הצלף",
    "הר מצדה",
    "הרב אהרן ליב שטימן",
    "הרב עובדיה יוסף",
    "הרב פנחס מילר",
    "הרב שלום אלישיב",
    "הרדוף",
    "הרי יהודה",
    "הרי ירושלים",
    "הרי מנשה",
    "השושנים",
    "השקמה",
    "התאנה",
    "ורדים",
    "חיננית",
    "חמניות",
    "חרוב",
    "חרוד",
    "חרמון",
    "חרשי העץ",
    "יודפת",
    "יזרעאל",
    "יסמין",
    "יעל",
    "יערה",
    "יקינטון",
    "ירדן",
    "ירקה",
    "כביש טבריה",
    "כוכבית",
    "ככר אלי כהן",
    "ככר הגליל",
    "כליל החורש",
    "כלניות",
    "כסולות",
    "כרכום",
    "כרמל",
    "לב העיר",
    "לבנה",
    "לוטם",
    "לולב",
    "לילך",
    "לפידות",
    "מגידו",
    "מורן",
    "מירון",
    "מלון גני נצרת",
    "מנחם אריאב",
    "מעון אקדמאים",
    "מעון סטודנטים",
    "מעלה הראשונים",
    "מעלה יצחק",
    "מעלה עלית",
    "מרגנית",
    "מרכז דקל",
    "מרכז לבון",
    "מרכז קליטה",
    "משעול דבורה",
    "נוף הגליל",
    "נוף יזרעאל",
    "נוף תבור",
    "נורית",
    "נטופה",
    "ניצן",
    "ניצנית",
    "נרקיסים",
    "סביון",
    "סחלב",
    "סיגלית",
    "סיוון",
    "סייפן",
    "סיתוונית",
    "סמ גומא",
    "עדעד",
    "עולש",
    "עטרה",
    "עירית",
    "עצמון",
    "ערבה",
    "פעמונית",
    "צבעוני",
    "צורית",
    "ציפורי",
    "ציפורן",
    "קדושת אהרון",
    "קורנית",
    "קישון",
    "קרית יצחק רבין",
    "רותם",
    "ריחן",
    "רימון",
    "רמת האירוסים",
    "רקפת",
    "ש אשכול",
    "ש דרומית",
    "ש זוג צעירים",
    "ש צבא הקבע",
    "ש רסקו",
    "ש שלום",
    "שומרון",
    "שכ בן גוריון",
    "שכ בנה ביתך (דרום)",
    "שכ בנה ביתך (צפון)",
    "שכ הכרמים",
    "שכ הר יונה",
    "שכ זאב",
    "שכ פינת עדן",
    "שכ צפונית",
    "שכונת ספיר",
    "שקד",
    "תדהר",
    "תירוש",
    "תכלת מרדכי",
    "תמר",
  ],
  נופים: [
    "האורן",
    "האיריס",
    "הברוש",
    "הדקל",
    "הכלנית",
    "הצבעוני",
    "נופים",
    "סיתוונית",
  ],
  נופית: [
    "אביטל",
    "איתנים",
    "ארבל",
    "גולן",
    "גלבוע",
    "געש",
    "הבריכה",
    "הגליל",
    "החרמון",
    "הכרמל",
    "העמק",
    "הפסגה",
    "התבור",
    "כנען",
    "מירון",
    "משעול אלונים",
    "משעול האלה",
    "משעול הגיא",
    "משעול הרדוף",
    "משעול זבולון",
    "משעול עדי",
    "משעול רכסים",
    "נופית",
    "סופה",
    "סער",
    "עצמון",
    "צפרירים",
    "קדים",
    "רימון",
  ],
  נופך: [
    "אבני החושן",
    "אודם",
    "ברקת",
    "טורקיז",
    "יהלום",
    "לשם",
    "נופך",
    "סמ הרב דדון",
    "ספיר",
    "תרשיש",
  ],
  נוקדים: [
    "האירוס",
    "החלמונית",
    "הניסנית",
    "העירית",
    "חוות שדה בר",
    "כפר אלדד",
    "מעלה רחבעם",
    "נוקדים",
    "נחל אשל",
    "נחל גורפן",
    "נחל דוד",
    "נחל דרגות",
    "נחל חבר",
    "נחל חצצון",
    "נחל יעלים",
    "נחל עמוס",
    "נחל עציונה",
    "נחל ערוגות",
    "נחל קדם",
    "נחל קדרון",
    "נחל תקוע",
  ],
  נורדיה: [
    "דרך הבאר",
    "דרך הפרדס",
    "האגוז",
    "האורן",
    "האלון",
    "האלונים",
    "האתרוג",
    "הבנים",
    "הגפן",
    "ההדס",
    "הורד",
    "הזית",
    "החבצלת",
    "החירות",
    "הכלנית",
    "המייסדים",
    "המנורה",
    "המרגנית",
    "הסביון",
    "הערבה",
    "הצאלון",
    "הרימון",
    "השיטה",
    "השקד",
    "התאנה",
    "התדהר",
    "התמר",
    "ווג'ווד",
    "כפר הגמלאים",
    "מגדלי הים התיכון",
    "מלון נורדיה",
    "מרגולין",
    "נאות נורדיה",
    "נורדיה",
    "קרוונים",
    "שד נורדיה",
    "תל חי",
  ],
  נורית: ["נורית"],
  נחושה: ["נחושה"],
  "נחל עוז": ["נחל עוז"],
  נחלה: ["נחלה"],
  נחליאל: [
    "אבני החושן",
    "אודם",
    "ברקת",
    "יהלום",
    "ישפה",
    "לשם",
    "נופך",
    "נחליאל",
    "ספיר",
    "פטדה",
    "שבו",
    "שוהם",
    "תרשיש",
  ],
  נחלים: [
    "גמלא",
    "דן",
    "זויתן",
    "חרמון",
    "טל",
    "ירדן",
    "לשם",
    "נחלים",
    "סער",
    "קדש",
    "קורן",
    "שיאון",
    "שניר",
  ],
  נחם: ["גפן", "חיטה", "נחם", "רימון", "שעורה", "תאנה", "תמר"],
  נחף: [
    "אלביאדר",
    "אלבלוע וקבור אלנסרה",
    "אלחווז ואלעין",
    "אלמריג'",
    "אלקובסי",
    "העיר העתיקה",
    "זוגות צעירים",
    "חקל אלמע'רה",
    "חקל אלעמוד",
    "כביש ראשי ואלדבה",
    "נחף",
  ],
  נחשולים: ["ביס שדה נחשולים", "נחשולים"],
  נחשון: ["נחשון"],
  נחשונים: ["נחשונים"],
  נטועה: ["נטועה"],
  נטור: ["נטור"],
  נטע: [
    "גוש קטיף",
    "הר ארגמן",
    "הר רמון",
    "הר שחר",
    "כפר דרום",
    "מבוא מרשם",
    "נחל בשור",
    "נחל לכיש",
    "נחל משמר",
    "נחל נוקד",
    "נחל עבדת",
    "נחל עין גדי",
    "נחל ערבה",
    "נחל צאלים",
    "נחל צופית",
    "נחל רביבים",
    "נחל רחף",
    "נחל תמנע",
    "נטע",
    "תל קטיפא",
  ],
  נטעים: ["נטעים"],
  נטף: ["נטף"],
  ניין: ["ניין"],
  'ניל"י': [
    "אודם",
    "אורן",
    "בזלת",
    "ברוש",
    "דרך המייסדים",
    "הבוסתן",
    "הדקל",
    "הדר",
    "השיטה",
    "זית",
    "מעלה ההר",
    "נוף ים",
    "נחל געתון",
    "נחל הבשור",
    "נחל מודיעין",
    "נחל מירון",
    "נחל נטוף",
    "נחל פארן",
    'ניל"י',
    "שיזף",
    "תדהר",
  ],
  ניצן: [
    "אלי סיני",
    "גדיד",
    "דוד המלך",
    "דרך גוש קטיף",
    "דרך נווה דקלים",
    "האגם",
    "האמונה",
    "האשל",
    "הגאולה",
    "הגפן",
    "הדיונה",
    "הזית",
    "הנצח",
    "הצוק",
    "הרותם",
    "הרימון",
    "השיטה",
    "השמחה",
    "השקד",
    "השקמה",
    "התאנה",
    "התמר",
    "התקווה",
    "יהודה",
    "יוסף",
    "כיסופים",
    "כפר דרום",
    "מורג",
    "ניצן",
    "נצר חזני",
    "עצמונה",
    "פאת שדה",
    "שירת הים",
  ],
  "ניצן ב'": [
    "דרך הים",
    "החוף",
    "מורד הנחל",
    "משל יותם",
    "משעול אדום החזה",
    "משעול האגוז",
    "משעול האגמית",
    "משעול האגס",
    "משעול האדווה",
    "משעול האורן",
    "משעול האלה",
    "משעול האלון",
    "משעול האלמוג",
    "משעול האנפה",
    "משעול האפרסק",
    "משעול האתרוג",
    "משעול הבז",
    "משעול הברוש",
    "משעול הגלים",
    "משעול הגפן",
    "משעול הדוכיפת",
    "משעול הדולב",
    "משעול הדולפין",
    "משעול הדייגים",
    "משעול הדרור",
    "משעול הזית",
    "משעול הזמיר",
    "משעול החוגלה",
    "משעול החוחית",
    "משעול החופית",
    "משעול החסידה",
    "משעול החרוב",
    "משעול הינשוף",
    "משעול הירגזי",
    "משעול הלגונה",
    "משעול הלימון",
    "משעול המגדלור",
    "משעול המזח",
    "משעול המפרש",
    "משעול הנחליאלי",
    "משעול הנשר",
    "משעול הסירה",
    "משעול הסלעית",
    "משעול הסנונית",
    "משעול העגור",
    "משעול העוגן",
    "משעול העפרוני",
    "משעול הערבה",
    "משעול הצאלון",
    "משעול הצדף",
    "משעול הצופית",
    "משעול הצפצפה",
    "משעול הקונכיה",
    "משעול הקכלי",
    "משעול הרימון",
    "משעול השונית",
    "משעול השזיף",
    "משעול השחף",
    "משעול השחרור",
    "משעול השלדג",
    "משעול השליו",
    "משעול השקד",
    "משעול השקנאי",
    "משעול התאנה",
    "משעול התור",
    "משעול התורן",
    "משעול התות",
    "משעול התמר",
    "משעול התנשמת",
    "משעול התפוז",
    "משעול התפוח",
    "משעול כוכב הים",
    "משעול נחל אורן",
    "משעול נחל בצת",
    "משעול נחל הירקון",
    "משעול נחל חילזון",
    "משעול נחל עמוד",
    "משעול נחל ערוגות",
    "משעול נחל צין",
    "משעול נחל ציפורי",
    "משעול נחל רחף",
    "ניצן ב'",
    "נתיב הציפורים",
    "שדרת הקוצר",
  ],
  "ניצנה (קהילת חינוך)": ["מרכז קליטה", "ניצנה (קהילת חינוך)"],
  "ניצני סיני": ["ניצני סיני"],
  "ניצני עוז": [
    "אלומות",
    "דרך השדות",
    "הבוסתן",
    "הפרחים",
    "השלושה",
    "ניצני עוז",
    "ערוגות",
    "רגבים",
    "שיבולים",
  ],
  ניצנים: ["ניצנים"],
  "ניר אליהו": ["ניר אליהו"],
  "ניר בנים": [
    "ניר בנים",
    "נתיב האפרסק",
    "שביל החלב",
    "שדרת הורד",
    "שכ קרוונים",
  ],
  "ניר גלים": [
    "אשל",
    "ברוש",
    "גומא",
    "דולב",
    "הדס",
    "המיסדים",
    "השדרה החקלאית",
    'התש"ט',
    "ורד",
    "זוטה לבנה",
    "חרוב",
    "טופח",
    "יקינטון",
    "ישיבת נוה הרצוג",
    "כליל החורש",
    "ליבנה",
    "מורן",
    "נורית",
    "ניר גלים",
    "ניר וגל",
    "סחלב",
    "ערבה",
    "פיקוס",
    "צאלון",
    "קינמון",
    "רותם",
    "שיקמה",
  ],
  "ניר דוד (תל עמל)": ["בית ראשון במולדת", "ניר דוד (תל עמל)"],
  'ניר ח"ן': [
    "החרצית",
    "היסמין",
    "הכלנית",
    "הנורית",
    "הנרקיס",
    "הסביון",
    "הצבעוני",
    'ניר ח"ן',
    "שדרות הפרחים",
  ],
  "ניר יפה": ["ניר יפה"],
  "ניר יצחק": ["ניר יצחק"],
  "ניר ישראל": ["ניר ישראל"],
  "ניר משה": ["ניר משה"],
  "ניר עוז": ["ניר עוז"],
  "ניר עם": ["ניר עם"],
  "ניר עציון": ["כפר הנוער ימין אורד", "ניר עציון"],
  "ניר עקיבא": ["ניר עקיבא"],
  "ניר צבי": [
    "איתן",
    "גומא",
    "האשל",
    "הגפן",
    "הדסים",
    "הדקל",
    "הדרים",
    "הערבה",
    "הצאלון",
    "הרימון",
    "יערה",
    "ניר צבי",
  ],
  נירים: ["נירים"],
  נירית: [
    "גולן",
    "גלבוע",
    "גלעד",
    "הגליל",
    "החרמון",
    "הכרמל",
    "הלל",
    "התבור",
    "מוריה",
    "מירון",
    "נירית",
    "שחר",
    "שכ נוף השרון",
    "שניר",
  ],
  נמרוד: ["נמרוד"],
  "נס הרים": [
    "דרך חלמית",
    "לבנה",
    "מבוא היערה",
    "מורד הרוזמרין",
    "מעלה הקטלב",
    "מעלות אורן",
    "נס הרים",
  ],
  "נס עמים": ["נס עמים"],
  "נס ציונה": [
    "אבנר בן נר",
    "אדום",
    "אהרון מאיר מזיא",
    "אזור תעשיה א",
    "אזור תעשיה ב",
    "איילון",
    "אינשטיין",
    "איתן רפאל",
    "אלה",
    "אלון",
    "אלי כהן",
    "אלתרמן",
    "אנה פרנק",
    "אנילביץ",
    'אצ"ל',
    "ארבל",
    "ארגמן",
    "ארזים",
    "בגין מנחם",
    "בוקסר אהרון",
    "בוקסר ירמיהו",
    "בורוכוב",
    "ביאליק",
    'ביל"ו',
    "בית השיקום",
    "בן אבי איתמר",
    "בן גוריון",
    "בן יהודה אבנר",
    "בני בנימין",
    "בר-כוכבא",
    "ברושים",
    "ברקת",
    "גאולה",
    "גבעת האהבה",
    "גבעת הצבר",
    "גבעת התור",
    "גבעת מיכאל",
    "גבעת נוף",
    "גבעתי",
    "גוטהלף שמואל",
    "גולדה מאיר",
    "גולומב",
    "גולי דמשק",
    "גולן",
    "גורדון",
    "גלילי",
    "גני איריס",
    "גני הדר",
    "געתון",
    "דבורנית",
    "דגל ישראל",
    "דגניה",
    "דוד אלעזר",
    "דוכיפת",
    "דולב",
    "דישון",
    "דן",
    "דפנה",
    "דרור",
    "דרך גבעות הכורכר",
    "דרך הנפת הדגל",
    "דרך השלום",
    "דרך רבין יצחק",
    "האביב",
    "האופק",
    "האורן",
    "האיזמל",
    "האירוסים",
    "האלופים",
    "האלמוגים",
    "האמהות",
    "האסיף",
    "האתרוג",
    "הבאר",
    "הבנים",
    "הגיא",
    "הגלבוע",
    "הגליל",
    "הגלים",
    "הגנה",
    "הגפן",
    "הדגן",
    "הדס",
    "הדקל",
    "ההדרים",
    "ההסתדרות",
    "הורד",
    "הזית",
    "החיטה",
    "החירות",
    "החלוץ",
    "החרש",
    "הטייסים",
    "היוגב",
    "היונה",
    "היימן ישראל",
    "הכלנית",
    "הכרמים",
    "הלב",
    "המאה ואחד",
    "המולדת",
    "המזמרה",
    "המייסדים",
    "המלאכה",
    "המלכים",
    "המנין הראשון",
    "המסגר",
    "הנבחרות",
    "הנקר",
    "הנרקיס",
    "הספורט",
    "העבודה",
    "העגור",
    "העוגן",
    "העצמאות",
    "הפטיש",
    "הפרדסים",
    "הפרטיזנים",
    "הציונות",
    "הצנחנים",
    "הקציר",
    "הרב בארי",
    "הרב שאול אחרק",
    "הרדוף",
    "הרימון",
    "הרמן מאיר",
    "הרצל",
    "הרקפת",
    "השומר",
    "השופטים",
    "השייטת",
    "השקד",
    "השקמה",
    "השריון",
    "התאנה",
    "התור",
    "התחיה",
    "התמר",
    'התרמ"ג',
    "ויצמן",
    "ז'בוטינסקי",
    "זאבי רחבעם",
    "זמיר",
    "זמל זוסיא",
    "זרובבל",
    "חבצלת",
    "חופים",
    "חושן",
    "חזן יעקב",
    "חזקיהו",
    "חיים בר-לב",
    "חסידה",
    "חצב",
    "חרוב",
    "חרמון",
    "טופז",
    "טוקאן",
    "טפר חיים",
    "יואש",
    "יונק הדבש",
    "יסמין",
    "יפה נוף",
    "יציאת אירופה",
    "יקינתון",
    "ירושלים",
    "ירקון",
    "כהנוב זאב",
    "כחליל",
    "ככר המיסדים",
    "כנרת",
    "כצנלסון",
    "כרכום",
    "כרמל",
    "לב המושבה",
    "לוחמי הגטאות",
    "לוטוס",
    "לוי משה",
    'לח"י',
    "לחמי שלום",
    "לילך",
    "לנדו דוד",
    "לסקוב",
    "לרר משה",
    "מבצע קדש",
    "מונטיפיורי",
    "מירון אליהו",
    "מכבי",
    "מכון למחקר",
    "מנור",
    "מע נס ציונה",
    "מקוה ישראל",
    "מרגולין",
    "מרגנית",
    "משחתת אילת",
    "משמר הירדן",
    "נגבה",
    "נווה הדר",
    "נווה כרמית",
    "נורדאו",
    "נורית",
    "נחליאלי",
    "נחמיאס שמואל",
    "נחשון",
    'ניל"י',
    "נס ציונה",
    "נעמן",
    "סביון",
    "סביוני הפארק",
    "סביוני נצר",
    "סוקולוב",
    "סחלב",
    "סטרומה",
    "סיגלית",
    "סייפן",
    "סיני",
    "סיתוונית",
    "סלוביס שמואל",
    "סמ גנים",
    "סמ דליה",
    "סמ האחיות ניימן",
    "סמ האירוסים",
    "סנה משה",
    "סנונית",
    "סנש חנה",
    "סעדיה נחום",
    "ספיר פנחס",
    "עדן",
    "עומרים",
    "עונות השנה",
    "עזרא ונחמיה",
    "עליה",
    "עמק ההדרים",
    "עמק השושנים",
    "עפרוני",
    "עצמון",
    "ערבה",
    "פארק המדע",
    "פולג",
    "פלג",
    "פלדמן יוסף",
    'פלמ"ח',
    "פלמינגו",
    "פניק דוד",
    "פעמונית",
    "פרג",
    "פשוש",
    "צבעוני",
    'צה"ל',
    "צופית",
    "צמרות",
    "צפצפה",
    "קבוץ גלויות",
    "קוברסקי משה",
    "קרן קיימת",
    "קשת",
    "ראובן",
    "רגבים",
    "רוטשילד",
    "רופמן שמואל",
    "רות המואביה",
    "רותם",
    "רחבעם",
    'רמב"ם',
    "רמון אילן",
    "רמז",
    "רמת בן צבי",
    "ש אזרחי",
    "ש בלוקונים",
    "ש הפועלים",
    "ש יד אליעזר",
    "ש נוה עובד",
    "ש סלע",
    "ש עמידר",
    "ש פרוגרסיבים",
    "ש רסקו",
    "שאול המלך",
    "שבועות",
    "שבזי",
    "שבטי ישראל",
    "שביט",
    "שד אפרים",
    "שד המעפילים",
    "שד העמק",
    "שדה יצחק",
    "שדות",
    "שונית",
    "שורק",
    "שושן",
    "שחף",
    "שיבולים",
    "שיבת ציון",
    "שיזף",
    "שינדלר אוסקר",
    "שכ העמק",
    "שכ טירת שלום",
    "שכ כפר אהרון",
    "שכ נוה ניר",
    "שכ פסגת סלע",
    "שכ רמת סמל",
    "שכ שיכון הבנים",
    "שלדג",
    "שמורת מליבו",
    "שמיד ישראל",
    "שניר",
    "שקמים",
    "שקנאי",
    "ששת הימים",
    "תבור",
    "תל אביב",
    "תל חי",
    "תלמים",
  ],
  נעורים: ["נעורים"],
  נעלה: [
    "אודם",
    "בדולח",
    "ברקת",
    "חושן",
    "טופז",
    "טורקיז",
    "יהלום",
    "לשם",
    "מעלה מודיעין",
    "נעלה",
    "נפך",
    "ספיר",
    "ענבר",
    "פנינה",
    "קרית חינוך",
    "שחם",
  ],
  'נעמ"ה': ['נעמ"ה'],
  נען: ["אולפן קיבוץ", "נען"],
  נערן: ["נערן"],
  "נצאצרה (שבט)": ["נצאצרה (שבט)"],
  "נצר חזני": ["נצר חזני"],
  "נצר סרני": ["נצר סרני"],
  נצרת: [
    "א-שוהדא'",
    "אבן עאמר",
    "אום קובי",
    "אזור תעשיה א",
    "אזור תעשיה ב",
    "איכסאל",
    "אל ואדי אל ג'וואני",
    "אל-בישארה",
    "אל-כורום",
    "אל-פאח'ורה",
    "אניס כרדוש",
    'בי"ח איטלקי',
    "ביח החולים הצרפתי",
    "בילאל",
    "ביר אבו אל ג'יש",
    "ביר אל אמיר",
    "גבל א-דולה",
    "גבל חמודי",
    "גרנד ניו הוטל",
    "דרך א-סלאם",
    "דרך עפולה",
    "הגליל",
    "המוסכים",
    "הרובע הלטיני",
    "ואדי אל-חאג'",
    "ח'אלת עיסא",
    "חאלת א-דיר",
    "כרם א-סאחב",
    "כרם אל-ג'מאל",
    "נצרת",
    "סאפוריה",
    "סלזיאן",
    "עומר אבן אל-חטאב",
    "פאולוס השישי",
    "פנורמה",
    "רואוס אל-ג'יבאל",
    "רח 1000",
    "רח 4000",
    "רח 9000",
    "רח 3100",
    "רח 4100",
    "רח 5100",
    "רח 6100",
    "רח 7100",
    "רח 7200",
    "רח 1010",
    "רח 2010",
    "רח 3010",
    "רח 4010",
    "רח 5010",
    "רח 6010",
    "רח 7010",
    "רח 8010",
    "רח 9010",
    "רח 1110",
    "רח 5110",
    "רח 6110",
    "רח 7210",
    "רח 3020",
    "רח 4020",
    "רח 6020",
    "רח 7020",
    "רח 8020",
    "רח 9020",
    "רח 1120",
    "רח 5120",
    "רח 6120",
    "רח 7220",
    "רח 1030",
    "רח 3030",
    "רח 4030",
    "רח 5030",
    "רח 6030",
    "רח 7030",
    "רח 9030",
    "רח 5130",
    "רח 6130",
    "רח 7230",
    "רח 1040",
    "רח 3040",
    "רח 5040",
    "רח 6040",
    "רח 7040",
    "רח 9040",
    "רח 5140",
    "רח 6140",
    "רח 7240",
    "רח 1050",
    "רח 3050",
    "רח 4050",
    "רח 6050",
    "רח 7050",
    "רח 6150",
    "רח 7250",
    "רח 1060",
    "רח 5060",
    "רח 6060",
    "רח 7060",
    "רח 5160",
    "רח 6160",
    "רח 4070",
    "רח 5070",
    "רח 6070",
    "רח 7070",
    "רח 6170",
    "רח 4080",
    "רח 5080",
    "רח 6080",
    "רח 7080",
    "רח 6180",
    "רח 4090",
    "רח 5090",
    "רח 6090",
    "רח 7090",
    "רח 1001",
    "רח 2001",
    "רח 3001",
    "רח 6001",
    "רח 8001",
    "רח 9001",
    "רח 1101",
    "רח 3101",
    "רח 4101",
    "רח 5101",
    "רח 6101",
    "רח 7101",
    "רח 7201",
    "רח 301",
    "רח 1011",
    "רח 2011",
    "רח 3011",
    "רח 4011",
    "רח 5011",
    "רח 6011",
    "רח 9011",
    "רח 1111",
    "רח 6111",
    "רח 7211",
    "רח 1021",
    "רח 2021",
    "רח 4021",
    "רח 5021",
    "רח 7021",
    "רח 8021",
    "רח 9021",
    "רח 5121",
    "רח 7221",
    "רח 321",
    "רח 1031",
    "רח 3031",
    "רח 4031",
    "רח 5031",
    "רח 6031",
    "רח 7031",
    "רח 9031",
    "רח 6131",
    "רח 7231",
    "רח 1041",
    "רח 3041",
    "רח 4041",
    "רח 5041",
    "רח 6041",
    "רח 7041",
    "רח 9041",
    "רח 5141",
    "רח 6141",
    "רח 7241",
    "רח 1051",
    "רח 4051",
    "רח 5051",
    "רח 6051",
    "רח 7051",
    "רח 6151",
    "רח 7251",
    "רח 1061",
    "רח 5061",
    "רח 6061",
    "רח 7061",
    "רח 5161",
    "רח 4071",
    "רח 5071",
    "רח 6071",
    "רח 7071",
    "רח 6171",
    "רח 4081",
    "רח 5081",
    "רח 6081",
    "רח 1181",
    "רח 6181",
    "רח 4091",
    "רח 5091",
    "רח 6091",
    "רח 6191",
    "רח 1002",
    "רח 2002",
    "רח 3002",
    "רח 5002",
    "רח 6002",
    "רח 8002",
    "רח 9002",
    "רח 1102",
    "רח 4102",
    "רח 5102",
    "רח 6102",
    "רח 7202",
    "רח 702",
    "רח 802",
    "רח 1012",
    "רח 3012",
    "רח 4012",
    "רח 5012",
    "רח 6012",
    "רח 7012",
    "רח 8012",
    "רח 9012",
    "רח 1112",
    "רח 5112",
    "רח 6112",
    "רח 7212",
    "רח 4022",
    "רח 5022",
    "רח 6022",
    "רח 7022",
    "רח 8022",
    "רח 9022",
    "רח 5122",
    "רח 6122",
    "רח 7222",
    "רח 1032",
    "רח 3032",
    "רח 4032",
    "רח 5032",
    "רח 6032",
    "רח 9032",
    "רח 6132",
    "רח 7232",
    "רח 1042",
    "רח 3042",
    "רח 4042",
    "רח 5042",
    "רח 6042",
    "רח 7042",
    "רח 9042",
    "רח 7242",
    "רח 1052",
    "רח 4052",
    "רח 5052",
    "רח 7052",
    "רח 6152",
    "רח 1062",
    "רח 4062",
    "רח 5062",
    "רח 6062",
    "רח 7062",
    "רח 6162",
    "רח 4072",
    "רח 5072",
    "רח 6072",
    "רח 7072",
    "רח 6172",
    "רח 4082",
    "רח 5082",
    "רח 6082",
    "רח 7082",
    "רח 6182",
    "רח 5092",
    "רח 6192",
    "רח 1003",
    "רח 2003",
    "רח 3003",
    "רח 4003",
    "רח 5003",
    "רח 6003",
    "רח 7003",
    "רח 8003",
    "רח 9003",
    "רח 1103",
    "רח 3103",
    "רח 4103",
    "רח 5103",
    "רח 6103",
    "רח 7203",
    "רח 303",
    "רח 2013",
    "רח 3013",
    "רח 4013",
    "רח 5013",
    "רח 6013",
    "רח 8013",
    "רח 9013",
    "רח 5113",
    "רח 6113",
    "רח 7213",
    "רח 313",
    "רח 413",
    "רח 1023",
    "רח 2023",
    "רח 4023",
    "רח 5023",
    "רח 6023",
    "רח 7023",
    "רח 8023",
    "רח 9023",
    "רח 6123",
    "רח 7223",
    "רח 3033",
    "רח 4033",
    "רח 5033",
    "רח 6033",
    "רח 7033",
    "רח 9033",
    "רח 6133",
    "רח 7233",
    "רח 1043",
    "רח 3043",
    "רח 4043",
    "רח 5043",
    "רח 6043",
    "רח 7043",
    "רח 6143",
    "רח 7243",
    "רח 4053",
    "רח 5053",
    "רח 6053",
    "רח 7053",
    "רח 6153",
    "רח 1063",
    "רח 4063",
    "רח 6063",
    "רח 7063",
    "רח 6163",
    "רח 4073",
    "רח 5073",
    "רח 6073",
    "רח 7073",
    "רח 6173",
    "רח 4083",
    "רח 5083",
    "רח 6083",
    "רח 7083",
    "רח 6183",
    "רח 4093",
    "רח 5093",
    "רח 6093",
    "רח 6193",
    "רח 1004",
    "רח 2004",
    "רח 3004",
    "רח 4004",
    "רח 6004",
    "רח 7004",
    "רח 8004",
    "רח 9004",
    "רח 3104",
    "רח 4104",
    "רח 5104",
    "רח 6104",
    "רח 7204",
    "רח 604",
    "רח 804",
    "רח 1014",
    "רח 3014",
    "רח 4014",
    "רח 5014",
    "רח 6014",
    "רח 7014",
    "רח 9014",
    "רח 114",
    "רח 1114",
    "רח 5114",
    "רח 6114",
    "רח 7214",
    "רח 2024",
    "רח 3024",
    "רח 4024",
    "רח 5024",
    "רח 6024",
    "רח 7024",
    "רח 8024",
    "רח 9024",
    "רח 124",
    "רח 5124",
    "רח 6124",
    "רח 7224",
    "רח 724",
    "רח 1034",
    "רח 5034",
    "רח 6034",
    "רח 7034",
    "רח 9034",
    "רח 6134",
    "רח 7234",
    "רח 1044",
    "רח 3044",
    "רח 4044",
    "רח 5044",
    "רח 6044",
    "רח 7044",
    "רח 6144",
    "רח 7244",
    "רח 1054",
    "רח 6054",
    "רח 7054",
    "רח 6154",
    "רח 1064",
    "רח 4064",
    "רח 5064",
    "רח 6064",
    "רח 7064",
    "רח 6164",
    "רח 4074",
    "רח 5074",
    "רח 6074",
    "רח 7074",
    "רח 6174",
    "רח 4084",
    "רח 5084",
    "רח 6084",
    "רח 6184",
    "רח 4094",
    "רח 6094",
    "רח 1005",
    "רח 2005",
    "רח 3005",
    "רח 4005",
    "רח 5005",
    "רח 7005",
    "רח 8005",
    "רח 9005",
    "רח 1105",
    "רח 3105",
    "רח 4105",
    "רח 5105",
    "רח 6105",
    "רח 7205",
    "רח 1015",
    "רח 2015",
    "רח 3015",
    "רח 4015",
    "רח 5015",
    "רח 6015",
    "רח 7015",
    "רח 8015",
    "רח 9015",
    "רח 1115",
    "רח 5115",
    "רח 6115",
    "רח 7215",
    "רח 1025",
    "רח 2025",
    "רח 4025",
    "רח 5025",
    "רח 6025",
    "רח 7025",
    "רח 8025",
    "רח 9025",
    "רח 1125",
    "רח 6125",
    "רח 7225",
    "רח 1035",
    "רח 3035",
    "רח 4035",
    "רח 5035",
    "רח 6035",
    "רח 7035",
    "רח 8035",
    "רח 9035",
    "רח 6135",
    "רח 7235",
    "רח 1045",
    "רח 3045",
    "רח 4045",
    "רח 5045",
    "רח 6045",
    "רח 7045",
    "רח 6145",
    "רח 7245",
    "רח 1055",
    "רח 4055",
    "רח 5055",
    "רח 6055",
    "רח 7055",
    "רח 4065",
    "רח 6065",
    "רח 7065",
    "רח 6165",
    "רח 4075",
    "רח 5075",
    "רח 6075",
    "רח 6175",
    "רח 4085",
    "רח 6085",
    "רח 7085",
    "רח 6185",
    "רח 4095",
    "רח 6095",
    "רח 6195",
    "רח 1006",
    "רח 2006",
    "רח 3006",
    "רח 4006",
    "רח 7006",
    "רח 8006",
    "רח 5106",
    "רח 7206",
    "רח 1016",
    "רח 2016",
    "רח 3016",
    "רח 4016",
    "רח 8016",
    "רח 9016",
    "רח 1116",
    "רח 5116",
    "רח 6116",
    "רח 7216",
    "רח 1026",
    "רח 3026",
    "רח 4026",
    "רח 5026",
    "רח 6026",
    "רח 7026",
    "רח 8026",
    "רח 9026",
    "רח 6126",
    "רח 7226",
    "רח 1036",
    "רח 3036",
    "רח 4036",
    "רח 6036",
    "רח 7036",
    "רח 9036",
    "רח 5136",
    "רח 6136",
    "רח 7236",
    "רח 1046",
    "רח 4046",
    "רח 5046",
    "רח 6046",
    "רח 7046",
    "רח 6146",
    "רח 7246",
    "רח 1056",
    "רח 4056",
    "רח 5056",
    "רח 6056",
    "רח 7056",
    "רח 6156",
    "רח 1066",
    "רח 4066",
    "רח 5066",
    "רח 6066",
    "רח 7066",
    "רח 6166",
    "רח 4076",
    "רח 5076",
    "רח 6076",
    "רח 6176",
    "רח 5086",
    "רח 6086",
    "רח 7086",
    "רח 6186",
    "רח 5096",
    "רח 6096",
    "רח 2007",
    "רח 3007",
    "רח 5007",
    "רח 6007",
    "רח 7007",
    "רח 8007",
    "רח 1107",
    "רח 5107",
    "רח 6107",
    "רח 7207",
    "רח 1017",
    "רח 2017",
    "רח 3017",
    "רח 4017",
    "רח 5017",
    "רח 6017",
    "רח 7017",
    "רח 1117",
    "רח 5117",
    "רח 6117",
    "רח 7217",
    "רח 1027",
    "רח 3027",
    "רח 4027",
    "רח 5027",
    "רח 7027",
    "רח 9027",
    "רח 127",
    "רח 6127",
    "רח 7227",
    "רח 1037",
    "רח 3037",
    "רח 4037",
    "רח 5037",
    "רח 6037",
    "רח 7037",
    "רח 9037",
    "רח 5137",
    "רח 6137",
    "רח 7237",
    "רח 4047",
    "רח 5047",
    "רח 6047",
    "רח 7047",
    "רח 6147",
    "רח 7247",
    "רח 1057",
    "רח 4057",
    "רח 5057",
    "רח 6057",
    "רח 7057",
    "רח 6157",
    "רח 1067",
    "רח 4067",
    "רח 5067",
    "רח 6067",
    "רח 6167",
    "רח 4077",
    "רח 5077",
    "רח 6077",
    "רח 6177",
    "רח 4087",
    "רח 5087",
    "רח 6087",
    "רח 7087",
    "רח 4097",
    "רח 5097",
    "רח 6097",
    "רח 6197",
    "רח 1008",
    "רח 2008",
    "רח 3008",
    "רח 4008",
    "רח 5008",
    "רח 6008",
    "רח 7008",
    "רח 8008",
    "רח 5108",
    "רח 6108",
    "רח 7208",
    "רח 1018",
    "רח 2018",
    "רח 4018",
    "רח 5018",
    "רח 6018",
    "רח 7018",
    "רח 5118",
    "רח 6118",
    "רח 7218",
    "רח 1028",
    "רח 3028",
    "רח 4028",
    "רח 5028",
    "רח 6028",
    "רח 7028",
    "רח 9028",
    "רח 5128",
    "רח 6128",
    "רח 7228",
    "רח 3038",
    "רח 4038",
    "רח 5038",
    "רח 6038",
    "רח 7038",
    "רח 9038",
    "רח 6138",
    "רח 7238",
    "רח 4048",
    "רח 5048",
    "רח 6048",
    "רח 7248",
    "רח 1058",
    "רח 4058",
    "רח 5058",
    "רח 6058",
    "רח 7058",
    "רח 6158",
    "רח 4068",
    "רח 5068",
    "רח 6068",
    "רח 6168",
    "רח 4078",
    "רח 5078",
    "רח 4088",
    "רח 5088",
    "רח 6088",
    "רח 5098",
    "רח 6098",
    "רח 1009",
    "רח 2009",
    "רח 3009",
    "רח 4009",
    "רח 5009",
    "רח 6009",
    "רח 7009",
    "רח 8009",
    "רח 5109",
    "רח 6109",
    "רח 7209",
    "רח 1019",
    "רח 3019",
    "רח 4019",
    "רח 5019",
    "רח 6019",
    "רח 7019",
    "רח 8019",
    "רח 5119",
    "רח 6119",
    "רח 7219",
    "רח 3029",
    "רח 4029",
    "רח 5029",
    "רח 6029",
    "רח 7029",
    "רח 9029",
    "רח 5129",
    "רח 6129",
    "רח 7229",
    "רח 3039",
    "רח 4039",
    "רח 5039",
    "רח 6039",
    "רח 7039",
    "רח 6139",
    "רח 7239",
    "רח 1049",
    "רח 4049",
    "רח 5049",
    "רח 6049",
    "רח 7049",
    "רח 6149",
    "רח 7249",
    "רח 1059",
    "רח 4059",
    "רח 5059",
    "רח 7059",
    "רח 6159",
    "רח 4069",
    "רח 5069",
    "רח 6069",
    "רח 7069",
    "רח 6169",
    "רח 4079",
    "רח 5079",
    "רח 6079",
    "רח 4089",
    "רח 5089",
    "רח 6089",
    "רח 5099",
    "רח 6099",
    "ש אחוה",
    "ש הפועל הערבי",
    "ש חילים משוחררים",
    "ש נמסאווי",
    "ש פועלי נצרת",
    "שיכון שנלר",
    "שכ אום קבאי",
    "שכ אל בטריס",
    "שכ אל דבס",
    "שכ אל כרום",
    "שכ אל מוחלס",
    "שכ אל מוטראן",
    "שכ ביר אל אמיר",
    "שכ הורדים",
    "שכ המרוניטים",
    "שכ חאלת א-דיר",
    "שכ מזרחית",
    "שכ סאפפרה",
    "שכ רום",
    "שכון רם",
    "תופיק זיאד",
  ],
  נשר: [
    "אביר יעקב",
    "אופקים",
    "אורח חיים",
    "אורן",
    "אזור מלאכה ומסחר",
    "אזור תעשייה",
    "אשכולות",
    "ברק",
    "בתי נשר",
    "דבורה",
    "דוכיפת",
    "דליה",
    "דרך בר יהודה",
    "דרך הטכניון",
    "דרך הטף",
    "דרך השלום",
    "דרך משה",
    "האילנות",
    "האירוסים",
    "האלון",
    "האשל",
    "הבונים",
    "הבטחון",
    'הבעש"ט',
    "הברוש",
    "הבריכה",
    "הגבורה",
    "הדקל",
    "ההדס",
    "ההסתדרות",
    "ההשכלה",
    "הורדים",
    "הותיקים",
    "הזית",
    "החלוצים",
    "החצב",
    "החרוב",
    "החרושת",
    "היצירה",
    "הירוק",
    "הכלניות",
    "הלוטם",
    "הלילך",
    "המגינים",
    "המורד",
    "המורן",
    "המייסדים",
    "המלאכה",
    "המסגר",
    "המסילה",
    "המעפילים",
    "המצפה",
    "המרגנית",
    "המרכז",
    "המשעול התלול",
    "המתמיד",
    "הנוריות",
    "הסביון",
    "הסחלב",
    "העליה",
    "העמק",
    "הערבה",
    "הערמונים",
    "הפלדה",
    "הצבעוני",
    "הקוממיות",
    "הקישון",
    "הקצר",
    "הרב בר מוחא מרדכי",
    "הרדוף",
    "הרותם",
    "הרקפות",
    "השושנים",
    "השזיף",
    "השיטה",
    "השיש",
    "השקמה",
    "התאנה",
    "התחנה",
    "התיכון",
    "התמר",
    "התעשיה",
    "התפוז",
    "התשבי",
    "זבולון",
    "חבצלת",
    "יסמין",
    "יעל",
    "יפה נוף",
    "ישורון",
    "כביש ארצי",
    "כיכר הדרך לטכניון",
    "ככר ההסתדרות",
    "כליל החורש",
    "מגלן",
    "מורדי הגטאות",
    "מיכאל",
    "מעון לעובד",
    "מעלה הגבורים",
    "מעלות אליהו",
    "מרום כרמל",
    "מרכז חדש",
    "נחשון",
    "נרקיסים",
    "נשר",
    "נשר הותיקה",
    "נתיב הארזים",
    "נתיב הטרשים",
    "נתיב הים",
    "נתיב הכרמל",
    "נתיב הרימון",
    "סיני",
    "סמ בית אל",
    "סמ התמר",
    "סמ כליל החורש",
    "עמוס",
    "פרץ אברהם",
    "צבר",
    'צה"ל',
    "ציון",
    "צפצפה",
    "קבוץ גלויות",
    "קרן היסוד",
    "ש אגש",
    "ש ותיקים",
    "ש זוגות צעירים",
    "ש חדש בן דור",
    "ש חסכון בן דור",
    "ש חסכון נשר",
    "ש חסכון תל חנן",
    "ש מפונים נשר",
    "ש נט",
    "ש עובדי נשר",
    "ש עולים תל חנן",
    "ש עמידר בן דור",
    "ש עמידר נשר",
    "ש עממי נשר",
    "ש קרת",
    "ש רסקו נשר",
    "שביל הבאר",
    "שביל המדרון",
    "שביל העמדה",
    "שדרת היוצרים",
    "שכ בן דור",
    "שכ גבעת נשר",
    "שכ גבעת עמוס",
    "שכ מדרון",
    "שכ רמות יצחק",
    "שכ תל חנן",
    "שקד",
    "ששת הימים",
  ],
  "נתיב הגדוד": ["נתיב הגדוד"],
  'נתיב הל"ה': ["אולפן קבוץ", 'נתיב הל"ה'],
  "נתיב העשרה": ["נתיב העשרה"],
  "נתיב השיירה": ["נתיב השיירה"],
  נתיבות: [
    "אבן דנן",
    "אגוז",
    "אגס",
    "אהוד מנור",
    "אור לציון",
    "אורן",
    "אזוב",
    "אזור תעשייה",
    "אחד עשר הנקודות",
    "אטד",
    "איש מצליח",
    "אלה",
    "אלון",
    "אלי כהן",
    "אפרסמון",
    "אפרסק",
    'אצ"ל',
    "ארזים",
    "אריאל שרון",
    "אריה אליאס",
    "אריק איינשטיין",
    "אשחר",
    "אשכולית",
    "אשל",
    "אתרוג",
    "בדולח",
    'ביל"ו',
    "בית וגן",
    "בן איש חי",
    "בן גוריון",
    "בעלי מלאכה",
    "ברוש",
    "בריטיש קוטג'",
    "ג'ו עמר",
    'גבורי תשכ"ז',
    "גבעת שיפרה",
    "גדיד",
    "גויאבה",
    "גן אור",
    "גני טל",
    "גפן",
    'ד"ר סמלו יוסף',
    "דובדבן",
    "דוגית",
    "דולב",
    "דקל",
    "דרכי רפאל",
    "האומן",
    "האורגים",
    'האר"י',
    "הבונים",
    "הגבורים",
    "הגבעה המערבית",
    "הגנה",
    "הדס",
    "החזון איש",
    'החיד"א',
    "החרש",
    "הירדן",
    "הכותל",
    "המסגר",
    "המשחררים",
    "הסוללים",
    "העצמאות",
    "הצורף",
    "הר הבית",
    "הרב אבוחצירא",
    "הרב איפרגן",
    "הרב אלנקוה",
    "הרב בר אילן",
    "הרב גרשונוביץ",
    "הרב הרצוג",
    "הרב חורי חיים",
    "הרב חי הכהן",
    "הרב לוין",
    "הרב מזוז",
    "הרב מלול יוסף",
    "הרב משה חלפון הכהן",
    "הרב ניסים",
    "הרב עוזיאל",
    "הרב צבאן",
    "הרב קוק",
    "הרדוף הנחלים",
    'הרמב"ם',
    "הרצל",
    'הרשב"י',
    "השר שפירא",
    "ורדימון יצחק",
    "ז'בוטינסקי",
    "זית",
    'חב"ד',
    "חבוש",
    "חוצות היוצר",
    "חזני",
    "חטיבת הראל",
    "חי טייב",
    "חיל אוויר",
    "חיל הים",
    "חיל הנדסה",
    "חיל צנחנים",
    "חיל קשר",
    "חיל שריון",
    "חיל תותחנים",
    "חפץ חיים",
    "חרוב",
    "חרצית",
    "יהושוע אזולאי",
    "יובל",
    "יוסף שילוח",
    "ילדי אוסלו",
    "יפה ירקוני",
    "יצחק שמיר",
    "ישיבת הנגב",
    "כיסוסית",
    "כלנית",
    "כפר דרום",
    "כפר ים",
    "כרם עצמונה",
    "לאה גולדברג",
    "לוטם",
    'לח"י',
    "ליבנה",
    "לימון",
    "מבצע קדש",
    "מויאל שמעון",
    "מונטיפיורי",
    "מורג",
    "מכללת חמדת הדרום",
    "מלכי ישראל",
    "מסעוד אלפסי",
    "מעפיל",
    "מרווה",
    "מרכז תעשיה",
    'משה"ב',
    "משמש",
    "משעול בעל הנס",
    "משעול נתניהו",
    "נווה דקלים",
    "נחל ארנון",
    "נחל בוהן",
    "נחל גרופית",
    "נחל דן",
    "נחל המעיינות",
    "נחל ורדית",
    "נחל זויתן",
    "נחל חבר",
    "נחל טף",
    "נחלת שלמה",
    "ניסנית",
    "נעמי שמר",
    "נצר חזני",
    "נצרים",
    "נרקיס",
    "נתיב המעלה",
    "נתיבות",
    "סביון",
    "סיני",
    "סשה ארגוב",
    "עינב",
    "עליה",
    "ער אציל",
    "ערבה",
    "ערמון",
    "פארק תעשייה",
    "פאת שדה",
    "פטל",
    'פלמ"ח',
    "פקאן",
    "פרחי ישראל",
    "צאלה",
    'צה"ל',
    "צפצפה",
    "קהילות יעקב",
    "קורין",
    "קטיף",
    "קטלב",
    "קידה",
    "קרית בן אריה",
    "קרית מנחם",
    "קרית משה",
    "רב יוסף הכהן",
    "רבי עקיבא",
    "רימון",
    "רמת הגולן",
    "רפיח ים",
    "רקפת",
    "ש 2 קומות",
    "ש וילות",
    "ש טרומיים",
    "ש נוה נוי",
    "שבטי ישראל",
    "שד ויצמן",
    "שד ירושלים",
    "שושנה דמרי",
    "שזיף",
    "שיטה",
    "שייקה אופיר",
    "שיקמה",
    "שירת הים",
    "שכ אור החיים",
    "שכ בבא סאלי",
    "שכ הורד",
    "שכ החורש",
    "שכ נטעים",
    "שכ שלום בוניך",
    "שלום בונייך",
    "שליו",
    "שפתי חיים",
    "שקד",
    "תאנה",
    "תות",
    "תל קטיפה",
    "תלתן",
    "תמר",
    "תפוז",
    "תפוח",
    "תקומה",
  ],
  נתניה: [
    "אבא הלל סילבר",
    "אביעד",
    "אבן גבירול",
    "אבן עזרא",
    "אבנר בן נר",
    "אבנר מאיר",
    "אברבנאל",
    "אברהם פרידמן",
    'אד"ם הכהן',
    "אדמונית",
    "אדר",
    "אהוד בן גרא",
    "אהרון",
    "אהרונוביץ",
    "אהרוני יצחק",
    "אולפן בן יהודה",
    "אוסישקין",
    "אוסקר שינדלר",
    "אופיר שיקה",
    "אורגד",
    "אורט",
    'אז תעשייה ע"ש ספיר',
    'אח"י דקר',
    "אחד העם",
    "אחימאיר",
    "אחרית הימים",
    "אייזיק ניוטון",
    "אייר",
    "איכילוב",
    "אימבר",
    "אינשטיין",
    "איסר הראל",
    "אירוס הארגמן",
    "אירנה סנדלר",
    'אל"מ פרידן בן ציון',
    "אלול",
    "אלומות",
    "אלון צבי",
    "אלוני אבא",
    "אלחריזי",
    "אלי כהן",
    "אליאב לובה",
    "אליהו הגלעדי",
    "אליהו התשבי",
    "אליהו עטיה",
    "אליס פון-באטנברג",
    "אלישע",
    "אלכסנדר בל",
    "אלכסנדר ינאי",
    "אלנקווה אפרים",
    "אלעזר דוד",
    "אלפרד נובל",
    "אלקלעי",
    "אמנון ותמר",
    "אמרי יוסף",
    "אנדה עמיר",
    "אנדריוס",
    "אנה פרנק",
    "אנטוורפן",
    "אנילביץ",
    "אנצו סירני",
    "אסתר המלכה",
    "אפרים",
    "אפרים קישון",
    "אפשטיין",
    'אצ"ג',
    'אצ"י',
    'אצ"ל',
    "אקסודוס",
    "אריה לייב יפה",
    "אריק לביא",
    "ארלוזורוב",
    "ארליך שמחה",
    "ארן זלמן",
    "ארצי שרמן",
    "אשר",
    "בארון דבורה",
    "בארי",
    "בגין מנחם",
    "בובליק גדליהו",
    "בובר",
    "בוכנר יהודה",
    "בוסל",
    "בורגנסקי ישראל",
    "בורוכוב",
    "ביאליק",
    'ביל"ו',
    "בילינסון",
    "בית גיל הזהב",
    "בית חלוצות",
    "בית פלדמן",
    "בכר שמחה",
    "בלוז פסקל",
    "בלומנפלד",
    "בלפור",
    "בלקינד נעמן",
    "בלקני פנחס",
    "בן איש חי",
    "בן אליעזר",
    "בן חנן יצחק",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן מאיר ישראל",
    "בן נתן רפאל",
    "בן עטר חיים",
    "בן ציון",
    "בן שלום עובדיה",
    "בנאי יוסי",
    "בנג'מין ד'יזראלי",
    "בני בנימין",
    "בני יששכר",
    "בנין המשטרה",
    "בעל התניא",
    "בקמן",
    "בר אילן",
    "בר גיורא",
    "בר יהודה",
    "בר יוחאי",
    "בר כוכבא",
    "בר לב חיים",
    "ברודצקי",
    "ברוריה",
    "ברוש",
    "ברט אהרון",
    "ברכה ואריה סגלסון",
    "ברכה שמעון",
    "ברמן בני",
    "ברנר",
    "ברק בן אבינועם",
    "ברקת ראובן",
    "בשביס זינגר",
    "בת חן",
    "גאון בני",
    "גבורי ישראל",
    "גבורי ניצנים",
    "גבע",
    "גבעת האירוסים",
    "גבעת חיים",
    "גד",
    "גדעון",
    "גוטמכר אליהו",
    "גוטמן נחום",
    "גולדברג לאה",
    "גולדמינץ",
    "גולומב",
    "גולי קניה",
    "גור מרדכי",
    "גורדון",
    "גלילי ישראל",
    "גליקסון",
    "גרונר דב",
    "גרינבוים",
    "גרינברג",
    "גרינוולד",
    "גרנדוס",
    "גרץ",
    'ד"ר אבו שדיד אברהם',
    'ד"ר דב יוסף',
    'ד"ר חיים צדוק',
    'ד"ר יוסף בורג',
    'ד"ר משה זמורה',
    'ד"ר פרח יהודה',
    'ד"ר שרייבר',
    'ד"רבן עמר חיים',
    "דב שילנסקי",
    "דבורה",
    "דברי חיים",
    "דובנוב",
    "דוד אבידן",
    "דוד המלך",
    "דודו דותן",
    "דווייט אייזנהאואר",
    "דוכיפת",
    "דון יוסף הנשיא",
    "דונה גרציה",
    "דורה מ סוכנות",
    "דורה צריפים",
    "דורי יעקב",
    "דיזנגוף",
    "דיין שמואל",
    "דימיטר פשב",
    "דמארי שושנה",
    "דן",
    "דניאל",
    "דנקנר",
    "דרור שלמה",
    "דרך דגניה",
    "דרך הירדן",
    "דרך הפארק",
    "דרך הרכבת",
    "ה-93",
    "האדמור מויז'ניץ",
    "האומנות",
    "האוניברסיטה",
    "האורג",
    "האורזים",
    "האורן",
    "האחוה",
    "האחים רייט",
    "האיריסים",
    "האלה",
    "האלונים",
    "האניה ארינפורה",
    "האצטדיון",
    'האר"י',
    "הארבל",
    "הארז",
    "הארי טרומן",
    "האתרוג",
    "הבונים",
    "הביכורים",
    "הברבור",
    "הבריגדה היהודית",
    "הבשן",
    "הגאולה",
    "הגביש",
    "הגדוד העברי",
    "הגוזל",
    "הגולן",
    "הגילה",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הגפן",
    "הגר ברוך",
    'הגר"א',
    "הדודאים",
    "הדיצה",
    "הדליות",
    "הדף היומי",
    "הדקל",
    "הדר",
    "הדרור",
    "ההגנה",
    "ההדס",
    "ההסתדרות",
    "ההרדוף",
    "ההשכלה",
    "הוז דב",
    "הויסמנס קמיל",
    "הולנד",
    "הורדים",
    "הזוהר",
    "הזז חיים",
    "הזית",
    "הזמיר",
    "החדווה",
    "החוזה מלובלין",
    "החיטה",
    "החינוך העברי",
    "החלוץ הלוחם",
    "החסידה",
    "החפץ חיים",
    "החצבים",
    "החרוב",
    "החרמון",
    "החשמונאים",
    "הטורים",
    "הידידות",
    "היהלום",
    "היהלומן אברהמס",
    "היסמין",
    "היצירה",
    "הכובשים",
    "הכלנית",
    "הכרמל",
    'הל"ה',
    "הלבנון",
    "הלילך",
    "הלפרין ירמיהו",
    "המגיד",
    "המדע",
    "המורן",
    "המחקר",
    "המחשב",
    "המייסדים",
    "המכביה",
    "המלאכה",
    "המליץ",
    "המלכים",
    "המסגר",
    "המעפילים",
    "המרגניות",
    "המרד",
    "המרכבה",
    "המתמיד",
    "הנביאים",
    "הנגב",
    "הנגר",
    "הנוטע",
    "הנורית",
    "הנרקיס",
    "הנשר",
    "הס משה",
    "הסחלב",
    "הספורטאי",
    "העומר",
    "העמל",
    "הענבל",
    "הפועל הצעיר",
    "הפורצים",
    "הפלדה",
    "הפרגים",
    "הפרדס",
    "הצורן",
    "הצנחנים",
    "הצפירה",
    "הקבלה",
    "הקדר",
    "הקונגרס הציוני",
    "הקליר",
    "הר מירון",
    "הר ציון",
    "הר שגיא",
    "הרב אבו חצירה",
    "הרב אהרונסון",
    "הרב אונטרמן יהודה",
    "הרב איפרגן שלום",
    "הרב אליהו מרדכי",
    "הרב ברוד",
    "הרב גולד",
    "הרב הלברשטם ברוך",
    "הרב הרטום שמואל",
    "הרב זוארץ פריגיה",
    "הרב טביב",
    "הרב טולדנו",
    "הרב טייב חי",
    "הרב יאנה",
    "הרב יוסף אלישיב",
    "הרב יצחק הלוי",
    "הרב מגרליץ",
    "הרב משה צבי נריה",
    "הרב נסים יצחק",
    "הרב עובדיה יוסף",
    "הרב עמיאל",
    "הרב פרידר אברהם",
    "הרב פרנקל ידידיה",
    "הרב קוק",
    "הרב קלישר",
    "הרב ראט משולם",
    "הרב ריינס",
    "הרב רפאל",
    "הרב רקח יעקב",
    "הרב שמעון גבאי",
    "הרופאה רבקה",
    "הרימון",
    "הרינה",
    "הרכב",
    'הרמ"א',
    "הרעות",
    "הרצוג",
    "הרצל",
    "הרקפת",
    "השבעה",
    "השומר",
    "השופטים",
    "השושנה",
    "השחף",
    "השיאים",
    "השיזף",
    "השילוח",
    "השיש",
    "השעורה",
    "השקד",
    "השקמה",
    "התאנה",
    "התהילה",
    "התור",
    "התלתן",
    "התמר",
    "התנאים",
    "התנועה הציונית",
    "התקומה",
    "התקופה",
    "התרופה",
    "וולך יונה",
    "וולפסון",
    "וינר נחמה",
    "ויס יעקב",
    "ויסבורג",
    "ויסלברגר",
    "ויתקין",
    "ולוס",
    "ועידת הלסינקי",
    "וריאן פריי",
    "ז'בוטינסקי",
    "ז'ילבר",
    "זבולון",
    "זהבית",
    "זומרשטיין",
    "זיגמונד פרויד",
    "זייד אלכסנדר",
    "זמבה",
    "זמנהוף",
    "זנגביל",
    'חב"ד',
    "חבצלת החוף",
    "חבקוק",
    "חולדה",
    "חוף גולדמינץ",
    "חורגין",
    "חזה עופרה",
    "חזני",
    "חטיבת אלכסנדרוני",
    "חטיבת גבעתי",
    "חטיבת הראל",
    "חטיבת קריתי",
    "חיבת ציון",
    "חיות צבי פרץ",
    "חיטמן עוזי",
    "חלמונית",
    "חנה מרון",
    'חרל"פ',
    "חרצית",
    "חשון",
    'חת"ם סופר',
    "טבת",
    "טהון יהושע",
    "טיומקין",
    "טרומפלדור",
    "טשרניחובסקי",
    "יאיר",
    "יאיר רוזנבלום",
    "יד חרוצים",
    "יהודה",
    "יהודה הלוי",
    "יהודה הנשיא",
    "יהושע בן נון",
    "יואב",
    "יודפת",
    "יוהאן גוטנברג",
    "יוהאן ואן הולסט",
    "יוחנן בדר",
    "יוחנן בן זכאי",
    "יוחנן הסנדלר",
    "יונה",
    "יונה בוגולה",
    "יוסי בן יוסי",
    "יוסף",
    "יוסף לפיד",
    "יוסף סמיה",
    "יוסף שרטוב",
    "יוספטל",
    "יורדי הים",
    "יותם",
    "יחזקאל",
    "יחיא הלוי",
    'יל"ג',
    "ילין",
    'יעב"ץ',
    "יעל אשת חבר הקיני",
    "יפה ירקוני",
    "יפתח הגלעדי",
    "יצחק הנפח",
    "יקינתון",
    "ירמיהו",
    "ישעיהו",
    "ישעיהו (סי) קינן",
    "יששכר",
    "כהן דוד הנזיר",
    "ככר אמני ישראל",
    "ככר הבנים",
    "ככר העצמאות",
    "ככר טבועי מצדה",
    "ככר מטלון",
    "ככר ציון",
    "ככר רוטרי",
    "ככר שתיים",
    "כסלו",
    "כפר אוריה",
    "כפר עידוד",
    "כפר תבור",
    "לאון אדרי",
    "לבון פנחס",
    "לבונטין",
    "לבנון חיים",
    "לובטקין צביה",
    "לוחמי גליפולי",
    "לוטם",
    "לוי",
    "לוי אשכול",
    "לוי שושן",
    "לוין אריה",
    'לח"י',
    "ליבסקינד",
    "ליבר זאב",
    "לילינבלום",
    "לישנסקי",
    "לנדאו שמואל",
    "לסקוב חיים",
    "מאיר אריאל",
    "מאיר זורע",
    "מאירוביץ",
    "מאנה",
    "מבוא פורר שלום",
    "מגורי חיים כהן",
    "מודעי יצחק",
    "מוהליבר",
    "מול עמנואל",
    "מונטיפיורי",
    "מוסד בית תינה",
    "מוסינזון יגאל",
    "מוצקין",
    "מורדי הגטאות",
    "מוריה",
    'מזא"ה',
    "מחנה יעקב",
    "מחנה עבודה",
    "מטולה",
    'מיכ"ל',
    "מיכאל",
    "מימון",
    "מינץ",
    "מכון וינגייט",
    "מכלוף גד",
    "מכנס גד",
    "מלאכי",
    "מלבן דורה",
    "מלון הנסיכה",
    "מלון כרמל",
    "מלון פארק",
    "מלחמת ששת הימים",
    "מלכין שרה",
    "מנדלי מוכר ספרים",
    "מנדלסון",
    "מנור אהוד",
    "מנלה גד",
    "מסדה",
    "מסילות",
    "מסריק",
    "מסרק",
    'מע"ר חדש',
    "מעלה רחבעם זאבי",
    "מעלות אילת",
    "מעלות הרב וורנר",
    "מעלות הרב לאו",
    "מעפילי אגוז",
    "מפי",
    "מקדונלד",
    "מקלף מרדכי",
    "מרים",
    "מרק אברהם",
    "מרשל פייר קניג",
    "משה הלוי לוין",
    "משה וילנסקי",
    "משה לב-עמי",
    "משכנות זבולון המר",
    "משמר הירדן",
    "משעול קיפניס",
    "משעול תמר",
    "משפחת מלבסקי",
    "מתתיהו הכהן",
    "נאות בגין",
    "נאות גנים",
    "נאות התכלת",
    "נאות פנחס רוזן",
    "נווה אבא",
    "נווה עוז",
    "נווה שלום",
    "נויפלד",
    "נוף חפר",
    "נופר",
    "נורדאו",
    "נורוק",
    "נחום",
    "נחום ניר רפאלקס",
    "נחל עוז",
    "נחמיה",
    'ניל"י',
    "ניניו",
    "ניסן",
    "ניסנבוים",
    "נפתלי",
    "נר הלילה",
    "נרי יצחק",
    "נתן אלתרמן המשורר",
    "נתן יונתן",
    "נתניה",
    "סבידור מנחם",
    "סביון",
    "סולד הנריאטה",
    "סוקולוב",
    "סטופ",
    "סטרומה",
    "סיגלית",
    "סיוון",
    "סילברשטיין צבי",
    "סיני",
    "סירקין",
    "סלומון יואל",
    "סמ סטודיו",
    "סמ עולי סידקוב",
    "סמ פיקסלר",
    "סמילנסקי",
    "סנה משה",
    "סנש חנה",
    "סעדיה גאון",
    "ספיר יוסף",
    "סר ניקולס וינטון",
    "עגנון",
    "עובדיה אברהם הכהן",
    "עובדיה מברטנורא",
    "עולי הגרדום",
    "עולש מצוי",
    "עזרא",
    "עטרי שוש",
    "עירית",
    "עליה",
    "עמית מאיר",
    "עמק חפר",
    "עמר ג'ו",
    "עציון",
    "פארק האלונים",
    "פארק שיאים",
    "פוליאקוב ישראל",
    "פייר אנייר",
    "פיירברג",
    "פיכמן",
    "פינס",
    "פינסקר",
    "פלוגת מכבי",
    'פלמ"ח',
    "פנחס רוזן",
    "פנצ'ו",
    "פנקס דוד",
    "פעמונית",
    "פרדי",
    "פרוג שמעון",
    "פרומקין",
    "פרופ' שור",
    "פרופס אהרון צבי",
    "פרידר עמנואל",
    "פרישמן",
    "פרנק פולר",
    "פרנקו פבל",
    "פרץ",
    "פתח תקוה",
    "צ'יאונה סוגיהארה",
    "צ'רצ'יל",
    "צאלון",
    "צבי מויססקו",
    "צבעוני",
    "צהלה",
    "צור צבי",
    "ציטלין הלל",
    "ציפורן",
    "ציר טנצר",
    "צירלסון",
    "קאפח יחיא",
    "קדושי בלזן",
    "קדושי עירק",
    "קדיש לוז",
    "קדמה",
    "קדש",
    "קהילת צפת",
    "קומוי נתן",
    "קורצ'ק יאנוש",
    "קיש",
    "קלוזנר יוסף",
    "קנדי",
    "קניון השרון",
    "קפלן",
    "קפלנסקי",
    "קפקא",
    "קצנלסון יצחק",
    'קק"ל',
    "קראוזה",
    "קריניצי",
    "קרית אליעזר קפלן",
    "קרית השרון",
    "קרית יהלום",
    "קרית יצחק רבין",
    "קרית צאנז",
    "קרל לוץ",
    "קרל פופר",
    "קרליבך",
    "קרמניאן משה",
    "קרן היסוד",
    "ראובן",
    "ראול ולנברג",
    "ראש פנה",
    "ראשון לציון",
    "רבי טרפון",
    "רבי מאיר",
    "רבי עקיבא",
    "רבן גמליאל",
    "רגב אריה",
    'רד"ק',
    "רוטנברג",
    "רופין ארתור",
    "רות המואביה",
    "רותם",
    "רזיאל",
    "רזניק דוד",
    "רחבת כצנלסון ראובן",
    "רחבת מרואני מכלוף",
    "רחל המשוררת",
    "ריבלין",
    "רייך בני",
    "רייך לאון",
    "רייק חביבה",
    "רם ברוך",
    'רמב"ם',
    'רמב"ן',
    "רמבה אייזיק",
    "רמז דוד",
    "רמת הגולן",
    "רמת זאב",
    "רמת ידין",
    "רמת נורדאו",
    "רמת פולג",
    "רפאל איתן",
    "רצועת החוף והמלונות",
    'רש"י',
    "ש גן ברכה",
    "ש דורה יבנה",
    "ש דורה צפון",
    "ש דורה קבלנים",
    "ש דיור לעולה",
    "ש המרכז",
    "ש הפומז",
    "ש הפועלים",
    "ש טוברוק",
    "ש מחנה מעבר",
    "ש מכנס",
    "ש סלע",
    "ש עין התכלת",
    "ש עמידר",
    "ש עמידר פתוח",
    "ש צאל",
    "ש ציונים כלליים",
    "ש רמת הרצל",
    "ש רסקו",
    "ש שמשון",
    'ש"ץ גרשון',
    "שאול המלך",
    "שבזי",
    "שבח",
    "שבט",
    "שבטי ישראל",
    "שד אליעזר",
    'שד בן אב"י',
    "שד בן גוריון",
    "שד בן צבי",
    "שד בנימין",
    "שד גולדה מאיר",
    "שד ויצמן",
    'שד ח"ן',
    "שד ירושלים",
    "שד לנטוס טום",
    "שד ניצה",
    "שד עובד בן עמי",
    "שד עין התכלת",
    "שד צרפת",
    "שד שמשון",
    "שדה יצחק",
    "שדרות אגם כנרת",
    "שדרות היהלומים",
    "שדרת האגמים",
    "שוב דוד",
    "שוהם",
    "שוורצבארד שלום",
    "שולמית",
    "שומרון דן",
    'שז"ר זלמן',
    "שטייגר",
    "שטמפפר",
    "שטנד",
    "שטריקר",
    "שיטרית",
    "שיפר יעקב",
    "שכ אבו שדיד",
    "שכ אום חאלד",
    "שכ בן ציון",
    "שכ גבע ב",
    "שכ גלי הים",
    "שכ האגם",
    "שכ נאות גולדה",
    "שכ נאות חפציבה",
    "שכ נאות שקד",
    "שכ נוה איתמר",
    "שכ נוה דוד",
    "שכ נווה שלום",
    "שכ נוף",
    "שכ נוף גלים",
    "שכ עיר ימים",
    "שכ פרדס הגדוד",
    "שכ רמת אפרים",
    "שכ רמת חן",
    "שכונת המדע",
    "שכטרמן",
    "שלום עליכם",
    "שלונסקי",
    "שלמה המלך",
    "שמואל",
    "שמואל הנציב",
    "שמורק",
    "שמורת אגם החולה",
    "שמורת נחל בניאס",
    "שמורת נחל בשור",
    "שמורת נחל דולב",
    "שמורת נחל עמוד",
    "שמורת נחל ערוגות",
    "שמורת נחל שורק",
    "שמורת נחל שניר",
    "שמחוני אסף",
    "שמעון",
    "שמעון ויזנטל",
    "שניאור זלמן",
    "שנירר שרה",
    "שער הגיא",
    "שער העמק",
    "שער עובד",
    "שפוני גדעון",
    "שפיגלמן אברהם",
    "שפינוזה",
    "שפירא אברהם",
    "שפירא משה",
    "שפרינצק",
    "שרעבי",
    "שרת",
    "תאשור",
    "תדהר",
    "תומס אדיסון",
    "תורמוס",
    "תל חי",
    "תמוז",
    "תנועת נוער ציוני",
    "תפארת בנים",
    "תרצה אתר",
    "תשרי",
  ],
  "סאג'ור": [
    "אבנאא יעקב",
    "אל-ח'לה",
    "אל-לורי",
    "אלבאבור",
    "אלביאדר",
    "אלבלאטה",
    "אלברכה",
    "אלג'בל",
    "אלג'מאל",
    "אלג'רף",
    "אלורדה",
    "אלזיר",
    "אלזיתון",
    "אלזנאר",
    "אלח'לוה",
    "אלחואכיר",
    "אלחמרה",
    "אלטיון",
    "אלטין",
    "אלכרם אלעמיק",
    "אלמזבלה",
    "אלמקאבר",
    "אלנס'ב",
    "אלנקאר",
    "אלס'חירה",
    "אלסהל",
    "אלסרוה",
    "אלעטאר",
    "אלעין",
    "אלקובה",
    "אלתנור",
    "דהר אלביאד",
    "דהר אלמגארה",
    "ואדי חמיד",
    "יוסף אלגריב",
    "מסרב אלחסאדין",
    "מע'ארת עובה",
    "סאג'ור",
    "ראס אלמרג'",
  ],
  סאסא: ["ביס שדה הר מירון", "סאסא"],
  סביון: [
    "אוריה",
    "אלון",
    "אמירים",
    "אנפה",
    "ארז",
    "גן השקמים",
    "גני יהודה",
    "דגן",
    "דוכיפת",
    "דרור",
    "האופק",
    "האורנים",
    "האירוס גני יהודה",
    "האתרוג",
    "הברוש",
    "הגבעה",
    "הגדרות",
    "הגיא",
    "הגנים גני יהודה",
    "הדס",
    "הדקל",
    "הדרום",
    "ההדרים",
    "הוורד",
    "הורדים גני יהודה",
    "הזית גני יהודה",
    "החורש",
    "החרמש",
    "המועדון",
    "המתנחלים גני יהודה",
    "הנוף",
    "הצבעוני גני יהודה",
    "הצבר",
    "הצפון",
    "הקשת",
    "הר דפנה",
    "הראשונים",
    "הרגב",
    "הרכס",
    "השושנה",
    "השחר",
    "השלוה",
    "השקד",
    "השקמה",
    "התומר",
    "התיכון",
    "התלם",
    "זמיר",
    "חוחית",
    "חופית",
    "יסעור",
    "ככר העשור",
    "כרם הזיתים",
    "לבנית",
    "מבואות",
    "מגל",
    "מגלן",
    "נחליאל",
    "סביון",
    "סלעית",
    "סמ הגבעה",
    "סמ הגדרות",
    "סמ הגיא",
    "סמ הדרום",
    "סמ הוורד",
    "סמ החבצלת גני יהודה",
    "סמ החצב גני יהודה",
    "סמ החרמש",
    "סמ הכלנית גני יהודה",
    "סמ המרגנית",
    "סמ הנורית גני יהודה",
    "סמ הנרקיס גני יהודה",
    "סמ העשור גני יהודה",
    "סמ הר דפנה",
    "סמ הרקפת גני יהודה",
    "סמ השקמה",
    "סמ חופית",
    "סמ כרם הזיתים",
    "סמ מבואות",
    "סמ מגל",
    "סמדר",
    "סנונית",
    "עגורים",
    "עיטם",
    "עלומים",
    "עפרוני",
    "צופית",
    "צוקית",
    "רימונית",
    "שחף",
    "שלדג",
  ],
  סגולה: [
    "דרך הבציר",
    "דרך הגורן",
    "דרך הזורע",
    "דרך היוגב",
    "דרך המסיק",
    "משעול האסיף",
    "משעול הגדיד",
    "משעול הדיר",
    "משעול החריש",
    "משעול הנוקד",
    "משעול הקטיף",
    "משעול הקציר",
    "סגולה",
    "שדרת האיכרים",
  ],
  "סואעד (חמרייה)": ["סואעד (חמרייה)"],
  "סואעד (כמאנה) (שבט)": ["סואעד (כמאנה) (שבט)"],
  סולם: ["סולם"],
  סוסיה: ["סוסיה"],
  סופה: ["סופה"],
  "סח'נין": [
    "אזור התעשייה",
    "אל ג'אבה",
    "אל חלאן",
    "אל מסאחה",
    "אל מסרארה",
    "אלבלאנה",
    "אלברכה",
    "אלסיד",
    "אלרויס",
    "ג'ורת סמיעה",
    "ד'יל נח'לה",
    "העיר העתיקה",
    "ואדי אל ספא",
    "ואדי אלעין",
    "זוגות צעירים",
    "ח'לת אל סח'ר",
    "ח'לת בלוט",
    "ח'לת ח'שיש שכ ורדים",
    "ח'לת חסן",
    "מרכז העיר",
    "מרסאן",
    "משפחת ח'לאילה",
    "משפחת טרביה",
    "סח'נין",
    "קסום",
    "שכונת הנוצרים",
  ],
  "סייד (שבט)": ["סייד (שבט)"],
  סלמה: ["סלמה"],
  סלעית: ["סלעית"],
  סמר: ["סמר"],
  סנסנה: ["סנסנה"],
  סעד: ["סעד"],
  סעוה: ["סעוה"],
  סער: ["סער"],
  ספיר: [
    "גבים",
    "הר ארחות",
    "הר כרכום",
    "הר נטפים",
    "הר רמון",
    "הר שגיא",
    "הר שחורת",
    "נקרות",
    "ספיר",
    "צין",
  ],
  סתריה: [
    "אבני החושן",
    "אודם",
    "אלמוג",
    "ברקת",
    "הגפן",
    "הזית",
    "החיטה",
    "הרימון",
    "השעורה",
    "התאנה",
    "התמר",
    "טופז",
    "יהלום",
    "לשם",
    "ספיר",
    "סתריה",
    "ענבר",
    "שבעת המינים",
    "שוהם",
    "שכון הבנים",
    "תרשיש",
  ],
  "ע'ג'ר": ["ע'ג'ר"],
  עבדון: ["עבדון"],
  עברון: ["עברון"],
  עגור: ["עגור"],
  עדי: [
    "אודם",
    "אלון",
    "ברקת",
    "הברוש",
    "הגומא",
    "הדס",
    "הדקל",
    "הורד",
    "הזית",
    "החרוב",
    "השקד",
    "טופז",
    "יהלום",
    "מרגלית",
    "ספיר",
    "עדי",
    "שנהב",
    "תמר",
  ],
  עדנים: [
    "דרך האיכרים",
    "דרך הכפר",
    "הבאר",
    "ההדס",
    "ההדר",
    "היסמין",
    "הלבונה",
    "המור",
    "המרווה",
    "הריחן",
    "הרקון",
    "השדות",
    "כיכר המחלבה",
    "כיכר המייסדים",
    "סמ האפרסמון",
    "סמ הרוזמרין",
    "סמ מגדל המים",
    "עדנים",
  ],
  עוזה: ["עוזה"],
  עוזייר: ["עוזייר"],
  עולש: ["מגל", "מורג", "נוף הרים", "נוף שדות", "עולש", "שדרות היוגב"],
  עומר: [
    "אגוז",
    "אגמון",
    "אדמונית",
    "אוכמנית",
    "אורן",
    "אטד",
    "אירוס",
    "אלומות",
    "אלון",
    "אפרסמון",
    "אקליפטוס",
    "ארז",
    "אשוח",
    "אשכולות",
    "אשל",
    "בית הבד",
    "ברוש",
    "ברקן",
    "גני עומר",
    "דבורנית",
    "דגנית",
    "דפנה",
    "דקל",
    "דרדר",
    "דרך האגם",
    "דרך היער",
    "דרך הנחל",
    "האלה",
    "הגדרון",
    "הגורן",
    "הגפן",
    "הגת",
    "הדוכיפת",
    "הדס",
    "הדר",
    "הזית",
    "החופית",
    "החורשה",
    "החסידה",
    "הטבלן",
    "הינשוף",
    "היקב",
    "הכרם",
    "הנחשון",
    "הנשר",
    "הסלעית",
    "העפרוני",
    "הפנינית",
    "הקורא",
    "הרדוף",
    "הרימון",
    "השיטה",
    "התנשמת",
    "חבצלת",
    "חיננית",
    "חלמונית",
    "חצב",
    "חרוב",
    "חרצית",
    "יערה",
    "יצהר",
    "כוכבן",
    "כליל החורש",
    "כלנית",
    "כרכום",
    "לבונה",
    "לבנה",
    "לוטם",
    "לילך",
    "לימונית",
    "מור",
    "מעבר האגמית",
    "מעבר האנפה",
    "מעבר החורשה",
    "מעבר היסעור",
    "מעבר הליבנית",
    "מעבר השחף",
    "מעבר השלדג",
    "מעבר השקנאי",
    "מעבר התמירון",
    "מרגנית",
    "מרווה",
    "משעול אורן",
    "משעול אתרוג",
    "משעול דולב",
    "משעול האדמון",
    "משעול הברבור",
    "משעול הדרור",
    "משעול הורדית",
    "משעול הזהבן",
    "משעול הזמיר",
    "משעול החוגלה",
    "משעול החוחית",
    "משעול היונה",
    "משעול הירקון",
    "משעול הלילית",
    "משעול הנץ",
    "משעול הסנונית",
    "משעול העגור",
    "משעול העורב",
    "משעול העיט",
    "משעול הפשוש",
    "משעול הצופית",
    "משעול הצוקית",
    "משעול הקוקיה",
    "משעול השחרור",
    "משעול התור",
    "משעול זרזיר",
    "משעול מורן",
    "משעול סיסם",
    "משעול שקמה",
    "נורית",
    "נחליאלי",
    "ניצנים",
    "נרקיס",
    "סביון",
    "סחלב",
    "סיגלון",
    "סיגלית",
    "סייפן",
    "סרפד",
    "סתונית",
    "עד עד",
    "עומר",
    "עומרים",
    "ערבה",
    "פארק תעשיה",
    "פלפלון",
    "פעמונית",
    "פרג",
    "צאלה",
    "צבעוני",
    "צבר",
    "ציפורן",
    "צלף",
    "צפצפה",
    "קידה",
    "קינמון",
    "רותם",
    "רקפת",
    "שושן",
    "שיבולים",
    "שיזף",
    "שקד",
    "שקמה",
    "תאנה",
    "תאשור",
    "תדהר",
    "תירוש",
    "תמר",
    "תפוז",
    "תפוח",
  ],
  עופר: [
    "האורן",
    "האלה",
    "האלון",
    "הברוש",
    "הדקל",
    "הזית",
    "החרוב",
    "הרימון",
    "השקד",
    "התאנה",
    "כליל החורש",
    "עופר",
  ],
  עופרה: [
    "אולפנא",
    "אמונים",
    'אצ"ל',
    "אשר",
    "בינה ודעת",
    "בני יעקב",
    "גבעת צבי",
    "גד",
    "דינה",
    "דן",
    "דרך ארץ ימיני",
    "דרך החיים",
    "הגפן",
    "הדבש",
    "הדובדבן",
    "ההגנה",
    "הזית",
    "הכרם",
    "המטע",
    "המייסדים",
    "הסלע",
    'הפלמ"ח',
    "הצבר",
    "הראשונים",
    "הרימון",
    "השקד",
    "התאנה",
    "התמר",
    "התעשיה",
    "זבולון",
    "ט' באייר",
    "יהודה",
    "יוסף",
    "יששכר",
    "לוי",
    'לח"י',
    "מורג",
    "מעלה בני עקיבא",
    "מקימי הגדר",
    "משעול הר אפרים",
    'ניל"י',
    "נפתלי",
    "סמ בנימין",
    "עופרה",
    "פנינת חן",
    "ראובן",
    "ש קרוונים",
    "שבטי ישראל",
    "שדה עופרים",
    "שכ הכרם",
    "שכ הצבר",
    "שכ עמונה",
    "שכ פנינת חן",
    "שכ צמרת",
    "שכ שדה עופרים",
    "שמעון",
  ],
  עוצם: ["עוצם"],
  "עוקבי (בנו עוקבה)": ["עוקבי (בנו עוקבה)"],
  עזוז: ["עזוז"],
  עזר: [
    "אנפה",
    "דוכיפת",
    "דרור",
    "האורן",
    "האלה",
    "האלון",
    "הברוש",
    "הדקל",
    "הורד",
    "הזית",
    "החוחית",
    "הכלנית",
    "הלילך",
    "הנרקיס",
    "הפרג",
    "הפשוש",
    "הצבעוני",
    "סנונית",
    "עזר",
    "שחף",
  ],
  עזריאל: ["הכרם", "הרימון", "התאנה", "התפוח", "עזריאל"],
  עזריה: ["עזריה"],
  עזריקם: ["עזריקם"],
  "עטאוונה (שבט)": ["עטאוונה (שבט)"],
  עטרת: [
    "ארץ צוף",
    "באר זית",
    "בני חשמונאי",
    "גיבורי התהילה",
    "הגדוד העברי",
    "הנביא שמואל",
    "הרי גופנה",
    "מעלה ההר",
    "נחלת אפרים",
    "עטרת",
    "רמתיים צופים",
  ],
  עידן: ["עידן"],
  עיילבון: ["עיילבון"],
  עיינות: ["עיינות"],
  עילוט: [
    "אבו בכר אלצדיק",
    "אבו מוסא",
    "אבו עוביידה",
    "אבו תנהא",
    "אבן בטוטה",
    "אבן סינא",
    "אל מותנבי",
    "אלאבטאל",
    "אלאקסא",
    "אלבאבא",
    "אלביאדר",
    "אלג'בל",
    "אלג'ולאן",
    "אלג'ליל",
    "אלהודא",
    "אלואד",
    "אלוארד",
    "אלזהראא",
    "אלזייתון",
    "אלחאג' מוסטפא",
    "אליאסמין",
    "אלירמוק",
    "אלכנסאא",
    "אלכרום",
    "אלמאמור",
    "אלמגידל",
    "אלמדינה",
    "אלמדרסה",
    "אלמידאן",
    "אלמנזל",
    "אלמראח",
    "אלנאורס",
    "אלנאסרה",
    "אלנבי לוט",
    "אלנור",
    "אלניל",
    "אלנסר",
    "אלנרג'ס",
    "אלסדרה",
    "אלסולטאן",
    "אלסלאם",
    "אלסנאבל",
    "אלסנובר",
    "אלספא",
    "אלספאח",
    "אלעין",
    "אלערב",
    "אלקודס",
    "אלקיירואן",
    "אלקרונפול",
    "אלשועראא",
    "אלשיך רביע",
    "אלתות",
    "ביסאן",
    "בלאל בן רבאח",
    "ג'בל סיך",
    "דג'לה",
    "דפלה",
    "הארון אלרשיד",
    "חאלד אבן אלוליד",
    "חוסין אבו עיאש",
    "חיפה",
    "טאהא חוסין",
    "טבריה",
    "יונס",
    "לימון",
    "מופלח סעיד",
    "מיי זיאדה",
    "סאלח",
    "סלאג'ה",
    "סלאח אלדין",
    "סלטי",
    "סעיד אבו זוויד",
    "סעיד סאלח",
    "ספד",
    "ספוריה",
    "עבד אללה אלחאג'",
    "עבד אלרחמאן",
    "עומר אבן אלח'טאב",
    "עותמאן בן עפאן",
    "עילוט",
    "עין אלשמאלייה",
    "עלי בן אבי טאלב",
    "עלי סברי",
    "פאדל אבו ראס",
    "ראס אלתין",
    "רמדאן",
    "שיך אחמד",
  ],
  "עין איילה": [
    "הגפן",
    "הזית",
    "החיטה",
    "הרימון",
    "השעורה",
    "התאנה",
    "התמר",
    "עין איילה",
  ],
  "עין אל-אסד": [
    "אל-אבנאא",
    "אל-בוסתאן",
    "אל-ביאדר",
    "אל-ג'נוד",
    "אל-זיתון",
    "אל-מוע'אר",
    "אל-עין",
    "אלח'לוה",
    "עין אל-אסד",
    "רח 11",
    "רח 4",
    "רח 14",
    "רח 5",
    "רח 15",
    "רח 16",
    "רח 7",
    "רח 8",
    "רח 18",
  ],
  "עין גב": ["בית ראשון במולדת", "עין גב"],
  "עין גדי": ["ביס שדה", "בית ראשון במולדת", "מצדה", "עין גדי"],
  "עין דור": [
    "בעלת האוב",
    "דבורה הנביאה",
    "דרך התבור",
    "הברוש",
    "הגורן",
    "הגן",
    "הדקל",
    "הזית",
    "החבצלת",
    "החינוך",
    "החרובים",
    "היוגבים",
    "הכלנית",
    "הכרמים",
    "המוסדניקים",
    "המייסדים",
    "הפיקוס",
    "הפקאן",
    "הפרדס",
    "השדות",
    "השומר הצעיר",
    "השיזף",
    "השקד",
    "התורמוס",
    "התות",
    "ילדי השמש",
    "עין דור",
    "שאול המלך",
    "שדרות מסע",
  ],
  "עין הבשור": [
    "אוגדה",
    "דקלה",
    "זהרון",
    "חרובית",
    "ימית",
    "מבוא סיני",
    "נביעות",
    "עין הבשור",
    "פריאל",
    "שדות",
  ],
  "עין הוד": ["עין הוד"],
  "עין החורש": ["עין החורש"],
  "עין המפרץ": ["עין המפרץ"],
  'עין הנצי"ב': ['עין הנצי"ב'],
  "עין העמק": ["הרחבה", "עין העמק"],
  "עין השופט": ["אולפן קיבוץ", "בית ראשון במולדת", "עין השופט"],
  "עין השלושה": ["בית ראשון במולדת", "עין השלושה"],
  "עין ורד": [
    "באר-גן",
    "דרור",
    "דרך בית הכנסת",
    "האלון",
    "הברכות",
    "הדרים",
    "היובל",
    "הירדן",
    "הכלנית",
    "הלימון",
    "המעיין",
    "הנוער",
    "הנחל",
    "הסביון",
    "הסייפן",
    "הפרדס",
    "הפרסה",
    "הצבעוני",
    "הצבר",
    "הראשונים",
    "השזיף",
    "התורמוס",
    "התפוז",
    "ככר הברכות",
    "ככר הירדן",
    "ככר העם",
    "ככר הצאלון",
    "ככר הראשונים",
    "עין ורד",
    "צאלון",
  ],
  "עין זיוון": ["בית ראשון במולדת", "עין זיוון"],
  "עין חוד": ["עין חוד"],
  "עין חצבה": ["עין חצבה"],
  "עין חרוד (איחוד)": ["עין חרוד (איחוד)"],
  "עין חרוד (מאוחד)": ["עין חרוד (מאוחד)"],
  "עין יהב": ["עין יהב"],
  "עין יעקב": [
    "אלון",
    "ברוש",
    "גפן",
    "דקל",
    "האלה",
    "זית",
    "חרוב",
    "משעול האלה",
    "עין יעקב",
    "רימון",
    "תאנה",
    "תמר",
  ],
  'עין כרם-בי"ס חקלאי': ['עין כרם-בי"ס חקלאי'],
  "עין כרמל": ["עין כרמל", "שכ קרוונים"],
  "עין מאהל": [
    "אלביאדר",
    "אלווערה",
    "אלח'ללה",
    "אלכרום",
    "אלמנזלה",
    "אלסואדיה",
    "אלעאבהרה",
    "אם נסאר",
    "ואדי אלח'וויך",
    "מרכז הכפר",
    "עין מאהל",
  ],
  "עין נקובא": [
    "האגוז",
    "האגס",
    "האפרסק",
    "הגנים",
    "הדקלים",
    "המעיין",
    "השלום",
    "השקד",
    "התאנים",
    "התפוח",
    "עין נקובא",
  ],
  "עין עירון": ["עין עירון"],
  "עין צורים": [
    "בירייה",
    "גוש עציון",
    "הגלבוע",
    "להגשמה",
    "מגשימים",
    "מרחבים",
    "נחשון",
    "נתיבות",
    "עין צורים",
    "שחל",
  ],
  "עין קנייא": ["עין קנייא"],
  "עין ראפה": [
    "אל-ווערה",
    "אל-ח'לה",
    "אל-חארה",
    "אל-עין",
    "אל-קריה",
    "אלוואד",
    "אלכרום",
    "אלכרם",
    "אלמישה",
    "אסכידניא",
    "בוסתאן",
    "בלדי",
    "בעיקשה",
    "ברקוק",
    "ג'וז",
    "ג'ורי",
    "ג'ורן",
    "ג'ניאן",
    "דליה",
    "ורד",
    "זוזו",
    "זיתון",
    "זערור",
    "זעתר",
    "חאכורה",
    "חורש",
    "חרוב",
    "יסמין",
    "כראב אלקיק",
    "לואיזה",
    "לוז",
    "לימון",
    "מחורה",
    "מעסרה",
    "מראח",
    "משמש",
    "נבעה",
    "נח'יל",
    "נענע",
    "נרג'ס",
    "סבר",
    "סוסן",
    "סלאם",
    "סנסלה",
    "עין אל-עמור",
    "עין סובא",
    "עין ראפה",
    "ענב",
    "עראק אלמטבך",
    "עריש",
    "קרונפול",
    "רוזמרין",
    "ריחאן",
    "תות",
    "תין",
    "תפאח",
  ],
  "עין שמר": ["עין שמר"],
  "עין שריד": [
    "אגמית",
    "ברבור",
    "דוכיפת",
    "דרור",
    "האנפה",
    "זיזית",
    "זמיר",
    "חוגלה",
    "חוחית",
    "חופית",
    "יסעור",
    "כחל",
    "נחליאלי",
    "סייפן",
    "סלעית",
    "סנונית",
    "עגור",
    "עיט",
    "עין שריד",
    "עפרוני",
    "פשוש",
    "צוקית",
    "שחף",
    "שלדג",
    "שקנאי",
  ],
  "עין תמר": ["עין תמר"],
  עינת: ["עינת"],
  "עיר אובות": ["עיר אובות"],
  עכו: [
    "אבות ובנים",
    "אבירי יוחנן",
    "אבן גבירול",
    "אבני החושן",
    "אברבנאל",
    "אברהם בן-שושן",
    "אברהם דנינו",
    "אגם",
    "אגס",
    "אהוד מנור",
    "אוסישקין",
    "אזור התעשיה",
    "אזור התעשייה",
    "אזור תעשיה דרום",
    "אזור תעשיה שער נעמן",
    "אזור תעשייה אלרום",
    'אח"י אילת',
    "אחד העם",
    "אחים קנדי",
    "איה",
    "איינשטיין",
    "אל ג'זאר",
    "אלחריזי",
    "אלכסנדר מוקדון",
    "אלמוג",
    "אלקבץ",
    "אלקלעי",
    "אמלפי",
    "אנה פרנק",
    "אנילביץ'",
    "אסירי המבצר",
    "אסתר ראב",
    "אפרים",
    "ארגוב",
    "אריאל",
    "את מילואות דרום",
    "את קרית הפלדה",
    "אתגר",
    "בבל",
    "בולטימור",
    "בורוכוב",
    "בורלא",
    "בורנשטיין",
    'בי"ח מזרע',
    'ביל"ו',
    "בית לוסיניאן",
    "בלדווין",
    "בן יהודה",
    "בן עמי",
    "בנימין",
    "בנימין מטודלה",
    "בר-לב",
    "ברל",
    "ברנר",
    "בת שבע",
    "גבעת התמרים",
    "גד",
    "גדוד 22",
    "גולדה מאיר",
    "גולומב",
    "גורדון",
    "גיבורי סיני",
    "גל",
    "גנואה",
    "דאהר אל עומר",
    "דאסין",
    "דב גרונר",
    "דבורה בארון",
    "דבורה עומר",
    "דוד נוי",
    "דוכיפת",
    "דולב",
    "דושניצקי",
    "דותן",
    "דיכטר",
    "דן",
    "דרור",
    "דרך הארבעה",
    "דרך החיים",
    "דרך הים",
    "דרך המכללה",
    "דרך השלום",
    "דרך נבון יצחק",
    "דרך צפת",
    "האודם",
    "האורן",
    "האחלמה",
    "האילן",
    "האיקליפטוס",
    "האירוסים",
    "האלה",
    "האנפה",
    "הארגמן",
    "הארז",
    "האשכולית",
    "האשל",
    "האתרוג",
    "הבהאים",
    "הבוסתן",
    "הברוש",
    "הבריגדה היהודית",
    "הברק",
    "הברקת",
    "הגאולה",
    "הגומא",
    "הגיא",
    "הגן",
    "הגפן",
    "הדגנית",
    "הדס",
    "הדקל",
    "ההגנה",
    "ההרדוף",
    "הזית",
    "החבצלת",
    "החורש",
    "החירות",
    "החרוב",
    "החרושת",
    "החרמון",
    "החרצית",
    "החרש",
    "החשמל",
    "היהלום",
    "היוצר",
    "היוצרים",
    "היימן",
    "היסמין",
    "היערה",
    "הישפה",
    "הכלנית",
    "הכפיר",
    "הכרם",
    "הלביא",
    "הלוטוס",
    "הלילך",
    "הלימון",
    "הלשם",
    "המגל",
    "המוסכים",
    "המלאכה",
    "המנדרינה",
    "המסגר",
    "המעפילים",
    "המפעל",
    'המר"ן',
    "המרגנית",
    "המרווה",
    "המרוניטים",
    "הנדיב",
    "הנופך",
    "הנורית",
    "הנרייטה סאלד",
    "הנרקיס",
    "הנשר",
    "הס",
    "הסביון",
    "הסדנה",
    "הסיגלית",
    "הסייפן",
    "הספיר",
    "הספנים",
    "העלייה",
    "העמל",
    "העצמאות",
    "הערבה",
    "הפטדה",
    'הפלמ"ח',
    "הפרחים",
    "הצבעוני",
    "הצלבנים",
    "הקורנס",
    "הרב לופס",
    "הרב נסים",
    "הרותם",
    "הרימון",
    "הרכבת",
    'הרמב"ם',
    "הרצוג",
    "הרצל",
    "הרקפת",
    "השבו",
    "השוהם",
    "השוק הלבן",
    "השוק העותמני",
    "השוק העירוני",
    "השושנים",
    "השחק",
    "השיטה",
    "השיר",
    "השיש",
    "התאנה",
    "התחנה המרכזית",
    "התעשייה",
    "התפוז",
    "התרשיש",
    "ויולה",
    "ויצמן",
    "ויתקין",
    "ונציה",
    "ז'בוטינסקי",
    "זבולון",
    "זהרירה חריפאי",
    "זיגורד",
    "זלדה",
    "זלמן הצורף",
    "זמיר",
    "חאן אל עומדן",
    "חאן אל פרנג",
    "חאן אל שוורדה",
    "חוף ארגמן",
    "חוף התמרים",
    "חזה",
    "חטיבת גולני",
    "טל",
    "טניס",
    "טרומפלדור",
    "יאנוש קורצ'אק",
    "יגאל ידין",
    "יד נתן ביס חקלאי",
    "יהודה",
    "יהודה הלוי",
    "יהושפט",
    "יובל",
    "יוליוס קיסר",
    "יונתן החשמונאי",
    "יוסף",
    "יוסף בן מתתיהו",
    "יוסף גדיש",
    "יוסף גרשון",
    "יוסף כתראן",
    "יחזקאל אגא",
    "יחיאל מפריס",
    "יחיאלי",
    'יל"ג',
    "יפה נוף",
    "יצחק שדה",
    "יקותיאל אדם",
    "ירושלים",
    "ירקוני",
    "יששכר",
    "כינור",
    "ככר הישיבה",
    "ככר ליידי אדית",
    "ככר עבוד",
    "ככר פרחי",
    "כנרית",
    "כפר עברי",
    "כפר פילדלפיה",
    "כצנלסון",
    "לאה גולדברג",
    "לואי דה פיליפו",
    "לואי התשיעי",
    "לוחמי הגטאות",
    "לוי",
    "ליאופולד השני",
    "לסקוב",
    "מוטה גור",
    "מונטיפיורי",
    "מוסד עתידות",
    "מחנה נופש לחייל",
    "מטר",
    "מיתר",
    "מנדולינה",
    "מנוף",
    "מנשה",
    "מע מזרע",
    "מעב משמר הים",
    "מעברה",
    "מעין",
    "מעלה החורשה",
    "מעלה הכרמים",
    "מפרץ שלמה",
    "מקדש הבהאים",
    "מרים בן פורת",
    "מרים ילן שטקליס",
    "מרכז הבאהים",
    "מרכז לבריאות הנפש",
    "מרכז רסקו",
    "מרקו פולו",
    "משה צורי",
    "משה שרת",
    "משמר הים",
    "נבל",
    "נוה אביב",
    "נוה משה דיין",
    "נוה נתניהו",
    "נוה ספיר",
    "נווה גנים",
    "נור א-דין אלישרוטי",
    "נחל",
    "נחליאלי",
    "נמל הדייגים",
    "נעמי שמר",
    "נפולאון בונפרטה",
    "נפתלי",
    "נתיבה בן יהודה",
    "ס'לאח א(ל) דין",
    "סולימאן באשה",
    "סידני סמית'",
    "סלעית",
    "סמ הטורים",
    "סמולנסקין",
    "סנש חנה",
    "ספורט",
    "סר אייזיק",
    "עגנון",
    "עוזי חיטמן",
    "עוזרי",
    "עטרות",
    "עיר עתיקה 10",
    "עיר עתיקה 11",
    "עיר עתיקה 12",
    "עיר עתיקה 13",
    "עיר עתיקה 14",
    "עכו",
    "עליית הנוער",
    "עפרוני",
    "עציון",
    "פולי",
    "פורצי המבצר",
    "פח'ר א(ל) דין",
    "פיזה",
    "פיליפ אוגוסטוס",
    "פינסקר",
    "פלג",
    "פלמינגו",
    "פנקס",
    'פר"ח אהרון',
    "פרלמן",
    "פרנציסקוס",
    "פרנקו",
    "פשוש",
    "צ'לו",
    "צאלח ובצרי",
    "צופית",
    "צפירה",
    "קדושי השואה",
    "קדושי קהיר",
    "קיבוץ גלויות",
    "קפארלי",
    "קפלן",
    "קרית וולפסון",
    "ראובן",
    "רחל המשוררת",
    "רחמים פיטוסי",
    "ריבלין",
    "ריצ'רד לב ארי",
    'רמב"ן',
    "רמז",
    'רמח"ל',
    "ש אגש רכבת",
    "ש אקדמאים",
    "ש משמר הים",
    "ש נוה ים",
    "ש עמידר",
    "ש עמידר חדש",
    "ש צבורי",
    "ש צפון",
    "ש צפון ב",
    "ש צפון ג",
    "ש צפוף",
    "ש קליפורניה",
    "ש רסקו",
    "שבט אשר",
    "שביל האגת",
    "שביל הדרור",
    "שביל הטופז",
    "שביל מור ולבונה",
    "שביל תכלת וארגמן",
    "שדליץ",
    "שושנה דמארי",
    "שחף",
    "שטראוס",
    "שיזף",
    "שכ בגין",
    "שכ בן גוריון",
    "שכ גולדה מאיר",
    "שכ האקוודוקט",
    "שכ העיר העתיקה",
    "שכ הקרוונים",
    "שכ יעל",
    "שכ מוריה",
    "שכ משק עזר",
    "שכ נאות ים",
    "שכ נוה אלון",
    "שכ נפוליאון",
    "שכ עין אלסט",
    "שכ פרי אליעזר",
    "שכ צפון הכרם",
    "שכ שפת הים",
    "שכונת רם",
    "שלדג",
    "שלום הגליל",
    "שלמה בן-יוסף",
    "שלמה קליין",
    "שמעון",
    "שמעון לוזון",
    "שני אליהו",
    "שער ניקנור",
    "שפירא",
    "שקדיה",
    "שרה לוי תנאי",
    "ששת הימים",
    "תחנת הרכבת",
    "תחנת נסיונות",
    "תכלת",
    "תלמי",
    "תפוח",
  ],
  עלומים: ["עלומים"],
  עלי: [
    "אודם",
    "אזור תעשייה",
    "ברקת",
    "גבעת הלבונה",
    'דרך י"ד באלול',
    "דרך עמיחי",
    "האגוז",
    "האורן",
    "האלה",
    "האלון",
    "הארז",
    "הדובדבן",
    "הדולב",
    "ההרדוף",
    "הורד",
    "החבצלת",
    "החורש",
    "החצב",
    "החרוב",
    "היסמין",
    "היערה",
    "היקינטון",
    "הירדן",
    "הכינור",
    "הכלנית",
    "הכרכום",
    "הלילך",
    "המוריה",
    "המרגנית",
    "הנבל",
    "הנורית",
    "הנרקיס",
    "הסביון",
    "הסייפן",
    "העשור",
    "הפעמון",
    "הפרג",
    "הצבעוני",
    "הצפצפה",
    "הר ציון",
    "הרותם",
    "הרקפת",
    "השקד",
    "השקמה",
    "יהלום",
    "לוע הארי",
    "לשם",
    "מבצע הראל",
    "מבצע נחשון",
    "נאות אילן",
    "נאות חן",
    "נוה שהם",
    "נוה שיר",
    "נוף הרים",
    "ספיר",
    "עלי",
    "פטדה",
    "שד עלי הכהן",
    "שהם",
    "שכ היובל",
    "שכ ט",
    "שכ פלגי מים",
    "שכ שונה",
    "שניר",
    "תרשיש",
  ],
  "עלי זהב": [
    "אלה",
    "אלון",
    "ארז",
    "אשחר",
    "ברוכין",
    "ברוש",
    "גפן",
    "דובדבן",
    "דולב",
    "דפנה",
    "הדס",
    "לוטם",
    "עלי זהב",
    "ערבה",
    "שקד",
    "שקמה",
    "תאנה",
    "תדהר",
    "תמר",
  ],
  עלמה: ["עלמה"],
  עלמון: [
    "אבני חושן",
    "אייל אציל",
    "אשבול",
    "האומנים",
    "כנפי רוח",
    "מדבר יהודה",
    "מדבר סיני",
    "מדבר פראן",
    "מעלה בנימין",
    "נואית",
    "נחל עוג",
    "נחל פרת",
    "נחל קדרון",
    "נמר המדבר",
    "עירית",
    "עלמון",
    "פסמון המדבר",
    "צורית",
    "קוצן זהוב",
  ],
  עמוקה: ["עמוקה"],
  עמיחי: [
    "אשכול",
    "גפן",
    "זמורה",
    "כנה",
    "כרם",
    "סמדר",
    "עמיחי",
    "ענב",
    "שורק",
  ],
  עמינדב: ["אלון", "ארז", "הברוש", "הדקל", "החרוב", "השקד", "התמר", "עמינדב"],
  עמיעד: ["עמיעד"],
  עמיעוז: ["עמיעוז"],
  עמיקם: ["הכלנית", "הנורית", "הנרקיס", "הצבעוני", "הרקפת", "עמיקם"],
  עמיר: ["בית ראשון במולדת", "עמיר"],
  עמנואל: [
    "אהלי יעקב",
    "אילת השחר",
    "איש מצליח",
    "אתר יבנה",
    "בית ישראל",
    "דרך יוסף הצדיק",
    "הבעל שם טוב",
    "הגאון מוילנה",
    "החוזה מלובלין",
    "חזון עובדיה",
    'חת"ם סופר',
    "יביע אומר",
    "לוין פנחס",
    "מאיר מפרמשלאן",
    "נתיב אור החיים",
    "נתיב רבבות אפרים",
    "נתיבות שלום",
    "עמנואל",
    "שבט הלוי",
  ],
  עמקה: [
    "אנפה",
    "דוכיפת",
    "דרור",
    "זמיר",
    "חופית",
    "ינשוף",
    "נחליאלי",
    "סנונית",
    "על כנפי נשרים",
    "עמקה",
    "עפרוני",
    "שחף",
  ],
  ענב: ["ענב", "שכ הגפן"],
  עספיא: [
    'אזור בי"ס רונסון',
    "אזור בתי הקברות",
    "אזור הכנסיה המרונית",
    "אזור המועצה",
    'אזור המתנ"ס',
    "אזור חטיבת ביניים",
    "אלבאבור",
    "אלדרנו",
    "אלכרום",
    "אלמסיל",
    "אלרחאריח",
    "בית אבות נוצרי",
    "בתי הספר היסודיים",
    "גרעין הכפר",
    "המעיין",
    "השכונה המערבית",
    "זוחלוק",
    "ח'לת חמדאן",
    "חיילים משוחררים א'",
    "חיילים משוחררים ב'",
    "טהרת אלנמלה ב לאומי",
    "טהרת אלנמלה ב קדמני",
    "כיכר השכונה המזרחית",
    "מגדל המים הישן",
    "מעל הכביש הראשי",
    "משפחת אלשיח",
    "משפחת טרביה",
    "משפחת מנצור",
    "סטלה כרמל",
    "סידנא אבו עבדאללה",
    "עספיא",
    "רבעון עבוד",
    "רח 10",
    "רח 20",
    "רח 30",
    "רח 11",
    "רח 21",
    "רח 31",
    "רח 2",
    "רח 12",
    "רח 22",
    "רח 32",
    "רח 3",
    "רח 13",
    "רח 23",
    "רח 33",
    "רח 4",
    "רח 14",
    "רח 24",
    "רח 34",
    "רח 5",
    "רח 15",
    "רח 25",
    "רח 35",
    "רח 6",
    "רח 16",
    "רח 26",
    "רח 7",
    "רח 17",
    "רח 27",
    "רח 8",
    "רח 18",
    "רח 28",
    "רח 9",
    "רח 19",
    "רח 29",
    "ש מזרחית מעל המעיין",
    "שד אבא חושי",
    "שכ הבדואים המערבית",
    "שכ הזיתים",
    "שכ מזרחית",
    "שכונה נוצרית מערבית",
    "תחנת המשטרה",
  ],
  עפולה: [
    "אבן גבירול",
    "אבן עזרא",
    "אגוז",
    "אגמון",
    "אגמיה",
    "אדמונית",
    "אדר",
    "אודם",
    "אומן",
    "אוסישקין",
    "אורן",
    "אז תעש עפולה עילית",
    "אזוב",
    "אזור תעשיה א'",
    "אזור תעשיה ב'",
    "אחד העם",
    "אחירותם",
    "אטד",
    "איינשטיין",
    "אירוס",
    "אל על",
    "אלבז נתן",
    "אלתרמן",
    "אמנון ותמר",
    "אסבסטונ עלית",
    "אסיף",
    "אפרים",
    "אפשטיין",
    "אקליפטוס",
    "ארבע האמהות",
    "אשחר",
    "אשל",
    "אתר תרצה",
    "בגין עליזה",
    "בורוכוב",
    "ביאליק",
    'ביל"ו',
    "בית אבות משלב",
    "בית וגן",
    "בן יהודה",
    "בן צבי יצחק",
    "בני ברית",
    "בני דן",
    "בני ישראל",
    "בני עקיבא",
    "בני ציון",
    "בציר",
    "בר גיורא",
    "בר כוכבא",
    "בר לב חיים",
    "ברזילאי יוסף",
    "ברכאן דב",
    "ברנר",
    "ברקת",
    "בשמת",
    "בשן",
    "גבורי סיני",
    "גבעתי",
    "גולדברג לאה",
    "גולומב אליהו",
    "גולן",
    "גור מרדכי",
    "גורדון",
    "גוש עציון",
    "גלעד",
    "גפן",
    "דבורנית",
    "דובדבן",
    "דובנוב יואש",
    "דוד אלעזר",
    "דורי יעקב",
    "דליות",
    "דמארי שושנה",
    "דפנה",
    "דרך בן גוריון",
    "דרך גולני",
    "האבות",
    "האביב",
    "האגס",
    "האופק",
    "האורג",
    "האורנים",
    "האירוסים",
    "האלה",
    "האלון",
    "האמוראים",
    "הארז",
    "האתרוג",
    "הבוכרים",
    "הבונה",
    "הבנאי",
    "הבנים",
    "הבעל שם טוב",
    "הברוש",
    "הבריגדה העברית",
    "הגאונים",
    "הגדוד העברי",
    "הגודד",
    "הגומא",
    "הגיא",
    "הגלבוע",
    "הגליל",
    "הגן",
    "הגת",
    "הדגן",
    "הדוכיפת",
    "הדולב",
    "הדס",
    "הדקל",
    "הדרור",
    "ההגנה",
    "ההדסים",
    "ההדרים",
    "ההסתדרות",
    "הוז זלמן",
    "הולנד",
    "הורד",
    "הזהבן",
    "הזמיר",
    "החוחית",
    "החורש",
    "החלוץ",
    "החמניה",
    "החסידה",
    "החסידים",
    "החצב",
    "החרט",
    "החרמש",
    "החרצית",
    "החשמונאים",
    "החשמל",
    "הטבלן",
    "היוגב",
    "היצירה",
    "הירקון",
    "הכורם",
    "הכלנית",
    "הכנסת",
    "הכשרת הישוב",
    "הליבנית",
    "המגל",
    "המגן",
    "המחשלים",
    "המייסדים",
    "המכבים",
    "המלאכה",
    "המלכה אסתר",
    "המלכים",
    "המסגר",
    "המסיק",
    "הנביאה דבורה",
    "הנביאים",
    "הנגר",
    "הנוטר",
    "הנופר",
    "הנורית",
    "הנשיא וייצמן",
    "הסביון",
    "הסדן",
    "הסוכנות היהודית",
    "הסופרים",
    "הסחלב",
    "הסייפן",
    "הסיתוונית",
    "הספורטק",
    "העבודה",
    "העולים",
    "העליה",
    "העמל",
    "העפרוני",
    "הפייטנים",
    'הפלמ"ח',
    "הצאלון",
    "הצוקית",
    "הצנחנים",
    "הצפצפות",
    "הקוממיות",
    "הקונגרס הציוני",
    "הקישון",
    "הקנאים",
    "הקשת",
    "הראשונים",
    "הרב יוסף חיים",
    "הרב לוין",
    "הרב ניסים אהרון",
    "הרב עמיאל",
    "הרב ציוני פרץ",
    "הרב קוק",
    "הרב קירשטיין אשר",
    "הרב רצון דוד",
    "הרב שיטרית יהודה",
    "הרדוף",
    "הרוגי מלכות",
    "הרימון",
    "הרסן",
    "הרצוג",
    "הרצל",
    "הרקפת",
    'הרשב"י',
    "השומר",
    "השופטים",
    "השחף",
    "השחר",
    "השלדג",
    "השלום",
    "השקד",
    "השקדיה",
    "השקמה",
    "השקנאי",
    "השרטט",
    "התנאים",
    "התעשיה",
    "התקוה",
    "התקומה",
    "וולך יונה",
    "וולפסון",
    "וינגייט",
    "ורדה",
    "ז'בוטינסקי",
    "זית",
    "חביבה רייך",
    "חבצלת",
    "חברון",
    "חופית",
    "חות הלמוד",
    "חטיבה תשע",
    "חטיבת כפיר",
    "חטפית",
    "חיבת ציון",
    "חיטה",
    "חיננית",
    "חלמונית",
    "חנקין יהושע",
    "חרוב",
    "חרוד",
    "חריש",
    "חרמון",
    "טבצ'ניק",
    "טבת",
    "טופח",
    "טרומפלדור",
    "טשרניחובסקי",
    "יאיר",
    "ידין יגאל",
    "יהודה הלוי",
    "יהלום",
    "יוספטל גיורא",
    "יסמין",
    "יערה",
    "יפה אברהם",
    "יצפור",
    "יקינתון",
    "ירדן",
    "ירושלים",
    "ירקוני יפה",
    "כדורי",
    "כהן יגאל",
    "כורש",
    "כחלית",
    "ככר העצמאות",
    "כליל החורש",
    "כנרת",
    "כפר הנער ניר העמק",
    "כצנלסון",
    "כרכום",
    "כרמלית",
    "לוטם",
    "לולב",
    'לח"י',
    "לילך",
    "לסקוב חיים",
    "לשם",
    "מאפו",
    "מגשימים",
    "מורן",
    "מזרחי אפרים",
    "מלכין שרה",
    "מנשה",
    "מעפילים",
    "מצדה",
    "מצפה",
    "מקלף מרדכי",
    "מרבד הקסמים",
    "מרווה",
    "מרכוס דוד",
    "מרכז הקליטה",
    "משושים",
    "משמר הירדן",
    "משק הפועלות",
    "נגבה",
    "נוה יעקב",
    "נורדאו",
    'ניל"י',
    "נפתלי",
    "נרקיסים",
    "סהרון",
    "סולד הנריטה",
    "סוקולוב",
    "סיגליות",
    "סלסילה",
    "סלעית",
    "סמילנסקי",
    "סמר",
    "סנש חנה",
    "ספיר",
    "עגור",
    'עגנון ש"י',
    "עדעד",
    "עומר",
    "עורבני",
    "עירית",
    "עלית הנוער",
    "עמנואל מורנו",
    "ענבל",
    "עפולה",
    "עפולה הצעירה",
    "עפולה תחתית",
    "עצמון",
    "ערבה",
    "ערוגות",
    "ערער",
    "פולג",
    "פועלי ציון",
    'פיק"א',
    "פיקוס",
    "פל-ים",
    "פנקס צבי",
    "פעמונית",
    "פקן",
    "פרג",
    "פרסה",
    "פרצים",
    "פשוש",
    "צאלים",
    "צבעוני",
    "צבר",
    "צופית",
    "צים",
    "צין",
    "ציפורי",
    "ציפורן",
    "צלמון",
    "צלף",
    "צמר",
    "קבוץ גלויות",
    "קדה",
    "קדושי השואה",
    "קדרון",
    "קהלת ציון",
    "קורצ'אק",
    "קטיף",
    "קלט",
    "קנה",
    "קניאל דוד",
    "קנמון יעקב",
    "קפלן",
    "קציר",
    "קרן היסוד",
    "קרן קימת לישראל",
    "ראסל",
    "רובע יזרעאל",
    "רוזמרין",
    "רום יעל",
    "רותם",
    'רמב"ם',
    "רמז",
    "רפול",
    'רש"י',
    "ש אקדמאים",
    'ש בי"ח המרכזי',
    "ש גאולים",
    "ש גבעת המורה",
    "ש דרוקר",
    "ש הבוכרים",
    "ש הפועלים",
    "ש חסכון עלית",
    "ש יוגוסלבים",
    "ש יער",
    "ש נחשון",
    "ש עמידר",
    "ש עממי עלית",
    "ש רווקיה",
    "ש רכבת",
    "ש רסקו",
    "שבזי",
    "שבטי ישראל",
    "שבעת המינים",
    "שד ארלוזורוב",
    "שד בגין מנחם",
    "שד קונטיקט",
    "שד רבין יצחק",
    "שד רובע יזרעאל",
    "שדה יצחק",
    "שדמית",
    "שוהם",
    "שורק",
    "שושן",
    "שושנים",
    "שיבולים",
    "שיבת ציון",
    "שיזף",
    "שיטה",
    "שינבוים",
    "שכ בנה ביתך",
    "שכ נווה יוסף",
    "שכ עלית",
    "שכ רוממה",
    "שלהבית",
    "שלום עליכם",
    "שמחוני אסף",
    "שמר נעמי",
    "שמשון שחורי",
    'שניאור זלמן שז"ר',
    "שעורה",
    "שפינוזה",
    "שפירא מאיר",
    "שפרינצק",
    "שרת",
    "תאנה",
    "תאשור",
    "תגית",
    "תדהר",
    "תורמוס",
    "תל חי",
    "תמר",
    'תרפ"א',
    "תרשיש",
  ],
  "עץ אפרים": [
    "האורן",
    "האלה",
    "האלון",
    "הארז",
    "האשל",
    "הברוש",
    "הדולב",
    "הדפנה",
    "הדקל",
    "הזית",
    "החרוב",
    "הליבנה",
    "הערבה",
    "הרימון",
    "השיטה",
    "השקמה",
    "התאנה",
    "התמר",
    "סמטת המורן",
    "סמטת התות",
    "עץ אפרים",
  ],
  "עצמון שגב": ["עצמון שגב"],
  עראבה: [
    "אבו אלהיתם",
    "אבו בכר",
    "אבו הורירה",
    "אבו עובידה",
    "אבו קרעה",
    "אבו תמאם",
    "אבן אלנפיס",
    "אבן חיאן",
    "אבן רושד",
    "אבראהים אלח'ליל",
    "אדריס",
    "אום אלקורא",
    "אחדארה",
    "אלאג'דאד",
    "אלאהראם",
    "אלאומם",
    "אלאח'א",
    "אלאח'טל",
    "אלאחראר",
    "אלאכלאס",
    "אלאמאן",
    "אלאמין",
    "אלאמיר",
    "אלאמל",
    "אלאנדלוס",
    "אלאנהאר",
    "אלאסבאט",
    "אלאסואר",
    "אלאסלאח",
    "אלאסרא",
    "אלאפראח",
    "אלאקסא",
    "אלבאדיה",
    "אלבואסל",
    "אלבוראק",
    "אלבטוף",
    "אלביאן",
    "אלביר",
    "אלבלאן",
    "אלבסאתין",
    "אלבתרא",
    "אלג'בל",
    "אלג'דבאן",
    "אלג'דיר",
    "אלג'ולאן",
    "אלג'זאאר",
    "אלג'ליל",
    "אלג'מל",
    "אלג'רביה",
    "אלג'רמק",
    "אלגוזלאן",
    "אלדורה",
    "אלהאדי",
    "אלהודא",
    "אלואחה",
    "אלוחדאת",
    "אלוחדה",
    "אלולאא",
    "אלופאא",
    "אלזיתון",
    "אלזקאק",
    "אלח'דק",
    "אלח'ואלד",
    "אלח'ולוד",
    "אלח'לה",
    "אלח'ליל",
    "אלח'נסא",
    "אלח'רובה",
    "אלחאסבאני",
    "אלחג'אז",
    "אלחג'אר",
    "אלחוסון",
    "אלחוריה",
    "אלחטיב",
    "אלחכמה",
    "אלחמרא",
    "אלחנאן",
    "אלחפאר",
    "אלחריקה",
    "אלטאי",
    "אלטויל",
    "אליאסמין",
    "אלימן",
    "אלכוארזמי",
    "אלכליג'",
    "אלכרום",
    "אלכרמל",
    "אללוז",
    "אלמג'ד",
    "אלמג'רבה",
    "אלמדאאן",
    "אלמדינה",
    "אלמוכתאר",
    "אלמוסאוה",
    "אלמוסטפא",
    "אלמועתסם",
    "אלמותנבי",
    "אלמחט",
    "אלמידאן",
    "אלמנארה",
    "אלמנבר",
    "אלמנג'ל",
    "אלמנהל",
    "אלמסרה",
    "אלמעסרה",
    "אלמעראג'",
    "אלמערי",
    "אלמערפה",
    "אלמרג'",
    "אלמרוש",
    "אלנג'אח",
    "אלנג'אר",
    "אלנובלא",
    "אלנור",
    "אלנח'יל",
    "אלנחל",
    "אלנסים",
    "אלנסר",
    "אלנעים",
    "אלנקב",
    "אלסג'רה",
    "אלסד",
    "אלסדאקה",
    "אלסויס",
    "אלסלאם",
    "אלסנאבל",
    "אלספא",
    "אלסראג'",
    "אלסראט",
    "אלע'ופראן",
    "אלעדל",
    "אלעהד",
    "אלעולא",
    "אלעלמין",
    "אלענאן",
    "אלערין",
    "אלעריש",
    "אלפאנוס",
    "אלפארוק",
    "אלפג'ר",
    "אלפוראת",
    "אלפורסאן",
    "אלפראבי",
    "אלפרג'",
    "אלצדיק",
    "אלקאדי",
    "אלקודס",
    "אלקזאז",
    "אלקמר",
    "אלקסטל",
    "אלראג'יד",
    "אלראזי",
    "אלרבאט",
    "אלרביע",
    "אלרדואן",
    "אלרוג'בה",
    "אלרומאן",
    "אלרחמה",
    "אלריאד",
    "אלריאחין",
    "אלרסול",
    "אלשאטי",
    "אלשועאע",
    "אלשועלה",
    "אלשיח' דאוד",
    "אלשמס",
    "אלשעב",
    "אלשריף",
    "אלת'באת",
    "אלתאובה",
    "אלתור",
    "אלתסאמוח",
    "אלתעאון",
    "אלתקוא",
    "אסואן",
    "אריחא",
    "באב אלואדי",
    "באב אלעמוד",
    "באניאס",
    "בור סעיד",
    "ביסאן",
    "ביר אלחמאם",
    "ביר אלמיי",
    "ג'בל אלערב",
    "ג'דה",
    "ג'למה שמאליה",
    "ג'נין",
    "ג'רש",
    "גרעין הכפר",
    "ד'אהר אלעומר",
    "דג'לה",
    "דמשק",
    "ואדי אלח'שב",
    "ואדי אלנתור",
    "ואדי אלעין",
    "ואדי חוסין",
    "זמזם",
    "ח'אלד בן אלוליד",
    "ח'ליל אלסכאכיני",
    "חיפא",
    "טבריא",
    "טלחה בן אלזוביר",
    "טראבלוס",
    "יאפא",
    "כניסת אלסיידה",
    "מכה",
    "מנא",
    "מר בו עבדאלעזיז",
    "מרג' אלזוהור",
    "מרים אלעדראא",
    "נאבלס",
    "סולטאן אלערב",
    "סוקראט",
    "סנעא",
    "ספד",
    "עדן",
    "עומר אלמוכתאר",
    "עומר בן אלח'טאב",
    "עוקאז'",
    "עותמאן בן עפאן",
    "עזה",
    "עלי בן אבי טאלב",
    "עמאן",
    "עמרו בן אלעאס",
    "עסקלאן",
    "עראבה",
    "ערפה",
    "פאטמה אלזהראא",
    "קורטובה",
    "ראס אלעין",
    "שג'רת אלדור",
    "שכ אלבלאן",
    "שכ אלח'אלה",
    "שכונת אלביר",
    "שכונת ג'למה",
    "שכונת כנאענה",
    "שכונת עאצלה",
    "תדמור",
    "תוניס",
    "תקרית",
  ],
  עראמשה: ["עראמשה"],
  "ערב אל נעים": ["ערב אל נעים"],
  ערד: [
    "אביב",
    "אגוז",
    "אגם",
    "אגמית",
    "אגס",
    "אדווה",
    "אודם",
    "אופן",
    "אופק",
    "אופקים",
    "אזור התעשיה",
    "אזור מלונות",
    "אחוה",
    "אחז המלך",
    "אטד",
    "אילת השחר",
    "אירוסים",
    "אנפה",
    "אנקור",
    "אסא המלך",
    "אפיק",
    "אשד",
    "אתרוג",
    "בועז",
    "בוקק",
    "בז",
    "בזלת",
    "בן יאיר אלעזר",
    "בצלאל",
    "ברזל",
    "ברקן",
    "ברקת",
    "בשור",
    "גביש",
    "גיתית",
    "גל",
    "דוד המלך",
    "דוכיפת",
    "דימונה",
    "דרך באר שבע",
    "דרך הגז",
    "דרך זוהר",
    "הבונים",
    "הגיא",
    "הגלעד",
    "הדס",
    "ההר",
    "החומה",
    "החוצבים",
    "היוצקים",
    "הכרמל",
    "המדרגות",
    "המכבים",
    "המלאכה",
    "המרכז",
    "הספורט",
    "העץ",
    'הפלמ"ח',
    "הצבי",
    "הקודחים",
    "הקנאים",
    "ורד",
    "זמיר",
    "זרזיר",
    "חבר",
    "חברון",
    "חוות אביר",
    "חוחית",
    "חולדה",
    "חופית",
    "חימר",
    "חלילית",
    "חלמונית",
    "חסידה",
    "חרוב",
    "חרצית",
    "חשמל",
    "טיילת",
    "טייסים",
    "טל",
    "יאשיהו",
    "יהודה",
    "יובל",
    "יונה",
    "יונתן",
    "יותם המלך",
    "יכין",
    "יסמין",
    "יסעור",
    "יערה",
    "ירושלים",
    "כביש 31",
    "כינור",
    "כלנית",
    "כפר ילדים נרדים",
    "לילית",
    "לילך",
    "לשם",
    "מבצע לוט",
    "מגדל",
    "מגשימים",
    "מואב",
    "מור",
    "מזור",
    "מזמור",
    "מזרח",
    "מיתר",
    "מכון ווג'ס",
    "מכונה",
    "מלון מרגוע",
    "מנוף",
    "מעוז",
    "מעון",
    "מעיין",
    "מעלות",
    "מפוח",
    "מפל",
    "מצדה",
    "מקבת",
    "מרכז הקליטה",
    "מרכז קליטה ב",
    "משמר",
    "נביעות",
    "נגה",
    "נוף",
    "נורית",
    "נחושת",
    "נחל",
    "ניגונים",
    "נפטא",
    "נץ",
    "נקר",
    "נרקיס",
    "נשר",
    "נתיב דבורה",
    "נתיב הגפן",
    "נתיב התאנה",
    "נתיב התמר",
    "נתיב יעל",
    "נתיב מרים",
    "סדן",
    "סהר",
    "סיגים",
    "סיירים",
    "סיני",
    "סיס",
    "סיפן",
    "סלע",
    "סלעית",
    "סמ האקליפטוס",
    "סנונית",
    "ספיר",
    "עגור",
    "עדעד",
    "עוזיהו",
    "עופרת",
    "עורב",
    "עיט",
    "עין התכלת",
    "עינות",
    "עירית",
    "עמל",
    "ענב",
    "ענבלים",
    "ענבר",
    "עפרוני",
    "ערבה",
    "ערד",
    "ערוץ",
    "פטיו",
    "פלג",
    "פלדה",
    "פשוש",
    "צאלים",
    "צבעוני",
    "צבר",
    "צופית",
    "צור",
    "צליל",
    "צפרירים",
    "קדם",
    "קשת",
    "רביבים",
    "רובע אבישור",
    "רובע אשלים",
    "רובע חלמיש",
    "רובע טללים",
    "רובע יהושפט",
    "רובע יעלים",
    "רובע לבאות",
    "רובע נעורים",
    "רות",
    "רותם",
    "רימון",
    "רכב",
    "רענן",
    "רקפת",
    "ש ראשונים",
    "שאול",
    "שאול המלך",
    "שד חן",
    "שוהם",
    "שופר",
    "שושן",
    "שחף",
    "שיזף",
    "שיר",
    "שיר השירים",
    "שיש",
    "שכ גבים",
    "שכ גני משי",
    "שכ הרדוף",
    "שכ חצבים",
    "שכ מבוא שקד",
    "שכ מעוף",
    "שכ רותם",
    "שכ רננים",
    "שכ שקד",
    "שלדג",
    "שלוה",
    "שלמה המלך",
    "שמיר",
    "שמעון",
    "תדהר",
    "תור",
    "תלתן",
    "תעשיה",
    "תפוח",
    "תרשיש",
  ],
  ערוגות: [
    "דרך הצבר",
    "דרך השדות",
    "האפרסק",
    "הזית",
    "הרימון",
    "התאנה",
    "התפוח",
    'כ"ב באדר',
    "ערוגות",
    "שד הכורמים",
    "שד המייסדים",
    "שד הראשונים",
  ],
  ערערה: [
    'אזור בי"ס אלהלאל',
    'אזור בי"ס אלסלאם',
    'אזור בי"ס טה חסין',
    "אזור בית הקברות",
    "אזור המסגד",
    "אל באטן",
    "אל ג'רס",
    "אל דהראת",
    "אל מסקא",
    "אלביאר",
    "אלביר",
    "אלח'רבה",
    "ואדי אל קצב",
    "חור צקר",
    "עארה",
    "ערערה",
    "קרית חינוך",
    "שכונה מערבית צפונית",
    "שכונה צפונית מזרחית",
  ],
  "ערערה-בנגב": [
    "אזור התעשייה",
    "ערערה-בנגב",
    "שכ 1",
    "שכ 21",
    "שכ 41",
    "שכ 51",
    "שכ 2",
    "שכ 22",
    "שכ 2ב",
    "שכ 3",
    "שכ 23",
    "שכ 4",
    "שכ 4א",
    "שכ 5",
    "שכ 5א",
    "שכ 6",
    "שכ 7",
    "שכ 8",
  ],
  עשרת: [
    "אלה",
    "ברקן",
    "גומא",
    "דולב",
    "האגוז",
    "האורן",
    "האלון",
    "האשל",
    "הגפן",
    "הדקל",
    "הזית",
    "החרוב",
    "הפרדס",
    "הרימון",
    "השקמה",
    "התאנה",
    "התדהר",
    "התמר",
    "עשרת",
    "ריזה",
    "שקד",
  ],
  עתלית: [
    "אמת המים",
    "אנפה",
    "אסיף",
    "בריטיש קוטג'",
    "גבעת הבריכות",
    "גבעת הפרחים",
    "גבעת סביון",
    "גבעת שרון",
    "גני עתלית",
    "דגה",
    "דרך הבנים",
    "דרך הים",
    "דרך השדות",
    "האגוז",
    "האורן",
    "האיריס",
    "האלון",
    "הארגמן",
    "האשל",
    "הבציר",
    "הגורן",
    "הגיבורים",
    "הגפן",
    "הגת",
    "הדקל",
    "ההרדוף",
    "הורד",
    "הזית",
    "הזמורה",
    "החורש",
    "החיטה",
    "החרוב",
    "היקב",
    "היקינטון",
    "הכלניות",
    "הכרם",
    "הלוחמים",
    "הלוטוס",
    "הלילך",
    "המבצר",
    "המגינים",
    "המחתרות",
    "המייסדים",
    "המעיין",
    "הנרקיסים",
    "הסביון",
    "הסחלב",
    "הסייפן",
    "הסלע",
    "העליה",
    "הפקאן",
    "הצבעוני",
    "הקלשון",
    "הרימון",
    "הרקפת",
    "השקד",
    "השריג",
    "התורן",
    "התירוש",
    "התכלת",
    "התקומה",
    "חבצלת",
    "חוות אהרונסון",
    "חופית",
    "חמניה",
    "חצרות איכרים",
    "חרמש",
    "חרצית",
    "יפה נוף",
    "כותנה",
    "כלנית",
    "לב עתלית",
    "לגונה",
    "מגל",
    "מורג",
    "מושבה א",
    "מושבה ב",
    "מזמרה",
    "מחרשה",
    "מכמורת",
    "מלח הארץ",
    "מעברה",
    "מפרש",
    "מרכז קליטה",
    "משעול האשכולות",
    "משעול הגפנים",
    "נוה דקל",
    "נוה משה",
    "נחל אורן",
    "נחל אלונה",
    "נחל בוסתן",
    "נחל ברקן",
    "נחל גלים",
    "נחל דליה",
    "נחל הוד",
    "נחל ספונים",
    "נחל שלף",
    "נתיב הכרמים",
    "סביוני עתלית",
    "סירה",
    "סמ האלמוג",
    "סמ הארז",
    "סמ הברוש",
    "סמ היסמין",
    "סמ הלוטם",
    "סמ המרגנית",
    "סמ הנורית",
    "סמ הסיגלית",
    "סמ התמר",
    "עוגן",
    "עין הים",
    "עתלית",
    "פלמינגו",
    "קציר",
    "ש חדש",
    "ש עמידר",
    "שונית",
    "שושנת הים",
    "שחף",
    "שייטים",
    "שכ אלון",
    "שכ ארגמן",
    "שכ הדרום",
    "שכ חברת המלח",
    "שכ חופית",
    "שכ יפה נוף",
    "שכ נחלים",
    "שכ קנדי",
    "שכ שושנת הים",
    "שלדג",
    "תלם",
    "תמירון",
  ],
  עתניאל: ["עתניאל"],
  פארן: ["ברק", "ורדית", "ערבה", "עשת", "פארן", "ציחור", "רעים"],
  פדואל: ["פדואל"],
  פדויים: ["פדויים"],
  פדיה: ["פדיה"],
  "פוריה - כפר עבודה": ["פוריה - כפר עבודה"],
  "פוריה - נווה עובד": ['בי"ח פוריה', "מרכז קליטה", "פוריה - נווה עובד"],
  "פוריה עילית": [
    "דרך החמה",
    "דרך החצב",
    "דרך הים",
    "דרך המצוק",
    "דרך העמק",
    "דרך השדה",
    "דרך השקד",
    "דרך התבור",
    "הזית",
    "הרימון",
    "השיזף",
    "פוריה עילית",
  ],
  פוריידיס: [
    "אלעין",
    "השיכון",
    "ט'הראת",
    "מרכז הכפר",
    "פוריידיס",
    "צריפים",
    "רח 10",
    "רח 1",
    "רח 11",
    "רח 2",
    "רח 12",
    "רח 3",
    "רח 13",
    "רח 4",
    "רח 14",
    "רח 14 א",
    "רח 14 ב",
    "רח 5",
    "רח 5 א",
    "רח 15",
    "רח 15 א",
    "רח 6",
    "רח 6 א",
    "רח 7",
    "רח 7 א",
    "רח 8",
    "רח 9",
    "שטח בנוי",
    "שכונה מזרחית",
    "שכונה צפונית",
  ],
  פורת: [
    "אשר",
    "אתרוג",
    "בנימין",
    "גד",
    "דן",
    "הדס",
    "הותיקים",
    "הראשונים",
    "הרב מכלוף יאנה",
    "זבולון",
    "יהודה",
    "יוסף",
    "יששכר",
    "לוי",
    "נפתלי",
    "פורת",
    "רואבן",
    "שבטי ישראל",
    "שמעון",
  ],
  פטיש: ["פטיש"],
  פלך: ["פלך"],
  פלמחים: ["אולפן", "מחנה הילה", "פלמחים"],
  "פני חבר": ["פני חבר"],
  פסגות: [
    "ארבל",
    "גלבוע",
    "גריזים",
    "הר ההר",
    "חורב",
    "כרמל",
    "מירון",
    "נבו",
    "סיני",
    "עצמון",
    "פארן",
    "פסגות",
    "תבור",
  ],
  פסוטה: ["פסוטה"],
  'פעמי תש"ז': [
    "הגפן",
    "הזית",
    "החיטה",
    "הרימון",
    "השעורה",
    "התאנה",
    "התמר",
    'פעמי תש"ז',
  ],
  פצאל: ["פצאל"],
  "פקיעין (בוקייעה)": [
    "אבו גראד",
    "אלאחאא",
    "אלאמל",
    "אלאסיל",
    "אלבטמה",
    "אלביאדה",
    "אלביאדר",
    "אלבלאן",
    "אלבלסם",
    "אלברג'",
    "אלברזה",
    "אלברייה",
    "אלג'אר",
    "אלג'בל",
    "אלג'וז",
    "אלג'ורי",
    "אלג'זאל",
    "אלגחר",
    "אלהדא",
    "אלהדהד",
    "אלואדי",
    "אלוורוד",
    "אלופאא",
    "אלזהור",
    "אלזיר",
    "אלזיתון",
    "אלזנבק",
    "אלזערור",
    "אלח'לוה",
    "אלח'רובה",
    "אלחור",
    "אלחסאד",
    "אלחקמה",
    "אללוז",
    "אלליימון",
    "אלמג'ד",
    "אלמג'סל",
    "אלמזחלוני",
    "אלמחג'ר אלגרבי",
    "אלמטבלה",
    "אלמיאדין",
    "אלמיס",
    "אלמל",
    "אלמסררה",
    "אלמראבט",
    "אלמראח",
    "אלנוורס",
    "אלנור",
    "אלנח'יל",
    "אלנרג'ס",
    "אלסבאר",
    "אלסלאם",
    "אלסמאק",
    "אלסנדיאן",
    "אלספסאף",
    "אלסריס",
    "אלענאב",
    "אלעקבה",
    "אלעראדין",
    "אלערזאן",
    "אלקנדול",
    "אלקסב",
    "אלקרונפול",
    "אלרג'ב",
    "אלרומאן",
    "אלריחאן",
    "אלשברק",
    "אלשיכונאת",
    "אלתבג'",
    "אלתות",
    "אלתינה",
    "אלתפאח",
    "ארעיש אלענב",
    "באב אלואדי",
    "באב אלמרג'",
    "ביר אלמתחנה",
    "בית אלשעב",
    "ואדי אלמרבאע",
    "כלת עלם",
    "כניס אליהוד",
    "כניסת אלקאתוליק",
    "כרום אלשראקה",
    "כרם אלבדרא",
    "לבוד אם נעימה",
    "מלעב קורת אלקדם",
    "מסטאח אלקטין",
    "נאדי אלאחווה",
    "סיידת אלג'ליל",
    "סירייה",
    "עג'רמה",
    "עין אלבסתאן",
    "עין אלבראניה",
    "עין אלג'נאן",
    "פקיעין (בוקייעה)",
    "פקיעין המערבית",
  ],
  "פקיעין חדשה": ["פקיעין חדשה"],
  "פרדס חנה-כרכור": [
    "אבוקה",
    "אביבים",
    "אבן גבירול",
    "אגס",
    "אזוב",
    "אזור תעשיה צפוני",
    "אחדות",
    "אחוזה",
    "אחוזה הירוקה",
    "אילת",
    "איריס",
    "אלה",
    "אלומות",
    "אלון",
    "אלונים",
    "אלחריזי",
    "אליהו",
    "אלישע",
    "אלכסנדרוני",
    "אנקור",
    "אסיף",
    "אסתר",
    "אפרסק",
    'אצ"ל',
    "ארבל",
    "ארז",
    "אשכולית",
    "אשל",
    "אתרוג",
    "בזל",
    'ביל"ו',
    "ביס חקלאי",
    "בית אל",
    "בן שטח",
    "בציר",
    "בר גיורא",
    "בר כוכבא",
    "ברקאי",
    "ברקן",
    "ברקת",
    "בשן",
    "בתי בלום",
    "בתי קומות",
    "גאולה",
    "גבע",
    "גבעת הבאר",
    "גבעת הברושים",
    "גבעת היהלום",
    "גבעתי",
    'גדנ"ע',
    "גולן",
    "גולני",
    "גומא",
    "גורדוניה",
    "גוש חלב",
    "גינת אגוז",
    "גלבוע",
    "גלעד",
    "גמלא",
    "גן עוז",
    "גן שלמה",
    "גני סמדר",
    "דביר",
    "דגן",
    "דגניה",
    "דובדבן",
    "דודאים",
    "דוכיפת",
    "דולב",
    "דן",
    "דניאל",
    "דפנה",
    "דרור",
    "דרך גל הזהב",
    "דרך הבנים",
    "דרך הים",
    "דרך הציפורים",
    "דרך הרופא",
    "דרך יזרעאל",
    "דרך למרחב",
    "דרך משמרות",
    'דרך פיק"א',
    "דרך קדמה",
    "דרכי נועם",
    "האדנים",
    "האומנות",
    "האורנים",
    "האחווה",
    "האיכר",
    "האקליפטוס",
    "הבוטנים",
    "הבוסתן",
    "הבריגדה",
    "הברכה",
    "הגאון",
    "הגבורה",
    "הגדוד",
    "הגורן",
    "הגיתית",
    "הגליל",
    "הגלעין",
    "הגנה",
    "הגפן",
    "הדסים",
    "הדקלים",
    "הדרים",
    "הדרים הצעירה",
    "ההסתדרות",
    "הוד",
    "הזית",
    "החורש",
    'החי"ל',
    "החלוץ",
    "החליל",
    "החץ",
    "הטללים",
    "היובל",
    "היוגב",
    "הימאים",
    "הכרם",
    "המגדל",
    "המגינים",
    "המדרשייה",
    "המושב",
    "המזמור",
    "המייסדים",
    "המיתר",
    "המלאכה",
    "המסילה",
    "המעין",
    "המעלה",
    "המשמר",
    "הנבל",
    "הנדיב",
    "הנוטר",
    'הנח"ל',
    "הנחילות",
    "הניצנים",
    "הנשיא",
    "העליה",
    "העמק",
    "העסיס",
    "העפרוני",
    "הערער",
    "העשור",
    "הפעמון",
    "הצבי",
    "הצוף",
    "הצנחנים",
    "הצפירה",
    "הקטיף",
    "הקרן",
    "הקשת",
    "הראל",
    "הרדוף",
    "הרימון",
    "השדה",
    "השומר",
    "השופטים",
    "השחר",
    "השיטה",
    "השיר",
    "השמינית",
    "השניים",
    "התנאים",
    "התעשיה",
    "התקווה",
    "ורד",
    "זהבית",
    "זכריה",
    "זמיר",
    "חבצלת",
    "חבקוק",
    "חברון",
    "חגי",
    "חגלה",
    "חיטה",
    "חיננית",
    "חלומות כרכור",
    "חלוצים",
    "חן",
    "חסידה",
    "חצוצרה",
    "חרובים",
    "חרושת",
    "חרמון",
    "חשמונאים",
    "טוקאיר מוסד",
    "טוריים מרכז קליטה",
    "טייסים",
    "טפח",
    "יהודה הלוי",
    "יהלום",
    "יובל",
    "יונה",
    "יחזקאל",
    "ינשוף",
    "יקינטון",
    "ירושלים",
    "כוכב",
    "כוכבית",
    "כורש",
    "כיכר אוריון",
    "כיכר המושב כרכור",
    "כינור",
    "ככר הבריכה",
    "ככר המושבה",
    "ככר מירקין",
    "כנרת",
    "כפר עבודה",
    "כרכור",
    "כרמל",
    "כתימה",
    "לבונה",
    "לוטם",
    'לח"י',
    "לילך",
    "לימון",
    "לשם",
    "מגד",
    "מדרשת נועם",
    "מודיעין",
    "מוריה",
    "מורן",
    "מטולה",
    "מיצפור",
    "מירון",
    "מכבים",
    "מכורה",
    "מלאכי",
    "מנגו",
    "מנדרינה",
    "מעון לעובד",
    "מעון לעובד כ",
    "מעיין גנים",
    "מעלה גיבורים",
    "מעפילים",
    "מצדה",
    "מצילתיים",
    "מקווה",
    "מרגנית",
    "מרדכי",
    "מרווה",
    "מרכז",
    "מרכז קליטה",
    "נבו",
    "נבטים",
    "נוגה",
    "נוה איתנים",
    "נוה הדרים",
    "נוה שלוה ביח",
    "נווה גד",
    "נווה גנים",
    "נווה הדקלים",
    "נווה התות",
    "נווה פרדסים",
    "נווה רותם",
    "נוי",
    "נורית",
    "נחלה",
    "נחליאלי",
    "נחמיה",
    "נחשון",
    "נטע",
    'ניל"י',
    "ניצני השרון",
    "ניר",
    "נעורים",
    "נץ",
    "נרקיס",
    "נשר",
    "סביון",
    "סגולה",
    "סיני",
    "סמ אדם",
    "סמ בוסתן",
    "סמ האריגה",
    "סמ הגן",
    "סמ הדיה",
    "סמ הזוהר",
    "סמ הזיו",
    "סמ היצירה",
    "סמ המושב",
    "סמ המטע",
    "סמ הריקמה",
    "סמ התשבי",
    "סמ זריחה",
    "סמ חמה",
    "סמ חריש",
    "סמ חרצית",
    "סמ יודפת",
    "סמ ירדן",
    "סמ כלנית",
    "סמ מגשימים",
    "סמ מרחבים",
    "סמ סיגלית",
    "סמ פעמונית",
    "סמ פריחה",
    "סמ צאלון",
    "סמ צבעוני",
    "סמ צבר",
    "סמ צפורן",
    "סמ רותם",
    "סמ רקפת",
    "סמ רשף",
    "סמ שומרון",
    "סמ שיזף",
    "סמטת דגניה א'",
    "סמטת דגניה ב'",
    "סמטת הבונה",
    "סמטת הדורות",
    "סמטת הידידים",
    "סמטת התפוח",
    "סמטת מתיתיהו",
    "סמטת פאר",
    "סנונית",
    "ספיר",
    "עוגב",
    "עולש",
    "עומרים",
    "עזרא",
    "עיט",
    "עינב",
    "עירון",
    "עירית",
    "עלומים",
    "עמל",
    "עמר",
    "ענבל",
    "עציון",
    "עצמאות",
    "עצמון",
    "ערבה",
    "ערוגות",
    "ערמון",
    "פאר",
    "פדויים",
    "פומלית",
    "פלג",
    'פלמ"ח',
    "פנחס",
    "פרדס חנה-כרכור",
    "פרחי הדר",
    "פשוש",
    "צהלה",
    "צליל",
    "צלצלי שמע",
    "צלצלי תרועה",
    "צמרת המושבה",
    "צפניה",
    "צפרירים",
    "קארו יוסף",
    "קדמת פרדס חנה",
    "קוממיות",
    "קורנית",
    "קלמנטינה",
    "קלע",
    "קמה",
    "קנאים",
    "קציר",
    "קרן היסוד",
    "ראשונים",
    "רבי עקיבא",
    "רביד",
    'רמב"ם',
    "רעות",
    "ש גאולה",
    "ש הפועלים",
    "ש התימנים",
    "ש חדש",
    "ש יפה נוף",
    "ש לויט",
    "ש מגד",
    "ש מגד א",
    "ש מזרחי",
    "ש ממשלתי",
    "ש משכנות כ",
    "ש נוה אפרים",
    "ש נוה אשר",
    "ש נוה מרדכי",
    "ש נוה מרחב",
    "ש נוה עובד",
    "ש עובדים",
    "ש עולים",
    "ש עולים חדש",
    "ש עמידר",
    "ש עממי",
    "ש פועל מזרחי",
    "ש פועלים",
    "ש פיקא",
    "ש רמז",
    "ש רסקו",
    'ש שב"ל',
    "ש תל שלום א",
    "ש תל שלום ב",
    "שאננים",
    'שב"ל',
    "שבזי",
    "שבי ציון",
    "שביל התלמיד",
    "שד ציונות",
    "שד קרן קימת",
    "שדרות מחנה שמונים",
    "שוהם",
    "שונית",
    "שופר",
    "שושן",
    "שחף",
    "שיבולים",
    "שכ אהבת ציון",
    "שכ אורות",
    "שכ אלון",
    "שכ גאולה אסבסט",
    "שכ האור",
    "שכ הגבורים",
    "שכ הדר",
    "שכ החילים",
    "שכ זוהר",
    "שכ יובלים",
    "שכ מור",
    "שכ משכנות ישראל",
    "שכ פרחי הדר",
    "שכ קורן",
    "שכ קנדי",
    "שכ שבטי ישראל",
    "שכון המשטרה",
    "שלדג",
    "שלום",
    "שלומציון",
    "שמואל הנגיד",
    "שמשון",
    "שניר",
    "שעורה",
    "שקדים",
    "שקמה",
    "תאשור",
    "תבואה",
    "תבור",
    "תדהר",
    "תומר",
    "תוף",
    "תותחנים",
    "תחיה",
    "תחכמוני",
    "תל אילן מוסד",
    "תל יצחק",
    "תל צבי",
    "תלמים",
    "תלפיות",
    "תמר",
    "תפוז",
    "תקומה",
    'תרע"ג',
    'תרפ"ט',
  ],
  פרדסיה: [
    "איילון",
    "אשחר",
    'בי"ח לב השרון',
    "בשמת",
    "גני השרון",
    "דן",
    "דרך האחווה",
    "דרך המייסדים",
    "דרך הצנחנים",
    "דרך הרעות",
    "דרך השלווה",
    "האגוז",
    "האודם",
    "האורן",
    "האירוס",
    "האלה",
    "האלון",
    "האקליפטוס",
    "הארבל",
    "הארז",
    "האשל",
    "הברוש",
    "הבשור",
    "הגלבוע",
    "הגפן",
    "הדובדבן",
    "הדס",
    "הדקל",
    "הדר השרון",
    "הורד",
    "הזית",
    "החצב",
    "החרוב",
    "החרמון",
    "החרצית",
    "היסמין",
    "הירדן",
    "הכלנית",
    "הכרמל",
    "הנורית",
    "הנרקיס",
    "הסביון",
    "הערבה",
    "הפטל",
    "הפיקוס",
    "הפקאן",
    "הצאלון",
    "הצבר",
    "הרותם",
    "הרימון",
    "הרקפת",
    "השושן",
    "השקד",
    "השקמה",
    "התאנה",
    "התבור",
    "התדהר",
    "התהילה",
    "התות",
    "התמר",
    "התפוח",
    "חמנית",
    "יערה",
    "לוטם",
    "לילך",
    "מחנה א",
    "מחנה ב",
    "מחנה ה",
    "מחנה ו",
    "מירון",
    "מרווה",
    "נאות השרון",
    "ניצנית",
    "סיגלית",
    "סייפן",
    "סמ הדרור",
    "סמ הורד",
    "סמ היונה",
    "סמ השחף",
    "סמ התור",
    "עולש",
    "ערוגות",
    "פישר יונה",
    "פרג",
    "פרדסיה",
    "צבעוני",
    "קינמון",
    "ריחן",
    'רמב"ם',
    "רמות נתניה",
    "שבזי שלום",
    "שביל הארז",
    "שביל הארזים",
    "שביל הרימון",
    "שביל התמר",
    "שגיא",
    "שד בן גוריון",
    "שד ההדרים",
    "שד הנשיא",
    "שד ניסים",
    "שניר",
    "שרת",
  ],
  פרוד: ["פרוד"],
  פרזון: ["פרזון"],
  "פרי גן": ["הגפן", "הדקל", "התאנה", "פרי גן"],
  "פתח תקווה": [
    "א ד ג",
    'א"ת פארק ינאי',
    "אבא ברדיצ'ב",
    "אביאל",
    "אבידן דוד",
    "אבימלך",
    "אבן גבירול",
    "אבן עזרא",
    "אברבנאל",
    "אגרון גרשון",
    "אהבת ציון",
    "אהרון הכהן בייפוס",
    "אהרונוביץ יוסף",
    "אהרונסון שרה",
    "אוגלר ישראל",
    "אודם",
    "אונקלוס",
    "אוסישקין מנחם",
    "אופיר",
    "אופקים",
    "אור יחזקאל",
    "אורלוב זאב",
    "אורלנסקי",
    "אורן אבי",
    "אושר גולדוסר",
    'אז"ר',
    "אזור מסחר",
    "אזור תעשיה החדש",
    "אזור תעשיה סגולה",
    "אזור תעשייה חדש",
    "אחד העם",
    "אחוה יעקב",
    "אחים יפה",
    "אחים ישראלית",
    "אחימאיר אבא",
    "אידלמן",
    "איכילוב יצחק",
    "אילניה",
    "אילת",
    "אימבר",
    "אינשטיין",
    "אלדד ישראל",
    "אלוף רחבעם זאבי",
    "אלחריזי",
    "אליקים",
    "אלישיב",
    "אלכסנדר ינאי",
    "אלכסנדר פן",
    "אלמוג",
    "אלקחי מרדכי",
    "אלקניאן",
    "אלתר מיהוד",
    "אלתרמן נתן",
    "אמסטר לאה",
    "אמסטרדם",
    "אנגל יואל",
    "אנדרסן",
    "אנה פרנק",
    "אנילביץ מרדכי",
    "אנסקי",
    "אסטה",
    "אסירי ציון",
    "אסתר המלכה",
    "אפעל",
    "אפשטיין",
    'אצ"ל',
    "אריה בן אליעזר",
    "ארלוזורוב",
    "אש שלום",
    "אשכול לוי",
    "אשכנזי יחזקאל",
    "אשרוב מישה",
    "אשרי יהודה",
    "בבלי",
    "בגלייבטר",
    'בד"ר',
    "בהט",
    "בובר מרטין",
    "בוך ישי",
    "בוסל",
    "בורוכוב",
    "בורלא יהודה",
    "בורשטיין חיים",
    "בזל",
    'בי"ח בילינסון',
    "ביאליק",
    "ביטרמן מרדכי",
    "ביילי חיים",
    "בייליס",
    'ביל"ו',
    "בירנבוים נתן",
    "בית חלוצים",
    "בית ריזין",
    "בלום ליאון",
    "בלטימור",
    "בלפור",
    "בן גוריון",
    "בן דוד יצחק",
    "בן דרור מרדכי",
    "בן זכאי",
    "בן חור",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן יעקב צבי",
    "בן משה",
    "בן נון יהושע",
    "בן צבי יצחק",
    "בני ברית",
    "בנימיני אמיר",
    "בס זליג",
    "בעל התניא",
    "בעל שם טוב",
    "בצרי יוסף",
    "בקר אהרון",
    "בר אילן",
    "בר גיורא",
    "בר יוחאי",
    "בר כוכבא",
    "בר-לוי אשר",
    'ברא"ז זאב',
    "בראון אריה",
    "ברגמן צבי",
    "ברוד מכס",
    "ברוורמן ישראל",
    "ברוידה",
    "ברוך איסחקוב",
    "ברון די מנשה",
    "ברון הירש",
    "ברוריה",
    "ברט אהרון",
    "ברטונוב יהושע",
    "ברטנורא",
    "ברלוביץ יצחק",
    "ברנדה זאב",
    "ברנדייס",
    "ברנו יצחקוב",
    "ברנט זרח",
    "ברנר",
    "ברנשטיין כהן",
    "ברץ יוסף",
    "ברקוביץ יצחק",
    "ברקת",
    "ברש אשר",
    "בת גנים",
    "בת שבע",
    "בת שלמה",
    "גאולי תימן",
    "גבעת השלושה מלבן",
    'גדנ"ע',
    "גדרה",
    "גהל מרטין",
    "גובר רבקה",
    "גוטמן",
    "גולדברג דוד",
    "גולדברג יצחק",
    "גולדברג לאה",
    "גולדנהירש",
    "גולדרייך",
    "גולדשטיין פרץ",
    "גולומב אליהו",
    "גונן",
    "גורדון מרדכי",
    "גוש חלב",
    "גוש עציון",
    "גזית",
    "גיסות השריון",
    "גיסין אבשלום",
    "גלבוע אמיר",
    "גלין",
    "גליס",
    "גליצנשטיין",
    "גלמן נחמן",
    "גלעד חיים",
    "גמילי שלום",
    "גן הבנים",
    "גני הדר",
    "גפני אברהם",
    "גפני שמחה",
    "גרוטו אלכסנדר",
    "גרוניגר פאול",
    "גרונר דב",
    "גרוסמן חייקה",
    "גרין יונה",
    "גרינבוים",
    "גרינברג אורי צבי",
    "גרינברג חיים",
    "גרינגרט",
    "גרינשטיין",
    "גרינשפן",
    "גרניט",
    "גרף צבי",
    "גרץ צבי",
    'ד"ר כהן אליהו',
    "דבורה הנביאה",
    "דגל ראובן",
    "דגניה",
    "דובדבני משה",
    "דובנוב שמעון",
    "דוד הכורך",
    "דון יוסף נשיא",
    "דורון",
    "דורני שלום",
    "דיין משה",
    "דינוביץ אבשלום",
    "דינור",
    "דיסקין בנימין",
    "דנמרק",
    "דנקנר מאיר",
    "דפנה",
    "דקר",
    "דרזנר יחיאל",
    "דרייפוס",
    "דרך אם המושבות",
    "דרך בגין מנחם",
    "דרך החיים",
    "דרך יצחק רבין",
    "דרך לוד",
    "דרך מכבית",
    "דרך ספיר יוסף",
    "דרנגר טובה ושמשון",
    "ה באייר",
    "האביב",
    "האגמון",
    'האדמו"ר איפרגן שלום',
    "האופן",
    "האורים",
    "האורנים",
    "האחים בכר",
    "האחים בן עזר",
    "האחים רוזוב",
    "האחים שטרייט",
    "האילנות",
    "האירוסים",
    "האלונים",
    "האלוף דוד מרכוס",
    'האר"י הקדוש',
    "הארז",
    "האתרוג",
    "הבורסקאי",
    "הבטחון",
    "הבנים",
    "הברוש",
    "הגיתית",
    "הגפן",
    'הגר"א',
    "הדאיה עובדיה",
    "הדף היומי",
    "הדקל",
    "הדר גנים",
    "הדר המושבות",
    "הדרור",
    "ההגנה",
    "ההדס",
    "ההדר",
    "ההסתדרות",
    "הוברמן",
    "הוז דב",
    "הולצברג שמחה",
    "הורד",
    "הושע",
    "הותיקים",
    "הזוהר",
    "הזז חיים",
    "הזית",
    "הזמיר",
    "החבצלת",
    'החי"ל',
    "החליל",
    "החמישה",
    "החצב",
    "החרוב",
    "החרוצים",
    "החרושת",
    "החרמון",
    "החרש",
    "החשמונאים",
    "היבנר",
    "היסמין",
    "היצירה",
    "הירדן",
    "הירקונים",
    "הכהן",
    "הכורם",
    "הכינור",
    "הכרם",
    'הל"ה',
    "הלוי",
    "הלולב",
    "הלילך",
    "הלימון",
    "הלל הזקן",
    "הלל זכריה",
    "הלסינקי",
    "הלפיד",
    "הלפרין",
    "המגיד",
    "המגינים",
    "המגשימים",
    "המדע",
    'המהר"ל מפראג',
    "המורה אליהו",
    "המורים",
    "המיתר",
    "המכבים",
    "המלכים",
    "המסילה",
    "המעפילים",
    "המפלסים",
    "המפעלים",
    "המצילתיים",
    "המר זבולון",
    "המרגניות",
    "המרץ",
    "המשוררת זלדה",
    "המשטרה",
    "המתנחלים",
    "הנביאים",
    "הנגב",
    'הנח"ל',
    "הניצנים",
    "הנצחון",
    'הנצי"ב',
    "הנרקיסים",
    "הנשיאים",
    "הס משה",
    "הסדנה",
    "הסיבים",
    "הסנהדרין",
    "הסתונית",
    "העובד הציוני",
    "העוגב",
    "העינבל",
    "העליה השניה",
    "העצמאות",
    "העצמון",
    "הערבה",
    "הפועל הצעיר",
    "הפורצים",
    'הפלמ"ח',
    "הפסגות",
    "הפרדס",
    "הפרח",
    "הפרטיזנים",
    "הצבר",
    "הצוערים",
    "הצייר",
    "הצנחנים",
    "הצפירה",
    "הצפצפה",
    "הקונגרס",
    "הקידמה",
    "הקציר",
    "הקרן",
    "הקשת",
    "הקתרוס",
    "הר ציון",
    "הראל איסר",
    "הראשונים",
    "הרב אבורביע",
    "הרב אהרונסון",
    "הרב אויערבך משה",
    "הרב אונטרמן",
    "הרב אורבך מאיר",
    "הרב אילויצקי",
    "הרב אלבז",
    "הרב אלקלעי",
    "הרב ביטון חביב",
    "הרב בלוי משה",
    "הרב גולד",
    "הרב גורן שלמה",
    "הרב גלוסקא",
    "הרב דייטש יעקב",
    "הרב הלר",
    "הרב הרצוג",
    "הרב וולף",
    "הרב חנזין",
    "הרב חפוטא יאיר",
    "הרב טולידנו",
    "הרב טייב יצחק",
    "הרב יחבוב אהרון",
    "הרב מבורך עידן",
    "הרב מוהליבר שמואל",
    "הרב מימון",
    "הרב מלכה משה",
    "הרב משורר ישעיהו",
    "הרב ניימן יעקב",
    "הרב ניסים יצחק",
    "הרב נריה צבי",
    "הרב ספיר",
    "הרב עבודי ניסן",
    "הרב עובדיה יוסף",
    "הרב עוזיאל",
    "הרב עטיה עזרא",
    "הרב עמיאל משה",
    "הרב פונצ'יק מנחם",
    "הרב פינטו מסעוד",
    "הרב פינצ'י",
    "הרב פלדמן",
    "הרב צדקה יהודה",
    "הרב צירלסון",
    "הרב צעירי שלמה",
    "הרב קדר",
    "הרב קוק",
    "הרב קטרוני",
    "הרב קלישר",
    "הרב ריין אברהם",
    "הרב ריינס יצחק",
    "הרב שלזינגר עקיבא",
    "הרב שקדי",
    "הרבנית מרגלית",
    "הרברט סמואל",
    'הרד"ק',
    'הרז"ה',
    "הרימון",
    "הרכב",
    "הרכבת",
    "הרכש",
    "הרמתי כהן שלום",
    "הרצל",
    "הרצפלד אברהם",
    "הרקפת",
    "השדרה",
    "השומר",
    "השופטים",
    "השופר",
    "השושנים",
    "השחר",
    "השיטה",
    "השילוח",
    "השלושה",
    "השפלה",
    "השקמה",
    "השרון",
    "התאנה",
    "התוכנה",
    "התוף",
    "התחיה",
    "התמר",
    "התנופה",
    "התעשיה",
    "התפוז",
    "התשעים ושלוש",
    "וויז סטפן",
    "וולפסון דוד",
    "ויזנטל שמעון",
    "ויטנברג יצחק",
    "וינברגר שמואל",
    "וינגיט",
    "וינצקי ליפא",
    "וינקלר אלתר",
    "ויסבורג",
    "ויסוקר קופל",
    "ויצמן",
    "ולנברג ראול",
    "ונגרובר",
    "ועד ארבע ארצות",
    "ורדינון אליעזר",
    "ושדי זכריה",
    "ז'בוטינסקי",
    "זבלודוביץ",
    "זהבי שמעון",
    "זולא אמיל",
    "זיגלבוים",
    "זייד אלכסנדר",
    "זכאי חיים",
    "זכרון יעקב",
    "זכרון משה",
    "זכריה",
    "זלוטניק שלמה",
    "זמיר עמנואל",
    "זמירות",
    "זמנהוף",
    "זנגויל ישראל",
    "זפרן מרדכי",
    "זקן השומרים",
    "זרובבל יעקב",
    'ח"ן',
    'חב"ד',
    "חבקוק",
    "חברון",
    "חגי",
    "חגין פנחס",
    "חדד סנדר",
    "חדרה",
    "חובבי ציון",
    "חזון איש",
    "חזן יעקב",
    "חטיבת אלכסנדרוני",
    "חיון דוד",
    "חיסין",
    "חכם יוסף חיים",
    "חכמי ישראל",
    "חכמי לובלין",
    "חלוצת הפרדסנות",
    "חלמיש",
    "חנני יוסף",
    "חנקין יהושע",
    "חסדי שמעון",
    "חפץ חיים",
    "חפץ מרדכי",
    'חת"ם סופר',
    "טביב אברהם",
    "טבנקין יצחק",
    "טבצ'ניק דוד",
    "טבריה",
    "טוסקניני",
    "טיכו אנה",
    "טרגר חנה",
    "טרומפלדור",
    "טשרניחובסקי",
    'י"ד הבנים',
    "יבנאל",
    "יבנה",
    "יגיע כפיים",
    "יגנס",
    "יהוד",
    "יהודה הלוי",
    "יהלום בנימין",
    "יואל",
    "יואלי זלמן שנאורסון",
    "יוכלמן יחיאל",
    "יונה",
    "יוספסברג",
    "יורדי הסירה",
    "יחיעם",
    "יטקובסקי אחים",
    'יל"ג',
    "ילדי טהרן",
    "ילדי טרנסניסטרה",
    "ילדי מרישין",
    "ילין אבינועם",
    "ילישביץ יעקב",
    "ימין שושן",
    "ימיני דב",
    "יניב",
    "יסוד המעלה",
    "יעבץ זאב",
    "יעקב כהן",
    "יערי מאיר",
    "יפה נוף",
    "יפרח",
    "יצחק אלחנן",
    "יצחק בשביס זינגר",
    "יצחקי יצחק",
    "יציאת אירופה",
    "יקותיאל אדם",
    "ירושלים",
    "ישעיהו ישראל",
    "ישראל ושרה",
    "ישראלוב",
    'כ"ז בניסן',
    'כ"ט בנובמבר',
    "כבוש העבודה",
    "כביש חוצה שומרון",
    "כהן חיים",
    "כוחלני חיה",
    "כורש המלך",
    "ככר אגוז",
    "ככר אורוגואי",
    "ככר ברוש",
    "ככר גפן",
    "ככר דולב",
    "ככר הברון רוטשילד",
    "ככר הדס",
    "ככר המייסדים",
    "ככר המלחים",
    "ככר ויצמן",
    "כנסת ישראל",
    "כפר אברהם",
    "כפר גנים א",
    "כפר גנים ב",
    "כפר גנים ג",
    "כפר הבפטיסטים",
    "כץ מיכל לייב",
    "כצנלסון אהרון",
    "כצנלסון ברל",
    "כרמלי",
    "לבון פנחס",
    "לברי מרק",
    "לובה אליאב",
    "לובטקין צביה",
    "לודיוב",
    "לוויס עמנואל",
    "לוחמי הגיטו",
    "לוחמי השחרור",
    "לוין",
    "לוין יוסף",
    "לוינסון כלב",
    "לויתן דב",
    "לויתן חנן",
    'לח"י',
    "ליבני איתן",
    "ליברכט עקיבא",
    "ליברמן אהרון",
    "ליויק",
    "לילנבלום",
    "ליסין",
    "ליפקיס זאב",
    "ליפשיץ דניאל",
    "לם ישראל",
    "למדן יצחק",
    "למפרט צבי",
    "לנדוי שמואל חיים",
    "לסקוב חיים",
    "לשם",
    'מ"ג',
    "מאיה ירון",
    "מאיר שפיה",
    "מאירוב",
    "מאירי פנחס",
    "מאפו",
    "מבטחים",
    "מבצע דקל",
    "מבצע חורב",
    "מבצע יואב",
    "מבצע יפתח",
    "מבצע קדש",
    "מהרשק בני",
    "מודיעין",
    "מוטה גור",
    "מולדובסקי קדיה",
    "מונטיפיורי",
    "מוסטובוי נח",
    "מוסינזון יגאל",
    "מוצקין",
    "מורי צברי",
    "מוריאל יהודה",
    "מוריה",
    'מזא"ה',
    "מזכרת בתיה",
    "מזרחי אברהם",
    "מחנה יהודה",
    "מטולה",
    "מטות יהודה",
    "מטלון יצחק",
    "מייזנר",
    "מיכאליס שלמה",
    "מיכה",
    "מיכל",
    "מילס שמואל",
    "מילר שלמה",
    "מינץ בנימין",
    "מירזיוף משיח",
    "מירקין מרדכי",
    "מכנס גד",
    "מלאכי",
    "מלחמת ששת הימים",
    "מנדלסון",
    "מנחת שלמה",
    "מניה שוחט",
    "מסדה",
    "מסקין",
    "מסריק",
    "מעפילי מפקורה",
    "מקליס מאיר",
    "מרגלית",
    "מרדכי בצ'איב",
    "מרחבי שלום",
    "מרידור יעקב",
    "מרים ילן שטקליס",
    "מרכוס משה",
    "מרכז רפואי רבין",
    "משה שמיר",
    "משולם משה",
    "משמר הירדן",
    "משעול אביאלי",
    "משעול אברהם ברון",
    "משעול אברהם סמו",
    "משעול אפרים כוכבי",
    "משעול אפשטיין",
    "משעול בורוכוב ירון",
    "משעול בן עזר מינה",
    "משעול גורני שלום",
    "משעול גינזבורג יוסף",
    "משעול גלובמן",
    "משעול דיקר שמשון",
    "משעול הבנים",
    "משעול הרב יצחק קאץ",
    "משעול חזקיה שמעונוב",
    "משעול חסיד גדליה",
    "משעול טלישבסקי",
    "משעול יעקב קלדרון",
    "משעול מוריס אליעזר",
    "משעול מינס מנשה",
    "משעול מרדכי וולפסון",
    "משעול מרקוס",
    "משעול משפחת מקלב",
    "משעול נשיא יוסף",
    "משעול פינסון אהרון",
    "משעול פרבר",
    "נאות צור",
    "נבטים",
    "נבנצאל",
    "נוביק רבקה",
    "נוה עוז",
    "נוה רעים",
    "נווה גן",
    "נווה גנים",
    "נווה עוז הרחבה",
    "נווה עוז מערב",
    "נורדאו מכס",
    "נחום",
    "נחלת צבי",
    "נחמן מברסלב",
    "נחשון",
    "ניגונים",
    "ניסן משה",
    "ניר נחום",
    "נס ציונה",
    "נפש חיה",
    "נצח ישראל",
    "נקאש",
    "נקר יוסף",
    "נתיב היובל",
    "נתן",
    "נתניהו יוני",
    "סביון",
    "סגליות",
    "סוטין חיים",
    "סוטיצקי אברהם",
    "סולד הנריטה",
    "סוקולוב",
    "סורוקה משה",
    "סטרומה",
    "סטרכילביץ צבי",
    "סילבר אבא הלל",
    "סיני",
    "סירני אנצו",
    "סלבנדי",
    "סלומון",
    "סלור",
    "סלנט שמואל",
    "סמ אסיה",
    "סמ אפרתי שמחה",
    "סמ הרב ירמה",
    "סמ התבור",
    "סמילנסקי",
    "סנדרוב",
    "סנה משה",
    "סנש חנה",
    "סעדיה גאון",
    "ספקטור",
    "עגנון",
    "עדש שפיק",
    "עובדיה",
    "עוזר חיים",
    "עולי בבל",
    "עולי הגרדום",
    "עופרי סעדיה",
    "עזרא ונחמיה",
    "עזריאל",
    "עידוד ישראל",
    "עין גדי",
    "עין גנים",
    "עמוס",
    "עמי",
    "עמינח נחמיה",
    "עמיצור אברהם",
    "עמיקם",
    "עמל",
    "עמק השבע",
    "ענבר",
    "עתלית",
    "פאגלין עמיחי",
    "פארק תעשיה אזורים",
    "פוגל הרמן",
    "פוחס דוד",
    "פוקס ישראל",
    "פיארברג",
    "פיכמן יעקב",
    "פינס יחיאל מיכל",
    "פינסקר",
    "פינקל נתן צבי",
    "פינשטיין חיה",
    'פיק"א',
    "פלד משה",
    "פלדמן יוסף",
    "פנחסי יעקב",
    "פני מנחם",
    "פנקס",
    "פסח קולר",
    "פסקל פרץ",
    "פפר יוסף",
    "פקיעין",
    "פרוג שמעון",
    "פרומקין",
    "פרופ ליבוביץ ישעיהו",
    "פרופ' טור",
    "פרופ' ישראל יבין",
    "פרופ' פאול נתן",
    "פרופ' פלר נח",
    "פרופ' שור",
    "פרידמן אלעזר",
    "פרידמן ילין",
    "פרישמן דוד",
    "פרנקפורטר דוד",
    "פרץ",
    "פרץ נפתלי",
    "פתח תקווה",
    "צ'לנוב יחיאל",
    "צדוק אסתר",
    'צה"ל',
    "צוויק בן ציון",
    "צוקרמן יצחק",
    "ציפורי טובה",
    "צלח שלום",
    "צפניה",
    "צפת",
    "צריפים עמישב",
    "ק סביסלוצקי",
    "קאמינקר",
    "קארו יוסף",
    "קבוץ גלויות",
    "קדושי פרהוד",
    "קדושי קהיר",
    "קדיש לוז",
    "קהילות יעקב",
    "קהילת יוסטון",
    "קהילת משקוליץ",
    "קהילת פינסק",
    "קהילת פראג",
    "קהילת רובנה",
    "קהילת שיקגו",
    "קוגלמן תרזה",
    "קוז'ידלו עמנואל",
    "קולקר שלום",
    "קופלמן אברהם",
    "קורצ'ק ינוש",
    "קיש",
    "קלויזנר",
    "קמינסקי רבקה",
    "קנופף יוסף",
    "קפלן אליעזר",
    "קפלנסקי שלמה",
    "קציר אהרון",
    "קראוסמן מרדכי",
    "קרב אל עלמיין",
    "קרוואני מרים",
    "קרול יעקב",
    "קרימיניצקי",
    "קרית אלון",
    "קרית אליעזר פרי",
    "קרית אריה",
    "קרית דוד אלעזר",
    "קרית המשוררים",
    "קרית הרב סלומון",
    "קרית השר חזני",
    "קרית השר ספיר",
    "קרית מטלון",
    "קרית משה",
    "קרמרמן יוסף",
    "קרן היסוד",
    "קרן קימת לישראל",
    "קרני אורי",
    "קשאני אליעזר",
    "ר' אריה לוין",
    "ראב אחים",
    "ראם שלמה",
    "ראש העין",
    "ראש פינה",
    "ראשון לציון",
    "רבי יהודה הנשיא",
    "רבי עקיבא",
    "רבינוביץ יעקב",
    "רבינזון יחזקאל",
    "רבניצקי",
    "רואי אליעזר",
    "רובינא חנה",
    "רוזובסקי יוסף",
    "רוטברד ברוך",
    "רוטשילד",
    "רום יעל",
    "רופין",
    "רוקח ישראל",
    "רות",
    "רזיאל דוד",
    "רח 600",
    "רח 701",
    "רח 503",
    "רח 573",
    "רח 593",
    "רח 793",
    "רח 604",
    "רח 574",
    "רח 594",
    "רח 794",
    "רח 575",
    "רח 595",
    "רח 795",
    "רח 936",
    "רח 576",
    "רח 596",
    "רח 597",
    "רח 598",
    "רח 599",
    "רחובות",
    "רחל המשוררת",
    "רחל ונחום",
    "ריבלין",
    "רייס רפאל",
    "רייק חביבה",
    "רינגלבלום",
    'רמ"א',
    'רמב"ם',
    'רמב"ן',
    "רמז דוד",
    'רמח"ל',
    "רמת הגולן",
    "רמת סיב",
    'רנ"ק',
    "רפאל איתן",
    "רפאלי שרגא",
    "רפפורט נתן",
    "רצון מנחם",
    'רש"י',
    "רשיש חדוה",
    "ש 3 קומות עמישב",
    "ש אחדות",
    "ש אחדות חדש",
    "ש אלמגור",
    "ש אסבסט עמישב",
    "ש ארגוב",
    "ש בת שבע",
    "ש גורדון",
    "ש גן בועז",
    "ש גני גזית",
    "ש גת רמון",
    "ש הגרמנים",
    "ש המורים",
    "ש העצמאות",
    "ש ותיקים פג'ה",
    "ש זוגות צעירים",
    "ש חדש עמישב",
    "ש חילים משוחררים",
    "ש חסידוף",
    "ש חסכון",
    "ש יבהם",
    "ש לומיר",
    "ש ליפוביץ",
    "ש מזרחי חדש",
    "ש מזרחי שעריה",
    "ש מחנה יהודה",
    "ש מעון עובדים",
    "ש מפונים",
    "ש מפם",
    "ש נוה יוסף",
    "ש נכסי חן",
    "ש סגל פג'ה",
    "ש סולל בונה",
    "ש סלע",
    "ש סלע חדש",
    "ש עובדי בילינסון",
    "ש עובדים",
    "ש עירוני חפץ חיים",
    "ש עירוני שעריה",
    "ש עמידר ב",
    "ש עמידר עמישב",
    "ש עמידר פג'ה",
    "ש עממי חפץ חיים",
    "ש עקיבא",
    'ש פאג"י',
    "ש פרסים",
    "ש פרסים עמישב",
    "ש צדור",
    "ש ציונים כללים",
    "ש קומות עמישב",
    "ש קומות שעריה",
    "ש רוגובין",
    "ש רמת ורבר",
    "ש רסקו",
    "ש שבת אחים",
    "ש שטרייס",
    "ש תקומה",
    "שאול המלך",
    "שאול חרנם",
    "שבדיה",
    "שבזי שלום",
    "שבט אשר",
    "שבט בנימין",
    "שבט גד",
    "שבט דן",
    "שבט יהודה",
    "שבט יוסף",
    "שבט יששכר",
    "שבט לוי",
    "שבט נפתלי",
    "שבט ראובן",
    "שבטי ישראל",
    "שדה יצחק",
    "שדרובצקי שלמה",
    "שוהם",
    "שווימר רבקה",
    "שוולב משה",
    "שולזינגר",
    "שולמית",
    'שז"ר זלמן',
    "שחם",
    "שטמפפר יהושע",
    "שטרוק הרמן",
    "שטרן אברהם",
    "שיטרית בכור",
    "שיכון הפועל המזרחי",
    "שילר שלמה",
    "שיפר יצחק",
    "שיפריס נתן",
    "שירת אסתר",
    "שכ אחים ישראלית",
    "שכ בילינסון",
    "שכ בר יהודה",
    "שכ גן שלמה",
    "שכ הרכבת",
    "שכ יוספטל",
    "שכ משכנות גנים",
    "שכ נווה מעוז",
    "שכ סגולה",
    "שכ עין גנים",
    "שכ עמישב",
    "שכ קרוונים",
    "שכ קרול",
    "שכ שיפר",
    "שכטר יוסף",
    'של"ג',
    "שלום עליכם",
    "שלום שמן",
    "שלומציון המלכה",
    "שלונסקי אברהם",
    "שלמה",
    "שמואל הנגיד",
    "שמואל יבניאלי",
    "שמואלביץ מרדכי",
    "שמואלי",
    "שמחוני אסף",
    "שמחי דוד",
    "שמעוני דוד",
    "שמעיה ואבטליון",
    "שמשון",
    "שניאור זלמן",
    "שנקר אריה",
    "שעריה",
    "שפיגל זוסיה",
    "שפילברג שרגא",
    "שפינוזה",
    "שפירא הרמן צבי",
    "שפרינצק",
    "שקלובסקי אליהו",
    "שרעבי שלום",
    "שרת משה",
    "תבורי משה",
    "תדהר דוד",
    "תוצרת הארץ",
    "תור הזהב",
    "תורה ועבודה",
    "תל חי",
    "תלמים",
    "תפוצות ישראל",
  ],
  פתחיה: ["פתחיה"],
  צאלים: ["אולפן קיבוץ", "בית ראשון", "צאלים"],
  צביה: ["צביה"],
  צבעון: ["צבעון"],
  צובה: ["אולפן קיבוץ", "צובה"],
  צוחר: ["מרכז קליטה", "צוחר"],
  צופיה: ["צופיה"],
  צופים: [
    "האגוז",
    "האלה",
    "האתרוג",
    "הגפן",
    "הדר",
    "ההדס",
    "הזית",
    "החרוב",
    "הרימון",
    "השקד",
    "התאנה",
    "התמר",
    "ערבי נחל",
    "צופים",
  ],
  צופית: [
    "ברוש",
    "גפן",
    "דקל",
    "האילנות",
    "הבאר",
    "הגנים",
    "הדר",
    "הזית",
    "החצב",
    "החרוב",
    "המצפה",
    "המשעול",
    "הקשת",
    "השדרה",
    "מעלה הגן",
    "צופית",
  ],
  צופר: ["צופר"],
  "צוקי ים": [
    "דרך האלמוג",
    "דרך הזיתים",
    "דרך הים",
    "דרך השונית",
    "החוף",
    "סמ גלים",
    "סמ הצדף",
    "סמ השחף",
    "סמ כוכב הים",
    "סמ נר הלילה",
    "סמ שלדג",
    "צוקי ים",
  ],
  צוקים: ["צוקים"],
  "צור הדסה": [
    "אגוז",
    "אגמית",
    "אגס",
    "אורן",
    "אלה",
    "אלון",
    "אנפה",
    "אפרסמון",
    "ארז",
    "אשד",
    "בשמת",
    "דוכיפת",
    "דולב",
    "דפנה",
    "דקל",
    "דרור",
    "דרך השמורה",
    "הזית",
    "הקנמון",
    "הר כתרון",
    "הרדוף",
    "ורד",
    "ורדית",
    "חוגלה",
    "טללים",
    "יסמין",
    "יסעור",
    "יקינטון",
    "כחל",
    "כליל החורש",
    "כלנית",
    "כרכום",
    "לבונה",
    "לוטם",
    "מבוא הגפן",
    "מבוא הדס",
    "מבוא החרוב",
    "מבוא חצב",
    "מבוא נרד",
    "מבוא סיגלית",
    "מבוא תמר",
    "מור",
    "מרווה",
    "נורית",
    "נחליאלי",
    "נטפים",
    "נרקיס",
    "סביון",
    "סייפן",
    "סלעית",
    "סנונית",
    "עגור",
    "עירית",
    "עפרוני",
    "ערבה",
    "פרג",
    "צבעוני",
    "צור הדסה",
    "צפית",
    "קורנית",
    "קטלב",
    "קידה",
    "קציעה",
    "רכסים",
    "רמון",
    "רקפת",
    "שד הרי יהודה",
    "שורק",
    "שיטה",
    "שיקמה",
    "שכ הר כתרון",
    "שלדג",
    "שלמון",
    "שקד",
    "תאנה",
    "תור",
    "תות",
    "תלתן",
  ],
  "צור יצחק": [
    "נחל איילון",
    "נחל אלכסנדר",
    "נחל ברק",
    "נחל דוד",
    "נחל הדס",
    "נחל ערוגות",
    "נחל פארן",
    "נחל פולג",
    "נחל צלמון",
    "נחל קנה",
    "נחל שילה",
    "צור יצחק",
  ],
  "צור משה": [
    "בוסתן הכפר",
    "דוכיפת",
    "האילנות",
    "האנפה",
    "האתרוג",
    "הבוסתן",
    "הבנים",
    "הגפן",
    "הדקלים",
    "הדרור",
    "הדרים",
    "ההדס",
    "ההדר",
    "הזית",
    "הזמיר",
    "החורשים",
    "החיטה",
    "החלוצים",
    "החסידה",
    "הלולב",
    "הסנונית",
    "העגור",
    "הערבה",
    "הפרדס",
    "הפרדסים",
    "הצבר",
    "הרימון",
    "השחף",
    "השיבולים",
    "השלדג",
    "השעורה",
    "התאנה",
    "התמר",
    "סמ דוד פלורנטין",
    "סמ האנפה",
    "פלורנטין דוד",
    "צור משה",
    "שכ גבעת משה",
  ],
  "צור נתן": ["צור נתן"],
  צוריאל: ["צוריאל"],
  צורית: [
    "האלה",
    "האלון",
    "הארז",
    "הברוש",
    "הדובדבן",
    "הזית",
    "החצב",
    "החרוב",
    "הכלנית",
    "הסחלב",
    "הערבה",
    "הצלף",
    "הרימון",
    "השיטה",
    "השקד",
    "התאנה",
    "התמר",
    "צורית",
  ],
  ציפורי: [
    "אמת המים",
    "דרך המנזר",
    "הזיתים",
    "החרובים",
    "המייסדים",
    "המצודה",
    "המשנה",
    "הסנהדרין",
    "הפסיפס",
    "חלב ודבש",
    "מבצע דקל",
    "ציפורי",
    "קטרון",
    "רבי יהודה הנשיא",
    "רימונים",
    "ריש לקיש",
  ],
  צלפון: [
    "האגוז",
    "האתרוג",
    "הגפן",
    "הדקל",
    "ההדס",
    "הזית",
    "החרוב",
    "הערבה",
    "הצבר",
    "הרימון",
    "השקד",
    "התאנה",
    "התמר",
    "צלפון",
  ],
  צנדלה: [
    "אבו בכר אלסדיק",
    "אלאנסר",
    "אלביאדר",
    "אלמוהג'ריין",
    "אלמועתסם",
    "אלמזאר",
    "אלסולטאני",
    "אלקדס",
    "בלאל בן רבאח",
    "בן אלהייתם",
    "בן סינא",
    "ג'אבר בן חייאן",
    "וערת אלתינה",
    "מוחמד אלפאתח",
    "מרג' אבן עאמר",
    "סלאח אלדין",
    "עומר בן אלח'טאב",
    "צנדלה",
  ],
  צפריה: [
    "דרך החיטה",
    "הארז",
    "הגפן",
    "הזית",
    "הרימון",
    "התאנה",
    "התמר",
    "צפריה",
  ],
  צפרירים: ["צפרירים"],
  צפת: [
    "אבוהב",
    "אבריטש",
    "אדרעי משה",
    "אודם",
    "אולפן סוכנות",
    "אזור הוילות",
    "אזור תעשיה חדש",
    "אזור תעשיה ישן",
    "איסקוב ליאון",
    "איש גמזו",
    "אלואיל אריה",
    "אלומות",
    "אלעזר דוד",
    "אלקבץ שלמה",
    "אלשיך",
    "אנילביץ",
    'אצ"ל',
    "ארלוזורוב",
    'ב"ק ישראל',
    "בגין מנחם",
    'בי"ח רבקה זיו',
    "ביאליק",
    "בית בוסל",
    "בית חנה",
    "בית יוסף",
    "בר יוחאי",
    "ברקת",
    "בת הרים",
    "גבעת שושנה",
    "דולב",
    "דורון צבי",
    "דרך אבני החושן",
    "דרך החסידים",
    'דרך הרמב"ם',
    "דרך השומשום",
    "דרך השוקולד",
    'דרך קק"ל',
    "האגוז",
    "האורן",
    "האחד עשר",
    "האלון",
    "האסיף",
    'האר"י',
    "הארזים",
    "הברוש",
    "הגדוד השלישי",
    "הגולן",
    "הגורן",
    "הגליל",
    "הגפן",
    "הדסה",
    "הדרור",
    "ההגנה",
    "ההסתדרות",
    "הופרט יעקב",
    "הזית",
    "החלוץ",
    "החרמון",
    "הירדן",
    "הכהן אברהם",
    "הכלנית",
    "הכשרת הישוב",
    "המעוף",
    "המקובלים",
    "המשיח",
    "הנחלים",
    "הנרקיס",
    "הנשיא",
    "הסביון",
    "העיר העתיקה",
    "העצמאות",
    'הפלמ"ח',
    "הפרחים",
    "הקציר",
    "הר אביטל",
    "הר אדיר",
    "הר ארבל",
    "הר בנטל",
    "הר גלבוע",
    "הר גלעד",
    "הר וגיא",
    "הר כרמל",
    "הרב זילברמן שמואל",
    "הרב חורי חיים",
    "הרב לוי ביסטריצקי",
    "הרב מניני ביתאן",
    "הרב מרדכי אליהו",
    "הרב קפלן שמחה",
    "הרבי מלובאוויטש",
    "הרימון",
    "הרמונים",
    "הרצל",
    "הרקפת",
    "השבעה",
    "השיבולים",
    "השקד",
    "התאנה",
    "התבור",
    "התמר",
    "ויצמן",
    "ז'בוטינסקי זאב",
    "זגגי",
    "זמיר",
    'זק"ש סוניה',
    "חוחית",
    "חטיבת יפתח",
    'חת"ם סופר',
    "יהלום",
    "יונה",
    "יוני",
    "יוספטל",
    "יסעור",
    "יעבץ",
    "יפה נוף",
    "ירושלים",
    'כ"ב ילדי מעלות',
    'כ"ג יורדי הסירה',
    "כוכב הצפון",
    "ככר דוד גלבוע",
    "ככר המגינים",
    "כליל החורש",
    "לבנון צבי",
    "לוחמי הגטאות",
    "לוטם",
    "לוי יעקב",
    'לח"י',
    "לשם",
    "מאור חיים",
    "מגיני צפת",
    "מונטיפיורי",
    "מזרחי מנחם",
    "מיבר מאיר",
    "מכמנים",
    "מלון דוד",
    "מלון פסגה",
    'מעלה הרמ"ק',
    "מעלה צפת",
    "מעלות הרב סאסי כהן",
    "מעלות משה",
    "מעלות עולי הגרדום",
    "מעלות קדוש אליהו",
    "מעלות רפאל",
    "מצפה האגם",
    "מצפה הימים",
    "מרדכי חביב",
    "מרום כנרת",
    "מרזר אריה",
    "מרכז העיר",
    "מרכז קליטה",
    "מרכז קליטה כנען",
    "מרכז קליטה מירון",
    'מרכז קליטה צה"ל',
    "משה רביב",
    "נג'ארה",
    "נווה אורנים",
    "נוף הבשן",
    "נוף הגולן",
    "נוף הגליל",
    "נוף ההר",
    "נוף החולה",
    "נוף החרמון",
    "נוף הירדן",
    "נוף העמק",
    "נוף טליה",
    "נוף כנרת",
    "נופך",
    "נחל דן",
    "נחל עמוד",
    "נחליאלי",
    "נץ",
    "נשרים",
    "סולד הנריאטה",
    "סמ רימונים",
    "סמטה ב",
    "סמטה ד",
    "סמטה ה",
    "סמטה ו",
    "סמטה יז",
    "סמטה יט",
    "ספיר",
    "עבו",
    "עכברה",
    "עליה ב",
    "עפרוני",
    "פצ'רסקי אלכסנדר",
    "פרומצ'נקו",
    "פשוש",
    'צה"ל',
    "צופית",
    "צפת",
    "קארו יוסף",
    "קבוץ גלויות",
    "קולונל רביצקי",
    "קורצ'ק",
    "קצה הנוף",
    "קרית ברסלב",
    "קרית הציירים",
    'קרית חב"ד',
    "קרית מאור חיים",
    "קרן היסוד",
    "רבי דוד ומשה",
    "רח ה",
    "רח ט",
    "רח טו",
    "רח טז",
    "רח יא",
    "רח יב",
    "רח יג",
    "רח יז",
    "רח יח",
    "רח יט",
    'רח כ"א',
    "רח כג",
    "רח כה",
    "רח כו",
    "רח כז",
    "רח כח",
    "רח ל",
    "רח לא",
    'רידב"ז',
    "רמז דוד",
    "רמת מנחם בגין",
    "רמת רזים",
    "ש בנה ביתך",
    "ש דרום",
    "ש חסכון",
    "ש כנען",
    "ש מודרג",
    "ש נוה הדר",
    "ש עירוני",
    "ש עמידר",
    "ש עממי",
    "ש רופאים",
    "ש רסקו",
    "שבעת המינים",
    "שגב",
    'שז"ר זלמן',
    "שכ איביקור",
    "שכ גני הדר",
    "שכ הדר",
    "שכ הר כנען",
    "שכ יצחק רבין",
    "שכ נוף טליה",
    "שכ ספיר",
    "שכ קרית שרה",
    "שכ רמת אליה",
    "שכ שקמה",
    "שכונת אשכול",
    "שכונת עופר",
    "שמי מנחם",
    "שפרינצק",
    "תור",
    'תרפ"ט',
  ],
  צרופה: [
    "הגפן",
    "הזית",
    "החיטה",
    "הרימון",
    "השעורה",
    "השקד",
    "התאנה",
    "התמר",
    "צרופה",
  ],
  צרעה: ["צרעה"],
  "קבועה (שבט)": ["קבועה (שבט)"],
  "קבוצת יבנה": ["אולפן קיבוץ", "קבוצת יבנה"],
  קדומים: [
    "אזור התעשיה",
    "אחלמה",
    "איש ימינך",
    "אריאל",
    "ברכה",
    "ברקת",
    "גבעות עולם",
    "גבעת שלם",
    "גלעד",
    "גריזים",
    "גת קדומים",
    "דגן",
    "דרך בר-און אורי",
    "דרך הציונות",
    "דרך הראשונים",
    "האדרת",
    "הגיתית",
    "הזית",
    "החושן",
    "החיל",
    "החליל",
    "היובל",
    "היצירה",
    "הכינור",
    "הנבל",
    "העוז",
    "העשור",
    "הפעמון",
    "הקרן",
    "הר המור",
    "הררי קדם",
    "השקד",
    "חלקת השדה",
    "חפצי בה",
    "טל חרמון",
    "יהלום",
    "יפה נוף",
    "יצהר",
    "כרמי קדם",
    "לשם",
    "מכללת קדומים",
    "מעלה דוד",
    "מעלה חמד",
    'מעלה רש"י',
    "מעלה שניאור",
    "מצפה קדומים",
    "משעול הגפן",
    "משעול החיטה",
    "משעול הרימון",
    "משעול השעורה",
    "משעול התאנה",
    "משעול התמר",
    "נבו",
    "נחלה",
    "סיני",
    "ספיר",
    "צופיה",
    "צפנת",
    "קדומים",
    "קול צופייך",
    "רבבות אפרים",
    "שבו",
    "שוהם",
    "שילה",
    "שכ גבעת שלם",
    "שכ גת קדומים",
    "שכ כרמי קדם",
    "שכ מצפה ישי",
    "שכ מצפה קדומים",
    "תבואות שמש",
    "תירוש",
    "תרצה",
    "תרשיש",
  ],
  "קדימה-צורן": [
    "אדום",
    "אזור תעשייה מערבי",
    'אח"י דקר',
    "אחד העם",
    "אלמוג",
    "אמנון ותמר צורן",
    "ארבל",
    "ארגמן",
    "אריות הגולן צורן",
    "בגין מנחם",
    "ביאליק",
    "בית הכרם",
    "בר יהודה",
    "ברנר",
    "ברקת",
    "בשמת",
    "גבעת מנחם בגין",
    "גד",
    "גורדון",
    'ד"ר שפירא',
    "דובדבן",
    "דישון",
    "דליה",
    "דפנה צורן",
    "דרך דוד בן גוריון",
    "דרך היער",
    "דרך התות",
    "דרך לב השרון צורן",
    "האגוז",
    "האדמונית צורן",
    "האופה",
    "האורג",
    "האורן",
    "האזדרכת",
    "האילן צורן",
    "האילנות צורן",
    "האירוסים",
    "האיריס צורן",
    "האלה",
    "האלון",
    "האלונים",
    "האלמוגן",
    "האמירים",
    "האנפה",
    "האפרסמון",
    "האקליפטוס",
    "הארז",
    "האשכולית",
    "האשל",
    "האתרוג",
    "הברבור",
    "הברוש צורן",
    "הברושים",
    "הגולן",
    "הגומא",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הגפן צורן",
    "הגפנים",
    "הדוכיפת",
    "הדולב צורן",
    "הדולפין",
    "הדס צורן",
    "הדרור",
    "ההגנה",
    "ההדסים",
    "ההדר",
    "ההדרים צורן",
    "ההסתדרות",
    "הורדים",
    "הזית צורן",
    "הזמיר",
    "הזרם",
    "החבצלת",
    "החוגלה",
    "החליל",
    "החסידה",
    "החצב צורן",
    "החצבים",
    "החרוב צורן",
    "החרובים",
    "החרמון",
    "החרצית צורן",
    "החשמל",
    "הטווס",
    "היובל",
    "היונה",
    "היסמין",
    "היען",
    "היער",
    "היערה צורן",
    "היקינטון",
    "הכינור",
    "הכלנית",
    "הכרכום",
    "הלבונה",
    "הלולב",
    "הלימון",
    "המור",
    "המיתר",
    "המעלית",
    "המרגנית צורן",
    "המתכת",
    "הנבל",
    "הנגב",
    "הנגר",
    "הנוריות",
    "הנורית צורן",
    "הנחליאלי",
    "הניצן",
    "הנירים צורן",
    "הנרקיס",
    "הנשר",
    "הסביון",
    "הסביונים צורן",
    "הסחלב צורן",
    "הסחלבים",
    "הסיגלון",
    "הסיתונית צורן",
    "הסנונית",
    "העגור",
    "העורב",
    "העיט",
    "הערבה",
    "הערמונים",
    'הפלמ"ח',
    "הפעמון",
    "הפקאן צורן",
    "הפקאנים",
    "הפרג",
    "הפרדס",
    "הפשוש",
    "הצבעוני",
    "הצבר",
    "הצברים צורן",
    "הציפורים",
    "הציפורן",
    "הצליל",
    "הקשת",
    "הר נבו",
    "הרב אביקסיס",
    "הרב אלקלעי",
    "הרותם",
    "הרימון",
    "הרצוג",
    "הרצל",
    "הרקפות צורן",
    "הרקפת",
    "השומרון",
    "השושן",
    "השחף",
    "השיבולת",
    "השיזף",
    "השיטה",
    "השלדג",
    "השקד",
    "השקמה צורן",
    "השקמים",
    "השרון",
    "התאנה",
    "התבור",
    "התפוז",
    "התפוח",
    "ויצמן",
    "ויתקין",
    "וסרמן סופיה",
    "ז'בוטינסקי",
    "חולות גאולים צורן",
    "חמנית",
    "חנקין",
    "טהון יהושע",
    "טיילת השרון הירוק",
    "יהלום",
    "יונק הדבש",
    "יוספטל",
    "כהן אלי",
    "כהן יעקב",
    "כליל החורש",
    "כנרת",
    "כצנלסון",
    "לוע הארי",
    "לילך צורן",
    "לשם",
    'מ"ג',
    "מואב",
    "מורדי הגטאות",
    "מימון",
    "מירון",
    "מעלה שלמה",
    "משמר הירדן",
    "נופר",
    "סולד",
    "סימטת קפלן",
    "סמ אבני סעדיה",
    "סמ הברון",
    "סמ הגן",
    "סמ ההדס",
    "סמ הפעמון",
    "סמ מדיני מאיר",
    "סמ רמז",
    "סמדר",
    "סמטת בן צבי",
    "סמטת היסמין",
    "סנפיר",
    "ספיר",
    "עדעד",
    "עמק החולה",
    "עמק יזרעאל",
    "עפרוני צורן",
    "פארק תעשיות השרון",
    "צופית צורן",
    "צורן",
    "קדימה",
    "קדימה-צורן",
    "קורצ'ק",
    "קרית שלמה",
    "רוזנבך",
    "רוטשילד",
    "רמז",
    "רמת אמיר",
    "רמת שמואל",
    "ש אזורים",
    "ש המועצה",
    "ש חדש",
    "ש עמידר",
    "ש שרת",
    "שבזי",
    "שבט אפרים",
    "שבט אשר",
    "שבט בנימין",
    "שבט דן",
    "שבט זבולון",
    "שבט יהודה",
    "שבט יששכר",
    "שבט נפתלי",
    "שבט ראובן",
    "שבט שמעון",
    "שביל הפרחים צורן",
    "שביל התפוזים צורן",
    "שד בן צבי",
    "שד הדקלים",
    "שד יצחק רבין צורן",
    "שדות",
    "שוהם",
    "שושן צחור צורן",
    "שושנת העמקים צורן",
    "שכ אילנות",
    "שכ השרון הירוק",
    "שכ יציב",
    "שניר",
    "שקנאי צורן",
    "תדהר",
    "תכלת",
    "תמנע",
  ],
  קדמה: ["קדמה"],
  "קדמת צבי": ["קדמת צבי"],
  קדר: [
    "אור",
    "אחדות",
    "אחווה",
    "הזית",
    "השחר",
    "זריחה",
    "עדן",
    "קדר",
    "קשת",
    "רעות",
  ],
  קדרון: [
    "אפרסק",
    "בן צבי יצחק",
    "דליה",
    "האגס",
    "הגפן",
    "הדר",
    "הורד",
    "הזית",
    "החרצית",
    "הכלנית",
    "הלילך",
    "הנורית",
    "הרימון",
    "התאנה",
    "התמר",
    "התפוח",
    "קדרון",
  ],
  קדרים: ["קדרים"],
  "קודייראת א-צאנע(שבט)": ["קודייראת א-צאנע"],
  "קוואעין (שבט)": ["קוואעין (שבט)"],
  קוממיות: ["קוממיות"],
  קורנית: [
    "ארבל",
    "בצת",
    "געתון",
    "דישון",
    "דן",
    "הים",
    "הירדן",
    "חילזון",
    "חרמון",
    "יצהר",
    "יראון",
    "כזיב",
    "מורן",
    "מירון",
    "מעלה קורנית",
    "נעמן",
    "צבעון",
    "ציפורי",
    "קורנית",
    "שניר",
    "שעל",
    "שרך",
    "תבור",
  ],
  קטורה: ["קטורה"],
  קיסריה: [
    "אביב",
    "אדוה",
    "אודם",
    "אודם-פארק התעשייה",
    "אור",
    "איריס",
    "אלון התבור",
    "אלמוג",
    "אלת המסטיק",
    "אקוודוקט",
    "ארבע העונות",
    "אשכולית",
    "בז",
    "בזלת",
    "ברק",
    "ברקן",
    "ברקת",
    "ברקת-פארק התעשייה",
    "גיא",
    "גל",
    "גני קיסריה",
    "גרניט",
    "דוגית",
    "דוכיפת",
    "דרור",
    "דרך הים",
    "דרך פל-ים",
    "דרך קיסריה",
    "האגוז",
    "האגס",
    "האורג",
    "האורן",
    "האלון",
    "האשל",
    "האתרוג",
    "הברוש",
    "הגפן",
    "הגשם",
    "הדולב",
    "הדיונה",
    "הדקל",
    "הדר",
    "הורדים",
    "הזוהר",
    "הזית",
    "החרוב",
    "החרש",
    "הטוחן",
    "הכוכבים",
    "הכורם",
    "הכלניות",
    "הכרמל",
    "הלבנה",
    "המגדל",
    "הנופר",
    "הנץ",
    "הנקר",
    "הצדף",
    "הרדוף",
    "הרדוף הנחלים",
    "הרוקם",
    "הרימון",
    "הרקיע",
    "השונית",
    "השזיף",
    "השחף",
    "השיטה",
    "השמורה",
    "השמים",
    "השמש",
    "השקד",
    "התאנה",
    "התור",
    "התמר",
    "זמיר",
    "חבצלת",
    "חבצלת החוף",
    "חולית",
    "חוף הזהב",
    "חורף",
    "חלמיש",
    "טופז",
    "טל",
    "ידיד החולות",
    "יהלום",
    "יהלום-פארק התעשייה",
    "כנרית",
    "ליבנה",
    "לימון",
    "לשם",
    "לשם-פארק התעשייה",
    "מאדים",
    "מלון דן קיסריה",
    "מלון סמדר",
    "מנדרינה",
    "משמרות",
    "נאות גולף",
    "נגה",
    "נר הלילה",
    "נרקיס",
    "נשר",
    "סלעית",
    "סמ הדולב",
    "סמ הליבנה",
    "סנונית",
    "סנונית-פארק התעשייה",
    "סנפיר",
    "ספיר",
    "ספיר-פארק התעשייה",
    "סתיו",
    "עגור",
    "עיט",
    "ענבר",
    "עפרוני",
    "ערה",
    "פארק התעשייה",
    "פומלו",
    "צופית",
    "קיסריה",
    "קיסריה העתיקה",
    "קיסריה וילות",
    "קיסרית",
    "קיץ",
    "רותם המדבר",
    "רמות קיסריה",
    "רעם",
    "רקפת",
    "שביט",
    "שד רוטשילד",
    "שוהם",
    "שוהם-פארק התעשייה",
    "שחם",
    "שכ אבני חן",
    "שכ הביכורים",
    "שכ ההדרים",
    "שכ החוף",
    "שכ החורש",
    "שכ הים",
    "שכ המעוף",
    "שכ העדי",
    "שכ הפרחים",
    "שכ הציפורים",
    "שכ הצמרות",
    "שכ הרקיע",
    "שכונה בטבע",
    "שלדג",
    "תפוז",
    "תרשיש",
    "תרשיש-פארק התעשייה",
  ],
  קלחים: ["קלחים"],
  קליה: ["קליה"],
  קלנסווה: [
    "אזור דרום מערבי",
    "אזור דרומי",
    "אזור הכדורגל",
    "אזור העירייה",
    "אזור מזרחי",
    "אזור מעל הנחל",
    "אזור צפוני",
    "קלנסווה",
    "רח 20",
    "רח 120",
    "רח 30",
    "רח 40",
    "רח 150",
    "רח 1",
    "רח 11",
    "רח 111",
    "רח 21",
    "רח 121",
    "רח 131",
    "רח 41",
    "רח 151",
    "רח 2",
    "רח 12",
    "רח 112",
    "רח 132",
    "רח 42",
    "רח 152",
    "רח 3",
    "רח 13",
    "רח 113",
    "רח 123",
    "רח 133",
    "רח 4",
    "רח 14",
    "רח 114",
    "רח 124",
    "רח 134",
    "רח 44",
    "רח 5",
    "רח 15",
    "רח 115",
    "רח 25",
    "רח 135",
    "רח 155",
    "רח 6",
    "רח 16",
    "רח 116",
    "רח 126",
    "רח 136",
    "רח 146",
    "רח 156",
    "רח 17",
    "רח 117",
    "רח 127",
    "רח 137",
    "רח 18",
    "רח 118",
    "רח 128",
    "רח 138",
    "רח 148",
    "רח 158",
    "רח 19",
    "רח 119",
    "רח 129",
    "רח 139",
    "רח 149",
    "שכ כרמים",
  ],
  קלע: ["קלע"],
  קציר: [
    "דרך האלון",
    "האורן",
    "האלה",
    "הארז",
    "הבוצר",
    "הגורן",
    "הגת",
    "הדגן",
    "הזורע",
    "הזית",
    "החורש",
    "החרוב",
    "החרמש",
    "היקב",
    "המגוב",
    "המגל",
    "המורג",
    "המחרשה",
    "המצפה",
    "הפטל",
    "הקוצר",
    "הקמה",
    "הרדוף",
    "מורן",
    "קציר",
    "שד האמירים",
    "שדמה",
    "שיבולת",
  ],
  "קצר א-סר": ["קצר א-סר"],
  קצרין: [
    "אביטל",
    "אבני איתן",
    "אגמית",
    "אוליפנט",
    "אורטל",
    "אזור התעשיה",
    "איה",
    "אל-רום",
    "אלוני הבשן",
    "אלונים",
    "אליעד",
    "אניעם",
    "אנפה",
    "אפיק",
    "ארבל",
    "ארז",
    "אשחר",
    "בדולח",
    "בהט",
    'בי"ס שדה גולן',
    "בני יהודה",
    "ברנע",
    "ברקת",
    "בתרא",
    "גביש",
    "גבע",
    "גבעת יואב",
    "גלבון",
    "גנוסר",
    "גשור",
    "דבורנית",
    "דברת",
    "דוכיפת",
    "דולב",
    "דליות",
    "דרור",
    "דרך החרמון",
    "דרך היין",
    "דרך המים",
    "דרך השמן",
    "האומנים",
    "האלה",
    "האפיק",
    "הבאר",
    "הבציר",
    "הגפן",
    "הגת",
    "הדס",
    "הזית",
    "החלב",
    "החצב",
    "היובל",
    "היינן",
    "היקב",
    "הכד",
    "הכרם",
    "המור",
    "המסיק",
    "המעיין",
    "המפל",
    "המשושים",
    "הנהר",
    "הנחל",
    "הערבות",
    "הפלג",
    "הצבעונים",
    "השוקת",
    "השיזף",
    "השמן",
    "ורדית",
    "זויתן",
    "זמירון",
    "חד נס",
    "חופית",
    "חוקוק",
    "חיספין",
    "חמת",
    "חסידה",
    "טופז",
    "טיילת אפק",
    "טיילת גמלא",
    "טיילת חרמון",
    "טיילת נוה",
    "יבנאל",
    "יהודיה",
    "יהלום",
    "יודפת",
    "יונתן",
    "יצהר",
    "יראון",
    "ישפה",
    "כוכבא",
    "כורזין",
    "כיכר גילבון",
    "כיכר דליות",
    "כיכר היין",
    "כיכר המים",
    "כיכר זוויתן",
    "כיכר סער",
    "כיכר קצרין העתיקה",
    "כיכר שיאון",
    "כנף",
    "כנרות",
    "כפר חרוב",
    "לבונה",
    "ליבנה",
    "לשם",
    "מבוא חמה",
    "מגדל",
    "מיצר",
    "מירון",
    "מרגלית",
    "מרום גולן",
    "משעול אדמון",
    "משעול אודם",
    "משעול אורחה",
    "משעול בארית",
    "משעול בנטל",
    "משעול גבתון",
    "משעול גדרון",
    "משעול חוגלה",
    "משעול חיוואי",
    "משעול חרמונית",
    "משעול יונה",
    "משעול ינשוף",
    "משעול יסעור",
    "משעול נמרון",
    "משעול נקר",
    "משעול נשר",
    "משעול סלעית",
    "נאות גולן",
    "נוב",
    "נווה אטיב",
    "נחליאלי",
    "נטור",
    "ניזמית",
    "נמרוד",
    "סוסיתא",
    "סמדר",
    "סנונית",
    "סער",
    "ספיר",
    "עגור",
    "עין זיוון",
    "עינבר",
    "עפרוני",
    "פנינה",
    "פעמונית",
    "ציפורי",
    "צמח",
    "צפת",
    "קדמת צבי",
    "קדש",
    "קלע אלון",
    "קצרין",
    "קשת",
    "רובע אפק",
    "רובע בתרא",
    "רובע גמלא",
    "רובע חן",
    "רובע קדמה",
    "רח 20",
    "רח 14",
    "רח 15",
    "רח 16",
    "רח 17",
    "רח 18",
    "רח 19",
    "רחבת הדודאים",
    "רימון",
    "רמות גלעד",
    "רמת מגשימים",
    "שוהם",
    "שחרון",
    "שיאון",
    "שימרון",
    "שכ נווה",
    "שעל",
    "שריג",
    "תדהר",
    "תרשיש",
  ],
  "קרית אונו": [
    "אבן גבירול",
    "אבן עזרא",
    "אהרונסון אהרון",
    "אוסישקין",
    "אחאב",
    "איריס",
    "אלון יגאל",
    "אמיל זולה",
    "אמיר",
    "אנגל",
    "אסתר",
    'אצ"ל',
    "ארלוזורוב",
    "ארנון",
    "אשכול לוי",
    "בגין מנחם",
    "בוקצ'ין",
    "ביאליק",
    "בילינסון",
    "בלפור",
    "בן יהודה",
    "בר יהודה",
    "בר לב חיים",
    "ברכה",
    "ברנר",
    "ברש",
    "גולומב",
    "גולני",
    "גורדון",
    "גני אילן",
    "דב הוז",
    "דבורה הנביאה",
    "דוד דבורה",
    "דוד המלך",
    "דרך איתן",
    "דרך בר אילן",
    "דרך דורי יעקב",
    "דרך הגנים",
    "האגוז",
    "האגס",
    "האלון",
    "האפרסק",
    "הארז",
    "האתרוג",
    "הבטחון",
    "הברוש",
    "הגדוד העברי",
    "הגפן",
    "הדובדבן",
    "הדסה",
    "הדרור",
    "ההגנה",
    "ההדר",
    "הולצברג שמחה",
    "הורד",
    "הושע",
    "הזית",
    "הזמיר",
    "החבצלת",
    "החשמל",
    "הירקון",
    "הכלנית",
    "הכפר",
    "הלימון",
    "המגינים",
    'המהרי"ץ',
    "המייסדים",
    "המעגל",
    "המעפילים",
    "הנביאים",
    "הנשיא",
    "הס",
    "העמל",
    'הפלמ"ח',
    "הפרדס",
    "הצבעוני",
    "הצבר",
    "הקישון",
    "הקשת",
    "הר גלבוע",
    "הר הכרמל",
    "הר חרמון",
    "הר תבור",
    "הרב ימיני חיים",
    "הרב שלום יצחק הלוי",
    "הרוגי מלכות בבל",
    "הרימון",
    "הרצוג חיים",
    "הרצל",
    "הרצפלד",
    "השזיף",
    "השלושה",
    "השקד",
    "השקמה",
    "התאנה",
    "התזמורת",
    "התמר",
    "התפוז",
    "התפוח",
    "וולך",
    "וילסון",
    "וינגיט",
    "ושינגטון",
    "ז'בוטינסקי",
    "זיידמן",
    "חזקיהו",
    "חטיבת אלכסנדרוני",
    "טרומן",
    "טרומפלדור",
    "טשרניחובסקי",
    "יהואש",
    "יהודה הלוי",
    "יהודה המכבי",
    "יהודית",
    "יהושע באום",
    "יחזקאל",
    "יעל",
    "יפה נוף",
    "ירושלים",
    "ירמוך",
    "ירמיהו",
    "ישעיהו",
    'כ"ץ',
    "ככר האחים גרין",
    "כצנלסון",
    "לודרדייל לייקס",
    "לוחמי האש",
    "לוין",
    "לוס אנג'לס",
    'לח"י',
    "לינקולן",
    "לכיש",
    "לנדאו",
    "מגידו",
    "מודיעין",
    "מונטיפיורי",
    "מורדי הגטאות",
    'מלצ"ט',
    "מעלות נעורים",
    "מצדה",
    "משה אהרון טוב",
    "נהר הירדן",
    "נורדאו",
    "נחל גמלא",
    "נחל דליות",
    "נחל דן",
    "נחל חרמון",
    "נחל משושים",
    "נחל סער",
    "נחל שיאון",
    "נחל שניר",
    "נחלת נשלסק",
    "סוקולוב",
    "סמ בזל",
    "סמ דרייפוס",
    "סמ האשל",
    "סמ העץ הבודד",
    "סמ חובבי ציון",
    "סמטת קלרי גרינוולד",
    "סנה משה",
    "סנש",
    "סעדיה גאון",
    "עגנון",
    "עזר ומרזוק",
    "עמוס",
    "עמק איילון",
    "עמק האלה",
    "עמק הורדים",
    "עמק הירדן",
    "עמק יזרעאל",
    "פנקס",
    "פסגת אונו",
    "פקיעין",
    "פרדס רייספלד",
    "צדקיהו",
    'צה"ל',
    "קורצ'ק",
    "קפלן",
    "קרית אונו",
    "רבין יצחק",
    "רוטשילד",
    "רופין",
    "רח 10",
    "רחבת בני ברית",
    "רחבת דראכטן",
    "רחבת האורן",
    "רייק חביבה",
    'רמב"ן',
    "רמה",
    "רמז",
    "רקפת",
    'רש"י',
    "ש אנגלו סקסי",
    "ש חברת חשמל",
    "ש ירון",
    "ש עובדים",
    "ש קיראון",
    "ש רימון",
    "ש רסקו",
    "שאול המלך",
    "שבזי",
    "שד בן גוריון",
    "שד סולד הנריטה",
    "שד קציר אהרון",
    "שד קרן קימת",
    "שדה יצחק",
    "שוחט מניה",
    "שטרן יאיר",
    "שכ גרין",
    "שכ עבר הירדן",
    "שכ שער הקריה",
    "שלמה המלך",
    "שמואל הנביא",
    "תל חי",
    "תנועת המרי",
  ],
  "קרית ארבע": [
    "אזור התעשייה",
    "ארץ חמדה",
    "אשמורת יצחק",
    "בן יפונה",
    "בתי אפרים",
    "גבעת האבות",
    "גור אריה",
    "דוד המלך",
    "הרב צבי יהודה",
    "טבנקין",
    "יאיר",
    "יהושע בן נון",
    "יוני נתניהו",
    "ישיבה תיכונית",
    "מכללת יהודה",
    "מלון אשכולות",
    "מעלות חברון",
    "נופי ממרא",
    "נחל אשכול",
    "סולם אלדד",
    "עמק חברון",
    "קרית ארבע",
    'רא"ם',
    "רמת ממרא",
    "שד החלוצים",
    "שכ הקרוונים",
  ],
  "קרית אתא": [
    "אבן גבירול",
    "אבן עזרא",
    "אגמית",
    "אורנים",
    "אזור התעשיה",
    "אחד העם",
    "אינשטין",
    "אלבז",
    "אליהו הנביא",
    "אלמוגן",
    "אלשייך",
    "אנילביץ",
    "אנפה",
    "ארלוזורוב",
    "אשכנזי",
    "אתא",
    "בונר",
    'בי"ס ארצי לשוטרים',
    "ביאליק",
    'ביל"ו',
    "בית לחם",
    'בית"ר',
    "בכורים",
    "בן יהודה",
    "בן יוסף אהרון",
    "בן יוסף שלמה",
    "בן עמי",
    "בן צבי יצחק",
    "בן ציון",
    "בעל שם טוב",
    "בצרי יוסף",
    "בר אילן",
    "בר יהודה",
    "בר יוחאי",
    "בר כוכבא",
    "ברבור",
    "ברדיצ'בסקי",
    "ברנדייס",
    "ברנר",
    "בתי מפעל",
    "ג'קומטי אלברטו",
    "גבורי הקריה",
    "גבעה א'",
    "גבעת הרקפות",
    "גבעת טל",
    "גבעת רם",
    "גוגן",
    "גויה",
    "גולומב",
    "גוש עציון",
    "גלבוע",
    "דאלי",
    "דגה",
    "דוכיפת",
    "דפנה",
    "דקר",
    "דרך אושה",
    "דרך דשנים",
    "דרך חיפה",
    "דרך יגור",
    "דרך יצחק רבין",
    "דרך כפר חסידים",
    "דרך מנחם בגין",
    "דרך שפרעם",
    "האורן",
    "האלה",
    "האלון",
    "האסיף",
    "הארז",
    "הארי השואג",
    "האשל",
    "הברוש",
    "הגבורה",
    "הגורן",
    "הגליל",
    "הגפן",
    "הדס",
    "הדקלים",
    "ההגנה",
    "הושע",
    "הותיקים",
    "הזית",
    "החרוב",
    "היערה",
    "הירדן",
    "הירקון",
    "הכלנית",
    "הלל",
    'המ"ג',
    "המגינים",
    "המחרשה",
    "המיסדים",
    "המכבים",
    "המלאכה",
    "המעפילים",
    "הנביאים",
    "הנגב",
    "הנרי מור",
    "הנרקיסים",
    "הס",
    "העמק",
    "העצמאות",
    "הערמונים",
    "הצנחנים",
    "הקורנית",
    "הקישון",
    "הרב דוידאשווילי",
    "הרב עוזיאל",
    "הרב קוק",
    "הרדוף",
    "הרי גולן",
    "הרצוג",
    "הרצל",
    "הרר צבי",
    "השבעה",
    "השומר",
    "השופטים",
    "השוק",
    "השיטה",
    "השקדים",
    "השקמה",
    "התאנים",
    "התבור",
    "התמרים",
    "התנאים",
    "התעלה",
    "התעשיה",
    "ואן גוך",
    "וולסקיז",
    "וינגייט",
    "ויצמן",
    "ורד",
    "ז'בוטינסקי",
    "זבולון",
    "זכריה",
    "זמיר",
    "זמנהוף",
    "חבצלת",
    "חבקוק",
    "חברון",
    "חגי",
    "חוחית",
    "חופית",
    "חנקין",
    "חפץ חיים",
    "טביב אברהם",
    "טרומפלדור",
    "טשרניחובסקי",
    "יא באדר",
    "יבניאלי שמואל",
    "יהודה הלוי",
    "יהודה הנשיא",
    "יהושע בן נון",
    "יואב",
    "יואל",
    "יונה",
    "יוספטל",
    'יל"ג',
    "יסמין",
    "יצחק שדה",
    "ירושלים",
    "ירמיהו",
    "ישעיהו",
    "כביש ראשי",
    "כהן אלי",
    "כהן יעקב",
    "ככר בני ברית",
    "ככר העיריה",
    "ככר הפרחים",
    "כנרת",
    "לוטרק",
    "לוי יפה",
    "לז'ה",
    "לילינבלום",
    "מאיה",
    "מאנה",
    "מאפו",
    "מבצע סיני",
    "מגלן",
    "מגריט",
    "מודיליאני",
    "מולר הוגו",
    "מונדריאן",
    "מוצקין",
    "מורדי הגטאות",
    "מורן",
    "מטיס",
    "מיכה",
    "מימון",
    "מינץ",
    "מירו",
    "מירון",
    "מלאכי",
    "מנדלי מוכר ספרים",
    "מנלה גד",
    "מע ק נחום",
    "מעלה החרמון",
    "מקור ברוך",
    "מרזוק",
    "מרכז העיר",
    "מרכז חדש",
    "משאש יוסף",
    "נווה אברהם",
    "נוף הכרמל",
    "נורדאו",
    "נחום",
    "נחל נעמן",
    "נחל קדרון",
    "נחליאלי",
    'ניל"י',
    "נץ",
    "נקר",
    "נתיב",
    "נתיב הדרור",
    "נתיב השומרון",
    "סולד הנריטה",
    "סוקולוב",
    "סזאן",
    "סטמפל משה",
    "סייפן",
    "סירקין",
    "סלח שלום",
    "סלעית",
    "סמ אביב",
    "סמ אילון",
    "סמ אתא",
    "סמ ג'ורג' בראק",
    "סמ הגלעדי",
    "סמ הערבה",
    "סמ הפקאן",
    "סמ השחר",
    "סמ יאיר",
    "סמ עין התכלת",
    "סמולנסקין",
    "סנונית",
    "סנש חנה",
    "סרה",
    "עבודה",
    "עגור",
    'עגנון ש"י',
    "עובדיה",
    "עזאר שמואל",
    "עייט",
    "עיר גנים",
    "עמוס",
    "עפרוני",
    "עצמון",
    "עשת אביגדור",
    "פולד ברכה",
    "פינסקר",
    "פיקסו",
    'פלמ"ח',
    "פרישמן",
    "פרץ",
    "פשוש",
    "צאלח יחיא",
    "צדוק אברהם",
    "צופית",
    "צוצלת",
    "צוקית",
    "צפורי",
    "צפניה",
    "צפת",
    "קארו יוסף",
    "קבוץ גלויות",
    "קיכלי",
    "קיש",
    "קלאוזנר",
    "קלה",
    "קלישר",
    "קנדינסקי",
    "קרית אתא",
    "קרית בנימין",
    "קרן היסוד",
    "קרן קימת לישראל",
    "ראשונים",
    "רגב אריה",
    "רודן",
    "רופין",
    "רותם",
    "רזיאל דוד",
    "ריינס",
    'רמב"ם',
    "רמז",
    "רנואר",
    "רקפת",
    'רש"י',
    "ש אגש א",
    "ש אגש ב",
    "ש אגש ק בנימין",
    "ש אזבסט גב ב",
    "ש אקדמאים",
    "ש ארע ק נחום",
    "ש ארעי א",
    "ש ארעי ב",
    "ש ארעי ג",
    "ש ארעי ד",
    "ש בולגרים",
    "ש בלוקונים",
    "ש בן גוריון",
    "ש הפוהמז",
    "ש חדש",
    "ש חילים ק נחום",
    "ש חסכון",
    "ש מועצה",
    "ש מרמרוש",
    "ש משכנות פרוסטיג",
    "ש נוה חן",
    "ש עולה חדש",
    "ש עמידר א",
    "ש עמידר ב",
    "ש עמידר ג",
    "ש עמידר ק פרוסטיג",
    "ש עממי ק בנימין",
    "ש פאי",
    "שאגל",
    "שבזי",
    "שבטי ישראל",
    "שד בורוכוב",
    "שד בית וגן",
    "שד הציונות",
    "שד כצנלסון",
    "שדה בוקר",
    "שושנת העמקים",
    "שחל",
    "שחף",
    "שחרור",
    "שטיינברג",
    "שטנד",
    "שיבת ציון",
    "שכ אברמסקי",
    "שכ בית וגן",
    "שכ גבעת אלונים",
    "שכ התימנים",
    "שכ צמרת אלונים",
    "שכ ק בנימין",
    "שכ ק פרוסטיג",
    "שכ ק שטנד",
    "שכ קרית נחום",
    "שכ תל חי",
    "שלדג",
    "שלום עליכם",
    "שליו",
    "שמחוני אסף",
    "שניאור זלמן",
    "שפרינצק",
    "שפריצר נתן",
    "שרקרק",
    "שרת משה",
    "תור",
    "תל חי",
  ],
  "קרית ביאליק": [
    "אגוז",
    "אהוד מנור",
    "אושה",
    "אזור התעשיה",
    "אחד העם",
    "אילון",
    "אירוס",
    "אפרים",
    "אפרסמון",
    'אצ"ל',
    "אקליפטוס",
    "אריה אליאס",
    "אריק איינשטיין",
    "אשל",
    "אשר",
    "בורלא",
    "בנימין",
    "בעלי המלאכה",
    "ברנר",
    "גבעת הרקפות",
    "גד",
    "גולן",
    "גוש חלב",
    "גלבוע",
    "דבורה עומר",
    "דגניה",
    "דגניות",
    "דובדבן",
    "דוכיפת",
    "דליה",
    "דן",
    "דפנה",
    "דרור",
    "דרך בגין",
    "דרך האמנים",
    "דרך עכו",
    "האורנים",
    "האלונים",
    "הארזים",
    "הברושים",
    "הגליל",
    "הגנים",
    "הגפן",
    "הדקלים",
    "ההגנה",
    "הזית",
    "החקלאים",
    "היוזם",
    "היוצר",
    "המסגר",
    "המעפיל",
    "הנרקיסים",
    "הסדנה",
    "העליה",
    "העמקים",
    "העצמאות",
    "הערמונים",
    'הפלמ"ח',
    "הפקאן",
    "הפרטיזנים",
    "הקריון",
    "הרב חנניה יום טוב",
    "הרב מימון",
    "השומר",
    "השקדים",
    "השקמה",
    "התאנים",
    "התל",
    "התמרים",
    "ורד",
    "ז'בוטינסקי",
    "זבולון",
    "זינגר",
    "חביבה",
    "חבצלת",
    "חוגלה",
    "חיים וייסבורג",
    "חיננית",
    "חנה",
    "חניתה",
    "חצב",
    "חרובים",
    "חרושת",
    "חרמון",
    "חרצית",
    "יגאל בשן",
    "יגור",
    "יהודה",
    "יהונתן",
    "יוסף",
    "יוספטל גיורא",
    "יחיעם",
    "יסמין",
    "יפה ירקוני",
    "יצחק שמיר",
    "יקינתון",
    "ישראל פוליאקוב",
    "ככר בובר",
    "ככר ברגר ביאטה",
    "ככר הפרחים",
    "ככר יד לבנים",
    "ככר רופין",
    "כלניות",
    "כנען",
    "כנרת",
    "כרמל",
    "לוחמי הגיטאות",
    "לוטם",
    "לוי יוסף",
    'לח"י',
    "לילך",
    "לנדאור",
    "מולר הנס",
    "מנשה",
    "מצילי יהודי הולנד",
    "מרגנית",
    "נאות גזית",
    "נורית",
    "נחום היימן",
    "נחליאלי",
    'ניל"י',
    "ניצנים",
    "ניר עם",
    "נעמי שמר",
    "נפתלי",
    "נתן אלתרמן",
    "סולד",
    "סחלב",
    "סיגליות",
    "סיתוונית",
    "סמ גבי שושן",
    "סמ הצאלים",
    "סמ חנה מרון",
    "סמ שייקה אופיר",
    "סנונית",
    "ספי ריבלין",
    'עגנון ש"י',
    "עוזי חיטמן",
    "עופרה חזה",
    "עפרוני",
    "עצמון",
    "פטל",
    "פרישמן",
    "פרץ",
    "צבעוני",
    "צברים",
    "צופית",
    "צור שלום",
    "ציפורי",
    "ציפורן",
    "קישון",
    "קרית ביאליק",
    "קרית שמריהו",
    "קרית שמריהו מזרח",
    "קרן היסוד",
    "קרן קיימת לישראל",
    "ראובן",
    "רחל",
    "רימון",
    "רקפות",
    "ש חסכון",
    "ש עמידר",
    "שבטי ישראל",
    "שביל הנרקיסים",
    "שביל הפרדס",
    "שד בן גוריון",
    "שד הבנים",
    "שד המייסדים",
    "שד הרצל",
    "שד ויצמן",
    'שד ח"ן',
    "שד ירושלים",
    "שד רבין",
    "שושנה דמרי",
    "שושנים",
    "שיכון רסקו",
    "שכ אפק",
    "שכ הפרפר",
    "שכ סביניה",
    "שלום עליכם",
    "שמעון",
    "שפרעם",
    "תבור",
    "תות",
    "תמר",
  ],
  "קרית גת": [
    "אביגיל הכרמלית",
    "אבץ",
    "אדוריים",
    "אדר",
    "אהוד בן גרא",
    "אודם",
    "אורון",
    "אזור המלאכה",
    "אחלמה",
    "איזמרגד",
    "אילון הזבולוני",
    "איתן נאוה",
    "אלול",
    "אלי כהן",
    "אליהו הנביא",
    "אלישע",
    "אלכסנדרוני",
    "אמציה",
    "אסא",
    "אסתר המלכה",
    "אפק",
    "אפרת",
    "אקסודוס",
    "אשור",
    "אשלגן",
    "באבא סאלי",
    'ביל"ו',
    "בית יוסף",
    "בעל שם טוב",
    "ברקת",
    "גאון הירדן",
    "גבעתי",
    'גדנ"ע',
    "גדעון בן יואש",
    "גולדה מאיר",
    "גולני",
    "גרופית",
    "דוד המלך",
    "דור",
    "דן",
    "דרך הדרום",
    "דרך החרושת",
    'האדמו"ר מחב"ד',
    "האורג",
    "האלה",
    "האפרסמון",
    "הארז",
    "הבונים",
    "הברוש",
    "הברזל",
    "הגבורה",
    "הגפן",
    "הדקל",
    "הוסטל עצמאות",
    "הזית",
    "החייל האלמוני",
    "החלוץ",
    "היוצר",
    "היסמין",
    "הכרמל",
    "הלוז",
    "הלילך",
    "המאבק",
    "המולדת",
    "המחתרות",
    "המלאכה",
    "המסגר",
    "המעפיל",
    "המעפיל האלמוני",
    "המרגלית",
    "המרי העברי",
    "הנורית",
    "הנרייטה סאלד",
    "הסחלב",
    "העבודה",
    "העיינות",
    "הערבה",
    "הפורצים",
    "הפלדה",
    "הפלוגה הדתית",
    "הפרדס",
    "הפרטיזנים",
    "הצבעוני",
    "הצדיק משטפנשט",
    "הציפורן",
    "הצנחנים",
    "הקוממיות",
    "הר ארדון",
    "הר בן יאיר",
    "הר גמלא",
    "הר חברון",
    "הר חזון",
    "הר מצדה",
    "הר עצמון",
    "הר פאר",
    "הר קדומים",
    "הרב עובדיה יוסף",
    "הרימון",
    "השומרון",
    "השיטה",
    "השקד",
    "השקמים",
    "התאנה",
    "התותחנים",
    "התמרים",
    "התקומה",
    "זהב",
    "זלדה שניאורסון",
    "זרחן",
    "חזקיהו",
    "חידקל",
    "חיל הנדסה",
    "חנה הנביאה",
    "חנה סנש",
    "חשון",
    "טופז",
    "טיילת הבוסתן",
    "טיילת כנען",
    "יאיר הגלעדי",
    "יהודה",
    "יהודה קן-דרור",
    "יהושפט",
    "יהלום",
    "יפתח הגלעדי",
    'יציאת תש"ז',
    "ישפה",
    'כ"ג',
    "ככר ברק",
    "ככר הגבורה",
    "ככר הירדן",
    "ככר המעפיל",
    "ככר הנגב",
    "ככר שאול המלך",
    "כסלו",
    "כסף",
    "לאה אמנו",
    "לאה גולדברג",
    "לשם",
    "מאיר פיינשטיין",
    "מבוא בנימין",
    "מבוא גבעון",
    "מבוא גלגל",
    "מבוא דביר",
    "מבוא החרמון",
    "מבוא הלוטם",
    "מבוא התבור",
    "מבוא חיל האויר",
    "מבוא ים",
    "מבוא ירח",
    "מבוא ישעיהו",
    "מבוא לבנה",
    "מבוא ליש",
    "מבוא מואב",
    'מבוא מטכ"ל',
    "מבוא מימן",
    "מבוא נוב",
    "מבוא ניסן",
    "מבוא סדן",
    "מבוא סיון",
    "מבוא עזקה",
    "מבוא עפרה",
    "מבוא פטיש",
    "מבוא קדם",
    "מבוא קדמה",
    "מבוא שניר",
    'מבוא תש"ח',
    "מבצע חורב",
    "מבצע חירם",
    "מבצע יואב",
    "מבצע נחשון",
    "מבצע עובדה",
    "מדינת היהודים",
    "מודיעין",
    "מיכה",
    "מירון",
    "מפרץ ים סוף",
    "מרים הנביאה",
    "מרכז מסחרי",
    "מרכז קליטה",
    "משה ברזני",
    "משמר הגבול",
    "משעול אהרון",
    "משעול אופיר",
    "משעול אפעה",
    "משעול אשר",
    "משעול גושן",
    "משעול גיחון",
    "משעול גלעד",
    "משעול גרר",
    "משעול גרשון",
    "משעול דקר",
    "משעול האיריס",
    'משעול האצ"ל',
    "משעול הארבל",
    "משעול האשכולית",
    "משעול האתרוג",
    "משעול הבשור",
    "משעול הגדוד העברי",
    "משעול הגלבוע",
    "משעול הדובדבן",
    "משעול הדישון",
    "משעול הדפנה",
    "משעול ההגנה",
    "משעול ההדרים",
    "משעול הורד",
    "משעול החבצלת",
    'משעול הלח"י',
    "משעול הלימון",
    "משעול המכתש",
    "משעול המעפיל",
    "משעול הסביון",
    "משעול הסייפן",
    "משעול העפלה",
    'משעול הפ"ז',
    'משעול הפלמ"ח',
    "משעול הפעמונית",
    "משעול השומר",
    "משעול השושן",
    "משעול התפוז",
    "משעול התפוח",
    "משעול זבולון",
    "משעול זוהר",
    "משעול חגי",
    "משעול חנוך",
    "משעול חצירה",
    "משעול חצרון",
    "משעול חתירה",
    "משעול יבוס",
    "משעול יהואש",
    "משעול יוסף",
    "משעול יעלים",
    "משעול כרמי",
    "משעול מנשה",
    "משעול סיירים",
    "משעול עינב",
    "משעול ערוגות",
    "משעול פארן",
    "משעול צאלים",
    "משעול צור",
    "משעול צין",
    "משעול צניפים",
    "משעול צפע",
    "משעול קישון",
    "משעול קמה",
    "משעול שבא",
    "משעול שמעון",
    "משעול שמשון",
    "משעול תנין",
    "נועם אלימלך",
    "נופך",
    'נח"ל',
    "נחל איילון",
    "נחל אלכסנדר",
    "נחל ארנון",
    "נחל אשכול",
    "נחל גוברין",
    "נחל דולב",
    "נחל דליות",
    "נחל זויתן",
    "נחל ירקון",
    "נחל נעמן",
    "נחל צאלים",
    "נחל קדרון",
    "נחל רביבים",
    'ניל"י',
    "נינוה",
    "נעמי שמר",
    "נפתלי",
    "נתיבות השלום",
    "נתן אלבז",
    "נתרן",
    "סטרומה",
    "סמ הכלנית",
    "ספיר",
    "עדולם",
    "עוזיהו",
    "עולי הגרדום",
    "עין עקרבים",
    "עציון גבר",
    "עתניאל בן קנז",
    "פארק תעשייה רבין",
    "פלטינה",
    "פקיעין",
    "פרת",
    "צורן",
    "ציקלג",
    "קרית גת",
    "ראובן",
    "רבקה אמנו",
    "רובע אתרי המקרא",
    "רובע בני ישראל",
    "רובע גליקסון",
    "רובע הבנים",
    "רובע ההעפלה",
    "רובע החורש",
    "רובע הנביאים",
    "רובע הפרחים",
    "רובע הקוממיות",
    "רובע השופטים",
    "רובע התעשיה",
    "רובע מגדים",
    "רובע מלכי ישראל",
    'רובע פ"ז',
    'רובע צה"ל',
    "רחבת איבצן",
    "רחבת אייר",
    "רחבת באר אורה",
    "רחבת גד",
    "רחבת האגס",
    "רחבת האדמונית",
    "רחבת האלמוגים",
    "רחבת הגביעונית",
    "רחבת הדבורנית",
    "רחבת הדונגית",
    "רחבת החלמונית",
    "רחבת הכרמית",
    "רחבת הר הזיתים",
    "רחבת הר הצופים",
    "רחבת הר סיני",
    "רחבת הרימונית",
    "רחבת חלוצה",
    "רחבת טבת",
    "רחבת יהל",
    "רחבת יותם",
    "רחבת ימין",
    "רחבת יעל",
    "רחבת ירמיהו",
    "רחבת יששכר",
    "רחבת מוריה",
    "רחבת ממשית",
    "רחבת ניצנה",
    "רחבת עבדת",
    "רחבת עין בוקק",
    "רחבת עין גדי",
    "רחבת עין הבשור",
    "רחבת עין המערה",
    "רחבת עין ירקעם",
    "רחבת עין מור",
    "רחבת עין נטפים",
    "רחבת עין עברונה",
    "רחבת עין צוקים",
    "רחבת עין תמר",
    "רחבת צופר",
    "רחבת קטורה",
    "רחבת רותם",
    "רחבת שאול המלך",
    "רחבת שזפון",
    "רחבת שיבת ציון",
    "רחבת תמנע",
    "רחבת תשרי",
    "רחל אמנו",
    "רמות דוד",
    "ש הזרע",
    'ש חב"ד',
    "ש רסקו",
    "שבו",
    "שבט",
    "שבטה",
    "שבטי ישראל",
    "שבעת המינים",
    "שד אגוז",
    "שד גת",
    "שד העצמאות",
    "שד השופטים",
    "שד יחזקאל",
    "שד לכיש",
    "שד מלכי ישראל",
    "שד פולק ישראל",
    'שד צה"ל',
    "שדרות אבני החושן",
    "שדרות אתרי המקרא",
    "שדרת אפרים",
    "שדרת גבורי ישראל",
    "שדרת דבורה",
    "שדרת הבשן",
    "שדרת הגולן",
    "שדרת המגדים",
    "שדרת הפרחים",
    "שדרת לוי",
    "שדרת משה",
    'שדרת פ"ז',
    "שוהם",
    "שונית",
    "שורק",
    "שיריון",
    "שכ רמות דוד",
    "שכ רפאל",
    "שכ שער הדרום",
    "שלמה אפשטיין",
    "שלמה המלך",
    "שמגר בן ענת",
    "שמואל הנביא",
    "שרה אהרונסון",
    "שרה אמנו",
    "תמוז",
    "תרשיש",
  ],
  "קרית טבעון": [
    "אורנים",
    "אזור תעשיה",
    "אלון יגאל",
    "אלונים",
    "אלעזר דוד",
    "אמנון ותמר",
    "אנפה",
    "אסבסטונים",
    "בורוכוב",
    "בן צבי",
    "בצל אורנים",
    "ברלב חיים",
    "ברק בן אבינועם",
    "גולומב",
    "גור מרדכי",
    "גורדון",
    "גן לאומי בית שערים",
    "דבורה הנביאה",
    "דגניות",
    "דוכיפת",
    "דולב",
    "דיין משה",
    "דרור",
    "דרך קרית עמל",
    "האיריס",
    "האלה",
    "הבונים",
    "הברושים",
    "הגומא",
    "הגפן",
    "הדס",
    "הדקל",
    "הורדים",
    "הזית",
    "החורש",
    "החרוב",
    "הכרכום",
    "הכרמל",
    "הליבנה",
    "המגדל",
    "המזרח",
    "המייסדים",
    "המעיין",
    "המרגניות",
    "הסחלב",
    "העליה",
    "העמק",
    "הצבעוני",
    "הציפורנים",
    "הרותם",
    "הרימונים",
    "הרצל",
    "השומרים",
    "השושנים",
    "השיטים",
    "השקדים",
    "התאנה",
    "התשבי",
    "ויצמן (חיים)",
    "זבולון",
    "זייד אלכסנדר",
    "חבצלת",
    "חוחית",
    "חנקין יהושע",
    "חצב",
    "חרצית",
    "יהודה הנשיא",
    "יזרעאל",
    "יסמין",
    "יערה",
    "יצחק שדה",
    "כיכר העליה",
    "ככר בן גוריון",
    "ככר הבנים",
    "ככר עלית הנוער",
    "ככר פטישי דב",
    "ככר קומפיין",
    "כלניות",
    "כצנלסון",
    "לילך",
    "מוסד אורים",
    "מורן",
    "מעלה אבשלום",
    "מעלה השיזף",
    "מרדכי (טננבוים)",
    "מרוה",
    "מרכז טבעון",
    "מרכז מסחרי חדש",
    "משעול הלוטם",
    "נוריות",
    "נחל קדומים",
    "נחליאלי",
    "נרקיסים",
    "סביון",
    "סולד הנריאטה",
    "סיגליות",
    "סייפן",
    "סימטת הגליל",
    "סמ אבשלום",
    "סמ האחים פיש",
    "סמ האלה",
    "סמ הפרגים",
    "סנונית",
    "סנש חנה",
    "סתוונית",
    "עורבני",
    "עירית",
    "עמלים",
    "ערבה",
    "קישון",
    "קרית חרושת",
    "קרית טבעון",
    "קרית עמל",
    "קרן קיימת",
    "קרת",
    "רבין יצחק",
    "רכבת העמק",
    "רמז",
    "רמת הדסה",
    "רמת טבעון",
    "רקפות",
    "ש אילה",
    "ש אקדמאים",
    "ש מומחים",
    "ש עולים",
    "ש עמלים",
    "ש קרית טבעון",
    "שכ אלרואי",
    "שכ מעלה טבעון",
    "שלום עליכם",
    "שפרינצק",
    "שקמה",
    "שרת משה",
    "תמר",
    "תנועות הנוער",
  ],
  "קרית ים": [
    "אביר יעקב",
    "אגוזים",
    "אהבת ציון",
    "אורן",
    "אזור תעשיה",
    "אחד העם",
    "אילנות",
    "אילת",
    "אירוס",
    "אלון",
    "אלי כהן",
    "אפרים",
    'אצ"ל',
    "ארזים",
    "ארן זלמן",
    "אשל",
    "אשר",
    "אתרוג",
    "בובר מרטין",
    "בוזגלו דוד",
    "בורוכוב",
    'ביל"ו',
    "בית סרז'נטים",
    "בליטי מאיר",
    "בנימין",
    "בר יהודה",
    "ברדיצ'בסקי",
    "ברזילי ישראל",
    "גד",
    "גולדה מאיר",
    "גולומב אליהו",
    "גולן",
    "גורדון",
    "גלבוע",
    "גלעד",
    "גן סולד",
    "גפן",
    "דגנית",
    "דוד המלך",
    "דולב",
    "דן",
    "דקל",
    "הברוש",
    "הבשור",
    "הגליל",
    "הגעתון",
    "הדס",
    "ההגנה",
    "הורד",
    "הזית",
    "החשמונאים",
    "הירדן",
    "המחתרת",
    "המייסדים",
    "המעפילים",
    "הנגב",
    "הנוטרים",
    "העליה",
    "העצמאות",
    "הערבה",
    "הקוממיות",
    "הראשונים",
    "הרב משאש יוסף",
    "הרדוף",
    "השופטים",
    "ז'בוטינסקי",
    "זבולון",
    "חבצלת",
    "חצב",
    "חרובים",
    "טרומפלדור",
    "טשרניחובסקי",
    "יהודה",
    "יהודה הלוי",
    "יוסף",
    "יוספטל",
    "יחיעם",
    "יסמין",
    "יקינתון",
    "ישעיהו ישראל",
    "יששכר",
    "ככר לוי אשכול",
    "כלנית",
    "כצנלסון",
    "לבון פנחס",
    "להמן הרברט",
    "לוחמי הגטאות",
    "לוטם",
    "לוי",
    'לח"י',
    "לילך",
    "לכיש",
    "מגידו",
    "מודיעין",
    "מוהליבר",
    "מורן",
    "מיכל",
    "מירון",
    "מנדלי מוכר ספרים",
    "מנשה",
    "מצדה",
    "מרגנית",
    "מרכז קליטה",
    'ניל"י',
    "נמיר מרדכי",
    "נמרי יעקב",
    "נעמן",
    "נפתלי",
    "נרקיס",
    "סביון",
    "סביוני ים",
    "סולד הנריאטה",
    "סולד רוברט",
    "סוקולוב",
    "סטרומה",
    "סיגלית",
    "סמ הקוממיות",
    "סמ סביון",
    "סנש חנה",
    "ספיר פנחס",
    "עגנון",
    "עדולם",
    "עציון",
    "ערמונים",
    "פינסקר",
    "פנקס",
    "פסגות ים",
    "פרישמן",
    "פרץ",
    "צאלון",
    'צה"ל',
    "ציפורן",
    "קפלן",
    "קרית ים",
    "קרן קימת",
    "ראובן",
    "רימון",
    'רמב"ם',
    "רקפת",
    "ש אגש",
    "ש אזבסטונים",
    "ש אלמוגים",
    "ש גליה",
    "ש חדש",
    "ש טיבר עמידר",
    "ש עמידר",
    "ש צבא קבע",
    "ש קליפורניה",
    "שבזי",
    "שבטי ישראל",
    "שד אלון יגאל",
    "שד ביאליק",
    "שד בן צבי",
    "שד ההסתדרות",
    'שד הפלמ"ח',
    "שד הרצל",
    "שד ויצמן",
    "שד ירושלים",
    "שדה יצחק",
    'שז"ר זלמן',
    "שטח שרון",
    "שיקמים",
    "שכ א'",
    "שכ בנה ביתך",
    "שכונה ד",
    "שמעון",
    "שפירא משה",
    "שפרינצק",
    "שקד",
    "שרת משה",
    "תאנה",
    "תבור",
    "תמר",
  ],
  "קרית יערים": [
    "אבינדב",
    "אזנים לתורה",
    "גורדון",
    "דרך אברהם",
    "הבעל שם טוב",
    "הגפן",
    'הגר"א',
    "הדגן",
    "הזית",
    "הרב בלוך",
    'הרי"ף',
    "הרימון",
    "התאנה",
    "התמר",
    "יעקב",
    "יצחק",
    "ירושלים",
    "מנחת שלמה",
    "מעלה יוסף",
    "מעלות דוד",
    "מעלות חיים",
    "מעלות קדושי טעלז",
    "משעול דוד",
    'משעול הרי"ף',
    "משעול מנשה",
    "נווה אליעזר",
    "נוף יערים",
    "נחלת יעקב",
    "קרית יערים",
    "שכ אחוזת יערים",
    "שכ מבואות הבירה",
    "שכ נחלת יעקב",
  ],
  "קרית יערים(מוסד)": ["קרית יערים(מוסד)"],
  "קרית מוצקין": [
    "אהרון",
    "אוסישקין",
    "אושה",
    'אח"י אילת',
    "אילון הזבולוני",
    "אירוס",
    "אלון יגאל",
    "אלמוגי יוסף",
    "אלעזר דוד",
    "אנילביץ מרדכי",
    "אשכול לוי",
    "את כורדני ב'",
    "בגין מנחם",
    "בן צבי",
    "בנאי יוסי",
    "בר אילן",
    "ברק",
    "בתי השבים",
    "גורדון",
    "גן העצמאות",
    "גרושקביץ",
    "דבורה",
    "דוד המלך",
    "דולצ'ין אריה",
    "דליה",
    "דקר",
    "דרך עכו",
    "האוזנר גדעון",
    "האלה",
    "הבנים",
    "הברוש",
    "הגדוד העברי",
    "הגולן",
    "הדס",
    "ההגנה",
    "הזית",
    "החשמונאים",
    "הילדים",
    "הירדן",
    "המגינים",
    "המעפילים",
    "המר זבולון",
    "הסביון",
    "העליה",
    "הערבה",
    "הרב הרצוג",
    "הרב לוי יוסף",
    "הרב עוזיאל",
    "הרב קוק",
    "הרקפת",
    "ורד",
    "ז'בוטינסקי",
    "חזני מיכאל",
    "חיבת ציון",
    "חיטמן עוזי",
    "טרומפלדור",
    "יבנה",
    "יהודה הלוי",
    "יודפת",
    "יוסף שילוח",
    "יוספטל",
    "יעל",
    "ירושלים",
    "ירקוני יפה",
    "יששכר",
    "כלנית",
    "כצנלסון",
    "לאה גולדברג",
    "לביא אריק",
    "מודיעין",
    "מוטה גור",
    "מורדי הגטאות",
    "מכבי",
    "מנור אהוד",
    "מנחם",
    "מנשה קדישמן",
    "מסדה",
    "משוררים",
    "נווה גנים",
    "נחום גוטמן",
    'ניל"י',
    "נרקיס",
    "נתיב רמז",
    "נתניהו יונתן",
    "סמ אורן",
    "סמ ארז",
    "סמ אתרוג",
    'סמ בית"ר',
    "סמ גלעד",
    "סמ דקל",
    'סמ הפלמ"ח',
    "סמ הקרב",
    "סמ תמר",
    "סמטת הסופרים",
    "סנש חנה",
    "סעדיה גאון",
    "ספיר פנחס",
    "עופרה חזה",
    "עין גדי",
    "עלוני נח",
    "פוגלמן מרדכי",
    "פז",
    "צאלון",
    'צה"ל',
    "ציגל אברהם",
    "ציפורן",
    "קדיש לוז",
    "קורצ'אק",
    "קליבנוב",
    "קרית מוצקין",
    "רוקח",
    'רמב"ם',
    'רנ"ס',
    'רש"י',
    "ש אגש",
    "ש דירות עם א",
    "ש דירות עם ב",
    "ש מומחים",
    "ש פנטגון",
    "ש צכי",
    "ש רומבואיד",
    "ש רסקו",
    'ש"י עגנון',
    "שביל הגפן",
    "שביל הרימון",
    "שד בן גוריון",
    "שד גושן משה",
    "שד ויצמן",
    "שד קרן קימת",
    "שדה יצחק",
    "שושנה דמארי",
    'שז"ר זלמן',
    "שכ אביבים",
    "שכ אזבסטונים",
    "שכ מוצקין הצעירה",
    "שכ צפון מוצקין",
    "שלום שבזי",
    "שמר נעמי",
    "שפרינצק",
    "שרת משה",
    "תדהר",
    "תמרי נחמיה",
  ],
  "קרית מלאכי": [
    "אבוקדו",
    "אבירי לב",
    "אגוז",
    "אגס",
    "אדם יקותיאל",
    "אהובה עוזרי",
    "אופרה",
    "אזור התעשיה",
    "אזור התעשייה",
    "אילת",
    "איריס",
    "אלון יגאל",
    "אלעזר דוד",
    "אננס",
    "אנפה",
    "אסבסטונים",
    "אפרסמון",
    "אפרסק",
    "אשכול לוי",
    "אשכולית",
    "אתרוג",
    "בגין מנחם",
    "בורג יוסף",
    "בן אהרון אליהו",
    "בן צבי יצחק",
    "בר יהודה",
    "גובר רבקה ומרדכי",
    "גולדה מאיר",
    "גולן",
    "גור מרדכי",
    "גושן",
    "גני שמואל",
    "גרונר דב",
    "דובדבן",
    "דוד אלבג' עבודי",
    "דוכיפת",
    "דולצ'ין אריה",
    "דורי יעקב",
    "דיה",
    "דיין משה",
    "דרור",
    "דרך יגאל הורביץ",
    "האומן",
    "הבאר",
    "הבשור",
    "הגליל",
    "הגפן",
    "הורדים",
    "הזית",
    "החרושת",
    "היוזמה",
    "היוצר",
    "הירדן",
    "הכרמל",
    'המ"ג',
    "המלאכה",
    "הנגב",
    "העליה",
    "העמל",
    "העמק",
    "הערבה",
    "הציפורן",
    "הקידמה",
    "הרב אבו חצירא",
    "הרב איפרגן",
    "הרב גורן שלמה",
    "הרב יורם אברג'יל",
    "הרב ניסים יצחק",
    "הרב עובדיה יוסף",
    "הרב עוזיאל",
    "הרב פינטו חיים",
    "הרבי מליובאוויטש",
    "הרדוף",
    "הרצוג חיים",
    "הרצל",
    "השומרון",
    "השושנים",
    "השפלה",
    "התאנה",
    "התומר",
    "ויצמן",
    "ורדימון",
    "ז'בוטינסקי",
    "חבוש",
    "חומת מגן",
    "חלוצי התעשיה",
    "חסידה",
    "ידין יגאל",
    "יודפת",
    "יונה",
    "יסמין",
    "יקינטון",
    "כהן אלי",
    "ככר הבנים",
    "ככר הבעל שם טוב",
    "ככר הנשיאים",
    "כלנית",
    "כנען",
    "לב הקריה",
    "לוי יצחק שניאורסאהן",
    "לימון",
    "לסקוב חיים",
    "מאיר עזרא",
    "מבצע יונתן",
    "מבצע ליטני",
    "מבצע סבנה",
    "מבצע שלמה",
    "מגידו",
    "מלמיסון ריאל",
    "מנגו",
    "מצדה",
    "מרכז הקליטה",
    "משמש",
    "נווה אור",
    "נחליאלי",
    'נחלת הר חב"ד',
    "נרקיס",
    "נתניהו יונתן",
    "סאן דייגו",
    "סוויסה אברהם",
    "סנש חנה",
    "ספיר פנחס",
    "ספרא",
    "עגור",
    "עזרא ונחמיה",
    "ענבי זעם",
    "פומלה",
    "פקאן",
    "פקר",
    "צור צבי",
    "צימוקי אריה",
    "קיווי",
    "קלמנטינה",
    "קרית מלאכי",
    "רזיאל דוד",
    "רימון",
    'רמב"ם',
    "רקפת",
    'רש"י',
    "ש בן גוריון",
    "ש המייסדים",
    "ש הנרקיסים",
    "ש וייצמן",
    "ש זוגות צעירים",
    "ש חדש",
    "ש חסכון",
    "ש יגאל אלון",
    "ש צבא קבע",
    "שבזי",
    "שד אריק שרון",
    "שד בן גוריון",
    "שד ירושלים",
    "שד קרן הידידות",
    "שד' קק\"ל",
    "שדה יצחק",
    'שז"ר',
    "שזיף",
    "שחף",
    "שכ גן",
    'שכ המ"ג',
    "שכ הרצל",
    "שכ יגר",
    'שכ נחלת הר חב"ד',
    "שכ קיבוץ גלויות",
    "שלדג",
    "שליו",
    "שמעון פרס",
    "שסק",
    "שקד",
    "שרת משה",
    "תות",
    "תמר",
    "תמרי",
    "תפוז",
    "תפוח",
  ],
  "קרית נטפים": ["נופי הדר", "עלומים", "קרית נטפים"],
  "קרית ענבים": ["קרית ענבים"],
  "קרית עקרון": [
    'א"ת מסחר עקרון 2000',
    "אגמיה",
    "אודם",
    "אחד העם",
    "אלי כהן",
    "אנפה",
    "בוסי סנט ג'ורג'",
    'ביל"ו סנטר',
    "בעלי מלאכה",
    "בר חיים יהודה",
    "ברקת",
    "גהלי יעקב",
    "גולני",
    "גני חן",
    "דובדבן",
    "דולב",
    "דרך ירושלים",
    "האבות",
    "האגוז",
    "האירוס",
    "האלון",
    "הבנים",
    "הברוש",
    "הגפן",
    "הדס",
    "הדקל",
    "הזית",
    "החבצלת",
    "היהלום",
    "הלבונה",
    "הלוטם",
    "הלוי דוד",
    "המייסדים",
    "הנורית",
    "הסביונים",
    "העופרים",
    "הפנינים",
    "הצנחנים",
    "הרב דוד יוסף מחלי",
    "הרב מימון",
    "הרב עובדיה יעבץ",
    "הרב עובדיה לוי",
    'הרמב"ם',
    "הרצל",
    "השיטה",
    "השקד",
    "התאנה",
    "התמר",
    "ויצמן",
    "חופית",
    "חסן מלך מרוקו השני",
    "חרוב",
    "חרצית",
    "טופז",
    "יורדי הסירה",
    "יעקב כהן",
    "יערה",
    "כובשי החרמון",
    "כיכר החינוך",
    "כיכר הידע",
    "כיכר הצדק",
    "כיכר הרעות",
    "כלנית",
    "לוחמי הגטאות",
    "מאיר בעל הנס",
    "מדעי שלום",
    "מורן",
    "מרבד הקסמים",
    "נאות אביבים",
    "נאות הדרים",
    "נווה אשכול",
    "נווה יוסף",
    "נופר",
    "נרקיס",
    "סלעית",
    "סנונית",
    "ספיר",
    "עמיחי",
    "עפרוני",
    "צדוק הלוי",
    "צופית",
    "קהילת אוהיו",
    "קרית עקרון",
    "קרן היסוד",
    "רח 10",
    "רח 14",
    "רקפת",
    "ש ההסתדרות",
    "ש ורבורג",
    "ש טרומיים",
    "ש נוה עובד",
    "ש עמידר צריפים",
    "ש צריפים",
    "ש צריפים אדומים",
    "ש קרן היסוד",
    "שבזי",
    "שד יצחק רבין",
    "שוהם",
    "שחף",
    "שחפית",
    "שטרן יאיר",
    "שיבת ציון",
    "שיזף",
    "שיחנית",
    "שכ בתים נטושים",
    "שכ יחד",
    "שכ נווה אשכול",
    "שכ שבת אחים",
    "שלום חנש",
    "שלמה בן יוסף",
    "שלמה דרף",
    "שקמה",
    "שרת משה",
  ],
  "קרית שלמה": ["קרית שלמה"],
  "קרית שמונה": [
    "אדם יקותיאל",
    "אוחנה שמואל",
    "אורי אילן",
    "אזור תעשיה דרום",
    "אזור תעשיה צפון",
    'אח"י אילת',
    "אחד העם",
    "אירוס",
    "ארלוזורוב",
    "ארנון",
    "בבא סאלי",
    "בגין מנחם",
    "ביאליק",
    "ברזילי ישראל",
    "ברל",
    "ברנר",
    "גבעת שחומית",
    "דן",
    "דן דיין",
    "הבזלת",
    "הבנים",
    "הברונזה",
    "הברזל",
    "הגלעד",
    "הגנה",
    "הגרניט",
    "הדישון",
    "הוורדים",
    "החולה",
    "הירדן",
    "הירקון",
    "הלבנון",
    "המחתרת",
    "המכבים",
    "הנגב",
    "הנחושת",
    "הנשיא",
    "העופרת",
    "הפלדה",
    "הר אביטל",
    "הר אדיר",
    "הר אדמון",
    "הר ארבל",
    "הר גילה",
    "הר הכרמל",
    "הר המוריה",
    "הר הצופים",
    "הר התבור",
    "הר חזון",
    "הר חרמון",
    "הר כנען",
    "הר מצדה",
    "הר ציון",
    "הר שגיא",
    "הרב הרצוג",
    "הרב מימון משה",
    "הרב משאש יוסף",
    "הרב קוק",
    "הרי אפרים",
    "הרי גלבוע",
    "הרי יהודה",
    "הרצל",
    "השופטים",
    "חבצלת",
    "חומה ומגדל",
    "חצב",
    "חרצית",
    "טרומפלדור",
    "טשרניחובסקי",
    "יגר יוסף",
    "יהודה הלוי",
    "יסמין",
    "ככר ההסתדרות",
    "ככר צהל",
    "כלנית",
    "כנרת",
    "מבוא אמנה",
    'מבוא הרצי"ה',
    "מורדות נפתלי",
    "מלול יעקב",
    "מלון הצפון",
    "מלון פגודה",
    "מלחמת ששת הימים",
    "מלכה",
    "מעלה יאיר",
    "מקורות",
    "מרגנית",
    "מרכז העיר",
    "משמר הגבול",
    "נוף חרמון",
    "נורית",
    "נרקיס",
    "סביון",
    "סולד",
    "סיני",
    "סלומונוביץ",
    "סלינגר דוד",
    "סמ החרמון",
    "סמ המלכים",
    "סמ הנביאים",
    "סמ הנוטר",
    "סמ הצוקים",
    "סמ הרי נפתלי",
    "סנש",
    "עגנון",
    "עולי הגרדום",
    "עין זהב",
    "עירית",
    "עלית הנוער",
    "עקיבא",
    "פרי אליעזר",
    "צמרת הגליל",
    "קרית שמונה",
    "קרן היסוד",
    "קרן קימת לישראל",
    "רזיאל",
    'רמב"ם',
    'רש"י',
    "ש אשכול",
    "ש מודרגים",
    "ש מזרח",
    "ש רכבת",
    "ש שרת",
    'שד האצ"ל',
    "שד יובל יניב",
    "שד תל חי",
    "שדה יצחק",
    'שכ אצ"ל',
    "שכ בנה ביתך",
    "שכ הורדים",
    "שכ ז'בוטינסקי",
    "שכ יגאל אלון",
    "שכ משה חיים שפירא",
    "שכ נוף חרמון",
    "שכון א",
    "שכון ב",
    "שכון ג",
    "שכון ד",
    "שכון ו",
    "שמעוני דוד",
    "שפרינצק",
  ],
  "קרני שומרון": [
    "אלוני שילה",
    "אלישיב",
    "ארגוני הלוחמים",
    "גינות שומרון",
    "האבוב",
    "האורנים",
    "האילנות",
    "האלון",
    "האמונה",
    'האצ"ל',
    "הארז",
    "הברוש",
    "הגבורה",
    "הגיתית",
    "הגלעד",
    "הגפן",
    "הדקל",
    "ההדס",
    "ההתנחלות",
    "הזכות",
    "החלוצים",
    "החרוב",
    "המייסדים",
    "הנבל",
    "הנוטעים",
    "הנחל",
    "הניצנים",
    "הנרקיס",
    "העוגב",
    "העוז",
    "העמל",
    "הערבה",
    "הפעמון",
    "הצלצלים",
    "הקוממיות",
    "הראשונים",
    "הרימון",
    "השקמה",
    "התוף",
    "התמר",
    "התקוה",
    "חומה ומגדל",
    "חלילים",
    "יפה נוף",
    "ישיבת הסדר",
    "כיסופים",
    "כלי שיר",
    'לח"י',
    "מעלות קדושים",
    "משעול הורדים",
    "משעול החבצלת",
    "משעול החצב",
    "משעול החרצית",
    "משעול היסמין",
    "משעול הכלנית",
    "משעול הסביון",
    "משעול הצבעוני",
    "משעול הרדוף",
    "משעול הרקפת",
    "משעול התאנה",
    "נוה עליזה",
    "נווה מנחם",
    'ניל"י',
    "סמ הכנורות",
    "סמ המצילתיים",
    "סמ הענבלים",
    "סמ השלישים",
    "צופים",
    "קבוץ גלויות",
    "קנין הארץ",
    "קרני שומרון",
    "רחבת אסירי ציון",
    "שביל החצוצרה",
    "שביל השופר",
    "שדרות רחבעם",
    "שיבת ציון",
  ],
  קשת: ["קשת"],
  ראמה: [
    "אבן סינא",
    "אלאימאן",
    "אלאמל",
    "אלאסד",
    "אלארז",
    "אלבאבור",
    "אלבורג'",
    "אלביאן",
    "אלביידר",
    "אלבלאט",
    "אלבלד",
    "אלבסאתין",
    "אלג'בל",
    "אלג'ורי",
    "אלדהב",
    "אלדורי",
    "אלואדי",
    "אלורוד",
    "אלזהור",
    "אלזקאק",
    "אלח'זאן",
    "אלח'לוה",
    "אלח'רוב",
    "אלחארה",
    "אלחורה",
    "אלחנאן",
    "אלחסון",
    "אלחצן",
    "אליאסמין",
    "אלכותלה",
    "אלכנאר",
    "אלכרום",
    "אלמאס",
    "אלמג'ארה",
    "אלמג'ד",
    "אלמג'לס",
    "אלמחטה",
    "אלמטל",
    "אלמידאן",
    "אלמיזאן",
    "אלמילאד",
    "אלמלעב",
    "אלמנאר",
    "אלמנטקה אלסנאעיה",
    "אלמסג'ד",
    "אלמסיל",
    "אלמסרה",
    "אלמפארק",
    "אלמקאלי",
    "אלמראח",
    "אלמראם",
    "אלמרג'",
    "אלנאורס",
    "אלנבעה",
    "אלנג'אר",
    "אלנג'ום",
    "אלנואר",
    "אלנור",
    "אלנח'יל",
    "אלנמר",
    "אלנסר",
    "אלנענע",
    "אלנרג'ס",
    "אלסאפי",
    "אלסאקיה",
    "אלסביל",
    "אלסואן",
    "אלסובאט",
    "אלסועוד",
    "אלסופסאף",
    "אלסלאם",
    "אלסנדיאן",
    "אלסנובר",
    "אלסעאדה",
    "אלעדל",
    "אלענדליב",
    "אלפארוק",
    "אלפורסאן",
    "אלפרח",
    "אלקלעה",
    "אלקנאיאת",
    "אלקסטל",
    "אלקסר",
    "אלראביה",
    "אלרוכאם",
    "אלרומאן",
    "אלריאד",
    "אלריחאן",
    "אלשביבה",
    "אלשהד",
    "אלתמר",
    "אלתסאמח",
    "אלתקסים",
    "באט אלמוג'ר",
    "בית אלשעב",
    "בלסם",
    "בראעם",
    "דיר אלאתין",
    "חיידר",
    "חכים",
    "כניסיה קתולית",
    "כניסת אלרום",
    "נאטור",
    "נבע סעיד",
    "סולטאן באשא",
    "עין אלאסראר",
    "עין אלג'זירה",
    "עין אלחודין",
    "פאנוס",
    "קביסה",
    "קרואן",
    "ראמה",
    "רואבי",
  ],
  "ראס אל-עין": ["ראס אל-עין"],
  "ראס עלי": ["ראס עלי"],
  "ראש העין": [
    "אביבים",
    "אביטל",
    "אבן גבירול",
    "אבן עזרא",
    'אבצ"ן',
    "אגוז",
    "אגמית",
    "אגס",
    "אדיר",
    "אדמונית",
    "אהלי קידר",
    "אוג",
    "אודם",
    "אורי ליפשיץ",
    "אוריהו הנביא",
    "אורן",
    "אזור תעשיה",
    "אחוה",
    "אחיה השילוני",
    "איה",
    "איילון",
    "אילות",
    "אילנות",
    "אירוס",
    "אלברט איינשטיין",
    "אלה",
    "אלון",
    "אלוף הרב גורן",
    "אליהו הנביא",
    "אלישע הנביא",
    "אלמוג",
    "אלשייך רחמים",
    "אמנון ותמר",
    "אנפה",
    "אעלה בתמר",
    "אפיק",
    "אפק",
    "אפרת",
    'אצ"ל',
    "ארבל",
    "ארז",
    "ארץ ישראל השלמה",
    "אשחר",
    "אשכולית",
    "אשל",
    "אתרוג",
    "בגין עליזה",
    "בזלת",
    "בזק",
    "בן נון יהושע",
    "בעל מלאכת שלמה",
    "ברבור",
    "ברוש",
    "ברכה צפירה",
    "ברק",
    "ברקן",
    "ברקת",
    "בשן",
    "בת שבע דה רוטשילד",
    "ג'ון קנדי",
    "גבורי ישראל",
    "גבעת הסלעים",
    "גבעתי",
    "גבתון",
    "גדרון",
    "גולדה מאיר",
    "גזית",
    "גלוסקא",
    "גלעד",
    "גני אפק",
    "גני טל",
    "גפן",
    "גרונר דב",
    "גרטי קורי",
    "גרטרוד עליון",
    "גרמה מיכאל",
    "גרניט",
    "דבורה הנביאה",
    "דוד המלך",
    "דוכיפת",
    "דוש קריאל גרדוש",
    "דייה",
    "דיין משה",
    "דליה רביקוביץ",
    "דליות",
    "דם המכבים",
    "דן",
    "דפנה",
    "דקר",
    "דרגות",
    "דרך בגין מנחם",
    "דרך הציונות",
    "דרך השומרון",
    "הא באייר",
    "הבנים",
    "הבקעה",
    "הבשור",
    "הגליל",
    'הגר"א',
    "הדודאים",
    "הדס",
    "הדרור",
    "הושע הנביא",
    "הזמיר",
    "החורשים",
    "החלוץ",
    "החליל",
    "החמישה",
    "החשמונאים",
    "הטייס",
    "היצירה",
    "הירקון",
    "הלבונה",
    'הלח"י',
    "המוביל הארצי",
    "המילדת",
    "המכתש",
    "המלאכה",
    "המסילה",
    "המעפיל",
    "המצפה",
    "המרץ",
    "המתמיד",
    "הנביאים",
    "הסופר",
    "הסלע",
    "הסנהדרין",
    "העבודה",
    "העורב",
    "העליה",
    "העצמאות",
    "הפרחים",
    "הצנחנים",
    "הקטר",
    "הר המור",
    "הראשונים",
    "הרב ונה יצחק",
    "הרב חג'בי דוד",
    "הרב יצהרי צדוק",
    "הרב כלף",
    "הרב עובדיה יוסף",
    "הרב שלום מנצורה",
    "הרברט סיימון",
    "הרכבת",
    "הרעות",
    "הרצל",
    'הרש"ש',
    "השפלה",
    "השרון",
    "השריון",
    "התנאים",
    "התעשייה",
    "התקוה",
    "וגנר",
    "וולפסון",
    "ורד",
    "ורדית",
    "ז'בוטינסקי זאב",
    "זהרה אלפסיה",
    "זוהר",
    "זיו",
    "זיקו גרציאני",
    "זית",
    "זכריה הנביא",
    "זכריה משה",
    "זרזיר",
    "זרעית",
    "חבקוק הנביא",
    "חגי הנביא",
    "חוגלה",
    "חוחית",
    "חופית",
    "חותמי המגילה",
    "חזון איש",
    "חטיבת אלכסנדרוני",
    "חיים הרצוג",
    "חיים וייצמן",
    "חלמיש",
    "חנה רובינא",
    "חנוך לוין",
    "חסידה",
    "חפץ חיים",
    "חצב",
    "חרוב",
    "חרמון",
    "חרסית",
    "חרצית",
    "חתוכה יורם",
    "טללים",
    "יבניאלי",
    "יגאל אלון",
    "יהודה",
    "יהודה הלוי",
    "יהלום",
    "יואל הנביא",
    "יונה",
    "יונה הנביא",
    "יונה וולך",
    "יונתן רטוש",
    "יוסי בנאי",
    "יחזקאל הנביא",
    "יחיא יצחק הלוי",
    "יסמין",
    "יסעור",
    "יערה",
    "יפה ירקוני",
    "יקינטון",
    "ירגזי",
    "ירדן",
    "ירושלים השלמה",
    "יריעות שלמה",
    "ירמוך",
    "ירמיהו הנביא",
    "ישעיהו הנביא",
    "כחל",
    "כיכר האקורדיון",
    "כיכר החצוצרה",
    "כיכר הפסנתר",
    "כיכר הפעמון",
    "כיכר הצ'לו",
    "כיכר התווים",
    "כיכר שוטר חיים רדמי",
    "כיכר תוף מרים",
    "כינור",
    "ככר אלי כהן",
    "כלנית",
    "כנפי נשרים",
    "כפיר",
    "כרכום",
    "כרמל",
    "לאה גולדברג",
    "לביא",
    "לילך",
    "לימון",
    "לכיש",
    "לשם",
    "מבצע דני",
    "מבצע הראל",
    "מבצע חירם",
    "מבצע נחשון",
    "מבצע עובדה",
    "מגדים",
    "מגדל דוד",
    "מגידו",
    'מהרי"ץ',
    'מהריק"א',
    "מודיעין",
    "מוזס עפרה",
    "מורן",
    "מחנה א",
    "מחנה ב",
    "מחנה ג",
    "מיכה הנביא",
    "מירון",
    "מישר",
    "מיתר",
    "מלאכי יוסף",
    "מלווין קלווין",
    "מלכי ישראל",
    "מנור",
    "מעודה שלמה",
    "מעיין",
    "מצדה",
    "מצפה אפק",
    "מרבד הקסמים",
    "מרגנית",
    "מרים הנביאה",
    "מרים ילן שטקליס",
    "משעול הרב מלמד שלמה",
    "משעול הרב שלמה מדר",
    "משעול עובדיה גמליאל",
    "משעול עלי (הכהן)",
    "נגב",
    "נדין גורדימר",
    "נווה אפיקים",
    "נווה אפק",
    "נחום גוטמן",
    "נחום הנביא",
    "נחל עירון",
    "נחל ערוגות",
    "נחל קישון",
    "נחל רבה",
    "נחליאלי",
    "נחשול",
    "נילס בוהר",
    "ניסים אלוני",
    "נלי זקס",
    "נעמי שמר",
    "נעמן",
    "נץ",
    "נקר",
    "נשר",
    "נתן אלתרמן",
    "נתן הנביא",
    "נתן יונתן",
    "נתן שאול",
    "סביון",
    "סיגלית",
    "סייפן",
    "סיני",
    "סיתונית",
    "סלעית",
    "סמדר",
    "סנונית",
    "סעדיה גאון",
    "סער",
    "ספיר יעקב",
    "עגור",
    "עדולם",
    "עובדיה הנביא",
    "עוגב",
    "עודד",
    "עוזי חיטמן",
    "עופרה חזה",
    "עורבני",
    "עין גדי",
    "עירית",
    "עלית הנוער",
    "עמוס הנביא",
    "עמוסי מנחם",
    "עמל",
    "עמרני אברהם",
    "ענבר",
    "עפרוני",
    "ערבה",
    "פארן",
    "פארק תעשיה אפק",
    "פול סמואלסון",
    "פולג",
    "פלג",
    "פלח הלימון",
    "פעמון",
    "צאלון",
    "צבעוני",
    "צדק",
    'צה"ל',
    "צופית",
    "צוקית",
    "צור",
    "צליל",
    "צפניה הנביא",
    "צרור המור",
    "קבוץ גלויות",
    "קהילות בני ישראל",
    "קוממיות",
    "קרל לנדשטיינר",
    "קרן",
    "קשת",
    "קתרוס",
    "ראובן רובין",
    "ראש העין",
    "רבי אביץ יחיא",
    "רבי עקיבא",
    "רביבים",
    "רוזלין יאלו",
    "רחל המשוררת",
    "ריחן",
    "ריטה לוי מונטצ'ינו",
    "רימון",
    'רמב"ם',
    "רמת הגולן",
    "רקפת",
    'רש"י',
    "ש חסכון",
    "ש עממי",
    'ש"י עגנון',
    "שבזי",
    "שבטי ישראל",
    "שגיא",
    "שדרות בן גוריון",
    "שדרת עפרוני",
    "שוהם",
    "שורק",
    "שושן",
    "שושנה דמארי",
    "שושנת העמקים",
    "שחף",
    "שיזף",
    "שייקה אופיר",
    "שילה",
    "שיר השירים",
    "שכ אביב",
    "שכ הרקפות",
    "שכ נתן",
    'שכ צה"ל',
    'שכ רמב"ם',
    "שכ שבזי",
    "שכון א",
    "שכון ב",
    "שכון ג",
    "שכון ד",
    "שכון ה",
    "שכון ו",
    "שלדג",
    "שלום זכריה",
    "שלום צברי",
    "שלמה המלך",
    "שמואל הנביא",
    "שמעיה הנביא",
    "שסק",
    "שקד",
    "שקמה",
    "שרה לוי תנאי",
    "שרקרק",
    "ששת הימים",
    "תאנה",
    "תבור",
    "תור",
    "תורי זהב",
    "תות",
    "תמר",
    "תענך",
    "תפוח",
    "תרצה אתר",
    "תרשיש",
  ],
  "ראש פינה": [
    "אזור תעשיה",
    "ברנשטיין",
    "דרך בן אריה",
    "דרך הבוסתנים",
    "דרך הגליל",
    "דרך החלמוניות",
    "דרך הטחנה",
    "דרך היער",
    "דרך היקב",
    "דרך המרכז",
    "דרך הרהט",
    "דרך עתיקה",
    "האגס",
    "האורנים",
    "האירוס",
    "האלה",
    "האלון",
    "האפרסק",
    "הברוש",
    "הברושים",
    "הגרנות",
    "הדקל",
    "הדרך הטורקית",
    "הורד",
    "הזיתים",
    "החורש",
    "החלוצים",
    "החלמית",
    "החצב",
    "החקלאי",
    "החרוב",
    "החרצית",
    "הכלנית",
    "הנדיב",
    "הנורית",
    "הנרקיס",
    "העליה",
    "הצבר",
    "הראשונים",
    "הרדוף",
    "הרחבה חקלאית",
    "הרחוב העליון",
    "הרימונים",
    "הרקפת",
    "השזיף",
    "השיטה",
    "השלום",
    "התורמוס",
    "התמרים",
    "התפוח",
    "חן",
    "מעלה בנימין",
    "מעלה גיא אוני",
    "מעלה כנען",
    "משעול גולן",
    "משעול גונן",
    "משעול האקליפטוס",
    "משעול התאנה",
    "ניסנבוים",
    "סולד הנריאטה",
    "סמ גלילי",
    "פטר פביאן",
    "פני גולן",
    "קרן היסוד",
    "קרן קימת",
    "ראש פינה",
    "ש אשטרום",
    "ש הבנים",
    "ש עמידר",
    "ש קרן היסוד",
    "ש רסקו",
    "שוב דוד",
    "שכ האמנים",
    "שכ הגפנים",
    "שכ הגרנות",
    "שכ הזיתים",
    "שכ הכרמים",
    "שכ הראשונים",
    "שכ פני גולן",
    "שכון ב",
    "שכון דרומי",
    "שמאי עמיקם",
    "תל אביב",
  ],
  "ראש צורים": ["ראש צורים"],
  "ראשון לציון": [
    "אבא אבן",
    "אבא קובנר",
    "אבו חצירה",
    "אבי האסירים",
    "אבידן שמעון",
    "אביחיל",
    "אביעזר",
    "אבן גבירול",
    "אבן חן",
    "אבן שושן",
    "אבן תמר",
    "אבני איתן",
    "אבני החושן",
    "אבנר מאיר",
    "אברבנאל",
    "אברמוביץ",
    "אבשלום",
    "אגוזי",
    "אדר",
    "אהוד בן גרא",
    "אהרון הכהן",
    "אהרון וברוך",
    "אהרון עמרם",
    "אהרונוביץ",
    "אהרונסון אהרון",
    "אוגדה",
    "אודם",
    "אוזן אהרון",
    "אולגה ויהושע",
    "אוסישקין",
    "אופירה",
    "אופק",
    "אור החיים",
    "אור כשדים",
    "אורטל",
    "אורי צבי",
    "אורנבך אשר",
    "אורנה פורת",
    "אושה",
    'אז"ר',
    "אזולאי דוד",
    "אזור אגני חימצון",
    "אזור התעשיה גן שורק",
    "אזור מסחר ובילוי",
    "אזור תעשיה חדש",
    'אח"י בת גלים',
    'אח"י געש',
    'אח"י דקר',
    'אח"י חנית',
    'אח"י חץ',
    'אח"י טיראן',
    'אח"י להב',
    'אח"י מבטח',
    'אח"י מזנק',
    'אח"י מעוז',
    'אח"י משגב',
    'אח"י נוגה',
    'אח"י סופה',
    'אח"י סער',
    'אח"י רשף',
    'אח"י שבא',
    'אח"י תנין',
    "אחד העם",
    "אחוזת אבות",
    "אחות שרה",
    'אחימאיר אב"א',
    "אחיעזר",
    "אידלסון בבה",
    "איזקסון צבי",
    "אייזנבנד",
    "אילון",
    "אימבר",
    "אינשטיין",
    "איש מצליח",
    "איתם",
    'איתמר בן אב"י',
    "איתן",
    "אל-רום",
    "אלון יגאל",
    "אלוני הבשן",
    "אלחנן",
    "אלחריזי",
    "אלטלנה",
    "אלטמן סידני",
    "אלי על",
    "אלירז שלמה",
    "אלישע",
    "אלפסי",
    "אלפרד מרדכי",
    "אלקבץ",
    "אלקלעי",
    "אלרואי",
    "אלתר דוד הכהן",
    "אלתרמן",
    "אמזלג",
    "אמיל זולא",
    "אנגל אהרון",
    "אנה טיכו",
    "אנוש",
    "אנילביץ",
    "אניעם",
    "אסא",
    "אסירי ציון",
    "אסנת ברזאני",
    "אסתר המלכה",
    "אסתר וינדמן",
    "אסתר(אסתרינה) צרפתי",
    "אעלה בתמר",
    "אפיק",
    "אפרים קישון",
    'אצ"ל',
    "ארבל",
    "ארבלי אלמוזלינו",
    "ארגוב סשה",
    "ארגמן",
    "ארו קנט' ג'וזף",
    "אריאב חיים",
    "אריק איינשטיין",
    "ארלוזורוב",
    "ארנון משה",
    "אש שלום",
    "אשחר",
    "בארי",
    "בדולח",
    "בהט",
    "בובר מרטין",
    "בוגלה יונה",
    "בוהר נילס",
    "בולטימור דוד",
    "בורג יוסף",
    "בורוכוב",
    "בוריס אוסובצקי",
    "בוריס זמנסקי",
    "בורלא",
    'בי"ס שדה',
    "ביאליק",
    "ביח נאות מרגלית",
    'ביל"ו',
    "בילינסון",
    "בינט מאיר",
    "ביס חקלאי",
    "בירגר יצחק",
    "בית אבות עד 120",
    "בית אשר",
    "בית הלוי",
    "בית הלל",
    "בית הערבה",
    "בית יהושע",
    "בית יוסף",
    "בית לחם",
    "בית שמאי",
    "בית תרצה",
    'בית"ר',
    "בלאו ראובן",
    "בלבן אליעזר",
    "בלו סול",
    "בלפור",
    "בלקינד שמשון",
    "בן אהרון אורפלי",
    "בן איש חי",
    "בן אליעזר",
    "בן דוד שלמה",
    "בן זאב",
    "בן זכאי",
    "בן חיים פאול",
    "בן יהודה",
    "בן לולו שמעון",
    "בן סרוק",
    "בן עובדיה יוסף",
    "בן פורת מרים",
    "בן פנחס דוד",
    "בן צבי יצחק",
    "בן-דוד יצחק",
    "בנאסרף ברוך",
    "בנבנישתי",
    "בני חייל",
    "בני יהודה",
    "בני עקיבא",
    "בסקינד נדב",
    "בעל הטורים",
    "בקר",
    "בקרמן אדם",
    "בר אברהם",
    "בר אילן",
    "בר יוחאי",
    "בר כוכבא",
    "בר-לב חיים",
    "ברג פול",
    "ברגמן",
    "ברגסון הנרי",
    "ברגר אריה",
    "ברד אברהם",
    "ברודסקי יוסף",
    "ברון דבורה",
    "ברזילי",
    "ברית יוסף",
    "ברנדיס",
    "ברניצקי נתן",
    "ברנר",
    "ברנשטיין",
    "ברק",
    "ברקת",
    "ברש אשר",
    "ברשבסקי",
    "בשארי סעדיה ויוסף",
    "בשביס זינגר יצחק",
    "בתלם",
    "ג'ו עמר",
    "ג'קוב פרנסואה",
    "גאולים",
    "גארי בקר",
    "גבורי ישראל",
    "גבים",
    "גבין משה",
    "גבעת אביב",
    "גבעת יואב",
    "גבעתי",
    "גבריהו",
    "גדולי ישראל",
    "גדעון",
    "גובר רבקה",
    "גולדברג הנדבן",
    "גולדה מאיר",
    "גולדמן נחום",
    "גולומב",
    "גולינקין",
    "גור",
    "גורדון",
    "גורדימר נאדין",
    "גורה שושנה ומשה",
    "גוש חלב",
    "גוש עציון",
    "גיבשטיין יהושע",
    "גינדי מנחם",
    "גינוסר",
    "גינזבורג",
    "גיסין",
    "גלוסקא",
    "גלוסקין",
    "גלזר דונלד",
    "גלילי ישראל",
    "גלשאו שלדון",
    "גן יבנה",
    "גנות צבי",
    "גרוזנברג",
    "גרוסמן חייקה",
    "גריזים",
    "גרינבוים",
    "גרינשפן הרשל",
    "גרמי רחל",
    "גרניט",
    "גרץ",
    "גרצברג שיקא",
    "גרשברג אברהם",
    "גרשוני אריה",
    "גשור",
    "גשר",
    "ד\"ר ז'יוואגו",
    'ד"ר ליאו רוזנבליט',
    "דבור",
    "דבורה הנביאה",
    "דבורנית",
    "דג הזהב",
    "דגניה",
    "דה-הבשי ג'ורג'",
    "דובנוב",
    "דוד אלעזר",
    "דוד המזכיר",
    "דוד המלך",
    "דוד הראובני",
    "דודו דותן",
    "דוידזון",
    "דולב",
    "דונה גרציה",
    "דורי",
    "די-זהב",
    "דליה",
    "דליה פרי",
    "דמארי שושנה",
    "דן",
    "דניאל הנביא",
    "דקלה",
    "דר אליקום",
    "דרבלה",
    "דרובין יואל",
    "דרור",
    "דרך ההצלחה",
    "דרך המכבים",
    "דרך חיים הרצוג",
    "דרך ישראל ישעיהו",
    "דרך עדן",
    'דרך צה"ל',
    "דשבסקי ישראל",
    "ה' באייר",
    "האבות",
    "האגוז",
    "האגס",
    "האגרת",
    'האו"ן',
    "האוכמנית",
    "האוניברסיטה",
    "האורגן",
    "האורן",
    "האורנים",
    "האחד עשר",
    "האחים אביוב",
    "האחים אוסטשינסקי",
    "האחים גלזר",
    "האחים וינברג",
    "האחים זיגר",
    "האחים יעקובי",
    "האחים סולימן",
    "האחים סמילצ'נסקי",
    "האחים עוקשי",
    "האילן",
    "האילתית",
    "האיריס",
    "האלה",
    "האלון",
    "האלוף דוד",
    "האלמוגים",
    "האם",
    "האמוראים",
    "האניה",
    "האפרסמון",
    "האפרסק",
    "האקליפטוס",
    'האר"י',
    "הארז",
    "האשכולית",
    "האשל",
    "האתרוג",
    "הבוצרים",
    "הבן הראשון",
    "הבנים",
    "הבעל שם טוב",
    "הברון הירש",
    "הברוש",
    "הברושים",
    "הבריגדה",
    "הברקן",
    "הבשן",
    "הגאון מוילנה",
    "הגבורה היהודית",
    "הגבעול",
    "הגדוד העברי",
    "הגומא",
    "הגיתית",
    "הגלבוע",
    "הגליל",
    "הגלים",
    "הגלעד",
    "הגננת בתיה",
    "הגננת הראשונה",
    "הגפן",
    "הגת",
    "הדגל העברי",
    "הדגן",
    "הדובדבן",
    "הדוגית",
    "הדודאים",
    "הדוכיפת",
    "הדולפין",
    "הדיונון",
    "הדייגים",
    "הדסה",
    "הדקל",
    "ההגנה",
    "ההדס",
    "ההדר",
    "ההדרים",
    "ההכשרות",
    "ההסתדרות",
    "ההרדוף",
    "ההתיישבות",
    "הוברמן אברהם (צבי)",
    "הוז דב",
    "הולצברג שמחה",
    "הופמן רואלד",
    "הורד",
    "הורוביץ",
    "הזהבית",
    "הזהרון",
    "הזורעים",
    "הזז",
    "הזית",
    "הזמורה",
    "הזמיר",
    "הזרזיר",
    "החבצלת",
    "החגלה",
    "החוחית",
    "החורש",
    'החיד"א',
    "החייל האלמוני",
    "החלוץ",
    "החלוצים",
    "החליל",
    "החלמונית",
    "החמנית",
    "החפץ חיים",
    "החצב",
    "החצוצרה",
    "החרוב",
    "החרמון",
    "החרצית",
    "החשמונאים",
    "הטיילת",
    "היוגבים",
    "היוזמה",
    "הייז פול",
    "היין",
    "הייסמן",
    "היסמין",
    "היער",
    "היצהר",
    "היצירה",
    "היקב",
    "היקינטון",
    "הירדן",
    "הירמוך",
    "הירקון",
    "הירשפלד",
    "הכובש",
    "הכוזרי",
    "הכורמים",
    "הכינור",
    "הכלנית",
    "הכנסת",
    "הכסיף",
    "הכרכום",
    "הכרמל",
    "הכרפס",
    "הכשרת הישוב",
    "הכתר",
    "הלבונה",
    "הלוויתן",
    "הלוחמים",
    "הלוטם",
    "הלוי יוסף",
    "הלולב",
    "הליבנה",
    "הלימון",
    "הלל נפתלי",
    "הלפרין",
    "המאה ועשרים",
    "המגילה",
    "המדע",
    'המהר"ל מפראג',
    "המוכתר",
    "המוסיקה",
    "המורה אברהם",
    "המורן",
    "המושבה",
    "המחנות העולים",
    "המייסדים",
    "המלחים",
    "המלך",
    "המעיין",
    "המעפילים",
    "המפוחית",
    "המפרש",
    "המצילתיים",
    "המרגנית",
    "המשוט",
    "המשורר שלום",
    "המשוררת רחל",
    "המתנדבים",
    "הנביאים",
    "הנגב",
    "הנגיד",
    "הנוטרים",
    "הנוער הלאומי",
    "הנוער העובד",
    "הנופר",
    "הנורית",
    'הנח"ל',
    "הנחלה",
    "הנחליאלי",
    "הנחשול",
    "הנמל",
    "הנצי\"ב מוולוז'ין",
    "הנרקיס",
    "הס",
    "הסביון",
    "הסוכה",
    "הסחלב",
    "הסיגלית",
    "הסיפון",
    "הסירה",
    "הסמדר",
    "הסנהדרין",
    "הסנפיר",
    "הסתוונית",
    "העוגב",
    "העוגן",
    "העורב",
    "העינב",
    "העליה",
    "העמית יצחק",
    "העסיס",
    "העפרוני",
    "העצמאות",
    "הערבה",
    "הערמון",
    "הפור",
    "הפורצים",
    "הפזית",
    "הפיקוס",
    "הפיקוסים",
    'הפלמ"ח',
    "הפסנתר",
    "הפעמונית",
    "הפרג",
    "הפרדס הראשון",
    "הפרח",
    "הפריגטה",
    "הצאלים",
    "הצבעוני",
    "הצבר",
    "הצדף",
    "הצופים",
    "הצפורן",
    "הצפירה",
    "הצפצפה",
    "הקברניט",
    "הקונכיה",
    "הקוסם מלובלין",
    "הקוקיה",
    "הקידמה",
    "הקישון",
    "הקלרנית",
    "הקנאים",
    "הקריה החקלאית",
    "הר המוריה",
    "הר סיני",
    "הר ציון",
    "הרב אונטרמן",
    "הרב איבגי",
    "הרב ברוק",
    "הרב גוטמן צבי",
    "הרב גורן",
    "הרב גרופי שלום",
    "הרב גרינברג ברוך",
    "הרב דמארי",
    "הרב הרצוג",
    "הרב זינגר",
    "הרב זכריה פנחס",
    "הרב חדד חיים",
    "הרב חיון גדליה",
    "הרב חיים דוד הלוי",
    'הרב חרל"פ',
    "הרב טולידאנו",
    "הרב טייב",
    "הרב טרוביץ שמואל",
    "הרב יוסף עזרן",
    "הרב ינובסקי",
    "הרב יעקובוביץ",
    "הרב מימון",
    "הרב מינץ",
    "הרב נורוק",
    "הרב ניסים",
    "הרב נריה",
    "הרב סובהי",
    "הרב עוזיאל",
    "הרב עוזרי",
    "הרב צאלח משה",
    "הרב צדוק",
    "הרב קוק",
    "הרב רפפורט",
    "הרב שלום שרעבי",
    "הרב שלינקה יצחק",
    "הרב שמעוני מרדכי",
    "הרבי מלובביץ",
    "הרברט סמואל",
    "הרותם",
    "הרימון",
    "הרפסודה",
    "הרץ גוסטב",
    "הרצל",
    "הרקפת",
    'הרשב"א',
    "הרשקו אברהם",
    "הרשקוביץ",
    "הרשת",
    "השגשוג",
    "השדה",
    "השומר",
    "השומר הצעיר",
    "השומרון",
    "השונית",
    "השופטים",
    "השזיף",
    "השחר",
    "השיטה",
    "השייטים",
    "השייטת",
    "השירה העברית",
    "השירות המטאורולוגי",
    "השמורה",
    "השניים",
    "השקד",
    "השקמה",
    "השר שפירא משה",
    "השרביט",
    "השריג",
    "התאנה",
    "התבור",
    "התגלית",
    "התומר",
    "התוף",
    "התור",
    "התורמוס",
    "התורן",
    "התות",
    "התזמורת",
    "התחנה לחקר חקלאות",
    "התירוש",
    "התכלת",
    "התלתן",
    "התמר",
    "התנאים",
    "התעשיין",
    "התפוז",
    "התפוח",
    "התקוה",
    "וולפסון",
    "ויגודסקי",
    "ויזל אלי",
    "וייז סטפן",
    "וילנסקי וולף",
    "וינברג אברהם",
    "ויניק",
    "ויצברד",
    "ויצמן",
    "ויתקין",
    "ולד יצחק (איגו)",
    "וקסלר משה",
    "ורבורג אוטו",
    "ורמוס הרולד",
    "ורניק ישראל",
    "ז'בוטינסקי",
    "זאב",
    "זבולון המר",
    "זדל",
    "זהבי צבי וצפורה",
    "זוסמן",
    "זינר דוד ויפה",
    "זיצר חוה ובנימין",
    "זכרון שרה",
    "זלצמן אהרון ומנחם",
    "זמנהוף",
    "זקס נלי",
    "זרובבל",
    'ח"ן',
    'חב"ד',
    "חבקוק שלום",
    "חברון",
    "חגי הנביא",
    "חד-נס",
    "חובב מאיר וצבי",
    "חומה",
    "חומה ומגדל",
    "חזון איש",
    "חזן יעקב",
    "חטיבה שבע",
    "חטיבת קריתי",
    'חי"ש',
    "חיים בן אפרים",
    "חיים ופניה חיסין",
    "חיים חפר",
    "חיל האויר",
    "חיל ההנדסה",
    "חיל החימוש",
    "חיל הים",
    "חיל המודיעין",
    "חיל הצנחנים",
    "חיל הקשר",
    "חיל הרגלים",
    "חיל הרפואה",
    "חיל השלישות",
    "חיל השריון",
    "חיל התותחנים",
    "חיספין",
    "חכם עזרא כהן",
    "חלוצי יסוד המעלה",
    "חלץ",
    'חמ"ד',
    "חמדיה",
    "חנה זמר",
    "חניתה",
    "חנקין",
    "חסד ואמת",
    "חסדאי",
    "חסידי אומות העולם",
    "חצור",
    "חצר-אדר",
    "חתני פרס נובל",
    "טביב",
    "טבנקין יצחק",
    "טהון",
    "טובה סנהדראי",
    "טוליפמן",
    "טופורובסקי",
    "טופז",
    "טיבוביץ יהודה",
    "טיומקין",
    "טירת צבי",
    "טרומפלדור",
    "טרכטנברג",
    "טשרניחובסקי",
    "יאיר",
    "יאיר דורון",
    "יאלו רוזלין",
    "יבניאלי",
    "יברבוים",
    "ידין",
    "יהודה",
    "יהודה הלוי",
    "יהודה ואביבה מגר",
    "יהודה לייב",
    "יהלום",
    "יואל כהן",
    "יובלים",
    "יודילוביץ'",
    "יוחנן הסנדלר",
    "יונה הנביא",
    "יונתן",
    "יוסף הנשיא",
    "יוסף טומי לפיד",
    "יוענה ז'בוטינסקי",
    "יחזקאל הנביא",
    'יל"ג',
    "ילדי טהרן",
    "ילין דוד",
    "ימית",
    "יעקב בן סירא",
    "יעקב נהמי",
    "יעקבי שאול",
    "יעקובזון",
    "יערי מאיר",
    "יפה ירקוני",
    "יפתח",
    "יצחק שמיר",
    "ירושלים",
    "ירח יעקובי",
    "ירמיהו",
    "ישעיהו",
    "ישראל ופאני",
    "ישראלי שמעון",
    'כ"ץ סר ברנרד',
    "כביש חולון בת ים",
    "כגן רחל",
    "כהן אלי",
    "כהן יעקב",
    "כהן מנחם",
    "כהנסקי עדינה",
    "כורש",
    'כיכר מג"ב',
    "ככר המיסדים",
    "ככר העצמאות",
    "ככר יום-טוב ראובן",
    'ככר כ"ט בנובמבר',
    "ככר מודיעין",
    "כל ישראל חברים",
    "כמוס צדוק ומלכה",
    "כנף",
    "כנרת",
    "כפר דרום",
    "כפר חיטים",
    "כפר חרוב",
    "כפר מסריק",
    "כצלר פייבוש",
    "כצנלסון",
    "כתר ארם צובא",
    "כתריאל",
    "לאה רבין",
    "לאונר נעם",
    "לבונטין",
    "לביטוב זהרה",
    "לובמן חביב",
    "לוז",
    "לוי אשכול",
    "לוי יצחק מברדיצב",
    "לוי מונטלצ'יני ריטה",
    "לוי משה",
    "לוין אשר",
    "לוין מיכאל וחנה",
    "לוע הארי",
    "לוריא סלבדור",
    "לזרוב",
    'לח"י',
    "ליבוביץ צבי",
    "ליבין יוסף וריסיה",
    "לילינבלום",
    "לילך",
    "ליפשיץ שלמה לייב",
    "לישנסקי יוסף",
    "למדן",
    "לנדאו חיים",
    "לנדאו לב דוידוביץ",
    "לסקוב",
    "לשם",
    "מאיר דיזנגוף",
    "מאירוביץ",
    "מאפו",
    "מבוא חמה",
    "מבוא ישורון",
    "מבואות חרמון",
    "מבצע אביב נעורים",
    "מבצע ברוש",
    "מבצע ברק",
    "מבצע דין וחשבון",
    "מבצע דני",
    "מבצע הראל",
    "מבצע חומת מגן",
    "מבצע חורב",
    "מבצע חירם",
    "מבצע יואב",
    "מבצע יונתן",
    "מבצע ליטאני",
    "מבצע משה",
    "מבצע נועה",
    "מבצע נחשון",
    "מבצע עובדה",
    "מבצע ענבי זעם",
    "מבצע קדש",
    "מבצע שלמה",
    "מבצע תמוז",
    "מגדלור",
    "מודליאני פרנקו",
    "מוהליבר",
    "מוטה גור",
    "מוליק (שמואל) איל",
    "מולכו שלמה",
    "מונטיפיורי",
    "מוסאל יצחק",
    "מוסינזון יגאל",
    "מוצקין",
    "מור",
    "מורדי הגטאות",
    "מוריי גל-מן",
    "מורשת ישראל",
    "מושביץ",
    "מזיא",
    "מזל אליעזר",
    "מזרחי משה",
    "מחלי הלוי",
    "מחנה רביבים",
    "מחניים",
    "מידן עמירם",
    "מיכה יוסף",
    "מיכואלס שלמה",
    "מילר צבי",
    "מיצר",
    "מירה גל",
    "מירון",
    'מיש"ר',
    "מכבי צעיר",
    "מלאכי",
    "מלך הגשם",
    "מלכי ישראל",
    "מלל ניסים",
    "מן גרשון",
    "מנדלי",
    "מנור אהוד",
    "מסילות",
    "מעברת נחלת יהודה",
    "מעגל השלום",
    "מעוז חיים",
    "מעונות ברכה",
    "מעלה גמלא",
    "מעלה החמישה",
    "מענית",
    "מעפילי אקסודוס",
    "מעפילי מרוקו",
    "מעפילי קפריסין",
    "מצדה",
    "מקלף",
    "מרבד הקסמים",
    "מרגולין",
    "מרדכי היהודי",
    "מרום גולן",
    "מרים",
    "מרכז העיר",
    "מרכז עסקים שורק",
    "מרכז ראשון (מערב)",
    "מרקוביץ אברהם אהרון",
    "מרקוביץ הרי",
    "משה בן נחמן",
    "משואות יצחק",
    "משורר השואה",
    "משחק העיניים",
    "משמר הירדן",
    "משמר השלושה",
    "משעול אדווה",
    "משעול אשד",
    "משעול בריכת רם",
    "משעול הכימיה",
    "משעול הכלכלה",
    "משעול הספרות",
    "משעול הפיסיקה",
    "משעול הרפואה",
    "משעול זוויתן",
    "משעול יזרעאל",
    "משעול כנען",
    "משעול משה ובנימין",
    "משעול משושים",
    "משעול פלג",
    "מתחם היקב",
    "מתחם צוריאל",
    "נאות אשלים",
    "נאות גולן",
    "נאות סיני",
    "נאות שקמה",
    "נביעות",
    "נגבה",
    "נהריים",
    "נוב",
    "נוה הדרים",
    "נוה זאב",
    "נוה מרדכי",
    "נוה ציון",
    'נווה אטי"ב',
    "נווה דקלים",
    "נווה הלל",
    "נווה ים",
    "נורדאו",
    "נח מוזס",
    'נח"ל ים',
    'נח"ל נמרוד',
    "נחל שורק",
    "נחלת יהודה",
    "נחמה",
    "נחמוני",
    "נחמיאס יעקב",
    "נחמיה",
    "נטור",
    'ניל"י',
    "ניר אברהם",
    "ניר בנים",
    "ניר דוד",
    "נירים",
    "נסיה",
    "נעמה",
    "נעמן",
    "נצר סירני",
    "נתיב העשרה",
    "נתן שלמה",
    "נתנס דניאל",
    "סגיס יהודה",
    "סגל מרדכי יואל",
    "סולד",
    "סולו רוברט",
    "סופר יעקב",
    "סוקולוב",
    "סחרוב דוד",
    "סטרומה",
    "סיטרמן (מיכאל)",
    "סילבר",
    "סימון הרברט",
    "סימונובסקי משה",
    "סיפורי אגדות",
    "סירקין נחמן",
    "סלומון",
    "סמ בנימין",
    "סמ בצלאל",
    "סמ ברקוביץ",
    "סמ גן נחום",
    "סמ הרעות",
    "סמואלסון פול",
    "סמילנסקי",
    "סניור",
    "סנש חנה",
    "סעדיה גאון",
    "ספינת השלושה",
    "ספיר יוסף",
    'עגנון ש"י',
    "עדה",
    "עובדיה",
    "עוזי חיטמן",
    "עולי הגרדום",
    "עומר דבורה",
    "עזר(ויצמן)",
    "עזרא",
    "עזרה ובצרון",
    "עטרות",
    "עטרת זהב",
    "עיינות",
    "עין גב",
    "עין דור",
    "עין המפרץ",
    'עין הנצי"ב',
    "עין הקורא",
    "עין זיוון",
    "עין צורים",
    "על כנפי נשרים",
    "עלומים",
    "עליזה בגין",
    "עלית הנוער",
    "עמוס הנביא",
    "עמיר",
    "עמנואל (רינגלבלום)",
    "עמנואלה בן יעקב",
    "ענבר",
    "עפרה חזה",
    "עצמונה",
    "עראקי שלמה",
    "פאול גרונינגר",
    "פאר זלקינד",
    "פארק אגמי ראשונים",
    "פוגל רוברט",
    "פוזננסקי מנחם",
    "פוחצ'בסקי מיכל",
    "פולה בן גוריון",
    "פופל מרדכי",
    "פורת אורי",
    "פטריה",
    "פיין בנימין",
    "פיין נחמן",
    "פיינמן ריצארד",
    "פייס",
    "פינברג יוסף",
    "פינס",
    "פינשטיין יונה צבי",
    'פיק"א',
    "פישלזון",
    "פישר אדמונד",
    "פלוטיצקי",
    "פלטין נפתלי",
    "פליישר חיה",
    "פליציה קראי",
    "פנזיאס ארנו",
    "פנחסוביץ",
    "פנינה ומשה",
    "פנינת הים",
    "פנקס דוד",
    "פסטרנק בוריס",
    "פרוג שמעון",
    "פרופ' חבוט",
    "פרופ' נחום",
    "פרי יעקב",
    "פרי-גן",
    "פרידמן מילטון",
    "פרימן יעקב",
    "פרישמן",
    "פרנק אנה",
    "פרנק צבי",
    "פרנקל",
    "פרס שמעון",
    "פרסול נעמי",
    "פרץ",
    "פרשקובסקי",
    'צ"ג בנות',
    "צ'חנובר אהרן",
    "צ'לנוב",
    "צייטלין",
    "צלליכין",
    "צמח",
    "צמיר אלימלך",
    "צפניה הנביא",
    "צרנוב יצחק",
    "קאליב",
    "קאנטי אליאס",
    "קאסין רנה",
    "קאפח",
    "קארו יוסף",
    "קבוץ גלויות",
    "קדמת סיני",
    "קדמת צבי",
    "קדמת ראשון",
    "קהילת ניו ג'רסי",
    "קוזי יוסף",
    "קוזנץ שמעון",
    "קוצר אריה",
    "קורל",
    "קורנית",
    "קורצ'ק יאנוש",
    "קזושנר אברהם",
    "קידוש השם",
    "קיסינג'ר הנרי",
    "קיפניס",
    "קירז'נר",
    "קלאוזנר יוסף",
    "קלוג אהרון",
    "קליביצקי",
    "קליין לורנץ",
    "קלישר",
    "קלמן",
    "קלע",
    "קמפינסקי",
    "קמרון אברהם",
    "קנטורוביץ ליאוניד",
    "קנר",
    "קפלן",
    "קפלנסקי",
    "קצרין",
    "קרון אהרון",
    "קריסטל",
    "קרית גנים",
    "קרית הלאום",
    "קרית חתני פרס נובל",
    "קרית כורש",
    "קרית כרמים",
    "קרית ספר",
    "קרית קאליב",
    "קרית ראשון",
    "קרית שמחה",
    "קרל נטר",
    "קרלן פרץ",
    "קרן היסוד",
    "קרן קימת לישראל",
    "קרצ'בסקי",
    "קשת",
    'ראב"ע',
    "ראובן",
    "ראובן ובת שבע",
    "ראול ולנברג",
    "ראם",
    "ראשון לציון",
    "רבדים",
    "רבי איזידור יצחק",
    "רבי טרפון",
    "רבי יהודה הנשיא",
    "רבי יוסף בוכריץ",
    "רבי מאיר",
    "רבי משה כלפון הכהן",
    "רבי נחמיה מוטא",
    "רבי עקיבא",
    "רביד",
    "רבינוביץ שמשון",
    "רבנו תם",
    "רגב מרדכי",
    "רגוניס יהודה",
    'רד"ק',
    "רובינא חנה",
    "רובינשטיין ארתור",
    "רוז'נסקי מרדכי",
    "רוזין",
    "רוזנטל",
    "רוזנשטיין מרדכי",
    "רוט יעקב",
    "רוטנברג",
    "רוטשילד",
    "רויז יוסף",
    "רון חיים",
    "רופין",
    "רוקח",
    "רזיאל דוד",
    "רח 461",
    "רח 272",
    "רח 275",
    "רחל ינאית בן צבי",
    'ריב"ל',
    "ריינגולד חנה",
    "ריינס",
    "רייק חביבה",
    "ריכטר ברטון",
    'רמב"ם',
    "רמות",
    "רמז",
    'רמח"ל',
    "רמת מגשימים",
    "רפאל אריה",
    "רפול",
    "רפידים",
    'רש"י',
    "ש בולגרים",
    "ש בן ציון",
    "ש גורדון",
    "ש גזית",
    "ש חדש- נחלת יהודה",
    "ש חסכון",
    "ש מיבדן",
    "ש משפחות צריפין",
    "ש נוה עובד",
    "ש נוה פועלים",
    "ש נווה דקלים",
    "ש סלע חדש",
    "ש עובדים",
    "ש עובדים נח יהודה",
    "ש עמידר צריפים",
    "ש קליפורניה",
    "ש רכבת",
    "ש רמת אליהו",
    "ש שקד",
    "שאול המלך",
    "שבזי שלום",
    "שבטי ישראל",
    "שביט",
    "שביל אלונים",
    "שביל האחים ימיני",
    "שבת אחים",
    "שגיא יהודה",
    "שד בן גוריון",
    "שד דיין משה",
    "שד היובל",
    "שד הציונות",
    "שד התקומה",
    "שד זמסקי מאיר",
    "שד יעקב",
    "שד יצחק רבין",
    "שד מינסטר",
    "שד מנחם בגין",
    "שד נים",
    "שד רחבעם (זאבי)",
    "שדה אליהו",
    "שדה ורבורג",
    "שדה חמד",
    "שדה יצחק",
    "שדה נחום",
    "שדות",
    "שדמות דבורה",
    'שדרת תש"ח',
    "שוהם",
    "שוחט מניה",
    "שולמית אלוני",
    "שולמן צבי",
    "שומרה",
    "שוסהיים חנה",
    "שושן הבירה",
    "שושנה",
    "שושנת יעקב",
    'שז"ר זלמן',
    "שיאון",
    "שיבת ציון",
    "שייקה אופיר",
    "שייקה דן",
    "שיכוני המזרח",
    "שינקין",
    "שירה",
    "שכ אביבה",
    "שכ אברמוביץ'",
    "שכ אזור התעשיה",
    "שכ בנות חיל",
    "שכ ברנשטיין",
    "שכ גאולה",
    "שכ גני ראשון",
    "שכ הפועל המזרחי",
    "שכ השומר",
    "שכ כפר אריה",
    "שכ כצנלסון",
    "שכ מזרחי",
    "שכ מישור הנוף",
    "שכ נוה חוף",
    "שכ נווה דקלים",
    "שכ נעורים",
    "שכ קבוץ",
    "שכ ראשונים",
    'שכ רמב"ם',
    "שכ רמז",
    "שלום עליכם",
    "שלונסקי",
    "שליט",
    "שלמה",
    "שלמה המלך",
    "שלמה לוי",
    "שלנג זאב",
    "שמגר בן ענת",
    "שמואל הנביא",
    "שמוטקין בנימין",
    "שמעוני",
    "שמר נעמי",
    "שמריהו לוין",
    "שמשון",
    "שמשון זליג",
    "שנהב",
    "שניאור זלמן",
    "שנקר אריה",
    "שעל",
    "שער הגולן",
    "שער המלך",
    "שפטל אריה",
    "שפינוזה",
    "שפירא צבי הרמן",
    "שפרינצק",
    "שקולניק",
    "שרה ואליהו",
    "שרונה",
    "שרוני ברוך",
    "שרירא שמואל",
    "שרף חיים",
    "שרת משה",
    "ששת הימים",
    "תדהר דוד",
    "תהילה",
    "תור הזהב",
    "תורה ועבודה",
    "תל חי",
    "תל יצחק",
    "תנועות הנוער",
    "תנחום",
    "תרזיה",
    'תרמ"ב',
    "תרשיש",
  ],
  רבבה: [
    "ארץ חמדה",
    "בני אפרים",
    "ברכת הארץ",
    "דבורה הנביאה",
    "הר השומרון",
    "הררי קדם",
    "טל שמיים",
    "יהושע בן נון",
    "יוסף הצדיק",
    "כלב בן יפונה",
    "נחל קנה",
    "נחל שילה",
    "נחל תרצה",
    "נחלת אבות",
    "רבבה",
  ],
  רבדים: ["רבדים"],
  רביבים: ["אולפן", "בית ראשון במולדת", "רביבים"],
  רביד: ["רביד"],
  רגבה: [
    "אמנון ותמר",
    "ברוש",
    "האורן",
    "האירוס",
    "האלה",
    "האשבל",
    "הברקן",
    "הגומא",
    "הגפן",
    "הדבורנית",
    "הדולב",
    "הדקל",
    "ההדס",
    "ההרדוף",
    "הזית",
    "החיטה",
    "החלמונית",
    "החצב",
    "החרצית",
    "היקינתון",
    "הכלנית",
    "הכרכום",
    "המרווה",
    "הצאלון",
    "הצבעוני",
    "הקורנית",
    "הרימון",
    "הרקפת",
    "השעורה",
    "התאנה",
    "התמר",
    "קינמון",
    "רגבה",
  ],
  רגבים: ["רגבים"],
  רהט: [
    "אבו בכר אלסדיק",
    "אבו הוריירה",
    "אבו חניפה",
    "אבו טלחה אלאנסארי",
    "אבו נואס",
    "אבו עביידה אלג'ראח",
    "אבו עוביידה",
    "אבו תלול",
    "אבן אלרומי",
    "אבן בטוטה",
    "אבן ח'לדון",
    "אבן חנבל",
    "אבן סינא",
    "אבן רושד",
    "אברהים טוקאן",
    "אג'נאדין",
    "אוחד",
    "אום אלקרא",
    "אום בטין",
    "אום רשרש",
    "אזוביר בן אלעואם",
    "אל אנדלוס",
    "אל אנסאר",
    "אל בדר",
    "אל ביאדר",
    "אל הודא",
    "אל ואחה",
    "אל כראמה",
    "אל מרוא",
    "אל ניל",
    "אל סדרה",
    "אל סלאח",
    "אל סנאבל",
    "אל ספא",
    "אל ע'דיר",
    "אל עבאס",
    "אל פארוק",
    "אל קאדסייה",
    "אל רואבי",
    "אל רשיד",
    "אלאג'ואד",
    "אלאדבא",
    "אלאהראם",
    "אלאזהר",
    "אלאח'א",
    "אלאחווה",
    "אלאיובי",
    "אלאימאן",
    "אלאמל",
    "אלאנבאר",
    "אלאנביא",
    "אלאנסאן",
    "אלאנעאם",
    "אלאנפאל",
    "אלאסרא",
    "אלאעראף",
    "אלאקסא",
    "אלארדן",
    "אלבאדיה",
    "אלבואדי",
    "אלבוח'ארי",
    "אלבחרין",
    "אלבטין",
    "אלביאן",
    "אלבלד",
    "אלבסרה",
    "אלבסתאן",
    "אלברוג'",
    "אלברכה",
    "אלבתרא",
    "אלג'ולן",
    "אלג'ומעה",
    "אלג'זאיר",
    "אלג'ליל",
    "אלג'נדל",
    "אלג'עדה",
    "אלדאליה",
    "אלדוחה",
    "אלדח'אן",
    "אלהג'רה",
    "אלהדיה",
    "אלהודג'",
    "אלהלאל",
    "אלואדי",
    "אלוורד",
    "אלוחדאת",
    "אלוטן",
    "אלופא",
    "אלזוחרוף",
    "אלזומר",
    "אלזייר",
    "אלזייתון",
    "אלזיתונה",
    "אלזמורד",
    "אלזנאבק",
    "אלזרקא",
    "אלח'ד'ר",
    "אלח'יר",
    "אלח'ליל",
    "אלח'לפאא",
    "אלח'נסא",
    "אלחג'",
    "אלחג'אז",
    "אלחורייה",
    "אלחיאת",
    "אלחכוך",
    "אלחכמה",
    "אלחנאן",
    "אלחנדק",
    "אלחנפי",
    "אלחסבאני",
    "אלחרם",
    "אלטאיף",
    "אלטארק",
    "אלטור",
    "אלטייב",
    "אלטייבה",
    "אליאסמין",
    "אלימאמה",
    "אלימן",
    "אלירמוך",
    "אלכאפור",
    "אלכהף",
    "אלכוית",
    "אלכרם",
    "אלכרמל",
    "אללוד",
    "אלמאידה",
    "אלמג'ד",
    "אלמדינה אלמונארה",
    "אלמהבאש",
    "אלמהד",
    "אלמוסאואה",
    "אלמחבה",
    "אלמידאן",
    "אלמכתבה",
    "אלמנאמה",
    "אלמנסור",
    "אלמערי",
    "אלמערפה",
    "אלמרכז",
    "אלמתנאן",
    "אלנאסרה",
    "אלנבי אברהים",
    "אלנבי הוד",
    "אלנבי יוסף",
    "אלנבי נוח",
    "אלנבע",
    "אלנג'אח",
    "אלנג'ם",
    "אלנהדה",
    "אלנהר",
    "אלנור",
    "אלנח'יל",
    "אלנחל",
    "אלנכיל",
    "אלנעים",
    "אלנצר",
    "אלנקב",
    "אלנרג'ס",
    "אלסבר",
    "אלסדאקה",
    "אלסחאבה",
    "אלסלאמה",
    "אלסלאסל",
    "אלסנדיאן",
    "אלספסאף",
    "אלסר",
    "אלע'זאלי",
    "אלע'פראן",
    "אלע'ררה",
    "אלעדל",
    "אלעהד",
    "אלעודה",
    "אלעטאא",
    "אלעפאף",
    "אלעראק",
    "אלפאתח",
    "אלפג'ר",
    "אלפורקאן",
    "אלפורת",
    "אלפרדוס",
    "אלפרזדק",
    "אלפתח",
    "אלקאהרה",
    "אלקאפלה",
    "אלקדר",
    "אלקודס",
    "אלקווין",
    "אלקות'ר",
    "אלקיסום",
    "אלקלם",
    "אלקמר",
    "אלקנדיל",
    "אלקרואן",
    "אלקרונפל",
    "אלראזי",
    "אלרבאט",
    "אלרביע",
    "אלרדואן",
    "אלרודה",
    "אלרחמה",
    "אלריאד",
    "אלריאן",
    "אלריחאן",
    "אלרים",
    "אלרמלה",
    "אלרעד",
    "אלשאפעי",
    "אלשהאמה",
    "אלשורא",
    "אלשמס",
    "אלשרוק",
    "אלשרק",
    "אלתובה",
    "אלתחריר",
    "אלתייאסיר",
    "אלתין",
    "אלתינה",
    "אלתלאוה",
    "אלתסאמח",
    "אלתעלה",
    "אלתקוא",
    "אנס בן מאלכ",
    "אספהאן",
    "אסקלאטי",
    "אסתנבול",
    "אקרא",
    "אריחא",
    "באבל",
    "בדר",
    "ביסאן",
    "ביר אלסבע",
    "בירות",
    "בלאל בן רבאח",
    "בניאס",
    "ברייר",
    "ג'בראן ח'ליל ג'בראן",
    "ג'דה",
    "ג'נין",
    "ד'ו אלנורין",
    "דובאי",
    "דמשק",
    "ואדי אלחאסי",
    "זיד בן אלארקם",
    "זייד בן ת'אבת",
    "זמזם",
    "ח'אלד בן אלואליד",
    "חיפא",
    "חלב",
    "חנין",
    "חסאן בן ת'אבת",
    "טאהא",
    "טאהא חוסין",
    "טארק בן זיאד",
    "טבריה",
    "טורקיה",
    "טראבלוס",
    "טרפה בן אלעבד",
    "יאסור",
    "יאסין",
    "יאפא",
    "כוילפה",
    "כרכור",
    "לובנאן",
    "לוקמאן",
    "ליביא",
    "מואתא",
    "מוסלם",
    "מועד בן ג'בל",
    "מינא",
    "מכה",
    "מסקט",
    "מסר",
    "מעאויה בן אביסופיאן",
    "מרים אלעד'רא",
    "נאבלוס",
    "נג'ד",
    "נג'יב מחפוז",
    "סוריא",
    "סנעאא",
    "סעד בן אבי וקאס",
    "ע'אפר",
    "ע'רנאטה",
    "עדן",
    "עומר בן אלח'טאב",
    "עומר בן עבד אלעזיז",
    "עות'מאן אבן עפאן",
    "עזה",
    "עין ג'אלות",
    "עכא",
    "עלי בן אבי טאלב",
    "עמאן",
    "עמוריה",
    "עמרו בן אלעאס",
    "עתלית",
    "פאטר",
    "פלסטין",
    "קבאא",
    "קורטבה",
    "קייס בן אלמלוח",
    "רהט",
    "רפח",
    "שכ 41",
    "שכ 23",
    "שכ 35",
    "שכ 6",
    "שכ 16",
    "שכ 36",
    "שכ 56",
    "שכ 37",
    "שכ 58",
    "תדמר",
    "תונס",
    "תופיק אלחכים",
  ],
  רווחה: ["רווחה"],
  רוויה: ["רוויה"],
  "רוח מדבר": ["רוח מדבר"],
  רוחמה: ["בית ראשון במולדת", "רוחמה"],
  רומאנה: ["רומאנה"],
  "רומת הייב": ["רומת הייב"],
  רועי: ["רועי"],
  רותם: ["רותם"],
  רחוב: ["רחוב"],
  רחובות: [
    "אבן עזרא",
    "אברבנאל",
    "אברך חנה",
    "אברמסון מירון",
    "אגמון",
    "אגמיה",
    "אדמונית",
    "אהרון מזיא",
    "אהרונוביץ יוסף",
    "אהרוני ישראל",
    "אודם",
    "אוסישקין",
    "אופנהיימר",
    "אורבך חיים",
    "אורי צבי גרינברג",
    "אושה",
    'אז תעשיה ע"ש רכטמן',
    "אזורי וייצמן",
    "אחד העם",
    "אחוזת הנשיא",
    "אחים קיבוביץ",
    "אטד",
    "אידלבאום משה",
    "איזנברג",
    "אייזנר זאב",
    "אינשטיין",
    "אלומות",
    "אליעזר יעקבסון",
    "אלכס יוסף צבי",
    "אלמוג",
    "אלקלעי",
    "אלתרמן נתן",
    "אמנון ותמר",
    "אמרי חיים",
    "אנילביץ",
    "אסתר ואברהם",
    "אפל צבי",
    "ארגמן",
    "ארז",
    "ארלוזורוב",
    "ארליך צבי",
    "ארצי זאב",
    "אשבל",
    "אשד שמעון",
    "אשל",
    "אשרוב",
    'את ע"ש ב.צ הורוביץ',
    "באר מים חיים",
    "בארי",
    "בוסל",
    "בוסתן",
    "בוסתנאי",
    "בורגין יהושע",
    "בורוכוב",
    "ביאלר קלמן",
    'ביל"ו',
    "בית הפועלים",
    "בכר מיכאל",
    "בלקינד ישראל",
    "בן אהרן יוסף",
    "בן אפרים נפתלי",
    "בן ארי שרגא",
    "בן גוריון",
    "בן דוד פנחס",
    "בן יהודה אליעזר",
    "בן יהודה מנחם",
    "בן ישראל",
    "בני משה",
    "בנימין",
    "בעל התניא",
    "בקמן נח",
    "בקר בועז",
    "בר אילן",
    "בר כוכבא",
    "בר-לב חיים",
    "ברגמן",
    "ברזילי משה",
    "ברמן יעקב",
    "ברנר",
    "ברקת",
    "בשארי אברהם",
    "בשיסט מרדכי",
    "בשמת",
    "גאולה",
    "גבורי ישראל",
    "גבעת החרובים",
    "גבריאלוב",
    "גדוד 52",
    "גדסי אהרן",
    "גוטהילף שרה ושמואל",
    "גולדברג",
    "גולדה מאיר",
    "גולדין",
    "גולדשטיין",
    "גולומב",
    "גור יהודה",
    "גורדון",
    "גורודיסקי",
    "גוש עציון",
    "גינות סביון",
    "גינזבורג זלמן",
    "גלגל",
    "גלוסקא זכריה",
    "גלוסקין",
    "גלזר אליהו",
    "גמליאל בן זכאי",
    "גני הדר",
    "גני סביון",
    "גני שילר",
    "גרץ",
    "דגן",
    "דהרי שלום",
    "דובדבן",
    "דובנבוים גרשון",
    "דובנוב",
    "דוד אלעזר",
    "דוידוב חי רחמים",
    "דוכיפת",
    "דולב",
    "דולינסקי",
    "דולצ'ין אריה",
    "דונדיקוב",
    "דור יהודה",
    "דיין שמעון",
    "דליה",
    "דמשק אליעזר",
    "דניאלי עמוס",
    "דפנה",
    "דקל",
    "דרויאן יעקב",
    "דרור",
    'דרך אצ"ל',
    "דרך בן ארי יצחק",
    "דרך בן גרא",
    "דרך גד",
    "דרך החקלאות",
    "דרך הים",
    "דרך ויסגל מאיר",
    "דרך יבנה",
    "דרך ירושלים",
    "דרך כץ יצחק",
    "דרך פסטרנק",
    "הא באייר",
    "האגוז",
    'האדמו"ר מקרעטשניף',
    "האורן",
    "האחים טרבס",
    "האירוסים",
    "האלה",
    "האלוף פרי יעקב",
    "האמהות",
    "האמוראים",
    'האר"י',
    "הבושם",
    "הבן איש חי",
    "הבנים",
    'הבעש"ט',
    "הברוש",
    "הבריגדה",
    "הגאונים",
    "הגולן",
    "הגורן",
    "הגליל",
    "הגלעד",
    "הגפן",
    'הגר"א',
    "הדגניות",
    "הדופיה",
    "הדר",
    "ההגנה",
    "ההדס",
    "ההסתדרות",
    "הוז דב",
    "הולצברג שמחה",
    "הולצמן חיים",
    "הורביץ אלי",
    "הורוביץ צבי",
    "הזוהר",
    "הזית",
    "החבורה",
    "החיטה",
    "החיננית",
    "החלוץ",
    "החצב",
    "החרוב",
    "החרצית",
    "החשמונאים",
    "היסמין",
    "הירדן",
    "הכלניות",
    "הכרם",
    "הלל יעקב",
    "המדע",
    "המלכים",
    "המנוף",
    "המעפיל",
    "המרגנית",
    "המרד",
    "הנביאים",
    "הנוטר",
    "הנורית",
    "הנרקיסים",
    "הנשיא הראשון",
    "הס",
    "הסביון",
    "הסגליות",
    "הסמדר",
    "הסנהדרין",
    "העבודה",
    "העירית",
    "העליה",
    "העמק",
    "העצמאות",
    "הערבה",
    "הפועל הצעיר",
    "הפקולטה לחקלאות",
    "הפרג",
    "הפרטיזנים",
    "הצבעוני",
    "הציונות",
    "הצנחנים",
    "הקטיף",
    "הר אביטל",
    "הר ארבל",
    "הר ארדון",
    "הר גלבוע",
    "הר גריזים",
    "הר הזיתים",
    "הר המוריה",
    "הר הנגב",
    "הר הצופים",
    "הר חרמון",
    "הר כנען",
    "הר כרכום",
    "הר מירון",
    "הר נבו",
    "הר סיני",
    "הר ציון",
    "הר שלמה",
    "הר תבור",
    'הרא"ה',
    'הרא"ז',
    "הראל מרדכי",
    "הרב בר שאול",
    "הרב גרז זבולון",
    "הרב דוד ישראל",
    "הרב הרצוג",
    "הרב מאור יוסף",
    "הרב מאיר",
    "הרב מדר זכריה",
    "הרב מזרחי יעקב",
    "הרב מימון",
    "הרב מלצר",
    "הרב משולם",
    "הרב נדב דוד",
    "הרב נריה משה צבי",
    "הרב קאפח יוסף",
    "הרב שאולי",
    "הרב שטיינמן צבי",
    "הרב שיבר",
    "הרדוף",
    'הרה"ג עובדיה דמתי',
    "הרותם",
    "הרי אדום",
    "הרי השומרון",
    "הרי יהודה",
    "הרימון",
    "הרצל",
    "הרקפת",
    "הרשנזון",
    "השומרים",
    "השופטים",
    "השעורה",
    "השקד",
    "השרון",
    "התאנה",
    "התחיה",
    "התנאים",
    "התקוה",
    "התקומה",
    "וידר זאב",
    "וייסבורג",
    "וילקומיץ שמחה",
    "וינר יוסף",
    "ויצמן",
    "ולנברג ראול",
    "ורד",
    'ז"ס',
    "ז'בוטינסקי",
    "זילברמן שאול",
    "זכריה חממי",
    "זכריה משה",
    'זק"ש אפרים',
    "זרסקי מנחם מנדי",
    'חב"ד',
    "חוגלה",
    "חוחית",
    "חורש",
    "חושן",
    "חזון איש",
    "חזית הדרום",
    'חי"ש',
    "חכם עפג'ין אברהם",
    "חנקין",
    'חס"מ',
    "חסידה",
    "חפץ",
    "חפץ חיים",
    "חצרות המושבה",
    "חרוצים",
    "חרושת",
    'חרל"פ',
    "חרש משה",
    "חתוכה זכריה",
    "טוב צפורה",
    "טוכמן יעקב",
    "טלר",
    "טפר ישראל",
    "טרומפלדור",
    "טרייבוש",
    "טשרניחובסקי",
    "י.ל. פרץ",
    "יאיר",
    "יד ושם",
    "יד לבנים",
    "ידין יגאל",
    "יהואש",
    "יהודה הלוי",
    "יהודה המכבי",
    "יהושע בן חנניה",
    "יהלום",
    "יונתן נתניהו",
    "יוסף חיים צדוק",
    "יוספזון",
    "יחזקאל",
    "יחיא יגאל",
    'יל"ג',
    "ילין חיים",
    "יעבץ",
    "יעקב",
    "יעקב ילין",
    "יעקב תמרי",
    "יעקובי יצחק",
    "יערה",
    "יפת דוד",
    "יצירה",
    "ירמיהו",
    "ישעיהו",
    "ישעיהו יוסף",
    "יתום משה",
    "כהן אורי",
    "כהן יחזקאל",
    "כהן מיכאל",
    "כהנוב אברהם",
    "כהנוביץ גרשון",
    "כובשי החרמון",
    "כובשי יוסף",
    "כוכבי דוד",
    "כישור",
    "ככר בלהה",
    "ככר העצמאות",
    "ככר יצחק שדה",
    "ככר רמה",
    "כליל",
    "כנרת",
    "כץ יצחק",
    "כצנלסון ישראל",
    "כרמל",
    "לבונה",
    "לבקוביץ",
    "לדיז'ינסקי",
    "לובה אליאב",
    "לוטם",
    "לוי אשכול",
    "לוי שלמה",
    "לוין אפשטין",
    "לוסטיג",
    "לוסטרניק אביבה ומתת",
    "לוע הארי",
    "לוצקי חוה",
    'לח"י',
    "לינדה בצלאל",
    "ליפקין",
    "לסקוב חיים",
    "לשם",
    "מאאס הרמן",
    "מאמר מרדכי",
    "מאפו",
    "מבצע ליטני",
    "מגיני הגליל",
    "מדהלה יעקב",
    "מדהלה יצחק",
    "מוגזח יצחק",
    "מודעי יצחק",
    "מוהליבר",
    "מוטה גור",
    "מוסקוביץ",
    "מוקסיי פנחס",
    "מור",
    "מורדי הגטאות",
    "מורי חיים",
    "מורן",
    "מזרחי דוד",
    "מזרחי משה",
    "מילצן חיים אליעזר",
    "מילר טובה וטוביה",
    "מכון ויצמן",
    "מלחמת יום הכפורים",
    "מלמד שלום",
    "מלמוד משה",
    "מנדלי מוכר ספרים",
    "מנוחה ונחלה",
    "מנוחין אוריאל",
    "מני עזרא",
    "מע ב רחובות",
    "מע ב רמה",
    "מע ג רחובות",
    "מע ד רחובות",
    "מע קרית משה",
    "מעון אקדמאים",
    "מעון סטודנטים",
    "מעונות ויקס",
    "מעונות שיין",
    "מפוח",
    "מצדה",
    "מקוב בתיה",
    "מרבד הקסמים",
    "מרגולין",
    "מרדכי נחמן",
    "מרווה",
    "מרטון",
    "מרים מזרחי",
    "מרכז רפואי קפלן",
    "מרשוב",
    "משקה יצחק",
    "מששוילי מנחם",
    "מתחם התחנה המרכזית",
    "נג'ארה ישראל",
    "נגבה",
    "נוה הדר",
    "נוה יהודה",
    "נוה מץ",
    "נוה עמית",
    "נווה אלון",
    "נוטמן אברהם",
    "נופר",
    "נורדאו",
    "נחושתן",
    "נחליאלי",
    "נחמני אבינועם",
    'ניל"י',
    "ניצן",
    "נפתולסקי נח",
    'נתיב הל"ה',
    "נתיב יום טוב",
    "נתיב משה",
    "נתיב נוטמן דוד",
    "ס.יזהר",
    "סדנה",
    "סוחובולסקי צבי",
    "סוקולוב",
    "סחלב",
    "סטולבוב",
    "סטרומה",
    "סיטקוב",
    "סייפן",
    "סיני",
    "סיסם",
    "סיקו מאיר",
    "סירני",
    "סירקין",
    "סיתוונית",
    "סמ אשכולית",
    "סמ בני ברית",
    "סמ האתרוג",
    "סמ הברקן",
    "סמ החרוב",
    "סמ היקינטון",
    "סמ הכרכום",
    "סמ הלילך",
    "סמ המוכתר",
    "סמ הפרדס",
    "סמ הרב בשארי שלום",
    "סמ הרבבסקי",
    "סמ השלום",
    "סמ התמר",
    "סמ חנה",
    "סמ לימון",
    "סמ משה",
    "סמ נחמני",
    "סמ תפוז",
    "סמ' שרה ומרדכי גריס",
    "סמטת היקב",
    "סמטת חביבי",
    "סמילנסקי",
    "סנה משה",
    "סעדיה גאון",
    "סעדיה הכהן",
    "ספיר",
    "סרנגה משולם",
    'עגנון ש"י',
    "עולי תימן",
    "עומר",
    "עוקשי ישראל",
    "עותמי שלום",
    "עזרא",
    "עין התכלת",
    "עינב",
    'עמ"י',
    "עמר",
    "ענבר",
    "ענתין",
    "עקיבא",
    "עשת",
    "פ.ת אפריקה ישראל",
    "פארק המדע",
    "פדובה טוביה",
    "פוגל בן ציון",
    "פומרנץ",
    "פורר יהושע",
    "פורר משה",
    "פייקס דוד",
    "פינס",
    "פינסקר",
    "פינקלשטין",
    "פינשטיין",
    "פלאוט מנחם",
    "פלדי יחיאל",
    'פלמ"ח',
    "פנחס ספיר",
    "פניגר יצחק",
    "פנקס אברהם",
    "פקיעין",
    "פקריס",
    "פרדס גאליה",
    "פרדס פישר",
    "פרוג",
    "פרופ' אומן ישראל",
    "פרופ' אורבך אפרים",
    "פרופ' ארנסט סימון",
    "פרופ' בירק יהודית",
    "פרופ' ברגמן שמואל",
    "פרופ' הרשקו אברהם",
    "פרופ' חיים הררי",
    "פרופ' כהנמן דניאל",
    "פרופ' מייקל לוויט",
    "פרופ' מילשטיין דוד",
    "פרופ' נאמן יובל",
    "פרופ' פיאמנטה משה",
    "פרופ' צ'חנובר אהרון",
    "פרופ' קציר אפרים",
    "פרופ' רבל מישל",
    "פרופ' ריבלין יוסף",
    "פרופ' רצהבי יהודה",
    "פריגוזין",
    "פריד משה",
    "פרישמן",
    "פרץ שלום",
    "פרשני אברהם",
    "פשוש",
    "צאלון",
    "צבר",
    "צופית",
    "צור שמשון",
    "צייכנר",
    "ציפורי",
    "צעידי שלום",
    "קאופמן חיים",
    "קארו",
    "קהילת טורונטו",
    "קובנר",
    "קוסובר",
    "קורנית",
    "קורצ'אק יאנוש",
    "קושלביץ בנימין",
    "קטלב",
    "קינד דוד",
    "קינד מוטי",
    "קיפניס משה",
    "קליבנר עמיחי",
    "קליין דב",
    "קלישר",
    "קלסדי דוד",
    "קנדלר",
    "קנטרוביץ",
    "קסלמן נחום",
    "קפרא מנשה",
    'קק"ל',
    "קרומר נח",
    "קרוננברג יוסף",
    "קרית דוד",
    "קרית ההגנה",
    "קרית ויז'ניץ",
    "קרית ויצמן",
    "קרית משה",
    "קרית משה דרום",
    "רוז'נסקי",
    "רוטרי",
    "רופין",
    "רופמן שמואל",
    "רח ה",
    "רח ו",
    "רח ז",
    "רח ט",
    "רח טז",
    "רחובות",
    "רחובות החדשה",
    "רחל",
    "רייפן יעקב",
    'רמב"ם',
    "רמז",
    'רמח"ל',
    "רמת אהרון",
    "רמת יגאל",
    'רש"י',
    "ש אושיות",
    "ש אסבסט קרית משה",
    "ש אפרים צריפים",
    "ש בן ציון",
    "ש גן יוסף",
    "ש הפוהמז",
    "ש וולפסון",
    "ש ותיקים",
    "ש חסכון",
    "ש מילצ'ן",
    "ש מרמורק",
    "ש נוה ויצמן",
    "ש סלע",
    "ש קלט קרית משה",
    "ש קרוונים",
    'ש"י',
    "שבזי",
    "שבתאי",
    "שד בגין מנחם",
    "שד וולקני",
    "שד חן",
    "שד מקס ואמפרו שיין",
    "שד רפאל בן מנחם",
    'שד"ל',
    "שדרת הקבוצים",
    "שוהם",
    "שוסייוב שלום",
    "שושן",
    "שושן אריה",
    "שושנת העמקים",
    "שחר ישראל",
    "שטיין משה",
    "שטינברג",
    "שיבולים",
    "שיזף",
    "שיטה מלבינה",
    "שיקמה",
    "שכ אבני חן",
    "שכ גבעתי",
    "שכ דניה",
    "שכ היובל",
    "שכ חבצלת",
    "שכ חסידי קרעשניף",
    "שכ יד ויצמן",
    "שכ מקוב",
    "שכ נאות כרמים",
    "שכ נוה אלון",
    "שכ עין גנים",
    "שכ רחובות ההולנדית",
    "שכ שלמה אבן גבירול",
    "שכ שעריים",
    "שכ תחנת רכבת",
    "שמואלין אהרן",
    "שמחי אברהם",
    "שמעון פרס",
    "שמעוני דוד",
    "שני",
    "שניר",
    "שעובי זרח",
    "שער הגיא",
    "שפינוזה ברוך",
    "שקולניק אהרן",
    "שר עירא",
    "שרעבי יעקב סעדיה",
    "שרת משה",
    "ששת הימים",
    "תאשור",
    "תדהר",
    "תור האביב",
    "תמיר עקיבא",
    'תר"ן',
    "תרשיש",
  ],
  רחלים: ["רחלים", "שכ נופי נחמיה"],
  ריחאניה: [
    "אבזאח",
    "אדיגה",
    "אזה",
    "אלברוס",
    "אסא",
    "ביסלאני",
    "ברקוק",
    "דאנה",
    "וובך",
    "וזרמס",
    "וסה",
    "ח'אן",
    "מאזה",
    "מאפה",
    "נאלמס",
    "נאלצ'יק",
    "נאספ",
    "נאפ",
    "נארת",
    "נסה",
    "נשאן",
    "סאוסרק'וה",
    "סינמיס",
    "סתנאי",
    "פסנה",
    "פשנה",
    "צייה",
    "קאזבק",
    "קברדאי",
    "קובאן",
    "ריחאניה",
    "תימר",
  ],
  ריחן: ["ריחן"],
  ריינה: [
    "אזור התעשייה",
    "אלבחשורה",
    "אלביאדר",
    "אלביר אלשמאלי",
    "אלביר אלתחתאני",
    "אלבלדה אלקדימה",
    "אלג'בל אלקבלי",
    "אלואדי אלשרקי",
    "אלמוזיה",
    "אלריינה אלג'דידה",
    "דרב אלנאסרה",
    "דרב אלסוק",
    "חי בלאל",
    "חי דאר רזק",
    "כוכב אלשמאלי",
    "מע'ר אלדור",
    "ריינה",
    "שכ כוכב הצפון",
  ],
  רימונים: [
    "אפרסמון",
    "בעלי מלאכה",
    "האירוס",
    "הבושם",
    "הגומא",
    "הגפן",
    "הזית",
    "הירדן",
    "הערבה",
    "הרדוף",
    "התאנה",
    "התמר",
    "יסמין",
    "משעול האודם",
    "משעול האתרוג",
    "משעול הדורות",
    "משעול החושן",
    "משעול הצבר",
    "משעול נווה מדבר",
    "משעול נופים",
    "נוף הרים",
    "נחל ייטב",
    "נחל פרת",
    "סלע הרימון",
    "ערבות יריחו",
    "רימונים",
    "שדרות הרימון",
  ],
  רינתיה: [
    "דרך המבואות",
    "דרך המייסדים",
    "האורנים",
    "החורש",
    "החצב",
    "החרובים",
    "הכלנית",
    "הסיגלון",
    "הפקאן",
    "הרותם",
    "הרימון",
    "הרקפת",
    "ככר האורנים",
    "ככר הזכרון",
    "ככר המייסדים",
    "רינתיה",
  ],
  רכסים: [
    "אשל",
    "בני תורה",
    "גבעה א",
    "גבעה ג",
    "גבעה ד",
    "גבעת חרובים",
    "דפנה",
    "דקל",
    "דרך המדרשים",
    "דרך הרב משקובסקי",
    "דרך פלץ יוסף",
    "דרך שרה",
    "האורנים",
    "האירוסים",
    "האלונים",
    "הגפן",
    "הדס",
    "הורדים",
    "החרוב",
    "הכלניות",
    "הנרקיסים",
    "הרב עוזיאל",
    "הרב קוק",
    "הרב קלרמן",
    "הרימונים",
    "הרקפות",
    "השקד",
    "השקמים",
    "זית",
    "חצב",
    "חרצית",
    "ככר אלון יגאל",
    "ככר מימון",
    "לב אליהו",
    "לילך",
    "מעלות דוד",
    "מרגנית",
    "נורית",
    "סביון",
    "סמ הורדים",
    "ערבה",
    "פרג",
    "קרית אור חדש",
    "רותם",
    "רכסים",
    "ש משכנות",
    "ש עמידר",
    "ש עמידר גב ב",
    "ש רסקו",
    "שמעונוביץ",
    "שעורה",
    "תאנה",
    "תמר",
  ],
  "רם-און": ["רם-און"],
  רמות: ["רמות"],
  "רמות השבים": [
    "בית העם",
    "דרך המלך",
    "דרך הסולטן",
    "האלונים",
    "האקליפטוס",
    "הבנים",
    "הברושים",
    "הבריכה",
    "הגבעה",
    "הדקל",
    "החורשה",
    "החצב",
    "הירקנים",
    "הכרם",
    "הלימון",
    "המיסדים",
    "המעגל",
    "המשאבים",
    "הפקן",
    "הפרדס",
    "הצבר",
    "השמש העולה",
    "השקד",
    "התות",
    "התמר",
    "התרנגול",
    "מעלה הורדים",
    "נווה צאלים",
    "סמ אל על",
    "סמ השדה",
    "סמ מגן",
    "סמטת הנוער",
    "רמות השבים",
    "שרת משה",
  ],
  "רמות מאיר": [
    "גבעת הכלניות",
    "דרך החממות",
    "דרך הלולים",
    "דרך הפרדסים",
    "דרך השדות",
    "הורדים",
    "המחצבה",
    "הראשונים",
    "השקמה",
    "נתיב העליה",
    "סמ הבאר",
    "סמ היקב",
    "קהילות צפון אפריקה",
    "רמות מאיר",
    "שד הזיתים",
  ],
  "רמות מנשה": ["בית ראשון במולדת", "רמות מנשה"],
  "רמות נפתלי": ["רמות נפתלי"],
  רמלה: [
    "אבו חצירה",
    "אבן חלדון",
    "אבן סינא",
    "אגוזי",
    "אגסי שמעון",
    "אדרת",
    "אהרון הכהן",
    "אוסישקין",
    "אזור התעשיה",
    "אזור התעשייה א'",
    "אזור תעשיה נשר",
    "אחד במאי",
    "אחד העם",
    "אחווה ושלום",
    "אחימאיר אבא",
    "אינשטיין",
    "איפרגן שלום",
    "אל בוסתן",
    "אל זהרא",
    "אל נור",
    "אל סולטאן",
    "אלבז נתן",
    "אלוני ניסים",
    "אלי כהן",
    "אליהו הנביא",
    "אלמוג",
    "אמדורסקי בני",
    "אמונה",
    "אמיל זולא",
    "אמנון ותמר",
    "אנגל",
    "אסבסטונים",
    "אסתר המלכה",
    'אצ"ל',
    "אקסודוס",
    "ארגוב סשה",
    "ארלוזורוב",
    "אתרוג",
    "בוגנים אהרון",
    "בורוכוב",
    "בטה עזרא",
    "ביאליק",
    "בילינסון",
    "בינט מאיר",
    "בית הסוהר",
    'בית"ר',
    "בלפור",
    "בן אליעזר אריה",
    "בן גוריון",
    "בן יהודה",
    "בן יוסף אהרון",
    "בן יוסף שלמה",
    "בן עטר חיים",
    "בן צבי יצחק",
    "בנאי יוסי",
    "בעל שם טוב",
    "בעלי המלאכה",
    "בר אילן",
    "בר יוחאי",
    "ברזילי",
    "ברלב חיים",
    "ברנר",
    "ג'ובראן חליל",
    "ג'וואריש",
    "גבורי ישראל",
    "גבע",
    "גבעת הדר",
    "גדעון",
    "גוטמן נחום",
    "גולומב",
    "גורדון",
    "גטו ורשה",
    "גלעד",
    "גן חקל",
    "גני דן",
    "גרץ",
    'ד"ר סאלק',
    'ד"ר קוך',
    "דבורה הנביאה",
    "דדון יעקב",
    "דוגית",
    "דוד אלעזר",
    "דוד המלך",
    "דוכיפת",
    "דותן דודו",
    "דיין משה",
    "דמארי שושנה",
    "דרור",
    "האורן",
    "האילנות",
    "האיריסים",
    "האלה",
    "האלונים",
    "הארון אלרשיד",
    "הארזים",
    "האשל",
    "הברוש",
    "הגדוד העברי",
    "הגלעדי",
    "הגפן",
    "הגשמה",
    "הדודאים",
    "הדס",
    "הדקל",
    "הדר",
    "ההגנה",
    "הוז דב",
    "הולצברג שמחה",
    "הושע",
    "הזית",
    "הח'ליפה סלימאן",
    "החבצלת",
    "החזון",
    "החלוץ",
    "החשמונאים",
    "החשמל",
    "היסמין",
    "היצירה",
    "הירדן",
    "הכלנית",
    "הכרמל",
    "הלילך",
    "הלל אברהם",
    "הלל הזקן",
    "המגדל הלבן",
    "המכבים",
    "המסדר הפרנציסקני",
    "המעפילים",
    "המרכז האזרחי",
    "הניצן",
    "הס",
    "העבודה",
    "העיר העתיקה",
    'הפלמ"ח',
    "הפעמונית",
    "הצנחנים",
    "הר הגלבוע",
    "הר חרמון",
    "הר מירון",
    "הר סיני",
    "הר עצמון",
    "הראל",
    "הרב אפריאט",
    "הרב הרצוג",
    "הרב טולידנו",
    "הרב לוין אריה",
    "הרב מימון",
    "הרב עוזיאל",
    "הרב עופרי שלום",
    "הרב פרנקל",
    "הרב פתיה יחזקאל",
    "הרב קוק",
    "הרב ריינס",
    "הרב שרעבי מרדכי",
    "הרב ששון ישראל",
    "הרב ששון שמחה",
    "הרדוף",
    "הרי הבשן",
    "הרי הגולן",
    "הרי יהודה",
    'הרמ"א',
    'הרמב"ם',
    "הרקפת",
    "השומר",
    "השלום",
    "השקמה",
    "השריון",
    "התאנים",
    "התבור",
    "התמר",
    "התקווה",
    "התשעה",
    "וולך יונה",
    "וילנה",
    "וינגייט",
    "ויתקין",
    "וכיל יחזקאל",
    "ורדים",
    "ז'בוטינסקי",
    "זכריה",
    "זמיר",
    "חביבי אמיל",
    "חברון",
    "חגי",
    "חוחית",
    "חזה עפרה",
    "חזקיהו אבנר",
    "חטיבת גולני",
    "חטיבת קריתי",
    "חיטמן עוזי",
    "חיים אשר",
    "חניתה",
    "חץ",
    "טוינה אברהם",
    "טופז",
    "טטראשווילי יצחק",
    "טייטלבום",
    "טרומפלדור",
    "טשרניחובסקי",
    "יבנה",
    "ידין יוסי",
    "יהודה הלוי",
    "יהונתן נתן",
    "יהושע בן נון",
    "יואל",
    "יובלים",
    "יונה הנביא",
    "יוסף חיים",
    "יוספטל גיורא",
    "יורדי הים",
    "יחזקאל",
    "יעל",
    "יצחק שלמה",
    "ירמיהו",
    "ישעיהו",
    "ככר בריל",
    "ככר דן",
    "ככר ההגנה",
    "ככר החשמל",
    "ככר המדינה",
    "כנרת",
    "כפר מיעוטים",
    "כצנלסון",
    "לבונה",
    "לביא אריק",
    'לוחמי בית"ר',
    "לוחמי גוש עציון",
    "לוחמי הבריגדה",
    "לוחמי הגטאות",
    "לוי אליהו סלם",
    "לוי אשכול",
    "לוי יחזקאל",
    "לוין חנוך",
    "לילינבלום",
    "לכיש",
    "לנדאו חיים",
    "מאיר אריאל",
    "מבצע יונתן",
    "מבצע משה",
    "מבצע קדש",
    "מוהליבר",
    "מונטיפיורי",
    "מוצקין",
    "מור",
    "מיכה",
    "מלאכי",
    "מנור אהוד",
    "מנחם דניאל",
    "מס דני",
    "מסדה",
    "מעברה ב",
    "מעברה ג",
    "מרגנית",
    "מרדכי היהודי",
    "מרדכי סעדיה",
    "מרזוק",
    "מרים הנביאה",
    "מרכוס",
    "משה רבנו",
    "נאות יצחק רבין",
    "נוה הדרים",
    "נוה מאיר",
    "נווה דקלים",
    "נווה יהונתן",
    "נווה רם",
    "נורדאו",
    "נורית",
    "נחמיה",
    "נחשול",
    "סביון",
    "סומך עובדיה",
    "סוקולוב",
    "סטרומה",
    "סיקסק",
    "סמולנסקין",
    "סמטת תוחלת",
    "סנפיר",
    "סנש חנה",
    "סעדיה גאון",
    "ספיר",
    "עובדיה",
    "עזרא",
    "עין דור",
    "עין חמד",
    "עמוס",
    "עמיחי",
    "עמיחי יהודה",
    "עמל",
    "פולד ברכה",
    "פוליאקוב ישראל",
    "פועלי ציון",
    "פינס",
    "פינסקר",
    "פלמינג",
    "פנקס",
    "פסטר",
    "פקיעין",
    "פרויד",
    "פרנקל ישראל",
    "פרץ",
    "פשוש",
    "צדקה משה",
    'צה"ל',
    "צופית",
    "צור",
    "צידון",
    "ציון אשר",
    "צלח מנשה",
    "צפור גן עדן",
    "צפניה",
    "צפת",
    "צריפי עיריה",
    "קהילת דטרויט",
    "קורצ'אק",
    "קישון אפרים",
    "קלוזנר",
    "קליטה עירונית",
    "קפלן",
    "קרית האומנים",
    "קרית מנחם בגין",
    "קרמר אריה",
    "קרן היסוד",
    "רבאט",
    "רביקוביץ דליה",
    "רותם",
    "רזיאל דוד",
    "רח כ",
    "רח לח",
    "רח עה",
    "רח רז",
    "רח תבת",
    "רח תיג",
    "רח תיז",
    "רח תכא",
    "רייסר מיכה",
    "רמלה",
    "רמת דן",
    'רש"י',
    "ש אגש",
    "ש בילו",
    "ש גיורא",
    "ש דו משפחתי",
    "ש חסכון",
    "ש כביש הטבעת",
    "ש מגדל אילון",
    "ש מול העיריה",
    "ש מזרחי",
    "ש משכנות",
    "ש נוה דוד",
    "ש נשר",
    "ש עובדים",
    "ש עולים",
    "ש עממי",
    "ש קרוונים",
    'ש"ץ גרשון',
    "שבזי",
    "שד הרצל",
    "שד ויצמן",
    "שדה יצחק",
    "שונית",
    "שטיין יהודה",
    "שטרן יאיר",
    "שיבת ציון",
    "שיזף",
    "שיטה",
    "שייקה אופיר",
    "שכ אשכול",
    "שכ בן גוריון",
    "שכ הגבעה",
    "שכ הפועלים",
    "שכ הרכבת",
    "שכ השופטים",
    "שכ וייצמן",
    "שכ יפה נוף",
    "שכ צר עמידר",
    'שכ רג"מ',
    "שכ שפרינצק",
    "שלדג",
    "שלום עליכם",
    "שלמה המלך",
    'שמ"ח',
    "שמואל הנביא",
    "שמחוני",
    "שמר נעמי",
    "שמש משה",
    "שמשון הגבור",
    "שפינוזה",
    "שפיק עדס",
    "שפירא אברהם",
    "שפירא משה חיים",
    "שפרינצק",
    "שרת",
    "תל חי",
    "תלתן",
    "תמיר שמואל",
    "תקומה",
  ],
  "רמת גן": [
    "אבא ברדיצ'ב",
    "אבטליון",
    "אביבים",
    "אביגדור קרמר",
    "אביגיל",
    "אבישי",
    "אבן אביתור",
    "אבן חביב",
    "אבן ספיר",
    "אבן עזרא",
    "אבני החושן",
    "אבנר מאיר",
    "אגמון רמת אפעל",
    "אהוד",
    "אהליאב",
    "אהרונסון שרה",
    "אונברסיטה בר אילן",
    "אורה",
    "אורי ניסן",
    "אורן",
    "אזור הבילויים",
    "אזור התעשיה",
    "אחד העם",
    "אחימאיר אבא",
    "אינשטיין",
    "איתמר",
    "איתן יונה",
    "אל על",
    "אל על רמת אפעל",
    "אלומות רמת אפעל",
    "אלונים",
    "אלוף אלברט מנדלר",
    "אלוף דוד",
    "אלוף הנצחון",
    "אלוף יצחק חופי",
    "אלוף שדה",
    "אלוף שמחוני",
    "אלימלך",
    "אלישיב",
    "אלישע",
    "אלכסנדר",
    "אלכסנדרוני",
    "אלמוג רמת אפעל",
    "אלרואי",
    "אלרום",
    "אמיר",
    "אמנון ותמר",
    "אנגלנדר יהודה",
    "אנה פרנק",
    "אנקורים",
    "אסף",
    "אפעל-מרכז סמינריוני",
    "אפרים",
    'אצ"ל',
    "ארגמן רמת אפעל",
    "ארלוזורוב",
    "ארנון",
    "ארניה",
    "אשתאול",
    "באדר יוחנן",
    "באר אורה",
    "באר שבע",
    "בדנר",
    "בועז",
    "בורסה",
    "בורסת היהלומים",
    "ביאליק",
    "בית אל",
    "בית הלל",
    "בית הערבה",
    "בית חורון",
    "בית לחם",
    "בית שמאי",
    "בן אליעזר אריה",
    'בן גוריון כפר אז"ר',
    "בן יוסף",
    "בן סרוק",
    "בן שמן",
    "בני הנביאים",
    "בני משה",
    "בניהו",
    "בנימין",
    "בעל שם טוב",
    "בצלאל",
    "בקעת ארבל",
    "בקעת גינוסר",
    "בר גיורא",
    "בר יוחאי",
    "בר כוכבא",
    "ברודצקי",
    "ברוריה",
    "ברוש",
    "ברנשטיין פרץ",
    "ברקאי",
    "ברקת רמת אפעל",
    "בשמת",
    "בת שוע",
    "גאולים",
    "גבעת גאולה",
    "גבעת הברושים",
    "גבעתי",
    "גדעון",
    "גולומב",
    "גולני",
    "גורי יהודה",
    'גיבורי מלחמת יוה"כ',
    "גילדסגיים",
    "גפונוב",
    "גרופית רמת אפעל",
    "גרנדוס",
    "גרשום",
    'ד"ר אליהו',
    'ד"ר כהן',
    "דב",
    "דבורה הנביאה",
    "דוד",
    "דולב רמת אפעל",
    "דונש",
    "דיין משה",
    "דן",
    "דן שומרון",
    "דנגור",
    "דניאל",
    "דקר",
    "דרך אבא הלל",
    "דרך אשכול",
    "דרך בגין מנחם",
    "דרך בן גוריון דוד",
    "דרך הנשיא הראשון",
    "דרך התפוצות",
    "דרך יצחק רבין",
    "דרך לוד",
    "דרך מוטה גור",
    "דרך נגבה",
    "דרך שיבא",
    "דרך ששת הימים",
    'ה באייר כפר אז"ר',
    "האגדה",
    "האגוז רמת אפעל",
    "האוכמניות רמת אפעל",
    "האורים",
    "האחות חיה",
    "האחים בז'רנו",
    "האחים ליטוינסקי",
    "האטד רמת אפעל",
    "האילנות",
    "האלה רמת אפעל",
    "האלון רמת אפעל",
    "האם",
    "האמוראים",
    'האר"י',
    "הארז",
    "האשל",
    "האשל רמת אפעל",
    "האתרוג רמת אפעל",
    "הבאר",
    "הבונים",
    "הבילויים",
    "הבנים",
    'הבנים כפר אז"ר',
    "הבשן",
    "הגאון אליהו",
    "הגולן",
    "הגלגל",
    "הגפן",
    "הגת",
    "הדודאים רמת אפעל",
    "הדס",
    "הדקלים",
    "הדר",
    "הדרורים",
    "ההגנה",
    'ההגנה כפר אז"ר',
    "ההלכה",
    "הושע",
    "הזיתים",
    "הזמיר",
    "החוגה רמת אפעל",
    "החולה",
    'החי"ל',
    "החייל האלמוני",
    "החילזון",
    "החיצון",
    "החץ",
    "החצב רמת אפעל",
    "החרוב רמת אפעל",
    "החרמון",
    "החרצית רמת אפעל",
    "החשמונאים",
    "הטייסים",
    "היד החזקה",
    'היובל כפר אז"ר',
    "היוגב",
    "היזמה",
    "היסמין רמת אפעל",
    "היצירה",
    "הירדן",
    "היתד",
    "הכבאים",
    "הלוטוס רמת אפעל",
    "הלפיד",
    "המאה ואחד",
    "המאירי",
    "המבדיל",
    "המחתרת",
    "המיתר",
    "המכבי",
    "המלאכה",
    "המלך יוסף",
    "המלך ינאי",
    "המעגל",
    "המעיין",
    "המעפיל",
    'המציי"ר',
    "המקור",
    "המרגנית",
    "המשטרה",
    "המתמיד",
    "הנגיד",
    'הנח"ל',
    'הנח"ל כפר אז"ר',
    "הנעורים",
    'הנצי"ב רמת אפעל',
    "הסולם",
    "הסנה",
    "הספארי",
    "הסרן דב",
    "העמל",
    'העצמאות כפר אז"ר',
    "הערבה רמת אפעל",
    "הערמונים",
    "הפארק הלאומי",
    "הפודים",
    "הפורצים",
    "הפטמן",
    'הפלמ"ח כפר אז"ר',
    "הפרגים רמת אפעל",
    "הפרסה",
    "הצלע",
    'הרא"ה',
    'הראב"ע',
    "הראובני",
    "הראל",
    "הראשונים",
    "הרב האלוף גורן",
    "הרב חבה אליהו",
    "הרב חי טייב יצחק",
    "הרב לוין",
    "הרב לנדרס",
    "הרב רפאל עבו",
    "הרבי מריז'ין",
    "הרדוף רמת אפעל",
    "הרי הגלעד",
    "הרימון",
    "הרכסים",
    "הרצל",
    "הרקון",
    "הרקפת רמת אפעל",
    "הררי",
    "השוטרת",
    "השומר",
    'השומר כפר אז"ר',
    "השורה",
    "השושן",
    "השיטה רמת אפעל",
    "השלושים ושלושה",
    "השקד רמת אפעל",
    "השקמה",
    "השר משה",
    "התנאים",
    "התקוה",
    "ויינשל אליעזר",
    'ז"ר',
    "ז'בוטינסקי",
    "זוהר",
    "זיסמן שלום",
    "זכריה",
    "חבצלת",
    "חבקוק",
    "חבר",
    "חברון",
    "חגי",
    "חד נס",
    "חורגין",
    'חזון אי"ש',
    "חיבת ציון",
    "חיים בר-לב",
    "חמי זכריה",
    "חמיר",
    "חצור",
    "חרוד",
    "חרוזים",
    "חרות",
    "טבנקין",
    "טור הברושים",
    "טרומן",
    'י"א באדר',
    "יאיר",
    "יבנה",
    "יד לבנים",
    "יד שלום",
    "ידידיה",
    "ידע עם",
    "יהודה הנשיא",
    "יהודית",
    "יהושע בן נון",
    "יהושפט",
    "יהלום רמת אפעל",
    "יואב",
    "יוחנן",
    "יונה",
    "יוסף הגלילי",
    "יוסף צבי",
    "יוספי",
    "יחזקאל",
    "יכין",
    "יעבץ",
    "יעל הגבורה",
    "יעלים",
    "יערה",
    "יריחו",
    "ירמיה",
    "ישורון",
    "ישעיהו",
    "כביש נתיבי אילון",
    "כביש עוקף",
    "כהן יעקב",
    "כיכר אלוף דני מט",
    "כיכר מלכיאל בבייב",
    "ככר גרונר",
    "ככר הלוחמים",
    "ככר העצמאות",
    "ככר הצבי",
    "ככר הציונות",
    "ככר הרב פרדס",
    "ככר יהודה",
    "ככר נורי מורד",
    "ככר עוזי חיטמן",
    'ככר רמב"ם',
    "כנפי נשרים",
    "כנרת",
    "כף גימל",
    'כפר אז"ר',
    "כפר המכביה",
    "כפר מסובים רמת אפעל",
    "כרמלי",
    "לאן",
    "לב הזהב",
    "לבונה רמת אפעל",
    "לביא",
    "לוחמי סיני",
    "לוי",
    "לויטה",
    'לח"י',
    "לייב יפה",
    "לנדאו חיים",
    "מאיר בעל הנס",
    "מבוא אנה פרנק",
    "מבוא אריה",
    "מבוא דוד",
    "מבוא העמל",
    "מבוא הפודים",
    "מבוא הקונגרס",
    "מבוא הרימון",
    "מבוא התיכון",
    "מבוא זאב",
    "מבוא חרמון",
    'מבוא יהל"ם',
    "מבוא מנדס",
    "מבוא נגבה",
    "מבוא נס",
    "מבוא נתן",
    "מבצע עין",
    "מבצע קדש",
    "מגדים",
    "מודעי יצחק",
    "מוזס נח",
    "מולכו",
    "מוצא",
    "מורדי הגטאות",
    "מוריה",
    "מחולה רמת אפעל",
    "מחניים",
    "מחץ",
    "מטה אהרון",
    "מטולה",
    "מיכאל",
    "מיכה",
    "מירקין",
    'מל"ל',
    "מלאכי",
    "מלחי שלמה",
    "מלכי צדק",
    "מנדל מתתיהו",
    "מנדס",
    "מנחם",
    "מנחם יצחק",
    "מעלה הבנים",
    "מעלה הנשר",
    "מעלה הצבי",
    "מעלה הצופים",
    "מעלה השואבה",
    "מעלה השחר",
    "מצדה",
    "מצפה",
    "מקדונלד",
    "מקס ואנה ווב",
    "מרדכי",
    "מרים",
    'מרכז רפואי ע"ש שיבא',
    "משואה רמת אפעל",
    "משמר הירדן",
    "משעול ספראי",
    "משעול צבי ינאי",
    "מתחם האיצטדיון",
    "מתחם מסובים",
    "מתחם נגבה",
    "מתחם קניון איילון",
    "מתניה",
    "נבטים רמת אפעל",
    "נדוני קהיר",
    "נהרדעא",
    "נוה יהושע",
    "נוה יעקב",
    "נווה אפעל",
    "נורדאו",
    "נורית",
    "נורית רמת אפעל",
    "נחום",
    "נחלה",
    "נחליאלי",
    "נחלת יוסף",
    "נחשון",
    "נטר",
    "נץ",
    "נצנים",
    "נרדימון",
    "נשיאי ישראל",
    "נתן",
    "סאלק",
    "סביון",
    "סוקולוב",
    "סורא",
    "סטופ",
    "סטרומה",
    "סיגלית",
    "סיירת דוכיפת",
    "סמ אילון",
    "סמ אלברט",
    "סמ בדש ציון",
    'סמ ברקת ר"א',
    "סמ הבאר",
    "סמ היסמין",
    "סמ המעלות",
    "סמ השלוח",
    "סמ התלמיד",
    "סמ חיים אינטרטור",
    "סמ יפתח",
    "סמ לבני",
    "סמ ספיר רמת אפעל",
    "סמ עמק האלה",
    "סמ קרן",
    "סמ שער הגיא",
    "סמדר",
    "סמטת מנשה",
    'סמטת ק"ם',
    "סן מרטין",
    "סנהדרין",
    "סנש חנה",
    "ספיר יוסף",
    "ספיר רמת אפעל",
    "עובדיה",
    "עודד",
    "עוזיאל",
    "עולי הגרדום",
    "עזריאל",
    "עטרות",
    "עידו",
    "עין יהב",
    "עיר גנים",
    "עלומים",
    "עליה ראשונה",
    "עליה שלישית",
    "עליה שניה",
    "עלית הנוער",
    "עמוס",
    "עמינדב",
    "עמיקם",
    "עמק דותן",
    "עמק האלה",
    "עמק הארזים",
    "עציון",
    "פבריגט",
    "פומבדיתא",
    'פלי"ם כפר אז"ר',
    "פנחס",
    "פני הגבעה",
    "פנקס",
    "פרוג שמעון",
    "פרופס",
    "פתייה",
    "צאלים",
    "צאלים רמת אפעל",
    "צבי",
    'צה"ל כפר אז"ר',
    "צומת לבבי",
    "צופר רמת אפעל",
    "צור רמת אפעל",
    "צוריאל",
    "צל הגבעה",
    "צפניה",
    "קארו יוסף",
    "קדמה",
    "קדרון",
    "קוממיות",
    "קיש",
    "קלאוזנר",
    "קליר אברהם ונעימה",
    "קניון אילון",
    "קפלן",
    "קציעה רמת אפעל",
    "קציר",
    "קרייתי",
    "קריניצי",
    "קרית בורוכוב",
    "קרית גן",
    "קרית סגל נווה אפעל",
    "קרית קריניצי",
    "קרסקי",
    'רא"ל משה לוי',
    "ראש פנה",
    "רבדים",
    "רבי",
    'רד"ק',
    "רודין",
    "רוזן",
    "רוחמה",
    "רוקח",
    "רות",
    "רותם",
    "רזיאל",
    "רחובות הנהר",
    "רייק חביבה",
    "רימון רמת אפעל",
    "רימלט אלימלך",
    "רמבה",
    "רמז",
    "רמת אפעל",
    "רמת גן",
    "רמת חן",
    "רמת עמידר",
    "רמת שיקמה",
    "רננים",
    'רפאל איתן כפר אז"ר',
    "רקפת",
    'רש"י',
    "ש אחיות",
    "ש אקדמאים",
    "ש גרין",
    "ש הבולגרים",
    "ש הפומז",
    "ש חרות",
    "ש מזרחי",
    "ש מפדה אזרחי",
    "ש משכנות",
    "ש סלמה",
    "ש סלמה מזריץ",
    "ש סלע",
    "ש עירוני",
    "ש עממי רסקו",
    "ש עמק דותן",
    "ש פרדס אברלה",
    "ש פרוגרסיבים",
    "ש צבא קבע",
    "ש צנחנים",
    "ש רמת יצחק",
    "ש רסקו",
    'ש"י עגנון',
    "שאנן",
    "שאר ישוב",
    "שד אברהם",
    "שד בן צבי",
    "שד האורנים רמת אפעל",
    "שד הארזים רמת אפעל",
    "שד הברושים רמת אפעל",
    "שד הגבורים",
    "שד הטייס",
    "שד הילד",
    "שד הכלניות",
    "שד הנרקיסים",
    "שד העם הצרפתי",
    "שד הצנחנים",
    "שד הקונגרס",
    "שד השחרור",
    "שד התמרים",
    "שד טובים",
    "שד יהדות פילדלפיה",
    "שד ירושלים",
    "שד למדן",
    "שד נחמה",
    'שד ק"ם',
    "שדרות הצבי",
    'שדרות ויצ"ו',
    "שדרת גידי",
    "שוהם",
    "שועלי שמשון",
    "שושנים",
    'שז"ר',
    "שחם רמת אפעל",
    "שיאון",
    'שיכון בנים כפר אז"ר',
    "שיכון ותיקים",
    "שיכון עלית",
    "שילה",
    "שכ בר אילן",
    "שכ גב גאולה",
    "שכ גפן",
    "שכ הלל",
    "שכ חרוזים",
    "שכ מרום נווה",
    "שכ נחלת גנים",
    "שכ ק בורוכוב",
    "שכ ראשונים",
    "שכ שפירא",
    "שכ תל גנים",
    "שכ תל יהודה",
    "שכטרמן רמת אפעל",
    "שלום עליכם",
    "שלומציון",
    "שלם",
    "שמואלי",
    "שמחה",
    "שמעוני",
    "שמרלינג",
    "שניאור זלמן",
    "שפירא",
    "שקדיה",
    "שרירא גאון",
    "שרת משה",
    "שתיל",
    "תדהר",
    "תדהר רמת אפעל",
    "תובל",
    "תל בנימין",
    "תל השומר",
    "תל חי",
    'תל חי כפר אז"ר',
    "תלפיות",
    "תמרי נחמיה",
    "תנועת המרי",
    'תע"ש',
    "תפארת ישראל",
    "תפארת צבי רמת אפעל",
    'תרס"ח',
    'תרע"ד',
    'תרפ"ב',
    "תרצה",
    'תש"י',
  ],
  "רמת דוד": ["מחנה יהודית", "רמת דוד"],
  "רמת הכובש": ["רמת הכובש"],
  "רמת השופט": ["בית ראשון במולדת", "רמת השופט"],
  "רמת השרון": [
    "אבו חצירה",
    "אגוז",
    "אגרת תימן",
    "אהוד",
    "אוסישקין",
    "אופיר",
    'אז"ר',
    "אזור בתכנון",
    "אזור תעשייה",
    "אחד העם",
    "איה",
    "אילון",
    "אילנות",
    "אילת",
    "איריס",
    "אלה",
    "אלון יגאל",
    "אלי כהן",
    "אני מאמין",
    "אנפה",
    "אנקור",
    "ארבל",
    "ארז",
    "ארינה",
    "ארלוזורוב",
    "ארנון",
    "אשל אברהם",
    "בגין מנחם",
    "בוסתנאי",
    "בועז",
    "בוקק",
    "בית גוברין",
    "בית הלל",
    "בית השואבה",
    "בית שמאי",
    "בלפור",
    "בן גוריון",
    "בן זכאי",
    "בן חיים",
    "בן נון יהושע",
    "בן שלום",
    "בנימיני צבי",
    "בר יוחאי",
    "בר כוכבא",
    "בראשית",
    "ברנשטיין כהן",
    "ברק",
    "בשן",
    "גאולים",
    "גבעון",
    "גד",
    "גדעון",
    "גולומב",
    "גולן",
    "גונדר אברהם",
    "גורדון",
    "גוש חלב",
    "גיחון",
    "גלילי ישראל",
    "גלעד",
    "גמלא",
    "גמליאל",
    "גן הבנים",
    "דבורה הנביאה",
    "דגן",
    "דוכיפת",
    "דולב",
    "דותן דודו",
    "דן",
    "דקל",
    "דרך הטניס",
    "דרך הצעירים",
    "דרך ראשונים",
    "האומנויות",
    "האורן",
    "האחיות",
    "האלון",
    "האלוף יצחק חופי",
    'האצ"ל',
    'האר"י',
    "האתרוג",
    "הבאר",
    "הבנים",
    'הבעש"ט',
    "הברושים",
    "הברכה",
    "הבשם",
    "הגבעה",
    "הגדודים",
    "הגיא",
    "הגינה",
    "הגליל",
    "הגנים",
    "הגפן",
    "הדגנית",
    "הדיה",
    "הדסים",
    "הדר",
    "הדרור",
    "ההגנה",
    "הוז דב",
    "הושע",
    "הזוהר",
    "הזורע",
    "הזית",
    "הזמיר",
    "החוגה",
    "החוחית",
    'החי"ל',
    "החלוץ",
    "החרוב",
    "החרש",
    "הטבק",
    "היוגב",
    "היונה",
    "היסעור",
    "הכרמל",
    "הלפיד",
    "המאבק",
    "המור",
    "המחתרת",
    "המייסדים",
    "המכבים",
    "המלאכה",
    "המלכים",
    "המסגר",
    "המעין",
    "המעפילים",
    "המצור",
    "המרד",
    "המרי",
    "המתנחלים",
    'הנ"ץ',
    "הנביאים",
    "הנוטע",
    "הנוטר",
    "הנוער",
    "הנצח",
    "הנשר",
    "הס משה",
    "הסיפן",
    "העבודה",
    "העומר",
    "העמל",
    "העמק",
    "העפרוני",
    "הפורצים",
    'הפלמ"ח',
    "הפרדס",
    "הפרחים",
    "הצופים",
    "הצפורן",
    "הקבוץ",
    "הקוצר",
    "הקישון",
    "הר נבו",
    "הרב אורבוך",
    "הרב הרצוג",
    "הרב קוק",
    "הרב ריינס",
    "הרדוף",
    "הרמון",
    "השדות",
    "השדמה",
    "השומר",
    "השומרון",
    "השופטים",
    "השחף",
    "השיטה",
    "השילוח",
    "השלום",
    "השמורה",
    "השפלה",
    "השקד",
    "השקמה",
    "השרון",
    "התאנה",
    "התומר",
    "התלמים",
    "התקוה",
    "התרבות",
    "ויתקין",
    "ז' בחשון",
    "ז'בוטינסקי",
    "זלמן שניאור",
    "זרובבל",
    "זרזיר",
    "חבצלת",
    "חטיבת אלכסנדרוני",
    "חטיבת הנגב",
    "חטיבת הראל",
    "חיש",
    "חרושת",
    "חרמון",
    "חשמונאים",
    "טהון",
    "טווס",
    "טרומפלדור",
    "יאיר",
    "יבוק",
    "יבנה",
    "יגיע כפים",
    "יהודה",
    "יהודה הלוי",
    "יהודה הנשיא",
    "יוני נתניהו",
    "יותם",
    "יחיעם",
    "יפתח",
    "יצהר",
    "יצחק אלחנן",
    "יצחק שדה",
    "ירדן",
    "ירמוך",
    "ירמיהו",
    'כ"ט בנובמבר',
    "כהן יעקב",
    "ככר ההסתדרות",
    "ככר העצמאות",
    "כלנית",
    "כפר אוריה",
    "כצנלסון",
    "כרכום",
    "לבונה",
    "לוחמי הגטאות",
    'לח"י',
    "לילך",
    "לכיש",
    "למרחב",
    "מבצע קדש",
    "מגידו",
    "מודיעין",
    "מוהליבר",
    "מוריה",
    "מורן",
    "מורשה שלב ג",
    "מסדה",
    "מע א ר השרון",
    "מעון אקדמאים",
    "מעונות מורשה",
    "מעין חרוד",
    "מצדה",
    "מרגנית",
    "מרדכי",
    "מרכז הטניס",
    "מתחם גלילות",
    'מתחם תע"ש',
    "נהרדעא",
    "נווה רעים",
    "נורדאו",
    "נורית",
    "נחל בשור",
    "נחל עמוד",
    "נחל קדרון",
    "נחל שורק",
    "נחשון",
    "נטף",
    'ניל"י',
    "נעמי",
    "נצנה",
    "נרקיסים",
    "סולד",
    "סוקולוב",
    "סיני",
    "סלע",
    "סמ אגדים",
    "סמ אלחריזי",
    "סמ אסף",
    "סמ בית שמאי",
    "סמ במעלה",
    "סמ געתון",
    "סמ דליה",
    "סמ היסמין",
    "סמ המעלות",
    "סמ המשי",
    "סמ הנרקיס",
    "סמ הקמה",
    "סמ הרב",
    'סמ ויצ"ו',
    "סמ ורד",
    "סמ חצב",
    "סמ חרצית",
    "סמ יואב",
    "סמ לוטם",
    "סמ למרחב",
    "סמ מגד",
    "סמ מעין חרוד",
    "סמ נחל קדרון",
    "סמ סביון",
    "סמ סיגלית",
    "סמ פיקר",
    "סמ צאלון",
    "סמ צבעוני",
    "סמ צלף",
    "סמ רקפת",
    "סמ שאר ישוב",
    "סמ שוהם",
    "סמדר",
    "סנה משה",
    "סנונית",
    "סנש חנה",
    "עבדת",
    "עגור",
    "עגנון",
    "עוזיה",
    "עזרא",
    "עין גדי",
    "עליה",
    "עצמון",
    "ערוגות",
    "פדויים",
    "פי גלילות",
    "פרץ",
    "פשוש",
    "צבר",
    "ציפורי",
    "צירלסון",
    "קבוץ גלויות",
    "קדושי סטרומה",
    "קדש",
    "קהילת בבל",
    "קהילת וילנה",
    "קהילת לודג'",
    "קוממיות",
    "קרית יערים",
    "קרן היסוד",
    "רב מכר",
    "רבנו תם",
    "רגבים",
    'רד"ק',
    "רות",
    "רותם",
    "רזיאל",
    'רמב"ם',
    "רמז",
    "רמת השרון",
    "רצועת הנופש",
    "רצועת ספורט ונופש",
    'רש"י',
    'רשב"ם',
    "ש גבעת הלבונה",
    "ש גולן",
    "ש המועצה",
    "ש הפועלים",
    "ש חסכון",
    "ש יוספטל",
    "ש ליטא",
    "ש מומחים",
    "ש מורשה חדש",
    "ש מזרחי",
    "ש משכנות",
    "ש נוה ארנון",
    "ש נוה רום",
    "ש נוה רסקו",
    "ש נווה מגן",
    "ש עובדים",
    "ש עולים",
    "ש עממי",
    "ש עממי חדש",
    "ש פקר",
    "ש פרוגרסיבים",
    "ש צבא קבע",
    "ש קדמה",
    "ש קוטגים",
    "ש קליפורניה",
    "ש רסקו מערב",
    "שארית ישראל",
    "שבזי",
    "שבטי ישראל",
    "שבי ציון",
    "שד ביאליק",
    "שד האלוף מאיר עמית",
    "שד הילד",
    "שד ויצמן",
    "שד יריב אהרון",
    "שושן",
    'שח"ל',
    "שיבולת",
    "שינקין",
    "שיפון",
    "שכ אלון",
    "שכ הדר",
    "שכ הכפר הירוק",
    "שכ נווה גן",
    "שכ עמידר",
    "שכ ק שאול",
    "שלדג",
    "שלום עליכם",
    "שמגר",
    "שמואל הנגיד",
    "שמחה הולצברג",
    "שמשון",
    "שניר",
    "שץ צבי",
    "שרף",
    "ששת הימים",
    "תבור",
    "תחכמוני",
    "תירוש",
    "תמנע",
    'תע"ש',
    "תענך",
    "תרזה",
    'תרפ"ד',
    "תרשיש",
  ],
  "רמת יוחנן": ["אולפן", "רמת יוחנן", "שכ קרוונים"],
  "רמת ישי": [
    "אבוקדו",
    "אגס",
    "אדמונית החורש",
    "אורנית",
    "אזור תעשיה",
    "אחירותם",
    "אלמון",
    "אמנון ותמר",
    "אפרסמון",
    "אפרסק",
    "אקליפטוס",
    "אשל",
    "גומא",
    "דבורנית",
    "דובדבן",
    "דפנה",
    "האגוז",
    "האורן",
    "האיריסים",
    "האלון",
    "הארז",
    "האתרוג",
    "הברוש",
    "הדגן",
    "הדקל",
    "ההדס",
    "החצבים",
    "היסמין",
    "היקינטון",
    "הכלניות",
    "המרגניות",
    "הנרקיסים",
    "הערבה",
    "הפרגים",
    "הפרדס",
    "הצאלון",
    "הצבעוני",
    "הצפצפה",
    "הרותם",
    "הרימון",
    "הרקפות",
    "השיזף",
    "השקמה",
    "התאנה",
    "חבצלת",
    "חוות נוה יער",
    "חורש האלונים",
    "חלמית",
    "חרצית",
    "יערה",
    "כליל החורש",
    "לימון",
    "מורן",
    "מטע",
    "מנגו",
    "מעלה דולב",
    "מעלה הגפן",
    "מעלה החורש",
    "מעלה הרדוף",
    "מעלה כרכום",
    "מעלה לבונה",
    "מעלה פרג",
    "מעלה שיטים",
    "מעלה שקמה",
    "מרווה",
    "נקטרינה",
    "סיגליות",
    "סמ אשחר",
    "סמ האלה",
    "סמ ההדר",
    "סמ הורד",
    "סמ הזית",
    "סמ החרוב",
    "סמ הכרם",
    "סמ הלוטם",
    "סמ הסביון",
    "סמ השיזף",
    "סמ השסק",
    "סמ השקד",
    "סמ התמר",
    "סמ לילך",
    "סמ לימונית",
    "סמ מור",
    "סמ מרגנית",
    "סמ נוריות",
    "סמ ציפורן",
    "סמ ריחן",
    "ספיון",
    "סתוונית",
    "עירית",
    "צבר",
    "קוקוס",
    "קורנית",
    "רוזמרין",
    "רמת ישי",
    "ש עובדים",
    "ש עמידר",
    "שכון ב",
    "שכון חילים",
    "תות",
    "תלתן",
    "תפוח",
  ],
  "רמת מגשימים": ["רמת מגשימים"],
  "רמת צבי": ["רמת צבי"],
  "רמת רזיאל": ["רמת רזיאל"],
  "רמת רחל": ["אכסנית נוער", "גדוד העבודה", "רמת רחל"],
  רנן: ["הורד", "הנורית", "הסייפן", "רנן"],
  רעים: ["רעים"],
  רעננה: [
    "אבן גבירול",
    "אבן עזרא",
    "אברבנאל",
    "אבשלום",
    "אהוד מנור",
    "אהרונסון שרה",
    "אוסטרובסקי",
    "אופסטרלנד",
    "אזולאי",
    "אזור התעשיה",
    'אח"י דקר',
    "אחד העם",
    "אחוזה",
    "אינשטיין",
    "אלחריזי",
    "אלי כהן",
    "אלכסנדר פן",
    "אלעזר דוד",
    "אלפסי",
    "אלקלעי",
    "אלתרמן נתן",
    "אנדה עמיר",
    "אנה פרנק",
    "אנילביץ",
    "אסירי ציון",
    "אסתר המלכה",
    "אריה",
    "אריק לביא",
    "ארלוזורוב",
    "אשכול לוי",
    "אשר",
    "בגין מנחם",
    "בובר",
    "בורוכוב",
    "בורלא יהודה",
    "ביאליק",
    'ביל"ו',
    "בית אבות אחוזת בית",
    "בית אבות משען",
    "בית הלל",
    "בית השואבה",
    "בית שמאי",
    "בן גוריון",
    "בן זכאי",
    "בן יהודה",
    "בנטוב מרדכי",
    "בני אמדורסקי",
    "בר אילן",
    "בר יוחאי",
    "בר כוכבא",
    "בר-גיורא",
    "ברלב חיים",
    "ברנדיס",
    "ברנר",
    "ג'ו עמר",
    "גאולה",
    "גובר רבקה",
    "גוטמן נחום",
    "גולדברג לאה",
    "גולדה מאיר",
    "גולומב",
    "גונן שמואל",
    "גור מרדכי",
    "גורדון",
    "גרונר דב",
    "גרינברג אורי צבי",
    "גרץ",
    "דבורה עומר",
    "דגניה",
    "דובנוב",
    "דוכיפת",
    "דולב",
    "דורי יעקב",
    "דיין משה",
    "דליה רביקוביץ'",
    "דפנה",
    "דרוקר",
    "דרור",
    "דרך האוניברסיטה",
    "דרך החקלאים",
    "דרך הפארק",
    "דרך כפר נחמן",
    "האגם",
    "האופק",
    "האורן",
    "האחווה",
    "האיריס",
    "האלה",
    "האלון",
    'האצ"ל',
    'האר"י',
    "הארז",
    "האשד",
    "האשל",
    "הבדולח",
    "הבנים",
    "הברוש",
    "הבריכה",
    "הגדוד העברי",
    'הגדנ"ע',
    "הגולן",
    "הגלבוע",
    "הגליל",
    "הגלים",
    "הגפן",
    "הדליה",
    "הדקל",
    "ההגנה",
    "ההסתדרות",
    "הוז דב",
    "הולצברג שמחה",
    "הורד",
    "הזוהר",
    "הזז חיים",
    "הזית",
    "הזמיר",
    "החוחית",
    "החורש",
    "החורשה",
    'החי"ל',
    "החלוץ",
    "החליל",
    "החצב",
    "החרוב",
    "החרושת",
    "החרמון",
    "החרצית",
    "החשמונאים",
    "הטייסים",
    "הטללים",
    "היהלום",
    "היובל",
    "היסמין",
    "היצירה",
    "היקינתון",
    "הירדן",
    "הירמוך",
    "הכוכב",
    "הכלנית",
    "הכרמל",
    'הל"ה',
    "הלבונה",
    "הלוטוס",
    "הלימון",
    "המחתרות",
    "המיסדים",
    "המיתר",
    "המלאכה",
    "המלכים",
    "המסגר",
    "המעיין",
    "המעלות",
    "המעפילים",
    "המרגנית",
    "המרד",
    "הנבל",
    "הנגב",
    "הנוטרים",
    "הנופר",
    "הנורית",
    "הנחל",
    "הנרקיס",
    "הנשיאים",
    "הנשר",
    "הס משה",
    "הסביון",
    "הסדנא",
    "הסולן",
    "הסחלב",
    "הסיגלית",
    "הסייפן",
    "הסנונית",
    "העבודה",
    "העיט",
    "העפרוני",
    "העצמאות",
    "הערבה",
    'הפלמ"ח',
    "הפנינה",
    "הפעמונים",
    "הפרחים",
    "הצאלון",
    "הצבעוני",
    "הצבר",
    "הצליל",
    "הצנחנים",
    "הקדושים",
    "הקישון",
    "הקרן",
    "הקשת",
    "הר סיני",
    "הרב אפרים",
    "הרב בן אור",
    "הרב נסים יצחק",
    "הרב קוק",
    "הרדוף",
    "הרותם",
    "הרמון",
    "הרצוג",
    "הרצל",
    "הרקפת",
    "השביט",
    "השומר",
    "השוק",
    "השחפים",
    "השחר",
    "השיזף",
    "השיטה",
    "השלום",
    "השנהב",
    "השקד",
    "השקמה",
    "השרון",
    "השריון",
    "התאנה",
    "התבור",
    "התדהר",
    "התור",
    "התחיה",
    "התמר",
    "התנאים",
    "התעשיה",
    "התפוז",
    "התקוה",
    "התקומה",
    "וינגייט",
    "ויצמן",
    "ולנברג ראול",
    "ז'בוטינסקי",
    "זרחין אלכסנדר",
    "חזון איש",
    "חזן יעקב",
    "חטיבת אלכסנדרוני",
    "חטיבת גבעתי",
    "חטיבת גולני",
    "חטיבת הראל",
    "חיבת ציון",
    "חיים חפר",
    "חייקה גרוסמן",
    "חנקין",
    "חפץ חיים",
    "טבנקין יצחק",
    "טרומפלדור",
    "טשרניחובסקי",
    "יאיר",
    "יאיר רוזנבלום",
    "ידין יגאל",
    "יהודה הלוי",
    "יהודה הנשיא",
    "יהודה עמיחי",
    "יעקב אורלנד",
    "יערה",
    "יפה אליעזר",
    "יפה ירקוני",
    "יצחק גרציאני",
    "יצחק שדה",
    "יציאת אירופה",
    "ירושלים",
    "כביש חיפה",
    "כהן-כגן רחל",
    "כליל החורש",
    "כנרת",
    "כפר בתיה",
    "כפר נחמן",
    "כצנלסון ברל",
    "לב הפארק",
    "לבהרי",
    "לזר",
    'לח"י',
    "לסקוב חיים",
    "מאה וששים",
    "מאפו",
    "מבצע קדש",
    "מגדל",
    "מולדת",
    "מונטיפיורי",
    "מוצקין",
    "מורדי הגטאות",
    "מורשה",
    "מחנה עולים",
    "מכבי",
    "מנדלי",
    "מניה שוחט",
    "מסדה",
    "מעון לעובד",
    "מקלף מרדכי",
    "מרדכי זעירא",
    "מרים ילן שטקליס",
    "מרכז קליטה",
    "משה וילנסקי",
    "נאות הדר",
    "נאות שדה",
    "נג'ארה",
    "נוה ברכה",
    "נוה דוד רמז",
    "נורדאו",
    "נחמיה",
    "נחשון",
    'ניל"י',
    "נעמי שמר",
    "נתיב האבות",
    "נתן יונתן",
    "סולד",
    "סוקולוב",
    "סטרומה",
    "סירקין",
    "סמ הגשר",
    "סמ הדס",
    "סמ החומה",
    "סמ החשמונאים",
    "סמ המכבים",
    "סמ נוגה",
    "סמ רקפת",
    "סנה משה",
    "סנש חנה",
    "סעדיה גאון",
    "סשה ארגוב",
    "ע. הלל",
    "עגנון",
    "עוזי חיטמן",
    "עטרות",
    "עלי מוהר",
    "עמל",
    "עפרה חזה",
    "עציון",
    "עקיבא",
    "עתידים",
    "פוזין",
    "פטריה",
    "פינס",
    "פינסקר",
    "פרדס משותף",
    "פרישמן",
    "פרץ",
    "צביה לובטקין",
    "צימנד",
    "ציפמן",
    "קארו יוסף",
    "קבוץ גלויות",
    "קוממיות",
    "קורצ'ק ינוש",
    "קזן",
    "קלאוזנר",
    "קלישר",
    "קפלן",
    "קצין",
    "קרית בן צבי",
    "קרית גולומב",
    "קרית גנים",
    "קרית הספורט",
    "קרית וייצמן",
    'קרית שז"ר',
    "קרית שרת",
    "קרן היסוד",
    "רבוצקי",
    "רבן גמליאל",
    "רוזן פנחס",
    "רזיאל דוד",
    "רחל המשוררת",
    "ריינס",
    "רייק חביבה",
    "רימלט אלימלך",
    'רמב"ם',
    'רמח"ל',
    "רננים",
    "רעננה",
    'רש"י',
    "ש בלוקונים",
    "ש חדש",
    "ש מזרחי",
    "ש עובדים",
    "ש עולים",
    "ש עמידר",
    "ש עממי",
    "ש פועלים",
    "שבזי",
    "שבטי ישראל",
    "שביל הזהב",
    "שברץ",
    "שושנה דמארי",
    "שכ אחד העם",
    "שכ אריה",
    "שכ אשר",
    "שכ לזר",
    "שכ לסטר",
    "שכ נאות שדה",
    "שכ פרץ",
    "שכ ציפמן",
    "שכ רום 2000",
    "שלום עליכם",
    "שלונסקי אברהם",
    "שליט",
    "שמואל הנגיד",
    "שמריהו לוין",
    "שפינוזה ברוך",
    "שפירא איזי",
    "שפירא משה",
    "ששת הימים",
    "תל חי",
    "תרצה אתר",
  ],
  רקפת: ["רקפת"],
  רשפון: [
    "דפנה",
    "דרך הגן",
    "דרך ההרדופים",
    "דרך המסילה",
    "דרך הפקאנים",
    "דרך הפרדס",
    "דרך השיבולים",
    "האתרוג",
    "החרוב",
    "הכפר",
    "הפרחים",
    "הרותם",
    "הרימון",
    "השקד",
    "התמר",
    "התפוז",
    "סמ האירוס",
    "סמ הכלנית",
    "סמ הנורית",
    "סמ הצבעוני",
    "סמ הרקפת",
    "רשפון",
    "שביל החלב",
    "שביל התפוזים",
  ],
  רשפים: ["רשפים"],
  רתמים: ["מרכז קליטה", "רתמים"],
  "שאר ישוב": ["טל", "יובלים", "נחלים", "פלגי מים", "שאר ישוב"],
  "שבי דרום": ["שבי דרום"],
  "שבי ציון": [
    "אלון",
    "דקל",
    "דרך הים",
    "האלה",
    "הברוש",
    "הגפן",
    "הזית",
    "החוף",
    "המנור",
    "המפרש",
    "הנחשול",
    "הסירה",
    "העוגן",
    "הפנגווין",
    "הצלצל",
    "הרדוף",
    "התורן",
    "ורד",
    "טיילת מיכה",
    "כוכב הים",
    "נר הלילה",
    "סנונית",
    "קונכיה",
    "קרן",
    "שבי ציון",
    "שחף",
    "שיקשק",
    "תמירון",
  ],
  "שבי שומרון": ["ש קרוונים", "שבי שומרון", "שובו בנים"],
  "שבלי - אום אל-גנם": [
    "אבו מדוור",
    "אום אל-גנם",
    "אום בוטמה",
    "אום ג'בל",
    "אלאנואר",
    "אלבטין",
    "אלביאדר",
    "אלבלאטה",
    "אלבלוט",
    "אלברכה",
    "אלג'אמע",
    "אלגרז",
    "אלד'הרה",
    "אלד'יאבי",
    "אלדבאצ'ה",
    "אלדום",
    "אלוסטאני",
    "אלוסעה",
    "אלזהרא",
    "אלזור",
    "אלזיתון",
    "אלח'לה",
    "אלח'רוב",
    "אלחאווז",
    "אלחארה",
    "אלטבקה",
    "אלטור",
    "אללוז",
    "אלמג'ארה",
    "אלמג'יר",
    "אלמדנג'את",
    "אלמדראג'",
    "אלמדרסה",
    "אלמזאר",
    "אלמחאג'ר",
    "אלמטבאת",
    "אלמידאן",
    "אלמצאיה",
    "אלמת'לת'",
    "אלסוואנייה",
    "אלסנדיאן",
    "אלסריס",
    "אלע'אבה",
    "אלעבהר",
    "אלעיאדה",
    "אלפרדוס",
    "אלפרש",
    "אלצנובר",
    "אלקסאטל",
    "אלקסברה",
    "אלרביד'את",
    "אלרואג'יח",
    "אלרינה",
    "אלרמאן",
    "אלשראר",
    "אלשתול",
    "אלתון",
    "באבור אלעת'מאן",
    "ביר אללוזה",
    "ביר אלשרקה",
    "ג'ורת אלג'זלאן",
    "ד'ראע איוב",
    "ואדי אלמשתא",
    "ח'לת אלנסור",
    "ח'לת אלפול",
    "חואסה",
    "מקאם אמנה",
    "מקאם עאסי",
    "סביתה",
    "סוק אלח'אן",
    "סרטבה",
    "עין אלזעטוטה",
    "עין אלטביל",
    "עין אלקסיב",
    "עין אלתינה",
    "עין מקלד",
    "עלואן",
    "קסטה",
    "שבלי",
    "שבלי - אום אל-גנם",
  ],
  "שגב-שלום": [
    "אבו אלעתאהיה",
    "אבו בקר אלסדיק",
    "אבו חניפה אלנעמאן",
    "אבו עוביידה אלגראח",
    "אבו תמאם",
    "אבונא אבראהים",
    "אבונא אדם",
    "אבן אלהיתם",
    "אבן אלמוקפע",
    "אבן סינא",
    "אבן עוף",
    "אחמד אבן חנבל",
    "אחמד זויל",
    "אחסאן עבד אלקדוס",
    "אל אנביא",
    "אל גזאלי",
    "אלאנדלוס",
    "אלגולאן",
    "אלדיואן",
    "אלוורוד",
    "אלזהרא",
    "אלירמוך",
    "אלכנסא",
    "אלמדינה",
    "אלמוסתקבל",
    "אלפרדוס",
    "אלקאדסיה",
    "אלקודס",
    "אלקורונפול",
    "אלראזי",
    "אלשאפעי",
    "אמראו אלקיס",
    "בדר",
    "בילאל אבן רבאח",
    "ג'אבר אבן חיאן",
    "ג'בראן חליל ג'בראן",
    "האגר",
    "הארון אלרשיד",
    "ואדי מכה",
    "זהיר אבן אלעואם",
    "זמזם",
    "חאלד אבן אלוליד",
    "חטין",
    "טאהא חוסין",
    "טארק אבן זיאד",
    "מאלק אבן אנס",
    "מואתא",
    "מוסא אבן מימון",
    "מכה",
    "מלך חוסין",
    "נבי מוסא",
    "נביא איוב",
    "נהאונד",
    "נוח",
    "סידנא יוסף",
    "סלאח אלדין",
    "סלמאן אלפארסי",
    "סעד בן אבי וקאס",
    "עבאס אבן פרנאס",
    "עומר אבן אלח'טאב",
    "עומר אלח'יאם",
    "עיסא",
    "עלי בן אבו טאלב",
    "עמאן",
    "עמר אלמח'תאר",
    "ענתר אבן שדאד",
    "עסגלאן",
    "ערפה",
    "עתמאן אבן עפאן",
    "שגב-שלום",
    "שייך עודה",
    "שכ 1",
    "שכ 2",
    "שכ 3",
    "שכ 4",
    "שכ 5",
    "שכ 6",
    "שכ 7",
    "שכ 8",
  ],
  "שדה אילן": [
    "אסיף",
    "גורן",
    "חריש",
    "כרם",
    "מגל",
    "מסיק",
    "קציר",
    "שדה אילן",
    "תירוש",
  ],
  "שדה אליהו": ["אולפן קיבוץ", "שדה אליהו"],
  "שדה אליעזר": ["שדה אליעזר"],
  "שדה בוקר": ["שדה בוקר"],
  "שדה דוד": ["שדה דוד"],
  "שדה ורבורג": [
    "דום הדקל",
    "דרך גן הילדים",
    "דרך האורנים",
    "דרך הדקל",
    "דרך ההדרים",
    "דרך הפרחים",
    "האלה",
    "האלון",
    "הארז",
    "הברוש",
    "הגפן",
    "ההדס",
    "הזית",
    "הרימון",
    "השקד",
    "השקמה",
    "סמ הילד",
    "שדה ורבורג",
    "שכון בנים",
  ],
  "שדה יואב": ["שדה יואב"],
  "שדה יעקב": [
    "דרך ארץ",
    "דרך האלון",
    "דרך המיסדים",
    "דרך המעין",
    "דרך הנחל",
    "דרך השדות",
    "דרך חריש",
    "דרך עליית הנוער",
    "דרך קטיף",
    'דרך שח"ל',
    "כיכר המחלבה",
    "מעלה הגבעה",
    "מעלה הגורן",
    "מעלה המגדל",
    "משעול הגנים",
    "נוה עמיאל",
    "שדה יעקב",
  ],
  "שדה יצחק": [
    "האלון",
    "הברוש",
    "הזית",
    "המייסדים",
    "הערבה",
    "הפיקוס",
    "הצאלון",
    "השיטה",
    "התמר",
    "שדה יצחק",
  ],
  "שדה משה": ["שדה משה"],
  "שדה נחום": ["שדה נחום"],
  "שדה נחמיה": ["שדה נחמיה"],
  "שדה ניצן": ["שדה ניצן"],
  "שדה עוזיהו": ["שדה עוזיהו"],
  "שדה צבי": ["שדה צבי"],
  "שדות ים": ["נופשונית", "שדות ים"],
  "שדות מיכה": ["שדות מיכה"],
  "שדי אברהם": ["שדי אברהם"],
  "שדי חמד": [
    "אורן",
    "אלון",
    "ארז",
    "ברוש",
    "גפן",
    "הדס",
    "החרצית",
    "זית",
    "חיטה",
    "סמטת שיטה",
    "רימון",
    "שבעת המינים",
    "שדי חמד",
    "שדרת הדקלים",
    "שיקמה",
    "שעורה",
    "תאנה",
    "תמר",
  ],
  "שדי תרומות": ["שדי תרומות"],
  שדמה: [
    "הזורעים",
    "החורשה",
    "המורג",
    "הנוקדים",
    "הקוצרים",
    "סמטת המגל",
    "שדמה",
    "שיכון בנים",
  ],
  "שדמות דבורה": ["שדמות דבורה"],
  "שדמות מחולה": ["שדמות מחולה"],
  שדרות: [
    "אבו חצירה ישראל",
    "אבן גבירול",
    "אדמונית החורש",
    "אהבת ישראל",
    "אהבת ציון",
    "אהוד מנור",
    "אוסלו",
    "אורנים",
    "אזור התעשיה",
    "אחד העם",
    "אלבז נתן",
    "אלון יגאל",
    "אלישע הנביא",
    "אלנבי",
    "אלעזר דוד",
    "אלתרמן נתן",
    "אמנון ותמר",
    "אמסטרדם",
    "אסירי ציון",
    "ארזים",
    "אריק איינשטיין",
    "אריק לביא",
    "ארן זלמן",
    "אשור",
    "אתונה",
    "בורוכוב",
    "ביאליק",
    "בית אהרון",
    "בלגרד",
    "בלפור",
    "בן יהודה",
    "בן צבי יצחק",
    "בר אילן",
    "בר-לב חיים",
    "בריסל",
    "ברית ערים",
    "ברנר",
    "בשמת",
    "ג'ו עמר",
    "גולומב",
    "גולני",
    "גורדוניה",
    "גלילי ישראל",
    "גרינבאום",
    "גרנית",
    "דבלין",
    "דגניה",
    "דורי יעקב",
    "דיין משה",
    "דליה",
    "דניאל",
    "דרך דוד כהן",
    "דרך ההנדסה",
    "דרך העליה",
    "דרך הרב שלום איפרגן",
    "דרך יצחק שמיר",
    "דרך מאיר גולדה",
    "דרך רונית אלקבץ",
    "האג",
    "האחוזה",
    "האלה",
    "האמוראים",
    'האצ"ל',
    'האר"י',
    "האשל",
    "הבנים",
    "הבעל שם טוב",
    "הגיא",
    "הגיבורים",
    "הגליל",
    "הגפן",
    "הדגל",
    "ההגנה",
    "ההסתדרות",
    "ההעפלה",
    "הולצברג שמחה",
    "הורד",
    'החיד"א',
    "החלוץ",
    "החשמונאים",
    "הלל הזקן",
    "הלל יפה",
    "הלסינקי",
    "המגינים",
    "המכבים",
    "המעפילים",
    "הנחלים",
    "הנציב",
    "הס משה",
    "העצמאות",
    'הפלמ"ח',
    "הצאלה",
    "הקוממיות",
    "הקונגרס",
    "הרב אליישיב",
    "הרב בן נון יעקב",
    "הרב דוד בר חן",
    "הרב יעקב יצחקי",
    "הרב כדורי",
    "הרב מוהליבר",
    "הרב מימון",
    "הרב מרדכי אליהו",
    "הרב עובדיה יוסף",
    "הרב קוק",
    "הרב שך",
    "הרב שלום משאש",
    "הרבי מילובביץ",
    "הרימון",
    "הרצוג",
    "הרצל",
    "הרצפלד",
    "הרקפת",
    "השומר",
    "השופטים",
    "השיטה",
    "השקד",
    "השרון",
    "התמר",
    "ויצמן",
    "ויתקין",
    "ונציה",
    "ורדימון",
    "זוהרה אלפסיה",
    "זכריה",
    "זרח משה",
    "חבקוק",
    "חברון",
    "חגי",
    "חומה ומגדל",
    "חות שקמים",
    "חזון איש",
    "חטיבת גבעתי",
    "חטיבת הנגב",
    "חטיבת הראל",
    "חטיבת יפתח",
    "חטיבת כרמלי",
    "חטיבת צנחנים",
    "חליל",
    "חללי אגוז",
    "חצב",
    "חצוצרה",
    "טיומקין",
    "טיילת ספיר פנחס",
    "טרומפלדור",
    "יבניאלי",
    "יהדות איטליה",
    "יהודה הלוי",
    "יהודה עמיחי",
    "יהושע בן נון",
    "יואל",
    "יונה",
    "יונתן פולארד",
    "יוסף חיים",
    "יוספטל",
    "יורדי הסירה",
    "יחזקאל",
    "יערה",
    "יצחק שדה",
    "יקינטון",
    "ירושלים",
    "ירמיהו",
    "ישיבת ציון",
    "ישעיהו",
    "ישראל ישעיהו",
    "כוכבית",
    "כיכר אנטוני",
    "כיכר בגין",
    "כיכר בית החיים",
    "כיכר בראשית",
    "כיכר גנדי",
    "כיכר דוד המלך",
    "כיכר האחוזה",
    "כיכר האיבה",
    "כיכר הבנים",
    "כיכר הברזל",
    "כיכר הגבורה",
    "כיכר הגופר",
    "כיכר הדורות",
    "כיכר החלוצים",
    "כיכר החליל",
    "כיכר הכינור",
    "כיכר המדע",
    "כיכר המוסיקה",
    "כיכר המלאכה",
    "כיכר המלך חסן",
    "כיכר הנביאים",
    "כיכר הנבל",
    "כיכר הסטודנטים",
    "כיכר הרכבת",
    "כיכר השעון",
    "כיכר התעשיה",
    'כיכר חב"ד',
    "כיכר חנוכייה",
    "כיכר יהונתן יפרח",
    "כיכר מוטה גור",
    "כיכר ספרא",
    "כיכר ציון",
    "כיכר צלנדרוף",
    'כיכר קק"ל',
    "כיכר קריספל",
    "כיכר רפול",
    "כיכר שמחה ברנר",
    "כיכר שמעון עמר",
    "כיכר שרון",
    "כינור",
    "ככר המושבות",
    "כלנית",
    "כצנלסון",
    "לאה גולדברג",
    "לוטוס",
    "לוטם",
    "לונדון",
    'לח"י',
    "ליסבון",
    "מבצע אנטבה",
    "מבצע חורב",
    "מבצע יואב",
    "מבצע יונתן",
    "מבצע משה",
    "מבצע סיני",
    "מבצע עובדה",
    "מבצע קדש",
    "מדריד",
    "מונטיפיורי משה",
    "מוצקין",
    "מיכה",
    "מלאכי",
    "מצדה",
    "מקווה ישראל",
    "מרגנית",
    "מרים הנביאה",
    "מרכז קליטה",
    "משה רבנו",
    "משעול אברהם",
    "משעול אימבר",
    "משעול איריס",
    'משעול ביל"ו',
    "משעול האלונים",
    "משעול האתרוג",
    "משעול הדס",
    "משעול הטייסים",
    "משעול הירדן",
    'משעול הל"ה',
    "משעול המחתרות",
    "משעול המייסדים",
    "משעול הנרקיס",
    "משעול הנשיא",
    "משעול הערבה",
    "משעול חובבי ציון",
    "משעול חנקין",
    "משעול יעקב",
    "משעול יצחק",
    "משעול יציאת אירופה",
    'משעול כי"ח',
    "משעול לילך",
    "משעול סטרומה",
    "משעול פועלי ציון",
    "משעול פעמונית",
    "משעול פתח תקוה",
    'משעול קק"ל',
    "משעול רבקה",
    "נאות אביב",
    "נאות אשכול",
    "נאות הדקל",
    "נאות המייסדים",
    "נאות הנביאים",
    "נאות הנשיא",
    "נאות יצחק רבין",
    "נבל",
    "נוגה",
    "נורית",
    "נחום",
    "נחל איילון",
    "נחל אשלים",
    "נחל דולב",
    "נחל הבשור",
    "נחל חידקל",
    "נחל לכיש",
    "נחל ניצנה",
    "נחל פרת",
    "נחל צאלים",
    "נחל שיזף",
    "נחל שקמה",
    "נחשון",
    'ניל"י',
    "ניס",
    "ניצן",
    "ניצנה",
    "ניר עם",
    "נעמי שמר",
    "נצח ישראל",
    "נתן",
    "סביון",
    "סחלב",
    "סיטאר",
    "סייפן",
    "סמ אגוז",
    "סמ דולב",
    "סמ האלון",
    "סמ הפלדה",
    "סמ השיקמה",
    "סמי אלמוגרבי",
    "ספי ריבלין",
    "סתוונית",
    "עגנון",
    "עוזי חיטמן",
    "עולי הגרדום",
    "פינס",
    "פינסקר",
    "פנקס",
    "פסנתר",
    "פראג",
    "פריז",
    "צבעוני (טוליפ)",
    "ציפורן",
    "קארו יוסף",
    "קבוץ גלויות",
    "קהילת מילנו",
    "קופנהגן",
    "קרן היסוד",
    "קרן קיימת לישראל",
    "רב בן ציון אבא שאול",
    "רבי דוד בוזגלו",
    "רבי דוד חסין",
    "רבי משה אלבז",
    "רוטשילד",
    "רומא",
    "רופין",
    "רזיאל דוד",
    "רחל",
    'רמב"ם',
    "ש זוג צעירים",
    "ש פטיו",
    "שאר ישוב",
    "שבזי שלום",
    "שביט",
    "שד בגין מנחם",
    "שדרות",
    "שושן צחור",
    'שז"ר זלמן',
    "שכ בן גוריון",
    "שכ ברנר שמחה",
    "שכ הברוש",
    "שכ חופית",
    "שכ מ-3",
    "שכ' בן גוריון ב'",
    "שכ' הכלניות",
    "שכ' המוסיקה",
    "שכ' יוקרה",
    "שכ' נאות השיקמה",
    "שמואל",
    "שמשון",
    "שניאור זלמן",
    "שנית",
    "שפירא חיים",
    "שרה",
    "שרת משה",
    "תופים",
    "תל חי",
  ],
  שואבה: ["חוות שער הגיא", "שואבה"],
  שובה: ["שובה"],
  שובל: ["אולפן קיבוץ", "שובל"],
  שוהם: [
    "ארז",
    "אשכול",
    "בזלת",
    "ברוש",
    "ברקת",
    "בשמת",
    "גזית",
    "גפן",
    "גרניט",
    "דולב",
    "דקל",
    "האודם",
    "האלה",
    "האתרוג",
    "הבשור",
    "הזוהר",
    "החבל",
    "החושן",
    "הירדן",
    "הלימון",
    "הלפיד",
    "המכבים",
    "הסלע",
    "הפרדס",
    "הקשת",
    "התפוז",
    "זית",
    "זמורה",
    "חיטה",
    "חפר",
    "חצב",
    "חרמון",
    "טופז",
    "יהלום",
    "יזרעאל",
    "יסמין",
    "כיכר אגמית",
    "כיכר אנפה",
    "כיכר ברבור",
    "כיכר דוכיפת",
    "כיכר דרור",
    "כיכר זהבן",
    "כיכר זמיר",
    "כיכר זרזיר",
    "כיכר חוחית",
    "כיכר חסידה",
    "כיכר טסית",
    "כיכר יונה",
    "כיכר ינשוף",
    "כיכר יסעור",
    "כיכר כנרית",
    "כיכר מגלן",
    "כיכר נחליאלי",
    "כיכר נץ",
    "כיכר סופית",
    "כיכר סייפן",
    "כיכר סנונית",
    "כיכר עגור",
    "כיכר עפרוני",
    "כיכר פשוש",
    "כיכר צופית",
    "כיכר צוקית",
    "כיכר שחף",
    "כיכר שלדג",
    "כיכר שקנאי",
    "כיכר שרקרק",
    "כיכר תנשמת",
    "כרכום",
    "כרמל",
    "לכיש",
    "לשם",
    "מודיעים",
    "מעלה הגלבוע",
    "מעלה החורש",
    "מעלה העצמאות",
    "מצפה",
    "מרגלית",
    "נורית",
    "נרקיס",
    "סמ הארז",
    "סמטת האשכוליות",
    "ספיר",
    "עדולם",
    "עינבר",
    "ענב",
    "ערבה",
    "צור",
    "צורן",
    "קדם",
    "רותם",
    "רחבת בדולח",
    "רחבת גזית",
    "רחבת גת",
    "רחבת החולה",
    "רחבת חלמיש",
    "רחבת כלנית",
    "רחבת נגה",
    "רחבת נטיף",
    "רחבת עדי",
    "רחבת פנינה",
    "רימון",
    "רקפת",
    "שגב",
    "שגיא",
    "שד עמק איילון",
    "שוהם",
    "שחם",
    "שיבולת",
    "שיזף",
    "שיטה",
    "שכ אלונים",
    "שכ ברושים",
    "שכ גבעולים",
    "שכ הדסים",
    "שכ ורדים",
    "שכ חמניות",
    "שכ טללים",
    "שכ יובלים",
    "שכ יעלים",
    "שכ סחלבים",
    "שכ רקפות",
    "שני",
    "שקמה",
    "שריג",
    "תאנה",
    "תבור",
    "תירוש",
    "תמר",
    "תענך",
    "תפן",
    "תרשיש",
  ],
  שומרה: ["שומרה"],
  שומריה: ["שומריה"],
  שוקדה: ["שוקדה"],
  שורש: [
    "האלה",
    "האלון",
    "הזית",
    "החרוב",
    "הלוטם",
    "הפסגה",
    "הרימון",
    "השיזף",
    "השקד",
    "טיילת שלום",
    "יפה נוף",
    "לבונה",
    "מלון שורש",
    "שורש",
  ],
  שורשים: ["שורשים"],
  "שושנת העמקים": [
    "אמנון ותמר",
    "האשל",
    "הדקל",
    "ההדר",
    "החוף",
    "צוקי ים",
    "שושנת העמקים",
  ],
  שזור: ["שזור"],
  שחר: [
    "הבציר",
    "המייסדים",
    "המסיק",
    "הקטיף",
    "הראשונים",
    "קיבוץ גלויות",
    "שדרת הדקלים",
    "שחר",
  ],
  שחרות: ["שחרות"],
  שיבולים: ["שיבולים"],
  שיטים: ["שיטים"],
  "שייח' דנון": ["שייח' דנון"],
  שילה: [
    "אבני החושן",
    "אוהל מועד",
    "אורים ותומים",
    "אחיה",
    "אלקנה",
    "ארגמן",
    "ארון הברית",
    "ארץ חפץ",
    "אש הקודש",
    "ברכת האילנות",
    "גאולים",
    "גאולת הארץ",
    "גורל הנחלות",
    "האגוז",
    "האפוד",
    "הארז",
    "הביכורים",
    "הגפן",
    "ההדס",
    "הזית",
    "החבצלת",
    "הכרם",
    "המסילה העולה",
    "המעמר",
    "הפרוכת",
    "הקטורת",
    "הר אפרים",
    "הרואה",
    "הרימון",
    "השני",
    "התאנה",
    "התכלת",
    "התפוח",
    "טו באב",
    "י באייר",
    "מחול הכרם",
    "מלאכות המשכן",
    "מעלה הארון",
    "מעלה הברית",
    "מעלה החושן",
    "מעלה הכרם",
    "מעלה המחול",
    "מעלה העדות",
    "מעלה הקטורת",
    "מעלה השני",
    "מקורות",
    "משכן העדות",
    "משעול ההר",
    "משעול החינוך",
    "נחלה",
    "עד כי יבוא שילה",
    "עדי עד",
    "עולי הרגל",
    "עצי שיטים",
    "קידה",
    "שבות רחל",
    "שילה",
    "שכ הנחלות",
    "שכ עלי הכהן",
    "שכ רמת שמואל",
    "תפילת חנה",
  ],
  שילת: [
    "אירוס",
    "אלה",
    "אלון",
    "ברוש",
    "גפן",
    "דרך האורנים",
    "דרך הזית",
    "דרך החממות",
    "דרך החרוב",
    "דרך החשמונאים",
    "דרך המכבים",
    "ורד",
    "חצב",
    "חרצית",
    "יסמין",
    "נורית",
    "סמ הערבה",
    "פארק תעשיות ומסחר",
    "רותם",
    "רימון",
    "רקפת",
    "שילת",
    "שקד",
    "תאנה",
  ],
  שכניה: ["שכניה"],
  שלווה: ["שלווה"],
  "שלווה במדבר": ["שלווה במדבר"],
  שלוחות: ["שלוחות"],
  שלומי: [
    "אזור התעשייה",
    "אחיהוד בן שלומי",
    "איבי הנחל",
    "אלבז נתן",
    "אנפה",
    "גבעת הארזים",
    "דוכיפת",
    "דרור",
    "דרך אזולאי סנדי",
    "דרך הארזים",
    "האלה",
    "האלון",
    "הבריאה",
    "ההבשלה",
    "ההמצאה",
    "ההשראה",
    "ההתגשמות",
    "הזריעה",
    "החורש",
    "החורשה",
    "הטיילת האנגלית",
    "היער הקסום",
    "היצירה",
    "הכריה",
    "המייסדים",
    "הפריחה",
    "הצמיחה",
    "הקציר",
    "הרב חזן מסעוד",
    "הרב מימון",
    "הרב עובדיה יוסף",
    "הרב עוזיאל",
    "התנופה",
    "ז'בוטינסקי",
    "זיכרון משה",
    "זמיר",
    "חוף הים",
    "חורשת האקליפטוס",
    "חלוקי הנחל",
    "יסמין",
    "יער חניתה",
    "יפה נוף",
    "יצחק רבין",
    "כחל",
    "לילך",
    "מעברת שלומי",
    "מעלה שטרן יאיר",
    "מרכז קליטה",
    "נווה רבין",
    "נוף הרים",
    "נורית",
    "ניצן",
    "נרקיס",
    "סחלב",
    "סמטת העייט",
    "סמטת נחל ייטב",
    "סמטת נחל נטפים",
    "סנונית",
    "עפרוני",
    "עץ המשאלות",
    "עץ השדה",
    "ערבי הנחל",
    "פנינת הצפון",
    "קהילת בריטניה",
    "קהילת שוויץ",
    "רח מס' 22",
    "רח מס' 34",
    "רח מס' 35",
    "רח מס' 36",
    "רח מס' 38",
    "רקפת",
    "שביל האודם",
    "שביל הברקת",
    "שביל היהלום",
    "שביל הלשם",
    "שביל הספיר",
    "שד בן גוריון",
    "שדרות יערית",
    "שושן",
    "שחף",
    "שכ בן גוריון",
    "שכ בנה ביתך",
    "שכ דדו",
    "שכ מורד ההר",
    "שכ שלומית",
    "שכונת יערית",
    "שלומי",
    "ששת הימים",
    "תור",
  ],
  שלומית: ["שלומית"],
  שמיר: ["בית ראשון במולדת", "שמיר"],
  שמעה: ["שמעה"],
  שמרת: [
    "אורים",
    "אורן",
    "אלה",
    "אלמוג",
    "ארז",
    "דקל",
    "דרור",
    "הדר",
    "חצב",
    "יחדיו",
    "ניצנים",
    "עומר",
    "רימון",
    "שחף",
    "שיבולים",
    "שלהבת",
    "שמרת",
    "שקד",
  ],
  שמשית: [
    "אודם",
    "אלמוג",
    "ארגמן",
    "ברקת",
    "גביש",
    "גזית",
    "גרניט",
    "האשד",
    "חושן",
    "יובלים",
    "לשם",
    "מעיינות",
    "מפלים",
    "מרגלית",
    "נחלים",
    "ספיר",
    "ענבר",
    "פלג",
    "צורן",
    "שוהם",
    "שחם",
    "שמשית",
    "שני",
    "תכלת",
  ],
  שני: ["שני"],
  שניר: [
    'בי"ס שדה חרמון',
    "דרך הבניאס",
    "דרך הגולן",
    "דרך הדולב",
    "דרך הדן",
    "דרך החצבני",
    "דרך החרמון",
    "דרך הירדן",
    "דרך המעיין",
    "דרך המפל",
    "דרך הסער",
    "דרך השיאון",
    "שניר",
  ],
  שעב: ["שעב"],
  שעורים: ["שעורים"],
  שעל: ["שעל"],
  שעלבים: ["שעלבים"],
  "שער אפרים": [
    "אודם",
    "אלמוג",
    "ברקת",
    "גריזים",
    "הארבל",
    "הארז",
    "הגלבוע",
    "הגלעד",
    "הגפן",
    "ההדס",
    "ההרים",
    "הזמורה",
    "החרמון",
    "הלשם",
    "הנופר",
    "הסלעים",
    "הספיר",
    "הרימון",
    "התאנה",
    "התבור",
    "התמר",
    "טופז",
    "יהלום",
    "נופך",
    "עצמון",
    "שגיא",
    "שד הזיתים",
    "שוהם",
    "שנהב",
    "שניר",
    "שער אפרים",
  ],
  "שער הגולן": ["בית ראשון במולדת", "שער הגולן"],
  "שער העמקים": ["שער העמקים"],
  "שער מנשה": ["שער מנשה"],
  "שערי תקווה": [
    "אביבים",
    "אבנט",
    "אודם",
    "אלמוג",
    "אפוד",
    "בזלת",
    "ביריה",
    "ברקת",
    "גרניט",
    "דגניה",
    "דן",
    "דפנה",
    "האורן",
    "האיילים",
    "האירוס",
    "הזית",
    "החצב",
    "הירקון",
    "הכלנית",
    "העופרים",
    "הצבאים",
    "הצבעוני",
    "הרקפת",
    "הררית",
    "השקד",
    "התאנה",
    "חושן",
    "חלמיש",
    "חרסית",
    "יהלום",
    "כנרת",
    "נגבה",
    "סלע",
    "ספיר",
    "ענבר",
    "צוק",
    "שהם",
    "שערי תקווה",
    "תל חי",
    "תרשיש",
  ],
  שפיים: ["שמורת השרון", "שפיים"],
  שפיר: [
    "האיכרים",
    "האסיף",
    "הבציר",
    "הגדיד",
    "הגורן",
    "הזורע",
    "החורש",
    "הנוטע",
    "שפיר",
  ],
  שפר: ["חמדת ימים", "שפר"],
  שפרעם: [
    "אבו אלנג'ק",
    "אבו אלסראיא",
    "אבו ד'ר אלע'פארי",
    "אבו חרב",
    "אבו מסנסל",
    "אבו סברי חמדי",
    "אבו סלמא",
    "אבו סמרה",
    "אבו סנאן",
    "אבו עפן",
    "אבו שאח",
    "אבו שהאב",
    "אבו שושי",
    "אבו שח'",
    "אבו ת'אבת",
    "אבו תמאם",
    "אבטין",
    "אבל אלכמח",
    "אגזם",
    "אדוארד סעיד",
    "אוול אייאר",
    "אום אלדרג'",
    "אום אלזינאת",
    "אום אלחממיס",
    "אום אלסחאלי",
    "אום אלפרג",
    "אום אלתות",
    "אום כולתום",
    "אום ראשד",
    "אום רומאנה",
    "אום רושרוש",
    "אזור",
    "אזור התעשיה",
    "אזעפראן",
    "אחמד בן בלא",
    "אחמד שאוקי",
    "איבן אלעמיד",
    "איבן אלרושד",
    "איבן ח'לדון",
    "איבן סינא",
    "איברהים נימר חוסין",
    "איליא אבו מאדי",
    "אירביד",
    "אל עבד אלנבי",
    "אל-וז",
    "אל-לאתין",
    "אל-לג'ון",
    "אל-לד",
    "אל-לוז",
    "אל-לימון",
    "אלאבל",
    "אלאג'אס",
    "אלאדיב",
    "אלאום תריזא",
    "אלאח'טל",
    "אלאחסאן",
    "אלאמאני",
    "אלאסאיל",
    "אלאסכנדריה",
    "אלאע'אני",
    "אלאפגאני",
    "אלאקצה אלעמריה",
    "אלארז",
    "אלבאב אלע'רבי",
    "אלבאבור",
    "אלבאז",
    "אלבארוק",
    "אלבוכארי",
    "אלבוראק",
    "אלבורג'",
    "אלבוריני",
    "אלבורתקאל",
    "אלבחר",
    "אלבטריק",
    "אלביטריה",
    "אלביידר",
    "אלביירוני",
    "אלבירה",
    "אלבלאבל",
    "אלבלאט",
    "אלבלאן",
    "אלבלוט",
    "אלבלח",
    "אלבס",
    "אלבסה",
    "אלבסליה",
    "אלבקארה",
    "אלבראדיש",
    "אלברגותי",
    "אלברווה",
    "אלברי",
    "אלברכה-העיר העתיקה",
    "אלברכוכ",
    "אלבשת",
    "אלג'אחט'",
    "אלג'אמע",
    "אלג'בל",
    "אלג'ורן",
    "אלג'זר",
    "אלג'יש",
    "אלג'עונה",
    "אלג'רוס",
    "אלג'רמק",
    "אלגאזאליאת",
    "אלגליל",
    "אלד'מידה",
    "אלדאמון",
    "אלדהאר",
    "אלדואלי",
    "אלדוארה",
    "אלדוראת",
    "אלדורי",
    "אלדיר",
    "אלדמירי",
    "אלדעקה",
    "אלדראג'",
    "אלדרבאשי",
    "אלדרדאר",
    "אלהאשמי",
    "אלהוארה-העיר העתיקה",
    "אלהודהוד",
    "אלואדי",
    "אלוליד",
    "אלוני",
    "אלוסטאני",
    "אלוער",
    "אלזאהריה",
    "אלזאויה",
    "אלזהרה",
    "אלזודאת",
    "אלזיאן",
    "אלזיזפון",
    "אלזייתון",
    "אלזין",
    "אלזנבק",
    "אלזערור",
    "אלזעתר",
    "אלזקאק",
    "אלזרזור",
    "אלזרעיה",
    "אלזרקא",
    "אלח'וארזמי",
    "אלח'ולפא",
    "אלח'וריה",
    "אלח'טיב",
    "אלח'לאייל",
    "אלח'לוה-העיר העתיקה",
    "אלח'ליל",
    "אלח'מסה",
    "אלח'נדק-העיר העתיקה",
    "אלח'נסא",
    "אלח'רוב",
    "אלח'רוביה",
    "אלחאג'",
    "אלחבק",
    "אלחג'אג'",
    "אלחג'אז",
    "אלחג'יראת",
    "אלחג'ל",
    "אלחגוג",
    "אלחדאד",
    "אלחדת'ה",
    "אלחולה",
    "אלחוסרום",
    "אלחוסרי",
    "אלחורש",
    "אלחי אלח'דר",
    "אלחיזב",
    "אלחילף",
    "אלחלאג'",
    "אלחמאם",
    "אלחמדאני",
    "אלחמיד",
    "אלחסבה",
    "אלחסון",
    "אלחסן",
    "אלחרש",
    "אלטאבע'ה",
    "אלטאחונה",
    "אלטבק",
    "אלטברי",
    "אלטואל",
    "אלטיאר",
    "אלטיור",
    "אלטייבה",
    "אלטירה",
    "אליאכוק",
    "אליאסמין",
    "אלימאמה",
    "אלימן",
    "אלירמוק",
    "אלכאברי",
    "אלכאשף",
    "אלכברה",
    "אלכדסה",
    "אלכוטראן",
    "אלכותר",
    "אלכמאני",
    "אלכמון",
    "אלכנאר",
    "אלכנדי",
    "אלכסאיר",
    "אלכסאר",
    "אלכסאראת",
    "אלכסברה",
    "אלכסטל",
    "אלכסתנא",
    "אלכעביה",
    "אלכראג'",
    "אלכראויה",
    "אלכרוואן",
    "אלכרז",
    "אלכרך",
    "אלכרכס",
    "אלכרם",
    "אלכרמל",
    "אלכתאב",
    "אלמבארקה",
    "אלמג'דל",
    "אלמדאחל",
    "אלמדרסה",
    "אלמדרסה אלת'נאויה",
    "אלמהנא",
    "אלמוח'תאר",
    "אלמוחסן",
    "אלמוטראן חג'אר",
    "אלמונדיה",
    "אלמועלם בולוס",
    "אלמוקדסי",
    "אלמותנבי",
    "אלמזאר",
    "אלמזרעה",
    "אלמחאדד",
    "אלמחבה",
    "אלמחפרה",
    "אלמטלה",
    "אלמטר",
    "אלמידאן",
    "אלמכמאנה",
    "אלמכתב-העיר העתיקה",
    "אלמלעב אלריאד'י",
    "אלמנאג'ר",
    "אלמנארה",
    "אלמנטאר",
    "אלמנטרה",
    "אלמנסורה",
    "אלמנפלוטי",
    "אלמנשיה",
    "אלמסלא",
    "אלמסלח'",
    "אלמע'אוור",
    "אלמע'ארבה",
    "אלמעסרה",
    "אלמערי",
    "אלמראח-העיר העתיקה",
    "אלמרג'",
    "אלמריכ",
    "אלמשארף",
    "אלמשהד",
    "אלמשמש",
    "אלנאבלסי",
    "אלנאעורה",
    "אלנאעמה",
    "אלנאקורה",
    "אלנבי סאלח",
    "אלנבי רובין",
    "אלנבעה",
    "אלנג'אר",
    "אלנגאח",
    "אלנהר",
    "אלנורס",
    "אלנימר",
    "אלנירוז",
    "אלנמוז",
    "אלנסר",
    "אלנעום",
    "אלנענע",
    "אלנפאפעה",
    "אלנקב",
    "אלנרג'ס",
    "אלסאלחה",
    "אלסאפח",
    "אלסביל",
    "אלסג'רה",
    "אלסהל",
    "אלסובאט",
    "אלסוכר",
    "אלסוק",
    "אלסוקור",
    "אלסורה",
    "אלסלבאק",
    "אלסמאן",
    "אלסמארה",
    "אלסמיריה",
    "אלסנדיאנה",
    "אלסנובר",
    "אלסעדה",
    "אלסעדיה",
    "אלספאח",
    "אלספאר",
    "אלספסאף",
    "אלספרה",
    "אלסקר",
    "אלסרג'את",
    "אלסרוו",
    "אלסריס",
    "אלע'אבסיה",
    "אלע'אזי",
    "אלע'אר",
    "אלע'בראא",
    "אלע'ד",
    "אלע'דיר",
    "אלע'ורבאל",
    "אלע'זאלי",
    "אלע'ית",
    "אלע'נאמה",
    "אלע'נטוס",
    "אלעאסי",
    "אלעבאסיה",
    "אלעבוד",
    "אלעג'וז",
    "אלעהדה אלעומריה",
    "אלעוואדיה",
    "אלעוודה",
    "אלעוכל",
    "אלעוליק",
    "אלעטאריה",
    "אלעיון",
    "אלעין",
    "אלעיקאב",
    "אלעמאר",
    "אלעמורי",
    "אלענאב",
    "אלענדליב",
    "אלעסקלאני",
    "אלעקבי",
    "אלעראקיב",
    "אלערישה",
    "אלפאלוג'ה",
    "אלפארוק",
    "אלפארסי",
    "אלפג'את",
    "אלפהד",
    "אלפואר מערבי",
    "אלפוסתוק",
    "אלפורן",
    "אלפיג'ן",
    "אלפל",
    "אלפלפל",
    "אלפר",
    "אלפראדה",
    "אלפרהוד",
    "אלפרס",
    "אלקאדסיה",
    "אלקאהירה",
    "אלקאק",
    "אלקדומי",
    "אלקהווה",
    "אלקוטראן",
    "אלקורונפול",
    "אלקלעה-העיר העתיקה",
    "אלקנדול",
    "אלקסארא אבן סיד",
    "אלקרעאוי",
    "אלראהבאת",
    "אלראשד",
    "אלרהו",
    "אלרואפד",
    "אלרוג'ום",
    "אלרוד'ה",
    "אלרומאן",
    "אלרחבאני",
    "אלריאן",
    "אלריחאן",
    "אלריחאניה",
    "אלרינה",
    "אלרשיד",
    "אלשאם",
    "אלשאע'ור",
    "אלשאפעי",
    "אלשבעאנה",
    "אלשג'רה",
    "אלשהאבי",
    "אלשהד",
    "אלשוהדא",
    "אלשוויכה",
    "אלשומר",
    "אלשונה",
    "אלשוערא",
    "אלשוף",
    "אלשופאניה",
    "אלשיכון",
    "אלשימאל",
    "אלשכארה",
    "אלשלבי",
    "אלשנאר",
    "אלשעבי",
    "אלשרופיה",
    "אלשתיווי",
    "אלת'אמין מין אדאר",
    "אלתונה",
    "אלתין",
    "אלתל",
    "אלתלאל א-סבע",
    "אלתסאמח",
    "אלתפאח",
    "אמיל גרינצוויג",
    "אמיל תומא",
    "אסעד מנסור",
    "אספהאן",
    "אעולא",
    "באב א-דיר-עיר עתיקה",
    "באב אלהווא",
    "באב אלחוסל",
    "בגדד",
    "בדראן משיעל",
    "בוליביה",
    "בוקרשט",
    "בורג' אלסוכר",
    "בטהובן",
    "ביאר אלעדס",
    "ביירות",
    "ביסאן",
    "ביר אבו חוסין",
    "ביר אלסיכה",
    "בית אלשעב",
    "בית הקברות הדרוזי",
    "בלד אלשיך",
    "בנקוק",
    "ברזיל",
    "ברעם",
    "ג'באתא",
    "ג'באתא מערבית",
    "ג'בור יוסף ג'בור",
    "ג'בל אלטור",
    "ג'בל אלערב",
    "ג'דרו",
    "ג'וב אלנח'ל",
    "ג'וב יוסף",
    "ג'וז אלהנד",
    "ג'ול ג'מאל",
    "ג'ולס",
    "ג'מילה בו חיירד",
    "ג'נין",
    "ג'עפר",
    "גאנדי",
    "גבע",
    "גוויד",
    "גומיז",
    "גלידאן",
    "גמל עבד אלנאסר",
    "ד'אהר עומר אלזידאני",
    "ד'הר אלווסטאני",
    "ד'הר אלכניס",
    "ד'הר אלקלעה",
    "ד'הר אלשריף",
    "ד'הר חסן",
    "ד'הר שרתא",
    "דאהור סלימאן תלחמי",
    "דאחס",
    "דוביאן",
    "דיר אלקאסי",
    "דיר חנא",
    "דלאתא",
    "דמשק",
    "דרע אלמשירפה",
    "דרע מרשאן",
    "דרעא",
    "הארון אלרשיד",
    "העיר העתיקה",
    "הראשון במאי",
    "השאם אלשראבי",
    "ואדי אלג'מאל",
    "ואדי אלחמאם",
    "ואדי אלמלק",
    "ואדי אלסנע",
    "ואדי אלסקיע",
    "ואדי אלשעיר",
    "ואדי בלאן",
    "ואדי הושה",
    "ואדי הושי",
    "ואדי חנין",
    "ואדי סלאמי",
    "ואן גוך",
    "ווערת ג'רוס",
    "ויא תריסטי",
    "וערת אלמשאחר",
    "וערת אלסובח",
    "זודאת אלחריק",
    "זומורוד",
    "זופא",
    "זידאן סלאמה",
    "זיתון אלפרוטסטנט",
    "זמארין",
    "זעפראן",
    "זרעין",
    "ח'דר אבו אלעולא",
    "ח'לאייל אלנור",
    "ח'ליל אלסכאכיני",
    "ח'לף אחמד אלסעידי",
    "ח'לת אלח'מאש",
    "ח'לת אליהוד",
    "ח'לת אלעבהר",
    "ח'לת אלעיאדה",
    "ח'לת אלפארס",
    "ח'לת אע'ראב",
    "ח'לת נסים",
    "ח'ניפס",
    "ח'ראמת אלדרוז",
    "ח'ראמת אלמסיחיין",
    "ח'רבת ג'דין",
    "ח'רבת ח'ראזה",
    "ח'רדל",
    "חאסביא",
    "חאפז אברהים",
    "חארת אבו רחמה",
    "חארת אלח'לווה",
    "חארת אלמכתב",
    "חארת באב אלחואסל",
    "חואסה",
    "חוסין עליאן",
    "חוראן",
    "חיטין",
    "חיילים משוחררים א+ב",
    "חיילים משוחררים צפו",
    "חלב",
    "חלים אבו רחמה",
    "חמוד",
    "חנא מויס",
    "חנא נקארה",
    "חנא סלימאן חדאד",
    "חנא עספור",
    "חסן נימר חוסין",
    "חסן סלאמה",
    "ט'הר אלכניס",
    "טאחונת אלראהיב",
    "טבריה",
    "טואחין כרדאני",
    "טובאס",
    "טמרה",
    "טנטורא",
    "טראבין",
    "יאפא",
    "יוחנא בולוס אלת'אני",
    "יום אלארד'",
    "יוסף אידריס",
    "יוסף כרכבי",
    "יוסף סליבא",
    "יני יני",
    "יריחו",
    "כאבול",
    "כאוכב",
    "כאמלה מרושי",
    "כואלד",
    "כויכאת",
    "כורדאני",
    "כלת אלג'אמע",
    "כלת אלתון",
    "כמאל ג'ונבלאט",
    "כפר ברעם",
    "כפר יאסיף",
    "כפר כנא",
    "כפר לאם",
    "כפר סבת",
    "כפר ענאן",
    "כפר קאסם",
    "כפר קדום",
    "כרום אלענב",
    "כרם אלח'ורי",
    "כרם ג'בור",
    "לוביה",
    "לורד",
    "לקייה",
    "מאיר וילנר",
    "מאר יוחנא אלמועמדאן",
    "מאראשון",
    "מארון אלראס",
    "מארתן לותר קינג",
    "מדריד",
    "מדרסת אלבסליה",
    "מואיד אברהים",
    "מוחמד אלענזה",
    "מוחמד גמגום",
    "מוחמד חמאדי",
    "מוחמד עבדו",
    "מוחמד עבדלווהאב",
    "מועין בסיסו",
    "מועתז",
    "מופק דיאב",
    "מוצרט",
    "מחמד חמודי",
    "מחמוד דרוויש",
    "מיסלון",
    "מיעאר",
    "מירון",
    "מלבס",
    "מנסור ארמלי",
    "מנסור כרדוש",
    "מנסור קורטאם",
    "מסכנא",
    "מע'ור אלע'יט",
    "מעלול",
    "מקאם אלשופה",
    "מרג' אלזוהור",
    "מרג' עיון",
    "מרים בוארדי",
    "מרכז מסחר-ע עתיקה",
    "נאבולי",
    "נאבלס",
    "נאג'י אלעלי",
    "נבטיה",
    "נג'ד",
    "נגיב נסאר",
    "נגראן",
    "נואר אלשמס",
    "נזאר קבאני",
    "ניקולא אלדור",
    "נלסון מנדלה",
    "נעום אלג'ליל",
    "נעים קאסם נפאע",
    "נעמה חוסין ח'אזם",
    "נצרת",
    "סאבא בחות'",
    "סאבע סמא",
    "סאברין",
    "סבארתאכוס",
    "סבסטיא",
    "סג'רת אליהוד",
    "סובחי יאסין",
    "סוואר אלד'הב",
    "סולטאן באשא אלאטרש",
    "סופתעאדי",
    "סח'נין",
    "סחמאתא",
    "סייד דרויש",
    "סילאן",
    "סירין",
    "סליבא",
    "סלימאן אבו עביד",
    "סלימאן אלח'ורי",
    "סלפית",
    "סמר",
    "סעד בן אבי וקאס",
    "סעד זע'לול",
    "סעד ח'ניפס",
    "סעד נכד",
    "סעיד אחמד ח'טיב",
    "ספד",
    "ספוריה",
    "ספסאף",
    "ספרג'ל",
    "סרפנד",
    "סרקיס",
    "סת נט'ירה",
    "ע'אבת טבעון",
    "עארף עארף",
    "עבאד אלשמס",
    "עבד אלכרים אלכרמי",
    "עבד אלמלק",
    "עבד אלקאדר אלגזארי",
    "עבד אלרחים מחמוד",
    "עבד אלרחמן אלדאכל",
    "עבדאלחלים חאפט'",
    "עבדאללה בהיג",
    "עבדאללה חסון",
    "עבדאללה קדרי",
    "עבוד",
    "עבלין",
    "עבס",
    "עג'רוש",
    "עווד",
    "עולם",
    "עומר אבן אלעאס",
    "עומר אלמכתאר",
    "עומר סעסעאני",
    "עוסמאן",
    "עוסמאן חוג'יראת",
    "עטא אלזיר",
    "עיון קארא",
    "עין חוד'",
    "עין סעיד",
    "עין ע'זאל",
    "עין עאפיה",
    "עין קנביט",
    "עכברה",
    "עלי עאשור",
    "עלמא",
    "עמאן",
    "עמקא",
    "ענזה",
    "ענתרה",
    "עסא אלראעי",
    "עסקלאן",
    "עראבה",
    "ערוס אלתרכמאן",
    "פאטמה אלזהרא",
    "פואד חגאזי",
    "פואד נסאר",
    "פורט סעיד",
    "פיירוז",
    "פיקאסו",
    "פסוטה",
    "פראדי",
    "פרח",
    "צ'ה גווארה",
    "צור",
    "צידון",
    "צלאח אלדין",
    "צפד",
    "קאסם אמין",
    "קדיתא",
    "קדמאני",
    "קוריש",
    "קיביה",
    "קיס",
    "קיסריה",
    "קראד אלכיס",
    "ראס עלי",
    "ראשד חוסין",
    "רד'א עזאם",
    "רויס",
    "רח 340",
    "רח 370",
    "רח 570",
    "רח 201",
    "רח 131",
    "רח 751",
    "רח 361",
    "רח 561",
    "רח 661",
    "רח 571",
    "רח 602",
    "רח 412",
    "רח 832",
    "רח 742",
    "רח 552",
    "רח 662",
    "רח 313",
    "רח 413",
    "רח 813",
    "רח 643",
    "רח 563",
    "רח 573",
    "רח 583",
    "רח 783",
    "רח 493",
    "רח 704",
    "רח 744",
    "רח 754",
    "רח 854",
    "רח 374",
    "רח 584",
    "רח 294",
    "רח 805",
    "רח 535",
    "רח 855",
    "רח 606",
    "רח 816",
    "רח 826",
    "רח 236",
    "רח 736",
    "רח 846",
    "רח 307",
    "רח 417",
    "רח 627",
    "רח 827",
    "רח 547",
    "רח 747",
    "רח 667",
    "רח 767",
    "רח 577",
    "רח 697",
    "רח 728",
    "רח 548",
    "רח 848",
    "רח 668",
    "רח 778",
    "רח 819",
    "רח 839",
    "רח 149",
    "רח 849",
    "רח 279",
    "רח 599",
    "רמלה",
    "רשיד אלח'ורי",
    "שאהין-חסון",
    "שבדיה",
    "שבלי אלעריאן",
    "שבעא",
    "שדאד",
    "שהרזאד",
    "שחאדה שחאדה",
    "שייח' אבו עלי נסר",
    "שייח' אמין טריף",
    "שעאנין",
    "שעב",
    "שפיק עזאם",
    "שפרעם",
    "תבריז",
    "תוניס",
    "תופיק זיאד",
    "תופיק טובי",
    "תין הלאל",
    "תל אלדהב",
    "תל אלזבדה",
    "תל אלזיב",
    "תל אלקאדי",
    "תע'לב",
  ],
  שקד: ["שקד"],
  שקף: [
    "אשכול",
    "הגפן",
    "הכרם",
    "זמורה",
    "סמדר",
    "עינב",
    "שקף",
    "שריג",
    "תירוש",
  ],
  שרונה: ["שרונה"],
  "שריגים (לי-און)": ["שריגים (לי-און)"],
  שריד: [
    "אורן",
    "איילה",
    "אילנות",
    "אלון",
    "אלמוג",
    "אנפה",
    "בית ראשון במולדת",
    "ברוש",
    "גפן",
    "דגן",
    "זית",
    "זמיר",
    "חבצלת",
    "חצב",
    "לפיד",
    "מעיין",
    "ניצנים",
    "נשר",
    "סחלב",
    "עומר",
    "עופר",
    "ערבה",
    "פשוש",
    "צבעוני",
    "רקפת",
    "שחר",
    "שיבולים",
    "שלהבת",
    "שקד",
    "שריד",
  ],
  שרשרת: ["שרשרת"],
  שתולה: ["שתולה"],
  שתולים: [
    "האורן",
    "האלה",
    "האלון",
    "הארז",
    "הברוש",
    "הגפן",
    "הדולב",
    "הדקל",
    "ההדס",
    "הזית",
    "הרימון",
    "השיטה",
    "השקד",
    "התאנה",
    "התמר",
    "שד הבנים",
    "שתולים",
  ],
  תאשור: ["הזית", "החיטה", "הרימון", "התאנה", "התמר", "תאשור"],
  תדהר: ["תדהר"],
  תובל: ["תובל"],
  תומר: ["תומר"],
  תושיה: ["תושיה"],
  תימורים: ["שכ קרוונים", "תימורים"],
  תירוש: ["תירוש"],
  "תל אביב - יפו": [
    "אב",
    "אבא ושרה נאמן",
    "אבודרהם",
    "אבולעפיה",
    "אבולעפיה רבקה ושלמה",
    "אבוקה",
    "אבטליון",
    "אביאסף",
    "אביגור שאול",
    "אבידן דוד",
    "אבידן שמעון",
    "אביטל",
    "אבימלך",
    "אבינרי יצחק",
    "אביצור שמואל",
    "אבישי",
    "אביתר",
    "אבן ג'נאח",
    "אבן גבירול",
    "אבן חלדון",
    "אבן סינא",
    "אבן ספיר",
    "אבן רשד",
    "אבן שושן אברהם",
    "אבני זכרון",
    "אבנר",
    "אברבנאל",
    "אברך ישעיהו",
    "אגדתי ברוך",
    "אגריפס",
    'אד"ם הכהן',
    "אדיל עאזר",
    "אדירים",
    'אדלר יש"י וברכה',
    "אדם ברוך",
    "אדמון ידידייה",
    "אדר",
    "אהבת ציון",
    "אהוד",
    "אהלי יעקב",
    "אהרון דוד גורדון",
    "אהרון טוב משה",
    "אהרונוביץ",
    "אהרונסון",
    "אוארבך",
    "אוגרית",
    "אוהב ישראל",
    "אוז'שקובה",
    "אויסטרך דויד",
    "אוליפנט",
    "אולשן יצחק",
    "אומץ",
    "אונטרמן איסר יהודה",
    "אוניברסיטת תל -אביב",
    "אוניקובסקי פרץ",
    "אונקלוס",
    "אוסישקין",
    "אופטושו יוסף",
    "אופיר",
    "אופיר שייקה",
    "אופנהיימר",
    "אור החיים",
    "אורי",
    "אורי צבי גרינברג",
    "אוריאל אקוסטה",
    "אורים",
    "אורלוב חנה",
    "אושה",
    'אז"ר',
    "אזוב",
    "אזור הספורט",
    "אזור תעשייה",
    "אזורי חן",
    'אח"י דקר',
    "אחד העם",
    "אחווה",
    "אחוזת בית",
    "אחילוף",
    "אחימאיר אבא",
    "אחימעץ",
    "אחיעזר",
    "אחרון יוסף",
    "אטאטורק",
    "איגר עקיבא",
    "אידלסון אברהם",
    "אידלסון רחל ושמואל",
    "איזמוזי'ק לאה ודויד",
    "אייבשיץ",
    "איילת השחר",
    "אייר",
    'איל"ן',
    "אילת",
    "אימבר",
    "אינשטיין",
    "איסקוב נסים",
    "איסרליש",
    "אירוס",
    "איתיאל",
    "איתין אהרון",
    "אלבז נתן",
    "אלביטר עבדאלראוף",
    "אלדד הדני",
    "אלו מציאות",
    "אלול",
    "אלון יגאל",
    "אלוני ניסים",
    "אלוף",
    "אלוף בצלות",
    "אלוף דן לנר",
    "אלוף מגן קלמן",
    "אלחנן יצחק",
    "אלחריזי",
    "אלטמן אריה",
    "אלי כהן",
    "אליאב בנימין",
    "אליאש מרים",
    "אליהו מפירארה",
    "אליוט ג'ורג'",
    "אליעז רפאל",
    "אליעזר בן הורקנוס",
    "אליפלט",
    "אליקום",
    "אלישברג",
    "אלישיב",
    "אלכסנדר ינאי",
    "אלמגור",
    "אלמוג דוד",
    "אלנבי",
    "אלעזר (רוקח)",
    "אלעזר בן יוסי",
    "אלעזר בן עזריה",
    "אלעזר בן צדוק",
    "אלעזר החורני",
    "אלפסי",
    "אלפרין חיים",
    "אלקחי מרדכי",
    "אלקלעי",
    "אלרואי",
    "אלשיך",
    "אלתרמן נתן",
    "אמונים",
    "אמזלג",
    "אמיל זולה",
    "אמירים",
    "אמנון ותמר",
    "אמסטרדם",
    "אנגל",
    "אנדרוס",
    "אנדרסן",
    "אנוסי משהד",
    "אנטוקולסקי",
    "אנטיגונוס",
    "אני מאמין",
    "אנילביץ מרדכי",
    "אנכי",
    "אנסקי",
    "אסירי ציון",
    "אסף הרופא",
    "אסתר המלכה",
    'אפ"ק',
    "אפטר יעקב",
    "אפלטון",
    "אפקים",
    "אפשטיין",
    "אפשטיין יעקב",
    'אצ"ל',
    "אקסלרוד נתן",
    "ארבע ארצות",
    "ארבר מנחם",
    "ארדון מרדכי",
    "ארזי יהודה",
    "ארטר יצחק",
    "אריאל",
    "אריכא יוסף",
    "אריסטו",
    "אריסטובול",
    "ארלוזורוב",
    "ארליך",
    "ארלנג'ר",
    "ארם משה",
    "ארנון",
    "ארניה אסוולדו",
    "ארסטידס סוזה מנדס",
    "ארצי יצחק",
    "אש שלום",
    "אשדוד",
    "אשכול לוי",
    "אשכנזי",
    "אשקלון",
    "אשרמן יוסף",
    "אשתורי הפרחי",
    'ב"ק ישראל',
    "באכר זאב",
    "באסולה",
    "באר טוביה",
    "באר יעקב",
    "באר שבע",
    "בארט",
    "בארי",
    "בבלי",
    "בדולח",
    "בובליק",
    "בובר שלמה",
    "בוגרשוב",
    "בודנהיימר",
    "בויאר אברהם",
    "בוני העיר",
    "בוסל",
    "בוסקוביץ",
    "בוסתנאי",
    "בועז",
    "בוקי בן יגלי",
    "בוקסנבאום יוסף",
    "בוקשפן דוד",
    "בור נילס",
    "בורוכוב",
    "בורלא יהודה",
    "בורמה",
    "בושם",
    "בזל",
    "בחור אליהו",
    "ביאליק",
    "בייליס מנחם מנדל",
    "ביירון",
    'ביל"ו',
    "בילויה",
    "בילטמור",
    "בילינסון",
    "בינט מאיר",
    "ביצרון",
    "ביריה",
    "בירנבוים",
    "בירנית",
    "בית אורן",
    "בית אל",
    "בית אלפא",
    "בית אשל",
    "בית גוברין",
    "בית החיים",
    "בית הלל",
    "בית הנוער",
    "בית השואבה",
    "בית חדש",
    "בית חורון",
    "בית יוסף",
    "בית יעקב",
    "בית יצקר",
    "בית ירח",
    "בית לחם",
    "בית עובד",
    "בית עולים שמעון",
    "בית פלט",
    "בית צורי אליהו",
    "בית קציר",
    "בית קשת",
    "בית רעים",
    "בית שמאי",
    "ביתוס",
    "בכורי ציון",
    "בלאס שמחה",
    "בלבן מאיר",
    "בלוך דוד",
    "בלום ליאון",
    "בלומנפלד יהודה",
    "בלפור",
    "בלקינד",
    "בלשן",
    'בן אב"י איתמר',
    "בן אביגדור",
    "בן אחיטוב",
    "בן אשר",
    "בן גל מיכאל",
    "בן גמליאל",
    "בן דוד חיים",
    "בן הלל מרדכי",
    "בן זאב",
    "בן זבארה",
    "בן חיים פאול",
    "בן חיל",
    "בן חסד",
    "בן יהודה",
    "בן יוסף שלמה",
    "בן יעקב",
    "בן ישי",
    "בן לברט",
    "בן סירא",
    "בן סירה יעקב",
    "בן סרוק",
    "בן עזרא",
    "בן עטר",
    "בן עמי",
    "בן שטח שמעון",
    "בן שמן",
    "בן שפרוט",
    "בנבנישתי",
    "בנטוב מרדכי",
    "בני אפרים",
    "בני ברק",
    "בני בתירא",
    "בני דן",
    "בני משה",
    "בניהו",
    "בנימין מטודלה",
    "בנימיני אהרון",
    "בסוק חיים מנחם דר'",
    "בעל הטורים",
    "בעל העקידה",
    "בעלי התוספות",
    "בצלאל",
    "בצרה",
    "בקר אהרון",
    "בר אילן",
    "בר גיורא",
    "בר הופמן",
    "בר יוחאי",
    "בר כוכבא",
    "בראלי",
    "ברגי בנימין",
    "ברגנר אליזבת",
    "ברגסון",
    "ברדיצ'בסקי",
    "ברודי",
    "ברודצקי",
    "ברוידס אברהם",
    "ברון עקיבא",
    "ברוק צבי",
    "ברוריה",
    "ברזאני משה",
    "ברזיל",
    "ברזילי",
    "ברטונוב יהושע",
    "בריג אמיל",
    "בריינין ראובן",
    "ברכיה",
    "ברכיהו הנקדן",
    "ברלין אליהו",
    "ברלין ישעיהו",
    "ברלינר",
    "ברנדט וילי",
    "ברנדיס",
    "ברנט",
    "ברנפלד שמעון",
    "ברנר",
    "ברנשטיין אדוארד",
    "ברנשטין כהן",
    "ברסלבי יוסף",
    "ברסקי יהודה לייב",
    "ברק",
    "ברקאי שמואל",
    "ברקוביץ",
    "ברש אשר",
    "ברתנא מרדכי",
    "בת גלים",
    "בת חן",
    "בת ים",
    "בת יפתח",
    "בת מרים יוכבד",
    "בת עין",
    "בת עמי",
    "בת ציון",
    "בת שבע דה רוטשילד",
    "גאולה",
    "גבולות",
    "גבירטיג מרדכי",
    "גבעולים",
    "גבעון",
    "גבעת בן יהודה",
    "גבעת המורה",
    "גבעת הפרחים",
    "גבעת הרצל",
    "גבעת התחמושת",
    "גבעת התמרים",
    'גבעת כ"ח',
    "גבעתי",
    "גברן חליל גברן",
    "גדיש",
    "גדליה",
    'גדנ"ע',
    "גדעון",
    "גדרה",
    "גואל",
    "גובר רבקה",
    "גוז'קוב",
    "גוטלובר",
    "גוטליב",
    "גוטמאכר",
    "גוטמן נחום",
    "גולדברג",
    "גולדברג לאה",
    "גולדמן נחום",
    "גולדפאדן",
    "גולומב",
    "גולי אריתראה",
    "גולי קניה",
    "גולינקין מרדכי",
    "גומא",
    "גונן",
    "גופר",
    "גור יהודה",
    "גורדון י ל",
    "גורי ישראל",
    "גורלסקי ברוך",
    "גורקי",
    "גוש 7040",
    "גוש 7062",
    "גוש 6992",
    "גוש 7063",
    "גוש 6993",
    "גוש 7004",
    "גוש 7005",
    "גוש 7006",
    "גוש 7008",
    "גוש 7048",
    "גוש 7019",
    "גוש 6969",
    "גוש חלב",
    "גוש עציון",
    "גז מטילדה",
    "גזית",
    "גזר",
    "גייגר",
    "גילוץ דבורה ומנחם",
    "גינוסר",
    "גינצבורג",
    "גיתית",
    "גלאנץ לייב",
    "גלבוע אמיר",
    "גלבלום אריה",
    "גלוסקא זכריה",
    "גלוסקין",
    "גלזנר",
    "גלילי ישראל",
    "גליצנשטין",
    "גליקסברג",
    "גליקסון",
    "גלעזר אליהו",
    "גלעינית",
    "גמזו חיים דר'",
    "גני יהושע",
    "גני צהלה",
    "גנני",
    "גנסין",
    "גנתון",
    "גסטר משה",
    "געש",
    "גר צדק",
    "גרוזנברג",
    "גרונימן",
    "גרונר דב",
    "גרוסמן חייקה",
    "גרוסמן מאיר",
    "גרטרוד קראוס",
    "גריזים",
    "גרינבוים",
    "גרץ",
    "גרשום שלום",
    "גת רימון",
    'ד"ר פלד רות',
    "דאנטה",
    "דב ממזריץ",
    "דבורה",
    "דבורה הנביאה",
    "דבלין אלפרד",
    "דברי חיים",
    "דגן",
    "דגניה",
    "דה האז",
    "דה וינצ'י לאונרדו",
    "דה מודינה אריה",
    "דה פיג'וטו",
    "דה פיליפ אדיס",
    "דואר שושנה",
    "דובנוב",
    "דוד הראובני",
    "דוד רחל (שרעבי)",
    "דודאים",
    "דוחן",
    "דוידקה",
    "דוליצקי",
    "דולצ'ין אריה",
    "דון-יחייא שבתי",
    "דונולו הרופא",
    "דורון",
    "דוש",
    "די רוסי",
    "דיזנגוף",
    "דיזנגוף סנטר",
    "דילה רינה יוסף",
    "דימונה",
    "דימיטר פשב",
    "דיסנצ'יק אריה",
    "דיצה",
    "דיק",
    "דישון",
    "דם המכבים",
    "דני קיי",
    "דניאל",
    "דנין",
    "דעואל",
    "דפנה",
    "דקל",
    "דר' מיכאלי יצחק",
    "דרויאנוב",
    "דרור",
    "דרזנר יחיאל",
    "דריוס מיו",
    "דריפוס",
    "דרך אליאב לובה",
    "דרך בגין",
    "דרך בן צבי",
    "דרך בר-לב חיים",
    "דרך דיין משה",
    "דרך הגבורה",
    "דרך ההגנה",
    "דרך הטייסים",
    "דרך השלום",
    "דרך יגאל ידין",
    "דרך יפו",
    "דרך נמיר מרדכי",
    "דרך שלמה",
    "דשבסקי",
    "הא באייר",
    "האגוז",
    "האגמון",
    "האוזנר דב",
    "האומנים",
    "האחים אל כוויתי",
    "האחים בילסקי",
    "האחים מסלאויטה",
    "האלומה",
    "האלקושי",
    "האמוראים",
    "האסיף",
    "האספרגוס",
    "האקליפטוס",
    'האר"י',
    "הארבעה",
    "הארד",
    "הארון אל רשיד",
    "האשכולית",
    "האתרוג",
    "הברון הירש",
    "הברוש",
    "הברזל",
    "הבשן",
    "הגבור האלמוני",
    "הגבורים",
    "הגדוד העברי",
    "הגולן",
    "הגיחון",
    "הגייסות",
    "הגלבוע",
    "הגליל",
    "הגלעד",
    "הגמנסיה העברית",
    "הגפן",
    'הגר"א',
    "הדגה",
    "הדגל",
    "הדואר",
    "הדולפין",
    "הדייגים",
    "הדמומית",
    "הדסה",
    "הדף היומי",
    "הדר יוסף",
    "ההגה",
    "ההדס",
    "הוברמן",
    "הודיה",
    "הוז דב",
    "הולצברג שמחה",
    "הונא",
    "הופיין",
    "הופמן יעקב",
    "הופנקו משה",
    "הורודצקי",
    "הושע",
    "הזוהר",
    "הזז חיים",
    "הזית",
    "הזמיר",
    "הזרם",
    "החבצלת",
    "החותרים",
    "החזיון",
    "החטה",
    "החלוצים",
    "החלפנים",
    "החצב",
    "החצוצרה",
    "החרוב",
    "החרמון",
    "החרמש",
    "החרצית",
    "החרש",
    "החשמונאים",
    "החשמל",
    "הטית זין",
    "היהודי זיס",
    "היימן",
    "היינריך היינה",
    "הייקל יוסף",
    "היכל התלמוד",
    "הילדסהיימר",
    "היסוד",
    "היסמין",
    "הירדן",
    "הירקון",
    "הירשביין",
    "הירשנברג",
    "היתד",
    "הכובשים",
    "הכוזרי",
    "הכינור",
    "הכישור",
    "הכלנית",
    "הכנסת הגדולה",
    "הכרם",
    "הכרמל",
    "הלה טוני",
    "הלוחמים",
    "הלוטוס",
    "הלוי ז'ק",
    "הלוי משה",
    "הלימון",
    "הלל הזקן",
    "הלמד הא",
    "הלסינקי",
    "הלענה",
    "הלפיד",
    "הלפרין",
    "המאבק",
    "המאירי אביגדור",
    "המבצעים",
    "המגדלור",
    "המגיד",
    "המגיד מדובנא",
    "המגן",
    "המחוגה",
    "המכבי",
    "המכבש",
    "המלאכה",
    "המלחים",
    "המליץ",
    "המלך ג'ורג'",
    "המלך חירם",
    "המנוע",
    "המנור",
    "המסגר",
    "המערכה",
    "המפעל",
    "המפרש",
    "המצביאים",
    "המצודה",
    "המצפן",
    "המקצוע",
    "המרד",
    "המרפא",
    "המרץ",
    "המשביר",
    "המשוט",
    "המשור",
    "המשלטים",
    "המשמר האזרחי",
    "המשנה",
    "המשתלה",
    "המתנדב",
    "הנביאים",
    "הנגב",
    "הנגרים",
    "הנוטר",
    "הנופש",
    "הנחושת",
    "הניצנים",
    'הנצי"ב',
    "הנרקיס",
    "הנשיאים",
    "הס משה",
    "הסבא משפולה",
    "הסבוראים",
    "הסדנה",
    "הסוללים",
    "הסיירים",
    "הסנה",
    "הספינה",
    "העבודה",
    "העוגב",
    "העוגן",
    "העליה",
    "העליה השניה",
    "העמל",
    "הערבה",
    "הערמון",
    "הפורצים",
    "הפטיש",
    "הפטמן",
    "הפלך",
    'הפלמ"ח',
    "הפנינים",
    "הפרגים",
    "הפרדס",
    "הפרטיזן היהודי",
    "הפרסה",
    "הצבי",
    "הצבר",
    "הצדף",
    "הצורפים",
    "הצלף",
    "הצנחנים",
    "הצפון החדש",
    "הצפון הישן",
    "הצפורן",
    "הצפירה",
    "הצרי",
    "הקברניט",
    "הקונגרס",
    "הקיקיון",
    "הקישון",
    "הקליר",
    "הקלעים",
    "הקנאים",
    "הקריה",
    "הקרן",
    "הקשת",
    "הר נבו",
    "הר סיני",
    'הרא"ש',
    'הראב"ע',
    "הראל",
    "הרב אלנקווה",
    "הרב באזוב דוד",
    "הרב בידאני עובדיה",
    "הרב גביזון חי",
    "הרב גורן שלמה",
    "הרב הלר חיים",
    "הרב הרצוג",
    "הרב טולידנו",
    "הרב טייב יצחק",
    "הרב ניסים",
    "הרב עוזיאל",
    "הרב עמיאל",
    "הרב פרידמן",
    "הרב פרנקל",
    "הרב צוברי יוסף",
    "הרב צירלסון",
    "הרב קוק",
    "הרב רייפמן",
    "הרבי מבכרך",
    "הרבי מויטבסק",
    "הרבי מלילוב",
    "הרבי מפשיסחא",
    "הרבי מקוטוב",
    "הרבי מקוצק",
    "הרבי מקרלין",
    "הרבסט קרול",
    "הרדוף",
    'הרה"ג מחבוב יחיא',
    "הרוגי מלכות",
    "הרודיון",
    "הרותם",
    "הרטגלס",
    "הרטוב",
    "הרימון",
    "הרכב",
    "הרכבי אברהם",
    "הרכבת",
    "הרכש",
    "הרמלין יוסף",
    "הרן",
    'הרנ"ה',
    "הרץ יוסף צבי",
    "הרצל",
    "הרצפלד אברהם",
    "הרקפת",
    "הררי",
    'הש"ך',
    "השומר",
    "השומרון",
    "השופט נופך",
    "השופטים",
    "השופר",
    "השוק",
    "השוק סמ",
    "השושנים",
    "השחף",
    "השחקנים",
    "השחר",
    "השיבולת",
    "השיטה",
    "השיירות",
    "השילוח",
    "השיפון",
    "השכל",
    'השל"ה',
    "השלושה",
    "השנית",
    "השעורה",
    "השפלה",
    "השקמה",
    "השרביט",
    "השרון",
    "התאנה",
    "התבור",
    "התורן",
    "התחיה",
    "התחנה המרכזית החדשה",
    "התחנה המרכזית הישנה",
    "התיבונים",
    "התלמי יהושע",
    "התמר",
    "התנאים",
    "התנופה",
    "התניא",
    "התעודה האדומה",
    "התערוכה",
    "התעשיה",
    "התקוה",
    "התקומה",
    "התשעים ושלוש",
    "ואלין משה",
    "ווג'ווד",
    "וולמן יהודה",
    "וולפסון",
    "וורמיזה",
    "ויזל",
    "ויטל חיים",
    "ויטלה מאוריציו",
    "ויטנברג יצחק",
    "וייז ג'ורג'",
    "ויינר זאב",
    "ויינשל",
    "וייס אייזיק הירש",
    "וייס יעקב",
    "ויל קורט",
    "וילון",
    "וילנא",
    "וילנסקי משה",
    "וילסון",
    "וינגייט",
    "וינשטיין נחום",
    "ויסבורג חיים",
    "ויסוצקי",
    "ויסר חנה ויעקב",
    "ויצמן",
    "ויקטור הוגו",
    "ויתקין",
    "וכטנגוב",
    "ולבה יואל",
    "ולנברג ראול",
    "ולנסיה",
    "ונטורה משה",
    "וסרמן יעקב",
    "ועידת קטוביץ",
    "וקנין רפי",
    "ורבורג",
    "ורד",
    "ורדיאל",
    "ורשבסקי",
    "ותיק",
    "ז'בוטינסקי",
    "ז'ורס ז'אן",
    "זאב",
    "זאבי רחבעם (גנדי)",
    "זבד",
    "זבולון",
    "זהבי דוד",
    "זולצר",
    "זטורי משה",
    "זיגלבוים",
    "זיו רבקה",
    "זייד אלכסנדר",
    "זימן",
    "זינגר בשביס יצחק",
    'זיע"א יוסף חיים',
    "זיקים",
    "זכות משה",
    "זכרון יעקב",
    "זכרון קדושים",
    "זכריה",
    "זכריני",
    "זלדה",
    "זלוציסטי",
    "זלטופולסקי",
    "זליבנסקי",
    "זליג",
    "זליגמן מקס",
    "זמיר עמנואל",
    "זמנהוף",
    "זמר חנה",
    "זנגביל",
    "זעירא מרדכי",
    'זק"ש נלי',
    "זרובבל",
    "זרובבל יעקב",
    "זרח",
    "זריצקי יוסף",
    "זרעים",
    "חביב אבשלום",
    "חביבי אמיל",
    "חבקוק",
    "חבר הלאומים",
    "חברה חדשה",
    "חברון",
    'חברת ש"ס',
    "חבשוש חיים",
    "חגי",
    "חדד סעדיא",
    "חדרה",
    "חובבי ציון",
    "חובות הלבבות",
    "חולדה",
    "חומה ומגדל",
    "חוני המעגל",
    "חוסן",
    "חוף גורדון",
    "חופשי דוד",
    "חזון איש",
    "חזן יעקב",
    "חזנוביץ",
    "חזק",
    "חזקיהו המלך",
    'חי"ש',
    'חידושי הרי"ם',
    "חיוג יהודה",
    "חיות פרץ",
    "חיותמן",
    "חיי אדם",
    "חייבי משה",
    "חיים ואלישע",
    "חיל המשמר",
    "חיל השריון",
    "חילו יצחק",
    "חיננית",
    "חיסין",
    "חירותנו",
    "חכים אליהו",
    "חכם שמעון",
    "חכמי אתונה",
    "חכמי דוד",
    "חכמי לובלין",
    "חכמי קירואן",
    "חלד יוסף",
    "חלמית",
    "חלץ",
    "חנוך",
    "חנינא בן דוסא",
    "חנינא בן תרדיון",
    "חניתה",
    "חנניה",
    "חנקין",
    "חסידי האומות",
    "חפץ חיים",
    "חפץ ישה",
    "חפר",
    "חצור",
    "חצרים",
    "חרוץ",
    "חריף אייזיק",
    "חשון",
    'חת"ם סופר',
    "טאגור רבינדרנארט",
    "טביוב",
    "טבנקין יצחק",
    "טבריה",
    "טברסקי",
    "טבת",
    "טג'יר שמואל וסולטנה",
    "טהון יהושע",
    "טוביה הרופא",
    "טובים עמנואל",
    "טוכולסקי קורט",
    "טולוז",
    "טולקובסקי",
    "טוסקניני",
    "טור מלכא",
    "טורטשינר",
    "טורי זהב",
    "טורקוב זיגמונד",
    "טיומקין",
    "טייגר היל",
    "טימן יעקב",
    "טירת צבי",
    "טלמון",
    "טרומן הרי",
    "טרומפלדור",
    "טריטש",
    "טרפון",
    "טשרנא",
    "טשרניחובסקי",
    "יאיר",
    "יבנאל",
    "יבנה",
    "יגור",
    "יגיע כפיים",
    "יד המעביר",
    "יד חרוצים",
    "ידעיה הפניני",
    "יהואש",
    "יהודה החסיד",
    "יהודה הימית",
    "יהודה הלוי",
    "יהודה המכבי",
    "יהודה הנשיא",
    "יהודה הצעיר",
    "יהודה מרגוזה",
    "יהונתן",
    "יהושע בן נון",
    "יהושע בן פרחיה",
    'יהל"ל',
    "יואב",
    "יואל הנביא",
    "יודפת",
    "יוחנן בן זכאי",
    "יוחנן הגדי",
    "יוחנן הורקנוס",
    "יוחנן הסנדלר",
    "יוחננוף כהן שמואל",
    "יום טוב",
    "יון מצולה",
    "יונדב",
    "יונה הנביא",
    "יוניצ'מן",
    "יונתן הופסי",
    "יוסט",
    "יוסי בן יוסי",
    "יוסף אליהו",
    "יוסף האפרתי",
    "יוסף הנשיא",
    "יועזר איש הבירה",
    "יורה",
    "יורם",
    "יזהר",
    "יחזקאל",
    "יחיאלי",
    "יחיעם",
    "יטבת",
    "ילדי טהרן",
    "ילין דוד",
    "ילין מור נתן",
    "ילקוט הרועים",
    "ים סוף",
    "ימין",
    "ינון",
    "יניב",
    "יסוד המעלה",
    "יסעור",
    'יעב"ץ',
    "יעוד ודרך",
    "יעל",
    "יעקב שבתאי",
    "יעקובוב אוריאל",
    "יערי מאיר",
    "יפה בצלאל",
    "יפה נוף",
    "יפו העתיקה",
    "יפעת",
    "יפרח",
    "יפת",
    "יפתח",
    "יצחק שדה",
    "יציאת אירופה",
    "יציץ",
    "יקותיאל",
    "יקותיאלי יוסף",
    "יקינתון",
    "יקנעם",
    "ירבלום מרק",
    "ירושלמי",
    "יריחו",
    "ירמיהו",
    'יש"ר מקנדיא',
    "ישורון",
    "ישיבת וולוז'ין",
    "ישכון",
    "ישעיהו",
    "ישראל מסלנט",
    "ישראל מריז'ין",
    "ישראלי יצחק",
    "ישראליס",
    "ישרש",
    "יששכרוב חי",
    'כ"ג יורדי הסירה',
    'כ"ץ בן ציון',
    'כ"ץ יעקב יוסף',
    "כביר",
    "כהן הרמן",
    "כהן יעקב",
    "כהן עופר",
    "כהנשטם",
    "כוכבי יצחק",
    "כורזים",
    "כורש",
    "כיכר אבריאל אהוד",
    "כיכר אורלנד יעקב",
    "כיכר דמארי שושנה",
    "כיכר הראל איסר",
    "כיכר ספורטה רפאל",
    "כיסופים",
    "ככר 2401",
    "ככר 3944",
    "ככר אבנור חנה",
    "ככר אופנבך ז'ק",
    "ככר אורבוך ג'ניה",
    "ככר אורי אביבה",
    "ככר אנה פרנק",
    "ככר ב' בנובמבר",
    "ככר ביאליק",
    "ככר בלוך ארנסט",
    "ככר ברנשטין לאונר",
    "ככר גורדון אורי",
    "ככר גרשוין ג'ורג'",
    "ככר דיזנגוף",
    "ככר דיק יוסף ג'ורג'",
    "ככר דר' דג'אני פואד",
    "ככר הבימה",
    "ככר ההסתדרות",
    "ככר היל",
    "ככר הכנסת",
    "ככר המדינה",
    "ככר המושבות",
    "ככר המלך אלברט",
    "ככר הסוכנות",
    "ככר השוטר",
    "ככר וולך יונה",
    "ככר חסידי האומות",
    "ככר יצחק רבין",
    "ככר ישורון אבות",
    "ככר כהן חיים",
    "ככר לביא אריק",
    "ככר לוי-תנאי שרה",
    "ככר לוין אריה הרב",
    "ככר לונדון",
    "ככר מגן דוד",
    "ככר מהלר גוסטב",
    "ככר מיכאלס",
    "ככר נמיר",
    "ככר סגן בוקעי יעקב",
    "ככר סתר מרדכי",
    "ככר פלומר",
    "ככר קדומים",
    "ככר שטרן מרדכי",
    "ככר שמאלי אליעזר",
    "ככר שנברג ארנולד",
    "כל ישראל חברים",
    "כנפי נשרים",
    "כנרת",
    "כסלו",
    "כפר גלעדי",
    "כפר יונה",
    "כפר סבא",
    "כפר שלם",
    "כצנלסון בת שבע",
    "כרם התימנים",
    "כרם חמד",
    "כרמי",
    "כרמי דב",
    "כרמיה",
    "כרמלית",
    "לאן א",
    "לאן ב",
    "לב אברהם",
    "לבון פנחס",
    "לבונטין",
    "לבלוב",
    "לבנדה",
    "לבנדובסקי",
    "לבנון חיים",
    "לבני איתן",
    "לה גארדיה",
    "לואי מרשל",
    "לובטקין צביה",
    "לודויפול",
    "לוזים",
    "לוחמי גליפולי",
    "לוי יוסף",
    "לוי יצחק",
    "לוי שלום אהרון",
    "לויד ג'ורג'",
    "לויטה",
    "לויטן יצחק",
    "לוין קיפניס",
    "לוין שלום",
    "לוינסון אברהם",
    "לוינסקי",
    "לונדון בצלאל",
    "לונץ",
    "לוע הארי",
    "לוריא",
    'לח"י',
    "לחובר",
    "לטריס",
    "ליב בן שרה",
    "ליבנה אליעזר",
    "ליברמן",
    "ליוויק",
    "ליכטר יהושע",
    "לילי מנחם",
    "ליליאן",
    "לילינבלום",
    "לינקאוס יוסף פופר",
    "לינקולן",
    "ליסין",
    "ליענר שלמה",
    "ליפסקי לואי",
    "ליש",
    "לישנסקי יוסף",
    "לכיש",
    "למדן יצחק",
    "למואל",
    "למרטין",
    "לסל",
    "לסקוב חיים",
    "לסקר",
    "לפידות",
    "לפין",
    "לצרוס משה",
    "לקרט",
    "לשם",
    "מאה שערים",
    "מאור הגולה",
    "מאור משה",
    "מאור עינים",
    "מאייר מרדכי",
    "מאירבר",
    "מאנגר איציק",
    "מאנה",
    "מאפו",
    "מבוא 3949",
    "מבוא גילת",
    "מבוא גניגר",
    "מבוא געתון",
    "מבוא גרופית",
    "מבוא וולקר יוסף",
    "מבחר",
    "מבטחים",
    "מבצע קדש",
    "מבקיעים",
    "מבשר",
    "מגדל",
    "מגדל שרשן",
    "מגדלי נאמן",
    "מגידו",
    "מגן אברהם",
    "מגשימים",
    "מד אנדרומדה",
    "מדמון מנחם",
    "מדרש פנחס",
    "מדרש תנחומא",
    "מהלל העדני",
    'מהר"ל',
    'מהרש"א',
    "מודיליאני",
    "מודיעין",
    "מוהליבר",
    "מוהר יחיאל",
    "מוזיר יעקב",
    "מוזס ולטר",
    "מוזס יהודה ונח",
    "מויאל אהרון",
    "מולדת",
    "מולכו שלמה",
    "מונאש",
    "מונטיפיורי",
    "מוסינזון",
    "מוסינזון יגאל",
    "מוצא",
    "מוצקין",
    "מוקדי משה",
    "מור",
    "מורד הטיילת",
    "מורדי הגטאות",
    "מורה נבוכים",
    "מוריץ דניאל",
    "מורשת",
    "מושיע",
    'מזא"ה',
    "מזל אריה",
    "מזל בתולה",
    "מזל גדי",
    "מזל דגים",
    "מזל דלי",
    "מזל טלה",
    "מזל מאזנים",
    "מזל סרטן",
    "מזל עקרב",
    "מזל קשת",
    "מזל שור",
    "מזל תאומים",
    "מזרחי",
    'מח"ל',
    "מחנה אריאל",
    "מחנה יוסף",
    "מחפוז נג'יב",
    "מחרוזת",
    "מטולה",
    "מטלון",
    "מטמון כהן",
    "מיזאן",
    "מיטב",
    "מייזל זלמן",
    "מייטוס אליהו",
    'מיכ"ל',
    "מיכאל (נאמן)",
    "מיכאל אנג'לו",
    "מיכה",
    "מילוא יוסף",
    "מילר כהן אניטה",
    "מימון שלמה",
    "מינץ בנימין",
    "מינקובסקי",
    "מיצקביץ אדם",
    "מיקוניס שמואל",
    "מירון",
    "מכלל יופי",
    "מכמורת",
    'מל"ן',
    "מלאכי",
    'מלבי"ם',
    "מלון אורה",
    "מלון אסטור",
    "מלון בזל",
    "מלון ימית",
    "מלון מנדרין",
    "מלון קרלטון",
    "מלילות",
    "מלכי ישראל",
    "מלצ'ט",
    "מלקוש",
    "מנדלי מוכר ספרים",
    "מנדלסון",
    "מנדלקרן",
    "מנדלר אלברט אברהם",
    "מנדלשטם",
    "מנדלשטם אוסיפ",
    "מנוחה",
    "מנורה",
    "מנורת המאור",
    "מניה וישראל",
    "מנשה בן ישראל",
    "מסוף ארלוזורוב",
    "מסוף רדינג",
    "מסילת העולים",
    "מסילת וולפסון",
    "מסילת ישרים",
    "מסלול",
    "מסקין אהרון",
    "מע א כ שלם",
    "מע ב כ שלם",
    "מע יד המעביר",
    'מע"ר',
    "מעבר 333",
    "מעבר 2389",
    "מעבר יבוק",
    "מעון",
    "מעונות אוניברסיטה",
    "מעלה שמיר משה",
    "מענית",
    "מעפילי אגוז",
    "מעפילי סלואדור",
    "מפרץ שלמה",
    "מצדה",
    "מצובה",
    'מצודת בית"ר',
    "מצפה",
    "מקוה ישראל",
    "מקור חיים",
    "מקלף מרדכי",
    "מקס ברוד",
    "מקראי קדש",
    "מרגולין",
    "מרגוע",
    "מרגלית מאיר",
    "מרגנית",
    "מרד גטו ורשה",
    "מרדור מוניה",
    "מרדכי (מוטה) גור",
    "מרזוק ועזר",
    "מרזוק מחמד",
    "מרחביה",
    "מרידור יעקב",
    "מרים החשמונאית",
    "מרינה",
    "מרכוס דוד",
    "מרכז בעלי מלאכה",
    "מרכז גולדה מאיר",
    "מרכז הירידים",
    "מרכז רפואי סוראסקי",
    "מרכלת",
    "מרמורק",
    "מרסל ינקו",
    "מרק יעקב",
    "מרקיש פרץ",
    "משגב עם",
    "משה (שלוש)",
    "משה בריל בר-כוכבא",
    "משל ירוחם",
    "משמר הגבול",
    "משמר הירדן",
    "משעול יעקב",
    "משעול שירה",
    "משעול שמידט יוסף",
    "מתחם התחנה(מנשייה)",
    'מתחם שטח נמל ת"א',
    "מתתיהו כהן גדול",
    'נ"א (כפר שלם)',
    "נאמן יובל פרופ'",
    "נבטים",
    "נג'ארה ישראל",
    "נגבה",
    "נדב",
    "נהלל",
    "נהרדעא",
    "נהריים",
    "נוה אליעזר",
    "נוה ברבור",
    "נוה כפיר",
    "נוה צדק",
    "נוה שאנן",
    "נוה שלום",
    "נוה תקוה",
    "נווה דן",
    "נווה עופר",
    "נוזהה",
    "נועם",
    "נוריאל",
    "נורית",
    "נח",
    "נחום",
    "נחל איילון",
    "נחל הבשור",
    "נחל עוז",
    "נחל שורק",
    "נחלאות",
    "נחליאל",
    "נחלת בנימין",
    "נחלת יצחק",
    "נחמה",
    "נחמיה",
    "נחמן מברסלב",
    "נחמני",
    "נחשון",
    "נטעים",
    "נטר קרל",
    'ניל"י',
    "ניסן כהן",
    "ניסנבוים",
    "ניצנה",
    "ניר אביב",
    "ניר עם",
    "נירים",
    "נמירובר",
    "נמל יפו",
    "נמל תל אביב",
    "נמרי דוידקה",
    "נס הרים",
    "נס לגויים",
    "נס ציונה",
    "נסאר ג'ורג'",
    "נעמן",
    "נפחא יצחק",
    "נצח ישראל",
    "נקר מאיר",
    "נרדור",
    "נרדי נחום",
    "נרקיס עוזי",
    "נשרי צבי",
    "נתיב המזלות",
    "נתיבות",
    "נתיבי איילון דרום",
    "נתיבי איילון צפון",
    "נתן",
    "נתן החכם",
    "סאמט שמעון",
    "סביון",
    "סג'רה",
    "סגולה",
    "סגל ירושלים",
    "סגל צבי הרמן",
    "סגל שמוליק",
    "סהרון",
    "סוגיהרה סמפו",
    "סוטין",
    "סולד הנרייטה",
    "סומו יהודה",
    "סומקן",
    "סוסליק אריה",
    "סוסקין אברהם",
    "סוקולוב",
    "סוקרטס",
    "סחרוב אנדרי",
    "סטארה אלברט",
    "סטוצ'בסקי יהויכין",
    "סטימצקי",
    "סטרומה",
    "סטרכילביץ צבי",
    "סי אנד סאן",
    "סיוון",
    "סייפן",
    "סילמן יהודה קדיש",
    "סימן טוב",
    "סירני חיים",
    "סירקין",
    "סלואדור",
    "סלומון",
    "סלונימסקי",
    "סלסלה",
    "סלע יעקב",
    "סלע עמנואל",
    "סמ איתן",
    "סמ אלון",
    "סמ אסירי ציון",
    "סמ באר שבע",
    "סמ בארי",
    "סמ בית הבד",
    "סמ בן יהודה",
    "סמ בר יוחאי",
    "סמ גולי קניה",
    "סמ גור אריה",
    "סמ גן מאיר",
    "סמ האור",
    "סמ הגינה",
    "סמ החובבים",
    "סמ החרמון",
    "סמ הכרמל",
    "סמ העליה",
    "סמ הר ציון",
    "סמ התבור",
    "סמ התרסי",
    "סמ זנגביל",
    "סמ זרת",
    "סמ חכמי ישראל",
    "סמ טאגור",
    'סמ יל"ג',
    "סמ כביר",
    "סמ מגדל",
    "סמ מולכו",
    "סמ מיכה",
    "סמ מנדלשטם",
    "סמ מסילה א",
    "סמ מסילה ב",
    "סמ מעוז",
    "סמ משמר הירדן",
    'סמ נ"ס',
    "סמ נוה צדק",
    "סמ נורדאו",
    "סמ נחושתן",
    "סמ נס ציונה",
    "סמ עובד",
    "סמ עמוס",
    "סמ ענתבי",
    "סמ צדוק חיים",
    "סמ ראשית חכמה",
    "סמ רבי פוזילוב",
    "סמ רשבג",
    "סמ שבזי",
    "סמ שלוש",
    'סמ שפ"ר',
    "סמ שרירא",
    "סמ תחכמוני",
    "סמבורסקי דניאל",
    "סמדר",
    "סמולנסקין",
    "סמטה אלמונית",
    "סמטה פלונית",
    'סמטת הש"ך',
    "סמטת נטע",
    "סמילנסקי",
    "סנה משה",
    "סנהדראי טובה",
    "סנהדרין",
    "סנפיר",
    "סנש חנה",
    "סעדיה גאון",
    "ספיר אליהו",
    "ספיר ברוך",
    "ספיר פנחס",
    "סרלין יוסף",
    "סשא ארגוב",
    "ע הלל",
    "עבאס אברהם",
    "עבד אל-גני",
    "עבודת ישראל",
    "עברי",
    "עגור",
    'עגנון ש"י',
    "עדולם",
    "עובדיה הנביא",
    "עובדיה מברטנורה",
    "עודד",
    "עוזר",
    "עולי הגרדום",
    "עולי ציון",
    "עולש",
    "עזאי",
    "עזה",
    "עזרא הסופר",
    "עזריה",
    "עזריה מן האדומים",
    "עטרות",
    "עיינות",
    "עין גב",
    "עין גדי",
    "עין גנים",
    "עין דור",
    "עין הקורא",
    "עין ורד",
    "עין זיתים",
    "עין חרוד",
    "עין יעקב",
    "עיר שמש",
    "עירית",
    "על פרשת דרכים",
    "עלומים",
    "עלית הנוער",
    "עם ישראל חי",
    "עמדן יעקב",
    "עמוס",
    "עמיחי יהודה",
    "עמינדב",
    "עמיעד",
    "עמיקם",
    "עמיקם אליהו (קשאק)",
    "עמיר",
    "עמירן עמנואל",
    "עמישב",
    "עמנואל הרומי",
    "עמק איילון",
    "עמק ברכה",
    "עמרי",
    "עמרם גאון",
    "ענתבי",
    "ענתות",
    "עציון גבר",
    "עצמון",
    "עקיבא אריה",
    "ערבי נחל",
    "עשהאל",
    "פאגלין עמיחי",
    "פארק צ'ארלס קלור",
    "פאת השולחן",
    "פברגט אנריקה",
    "פוזננסקי שמואל",
    "פוחובסקי אריה",
    "פוירשטיין אמיל",
    "פולד ברכה",
    "פומבדיתא",
    "פומרוק יוסף",
    "פועה",
    "פוריה",
    "פושקין",
    "פחד יצחק",
    "פטאי יוסף",
    "פטריה",
    "פטרסון",
    "פיארברג",
    "פייבל",
    "פיינברג יוסף",
    "פיינשטיין",
    "פייר מנדס פרנס",
    "פיכמן",
    "פילדלפיה",
    "פילון",
    "פיליכובסקי",
    "פילץ אריה",
    "פין",
    "פינלס",
    "פינס",
    "פינסקר",
    "פינקל שמעון",
    "פיקוס",
    "פישמן מימון",
    "פלג",
    "פלדרמן וילהלם",
    "פלוגות",
    "פלורנטין",
    "פלטיאל",
    "פליטי הספר",
    "פלקירה",
    "פן אלכסנדר",
    "פנואל",
    "פנחס בן יאיר",
    "פנץ נתן",
    "פנקס",
    "פסטלוצי",
    "פסטר",
    "פסטרנק אריה",
    "פעמוני יוסף",
    "פעמונית",
    "פקיעין",
    "פראג",
    "פראנצויז",
    "פראנקל",
    "פרוג",
    "פרויד",
    "פרופס אהרון צבי",
    "פרוץ לאו",
    "פרזון",
    "פרחי אביב",
    "פרי אליעזר",
    "פרי מגדים",
    "פרי עץ חיים",
    "פרידלנד צבי",
    "פרידמן שרגא",
    "פרייגרזון צבי",
    "פריש דניאל",
    "פרישמן",
    "פרלוק משה",
    "פרלשטיין יצחק",
    "פרלשטיין פסיה ויעקב",
    "פרנץ קפקא",
    "פרנקפורט",
    "פרסיץ שושנה",
    "פרץ י ל",
    "פתחיה מרגנשבורג",
    "צ'לנוב",
    "צ'מרינסקי ברוך",
    "צ'רטוק שמשון",
    "צ'רצ'יל וינסטון",
    "צבי צור (צ'רה)",
    "צדוק הכהן",
    'צה"ל',
    "צהלון הרופא",
    "צוויג סטפן",
    "צויפל",
    "צונזר",
    "צונץ",
    "צופית",
    "צוקרמן יצחק",
    "צורישדי",
    "צורית",
    "צידון",
    'ציהתל"י',
    "צייטלין",
    "צימפל פרידריך",
    "צלאן פאול",
    "צלופחד",
    "צלילי חנינא",
    "צמח דוד",
    "צמחי היהודים",
    "צנובר",
    "צפניה",
    "צפריר",
    "צפת",
    "צקלג",
    "צריפין",
    "צרעה",
    'ק"ם',
    "קאונטרי קלאב",
    "קארו יוסף",
    "קבוץ גלויות",
    "קדושי השואה",
    "קדם",
    "קדמוני אסא",
    "קדמיאל",
    "קדמן גורית",
    "קדרון",
    "קדש ברנע",
    "קהילת אודסה",
    "קהילת אוסטרליה",
    "קהילת בודפסט",
    "קהילת ביאליסטוק",
    "קהילת ברזיל",
    "קהילת בריסק",
    "קהילת ונציה",
    "קהילת ורשה",
    "קהילת ז'יטומיר",
    "קהילת זגלביה",
    "קהילת טשרנוביץ",
    "קהילת יאסי",
    "קהילת לבוב",
    "קהילת לודג'",
    "קהילת מכסיקו",
    "קהילת ניו יורק",
    "קהילת סאלוניקי",
    "קהילת סופיה",
    "קהילת עדן",
    "קהילת פאדובה",
    "קהילת פוזנא",
    "קהילת קובנה",
    "קהילת קיוב",
    "קהילת קישינוב",
    "קהילת קליבלנד",
    "קהילת קנדה",
    "קהילת קרקוב",
    "קהילת ריגא",
    "קובנר אבא",
    "קויפמן יחזקאל",
    "קול משה",
    "קוממיות",
    "קומפרט",
    "קוסובסקי",
    "קוץ' אדוארד",
    "קור פאול",
    "קורדובה",
    "קורדובירו",
    "קורולנקו",
    "קורין עזרא",
    "קורצ'אק יאנוש",
    "קורקידי",
    "קיוסו אלברט",
    "קיכלר זילברמן לנה",
    "קיסרי אורי",
    "קיסריה",
    "קיציס יוסף",
    "קיש",
    "קלוזנר",
    "קלונימוס",
    "קליי",
    "קלין רלף",
    "קלישר",
    "קלמן",
    "קלמר משה",
    "קלצ'קין",
    "קמה",
    "קמואל",
    "קמינסקה",
    "קנטור",
    "קפאח יחיה",
    "קפלן",
    'קפלן יעקב ד"ר',
    "קפלן שלמה",
    "קפלנסקי",
    "קפריסין",
    "קצנלסון יצחק",
    "קרוא ברוך",
    "קרויס",
    "קריגר משה",
    "קרייתי",
    "קרית החינוך",
    "קרית המוזיאונים",
    "קרית ספר",
    "קרית עתידים",
    "קרית שאול",
    "קרליבך",
    "קרמניצקי",
    "קרן היסוד",
    "קרן קיימת לישראל",
    "קרני יהודה",
    "קרפל ליפא",
    "קרשקש חסדאי",
    "קשאני אליעזר",
    'ראב"ד',
    "ראם",
    "ראסין ז'אן",
    "ראש פינה",
    "ראשון לציון",
    "ראשונים",
    "ראשית חכמה",
    "רב אלוף דוד אלעזר",
    "רב אלוף יעקב דורי",
    "רב אשי",
    "רב האי גאון",
    "רב החובל",
    "רב צעיר",
    "רבדים",
    "רבי אדא",
    "רבי אחא",
    "רבי חנינא",
    "רבי יוחנן",
    "רבי מאיר",
    "רבי מאיר בעל הנס",
    "רבי נחמן",
    "רבי עקיבא",
    "רבי פנחס",
    "רבי תנחום",
    "רביבים",
    "רבינא",
    'רבינוביץ שפ"ר',
    "רבן גמליאל",
    "רבנו חננאל",
    "רבנו ירוחם",
    "רבנו תם",
    "רבניצקי",
    'רד"ק',
    "רדינג",
    "רובין ראובן",
    "רובין שלמה",
    "רובינא חנה",
    "רובינשטיין ארתור",
    "רובינשטיין יצחק",
    "רודנסקי שמואל",
    "רוזאניס",
    "רוזוב",
    "רוזין יהושע",
    "רוזן פנחס",
    "רוזנבאום",
    "רוזנבלום הרצל",
    "רוזנבלום יאיר",
    "רוזנבלט צבי",
    "רוזנפלד שלום",
    "רוזנשטיין אברהם",
    "רוחמה",
    "רוטנשטרייך",
    "רויגו אברהם",
    "רוממה",
    "רומן רולן",
    "רומנו",
    "רומנילי",
    "רוני",
    "רוסלאן",
    "רופא המחתרות",
    "רופין",
    "רוקח שמעון",
    "רות",
    "רזי",
    "רזיאל",
    "רח 1100",
    "רח 200",
    "רח 2300",
    "רח 2400",
    "רח 3900",
    "רח 1310",
    "רח 2310",
    "רח 2410",
    "רח 3910",
    "רח 2120",
    "רח 3320",
    "רח 3820",
    "רח 3920",
    "רח 1130",
    "רח 3130",
    "רח 4230",
    "רח 1330",
    "רח 4830",
    "רח 3930",
    "רח 2040",
    "רח 3040",
    "רח 1140",
    "רח 2140",
    "רח 1240",
    "רח 2240",
    "רח 440",
    "רח 3740",
    "רח 4840",
    "רח 3350",
    "רח 3850",
    "רח 3860",
    "רח 2170",
    "רח 2370",
    "רח 470",
    "רח 3870",
    "רח 4870",
    "רח 1080",
    "רח 3180",
    "רח 1280",
    "רח 2280",
    "רח 2380",
    "רח 3780",
    "רח 3880",
    "רח 980",
    "רח 1290",
    "רח 2390",
    "רח 3890",
    "רח 101",
    "רח 2301",
    "רח 401",
    "רח 3901",
    "רח 2311",
    "רח 711",
    "רח 3911",
    "רח 3021",
    "רח 1121",
    "רח 1221",
    "רח 2421",
    "רח 3821",
    "רח 921",
    "רח 3921",
    "רח 3031",
    "רח 131",
    "רח 2131",
    "רח 1331",
    "רח 3731",
    "רח 4831",
    "רח 3931",
    "רח 1141",
    "רח 3341",
    "רח 3741",
    "רח 4841",
    "רח 3941",
    "רח 251א",
    "רח 3351",
    "רח 3851",
    "רח 3061",
    "רח 3161",
    "רח 3361",
    "רח 3861",
    "רח 3871",
    "רח 4871",
    "רח 3281",
    "רח 2381",
    "רח 3381",
    "רח 4881",
    "רח 3191",
    "רח 1291",
    "רח 3891",
    "רח 3102",
    "רח 4202",
    "רח 2302",
    "רח 402",
    "רח 3902",
    "רח 1112",
    "רח 2312",
    "רח 3412",
    "רח 3812",
    "רח 912",
    "רח 3912",
    "רח 222",
    "רח 4222",
    "רח 3622",
    "רח 3822",
    "רח 3922",
    "רח 2232",
    "רח 4232",
    "רח 1332",
    "רח 632",
    "רח 3832",
    "רח 4832",
    "רח 3932",
    "רח 1142",
    "רח 3142",
    "רח 3742",
    "רח 4842",
    "רח 152",
    "רח 3152",
    "רח 1252",
    "רח 3952",
    "רח 2362",
    "רח 3362",
    "רח 3662",
    "רח 3862",
    "רח 4862",
    "רח 2172",
    "רח 1272",
    "רח 3872",
    "רח 4872",
    "רח 282",
    "רח 2282",
    "רח 2382",
    "רח 4882",
    "רח 192",
    "רח 1292",
    "רח 3392",
    "רח 3892",
    "רח 4892",
    "רח 2003",
    "רח 103",
    "רח 4203",
    "רח 1303",
    "רח 2303",
    "רח 403",
    "רח 3903",
    "רח 2213",
    "רח 3413",
    "רח 3813",
    "רח 3913",
    "רח 4223",
    "רח 423",
    "רח 2423",
    "רח 3923",
    "רח 133",
    "רח 1133",
    "רח 1233",
    "רח 2233",
    "רח 1333",
    "רח 4833",
    "רח 3933",
    "רח 2143",
    "רח 443",
    "רח 4843",
    "רח 153",
    "רח 3153",
    "רח 1253",
    "רח 1063",
    "רח 2363",
    "רח 3863",
    "רח 4863",
    "רח 1073",
    "רח 1273",
    "רח 3873",
    "רח 4873",
    "רח 1183",
    "רח 283",
    "רח 2383",
    "רח 3383",
    "רח 3483",
    "רח 3783",
    "רח 3883",
    "רח 1193",
    "רח 3193",
    "רח 1293",
    "רח 2293",
    "רח 2393",
    "רח 3393",
    "רח 693",
    "רח 3893",
    "רח 2304",
    "רח 3404",
    "רח 2314",
    "רח 3414",
    "רח 614",
    "רח 3914",
    "רח 1024",
    "רח 3034",
    "רח 3134",
    "רח 1234",
    "רח 2234",
    "רח 1334",
    "רח 4834",
    "רח 3934",
    "רח 3144",
    "רח 1244",
    "רח 444",
    "רח 454",
    "רח 3754",
    "רח 4854",
    "רח 2364",
    "רח 3864",
    "רח 1174",
    "רח 1274",
    "רח 3874",
    "רח 1084",
    "רח 1184",
    "רח 2184",
    "רח 1284",
    "רח 2384",
    "רח 3784",
    "רח 4884",
    "רח 1194",
    "רח 1294",
    "רח 2294",
    "רח 3594",
    "רח 3694",
    "רח 3894",
    "רח 305",
    "רח 1305",
    "רח 2305",
    "רח 3405",
    "רח 3905",
    "רח 1115",
    "רח 1215",
    "רח 4215",
    "רח 615",
    "רח 3615",
    "רח 3915",
    "רח 2225",
    "רח 2425",
    "רח 4825",
    "רח 1135",
    "רח 1235",
    "רח 2235",
    "רח 3335",
    "רח 4835",
    "רח 3935",
    "רח 345",
    "רח 4845",
    "רח 3945",
    "רח 155",
    "רח 4155",
    "רח 355",
    "רח 165",
    "רח 2365",
    "רח 3865",
    "רח 4865",
    "רח 4075",
    "רח 3175",
    "רח 1275",
    "רח 375",
    "רח 3875",
    "רח 975",
    "רח 1085",
    "רח 1185",
    "רח 2185",
    "רח 1285",
    "רח 2385",
    "רח 3785",
    "רח 3885",
    "רח 3195",
    "רח 2295",
    "רח 695",
    "רח 3895",
    "רח 1306",
    "רח 2306",
    "רח 3406",
    "רח 1116",
    "רח 3416",
    "רח 3916",
    "רח 4226",
    "רח 1326",
    "רח 3426",
    "רח 4826",
    "רח 3036",
    "רח 3136",
    "רח 1236",
    "רח 2336",
    "רח 3336",
    "רח 3846",
    "רח 3946",
    "רח 356",
    "רח 3356",
    "רח 3756",
    "רח 856",
    "רח 3856",
    "רח 4856",
    "רח 4166",
    "רח 666",
    "רח 1276",
    "רח 3876",
    "רח 976",
    "רח 1186",
    "רח 1286",
    "רח 3386",
    "רח 4886",
    "רח 1296",
    "רח 2296",
    "רח 3896",
    "רח 107",
    "רח 2307",
    "רח 3407",
    "רח 3807",
    "רח 3907",
    "רח 3017",
    "רח 2217",
    "רח 4217",
    "רח 3817",
    "רח 3917",
    "רח 4227",
    "רח 1327",
    "רח 4827",
    "רח 3927",
    "רח 1237",
    "רח 3837",
    "רח 4837",
    "רח 3937",
    "רח 247",
    "רח 1247",
    "רח 4847",
    "רח 3947",
    "רח 157",
    "רח 3357",
    "רח 3857",
    "רח 4857",
    "רח 4867",
    "רח 1277",
    "רח 3677",
    "רח 3777",
    "רח 3877",
    "רח 4877",
    "רח 977",
    "רח 1287",
    "רח 3387",
    "רח 3887",
    "רח 4887",
    "רח 1297",
    "רח 2297",
    "רח 697",
    "רח 3897",
    "רח 4897",
    "רח 2008",
    "רח 4208",
    "רח 2308",
    "רח 3408",
    "רח 3908",
    "רח 3018",
    "רח 1118",
    "רח 1318",
    "רח 818",
    "רח 3818",
    "רח 3918",
    "רח 3028",
    "רח 2228",
    "רח 4228",
    "רח 1328",
    "רח 3628",
    "רח 4828",
    "רח 3928",
    "רח 1138",
    "רח 238",
    "רח 1238",
    "רח 3338",
    "רח 3838",
    "רח 4838",
    "רח 3938",
    "רח 3848",
    "רח 3948",
    "רח 4158",
    "רח 3858",
    "רח 4858",
    "רח 1268",
    "רח 668",
    "רח 3768",
    "רח 4868",
    "רח 3178",
    "רח 1278",
    "רח 3878",
    "רח 4878",
    "רח 2188",
    "רח 1288",
    "רח 4888",
    "רח 1098",
    "רח 3198",
    "רח 2298",
    "רח 3898",
    "רח 4898",
    "רח 2209",
    "רח 2309",
    "רח 1019",
    "רח 3019",
    "רח 3619",
    "רח 3919",
    "רח 3029",
    "רח 3129",
    "רח 2229",
    "רח 4229",
    "רח 1329",
    "רח 429",
    "רח 3429",
    "רח 3629",
    "רח 3929",
    "רח 1139",
    "רח 2139",
    "רח 1239",
    "רח 4839",
    "רח 1249",
    "רח 4849",
    "רח 4859",
    "רח 2169",
    "רח 2369",
    "רח 4869",
    "רח 1179",
    "רח 3179",
    "רח 1279",
    "רח 379",
    "רח 2379",
    "רח 479",
    "רח 3879",
    "רח 979",
    "רח 1289",
    "רח 3389",
    "רח 589",
    "רח 3789",
    "רח 3889",
    "רח 4889",
    "רח 1099",
    "רח 2299",
    "רח 3899",
    "רח א מכבי חדשה",
    "רח א סחנה ג",
    "רח ב כפר שלם",
    "רח ב מכבי חדשה",
    "רח ב סחנה ג",
    "רח ג מכבי חדשה",
    "רח ג סחנה ג",
    "רח ד סחנה ג",
    "רח ה",
    "רח ה כפר שלם",
    "רח ה סחנה ג",
    "רח ו כפר שלם",
    "רח ז",
    "רח ז כפר שלם",
    "רח ח כ שלם",
    "רח ח סחנה ג",
    "רח ט כ שלם",
    "רח טו כפר שלם",
    "רח טז כפר שלם",
    "רח י כ שלם",
    "רח יא כ שלם",
    "רח יא סחנה ג",
    "רח יב כ שלם",
    "רח יב סחנה ג",
    "רח יג כ שלם",
    "רח יד כ שלם",
    "רח יז כ שלם",
    "רח יח כ שלם",
    "רח יט כ שלם",
    "רח כ כ שלם",
    "רח כא כפר שלם",
    "רח כג כפר שלם",
    "רח כד כ שלם",
    "רח כה כ שלם",
    "רח כו כ שלם",
    "רח כז כ שלם",
    "רח כח כ שלם",
    "רח כט כ שלם",
    "רח ל כפר שלם",
    "רח לא כפר שלם",
    "רח לג כפר שלם",
    "רח לד כפר שלם",
    "רח לה כפר שלם",
    "רח לו כפר שלם",
    "רח לז כפר שלם",
    "רח לח כפר שלם",
    "רח לט כ שלם",
    "רח מ כ שלם",
    'רח מ"ה',
    "רח מא כ שלם",
    "רח מב כ שלם",
    "רח מג כ שלם",
    "רח מד כ שלם",
    "רח מו כ שלם",
    "רח מו סחנה ג",
    "רח מז כ שלם",
    "רח מח כ שלם",
    "רח מט כ שלם",
    "רח נ כ שלם",
    "רח נב כ שלם",
    "רח נה כ שלם",
    "רח נו כ שלם",
    "רח נז כ שלם",
    "רח נח כ שלם",
    "רח נט כ שלם",
    "רח סא כ שלם",
    "רח סב כ שלם",
    "רח סג כ שלם",
    "רח סד כ שלם",
    "רח סה כ שלם",
    "רח סו כ שלם",
    "רח סל",
    "רח עג כ שלם",
    "רח פ",
    "רח פג",
    "רח פד",
    "רח פה",
    "רח פז",
    "רח צ",
    'רח רי"ז',
    "רחבת אבוטבול יעקב",
    "רחבת אלחנני אריה",
    "רחבת לאונרד ברנשטין",
    "רחבת מילאנו",
    "רחבת מרטון ארנסט",
    "רחל",
    "רטוש יונתן",
    'רי"ז',
    'ריב"ל',
    "ריבולוב מנחם",
    "רייך",
    "ריינס",
    "רייס רפאל",
    "רייק חביבה",
    "רילף יצחק",
    "רינגלבלום",
    "רינהרט מקס",
    "ריש גלותא",
    "רכטר זאב",
    'רלב"ג',
    'רמב"ם',
    "רמבה אייזיק",
    "רמברנדט",
    "רמה",
    "רמות נפתלי",
    "רמות צהלה",
    "רמז דוד",
    'רמח"ל',
    "רמת אביב החדשה",
    "רמת הטייסים",
    'רנ"ק',
    "רענן",
    "רפידים",
    "רצאבי זכריה",
    "רצון",
    "רציף הירקון",
    "רציף הרברט סמואל",
    "רקנאטי",
    'רש"י',
    "רשף",
    "רשפים",
    "ש אפקה",
    "ש בבלי",
    "ש דן",
    "ש הדר יוסף",
    "ש הקאוקזים",
    "ש חסכון יפו",
    "ש חסכון כביש בת ים",
    "ש יוגוסלבים",
    "ש ישגב",
    "ש לדוגמא",
    "ש לדוגמא ב",
    "ש מגורים ב",
    "ש מחל",
    "ש מעוז אביב",
    "ש מעונות",
    "ש נוה אביבים",
    "ש נוה משכן",
    "ש נוה צהל",
    "ש נוה שלם",
    "ש נוה שרת",
    "ש סחנה א",
    "ש סלע ק שלום",
    "ש עולי צכיה",
    "ש עממי שד ירושלים",
    "ש צבא קבע ק שלום",
    "ש צהלה",
    "ש צהלון",
    "ש קרית שלום",
    "ש רמת אביב",
    "ש רמת החיל",
    "ש רמת ישראל",
    "ש שנחאי",
    'ש"בי יעקב מאור',
    'ש"י',
    'ש"ץ',
    "שאר ישוב",
    "שארית ישראל",
    "שבזי",
    "שבח",
    "שבט",
    "שבט אשר",
    "שבט בנימין",
    "שבט גד",
    "שבט דן",
    "שבט זבולון",
    "שבט יהודה",
    "שבט יוסף",
    "שבט יששכר",
    "שבט לוי",
    "שבט ראובן",
    "שבט שמעון",
    "שבטי ישראל",
    "שביב",
    "שביל 2161",
    "שביל אג'ימן ישעיהו",
    "שביל האטד",
    "שביל החלב",
    "שביל מרדכי",
    "שביל עכו",
    "שבלים",
    "שבע השקמים",
    "שבתאי",
    "שגאל מרק",
    "שגב",
    "שד נורדאו",
    "שד סמאטס",
    'שד"ל',
    "שד' אבנר מאיר",
    "שד' בן ציון",
    "שד' דוד המלך",
    "שד' הבעש\"ט",
    "שד' ההשכלה",
    "שד' הותיקים",
    "שד' החי\"ל",
    "שד' המעפילים",
    "שד' הציונות",
    "שד' הר ציון",
    "שד' השלכת",
    "שד' ושינגטון",
    "שד' חב\"ד",
    "שד' חכמי ישראל",
    "שד' יד לבנים",
    "שד' יהודית",
    "שד' יוצ\"ר",
    "שד' ירושלים",
    "שד' מסריק",
    "שד' רוקח",
    "שד' שאול המלך",
    "שד' ששת הימים",
    "שד' תרס\"ט",
    "שדה תעופה שדה דב",
    "שדמי נחום",
    "שדרות בן גוריון",
    'שדרות ח"ן',
    "שדרות רוטשילד",
    "שוהם מתתיהו",
    "שוהם צילה",
    "שויצר דוביד",
    "שולמית",
    "שולמן",
    "שונצינו",
    "שוסטקוביץ דימיטרי",
    "שוקן",
    "שור",
    "שורר חיים",
    'שז"ר זלמן',
    'שח"ל',
    "שטח התערוכה הישנה",
    "שטיבל",
    "שטיין",
    "שטיינר",
    "שטינברג",
    "שטינמן",
    "שטינשנידר",
    "שטנד",
    "שטראוס",
    "שטרוק",
    "שטרייכמן יחזקאל",
    "שטריקר",
    "שטרן אברהם (יאיר)",
    "שטרן איזק",
    "שטרנהרץ",
    'שי"ר',
    "שיבת ציון",
    "שיטרית",
    "שיינקין",
    "שייקה דן",
    "שילה",
    "שילר",
    "שינדלר אוסקר",
    "שינמן פנחס",
    "שיפר",
    "שיק",
    "שכ אבו כביר",
    "שכ ביצרון",
    "שכ גב אלישיב",
    "שכ גב משה א",
    "שכ גב עמל א",
    "שכ גב עמל ב",
    "שכ גב שמשון",
    "שכ גבעת עליה",
    "שכ גלילות",
    "שכ גן ההגנה",
    "שכ גן הירקון",
    "שכ דקר (יפו א')",
    "שכ הארגזים",
    "שכ הבולגרים",
    "שכ הרכבת",
    "שכ התקווה",
    "שכ ולהלה",
    "שכ חסן בק",
    "שכ יד אליהו",
    "שכ ידידיה",
    "שכ יפו (דרום)",
    "שכ יפו (מרכז)",
    "שכ יפו (צפון)",
    "שכ כ סומיל",
    "שכ כוכב הצפון",
    "שכ לבנה",
    "שכ מונטיפיורי",
    "שכ מחלול א",
    "שכ מחלול ב",
    "שכ מכבי חדשה",
    "שכ מכבי ישנה",
    "שכ מעוז אביב ב'",
    "שכ נאות אפקה א'",
    "שכ נאות אפקה ב'",
    "שכ נוה שאנן",
    "שכ נווה חן",
    "שכ נווה צדק",
    "שכ נורדיה",
    "שכ נחלת יצחק",
    "שכ עג'מי",
    "שכ עובד",
    "שכ עזרא",
    "שכ פלורנטין",
    "שכ קזנובה",
    "שכ קרית שאול",
    "שכ ראשלצ",
    "שכ רביבים",
    "שכ רמת אביב ג'",
    "שכ שבת",
    "שכ שיבת ציון",
    "שכ שיך מוניס",
    "שכ שמעון",
    "שכ שפירא",
    "שכ שפת הים",
    "שכ תל ברוך",
    "שכון ד",
    'של"ג',
    "שלבים",
    "שלהבית",
    "שלוחות",
    "שלום עליכם",
    "שלומציון המלכה",
    "שלונסקי",
    "שלוש",
    "שלושת החיצים",
    "שלזינגר",
    "שלמה המלך",
    "שלמון",
    "שם הגדולים",
    "שמגר",
    "שמואל בן עדיה",
    "שמואל הנגיד",
    "שמואל ירחינאי",
    "שמחה",
    "שמי יחיאל",
    "שמיר",
    "שמעון בן זומא",
    "שמעון בן עזאי",
    "שמעון הבורסקי",
    "שמעון הצדיק",
    "שמעון התרסי",
    "שמעוני",
    "שמעיה",
    "שמריהו לוין",
    "שמרלינג",
    "שמשון",
    "שניאור זלמן",
    "שניצלר",
    "שניצר שמואל",
    "שניר",
    "שנקר אריה",
    "שער הגיא",
    "שער ציון",
    "שערי ניקנור",
    "שערי תשובה",
    "שפיה",
    "שפינוזה",
    "שפיר קליין",
    "שפירא ישראל",
    "שפירא צבי",
    "שפע טל",
    "שפרינצק",
    "שפתי ישנים",
    "שץ גגה גרשון",
    "שקד",
    "שרון אריה",
    "שריד",
    "שרים אליהו הרב",
    "שרירא גאון",
    "שרמן משה",
    "שרעבי",
    "שרעבי שלום",
    "שרשים",
    "שרת משה",
    "ששון",
    "שתולים",
    "שתי האחיות",
    "תבואות הארץ",
    "תבין אלי",
    "תג'ר ציונה",
    "תדהר דוד",
    "תובל",
    "תוספתא",
    "תוצרת הארץ",
    "תושיה",
    "תות",
    "תחכמוני",
    "תחנה מרכזית",
    "תחנה מרכזית החדשה",
    "תירוש",
    "תכנית ל",
    "תל אביב - יפו",
    "תל ברוך",
    "תל גבורים",
    "תל חי",
    "תל חיים",
    "תמוז",
    "תמיר שמואל",
    "תמנע",
    "תמריר",
    "תעוז",
    "תפוח",
    "תקוע",
    "תרדיון",
    'תרמ"ב',
    'תרפ"א',
    "תרפט",
    'תרצ"ו',
    "תרצה",
    'תש"ח',
    "תשבי",
    "תשרי",
  ],
  "תל יוסף": ["תל יוסף"],
  "תל יצחק": ["אחוזת פולג", "נוה הדסה", "תל יצחק"],
  "תל מונד": [
    "אבני חן",
    "אורנית",
    "אזור תעשייה",
    "ארבל",
    "ברקת",
    "בת חן",
    "דולב",
    "דליה",
    "דן",
    "דרור",
    "דרך אבנט",
    "דרך בני דרור",
    "דרך הלורד",
    "דרך הראשונים",
    "האהבה",
    "האודם",
    "האורן",
    "האחווה",
    "האיריס",
    "האלה",
    "האלון",
    "האנפה",
    "הארגמן",
    "הבוצר",
    "הברוש",
    "הבשור",
    "הגולן",
    "הגלבוע",
    "הגפן",
    "הדוכיפת",
    "הדפנה",
    "הדקל",
    "ההדס",
    "ההדרים",
    "הורד",
    "הזורע",
    "הזמיר",
    "החבצלת",
    "החוחית",
    "החסידה",
    "החצב",
    "החרוב",
    "החרות",
    "החרמון",
    "החרצית",
    "הטופז",
    "הידידות",
    "היהלום",
    "היסמין",
    "הירדן",
    "הירמוך",
    "הכורם",
    "הכלנית",
    "הכרמל",
    "הלילך",
    "הלימון",
    "הלשם",
    "המטעים",
    "המנגו",
    "המרגלית",
    "המרגנית",
    "הנוטע",
    "הנורית",
    "הנרקיס",
    "הסביון",
    "הסיגלית",
    "הסייפן",
    "הסלעית",
    "הסנונית",
    "הספיר",
    "העפרוני",
    "הערבה",
    "הפקאן",
    "הפרדס",
    "הצבעוני",
    "הצבר",
    "הצופית",
    "הרותם",
    "הרימון",
    "הרעות",
    "הרקפת",
    "השחף",
    "השלווה",
    "השקד",
    "השקמה",
    "התאנה",
    "התבור",
    "התמר",
    "התפוז",
    "התקוה",
    "זויתן",
    "זית",
    "כלא תל מונד",
    "כנען",
    "כפר זיו",
    "לכיש",
    "מחנה עולים",
    "מירון",
    "מעלה הפרדס",
    "מצדה",
    "משעול המרוה",
    "משעול התאנה",
    "משעול מוריה",
    "נבו",
    "נוה עובד ב",
    "סיני",
    "פולג",
    "קרית חינוך",
    "ש אלי כהן",
    "ש הדר חיים א",
    "ש הדר חיים ב",
    "ש הדר חיים ג",
    "ש וולפסון",
    "ש נוה עובד א",
    "ש נוה שפרינצק",
    "ש עמידר צריפ",
    "שוהם",
    "שורק",
    "שכ אשכול",
    "שכ יעקב",
    "תל מונד",
  ],
  "תל עדשים": [
    "אגוז",
    "אגס",
    "גפן",
    "דרך אלומה",
    "האורן",
    "הבוץ",
    "הברושים",
    "החרוב",
    "היקים",
    "המטע",
    "העולים",
    "הראשונים",
    "סמטת החורש",
    "רימון",
    "שזיף",
    "שכ אלומה",
    "תאנה",
    "תות",
    "תל עדשים",
    "תמר",
    "תפוח",
  ],
  "תל קציר": ["בית ראשון במולדת", "תל קציר"],
  "תל שבע": [
    "אבו בקר",
    "אבן ח'לדון",
    "אום ג'ביל",
    "אלאמל",
    "אלאס'יל",
    "אלבאבור",
    "אלבאניאס",
    "אלבוסתאן",
    "אלביר",
    "אלברק",
    "אלג'בל",
    "אלדיר",
    "אלואדי",
    "אלזהר",
    "אלח'ד'ר",
    "אלח'רבה",
    "אלח'רוב",
    "אלטור",
    "אלכרימה",
    "אלמאמון",
    "אלמותנבי",
    "אלמזארב",
    "אלרוחה",
    "טאהא חסין",
    "נוח",
    "רומאן",
    "שכ 10",
    "שכ 20",
    "שכ 1",
    "שכ 11",
    "שכ 21",
    "שכ 2",
    "שכ 12",
    "שכ 3",
    "שכ 13",
    "שכ 4",
    "שכ 14",
    "שכ 5",
    "שכ 15",
    "שכ 45",
    "שכ 6",
    "שכ 16",
    "שכ 26",
    "שכ 36",
    "שכ 7",
    "שכ 37",
    "שכ 8",
    "שכ 28",
    "שכ 38",
    "שכ 9",
    "שכ ישנה",
    "תל שבע",
    "תלה",
  ],
  "תל תאומים": ["קרוונים", "תל תאומים"],
  תלם: ["תלם"],
  "תלמי אליהו": ["תלמי אליהו"],
  "תלמי אלעזר": ["תלמי אלעזר"],
  'תלמי ביל"ו': ['תלמי ביל"ו'],
  "תלמי יוסף": ["תלמי יוסף"],
  "תלמי יחיאל": ["שכ קרוונים", "תלמי יחיאל"],
  "תלמי יפה": ["תלמי יפה"],
  תלמים: [
    "גפן",
    "זית",
    "חיטה",
    "רימון",
    "שדרת שבעת המינים",
    "שעורה",
    "תאנה",
    "תלמים",
    "תמר",
  ],
  תמרת: [
    "האורן",
    "האיריס",
    "האלה",
    "האלון",
    "הברוש",
    "הדס",
    "הזית",
    "החרוב",
    "הלבנה",
    "השיטה",
    "יערה",
    "לוטם",
    "מורן",
    "סירה",
    "קידה",
    "תדהר",
    "תמרת",
  ],
  תנובות: [
    "אלומים",
    "גלבוע",
    "הגלעד",
    "החרמון",
    "הכרמל",
    "המוריה",
    "הצופים",
    "השומרון",
    "התבור",
    "חורב",
    "יבולים",
    "כנען",
    "מצדה",
    "נבו",
    "נירים",
    "עומר",
    "עצמון",
    "ציון",
    "שיבולים",
    "תלמים",
    "תנובות",
  ],
  תעוז: ["הגיא", "ההר", "המעיין", "תעוז"],
  תפרח: ["ארץ הנגב", "קרית חינוך", "תפרח"],
  תקומה: ["תקומה"],
  תקוע: ["תקוע"],
  "תראבין א-צאנע (שבט)": ["ואדי אל חליל", "תראבין א-צאנע (שבט)"],
  "תראבין א-צאנע(ישוב)": [
    "רח 1",
    "רח 11",
    "רח 21",
    "רח 2",
    "רח 12",
    "רח 3",
    "רח 13",
    "רח 23",
    "רח 14",
    "רח 5",
    "רח 15",
    "רח 6",
    "רח 16",
    "רח 7",
    "רח 17",
    "רח 8",
    "רח 9",
    "רח 19",
    "שכ 1",
    "שכ 2",
    "שכ 3",
    "שכ 4",
    "שכ 5",
    "תראבין א-צאנע(ישוב)",
  ],
  תרום: ["תרום"],
};
