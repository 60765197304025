import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import BasicTable from "../../../components/basic-table";
import Box from "../../../components/box";
import HeaderWithNavs from "../../../components/cloud-layout/headers-with-navs";
import Spinner from "../../../components/spinner";
import { WithRole } from "../../../components/with-role";
import {} from "../../../utils";
import { currencySymbols } from "../../../utils/billing";
import { useAjax, useUser, useWLDVPS } from "../../../utils/hooks";

export default function MyCloudProducts() {
  const user = useUser();
  const intl = useIntl();
  const ajax = useAjax();
  const wldvps = useWLDVPS();

  const [otherProducts, setOtherProducts] = useState(null);

  const getOtherProduct = useCallback(async () => {
    if (!user._id) {
      return;
    }

    const data = await ajax("/users/getOtherProduct", { userID: user._id });

    if (data.result === "success") {
      const otherProducts = data.otherProducts.filter(
        (op) => op.whitelabel === wldvps
      );
      setOtherProducts(otherProducts);
    }
  }, [ajax, user, wldvps]);

  useEffect(() => {
    getOtherProduct();
  }, [getOtherProduct]);

  return (
    <WithRole permission="products.list">
      <HeaderWithNavs
        title={intl.formatMessage({ id: "my-cloud-products.title" })}
      >
        <Box>
          <BasicTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="clients-list-other-products.product-name" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.product-quantity" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.pay-every" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.pay-day" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.next-pay-day" />
                </th>
                <th>
                  <FormattedMessage id="clients-list-other-products.product-price" />
                </th>
              </tr>
            </thead>
            <tbody>
              {!otherProducts && (
                <tr>
                  <td colSpan={9}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {otherProducts?.length === 0 && (
                <tr>
                  <td colSpan={4}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}
              {otherProducts?.map((otherProduct, idx) => (
                <tr key={idx}>
                  <td>{otherProduct.name}</td>
                  <td>{otherProduct.quantity}</td>
                  <td>{`${otherProduct.payEvery} ${intl.formatMessage({
                    id: `general.${
                      otherProduct.payEvery === 1 ? "month" : "months"
                    }`,
                  })}`}</td>
                  <td>{otherProduct.payDay}</td>
                  <td>
                    {format(new Date(otherProduct.next_pay_day), "d/M/y")}
                  </td>
                  <td>
                    {currencySymbols[otherProduct.currency]}
                    {otherProduct.price}
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </Box>
      </HeaderWithNavs>
    </WithRole>
  );
}
