import styles from "./externals-ips.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import HeaderWithNavs from "../../../../components/cloud-layout/headers-with-navs";
import { WithRole } from "../../../../components/with-role";
import BasicTable from "../../../../components/basic-table";
import { useAjax, useConfirm } from "../../../../utils/hooks";
import Box from "../../../../components/box";
import CustomMenu from "../../../../components/custom-menu";
import CustomMenuItem from "../../../../components/custom-menu/item";
import AssignIpToServerModal from "../../../../components/modals/assign-ip-to-server";
import IconButton from "../../../../components/icon-button";
import {} from "../../../../utils";

export default function ExternalsIps() {
  const intl = useIntl();
  const ajax = useAjax();
  const confirm = useConfirm();

  const [externals, setExternals] = useState([]);
  const [
    isAssignIpToServerModalOpen,
    setIsAssignIpToServerModalOpen,
  ] = useState(false);

  const getAllExternals = useCallback(async () => {
    const data = await ajax(`/network/getAllExternalsIps`);

    if (data.result === "success") {
      setExternals(data.ips);
    }
  }, [ajax]);

  useEffect(() => {
    getAllExternals();
  }, [getAllExternals]);

  function handleDropDownToggle(external) {
    external.dropdownOpen = !external.dropdownOpen;
    setExternals([...externals]);
  }

  async function handleRemoveMenuClicked(external) {
    const state = await confirm({
      title: intl.formatMessage({ id: "externals-ips.remove.title" }),
      message: intl.formatMessage(
        { id: "externals-ips.remove.content" },
        {
          ip: external.ip,
          domain: external.server.hostname,
          b: (arr) => `<b>${arr[0]}</b>`,
        }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax(`/network/removeAssignedExternalIp`, {
      serverID: external.server._id,
    });

    await getAllExternals();
  }

  async function handleAssignIpToServerModalClosed(state) {
    if (state) {
      await getAllExternals();
    }

    setIsAssignIpToServerModalOpen(false);
  }

  return (
    <WithRole permission="super-admin.networks.external-ips">
      <HeaderWithNavs title={intl.formatMessage({ id: "externals-ips.title" })}>
        <div className={styles.wrapper}>
          <Box>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="externals-ips.ip" />
                  </th>
                  <th>
                    <FormattedMessage id="externals-ips.server" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {externals.map((external, key) => (
                  <tr key={key}>
                    <td>{external.ip}</td>
                    <td>{external.server.hostname}</td>
                    <td>
                      <CustomMenu
                        isOpen={external.dropdownOpen}
                        toggle={() => handleDropDownToggle(external)}
                      >
                        <CustomMenuItem
                          onClick={() => handleRemoveMenuClicked(external)}
                        >
                          <FormattedMessage id="general.remove" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>

            <IconButton
              color="light-purple"
              onClick={() => setIsAssignIpToServerModalOpen(true)}
            >
              <FormattedMessage id="externals-ips.assign-ip-to-server" />
            </IconButton>
          </Box>
        </div>

        <AssignIpToServerModal
          isOpen={isAssignIpToServerModalOpen}
          onClose={handleAssignIpToServerModalClosed}
        />
      </HeaderWithNavs>
    </WithRole>
  );
}
