import styles from "./index.module.scss";

import React from "react";
import { useIntl } from "react-intl";
import { useUser } from "../../utils/hooks";
import { getSuspendedReason } from "../../utils/user";
import Box from "../box";

export default function LockedUser() {
  const intl = useIntl();
  const user = useUser();

  return (
    <div className={styles.wrapper}>
      <Box>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: "locked-user.description" },
              {
                reason: intl.formatMessage({
                  id: `locked-user.issue-${getSuspendedReason(user.status)}`,
                }),
                phoneNumber: user.companyPhone,
                b: (arr) => `<b>${arr[0]}</b>`,
              }
            ),
          }}
        ></div>
      </Box>
    </div>
  );
}
