import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";

function Filters({ items, activeItem, onClick }) {
  return (
    <div className={styles.wrapper}>
      {items.map((item, idx) => (
        <div
          key={idx}
          className={`${styles.box} ${
            activeItem === item ? styles.active : ""
          }`}
          onClick={() => onClick(item)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
}

Filters.propTypes = {
  items: PropTypes.array,
  activeItem: PropTypes.object,
  onClick: PropTypes.func,
};

export default Filters;
