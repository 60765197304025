import styles from "./forgot-password.module.scss";

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import validator from "validator";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLang, useAjax } from "../../utils/hooks";
import LoginLayout from "../../components/login-layout";
import TextField from "../../components/textfield";
import IconButton from "../../components/icon-button";
import KeyDetector from "../../components/key-detector";
import {} from "../../utils";

export default function ForgotPassword() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const router = useHistory();

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function handleResetClicked() {
    const _email = email ? email.trim().toLowerCase() : false;

    setError(false);

    if (!validator.isEmail(_email)) {
      return setError(intl.formatMessage({ id: "general.wrong-email" }));
    }

    setLoading(true);
    await ajax("/users/resetPassword", { email: _email });
    setLoading(false);

    router.push(`/${lang}/login?after_reset_password=1`);
  }

  return (
    <LoginLayout>
      <div className={styles.forgotPassword}>
        <div className={`mb8 ${styles.bold}`}>
          <FormattedMessage id="general.forgot-password" />
        </div>
        <div className={`mb28 ${styles.normal}`}>
          <FormattedMessage id="forgot-password.subtitle" />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleResetClicked}>
          <TextField
            icon="user"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.email-address" })}
          />
        </KeyDetector>
        {error && (
          <div className={`mb28 ${styles.errorWrapper}`}>
            <FormattedMessage id={error} />
          </div>
        )}
        <div className="mb28">
          <IconButton
            icon="arrow"
            onClick={handleResetClicked}
            color="purple"
            className={styles.forgotPasswordButton}
            disabled={loading}
          >
            <FormattedMessage id="forgot-password.reset" />
          </IconButton>
        </div>
        <div className={styles.backWrapper}>
          <Link href={`/[lang]/login`} to={`/${lang}/login`}>
            <FormattedMessage id="forgot-password.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
