import styles from "./body.module.scss";

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import IpSubnetCalculator from "ip-subnet-calculator";
import CustomText from "../../custom-text";
import { validateIPaddress } from "../../../utils/networks";
import CountrySelector from "../../country-selector";
import CustomReactSelect from "../../custom-react-select";

export const checkErrorForCreateVlan = ({
  withVlan,
  location,
  vlanName,
  ipAddressScope,
  ipAddressScopeCustom,
  netmask,
  gateway,
  intl,
}) => {
  if (withVlan) {
    if (!location) {
      return intl.formatMessage({
        id: "add-ip-address-scope-modal.wrong-location",
      });
    } else if (!vlanName || vlanName.trim() === "") {
      return intl.formatMessage({
        id: "add-ip-address-scope-modal.wrong-vlan-name",
      });
    }
  }

  if (
    ipAddressScope?.value !== "custom" &&
    !validateIPaddress(ipAddressScope?.value)
  ) {
    return intl.formatMessage({ id: "add-ip-address-scope-modal.wrong-ip" });
  } else if (!netmask?.value) {
    return intl.formatMessage({
      id: "add-ip-address-scope-modal.wrong-netmask",
    });
  } else if (!gateway) {
    return intl.formatMessage({
      id: "add-ip-address-scope-modal.wrong-gateway",
    });
  } else if (ipAddressScope?.value === "custom") {
    if (!validateIPaddress(ipAddressScopeCustom)) {
      return intl.formatMessage({
        id: "add-ip-address-scope-modal.wrong-ip-custom",
      });
    }

    const ipArr = ipAddressScopeCustom.split(".");

    if (!["10", "172", "192"].includes(ipArr[0])) {
      return intl.formatMessage({
        id: "add-ip-address-scope-modal.wrong-number",
      });
    } else if (ipArr[0] === "172" && parseInt(ipArr[1]) < 16) {
      return intl.formatMessage({
        id: "add-ip-address-scope-modal.wrong-ip-172",
      });
    } else if (ipArr[0] === "192" && parseInt(ipArr[1]) < 168) {
      return intl.formatMessage({
        id: "add-ip-address-scope-modal.wrong-ip-192",
      });
    }
  }

  return false;
};

function AddIpAddressScopeModalBody({
  withVlan,
  location,
  setLocation,
  vlanName,
  setVlanName,

  ipAddressScope,
  netmask,
  gateway,
  setGateway,
  setSubnet,
  ipAddressScopeCustom,
  setIpAddressScopeCustom,
  setIpAddressScope,
  setNetmask,
  ipv,
  subnet,
  error,
}) {
  const intl = useIntl();

  const [gateways, setGateways] = useState([]);

  useEffect(() => {
    if (!ipAddressScope || !netmask) {
      setGateway(null);
      setGateways([]);
      setSubnet("-");
      return;
    }

    if (
      ipAddressScope.value === "custom" &&
      !validateIPaddress(ipAddressScopeCustom)
    ) {
      setGateway(null);
      setGateways([]);
      setSubnet("-");
      return;
    }

    const ipSubnetCalc = IpSubnetCalculator.calculateSubnetMask(
      ipAddressScope.value === "custom"
        ? ipAddressScopeCustom
        : ipAddressScope.value,
      netmask.value
    );

    if (ipSubnetCalc) {
      setSubnet(ipSubnetCalc.prefixMaskStr);

      const gateways = [];
      for (let i = ipSubnetCalc.ipLow + 1; i < ipSubnetCalc.ipHigh; i++) {
        gateways.push(IpSubnetCalculator.toString(i));
      }
      setGateways(gateways);
    } else {
      setGateway(null);
      setGateways([]);
      setSubnet("-");
    }
  }, [ipAddressScope, ipAddressScopeCustom, netmask, setGateway, setSubnet]);

  const ipAddressScopeOptions = useMemo(
    () => [
      { label: intl.formatMessage({ id: "general.custom" }), value: "custom" },
      { label: "172.16.0.0", value: "172.16.0.0" },
      { label: "192.168.0.0", value: "192.168.0.0" },
      { label: "10.0.0.0", value: "10.0.0.0" },
    ],
    [intl]
  );

  const gatewayOptions = useMemo(() => {
    return [
      { label: intl.formatMessage({ id: "general.none" }), value: "" },
      ...gateways.map((gateway) => ({ label: gateway, value: gateway })),
    ];
  }, [gateways, intl]);

  const netmaskOptions = useMemo(
    () =>
      (ipv === 4
        ? [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
        : [123]
      ).map((num) => ({ label: num, value: num })),
    [ipv]
  );

  return (
    <div className={styles.wrapper}>
      {withVlan && (
        <div className={`${styles.row} ${styles.colOnMobile}`}>
          <div className={styles.col}>
            <label>
              <span>
                <FormattedMessage id="add-ip-address-scope-modal.location" />
              </span>
              <CountrySelector
                value={location}
                onChange={(country) => setLocation(country)}
                countriesFilter={["IL", "NL", "US"]}
              />
            </label>
          </div>
          <div className={styles.col}>
            <label>
              <span>
                <FormattedMessage id="add-ip-address-scope-modal.vlan-name" />
              </span>
              <CustomText
                value={vlanName}
                onChange={(e) => setVlanName(e.target.value)}
              />
            </label>
          </div>
        </div>
      )}

      <div className={`${styles.row} ${styles.colOnMobile}`}>
        <div className={styles.col}>
          <label>
            <span>
              <FormattedMessage id="add-ip-address-scope-modal.ip-address-scope" />
            </span>
            <CustomReactSelect
              options={ipAddressScopeOptions}
              value={ipAddressScope}
              onChange={(item) => setIpAddressScope(item)}
            />
          </label>
        </div>
        <div className={styles.col}>
          <label>
            <span>
              <FormattedMessage id="add-ip-address-scope-modal.netmask" />
            </span>
            <CustomReactSelect
              options={netmaskOptions}
              value={netmask}
              onChange={(item) => setNetmask(item)}
            />
          </label>
        </div>
      </div>

      {ipAddressScope?.value === "custom" && (
        <div className={styles.row}>
          <div className={styles.col}>
            <label>
              <span></span>
              <CustomText
                placeholder="xxx.xxx.xxx.xxx"
                value={ipAddressScopeCustom}
                onChange={(e) => setIpAddressScopeCustom(e.target.value)}
              />
            </label>
          </div>
        </div>
      )}

      <div className={styles.row}>
        <div className={styles.col}>
          <label>
            <span>
              <FormattedMessage id="add-ip-address-scope-modal.gateway" />
            </span>
            <CustomReactSelect
              options={gatewayOptions}
              value={gateway}
              onChange={(item) => setGateway(item)}
            />
          </label>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.subnetMaskWrapper}>
            <FormattedMessage id="add-ip-address-scope-modal.subnet-mask" />{" "}
            {subnet}
          </div>
        </div>
      </div>

      {error && <div className="error">{error}</div>}
    </div>
  );
}

AddIpAddressScopeModalBody.propTypes = {
  withVlan: PropTypes.any,
  location: PropTypes.any,
  setLocation: PropTypes.any,
  vlanName: PropTypes.any,
  setVlanName: PropTypes.any,
  ipAddressScope: PropTypes.any,
  netmask: PropTypes.any,
  gateway: PropTypes.any,
  setGateway: PropTypes.any,
  setSubnet: PropTypes.any,
  ipAddressScopeCustom: PropTypes.any,
  setIpAddressScopeCustom: PropTypes.any,
  setIpAddressScope: PropTypes.any,
  setNetmask: PropTypes.any,
  ipv: PropTypes.number,
  subnet: PropTypes.any,
  error: PropTypes.any,
};

export default AddIpAddressScopeModalBody;
