import styles from "./server-logs.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../../../components/box";
import { useAjax, useServer } from "../../../../../utils/hooks";
import { getColorOfTask } from "../../../../../utils/tasks";
import { Spinner } from "reactstrap";
import ServerLogModal from "../../../../../components/modals/server-log";
import {} from "../../../../../utils";
import Pagination from "../../../../../components/pagination";

const maxRowsInPage = 50;

export default function ServerLogs() {
  const server = useServer();
  const ajax = useAjax();
  const intl = useIntl();

  const [trackedChanges, setTrackedChanges] = useState([]);
  const [logs, setLogs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isServerLogModalOpen, setIsServerLogModalOpen] = useState(false);

  const [page, setPage] = useState(0);

  const getTrackedChangesOnServer = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax("/servers/getTrackedChangesOnServer", {
      serverID: server._id,
    });

    if (data.result === "success") {
      let serverData;
      if (data.trackedChanges[0]) {
        serverData = data.trackedChanges[0].data;
      } else {
        serverData = server;
      }

      if (data.trackedChanges.length > 1) {
        data.trackedChanges.unshift({
          server_id: server._id,
          data: {
            image: serverData.image,
            cpu: serverData.cpu,
            ram_mb: serverData.ram_mb,
            ssd_gb: serverData.ssd_gb,
            additional_ssd_gb: serverData.additional_ssd_gb,
            backup: serverData.backup,
          },
          created_at: server.created_at,
          first: true,
        });
      }

      setTrackedChanges(data.trackedChanges);
    }
  }, [ajax, server]);

  useEffect(() => {
    getTrackedChangesOnServer();
  }, [getTrackedChangesOnServer]);

  useEffect(() => {
    const logs = [];

    trackedChanges.forEach((task, idx) => {
      if (idx > 0) {
        task.prev = trackedChanges[idx - 1];
      }

      if (task.prev) {
        if (
          !task.prev.first &&
          (task.data.image !== task.prev.data.image ||
            task.data.cpu !== task.prev.data.cpu ||
            task.data.ram_mb !== task.prev.data.ram_mb ||
            task.data.ssd_gb !== task.prev.data.ssd_gb ||
            task.data.additional_ssd_gb !== task.prev.data.additional_ssd_gb ||
            task.data.payment_type !== task.prev.data.payment_type)
        ) {
          task.changed = "change-plan";
        } else if (task.data.running !== task.prev.data.running) {
          task.changed = "state";
        }
      } else {
        task.changed = "first";
      }

      if (task.changed) {
        logs.push(task);
      }
    });

    logs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    setLogs(logs);
  }, [intl, trackedChanges]);

  function handleServerLogModalOpen(log) {
    setSelectedDate(format(new Date(log.created_at), "dd.MM.yy | HH:mm"));
    setSelectedLog(log);
    setIsServerLogModalOpen(true);
  }

  function handleServerLogModalClosed() {
    setIsServerLogModalOpen(false);
  }

  function renderAction(log) {
    if (log.changed === "first") {
      return (
        <span
          onClick={() => handleServerLogModalOpen(log)}
          className={styles.clickable}
        >
          <FormattedMessage id="server-log.server-created" />
        </span>
      );
    }

    if (log.changed === "state") {
      return (
        <span>
          <FormattedMessage
            id={
              log.data.running
                ? "server-log.start-server"
                : "server-log.stop-server"
            }
          />
        </span>
      );
    }

    if (log.changed === "change-plan") {
      return (
        <span
          onClick={() => handleServerLogModalOpen(log)}
          className={styles.clickable}
        >
          <FormattedMessage id="server-log.change-plan" />
        </span>
      );
    }

    return null;
  }

  const filteredLogs = useMemo(
    () =>
      logs.slice(page * maxRowsInPage, page * maxRowsInPage + maxRowsInPage),
    [logs, page]
  );

  return (
    <div className={styles.wrapper}>
      <Pagination
        page={page}
        setPage={setPage}
        totalRows={logs.length}
        maxRowsInPage={maxRowsInPage}
      />

      <Box className={styles.box} title={" "}>
        <div className={styles.tableWrapper}>
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-log.date" />
                </th>
                <th>
                  <FormattedMessage id="server-log.action-change" />
                </th>
                <th>
                  <FormattedMessage id="server-log.status" />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredLogs?.map((log, key) => (
                <tr key={key}>
                  <td>
                    {format(new Date(log.created_at), "dd.MM.yy | HH:mm")}
                  </td>
                  <td className={styles.bold}>{renderAction(log)}</td>
                  <td>
                    {log.status === -1 && log.startedAt && <Spinner />}
                    {log.status !== -1 && (
                      <b
                        style={{
                          color: log.status
                            ? getColorOfTask(log.status)
                            : "var(--light-green)",
                        }}
                      >
                        {log.status ||
                          intl.formatMessage({ id: "general.success" })}
                      </b>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>

      <Pagination
        page={page}
        setPage={setPage}
        totalRows={logs.length}
        maxRowsInPage={maxRowsInPage}
      />

      <ServerLogModal
        isOpen={isServerLogModalOpen}
        onClose={handleServerLogModalClosed}
        date={selectedDate}
        log={selectedLog}
      />
    </div>
  );
}
