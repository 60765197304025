import styles from "./header.module.scss";

import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import copy from "copy-to-clipboard";
import { useLang, useLogout, useUser } from "../../utils/hooks";
import Notifications from "../notifications";
import UserAvatar from "../user-avatar";
import ActivityLog from "../activity-log";
import { getFullName } from "../../utils/user";
import ParentSelector from "./parent-selector";
import { ReactComponent as HamburgerSvg } from "../svgs/hamburger.svg";
import SidebarMobile from "./sidebar-mobile";
import { Link } from "react-router-dom";

export default function Header() {
  const lang = useLang();
  const logout = useLogout();
  const user = useUser();

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  // const [search, setSearch] = useState("");
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  function handleHamburgerClicked() {
    setIsMobileSidebarOpen(true);
  }

  return (
    <div className={styles.headerWrapper}>
      {/* <SearchSvg /> */}

      <div className={styles.search}>
        {/* <Input
          placeholder={`${intl.formatMessage({ id: "general.search" })}...`}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
          <div className={styles.serachPopupWrapper}>
            <SearchPopup search={search} setSearch={setSearch} />
          </div>
        )} */}
      </div>

      <div>
        <ParentSelector />
      </div>

      {/* <div>
        <LanguageSelector />
      </div>

      <div className={styles.hl}></div> */}

      <div className={styles.activityLogWrapper}>
        <ActivityLog />
      </div>

      <div className={styles.notificationsWrapper}>
        <Notifications />
      </div>

      <div className={styles.userWrapper}>
        <Dropdown
          isOpen={userDropdownOpen}
          toggle={() => setUserDropdownOpen(!userDropdownOpen)}
        >
          <DropdownToggle nav>
            <span className={styles.username}>{getFullName(user)}</span>
            <UserAvatar />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <Link
                href="/[lang]/account/account-details"
                to={`/${lang}/account/account-details`}
              >
                <FormattedMessage id="header.account" />
              </Link>
            </DropdownItem>
            <DropdownItem divider />
            {process.env.NODE_ENV === "development" && (
              <DropdownItem onClick={() => copy(user._id)}>
                <FormattedMessage id="header.copy-user-id" />
              </DropdownItem>
            )}
            <DropdownItem>
              <FormattedMessage id="header.feedback-bug-report" />
            </DropdownItem>
            <DropdownItem onClick={() => logout()}>
              <FormattedMessage id="header.sign-out" />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className={styles.hamburgerWrapper} onClick={handleHamburgerClicked}>
        <HamburgerSvg />
      </div>

      <SidebarMobile
        isOpen={isMobileSidebarOpen}
        onClose={() => setIsMobileSidebarOpen(false)}
      />
    </div>
  );
}
