import styles from "./index.module.scss";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax, useUser } from "../../../utils/hooks";
import { countriesPhoneCodes } from "../../../utils/countries";
import PhoneInput from "../../phone-input";
import CustomText from "../../custom-text";

const RESEND_TIMER_INIT = 120;
let timerID;

function PhoneSmsModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const user = useUser();
  const intl = useIntl();

  const [mode, setMode] = useState("before");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(RESEND_TIMER_INIT);
  const counterRef = useRef(counter);
  counterRef.current = counter;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    timerID = setInterval(() => {
      if (counterRef.current > 0) {
        setCounter(counterRef.current - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  async function handleSendClicked() {
    setError(false);

    if (phoneNumber.trim() === "" || isNaN(phoneNumber)) {
      return;
    }

    setLoading(true);
    const data = await ajax(`/users/sendSMS`, {
      phoneCode: countriesPhoneCodes[user.country],
      phoneNumber,
    });
    setLoading(false);

    if (data.result === "success") {
      setMode("after");

      setCounter(RESEND_TIMER_INIT);
    } else {
      setError(data.message);
    }
  }

  async function handleNextClicked() {
    setError(false);

    if (code.trim() === "") {
      return;
    }

    setLoading(true);
    const data = await ajax(`/users/verifySMS`, {
      code,
      action: "update-phone-number",
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      setError("phone-sms-modal.wrong-code");
    }
  }

  function handleResendClicked() {
    setCounter(RESEND_TIMER_INIT);
    handleSendClicked();
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="phone-sms-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.wrapper}>
          {mode === "before" && (
            <>
              <div className={styles.text}>
                <FormattedMessage id="phone-sms-modal.content" />
              </div>
              <div>
                <PhoneInput
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  countryCode={user.country}
                />
              </div>
            </>
          )}

          {mode === "after" && (
            <>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    {
                      id: "phone-sms-modal.after-sms",
                    },
                    {
                      number: `(+${
                        countriesPhoneCodes[user.country]
                      })${phoneNumber}`,
                    }
                  ),
                }}
              ></div>
              <div>
                <CustomText
                  placeholder={intl.formatMessage({
                    id: "phone-sms-modal.enter-code",
                  })}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </>
          )}

          {error && (
            <div className={`error ${styles.error}`}>
              <FormattedMessage id={error} />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={styles.buttons}>
          {mode === "before" && (
            <IconButton
              disabled={loading}
              color="purple"
              onClick={handleSendClicked}
            >
              <FormattedMessage id="general.send" />
            </IconButton>
          )}

          {mode === "after" && (
            <div className={styles.afterButtons}>
              <IconButton
                disabled={loading}
                color="purple"
                onClick={handleNextClicked}
              >
                <FormattedMessage id="general.verify" />
              </IconButton>
              <IconButton
                disabled={loading || counterRef.current > 0}
                color="purple"
                onClick={handleResendClicked}
              >
                {counterRef.current > 0 ? (
                  <FormattedMessage
                    id="general.resend-code-counter"
                    values={{ counter: counterRef.current }}
                  />
                ) : (
                  <FormattedMessage id="general.resend-code" />
                )}
              </IconButton>
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

PhoneSmsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PhoneSmsModal;
