import produce from "immer";

const SET_FINGERPRINT = "SET_FINGERPRINT";
const SET_ALERT_MODAL_DATA = "SET_ALERT_MODAL_DATA";
const SET_CONFIRM_MODAL_DATA = "SET_CONFIRM_MODAL_DATA";
const SET_PROMPT_MODAL_DATA = "SET_PROMPT_MODAL_DATA";
const SET_ACTIVITY_LOG_STATE = "SET_ACTIVITY_LOG_STATE";
const SET_ACTIVITY_LOG_SCROLL_TOP = "SET_ACTIVITY_LOG_SCROLL_TOP";
const SET_MAX_SERVERS_TO_SHOW = "SET_MAX_SERVERS_TO_SHOW";
const SET_TOTAL_SERVERS = "SET_TOTAL_SERVERS";
const SET_SERVERS_FETCHING_PAGE = "SET_SERVERS_FETCHING_PAGE";
const SET_SERVERS_FETCHING_FILTER = "SET_SERVERS_FETCHING_FILTER";
const SET_SERVERS_FETCHING_SHOW_ONLY = "SET_SERVERS_FETCHING_SHOW_ONLY";
const SET_SERVERS_FETCHING_GROUP = "SET_SERVERS_FETCHING_GROUP";
const SET_WLDVPS = "SET_WLDVPS";

const initialState = {
  alertModalData: { isOpen: false },
  activityLog: { isOpen: false },
  serversFetching: {
    page: 0,
    maxServersToShow: { label: "10 per page", value: 10 },
    totalServers: 0,
    filter: "",
    showOnly: "active-servers",
    group: false,
  },
  currentWLDVPS: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FINGERPRINT: {
      return { ...state, fingerprint: action.data };
    }
    case SET_ALERT_MODAL_DATA: {
      return { ...state, alertModalData: action.data };
    }
    case SET_CONFIRM_MODAL_DATA: {
      return { ...state, confirmModalData: action.data };
    }
    case SET_PROMPT_MODAL_DATA: {
      return { ...state, promptModalData: action.data };
    }
    case SET_ACTIVITY_LOG_STATE: {
      return produce(state, (draftState) => {
        draftState.activityLog.isOpen = action.state;
      });
    }
    case SET_ACTIVITY_LOG_SCROLL_TOP: {
      return produce(state, (draftState) => {
        draftState.activityLog.scrollTop = action.top;
      });
    }
    case SET_MAX_SERVERS_TO_SHOW: {
      return produce(state, (draftState) => {
        draftState.serversFetching.maxServersToShow = action.maxServersToShow;
      });
    }
    case SET_TOTAL_SERVERS: {
      return produce(state, (draftState) => {
        draftState.serversFetching.totalServers = action.totalServers;
      });
    }
    case SET_SERVERS_FETCHING_PAGE: {
      return produce(state, (draftState) => {
        draftState.serversFetching.page = action.page;
      });
    }
    case SET_SERVERS_FETCHING_FILTER: {
      return produce(state, (draftState) => {
        draftState.serversFetching.filter = action.filter;
      });
    }
    case SET_SERVERS_FETCHING_SHOW_ONLY: {
      return produce(state, (draftState) => {
        draftState.serversFetching.showOnly = action.showOnly;
      });
    }
    case SET_SERVERS_FETCHING_GROUP: {
      return produce(state, (draftState) => {
        draftState.serversFetching.group = action.group;
      });
    }
    case SET_WLDVPS: {
      return { ...state, wldvps: action.wldvps };
    }
  }
  return state;
}

export const setFingerPrint = (data) => {
  return {
    type: SET_FINGERPRINT,
    data: data.filter((item) => item.key !== "plugins" && item.key !== "fonts"),
  };
};

export const setAlertModalData = (data) => {
  return { type: SET_ALERT_MODAL_DATA, data };
};

export const setConfirmModalData = (data) => {
  return { type: SET_CONFIRM_MODAL_DATA, data };
};

export const setPromptModalData = (data) => {
  return { type: SET_PROMPT_MODAL_DATA, data };
};

export const setActivityLogState = (state) => {
  return { type: SET_ACTIVITY_LOG_STATE, state };
};

export const setActivityLogScrollTop = (top) => {
  return { type: SET_ACTIVITY_LOG_SCROLL_TOP, top };
};

export const setMaxServersToShow = (maxServersToShow) => {
  return { type: SET_MAX_SERVERS_TO_SHOW, maxServersToShow };
};

export const setServersPage = (page) => {
  return { type: SET_SERVERS_FETCHING_PAGE, page };
};

export const setTotalServers = (totalServers) => {
  return { type: SET_TOTAL_SERVERS, totalServers };
};

export const setServersFetchingFilter = (filter) => {
  return { type: SET_SERVERS_FETCHING_FILTER, filter };
};

export const setServersFetchingShowOnly = (showOnly) => {
  return { type: SET_SERVERS_FETCHING_SHOW_ONLY, showOnly };
};

export const setServersFetchingGroup = (group) => {
  return { type: SET_SERVERS_FETCHING_GROUP, group };
};

export const setWLDVPS = (wldvps) => {
  return { type: SET_WLDVPS, wldvps };
};
