import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

function CustomReactSelect({
  instanceId,
  options,
  value,
  onChange,
  isDisabled,
  className,
}) {
  return (
    <Select
      isDisabled={isDisabled}
      instanceId={instanceId}
      options={options}
      value={value}
      onChange={onChange}
      className={`${styles.wrapper} ${className || ""}`}
    />
  );
}

CustomReactSelect.propTypes = {
  instanceId: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default CustomReactSelect;
