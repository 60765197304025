import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ReactComponent as LongArrowLeftSvg } from "../svgs/long-arrow-left.svg";
import { ReactComponent as LongArrowRightSvg } from "../svgs/long-arrow-right-purple.svg";

function Pagination({ page, setPage, totalRows, maxRowsInPage }) {
  const intl = useIntl();

  const maxPages = parseInt(totalRows / maxRowsInPage) + 1;

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>{`${
        page + 1
      } / ${maxPages} ${intl.formatMessage({
        id: "general.total",
      })}`}</div>
      <div
        className={`${styles.box} ${styles.first} ${
          page === 0 ? styles.disabled : ""
        }`}
        onClick={() => setPage(page - 1)}
      >
        <LongArrowLeftSvg />
      </div>
      <div
        className={`${styles.box} ${
          page + 1 === maxPages ? styles.disabled : ""
        }`}
        onClick={() => setPage(page + 1)}
      >
        <LongArrowRightSvg />
      </div>
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  totalRows: PropTypes.number,
  maxRowsInPage: PropTypes.number,
};

export default Pagination;
