import styles from "./manage-roles.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as TrashSvg } from "../../../../components/svgs/trash-purple.svg";
import { ReactComponent as EditSvg } from "../../../../components/svgs/edit.svg";
import BasicTable from "../../../../components/basic-table";
import LayoutMainAction from "../../../../components/layout-main-action";
import Box from "../../../../components/box";
import { useAjax, useConfirm, useLang } from "../../../../utils/hooks";
import Spinner from "../../../../components/spinner";
import CustomTag from "../../../../components/custom-tag";
import {} from "../../../../utils";
import { Link } from "react-router-dom";

export default function AccountPermissionsManageRoles() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const confirm = useConfirm();

  const [roles, setRoles] = useState(null);

  const getAllRoles = useCallback(async () => {
    const data = await ajax("/roles/getAll");

    if (data.result === "success") {
      setRoles(data.roles);
    }
  }, [ajax, setRoles]);

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  async function handleDeleteClicked(role) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "account-permissions-manage-roles.delete.title",
      }),
      message: intl.formatMessage(
        {
          id: "account-permissions-manage-roles.delete.content",
        },
        { name: role.name }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/roles/delete", { roleID: role._id });
    await getAllRoles();
  }

  const smallButton = {
    title: intl.formatMessage({
      id: "account-permissions-manage-roles.back",
    }),
    href: "/[lang]/account/permissions",
    as: `/${lang}/account/permissions`,
  };

  const buttons = [
    {
      title: intl.formatMessage({
        id: "account-permissions-manage-roles.add-new-role",
      }),
      href: "/[lang]/account/permissions/create-new-role",
      as: `/${lang}/account/permissions/create-new-role`,
    },
  ];

  return (
    <LayoutMainAction
      title={intl.formatMessage({
        id: "account-permissions-create-new-role.title",
      })}
      buttons={buttons}
      smallButton={smallButton}
    >
      <div className={styles.wrapper}>
        <Box>
          {!roles && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}
          {roles && (
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="account-permissions-create-new-role.name-of-role" />
                  </th>
                  <th>
                    <FormattedMessage id="account-permissions-create-new-role.number-of-permissions" />
                  </th>
                  <th>
                    <FormattedMessage id="account-permissions-create-new-role.selected-servers" />
                  </th>
                  <th>
                    <FormattedMessage id="account-permissions-create-new-role.edit" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {roles.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {roles.map((role, key) => (
                  <tr key={key}>
                    <td>
                      <CustomTag color="lighter-purple2">{role.name}</CustomTag>
                    </td>
                    <td>{Object.keys(role.permissions || {}).length}</td>
                    <td>{Object.keys(role.servers || {}).length}</td>
                    <td>
                      <TrashSvg onClick={() => handleDeleteClicked(role)} />
                      <Link
                        href="/[lang]/account/permissions/create-new-role/[id]"
                        to={`/${lang}/account/permissions/create-new-role/${role._id}`}
                      >
                        <EditSvg />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          )}
        </Box>
      </div>
    </LayoutMainAction>
  );
}
