import styles from "./overview.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TrackVisibility from "react-on-screen";
import Overview from "../../../../../components/server/overview";
import {
  useServer,
  useUser,
  useAjax,
  useRoles,
  useWLDVPS,
  useServerStatus,
  useAlert,
} from "../../../../../utils/hooks";
import UpgradeManagedHostingModal from "../../../../../components/modals/upgrade-managed-hosting";
import MonitorServicesModal from "../../../../../components/modals/monitor-services";
import { renderTraffic } from "../../../../../utils/servers";
import {
  SUPER_ADMIN,
  userCurrency,
  WHITELABEL,
} from "../../../../../utils/user";
import LatestSnapshots from "./latest-snapshots";
import ScheduledTasks from "./scheduled-tasks";
import AdminOptions from "./admin-options";
import { WithRole } from "../../../../../components/with-role";
import {} from "../../../../../utils";
import { convertCurrency } from "../../../../../utils/billing";

export default function ServerOverview() {
  const intl = useIntl();
  const ajax = useAjax();
  const server = useServer();
  const user = useUser();
  const { isAllowed } = useRoles();
  const wldvps = useWLDVPS();
  const alert = useAlert();

  const { serverStatus } = useServerStatus(server?._id);

  // const [step, setStep] = useState(4);
  const [
    isUpgradeManagedHostingModalOpen,
    setIsUpgradeManagedHostingModalOpen,
  ] = useState(false);
  const [
    isUpgradeMonitorServicesModalOpen,
    setIsUpgradeMonitorServicesModalOpen,
  ] = useState(false);

  const [serverPrice, setServerPrice] = useState(0);
  // const [serverPriceWL, setServerPriceWL] = useState(0);
  const [addonsPrice, setAddonsPrice] = useState(0);
  // const [addonsPriceWL, setAddonsPriceWL] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [monthlyPriceWL, setMonthlyPriceWL] = useState(0);
  const [hourlySoFarPrice, setHourlySoFarPrice] = useState(0);
  const [hourlyOnPrice, setHourlyOnPrice] = useState(0);
  const [hourlyOffPrice, setHourlyOffPrice] = useState(0);
  const [hourlySoFarPriceWL, setHourlySoFarPriceWL] = useState(0);
  const [hourlyOnPriceWL, setHourlyOnPriceWL] = useState(0);
  const [hourlyOffPriceWL, setHourlyOffPriceWL] = useState(0);

  const fetchServerPrice = useCallback(
    async (forWhitelabel = false) => {
      if (!server) {
        return;
      }

      let userID;
      if (user.role === SUPER_ADMIN && server.userWhitelabel) {
        userID = { whitelabel: server.userWhitelabel };
      } else if (forWhitelabel) {
        userID = user._id;
      } else {
        userID = server.user_id;
      }

      const data = await ajax(`/billing/getServerPrice`, {
        userID,
        serverData: server,
        addons: server.addons
          .filter((addon) => addon.whitelabel === wldvps)
          .map((addon) => addon.addon_id),
        hourlySoFarPrice: true,
      });

      if (data.result === "error") {
        return;
      }

      if (forWhitelabel) {
        setHourlySoFarPriceWL(data.hourlySoFarPrice);
        setHourlyOnPriceWL(data.hourlyPriceOn + data.addonsHourlyPriceOn);
        setHourlyOffPriceWL(data.hourlyPriceOff + data.addonsHourlyPriceOff);
      } else {
        setHourlySoFarPrice(data.hourlySoFarPrice);
        setHourlyOnPrice(data.hourlyPriceOn + data.addonsHourlyPriceOn);
        setHourlyOffPrice(data.hourlyPriceOff + data.addonsHourlyPriceOff);
      }

      const serverPayment = wldvps ? server.whitelabel_payment : server.payment;

      let monthlyPrice;
      if (serverPayment.fixed_price) {
        monthlyPrice = serverPayment.fixed_price;
      } else {
        monthlyPrice = data.monthlyPrice;
      }

      const addonsPrice = convertCurrency(
        data.addonsPrice,
        "USD",
        serverPayment.currency,
        user.rates,
        data.userFixed
      );

      if (forWhitelabel) {
        // setServerPriceWL(monthlyPrice);
        // setAddonsPriceWL(addonsPrice);
        setMonthlyPriceWL(monthlyPrice + addonsPrice);
      } else {
        setServerPrice(monthlyPrice);
        setAddonsPrice(addonsPrice);
        setMonthlyPrice(monthlyPrice + addonsPrice);
      }
    },
    [ajax, server, wldvps, user.rates, user._id, user.role]
  );

  useEffect(() => {
    fetchServerPrice();
  }, [fetchServerPrice]);

  useEffect(() => {
    if (wldvps && user.role === WHITELABEL) {
      fetchServerPrice(!!wldvps);
    }
  }, [fetchServerPrice, wldvps, user.role]);

  // async function handleDefineFirewallClicked() {
  //   const closedVia = await alert(
  //     intl.formatMessage({ id: "overview.firewall-modal.title" }),
  //     intl.formatMessage({ id: "overview.firewall-modal.content" }),
  //     {
  //       text: intl.formatMessage({ id: "overview.firewall-modal.button" }),
  //       icon: "arrow",
  //     }
  //   );

  //   if (closedVia === "button") {
  //     await alert(
  //       intl.formatMessage({ id: "overview.firewall-modal-thanks.title" }),
  //       intl.formatMessage({ id: "overview.firewall-modal-thanks.content" }),
  //       {
  //         text: intl.formatMessage({
  //           id: "overview.firewall-modal-thanks.button",
  //         }),
  //         color: "text",
  //         textColor: "green",
  //       }
  //     );

  //     setStep(step + 1);
  //   }
  // }

  // async function handleManagementServicesClicked() {
  //   setIsUpgradeManagedHostingModalOpen(true);
  // }

  function handleUpgradeManagedHostingModalClosed() {
    setIsUpgradeManagedHostingModalOpen(false);
  }

  // function handleMonitorServicesClicked() {
  //   setIsUpgradeMonitorServicesModalOpen(true);
  // }

  function handleMonitorServicesClosed() {
    setIsUpgradeMonitorServicesModalOpen(false);
  }

  async function handleServerPriceClicked() {
    if (!user || !server) {
      return null;
    }

    const serverPayment = wldvps ? server.whitelabel_payment : server.payment;

    const currency = userCurrency(serverPayment);

    await alert(
      intl.formatMessage({ id: "overview.monthly-cost" }),
      `${intl.formatMessage(
        {
          id: "overview.server-cost",
        },
        {
          serverCostCurrency: currency,
          serverCost: serverPrice.toFixed(3),
          b: (arr) => `<b>${arr[0]}</b>`,
        }
      )}<br />${intl.formatMessage(
        { id: "overview.addons-cost" },
        {
          addonsCostCurrency: currency,
          addonsCost: addonsPrice.toFixed(3),
          b: (arr) => `<b>${arr[0]}</b>`,
        }
      )}<br /><hr />${intl.formatMessage(
        { id: "overview.total-cost" },
        {
          totalCostCurrency: currency,
          totalCost: monthlyPrice.toFixed(3),
          b: (arr) => `<b>${arr[0]}</b>`,
        }
      )}`
    );
  }

  function renderCpuUsage() {
    if (!serverStatus) {
      return "-";
    }

    const value = Math.min(
      100,
      (serverStatus.cpu / serverStatus.cpus || 0) * 100
    );

    return `${value.toFixed(2)}%`;
  }

  function renderRamUsage() {
    if (!serverStatus) {
      return "-";
    }

    const value = Math.min(
      100,
      (serverStatus.mem / serverStatus.maxmem || 0) * 100
    );

    return `${value.toFixed(2)}%`;
  }

  function renderServerPrice(forWhitelabel) {
    if (!user || !server) {
      return null;
    }

    const serverPayment = wldvps ? server.whitelabel_payment : server.payment;

    if (server.payment.payment_type === "hourly") {
      return `${userCurrency(serverPayment)}${(forWhitelabel
        ? hourlySoFarPriceWL
        : hourlySoFarPrice
      ).toFixed(3)} ${intl.formatMessage({
        id: "overview.so-far",
      })}<br /><span style='font-size: 11px;'>${userCurrency(
        serverPayment
      )}${(forWhitelabel ? hourlyOnPriceWL : hourlyOnPrice).toFixed(
        3
      )}/h On | ${userCurrency(serverPayment)}${(forWhitelabel
        ? hourlyOffPriceWL
        : hourlyOffPrice
      ).toFixed(3)}/h Off</span>`;
    }

    return `${userCurrency(serverPayment)}${
      (forWhitelabel ? monthlyPriceWL : monthlyPrice)?.toFixed(2) || 0
    }`;
  }

  if (!server) {
    return null;
  }

  return (
    <WithRole permission="servers.list">
      <div className={styles.wrapper}>
        <div className={styles.usageWrapper}>
          <div className={`${styles.box} ${styles.bandwidth}`}>
            <div className={styles.title}>
              <FormattedMessage id="overview.traffic-usage" />
            </div>
            <div className={`${styles.number} ${styles.bandwidth}`}>
              {renderTraffic(server)}
              {server.payment.payment_type === "monthly" ? " / 5TB" : ""}
            </div>
            {/* <div className={styles.upgrade}>
                <FormattedMessage id="overview.upgrade" />
              </div> */}
          </div>
          <div className={styles.box}>
            <div className={styles.title}>
              <FormattedMessage id="overview.cpu-usage" />
            </div>
            <div className={styles.number}>{renderCpuUsage()}</div>
            {/* <div>
                <MiniGraphSvg />
              </div> */}
          </div>
          <div className={styles.box}>
            <div className={styles.title}>
              <FormattedMessage id="overview.ram-usage" />
            </div>
            <div className={styles.number}>{renderRamUsage()}</div>
            {/* <div>
                <MiniGraphSvg />
              </div> */}
          </div>

          {user.role !== WHITELABEL && (
            <div className={styles.box}>
              <div className={styles.title}>
                <FormattedMessage id="overview.disk-write-read" />
              </div>
              <div className={`${styles.number} ${styles.disk}`}>
                {serverStatus
                  ? `${parseInt(
                      serverStatus.diskread / Math.pow(1024, 3)
                    )}K read`
                  : "-"}
              </div>
              <div className={`${styles.number} ${styles.disk}`}>
                {serverStatus
                  ? `${parseInt(
                      serverStatus.diskwrite / Math.pow(1024, 3)
                    )}K write`
                  : "-"}
              </div>
            </div>
          )}
          {user.role === WHITELABEL && (
            <div className={styles.box}>
              <div className={styles.title}>
                {server.payment.payment_type === "monthly" ? (
                  <FormattedMessage id="overview.your-monthly-cost" />
                ) : (
                  <FormattedMessage id="overview.hourly-cost" />
                )}
              </div>
              <div
                className={`${styles.number} ${
                  server.payment.payment_type === "monthly" ? "" : styles.cost
                }`}
                dangerouslySetInnerHTML={{ __html: renderServerPrice(true) }}
              ></div>
              {server.addons.filter((addon) => addon.whitelabel === null)
                .length > 0 && (
                <div className={styles.subtitle}>
                  <FormattedMessage id="overview.includes-addons" />
                </div>
              )}
            </div>
          )}

          <div className={styles.box}>
            <div className={styles.title}>
              {server.payment.payment_type === "monthly" ? (
                <FormattedMessage
                  id={
                    user.role === WHITELABEL
                      ? "overview.client-monthly-cost"
                      : "overview.monthly-cost"
                  }
                />
              ) : (
                <FormattedMessage id="overview.hourly-cost" />
              )}
            </div>
            <div
              className={`${styles.number} ${
                server.payment.payment_type === "monthly" ? "" : styles.cost
              }`}
            >
              <a
                href="#"
                onClick={handleServerPriceClicked}
                dangerouslySetInnerHTML={{ __html: renderServerPrice() }}
              ></a>
            </div>
            {server.addons.filter((addon) => addon.whitelabel === wldvps)
              .length > 0 && (
              <div className={styles.subtitle}>
                <FormattedMessage id="overview.includes-addons" />
              </div>
            )}
          </div>
        </div>

        {/* <div className={styles.suggestionsWrapper}>
            <div className={styles.title}>
              <FormattedMessage id="overview.suggestions" />
            </div>
            <div>
              <FireStep totalSteps={7} step={step} />
            </div>
            <div className={styles.flex}>
              <div className={styles.nextAction}>
                <FormattedMessage id="overview.next-action" />
              </div>
              <div>
                <div className={styles.textWrapper}>
                  <span className={styles.free}>
                    <FormattedMessage id="general.free" />
                  </span>
                  {step === 2 && (
                    <IconButton
                      color="light-purple"
                      onClick={handleDefineFirewallClicked}
                    >
                      <FormattedMessage id="overview.define-firewall" />
                    </IconButton>
                  )}
                  {step === 3 && (
                    <IconButton
                      color="light-purple"
                      onClick={handleManagementServicesClicked}
                    >
                      <FormattedMessage id="overview.management-services" />
                    </IconButton>
                  )}
                  {step === 4 && (
                    <IconButton
                      color="light-purple"
                      onClick={handleMonitorServicesClicked}
                    >
                      <FormattedMessage id="overview.monitor-services" />
                    </IconButton>
                  )}
                </div>
                <div className={styles.dontCare}>
                  <FormattedMessage id="overview.dont-care" />
                </div>
              </div>
            </div>
          </div> */}

        <div className={styles.overviewWrapper}>
          <Overview server={server} />
        </div>

        {server.status === "Active" && (
          <div className={styles.extraWrapper}>
            <TrackVisibility className={styles.box}>
              <LatestSnapshots />
            </TrackVisibility>

            {isAllowed("servers.scheduler") && (
              <TrackVisibility className={styles.box}>
                <ScheduledTasks />
              </TrackVisibility>
            )}
          </div>
        )}

        {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <div className={styles.extraWrapper}>
            <div className={`${styles.box} ${styles.fullWidth}`}>
              <AdminOptions />
            </div>
          </div>
        )}
      </div>

      <UpgradeManagedHostingModal
        isOpen={isUpgradeManagedHostingModalOpen}
        onClose={handleUpgradeManagedHostingModalClosed}
      />

      <MonitorServicesModal
        isOpen={isUpgradeMonitorServicesModalOpen}
        onClose={handleMonitorServicesClosed}
      />
    </WithRole>
  );
}
