import styles from "./console.module.scss";

import React, { useEffect, useCallback, useState } from "react";
import { useAjax, useServer, useUrls } from "../../../../../utils/hooks";
import IconButton from "../../../../../components/icon-button";
import { FormattedMessage, useIntl } from "react-intl";
import { WithRole } from "../../../../../components/with-role";
import Box from "../../../../../components/box";
import { useDispatch } from "react-redux";
import { updateIsWorking } from "../../../../../store/servers";
import {} from "../../../../../utils";

let timerID;

export default function ServerConsole() {
  const ajax = useAjax();
  const server = useServer();
  const urls = useUrls();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [url, setUrl] = useState(null);
  const [pasteToIframe, setPasteToIframe] = useState(false);
  const [pasting, setPasting] = useState(false);
  const [startingServer, setStartingServer] = useState(false);

  const getConsoleUrl = useCallback(async () => {
    if (!server || url) {
      return;
    }

    const data = await ajax("/servers/getConsoleUrl", { serverID: server._id });

    setUrl(data.url);
  }, [ajax, server, url]);

  useEffect(() => {
    if (server && server.qemuStatus.status === "running") {
      getConsoleUrl();
    }
  }, [getConsoleUrl, server]);

  const handleDomFocusOut = useCallback(() => {
    if (!pasteToIframe) {
      return;
    }

    setTimeout(function () {
      if (
        server &&
        document.activeElement instanceof HTMLIFrameElement &&
        pasteToIframe
      ) {
        setPasteToIframe(false);
        clearTimeout(timerID);

        setPasting({ num: 1, total: 10 });

        navigator.clipboard
          .readText()
          .then((text) => {
            setTimeout(() => {
              document
                .querySelector(".server-console-wrapper")
                .querySelector("iframe")
                .contentWindow.postMessage(
                  { type: "paste", text },
                  urls[`${server.location}_CONSOLE_URL`]
                );
            }, 500);
          })
          .catch(() => {
            alert("Clipboard access not supported / blocked");
          });
      }
    }, 0);
  }, [pasteToIframe, server, urls]);

  useEffect(() => {
    document.addEventListener("focusout", handleDomFocusOut);

    return () => {
      document.removeEventListener("focusout", handleDomFocusOut);
    };
  }, [handleDomFocusOut]);

  useEffect(() => {
    window.addEventListener("message", onWindowMessage);

    return () => {
      window.removeEventListener("message", onWindowMessage);
    };
  }, []);

  function onWindowMessage(data) {
    const { num, total, type } = data.data;

    if (type === "clipboard-progress") {
      setPasting({ num, total });

      if (num >= total) {
        setPasting(false);
      }
    }
  }

  function handlePasteFromClipboardClicked() {
    setPasteToIframe(true);

    timerID = setTimeout(() => {
      setPasteToIframe(false);
    }, 5000);
  }

  async function handlePasteServerPasswordClicked() {
    const data = await ajax(`/servers/getServerPassword`, {
      serverID: server._id,
    });

    if (data.result === "success") {
      await navigator.clipboard.writeText(data.password);

      setPasteToIframe(true);

      timerID = setTimeout(() => {
        setPasteToIframe(false);
      }, 5000);
    }
  }

  async function handleRunServerClicked() {
    if (startingServer) {
      return;
    }

    dispatch(updateIsWorking([server._id], true));

    setStartingServer(true);
    await ajax("/servers/action", {
      serversIds: [server._id],
      actionType: "start",
    });
  }

  function renderContent() {
    if (server && server.qemuStatus.status !== "running") {
      return (
        <Box>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: startingServer
                  ? "server-console.starting-server"
                  : "server-console.no-running",
              }),
            }}
            onClick={handleRunServerClicked}
          ></div>
        </Box>
      );
    }

    return (
      <>
        <div className={styles.buttons}>
          <IconButton
            className={styles.first}
            disabled={!url}
            color="white"
            href={url}
            as={url}
            target="_blank"
          >
            <FormattedMessage id="server-console.new-window" />
          </IconButton>
          <IconButton
            disabled={!url}
            color="white"
            onClick={handlePasteFromClipboardClicked}
          >
            <FormattedMessage id="server-console.paste-clipboard" />
          </IconButton>
          <IconButton
            disabled={!url}
            color="white"
            onClick={handlePasteServerPasswordClicked}
          >
            <FormattedMessage id="server-console.paste-server-password" />
          </IconButton>
        </div>

        {(pasteToIframe || pasting) && (
          <div className={styles.progressWrapper}>
            <FormattedMessage id="server-console.click-on-console" />
            {pasting && (
              <div
                className={styles.progress}
                style={{
                  width: `${(pasting.num / pasting.total) * 100}%`,
                }}
              ></div>
            )}
          </div>
        )}

        {url && <iframe src={url} />}
        {!url && <div className={styles.blackBox}>Loading...</div>}
      </>
    );
  }

  return (
    <WithRole permission="servers.console">
      <div className={`${styles.wrapper} server-console-wrapper`}>
        {renderContent()}
      </div>
    </WithRole>
  );
}
