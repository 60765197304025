import React, { useState } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import AdminUserLayout from "../../../../../components/admin-user-layout";
import WithUser from "../../../../../components/with-user";
import ClientsListCreditsManagement from "./credits-management";
import ClientsListDocuments from "./documents";
import ClientsListOtherProducts from "./other-products";
import ClientsListServers from "./servers";
import ClientsListSummary from "./summary";
import ClientsListTickets from "./tickets";

const reloadUser = {};

export default function ClientsListRouting() {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  return (
    <WithUser userID={id} setUser={setUser} reload={reloadUser}>
      <AdminUserLayout user={user}>
        <Switch>
          <Route
            exact
            path={[
              "/:lang/clients/clients-list/:id",
              "/:lang/clients/clients-list/:id/summary",
            ]}
          >
            <ClientsListSummary user={user} reloadUser={reloadUser} />
          </Route>
          <Route
            exact
            path="/:lang/clients/clients-list/:id/credits-management"
          >
            <ClientsListCreditsManagement user={user} reloadUser={reloadUser} />
          </Route>
          <Route exact path="/:lang/clients/clients-list/:id/servers">
            <ClientsListServers user={user} reloadUser={reloadUser} />
          </Route>
          <Route exact path="/:lang/clients/clients-list/:id/other-products">
            <ClientsListOtherProducts user={user} reloadUser={reloadUser} />
          </Route>
          <Route exact path="/:lang/clients/clients-list/:id/documents">
            <ClientsListDocuments user={user} reloadUser={reloadUser} />
          </Route>
          <Route exact path="/:lang/clients/clients-list/:id/tickets">
            <ClientsListTickets user={user} reloadUser={reloadUser} />
          </Route>
        </Switch>
      </AdminUserLayout>
    </WithUser>
  );
}
