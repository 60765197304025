import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax, useLang, useWLDVPS } from "../../../utils/hooks";
import BasicTable from "../../basic-table";
import Spinner from "../../spinner";
import Checkbox from "../../checkbox";
import { currencySymbols } from "../../../utils/billing";
import { format, getDate, startOfDay } from "date-fns";

function AddOtherProductsToInvoiceModal({ isOpen, onClose, user }) {
  const ajax = useAjax();
  const lang = useLang();
  const wldvps = useWLDVPS();

  const [otherProducts, setOtherProducts] = useState(null);
  const [selectedOtherProducts, setSelectedOtherProducts] = useState({});
  const [selectedIsFirstMonth, setSelectedIsFirstMonth] = useState({});

  const getUser = useCallback(async () => {
    const data = await ajax(`/admin/getUser`, { userID: user.value });

    if (data.result === "success") {
      const otherProducts = data.user.otherProducts.filter(
        (op) => op.whitelabel === wldvps
      );
      setOtherProducts(otherProducts);
    }
  }, [ajax, user, wldvps]);

  useEffect(() => {
    if (isOpen) {
      setOtherProducts(null);
      setSelectedOtherProducts({});
      setSelectedIsFirstMonth({});

      getUser();
    }
  }, [isOpen, getUser]);

  useEffect(() => {
    if (!otherProducts) {
      return;
    }

    const selectedOtherProducts = {};
    const currentDay = getDate(new Date());

    otherProducts.forEach((product) => {
      if (product.is_first_month) {
        selectedIsFirstMonth[product._id] = true;
        selectedOtherProducts[product._id] = product;
      }

      if (
        currentDay >= product.payDay &&
        startOfDay(new Date()) >= startOfDay(new Date(product.next_pay_day))
      ) {
        selectedOtherProducts[product._id] = product;
      }
    });

    setSelectedOtherProducts(selectedOtherProducts);
  }, [otherProducts, selectedIsFirstMonth]);

  function handleServerCheckboxChanged(product) {
    if (product) {
      if (selectedOtherProducts[product._id]) {
        delete selectedOtherProducts[product._id];
      } else {
        selectedOtherProducts[product._id] = product;
      }

      return setSelectedOtherProducts({ ...selectedOtherProducts });
    }

    if (Object.keys(selectedOtherProducts).length === otherProducts.length) {
      setSelectedOtherProducts({});
    } else {
      otherProducts.forEach((server) => {
        selectedOtherProducts[server._id] = server;
      });

      setSelectedOtherProducts({ ...selectedOtherProducts });
    }
  }

  function handleIsFirstMonthCheckboxChanged(product) {
    if (product) {
      if (selectedIsFirstMonth[product._id]) {
        delete selectedIsFirstMonth[product._id];
      } else {
        selectedIsFirstMonth[product._id] = product;
      }

      return setSelectedIsFirstMonth({ ...selectedIsFirstMonth });
    }

    if (Object.keys(selectedIsFirstMonth).length === otherProducts.length) {
      setSelectedIsFirstMonth({});
    } else {
      otherProducts.forEach((server) => {
        selectedIsFirstMonth[server._id] = server;
      });

      setSelectedIsFirstMonth({ ...selectedIsFirstMonth });
    }
  }

  function handleAddClicked() {
    onClose({ selectedOtherProducts, selectedIsFirstMonth });
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-other-products-to-invoice-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div>
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th style={{ width: "50px" }}>
                  <Checkbox
                    checked={
                      Object.keys(selectedOtherProducts).length ===
                      otherProducts?.length
                    }
                    onChange={() => handleServerCheckboxChanged()}
                  />
                </th>
                <th>
                  <FormattedMessage id="add-other-products-to-invoice-modal.name" />
                </th>
                <th>
                  <FormattedMessage id="add-other-products-to-invoice-modal.quantity" />
                </th>
                <th>
                  <FormattedMessage id="add-other-products-to-invoice-modal.price" />
                </th>
                <th>
                  <Checkbox
                    checked={
                      Object.keys(selectedIsFirstMonth).length ===
                      otherProducts?.length
                    }
                    onChange={() => handleIsFirstMonthCheckboxChanged()}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {!otherProducts && (
                <tr>
                  <td colSpan={8}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {otherProducts?.length === 0 && (
                <tr>
                  <td colSpan={8}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {otherProducts?.map((product, key) => (
                <tr key={key}>
                  <td>
                    <Checkbox
                      checked={!!selectedOtherProducts[product._id]}
                      onChange={() => handleServerCheckboxChanged(product)}
                    />
                  </td>
                  <td>
                    <a
                      href={`/${lang}/clients/clients-list/${user.value}/other-products`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {product.name}
                    </a>
                  </td>
                  <td>{product.quantity}</td>
                  <td style={{ padding: "10px 0" }}>
                    <div>
                      price: {currencySymbols[product.currency]}
                      {product.price}
                    </div>
                    <div>
                      pay every: {product.payEvery}{" "}
                      <FormattedMessage id="general.months" />
                    </div>
                    <div>pay day: {product.payDay}th</div>
                    <div>
                      next pay day:{" "}
                      {format(
                        startOfDay(new Date(product.next_pay_day)),
                        "d/M/y"
                      )}
                    </div>
                  </td>
                  <td>
                    <Checkbox
                      checked={!!selectedIsFirstMonth[product._id]}
                      onChange={() =>
                        handleIsFirstMonthCheckboxChanged(product)
                      }
                      label="add-other-products-to-invoice-modal.is-first-month"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={handleAddClicked}>
          <FormattedMessage id="general.add" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddOtherProductsToInvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default AddOtherProductsToInvoiceModal;
