export const JOB_SERVER_START = "JOB_SERVER_START";
export const JOB_SERVER_RESUME = "JOB_SERVER_RESUME";
export const JOB_SERVER_STOP = "JOB_SERVER_STOP";
export const JOB_SERVER_SUSPEND = "JOB_SERVER_SUSPEND";
export const JOB_SERVER_RESTART = "JOB_SERVER_RESTART";
export const JOB_SERVER_CLONE = "JOB_SERVER_CLONE";
export const JOB_SERVER_DESTROY = "JOB_SERVER_DESTROY";
export const JOB_SERVER_CREATE = "JOB_SERVER_CREATE";
export const JOB_SERVER_REINSTALL = "JOB_SERVER_REINSTALL";
export const JOB_CREATE_SNAPSHOT = "JOB_CREATE_SNAPSHOT";
export const JOB_REMOVE_SNAPSHOT = "JOB_REMOVE_SNAPSHOT";
export const JOB_ROLLBACK_SNAPSHOT = "JOB_ROLLBACK_SNAPSHOT";
export const JOB_CHANGE_PASSWORD = "JOB_CHANGE_PASSWORD";
export const JOB_CHANGE_HOSTNAME = "JOB_CHANGE_HOSTNAME";
export const JOB_CHANGE_TAG = "JOB_CHANGE_TAG";
export const JOB_EDIT_SNAPSHOT = "JOB_EDIT_SNAPSHOT";
export const JOB_CHANGE_PLAN = "JOB_CHANGE_PLAN";
export const JOB_PREPARE_SERVER = "JOB_PREPARE_SERVER";
export const JOB_MULTI_CHARGE = "JOB_MULTI_CHARGE";
export const JOB_RESTORE_BACKUP = "JOB_RESTORE_BACKUP";
export const JOB_RESTORE_BACKUP_NEW_SERVER = "JOB_RESTORE_BACKUP_NEW_SERVER";

export function getServerByTask(servers, serverID) {
  if (!servers) {
    return false;
  }

  const server = servers.filter((s) => s._id === serverID);

  return server.length ? server[0] : false;
}

export function getTypeOfTask(task, intl) {
  switch (task.type) {
    case JOB_SERVER_START:
      return intl.formatMessage({ id: "tasks.start" });
    case JOB_SERVER_RESUME:
      return intl.formatMessage({ id: "tasks.resume" });
    case JOB_SERVER_STOP:
      return intl.formatMessage({ id: "tasks.stop" });
    case JOB_SERVER_SUSPEND:
      return intl.formatMessage({ id: "tasks.suspend" });
    case JOB_SERVER_RESTART:
      return intl.formatMessage({ id: "tasks.restart" });
    case JOB_SERVER_CLONE:
      return intl.formatMessage({ id: "tasks.clone" });
    case JOB_SERVER_DESTROY:
      return intl.formatMessage({ id: "tasks.destroy" });
    case JOB_SERVER_CREATE:
      return intl.formatMessage({ id: "tasks.create" });
    case JOB_SERVER_REINSTALL:
      return intl.formatMessage({ id: "tasks.reinstall" });
    case JOB_CREATE_SNAPSHOT:
      return intl.formatMessage({ id: "tasks.create-snapshot" });
    case JOB_EDIT_SNAPSHOT:
      return intl.formatMessage({ id: "tasks.edit-snapshot" });
    case JOB_REMOVE_SNAPSHOT:
      return intl.formatMessage({ id: "tasks.remove-snapshot" });
    case JOB_ROLLBACK_SNAPSHOT:
      return intl.formatMessage({ id: "tasks.rollback-snapshot" });
    case JOB_CHANGE_PASSWORD:
      return intl.formatMessage({ id: "tasks.change-password" });
    case JOB_CHANGE_HOSTNAME:
      return intl.formatMessage({ id: "tasks.change-hostname" });
    case JOB_CHANGE_TAG:
      return intl.formatMessage({ id: "tasks.change-tag" });
    case JOB_CHANGE_PLAN:
      return intl.formatMessage({ id: "tasks.change-plan" });
    case JOB_PREPARE_SERVER:
      return intl.formatMessage({ id: "tasks.prepare-server" });
    case JOB_MULTI_CHARGE:
      return intl.formatMessage({ id: "tasks.multi-charge" });
    case JOB_RESTORE_BACKUP:
      return intl.formatMessage({ id: "tasks.restore-backup" });
    case JOB_RESTORE_BACKUP_NEW_SERVER:
      return intl.formatMessage({ id: "tasks.restore-backup-new-server" });
    default:
      return "";
  }
}

export function getStatusOfTask(task, intl) {
  if (task.step === -1) {
    return intl.formatMessage({ id: "tasks.failed" });
  }

  if (task.finishedAt || task.finished_at) {
    return task.step === -1
      ? intl.formatMessage({ id: "tasks.failed" })
      : intl.formatMessage({ id: "tasks.success" });
  }

  return -1;
}

export function getColorOfTask(status) {
  switch (status) {
    case "Success":
      return "var(--light-green)";
    case "Failed":
      return "var(--red)";
    default:
      return "var(--primary-text)";
  }
}

export const renderSchedulerAction = (action, intl) => {
  switch (action) {
    case "start-server":
      return intl.formatMessage({ id: "scheduler.start-server" });
    case "stop-server-soft":
      return intl.formatMessage({ id: "scheduler.stop-server-soft" });
    case "stop-server-hard":
      return intl.formatMessage({ id: "scheduler.stop-server-hard" });
    case "suspend-server":
      return intl.formatMessage({ id: "scheduler.suspend-server" });
    case "resume-server":
      return intl.formatMessage({ id: "scheduler.resume-server" });
    case "create-snapshot":
      return intl.formatMessage({ id: "scheduler.create-snapshot" });
  }
};
