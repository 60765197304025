import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax, useUser } from "../../../utils/hooks";
import Checkbox from "../../checkbox";
import { SUPER_ADMIN } from "../../../utils/user";

function RemoveIpFromServerModal({
  isOpen,
  onClose,
  ipObject,
  userIDToWork,
  type,
}) {
  const ajax = useAjax();
  const user = useUser();

  const [applyInProxmox, setApplyInProxmox] = useState(false);

  const [loading, setLoading] = useState(false);

  async function handleRemoveClicked() {
    setLoading(true);
    await ajax("/network/removeIpFromServer", {
      applyInProxmox,
      ipObject,
      type,
      userIDToWork: userIDToWork?.value,
    });
    setLoading(false);

    onClose(true);
  }

  useEffect(() => {
    setApplyInProxmox(![SUPER_ADMIN].includes(user.role));
  }, [user]);

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="remove-ip-from-server-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.content}>
          <FormattedMessage
            id="remove-ip-from-server-modal.content"
            values={{ ip: ipObject?.ip }}
          />
        </div>
        {[SUPER_ADMIN].includes(user) && (
          <div>
            <Checkbox
              label="remove-ip-from-server-modal.apply-in-proxmox"
              checked={applyInProxmox}
              onChange={(e) => setApplyInProxmox(e.target.checked)}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleRemoveClicked}
        >
          <FormattedMessage id="general.remove" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

RemoveIpFromServerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  ipObject: PropTypes.object,
  userIDToWork: PropTypes.object,
  type: PropTypes.string,
};

export default RemoveIpFromServerModal;
