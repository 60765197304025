import styles from "./sessions.module.scss";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { syntaxHighlight } from "../../utils";
import { getCountryByCode } from "../../utils/countries";
import { useAjax } from "../../utils/hooks";
import BasicTable from "../basic-table";
import CustomMenu from "../custom-menu";
import CustomMenuItem from "../custom-menu/item";
import IconButton from "../icon-button";

export default function Sessions() {
  const ajax = useAjax();

  const [fingerprints, setFingerprints] = useState([]);
  const [selectedFingerprint, setSelectedFingerprint] = useState(null);
  const [sessionID, setSessionID] = useState(false);

  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState({});
  const [isFingerprintModalOpen, setIsFingerprintModalOpen] = useState(false);

  const getFingerprint = useCallback(async () => {
    const data = await ajax("/fingerprint/get");

    if (data.result === "success") {
      let fingerprints = [];

      data.fingerprints.forEach((fingerprint) => {
        fingerprint.logins = fingerprint.logins.filter(
          (login, index) =>
            index ===
            fingerprint.logins.findIndex(
              (obj) =>
                obj.ip === login.ip &&
                obj.session_id === login.session_id &&
                obj.country === login.country
            )
        );

        fingerprints.push(fingerprint);
      });

      fingerprints = fingerprints.sort((a, b) => {
        if (new Date(a.created_at) === new Date(b.created_at)) {
          return 0;
        }

        return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1;
      });

      setFingerprints(fingerprints);
      setSessionID(data.sessionID);
    }
  }, [ajax]);

  useEffect(() => {
    getFingerprint();
  }, [getFingerprint]);

  function handleShowFingerprintClicked(fingerprint) {
    setSelectedFingerprint(fingerprint);
    setIsFingerprintModalOpen(true);
  }

  async function handleRemoveSessionMenuClicked(fingerprintID, sessionID) {
    await ajax(`/fingerprint/logSessionOut`, {
      fingerprintID,
      sessionID,
    });

    await getFingerprint();
  }

  function handleLoginDropdownToggle(key) {
    isLoginDropdownOpen[key] = !isLoginDropdownOpen[key];
    setIsLoginDropdownOpen({ ...isLoginDropdownOpen });
  }

  function handleFingerprintModalToggle() {
    setIsFingerprintModalOpen(!isFingerprintModalOpen);
  }

  return (
    <div className={styles.wrapper}>
      <BasicTable>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="sessions.login-date" />
            </th>
            <th>
              <FormattedMessage id="sessions.ip" />
            </th>
            <th>
              <FormattedMessage id="sessions.country" />
            </th>
            <th className={styles.onlyOnDesktop}>
              <FormattedMessage id="sessions.fingerprint" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fingerprints.map((fingerprint, fingerprintIDX) =>
            fingerprint.logins.map((login, loginIDX) => (
              <tr key={`${fingerprintIDX}_${loginIDX}`}>
                <td>{format(new Date(login.created_at), "dd/MM/yy HH:mm")}</td>
                <td>{login.ip}</td>
                <td>{getCountryByCode(login.country)}</td>
                <td className={styles.onlyOnDesktop}>
                  <IconButton
                    onClick={() => handleShowFingerprintClicked(fingerprint)}
                    color="text"
                  >
                    <FormattedMessage id="general.show..." />
                  </IconButton>
                </td>
                <td>
                  {login.session_id !== sessionID && (
                    <CustomMenu
                      isOpen={
                        isLoginDropdownOpen[`${fingerprintIDX}_${loginIDX}`]
                      }
                      toggle={() =>
                        handleLoginDropdownToggle(
                          `${fingerprintIDX}_${loginIDX}`
                        )
                      }
                    >
                      <CustomMenuItem
                        onClick={() =>
                          handleRemoveSessionMenuClicked(
                            fingerprint._id,
                            login.session_id
                          )
                        }
                      >
                        <FormattedMessage id="sessions.log-session-out" />
                      </CustomMenuItem>
                    </CustomMenu>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </BasicTable>

      <Modal
        isOpen={isFingerprintModalOpen}
        toggle={handleFingerprintModalToggle}
        size="lg"
      >
        <ModalHeader toggle={handleFingerprintModalToggle}>
          <FormattedMessage id="sessions.fingerprint" />
        </ModalHeader>
        <ModalBody>
          {selectedFingerprint && (
            <pre
              className="source-box"
              dangerouslySetInnerHTML={{
                __html: syntaxHighlight(
                  JSON.parse(selectedFingerprint.fingerprint)
                ),
              }}
            ></pre>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
