import styles from "./tasks.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { format } from "date-fns";
import { ReactComponent as TrashSvg } from "../../../../../components/svgs/trash.svg";
import { ReactComponent as EditSvg } from "../../../../../components/svgs/edit-purple.svg";
import Box from "../../../../../components/box";
import IconButton from "../../../../../components/icon-button";
import BasicTable from "../../../../../components/basic-table";
import ScheduleNewTaskModal from "../../../../../components/modals/schedule-new-task";
import { useServer, useAjax, useConfirm } from "../../../../../utils/hooks";
import { renderSchedulerAction } from "../../../../../utils/tasks";
import { getDayAsText } from "../../../../../utils";
import { WithRole } from "../../../../../components/with-role";
import Spinner from "../../../../../components/spinner";

export default function ServerTasks() {
  const server = useServer();
  const ajax = useAjax();
  const intl = useIntl();
  const confirm = useConfirm();
  const [selectedTask, setSelectedTask] = useState(null);

  const [tasks, setTasks] = useState(null);
  const [isScheduleNewTaskModalOpen, setIsScheduleNewTaskModalOpen] = useState(
    false
  );

  const getScheduledTasks = useCallback(async () => {
    if (!server) {
      return null;
    }

    const data = await ajax(`/scheduler/getScheduledTasks`, {
      serverID: server._id,
    });

    if (data.result === "success") {
      setTasks(data.tasks);
    }
  }, [ajax, server]);

  useEffect(() => {
    getScheduledTasks();
  }, [getScheduledTasks]);

  function handleCreateNewTaskClicked() {
    setSelectedTask(null);
    setIsScheduleNewTaskModalOpen(true);
  }

  async function handleScheduleNewTaskModalClosed(status) {
    if (status) {
      await getScheduledTasks();
    }

    setIsScheduleNewTaskModalOpen(false);
  }

  function handleEditScheduledTaskClicked(task) {
    setSelectedTask(task);
    setIsScheduleNewTaskModalOpen(true);
  }

  async function handleRemoveScheduledTaskClicked(task) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "server-tasks.remove-scheduled-task.title",
      }),
      message: intl.formatMessage({
        id: "server-tasks.remove-scheduled-task.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/scheduler/removeScheduledTask", {
      serverID: server._id,
      schedulerID: task._id,
    });
    await getScheduledTasks();
  }

  function renderAt(task) {
    if (task.frequency === "weekly") {
      return task.at.reduce(
        (str, item, key) =>
          `${str}${key > 0 ? ", " : ""}${getDayAsText(parseInt(item) + 1)}`,
        ""
      );
    } else if (task.frequency === "monthly") {
      return `${task.at[0]}st`;
    }

    return "-";
  }

  return (
    <WithRole permission="servers.scheduler">
      <div className={styles.wrapper}>
        <Box>
          <div className={styles.title}>
            <FormattedMessage id="server-tasks.title" />
          </div>
          <div>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="server-tasks.action" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.frequency" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.at" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.once" />
                  </th>
                  <th>
                    <FormattedMessage id="server-tasks.next-run" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!tasks && (
                  <tr>
                    <td colSpan={6}>
                      <div className="spinner-wrapper">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}

                {tasks?.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {tasks?.map((task, key) => (
                  <tr key={key}>
                    <td>{renderSchedulerAction(task.action, intl)}</td>
                    <td className="capitalize">{task.frequency}</td>
                    <td
                      className="capitalize"
                      dangerouslySetInnerHTML={{ __html: renderAt(task) }}
                    ></td>
                    <td>
                      {task.once ? <FormattedMessage id="general.yes" /> : ""}
                    </td>
                    <td>{format(new Date(task.next_run), "d/M/y HH:mm")}</td>
                    <td>
                      <EditSvg
                        className="pointer"
                        onClick={() => handleEditScheduledTaskClicked(task)}
                      />
                      <TrashSvg
                        className="pointer"
                        onClick={() => handleRemoveScheduledTaskClicked(task)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          </div>
          <div className={styles.button}>
            <IconButton
              disabled={server?.isWorking}
              color="light-purple"
              onClick={handleCreateNewTaskClicked}
            >
              <FormattedMessage id="server-tasks.create" />
            </IconButton>
          </div>
        </Box>

        <ScheduleNewTaskModal
          isOpen={isScheduleNewTaskModalOpen}
          onClose={handleScheduleNewTaskModalClosed}
          tasks={tasks}
          editTask={selectedTask}
        />
      </div>
    </WithRole>
  );
}
