import styles from "./change-payment.module.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { WithRole } from "../../../../../../components/with-role";
import { capitalize } from "../../../../../../utils";
import {
  useAjax,
  useAlert,
  useServer,
  useUser,
} from "../../../../../../utils/hooks";
import IconButton from "../../../../../../components/icon-button";
import Box from "../../../../../../components/box";

export default function ServerSettingsChangePayment() {
  const intl = useIntl();
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();
  const server = useServer();

  const [planToChange, setPlanToChange] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!server) {
      return;
    }

    setPlanToChange(
      server.payment.payment_type === "monthly" ? "hourly" : "monthly"
    );
  }, [server]);

  async function handleChangePaymentTypeClicked() {
    setLoading(true);
    const data = await ajax(`/servers/changeServerPaymentType`, {
      serverID: server._id,
    });

    if (data.result === "success") {
      await alert(
        intl.formatMessage({ id: "server-settings-change-payment.title" }),
        intl.formatMessage({
          id: "server-settings-change-payment.plan-changed",
        })
      );
    } else {
      await alert(
        intl.formatMessage({ id: "server-settings-change-payment.title" }),
        intl.formatMessage({ id: "general.failed-content" })
      );
    }

    setLoading(false);
  }

  async function handleCancelChangeRequestClicked() {
    setLoading(true);
    const data = await ajax(`/servers/changeServerPaymentType`, {
      serverID: server._id,
    });

    if (data.result === "success") {
      await alert(
        intl.formatMessage({ id: "server-settings-change-payment.title" }),
        intl.formatMessage({
          id: "server-settings-change-payment.request-cancelled",
        })
      );
    } else {
      await alert(
        intl.formatMessage({ id: "server-settings-change-payment.title" }),
        intl.formatMessage({ id: "general.failed-content" })
      );
    }

    setLoading(false);
  }

  function renderBox() {
    if (!server) {
      return null;
    }

    if (server.payment.change_to) {
      return (
        <Box>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: "server-settings-change-payment.currently",
                },
                {
                  plan: capitalize(server.payment.payment_type),
                  b: (arr) => `<b>${arr[0]}</b>`,
                }
              ),
            }}
          ></div>
          <br />
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: "server-settings-change-payment.info-to-cancel",
                },
                {
                  planToChange: capitalize(server.payment.change_to),
                  b: (arr) => `<b>${arr[0]}</b>`,
                }
              ),
            }}
          ></div>

          <IconButton
            disabled={loading}
            color="light-purple"
            onClick={handleCancelChangeRequestClicked}
          >
            <FormattedMessage
              id={`server-settings-change-payment.cancel-change-request`}
            />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "server-settings-change-payment.currently",
              },
              {
                plan: capitalize(server.payment.payment_type),
                b: (arr) => `<b>${arr[0]}</b>`,
              }
            ),
          }}
        ></div>
        <br />
        <div className={styles.content}>
          <FormattedMessage
            id="server-settings-change-payment.info"
            values={{ planToChange: capitalize(planToChange) }}
          />{" "}
          {!user.change_payment_plan_immediately && (
            <FormattedMessage id="server-settings-change-payment.info2" />
          )}
        </div>
        {planToChange && (
          <IconButton
            disabled={loading}
            color="light-purple"
            onClick={handleChangePaymentTypeClicked}
          >
            <FormattedMessage
              id={`server-settings-change-payment.change-to-${planToChange}`}
            />
          </IconButton>
        )}
      </Box>
    );
  }

  return (
    <WithRole permission="servers.change-payment">
      <div className={styles.wrapper}>{renderBox()}</div>
    </WithRole>
  );
}
