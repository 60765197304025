import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../checkbox";
import { FormattedMessage } from "react-intl";
import { sanitize } from "../../utils";

function CheckboxSelector({ onChange, items, selectedItems, className }) {
  function handleItemClicked(e, item) {
    e.preventDefault();
    onChange(item);
  }

  return (
    <div className={`${styles.radioSelector} ${className || ""}`}>
      {items.map((item, idx) => (
        <div
          key={idx}
          className={styles.itemWrapper}
          onClick={(e) => handleItemClicked(e, item)}
        >
          <div className={styles.radioWrapper}>
            <Checkbox checked={!!selectedItems[item._id]} readOnly />
          </div>
          <div className={styles.title}>
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(item.title) }}
            ></div>
            {item.description && (
              <div
                className={`${styles.description} mt4`}
                dangerouslySetInnerHTML={{ __html: sanitize(item.description) }}
              ></div>
            )}
          </div>
          <div className={styles.priceWrapper}>
            {item.price.currency}
            {item.price.num.toFixed(2)}
            {item.price.per && (
              <span>
                {" / "}
                {item.price.per === "month" && (
                  <span className={styles.per}>
                    <FormattedMessage id="general.month" />
                  </span>
                )}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

CheckboxSelector.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.array,
  selectedItems: PropTypes.object,
  className: PropTypes.string,
};

export default CheckboxSelector;
