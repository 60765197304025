import styles from "./credits-management.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "../../../../../components/box";
import { FormattedMessage, useIntl } from "react-intl";
import CustomText from "../../../../../components/custom-text";
import { useAjax } from "../../../../../utils/hooks";
import IconButton from "../../../../../components/icon-button";
import BasicTable from "../../../../../components/basic-table";
import { format } from "date-fns";
import {} from "../../../../../utils";
import Spinner from "../../../../../components/spinner";

function ClientsListCreditsManagement({ user, reloadUser }) {
  const ajax = useAjax();
  const intl = useIntl();

  const [creditsToAdd, setCreditsToAdd] = useState(0);
  const [reason, setReason] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSetCreditClicked() {
    setError(false);

    const _reason = reason?.trim();
    const _creditsToAdd = parseFloat(creditsToAdd);

    if (!_creditsToAdd || _creditsToAdd === 0) {
      return setError(
        intl.formatMessage({
          id: "clients-list-credits-management.wrong-credits-to-add",
        })
      );
    } else if (!_reason) {
      return setError(
        intl.formatMessage({
          id: "clients-list-credits-management.missing-reason",
        })
      );
    }

    setLoading(true);
    await ajax("/admin/setCredits", {
      creditsToAdd,
      reason,
      userID: user._id,
    });
    setLoading(false);

    setSuccess(creditsToAdd > 0 ? 1 : -1);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);

    setCreditsToAdd(0);
    setReason("");

    reloadUser.do();
  }

  return (
    <div className={styles.wrapper}>
      <Box>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="clients-list-credits-management.current-credits" />
            :
          </span>
          <span>${user?.credits}</span>
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="clients-list-credits-management.add-reduce" />
          </span>
          <CustomText
            value={creditsToAdd}
            onChange={(e) => setCreditsToAdd(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="clients-list-credits-management.reason-for-change" />
          </span>
          <CustomText
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>

        {error && <div className="error">{error}</div>}
        {success && (
          <div className="success">
            <FormattedMessage
              id={
                success === 1
                  ? "clients-list-credits-management.success-added"
                  : "clients-list-credits-management.success-reduced"
              }
            />
          </div>
        )}

        <IconButton
          disabled={loading}
          color="light-purple"
          onClick={handleSetCreditClicked}
        >
          <FormattedMessage
            id={creditsToAdd < 0 ? "general.reduce" : "general.add"}
          />
        </IconButton>
      </Box>

      <Box>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="clients-list-credits-management.date" />
              </th>
              <th>
                <FormattedMessage id="clients-list-credits-management.amount" />
              </th>
              <th>
                <FormattedMessage id="clients-list-credits-management.reason" />
              </th>
            </tr>
          </thead>
          <tbody>
            {!user?.creditsLogs && (
              <tr>
                <td colSpan={3}>
                  <div className="spinner-wrapper">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}

            {user?.creditsLogs.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <FormattedMessage id="general.no-rows" />
                </td>
              </tr>
            )}

            {user?.creditsLogs.map((log, key) => (
              <tr key={key}>
                <td>{format(new Date(log.created_at), "d/M/yyyy HH:mm")}</td>
                <td>${log.amount}</td>
                <td>{log.reason}</td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </Box>
    </div>
  );
}

ClientsListCreditsManagement.propTypes = {
  user: PropTypes.object,
  reloadUser: PropTypes.object,
};

export default ClientsListCreditsManagement;
