import styles from "./cpanel-license.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import RadioSelector from "../radio-selector";
import CheckboxSelector from "../checkbox-selector";

function WindowsServerExtras({ fullWidth = false }) {
  const intl = useIntl();

  const [items, setItems] = useState([
    {
      title: "<b>Eset</b>",
      description: "Explanation about the benefits of profesional service",
      selected: false,
      price: { num: 5, currency: "$", per: "month" },
    },
    {
      title: "<b>SQL Standard edition</b>",
      description: "Explanation about the benefits of profesional service",
      selected: false,
      price: { num: 22, currency: "$", per: "month" },
    },
    {
      title: "<b>SQL Enterprise</b>",
      description: "Explanation about the benefits of profesional service",
      selected: false,
      price: { num: 500, currency: "$", per: "month" },
    },
    {
      title: "<b>Microsoft Office Professional plus</b>",
      description: "Explanation about the benefits of profesional service",
      selected: false,
      price: { num: 20, currency: "$", per: "month" },
    },
    {
      title: "<b>Remote desktop (TS CAL Licence)</b>",
      description: "Explanation about the benefits of profesional service",
      selected: false,
      price: { num: 7, currency: "$" },
    },
  ]);

  function handleSelectorChanged(item) {
    item.selected = !item.selected;
    setItems([...items]);
  }

  return (
    <div className={`${styles.wrapper} ${fullWidth && styles.fullWidth}`}>
      <div className={styles.title}>
        <FormattedMessage id="windows-server-extra.web-panel" />
      </div>
      <RadioSelector
        className={styles.selector}
        value={null}
        onChange={() => {}}
        items={[
          {
            title: "Plesk Web Admin Edition",
            value: "plesk-admin",
            price: { num: 10, currency: "$", per: "month" },
          },
          {
            title: "Plesk Web Pro Edition",
            value: "plesk-pro",
            price: { num: 15, currency: "$", per: "month" },
          },
          {
            title: "Plesk Web Host Edition",
            value: "plesk-host",
            price: { num: 25, currency: "$", per: "month" },
          },
        ]}
      />

      <div
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: "windows-server-extra.additional-services",
          }),
        }}
      ></div>
      <CheckboxSelector
        className={styles.selector}
        onChange={handleSelectorChanged}
        items={items}
      />
    </div>
  );
}

WindowsServerExtras.propTypes = {
  fullWidth: PropTypes.bool,
};

export default WindowsServerExtras;
