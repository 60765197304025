import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";

function Counter({
  count,
  setCount,
  render,
  extraText = "",
  min = 1,
  max = Number.MAX_SAFE_INTEGER,
  delta = 1,
}) {
  function handleDecCount() {
    setCount(Math.max(count - delta, min));
  }

  function handleIncCount() {
    setCount(Math.min(count + delta, max));
  }

  return (
    <div className={styles.counterWrapper}>
      <span>
        {render || count}
        {extraText ? ` ${extraText}` : ""}
      </span>
      <div className={styles.button} onClick={handleDecCount}>
        -
      </div>
      <div className={styles.button} onClick={handleIncCount}>
        +
      </div>
    </div>
  );
}

Counter.propTypes = {
  render: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  count: PropTypes.number,
  setCount: PropTypes.func,
  extraText: PropTypes.string,
  delta: PropTypes.number,
};

export default Counter;
