import styles from "./index.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import PasswordStrengthBar from "react-password-strength-bar";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import CustomReactSelect from "../../custom-react-select";
import validator from "validator";
import GeneratePasswordModal from "../generate-password";

function CreateNewUserModal({ isOpen, onClose, editUser }) {
  const ajax = useAjax();
  const intl = useIntl();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [
    isGeneratePasswordModalOpen,
    setIsGeneratePasswordModalOpen,
  ] = useState(false);
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState(null);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getRoles = useCallback(async () => {
    const data = await ajax("/roles/getAll");

    if (data.result === "success") {
      setRoles(
        data.roles.map((role) => ({ label: role.name, value: role._id }))
      );
    }
  }, [ajax]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    if (isOpen) {
      setEmail("");
      setPassword("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (editUser && roles) {
      setRole(roles.find((role) => role.value === editUser.role._id));
    }
  }, [editUser, roles]);

  async function handleCreateClicked() {
    let data;

    if (editUser) {
      setLoading(true);
      data = await ajax("/roles/editUserWithRole", {
        roleID: role.value,
        userID: editUser.user._id,
      });
      setLoading(false);
    } else {
      const _email = email?.trim().toLowerCase();
      const _password = password?.trim();

      setError(false);

      if (!validator.isEmail(_email)) {
        return setError(intl.formatMessage({ id: "general.wrong-email" }));
      } else if (passwordScore <= 1) {
        return setError(intl.formatMessage({ id: "general.weak-password" }));
      } else if (!role) {
        return setError(
          intl.formatMessage({ id: "create-new-user-modal.empty-role" })
        );
      }

      setLoading(true);
      data = await ajax("/roles/createUserAndRoles", {
        email: _email,
        password: _password,
        roleID: role.value,
      });
      setLoading(false);
    }

    if (data.result === "success") {
      onClose(true);
    } else {
      setError(intl.formatMessage({ id: data.message }));
    }
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  return (
    <>
      <Modal
        className={styles.wrapper}
        isOpen={isOpen}
        toggle={() => onClose(false)}
      >
        <ModalHeader toggle={() => onClose(false)}>
          <FormattedMessage
            id={
              editUser
                ? "create-new-user-modal.edit-title"
                : "create-new-user-modal.title"
            }
          />
        </ModalHeader>
        <ModalBody>
          {!editUser && (
            <div className={styles.description1}>
              <FormattedMessage id="create-new-user-modal.description1" />
            </div>
          )}

          <div className={styles.fields}>
            {!editUser && (
              <>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-user-modal.email" />
                  </span>{" "}
                  <CustomText
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="create-new-user-modal.password" />
                  </span>{" "}
                  <div className={styles.passwordWrapper}>
                    <CustomText
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <IconButton
                      color="white"
                      onClick={() => setIsGeneratePasswordModalOpen(true)}
                    >
                      <FormattedMessage id="general.generate" />
                    </IconButton>
                  </div>
                </div>

                <div className={styles.passwordStrengthBarWrapper}>
                  <PasswordStrengthBar
                    password={password}
                    onChangeScore={setPasswordScore}
                  />
                </div>
              </>
            )}
            <div className={styles.row}>
              <span>
                <FormattedMessage id="create-new-user-modal.role" />
              </span>{" "}
              <CustomReactSelect
                instanceId="create-new-user-modal-role"
                options={roles}
                value={role}
                onChange={(item) => setRole(item)}
              />
            </div>
          </div>

          {!editUser && (
            <div
              className={styles.description2}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "create-new-user-modal.description2",
                }),
              }}
            ></div>
          )}

          {error && <div className="error">{error}</div>}
        </ModalBody>
        <ModalFooter>
          <IconButton
            disabled={loading}
            color="purple"
            onClick={handleCreateClicked}
          >
            <FormattedMessage
              id={
                editUser
                  ? "create-new-user-modal.edit-title"
                  : "create-new-user-modal.title"
              }
            />
          </IconButton>
          <IconButton
            disabled={loading}
            color="text"
            onClick={() => onClose(false)}
          >
            <FormattedMessage id="general.cancel" />
          </IconButton>
        </ModalFooter>
      </Modal>

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
    </>
  );
}

CreateNewUserModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editUser: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default CreateNewUserModal;
