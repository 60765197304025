import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ReactComponent as HelpSvg } from "../../components/svgs/help.svg";
import Radio from "../radio";
import { Tooltip } from "reactstrap";
import { sanitize } from "../../utils";

function RadioSelector({ value, onChange, items, className }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState({});

  function handleItemTooltipChanged(item) {
    isTooltipOpen[item._id] = !isTooltipOpen[item._id];
    setIsTooltipOpen({ ...isTooltipOpen });
  }

  return (
    <div className={`${styles.radioSelector} ${className || ""}`}>
      {items.map((item, idx) => (
        <div
          key={idx}
          className={styles.itemWrapper}
          onClick={() => onChange(item.value)}
        >
          <div className={styles.radioWrapper}>
            <Radio
              value={item.value}
              checked={item.value === value}
              onChange={onChange}
            />
          </div>

          <div className={styles.title}>
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(item.title) }}
            ></div>

            {item.product.tooltip_info && (
              <>
                <div id={`tooltip-${item._id}`}>
                  <div>
                    <HelpSvg />
                  </div>
                </div>

                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen[item._id]}
                  target={`tooltip-${item._id}`}
                  toggle={() => handleItemTooltipChanged(item)}
                >
                  {item.product.tooltip_info}
                </Tooltip>
              </>
            )}
          </div>

          <div className={styles.priceWrapper}>
            {item.price.currency}
            {item.price.num.toFixed(2)}
            {item.price.per && (
              <span>
                {" / "}
                {item.price.per === "month" && (
                  <span className={styles.per}>
                    <FormattedMessage id="general.month" />
                  </span>
                )}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

RadioSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  items: PropTypes.array,
  className: PropTypes.string,
};

export default RadioSelector;
