import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax, useInitialDataFetching } from "../../../utils/hooks";
import CustomText from "../../custom-text";

function TwoFactorAuthenticationModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const intl = useIntl();
  const initialDataFetching = useInitialDataFetching();

  const [qr, setQR] = useState(null);
  const [secret, setSecret] = useState(null);
  const [code, setCode] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const get2FAQR = useCallback(async () => {
    const data = await ajax("/users/get2FAQR");

    setQR(data.qr);
    setSecret(data.secret);
  }, [ajax]);

  useEffect(() => {
    if (isOpen) {
      setCode("");
      get2FAQR();
    }
  }, [isOpen, get2FAQR]);

  async function handleActiveClicked() {
    setError(false);

    setLoading(true);
    const data = await ajax(`/users/active2FA`, { code });
    setLoading(false);

    if (data.result === "success") {
      await initialDataFetching();
      onClose(true);
    } else {
      setError(
        intl.formatMessage({ id: "two-factor-authentication-modal.wrong-code" })
      );
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="two-factor-authentication-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "two-factor-authentication-modal.instructions",
            }),
          }}
        ></div>

        {qr && (
          <div className={styles.imgWrapper}>
            <img src={qr} width={166} height={166} />
          </div>
        )}

        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "two-factor-authentication-modal.instructions2",
              },
              { secret, b: (arr) => `<b>${arr[0]}</b>` }
            ),
          }}
        ></div>

        <br />

        <div>
          <FormattedMessage id="two-factor-authentication-modal.instructions3" />
        </div>

        <div className={styles.textWrapper}>
          <CustomText value={code} onChange={(e) => setCode(e.target.value)} />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleActiveClicked}
        >
          <FormattedMessage id="general.active" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

TwoFactorAuthenticationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TwoFactorAuthenticationModal;
