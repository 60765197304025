import styles from "./monitor-content.module.scss";

import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Radio from "../../radio";

export default function MonitorContent() {
  const [plan, setPlan] = useState("basic");

  return (
    <div className={styles.wrapper}>
      <div className={styles.row} onClick={() => setPlan("basic")}>
        <div className={styles.radio}>
          <Radio checked={plan === "basic"} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <FormattedMessage id="monitor-services-modal.basic.title" />
          </div>
          <div className={styles.description}>
            <FormattedMessage id="monitor-services-modal.basic.description" />
          </div>
          <div className={styles.price}>
            $2 <FormattedMessage id="general.monthly" />
          </div>
        </div>
      </div>

      <div className={styles.row} onClick={() => setPlan("business")}>
        <div className={styles.radio}>
          <Radio checked={plan === "business"} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <FormattedMessage id="monitor-services-modal.business.title" />
          </div>
          <div className={styles.description}>
            <FormattedMessage id="monitor-services-modal.business.description" />
          </div>
          <div className={styles.price}>
            $5 <FormattedMessage id="general.monthly" />
          </div>
        </div>
      </div>

      <div className={styles.row} onClick={() => setPlan("agency")}>
        <div className={styles.radio}>
          <Radio checked={plan === "agency"} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <FormattedMessage id="monitor-services-modal.agency.title" />
          </div>
          <div className={styles.description}>
            <FormattedMessage id="monitor-services-modal.agency.description" />
          </div>
          <div className={styles.price}>
            $10 <FormattedMessage id="general.monthly" />
          </div>
        </div>
      </div>
    </div>
  );
}
