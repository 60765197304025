import styles from "./jobs-manager.module.scss";

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useLang } from "../../../utils/hooks";
import Box from "../../../components/box";
import BasicTable from "../../../components/basic-table";
import { differenceInSeconds, format } from "date-fns";
import IconButton from "../../../components/icon-button";
import {} from "../../../utils";

export default function ManagementJobsManager() {
  const ajax = useAjax();
  const intl = useIntl();
  const lang = useLang();

  const [jobs, setJobs] = useState(null);
  const [failedJobs, setFailedJobs] = useState(null);
  const [serverTime, setServerTime] = useState(null);
  const [showLogsForJob, setShowLogsForJob] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    const data = await ajax(`/admin/getJobs`);

    if (data.result === "error") {
      return;
    }

    setJobs(data.jobs);
    setFailedJobs(data.failedJobs);
    setServerTime(data.serverTime);
  }, [ajax]);

  useEffect(() => {
    let timerID;

    fetch();
    timerID = setInterval(() => fetch(), 2000);

    return () => {
      clearInterval(timerID);
    };
  }, [fetch]);

  async function handleMarkAsResolvedClicked(e, job) {
    e.preventDefault();

    setLoading(true);
    await ajax(`/admin/markAsResolved`, {
      jobID: job._id,
    });

    await fetch();
    setLoading(false);
  }

  async function handleStopJobClicked(job) {
    setLoading(true);
    await ajax(`/jobs/cancel`, {
      jobID: job._id,
      force: true,
    });

    await fetch();

    setLoading(false);
  }

  function handleJobRowClicked(job) {
    if (job._id === showLogsForJob._id) {
      setShowLogsForJob(false);
      return;
    }

    setShowLogsForJob(job);
  }

  return (
    <WithRole permission="super-admin.jobs-manager">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-jobs-manager.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box
            title={intl.formatMessage({
              id: "management-jobs-manager.on-progress",
            })}
          >
            <BasicTable layout="auto">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="management-jobs-manager.created-at" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.job" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.progress" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.timeout-counter" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.user" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.server" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.action" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobs?.length === 0 && (
                  <tr>
                    <td colSpan={7}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}
                {jobs?.map((job, key) => (
                  <Fragment key={key}>
                    <tr>
                      <td>
                        {" "}
                        {format(new Date(job.created_at), "dd/MM/yy HH:mm")}
                      </td>
                      <td>{job.type}</td>
                      <td>
                        {job.step} / {job.totalSteps}
                      </td>
                      <td>
                        {job.started_at
                          ? differenceInSeconds(
                              new Date(job.timeout_at),
                              new Date(serverTime)
                            )
                          : "-"}
                      </td>
                      <td>
                        {job.user.firstName} {job.user.lastName}
                      </td>
                      <td>
                        <a
                          href={`/${lang}/my-cloud/servers/${job.server_id}/overview`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {job.server_id}
                        </a>
                        {job.server && (
                          <>
                            <br />
                            {job.server.hostname}
                          </>
                        )}
                      </td>
                      <td>
                        <IconButton
                          color="light-purple"
                          onClick={() => handleStopJobClicked(job)}
                        >
                          <FormattedMessage id="general.stop" />
                        </IconButton>
                      </td>
                    </tr>
                    {showLogsForJob._id === job._id && (
                      <tr>
                        <td colSpan={7}>
                          {job.logs.map((l, idx) => (
                            <div key={idx}>
                              {format(new Date(l.created_at), "dd/MM/yy HH:mm")}{" "}
                              - {l.text}
                            </div>
                          ))}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </BasicTable>
          </Box>

          <Box
            title={intl.formatMessage({
              id: "management-jobs-manager.failed-jobs",
            })}
          >
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="management-jobs-manager.created-at" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.job" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.user" />
                  </th>
                  <th>
                    <FormattedMessage id="management-jobs-manager.server" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {failedJobs?.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}
                {failedJobs?.map((job, key) => (
                  <Fragment key={key}>
                    <tr
                      className="cursor-pointer"
                      onClick={() => handleJobRowClicked(job)}
                    >
                      <td>
                        {format(new Date(job.created_at), "dd/MM/yy HH:mm")}
                      </td>
                      <td>{job.type}</td>
                      <td>
                        {job.user.firstName} {job.user.lastName}
                      </td>
                      <td>
                        {job.server_id}{" "}
                        {job.server && job.server.hostname
                          ? `(${job.server.hostname})`
                          : ""}
                      </td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <a
                          href="#"
                          className={`btn-link ${loading ? "disabled" : ""}`}
                          onClick={(e) => handleMarkAsResolvedClicked(e, job)}
                        >
                          <FormattedMessage id="admin-jobs-manager.mark-as-resolved" />
                        </a>
                      </td>
                    </tr>
                    {showLogsForJob._id === job._id && (
                      <tr>
                        <td colSpan={5}>
                          {job.logs.map((l, idx) => (
                            <div key={idx}>
                              {format(new Date(l.created_at), "dd/MM/yy HH:mm")}{" "}
                              -{" "}
                              {typeof l.text === "string"
                                ? l.text
                                : JSON.stringify(l.text)}
                            </div>
                          ))}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </BasicTable>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
