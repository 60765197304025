import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import { validateIPaddress } from "../../../utils/networks";

function AddNewAliasModal({ isOpen, onClose, edit, userIDToWork }) {
  const intl = useIntl();
  const ajax = useAjax();

  const [name, setName] = useState("");
  const [ip, setIP] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (edit) {
        setName(edit.name);
        setIP(edit.ip);
      } else {
        setName("");
        setIP("");
      }

      setError(false);
      setLoading(false);
    }
  }, [isOpen, edit]);

  async function handleAddAliasClicked() {
    const _name = name.trim();

    setError(false);

    if (!_name) {
      return setError(
        intl.formatMessage({ id: "add-new-alias-modal.no-name" })
      );
    } else if (!validateIPaddress(ip)) {
      return setError(
        intl.formatMessage({ id: "add-new-alias-modal.wrong-ip" })
      );
    }

    setLoading(true);
    if (edit) {
      await ajax("/firewall/editAlias", {
        userIDToWork,
        aliasID: edit._id,
        name,
        ip,
      });
    } else {
      await ajax("/firewall/addAlias", {
        userIDToWork: userIDToWork?.value,
        name,
        ip,
      });
    }

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-new-alias-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-new-alias-modal.alias-name" />
          </span>
          <CustomText value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-new-alias-modal.alias-ip" />
          </span>
          <CustomText value={ip} onChange={(e) => setIP(e.target.value)} />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAddAliasClicked}
        >
          <FormattedMessage id="general.save" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddNewAliasModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  userIDToWork: PropTypes.object,
};

export default AddNewAliasModal;
