import styles from "./servers.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import BasicTable from "../../../../../components/basic-table";
import Box from "../../../../../components/box";
import CustomMenu from "../../../../../components/custom-menu";
import CustomMenuItem from "../../../../../components/custom-menu/item";
import Spinner from "../../../../../components/spinner";
import { convertCurrency, currencySymbols } from "../../../../../utils/billing";
import {
  useAjax,
  useConfirm,
  useLang,
  useUser,
  useWLDVPS,
} from "../../../../../utils/hooks";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import IconButton from "../../../../../components/icon-button";
import CreateNewServerModal from "../../../../../components/modals/create-new-product";
import { getImageName } from "../../../../../utils/servers";
import {} from "../../../../../utils";

function ClientsListServers({ user }) {
  const ajax = useAjax();
  const intl = useIntl();
  const confirm = useConfirm();
  const currentUser = useUser();
  const lang = useLang();
  const wldvps = useWLDVPS();

  const [servers, setServers] = useState(null);
  const [statusToShow, setStatusToShow] = useState("Active");
  const [filter, setFilter] = useState("");
  const [filteredServers, setFilteredServers] = useState(null);
  const [sort, setSort] = useState("domain");
  const [isServerDropdownOpen, setIsServerDropdownOpen] = useState({});
  const [isStatusToShowDropdownOpen, setIsStatusToShowDropdownOpen] = useState(
    false
  );
  const [isCreateNewServerModalOpen, setIsCreateNewServerModalOpen] = useState(
    false
  );
  const [selectedServer, setSelectedServer] = useState(null);

  const [loading, setLoading] = useState(false);

  const getServers = useCallback(async () => {
    if (!user) {
      return;
    }

    const data = await ajax("/admin/getServers", {
      userID: user._id,
      withTracking: true,
      withHourlySoFarPrice: true,
    });

    setServers(data.servers);
  }, [ajax, user]);

  useEffect(() => {
    getServers();
  }, [getServers]);

  useEffect(() => {
    if (!servers) {
      return;
    }

    setFilteredServers(
      servers
        .filter(
          (server) =>
            (statusToShow === "All" || server.status === statusToShow) &&
            (!filter || server.hostname.includes(filter))
        )
        .sort((a, b) => {
          if (sort === "domain") {
            return a.hostname.localeCompare(b.hostname);
          } else if (sort === "domain-reverse") {
            return b.hostname.localeCompare(a.hostname);
          } else if (sort === "image") {
            return a.image.localeCompare(b.image);
          } else if (sort === "image-reverse") {
            return b.image.localeCompare(a.image);
          } else if (sort === "price") {
            return a.price - b.price;
          } else if (sort === "price-reverse") {
            return b.price - a.price;
          }
        })
    );
  }, [servers, statusToShow, filter, sort]);

  async function handleRemoveServerClicked(server) {
    const data = await ajax("/admin/validateServerBeforeDelete", {
      serverID: server._id,
    });

    const state = await confirm({
      title: intl.formatMessage({ id: "clients-list-servers.remove-title" }),
      message: intl.formatMessage({
        id: data.deleteAllowed
          ? "clients-list-servers.remove-content"
          : "clients-list-servers.remove-content-force",
      }),
      button1: {
        text: intl.formatMessage({ id: "general.close" }),
        color: "white",
      },
      button2: {
        text: intl.formatMessage({
          id: data.deleteAllowed
            ? "general.delete"
            : "clients-list-servers.force-button",
        }),
        color: data.deleteAllowed ? "white" : "red",
      },
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/admin/deleteServer", {
      force: !data.deleteAllowed,
      serverID: server._id,
    });

    await getServers();
  }

  function renderServerLifetime(server) {
    if (server.terminated_at) {
      return `${format(new Date(server.created_at), "d/M/y")}-${format(
        new Date(server.terminated_at),
        "d/M/y"
      )}`;
    }

    return `${intl.formatMessage({ id: "general.from" })} ${format(
      new Date(server.created_at),
      "d/M/y"
    )}`;
  }

  function handleServerDropdownToggle(server) {
    isServerDropdownOpen[server._id] = !isServerDropdownOpen[server._id];
    setIsServerDropdownOpen({ ...isServerDropdownOpen });
  }

  async function handleRemoveAllTerminatedClicked() {
    const state = await confirm({
      title: intl.formatMessage({
        id: "clients-list-servers.remove-all-terminated-title",
      }),
      message: intl.formatMessage({
        id: "clients-list-servers.remove-all-terminated-content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax("/admin/removeTerminatedServers", { userID: user._id });
    setLoading(false);

    await getServers();
  }

  function handleCreateNewServerModalOpen() {
    setSelectedServer(false);
    setIsCreateNewServerModalOpen(true);
  }

  function handleEditServerModalOpen(server) {
    setSelectedServer(server);
    setIsCreateNewServerModalOpen(true);
  }

  async function handleCreateNewServerModalClosed(state) {
    if (state) {
      await getServers();
    }

    setIsCreateNewServerModalOpen(false);
  }

  function handleSortByClicked(newSort) {
    if (sort === newSort) {
      if (newSort.endsWith("-reverse")) {
        newSort = newSort.replace("-reverse", "");
      } else {
        newSort = `${newSort}-reverse`;
      }
    }

    setSort(newSort);
  }

  function renderPrice(server) {
    const currency =
      currencySymbols[
        (wldvps ? server.whitelabel_payment : server.payment).currency
      ];

    const serverPayment = wldvps ? server.whitelabel_payment : server.payment;

    if (serverPayment.fixed_price > 0) {
      serverPayment.fixed_price;
    }

    if (typeof server.price === "object") {
      return `${currency}${server.price.hourlyPriceOn.toFixed(
        3
      )}/on ${currency}${server.price.hourlyPriceOff.toFixed(3)}/off`;
    }

    return `${currency}${(server.price + (server.addons_price || 0)).toFixed(
      3
    )}`;
  }

  const totalTerminated = useMemo(
    () =>
      servers?.reduce(
        (total, item) => total + (item.status === "Terminated" ? 1 : 0),
        0
      ),
    [servers]
  );

  const totalPrice = useMemo(() => {
    if (!filteredServers) {
      return null;
    }

    const totalPriceCalculated = filteredServers.reduce((total, item) => {
      const serverPayment = wldvps ? item.whitelabel_payment : item.payment;

      let price;
      if (serverPayment.fixed_price > 0) {
        price = serverPayment.fixed_price;
      } else {
        price = typeof item.price === "object" ? 0 : item.price;
      }

      price += item.addons_price || 0;

      return (
        total +
        convertCurrency(
          price,
          (wldvps ? item.whitelabel_payment : item.payment).currency,
          "ILS",
          currentUser.rates,
          {
            USD: currentUser.fixed_usd,
          }
        )
      );
    }, 0);

    return `${currencySymbols["ILS"]}${totalPriceCalculated.toFixed(2)}`;
  }, [currentUser.fixed_usd, currentUser.rates, filteredServers, wldvps]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <ButtonDropdown
            className={styles.ghost}
            isOpen={isStatusToShowDropdownOpen}
            toggle={() =>
              setIsStatusToShowDropdownOpen(!isStatusToShowDropdownOpen)
            }
          >
            <DropdownToggle caret>
              <FormattedMessage
                id="clients-list.server-status"
                values={{ status: statusToShow }}
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => setStatusToShow("All")}>
                <FormattedMessage id="general.all" />
              </DropdownItem>
              <DropdownItem onClick={() => setStatusToShow("Active")}>
                <FormattedMessage id="general.active" />
              </DropdownItem>
              <DropdownItem onClick={() => setStatusToShow("Terminated")}>
                <FormattedMessage id="general.terminated" />
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>

        <div>
          <input
            className={styles.filterInput}
            placeholder={intl.formatMessage({ id: "general.filter..." })}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </div>

      <Box>
        <BasicTable layout="auto">
          <thead>
            <tr>
              <th
                className={styles.pointer}
                onClick={() => handleSortByClicked("domain")}
              >
                <FormattedMessage id="clients-list-servers.domain" />
              </th>
              <th>
                <FormattedMessage id="clients-list-servers.status" />
              </th>
              <th
                className={styles.pointer}
                onClick={() => handleSortByClicked("image")}
              >
                <FormattedMessage id="clients-list-servers.image" />
              </th>
              {/* <th>
                    <FormattedMessage id="clients-list-servers.cpu" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list-servers.ram" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list-servers.disk" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list-servers.a-disk" />
                  </th> */}
              <th>
                <FormattedMessage id="clients-list-servers.backup" />
              </th>

              {!wldvps && (
                <>
                  <th>
                    <FormattedMessage id="clients-list-servers.node" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list-servers.vmid" />
                  </th>
                </>
              )}

              <th>
                <FormattedMessage id="clients-list-servers.lifetime" />
              </th>
              <th>
                <FormattedMessage id="clients-list-servers.payment-type" />
              </th>
              <th
                className={styles.pointer}
                onClick={() => handleSortByClicked("price")}
              >
                <FormattedMessage id="clients-list-servers.price" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!filteredServers && (
              <tr>
                <td colSpan={wldvps ? 8 : 10}>
                  <div className="spinner-wrapper">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}

            {filteredServers?.length === 0 && (
              <tr>
                <td colSpan={wldvps ? 8 : 10}>
                  <FormattedMessage id="general.no-rows" />
                </td>
              </tr>
            )}

            {filteredServers?.map((server, key) => (
              <tr key={key}>
                <td>
                  <a
                    href={`/${lang}/my-cloud/servers/${server._id}/overview`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {server.hostname}
                  </a>
                </td>
                <td>{server.status}</td>
                <td>{getImageName(server.image)}</td>
                {/* <td>{server.cpu}</td>
                      <td>{server.ram_mb}</td>
                      <td>{server.ssd_gb}</td>
                      <td>{server.additional_ssd_gb}</td> */}
                <td>
                  {intl.formatMessage({
                    id: server.backup ? "general.yes" : "general.no",
                  })}
                </td>

                {!wldvps && (
                  <>
                    <td>{server.node}</td>
                    <td>{server.vmid}</td>
                  </>
                )}

                <td>{renderServerLifetime(server)}</td>
                <td>{server.payment.payment_type}</td>
                <td>{renderPrice(server)}</td>
                <td>
                  <CustomMenu
                    isOpen={isServerDropdownOpen[server._id]}
                    toggle={() => handleServerDropdownToggle(server)}
                  >
                    <CustomMenuItem
                      onClick={() => handleEditServerModalOpen(server)}
                    >
                      <FormattedMessage id="general.edit" />
                    </CustomMenuItem>
                    <CustomMenuItem
                      onClick={() => handleRemoveServerClicked(server)}
                    >
                      <FormattedMessage id="general.remove" />
                    </CustomMenuItem>
                  </CustomMenu>
                </td>
              </tr>
            ))}
            {filteredServers?.length > 0 && (
              <tr>
                <td>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        {
                          id: "clients-list-servers.total-servers",
                        },
                        {
                          total: filteredServers.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></div>
                </td>
                <td colSpan={wldvps ? 5 : 7}></td>
                <td>{totalPrice}</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </BasicTable>

        {!wldvps && (
          <div className={styles.buttonsWrapper}>
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleCreateNewServerModalOpen}
            >
              <FormattedMessage id="general.add" />
            </IconButton>
            {/* {user && (
                <IconButton
                  disabled={loading}
                  color="light-purple"
                  href={`${getServerUrl()}/admin/export-servers?userid=${user._id}`}
                  target="_blank"
                >
                  <FormattedMessage id="clients-list-servers.export-servers" />
                </IconButton>
              )} */}
            {totalTerminated > 0 && (
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={handleRemoveAllTerminatedClicked}
              >
                <FormattedMessage
                  id="clients-list-servers.remove-all-terminated"
                  values={{
                    total: totalTerminated,
                  }}
                />
              </IconButton>
            )}
          </div>
        )}
      </Box>

      <CreateNewServerModal
        isOpen={isCreateNewServerModalOpen}
        onClose={handleCreateNewServerModalClosed}
        user={user}
        editServer={selectedServer}
      />
    </div>
  );
}

ClientsListServers.propTypes = {
  user: PropTypes.object,
};

export default ClientsListServers;
