import styles from "./login.module.scss";

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import validator from "validator";
import { Link } from "react-router-dom";
import { useAjax, useInitialDataFetching, useLang } from "../../utils/hooks";
import LoginLayout from "../../components/login-layout";
import TextField from "../../components/textfield";
import IconButton from "../../components/icon-button";
import SocialButton from "../../components/social-button";
import KeyDetector from "../../components/key-detector";

export default function Login() {
  const intl = useIntl();
  const router = useHistory();
  const { fingerprint } = useSelector((state) => ({
    fingerprint: state.settings.fingerprint,
  }));
  const lang = useLang();
  const ajax = useAjax();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const initialDataFetching = useInitialDataFetching();

  useEffect(() => {
    if (window.location.search === "?after_reset_password=1") {
      setMessage("login.check-mail");
    }

    if (window.location.search === "?after_set_password=1") {
      setMessage("login.password-set");
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const ls = urlSearchParams.get("ls");

    if (ls) {
      router.push(`/${lang || "en"}/my-cloud/servers`);
    }
  }, [lang, router]);

  async function handleLoginClicked() {
    const _email = email ? email.trim().toLowerCase() : false;
    const _password = password.trim();

    setError(false);

    if (!validator.isEmail(_email)) {
      return setError("general.wrong-email");
    } else if (!_password) {
      return setError("general.weak-password");
    }

    setLoading(true);

    const data = await ajax(`/users/login`, {
      email: _email,
      password,
      fingerprint,
    });

    if (data.result === "success") {
      if (data.message === "new-ip") {
        window.p1 = _email;
        window.p2 = password;

        return router.push(`/${lang}/email-verification`);
      }

      if (!data.userid) {
        window.p1 = _email;
        window.p2 = password;
        window.p3 = data.userIdToConnect;
        window.p4 = data.quickLoginToken;

        return router.push(`/${lang}/two-factor-authentication`);
      }

      if (await initialDataFetching(true)) {
        router.push(`/${lang || "en"}/my-cloud/servers`);
      }
    } else {
      if (data.message === "ip-not-authorized") {
        setError("general.ip-not-authorized");
      } else {
        setError("general.wrong-email-password");
      }

      setLoading(false);
    }
  }

  return (
    <LoginLayout>
      <div className={styles.login}>
        <div className={`mb8 ${styles.bold}`}>
          <FormattedMessage id="general.sign-in" />
        </div>
        <div className={`mb28 ${styles.normal}`}>
          <FormattedMessage id="login.subtitle" />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleLoginClicked}>
          <TextField
            icon="user"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.email-address" })}
          />
        </KeyDetector>
        <KeyDetector className="mb16" onKeyDetected={handleLoginClicked}>
          <TextField
            icon="lock"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.password" })}
          />
        </KeyDetector>
        <div className={`mb28 ${styles.forgotPassword}`}>
          <Link href="/[lang]/forgot-password" to={`/${lang}/forgot-password`}>
            <FormattedMessage id="login.forget-password" />
          </Link>
        </div>

        {error && (
          <div className={`mb28 ${styles.errorWrapper}`}>
            <FormattedMessage id={error} />
          </div>
        )}

        {message && (
          <div className={`mb28 ${styles.messageWrapper}`}>
            <FormattedMessage id={message} />
          </div>
        )}

        <div className="mb28">
          <IconButton
            disabled={loading}
            icon="arrow"
            onClick={handleLoginClicked}
            color="purple"
            className={styles.loginButton}
          >
            <FormattedMessage id="general.login" />
          </IconButton>
        </div>
        <div className={`mb28 ${styles.orWrapper}`}>
          <hr />
          <div className={`ml16 mr16 ${styles.text}`}>
            <FormattedMessage id="general.or" />
          </div>
          <hr />
        </div>
        <div className={`mb28 ${styles.socialWrapper}`}>
          <SocialButton
            loading={loading}
            setLoading={setLoading}
            type="facebook"
          />
          <SocialButton
            loading={loading}
            setLoading={setLoading}
            type="google"
          />
        </div>
        <div className={styles.noAccountWrapper}>
          <FormattedMessage id="login.no-account" />{" "}
          <Link href={`/[lang]/register`} to={`/${lang}/register`}>
            <FormattedMessage id="login.no-account-sign-up" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
