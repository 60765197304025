import styles from "./summary.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import validator from "validator";
import PasswordStrengthBar from "react-password-strength-bar";
import Box from "../../../../../components/box";
import CustomText from "../../../../../components/custom-text";
import CustomReactSelect from "../../../../../components/custom-react-select";
import {
  billingGateways,
  getSymbolsForReactSelect,
  paymentGateways,
} from "../../../../../utils/billing";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  useRoles,
  useUser,
  useWLDVPS,
} from "../../../../../utils/hooks";
import { getFullName, SUPER_ADMIN } from "../../../../../utils/user";
import {
  countries,
  getCountryByCode,
  countriesPhoneCodes,
} from "../../../../../utils/countries";
import IconButton from "../../../../../components/icon-button";
import { format } from "date-fns";
import Checkbox from "../../../../../components/checkbox";
import UpdateCreditCardQuickModal from "../../../../../components/modals/update-credit-card-quick";
import { getAllWLDVPSForSelect } from "../../../../../utils/wldvps";
import {} from "../../../../../utils";
import GeneratePasswordModal from "../../../../../components/modals/generate-password";
import { ReactComponent as HelpSvg } from "../../../../../components/svgs/help.svg";
import { Tooltip } from "reactstrap";

const currencies = getSymbolsForReactSelect();

const dayNumbers = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
].map((number, idx) => ({
  label: number,
  value: number,
  key: idx,
}));

const leadSources = [
  { label: "-", value: null, key: 0 },
  { label: "ליד - תמיר", value: "ליד - תמיר", key: 1 },
  { label: "ליד - דרים", value: "ליד - דרים", key: 2 },
];

const payWithOptions = [
  { label: "Credit card", value: "credit-card" },
  { label: "Masav", value: "masav" },
  { label: "Bank Transfer", value: "bank-transfer" },
  { label: "Payoneer", value: "payoneer" },
  { label: "Paypal", value: "paypal" },
  { label: "Free Of Charge", value: "free-of-charge" },
];

function ClientsListSummary({ user, reloadUser }) {
  const wldvps = useWLDVPS();
  const router = useHistory();
  const { id } = useParams();
  const intl = useIntl();
  const intlRef = useRef(intl);
  const ajax = useAjax();
  const alert = useAlert();
  const confirm = useConfirm();
  const lang = useLang();
  const { isAllowed } = useRoles();
  const currentUser = useUser();
  //const currentUserRef = useRef(currentUser);

  const registrationSteps = [
    {
      label: intl.formatMessage({
        id: "clients-list-summary.registration-step--1",
      }),
      value: -1,
      key: 0,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.registration-step-1",
      }),
      value: 1,
      key: 1,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.registration-step-2",
      }),
      value: 2,
      key: 2,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.registration-step-3",
      }),
      value: 3,
      key: 3,
    },
  ];
  const registrationStepsRef = useRef(registrationSteps);

  const statuses = [
    {
      label: intl.formatMessage({ id: "general.active" }),
      value: "USER_STATUS_ACTIVE",
      key: 0,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.suspended-scam",
      }),
      value: "USER_STATUS_SUSPENDED_SCAM",
      key: 1,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.suspended-spam",
      }),
      value: "USER_STATUS_SUSPENDED_SPAM",
      key: 1,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.suspended-attack",
      }),
      value: "USER_STATUS_SUSPENDED_ATTACK",
      key: 1,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.suspended-abuse",
      }),
      value: "USER_STATUS_SUSPENDED_ABUSE",
      key: 1,
    },
    {
      label: intl.formatMessage({
        id: "clients-list-summary.suspended-payment-issue",
      }),
      value: "USER_STATUS_SUSPENDED_PAYMENT_ISSUE",
      key: 1,
    },
  ];
  const statusesRef = useRef(statuses);

  const [whitelabels, setWhitelabels] = useState([]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxID, setTaxID] = useState("");
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [postcode, setPostcode] = useState("");
  const [phoneCode, setPhoneCode] = useState("972");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [totalSmsSent, setTotalSmsSent] = useState("0");
  const [serversLimit, setServersLimit] = useState("50");
  const [registerStep, setRegisterStep] = useState(false);
  const [accountManager, setAccountManager] = useState("");
  const [leadSource, setLeadSource] = useState(leadSources[0]);
  const [paymentGateway, setPaymentGateway] = useState(paymentGateways[0]);
  const [billingGateway, setBillingGateway] = useState(billingGateways[0]);
  const [status, setStatus] = useState(false);
  const [notes, setNotes] = useState("");
  const [whitelabel, setWhitelabel] = useState(null);
  const [allowedToGiveFreeDays, setAllowedToGiveFreeDays] = useState(false);
  const [parentUser, setParentUser] = useState(null);

  const [
    isGeneratePasswordModalOpen,
    setIsGeneratePasswordModalOpen,
  ] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);

  // from payment management
  const [currency, setCurrency] = useState(false);
  const [payWith, setPayWith] = useState(null);
  const [fixedUsd, setFixedUsd] = useState(0);
  const [payEvery, setPayEvery] = useState(false);
  const [payDay, setPayDay] = useState(false);
  const [discountHourlyOn, setDiscountHourlyOn] = useState("");
  const [discountHourlyOff, setDiscountHourlyOff] = useState("");
  const [discountMonthly, setDiscountMonthly] = useState("");
  const [
    discountHourlyManagedHosting,
    setDiscountHourlyManagedHosting,
  ] = useState("");
  const [
    discountMonthlyManagedHosting,
    setDiscountMonthlyManagedHosting,
  ] = useState("");
  const [discountMonthlyBandwidth, setDiscountMonthlyBandwidth] = useState("");
  const [hourlyLimit, setHourlyLimit] = useState("");
  const [generalTraffic, setGeneralTraffic] = useState("");
  const [freeHourlyBandwidth, setFreeHourlyBandwidth] = useState(false);
  const [freeBackups, setFreeBackups] = useState(false);
  const [bankCode, setBankCode] = useState("");
  const [branchNumber, setBranchNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [clientIdentificationNumber, setClientIdentificationNumber] = useState(
    ""
  );
  const [paypalEmail, setPaypalEmail] = useState("");
  const [
    changePaymentPlanImmediately,
    setChangePaymentPlanImmediately,
  ] = useState(false);
  const [skipImagesPriceMonthly, setSkipImagesPriceMonthly] = useState(false);
  const [skipImagesPriceHourlyOn, setSkipImagesPriceHourlyOn] = useState(false);
  const [skipImagesPriceHourlyOff, setSkipImagesPriceHourlyOff] = useState(
    false
  );
  const [
    includeHourlyOnDiscountWindows10,
    setIncludeHourlyOnDiscountWindows10,
  ] = useState(false);
  const [
    includeMonthlyDiscountWindows10,
    setIncludeMonthlyDiscountWindows10,
  ] = useState(false);
  const [
    isUpdateCreditCardQuickModalOpen,
    setIsUpdateCreditCardQuickModalOpen,
  ] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState({});

  const monthNumbers = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    24,
    36,
    48,
    60,
  ].map((number, idx) => ({
    label: `${number} ${intlRef.current.formatMessage({
      id: number === 1 ? "general.month" : "general.months",
    })}`,
    value: number,
    key: idx,
  }));

  const monthNumbersRef = useRef(monthNumbers);

  const getUserByEmail = useCallback(async () => {
    const data = await ajax("/admin/getUserByEmail", {
      email: id,
    });

    if (data.result === "success") {
      router.push(`/${lang}/clients/clients-list/${data.user._id}`);
    }
  }, [ajax, lang, router, id]);

  useEffect(() => {
    if (validator.isEmail(id)) {
      getUserByEmail();
    }
  }, [getUserByEmail, id]);

  const fetchAllUsers = useCallback(async () => {
    const data = await ajax(`/users/getAllUsers`, {
      filter: { email: 1 },
    });

    setAllUsers([
      {
        label: intlRef.current.formatMessage({ id: "general.none" }),
        value: null,
        key: -1,
      },
      ...data.users.map((user, idx) => ({
        label: user.email,
        value: user._id,
        key: idx,
      })),
    ]);
  }, [ajax]);

  useEffect(() => {
    if (!user) {
      return;
    }

    setEmail(user.email || "");
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setCompanyName(user.companyName || "");
    setTaxID(user.tax_id || "");
    setCountry(
      user.country
        ? { label: getCountryByCode(user.country), value: user.country }
        : null
    );
    setCity(user.city || "");
    setAddress(user.address || "");
    setState(user.state || "");
    setApartmentNumber(user.apartmentNumber || "");
    setPostcode(user.postcode || "");
    setPhoneCode(user.phoneCode || "972");
    setPhoneNumber(user.phoneNumber || "");
    setTotalSmsSent(user.totalSmsSent ? user.totalSmsSent.toString() : "0");
    setServersLimit(user.serversLimit ? user.serversLimit.toString() : "0");
    setRegisterStep(
      registrationStepsRef.current.find(
        (step) => step.value === user.registerStep
      )
    );
    setAccountManager(user.account_manager || "");
    setLeadSource(
      leadSources.find((leadSource) => user.lead_source === leadSource.value)
    );

    let paymentGateway = paymentGateways.find(
      (gateway) => gateway.value === user.payment_gateway
    );
    if (!paymentGateway) {
      paymentGateway = paymentGateways[0];
    }
    setPaymentGateway(paymentGateway);

    let billingGateway = billingGateways.find(
      (gateway) => gateway.value === user.billing_gateway
    );
    if (!billingGateway) {
      billingGateway = billingGateways[0];
    }
    setBillingGateway(billingGateway);

    setStatus(
      statusesRef.current.find((status) => status.value === user.status)
    );
    setNotes(user.notes || "");
    setAllowedToGiveFreeDays(user.allowed_to_give_free_days || false);

    setCurrency(
      user.currency
        ? currencies.find((c) => c && c.value === user.currency)
        : currencies[0]
    );

    setPayWith(
      user.pay_with
        ? payWithOptions.find((item) => item.value === user.pay_with)
        : payWithOptions[0]
    );

    setFixedUsd(user?.fixed_usd?.toString() || "0");

    setPayEvery(
      monthNumbersRef.current.find(
        (monthNumber) => user.payEvery === monthNumber.value
      )
    );
    setPayDay(dayNumbers.find((dayNumber) => user.payDay === dayNumber.value));
    setDiscountHourlyOn(user?.discount_hourly_on?.toString() || "0");
    setDiscountHourlyOff(user?.discount_hourly_off?.toString() || "0");
    setDiscountMonthly(user?.discount_monthly?.toString() || "0");
    setDiscountHourlyManagedHosting(
      user?.discount_managed_hosting_hourly?.toString() || "0"
    );
    setDiscountMonthlyManagedHosting(
      user?.discount_managed_hosting_monthly.toString() || "0"
    );
    setDiscountMonthlyBandwidth(
      user?.discount_monthly_bandwidth?.toString() || "0"
    );
    setHourlyLimit(user?.hourly_limit.toString() || "100");
    setGeneralTraffic(user?.general_traffic.toString() || "0");
    setFreeHourlyBandwidth(user.free_hourly_bandwidth || false);
    setFreeBackups(user.free_backups || false);

    setBankCode((user.masav && user.masav.bankCode) || "");
    setBranchNumber((user.masav && user.masav.branchNumber) || "");
    setAccountNumber((user.masav && user.masav.accountNumber) || "");
    setClientIdentificationNumber(
      (user.masav && user.masav.clientIdentificationNumber) || ""
    );

    setPaypalEmail((user.paypal && user.paypal.email) || "");

    setChangePaymentPlanImmediately(
      user.change_payment_plan_immediately || false
    );
    setSkipImagesPriceMonthly(user.skip_images_price_monthly || false);
    setSkipImagesPriceHourlyOn(user.skip_images_price_hourly_on || false);
    setSkipImagesPriceHourlyOff(user.skip_images_price_hourly_off || false);
    setIncludeHourlyOnDiscountWindows10(
      user.include_hourly_on_discount_windows10 || false
    );
    setIncludeMonthlyDiscountWindows10(
      user.include_monthly_discount_windows10 || false
    );
  }, [user]);

  useEffect(() => {
    if (user && currentUser.whitelabelSettings) {
      const whitelabels = getAllWLDVPSForSelect(currentUser.whitelabelSettings);

      setWhitelabels(whitelabels);

      setWhitelabel(
        user.whitelabel
          ? whitelabels.find((ws) => ws && ws.value === user.whitelabel)
          : whitelabels[0]
      );
    }
  }, [currentUser, user]);

  useEffect(() => {
    if (country) {
      setPhoneCode(countriesPhoneCodes[country.value]);
    }
  }, [country]);

  useEffect(() => {
    if (currentUser.role === SUPER_ADMIN) {
      fetchAllUsers();
    }
  }, [currentUser.role, fetchAllUsers]);

  useEffect(() => {
    if (user && allUsers) {
      if (user.created_via_userid) {
        const parentUser = allUsers.find(
          (u) => u.value === user.created_via_userid
        );

        setParentUser(parentUser || allUsers[0]);
      } else {
        setParentUser(allUsers[0]);
      }
    }
  }, [allUsers, user]);

  async function handleUpdateClicked() {
    const _email = email.trim().toLowerCase();
    const _password = password.trim();

    if (!validator.isEmail(_email)) {
      return setError(intl.formatMessage({ id: "general.wrong-email" }));
    } else if (_password && passwordScore <= 1) {
      return setError(intl.formatMessage({ id: "general.weak-password" }));
    }

    const axiosData = {
      userID: user._id,
      email: _email,
      password: _password,
      firstName,
      lastName,
      companyName,
      taxID,
      country: country.value,
      city,
      address,
      state,
      apartmentNumber,
      postcode,
      phoneCode,
      phoneNumber,
      totalSmsSent,
      serversLimit,
      registerStep: registerStep.value,
      accountManager,
      leadSource: leadSource.value,
      paymentGateway: paymentGateway.value,
      billingGateway: billingGateway.value,
      status: status.value,
      notes,

      // payment management
      currency: currency.value,
      fixedUsd,
      payWith: payWith.value,
      payEvery: payEvery.value,
      payDay: payDay.value,
      discountHourlyOn,
      discountHourlyOff,
      discountHourlyManagedHosting,
      discountMonthlyManagedHosting,
      discountMonthly,
      discountMonthlyBandwidth,
      hourlyLimit,
      generalTraffic,
      freeHourlyBandwidth,
      freeBackups,
      changePaymentPlanImmediately,
      skipImagesPriceMonthly,
      skipImagesPriceHourlyOn,
      skipImagesPriceHourlyOff,
      includeHourlyOnDiscountWindows10,
      includeMonthlyDiscountWindows10,
    };

    if (payWith?.value === "masav") {
      axiosData.masav = {
        bankCode,
        branchNumber,
        accountNumber,
        clientIdentificationNumber,
      };
    }

    if (payWith?.value === "bank-transfer") {
      axiosData.masav = {
        bankCode,
        branchNumber,
        accountNumber,
      };
    }

    if (payWith?.value === "paypal") {
      axiosData.paypal = {
        email: paypalEmail,
      };
    }

    setLoading(true);
    const data = await ajax(`/admin/editUser`, axiosData);

    let additionalMessages = "";

    if (isAllowed("super-admin.clients.advance-changes")) {
      await ajax(`/admin/editUserAdvance`, {
        userID: user._id,
        whitelabel: whitelabel.value,
        allowedToGiveFreeDays,
        created_via_userid: parentUser.value,
      });
    }

    if (data.greenInvoice?.result === "error") {
      additionalMessages += `<br /><br /><b>GreenInvoice warning:</b> ${
        data.greenInvoice.errorCode === 1010
          ? "לקוח כבר קיים - יש לרשום שם אחר"
          : data.greenInvoice.errorMessage
      }`;
    }

    if (data.payplus?.result === "error") {
      const payplusData = JSON.parse(data.payplus.data);

      additionalMessages += `<br /><br /><b>Payplus warning:</b> ${payplusData.results.description}`;
    }

    reloadUser.do();
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "clients-list-summary.edit.title" }),
      `${intl.formatMessage({
        id: "clients-list-summary.edit.content",
      })}${additionalMessages}`
    );
  }

  async function handleDeleteUserClicked() {
    const state = await confirm({
      title: intl.formatMessage({
        id: "clients-list-summary.delete-user-modal-title",
      }),
      message: intl.formatMessage(
        {
          id: "clients-list-summary.delete-user-modal-content",
        },
        { name: getFullName(user) }
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    const data = await ajax(`/admin/deleteUser`, {
      userID: user._id,
    });
    setLoading(false);

    if (data.result === "success") {
      router.push(`/${lang}/clients/clients-list`);
    } else {
      await alert(
        intl.formatMessage({ id: "clients-list-summary.delete-user-failed" }),
        intl.formatMessage({
          id: "clients-list-summary.delete-user-failed-reason",
        })
      );
    }
  }

  async function handleUpdateCreditCardQuickModalOpen() {
    if (!user.firstName || !user.lastName) {
      return await alert(
        intl.formatMessage({
          id: "clients-list-summary.update-credit-card.title",
        }),
        intl.formatMessage({
          id: "clients-list-summary.update-credit-card.error",
        })
      );
    }

    setIsUpdateCreditCardQuickModalOpen(true);
  }

  function handleUpdateCreditCardQuickModalClosed(state) {
    if (state) {
      reloadUser.do();
    }

    setIsUpdateCreditCardQuickModalOpen(false);
  }

  async function handleRemoveCreditCardClicked() {
    const state = await confirm({
      title: intl.formatMessage({
        id: "clients-list-summary.remove-card-title",
      }),
      message: intl.formatMessage(
        {
          id: "clients-list-summary.remove-card-content",
        },
        { name: getFullName(user) }
      ),
    });

    if (state !== "button2") {
      return;
    }

    const data = await ajax(`/credit-card/removeCreditCardFromUser`, {
      userID: user._id,
    });

    if (data.result === "success") {
      reloadUser.do();
    } else {
      alert(
        intl.formatMessage({ id: "clients-list-summary.remove-card-title" }),
        intl.formatMessage({ id: "clients-list-summary.remove-card-success" })
      );
    }
  }

  async function handleDisable2FAclicked() {
    setLoading(true);

    await ajax("/users/disable2FA", { userID: user._id });

    user.twoFA.activated = false;

    setLoading(false);
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  function handleTooltipToggle(id) {
    isTooltipOpen[id] = !isTooltipOpen[id];
    setIsTooltipOpen({ ...isTooltipOpen });
  }

  function renderPayWithForm() {
    if (!payWith) {
      return null;
    }

    if (payWith.value === "credit-card") {
      const primaryCard = user.creditCards.find((cc) => cc.primary);

      return (
        <div className={styles.creditCardWrapper}>
          {primaryCard && (
            <div className={styles.texts}>
              <div>
                <span>
                  <FormattedMessage
                    id="clients-list-summary.current-primary"
                    values={{ digits: primaryCard.last4Digits }}
                  />
                </span>
              </div>
              <div>
                <span>
                  <FormattedMessage
                    id="clients-list-summary.current-primary-expiration-date"
                    values={{
                      monthYear: `${primaryCard.cardMonth}/${primaryCard.cardYear}`,
                    }}
                  />
                </span>
              </div>
            </div>
          )}
          <div className={styles.buttonsWrapper}>
            <IconButton
              color="light-purple"
              onClick={handleUpdateCreditCardQuickModalOpen}
            >
              <FormattedMessage
                id={primaryCard ? "general.update" : "general.add"}
              />
            </IconButton>
            {primaryCard && (
              <IconButton
                color="light-purple"
                onClick={handleRemoveCreditCardClicked}
              >
                <FormattedMessage id="general.remove" />
              </IconButton>
            )}
          </div>
        </div>
      );
    }

    if (payWith.value === "masav" || payWith.value === "bank-transfer") {
      return (
        <>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.bank-code" />
            </span>
            <CustomText
              value={bankCode}
              onChange={(e) => setBankCode(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.branch-number" />
            </span>
            <CustomText
              value={branchNumber}
              onChange={(e) => setBranchNumber(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.account-number" />
            </span>
            <CustomText
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          {payWith.value === "masav" && (
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.client-identification-number" />
              </span>
              <CustomText
                value={clientIdentificationNumber}
                onChange={(e) => setClientIdentificationNumber(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          )}
        </>
      );
    }

    if (payWith.value === "paypal") {
      return (
        <>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.paypal-email" />
            </span>
            <CustomText
              value={paypalEmail}
              onChange={(e) => setPaypalEmail(e.target.value)}
              autoComplete="new-password"
            />
          </div>
        </>
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.boxes}>
        <Box>
          {user && (
            <div className={styles.datesWrapper}>
              <div>
                <FormattedMessage
                  tagName="b"
                  id="clients-list-summary.registration-date"
                />
                : {format(new Date(user.created_at), "d/M/y")}
              </div>
              <div>
                <FormattedMessage
                  tagName="b"
                  id="clients-list-summary.last-login"
                />
                : {format(new Date(user.last_login), "d/M/y HH:mm")}
              </div>
            </div>
          )}

          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.email" />
            </span>
            <CustomText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.password" />
            </span>
            <CustomText
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={intl.formatMessage({
                id: "general.leave-blank",
              })}
              autoComplete="new-password"
            />
            <IconButton
              className={styles.button}
              color="white"
              onClick={() => setIsGeneratePasswordModalOpen(true)}
            >
              <FormattedMessage id="general.generate" />
            </IconButton>
          </div>
          {password && (
            <div className={styles.row}>
              <span></span>
              <PasswordStrengthBar
                className={styles.passwordStrengthBarWrapper}
                password={password}
                onChangeScore={setPasswordScore}
              />
            </div>
          )}
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.first-name" />
            </span>
            <CustomText
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.last-name" />
            </span>
            <CustomText
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.company-name" />
            </span>
            <CustomText
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.tax-id" />
            </span>
            <CustomText
              value={taxID}
              onChange={(e) => setTaxID(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.country" />
            </span>
            <CustomReactSelect
              instanceId="clients-list-summary-country"
              options={Object.keys(countries).map((key) => ({
                label: key,
                value: countries[key],
              }))}
              value={country}
              onChange={(item) => setCountry(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.city" />
            </span>
            <CustomText
              value={city}
              onChange={(e) => setCity(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.address" />
            </span>
            <CustomText
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.state" />
            </span>
            <CustomText
              value={state}
              onChange={(e) => setState(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.apartment-number" />
            </span>
            <CustomText
              value={apartmentNumber}
              onChange={(e) => setApartmentNumber(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.post-code" />
            </span>
            <CustomText
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.phone-number" />
            </span>
            <div className={styles.phoneWrapper}>
              <CustomText
                className={styles.phoneCode}
                value={phoneCode}
                onChange={(e) => setPhoneCode(e.target.value)}
                autoComplete="new-password"
              />
              <CustomText
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.total-sms-sent" />
            </span>
            <CustomText
              value={totalSmsSent}
              onChange={(e) => setTotalSmsSent(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.servers-limit" />
            </span>
            <div className={styles.serversLimitWrapper}>
              <CustomText
                value={serversLimit}
                onChange={(e) => setServersLimit(e.target.value)}
                autoComplete="new-password"
              />
              <span>
                <FormattedMessage
                  id="clients-list-summary.active-servers"
                  values={{ number: user?.totalActiveServers }}
                />
              </span>
            </div>
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.registration-step" />
            </span>
            <CustomReactSelect
              options={registrationSteps}
              value={registerStep}
              onChange={(item) => setRegisterStep(item)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.account-manager" />
            </span>
            <CustomText
              value={accountManager}
              onChange={(e) => setAccountManager(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          {!wldvps && (
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.lead-source" />
              </span>
              <CustomReactSelect
                options={leadSources}
                value={leadSource}
                onChange={(item) => setLeadSource(item)}
              />
            </div>
          )}
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.status" />
            </span>
            <CustomReactSelect
              options={statuses}
              value={status}
              onChange={(item) => setStatus(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.notes" />
            </span>
            <CustomText
              type="textarea"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="clients-list-summary.2fa-status" />
            </span>
            {intl.formatMessage({
              id: user?.twoFA.activated
                ? "general.activated"
                : "general.disabled",
            })}

            {user?.twoFA.activated && (
              <IconButton
                color="light-purple"
                className={styles.disable2FA}
                onClick={handleDisable2FAclicked}
                disabled={loading}
              >
                <FormattedMessage id="general.disable" />
              </IconButton>
            )}
          </div>
        </Box>
        <div>
          <Box style={{ marginBottom: "10px" }}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.pay-with" />
              </span>
              <CustomReactSelect
                options={payWithOptions}
                value={payWith}
                onChange={(item) => setPayWith(item)}
              />
            </div>
            {renderPayWithForm()}
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.fixed-usd" />
              </span>
              <CustomText
                value={fixedUsd}
                onChange={(e) => setFixedUsd(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.payment-gateway" />
              </span>
              <CustomReactSelect
                options={paymentGateways}
                value={paymentGateway}
                onChange={(item) => setPaymentGateway(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.billing-gateway" />
              </span>
              <CustomReactSelect
                options={billingGateways}
                value={billingGateway}
                onChange={(item) => setBillingGateway(item)}
              />
            </div>
          </Box>
          <Box style={{ marginBottom: "10px" }}>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.user-currency" />
              </span>
              <CustomReactSelect
                options={currencies}
                value={currency}
                onChange={(item) => setCurrency(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.pay-every" />
              </span>
              <CustomReactSelect
                options={monthNumbers}
                value={payEvery}
                onChange={(item) => setPayEvery(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.pay-day" />
              </span>
              <CustomReactSelect
                options={dayNumbers}
                value={payDay}
                onChange={(item) => setPayDay(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.discount-hourly-on" />
                <HelpSvg id="discount-hourly-on" className={styles.helpSvg} />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["discount-hourly-on"]}
                  target="discount-hourly-on"
                  toggle={() => handleTooltipToggle("discount-hourly-on")}
                >
                  הנחת משאבים לשרתים שעתיים דלוקים
                </Tooltip>
              </span>
              <CustomText
                value={discountHourlyOn}
                onChange={(e) => setDiscountHourlyOn(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.discount-hourly-off" />
                <HelpSvg id="discount-hourly-off" className={styles.helpSvg} />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["discount-hourly-off"]}
                  target="discount-hourly-off"
                  toggle={() => handleTooltipToggle("discount-hourly-off")}
                >
                  הנחת משאבים לשרתים שעתיים כבויים
                </Tooltip>
              </span>
              <CustomText
                value={discountHourlyOff}
                onChange={(e) => setDiscountHourlyOff(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.discount-monthly" />
                <HelpSvg id="discount-monthly" className={styles.helpSvg} />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["discount-monthly"]}
                  target="discount-monthly"
                  toggle={() => handleTooltipToggle("discount-monthly")}
                >
                  הנחת משאבים לשרתים חודשיים. הנחה זו תקפה גם על כתובות אייפי
                  נוספות
                </Tooltip>
              </span>
              <CustomText
                value={discountMonthly}
                onChange={(e) => setDiscountMonthly(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.discount-hourly-managed-hosting" />
                <HelpSvg
                  id="discount-hourly-managed-hosting"
                  className={styles.helpSvg}
                />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["discount-hourly-managed-hosting"]}
                  target="discount-hourly-managed-hosting"
                  toggle={() =>
                    handleTooltipToggle("discount-hourly-managed-hosting")
                  }
                >
                  הנחה על שירותי ניהול לשרתים שעתיים
                </Tooltip>
              </span>
              <CustomText
                value={discountHourlyManagedHosting}
                onChange={(e) =>
                  setDiscountHourlyManagedHosting(e.target.value)
                }
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.discount-monthly-managed-hosting" />
                <HelpSvg
                  id="discount-monthly-managed-hosting"
                  className={styles.helpSvg}
                />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["discount-monthly-managed-hosting"]}
                  target="discount-monthly-managed-hosting"
                  toggle={() =>
                    handleTooltipToggle("discount-monthly-managed-hosting")
                  }
                >
                  הנחה על שירותי ניהול לשרתים חודשיים
                </Tooltip>
              </span>
              <CustomText
                value={discountMonthlyManagedHosting}
                onChange={(e) =>
                  setDiscountMonthlyManagedHosting(e.target.value)
                }
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.discount-monthly-traffic" />
                <HelpSvg
                  id="discount-monthly-traffic"
                  className={styles.helpSvg}
                />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["discount-monthly-traffic"]}
                  target="discount-monthly-traffic"
                  toggle={() => handleTooltipToggle("discount-monthly-traffic")}
                >
                  הנחה על תעבורה לשרתים חודשיים
                </Tooltip>
              </span>
              <CustomText
                value={discountMonthlyBandwidth}
                onChange={(e) => setDiscountMonthlyBandwidth(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.hourly-limit" />
                <HelpSvg id="hourly-limit" className={styles.helpSvg} />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["hourly-limit"]}
                  target="hourly-limit"
                  toggle={() => handleTooltipToggle("hourly-limit")}
                >
                  לא פעיל כרגע - זה צריך להפיק חשבונית שעתית ללקוח שעובר את
                  הסכום המצויין בשדה
                </Tooltip>
              </span>
              <CustomText
                value={hourlyLimit}
                onChange={(e) => setHourlyLimit(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="clients-list-summary.general-traffic" />
                <HelpSvg id="general-traffic" className={styles.helpSvg} />
                <Tooltip
                  placement="bottom"
                  isOpen={isTooltipOpen["general-traffic"]}
                  target="general-traffic"
                  toggle={() => handleTooltipToggle("general-traffic")}
                >
                  תעבורה כללית לשרתים חודשיים - המשתמש יחוייב בתעבורה חודשית רק
                  אם היא גדולה מהערך שרשום בשדה. רלוונטי רק אם המספר גדול מ0
                </Tooltip>
              </span>
              <CustomText
                value={generalTraffic}
                onChange={(e) => setGeneralTraffic(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.free-hourly-traffic"
                checked={freeHourlyBandwidth}
                onChange={(e) => setFreeHourlyBandwidth(e.target.checked)}
              />
              <HelpSvg
                id="tooltip-free-hourly-traffic"
                className={styles.helpSvg}
              />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["tooltip-free-hourly-traffic"]}
                target="tooltip-free-hourly-traffic"
                toggle={() =>
                  handleTooltipToggle("tooltip-free-hourly-traffic")
                }
              >
                התעבורה של שרתים שעתיים חינמית לחלוטין
              </Tooltip>
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.free-backups"
                checked={freeBackups}
                onChange={(e) => setFreeBackups(e.target.checked)}
              />
              <HelpSvg id="tooltip-free-backups" className={styles.helpSvg} />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["tooltip-free-backups"]}
                target="tooltip-free-backups"
                toggle={() => handleTooltipToggle("tooltip-free-backups")}
              >
                הגיבויים חינמים לחלוטין לשרתים שעתיים וחודשיים
              </Tooltip>
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.change-payment-plan-immediately"
                checked={changePaymentPlanImmediately}
                onChange={(e) =>
                  setChangePaymentPlanImmediately(e.target.checked)
                }
              />
              <HelpSvg
                id="change-payment-plan-immediately"
                className={styles.helpSvg}
              />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["change-payment-plan-immediately"]}
                target="change-payment-plan-immediately"
                toggle={() =>
                  handleTooltipToggle("change-payment-plan-immediately")
                }
              >
                אפשרות זאת תגרום ללקוח לשנות את השרתים משעתי לחודשי וההפך ללא
                צורך בהמתנה לסוף החודש
              </Tooltip>
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.skip-images-price-monthly"
                checked={skipImagesPriceMonthly}
                onChange={(e) => setSkipImagesPriceMonthly(e.target.checked)}
              />
              <HelpSvg
                id="skip-images-price-monthly"
                className={styles.helpSvg}
              />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["skip-images-price-monthly"]}
                target="skip-images-price-monthly"
                toggle={() => handleTooltipToggle("skip-images-price-monthly")}
              >
                רישוי וינדוס 10 חינמי לחלוטין בשרתים חודשיים
              </Tooltip>
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.skip-images-price-hourly-on"
                checked={skipImagesPriceHourlyOn}
                onChange={(e) => setSkipImagesPriceHourlyOn(e.target.checked)}
              />
              <HelpSvg
                id="skip-images-price-hourly-on"
                className={styles.helpSvg}
              />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["skip-images-price-hourly-on"]}
                target="skip-images-price-hourly-on"
                toggle={() =>
                  handleTooltipToggle("skip-images-price-hourly-on")
                }
              >
                רישוי וינדוס 10 חינמי לחלוטין בשרתים שעתיים דלוקים
              </Tooltip>
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.skip-images-price-hourly-off"
                checked={skipImagesPriceHourlyOff}
                onChange={(e) => setSkipImagesPriceHourlyOff(e.target.checked)}
              />
              <HelpSvg
                id="skip-images-price-hourly-off"
                className={styles.helpSvg}
              />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["skip-images-price-hourly-off"]}
                target="skip-images-price-hourly-off"
                toggle={() =>
                  handleTooltipToggle("skip-images-price-hourly-off")
                }
              >
                רישוי וינדוס 10 חינמי לחלוטין בשרתים שעתיים כבויים
              </Tooltip>
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.include-hourly-on-discount-windows-10"
                checked={includeHourlyOnDiscountWindows10}
                onChange={(e) =>
                  setIncludeHourlyOnDiscountWindows10(e.target.checked)
                }
              />
              <HelpSvg
                id="include-hourly-on-discount-windows-10"
                className={styles.helpSvg}
              />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["include-hourly-on-discount-windows-10"]}
                target="include-hourly-on-discount-windows-10"
                toggle={() =>
                  handleTooltipToggle("include-hourly-on-discount-windows-10")
                }
              >
                האם להחיל את השדה של הנחת משאבים לשרתים שעתיים דלוקים גם על
                רישיונות וינדוס 10?
              </Tooltip>
            </div>
            <div className={styles.row}>
              <Checkbox
                label="clients-list-summary.include-monthly-discount-windows-10"
                checked={includeMonthlyDiscountWindows10}
                onChange={(e) =>
                  setIncludeMonthlyDiscountWindows10(e.target.checked)
                }
              />
              <HelpSvg
                id="include-monthly-discount-windows-10"
                className={styles.helpSvg}
              />
              <Tooltip
                placement="bottom"
                isOpen={isTooltipOpen["include-monthly-discount-windows-10"]}
                target="include-monthly-discount-windows-10"
                toggle={() =>
                  handleTooltipToggle("include-monthly-discount-windows-10")
                }
              >
                האם להחיל את השדה של הנחת משאבים לשרתים חודשיים גם על רישיונות
                וינדוס 10?
              </Tooltip>
            </div>
          </Box>
          {isAllowed("super-admin.clients.advance-changes") && (
            <Box>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="clients-list-summary.whitelabel" />
                </span>
                <CustomReactSelect
                  options={whitelabels}
                  value={whitelabel}
                  onChange={(item) => setWhitelabel(item)}
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="clients-list-summary.parent-user" />
                </span>
                <CustomReactSelect
                  options={allUsers}
                  value={parentUser}
                  onChange={(item) => setParentUser(item)}
                />
              </div>
              <div className={styles.row}>
                <Checkbox
                  label="clients-list-summary.allowed-free-days"
                  checked={allowedToGiveFreeDays}
                  onChange={(e) => setAllowedToGiveFreeDays(e.target.checked)}
                />
              </div>
            </Box>
          )}
        </div>
      </div>

      {billingGateway?.value === "green-invoice" && (!firstName || !lastName) && (
        <Box className={styles.buttonsBox}>
          <FormattedMessage id="clients-list-summary.greeninvoice-warning" />
        </Box>
      )}

      {password && (
        <Box className={styles.buttonsBox}>
          <FormattedMessage id="clients-list-summary.password-warning" />
        </Box>
      )}

      <Box className={styles.buttonsBox}>
        <div>
          {error && <div className={`error ${styles.error}`}>{error}</div>}

          <div className={styles.buttonsWrapper}>
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleUpdateClicked}
            >
              <FormattedMessage id="general.update" />
            </IconButton>
            {currentUser?._id !== user?._id && (
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={handleDeleteUserClicked}
              >
                <FormattedMessage id="general.delete" />
              </IconButton>
            )}
          </div>
        </div>
      </Box>

      <UpdateCreditCardQuickModal
        isOpen={isUpdateCreditCardQuickModalOpen}
        onClose={handleUpdateCreditCardQuickModalClosed}
        userIDToSet={user}
      />

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
    </div>
  );
}

ClientsListSummary.propTypes = {
  user: PropTypes.object,
  reloadUser: PropTypes.object,
};

export default React.memo(ClientsListSummary);
