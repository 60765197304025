import React from "react";
import { Switch, Route } from "react-router-dom";
import { useUser } from "../../../../../../utils/hooks";
import { USER } from "../../../../../../utils/user";
import ServerAddonsAdmin from "./addons/addons-admin";
import ServerAddonsClient from "./addons/addons-client";
import ServerSettingsBootOrder from "./boot-order";
import ServerSettingsChangeOS from "./change-os";
import ServerSettingsChangePassword from "./change-password";
import ServerSettingsChangePayment from "./change-payment";
import ServerSettingsChangePlan from "./change-plan";
import ServerSettingsCustomISO from "./custom-iso";
import ServerSettingsIPv4 from "./ipv4";

export default function MyCloudServerSettingsRouting() {
  const user = useUser();

  return (
    <Switch>
      <Route
        exact
        path={[
          "/:lang/my-cloud/servers/:id/server-settings",
          "/:lang/my-cloud/servers/:id/server-settings/ipv4",
        ]}
      >
        <ServerSettingsIPv4 />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/server-settings/addons">
        {user.role === USER && <ServerAddonsClient />}
        {user.role !== USER && <ServerAddonsAdmin />}
      </Route>
      <Route
        exact
        path="/:lang/my-cloud/servers/:id/server-settings/boot-order"
      >
        <ServerSettingsBootOrder />
      </Route>
      <Route
        exact
        path="/:lang/my-cloud/servers/:id/server-settings/change-plan"
      >
        <ServerSettingsChangePlan />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/server-settings/change-os">
        <ServerSettingsChangeOS />
      </Route>
      <Route
        exact
        path="/:lang/my-cloud/servers/:id/server-settings/custom-iso"
      >
        <ServerSettingsCustomISO />
      </Route>
      <Route
        exact
        path="/:lang/my-cloud/servers/:id/server-settings/change-payment"
      >
        <ServerSettingsChangePayment />
      </Route>
      <Route
        exact
        path="/:lang/my-cloud/servers/:id/server-settings/change-password"
      >
        <ServerSettingsChangePassword />
      </Route>
    </Switch>
  );
}
