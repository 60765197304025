import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import validator from "validator";
import PasswordStrengthBar from "react-password-strength-bar";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import GeneratePasswordModal from "../generate-password";

function AddNewClientModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const intl = useIntl();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [
    isGeneratePasswordModalOpen,
    setIsGeneratePasswordModalOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setEmail("");
      setPassword("");
    }
  }, [isOpen]);

  function handleGeneratePasswordClicked() {
    setIsGeneratePasswordModalOpen(true);
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  async function handleAddUserClicked() {
    const _email = email ? email.trim().toLowerCase() : "";
    const _password = password ? password.trim() : "";

    if (!validator.isEmail(_email)) {
      return setError(intl.formatMessage({ id: "general.wrong-email" }));
    } else if (passwordScore <= 1) {
      return setError(
        intl.formatMessage({ id: "server-hostname-label.weak-password" })
      );
    }

    setLoading(true);
    const data = await ajax("/admin/createUser", {
      email: _email,
      password: _password,
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(data.user);
    } else {
      setError(
        intl.formatMessage({ id: `add-new-client-modal.${data.message}` })
      );
    }
  }

  return (
    <>
      <Modal
        className={styles.wrapper}
        isOpen={isOpen}
        toggle={() => onClose(false)}
      >
        <ModalHeader toggle={() => onClose(false)}>
          <FormattedMessage id="add-new-client-modal.title" />
        </ModalHeader>
        <ModalBody>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="add-new-client-modal.email" />
            </span>
            <CustomText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="add-new-client-modal.password" />
            </span>
            <CustomText
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
          </div>

          <div>
            <PasswordStrengthBar
              password={password}
              onChangeScore={setPasswordScore}
            />
          </div>

          {error && <div className="error">{error}</div>}
        </ModalBody>
        <ModalFooter>
          <IconButton
            disabled={loading}
            color="purple"
            onClick={handleGeneratePasswordClicked}
          >
            <FormattedMessage id="general.generate-password" />
          </IconButton>
          <IconButton
            disabled={loading}
            color="purple"
            onClick={handleAddUserClicked}
          >
            <FormattedMessage id="general.add" />
          </IconButton>
          <IconButton
            disabled={loading}
            color="text"
            onClick={() => onClose(false)}
          >
            <FormattedMessage id="general.cancel" />
          </IconButton>
        </ModalFooter>
      </Modal>

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
    </>
  );
}

AddNewClientModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddNewClientModal;
