import styles from "./snapshots.module.scss";

import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as SnapSvg } from "../../../../../components/svgs/snap.svg";
import Box from "../../../../../components/box";
import CustomMenu from "../../../../../components/custom-menu";
import BasicTable from "../../../../../components/basic-table";
import CustomMenuItem from "../../../../../components/custom-menu/item";
import { useAjax, useServer } from "../../../../../utils/hooks";
import {} from "../../../../../utils";
import { getSocket } from "../../../../../utils/globals";
import { WithRole } from "../../../../../components/with-role";
import Spinner from "../../../../../components/spinner";
import { format, fromUnixTime } from "date-fns";
import RestoreFromBackupModal from "../../../../../components/modals/restore-from-backup";
import CreateServerFromBackupModal from "../../../../../components/modals/create-server-from-backup";

export default function ServerBackups() {
  const server = useServer();
  const ajax = useAjax();
  const socket = getSocket();

  const [backups, setBackups] = useState(null);
  const [selectedBackup, setSelectedBackup] = useState(null);

  const [isRollbackBackupModalOpen, setIsRollbackBackupModalOpen] = useState(
    false
  );
  const [
    isCreateServerFromBackupModalOpen,
    setIsCreateServerFromBackupModalOpen,
  ] = useState(false);

  const listBackups = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax("/backups/list", { serverID: server._id });

    if (data.result === "success") {
      data.backups.sort((a, b) => a.ctime - b.ctime);

      setBackups(data.backups);
    }
  }, [ajax, server]);

  const updateBackups = useCallback(() => {
    listBackups();
  }, [listBackups]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    listBackups();

    socket.on("update-backups", updateBackups);

    return () => {
      socket.off("update-backups", updateBackups);
    };
  }, [listBackups, socket, updateBackups]);

  function handleRollbackClicked(backup) {
    setSelectedBackup(backup);
    setIsRollbackBackupModalOpen(true);
  }

  function handleRollbackBackupModalClosed() {
    setIsRollbackBackupModalOpen(false);
  }

  function handleCreateServerFromBackupClicked(backup) {
    setSelectedBackup(backup);
    setIsCreateServerFromBackupModalOpen(true);
  }

  function handleCreateServerFromBackupModalClosed() {
    setIsCreateServerFromBackupModalOpen(false);
  }

  function handleBackupDropdownToggle(backup) {
    backup.isDropdownOpen = !backup.isDropdownOpen;
    setBackups([...backups]);
  }

  return (
    <WithRole permission="servers.backups">
      <Box className={styles.wrapper}>
        <div className={styles.title}>
          <FormattedMessage id="server-backups.title" />
        </div>
        <div className={styles.description}>
          <div className={styles.svgWrapper}>
            <SnapSvg />
          </div>
          <div>
            <FormattedMessage id="server-backups.description" />
          </div>
        </div>
        <div className={styles.table}>
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="server-snapshots.date" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!backups && (
                <tr>
                  <td colSpan={2}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {backups?.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {backups?.map((backup, idx) => (
                <tr key={idx}>
                  <td>{format(fromUnixTime(backup.ctime), "d/M/y HH:mm")}</td>
                  <td>
                    <CustomMenu
                      isOpen={backup.isDropdownOpen}
                      toggle={() => handleBackupDropdownToggle(backup)}
                    >
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() => handleRollbackClicked(backup)}
                      >
                        <FormattedMessage id="server-backups.restore-backup" />
                      </CustomMenuItem>
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleCreateServerFromBackupClicked(backup)
                        }
                      >
                        <FormattedMessage id="server-backups.create-server-from-backup" />
                      </CustomMenuItem>
                    </CustomMenu>
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </div>
      </Box>

      <RestoreFromBackupModal
        isOpen={isRollbackBackupModalOpen}
        onClose={handleRollbackBackupModalClosed}
        backup={selectedBackup}
      />

      <CreateServerFromBackupModal
        isOpen={isCreateServerFromBackupModalOpen}
        onClose={handleCreateServerFromBackupModalClosed}
        backup={selectedBackup}
      />
    </WithRole>
  );
}
