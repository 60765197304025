import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";

function ProgressBar({ current, max }) {
  const prec = (current / max) * 100;

  return (
    <div className={styles.wrapper}>
      <div className={styles.progress} style={{ width: `${prec}%` }}></div>
      <div
        className={`${styles.progressNumber} ${prec >= 56 ? styles.white : ""}`}
      >
        {parseInt(prec)}%
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  current: PropTypes.number,
  max: PropTypes.number,
};

export default ProgressBar;
