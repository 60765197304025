import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import mainStore from "./store";
import messagesEn from "./translations/en.json";
import messagesHe from "./translations/he.json";
import LayoutWithStripe from "./components/layout-with-stripe";

const messages = { en: messagesEn, he: messagesHe };

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "none");

ReactDOM.render(
  <>
    <IntlProvider messages={messages.en} locale="en" defaultLocale="en">
      <Provider store={mainStore}>
        <Router>
          <LayoutWithStripe stripePromise={stripePromise}>
            <App />
          </LayoutWithStripe>
        </Router>
      </Provider>
    </IntlProvider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
