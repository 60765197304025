import styles from "./documents.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useAjax,
  useAlert,
  useConfirm,
  usePrompt,
} from "../../../../../utils/hooks";
import Box from "../../../../../components/box";
import IconButton from "../../../../../components/icon-button";
import BasicTable from "../../../../../components/basic-table";
import { format } from "date-fns";
import CustomMenu from "../../../../../components/custom-menu";
import CustomMenuItem from "../../../../../components/custom-menu/item";
import {} from "../../../../../utils";
import { getServerUrl } from "../../../../../utils/wldvps";

function ClientsListDocuments({ user }) {
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();
  const confirm = useConfirm();
  const prompt = usePrompt();

  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFilesToUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const fetchFiles = useCallback(async () => {
    if (!user) {
      return;
    }

    const data = await ajax(`/users/getUploadedFiles`, {
      userID: user._id,
    });

    if (data.result === "success") {
      setFiles(data.files);
    }
  }, [ajax, user]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  async function handleUploadClicked() {
    const formData = new FormData();

    formData.append("userID", user._id);

    filesToUpload.forEach((file) => {
      formData.append(file.name, file);
    });

    setLoading(true);
    await ajax(`/users/uploadFiles`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    await fetchFiles();
    setLoading(true);

    setFilesToUpload([]);

    await alert(
      intl.formatMessage({ id: "clients-list-documents.uploaded.title" }),
      intl.formatMessage({ id: "clients-list-documents.uploaded.content" })
    );
  }

  function handleDropDownToggle(file) {
    file.dropdownOpen = !file.dropdownOpen;
    setFiles([...files]);
  }

  async function handleDeleteFileMenuClicked(file) {
    const state = await confirm({
      title: intl.formatMessage({ id: "clients-list-documents.delete.title" }),
      message: intl.formatMessage(
        { id: "clients-list-documents.delete.content" },
        { fileName: file.name }
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax(`/users/deleteFile`, {
      userID: user._id,
      fileName: file.name,
    });

    await fetchFiles();

    setLoading(false);
  }

  async function handleEditFileNotesMenuClicked(file) {
    const notes = await prompt({
      title: intl.formatMessage({ id: "clients-list-documents.edit-notes" }),
      message: intl.formatMessage({
        id: "clients-list-documents.edit-notes-type",
      }),
      defaultText: file.metadatas?.notes,
      textType: "textarea",
    });

    if (notes === null) {
      return;
    }

    setLoading(true);
    await ajax(`/users/updateFileNotes`, {
      userID: user._id,
      fileName: file.name,
      notes,
    });

    await fetchFiles();
    setLoading(true);
  }

  function renderFiles() {
    if (!files.length) {
      return null;
    }

    return (
      <Box>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="clients-list-documents.file-name" />
              </th>
              <th>
                <FormattedMessage id="clients-list-documents.notes" />
              </th>
              <th>
                <FormattedMessage id="clients-list-documents.created-at" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, idx) => (
              <tr key={idx}>
                <td>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${getServerUrl()}/users/getFile?userid=${
                      user._id
                    }&filename=${file.name}`}
                  >
                    {file.name}
                  </a>
                </td>
                <td>{file.metadatas?.notes}</td>
                <td>{format(new Date(file.createdAt), "d/M/y HH:mm")}</td>
                <td>
                  <CustomMenu
                    isOpen={file.dropdownOpen}
                    toggle={() => handleDropDownToggle(file)}
                  >
                    <CustomMenuItem
                      onClick={() => handleEditFileNotesMenuClicked(file)}
                    >
                      <FormattedMessage id="clients-list-documents.edit-notes" />
                    </CustomMenuItem>
                    <CustomMenuItem
                      onClick={() => handleDeleteFileMenuClicked(file)}
                    >
                      <FormattedMessage id="general.delete" />
                    </CustomMenuItem>
                  </CustomMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </Box>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Box className={styles.dropzoneWrapper}>
        <div className={styles.dropperArea} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <FormattedMessage id="clients-list-documents.after-drag" />
          ) : (
            <FormattedMessage id="clients-list-documents.before-drag" />
          )}
        </div>

        {filesToUpload.length > 0 && (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: "clients-list-documents.files-selected" },
                  {
                    count: filesToUpload.length,
                    b: (arr) => `<b>${arr[0]}</b>`,
                  }
                ),
              }}
            ></div>
            {filesToUpload.map((file, idx) => (
              <div key={idx}>{file.name}</div>
            ))}
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleUploadClicked}
            >
              <FormattedMessage id="general.upload" />
            </IconButton>
          </div>
        )}
      </Box>

      {renderFiles()}
    </div>
  );
}

ClientsListDocuments.propTypes = {
  user: PropTypes.object,
};

export default ClientsListDocuments;
