import styles from "./create-new-invoice.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import { WithRole } from "../../../components/with-role";
import Box from "../../../components/box";
import CustomReactSelect from "../../../components/custom-react-select";
import {
  convertCurrency,
  currencySymbols,
  getPrice,
  getSymbolsForReactSelect,
  getVatPrec,
  calculateServerPriceHourlyOn,
  calculateServerPriceHourlyOff,
} from "../../../utils/billing";
import { useAjax, useLang, useUser, useWLDVPS } from "../../../utils/hooks";
import CustomText from "../../../components/custom-text";
import IconButton from "../../../components/icon-button";
import AddServersToInvoiceModal from "../../../components/modals/add-servers-to-invoice";
import BasicTable from "../../../components/basic-table";
import { ReactComponent as TrashSvg } from "../../../components/svgs/trash-purple.svg";
import AddCustomToInvoiceModal from "../../../components/modals/add-custom-to-invoice";
import AddOtherProductsToInvoiceModal from "../../../components/modals/add-other-products-to-invoice";
import AddAddonsToInvoiceModal from "../../../components/modals/add-addons-to-invoice";
import {
  addDays,
  addMonths,
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  format,
  getDaysInMonth,
  setDate,
  startOfDay,
  startOfMonth,
} from "date-fns";
import {} from "../../../utils";
import { useHistory } from "react-router-dom";

const payWithOptions = [
  { label: "credit-card", value: "credit-card" },
  { label: "masav", value: "masav" },
  { label: "bank-transfer", value: "bank-transfer" },
  { label: "payoneer", value: "payoneer" },
  { label: "paypal", value: "paypal" },
];

const currencyOptions = getSymbolsForReactSelect();

export default function BillingCreateNewInvoice() {
  const intl = useIntl();
  const user = useUser();
  const ajax = useAjax();
  const lang = useLang();
  const router = useHistory();
  const wldvps = useWLDVPS();

  const updateNextPayDayOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: "billing-create-new-invoice.update-next-pay-day.none",
        }),
        value: "none",
      },
      {
        label: intl.formatMessage({
          id: "billing-create-new-invoice.update-next-pay-day.on-created",
        }),
        value: "on-create",
      },
      // {
      //   label: intl.formatMessage({
      //     id: "billing-create-new-invoice.update-next-pay-day.on-paid",
      //   }),
      //   value: "on-paid",
      // },
    ],
    [intl]
  );

  const [userToCreate, setUserToCreate] = useState(null);
  const [payWith, setPayWith] = useState(null);
  const [vat, setVat] = useState("");
  const [currency, setCurrency] = useState(null);
  const [creditsDiscount, setCreditsDiscount] = useState("0");
  const [createdAt, setCreatedAt] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [items, setItems] = useState([]);
  const [updateNextPayDay, setUpdateNextPayDay] = useState(
    updateNextPayDayOptions[1]
  );

  const [editInvoice, setEditInvoice] = useState(false);

  const [
    isAddServersToInvoiceModalOpen,
    setIsAddServersToInvoiceModalOpen,
  ] = useState(false);
  const [
    isAddAddonsToInvoiceModalOpen,
    setIsAddAddonsToInvoiceModalOpen,
  ] = useState(false);
  const [
    isAddCustomToInvoiceModalOpen,
    setIsAddCustomToInvoiceModalOpen,
  ] = useState(false);
  const [
    isAddOtherProductsToInvoiceModalOpen,
    setIsAddOtherProductsToInvoiceModalOpen,
  ] = useState(false);

  const [users, setUsers] = useState([]);
  const [usersForSelect, setUsersForSelect] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getInvoice = useCallback(
    async (invoiceID) => {
      const data = await ajax("/billing/getInvoice", {
        invoiceID,
      });

      if (data.result === "success") {
        setUserToCreate(
          usersForSelect.find((u) => u.value === data.invoice.user_id)
        );
        setPayWith(
          payWithOptions.find((pwo) => pwo.value === data.invoice.pay_with)
        );
        setVat(Math.round(data.invoice.vat));
        setCurrency(
          currencyOptions.find((co) => co.value === data.invoice.currency)
        );
        setCreditsDiscount(data.invoice.credits_discount);
        setCreatedAt(new Date(data.invoice.created_at));
        setDueDate(new Date(data.invoice.due_date));
        setItems(
          data.invoice.items.map((item) => ({
            name: item.name,
            description: item.description,
            amount: item.amount,
            quantity: item.quantity,
            item_type: item.item_type,
            item_id: item.item_id,
            item: { _id: item.item_id },
          }))
        );
        setUpdateNextPayDay(updateNextPayDayOptions[0]);
        setEditInvoice(invoiceID);
      }
    },
    [ajax, usersForSelect, updateNextPayDayOptions]
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const edit = urlSearchParams.get("edit");

    if (edit) {
      getInvoice(edit);
    }
  }, [getInvoice]);

  const handleUserChanged = useCallback(
    (item) => {
      setUserToCreate(item);

      const user = users.find((usr) => usr._id === item.value);

      if (user) {
        setVat(Math.round((1 - getVatPrec(user)) * -100));
        setPayWith(
          payWithOptions.find((option) => option.value === user.pay_with)
        );
        setCurrency(
          currencyOptions.find((option) => option.value === user.currency)
        );
      }
    },
    [users]
  );

  useEffect(() => {
    if (usersForSelect.length === 0) {
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const filter = urlSearchParams.get("filter");

    if (filter) {
      const userToCreate = usersForSelect.find((user) =>
        user.label.includes(filter)
      );

      if (userToCreate) {
        handleUserChanged(userToCreate);
      }
    }
  }, [usersForSelect, handleUserChanged]);

  const fetchUsers = useCallback(async () => {
    const data = await ajax(`/admin/getUsers`);

    setUsers(data.users);
    setUsersForSelect(
      data.users.map((user, idx) => ({
        label: user.email,
        value: user._id,
        key: idx,
      }))
    );
  }, [ajax]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  function handleAddServersToInvoiceModalOpen() {
    setIsAddServersToInvoiceModalOpen(true);
  }

  function handleAddServersToInvoiceModalClosed(state) {
    const curDate = new Date();
    const startOfMonthMinus1Date = addDays(startOfMonth(new Date()), -1);

    if (state) {
      const userObj = users.find((user) => user._id === userToCreate.value);

      const selectedServersKeys = Object.keys(state.selectedServers);

      selectedServersKeys.forEach((key) => {
        const itm = state.selectedServers[key];

        const serverPayment = wldvps ? itm.whitelabel_payment : itm.payment;

        let itemsToPush = {};

        // ============= HOURLY =============
        if (itm.payment.payment_type === "hourly") {
          let trackingFrom, lastTo;

          for (let i = 0; i < itm.tracking.length + 1; i++) {
            if (
              (itm.tracking[i]
                ? new Date(itm.tracking[i].created_at)
                : curDate) < new Date(serverPayment.next_pay_day)
            ) {
              continue;
            }

            if (
              i === itm.tracking.length &&
              !trackingFrom &&
              Object.keys(itemsToPush).length === 0
            ) {
              trackingFrom = itm.tracking[itm.tracking.length - 1];
              trackingFrom.created_at = startOfMonth(new Date());
            }

            if (trackingFrom) {
              const from = new Date(trackingFrom.created_at);
              const to = itm.tracking[i]
                ? new Date(itm.tracking[i].created_at)
                : curDate;
              lastTo = to;

              const diff = differenceInMinutes(to, from);

              const { price } = (trackingFrom.data.running
                ? calculateServerPriceHourlyOn
                : calculateServerPriceHourlyOff)(
                userObj,
                trackingFrom.data,
                user.productPrices
              );

              let amount = convertCurrency(
                price,
                itm.payment.currency,
                currency.value,
                user.rates,
                {
                  USD: userObj.fixed_usd,
                }
              ).toFixed(3);

              let quantity = diff / 60;

              let serverDescription = `${
                trackingFrom.data.image ? `${trackingFrom.data.image}/` : ""
              }${trackingFrom.data.cpu ? `CPU ${trackingFrom.data.cpu}` : ""}${
                trackingFrom.data.ram_mb ? `/${trackingFrom.data.ram_mb}MB` : ""
              }${
                trackingFrom.data.ssd_gb ? `/${trackingFrom.data.ssd_gb}GB` : ""
              }${
                trackingFrom.data.additional_ssd_gb
                  ? `/+${trackingFrom.data.additional_ssd_gb}GB`
                  : ""
              }${trackingFrom.data.backup ? "/Backup" : ""}\n${
                trackingFrom.data.running ? "Power On" : "Power Off"
              }`;

              if (trackingFrom.data.status === "Terminated") {
                serverDescription = `\n${format(from, "d/M/y")}-${format(
                  to,
                  "d/M/y"
                )}\nServer Terminated`;

                amount = 0;
                quantity = 1;
              } else {
                serverDescription += `\n${format(
                  startOfMonthMinus1Date,
                  "d/M/y"
                )}-${format(curDate, "d/M/y")}`;
              }

              if (itemsToPush[serverDescription]) {
                itemsToPush[serverDescription].quantity += diff / 60;
              } else {
                itemsToPush[serverDescription] = {
                  name: itm.hostname,
                  description: serverDescription,
                  amount,
                  quantity,
                  item_type: "server",
                  item_id: itm._id,
                  item: itm,
                };
              }
            }

            trackingFrom = itm.tracking[i];
          }

          itemsToPush = [
            ...Object.keys(itemsToPush).map((k, idx) => {
              if (idx == Object.keys(itemsToPush).length - 1) {
                itemsToPush[k].nextPayDayBefore = new Date(
                  serverPayment.next_pay_day
                );
                itemsToPush[k].nextPayDayAfter = lastTo;
              }
              return itemsToPush[k];
            }),
          ];
          // ============= MONTHLY =============
        } else {
          const serverDescription = `${itm.image ? `${itm.image}/` : ""}${
            itm.cpu ? `CPU ${itm.cpu}` : ""
          }${itm.ram_mb ? `/${itm.ram_mb}MB` : ""}${
            itm.ssd_gb ? `/${itm.ssd_gb}GB` : ""
          }${itm.additional_ssd_gb ? `/+${itm.additional_ssd_gb}GB` : ""}${
            itm.backup ? "/Backup" : ""
          }`;

          const priceObj = getPrice(state.selectedServers[key], true, wldvps);
          let amount = convertCurrency(
            priceObj.price,
            priceObj.currency,
            currency.value,
            user.rates,
            {
              USD: userObj.fixed_usd,
            }
          );

          const isFirstMonth =
            itm.payment.payment_type === "monthly" &&
            !!state.selectedIsFirstMonth[itm._id];

          if (isFirstMonth) {
            const restOfMonth =
              differenceInDays(
                endOfMonth(new Date(serverPayment.next_pay_day)),
                startOfDay(new Date(serverPayment.next_pay_day))
              ) + 1;

            amount =
              amount *
              (restOfMonth /
                getDaysInMonth(new Date(serverPayment.next_pay_day)));
          }

          itemsToPush = [
            {
              name: itm.hostname,
              description: `${serverDescription}\n${format(
                isFirstMonth
                  ? startOfDay(new Date(serverPayment.next_pay_day))
                  : startOfMonth(new Date(serverPayment.next_pay_day)),
                "d/M/y"
              )}-${format(
                endOfMonth(
                  addMonths(
                    startOfDay(new Date(serverPayment.next_pay_day)),
                    serverPayment.payEvery - 1
                  )
                ),
                "d/M/y"
              )}`,
              amount: amount.toFixed(2),
              quantity: 1,
              item_type: "server",
              item_id: itm._id,
              item: itm,
              isFirstMonth,
              nextPayDayBefore: startOfDay(
                new Date(serverPayment.next_pay_day)
              ),
              nextPayDayAfter: setDate(
                addMonths(
                  startOfDay(new Date(serverPayment.next_pay_day)),
                  serverPayment.payEvery
                ),
                serverPayment.payDay
              ),
            },
          ];
        }

        items.push(...itemsToPush);
      });

      // ============= BANDWIDTH =============
      const selectedBandwidthKeys = Object.keys(state.selectedIncludeBandwith);

      selectedBandwidthKeys.forEach((key) => {
        let server;

        const selectedServersKeys = Object.keys(state.selectedServers);

        selectedServersKeys.forEach((okey) => {
          if (okey === key) {
            server = state.selectedServers[okey];
          }
        });

        if (!server) {
          return;
        }

        const isFirstMonth = !!state.selectedIsFirstMonth[
          state.selectedIncludeBandwith[key]._id
        ];

        const totalGB = server.unpaidBandwidth / Math.pow(1024, 3);

        let amount, quantity, remainGB;

        const serverPayment = wldvps
          ? server.whitelabel_payment
          : server.payment;
        let from = startOfMonth(new Date(serverPayment.next_pay_day));
        let to = endOfMonth(new Date(serverPayment.next_pay_day));

        if (server.payment.payment_type === "hourly") {
          from = addDays(addMonths(from, 1), -1);
          to = addMonths(to, 1);
        }

        let description = `Belongs to server ${server.hostname}\n${format(
          from,
          "d/M/y"
        )}-${format(to, "d/M/y")}`;

        if (server.payment.payment_type === "hourly") {
          amount = convertCurrency(
            parseFloat(process.env.REACT_APP_HOURLY_BANDWIDTH_PRICE),
            "USD",
            currency.value,
            user.rates,
            {
              USD: userObj.fixed_usd,
            }
          );

          if (userObj.free_hourly_bandwidth) {
            amount = 0;
          }

          quantity = server.unpaidBandwidth / Math.pow(1024, 3);

          remainGB = totalGB.toFixed(2);
        } else {
          let toPayGB =
            totalGB -
            parseInt(process.env.REACT_APP_MAX_MONTHLY_BANDWIDTH_TB) * 1024;

          if (server.payment.free_bandwidth) {
            if (server.payment.free_bandwidth_up_to === 0) {
              toPayGB = 0;
            } else {
              toPayGB -= server.payment.free_bandwidth_up_to * 1024;
              toPayGB = Math.max(0, toPayGB);
            }
          }

          if (toPayGB === 0) {
            return;
          }

          amount = convertCurrency(
            0.01 * (1 - userObj.discount_monthly_bandwidth / 100),
            "USD",
            currency.value,
            user.rates,
            {
              USD: userObj.fixed_usd,
            }
          );

          quantity = toPayGB.toFixed(2);

          remainGB = toPayGB.toFixed(2);

          description += `\nTotal: ${(totalGB / 1024).toFixed(2)}TB, To Pay: ${(
            toPayGB / 1024
          ).toFixed(2)}TB`;
        }

        items.push({
          name: `${
            server.payment.payment_type === "hourly"
              ? `Bandwidth/GB (${remainGB}GB)`
              : "Monthly bandwidth"
          }`,
          description,
          amount: amount.toFixed(3),
          quantity,
          item_type:
            server.payment.payment_type === "hourly"
              ? "hourly-bandwidth"
              : "monthly-bandwidth",
          item_id: server._id,
          item: server,
          isFirstMonth,
        });
      });

      setItems([...items]);
    }

    sortItems();

    setIsAddServersToInvoiceModalOpen(false);
  }

  function handleAddAddonsToInvoiceModalOpen() {
    setIsAddAddonsToInvoiceModalOpen(true);
  }

  function handleAddAddonsToInvoiceModalClosed(state) {
    const curDate = new Date();

    if (state) {
      const userObj = users.find((user) => user._id === userToCreate.value);

      const selectedAddonsKeys = Object.keys(state.selectedAddons);

      selectedAddonsKeys.forEach((key) => {
        const itm = state.selectedAddons[key];

        // ============= HOURLY =============
        if (itm.server.payment.payment_type === "hourly") {
          const from = new Date(itm.next_pay_day);
          const to = itm.server.terminated_at
            ? new Date(itm.server.terminated_at)
            : curDate;
          const diff = differenceInMinutes(to, from);

          let amount = convertCurrency(
            state.selectedAddons[key].price,
            state.selectedAddons[key].currency,
            currency.value,
            user.rates,
            {
              USD: userObj.fixed_usd,
            }
          );

          if (
            state.selectedAddons[key].addon_type === "managed-hosting" &&
            userObj.discount_managed_hosting_hourly > 0
          ) {
            amount *= 1 - userObj.discount_managed_hosting_hourly / 100;
          }
          if (
            state.selectedAddons[key].addon_type === "windows-license" &&
            state.selectedAddons[key].name.toLowerCase().includes("desktop") &&
            userObj.skip_images_price_hourly
          ) {
            amount = 0;
          }

          items.push({
            name: state.selectedAddons[key].name,
            description: `${
              state.selectedAddons[key].description
                ? `${state.selectedAddons[key].description}\n`
                : ""
            }${format(from, "d/M/y")}-${format(
              to,
              "d/M/y"
            )}\nBelongs to server ${state.selectedAddons[key].server.hostname}`,
            amount: (amount / 30 / 24).toFixed(2),
            quantity: (
              state.selectedAddons[key].quantity *
              (diff / 60)
            ).toFixed(3),
            item_type: "addon",
            item_id: state.selectedAddons[key]._id,
            item: state.selectedAddons[key],
            nextPayDayBefore: new Date(itm.next_pay_day),
            nextPayDayAfter: to,
          });
          // ============= MONTHLY =============
        } else {
          const isFirstMonth = !!state.selectedIsFirstMonth[itm._id];

          let amount = convertCurrency(
            state.selectedAddons[key].price,
            state.selectedAddons[key].currency,
            currency.value,
            user.rates,
            {
              USD: userObj.fixed_usd,
            }
          );

          if (isFirstMonth) {
            const restOfMonth =
              differenceInDays(
                endOfMonth(new Date(itm.next_pay_day)),
                startOfDay(new Date(itm.next_pay_day))
              ) + 1;

            amount =
              amount *
              (restOfMonth / getDaysInMonth(new Date(itm.next_pay_day)));
          }

          if (
            state.selectedAddons[key].addon_type === "managed-hosting" &&
            userObj.discount_managed_hosting_monthly > 0
          ) {
            amount *= 1 - userObj.discount_managed_hosting_monthly / 100;
          }

          if (
            state.selectedAddons[key].addon_type === "windows-license" &&
            state.selectedAddons[key].name.toLowerCase().includes("desktop")
          ) {
            if (userObj.include_monthly_discount_windows10) {
              amount *= 1 - userObj.discount_monthly / 100;
            }
            if (userObj.skip_images_price_monthly) {
              amount = 0;
            }
          }

          if (state.selectedAddons[key].addon_type === "additional-ipv4") {
            amount *= 1 - userObj.discount_monthly / 100;
          }

          items.push({
            name: state.selectedAddons[key].name,
            description: `${
              state.selectedAddons[key].description || ""
            }\n${format(
              isFirstMonth
                ? startOfDay(new Date(itm.next_pay_day))
                : startOfMonth(new Date(itm.next_pay_day)),
              "d/M/y"
            )}-${format(
              endOfMonth(
                addMonths(
                  startOfDay(new Date(itm.next_pay_day)),
                  itm.payEvery - 1
                )
              ),
              "d/M/y"
            )}\nBelongs to server ${state.selectedAddons[key].server.hostname}`,
            amount: amount.toFixed(2),
            quantity: state.selectedAddons[key].quantity,
            item_type: "addon",
            item_id: state.selectedAddons[key]._id,
            item: state.selectedAddons[key],
            isFirstMonth,
            nextPayDayBefore: startOfDay(new Date(itm.next_pay_day)),
            nextPayDayAfter: setDate(
              addMonths(startOfDay(new Date(itm.next_pay_day)), itm.payEvery),
              itm.payDay
            ),
          });
        }
      });

      setItems([...items]);
    }

    sortItems();

    setIsAddAddonsToInvoiceModalOpen(false);
  }

  function handleRemoveItemClicked(key) {
    items.splice(key, 1);
    setItems([...items]);
  }

  function handleAddCustomToInvoiceModalOpen() {
    setIsAddCustomToInvoiceModalOpen(true);
  }

  function handleAddCustomToInvoiceModalClosed(state) {
    if (state) {
      items.push({
        name: state.name,
        description: state.description,
        amount: state.amount,
        quantity: state.quantity,
        item_type: "custom",
      });
    }

    sortItems();

    setIsAddCustomToInvoiceModalOpen(false);
  }

  function handleAddOtherProductsToInvoiceModalOpen() {
    setIsAddOtherProductsToInvoiceModalOpen(true);
  }

  function handleAddOtherProductsToInvoiceModalClosed(state) {
    if (state) {
      const selectedOtherProductsKeys = Object.keys(
        state.selectedOtherProducts
      );

      items.push(
        ...selectedOtherProductsKeys.map((key) => {
          const itm = state.selectedOtherProducts[key];
          const isFirstMonth = !!state.selectedIsFirstMonth[itm._id];

          let amount = convertCurrency(
            itm.price,
            itm.currency,
            currency.value,
            user.rates,
            {
              USD: userObj.fixed_usd,
            }
          );

          if (isFirstMonth) {
            const restOfMonth =
              differenceInDays(
                endOfMonth(new Date(itm.next_pay_day)),
                startOfDay(new Date(itm.next_pay_day))
              ) + 1;

            amount =
              amount *
              (restOfMonth / getDaysInMonth(new Date(itm.next_pay_day)));
          }

          let from, to;

          if (itm.payEvery > 1) {
            from = startOfDay(new Date(itm.next_pay_day));

            to = addMonths(
              addDays(endOfDay(new Date(itm.next_pay_day)), -1),
              itm.payEvery
            );
          } else {
            from = isFirstMonth
              ? startOfDay(new Date(itm.next_pay_day))
              : startOfMonth(new Date(itm.next_pay_day));

            to = addMonths(
              endOfMonth(new Date(itm.next_pay_day)),
              itm.payEvery - 1
            );
          }

          return {
            name: itm.name,
            description: `${
              state.selectedOtherProducts[key].description
                ? `${state.selectedOtherProducts[key].description}\n`
                : ""
            }${format(from, "d/M/y")}-${format(to, "d/M/y")}`,
            amount: amount.toFixed(2),
            quantity: itm.quantity,
            item_type: "other-product",
            item_id: itm._id,
            item: itm,
            isFirstMonth,
            nextPayDayBefore: startOfDay(new Date(itm.next_pay_day)),
            nextPayDayAfter: setDate(
              addMonths(startOfDay(new Date(itm.next_pay_day)), itm.payEvery),
              itm.payDay
            ),
          };
        })
      );

      setItems([...items]);
    }

    sortItems();

    setIsAddOtherProductsToInvoiceModalOpen(false);
  }

  function handleItemChanged(e, item, field) {
    item[field] = e.target.value;
    setItems([...items]);
  }

  function handleClearAllItemsClicked() {
    setItems([]);
  }

  async function handleCreateInvoiceClicked() {
    setError(false);

    const _vat = parseFloat(vat);
    const credits_discount = parseFloat(creditsDiscount);

    if (isNaN(_vat)) {
      return setError(
        intl.formatMessage({
          id: "billing-create-new-invoice.create.wrong-vat",
        })
      );
    } else if (isNaN(credits_discount)) {
      return setError(
        intl.formatMessage({
          id: "billing-create-new-invoice.create.wrong-credits-discount",
        })
      );
    } else if (items.length === 0) {
      return setError(
        intl.formatMessage({
          id: "billing-create-new-invoice.create.wrong-items",
        })
      );
    }

    for (let i = 0; i < items.length; i++) {
      if (!items[i].name) {
        return setError(
          intl.formatMessage(
            {
              id: "billing-create-new-invoice.create.items-no-name",
            },
            { number: i + 1 }
          )
        );
      } else if (isNaN(parseFloat(items[i].amount))) {
        return setError(
          intl.formatMessage(
            {
              id: "billing-create-new-invoice.create.items-no-amount",
            },
            { number: i + 1 }
          )
        );
      } else if (isNaN(parseFloat(items[i].quantity))) {
        return setError(
          intl.formatMessage(
            {
              id: "billing-create-new-invoice.create.items-no-quantity",
            },
            { number: i + 1 }
          )
        );
      }
    }

    const itemsToAjax = items.map((item) => {
      if (item.item) {
        item.item = {
          _id: item.item._id,
          payment: item.item.payment,
          whitelabel_payment: item.item.whitelabel_payment,
          next_pay_day: item.item.next_pay_day,
          payEvery: item.item.payEvery,
        };
      }

      return item;
    });

    setLoading(true);
    await ajax("/billing/createNewInvoice", {
      userToCreate: userToCreate.value,
      payWith: payWith.value,
      vat,
      currency: currency.value,
      credits_discount: creditsDiscount,
      createdAt,
      dueDate,
      items: itemsToAjax,
      updateNextPayDay: updateNextPayDay.value,
      editInvoice,
    });
    setLoading(false);

    router.push(`/${lang}/billing/invoices?filter=${userToCreate.label}`);
  }

  function getTotal(includeVat) {
    let total = 0;

    items.forEach((item) => {
      total += item.amount * item.quantity;
    });

    if (includeVat) {
      total *= 1 + vat / 100;
    }

    if (isNaN(total)) {
      return "-";
    }

    return `${currencySymbols[currency.value]}${total.toFixed(2)}`;
  }

  function getUpdateNextPayDayHref(item) {
    if (item.item_type === "other-product") {
      return `/${lang}/clients/clients-list/${userObj._id}/other-products`;
    }

    if (item.item_type === "addon" && item.item.server) {
      return `/${lang}/my-cloud/servers/${item.item.server._id}/server-settings/addons`;
    }

    return `/${lang}/my-cloud/servers/${item.item._id}/overview`;
  }

  function sortItems() {
    const newItems = items.filter((item) => item.item_type === "server");
    const addons = items.filter((item) => item.item_type === "addon");
    const monthlyBandwidths = items.filter(
      (item) => item.item_type === "monthly-bandwidth"
    );
    const hourlyBandwidths = items.filter(
      (item) => item.item_type === "hourly-bandwidth"
    );
    const otherProduct = items.filter(
      (item) => item.item_type === "other-product"
    );
    const customs = items.filter((item) => item.item_type === "custom");

    addons.forEach((addon) => {
      let inserted = false;

      for (let i = 0; i < newItems.length; i++) {
        if (addon.item.server?._id === newItems[i].item._id) {
          newItems.splice(i + 1, 0, addon);
          inserted = true;
          break;
        }
      }

      if (!inserted) {
        newItems.push(addon);
      }
    });

    monthlyBandwidths.forEach((monthlyBandwidth) => {
      let inserted = false;

      for (let i = 0; i < newItems.length; i++) {
        if (monthlyBandwidth.item.server?._id === newItems[i].item._id) {
          newItems.splice(i + 1, 0, monthlyBandwidth);
          inserted = true;
          break;
        }
      }

      if (!inserted) {
        newItems.push(monthlyBandwidth);
      }
    });

    hourlyBandwidths.forEach((hourlyBandwidth) => {
      let inserted = false;

      for (let i = 0; i < newItems.length; i++) {
        if (hourlyBandwidth.item.server?._id === newItems[i].item._id) {
          newItems.splice(i + 1, 0, hourlyBandwidth);
          inserted = true;
          break;
        }
      }

      if (!inserted) {
        newItems.push(hourlyBandwidth);
      }
    });

    otherProduct.forEach((product) => {
      newItems.push(product);
    });

    customs.forEach((customItem) => {
      newItems.push(customItem);
    });

    setItems(newItems);
  }

  const userObj = useMemo(
    () => users.find((user) => user._id === userToCreate?.value),
    [userToCreate?.value, users]
  );

  function renderDiscountsInformation() {
    if (
      !userObj.discount_hourly_off &&
      !userObj.discount_hourly_on &&
      !userObj.discount_monthly &&
      !userObj.discount_managed_hosting_hourly &&
      !userObj.discount_managed_hosting_monthly &&
      !userObj.discount_monthly_bandwidth &&
      !userObj.free_hourly_bandwidth &&
      !userObj.free_backups &&
      !userObj.skip_images_price_hourly_on &&
      !userObj.skip_images_price_hourly_off &&
      !userObj.skip_images_price_monthly
    ) {
      return null;
    }

    return (
      <Box className={styles.boxWrapper}>
        {userObj.discount_hourly_off !== 0 && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.discount-hourly-off"
              />
            </span>
            : {userObj.discount_hourly_off}%
          </div>
        )}
        {userObj.discount_hourly_on !== 0 && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.discount-hourly-on"
              />
            </span>
            : {userObj.discount_hourly_on}%
          </div>
        )}
        {userObj.discount_monthly !== 0 && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.discount-monthly"
              />
            </span>
            : {userObj.discount_monthly}%
          </div>
        )}
        {userObj.discount_managed_hosting_hourly !== 0 && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.discount-hourly-managed-hosting"
              />
            </span>
            : {userObj.discount_managed_hosting_hourly}%
          </div>
        )}
        {userObj.discount_managed_hosting_monthly !== 0 && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.discount-monthly-managed-hosting"
              />
            </span>
            : {userObj.discount_managed_hosting_monthly}%
          </div>
        )}
        {userObj.discount_monthly_bandwidth !== 0 && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.discount-monthly-traffic"
              />
            </span>
            : {userObj.discount_monthly_bandwidth}%
          </div>
        )}
        {userObj.free_hourly_bandwidth && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.free-hourly-traffic"
              />
            </span>
          </div>
        )}
        {userObj.free_backups && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.free-backups"
              />
            </span>
          </div>
        )}
        {userObj.skip_images_price_hourly_on && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.skip-images-price-hourly-on"
              />
            </span>
          </div>
        )}
        {userObj.skip_images_price_hourly_off && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.skip-images-price-hourly-off"
              />
            </span>
          </div>
        )}
        {userObj.skip_images_price_monthly && (
          <div>
            <span>
              <FormattedMessage
                tagName="b"
                id="clients-list-summary.skip-images-price-monthly"
              />
            </span>
          </div>
        )}
      </Box>
    );
  }

  return (
    <WithRole permission="admin.billing.create-invoice">
      <div className={styles.wrapper}>
        <h1 className={styles.mainTitle}>
          <FormattedMessage
            tagName="span"
            id={
              editInvoice
                ? "billing-create-new-invoice.main-title-edit"
                : "billing-create-new-invoice.main-title"
            }
          />
        </h1>

        <hr />

        <Box className={styles.boxWrapper}>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="billing-create-new-invoice.user" />
            </span>
            <CustomReactSelect
              instanceId="billing-create-new-invoice-user"
              options={usersForSelect}
              value={userToCreate}
              onChange={handleUserChanged}
              isDisabled={!!editInvoice}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="billing-create-new-invoice.pay-with" />
            </span>
            <CustomReactSelect
              instanceId="billing-create-new-invoice-pay-with"
              options={payWithOptions}
              value={payWith}
              onChange={(item) => setPayWith(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="billing-create-new-invoice.currency" />
            </span>
            <CustomReactSelect
              instanceId="billing-create-new-invoice-currency"
              options={currencyOptions}
              value={currency}
              onChange={(item) => setCurrency(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="billing-create-new-invoice.vat" />
            </span>
            <CustomText value={vat} onChange={(e) => setVat(e.target.value)} />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="billing-create-new-invoice.created-date" />
            </span>
            <DatePicker
              wrapperClassName="select"
              selected={createdAt}
              onChange={(date) => setCreatedAt(date)}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="billing-create-new-invoice.due-date" />
            </span>
            <DatePicker
              wrapperClassName="select"
              selected={dueDate}
              onChange={(date) => setDueDate(date)}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className={`${styles.row} ${styles.creditsWrapper}`}>
            <span>
              <FormattedMessage id="billing-create-new-invoice.credits-discount" />
            </span>
            <CustomText
              value={creditsDiscount}
              onChange={(e) => setCreditsDiscount(e.target.value)}
            />
            <span className={styles.help}>
              <FormattedMessage id="billing-create-new-invoice.credits-discount-info" />
            </span>
          </div>
        </Box>

        {userToCreate && payWith && currency && (
          <>
            {renderDiscountsInformation()}

            <Box
              className={styles.boxWrapper}
              title={
                <div className={styles.addButtons}>
                  <FormattedMessage
                    tagName="span"
                    id="billing-create-new-invoice.items"
                  />
                  <IconButton
                    color="light-purple"
                    onClick={handleAddServersToInvoiceModalOpen}
                  >
                    <FormattedMessage id="billing-create-new-invoice.add-servers" />
                  </IconButton>
                  <IconButton
                    color="light-purple"
                    onClick={handleAddAddonsToInvoiceModalOpen}
                  >
                    <FormattedMessage id="billing-create-new-invoice.add-addons" />
                  </IconButton>
                  <IconButton
                    color="light-purple"
                    onClick={handleAddOtherProductsToInvoiceModalOpen}
                  >
                    <FormattedMessage id="billing-create-new-invoice.add-other-products" />
                  </IconButton>
                  <IconButton
                    color="light-purple"
                    onClick={handleAddCustomToInvoiceModalOpen}
                  >
                    <FormattedMessage id="billing-create-new-invoice.add-custom" />
                  </IconButton>
                  <IconButton color="red" onClick={handleClearAllItemsClicked}>
                    <FormattedMessage id="billing-create-new-invoice.clear-all" />
                  </IconButton>
                </div>
              }
            >
              <BasicTable>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="billing-create-new-invoice.name" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-create-new-invoice.description" />
                    </th>
                    <th style={{ width: "100px" }}>
                      <FormattedMessage id="billing-create-new-invoice.amount" />
                      {currency && ` (${currencySymbols[currency.value]})`}
                    </th>
                    <th style={{ width: "100px" }}>
                      <FormattedMessage id="billing-create-new-invoice.quantity" />
                    </th>
                    <th style={{ width: "50px" }}>
                      <FormattedMessage id="billing-create-new-invoice.actions" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        <FormattedMessage id="billing-create-new-invoice.no-items" />
                      </td>
                    </tr>
                  )}

                  {items.map((item, key) => (
                    <tr key={key}>
                      <td>
                        <CustomText
                          value={item.name}
                          onChange={(e) => handleItemChanged(e, item, "name")}
                        />
                        <div>
                          {item.item_type}{" "}
                          {item.item_type === "server" &&
                          item.item.payment?.payment_type
                            ? ` - ${item.item.payment.payment_type}`
                            : ""}
                          {item.isFirstMonth && " (First month)"}
                        </div>
                        <div>
                          {currencySymbols[currency.value]}
                          {(item.amount * item.quantity).toFixed(3)}
                        </div>
                      </td>
                      <td>
                        <CustomText
                          type="textarea"
                          value={item.description}
                          onChange={(e) =>
                            handleItemChanged(e, item, "description")
                          }
                        />
                      </td>
                      <td>
                        <CustomText
                          value={item.amount}
                          onChange={(e) => handleItemChanged(e, item, "amount")}
                        />
                      </td>
                      <td>
                        <CustomText
                          value={item.quantity}
                          onChange={(e) =>
                            handleItemChanged(e, item, "quantity")
                          }
                        />
                      </td>
                      <td>
                        <div className={styles.trash}>
                          <TrashSvg
                            onClick={() => handleRemoveItemClicked(key)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>
            </Box>

            {!editInvoice && (
              <Box className={styles.boxWrapper}>
                <div className={styles.row}>
                  <span>
                    <FormattedMessage id="billing-create-new-invoice.update-next-pay-day" />
                  </span>
                  <CustomReactSelect
                    options={updateNextPayDayOptions}
                    value={updateNextPayDay}
                    onChange={(item) => setUpdateNextPayDay(item)}
                  />
                </div>

                {updateNextPayDay.value !== "none" && (
                  <BasicTable>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="billing-create-new-invoice.item" />
                        </th>
                        <th>
                          <FormattedMessage id="billing-create-new-invoice.next-pay-day-before" />
                        </th>
                        <th>
                          <FormattedMessage id="billing-create-new-invoice.next-pay-day-after" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items
                        .filter(
                          (item) =>
                            item.item_type &&
                            item.nextPayDayBefore &&
                            item.nextPayDayAfter
                        )
                        .map((item, key) => (
                          <tr key={key}>
                            <td>
                              <a
                                href={getUpdateNextPayDayHref(item)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.item.hostname || item.item.name}
                              </a>
                            </td>
                            <td>
                              {format(item.nextPayDayBefore, "d/M/y HH:mm")}
                            </td>
                            <td>
                              {format(item.nextPayDayAfter, "d/M/y HH:mm")}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </BasicTable>
                )}
              </Box>
            )}

            <div>
              <div className={styles.smallInfo}>
                {error && <div className="error">{error}</div>}
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      { id: "billing-create-new-invoice.total-before-vat" },
                      { total: getTotal(false), b: (arr) => `<b>${arr[0]}</b>` }
                    ),
                  }}
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      { id: "billing-create-new-invoice.total-after-vat" },
                      { total: getTotal(true), b: (arr) => `<b>${arr[0]}</b>` }
                    ),
                  }}
                ></div>
              </div>

              <IconButton
                disabled={loading}
                color="green"
                onClick={handleCreateInvoiceClicked}
              >
                <FormattedMessage
                  id={
                    editInvoice
                      ? "billing-create-new-invoice.edit-invoice"
                      : "billing-create-new-invoice.create-invoice"
                  }
                />
              </IconButton>
            </div>
          </>
        )}

        <AddServersToInvoiceModal
          isOpen={isAddServersToInvoiceModalOpen}
          onClose={handleAddServersToInvoiceModalClosed}
          user={userToCreate}
        />

        <AddAddonsToInvoiceModal
          isOpen={isAddAddonsToInvoiceModalOpen}
          onClose={handleAddAddonsToInvoiceModalClosed}
          user={userToCreate}
        />

        <AddCustomToInvoiceModal
          isOpen={isAddCustomToInvoiceModalOpen}
          onClose={handleAddCustomToInvoiceModalClosed}
        />

        <AddOtherProductsToInvoiceModal
          isOpen={isAddOtherProductsToInvoiceModalOpen}
          onClose={handleAddOtherProductsToInvoiceModalClosed}
          user={userToCreate}
        />
      </div>
    </WithRole>
  );
}
