import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";

function SimpleBadge({
  className,
  children,
  color = "light-purple",
  textColor = "primary-color",
}) {
  return (
    <div
      className={`${className || ""} ${styles.simpleBadgeWrapper} ${
        styles[`textcolor-${textColor}`]
      } ${styles[`color-${color}`]}`}
    >
      {children}
    </div>
  );
}

SimpleBadge.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

export default SimpleBadge;
