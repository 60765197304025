import styles from "./no-servers.module.scss";

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../icon-button";
import { ReactComponent as CloudSvg } from "../svgs/cloud2.svg";
import { useLang, useRoles, useWLDVPS } from "../../utils/hooks";

export default function NoServers() {
  const intl = useIntl();
  const lang = useLang();
  const { isAllowed } = useRoles();
  const wldvps = useWLDVPS();

  return (
    <>
      <div className={`mb16 ${styles.card}`}>
        {!wldvps && <CloudSvg />}
        <div
          className={`mb8 ${styles.title}`}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "servers.create-title" }),
          }}
        ></div>
        <div className={`mb36 ${styles.subtitle}`}>
          <FormattedMessage id="servers.create-subtitle" />
        </div>
        {isAllowed("servers.create") && (
          <div>
            <IconButton
              icon="arrow"
              color="green"
              href="/[lang]/my-cloud/create-new-server"
              as={`/${lang}/my-cloud/create-new-server`}
            >
              <FormattedMessage id="servers.create-button" />
            </IconButton>
          </div>
        )}
      </div>

      {/* <div className={styles.card}>
        <div className={`mb8 ${styles.title}`}>
          <FormattedMessage id="servers.migrate-title" />
        </div>
        <div className={`mb36 ${styles.subtitle}`}>
          <FormattedMessage id="servers.migrate-subtitle" />
        </div>
        <div>
          <IconButton icon="arrow" color="green">
            <FormattedMessage id="servers.migrate-button" />
          </IconButton>
        </div>
      </div> */}
    </>
  );
}
