import styles from "./overview.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import copy from "copy-to-clipboard";
import { ReactComponent as EditSvg } from "../svgs/edit.svg";
import { ReactComponent as HelpSvg } from "../svgs/help.svg";
import { useAjax, usePrompt, useRoles, useUser } from "../../utils/hooks";
import { getCountryByCode, getCountryIcon } from "../../utils/countries";
import { getImageName, renderBandwidth } from "../../utils/servers";
import { Modal, ModalBody, ModalHeader, Tooltip } from "reactstrap";
import ServerSettingsChangePassword from "../../pages/[lang]/my-cloud/servers/[id]/server-settings/change-password";
import ClipboardCopy from "../clipboard-copy";
import { format } from "date-fns";
import isValidDomain from "is-valid-domain";
import { SUPER_ADMIN } from "../../utils/user";
import WanSelectorModal from "../modals/wan-selector";

function Overview({ server }) {
  const ajax = useAjax();
  const prompt = usePrompt();
  const user = useUser();
  const intl = useIntl();
  const { isAllowed } = useRoles();

  const [dedicatedip, setDedicatedip] = useState(null);
  const [isXipTooltipOpen, setIsXipTooltipOpen] = useState(false);

  const [
    isServerSettingsChangePasswordModalOpen,
    setIsServerSettingsChangePasswordModalOpen,
  ] = useState(false);
  const [isWanSelectorModalOpen, setIsWanSelectorModalOpen] = useState(false);

  useEffect(() => {
    if (!server) {
      return;
    }

    setDedicatedip(server.dedicatedip);
  }, [server]);

  function renderUsername() {
    if (server.image.toLowerCase().indexOf("windows") > -1) {
      return "Administrator";
    } else if (server.image.toLowerCase().indexOf("pfsense") > -1) {
      return "admin";
    }

    return "root";
  }

  async function handleCopyPasswordClicked() {
    const data = await ajax("/servers/getServerPassword", {
      serverID: server._id,
    });

    copy(data.password);
  }

  function handleEditPasswordClicked() {
    setIsServerSettingsChangePasswordModalOpen(true);
  }

  function handleEditPasswordClosed() {
    setIsServerSettingsChangePasswordModalOpen(false);
  }

  function handleEditIPClicked() {
    setIsWanSelectorModalOpen(true);
  }

  async function handleWanSelectorModalClosed(ip, subnet) {
    if (ip && subnet) {
      await ajax("/network/attachIPToServer", {
        serverID: server._id,
        ip,
        subnetID: subnet._id,
        type: "wan",
        applyInProxmox: true,
        removeOtherConnectedIps: true,
        userIDToWork: user._id,
      });

      setDedicatedip(ip);
    }

    setIsWanSelectorModalOpen(false);
  }

  async function handleChangeHostnameClicked() {
    const text = await prompt({
      title: intl.formatMessage({ id: "servers-list.set-hostname-title" }),
      message: intl.formatMessage({ id: "servers-list.set-hostname-content" }),
      defaultText: server.hostname,
      acceptOnlyValue: (text) => {
        if (!isValidDomain(text)) {
          return {
            status: false,
            reason: intl.formatMessage({ id: "servers-list.invalid-domain" }),
          };
        }

        return {
          status: true,
        };
      },
    });

    if (text) {
      await ajax("/servers/setHostName", {
        hostname: text,
        serverID: server._id,
      });
    }
  }

  async function handleChangeTagClicked() {
    const text = await prompt({
      title: intl.formatMessage({ id: "servers-list.set-tag-title" }),
      message: intl.formatMessage({ id: "servers-list.set-tag-content" }),
      defaultText: server.tag,
    });

    if (text === null) {
      return;
    }

    await ajax("/servers/setTag", {
      tag: text || "",
      serverID: server._id,
    });
  }

  if (!server) {
    return null;
  }

  return (
    <div className={styles.overview}>
      <div className={styles.section}>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.location" />
          </span>
          <span className={styles.gray}>
            {getCountryIcon(server.location)}{" "}
            {getCountryByCode(server.location)}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.ip-address" />
          </span>
          <span className={styles.gray}>
            {dedicatedip} <ClipboardCopy text={dedicatedip} />{" "}
            {[SUPER_ADMIN].includes(user.role) &&
              isAllowed("networks.ips") &&
              server.status === "Active" && (
                <EditSvg
                  className={`pointer ${
                    server.isWorking ? "link-disabled" : ""
                  }`}
                  onClick={handleEditIPClicked}
                />
              )}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.user-name" />
          </span>
          <span className={styles.gray}>
            {renderUsername()} <ClipboardCopy text={renderUsername()} />
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.password" />
          </span>
          <span className={styles.gray}>
            ••••••••••• <ClipboardCopy onClick={handleCopyPasswordClicked} />{" "}
            {isAllowed("servers.change-password") &&
              server.status === "Active" && (
                <EditSvg
                  className={`pointer ${
                    server.isWorking ? "link-disabled" : ""
                  }`}
                  onClick={handleEditPasswordClicked}
                />
              )}
          </span>
        </div>
        {dedicatedip && (
          <div>
            <span className={styles.black}>
              <FormattedMessage id="servers-list.domain" />
            </span>
            <span className={styles.gray}>
              {`${dedicatedip}.xip.io`}{" "}
              <div className={styles.flex}>
                <ClipboardCopy text={`${dedicatedip}.xip.io`} />
                <div id="tooltip-xip">
                  <div>
                    <HelpSvg />
                  </div>
                </div>
                <Tooltip
                  placement="bottom"
                  isOpen={isXipTooltipOpen}
                  target="tooltip-xip"
                  toggle={() => setIsXipTooltipOpen(!isXipTooltipOpen)}
                >
                  xip.io is a magic domain name that provides wildcard DNS for
                  any IP address.
                </Tooltip>
              </div>
            </span>
          </div>
        )}
        {user.role === SUPER_ADMIN && (
          <div>
            <span className={styles.black}>Node</span>
            <span className={styles.gray}>
              {server.node} <ClipboardCopy text={server.node} />
            </span>
          </div>
        )}
      </div>

      <div className={styles.section}>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.cpu" />
          </span>
          <span className={styles.gray}>
            {server.cpu} vCPU Xeon Platinum (2.90Ghz)
          </span>{" "}
          {/* <span className={styles.upgrade}>
            <FormattedMessage id="servers-list.upgrade" />
          </span> */}
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.ram" />
          </span>
          <span className={styles.gray}>{server.ram_mb}MB</span>{" "}
          {/* <span className={styles.upgrade}>
            <FormattedMessage id="servers-list.upgrade" />
          </span> */}
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.storage" />
          </span>
          <span className={styles.gray}>
            {server.ssd_gb}GB SSD NVMe{" "}
            {server.additional_ssd_gb
              ? `(+${server.additional_ssd_gb}GB SSD NVMe)`
              : ""}
          </span>{" "}
          {/* <span className={styles.upgrade}>
            <FormattedMessage id="servers-list.upgrade" />
          </span> */}
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.bandwidth" />
          </span>
          <span className={styles.gray}>{renderBandwidth(server)}</span>
        </div>
        {user.role === SUPER_ADMIN && (
          <div>
            <span className={styles.black}>VMID</span>
            <span className={styles.gray}>
              {server.vmid} <ClipboardCopy text={server.vmid} />
            </span>
          </div>
        )}
      </div>

      <div className={styles.section}>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.hostname" />
          </span>
          <span className={styles.gray}>
            {server.hostname} <ClipboardCopy text={server.hostname} />
            {isAllowed("servers.edit-details") &&
              server.status === "Active" && (
                <EditSvg
                  className={`pointer ${
                    server.isWorking ? "link-disabled" : ""
                  }`}
                  onClick={handleChangeHostnameClicked}
                />
              )}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.tag" />
          </span>
          <span className={styles.gray}>
            {server.tag || <FormattedMessage id="servers-list.tag-unset" />}{" "}
            {isAllowed("servers.edit-details") &&
              server.status === "Active" && (
                <EditSvg
                  className={`pointer ${
                    server.isWorking ? "link-disabled" : ""
                  }`}
                  onClick={handleChangeTagClicked}
                />
              )}
          </span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.image" />
          </span>
          <span className={styles.gray}>{getImageName(server.image)}</span>
        </div>
        <div>
          <span className={styles.black}>
            <FormattedMessage id="servers-list.created-date" />
          </span>
          <span className={styles.gray}>
            {format(new Date(server.created_at), "d/M/y")}
          </span>
        </div>
        {server.terminated_at && (
          <div>
            <span className={styles.black}>
              <FormattedMessage id="servers-list.terminated-date" />
            </span>
            <span className={styles.gray}>
              {format(new Date(server.terminated_at), "d/M/y")}
            </span>
          </div>
        )}
      </div>

      <Modal
        isOpen={isServerSettingsChangePasswordModalOpen}
        toggle={handleEditPasswordClosed}
      >
        <ModalHeader toggle={handleEditPasswordClosed}>
          <FormattedMessage id="server-settings-change-password.title" />
        </ModalHeader>
        <ModalBody>
          <ServerSettingsChangePassword serverToUse={server} onlyContent />
        </ModalBody>
      </Modal>

      <WanSelectorModal
        location={server.location}
        isOpen={isWanSelectorModalOpen}
        onClose={handleWanSelectorModalClosed}
      />
    </div>
  );
}

Overview.propTypes = {
  server: PropTypes.object,
  reset: PropTypes.func,
  update: PropTypes.func,
};

export default Overview;
