import styles from "./change-payment-form.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useLang } from "../../../utils/hooks";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import {} from "../../../utils";
import Spinner from "../../../components/spinner";
import BasicTable from "../../../components/basic-table";
import { getFullName } from "../../../utils/user";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";

export default function BillingChangePaymentForm() {
  const ajax = useAjax();
  const intl = useIntl();
  const lang = useLang();

  const [servers, setServers] = useState(null);

  const [loading, setLoading] = useState(false);

  const getChangePaymentForm = useCallback(async () => {
    const data = await ajax("/billing/getChangePaymentForm");

    setServers(data.servers);
  }, [ajax]);

  useEffect(() => {
    getChangePaymentForm();
  }, [getChangePaymentForm]);

  function handleDropDownToggle(server) {
    server.dropdownOpen = !server.dropdownOpen;
    setServers([...servers]);
  }

  async function handleChangeNowMenuClicked(server) {
    setLoading(true);

    await ajax("/servers/changePaymentType", {
      serverID: server._id,
    });

    await getChangePaymentForm();

    setLoading(false);
  }

  return (
    <WithRole permission="super-admin.change-payment-form">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "billing-change-payment-form.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="billing-change-payment-form.hostname" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-change-payment-form.user" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-change-payment-form.change-to" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!servers && (
                  <tr>
                    <td colSpan={4}>
                      <div className="spinner-wrapper">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}

                {servers?.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {servers?.map((server, key) => (
                  <tr key={key}>
                    <td>
                      <a
                        href={`/${lang}/my-cloud/servers/${server._id}/overview`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {server.hostname}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/${lang}/clients/clients-list/${server.user._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {getFullName(server.user)}
                      </a>
                    </td>
                    <td>{server.payment.change_to}</td>
                    <td>
                      {loading && (
                        <div className="spinner-wrapper">
                          <Spinner />
                        </div>
                      )}

                      {!loading && (
                        <CustomMenu
                          isOpen={server.dropdownOpen}
                          toggle={() => handleDropDownToggle(server)}
                        >
                          <CustomMenuItem
                            onClick={() => handleChangeNowMenuClicked(server)}
                          >
                            <FormattedMessage id="billing-change-payment-form.change-now" />
                          </CustomMenuItem>
                        </CustomMenu>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
