import styles from "./ip-set.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import HeaderWithNavs from "../../../../components/cloud-layout/headers-with-navs";
import Box from "../../../../components/box";
import BasicTable from "../../../../components/basic-table";
import CustomMenu from "../../../../components/custom-menu";
import CustomMenuItem from "../../../../components/custom-menu/item";
import { useAjax, useConfirm, useLang } from "../../../../utils/hooks";
import IconButton from "../../../../components/icon-button";
import AddNewIPModal from "../../../../components/modals/add-new-ip";
import {} from "../../../../utils";
import { getFirewallPageNavItems } from "../../../../utils/firewall";
import Spinner from "../../../../components/spinner";

export default function FirewallIpSet() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const confirm = useConfirm();

  const [ipsets, setIPsets] = useState(null);
  const [aliases, setAliases] = useState([]);
  const [isAddNewIPModalOpen, setIsAddNewIPModalOpen] = useState(false);
  const [selectedIpSet, setSelectedIpSet] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});

  const [userIDToWork, setUserIDToWork] = useState(null);

  const getIPSets = useCallback(async () => {
    const [{ ipsets }, { aliases }] = await Promise.all([
      ajax("/firewall/getIPSets", {
        userIDToWork: userIDToWork?.value,
      }),
      ajax("/firewall/getAliases", {
        userIDToWork: userIDToWork?.value,
      }),
    ]);

    ipsets.forEach((ipSet) => {
      const ips = ipSet.ips.split("<br />");

      for (let i = 0; i < ips.length; i++) {
        const alias = aliases.find((a) => a._id === ips[i]);

        if (alias) {
          ips[i] = alias.name;
        }
      }

      ipSet.ips = ips.join("<br />");
    });

    setAliases(aliases);
    setIPsets(ipsets);
  }, [ajax, userIDToWork?.value]);

  useEffect(() => {
    getIPSets();
  }, [getIPSets]);

  function handleAddClicked() {
    setSelectedIpSet(false);
    setIsAddNewIPModalOpen(true);
  }

  async function handleAddNewIPModalClosed(state) {
    if (state) {
      await getIPSets();
    }

    setIsAddNewIPModalOpen(false);
  }

  function handleDropDownToggle(idx) {
    openDropdowns[idx] = !openDropdowns[idx];
    setOpenDropdowns({ ...openDropdowns });
  }

  function handleEditClicked(ipset) {
    const ipsetToEdit = { ...ipset };

    const ips = ipsetToEdit.ips.split("<br />");

    for (let i = 0; i < ips.length; i++) {
      const alias = aliases.find((a) => a.name === ips[i]);

      if (alias) {
        ips[i] = alias._id;
      }
    }

    ipsetToEdit.ips = ips.join("<br />");

    setSelectedIpSet(ipsetToEdit);
    setIsAddNewIPModalOpen(true);
  }

  async function handleRemoveClicked(ipset) {
    await confirm({
      title: intl.formatMessage({ id: "firewall-ip-set.remove.title" }),
      message: intl.formatMessage(
        { id: "firewall-ip-set.remove.content" },
        { name: ipset.name }
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/firewall/removeIPSet", {
          ipsetID: ipset.name,
          userIDToWork: userIDToWork?.value,
        });

        await getIPSets();
      },
    });
  }

  const withUserSelector = useMemo(
    () => ({
      className: styles.box,
      userIDToWork,
      setUserIDToWork,
    }),
    [userIDToWork]
  );

  const firewallPageNavItems = useMemo(
    () => getFirewallPageNavItems("ip-set", intl, lang, userIDToWork),
    [intl, lang, userIDToWork]
  );

  return (
    <HeaderWithNavs
      title={intl.formatMessage({ id: "firewall.title" })}
      withUserSelector={withUserSelector}
      navItems={firewallPageNavItems}
    >
      <Box className={styles.wrapper}>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="firewall-ip-set.name" />
              </th>
              <th>
                <FormattedMessage id="firewall-ip-set.ip" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!ipsets && (
              <tr>
                <td colSpan={3}>
                  <div className="spinner-wrapper">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}

            {ipsets?.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <FormattedMessage id="general.no-rows" />
                </td>
              </tr>
            )}

            {ipsets?.map((ipset, idx) => (
              <tr key={idx}>
                <td className={styles.name}>{ipset.comment.split("_")[0]}</td>
                <td
                  className={styles.ip}
                  dangerouslySetInnerHTML={{ __html: ipset.ips }}
                ></td>
                <td>
                  <CustomMenu
                    isOpen={openDropdowns[idx]}
                    toggle={() => handleDropDownToggle(idx)}
                  >
                    <CustomMenuItem onClick={() => handleEditClicked(ipset)}>
                      <FormattedMessage id="general.edit" />
                    </CustomMenuItem>
                    <CustomMenuItem onClick={() => handleRemoveClicked(ipset)}>
                      <FormattedMessage id="general.remove" />
                    </CustomMenuItem>
                  </CustomMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
        <div className={styles.buttonWrapper}>
          <IconButton color="light-purple" onClick={handleAddClicked}>
            <FormattedMessage id="firewall-ip-set.add" />
          </IconButton>
        </div>
      </Box>

      <AddNewIPModal
        isOpen={isAddNewIPModalOpen}
        onClose={handleAddNewIPModalClosed}
        edit={selectedIpSet}
        ipsets={ipsets}
        userIDToWork={userIDToWork}
      />
    </HeaderWithNavs>
  );
}
