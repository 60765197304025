import styles from "./open-new-ticket.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import Box from "../../../components/box";
import LayoutMainAction from "../../../components/layout-main-action";
import { useAjax, useLang, useServers, useUser } from "../../../utils/hooks";
import CustomText from "../../../components/custom-text";
import ItemWithHeader from "../../../components/item-with-header";
import CustomReactSelect from "../../../components/custom-react-select";
import { stripTags } from "../../../utils";
import IconButton from "../../../components/icon-button";
import { WithRole } from "../../../components/with-role";
import { SUPER_ADMIN, USER, WHITELABEL } from "../../../utils/user";
import Checkbox from "../../../components/checkbox";
import TinyEditor from "../../../components/tiny-editor";

export const priorities = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];

export default function SupportOpenNewTicket() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const router = useHistory();
  const user = useUser();
  const servers = useServers();

  const [relatedServices, setRelatedServices] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const [subject, setSubject] = useState("");
  const [relatedService, setRelatedService] = useState(null);
  const [content, setContent] = useState("");
  const [department, setDepartment] = useState(null);
  const [priority, setPriority] = useState(priorities[0]);

  const [supporters, setSupporters] = useState([]);
  const [users, setUsers] = useState([]);
  const [userToAttach, setUserToAttach] = useState(false);
  const [assignToSupporter, setAssignToSupporter] = useState(false);
  const [hasReminder, setHasReminder] = useState(false);
  const [reminder, setReminder] = useState(null);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchDepartments = useCallback(async () => {
    const data = await ajax(`/tickets/getDepartments`);

    const departments = data.departments.map((department, idx) => ({
      label: department.name,
      value: department.name,
      key: idx,
      supporters: department.supporters,
    }));

    setDepartments(departments);
    setDepartment(departments[0]);
  }, [ajax]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  useEffect(() => {
    if (!servers || relatedServices) {
      return;
    }

    const relatedServicesToSet = [
      {
        label: intl.formatMessage({ id: "open-new-ticket.general" }),
        value: null,
      },
      ...servers.map((server) => ({
        value: server._id,
        label: server.hostname,
      })),
    ];

    setRelatedServices(relatedServicesToSet);
    setRelatedService(relatedServicesToSet[0]);
  }, [intl, servers, relatedServices]);

  const fetchAllUsers = useCallback(async () => {
    if (!user.role || user.role === USER) {
      return;
    }

    const data = await ajax(`/users/getAllUsers`, {
      filter: { email: 1, firstName: 1, lastName: 1, role: 1 },
      withMe: true,
    });

    const users = data.users.map((u) => ({
      label: `${u.email} | ${u.firstName || ""} ${u.lastName || ""}`,
      value: u._id,
    }));

    setUsers(users);
  }, [ajax, user.role]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(() => {
    if (department && department.supporters) {
      setSupporters([
        {
          label: intl.formatMessage({ id: "general.none" }),
          value: null,
          key: -1,
        },
        ...department.supporters.map((supporter, idx) => ({
          label: `${supporter.email} - ${supporter.firstName} ${supporter.lastName}`,
          value: supporter._id,
          key: idx,
        })),
      ]);
    }
  }, [department, intl]);

  function getLayoutButton() {
    return [
      {
        title: intl.formatMessage({ id: "open-new-ticket.my-tickets" }),
        href: "/[lang]/support/my-tickets",
        as: `/${lang}/support/my-tickets`,
        color: "ghost",
      },
    ];
  }

  async function handleSubmitTicketClicked() {
    const _subject = subject ? subject.trim() : false;
    const _content = content ? content.trim() : false;

    setError(false);

    if (!_subject) {
      return setError(intl.formatMessage({ id: "open-new-ticket.no-subject" }));
    } else if (!content) {
      return setError(intl.formatMessage({ id: "open-new-ticket.no-content" }));
    }

    setLoading(true);
    const data = await ajax("/tickets/createTicket", {
      department: department.value,
      subject: _subject,
      content: _content,
      relatedService,
      priority: priority.value,
      userIDToAttach: userToAttach.value,
      assignToUser: assignToSupporter.value,
      reminder: hasReminder ? reminder : null,
    });
    setLoading(false);

    if (data.result === "success") {
      router.push(`/${lang}/support/my-tickets/${data.ticket._id}`);
    } else {
      setError(intl.formatMessage({ id: "general.general-error" }));
    }
  }

  return (
    <WithRole permission="tickets.submit">
      <LayoutMainAction
        title={intl.formatMessage({ id: "open-new-ticket.main-title" })}
        buttons={getLayoutButton()}
      >
        <Box>
          <div className={styles.wrapper}>
            <div className={styles.row}>
              <div className={styles.col}>
                <ItemWithHeader
                  header={intl.formatMessage({ id: "open-new-ticket.subject" })}
                >
                  <CustomText
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </ItemWithHeader>
              </div>
              <div className={styles.col}>
                <ItemWithHeader
                  header={intl.formatMessage({
                    id: "open-new-ticket.relate-service",
                  })}
                >
                  <CustomReactSelect
                    instanceId="open-new-ticket-relate-service"
                    options={relatedServices}
                    value={relatedService}
                    onChange={(item) => setRelatedService(item)}
                  />
                </ItemWithHeader>
              </div>
            </div>

            <div className={styles.row}>
              <div className={`${styles.col} ${styles.noMargin}`}>
                {!editorLoaded && (
                  <div className={styles.spinner}>
                    <Spinner />
                  </div>
                )}

                <TinyEditor
                  onInit={() => setEditorLoaded(true)}
                  onChange={(content) => setContent(content)}
                  height={300}
                />

                {editorLoaded && stripTags(content).length <= 5 && (
                  <div className={styles.supportedLanguages}>
                    <FormattedMessage id="open-new-ticket.supported-languages" />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <ItemWithHeader
                  header={intl.formatMessage({
                    id: "open-new-ticket.department",
                  })}
                >
                  <CustomReactSelect
                    instanceId="open-new-ticket-department"
                    options={departments}
                    value={department}
                    onChange={(item) => setDepartment(item)}
                  />
                </ItemWithHeader>
              </div>
              <div className={styles.col}>
                <ItemWithHeader
                  header={intl.formatMessage({
                    id: "open-new-ticket.priority",
                  })}
                >
                  <CustomReactSelect
                    instanceId="open-new-ticket-priority"
                    options={priorities}
                    value={priority}
                    onChange={(item) => setPriority(item)}
                  />
                </ItemWithHeader>
              </div>
            </div>

            {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
              <>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <ItemWithHeader
                      header={intl.formatMessage({
                        id: "open-new-ticket.user-to-create",
                      })}
                    >
                      <CustomReactSelect
                        instanceId="open-new-ticket-user-to-create"
                        options={users}
                        value={userToAttach}
                        onChange={(item) => setUserToAttach(item)}
                      />
                    </ItemWithHeader>
                  </div>
                  <div className={styles.col}>
                    <ItemWithHeader
                      header={intl.formatMessage({
                        id: "open-new-ticket.assign-to-user",
                      })}
                    >
                      <CustomReactSelect
                        instanceId="open-new-ticket-assign-to-user"
                        options={supporters}
                        value={assignToSupporter}
                        onChange={(item) => setAssignToSupporter(item)}
                      />
                    </ItemWithHeader>
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.col}></div>
                  <div className={styles.col}>
                    {assignToSupporter.value && (
                      <div className={styles.reminderWrapper}>
                        <Checkbox
                          checked={hasReminder}
                          onChange={(e) => setHasReminder(e.target.checked)}
                          label="open-new-ticket.reminder"
                        />
                        <DatePicker
                          wrapperClassName="select"
                          disabled={!hasReminder}
                          selected={reminder}
                          onChange={(date) => setReminder(date)}
                          showTimeSelect
                          dateFormat="dd/MM/yyyy HH:mm"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <hr />

            <div>
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={handleSubmitTicketClicked}
              >
                <FormattedMessage id="open-new-ticket.open-ticket" />
              </IconButton>

              {error && <div className={`error ${styles.error}`}>{error}</div>}
            </div>
          </div>
        </Box>
      </LayoutMainAction>
    </WithRole>
  );
}
