import styles from "./tags-groups.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import CustomText from "../custom-text";
import { useAjax, usePrompt, useUser, useWLDVPS } from "../../utils/hooks";
import CustomReactSelect from "../custom-react-select";
import { getFullName, SUPER_ADMIN, WHITELABEL } from "../../utils/user";
import { useMemo } from "react";

function TagsGroups({
  tag,
  setTag,
  group,
  setGroup,
  attachServerToClient,
  setAttachServerToClient,
  selectedFreeDays,
  setSelectedFreeDays,
}) {
  const intl = useIntl();
  const ajax = useAjax();
  const prompt = usePrompt();
  const user = useUser();
  const wldvps = useWLDVPS();

  const [groups, setGroups] = useState([]);
  const [allUsers, setAllUsers] = useState();

  const couponOptions = useMemo(() => {
    const arr = [
      { label: 0, value: 0 },
      { label: 7, value: 7 },
      { label: 14, value: 14 },
      { label: 30, value: 30 },
    ];

    if (!wldvps) {
      arr.push(
        ...[
          { label: 60, value: 60 },
          { label: 90, value: 90 },
        ]
      );
    }

    return arr;
  }, [wldvps]);

  useEffect(() => {
    setSelectedFreeDays(couponOptions[0]);
  }, [setSelectedFreeDays, couponOptions]);

  const getGroups = useCallback(async () => {
    const data = await ajax("/groups/getAll");

    if (data.result === "success") {
      const groups = [
        {
          label: intl.formatMessage({ id: "tags-groups.create-new-group" }),
          value: -1,
        },
        ...data.groups.map((group) => ({
          label: group.name,
          value: group._id,
        })),
      ];

      setGroups(groups);

      return groups;
    }
  }, [ajax, intl]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const getAllUsers = useCallback(async () => {
    const data = await ajax("/users/getAllUsers", {
      filter: { email: 1, firstName: 1, lastName: 1, clientNumber: 1 },
    });

    if (data.result === "success") {
      const users = [
        { label: intl.formatMessage({ id: "general.none" }), value: null },
        ...data.users.map((user) => ({
          label: `${user.email} | ${getFullName(user)} | ${user.clientNumber}`,
          value: user._id,
        })),
      ];

      setAllUsers(users);
      setAttachServerToClient(users[0]);
    }
  }, [ajax, intl, setAttachServerToClient]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && allUsers) {
      const user = allUsers.find(
        (user) => user.value === "5ebcfd5949b7e530ddd58262"
      );

      setAttachServerToClient(user);
    }
  }, [allUsers, setAttachServerToClient]);

  async function handleGroupChanged(item) {
    if (item.value === -1) {
      let name = await prompt({
        title: intl.formatMessage({ id: "groups.add.title" }),
        message: intl.formatMessage({ id: "groups.add.content" }),
      });

      name = name ? name.trim() : false;

      if (!name) {
        return setGroup(null);
      }

      await ajax("/groups/create", { name });

      const groups = await getGroups();

      return setGroup(groups.find((group) => group.label === name));
    }

    setGroup(item);
  }

  return (
    <div className={styles.tagsGroupsWrapper}>
      <div className={styles.box}>
        <div className={styles.title}>
          <FormattedMessage id="tags-groups.choose-tag-name" />
        </div>
        <div className={styles.container}>
          <div className={styles.text}>
            <FormattedMessage id="tags-groups.choose-tag-name.text" />
          </div>
          <div>
            <CustomText
              placeholder={intl.formatMessage({
                id: "tags-groups.choose-tag-name.type-tags",
              })}
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={styles.box}>
        <div className={styles.title}>
          <FormattedMessage id="tags-groups.attach-to-group" />
        </div>
        <div className={styles.container}>
          {/* <div className={styles.text}>
            <FormattedMessage id="tags-groups.attach-to-group.text" />
          </div> */}
          <div>
            <CustomReactSelect
              instanceId="attach-to-group"
              options={groups}
              value={group}
              onChange={handleGroupChanged}
            />
          </div>
        </div>
      </div>

      {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
        <div className={styles.box}>
          <div className={styles.title}>
            <FormattedMessage id="tags-groups.attach-to-client" />
          </div>
          <div className={styles.container}>
            <div>
              <span className={styles.text}>
                <FormattedMessage id="tags-groups.attach-to-client-description" />
              </span>
              <CustomReactSelect
                instanceId="attach-to-client"
                options={allUsers}
                value={attachServerToClient}
                onChange={(item) => setAttachServerToClient(item)}
              />
            </div>

            {attachServerToClient?.value &&
              (!wldvps || user.allowed_to_give_free_days) && (
                <>
                  <br />
                  <div>
                    <span className={styles.text}>
                      <FormattedMessage id="tags-groups.attach-to-client-coupon" />
                    </span>
                    <CustomReactSelect
                      instanceId="attach-to-client-coupon"
                      options={couponOptions}
                      value={selectedFreeDays}
                      onChange={(item) => setSelectedFreeDays(item)}
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      )}
    </div>
  );
}

TagsGroups.propTypes = {
  tag: PropTypes.string,
  setTag: PropTypes.func,
  group: PropTypes.object,
  setGroup: PropTypes.func,
  attachServerToClient: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setAttachServerToClient: PropTypes.func,
  selectedFreeDays: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  setSelectedFreeDays: PropTypes.func,
};

export default TagsGroups;
