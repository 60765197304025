import styles from "./blacklist.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import CustomText from "../../../components/custom-text";
import IconButton from "../../../components/icon-button";
import { useAjax, useAlert } from "../../../utils/hooks";
import {} from "../../../utils";

export default function ManagementBlacklist() {
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();

  const [ips, setIPS] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchBlacklist = useCallback(async () => {
    const data = await ajax(`/admin/getBlacklist`);

    if (data.result === "error") {
      return;
    }

    setIPS(data.ips);
  }, [ajax]);

  useEffect(() => {
    fetchBlacklist();
  }, [fetchBlacklist]);

  async function handleSaveClicked() {
    setLoading(true);
    await ajax(`/admin/saveBlacklist`, { ips });
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "management-blacklist.save.title" }),
      intl.formatMessage({ id: "management-blacklist.save.content" })
    );
  }

  return (
    <WithRole permission="super-admin.blacklist">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-blacklist.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box>
            <FormattedMessage id="management-blacklist.description" />

            <CustomText
              type="textarea"
              value={ips}
              onChange={(e) => setIPS(e.target.value)}
            />

            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleSaveClicked}
            >
              <FormattedMessage id="general.save" />
            </IconButton>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
