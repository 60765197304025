import styles from "./with-servers.module.scss";

import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ReactComponent as LongArrowLeft } from "../svgs/long-arrow-left.svg";
import IconButton from "../icon-button";
import ServersList from "./servers-list";
import {
  useLang,
  useAjax,
  useAlert,
  usePrompt,
  useConfirm,
  useUser,
  useRoles,
  useServers,
} from "../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import WithServersGroups from "./with-servers-groups";
import CustomMenuItem from "../custom-menu/item";
import { updateIsWorking } from "../../store/servers";
import { setServersFetchingShowOnly } from "../../store/settings";
import UserSelectorModal from "../modals/user-selector";
import { getServerUrl } from "../../utils/wldvps";

export default function WithServers() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const alert = useAlert();
  const prompt = usePrompt();
  const confirm = useConfirm();
  const user = useUser();
  const dispatch = useDispatch();
  const { isAllowed } = useRoles();
  const servers = useServers();

  const { serversFetching } = useSelector((state) => ({
    serversFetching: state.settings.serversFetching,
  }));

  const [selectedServersIds, setSelectedServersIds] = useState({});
  const [selectedServers, setSelectedServers] = useState([]);
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);
  const [isShowOnlyDropdownOpen, setIsShowOnlyDropdownOpen] = useState(false);
  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);

  const [backToClient, setBackToClient] = useState(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const backToClient = urlSearchParams.get("backToClient");

    if (backToClient) {
      setBackToClient(backToClient);
    }
  }, []);

  useEffect(() => {
    setSelectedServers(
      servers.filter((server) => selectedServersIds[server._id])
    );
  }, [selectedServersIds, servers]);

  function actionDropdownToggle() {
    setIsActionDropdownOpen(!isActionDropdownOpen);
  }

  function showOnlyDropdownToggle() {
    setIsShowOnlyDropdownOpen(!isShowOnlyDropdownOpen);
  }

  async function reinstallServers() {
    const serversIds = Object.keys(selectedServersIds)
      .filter((k) => selectedServersIds[k])
      .map((k) => k);

    let lastUserID;
    for (let i = 0; i < serversIds.length; i++) {
      const server = servers.find((server) => server._id === serversIds[i]);

      if (lastUserID && server.user_id !== lastUserID) {
        return await alert(
          intl.formatMessage({ id: "with-servers.different-users.title" }),
          intl.formatMessage({ id: "with-servers.different-users.content" })
        );
      }

      lastUserID = server.user_id;
    }

    const val = await prompt({
      title: intl.formatMessage({
        id: `with-servers.filters.reinstall-servers`,
      }),
      message: intl.formatMessage(
        { id: `with-servers.filters.reinstall-servers-message` },
        {
          total: serversIds.length,
          hostname: `REINSTALL_${serversIds.length}_SERVERS`,
          b: (arr) => `<b>${arr[0]}</b>`,
        }
      ),
      button1: {
        text: intl.formatMessage({ id: "general.was-mistake" }),
        color: "white",
      },
      button2: {
        text: intl.formatMessage({ id: "general.yes-sure" }),
        color: "white",
      },
    });

    if (val === `REINSTALL_${serversIds.length}_SERVERS`) {
      await ajax("/servers/reinstall", { serversIds });
    } else if (val) {
      await alert(
        intl.formatMessage({ id: "with-servers.filters.reinstall-servers" }),
        intl.formatMessage({ id: "general.wrong-prompt-value" })
      );
    }
  }

  async function destroyServers() {
    const serversIds = Object.keys(selectedServersIds)
      .filter((k) => selectedServersIds[k])
      .map((k) => k);

    let lastUserID;
    for (let i = 0; i < serversIds.length; i++) {
      const server = servers.find((server) => server._id === serversIds[i]);

      if (lastUserID && server.user_id !== lastUserID) {
        return await alert(
          intl.formatMessage({ id: "with-servers.different-users.title" }),
          intl.formatMessage({ id: "with-servers.different-users.content" })
        );
      }

      lastUserID = server.user_id;
    }

    let hostname = `DESTROY_${serversIds.length}_SERVERS`;
    if (serversIds.length === 1) {
      const server = servers.find((server) => server._id === serversIds[0]);

      hostname = server.hostname;
    }

    const val = await prompt({
      title: intl.formatMessage({ id: `with-servers.filters.destory-servers` }),
      message: intl.formatMessage(
        { id: `with-servers.filters.destory-servers-message` },
        {
          total: serversIds.length,
          hostname,
          b: (arr) => `<b>${arr[0]}</b>`,
        }
      ),
      button1: {
        text: intl.formatMessage({ id: "general.was-mistake" }),
        color: "white",
      },
      button2: {
        text: intl.formatMessage({ id: "general.yes-sure" }),
        color: "white",
      },
    });

    if (val === hostname) {
      await ajax("/servers/destroy", { serversIds });
    } else if (val) {
      await alert(
        intl.formatMessage({ id: "with-servers.filters.destory-servers" }),
        intl.formatMessage({ id: "general.wrong-prompt-value" })
      );
    }
  }

  async function handleServerAction(actionType, isSoft = false) {
    const serversIds = Object.keys(selectedServersIds)
      .filter((k) => selectedServersIds[k])
      .map((k) => k);

    let lastUserID;
    for (let i = 0; i < serversIds.length; i++) {
      const server = servers.find((server) => server._id === serversIds[i]);

      if (lastUserID && server.user_id !== lastUserID) {
        return await alert(
          intl.formatMessage({ id: "with-servers.different-users.title" }),
          intl.formatMessage({ id: "with-servers.different-users.content" })
        );
      }

      lastUserID = server.user_id;
    }

    let actionTitle;

    switch (actionType) {
      case "start":
        actionTitle = "with-servers.filters.start-servers";
        break;
      case "stop":
        if (isSoft) {
          actionTitle = "with-servers.filters.soft-shutdown-servers";
        } else {
          actionTitle = "with-servers.filters.hard-shutdown-servers";
        }
        break;
      case "reset":
        if (isSoft) {
          actionTitle = "with-servers.filters.soft-reboot-servers";
        } else {
          actionTitle = "with-servers.filters.hard-reboot-servers";
        }
        break;
    }

    const state = await confirm({
      title: intl.formatMessage({ id: actionTitle }),
      message: intl.formatMessage(
        { id: "with-servers.filters.many-servers-action" },
        { total: serversIds.length }
      ),
    });

    if (state !== "button2") {
      return;
    }

    dispatch(updateIsWorking(serversIds, true));

    await ajax("/servers/action", {
      serversIds,
      actionType,
      isSoft,
    });
  }

  async function changeServersOwner() {
    const serversIds = Object.keys(selectedServersIds)
      .filter((k) => selectedServersIds[k])
      .map((k) => k);

    let lastUserID, lastUserEmail;
    for (let i = 0; i < serversIds.length; i++) {
      const server = servers.find((server) => server._id === serversIds[i]);

      if (lastUserID && server.user_id !== lastUserID) {
        return await alert(
          intl.formatMessage({ id: "with-servers.different-users.title" }),
          intl.formatMessage({ id: "with-servers.different-users.content" })
        );
      }

      lastUserID = server.user_id;
      lastUserEmail = server.userEmail;
    }

    setIsUserSelectorModalOpen(lastUserEmail);
  }

  async function handleUserSelectorModalClosed(state) {
    if (state) {
      const serversIds = Object.keys(selectedServersIds)
        .filter((k) => selectedServersIds[k])
        .map((k) => k);

      await ajax("/servers/changeServerOwner", {
        serversIDs: serversIds,
        userID: state.value,
      });
    }

    setIsUserSelectorModalOpen(false);
  }

  function handleExportServerClicked() {
    const serversIds = Object.keys(selectedServersIds)
      .filter((k) => selectedServersIds[k])
      .map((k) => k);

    confirm({
      title: intl.formatMessage({ id: "with-servers.filters.export-servers" }),
      message: intl.formatMessage(
        { id: "with-servers.filters.export-servers-content" },
        { total: serversIds.length }
      ),
      button1: {
        text: intl.formatMessage({ id: "general.cancel" }),
        color: "white",
      },
      button2: {
        text: intl.formatMessage({ id: "general.yes" }),
        color: "white",
        href: `${getServerUrl()}/admin/export-servers?userid=${
          ["super-admin", "whitelabel"].includes(user.role) ? "" : user._id
        }&serversids=${serversIds.join(",")}`,
        target: "_blank",
      },
    });
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.actions}>
        <div>
          {backToClient && (
            <div className={styles.backWrapper}>
              <Link
                href="/[lang]/clients/clients-list/[id]"
                to={`/${lang}/clients/clients-list/${backToClient}`}
              >
                <LongArrowLeft />{" "}
                <FormattedMessage id="with-servers.back-to-client" />
              </Link>
            </div>
          )}
          <div className={styles.bold}>
            <FormattedMessage id="with-servers.title" />
          </div>
          <div className={styles.normal}>
            <FormattedMessage id="with-servers.desc" />
          </div>
        </div>
        <div className={styles.buttons}>
          {isAllowed("servers.create") && (
            <IconButton
              color="green"
              href={`/[lang]/my-cloud/create-new-server`}
              as={`/${lang}/my-cloud/create-new-server`}
            >
              <FormattedMessage id="with-servers.create-new-server" />
            </IconButton>
          )}
        </div>
      </div>

      <div className={styles.hr}>
        <hr />
      </div>

      {isAllowed("servers.manage-groups") && <WithServersGroups />}

      <div className={styles.showOnly}>
        <div className={styles.actionButton}>
          <ButtonDropdown
            className={styles.purple}
            isOpen={isActionDropdownOpen}
            toggle={actionDropdownToggle}
          >
            <DropdownToggle caret>
              <FormattedMessage id="with-servers.filters.select-action" />
            </DropdownToggle>
            <DropdownMenu right>
              {selectedServers.length === 0 && (
                <DropdownItem>
                  <FormattedMessage id="with-servers.filters.no-actions" />
                </DropdownItem>
              )}

              {selectedServers.length > 0 && (
                <>
                  <DropdownItem onClick={handleExportServerClicked}>
                    <FormattedMessage id="with-servers.filters.export-servers" />
                  </DropdownItem>

                  {selectedServers.every(
                    (server) => !server.running && server.status === "Active"
                  ) && (
                    <DropdownItem onClick={() => handleServerAction("start")}>
                      <FormattedMessage id="with-servers.filters.start-servers" />
                    </DropdownItem>
                  )}

                  {isAllowed("servers.start-stop-reset") &&
                    selectedServers.every(
                      (server) => server.running && server.status === "Active"
                    ) && (
                      <>
                        {selectedServers.every((server) => server.ping) && (
                          <DropdownItem
                            onClick={() => handleServerAction("stop", true)}
                          >
                            <FormattedMessage id="with-servers.filters.soft-shutdown-servers" />
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={() => handleServerAction("stop")}
                        >
                          <FormattedMessage id="with-servers.filters.hard-shutdown-servers" />
                        </DropdownItem>

                        {selectedServers.every((server) => server.ping) && (
                          <DropdownItem
                            onClick={() => handleServerAction("reset", true)}
                          >
                            <FormattedMessage id="with-servers.filters.soft-reboot-servers" />
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={() => handleServerAction("reset")}
                        >
                          <FormattedMessage id="with-servers.filters.hard-reboot-servers" />
                        </DropdownItem>
                      </>
                    )}

                  {isAllowed("admin.servers.change-owner") && (
                    <DropdownItem onClick={changeServersOwner}>
                      <FormattedMessage id="with-servers.filters.change-owner" />
                    </DropdownItem>
                  )}

                  {isAllowed("servers.reinstall-destroy") &&
                    selectedServers.every(
                      (server) => server.status === "Active"
                    ) && (
                      <>
                        <DropdownItem onClick={reinstallServers}>
                          <FormattedMessage id="with-servers.filters.reinstall-servers" />
                        </DropdownItem>

                        <DropdownItem divider />

                        <CustomMenuItem color="red" onClick={destroyServers}>
                          <FormattedMessage id="with-servers.filters.destory-servers" />
                        </CustomMenuItem>
                      </>
                    )}
                </>
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <div>
          <FormattedMessage id="with-servers.filters.show-only" />
          <ButtonDropdown
            className={styles.ghost}
            isOpen={isShowOnlyDropdownOpen}
            toggle={showOnlyDropdownToggle}
          >
            <DropdownToggle caret>
              <FormattedMessage
                id={`with-servers.filters.${serversFetching.showOnly}`}
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() =>
                  dispatch(setServersFetchingShowOnly("all-servers"))
                }
              >
                <FormattedMessage id="with-servers.filters.all-servers" />
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  dispatch(setServersFetchingShowOnly("active-servers"))
                }
              >
                <FormattedMessage id="with-servers.filters.active-servers" />
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  dispatch(setServersFetchingShowOnly("active-running-servers"))
                }
              >
                <FormattedMessage id="with-servers.filters.active-running-servers" />
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  dispatch(setServersFetchingShowOnly("active-stopped-servers"))
                }
              >
                <FormattedMessage id="with-servers.filters.active-stopped-servers" />
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  dispatch(setServersFetchingShowOnly("terminated-servers"))
                }
              >
                <FormattedMessage id="with-servers.filters.terminated-servers" />
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>

        <div className={styles.totalServers}>
          <FormattedMessage
            id="with-servers.filters.total-servers"
            values={{ number: serversFetching.totalServers }}
          />
        </div>
      </div>

      <ServersList
        selectedServersIds={selectedServersIds}
        setSelectedServersIds={setSelectedServersIds}
      />

      <UserSelectorModal
        title={intl.formatMessage({
          id: "server-admin-options.change-server-owner-title",
        })}
        content={intl.formatMessage(
          {
            id: "server-admin-options.change-server-owner-content",
          },
          { oldEmail: isUserSelectorModalOpen, b: (arr) => `<b>${arr[0]}</b>` }
        )}
        isOpen={isUserSelectorModalOpen}
        onClose={handleUserSelectorModalClosed}
      />
    </div>
  );
}
