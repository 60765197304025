import styles from "./leads-report.module.scss";

import React, { useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import ItemWithHeader from "../../../components/item-with-header";
import CustomReactSelect from "../../../components/custom-react-select";
import { addMonths, format } from "date-fns";
import IconButton from "../../../components/icon-button";
import {} from "../../../utils";
import { getServerUrl } from "../../../utils/wldvps";

const invoiceMonths = [];
for (let i = -12; i <= 0; i++) {
  const dt = addMonths(new Date(), i);
  invoiceMonths.push({ label: format(dt, "M/y"), value: dt, key: i });
}

export default function BillingLeadsReport() {
  const intl = useIntl();

  const [month, setMonth] = useState(invoiceMonths[invoiceMonths.length - 1]);

  return (
    <WithRole permission="super-admin.leads-report">
      <LayoutMainAction
        title={intl.formatMessage({ id: "billing-leads-report.main-title" })}
      >
        <div className={styles.wrapper}>
          <Box>
            <ItemWithHeader
              header={intl.formatMessage({
                id: "billing-leads-report.choose-month",
              })}
            >
              <div className={styles.items}>
                <CustomReactSelect
                  instanceId="billing-leads-report-choose-month"
                  options={invoiceMonths}
                  value={month}
                  onChange={(option) => setMonth(option)}
                />

                <IconButton
                  href={`${getServerUrl()}/billing/generateLeadsReport?month=${
                    month.value
                  }`}
                  target="_blank"
                  color="light-purple"
                >
                  <FormattedMessage id="billing-leads-report.generate-report" />
                </IconButton>
              </div>
            </ItemWithHeader>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
