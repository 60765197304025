import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";

function Spinner({ width = 22, height = 22 }) {
  return (
    <Loader
      className={styles.wrapper}
      type="Oval"
      color="#67DA5D"
      width={width}
      height={height}
    />
  );
}

Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Spinner;
