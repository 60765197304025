import React from "react";
import { Switch, Route } from "react-router-dom";
import ServerSettingsLayout from "../../../../../components/server-settings/layout";
import ServerBackups from "./backups";
import ServerConsole from "./console";
import ServerNotes from "./notes";
import ServerOverview from "./overview";
import ServerPayment from "./payment";
import ServerLogs from "./server-logs";
import MyCloudServerSettingsRouting from "./server-settings";
import ServerSnapshots from "./snapshots";
import ServerTasks from "./tasks";
import ServerUsageGraphs from "./usage-graphs";

export default function MyCloudServerRouting() {
  return (
    <Switch>
      <Route
        exact
        path={[
          "/:lang/my-cloud/servers/:id",
          "/:lang/my-cloud/servers/:id/overview",
        ]}
      >
        <ServerOverview />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/usage-graphs">
        <ServerUsageGraphs />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/snapshots">
        <ServerSnapshots />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/backups">
        <ServerBackups />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/tasks">
        <ServerTasks />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/server-logs">
        <ServerLogs />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/console">
        <ServerConsole />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/payment">
        <ServerPayment />
      </Route>
      <Route exact path="/:lang/my-cloud/servers/:id/notes">
        <ServerNotes />
      </Route>

      <Route path="/:lang/my-cloud/servers/:id/server-settings">
        <ServerSettingsLayout>
          <MyCloudServerSettingsRouting />
        </ServerSettingsLayout>
      </Route>
    </Switch>
  );
}
