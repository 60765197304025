import styles from "./products.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  useRoles,
  useUser,
} from "../../../utils/hooks";
import Box from "../../../components/box";
import BasicTable from "../../../components/basic-table";
import AddProductModal from "../../../components/modals/add-product";
import { currencySymbols } from "../../../utils/billing";
import Spinner from "../../../components/spinner";
import { ReactComponent as EditSvg } from "../../../components/svgs/edit-purple.svg";
import { ReactComponent as TrashSvg } from "../../../components/svgs/trash-purple.svg";
import Checkbox from "../../../components/checkbox";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {} from "../../../utils";
import { SUPER_ADMIN } from "../../../utils/user";
import { getAllWLDVPS } from "../../../utils/wldvps";

export default function ManagementProducts() {
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();
  const confirm = useConfirm();
  const lang = useLang();
  const user = useUser();
  const { isAllowed } = useRoles();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);

  const [isWhitelabelDropdownOpen, setIsWhitelabelDropdownOpen] = useState(
    false
  );
  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
  const [isShowOnlyDropdownOpen, setIsShowOnlyDropdownOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [showOnly, setShowOnly] = useState(null);

  const [whitelabel, setWhitelabel] = useState(null);
  const [whitelabels, setWhitelabels] = useState([]);

  const [loading, setLoading] = useState(false);

  const getProducts = useCallback(async () => {
    setLoading(true);
    const data = await ajax("/products/getAll", {
      withUsers: true,
      asWhitelabel: whitelabel,
    });
    setLoading(false);

    if (data.result === "success") {
      setProducts(data.products);
    }
  }, [ajax, whitelabel]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (products) {
      setFilteredProducts(
        products.filter(
          (product) =>
            (!filter ||
              product.name.toLowerCase().includes(filter.toLowerCase())) &&
            (!showOnly || product.type === showOnly)
        )
      );
    }
  }, [filter, products, showOnly]);

  useEffect(() => {
    let whitelabels;

    if (user.role === SUPER_ADMIN) {
      whitelabels = getAllWLDVPS(user.whitelabelSettings);

      setWhitelabels(whitelabels);
    }
  }, [user.role, user.whitelabelSettings]);

  function handleAddProductModalOpen() {
    setSelectedProduct(null);
    setIsAddProductModalOpen(true);
  }

  async function handleAddProductModalClosed(state) {
    if (state) {
      await getProducts();
    }

    setIsAddProductModalOpen(false);
  }

  function handleEditClicked(product) {
    setSelectedProduct(product);
    setIsAddProductModalOpen(true);
  }

  async function handleRemoveClicked(product) {
    const state = await confirm({
      title: intl.formatMessage({ id: "management-products.remove.title" }),
      message: intl.formatMessage(
        { id: "management-products.remove.content" },
        { name: product.name }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/products/remove", { _id: product._id });

    await getProducts();

    setSelectedProducts({});
  }

  async function handleRemoveManyClicked() {
    const selectedProductsArr = Object.keys(selectedProducts);

    if (selectedProductsArr.length === 0) {
      return;
    }

    const state = await confirm({
      title: intl.formatMessage({
        id: "management-products.remove-many.title",
      }),
      message: intl.formatMessage(
        { id: "management-products.remove-many.content" },
        { count: selectedProductsArr.length }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/products/remove", { _id: selectedProductsArr });

    await getProducts();

    setSelectedProducts({});
  }

  function handleSortProducts(a, b) {
    return (
      (a.type > b.type) - (a.type < b.type) ||
      (a.name > b.name) - (a.name < b.name)
    );
  }

  function handleProductCheckboxChanged(product) {
    if (!product) {
      const selectedProducts = isAllSelected
        ? {}
        : filteredProducts.reduce(
            (obj, item) => ({ ...obj, [item._id]: true }),
            {}
          );
      return setSelectedProducts({ ...selectedProducts });
    }

    if (selectedProducts[product._id]) {
      delete selectedProducts[product._id];
    } else {
      selectedProducts[product._id] = true;
    }

    setSelectedProducts({ ...selectedProducts });
  }

  async function handleAttachedUsersClicked(e, users) {
    e.preventDefault();

    const keys = Object.keys(users);

    if (keys.length > 0) {
      await alert(
        intl.formatMessage({ id: "management-products.attached-to" }),
        keys.map((key) => (
          <div key={key}>
            <a
              href={`/${lang}/clients/clients-list/${key}`}
              target="_blank"
              rel="noreferrer"
            >
              {users[key]}
            </a>
          </div>
        ))
      );
    }
  }

  const buttons = useMemo(
    () =>
      isAllowed("admin.products.add-edit")
        ? [
            {
              title: intl.formatMessage({
                id: "management-products.add-new-product",
              }),
              onClick: handleAddProductModalOpen,
            },
          ]
        : [],
    [intl, isAllowed]
  );

  const isAllSelected = useMemo(() => {
    if (!filteredProducts) {
      return false;
    }

    return (
      Object.keys(filteredProducts).length ===
      Object.keys(selectedProducts).length
    );
  }, [filteredProducts, selectedProducts]);

  const productsTypes = useMemo(() => {
    if (!products) {
      return [];
    }

    return [...new Set(products.map((product) => product.type))];
  }, [products]);

  return (
    <WithRole permission="admin.products.manage">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-products.main-title",
        })}
        buttons={buttons}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            {isAllowed("admin.products.add-edit") && (
              <div>
                <ButtonDropdown
                  className={styles.purple}
                  isOpen={isActionsDropdownOpen}
                  toggle={() =>
                    setIsActionsDropdownOpen(!isActionsDropdownOpen)
                  }
                >
                  <DropdownToggle caret>
                    <FormattedMessage id="general.actions" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={handleRemoveManyClicked}>
                      <FormattedMessage id="general.delete" />
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            )}

            <div>
              <FormattedMessage id="clients-list.show-only" />
              <ButtonDropdown
                className={styles.ghost}
                isOpen={isShowOnlyDropdownOpen}
                toggle={() =>
                  setIsShowOnlyDropdownOpen(!isShowOnlyDropdownOpen)
                }
              >
                <DropdownToggle caret>
                  {showOnly || <FormattedMessage id="general.all" />}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => setShowOnly(null)}>
                    <FormattedMessage id="general.all" />
                  </DropdownItem>
                  {productsTypes.map((type, key) => (
                    <DropdownItem key={key} onClick={() => setShowOnly(type)}>
                      {type}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </div>

            {user.role === SUPER_ADMIN && (
              <div>
                <FormattedMessage id="support-my-tickets.whitelabel" />
                <ButtonDropdown
                  className={styles.ghost}
                  isOpen={isWhitelabelDropdownOpen}
                  toggle={() =>
                    setIsWhitelabelDropdownOpen(!isWhitelabelDropdownOpen)
                  }
                >
                  <DropdownToggle caret>
                    {whitelabel || "DreamVPS"}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {whitelabels.map((wl, key) => (
                      <DropdownItem key={key} onClick={() => setWhitelabel(wl)}>
                        {wl || "DreamVPS"}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            )}

            <div>
              <input
                className={styles.filterInput}
                placeholder={intl.formatMessage({ id: "general.filter..." })}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
          </div>

          <Box>
            {loading && (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}
            {!loading && (
              <BasicTable>
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>
                      <Checkbox
                        checked={isAllSelected}
                        onChange={() => handleProductCheckboxChanged()}
                      />
                    </th>
                    <th style={{ width: "40px" }}>#</th>
                    <th>
                      <FormattedMessage id="management-products.name" />
                    </th>
                    <th style={{ width: "110px" }}>
                      <FormattedMessage id="management-products.sku" />
                    </th>
                    <th style={{ width: "60px" }}>
                      <FormattedMessage id="management-products.price" />
                    </th>
                    {isAllowed("admin.products.our-prices") && (
                      <th style={{ width: "70px" }}>
                        <FormattedMessage id="management-products.our-price" />
                      </th>
                    )}
                    <th style={{ width: "120px" }}>
                      <FormattedMessage id="management-products.type" />
                    </th>
                    <th>
                      <FormattedMessage id="management-products.attached-to" />
                    </th>
                    <th style={{ width: "100px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!filteredProducts && (
                    <tr>
                      <td colSpan={6}>
                        <div className="spinner-wrapper">
                          <Spinner />
                        </div>
                      </td>
                    </tr>
                  )}

                  {filteredProducts?.length === 0 && (
                    <tr>
                      <td colSpan={6}>
                        <FormattedMessage id="general.no-rows" />
                      </td>
                    </tr>
                  )}

                  {filteredProducts
                    ?.sort(handleSortProducts)
                    .map((product, key) => (
                      <tr key={key}>
                        <td style={{ width: "60px" }}>
                          <Checkbox
                            checked={!!selectedProducts[product._id]}
                            onChange={() =>
                              handleProductCheckboxChanged(product)
                            }
                          />
                        </td>
                        <td>{key + 1}</td>
                        <td>{product.name}</td>
                        <td>{product.sku}</td>
                        <td>
                          {currencySymbols[product.currency]}
                          {product.price}
                        </td>
                        {isAllowed("admin.products.our-prices") && (
                          <td>
                            {currencySymbols[product.our_currency]}
                            {product.our_price}
                          </td>
                        )}
                        <td>{product.type}</td>
                        <td>
                          <a
                            href="#"
                            onClick={(e) =>
                              handleAttachedUsersClicked(e, product.users)
                            }
                          >
                            <FormattedMessage
                              id="management-products.n-users"
                              values={{
                                total: Object.keys(product.users).length,
                              }}
                            />
                          </a>
                        </td>
                        <td>
                          {isAllowed("admin.products.add-edit") && (
                            <div className={styles.actionButtons}>
                              <EditSvg
                                onClick={() => handleEditClicked(product)}
                              />
                              {["product", "addon"].includes(product.type) && (
                                <TrashSvg
                                  onClick={() => handleRemoveClicked(product)}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </BasicTable>
            )}
          </Box>
        </div>

        <AddProductModal
          isOpen={isAddProductModalOpen}
          onClose={handleAddProductModalClosed}
          editProduct={selectedProduct}
          whitelabel={whitelabel}
        />
      </LayoutMainAction>
    </WithRole>
  );
}
