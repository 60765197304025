import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";

function AddCustomToInvoiceModal({ isOpen, onClose }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    if (isOpen) {
      setName("");
      setDescription("");
      setAmount("");
      setQuantity("");
    }
  }, [isOpen]);

  function handleAddClicked() {
    onClose({ name, description, amount, quantity });
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-custom-to-invoice-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-custom-to-invoice-modal.name" />
          </span>
          <CustomText value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-custom-to-invoice-modal.description" />
          </span>
          <CustomText
            type="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-custom-to-invoice-modal.amount" />
          </span>
          <CustomText
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-custom-to-invoice-modal.quantity" />
          </span>
          <CustomText
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={handleAddClicked}>
          <FormattedMessage id="general.add" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddCustomToInvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddCustomToInvoiceModal;
