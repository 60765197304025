import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as LongArrowLeft } from "../svgs/long-arrow-left.svg";
import IconButton from "../icon-button";

function LayoutMainAction({
  title,
  desc,
  smallButton,
  buttons = [],
  children,
}) {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.actions} ${desc ? "" : styles.alignCenter}`}>
        <div>
          {smallButton && (
            <div className={styles.backWrapper}>
              <Link href={smallButton.href} to={smallButton.as}>
                <LongArrowLeft /> {smallButton.title}
              </Link>
            </div>
          )}
          {title && <div className={styles.bold}>{title}</div>}
          {desc && <div className={styles.normal}>{desc}</div>}
        </div>
        {buttons && (
          <div className={styles.buttons}>
            {buttons.map((button, key) => (
              <IconButton
                key={key}
                color={button.color || "green"}
                href={button.href}
                as={button.as}
                target={button.target}
                onClick={button.onClick}
                disabled={button.disabled}
              >
                {button.title}
              </IconButton>
            ))}
          </div>
        )}
      </div>

      {(title || buttons) && (
        <div className={styles.hr}>
          <hr />
        </div>
      )}

      {children}
    </div>
  );
}

LayoutMainAction.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  smallButton: PropTypes.object,
  buttons: PropTypes.array,
  children: PropTypes.any,
};

export default LayoutMainAction;
