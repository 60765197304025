import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";

function SwitchSelector({ items, onChange }) {
  return (
    <div className={styles.switchSelectorWrapper}>
      <div
        onClick={() => onChange(items[0].value)}
        className={`${styles.bubble} ${
          items[0].selected ? styles.selected : ""
        }`}
      >
        {items[0].title}
      </div>
      <div
        onClick={() => onChange(items[1].value)}
        className={`${styles.bubble} ${
          items[1].selected ? styles.selected : ""
        }`}
      >
        {items[1].title}
      </div>
    </div>
  );
}

SwitchSelector.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};

export default SwitchSelector;
