import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import {
  getInvoiceAmount,
  getInvoiceAmountFormatted,
} from "../../../utils/billing";
import Radio from "../../radio";
import { useAjax, useAlert } from "../../../utils/hooks";

function RefundInvoiceModal({ isOpen, onClose, invoice }) {
  const ajax = useAjax();
  const alert = useAlert();
  const intl = useIntl();

  const [value, setValue] = useState("");
  const [refundType, setRefundType] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(getInvoiceAmount(invoice));
    setRefundType("just-invoice");
  }, [isOpen, invoice]);

  function handleInputBlur() {
    const invoiceAmount = getInvoiceAmount(invoice);

    if (parseFloat(value) > parseFloat(invoiceAmount)) {
      setValue(invoiceAmount);
    }
  }

  async function handleRefundClicked() {
    setLoading(true);
    const data = await ajax(`/credit-card/refund`, {
      invoiceNumber: invoice.invoice_number,
      amount: value,
      refundType: refundType,
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      await alert(
        intl.formatMessage({ id: "refund-invoice-modal.title" }),
        intl.formatMessage({ id: "refund-invoice-modal.refund.content" })
      );
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="refund-invoice-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div>
          <FormattedMessage
            id="refund-invoice-modal.description"
            values={{ amount: getInvoiceAmountFormatted(invoice) }}
          />
        </div>
        <div className={styles.inputWrapper}>
          <CustomText
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleInputBlur}
          />
        </div>
        <div className={styles.radios}>
          <div>
            <FormattedMessage
              tagName="b"
              id="refund-invoice-modal.refund-type"
            />
          </div>
          <div>
            <Radio
              label="refund-invoice-modal.just-create"
              checked={refundType === "just-invoice"}
              onChange={() => setRefundType("just-invoice")}
            />
          </div>
          <div>
            <Radio
              label="refund-invoice-modal.credits"
              labelValues={{
                amountInUSD: (value / invoice?.credits_used_rate).toFixed(2),
              }}
              checked={refundType === "credits"}
              onChange={() => setRefundType("credits")}
            />
          </div>
          <div>
            <Radio
              label="refund-invoice-modal.credit-card"
              checked={refundType === "credit-card"}
              onChange={() => setRefundType("credit-card")}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleRefundClicked}
        >
          <FormattedMessage id="general.refund" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

RefundInvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  invoice: PropTypes.object,
};

export default RefundInvoiceModal;
