import styles from "./index.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import CustomReactSelect from "../../custom-react-select";
import { getSymbolsForReactSelect } from "../../../utils/billing";
import { useAjax, useRoles, useUser } from "../../../utils/hooks";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";

const currencies = getSymbolsForReactSelect();

function AddProductModal({ isOpen, onClose, editProduct, whitelabel }) {
  const ajax = useAjax();
  const intl = useIntl();
  const user = useUser();
  const { isAllowed } = useRoles();

  const typeOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "add-product-modal.types.product" }),
        value: "product",
      },
      {
        label: intl.formatMessage({ id: "add-product-modal.types.addon" }),
        value: "addon",
      },
      {
        label: intl.formatMessage({
          id: "add-product-modal.types.cpanel-addon",
        }),
        value: "cpanel-addon",
      },
      {
        label: intl.formatMessage({
          id: "add-product-modal.types.cpanel-license",
        }),
        value: "cpanel-license",
      },
      {
        label: intl.formatMessage({
          id: "add-product-modal.types.windows-license",
        }),
        value: "windows-license",
      },
      {
        label: intl.formatMessage({
          id: "add-product-modal.types.server-monitor",
        }),
        value: "server-monitor",
      },
      {
        label: intl.formatMessage({
          id: "add-product-modal.types.managed-hosting",
        }),
        value: "managed-hosting",
      },
      {
        label: intl.formatMessage({
          id: "add-product-modal.types.additional-ipv4",
        }),
        value: "additional-ipv4",
      },
    ],
    [intl]
  );

  const [name, setName] = useState("");
  const [sku, setSKU] = useState("");
  const [tooltipInfo, setTooltipInfo] = useState("");
  const [price, setPrice] = useState("");
  const [ourPrice, setOurPrice] = useState("");
  const [currency, setCurrency] = useState(null);
  const [ourCurrency, setOurCurrency] = useState(null);
  const [type, setType] = useState(typeOptions[0]);
  const [flashyappTag, setFlashyappTag] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (editProduct) {
        setName(editProduct.name);
        setSKU(editProduct.sku);
        setTooltipInfo(editProduct.tooltip_info);
        setPrice(editProduct.price);
        setOurPrice(editProduct.our_price);
        setCurrency(currencies.find((c) => c.value === editProduct.currency));
        setOurCurrency(
          currencies.find((c) => c.value === editProduct.our_currency)
        );
        setType(typeOptions.find((t) => t.value === editProduct.type));
        setFlashyappTag(editProduct.flashyapp_tag);
      } else {
        setName("");
        setSKU("");
        setTooltipInfo("");
        setPrice("");
        setOurPrice("");
        setCurrency(currencies[1]);
        setOurCurrency(currencies[1]);
        setType(typeOptions[0]);
        setFlashyappTag("");
      }

      setError(false);
      setLoading(false);
    }
  }, [isOpen, typeOptions, editProduct]);

  async function handleAddClicked() {
    const _name = name?.trim();
    const _sku = sku?.trim();
    const _tooltipInfo = tooltipInfo?.trim();
    const _price = parseFloat(price);

    setError(false);

    if (!_name) {
      return setError(
        intl.formatMessage({ id: "add-product-modal.wrong-name" })
      );
    } else if (!sku) {
      return setError(
        intl.formatMessage({ id: "add-product-modal.wrong-sku" })
      );
    } else if (!_price && _price !== 0) {
      return setError(
        intl.formatMessage({ id: "add-product-modal.wrong-price" })
      );
    } else if (!currency) {
      return setError(
        intl.formatMessage({ id: "add-product-modal.wrong-currency" })
      );
    } else if ([SUPER_ADMIN, WHITELABEL].includes(user.role) && !ourCurrency) {
      return setError(
        intl.formatMessage({ id: "add-product-modal.wrong-currency" })
      );
    }

    setLoading(true);
    const data = await ajax("/products/add", {
      name: _name,
      sku: _sku,
      tooltipInfo: _tooltipInfo,
      price: _price,
      ourPrice,
      currency: currency.value,
      ourCurrency: ourCurrency?.value,
      type: type.value,
      flashyappTag,
      editID: editProduct?._id,
      whitelabelToUse: whitelabel,
    });

    if (data.result === "success") {
      onClose(true);
    } else if (data.message === "sku-taken") {
      setError(intl.formatMessage({ id: "add-product-modal.sku-taken" }));
    }
  }

  async function handleGenerateClicked() {
    setLoading(true);
    const data = await ajax("/products/generateSKU");

    setSKU(data.sku);
    setLoading(false);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editProduct
              ? "add-product-modal.title-edit"
              : "add-product-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-product-modal.name" />
          </span>
          <CustomText value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-product-modal.sku" />
          </span>
          <CustomText
            value={sku}
            onChange={(e) => setSKU(e.target.value)}
            readOnly
          />
          {!editProduct && (
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleGenerateClicked}
            >
              <FormattedMessage id="general.generate" />
            </IconButton>
          )}
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-product-modal.tooltip-info" />
          </span>
          <CustomText
            value={tooltipInfo}
            onChange={(e) => setTooltipInfo(e.target.value)}
          />
        </div>
        {isAllowed("admin.products.our-prices") && (
          <div className={`${styles.row} ${styles.currency}`}>
            <span>
              <FormattedMessage id="add-product-modal.our-price" />
            </span>
            <CustomText
              value={ourPrice}
              onChange={(e) => setOurPrice(e.target.value)}
            />
            <CustomReactSelect
              className={styles.select}
              options={currencies}
              value={ourCurrency}
              onChange={(item) => setOurCurrency(item)}
            />
          </div>
        )}
        <div className={`${styles.row} ${styles.currency}`}>
          <span>
            <FormattedMessage id="add-product-modal.client-price" />
          </span>
          <CustomText
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <CustomReactSelect
            className={styles.select}
            options={currencies}
            value={currency}
            onChange={(item) => setCurrency(item)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="add-product-modal.type" />
          </span>
          <CustomReactSelect
            options={
              process.env.NODE_ENV === "production"
                ? typeOptions.filter((type) =>
                    ["product", "addon"].includes(type.value)
                  )
                : typeOptions
            }
            value={type}
            onChange={(item) => setType(item)}
          />
        </div>
        {isAllowed("super-admin.flashyapp") && (
          <div className={styles.row}>
            <span>
              <FormattedMessage id="add-product-modal.flashyapp-tag" />
            </span>
            <CustomText
              value={flashyappTag}
              onChange={(e) => setFlashyappTag(e.target.value)}
            />
          </div>
        )}

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAddClicked}
        >
          <FormattedMessage id={editProduct ? "general.edit" : "general.add"} />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddProductModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editProduct: PropTypes.object,
  whitelabel: PropTypes.string,
};

export default AddProductModal;
