export const getFirewallPageNavItems = (selected, intl, lang, userIDToWork) => {
  const userParam = userIDToWork
    ? `?userid=${userIDToWork.value}&userlabel=${encodeURIComponent(
        userIDToWork.label
      )}`
    : "";

  return [
    {
      title: intl.formatMessage({ id: "firewall.nav.security-groups" }),
      href: "/[lang]/my-cloud/firewall/security-groups",
      as: `/${lang}/my-cloud/firewall/security-groups${userParam}`,
      selected: selected === "security-groups",
    },
    {
      title: intl.formatMessage({ id: "firewall.nav.ip-set" }),
      href: "/[lang]/my-cloud/firewall/ip-set",
      as: `/${lang}/my-cloud/firewall/ip-set${userParam}`,
      selected: selected === "ip-set",
    },
    {
      title: intl.formatMessage({ id: "firewall.nav.alias" }),
      href: "/[lang]/my-cloud/firewall/alias",
      as: `/${lang}/my-cloud/firewall/alias${userParam}`,
      selected: selected === "alias",
    },
  ];
};
