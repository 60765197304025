import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";
import CustomText from "../custom-text";
import { countriesPhoneCodes } from "../../utils/countries";

function PhoneInput({ value, onChange, countryCode }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.abs}>
        <ReactCountryFlag
          svg
          countryCode={countryCode}
          style={{ width: "24px", height: "24px" }}
        />
        <span className={styles.code}>
          (+{countriesPhoneCodes[countryCode]})
        </span>
      </div>
      <CustomText value={value} onChange={onChange} />
    </div>
  );
}

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  countryCode: PropTypes.string,
};

export default PhoneInput;
