import styles from "./additional-services.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Checkbox from "../checkbox";
import SimpleBadge from "../simple-badge";
import PrivateNetworking from "./private-networking";
import UpgradeManagedHostingContent from "../modals/upgrade-managed-hosting/upgrade-managed-hosting-content";
import { validateIPaddress } from "../../utils/networks";
import { SUPER_ADMIN } from "../../utils/user";
import { useAjax, useUser } from "../../utils/hooks";
import Radio from "../radio";
import { ReactComponent as HelpSvg } from "../svgs/help.svg";
import { Tooltip } from "reactstrap";
import IconButton from "../icon-button";
import WanSelectorModal from "../modals/wan-selector";
import { WINDOWS10_IMAGE } from "../../utils/servers";

function AdditionalServices({
  location,
  image,
  backup,
  setBackup,
  setIsNextButtonDisabled,
  showPrivateNetworking,
  setShowPrivateNetworking,
  vlans,
  setVlans,
  selectedVlan,
  setSelectedVlan,
  vlanName,
  setVlanName,
  ipAddressScope,
  setIpAddressScope,
  ipAddressScopeCustom,
  setIpAddressScopeCustom,
  netmask,
  setNetmask,
  gateway,
  setGateway,
  selectedIP,
  setSelectedIP,
  publicNetworking,
  setPublicNetworking,
  monthlyBackupPrice,
  priceHourlyBackup,
  paymentType,
  selectedAddons,
  setSelectedAddons,
  customIP,
  setCustomIP,
}) {
  const intl = useIntl();
  const user = useUser();
  const ajax = useAjax();

  const [managedHostings, setManagedHostings] = useState([]);
  const [serverMonitors, setServerMonitors] = useState([]);
  const [isWanSelectorModalOpen, setIsWanSelectorModalOpen] = useState(false);
  const [error, setError] = useState(false);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll", {
      type: ["server-monitor", "managed-hosting"],
    });

    setServerMonitors(
      data.products.filter((product) => product.type === "server-monitor")
    );
    setManagedHostings(
      data.products.filter((product) => product.type === "managed-hosting")
    );
  }, [ajax]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    setError(false);
    setIsNextButtonDisabled(false);

    if (!showPrivateNetworking) {
      return;
    }

    const _vlanName = vlanName.trim();

    if (!selectedVlan) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-nic" })
      );
    } else if (!ipAddressScope) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-cidr" })
      );
    } else if (
      ipAddressScope.value === "custom" &&
      !validateIPaddress(ipAddressScopeCustom)
    ) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-ip-address" })
      );
    }

    if (ipAddressScope.value === "custom") {
      const ipArr = ipAddressScopeCustom.split(".");
      if (!["10", "172", "192"].includes(ipArr[0])) {
        setIsNextButtonDisabled(true);
        return setError(
          intl.formatMessage({
            id: "private-networking-modal.ip-address-scope-custom-wrong-number",
          })
        );
      } else if (ipArr[0] === "172" && parseInt(ipArr[1]) < 16) {
        setIsNextButtonDisabled(true);
        return setError(
          intl.formatMessage({
            id: "private-networking-modal.ip-address-scope-custom-wrong-ip-172",
          })
        );
      } else if (ipArr[0] === "192" && parseInt(ipArr[1]) < 168) {
        setIsNextButtonDisabled(true);
        return setError(
          intl.formatMessage({
            id: "private-networking-modal.ip-address-scope-custom-wrong-ip-192",
          })
        );
      }
    }

    if (!netmask) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-netmask" })
      );
    } else if (!_vlanName) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-vlan-name" })
      );
    } else if (!selectedIP) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-ip" })
      );
    } else if (!gateway) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-gateway" })
      );
    } else if (selectedIP.value === gateway.value) {
      setIsNextButtonDisabled(true);
      return setError(
        intl.formatMessage({ id: "private-networking-modal.ip-equal-gateway" })
      );
    }
  }, [
    gateway,
    intl,
    ipAddressScope,
    ipAddressScopeCustom,
    netmask,
    selectedIP,
    selectedVlan,
    setIsNextButtonDisabled,
    vlanName,
    showPrivateNetworking,
  ]);

  useEffect(() => {
    setBackup(image.value !== WINDOWS10_IMAGE);
  }, [image, setBackup]);

  function getPrice(num, toFixed = 2) {
    if (!num || num === 0) {
      return (
        <div className={styles.priceFree}>
          <FormattedMessage id="general.free" />
        </div>
      );
    }

    num = num.toFixed(toFixed);

    return (
      <div
        className={styles.price}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(
            {
              id: "additional-services.monthly-price",
            },
            { price: `<b>${num}</b>`, currency: "<b>$</b>" }
          ),
        }}
      ></div>
    );
  }

  function handleMonitoringChanged(item) {
    if (!selectedAddons[item._id]) {
      const keys = Object.keys(selectedAddons);

      for (let i = 0; i < keys.length; i++) {
        const serverMonitor = serverMonitors.find((sm) =>
          keys.includes(sm._id)
        );

        if (serverMonitor) {
          delete selectedAddons[serverMonitor._id];
        }
      }
    }

    if (selectedAddons[item._id]) {
      delete selectedAddons[item._id];
    } else {
      selectedAddons[item._id] = item;
    }

    setSelectedAddons({ ...selectedAddons });
  }

  function handleItemTooltipToggle(item) {
    item.isTooltipOpen = !item.isTooltipOpen;
    setServerMonitors([...serverMonitors]);
  }

  function handleCustomIpClicked(e) {
    e.stopPropagation();

    setIsWanSelectorModalOpen(true);
  }

  function handleWanSelectorModalClosed(state) {
    setCustomIP(state);

    setIsWanSelectorModalOpen(false);
  }

  function getMonitoringMessageID(item) {
    const name = item.name.toLowerCase();

    if (name.includes("basic")) {
      return "basic";
    } else if (name.includes("business")) {
      return "business";
    } else {
      return "agency";
    }
  }

  return (
    <div className={styles.additionalServicesWrapper}>
      {image.value !== WINDOWS10_IMAGE && (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <FormattedMessage id="additional-services.backups" />
          </div>
          <div className={styles.box} onClick={() => setBackup(!backup)}>
            <div className={styles.flex}>
              <div
                className={styles.checkboxWrapper}
                style={{ pointerEvents: "none" }}
              >
                <Checkbox checked={backup} readOnly />
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoWrapperSection}>
                  <span className={styles.infoTitle}>
                    <FormattedMessage id="additional-services.backups.title" />
                  </span>
                </div>
                <div className={styles.infoContent}>
                  <FormattedMessage id="additional-services.backups.content" />
                </div>
              </div>
              {priceHourlyBackup || monthlyBackupPrice ? (
                getPrice(
                  paymentType === "hourly"
                    ? priceHourlyBackup
                    : monthlyBackupPrice,
                  3
                )
              ) : (
                <SimpleBadge className={styles.simpleBadgeWrapper}>
                  <FormattedMessage id="general.recommended" />
                </SimpleBadge>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <FormattedMessage id="additional-services.general-services" />
        </div>

        {[SUPER_ADMIN].includes(user.role) && (
          <div
            className={styles.box}
            onClick={() => setPublicNetworking(!publicNetworking)}
          >
            <div className={styles.flex}>
              <div
                className={styles.checkboxWrapper}
                style={{ pointerEvents: "none" }}
              >
                <Checkbox checked={publicNetworking} readOnly />
              </div>
              <div className={`${styles.infoWrapper} ${styles.withButton}`}>
                <span className={`${styles.infoTitle} ${styles.normal}`}>
                  <FormattedMessage id="additional-services.general-services.public-networking" />
                </span>

                {publicNetworking && (
                  <IconButton
                    color="light-purple"
                    onClick={handleCustomIpClicked}
                  >
                    {`${intl.formatMessage({
                      id: "additional-services.general-services.custom-ip",
                    })} - ${
                      customIP || intl.formatMessage({ id: "general.none" })
                    }`}
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        )}

        <div
          className={styles.box}
          onClick={() => setShowPrivateNetworking(!showPrivateNetworking)}
        >
          <div className={styles.flex}>
            <div
              className={styles.checkboxWrapper}
              style={{ pointerEvents: "none" }}
            >
              <Checkbox checked={showPrivateNetworking} readOnly />
            </div>
            <div className={styles.infoWrapper}>
              <span className={`${styles.infoTitle} ${styles.normal}`}>
                <FormattedMessage id="additional-services.general-services.private-networking" />
              </span>
            </div>
            {getPrice()}
          </div>
          {showPrivateNetworking && (
            <div className={styles.flex}>
              <PrivateNetworking
                vlans={vlans}
                setVlans={setVlans}
                selectedVlan={selectedVlan}
                setSelectedVlan={setSelectedVlan}
                location={location}
                vlanName={vlanName}
                setVlanName={setVlanName}
                ipAddressScope={ipAddressScope}
                setIpAddressScope={setIpAddressScope}
                ipAddressScopeCustom={ipAddressScopeCustom}
                setIpAddressScopeCustom={setIpAddressScopeCustom}
                netmask={netmask}
                setNetmask={setNetmask}
                gateway={gateway}
                setGateway={setGateway}
                selectedIP={selectedIP}
                setSelectedIP={setSelectedIP}
              />
            </div>
          )}
        </div>

        {error && <div className="error">{error}</div>}
      </div>

      {serverMonitors.length > 0 && (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <FormattedMessage id="additional-services.general-services.monitoring" />
          </div>
          {serverMonitors.map((item, key) => (
            <div
              key={key}
              className={styles.box}
              onClick={() => handleMonitoringChanged(item)}
            >
              <div className={styles.flex}>
                <div className={styles.checkboxWrapper}>
                  <Radio
                    value={item._id}
                    checked={!!selectedAddons[item._id]}
                    onClick={() => handleMonitoringChanged(item)}
                  />
                </div>
                <div className={styles.infoWrapper}>
                  <div className={styles.infoWrapperSection}>
                    <span className={styles.infoTitle}>
                      <FormattedMessage
                        id={`additional-services.general-services.monitoring.${getMonitoringMessageID(
                          item
                        )}.title`}
                      />

                      {item.tooltip_info && (
                        <>
                          <div id={`tooltip-monitor-${item._id}`}>
                            <div>
                              <HelpSvg />
                            </div>
                          </div>

                          <Tooltip
                            placement="bottom"
                            isOpen={item.isTooltipOpen}
                            target={`tooltip-monitor-${item._id}`}
                            toggle={() => handleItemTooltipToggle(item)}
                          >
                            {item.tooltip_info}
                          </Tooltip>
                        </>
                      )}
                    </span>
                  </div>
                  <div className={styles.infoContent}>
                    <FormattedMessage
                      id={`additional-services.general-services.monitoring.${getMonitoringMessageID(
                        item
                      )}.content`}
                    />
                  </div>
                </div>
                {getPrice(item.price)}
              </div>
            </div>
          ))}
        </div>
      )}

      {managedHostings.length > 0 && (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <FormattedMessage id="additional-services.managed-hosting" />
          </div>

          <div className={styles.box}>
            <div className={styles.flex}>
              <UpgradeManagedHostingContent
                managedHostings={managedHostings}
                selectedAddons={selectedAddons}
                setSelectedAddons={setSelectedAddons}
              />
            </div>
          </div>
        </div>
      )}

      <WanSelectorModal
        isOpen={isWanSelectorModalOpen}
        onClose={handleWanSelectorModalClosed}
        location={location}
      />
    </div>
  );
}

AdditionalServices.propTypes = {
  location: PropTypes.string,
  image: PropTypes.object,
  backup: PropTypes.bool,
  setBackup: PropTypes.func,
  setIsNextButtonDisabled: PropTypes.func,
  showPrivateNetworking: PropTypes.bool,
  setShowPrivateNetworking: PropTypes.func,
  vlans: PropTypes.array,
  setVlans: PropTypes.func,
  selectedVlan: PropTypes.object,
  setSelectedVlan: PropTypes.func,
  vlanName: PropTypes.string,
  setVlanName: PropTypes.func,
  ipAddressScope: PropTypes.object,
  setIpAddressScope: PropTypes.func,
  ipAddressScopeCustom: PropTypes.string,
  setIpAddressScopeCustom: PropTypes.func,
  netmask: PropTypes.object,
  setNetmask: PropTypes.func,
  gateway: PropTypes.object,
  setGateway: PropTypes.func,
  selectedIP: PropTypes.object,
  setSelectedIP: PropTypes.func,
  publicNetworking: PropTypes.bool,
  setPublicNetworking: PropTypes.func,
  monthlyBackupPrice: PropTypes.number,
  priceHourlyBackup: PropTypes.number,
  paymentType: PropTypes.string,
  selectedAddons: PropTypes.object,
  setSelectedAddons: PropTypes.func,
  customIP: PropTypes.string,
  setCustomIP: PropTypes.func,
};

export default AdditionalServices;
