import React from "react";
import { ReactComponent as UserSvg } from "../svgs/user.svg";
import { ReactComponent as LockSvg } from "../svgs/lock.svg";
import { ReactComponent as FacebookMiniSvg } from "../svgs/facebook-mini.svg";
import { ReactComponent as GoogleMiniSvg } from "../svgs/google-mini.svg";
import { ReactComponent as CloudSvg } from "../svgs/cloud.svg";
import { ReactComponent as SupportSvg } from "../svgs/support.svg";
import { ReactComponent as BillingSvg } from "../svgs/billing.svg";
import { ReactComponent as AccountSvg } from "../svgs/account.svg";
import { ReactComponent as ApiSvg } from "../svgs/api.svg";
import { ReactComponent as AffiliateSvg } from "../svgs/affiliate.svg";
import { ReactComponent as RamSvg } from "../svgs/ram.svg";
import { ReactComponent as ManagementSvg } from "../svgs/management.svg";

export default function SVG({ icon }) {
  if (icon === "user") {
    return <UserSvg />;
  } else if (icon === "lock") {
    return <LockSvg />;
  } else if (icon === "facebook-mini") {
    return <FacebookMiniSvg />;
  } else if (icon === "google-mini") {
    return <GoogleMiniSvg />;
  } else if (icon === "cloud") {
    return <CloudSvg />;
  } else if (icon === "support") {
    return <SupportSvg />;
  } else if (icon === "billing") {
    return <BillingSvg />;
  } else if (icon === "account") {
    return <AccountSvg />;
  } else if (icon === "api") {
    return <ApiSvg />;
  } else if (icon === "affiliate") {
    return <AffiliateSvg />;
  } else if (icon === "ram") {
    return <RamSvg />;
  } else if (icon === "management") {
    return <ManagementSvg />;
  } else {
    console.error(`${icon} - Missing SVG`);
    return null;
  }
}
