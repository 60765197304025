import styles from "./parent-selector.module.scss";

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useAjax, useAlert, useUser } from "../../utils/hooks";

export default function ParentSelector() {
  const intl = useIntl();
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  async function handleChangeParent(parentID) {
    setLoading(true);

    const data = await ajax(`/users/changeWorkspace`, {
      parentID,
    });

    if (data.result === "error") {
      await alert(
        intl.formatMessage({ id: "general.error" }),
        intl.formatMessage({ id: "general.ip-not-authorized" })
      );
      setLoading(false);
    } else {
      window.location.href = "/";
    }
  }

  if (!user.roles || user.roles.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <UncontrolledDropdown className="ml-2">
        <DropdownToggle
          disabled={loading}
          caret
          color="light"
          size="sm"
          className="language-button"
        >
          <span className="name">
            {intl.formatMessage({
              id: "parent-selector.working-under",
            })}
            {user.current_parent
              ? user.roles.find((r) => r.parent_user_id === user.current_parent)
                  .parentUser.email
              : intl.formatMessage({
                  id: "parent-selector.my-account",
                })}
          </span>
        </DropdownToggle>
        <DropdownMenu className="mt-3" right>
          <DropdownItem
            key={-1}
            onClick={() => handleChangeParent(null)}
            disabled={!user.current_parent}
          >
            <FormattedMessage id="parent-selector.my-account" />
          </DropdownItem>
          {user.roles.map((role, idx) => (
            <DropdownItem
              key={idx}
              onClick={() => handleChangeParent(role.parentUser._id)}
              disabled={user.current_parent === role.parentUser._id}
            >
              {role.parentUser.email}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
