import React from "react";
import PropTypes from "prop-types";
import { Label, Input } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { ReactComponent as RadioOn } from "../svgs/radio-on.svg";
import { ReactComponent as RadioOff } from "../svgs/radio-off.svg";
import styles from "./index.module.scss";

function Radio({
  label,
  labelValues,
  value,
  checked,
  onChange = () => {},
  onClick = () => {},
}) {
  return (
    <Label className={styles.checkboxWrapper} check>
      <Input
        type="radio"
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.target.value)}
        onClick={onClick}
      />
      {checked && <RadioOn />}
      {!checked && <RadioOff />}
      {typeof label === "string" && (
        <FormattedMessage id={label} values={labelValues} />
      )}
      {typeof label !== "string" && label}
    </Label>
  );
}

Radio.propTypes = {
  label: PropTypes.any,
  labelValues: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default Radio;
