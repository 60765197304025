import React from "react";
import { useUser } from "../../utils/hooks";

export default function UserAvatar() {
  const user = useUser();

  return (
    <div>
      <img
        src={user.profilePicture || "/img/user-avatar.png"}
        width={36}
        height={36}
      />
    </div>
  );
}
