import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import publicIp from "public-ip";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import CustomMenu from "../../custom-menu";
import CustomMenuItem from "../../custom-menu/item";
import { validateIPaddress } from "../../../utils/networks";

const directions = [
  { label: "INBOUND", value: "in" },
  { label: "OUTBOUND", value: "out" },
];

const actions = [
  { label: "ACCEPT", value: "ACCEPT" },
  { label: "DROP", value: "DROP" },
  { label: "REJECT", value: "REJECT" },
];

const yesNo = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

function AddNewRuleModal({
  securityGroup,
  selectedRule,
  userIDToWork,
  isOpen,
  onClose,
}) {
  const ajax = useAjax();
  const intl = useIntl();

  const [macros, setMacros] = useState(false);
  const [protocols, setProtocols] = useState(false);
  const [sourceOption, setSourceOption] = useState(false);
  const [sourceOptions, setSourceOptions] = useState(false);

  const [direction, setDirection] = useState(null);
  const [action, setAction] = useState(null);
  const [source, setSource] = useState("");
  const [enable, setEnable] = useState(null);
  const [macro, setMacro] = useState(null);
  const [comment, setComment] = useState("");
  const [protocol, setProtocol] = useState(null);
  const [destinationPort, setDestinationPort] = useState("");

  const [isSourceIpDropdownOpen, setIsSourceIpDropdownOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchFirewallRuleSettings = useCallback(async () => {
    const [dataRuleSettings, dataIPSets, dataAliases] = await Promise.all([
      ajax(`/firewall/getRuleSettings`),
      ajax(`/firewall/getIPSets`, {
        userIDToWork: userIDToWork?.value,
      }),
      ajax("/firewall/getAliases", {
        userIDToWork: userIDToWork?.value,
      }),
    ]);

    const macros = [
      {
        label: intl.formatMessage({ id: "general.none" }),
        value: null,
        key: -1,
      },
      ...Object.keys(dataRuleSettings.macros).map((k, idx) => ({
        label: k,
        value: k,
        key: idx,
      })),
    ];
    setMacros(macros);
    setMacro(macros[0]);

    const protocols = Object.keys(dataRuleSettings.protocols).map((k, idx) => ({
      label: k,
      value: dataRuleSettings.protocols[k],
      key: idx,
    }));
    setProtocols(protocols);
    setProtocol(protocols[0]);

    if (selectedRule) {
      setDirection(
        directions.find((direction) => direction.value === selectedRule.type)
      );
      setAction(actions.find((action) => action.value === selectedRule.action));

      if (selectedRule.source === "+il") {
        setSource("Country: IL");
      }
      if (selectedRule.source === "+cloudflare") {
        setSource("Cloudflare");
      } else {
        setSource(selectedRule.source);
      }

      setDestinationPort(selectedRule.dport || "");
      setEnable(yesNo.find((enable) => enable.value === selectedRule.enable));
      setMacro(
        selectedRule.macro
          ? macros.find((macro) => macro.value === selectedRule.macro)
          : macros[0]
      );
      setProtocol(
        selectedRule.proto
          ? protocols.find((proto) => proto.value === selectedRule.proto)
          : protocols[0]
      );
      setComment(selectedRule.comment || "");
    } else {
      setDirection(directions[0]);
      setAction(actions[0]);
      setSource("");
      setDestinationPort("");
      setEnable(yesNo[0]);
      setMacro(macros[0]);
      setProtocol(protocols[0]);
      setComment("");
    }

    const sourceOptions = [
      {
        label: intl.formatMessage({
          id: "firewall.source-ip.my-ip",
        }),
        value: "my-ip",
      },
      {
        label: intl.formatMessage({
          id: "firewall.source-ip.any-ip",
        }),
        value: "any-ip",
      },
      {
        label: intl.formatMessage({
          id: "firewall.source-ip.cloudflare",
        }),
        value: "cloudflare",
      },
      {
        label: intl.formatMessage({
          id: "firewall.source-ip.country-il",
        }),
        value: "country-il",
      },
    ];

    dataAliases.aliases.forEach((alias) => {
      sourceOptions.push({
        label: alias.name,
        value: `alias_${alias.name}`,
      });
    });

    dataIPSets.ipsets.forEach((ipset) => {
      const name = ipset.comment.split("_")[0];
      sourceOptions.push({
        label: name,
        value: `ipset_${name}`,
      });

      if (name === selectedRule.source) {
        setSourceOption(sourceOptions[sourceOptions.length - 1]);
      }
    });

    setSourceOptions(sourceOptions);
  }, [ajax, intl, selectedRule, userIDToWork?.value]);

  useEffect(() => {
    if (isOpen) {
      fetchFirewallRuleSettings();

      setLoading(false);
    }
  }, [isOpen, fetchFirewallRuleSettings]);

  useEffect(() => {
    if (!sourceOption) {
      return;
    }

    if (sourceOption.value === "my-ip") {
      publicIp.v4().then((ip) => setSource(ip));
    } else if (sourceOption.value === "any-ip") {
      setSource("0.0.0.0/0");
    } else if (sourceOption.value === "cloudflare") {
      setSource("Cloudflare");
    } else if (sourceOption.value === "country-il") {
      setSource("Country: IL");
    } else if (sourceOption.value.startsWith("ipset_")) {
      setSource(`IPSet: ${sourceOption.label}`);
    } else if (sourceOption.value.startsWith("alias_")) {
      setSource(`Alias: ${sourceOption.label}`);
    } else {
      setSource("");
    }
  }, [sourceOption]);

  async function handleAddRuleClicked() {
    let error = false;

    if (!direction) {
      error = "firewall.add-group.no-direction";
    } else if (!action) {
      error = "firewall.add-group.no-action";
    } else if (!enable) {
      error = "firewall.add-group.no-enable";
    } else if (!macro && !protocol) {
      error = "firewall.add-group.no-macro-or-protocol";
    } else if (
      source !== "0.0.0.0/0" &&
      source !== "Country: IL" &&
      source !== "Cloudflare" &&
      !source.startsWith("Alias: ") &&
      !source.startsWith("IPSet: ") &&
      !validateIPaddress(source)
    ) {
      error = "firewall.add-group.no-source";
    }

    setError(error);

    if (error) {
      return;
    }

    setLoading(true);
    const data = await ajax(`/firewall/addRuleToGroup`, {
      userIDToWork: userIDToWork?.value,
      edit: selectedRule,
      groupID: securityGroup.id,
      type: direction.value,
      action: action.value,
      source,
      enable: enable.value,
      macro: macro.value,
      comment,
      proto: protocol.value,
      dport: destinationPort,
    });

    if (data.result === "success") {
      onClose(true);
    } else {
      setError(`firewall.add-group.${data.message}`);
      setLoading(false);
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-new-rule-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <div className={styles.col}>
            <span className={styles.text}>
              <FormattedMessage id="add-new-rule-modal.direction" />
            </span>
            <Select
              placeholder={intl.formatMessage({ id: "general.selection" })}
              options={directions}
              value={direction}
              onChange={(option) => setDirection(option)}
            />
          </div>
          <div className={styles.col}>
            <span className={styles.text}>
              <FormattedMessage id="add-new-rule-modal.enable" />
            </span>
            <Select
              placeholder={intl.formatMessage({ id: "general.selection" })}
              options={yesNo}
              value={enable}
              onChange={(option) => setEnable(option)}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <span className={styles.text}>
              <FormattedMessage id="add-new-rule-modal.action" />
            </span>
            <Select
              placeholder={intl.formatMessage({ id: "general.selection" })}
              options={actions}
              value={action}
              onChange={(option) => setAction(option)}
            />
          </div>
          <div className={styles.col}>
            <span className={styles.text}>
              <FormattedMessage id="add-new-rule-modal.macro" />
            </span>
            <Select
              placeholder={intl.formatMessage({ id: "general.selection" })}
              options={macros}
              value={macro}
              onChange={(option) => setMacro(option)}
            />
          </div>
        </div>

        {(!macro || !macro.value) && (
          <div className={styles.row}>
            <div className={styles.col}></div>
            <div className={styles.col}>
              <span className={styles.text}>
                <FormattedMessage id="add-new-rule-modal.protocol" />
              </span>
              <Select
                placeholder={intl.formatMessage({ id: "general.selection" })}
                options={protocols}
                value={protocol}
                onChange={(option) => setProtocol(option)}
              />
            </div>
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.col}>
            <span className={`${styles.text} ${styles.sourceIP}`}>
              <FormattedMessage
                id={
                  direction?.value === "in"
                    ? "add-new-rule-modal.source-ip"
                    : "add-new-rule-modal.destionation-ip"
                }
              />
            </span>

            <CustomText
              value={source}
              onChange={(e) => setSource(e.target.value)}
            />

            <CustomMenu
              isOpen={isSourceIpDropdownOpen}
              toggle={() => setIsSourceIpDropdownOpen(!isSourceIpDropdownOpen)}
            >
              {sourceOptions &&
                sourceOptions.map((option, key) => (
                  <CustomMenuItem
                    key={key}
                    onClick={() => setSourceOption(option)}
                  >
                    {option.label}
                  </CustomMenuItem>
                ))}
            </CustomMenu>
          </div>
          {(!macro || !macro.value) && (
            <div className={styles.col}>
              <span className={styles.text}>
                <FormattedMessage id="add-new-rule-modal.port" />
              </span>
              <CustomText
                placeholder="Ex: 21"
                value={destinationPort}
                onChange={(e) => setDestinationPort(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className={styles.row}>
          <div className={`${styles.col} ${styles.full}`}>
            <span className={styles.text}>
              <FormattedMessage id="add-new-rule-modal.comment" />
            </span>
            <CustomText
              placeholder={intl.formatMessage({
                id: "add-new-rule-modal.insert-comment",
              })}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>

        {error && (
          <div className="error">
            <FormattedMessage id={error} />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAddRuleClicked}
        >
          {selectedRule ? (
            <FormattedMessage id="general.edit" />
          ) : (
            <FormattedMessage id="general.create" />
          )}
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddNewRuleModal.propTypes = {
  securityGroup: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedRule: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  userIDToWork: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddNewRuleModal;
