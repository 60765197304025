import styles from "./layout.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLang, useRoles, useServer } from "../../utils/hooks";

function ServerSettingsLayout({ children }) {
  const router = useHistory();
  const { id } = useParams();
  const lang = useLang();
  const server = useServer();
  const { isAllowed } = useRoles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.navWrapper}>
        <div className={styles.nav}>
          {server?.status === "Active" && (
            <div
              className={`${styles.item} ${
                router.location.pathname.endsWith("/server-settings/ipv4") &&
                styles.selected
              }`}
            >
              <Link
                href="/[lang]/my-cloud/servers/[id]/server-settings/ipv4"
                to={`/${lang}/my-cloud/servers/${id}/server-settings/ipv4`}
              >
                <FormattedMessage id="server-settings-layout.ipv4" />
              </Link>
            </div>
          )}

          {isAllowed("servers.addons") && (
            <div
              className={`${styles.item} ${
                router.location.pathname.endsWith("/server-settings/addons") &&
                styles.selected
              }`}
            >
              <Link
                href="/[lang]/my-cloud/servers/[id]/server-settings/addons"
                to={`/${lang}/my-cloud/servers/${id}/server-settings/addons`}
              >
                <FormattedMessage id="server-settings-layout.addons" />
              </Link>
            </div>
          )}

          {server?.status === "Active" && isAllowed("servers.boot-order") && (
            <div
              className={`${styles.item} ${
                router.location.pathname.endsWith(
                  "/server-settings/boot-order"
                ) && styles.selected
              }`}
            >
              <Link
                href="/[lang]/my-cloud/servers/[id]/server-settings/boot-order"
                to={`/${lang}/my-cloud/servers/${id}/server-settings/boot-order`}
              >
                <FormattedMessage id="server-settings-layout.boot-order" />
              </Link>
            </div>
          )}

          {server?.status === "Active" && isAllowed("servers.change-plan") && (
            <>
              <div
                className={`${styles.item} ${
                  router.location.pathname.endsWith(
                    "/server-settings/change-plan"
                  ) && styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/change-plan"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/change-plan`}
                >
                  <FormattedMessage id="server-settings-layout.change-plan" />
                </Link>
              </div>

              {/* <div
                className={`${styles.item} ${
                  router.location.pathname.endsWith("/server-settings/manage-hosting") &&
                  styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/manage-hosting"
                  as={`/${lang}/my-cloud/servers/${id}/server-settings/manage-hosting`}
                >
                  <a>
                    <FormattedMessage id="server-settings-layout.manage-hosting" />
                  </a>
                </Link>
              </div> */}

              {/* <div
                className={`${styles.item} ${
                  router.location.pathname.endsWith("/server-settings/manage-addons") &&
                  styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/manage-addons"
                  as={`/${lang}/my-cloud/servers/${id}/server-settings/manage-addons`}
                >
                  <a>
                    <FormattedMessage id="server-settings-layout.manage-addons" />
                  </a>
                </Link>
              </div> */}

              {/* <div
                className={`${styles.item} ${
                  router.location.pathname.endsWith("/server-settings/server-monitor") &&
                  styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/server-monitor"
                  as={`/${lang}/my-cloud/servers/${id}/server-settings/server-monitor`}
                >
                  <a>
                    <FormattedMessage id="server-settings-layout.server-monitor" />
                  </a>
                </Link>
              </div> */}
            </>
          )}

          {server?.status === "Active" && isAllowed("servers.change-os") && (
            <>
              <div
                className={`${styles.item} ${
                  router.location.pathname.endsWith(
                    "/server-settings/change-os"
                  ) && styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/change-os"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/change-os`}
                >
                  <FormattedMessage id="server-settings-layout.change-os" />
                </Link>
              </div>

              <div
                className={`${styles.item} ${
                  router.location.pathname.endsWith(
                    "/server-settings/custom-iso"
                  ) && styles.selected
                }`}
              >
                <Link
                  href="/[lang]/my-cloud/servers/[id]/server-settings/custom-iso"
                  to={`/${lang}/my-cloud/servers/${id}/server-settings/custom-iso`}
                >
                  <FormattedMessage id="server-settings-layout.custom-iso" />
                </Link>
              </div>
            </>
          )}

          {server?.status === "Active" && isAllowed("servers.change-payment") && (
            <div
              className={`${styles.item} ${
                router.location.pathname.endsWith(
                  "/server-settings/change-payment"
                ) && styles.selected
              }`}
            >
              <Link
                href="/[lang]/my-cloud/servers/[id]/server-settings/change-payment"
                to={`/${lang}/my-cloud/servers/${id}/server-settings/change-payment`}
              >
                <FormattedMessage id="server-settings-layout.change-payment" />
              </Link>
            </div>
          )}

          {server?.status === "Active" && isAllowed("servers.change-password") && (
            <div
              className={`${styles.item} ${
                router.location.pathname.endsWith(
                  "/server-settings/change-password"
                ) && styles.selected
              }`}
            >
              <Link
                href="/[lang]/my-cloud/servers/[id]/server-settings/change-password"
                to={`/${lang}/my-cloud/servers/${id}/server-settings/change-password`}
              >
                <FormattedMessage id="server-settings-layout.change-password" />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
}

ServerSettingsLayout.propTypes = {
  children: PropTypes.any,
};

export default ServerSettingsLayout;
