import styles from "./hourly-cron.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useConfirm } from "../../../utils/hooks";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { sanitize } from "../../../utils";
import Spinner from "../../../components/spinner";
import BasicTable from "../../../components/basic-table";
import { format } from "date-fns";

export default function BillingHourlyCron() {
  const ajax = useAjax();
  const intl = useIntl();
  const confirm = useConfirm();

  const [items, setItems] = useState(null);

  const [loading, setLoading] = useState(false);

  const simulateHourlyCron = useCallback(async () => {
    const data = await ajax("/billing/simulateHourlyCron");

    if (data.result === "success") {
      setItems(data.items);
    } else {
      setItems([]);
    }
  }, [ajax]);

  useEffect(() => {
    simulateHourlyCron();
  }, [simulateHourlyCron]);

  const handleRunCronClicked = useCallback(async () => {
    const state = await confirm({
      title: intl.formatMessage({
        id: "billing-hourly-cron.run-cron-for-real",
      }),
      message: intl.formatMessage({
        id: "billing-hourly-cron.run-cron-for-real.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax("/billing/runHourlyCron");
    setLoading(false);
  }, [ajax, confirm, intl]);

  const handleRunCronFakeClicked = useCallback(async () => {
    const state = await confirm({
      title: intl.formatMessage({
        id: "billing-hourly-cron.run-cron-for-fake",
      }),
      message: intl.formatMessage({
        id: "billing-hourly-cron.run-cron-for-fake.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax("/billing/runHourlyCron", { fake: true });
    setLoading(false);
  }, [ajax, confirm, intl]);

  const menuButtons = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: "billing-hourly-cron.run-cron-for-fake",
        }),
        onClick: handleRunCronFakeClicked,
        disabled: loading,
      },
      {
        title: intl.formatMessage({
          id: "billing-hourly-cron.run-cron-for-real",
        }),
        onClick: handleRunCronClicked,
        disabled: loading,
      },
    ],
    [intl, handleRunCronClicked, handleRunCronFakeClicked, loading]
  );

  return (
    <WithRole permission="super-admin.hourly-cron">
      <LayoutMainAction
        title={intl.formatMessage({ id: "billing-hourly-cron.main-title" })}
        buttons={menuButtons}
      >
        <div className={styles.wrapper}>
          {!items && (
            <Box>
              <FormattedMessage id="billing-hourly-cron.running-simulator" />{" "}
              <Spinner />
            </Box>
          )}

          {items?.length === 0 && (
            <Box>
              <FormattedMessage id="general.no-rows" />
            </Box>
          )}

          {items?.map((item, key) => (
            <Box title={item.userEmail} key={key}>
              <BasicTable layout="auto">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="billing-hourly-cron.name" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-hourly-cron.description" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-hourly-cron.quantity" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-hourly-cron.amount" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-hourly-cron.total" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-hourly-cron.next-pay-day-before" />
                    </th>
                    <th>
                      <FormattedMessage id="billing-hourly-cron.next-pay-day-after" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item.data.map((d, key) => (
                    <tr key={key}>
                      <td>
                        <div>
                          <b>{d.name}</b>
                        </div>
                        <div>{d.item_type}</div>
                      </td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(d.description),
                          }}
                        ></div>
                      </td>
                      <td>{d.quantity}</td>
                      <td>{parseFloat(d.amount).toFixed(3)}</td>
                      <td>{(parseFloat(d.amount) * d.quantity).toFixed(3)}</td>
                      <td>
                        {d.nextPayDayBefore &&
                          format(new Date(d.nextPayDayBefore), "d/M/y HH:mm")}
                      </td>
                      <td>
                        {d.nextPayDayAfter &&
                          format(new Date(d.nextPayDayAfter), "d/M/y HH:mm")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>
            </Box>
          ))}
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
