import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as NotificationSvg } from "../svgs/notification.svg";
import { ReactComponent as NotificationOnSvg } from "../svgs/notification-on.svg";
import { useAjax, useLang, useNotifications } from "../../utils/hooks";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

function Notifications() {
  const ajax = useAjax();
  const router = useHistory();
  const lang = useLang();
  const notifications = useNotifications();

  const [isOpen, setIsOpen] = useState(false);

  function handleDomClicked() {
    setIsOpen(false);
  }

  useEffect(() => {
    window.addEventListener("click", handleDomClicked);

    return () => {
      window.removeEventListener("click", handleDomClicked);
    };
  }, []);

  function handleSectionClicked(e) {
    e.stopPropagation();
    setIsOpen(true);
  }

  async function handleMarkAllClicked() {
    await ajax("/notifications/markAllAsRead");
  }

  async function handleReadNotificationClicked(notification) {
    await ajax("/notifications/readNotification", {
      notificationID: notification._id,
    });
  }

  function getNotificationLink(notification) {
    switch (notification.type) {
      case "NOTIFICATION_TYPE_NEW_TICKET":
        return {
          href: "/[lang]/support/my-tickets/ticket_number",
          as: `/${lang}/support/my-tickets/${notification.data.ticketNumber}`,
        };
      case "NOTIFICATION_TYPE_NEW_REPLY_CLIENT":
        return {
          href: "/[lang]/support/my-tickets/ticket_number",
          as: `/${lang}/support/my-tickets/${notification.data.ticketNumber}`,
        };
      case "NOTIFICATION_TYPE_NEW_REPLY_ADMIN":
        return {
          href: "/[lang]/support/my-tickets/ticket_number",
          as: `/${lang}/support/my-tickets/${notification.data.ticketNumber}`,
        };
      case "NOTIFICATION_TYPE_ACTIVE_2FA":
        return {
          href: "/[lang]/account/security",
          as: `/${lang}/account/security`,
        };
      default:
        return { href: router.pathname, as: router.asPath };
    }
  }

  return (
    <div className={styles.wrapper}>
      <div onClick={handleSectionClicked}>
        {notifications.unread > 0 ? <NotificationOnSvg /> : <NotificationSvg />}
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className={`${styles.popup} ${isOpen ? styles.isOpen : ""}`}
      >
        <div>
          <div className={styles.markAll} onClick={handleMarkAllClicked}>
            <FormattedMessage id="notifications.mark-all-as-read" />
          </div>
          {notifications.data.map((notification, key) => {
            const { href, as } = getNotificationLink(notification);

            return (
              <Link
                key={key}
                href={href}
                to={as}
                onClick={() => handleReadNotificationClicked(notification)}
              >
                <div
                  className={`${styles.content} ${
                    notification.read ? "" : styles.bold
                  }`}
                >
                  <FormattedMessage
                    id={notification.title.id}
                    values={notification.title.values}
                  />
                </div>
                <div className={styles.date}>{notification.date}</div>

                <hr />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Notifications);
