import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import BasicTable from "../../../../components/basic-table";
import Box from "../../../../components/box";
import LayoutMainAction from "../../../../components/layout-main-action";
import Pagination from "../../../../components/pagination";
import Spinner from "../../../../components/spinner";
import { useAjax, useLang, useUser, useWLDVPS } from "../../../../utils/hooks";
import { SUPER_ADMIN } from "../../../../utils/user";
import AddNewClientModal from "../../../../components/modals/add-new-client";
import { WithRole } from "../../../../components/with-role";
import { getAllWLDVPS } from "../../../../utils/wldvps";
import {} from "../../../../utils";
import Checkbox from "../../../../components/checkbox";

const maxRowsInPage = 50;

export default function ClientsList() {
  const intl = useIntl();
  const ajax = useAjax();
  const user = useUser();
  const router = useHistory();
  const lang = useLang();
  const wldvps = useWLDVPS();

  const [users, setUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [filter, setFilter] = useState("");
  const [showOnlyMyCreated, setShowOnlyMyCreated] = useState(false);
  const [whitelabel, setWhitelabel] = useState("all");
  const [showOnly, setShowOnly] = useState("active-users");
  const [isWhitelabelDropdownOpen, setIsWhitelabelDropdownOpen] = useState(
    false
  );
  const [isShowOnlyDropdownOpen, setIsShowOnlyDropdownOpen] = useState(false);
  const [isAddNewClientModalOpen, setIsAddNewClientModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [whitelabels, setWhitelabels] = useState([]);

  const buttons = [
    {
      title: intl.formatMessage({ id: "clients-list.add-new" }),
      onClick: () => setIsAddNewClientModalOpen(true),
    },
    // {
    //   title: intl.formatMessage({ id: "clients-list.export" }),
    //   href: `${getServerUrl()}/users/export`,
    //   target: "_blank",
    // },
  ];

  const getUsers = useCallback(async () => {
    const data = await ajax("/admin/getUsers");

    setUsers(data.users);
  }, [ajax]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (!user.whitelabelSettings) {
      return;
    }

    const whitelabels = getAllWLDVPS(user.whitelabelSettings);

    setWhitelabels(whitelabels);
  }, [user]);

  useEffect(() => {
    if (!users) {
      return;
    }

    setFilteredUsers(
      users.filter(
        (usr) =>
          (showOnly === "active-users" ||
            (showOnly === "need-to-fill-details" && usr.registerStep === 1) ||
            (showOnly === "need-to-verify-phone" && usr.registerStep === 2) ||
            (showOnly === "need-to-add-credit-card" &&
              usr.registerStep === 3)) &&
          (!filter ||
            usr.email.toLowerCase().includes(filter.toLowerCase()) ||
            usr.clientNumber.toString().includes(filter) ||
            (usr.firstName &&
              usr.firstName.toLowerCase().includes(filter.toLowerCase())) ||
            (usr.lastName &&
              usr.lastName.toLowerCase().includes(filter.toLowerCase())) ||
            (usr.firstName &&
              usr.lastName &&
              `${usr.firstName} ${usr.lastName}`
                .toLowerCase()
                .includes(filter.toLowerCase())) ||
            (usr.companyName &&
              usr.companyName.toLowerCase().includes(filter.toLowerCase()))) &&
          (user.role !== SUPER_ADMIN ||
            whitelabel === "all" ||
            usr.whitelabel === whitelabel) &&
          (!showOnlyMyCreated || usr.created_via_userid === user._id)
      )
    );
  }, [filter, showOnly, users, whitelabel, user, showOnlyMyCreated]);

  function handleWhitelabelDropdownToggle() {
    setIsWhitelabelDropdownOpen(!isWhitelabelDropdownOpen);
  }

  function handleShowOnlyDropdownToggle() {
    setIsShowOnlyDropdownOpen(!isShowOnlyDropdownOpen);
  }

  function handleAddNewClientModalClosed(state) {
    if (state) {
      return router.push(`/${lang}/clients/clients-list/${state._id}`);
    }

    setIsAddNewClientModalOpen(false);
  }

  return (
    <WithRole permission="admin.clients.add">
      <LayoutMainAction
        title={intl.formatMessage({ id: "clients-list.title" })}
        buttons={buttons}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            {!wldvps && (
              <div>
                <FormattedMessage id="clients-list.whitelabel" />
                <ButtonDropdown
                  className={styles.ghost}
                  isOpen={isWhitelabelDropdownOpen}
                  toggle={handleWhitelabelDropdownToggle}
                >
                  <DropdownToggle caret>
                    {whitelabel === "all" ? (
                      <FormattedMessage id="general.all" />
                    ) : (
                      whitelabel || "DreamVPS"
                    )}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => setWhitelabel("all")}>
                      <FormattedMessage id="general.all" />
                    </DropdownItem>
                    {whitelabels.map((whitelabel, key) => (
                      <DropdownItem
                        key={key}
                        onClick={() => setWhitelabel(whitelabel)}
                      >
                        {whitelabel || "DreamVPS"}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            )}

            <div>
              <FormattedMessage id="clients-list.show-only" />
              <ButtonDropdown
                className={styles.ghost}
                isOpen={isShowOnlyDropdownOpen}
                toggle={handleShowOnlyDropdownToggle}
              >
                <DropdownToggle caret>
                  <FormattedMessage id={`clients-list.${showOnly}`} />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => setShowOnly("active-users")}>
                    <FormattedMessage id="clients-list.active-users" />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setShowOnly("need-to-fill-details")}
                  >
                    <FormattedMessage id="clients-list.need-to-fill-details" />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setShowOnly("need-to-verify-phone")}
                  >
                    <FormattedMessage id="clients-list.need-to-verify-phone" />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setShowOnly("need-to-add-credit-card")}
                  >
                    <FormattedMessage id="clients-list.need-to-add-credit-card" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>

            {user.role === SUPER_ADMIN && (
              <div>
                <Checkbox
                  label="clients-list.show-my-created"
                  checked={showOnlyMyCreated}
                  onChange={(e) => setShowOnlyMyCreated(e.target.checked)}
                />
              </div>
            )}

            <div className={styles.filterInputWrapper}>
              <input
                className={styles.filterInput}
                placeholder={intl.formatMessage({ id: "general.filter..." })}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>

            {filteredUsers && (
              <Pagination
                page={page}
                setPage={setPage}
                totalRows={filteredUsers.length}
                maxRowsInPage={maxRowsInPage}
              />
            )}
          </div>

          <Box>
            <BasicTable layout="auto">
              <thead>
                <tr>
                  <th style={{ width: "40px" }}>#</th>
                  <th>
                    <FormattedMessage id="clients-list.email" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list.client-number" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list.first-name" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list.last-name" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list.company-name" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list.active-servers" />
                  </th>
                  <th>
                    <FormattedMessage id="clients-list.registration-date" />
                  </th>
                  {!wldvps && (
                    <th>
                      <FormattedMessage id="clients-list.whitelabel" />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {!filteredUsers && (
                  <tr>
                    <td colSpan={9}>
                      <Spinner />
                    </td>
                  </tr>
                )}

                {filteredUsers?.length === 0 && (
                  <tr>
                    <td colSpan={9}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {filteredUsers &&
                  filteredUsers
                    .slice(
                      page * maxRowsInPage,
                      page * maxRowsInPage + maxRowsInPage
                    )
                    .map((user, key) => (
                      <tr key={key}>
                        <td>{key + 1 + maxRowsInPage * page}</td>
                        <td>
                          <a
                            href={`/${lang}/clients/clients-list/${user._id}/summary`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {user.email}
                          </a>
                        </td>
                        <td>{user.clientNumber}</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.companyName}</td>
                        <td>{user.serversActive}</td>
                        <td>{format(new Date(user.created_at), "d/M/yyyy")}</td>
                        {!wldvps && <td>{user.whitelabel}</td>}
                      </tr>
                    ))}
              </tbody>
            </BasicTable>
          </Box>

          {filteredUsers && (
            <Pagination
              page={page}
              setPage={setPage}
              totalRows={filteredUsers.length}
              maxRowsInPage={maxRowsInPage}
            />
          )}
        </div>

        <AddNewClientModal
          isOpen={isAddNewClientModalOpen}
          onClose={handleAddNewClientModalClosed}
        />
      </LayoutMainAction>
    </WithRole>
  );
}
