import { updateIsWorking } from "../../store/servers";

async function reinstall(server, intl, ajax, router, lang, prompt) {
  const val = await prompt({
    title: intl.formatMessage({ id: `server-layout.reinstall.title` }),
    message: intl.formatMessage(
      { id: `server-layout.reinstall.message` },
      { hostname: server.hostname, b: (arr) => `<b>${arr[0]}</b>` }
    ),
    button1: {
      text: intl.formatMessage({ id: "general.was-mistake" }),
      color: "white",
    },
    button2: {
      text: intl.formatMessage({ id: "general.yes-sure" }),
      color: "white",
    },
    acceptOnlyValue: server.hostname,
  });

  if (val === server.hostname) {
    await ajax("/servers/reinstall", { serversIds: [server._id] });
    router.push(`/${lang}/my-cloud/servers`);
  }
}

async function destroy(server, intl, ajax, router, lang, prompt) {
  const val = await prompt({
    title: intl.formatMessage({ id: `server-layout.destroy.title` }),
    message: intl.formatMessage(
      { id: `server-layout.destroy.message` },
      { hostname: server.hostname, b: (arr) => `<b>${arr[0]}</b>` }
    ),
    button1: {
      text: intl.formatMessage({ id: "general.was-mistake" }),
      color: "white",
    },
    button2: {
      text: intl.formatMessage({ id: "general.yes-sure" }),
      color: "white",
    },
    acceptOnlyValue: server.hostname,
  });

  if (val === server.hostname) {
    await ajax("/servers/destroy", { serversIds: [server._id] });
    router.push(`/${lang}/my-cloud/servers`);
  }
}

async function serverAction(action, server, ajax, dispatch) {
  let actionType;
  let isSoft = false;

  switch (action) {
    case "start-server": {
      actionType = "start";
      break;
    }
    case "soft-shutdown": {
      actionType = "stop";
      isSoft = true;
      break;
    }
    case "soft-reboot": {
      actionType = "reset";
      isSoft = true;
      break;
    }
    case "hard-shutdown": {
      actionType = "stop";
      break;
    }
    case "hard-reboot": {
      actionType = "reset";
      break;
    }
  }

  dispatch(updateIsWorking([server._id], true));

  await ajax("/servers/action", {
    serversIds: [server._id],
    actionType,
    isSoft,
  });
}

async function manageTags(server, intl, ajax, prompt) {
  const text = await prompt({
    title: intl.formatMessage({ id: "servers-list.set-tag-title" }),
    message: intl.formatMessage({ id: "servers-list.set-tag-content" }),
    defaultText: server.tag,
  });

  if (text === null) {
    return;
  }

  await ajax("/servers/setTag", {
    tag: text || "",
    serverID: server._id,
  });
}

export async function handleServerActionClicked(
  action,
  server,
  setIsCloneServerModalOpen,
  ajax,
  intl,
  router,
  lang,
  confirm,
  prompt,
  dispatch
) {
  if (action === "clone") {
    setIsCloneServerModalOpen(true);
    return;
  }

  if (action === "manage-tags") {
    manageTags(server, intl, ajax, prompt);
    return;
  }

  if (action === "reinstall") {
    reinstall(server, intl, ajax, router, lang, prompt);
    return;
  }

  if (action === "destroy") {
    destroy(server, intl, ajax, router, lang, prompt);
    return;
  }

  const state = await confirm({
    title: intl.formatMessage({ id: `server-layout.${action}.title` }),
    message: intl.formatMessage({ id: `server-layout.${action}.message` }),
    button1: {
      text: intl.formatMessage({ id: "general.was-mistake" }),
      color: "white",
    },
    button2: {
      text: intl.formatMessage({ id: "general.yes-sure" }),
      color: "white",
    },
  });

  if (state === "button2") {
    serverAction(action, server, ajax, dispatch);
  }
}
