import styles from "./bi-reports.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import {} from "../../../utils";
import { useAjax, useLang } from "../../../utils/hooks";
import BiReportListModal from "../../../components/modals/bi-report-list";
import Spinner from "../../../components/spinner";
import { getFullName } from "../../../utils/user";
import { format } from "date-fns";

export default function AccountBiReports() {
  const intl = useIntl();
  const ajax = useAjax();
  const lang = useLang();

  const [reportData, setReportData] = useState(null);
  const [isBiReportListModalOpen, setIsBiReportListModalOpen] = useState(false);
  const [modalList, setModalList] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);

  const clientsRegistrationsReport = useCallback(async () => {
    const data = await ajax("/biReports/getReportData");

    if (data.result === "success") {
      setReportData(data.reportData);
    }
  }, [ajax]);

  useEffect(() => {
    clientsRegistrationsReport({});
  }, [clientsRegistrationsReport]);

  function handleInfoClicked(list, type, modalTitle) {
    if (
      ["created-servers", "terminated-servers", "active-servers"].includes(type)
    ) {
      const sortField =
        type === "terminated-servers" ? "terminated_at" : "created_at";

      const modalList = list
        .sort((a, b) => new Date(a[sortField]) - new Date(b[sortField]))
        .map((item) => {
          let additionalText;

          if (type === "created-servers") {
            additionalText = ` - ${format(new Date(item.created_at), "d/M/y")}`;
          } else if (type === "terminated-servers") {
            additionalText = ` - ${format(
              new Date(item.terminated_at),
              "d/M/y"
            )}`;
          }

          return {
            title: item.hostname,
            url: `/${lang}/my-cloud/servers/${item._id}/overview`,
            additionalText,
          };
        });

      setModalList(modalList);

      setModalTitle(`${modalTitle} (${list.length})`);
    } else if (type === "users") {
      const modalList = list
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .map((item) => ({
          title: getFullName(item),
          url: `/${lang}/clients/clients-list/${item._id}`,
          additionalText: ` - ${format(new Date(item.created_at), "d/M/y")}`,
        }));

      setModalList(modalList);

      setModalTitle(`${modalTitle} (${list.length})`);
    }

    setIsBiReportListModalOpen(true);
  }

  function handleBiReportListModalClosed() {
    setIsBiReportListModalOpen(false);
  }

  return (
    <WithRole permission="super-admin.bi-reports">
      <div className={styles.wrapper}>
        <h2 className={styles.mainTitle}>
          <FormattedMessage id="account-bi-reports.title" />
        </h2>

        <hr />

        <Box className={styles.boxWrapper}>
          {!reportData && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}

          {reportData && (
            <>
              <div className={styles.row}>
                <span>
                  <FormattedMessage
                    tagName="b"
                    id="account-bi-reports.clients-registrations"
                  />
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.dailyClientsRegistrations.now,
                        "users",
                        intl.formatMessage({
                          id: "account-bi-reports.clients-registrations",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.today" },
                        {
                          number:
                            reportData.dailyClientsRegistrations.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.dailyClientsRegistrations.than,
                        "users",
                        intl.formatMessage({
                          id: "account-bi-reports.clients-registrations",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.yesterday" },
                        {
                          number:
                            reportData.dailyClientsRegistrations.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <div className={styles.row}>
                <span></span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.weeklyClientsRegistrations.now,
                        "users",
                        intl.formatMessage({
                          id: "account-bi-reports.clients-registrations",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.this-week" },
                        {
                          number:
                            reportData.weeklyClientsRegistrations.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.weeklyClientsRegistrations.than,
                        "users",
                        intl.formatMessage({
                          id: "account-bi-reports.clients-registrations",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.last-week" },
                        {
                          number:
                            reportData.weeklyClientsRegistrations.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <div className={styles.row}>
                <span></span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.monthlyClientsRegistrations.now,
                        "users",
                        intl.formatMessage({
                          id: "account-bi-reports.clients-registrations",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.this-month" },
                        {
                          number:
                            reportData.monthlyClientsRegistrations.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.monthlyClientsRegistrations.than,
                        "users",
                        intl.formatMessage({
                          id: "account-bi-reports.clients-registrations",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.last-month" },
                        {
                          number:
                            reportData.monthlyClientsRegistrations.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <hr />

              <div className={styles.row}>
                <span>
                  <FormattedMessage
                    tagName="b"
                    id="account-bi-reports.created-servers"
                  />
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.dailyCreatedServers.now,
                        "created-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.created-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.today" },
                        {
                          number: reportData.dailyCreatedServers.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.dailyCreatedServers.than,
                        "created-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.created-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.yesterday" },
                        {
                          number: reportData.dailyCreatedServers.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <div className={styles.row}>
                <span></span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.weeklyCreatedServers.now,
                        "created-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.created-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.this-week" },
                        {
                          number: reportData.weeklyCreatedServers.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.weeklyCreatedServers.than,
                        "created-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.created-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.last-week" },
                        {
                          number: reportData.weeklyCreatedServers.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <div className={styles.row}>
                <span></span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.monthlyCreatedServers.now,
                        "created-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.created-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.this-month" },
                        {
                          number: reportData.monthlyCreatedServers.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.monthlyCreatedServers.than,
                        "created-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.created-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.last-month" },
                        {
                          number: reportData.monthlyCreatedServers.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <hr />

              <div className={styles.row}>
                <span>
                  <FormattedMessage
                    tagName="b"
                    id="account-bi-reports.terminated-servers"
                  />
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.dailyTerminatedServers.now,
                        "terminated-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.terminated-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.today" },
                        {
                          number: reportData.dailyTerminatedServers.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.dailyTerminatedServers.than,
                        "terminated-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.terminated-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.yesterday" },
                        {
                          number: reportData.dailyTerminatedServers.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <div className={styles.row}>
                <span></span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.weeklyTerminatedServers.now,
                        "terminated-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.terminated-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.this-week" },
                        {
                          number: reportData.weeklyTerminatedServers.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.weeklyTerminatedServers.than,
                        "terminated-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.terminated-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.last-week" },
                        {
                          number:
                            reportData.weeklyTerminatedServers.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <div className={styles.row}>
                <span></span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.monthlyTerminatedServers.now,
                        "terminated-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.terminated-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.this-month" },
                        {
                          number:
                            reportData.monthlyTerminatedServers.now.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.monthlyTerminatedServers.than,
                        "terminated-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.terminated-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.last-month" },
                        {
                          number:
                            reportData.monthlyTerminatedServers.than.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <hr />

              <div className={styles.row}>
                <span>
                  <FormattedMessage
                    tagName="b"
                    id="account-bi-reports.active-servers"
                  />
                </span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.activeMonthlyServers,
                        "active-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.active-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.monthly" },
                        {
                          number: reportData.activeMonthlyServers.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>

              <div className={styles.row}>
                <span></span>
                <span>
                  <a
                    onClick={() =>
                      handleInfoClicked(
                        reportData.activeHourlyServers,
                        "active-servers",
                        intl.formatMessage({
                          id: "account-bi-reports.active-servers",
                        })
                      )
                    }
                    href="#"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        { id: "account-bi-reports.hourly" },
                        {
                          number: reportData.activeHourlyServers.length,
                          b: (arr) => `<b>${arr[0]}</b>`,
                        }
                      ),
                    }}
                  ></a>
                </span>
              </div>
            </>
          )}
        </Box>
      </div>

      <BiReportListModal
        title={modalTitle}
        list={modalList}
        isOpen={isBiReportListModalOpen}
        onClose={handleBiReportListModalClosed}
      />
    </WithRole>
  );
}
