import styles from "./index.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Box from "../../../../components/box";
import BasicTable from "../../../../components/basic-table";
import IconButton from "../../../../components/icon-button";
import CreateNewUserModal from "../../../../components/modals/create-new-user";
import { getFullName, getInitials } from "../../../../utils/user";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  useUser,
} from "../../../../utils/hooks";
import LayoutMainAction from "../../../../components/layout-main-action";
import Filters from "../../../../components/filters";
import LetterAvatar from "../../../../components/letter-avatar";
import { ReactComponent as EditSvg } from "../../../../components/svgs/edit.svg";
import { ReactComponent as TrashSvg } from "../../../../components/svgs/trash-purple.svg";
import CustomTag from "../../../../components/custom-tag";
import {} from "../../../../utils";

export default function AccountPermissions() {
  const user = useUser();
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const confirm = useConfirm();
  const alert = useAlert();

  const [roles, setRoles] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [isCreateNewUserModalOpen, setIsCreateNewUserModalOpen] = useState(
    false
  );

  const [filterByRole, setFilterByRole] = useState(false);

  const getUsersOfParent = useCallback(async () => {
    const data = await ajax("/roles/getUsersOfParent");

    if (data.result === "success") {
      setUsers(data.users);
    }
  }, [ajax]);

  const getRoles = useCallback(async () => {
    const data = await ajax("/roles/getAll");

    if (data.result === "success") {
      const roles = [
        {
          _id: false,
          title: intl.formatMessage({ id: "general.all" }),
          active: true,
        },
        ...data.roles.map((role) => ({
          _id: role._id,
          title: role.name,
        })),
      ];

      setRoles(roles);
      setActiveItem(roles[0]);
    }
  }, [setRoles, ajax, intl]);

  useEffect(() => {
    getUsersOfParent();
  }, [getUsersOfParent]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  function handleAddNewUserClicked() {
    if (roles.length <= 1) {
      return alert(
        intl.formatMessage({ id: "account-permissions.no-roles.title" }),
        intl.formatMessage({ id: "account-permissions.no-roles.content" })
      );
    }

    setSelectedUser(false);
    setIsCreateNewUserModalOpen(true);
  }

  async function handleCreateNewUserModalClosed(status) {
    if (status) {
      await getUsersOfParent();
    }

    setIsCreateNewUserModalOpen(false);
  }

  function handleEditRolesModalOpen(user) {
    setSelectedUser(user);
    setIsCreateNewUserModalOpen(true);
  }

  async function handleRemoveClicked(user) {
    const res = await confirm({
      title: intl.formatMessage({ id: "account-permissions.remove" }),
      message: intl.formatMessage(
        { id: "account-permissions.remove-message" },
        { email: user.user.email }
      ),
    });

    if (res === "button2") {
      await ajax("/roles/removeUserWithRoles", { userID: user.user._id });
      await getUsersOfParent();
    }
  }

  function handleUsersFilter(user) {
    return !filterByRole._id || filterByRole.title === user.role.name;
  }

  function handleFiltersClicked(item) {
    setActiveItem(item);
    setFilterByRole(item);
  }

  function getLayoutButton() {
    return [
      {
        title: intl.formatMessage({
          id: "account-permissions.add-new-memeber",
        }),
        onClick: handleAddNewUserClicked,
      },
    ];
  }

  if (user.current_parent) {
    return (
      <Box>
        <FormattedMessage id="general.not-allowed-under-parent" />
      </Box>
    );
  }

  return (
    <LayoutMainAction
      title={intl.formatMessage({ id: "account-permissions.title" })}
      buttons={getLayoutButton()}
    >
      <div className={styles.filters}>
        <div>
          <div className={styles.title}>
            <FormattedMessage id="account-permissions.filter-by-roles" />
          </div>
          <div>
            <Filters
              items={roles}
              activeItem={activeItem}
              onClick={handleFiltersClicked}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <IconButton
            color="text"
            textColor="purple"
            href="/[lang]/account/permissions/manage-roles"
            as={`/${lang}/account/permissions/manage-roles`}
          >
            <FormattedMessage id="account-permissions.manage-roles" />
          </IconButton>
          <IconButton
            color="text"
            textColor="purple"
            href="/[lang]/account/permissions/create-new-role"
            as={`/${lang}/account/permissions/create-new-role`}
          >
            <FormattedMessage id="account-permissions.create-new" />
          </IconButton>
        </div>
      </div>

      <Box className={styles.wrapper}>
        <BasicTable>
          <thead>
            <tr>
              <th style={{ width: "60px" }}></th>
              <th>
                <FormattedMessage id="account-permissions.email" />
              </th>
              <th>
                <FormattedMessage id="account-permissions.name" />
              </th>
              <th>
                <FormattedMessage id="account-permissions.role" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.filter(handleUsersFilter).length === 0 && (
              <tr>
                <td colSpan={5}>
                  <FormattedMessage id="general.no-rows" />
                </td>
              </tr>
            )}

            {users.filter(handleUsersFilter).map((user, key) => (
              <tr key={key}>
                <td>
                  <LetterAvatar fullName={`${user.firstName} ${user.lastName}`}>
                    {getInitials(user)}
                  </LetterAvatar>
                </td>
                <td>{user.user.email}</td>
                <td>{getFullName(user.user)}</td>
                <td>
                  <CustomTag color="lighter-purple2">
                    {user.role.name}
                  </CustomTag>
                </td>
                <td>
                  <EditSvg onClick={() => handleEditRolesModalOpen(user)} />
                  <TrashSvg onClick={() => handleRemoveClicked(user)} />
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </Box>

      <CreateNewUserModal
        isOpen={isCreateNewUserModalOpen}
        onClose={handleCreateNewUserModalClosed}
        editUser={selectedUser}
      />
    </LayoutMainAction>
  );
}
