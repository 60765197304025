import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax } from "../../../utils/hooks";
import Checkbox from "../../checkbox";
import { getFullName } from "../../../utils/user";

function AttachSupportersModal({ isOpen, onClose, department }) {
  const ajax = useAjax();

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchUsers = useCallback(async () => {
    if (!department) {
      return;
    }

    const data = await ajax(`/tickets/getSupportersToAssign`);

    data.supporters.forEach((supporter) => {
      supporter.selected = !!department.supporters.find(
        (s) => s._id === supporter._id
      );
    });

    setUsers(data.supporters);
  }, [ajax, department]);

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
    }
  }, [fetchUsers, isOpen]);

  function handleUserSelected(user) {
    user.selected = !user.selected;
    setUsers([...users]);
  }

  function handleSelectAllClicked(select) {
    users.forEach((user) => (user.selected = select));
    setUsers([...users]);
  }

  async function handleSaveClicked() {
    setLoading(true);
    await ajax(`/tickets/assignSupportersToDepartment`, {
      name: department.name,
      supporters: users.filter((supporter) => supporter.selected),
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="attach-supporters-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.selectors}>
          <span onClick={() => handleSelectAllClicked(true)}>
            <FormattedMessage id="general.select-all" />
          </span>
          <span onClick={() => handleSelectAllClicked(false)}>
            <FormattedMessage id="general.deselect-all" />
          </span>
        </div>

        <div>
          {users.map((user, key) => (
            <div key={key} className={styles.row}>
              <Checkbox
                label={<span>{`${user.email} - ${getFullName(user)}`}</span>}
                checked={user.selected || false}
                onClick={() => handleUserSelected(user)}
                readOnly
              />
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleSaveClicked}
        >
          <FormattedMessage id="general.save" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AttachSupportersModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  department: PropTypes.object,
};

export default AttachSupportersModal;
