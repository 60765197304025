import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ReactComponent as LogoSvg } from "../svgs/logo-pb.svg";
import { ReactComponent as LiveChatSvg } from "../svgs/live-chat.svg";
import { ReactComponent as TipSvg } from "../svgs/tip.svg";
import styles from "./index.module.scss";
import IconButton from "../icon-button";
import Counter from "../counter";
import {
  useLang,
  useLimits,
  useRoles,
  useUser,
  useWLDVPS,
} from "../../utils/hooks";
import { userCurrency } from "../../utils/user";
import Checkbox from "../checkbox";

function CreateNewServerLayout({
  children,
  pageTitle,
  extraText,
  greenText,
  bottomBar,
  step,
  isNextButtonDisabled,
  backToSiteClicked = () => {},
  handleNextButtonClicked = () => {},
  loading,

  hostnames,
  setHostnames,

  location,
  backup,
  image,
  ram_mb,
  ssd_gb,
  paymentType,

  priceMonthly,
  priceHourlyOn,
  priceHourlyOff,
  firstMonthPayment,
  addonsPriceMonthly,
  addonsFirstMonthPayment,
  addonsPriceHourlyOn,
  addonsPriceHourlyOff,

  bypassPayment,
  setBypassPayment,

  selectedAddons,
}) {
  const intl = useIntl();
  const lang = useLang();
  const user = useUser();
  const { serversLimit } = useLimits();
  const { isAllowed } = useRoles();
  const wldvps = useWLDVPS();

  const [warning, setWarning] = useState(false);

  useEffect(() => {
    setWarning(
      image &&
        image.value.toLowerCase().includes("windows") &&
        (ram_mb <= 1024 || ssd_gb <= 20)
    );
  }, [image, ram_mb, ssd_gb]);

  function handleAmountChanged(num) {
    const newHostnames = [...hostnames];
    newHostnames.length = num;

    for (let i = 0; i < newHostnames.length; i++) {
      newHostnames[i] = newHostnames[i] || "";
    }

    setHostnames(newHostnames);
  }

  function renderAfterPriceSection() {
    if (!backup) {
      return null;
    }

    return `(${intl.formatMessage({
      id: "create-new-server-layout.backup-included",
    })})`;
  }

  function renderLogo() {
    if (wldvps) {
      return <img src={`/img/wldvps/${wldvps}/logo.png`} />;
    }

    return <LogoSvg />;
  }

  const selectedAddonsArray = useMemo(
    () =>
      selectedAddons
        ? Object.keys(selectedAddons).filter((k) => selectedAddons[k])
        : [],
    [selectedAddons]
  );

  return (
    <div className={styles.LoginLayout}>
      <style>{`
        html,
        body,
        div#root > div,
        div#root > div > div,
        div#root {
          height: 100%;
        }
      `}</style>
      <Link
        href="/[lang]/my-cloud/servers"
        to={`/${lang}/my-cloud/servers`}
        className={styles.mainBack}
      >
        <FormattedMessage id="create-new-server-layout.back-to-site" />
      </Link>

      <Link
        href="/[lang]/my-cloud/servers"
        to={`/${lang}/my-cloud/servers`}
        className={styles.logo}
      >
        {renderLogo()}
      </Link>

      <div className={styles.pageInfoWrapper}>
        <div className={styles.pageTitle}>
          <div className={styles.title}>
            {/* <div className={styles.normal}>
              <FormattedMessage id="general.step" /> {step}/7
            </div> */}
            <div className={styles.bold}>
              <FormattedMessage id="create-new-server-layout.title" />
            </div>
          </div>
          {pageTitle}
          {extraText && <div className={styles.extraText}>{extraText}</div>}
          {greenText && <div className={styles.greenText}>{greenText}</div>}
        </div>
      </div>

      <div className={styles.pageContentWrapper}>
        {children}
        {bottomBar && (
          <div
            className={`${styles.bottomBar} ${
              selectedAddonsArray.length > 0 ? styles.withAddons : ""
            }`}
          >
            <div className={styles.numbers}>
              <div className={styles.amount}>
                <span className={styles.text}>
                  <FormattedMessage id="create-new-server-layout.amount" />:
                </span>
                <Counter
                  count={hostnames.length}
                  setCount={handleAmountChanged}
                  max={serversLimit}
                />
              </div>
              <div className={styles.prices}>
                <span className={styles.summary}>
                  <FormattedMessage id="create-new-server-layout.summary" />:
                </span>

                {paymentType === "monthly" && (
                  <>
                    {
                      <span className={styles.firstMonthPrice}>
                        {userCurrency(user)}
                        {(firstMonthPayment + addonsFirstMonthPayment).toFixed(
                          2
                        )}
                      </span>
                    }
                    <span className={styles.firstMonthText}>
                      <FormattedMessage id="create-new-server-layout.first-month" />
                    </span>
                    <span className={styles.slash}>/</span>
                    {(priceMonthly || addonsPriceMonthly) && (
                      <span className={styles.eachMonthPrice}>
                        {userCurrency(user)}
                        {(priceMonthly + addonsPriceMonthly).toFixed(2)}
                      </span>
                    )}
                    <span className={styles.eachMonthText}>
                      <FormattedMessage id="create-new-server-layout.each-month" />{" "}
                      {renderAfterPriceSection()}
                    </span>
                  </>
                )}

                {paymentType === "hourly" && (
                  <>
                    {
                      <span className={styles.firstMonthPrice}>
                        {userCurrency(user)}
                        {(priceHourlyOn + addonsPriceHourlyOn).toFixed(3)}
                        /hr
                      </span>
                    }
                    <span className={styles.firstMonthText}>
                      <FormattedMessage id="create-new-server-layout.power-on" />
                    </span>

                    <span className={styles.eachMonthPrice}>
                      {userCurrency(user)}
                      {(priceHourlyOff + addonsPriceHourlyOff).toFixed(3)}
                      /hr
                    </span>

                    <span className={styles.eachMonthText}>
                      <FormattedMessage id="create-new-server-layout.power-off" />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div
              className={`${styles.buttonWrapper} ${
                selectedAddonsArray.length > 0 ? styles.withAddons : ""
              }`}
            >
              <IconButton
                icon={step < 8 && "arrow"}
                color="green"
                onClick={handleNextButtonClicked}
                disabled={isNextButtonDisabled}
              >
                {step < 8 && <FormattedMessage id="general.next" />}
                {step === 8 && loading && (
                  <FormattedMessage id="create-new-server-layout.working" />
                )}
                {step === 8 &&
                  !loading &&
                  (hostnames.length > 1 ? (
                    <FormattedMessage
                      id="create-new-server-layout.create-action-many"
                      values={{ total: hostnames.length }}
                    />
                  ) : (
                    <FormattedMessage id="create-new-server-layout.create-action" />
                  ))}
              </IconButton>

              {warning && (
                <span className={`error ${styles.warning}`}>
                  <FormattedMessage id="server-settings-change-plan.windows-error" />
                </span>
              )}

              {isAllowed("super-admin.can-bypass-payment") && (
                <div
                  className={`${styles.checkboxWrapper} ${
                    selectedAddonsArray.length > 0 ? styles.withAddons : ""
                  }`}
                >
                  <Checkbox
                    label="general.bypass-payment"
                    checked={bypassPayment}
                    onChange={() => setBypassPayment(!bypassPayment)}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.tipSection}>
        <hr />
        <div className={styles.wrapper}>
          <div>
            <TipSvg />
          </div>
          <div>
            <div className={styles.tip}>
              <FormattedMessage id="create-new-server.tip" />
            </div>
            <div className={styles.tipContent}>
              <FormattedMessage id="create-new-server.tip.location" />
            </div>
          </div>
        </div>
      </div>

      {!loading && (
        <>
          <div
            className={`${styles.backToSite} ${
              step === 8
                ? hostnames.length > 1
                  ? styles.lastStepLong
                  : styles.lastStep
                : ""
            }`}
            onClick={backToSiteClicked}
          >
            <IconButton color="ghost">
              <FormattedMessage id="general.back" />
            </IconButton>
          </div>

          {step <= 2 && (
            <IconButton
              icon="arrow"
              color="green"
              onClick={handleNextButtonClicked}
              className={styles.nextButton}
              disabled={
                isNextButtonDisabled ||
                (step === 1 && !location) ||
                (step === 2 && !image) ||
                (step === 3 && warning)
              }
            >
              <FormattedMessage id="general.next" />
            </IconButton>
          )}
        </>
      )}
      <div className={styles.needHelp}>
        <span>
          <FormattedMessage id="general.need-help" />
        </span>
        <LiveChatSvg />
      </div>
    </div>
  );
}

CreateNewServerLayout.propTypes = {
  action: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  children: PropTypes.any,
  pageTitle: PropTypes.string,
  extraText: PropTypes.string,
  greenText: PropTypes.string,
  bottomBar: PropTypes.bool,
  step: PropTypes.number,
  isNextButtonDisabled: PropTypes.bool,
  onNextButtonClicked: PropTypes.func,
  backToSiteClicked: PropTypes.func,
  handleNextButtonClicked: PropTypes.func,
  loading: PropTypes.bool,

  hostnames: PropTypes.array,
  setHostnames: PropTypes.func,

  location: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  backup: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  ram_mb: PropTypes.number,
  ssd_gb: PropTypes.number,
  paymentType: PropTypes.string,

  bypassPayment: PropTypes.bool,
  setBypassPayment: PropTypes.func,

  firstMonthPayment: PropTypes.number,
  priceMonthly: PropTypes.number,
  priceHourlyOn: PropTypes.number,
  priceHourlyOff: PropTypes.number,
  addonsPriceMonthly: PropTypes.number,
  addonsFirstMonthPayment: PropTypes.number,
  addonsPriceHourlyOn: PropTypes.number,
  addonsPriceHourlyOff: PropTypes.number,

  selectedAddons: PropTypes.object,
};

export default CreateNewServerLayout;
