import styles from "./overview.module.scss";

import React, { useEffect, useCallback, useState } from "react";
import { format, fromUnixTime } from "date-fns";
import { FormattedMessage } from "react-intl";
import BasicTable from "../../../../../components/basic-table";
import IconButton from "../../../../../components/icon-button";
import {
  useAjax,
  useServer,
  useLang,
  useRoles,
} from "../../../../../utils/hooks";
import { Link } from "react-router-dom";
import TakeSnapshotModal from "../../../../../components/modals/take-snapshot";
import {} from "../../../../../utils";
import { getSocket } from "../../../../../utils/globals";

export default function LatestSnapshots() {
  const server = useServer();
  const ajax = useAjax();
  const lang = useLang();
  const socket = getSocket();
  const { isAllowed } = useRoles();

  const [snapshots, setSnapshots] = useState([]);
  const [isTakeSnapshotModalOpen, setIsTakeSnapshotModalOpen] = useState(false);

  const listSnapshots = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax(`/snapshots/list`, {
      serverID: server._id,
    });

    if (data.result === "success") {
      data.data = data.data.filter((s) => s.snaptime);

      data.data.sort((a, b) => {
        if (a.snaptime === b.snaptime) {
          return 0;
        }
        return a.snaptime > b.snaptime ? 1 : -1;
      });

      data.data = data.data.map((s, idx) => {
        s.isDropdownOpen = false;
        s.index = idx;
        return s;
      });

      setSnapshots(data.data.splice(0, 3));
    } else {
      setSnapshots([]);
    }
  }, [ajax, server]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    listSnapshots();

    socket.on("update-snapshots", () => {
      listSnapshots();
    });

    return () => {
      socket.removeAllListeners("update-snapshots");
    };
  }, [listSnapshots, socket]);

  function handleTakeSnapshotClicked() {
    setIsTakeSnapshotModalOpen(true);
  }

  function handleTakeSnapshotModalClosed() {
    setIsTakeSnapshotModalOpen(false);
  }

  if (!server) {
    return null;
  }

  return (
    <>
      <div className={styles.title}>
        <div className={styles.bold}>
          <FormattedMessage id="overview.latest-snapshots" />
        </div>
        <div className={styles.link}>
          <Link
            href="/[lang]/my-cloud/servers/[id]/snapshots"
            to={`/${lang}/my-cloud/servers/${server._id}/snapshots`}
          >
            <FormattedMessage id="overview.view-all" />
          </Link>
        </div>
      </div>
      <div className={styles.table}>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="general.name" />
              </th>
              <th>
                <FormattedMessage id="general.date" />
              </th>
              <th style={{ width: "25px" }}>
                <FormattedMessage id="general.ram" />
              </th>
            </tr>
          </thead>
          <tbody>
            {snapshots.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <FormattedMessage id="overview.no-snapshots" />
                </td>
              </tr>
            )}

            {snapshots.length > 0 &&
              snapshots.map((snapshot, key) => (
                <tr key={key}>
                  <td>{snapshot.name}</td>
                  <td>
                    {format(
                      fromUnixTime(snapshot.snaptime),
                      "dd.MM.yyyy | HH:mm:ss"
                    )}
                  </td>
                  <td>
                    <FormattedMessage
                      id={`general.${snapshot.vmstate ? "yes" : "no"}`}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </BasicTable>
      </div>
      {isAllowed("servers.create-delete-snapshots") &&
        server.status === "Active" && (
          <div className={styles.button}>
            <IconButton
              disabled={server.isWorking}
              color="light-purple"
              onClick={handleTakeSnapshotClicked}
            >
              <FormattedMessage id="overview.take-snapshot" />
            </IconButton>
          </div>
        )}

      <TakeSnapshotModal
        isOpen={isTakeSnapshotModalOpen}
        onClose={handleTakeSnapshotModalClosed}
      />
    </>
  );
}
