import styles from "./step1.module.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import Select from "react-select";
import CustomText from "../../custom-text";
import CountrySelector from "../../country-selector";
import { useAjax, useInitialDataFetching, useUser } from "../../../utils/hooks";
import IconButton from "../../icon-button";
import { countries } from "../../../utils/countries";
import { citiesAddresses_IL } from "../../../utils/cities";

const countriesArray = Object.keys(countries).map((k, idx) => ({
  label: k,
  value: countries[k],
  key: idx,
}));

function CompleteRegistrationModalStep1({ onNext, onClose }) {
  const intl = useIntl();
  const ajax = useAjax();
  const user = useUser();
  const initialDataFetching = useInitialDataFetching();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [stateRegion, setStateRegion] = useState("");
  const [hideState, setHideState] = useState(false);
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const [cities, setCities] = useState(false);
  const [addresses, setAddresses] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setCompanyName(user.companyName || "");
    setCountry(
      countriesArray.find((country) => country.value === user.countryCode).value
    );
    setStateRegion(user.stateRegion || "");
    setApartmentNumber(user.apartmentNumber || "");
    setPostcode(user.postcode || "");

    if (user.countryCode !== "IL") {
      if (user.city) {
        setCity(user.city);
      }
      if (user.address) {
        setAddress(user.address);
      }
    }
  }, [user]);

  useEffect(() => {
    if (country === "IL") {
      setCities(
        Object.keys(citiesAddresses_IL).map((city, idx) => ({
          label: city,
          value: city,
          key: idx,
        }))
      );

      setHideState(true);

      if (city) {
        setAddresses(
          citiesAddresses_IL[city.value].map((address, idx) => ({
            label: address,
            value: address,
            key: idx,
          }))
        );
      }
    }
  }, [city, country]);

  useEffect(() => {
    if (cities && country === "IL" && !city) {
      setCity(cities.find((city) => city.value === user.city));
    }
  }, [cities, city, country, user]);

  useEffect(() => {
    if (addresses && country === "IL" && !address) {
      setAddress(addresses.find((address) => address.value === user.address));
    }
  }, [address, addresses, country, user]);

  async function handleNextClicked() {
    setError(false);

    let _firstName = firstName.trim();
    let _lastName = lastName.trim();
    let _companyName = companyName.trim();
    let _stateRegion = stateRegion.trim();
    let _apartmentNumber = apartmentNumber.trim();
    let _postcode = postcode.trim();

    if (
      !_firstName ||
      !_lastName ||
      !_companyName ||
      !country ||
      !city ||
      !address ||
      !_apartmentNumber ||
      !_postcode
    ) {
      setError(intl.formatMessage({ id: "general.fill-all-fields" }));
      return;
    }

    setLoading(true);
    await ajax(`/users/updateClientData`, {
      firstName: _firstName,
      lastName: _lastName,
      companyName: _companyName,
      country,
      stateRegion: _stateRegion,
      address: typeof address == "string" ? address : address.value,
      city: typeof city === "string" ? city : city.value,
      apartmentNumber: _apartmentNumber,
      postcode: _postcode,
      updateRegisterStep: true,
    });

    await initialDataFetching();
    setLoading(false);

    onNext();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.boxes}>
        <div className={styles.box}>
          <div className={styles.field}>
            <CustomText
              placeholder={intl.formatMessage({ id: "general.first-name" })}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              autoComplete="given-name"
            />
          </div>
          <div className={styles.field}>
            <CustomText
              placeholder={intl.formatMessage({ id: "general.company-name" })}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              autoComplete="organization"
            />
          </div>
          <div className={styles.field}>
            {!cities && (
              <CustomText
                placeholder={intl.formatMessage({ id: "general.city" })}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            )}
            {cities && (
              <Select
                className={styles.select}
                options={cities}
                value={city}
                onChange={(option) => setCity(option)}
              />
            )}
          </div>
          {!hideState && (
            <div className={styles.field}>
              <CustomText
                placeholder={intl.formatMessage({
                  id: "general.state-region",
                })}
                value={stateRegion}
                onChange={(e) => setStateRegion(e.target.value)}
                autoComplete="street-address"
              />
            </div>
          )}
          <div className={styles.field}>
            <CustomText
              placeholder={intl.formatMessage({
                id: "general.apartment-number",
              })}
              value={apartmentNumber}
              onChange={(e) => setApartmentNumber(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.field}>
            <CustomText
              placeholder={intl.formatMessage({ id: "general.last-name" })}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              autoComplete="family-name"
            />
          </div>
          <div className={styles.field}>
            <CountrySelector
              disabled={true}
              value={country}
              onChange={(value) => setCountry(value)}
            />
          </div>
          <div className={styles.field}>
            {!addresses && (
              <CustomText
                placeholder={intl.formatMessage({ id: "general.address" })}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            )}
            {addresses && (
              <Select
                className={styles.select}
                disabled={!city}
                options={addresses}
                value={address}
                onChange={(option) => setAddress(option)}
              />
            )}
          </div>
          <div className={styles.field}>
            <CustomText
              placeholder={intl.formatMessage({ id: "general.post-ide" })}
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              autoComplete="postal-code"
            />
          </div>
        </div>
      </div>

      {error && (
        <div className={`error ${styles.error}`}>
          <FormattedMessage id="general.fill-all-fields" />
        </div>
      )}

      <div className={styles.buttons}>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleNextClicked}
        >
          <FormattedMessage id="general.next" />
        </IconButton>
        {onClose && (
          <IconButton disabled={loading} color="text" onClick={onClose}>
            <FormattedMessage id="general.cancel" />
          </IconButton>
        )}
      </div>
    </div>
  );
}

CompleteRegistrationModalStep1.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default CompleteRegistrationModalStep1;
