import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";

function ItemWithHeader({ header, children }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{header}</div>
      <div>{children}</div>
    </div>
  );
}

ItemWithHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.any,
};

export default ItemWithHeader;
