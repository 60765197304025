import styles from "./proxmox-settings.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useAlert } from "../../../utils/hooks";
import Box from "../../../components/box";
import CustomReactSelect from "../../../components/custom-react-select";
import CustomText from "../../../components/custom-text";
import Checkbox from "../../../components/checkbox";
import IconButton from "../../../components/icon-button";
import {} from "../../../utils";

const dataCenterOptions = [
  { label: "IL", value: "IL" },
  { label: "NL", value: "NL" },
  { label: "US", value: "US" },
];

const processorsTypeOptions = [
  { label: "host", value: "host" },
  { label: "486", value: "486" },
  { label: "athlon", value: "athlon" },
  { label: "Broadwell", value: "Broadwell" },
  { label: "Broadwell-IBRS", value: "Broadwell-IBRS" },
  { label: "Broadwell-noTSX", value: "Broadwell-noTSX" },
  {
    label: "Broadwell-noTSX-IBRS",
    value: "Broadwell-noTSX-IBRS",
  },
  {
    label: "Cascadelake-Server",
    value: "Cascadelake-Server",
  },
  { label: "Conroe", value: "Conroe" },
  { label: "core2duo", value: "core2duo" },
  { label: "coreduo", value: "coreduo" },
  { label: "EPYC", value: "EPYC" },
  { label: "EPYC-IBPB", value: "EPYC-IBPB" },
  { label: "Haswell", value: "Haswell" },
  { label: "Haswell-IBRS", value: "Haswell-IBRS" },
  { label: "Haswell-noTSX", value: "Haswell-noTSX" },
  {
    label: "Haswell-noTSX-IBRS",
    value: "Haswell-noTSX-IBRS",
  },
  { label: "IvyBridge", value: "IvyBridge" },
  { label: "IvyBridge-IBRS", value: "IvyBridge-IBRS" },
  { label: "KnightsMill", value: "KnightsMill" },
  { label: "kvm32", value: "kvm32" },
  { label: "kvm64", value: "kvm64" },
  { label: "Nehalem", value: "Nehalem" },
  { label: "Nehalem-IBRS", value: "Nehalem-IBRS" },
  { label: "Opteron_G1", value: "Opteron_G1" },
  { label: "Opteron_G2", value: "Opteron_G2" },
  { label: "Opteron_G3", value: "Opteron_G3" },
  { label: "Opteron_G4", value: "Opteron_G4" },
  { label: "Opteron_G5", value: "Opteron_G5" },
  { label: "Penryn", value: "Penryn" },
  { label: "pentium", value: "pentium" },
  { label: "pentium2", value: "pentium2" },
  { label: "pentium3", value: "pentium3" },
  { label: "phenom", value: "phenom" },
  { label: "qemu32", value: "qemu32" },
  { label: "qemu64", value: "qemu64" },
  { label: "SandyBridge", value: "SandyBridge" },
  { label: "SandyBridge-IBRS", value: "SandyBridge-IBRS" },
  { label: "Skylake-Client", value: "Skylake-Client" },
  {
    label: "Skylake-Client-IBRS",
    value: "Skylake-Client-IBRS",
  },
  { label: "Skylake-Server", value: "Skylake-Server" },
  {
    label: "Skylake-Server-IBRS",
    value: "Skylake-Server-IBRS",
  },
  { label: "Westmere", value: "Westmere" },
  { label: "Westmere-IBRS", value: "Westmere-IBRS" },
];

const biosOptions = [
  { label: "seabios", value: "seabios" },
  { label: "ovmf", value: "ovmf" },
];

const modelOptions = [
  { label: "rtl8139", value: "rtl8139" },
  { label: "ne2k_pci", value: "ne2k_pci" },
  { label: "e1000", value: "e1000" },
  { label: "pcnet", value: "pcnet" },
  { label: "virtio", value: "virtio" },
  { label: "ne2k_isa", value: "ne2k_isa" },
  { label: "i82551", value: "i82551" },
  { label: "i82557b", value: "i82557b" },
  { label: "i82559er", value: "i82559er" },
  { label: "vmxnet3", value: "vmxnet3" },
  { label: "e1000-82540em", value: "e1000-82540em" },
  { label: "e1000-82544gc", value: "e1000-82544gc" },
  { label: "e1000-82545em", value: "e1000-82545em" },
];

const formatOptions = [
  { label: "none", value: "none" },
  { label: "qcow2", value: "qcow2" },
];

const cacheOptions = [
  { label: "none", value: "none" },
  { label: "directsync", value: "directsync" },
  { label: "writethrough", value: "writethrough" },
  { label: "writeback", value: "writeback" },
  { label: "unsafe", value: "unsafe" },
];

export default function ManagementProxmoxSettings() {
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();

  const [nodes, setNodes] = useState(false);
  const [systemProducts, setSystemProducts] = useState([]);
  // const [systemProductOptions, setSystemProductOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [proxmoxData, setProxmoxData] = useState(false);

  const [selectedDataCenter, setSelectedDataCenter] = useState(null);
  const [nodeToCreate, setNodeToCreate] = useState(false);
  const [minimumMemory, setMinimumMemory] = useState(0);
  const [ballooningDevice, setBallooningDevice] = useState(false);
  const [processorsType, setProcessorsType] = useState(false);
  const [numa, setNUMA] = useState(false);
  const [bios, setBIOS] = useState(false);
  const [storage, setStorage] = useState(false);
  const [storageOptions, setStorageOptions] = useState([]);

  const [diskImage, setDiskImage] = useState(false);
  const [format, setFormat] = useState(false);
  const [cache, setCache] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [ssdEmulation, setSSDEmulation] = useState(false);
  const [readLimit, setReadLimit] = useState("");
  const [writeLimit, setWriteLimit] = useState("");

  const [diskImage1, setDiskImage1] = useState(false);
  const [format1, setFormat1] = useState(false);
  const [cache1, setCache1] = useState(false);
  const [discard1, setDiscard1] = useState(false);
  const [ssdEmulation1, setSSDEmulation1] = useState(false);
  const [readLimit1, setReadLimit1] = useState("");
  const [writeLimit1, setWriteLimit1] = useState("");

  const [model, setModel] = useState(false);
  const [rateLimit, setRateLimit] = useState("");

  const [loading, setLoading] = useState(false);

  const getSystemProducts = useCallback(async () => {
    setLoading(true);

    const data = await ajax(`/system-products/getSystemProducts`);

    if (data.result === "error") {
      return;
    }

    setNodes(data.nodes);
    setSystemProducts(data.systemProducts);

    setLoading(false);
  }, [ajax]);

  useEffect(() => {
    getSystemProducts();
  }, [getSystemProducts]);

  useEffect(() => {
    const systemProductOptions = systemProducts.map((p) => ({
      label: p,
      value: p,
    }));

    // setSystemProductOptions(systemProductOptions);

    setSelectedProduct(systemProductOptions[0]);
  }, [intl, systemProducts]);

  useEffect(() => {
    async function getProxmoxDataForSystemProduct() {
      setLoading(true);

      const data = await ajax(
        `/system-products/getProxmoxDataForSystemProduct`,
        { selectedProduct: selectedProduct.value }
      );

      if (data.proxmoxData) {
        setProxmoxData(data.proxmoxData);
      }

      setLoading(false);
    }

    if (!selectedProduct) {
      return;
    }

    getProxmoxDataForSystemProduct();
  }, [ajax, selectedProduct]);

  useEffect(() => {
    if (selectedDataCenter && proxmoxData[selectedDataCenter.value]) {
      setNodeToCreate({
        label: proxmoxData[selectedDataCenter.value].nodeToCreate,
        value: proxmoxData[selectedDataCenter.value].nodeToCreate,
      });
      setMinimumMemory(
        proxmoxData[selectedDataCenter.value].minimumMemory || ""
      );
      setBallooningDevice(
        proxmoxData[selectedDataCenter.value].ballooningDevice || false
      );
      setProcessorsType({
        label: proxmoxData[selectedDataCenter.value].processorsType,
        value: proxmoxData[selectedDataCenter.value].processorsType,
      });
      setNUMA(proxmoxData[selectedDataCenter.value].numa || false);
      setBIOS({
        label: proxmoxData[selectedDataCenter.value].bios,
        value: proxmoxData[selectedDataCenter.value].bios,
      });
      setStorage({
        label: proxmoxData[selectedDataCenter.value].storage,
        value: proxmoxData[selectedDataCenter.value].storage,
      });

      setDiskImage({
        label: proxmoxData[selectedDataCenter.value].diskImage,
        value: proxmoxData[selectedDataCenter.value].diskImage,
      });
      setFormat({
        label: proxmoxData[selectedDataCenter.value].format,
        value: proxmoxData[selectedDataCenter.value].format,
      });
      setCache({
        label: proxmoxData[selectedDataCenter.value].cache,
        value: proxmoxData[selectedDataCenter.value].cache,
      });
      setDiscard(proxmoxData[selectedDataCenter.value].discard || false);
      setSSDEmulation(
        proxmoxData[selectedDataCenter.value].ssdEmulation || false
      );
      setReadLimit(proxmoxData[selectedDataCenter.value].readLimit || "");
      setWriteLimit(proxmoxData[selectedDataCenter.value].writeLimit || "");

      setDiskImage1({
        label: proxmoxData[selectedDataCenter.value].diskImage1,
        value: proxmoxData[selectedDataCenter.value].diskImage1,
      });
      setFormat1({
        label: proxmoxData[selectedDataCenter.value].format1,
        value: proxmoxData[selectedDataCenter.value].format1,
      });
      setCache1({
        label: proxmoxData[selectedDataCenter.value].cache1,
        value: proxmoxData[selectedDataCenter.value].cache1,
      });
      setDiscard1(proxmoxData[selectedDataCenter.value].discard1 || false);
      setSSDEmulation1(
        proxmoxData[selectedDataCenter.value].ssdEmulation1 || false
      );
      setReadLimit1(proxmoxData[selectedDataCenter.value].readLimit1 || "");
      setWriteLimit1(proxmoxData[selectedDataCenter.value].writeLimit1 || "");

      setModel({
        label: proxmoxData[selectedDataCenter.value].model,
        value: proxmoxData[selectedDataCenter.value].model,
      });
      setRateLimit(proxmoxData[selectedDataCenter.value].rateLimit || "");
    }
  }, [selectedDataCenter, proxmoxData]);

  const getProxmoxDataForStorages = useCallback(async () => {
    if (!selectedDataCenter) {
      return;
    }

    const data = await ajax("/system-products/getProxmoxDataForStorages", {
      dataCenter: selectedDataCenter.value,
    });

    setStorageOptions(
      data.storages.map((item) => ({
        label: item,
        value: item,
      }))
    );
  }, [ajax, selectedDataCenter]);

  useEffect(() => {
    getProxmoxDataForStorages();
  }, [getProxmoxDataForStorages]);

  async function handleSaveClicked() {
    setLoading(true);

    await ajax(`/system-products/setProxmoxDataForSystemProduct`, {
      name: selectedProduct.value,
      dataCenter: selectedDataCenter.value,
      nodeToCreate: nodeToCreate.value,
      minimumMemory,
      ballooningDevice,
      processorsType: processorsType.value,
      numa,
      bios: bios.value,
      storage: storage.value,

      diskImage: diskImage.value,
      format: format.value,
      cache: cache.value,
      discard,
      ssdEmulation,
      readLimit,
      writeLimit,

      diskImage1: diskImage1.value,
      format1: format1.value,
      cache1: cache1.value,
      discard1,
      ssdEmulation1,
      readLimit1,
      writeLimit1,

      model: model.value,
      rateLimit,
    });

    setLoading(false);

    await alert(
      intl.formatMessage({
        id: "management-proxmox-settings.settings-for-product",
      }),
      intl.formatMessage({ id: "management-proxmox-settings.settings-saved" })
    );
  }

  function renderHardDisk(diskNum) {
    return (
      <Box title={`Hard disk (scsi${diskNum})`}>
        <div className={styles.row}>
          <span>Disk image</span>
          <CustomReactSelect
            options={storageOptions}
            value={diskNum === 0 ? diskImage : diskImage1}
            onChange={(e) =>
              diskNum === 0 ? setDiskImage(e) : setDiskImage1(e)
            }
          />
        </div>

        <div className={styles.row}>
          <span>Format</span>
          <CustomReactSelect
            options={formatOptions}
            value={diskNum === 0 ? format : format1}
            onChange={(e) => (diskNum === 0 ? setFormat(e) : setFormat1(e))}
          />
        </div>

        <div className={styles.row}>
          <span>Cache</span>
          <CustomReactSelect
            options={cacheOptions}
            value={diskNum === 0 ? cache : cache1}
            onChange={(e) => (diskNum === 0 ? setCache(e) : setCache1(e))}
          />
        </div>

        <div className={styles.row}>
          <span>Discard</span>
          <Checkbox
            checked={diskNum === 0 ? discard : discard1}
            onClick={() =>
              diskNum === 0 ? setDiscard(!discard) : setDiscard1(!discard1)
            }
            readOnly
          />
        </div>

        <div className={styles.row}>
          <span>SSD emulation</span>
          <Checkbox
            checked={diskNum === 0 ? ssdEmulation : ssdEmulation1}
            onClick={() =>
              diskNum === 0
                ? setSSDEmulation(!ssdEmulation)
                : setSSDEmulation1(!ssdEmulation1)
            }
            readOnly
          />
        </div>

        <div className={styles.row}>
          <span>Read limit</span>
          <CustomText
            value={diskNum === 0 ? readLimit : readLimit1}
            onChange={(e) =>
              diskNum === 0
                ? setReadLimit(e.target.value)
                : setReadLimit1(e.target.value)
            }
          />
        </div>

        <div className={styles.row}>
          <span>Write limit</span>
          <CustomText
            value={diskNum === 0 ? writeLimit : writeLimit1}
            onChange={(e) =>
              diskNum === 0
                ? setWriteLimit(e.target.value)
                : setWriteLimit1(e.target.value)
            }
          />
        </div>
      </Box>
    );
  }

  return (
    <WithRole permission="super-admin.proxomx-settings">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-proxmox-settings.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box className={styles.box}>
            {/* <div className={styles.row}>
              <span>
                <FormattedMessage id="management-proxmox-settings.settings-for-product" />
              </span>
              <CustomReactSelect
                options={systemProductOptions}
                value={selectedProduct}
                onChange={(item) => setSelectedProduct(item)}
              />
            </div> */}
            {selectedProduct && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="management-proxmox-settings.settings-for-product" />
                </span>
                <CustomReactSelect
                  options={dataCenterOptions}
                  value={selectedDataCenter}
                  onChange={(item) => {
                    setSelectedDataCenter(item);
                  }}
                />
              </div>
            )}
          </Box>

          {selectedDataCenter && (
            <>
              <Box
                className={styles.box}
                title={intl.formatMessage({
                  id: "management-proxmox-settings.hardware.where-to-create",
                })}
              >
                <CustomReactSelect
                  options={nodes
                    .filter(
                      (n) =>
                        n
                          .toLowerCase()
                          .indexOf(selectedDataCenter.value.toLowerCase()) > -1
                    )
                    .map((node) => ({ label: node, value: node }))}
                  value={nodeToCreate}
                  onChange={(item) => {
                    setNodeToCreate(item);
                  }}
                />
              </Box>

              <Box className={styles.box} title="Memory">
                <div className={styles.row}>
                  <span>Minimum memory</span>
                  <CustomText
                    value={minimumMemory}
                    onChange={(e) => setMinimumMemory(e.target.value)}
                  />
                </div>
                <div className={styles.row}>
                  <span>Ballooning device</span>
                  <Checkbox
                    checked={ballooningDevice}
                    onClick={() => setBallooningDevice(!ballooningDevice)}
                    readOnly
                  />
                </div>
              </Box>

              <Box className={styles.box} title="Processors">
                <div className={styles.row}>
                  <span>Processors type</span>
                  <CustomReactSelect
                    options={processorsTypeOptions}
                    value={processorsType}
                    onChange={(item) => setProcessorsType(item)}
                  />
                </div>
                <div className={styles.row}>
                  <span>Enable numa</span>
                  <Checkbox
                    checked={numa}
                    onClick={() => setNUMA(!numa)}
                    readOnly
                  />
                </div>
              </Box>

              <Box className={styles.box} title="BIOS">
                <div className={styles.row}>
                  <span>Bios</span>
                  <CustomReactSelect
                    options={biosOptions}
                    value={bios}
                    onChange={(item) => {
                      setBIOS(item);
                    }}
                  />
                </div>
              </Box>

              <Box className={styles.box} title="CloudInit drive">
                <CustomReactSelect
                  options={storageOptions}
                  value={storage}
                  onChange={(item) => {
                    setStorage(item);
                  }}
                />
              </Box>

              {renderHardDisk(0)}

              {renderHardDisk(1)}

              <Box className={styles.box} title="Network device">
                <div className={styles.row}>
                  <span>Model</span>
                  <CustomReactSelect
                    options={modelOptions}
                    value={model}
                    onChange={(item) => setModel(item)}
                  />
                </div>

                <div className={styles.row}>
                  <span>Write limit</span>
                  <CustomText
                    value={rateLimit}
                    onChange={(e) => setRateLimit(e.target.value)}
                  />
                </div>
              </Box>

              <IconButton
                color="light-purple"
                disabled={loading}
                onClick={handleSaveClicked}
              >
                <FormattedMessage id="general.save" />
              </IconButton>
            </>
          )}
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
