import styles from "./confirm-password.module.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLang, useAjax, useAlert } from "../../utils/hooks";
import LoginLayout from "../../components/login-layout";
import TextField from "../../components/textfield";
import IconButton from "../../components/icon-button";
import {} from "../../utils";
import KeyDetector from "../../components/key-detector";

export default function ConfirmPassword() {
  const router = useHistory();
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const alert = useAlert();

  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    if (!token || !token.trim()) {
      return router.push(`/${lang}/login`);
    }
  }, [lang, router]);

  async function handleVerifyClicked() {
    const url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    setError(false);

    if (password.trim() === "" || token.trim() === "") {
      return setError(
        intl.formatMessage({
          id: "confirm-password.empty-field",
        })
      );
    }

    setLoading(true);
    const data = await ajax(`/users/verifyPassword`, {
      token: decodeURIComponent(token),
      password,
    });

    if (data.result === "success") {
      await alert(
        intl.formatMessage({ id: "confirm-password.title" }),
        intl.formatMessage({
          id: "confirm-password.verification-succeeded",
        })
      );
      router.push(`/${lang}/login`);
      return;
    }

    setLoading(false);
    setError(intl.formatMessage({ id: "confirm-password.wrong-password" }));
  }

  return (
    <LoginLayout>
      <div className={styles.register}>
        <div className={`mb8 ${styles.bold}`}>
          <FormattedMessage id="confirm-password.title" />
        </div>
        <div
          className={`mb28 ${styles.normal}`}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "confirm-password.subtitle" }),
          }}
        ></div>
        <KeyDetector className="mb16" onKeyDetected={handleVerifyClicked}>
          <TextField
            icon="user"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.password" })}
          />
        </KeyDetector>
        {error && <div className={`mb28 ${styles.errorWrapper}`}>{error}</div>}
        <div className="mb28">
          <IconButton
            disabled={loading}
            icon="arrow"
            onClick={handleVerifyClicked}
            color="purple"
            className={styles.registerButton}
          >
            <FormattedMessage id="confirm-password.verify" />
          </IconButton>
        </div>
        <div className={styles.backWrapper}>
          <Link href={`/[lang]/login`} to={`/${lang}/login`}>
            <FormattedMessage id="forgot-password.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
