import styles from "./password.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { format } from "date-fns";
import PasswordStrengthBar from "react-password-strength-bar";
import { useAjax, useAlert, useUser } from "../../utils/hooks";
import CustomText from "../custom-text";
import IconButton from "../icon-button";
import GeneratePasswordModal from "../modals/generate-password";

function SecurityPassword({ inModal }) {
  const user = useUser();
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    isGeneratePasswordModalOpen,
    setIsGeneratePasswordModalOpen,
  ] = useState(false);

  async function handleChangeClicked() {
    setError(false);

    if (passwordScore <= 1) {
      return setError(intl.formatMessage({ id: "general.weak-password" }));
    } else if (password !== passwordConfirm) {
      return setError(intl.formatMessage({ id: "general.password-mismatch" }));
    }

    setLoading(true);
    const data = await ajax("/users/updatePassword", {
      password,
    });
    setLoading(false);

    if (data.result === "error") {
      return setError(intl.formatMessage({ id: "general.same-password" }));
    }

    if (inModal) {
      inModal();
    } else {
      await alert(
        intl.formatMessage({ id: "security-password.change-password.title" }),
        intl.formatMessage({ id: "security-password.change-password.content" })
      );
    }
  }

  function handleGeneratePasswordModalOpen() {
    setIsGeneratePasswordModalOpen(true);
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
      setPasswordConfirm(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  if (!user._id) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {user.passwordLastTimeChanged && (
        <div className={styles.lastTimeWrapper}>
          <FormattedMessage
            id={
              inModal
                ? "security-password.last-time-changed-in-modal"
                : "security-password.last-time-changed"
            }
            values={{
              date: format(
                new Date(user.passwordLastTimeChanged),
                "dd/MM/yy HH:mm"
              ),
            }}
          />
        </div>
      )}

      <div className={styles.row}>
        <label>
          <span>
            <FormattedMessage id="security-password.new-password" />
          </span>
          <CustomText
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
      </div>
      <div className={styles.row}>
        <label>
          <span>
            <FormattedMessage id="security-password.confirm-password" />
          </span>
          <CustomText
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </label>
      </div>

      <div className={styles.passwordStrengthBarWrapper}>
        <PasswordStrengthBar
          password={password}
          onChangeScore={setPasswordScore}
        />
      </div>

      {error && <div className={`error ${styles.error}`}>{error}</div>}

      <div
        className={`${styles.buttonsWrapper} ${inModal ? styles.inModal : ""}`}
      >
        <IconButton
          disabled={loading}
          color="light-purple"
          onClick={handleChangeClicked}
        >
          <FormattedMessage id="security-password.change" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="light-purple"
          onClick={handleGeneratePasswordModalOpen}
        >
          <FormattedMessage id="security-password.generate" />
        </IconButton>
      </div>

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
    </div>
  );
}

SecurityPassword.propTypes = {
  inModal: PropTypes.func,
};

export default SecurityPassword;
