import styles from "./gmail-blacklist.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import BasicTable from "../../../components/basic-table";
import { useAjax, useAlert } from "../../../utils/hooks";
import {} from "../../../utils";
import CustomText from "../../../components/custom-text";
import IconButton from "../../../components/icon-button";
import { ReactComponent as TrashSvg } from "../../../components/svgs/trash-purple.svg";
import isValidDomain from "is-valid-domain";
import validator from "validator";

export default function SupportGmailTickets() {
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();

  const [list, setList] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getGmailBlacklist = useCallback(async () => {
    const data = await ajax("/tickets/getGmailBlacklist");

    setList(data.list);
  }, [ajax]);

  useEffect(() => {
    getGmailBlacklist();
  }, [getGmailBlacklist]);

  function handleItemChanged(e, idx) {
    list[idx] = e.target.value;

    setList([...list]);
  }

  function handleAddItemClicked() {
    list.push("");
    setList([...list]);
  }

  function handleRemoveItemClicked(idx) {
    list.splice(idx, 1);
    setList([...list]);
  }

  async function handleSaveClicked() {
    const newList = list.filter((item) => item);

    setList(newList);
    setError(false);

    for (let i = 0; i < newList.length; i++) {
      if (
        renderType(newList[i]) ===
        intl.formatMessage({ id: "support-gmail-tickets.error" })
      ) {
        return setError(
          intl.formatMessage({ id: "support-gmail-tickets.fix-errors" })
        );
      }
    }

    setLoading(true);
    await ajax("/tickets/setGmailBlacklist", { list });
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "support-gmail-tickets.save.title" }),
      intl.formatMessage({ id: "support-gmail-tickets.save.content" })
    );
  }

  function renderType(item) {
    if (!item) {
      return intl.formatMessage({ id: "support-gmail-tickets.empty" });
    } else if (isValidDomain(item)) {
      return intl.formatMessage({ id: "support-gmail-tickets.domain" });
    } else if (validator.isEmail(item)) {
      return intl.formatMessage({ id: "support-gmail-tickets.email" });
    }

    return intl.formatMessage({ id: "support-gmail-tickets.error" });
  }

  return (
    <WithRole permission="super-admin.gmail-blacklist">
      <LayoutMainAction
        title={intl.formatMessage({ id: "support-gmail-tickets.main-title" })}
      >
        <div className={styles.wrapper}>
          <Box>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="support-gmail-tickets.email-domain" />
                  </th>
                  <th style={{ width: "90px" }}>
                    <FormattedMessage id="support-gmail-tickets.type" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <FormattedMessage id="support-gmail-tickets.no-rows" />
                    </td>
                  </tr>
                )}

                {list.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <CustomText
                        value={item}
                        onChange={(e) => handleItemChanged(e, key)}
                      />
                    </td>
                    <td>{renderType(item)}</td>
                    <td>
                      <TrashSvg
                        className={styles.trash}
                        onClick={() => handleRemoveItemClicked(key)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>

            {error && <div className="error">{error}</div>}

            <div className={styles.buttons}>
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={handleAddItemClicked}
              >
                <FormattedMessage id="general.add" />
              </IconButton>
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={handleSaveClicked}
              >
                <FormattedMessage id="general.save" />
              </IconButton>
            </div>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
