import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as LongArrowRight } from "../svgs/long-arrow-right.svg";
import { ReactComponent as PlusSvg } from "../svgs/plus.svg";
import { ReactComponent as ArrowButtonSvg } from "../svgs/arrow-buttom.svg";
import styles from "./index.module.scss";

function IconButton({
  onClick,
  color,
  disabled,
  className = "",
  children,
  icon = false,
  textColor = false,
  href = false,
  as = false,
  target = null,
}) {
  function renderATag() {
    return (
      <a
        href={href}
        target={target}
        disabled={disabled}
        className={`${className} ${styles.IconButton} ${
          styles[`color-${color}`]
        } ${color === "green" ? "grow" : ""} ${
          textColor ? styles[textColor] : ""
        }`}
      >
        <div>{children}</div>
        {icon === "arrow" && <LongArrowRight />}
        {icon === "plus" && <PlusSvg />}
        {icon === "small-arrow" && <ArrowButtonSvg />}
      </a>
    );
  }

  if (target === "_blank") {
    return renderATag();
  }

  if (href) {
    return (
      <Link
        href={href}
        to={as}
        target={target}
        disabled={disabled}
        className={`${className} ${styles.IconButton} ${
          styles[`color-${color}`]
        } ${color === "green" ? "grow" : ""} ${
          textColor ? styles[textColor] : ""
        }`}
      >
        <div>{children}</div>
        {icon === "arrow" && <LongArrowRight />}
        {icon === "plus" && <PlusSvg />}
        {icon === "small-arrow" && <ArrowButtonSvg />}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${styles.IconButton} ${
        styles[`color-${color}`]
      } ${color === "green" ? "grow" : ""} ${
        textColor ? styles[textColor] : ""
      } ${disabled ? styles.disabled : ""}`}
    >
      <div>{children}</div>
      {icon === "arrow" && <LongArrowRight />}
      {icon === "plus" && <PlusSvg />}
      {icon === "small-arrow" && <ArrowButtonSvg />}
    </button>
  );
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  textColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  href: PropTypes.string,
  as: PropTypes.string,
  target: PropTypes.string,
};

export default IconButton;
