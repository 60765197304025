import styles from "./boot-order.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import Box from "../../../../../../components/box";
import { useAjax, useConfirm, useServer } from "../../../../../../utils/hooks";
import { proxmoxStringToObject } from "../../../../../../utils";
import { getTotalSCSI, getTotalSata } from "../../../../../../utils/networks";
import IconButton from "../../../../../../components/icon-button";
import { FormattedMessage, useIntl } from "react-intl";

function renderValue(value) {
  if (value.includes("scsi")) {
    return value.replace("scsi", "Disk ");
  }
  if (value.includes("sata")) {
    return value.replace("sata", "Disk ");
  }
  if (value.includes("ide")) {
    return "CD-ROM";
  }
}

const SortableItem = SortableElement(({ idx, value }) => (
  <li className="li-boot-order">
    <div className={styles.bootItemWrapper}>
      <span>{idx + 1}</span>
      {renderValue(value)}
    </div>
  </li>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className={styles.listUL}>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value}`}
          index={index}
          value={value}
          idx={index}
        />
      ))}
    </ul>
  );
});

export default function ServerSettingsBootOrder() {
  const ajax = useAjax();
  const server = useServer();
  const confirm = useConfirm();
  const intl = useIntl();

  const [bootOrder, setBootOrder] = useState(null);

  const [loading, setLoading] = useState(false);

  const fetchConfig = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax("/proxmox/nodes/qemu/getConfig", {
      serverID: server._id,
    });

    if (data.result === "success" && data.data) {
      if (data.data.boot === "cdn") {
        data.data.boot = "order=sata0;ide0;net0";
      }

      const scsiArr = new Array(getTotalSCSI(data.data));
      const sataArr = new Array(getTotalSata(data.data));

      const bootOrder = [];
      const boots = proxmoxStringToObject(data.data.boot).order.split(";");

      boots.forEach((boot) => {
        if (boot.includes("ide")) {
          bootOrder.push(boot);
        }
        if (boot.includes("scsi")) {
          const scsiNum = parseInt(boot.replace("scsi", ""));

          bootOrder.push(boot);
          scsiArr[scsiNum] = true;
        }
        if (boot.includes("sata")) {
          const sataNum = parseInt(boot.replace("sata", ""));

          bootOrder.push(boot);
          sataArr[sataNum] = true;
        }
      });

      for (let i = 0; i < scsiArr.length; i++) {
        if (!scsiArr[i]) {
          bootOrder.push(`scsi${i}`);
        }
      }

      setBootOrder(bootOrder);
    }
  }, [ajax, server]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  function onSortEnd({ oldIndex, newIndex }) {
    setBootOrder(arrayMove(bootOrder, oldIndex, newIndex));
  }

  async function handleUpdateClicked() {
    let state = await confirm({
      title: intl.formatMessage({
        id: "server-settings-boot-order.update.title",
      }),
      message: intl.formatMessage({
        id: "server-settings-boot-order.update.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax("/servers/updateBootOrder", {
      serverID: server._id,
      boot: `order=${bootOrder.join(";")}`,
    });
    setLoading(false);

    if (server.qemuStatus.status === "stopped") {
      return;
    }

    state = await confirm({
      title: intl.formatMessage({
        id: "server-settings-boot-order.reboot.title",
      }),
      message: intl.formatMessage({
        id: "server-settings-boot-order.reboot.content",
      }),
      button1: {
        text: intl.formatMessage({ id: "general.no" }),
        color: "white",
      },
      button2: {
        text: intl.formatMessage({
          id: "server-settings-boot-order.reboot.yes-restart",
        }),
        color: "white",
      },
    });

    if (state === "button2") {
      await ajax("/servers/action", {
        serversIds: [server._id],
        actionType: "reset",
        isSoft: false,
      });
    }
  }

  function preventDefault(e) {
    e.preventDefault();
  }

  return (
    <div className={styles.wrapper}>
      <Box className={styles.box}>
        <div className={styles.text}>
          <FormattedMessage id="server-settings-boot-order.drag-drop" />
        </div>

        <div onContextMenu={preventDefault}>
          {bootOrder && (
            <SortableList items={bootOrder} onSortEnd={onSortEnd} />
          )}
        </div>

        <IconButton
          disabled={loading || server?.isWorking}
          color="light-purple"
          onClick={handleUpdateClicked}
        >
          <FormattedMessage id="general.update" />
        </IconButton>
      </Box>
    </div>
  );
}
