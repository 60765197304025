import styles from "./notes.module.scss";

import React, { useEffect, useState } from "react";
import Box from "../../../../../components/box";
import { FormattedMessage } from "react-intl";
import { useAjax, useServer, useWLDVPS } from "../../../../../utils/hooks";
import IconButton from "../../../../../components/icon-button";
import CustomText from "../../../../../components/custom-text";
import { WithRole } from "../../../../../components/with-role";
import {} from "../../../../../utils";

export default function ServerNotes() {
  const server = useServer();
  const ajax = useAjax();
  const wldvps = useWLDVPS();

  const [notes, setNotes] = useState("");

  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!server) {
      return;
    }

    setNotes(wldvps ? server.whitelabel_notes : server.notes);
  }, [server, wldvps]);

  async function handleSaveClicked() {
    setLoading(true);
    await ajax("/servers/updateServerNotes", { notes, serverID: server._id });
    setLoading(false);

    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 2000);
  }

  return (
    <WithRole permission="admin.servers.notes">
      <div className={styles.wrapper}>
        <Box>
          <div className={styles.title}>
            <FormattedMessage id="server-notes.title" />
          </div>

          <CustomText
            type="textarea"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />

          {updated && (
            <div className={`success ${styles.success}`}>
              <FormattedMessage id="server-notes.success" />
            </div>
          )}

          <IconButton
            disabled={loading}
            color="light-purple"
            onClick={handleSaveClicked}
          >
            <FormattedMessage id="general.save" />
          </IconButton>
        </Box>
      </div>
    </WithRole>
  );
}
