import styles from "./index.module.scss";

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRoles, useUser } from "../../utils/hooks";
import Box from "../box";
import { getFullName } from "../../utils/user";

export function WithRole({ permission, children }) {
  const intl = useIntl();
  const user = useUser();
  const { isAllowed } = useRoles();

  function renderNotAllowed() {
    let parentUser;

    if (user && Array.isArray(user.roles)) {
      const usr = user.roles.find(
        (role) => role.parent_user_id === user.current_parent
      );

      if (usr) {
        parentUser = usr.parentUser;
      }
    }

    return (
      <div className={styles.notAllowed}>
        <Box>
          <FormattedMessage
            id="general.not-allowed"
            values={{
              name: parentUser
                ? getFullName(parentUser)
                : intl.formatMessage({
                    id: "general.not-allowed-administrator",
                  }),
            }}
          />
        </Box>
      </div>
    );
  }

  if (!isAllowed(permission)) {
    return renderNotAllowed();
  }

  return children || null;
}
