import styles from "./servers-and-prices.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as TrashSvg } from "../../../components/svgs/trash.svg";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import Box from "../../../components/box";
import { useAjax, useAlert, usePrompt, useWLDVPS } from "../../../utils/hooks";
import CustomReactSelect from "../../../components/custom-react-select";
import IconButton from "../../../components/icon-button";
import { Collapse } from "reactstrap";
import CustomText from "../../../components/custom-text";
import BasicTable from "../../../components/basic-table";
import Checkbox from "../../../components/checkbox";
import {} from "../../../utils";

export default function ManagementProductsAndPrices() {
  const ajax = useAjax();
  const intl = useIntl();
  const alert = useAlert();
  const wldvps = useWLDVPS();
  const prompt = usePrompt();

  const [productsAndPrices, setProductsAndPrices] = useState([]);
  const [baseProductsAndPrices, setBaseProductsAndPrices] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [collapseStatus, setCollapseStatus] = useState(-1);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    const data = await ajax(`/admin/getProductsAndPrices`);

    if (data.result === "error") {
      return;
    }

    setProductsAndPrices(data.productsAndPrices);
    setBaseProductsAndPrices(data.baseProductsAndPrices);
  }, [ajax]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (wldvps && !selectedProduct && productsAndPrices.length > 0) {
      setSelectedProduct({
        label: productsAndPrices[0].name,
        value: productsAndPrices[0],
      });
    }
  }, [wldvps, selectedProduct, productsAndPrices]);

  function isDisabled() {
    return selectedProduct.value.name !== "DreamCloud VPS";
  }

  function handleInputClicked(e) {
    selectedProduct.value.prices[
      e.target
        .closest("[data-field]")
        .parentElement.closest("[data-field]").dataset.field
    ][parseInt(e.target.closest("[data-field]").dataset.fieldidx)][
      e.target.closest("[data-field]").dataset.field
    ] = e.target.checked;

    setSelectedProduct({ ...selectedProduct });
  }

  function handleInputChanged(e) {
    selectedProduct.value.prices[
      e.target
        .closest("[data-field]")
        .parentElement.closest("[data-field]").dataset.field
    ][parseInt(e.target.closest("[data-field]").dataset.fieldidx)][
      e.target.closest("[data-field]").dataset.field
    ] = e.target.value;

    setSelectedProduct({ ...selectedProduct });
  }

  function handleInputOtherChanged(e) {
    selectedProduct.value.prices[
      e.target.closest("[data-field]").dataset.field
    ] = e.target.value;

    setSelectedProduct({ ...selectedProduct });
  }

  function handleAddRowClicked(e) {
    const field = e.target.closest("[data-field]").dataset.field;
    let data;

    if (field === "location") {
      data = { city: "", country: "", code: "", price: 0 };
    } else if (field === "image") {
      data = {
        value: "",
        os: "",
        version: "",
        platform: "",
        price: 0,
        zfs_vmid_il: 0,
        zfs_disk_il: 0,
        zfs_vmid_nl: 0,
        zfs_disk_nl: 0,
        linked_clone_node_il: "",
        linked_clone_vmid_il: "",
        linked_clone_node_nl: "",
        linked_clone_vmid_nl: "",
        linked_clone_node_us: "",
        linked_clone_vmid_us: "",
        enable: false,
        only_for_admin: false,
      };
    } else {
      data = { value: 0, price: 0 };
    }

    selectedProduct.value.prices[
      e.target.closest("[data-field]").dataset.field
    ].push(data);
    setSelectedProduct({ ...selectedProduct });
  }

  function handleSortClicked(e) {
    selectedProduct.value.prices[
      e.target.closest("[data-field]").dataset.field
    ].sort((a, b) => a.value - b.value);

    setSelectedProduct({ ...selectedProduct });
  }

  function handleRemoveRowClicked(e) {
    selectedProduct.value.prices[
      e.target.closest("[data-field]").dataset.field
    ].splice(parseInt(e.target.closest("[data-fieldidx]").dataset.fieldidx), 1);

    setSelectedProduct({ ...selectedProduct });
  }

  async function handleUpdatedClicked() {
    setLoading(true);
    await ajax(`/admin/savePricesForProduct`, {
      product: selectedProduct.value,
    });
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "management-servers-and-prices.title" }),
      intl.formatMessage({ id: "management-servers-and-prices.content" })
    );
  }

  async function handleAutoPricesClicked(field) {
    let value = await prompt({
      title: intl.formatMessage({
        id: "management-servers-and-prices.auto-prec-title",
      }),
      message: intl.formatMessage({
        id: "management-servers-and-prices.auto-prec-content",
      }),
    });

    if (value) {
      value = parseFloat(value);

      if (isNaN(value)) {
        return;
      }

      for (let i = 0; i < selectedProduct.value.prices[field].length; i++) {
        selectedProduct.value.prices[field][i].price =
          baseProductsAndPrices.prices[field][i].price +
          baseProductsAndPrices.prices[field][i].price * (value / 100);
      }

      setSelectedProduct({ ...selectedProduct });
    }
  }

  function renderLocationFields() {
    return (
      <>
        <IconButton color="text" onClick={() => setCollapseStatus("location")}>
          Locations
        </IconButton>
        <Collapse isOpen={collapseStatus === "location"}>
          <div>
            {selectedProduct.value.prices.location.map((c, idx) => (
              <div
                className={styles.noTableWrapper}
                key={idx}
                data-field="location"
              >
                City:
                <div data-fieldidx={idx} data-field="city">
                  <CustomText
                    disabled={isDisabled()}
                    value={c.city}
                    onChange={handleInputChanged}
                  />
                </div>
                Country:
                <div data-fieldidx={idx} data-field="country">
                  <CustomText
                    disabled={isDisabled()}
                    value={c.country}
                    onChange={handleInputChanged}
                  />
                </div>
                Code:
                <div data-fieldidx={idx} data-field="code">
                  <CustomText
                    disabled={isDisabled()}
                    value={c.code}
                    onChange={handleInputChanged}
                  />
                </div>
                {/* Price:
                <div data-fieldidx={idx} data-field="price">
                  <CustomText value={c.price} onChange={handleInputChanged} />
                </div> */}
                <div data-fieldidx={idx}>
                  <TrashSvg
                    className={isDisabled() ? styles.disabled : ""}
                    onClick={handleRemoveRowClicked}
                  />
                </div>
              </div>
            ))}
            <br />
            <div data-field="location">
              <IconButton
                disabled={isDisabled()}
                color="light-purple"
                onClick={handleAddRowClicked}
              >
                Add
              </IconButton>
            </div>
          </div>
        </Collapse>
      </>
    );
  }

  function renderImageFields() {
    return (
      <>
        <IconButton color="text" onClick={() => setCollapseStatus("image")}>
          Images
        </IconButton>
        <Collapse isOpen={collapseStatus === "image"}>
          <div>
            <div
              data-field="image"
              className={`${styles.width} ${styles.imageTableWrapper}`}
            >
              <BasicTable>
                <thead>
                  <tr>
                    <th style={{ width: "280px" }}>Value</th>
                    <th>OS</th>
                    <th style={{ width: "290px" }}>Version</th>
                    <th>Platform</th>
                    <th>VMID IL</th>
                    <th>VMID NL</th>
                    <th>VMID US</th>
                    <th>Enable</th>
                    <th>Only Admin</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProduct.value.prices.image.map((c, idx) => (
                    <tr key={idx}>
                      <td data-fieldidx={idx} data-field="value">
                        <CustomText
                          disabled={isDisabled()}
                          value={c.value}
                          onChange={handleInputChanged}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="os">
                        <CustomText
                          disabled={isDisabled()}
                          value={c.os}
                          onChange={handleInputChanged}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="version">
                        <CustomText
                          disabled={isDisabled()}
                          value={c.version}
                          onChange={handleInputChanged}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="platform">
                        <CustomText
                          disabled={isDisabled()}
                          value={c.platform}
                          onChange={handleInputChanged}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="linked_clone_vmid_il">
                        <CustomText
                          disabled={isDisabled()}
                          value={c.linked_clone_vmid_il || ""}
                          onChange={handleInputChanged}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="linked_clone_vmid_nl">
                        <CustomText
                          disabled={isDisabled()}
                          value={c.linked_clone_vmid_nl || ""}
                          onChange={handleInputChanged}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="linked_clone_vmid_us">
                        <CustomText
                          disabled={isDisabled()}
                          value={c.linked_clone_vmid_us || ""}
                          onChange={handleInputChanged}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="enable">
                        <Checkbox
                          disabled={isDisabled()}
                          readOnly
                          checked={c.enable}
                          onClick={handleInputClicked}
                        />
                      </td>
                      <td data-fieldidx={idx} data-field="only_for_admin">
                        <Checkbox
                          disabled={isDisabled()}
                          readOnly
                          checked={c.only_for_admin}
                          onClick={handleInputClicked}
                        />
                      </td>
                      <td data-fieldidx={idx}>
                        <TrashSvg
                          className={isDisabled() ? styles.disabled : ""}
                          onClick={handleRemoveRowClicked}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>
            </div>
            <br />
            <div data-field="image">
              <IconButton
                disabled={isDisabled()}
                color="light-purple"
                onClick={handleAddRowClicked}
              >
                Add
              </IconButton>
            </div>
          </div>
        </Collapse>
      </>
    );
  }

  function renderHardwareFields(name, field) {
    if (!selectedProduct.value.prices[field]) {
      return null;
    }

    return (
      <>
        <IconButton color="text" onClick={() => setCollapseStatus(field)}>
          {name}
        </IconButton>
        <Collapse isOpen={collapseStatus === field}>
          <div>
            {selectedProduct.value.prices[field].map((c, idx) => (
              <div
                key={idx}
                data-field={field}
                className={styles.noTableWrapper}
              >
                Value:
                <div data-fieldidx={idx} data-field="value">
                  <CustomText
                    disabled={isDisabled()}
                    value={c.value}
                    onChange={handleInputChanged}
                  />
                </div>
                Price:
                <div data-fieldidx={idx} data-field="price">
                  <CustomText value={c.price} onChange={handleInputChanged} />
                </div>
                <div data-fieldidx={idx}>
                  <TrashSvg
                    className={isDisabled() ? styles.disabled : ""}
                    onClick={handleRemoveRowClicked}
                  />
                </div>
              </div>
            ))}
            <br />
            <div className={styles.buttons} data-field={field}>
              <IconButton
                disabled={isDisabled()}
                color="light-purple"
                onClick={handleAddRowClicked}
              >
                Add
              </IconButton>

              <IconButton
                disabled={isDisabled()}
                color="light-purple"
                onClick={handleSortClicked}
              >
                Sort
              </IconButton>

              <IconButton
                color="light-purple"
                onClick={() => handleAutoPricesClicked(field)}
              >
                Auto Prices...
              </IconButton>
            </div>
          </div>
        </Collapse>
      </>
    );
  }

  function renderOtherFields() {
    return (
      <div>
        <IconButton color="text" onClick={() => setCollapseStatus("other")}>
          Other
        </IconButton>
        <Collapse isOpen={collapseStatus === "other"}>
          <div>
            <div data-field="basePrice">
              Base Price:
              <CustomText
                value={selectedProduct.value.prices.basePrice}
                onChange={handleInputOtherChanged}
              />
            </div>
            <div data-field="backup">
              Backup:
              <CustomText
                value={selectedProduct.value.prices.backup || ""}
                onChange={handleInputOtherChanged}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  const productsAndPricesForSelect = useMemo(
    () =>
      productsAndPrices.map((item) => ({
        label: item.name,
        value: item,
      })),
    [productsAndPrices]
  );

  return (
    <WithRole permission="admin.system-products.manage-prices">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-servers-and-prices.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box>
            {!wldvps && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="management-servers-and-prices.server" />
                </span>
                <CustomReactSelect
                  options={productsAndPricesForSelect}
                  value={selectedProduct}
                  onChange={(item) => setSelectedProduct(item)}
                />
              </div>
            )}

            {selectedProduct && (
              <>
                {!isDisabled() && <div>{renderLocationFields()}</div>}
                {!isDisabled() && <div>{renderImageFields()}</div>}
                <div>{renderHardwareFields("CPUs", "cpu")}</div>
                <div>{renderHardwareFields("RAMs", "ram_mb")}</div>
                <div>{renderHardwareFields("SSDs", "ssd_gb")}</div>
                <div>
                  {renderHardwareFields("Additional SSDs", "additional_ssd_gb")}
                </div>
                <div>{renderOtherFields()}</div>
                <br />
                <IconButton
                  disabled={loading}
                  color="light-purple"
                  onClick={handleUpdatedClicked}
                >
                  <FormattedMessage id="general.update" />
                </IconButton>
              </>
            )}
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
