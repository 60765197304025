import styles from "./servers-list.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Spinner from "../spinner/index.js";
import { ReactComponent as IsraelSvg } from "../svgs/hebrew.svg";
import { ReactComponent as NetherlandsSvg } from "../svgs/netherlands.svg";
import { ReactComponent as UsaSvg } from "../svgs/usa.svg";
import { ReactComponent as RunningSvg } from "../svgs/running.svg";
import { ReactComponent as PlaySvg } from "../svgs/play.svg";
import { ReactComponent as TerminatedSvg } from "../svgs/terminated.svg";
import { ReactComponent as StoppedSvg } from "../svgs/stopped.svg";
import { ReactComponent as EllipseSvg } from "../svgs/ellipse.svg";
import { ReactComponent as LongArrowLeftSvg } from "../svgs/long-arrow-left.svg";
import { ReactComponent as LongArrowRightSvg } from "../svgs/long-arrow-right-purple.svg";
import Checkbox from "../checkbox";
import CustomTag from "../custom-tag";
import CustomMenu from "../custom-menu";
import Overview from "../server/overview";
import {
  useLang,
  useAjax,
  usePrompt,
  useConfirm,
  useRoles,
  useUser,
  useServers,
} from "../../utils/hooks";
import CustomMenuItem from "../custom-menu/item";
import { useDispatch, useSelector } from "react-redux";
import { getOsLogo, getImageName } from "../../utils/servers";
import { handleServerActionClicked } from "../server-layout/server-actions";
import { useHistory } from "react-router-dom";
import TakeSnapshotModal from "../modals/take-snapshot";
import CloneServerModal from "../modals/clone-server";
import ClipboardCopy from "../clipboard-copy";
import { DropdownItem } from "reactstrap";
import { SUPER_ADMIN, WHITELABEL } from "../../utils/user";
import CustomReactSelect from "../custom-react-select";
import {
  setMaxServersToShow,
  setServersFetchingFilter,
  setServersPage,
} from "../../store/settings";
import NoServers from "./no-servers";

function ServersList({ selectedServersIds, setSelectedServersIds }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const lang = useLang();
  const user = useUser();
  const ajax = useAjax();
  const router = useHistory();
  const prompt = usePrompt();
  const confirm = useConfirm();
  const servers = useServers();
  const { isAllowed } = useRoles();
  const { serversFetching } = useSelector((state) => ({
    serversFetching: state.settings.serversFetching,
  }));

  const maxToShowOptions = [
    { label: intl.formatMessage({ id: "servers-list.10" }), value: 10 },
    { label: intl.formatMessage({ id: "servers-list.20" }), value: 20 },
    { label: intl.formatMessage({ id: "servers-list.30" }), value: 30 },
    { label: intl.formatMessage({ id: "servers-list.50" }), value: 50 },
    { label: intl.formatMessage({ id: "servers-list.100" }), value: 100 },
  ];

  const [isServerMenuOpen, setIsServerMenuOpen] = useState([]);
  const [expandedServers, setExpandedServers] = useState({});
  const [isTakeSnapshotModalOpen, setIsTakeSnapshotModalOpen] = useState(false);
  const [isCloneServerModalOpen, setIsCloneServerModalOpen] = useState(false);
  const [selectedServer, setSelectedServer] = useState(false);

  useEffect(() => {
    dispatch(setServersPage(0));
  }, [dispatch, serversFetching.filter]);

  function renderServerStatus(server, mobile = false) {
    if (server.status !== "Active") {
      return <TerminatedSvg />;
    }

    if (server.suspended) {
      return intl.formatMessage({ id: "general.suspended" });
    }

    let status;
    if (server.isWorking) {
      status = <Spinner />;
    } else {
      if (server.running) {
        status = mobile ? <PlaySvg /> : <RunningSvg />;
      } else {
        status = mobile ? null : <StoppedSvg />;
      }
    }

    if (server.lockMode) {
      //
    }

    return status;
  }

  function toggleServerMenu(index) {
    isServerMenuOpen[index] = !isServerMenuOpen[index];
    setIsServerMenuOpen([...isServerMenuOpen]);
  }

  function handleItemClicked(serverID) {
    expandedServers[serverID] = !expandedServers[serverID];
    setExpandedServers({ ...expandedServers });
  }

  function handleCheckboxChanged(serverID) {
    if (serverID) {
      selectedServersIds[serverID] = !selectedServersIds[serverID];
      setSelectedServersIds({ ...selectedServersIds });

      return;
    }

    const switchTo = !isAllCheckboxChecked();

    servers.forEach((server) => {
      if (typeof selectedServersIds[server._id] !== "boolean") {
        selectedServersIds[server._id] = false;
      }
    });

    Object.keys(selectedServersIds).forEach((serverID) => {
      selectedServersIds[serverID] = switchTo;
    });
    setSelectedServersIds({ ...selectedServersIds });
  }

  function isAllCheckboxChecked() {
    const keys = Object.keys(selectedServersIds);

    if (keys.length !== servers.length) {
      return false;
    }

    return !keys.find((serverID) => !selectedServersIds[serverID]);
  }

  function handleTakeSnapshotModalOpen() {
    setIsTakeSnapshotModalOpen(true);
  }

  function handleTakeSnapshotModalClosed() {
    setIsTakeSnapshotModalOpen(false);
  }

  function handleCloneServerModalClosed() {
    setIsCloneServerModalOpen(false);
  }

  function getLocationSvg(location) {
    switch (location) {
      case "NL":
        return <NetherlandsSvg />;
      case "US":
        return <UsaSvg />;
    }

    return <IsraelSvg />;
  }

  function renderServerRow(server, key) {
    return (
      <div key={key}>
        <div
          className={`${styles.serverWrapper} ${
            expandedServers[server._id] ? styles.isOpen : ""
          }`}
          onClick={() => handleItemClicked(server._id)}
        >
          <div className={styles.item}>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                checked={selectedServersIds[server._id] || false}
                onChange={() => handleCheckboxChanged(server._id)}
              />
            </div>
            <div className={styles.domainWrapper}>
              <div className={styles.domain}>
                <Link
                  href="/[lang]/my-cloud/servers/[id]/overview"
                  to={`/${lang}/my-cloud/servers/${server._id}/overview`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {server.hostname}
                </Link>
              </div>
              <div
                className={styles.mainIPWrapper}
                onClick={(e) => e.stopPropagation()}
              >
                <span className={styles.mainIP}>
                  <span className={styles.bold}>
                    <FormattedMessage id="general.main-ip" />:{" "}
                  </span>
                  {server.dedicatedip}{" "}
                </span>
                <ClipboardCopy text={server.dedicatedip}></ClipboardCopy>
              </div>
              {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                <div className={styles.userEmailWrapper}>
                  {server.userEmail}
                </div>
              )}
            </div>
            <div className={styles.osImage}>
              <img
                src={`/img/${getOsLogo(server.image)}`}
                width={24}
                height={24}
              />{" "}
              <span>{getImageName(server.image)}</span>
            </div>
            <div className={styles.countryWrapper}>
              {getLocationSvg(server.location)} <span>{server.location}</span>
            </div>
            <div className={styles.tagWrapper}>
              {server.tag && <CustomTag>{server.tag}</CustomTag>}
            </div>
            <div className={styles.statusWrapper}>
              {renderServerStatus(server)}
            </div>
            <div className={styles.statusWrapperMobile}>
              {renderServerStatus(server, true)}
            </div>
            <div
              className={styles.actionsWrapper}
              onClick={(e) => e.stopPropagation()}
            >
              {server.status === "Active" && (
                <CustomMenu
                  isOpen={isServerMenuOpen[key]}
                  toggle={() => toggleServerMenu(key)}
                  className={styles.serverActionsMenu}
                >
                  <CustomMenuItem
                    href="/[lang]/my-cloud/servers/[id]/overview"
                    as={`/${lang}/my-cloud/servers/${server._id}/overview`}
                  >
                    <FormattedMessage id="servers-list.server-details" />
                  </CustomMenuItem>

                  {isAllowed("servers.console") && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      href="/[lang]/my-cloud/servers/[id]/console"
                      as={`/${lang}/my-cloud/servers/${server._id}/console`}
                    >
                      <FormattedMessage id="servers-list.view-console" />
                    </CustomMenuItem>
                  )}

                  {isAllowed("servers.start-stop-reset") && !server.running && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() =>
                        handleServerActionClicked(
                          "start-server",
                          server,
                          null,
                          ajax,
                          intl,
                          router,
                          lang,
                          confirm,
                          prompt,
                          dispatch
                        )
                      }
                    >
                      <FormattedMessage id="servers-list.start-server" />
                    </CustomMenuItem>
                  )}

                  {isAllowed("servers.start-stop-reset") && server.running && (
                    <>
                      {server.ping && (
                        <>
                          <CustomMenuItem
                            disabled={server.isWorking}
                            onClick={() =>
                              handleServerActionClicked(
                                "soft-shutdown",
                                server,
                                null,
                                ajax,
                                intl,
                                router,
                                lang,
                                confirm,
                                prompt,
                                dispatch
                              )
                            }
                          >
                            <FormattedMessage id="servers-list.soft-shutdown-server" />
                          </CustomMenuItem>
                          <CustomMenuItem
                            disabled={server.isWorking}
                            onClick={() =>
                              handleServerActionClicked(
                                "soft-reboot",
                                server,
                                null,
                                ajax,
                                intl,
                                router,
                                lang,
                                confirm,
                                prompt,
                                dispatch
                              )
                            }
                          >
                            <FormattedMessage id="servers-list.soft-reboot-server" />
                          </CustomMenuItem>
                        </>
                      )}

                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "hard-shutdown",
                            server,
                            null,
                            ajax,
                            intl,
                            router,
                            lang,
                            confirm,
                            prompt,
                            dispatch
                          )
                        }
                      >
                        <FormattedMessage id="servers-list.hard-shutdown-server" />
                      </CustomMenuItem>
                      <CustomMenuItem
                        disabled={server.isWorking}
                        onClick={() =>
                          handleServerActionClicked(
                            "hard-reboot",
                            server,
                            null,
                            ajax,
                            intl,
                            router,
                            lang,
                            confirm,
                            prompt,
                            dispatch
                          )
                        }
                      >
                        <FormattedMessage id="servers-list.hard-reboot-server" />
                      </CustomMenuItem>
                    </>
                  )}

                  {isAllowed("servers.reinstall-destroy") && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() =>
                        handleServerActionClicked(
                          "reinstall",
                          server,
                          null,
                          ajax,
                          intl,
                          router,
                          lang,
                          confirm,
                          prompt,
                          dispatch
                        )
                      }
                    >
                      <FormattedMessage id="servers-list.reinstall-server" />
                    </CustomMenuItem>
                  )}

                  {isAllowed("servers.clone") && (
                    <CustomMenuItem
                      disabled={server.isWorking}
                      onClick={() => {
                        handleServerActionClicked(
                          "clone",
                          server,
                          setIsCloneServerModalOpen,
                          ajax,
                          intl,
                          router,
                          lang,
                          confirm,
                          prompt,
                          dispatch
                        );

                        setSelectedServer(server);
                      }}
                    >
                      <FormattedMessage id="servers-list.clone-server" />
                    </CustomMenuItem>
                  )}

                  {isAllowed("servers.reinstall-destroy") && (
                    <>
                      <DropdownItem divider />

                      <CustomMenuItem
                        disabled={server.isWorking}
                        color="red"
                        onClick={() =>
                          handleServerActionClicked(
                            "destroy",
                            server,
                            null,
                            ajax,
                            intl,
                            router,
                            lang,
                            confirm,
                            prompt,
                            dispatch
                          )
                        }
                      >
                        <FormattedMessage id="servers-list.destroy-server" />
                      </CustomMenuItem>
                    </>
                  )}
                </CustomMenu>
              )}
            </div>
          </div>

          <div className={styles.hr}>
            <hr />
          </div>

          <div
            className={styles.overviewWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            {expandedServers[server._id] && <Overview server={server} />}
          </div>

          <div
            onClick={(e) => e.stopPropagation()}
            className={styles.quickActions}
          >
            {isAllowed("servers.start-stop-reset") && !server.running && (
              <>
                <span
                  onClick={() =>
                    handleServerActionClicked(
                      "start-server",
                      server,
                      null,
                      ajax,
                      intl,
                      router,
                      lang,
                      confirm,
                      prompt,
                      dispatch
                    )
                  }
                >
                  <FormattedMessage id="servers-list.start" />
                </span>
                <EllipseSvg />
              </>
            )}

            {isAllowed("servers.start-stop-reset") && server.running && (
              <>
                <span
                  className={server.isWorking ? "link-disabled" : ""}
                  onClick={() =>
                    handleServerActionClicked(
                      server.ping ? "soft-reboot" : "hard-reboot",
                      server,
                      null,
                      ajax,
                      intl,
                      router,
                      lang,
                      confirm,
                      prompt,
                      dispatch
                    )
                  }
                >
                  <FormattedMessage id="servers-list.restart" />
                </span>
                <EllipseSvg />
              </>
            )}

            {isAllowed("servers.create-delete-snapshots") && (
              <>
                <span
                  className={server.isWorking ? "link-disabled" : ""}
                  onClick={handleTakeSnapshotModalOpen}
                >
                  <FormattedMessage id="servers-list.snapshot" />
                </span>
                <EllipseSvg />
              </>
            )}

            {isAllowed("servers.console") && (
              <>
                <span>
                  <Link
                    href="/[lang]/my-cloud/servers/[id]/console"
                    to={`/${lang}/my-cloud/servers/${server._id}/console`}
                    className={server.isWorking ? "link-disabled" : ""}
                  >
                    <FormattedMessage id="servers-list.console" />
                  </Link>
                </span>
                <EllipseSvg />
              </>
            )}

            <span>
              <Link
                href="/[lang]/my-cloud/servers/[id]/overview"
                to={`/${lang}/my-cloud/servers/${server._id}/overview`}
              >
                <FormattedMessage id="servers-list.full-view" />
              </Link>
            </span>
          </div>
        </div>
        <div style={{ height: "16px" }}></div>
      </div>
    );
  }

  const maxPages = useMemo(
    () =>
      parseInt(
        serversFetching.totalServers / serversFetching.maxServersToShow.value
      ) + 1,
    [serversFetching.maxServersToShow.value, serversFetching.totalServers]
  );

  function renderPagination(position) {
    return (
      <div className={`${styles.paginationWrapper} ${styles[position]}`}>
        <div className={styles.maxServersToShowWrapper}>
          <CustomReactSelect
            options={maxToShowOptions}
            value={serversFetching.maxServersToShow}
            onChange={(item) => dispatch(setMaxServersToShow(item))}
          />
        </div>

        <div className={styles.text}>{`${
          serversFetching.page + 1
        } / ${maxPages} ${intl.formatMessage({
          id: "general.total",
        })}`}</div>
        <div
          className={`${styles.box} ${styles.first} ${
            serversFetching.page === 0 ? styles.disabled : ""
          }`}
          onClick={() => dispatch(setServersPage(serversFetching.page - 1))}
        >
          <LongArrowLeftSvg />
        </div>
        <div
          className={`${styles.box} ${
            serversFetching.page + 1 === maxPages ? styles.disabled : ""
          }`}
          onClick={() => dispatch(setServersPage(serversFetching.page + 1))}
        >
          <LongArrowRightSvg />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.listHeader}>
        <div className={`${styles.item} ${styles.checkbox}`}>
          <Checkbox
            checked={isAllCheckboxChecked()}
            onChange={() => handleCheckboxChanged()}
          />
        </div>

        <input
          className={styles.filterInput}
          placeholder={intl.formatMessage({
            id: "servers-list.filter-servers",
          })}
          value={serversFetching.filter}
          onChange={(e) => dispatch(setServersFetchingFilter(e.target.value))}
        />

        {renderPagination("top")}
      </div>

      {servers.length === 0 && <NoServers />}

      {servers.map((server, key) => renderServerRow(server, key))}

      {renderPagination("bottom")}

      <TakeSnapshotModal
        isOpen={isTakeSnapshotModalOpen}
        onClose={handleTakeSnapshotModalClosed}
      />

      {selectedServer && (
        <CloneServerModal
          server={selectedServer}
          isOpen={isCloneServerModalOpen}
          onClose={handleCloneServerModalClosed}
        />
      )}
    </>
  );
}

ServersList.propTypes = {
  selectedServersIds: PropTypes.object,
  setSelectedServersIds: PropTypes.func,
};

export default ServersList;
