import styles from "./account-details.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import HeaderWithNavs from "../../../components/cloud-layout/headers-with-navs";
import Box from "../../../components/box";
import CustomText from "../../../components/custom-text";
import IconButton from "../../../components/icon-button";
import {
  useLang,
  useAjax,
  useInitialDataFetching,
  useUser,
  useServers,
} from "../../../utils/hooks";
import CountrySelector from "../../../components/country-selector";
import PhoneSmsModal from "../../../components/modals/phone-sms";
import EmailVerificationModal from "../../../components/modals/email-verification";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";
import {} from "../../../utils";
import { Link } from "react-router-dom";

export default function AccountDetails() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const user = useUser();
  const fetchInitialData = useInitialDataFetching();
  const servers = useServers();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [stateRegion, setStateRegion] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [apartmentNumber, setApartmentNumber] = useState("");
  const [postcode, setPostCode] = useState("");

  const [isPhoneSmsModalOpen, setIsPhoneSmsModalOpen] = useState(false);
  const [
    isEmailVerificationModalOpen,
    setIsEmailVerificationModalOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setEmail(user.email || "");
    setCompanyName(user.companyName || "");
    setPhoneCode(user.phoneCode || "");
    setPhoneNumber(user.phoneNumber || "");
    setCountry(user.country || "");
    setStateRegion(user.stateRegion || "");
    setAddress(user.address || "");
    setCity(user.city || "");
    setApartmentNumber(user.apartmentNumber || "");
    setPostCode(user.postcode || "");
  }, [user]);

  async function handleUpdateClicked() {
    setLoading(true);
    await ajax(`/users/updateClientData`, {
      firstName,
      lastName,
      companyName,
      stateRegion,
      city,
      apartmentNumber,
      postcode,
    });

    await fetchInitialData();
    setLoading(false);
  }

  async function handlePhoneSmsModalClosed(state) {
    if (state) {
      await fetchInitialData();
    }

    setIsPhoneSmsModalOpen(false);
  }

  async function handleEmailVerificationModalClosed() {
    setIsEmailVerificationModalOpen(false);
  }

  const activeServers = useMemo(
    () => servers?.filter((server) => server.status === "Active").length,
    [servers]
  );

  if (user.current_parent) {
    return (
      <Box>
        <FormattedMessage id="general.not-allowed-under-parent" />
      </Box>
    );
  }

  if (user.registerStep !== -1) {
    return (
      <Box>
        <Link
          href="/[lang]/my-cloud/create-new-server"
          to={`/${lang}/my-cloud/create-new-server`}
        >
          <FormattedMessage id="general.complete-registration-first" />
        </Link>
      </Box>
    );
  }

  return (
    <HeaderWithNavs title={intl.formatMessage({ id: "account-details.title" })}>
      <div className={styles.wrapper}>
        <Box className={styles.box}>
          <div className={styles.cols}>
            <div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.first-name" />
                </span>{" "}
                <CustomText
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="given-name"
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.last-name" />
                </span>{" "}
                <CustomText
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="family-name"
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.email" />
                </span>{" "}
                <CustomText value={email} readOnly />
                {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                  <IconButton
                    color="light-purple"
                    onClick={() => setIsEmailVerificationModalOpen(true)}
                  >
                    <FormattedMessage id="general.change" />
                  </IconButton>
                )}
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.company-name" />
                </span>{" "}
                <CustomText
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  autoComplete="organization"
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.phone-number" />
                </span>{" "}
                <CustomText
                  className={styles.phoneNumber}
                  value={`+${phoneCode}${phoneNumber}`}
                  readOnly
                />
                {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                  <IconButton
                    color="light-purple"
                    onClick={() => setIsPhoneSmsModalOpen(true)}
                  >
                    <FormattedMessage id="general.change" />
                  </IconButton>
                )}
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.credits" />
                </span>{" "}
                <div>{user.credits}</div>
              </div>
            </div>
            <div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.country" />
                </span>{" "}
                <CountrySelector value={country} disabled />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.state-region" />
                </span>{" "}
                <CustomText
                  value={stateRegion}
                  onChange={(e) => setStateRegion(e.target.value)}
                  autoComplete="street-address"
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.address" />
                </span>{" "}
                <CustomText
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.city" />
                </span>{" "}
                <CustomText
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.apartment-number" />
                </span>{" "}
                <CustomText
                  value={apartmentNumber}
                  onChange={(e) => setApartmentNumber(e.target.value)}
                />
              </div>
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="account-details.post-code" />
                </span>{" "}
                <CustomText
                  value={postcode}
                  onChange={(e) => setPostCode(e.target.value)}
                  autoComplete="postal-code"
                />
              </div>
            </div>
          </div>
          {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
            <div className={styles.buttonWrapper}>
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={handleUpdateClicked}
              >
                <FormattedMessage id="account-details.update" />
              </IconButton>
            </div>
          )}
        </Box>

        {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <Box className={styles.box}>
            <div>
              <FormattedMessage
                id="account-details.total-active-servers"
                values={{ number: activeServers, max: user.serversLimit }}
              />
            </div>
            <Link
              href="/[lang]/support/open-new-ticket"
              to={`/${lang}/support/open-new-ticket`}
              className={styles.contactUs}
            >
              <FormattedMessage id="account-details.contact-us" />
            </Link>
          </Box>
        )}
      </div>

      <PhoneSmsModal
        isOpen={isPhoneSmsModalOpen}
        onClose={handlePhoneSmsModalClosed}
      />

      <EmailVerificationModal
        isOpen={isEmailVerificationModalOpen}
        onClose={handleEmailVerificationModalClosed}
      />
    </HeaderWithNavs>
  );
}
