import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import Checkbox from "../../checkbox";

function CreateGroupAndAssignModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const intl = useIntl();

  const [domains, setDomains] = useState([]);

  const [name, setName] = useState("");
  const [selectedServers, setSelectedServers] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAllDomains = useCallback(async () => {
    const data = await ajax(`/servers/getAllDomains`, { onlyActive: true });

    if (data.result === "success") {
      setDomains(data.domains);
    }
  }, [ajax]);

  useEffect(() => {
    if (isOpen) {
      setName("");
      setSelectedServers({});
      setLoading(false);
      setError(false);

      getAllDomains();
    }
  }, [isOpen, getAllDomains]);

  function handleCheckboxChanged(e, domain) {
    selectedServers[domain._id] = !selectedServers[domain._id];
    setSelectedServers({ ...selectedServers });
  }

  async function handleCreateClicked() {
    const _name = name ? name.trim() : false;

    if (!_name) {
      return setError(
        intl.formatMessage({ id: "create-group-and-assign-modal.empty-name" })
      );
    }

    setLoading(true);
    const data = await ajax("/groups/create", { name: _name });

    await ajax("/groups/assignServers", {
      _id: data.group._id,
      servers: Object.keys(selectedServers).filter((k) => selectedServers[k]),
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="create-group-and-assign-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <FormattedMessage
            id="create-group-and-assign-modal.content1"
            tagName="span"
          />
          <CustomText value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className={styles.row}>
          <FormattedMessage
            id="create-group-and-assign-modal.content2"
            tagName="span"
          />
          {domains.map((domain, key) => (
            <div key={key} className={styles.checkboxWrapper}>
              <Checkbox
                label={<span>{domain.hostname}</span>}
                checked={selectedServers[domain._id] || false}
                onChange={(e) => handleCheckboxChanged(e, domain)}
              />
            </div>
          ))}
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleCreateClicked}
        >
          <FormattedMessage id="general.save" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

CreateGroupAndAssignModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateGroupAndAssignModal;
