import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { ReactComponent as CopySvg } from "../svgs/copy.svg";

function ClipboardCopy({ text, onClick = () => {} }) {
  const [show, setShow] = useState(false);

  function handleOnCopy() {
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 1000);
  }

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={`${styles.copied} ${show ? styles.show : ""}`}>
        <FormattedMessage id="clipboard-copy.copied" />
      </div>

      <CopyToClipboard text={text} onCopy={handleOnCopy}>
        <CopySvg className="pointer" />
      </CopyToClipboard>
    </div>
  );
}

ClipboardCopy.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default ClipboardCopy;
