import styles from "./coupons.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useConfirm } from "../../../utils/hooks";
import {} from "../../../utils";
import Box from "../../../components/box";
import BasicTable from "../../../components/basic-table";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import IconButton from "../../../components/icon-button";
import CreateNewCouponModal from "../../../components/modals/create-new-coupon";

export default function BillingCoupons() {
  const intl = useIntl();
  const ajax = useAjax();
  const confirm = useConfirm();

  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isCreateNewCouponModalOpen, setIsCreateNewCouponModalOpen] = useState(
    false
  );

  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    const data = await ajax(`/coupons/getAll`);

    setCoupons(data.coupons);
  }, [ajax]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function handleCreateCouponClicked() {
    setSelectedCoupon(null);
    setIsCreateNewCouponModalOpen(true);
  }

  async function handleCreateNewCouponModalClosed(state) {
    if (state) {
      await fetch();
    }

    setIsCreateNewCouponModalOpen(false);
  }

  function handleDropDownToggle(coupon) {
    coupon.dropdownOpen = !coupon.dropdownOpen;
    setCoupons([...coupons]);
  }

  async function handleDeleteCouponMenuClicked(coupon) {
    const state = await confirm({
      title: intl.formatMessage({ id: "billing-coupons.delete.title" }),
      message: intl.formatMessage(
        { id: "billing-coupons.delete.content" },
        { code: coupon.code }
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax(`/coupons/delete`, {
      couponID: coupon._id,
    });

    await fetch();
    setLoading(false);
  }

  function handleEditCouponMenuClicked(coupon) {
    setSelectedCoupon(coupon);
    setIsCreateNewCouponModalOpen(true);
  }

  return (
    <WithRole permission="admin.coupons.manage">
      <LayoutMainAction
        title={intl.formatMessage({ id: "billing-coupons.main-title" })}
      >
        <div className={styles.wrapper}>
          <Box>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="billing-coupons.coupon-code" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-coupons.coupon-used" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-coupons.coupon-limit" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-coupons.coupon-type" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-coupons.coupon-rules" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {coupons?.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}
                {coupons?.map((coupon, idx) => (
                  <tr key={idx}>
                    <td>{coupon.code}</td>
                    <td>{coupon.used}</td>
                    <td>
                      {coupon.limit === 0
                        ? intl.formatMessage({
                            id: "billing-coupons.unlimited",
                          })
                        : coupon.limit}
                    </td>
                    <td>
                      <FormattedMessage id={`billing-coupons.${coupon.type}`} />
                    </td>
                    {coupon.type === "free-days" && (
                      <td>
                        <div>
                          <FormattedMessage id="billing-coupons.free-days-total" />
                          : {coupon.rules.freeDays}
                        </div>
                        <div>
                          <FormattedMessage id="billing-coupons.free-days-amount-limitation" />
                          : {coupon.rules.amountLimitation}
                        </div>
                      </td>
                    )}
                    {coupon.type === "add-credits" && (
                      <td>
                        <div>
                          <FormattedMessage id="billing-coupons.add-credits-amount" />
                          : ${coupon.rules.amountToAdd}
                        </div>
                      </td>
                    )}
                    <td>
                      <CustomMenu
                        isOpen={coupon.dropdownOpen}
                        toggle={() => handleDropDownToggle(coupon)}
                      >
                        <CustomMenuItem
                          onClick={() => handleEditCouponMenuClicked(coupon)}
                        >
                          <FormattedMessage id="general.edit" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleDeleteCouponMenuClicked(coupon)}
                        >
                          <FormattedMessage id="general.delete" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>

            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleCreateCouponClicked}
            >
              <FormattedMessage id="billing-coupons.create-new-coupon" />
            </IconButton>
          </Box>

          <CreateNewCouponModal
            isOpen={isCreateNewCouponModalOpen}
            onClose={handleCreateNewCouponModalClosed}
            editCoupon={selectedCoupon}
          />
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
