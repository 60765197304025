import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import MonitorContent from "./monitor-content";

function MonitorServicesModal({ isOpen, onClose }) {
  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="monitor-services-modal.title" />
      </ModalHeader>
      <ModalBody>
        <MonitorContent />

        <div className={styles.note}>
          <div className={styles.bold}>
            <FormattedMessage id="general.note" />:
          </div>
          <div>
            <FormattedMessage id="monitor-services-modal.note" />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="green">
          <FormattedMessage id="general.upgrade-now" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

MonitorServicesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MonitorServicesModal;
