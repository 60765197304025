import sanitizeHtml from "sanitize-html";
import React from "react";
import axios from "axios";
import passwordValidator from "password-validator";

const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces();

export const isStrongPassword = (password) => {
  return schema.validate(password);
};

export const axiosPost = async (url, params) => {
  return await axios.post(url, params, { withCredentials: true });
};

export const selectText = (node) => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn("Could not select text in node: Unsupported browser.");
  }
};

export const getDayAsText = (day) => {
  switch (day) {
    case 1:
      return "Sunday";
    case 2:
      return "Monday";
    case 3:
      return "Tuesday";
    case 4:
      return "Wednesday";
    case 5:
      return "Thursday";
    case 6:
      return "Friday";
    default:
      return "Saturday";
  }
};

export const dataLayerPush = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const syntaxHighlight = (json) => {
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
};

export const proxmoxStringToObject = (string) => {
  if (!string) {
    return null;
  }

  const ret = {};

  const stringArr = string.split(",");

  for (const k in stringArr) {
    const pair = stringArr[k].split("=");
    ret[pair[0]] = pair[1];
  }

  return ret;
};

export const filterLang = (pageProps) => {
  const lang = pageProps?.lang || "en";

  return ["en", "he"].includes(lang) ? lang : "en";
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stripTags = (text) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
};

export const getDirection = (content) => {
  content = stripTags(content);

  return content[0] >= "A" && content[0] <= "z"
    ? { direction: "ltr", textAlign: "left" }
    : { direction: "rtl", textAlign: "right" };
};

export const cutString = (str, maxLength) => {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const setDir = (text) => {
  const stripped = stripTags(text);
  const isRTL = stripped[0] >= "א" && stripped[0] <= "ת";

  return (
    <div
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      {text}
    </div>
  );
};

export const noop = () => {};

export const sanitize = (html) =>
  sanitizeHtml(html, {
    allowedTags: ["b", "i", "em", "strong", "a", "br", "pre", "p"],
    allowedAttributes: {
      a: ["href"],
    },
    allowedIframeHostnames: [],
  });

export const asyncTimeout = (interval) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, interval);
  });
};
