import styles from "./item.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

function CustomMenuItem({
  onClick,
  children,
  disabled = false,
  href = null,
  as = null,
  target = null,
  color = "default",
}) {
  function renderChildren() {
    if (href && as) {
      return (
        <Link
          href={href}
          to={as}
          className={`${styles.childrenWrapper} ${styles[color]} ${styles.flex}`}
          target={target}
        >
          {children}
        </Link>
      );
    } else if (href) {
      return (
        <a
          className={`${styles.childrenWrapper} ${styles[color]} ${styles.flex}`}
          href={href}
          target={target}
        >
          {children}
        </a>
      );
    }

    return (
      <div className={`${styles.childrenWrapper} ${styles[color]}`}>
        {children}
      </div>
    );
  }

  return (
    <DropdownItem
      className={`${styles.wrapper} ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
    >
      {renderChildren()}
    </DropdownItem>
  );
}

CustomMenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  as: PropTypes.string,
  target: PropTypes.string,
  color: PropTypes.string,
};

export default CustomMenuItem;
