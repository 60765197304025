import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import UpgradeManagedHostingContent from "./upgrade-managed-hosting-content";

function UpgradeManagedHostingModal({ isOpen, onClose }) {
  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="upgrade-managed-hosting-modal.title" />
      </ModalHeader>
      <ModalBody className={styles.body}>
        <UpgradeManagedHostingContent />
      </ModalBody>
      <ModalFooter>
        <IconButton icon="arrow" color="green">
          <FormattedMessage id="general.upgrade-now" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}
UpgradeManagedHostingModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UpgradeManagedHostingModal;
