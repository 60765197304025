import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as LogoSvg } from "../svgs/logo.svg";
import { ReactComponent as LeftArrowLeft } from "../svgs/long-arrow-left.svg";
import { ReactComponent as BackupFeature } from "../svgs/backup-feature.svg";
import { ReactComponent as GreenStar } from "../svgs/green-star.svg";
import styles from "./index.module.scss";
import { useWLDVPS } from "../../utils/hooks";

function LoginLayout({ children }) {
  const intl = useIntl();
  const wldvps = useWLDVPS();

  function renderLogo() {
    if (wldvps) {
      return (
        <img className={styles.logo} src={`/img/wldvps/${wldvps}/logo.png`} />
      );
    }

    return <LogoSvg className={styles.logo} />;
  }

  return (
    <div className={styles.LoginLayout}>
      <style>{`
        html,
        body,
        div#root > div,
        div#root > div > div,
        div#root {
          height: 100%;
        }
      `}</style>
      {renderLogo()}
      <div className={styles.inputsWrapper}>{children}</div>
      <div className={styles.image}>
        <div className={styles.bigImageWrapper}>
          <GreenStar className={styles.star1} />
          <BackupFeature />
          <GreenStar className={styles.star2} />
        </div>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "login.big-image-title" }),
          }}
        ></div>
        <div className={styles.content}>
          <FormattedMessage id="login.big-image-content" />
        </div>
      </div>
      {!wldvps && (
        <div className={styles.backToSite}>
          <a href="https://www.dreamvps.com">
            <LeftArrowLeft />
            <span>
              <FormattedMessage id="login-layout.back-to-site" />
            </span>
          </a>
        </div>
      )}
    </div>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.any,
};

export default LoginLayout;
