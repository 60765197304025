import styles from "./index.module.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IpSubnetCalculator from "ip-subnet-calculator";
import BasicTable from "../../basic-table";
import IconButton from "../../icon-button";
import CustomMenu from "../../custom-menu";
import CustomMenuItem from "../../custom-menu/item";
import EditIpAddressModal from "../edit-ip-address";
import RemoveIpFromServerModal from "../remove-ip-from-server";
import { useAjax } from "../../../utils/hooks";

let timerID;

function AssignIpsModal({
  getVLANs,
  vlans,
  vlan,
  subnet,
  isOpen,
  onClose,
  userIDToWork,
  type,
}) {
  const intl = useIntl();
  const ajax = useAjax();

  const [ips, setIps] = useState([]);
  const [selectedIpObj, setSelectedIpObj] = useState(null);
  const [ipsDropdownOpen, setIpsDropdownOpen] = useState({});
  const [filter, setFilter] = useState("");
  const [scrollTop, setScrollTop] = useState(0);

  const [isEditIpAddressModalOpen, setIsEditIpAddressModalOpen] = useState(
    false
  );
  const [
    isRemoveIpFromServerModalOpen,
    setIsRemoveIpFromServerModalOpen,
  ] = useState(false);

  const [selectedIpObject, setSelectedIpObject] = useState(null);

  useEffect(() => {
    if (!subnet) {
      return;
    }

    const ipSubnetCalc = IpSubnetCalculator.calculateSubnetMask(
      subnet.ipAddress,
      subnet.netmask
    );

    if (!ipSubnetCalc) {
      return;
    }

    const ips = [];

    for (let i = ipSubnetCalc.ipLow; i <= ipSubnetCalc.ipHigh; i++) {
      const ipStr = IpSubnetCalculator.toString(i);

      if (!ipStr.endsWith(".0") && !ipStr.endsWith(".255")) {
        const ipObj = subnet.ips.find((ip) => ip.ip === ipStr);

        ips.push(ipObj || { ip: ipStr });
      }
    }

    setIps(ips);
  }, [vlans, vlan, subnet]);

  function handleIpsDropdownToggle(key) {
    ipsDropdownOpen[key] = !ipsDropdownOpen[key];
    setIpsDropdownOpen({ ...ipsDropdownOpen });
  }

  function handleEditIpAddressModalOpen(ipObj) {
    setSelectedIpObj(ipObj);
    setIsEditIpAddressModalOpen(true);
  }

  async function handleEditIpAddressModalClosed(state) {
    if (state) {
      await getVLANs();
    }

    setIsEditIpAddressModalOpen(false);
  }

  async function handleRemoveIpAddressClicked(ipObj) {
    setSelectedIpObject(ipObj);
    setIsRemoveIpFromServerModalOpen(true);
  }

  async function handleRemoveIpFromServerModalClosed(state) {
    if (state) {
      await getVLANs();
    }

    setIsRemoveIpFromServerModalOpen(false);
  }

  async function handleSetAsGatewayClicked(ipObj = null) {
    await ajax("/network/changeSubnetGateway", {
      userIDToWork,
      vlanID: vlan._id,
      subnetID: subnet._id,
      newGateway: ipObj ? ipObj.ip : null,
    });

    await getVLANs();
  }

  function handleFilter(ipObject) {
    const _filter = filter.toLowerCase();

    return (
      !_filter ||
      ipObject.ip.includes(_filter) ||
      (ipObject.ip === subnet.gateway && "gateway".includes(_filter)) ||
      ipObject.server?.hostname.toLowerCase().includes(_filter) ||
      ipObject.reserve_name?.toLowerCase().includes(_filter)
    );
  }

  function renderIpContent(ipObject) {
    if (ipObject.ip === subnet?.gateway) {
      return intl.formatMessage({ id: "networks.gateway" });
    }

    if (ipObject.reserve_name) {
      return `${ipObject.reserve_name} (${intl.formatMessage({
        id: "networks.reserved",
      })})`;
    }

    if (!ipObject.server) {
      return ipObject.server_id
        ? intl.formatMessage(
            { id: "networks.server-not-found" },
            { serverID: ipObject.server_id }
          )
        : "-";
    }

    if (type === "lan") {
      return ipObject.server.hostname;
    }

    if (!ipObject.server.user) {
      return ipObject.server.hostname;
    }

    return intl.formatMessage(
      { id: "networks.client-details" },
      {
        email: ipObject.server.user.email,
        number: ipObject.server.user.clientNumber,
        domain: ipObject.server.hostname,
      }
    );
  }

  function handleTableScroll(e) {
    const st = e.target.scrollTop;

    if (timerID) {
      clearTimeout(timerID);
    }

    timerID = setTimeout(() => {
      setScrollTop(st);
    }, 100);
  }

  function renderRow(ipObj, key) {
    if (key * 53 < scrollTop || key * 53 > scrollTop + 10 * 53) {
      return (
        <tr key={key}>
          <td>{ipObj.ip}</td>
          <td colSpan={2}>
            <div style={{ height: "53px" }}></div>
          </td>
        </tr>
      );
    }

    return (
      <tr key={key}>
        <td>{ipObj.ip}</td>
        <td>{renderIpContent(ipObj)}</td>
        <td>
          {!ipObj.lock && (
            <CustomMenu
              isOpen={ipsDropdownOpen[key]}
              toggle={() => handleIpsDropdownToggle(key)}
            >
              {subnet?.gateway !== ipObj.ip && (
                <CustomMenuItem
                  onClick={() => handleEditIpAddressModalOpen(ipObj)}
                >
                  <FormattedMessage id="general.edit" />
                </CustomMenuItem>
              )}
              {(ipObj.server_id || ipObj.reserve_name) && (
                <CustomMenuItem
                  onClick={() => handleRemoveIpAddressClicked(ipObj)}
                >
                  <FormattedMessage id="general.remove" />
                </CustomMenuItem>
              )}
              {subnet?.gateway !== ipObj.ip && !ipObj.server_id && (
                <CustomMenuItem
                  onClick={() => handleSetAsGatewayClicked(ipObj)}
                >
                  <FormattedMessage id="assign-ips-modal.set-as-gateway" />
                </CustomMenuItem>
              )}
              {subnet?.gateway === ipObj.ip && (
                <CustomMenuItem onClick={() => handleSetAsGatewayClicked()}>
                  <FormattedMessage id="general.remove" />
                </CustomMenuItem>
              )}
            </CustomMenu>
          )}
        </td>
      </tr>
    );
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="assign-ips-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.header}>
          <input
            className={styles.filterInput}
            placeholder={intl.formatMessage({ id: "general.filter..." })}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>

        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="assign-ips-modal.ip-address" />
              </th>
              <th>
                <FormattedMessage id="assign-ips-modal.details" />
              </th>
              <th>
                <FormattedMessage id="assign-ips-modal.actions" />
              </th>
            </tr>
          </thead>
        </BasicTable>

        <div className={styles.tableWrapper} onScroll={handleTableScroll}>
          <BasicTable>
            <tbody>
              {ips
                .filter(handleFilter)
                .map((ipObj, key) => renderRow(ipObj, key))}
            </tbody>
          </BasicTable>
        </div>

        <EditIpAddressModal
          subnet={subnet}
          ipObject={selectedIpObj}
          isOpen={isEditIpAddressModalOpen}
          onClose={handleEditIpAddressModalClosed}
          getVLANs={getVLANs}
          userIDToWork={userIDToWork}
          type={type}
        />

        <RemoveIpFromServerModal
          isOpen={isRemoveIpFromServerModalOpen}
          onClose={handleRemoveIpFromServerModalClosed}
          userIDToWork={userIDToWork}
          type={type}
          ipObject={selectedIpObject}
        />
      </ModalBody>
      <ModalFooter>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AssignIpsModal.propTypes = {
  vlans: PropTypes.array,
  getVLANs: PropTypes.func,
  vlan: PropTypes.object,
  subnet: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userIDToWork: PropTypes.object,
  type: PropTypes.string,
};

export default AssignIpsModal;
