import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CryptoJS from "crypto-js";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import cardValidator from "card-validator";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax } from "../../../utils/hooks";
import Spinner from "../../spinner";
import { useStripe } from "@stripe/react-stripe-js";

function UpdateCreditCardQuickModal({ isOpen, onClose, userIDToSet }) {
  const ajax = useAjax();
  const stripe = useStripe();

  const [creditCard, setCreditCard] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCVV] = useState("");

  const [sessionId, setSessionId] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchCheckoutSession = useCallback(async () => {
    const data = await ajax(`/stripe/createStripeCheckoutSession`, {
      userID: userIDToSet._id,
      redirect: window.location.href,
    });

    if (data.result === "success") {
      setSessionId(data.sessionID);
    }
  }, [ajax, userIDToSet]);

  useEffect(() => {
    if (userIDToSet && userIDToSet.payment_gateway === "stripe") {
      fetchCheckoutSession();
    }
  }, [userIDToSet, fetchCheckoutSession]);

  async function handleCheckoutRedirectClicked() {
    await stripe.redirectToCheckout({
      sessionId,
    });
  }

  async function handleSaveClicked() {
    setError(false);

    const cardValidatorNumber = cardValidator.number(creditCard);
    const cardValidatorMonth = cardValidator.expirationMonth(month);
    const cardValidatorYear = cardValidator.expirationYear(year);
    const cardValidatorCVV = cardValidator.cvv(cvv);

    if (!cardValidatorNumber.isValid && creditCard.length !== 9) {
      return setError("complete-registration-modal.credit-card-number-wrong");
    } else if (!cardValidatorMonth.isValid) {
      return setError("complete-registration-modal.credit-card-month-wrong");
    } else if (!cardValidatorYear.isValid) {
      return setError("complete-registration-modal.credit-card-year-wrong");
    } else if (!cardValidatorCVV.isValid) {
      return setError("complete-registration-modal.credit-card-cvv-wrong");
    }

    setLoading(true);
    const { token } = await ajax("/credit-card/prepareUpdateCreditCard");

    const details = CryptoJS.AES.encrypt(
      `${creditCard}_${cvv}_${month}_${year}`,
      token
    ).toString();

    await ajax("/credit-card/updateCreditCard", {
      isPrimary: true,
      userIDToSet: userIDToSet._id,
      details,
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="update-credit-card-quick-modal.title" />
      </ModalHeader>
      <ModalBody>
        {["icount", "payplus"].includes(userIDToSet?.payment_gateway) && (
          <>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.card-number" />
              </span>
              <CustomText
                value={creditCard}
                onChange={(e) => setCreditCard(e.target.value)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.month" />
              </span>
              <CustomText
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                placeholder="MM"
                maxLength={2}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.year" />
              </span>
              <CustomText
                value={year}
                onChange={(e) => setYear(e.target.value)}
                placeholder="YY"
                maxLength={2}
              />
            </div>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="update-credit-card-quick-modal.cvv" />
              </span>
              <CustomText
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
              />
            </div>

            {error && (
              <div className="error">
                <FormattedMessage id={error} />
              </div>
            )}
          </>
        )}

        {userIDToSet?.payment_gateway === "stripe" && (
          <div>
            {!sessionId && <Spinner />}
            {sessionId && (
              <IconButton
                color="light-purple"
                onClick={handleCheckoutRedirectClicked}
              >
                <FormattedMessage id="update-credit-card-quick-modal.update-stripe" />
              </IconButton>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {["icount", "payplus"].includes(userIDToSet?.payment_gateway) && (
          <IconButton
            disabled={loading}
            color="purple"
            onClick={handleSaveClicked}
            className={styles.saveButton}
          >
            <FormattedMessage id="general.save" tagName="span" />
            {loading && <Spinner />}
          </IconButton>
        )}
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

UpdateCreditCardQuickModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userIDToSet: PropTypes.object,
};

export default UpdateCreditCardQuickModal;
