import styles from "./addons-client.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../../../../../components/box";
import { WithRole } from "../../../../../../../components/with-role";
import { noop } from "../../../../../../../utils";
import CpanelLicense from "../../../../../../../components/create-new-server/cpanel-license";
import { useAjax, useServer } from "../../../../../../../utils/hooks";
import IconButton from "../../../../../../../components/icon-button";
import { differenceInDays, endOfMonth, getDaysInMonth } from "date-fns";
import { currencySymbols } from "../../../../../../../utils/billing";

export default function ServerAddonsClient() {
  const server = useServer();
  const ajax = useAjax();
  const intl = useIntl();

  const [selectedAddons, setSelectedAddons] = useState({});
  const [priceMonthlyFirst, setPriceMonthlyFirst] = useState(null);
  const [priceMonthly, setPriceMonthly] = useState(null);
  const [priceHourly, setPriceHourly] = useState(null);

  const getServerPrice = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax(`/billing/getServerPrice`, {
      userID: server.user_id,
      serverData: {
        image: server.image,
        cpu: server.cpu,
        ram_mb: server.ram_mb,
        ssd_gb: server.ssd_gb,
        additional_ssd_gb: server.additional_ssd_gb,
        backup: server.backup,
      },
      addons: Object.keys(selectedAddons),
    });

    const daysInMonth = getDaysInMonth(new Date());
    const daysLeft = differenceInDays(endOfMonth(new Date()), new Date()) + 1;
    const chargeImmediatelyPrice = (data.addonsPrice / daysInMonth) * daysLeft;

    setPriceMonthlyFirst(chargeImmediatelyPrice.toFixed(2));
    setPriceMonthly(data.addonsPrice);
    setPriceHourly(data.addonsHourlyPriceOn.toFixed(3));
  }, [ajax, selectedAddons, server]);

  useEffect(() => {
    getServerPrice();
  }, [getServerPrice]);

  async function handleSaveChangesClicked() {
    //
  }

  function renderButton() {
    if (!selectedAddons[Object.keys(selectedAddons)[0]]) {
      return null;
    }

    const addonExists = server.addons.find(
      (addon) =>
        addon.addon_id === selectedAddons[Object.keys(selectedAddons)[0]]._id
    );

    if (addonExists) {
      return null;
    }

    return (
      <div className={styles.buttonWrapper}>
        {server?.payment.payment_type === "monthly" && (
          <>
            <div>
              <FormattedMessage id="server-settings-change-plan.immediately-price" />{" "}
              <b>
                {addon?.price.currency}
                {priceMonthlyFirst}
              </b>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {
                    id: "server-settings-change-plan.price",
                  },
                  {
                    currency: addon?.price.currency,
                    price: priceMonthly,
                    b: (val) => `<b>${val}</b>`,
                  }
                ),
              }}
            ></div>
          </>
        )}

        {server?.payment.payment_type === "hourly" && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {
                    id: "server-addons-client.hourly-price",
                  },
                  {
                    currency: currencySymbols[addon?.currency],
                    newPrice: priceHourly,
                    b: (val) => `<b>${val}</b>`,
                  }
                ),
              }}
            ></div>
          </>
        )}

        <IconButton color="green" onClick={handleSaveChangesClicked}>
          <FormattedMessage id="general.save-changes" />
        </IconButton>
      </div>
    );
  }

  function renderComponent() {
    if (server?.image.toLowerCase().includes("cpanel")) {
      const addon = server.addons.find(
        (addon) => addon.addon_type === "cpanel-license"
      );

      return (
        <>
          <CpanelLicense
            incStep={noop}
            selectedAddons={selectedAddons}
            setSelectedAddons={setSelectedAddons}
            handleSaveChangesClicked={handleSaveChangesClicked}
            defaultSelected={addon?.addon_id}
          />

          {renderButton()}
        </>
      );
    }

    return (
      <Box>
        <FormattedMessage id="server-addons-client.no-addons-to-show" />
      </Box>
    );
  }

  const addon = useMemo(() => selectedAddons[Object.keys(selectedAddons)[0]], [
    selectedAddons,
  ]);

  return (
    <WithRole permission="servers.addons">
      <div className={styles.wrapper}>{renderComponent()}</div>
    </WithRole>
  );
}
