import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax } from "../../../utils/hooks";
import { getFullName } from "../../../utils/user";
import CustomReactSelect from "../../custom-react-select";
import { sanitize } from "../../../utils";

function UserSelectorModal({ title, content, isOpen, onClose }) {
  const ajax = useAjax();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const getAllUsers = useCallback(async () => {
    const data = await ajax("/users/getAllUsers", {
      filter: { email: 1, firstName: 1, lastName: 1 },
    });

    if (data.result === "success") {
      setUsers(
        data.users.map((user) => ({
          label: `${user.email} | ${getFullName(user)}`,
          value: user._id,
        }))
      );
    }
  }, [ajax]);

  useEffect(() => {
    if (isOpen) {
      getAllUsers();
    }
  }, [isOpen, getAllUsers]);

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>{title}</ModalHeader>
      <ModalBody>
        {content && (
          <div
            className={styles.contentWrapper}
            dangerouslySetInnerHTML={{ __html: sanitize(content) }}
          ></div>
        )}

        <CustomReactSelect
          instanceId="user-selector-modal"
          options={users}
          value={selectedUser}
          onChange={(item) => setSelectedUser(item)}
        />
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={() => onClose(selectedUser)}>
          <FormattedMessage id="general.ok" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

UserSelectorModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UserSelectorModal;
