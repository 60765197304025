import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import CustomText from "../../custom-text";
import IconButton from "../../icon-button";
import { useAjax, useAlert } from "../../../utils/hooks";

function SmsVerificationModal({
  title,
  content,
  email,
  password,
  action,
  isOpen,
  onClose,
}) {
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();

  const [number, setNumber] = useState(false);
  const [code, setCode] = useState("");
  const [checking, setChecking] = useState(false);
  const [error, setError] = useState(false);

  const sendSMS = useCallback(async () => {
    const data = await ajax(`/users/sendSMS`, {
      email,
      password,
    });

    if (data.result === "success") {
      setNumber(data.number);
    } else {
      setError(intl.formatMessage({ id: data.message }));
    }
  }, [ajax, intl, email, password]);

  useEffect(() => {
    if (isOpen) {
      setCode("");
      sendSMS();
    }
  }, [isOpen, sendSMS]);

  async function handleCheckClicked() {
    setChecking(true);
    const data = await ajax(`/users/verifySMS`, {
      code,
      email,
      password,
      action,
    });
    setChecking(false);

    if (data.result === "success") {
      onClose(true, data);
    } else {
      await alert(
        intl.formatMessage({
          id: "reset-all-servers-password.sms-modal-title",
        }),
        intl.formatMessage({ id: "general.wrong-sms-code" })
      );
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className={styles.wrapper}>
        {!number && <div color="error">{error}</div>}
        {number && (
          <div>
            <div>
              <FormattedMessage
                id="general.enter-sms-code"
                values={{ number }}
              />
            </div>
            <CustomText
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            {content && <div>{content}</div>}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={!code || checking}
          color="purple"
          onClick={handleCheckClicked}
        >
          <FormattedMessage id="general.reset" />
        </IconButton>
        <IconButton
          disabled={checking}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

SmsVerificationModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  action: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SmsVerificationModal;
