import styles from "./index.module.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import IpSubnetCalculator from "ip-subnet-calculator";
import { ReactComponent as MinusSvg } from "../../../../components/svgs/minus.svg";
import { ReactComponent as PlusSvg } from "../../../../components/svgs/plus.svg";
import { ReactComponent as EditSvg } from "../../../../components/svgs/edit-purple.svg";
import { ReactComponent as TrashSvg } from "../../../../components/svgs/trash-purple.svg";
import HeaderWithNavs from "../../../../components/cloud-layout/headers-with-navs";
import Box from "../../../../components/box";
import IconButton from "../../../../components/icon-button";
import CollapseTable from "../../../../components/collapse-table";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  useRoles,
  useUser,
} from "../../../../utils/hooks";
import { getCountryIcon } from "../../../../utils/countries";
import CustomMenu from "../../../../components/custom-menu";
import CustomMenuItem from "../../../../components/custom-menu/item";
import BasicTable from "../../../../components/basic-table";
import AddIpAddressScopeModal from "../../../../components/modals/add-ip-address-scope";
import AddIpAddressScopeModalBody, {
  checkErrorForCreateVlan,
} from "../../../../components/modals/add-ip-address-scope/body";
import AssignIpsModal from "../../../../components/modals/assign-ips";
import { WithRole } from "../../../../components/with-role";
import UserSelectorModal from "../../../../components/modals/user-selector";
import { SUPER_ADMIN, WHITELABEL } from "../../../../utils/user";
import CustomReactSelect from "../../../../components/custom-react-select";
import {} from "../../../../utils";
import { Link } from "react-router-dom";

const ipvOptions = [
  { label: "IPv4", value: 4 },
  { label: "IPv6", value: 6 },
];

function MyCloudNetworks({ type = "lan" }) {
  const intl = useIntl();
  const ajax = useAjax();
  const { isAllowed } = useRoles();
  const isAllowedRef = useRef(isAllowed);
  isAllowedRef.current = isAllowed;
  const user = useUser();
  const lang = useLang();

  const confirm = useConfirm();
  const confirmRef = useRef(confirm);

  const alert = useAlert();

  const [location, setLocation] = useState(false);
  const [vlanName, setVlanName] = useState("");
  const [ipAddressScope, setIpAddressScope] = useState(null);
  const [ipAddressScopeCustom, setIpAddressScopeCustom] = useState("");
  const [netmask, setNetmask] = useState(null);
  const [gateway, setGateway] = useState(null);
  const [subnet, setSubnet] = useState("-");
  const [ipv, setIpv] = useState(ipvOptions[0]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [vlans, setVlans] = useState([]);
  const [selectedVlan, setSelectedVlan] = useState(null);
  const [selectedSubnet, setSelectedSubnet] = useState(null);
  const [tableItems, setTableItems] = useState([]);
  const [isVlanDropdownOpen, setIsVlanDropdownOpen] = useState({});

  const [isAssignIpsModalOpen, setIsAssignIpsModalOpen] = useState(false);
  const [
    isAddIpAddressScopeModalOpen,
    setIsAddIpAddressScopeModalOpen,
  ] = useState(false);

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);
  const [isUserSelectorModal2Open, setIsUserSelectorModal2Open] = useState(
    false
  );
  const [userIDToWork, setUserIDToWork] = useState(null);

  const tableHeader = useMemo(() => {
    const items = [
      { title: intl.formatMessage({ id: "networks.name" }) },
      { title: intl.formatMessage({ id: "networks.location" }) },
      {
        title: intl.formatMessage({ id: "networks.type" }),
        className: styles.onlyOnDesktop,
      },
      {
        title: intl.formatMessage({ id: "networks.subnets" }),
        className: styles.onlyOnDesktop,
      },
      {},
    ];

    if (type === "wan") {
      items.splice(3, 0, {
        title: intl.formatMessage({ id: "networks.ip-address" }),
      });
    }

    if ([SUPER_ADMIN].includes(user.role) && type === "lan") {
      items.splice(3, 0, {
        title: intl.formatMessage({ id: "networks.vlan-number" }),
      });
    }

    return items;
  }, [intl, type, user.role]);

  const handleVlanDropDownToggle = useCallback(
    (vlan) => {
      isVlanDropdownOpen[vlan._id] = !isVlanDropdownOpen[vlan._id];
      setIsVlanDropdownOpen({ ...isVlanDropdownOpen });
    },
    [isVlanDropdownOpen]
  );

  function handleChangeOwnerClicked(vlan) {
    setSelectedVlan(vlan);
    setIsUserSelectorModal2Open(true);
  }

  async function handleUserSelectorModal2Closed(user) {
    if (user) {
      await ajax("/network/changeVlanOwner", {
        vlanID: selectedVlan._id,
        userID: user.value,
      });

      await getVLANs();
    }

    setIsUserSelectorModal2Open(false);
  }

  const getVLANs = useCallback(async () => {
    async function handleDeleteClicked(vlan) {
      const state = await confirmRef.current({
        title: intl.formatMessage({ id: "networks.delete-network-title" }),
        message: intl.formatMessage(
          { id: "networks.delete-network-message" },
          { networkName: vlan.vlanName }
        ),
      });

      if (state === "button1") {
        return;
      }

      const data = await ajax("/network/deleteVLAN", {
        userIDToWork: userIDToWork?.value,
        vlanID: vlan._id,
      });

      if (data.result === "success") {
        await getVLANs();
      }
    }

    async function handleDeleteSubnetClicked(vlan, subnet) {
      const state = await confirmRef.current({
        title: intl.formatMessage({ id: "networks.delete-subnet-title" }),
        message: intl.formatMessage(
          { id: "networks.delete-subnet-message" },
          { ip: subnet.ipAddress }
        ),
      });

      if (state === "button1") {
        return;
      }

      await ajax("/network/deleteSubnet", {
        userIDToWork: userIDToWork?.value,
        subnetID: subnet._id,
        vlanID: vlan._id,
      });

      await getVLANs();
    }

    const data = await ajax("/network/getVLANs", {
      userIDToWork: userIDToWork?.value,
      ipv: ipv.value,
      type,
    });

    if (!data.vlans) {
      return;
    }

    setVlans(data.vlans);

    const tableItems = data.vlans.map((vlan) => {
      const CountryIcon = getCountryIcon(vlan.location);

      const cols = [
        { content: vlan.vlanName },
        {
          content: (
            <span className={styles.location}>
              {CountryIcon} {vlan.location}
            </span>
          ),
        },
        { content: vlan.type.toUpperCase(), className: styles.onlyOnDesktop },
        { content: vlan.subnets.length, className: styles.onlyOnDesktop },
        {
          content: isAllowedRef.current("networks.vlan") && (
            <div onClick={(e) => e.stopPropagation()}>
              <CustomMenu
                className={styles.vlanMenu}
                isOpen={isVlanDropdownOpen[vlan._id]}
                toggle={() => handleVlanDropDownToggle(vlan)}
              >
                {isAllowedRef.current("admin.networks.change-vlan-owner") && (
                  <CustomMenuItem
                    onClick={() => handleChangeOwnerClicked(vlan)}
                  >
                    <FormattedMessage id="networks.change-vlan-owner" />
                  </CustomMenuItem>
                )}

                <CustomMenuItem onClick={() => handleDeleteClicked(vlan)}>
                  <FormattedMessage id="general.delete" />
                </CustomMenuItem>
              </CustomMenu>
            </div>
          ),
        },
      ];

      if (type === "wan") {
        cols.splice(3, 0, { content: vlan.subnets[0].ipAddress });
      }

      if ([SUPER_ADMIN].includes(user.role) && type === "lan") {
        cols.splice(3, 0, {
          content: data[`vlan_numbers_${vlan.location}`][0],
        });
      }

      return {
        cols,
        expanded: (
          <div className={styles.networksList}>
            <BasicTable layout="auto">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="networks.ip-address" />
                  </th>
                  <th>
                    <FormattedMessage id="networks.subnet-mask" />
                  </th>
                  <th>
                    <FormattedMessage id="networks.range" />
                  </th>
                  <th>
                    <FormattedMessage id="networks.gateway" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {vlan.subnets.length === 0 && (
                  <tr>
                    <td colSpan={5}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}
                {vlan.subnets.map((subnet, key) => {
                  const ipSubnetCalc = IpSubnetCalculator.calculateSubnetMask(
                    subnet.ipAddress,
                    subnet.netmask
                  );

                  return (
                    <tr key={key}>
                      <td>
                        {subnet.ipAddress}/{subnet.netmask}
                      </td>
                      <td>{ipSubnetCalc?.prefixMaskStr}</td>
                      <td>1</td>
                      <td>{subnet.gateway || "-"}</td>
                      <td className={styles.svgs}>
                        {isAllowedRef.current("networks.ips") && (
                          <EditSvg
                            onClick={() =>
                              handleAssignIpsModalOpen(vlan, subnet)
                            }
                          />
                        )}
                        {isAllowedRef.current("networks.subnet") && (
                          <TrashSvg
                            onClick={() =>
                              handleDeleteSubnetClicked(vlan, subnet)
                            }
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </BasicTable>

            {isAllowedRef.current("networks.subnet") && (
              <IconButton
                color="light-purple"
                onClick={() => handleAddIpAddressScopeModalOpen(vlan)}
              >
                <FormattedMessage id="networks.ip-address-scope" />
              </IconButton>
            )}
          </div>
        ),
      };
    });

    setTableItems(tableItems);
  }, [
    ajax,
    intl,
    isVlanDropdownOpen,
    handleVlanDropDownToggle,
    userIDToWork?.value,
    type,
    ipv,
    user.role,
  ]);

  useEffect(() => {
    getVLANs();
  }, [getVLANs]);

  useEffect(() => {
    const vlan = vlans.find((v) => v._id === selectedVlan?._id);

    if (vlan) {
      const subnet = vlan.subnets.find((s) => s._id === selectedSubnet?._id);

      setSelectedSubnet(subnet);
    }
  }, [selectedVlan?._id, selectedSubnet?._id, vlans]);

  function handleAddIpAddressScopeModalOpen(vlan) {
    setSelectedVlan(vlan);
    setIsAddIpAddressScopeModalOpen(true);
  }

  async function handleAddIpAddressScopeModalClosed(state) {
    if (state) {
      await getVLANs();
    }

    setIsAddIpAddressScopeModalOpen(false);
  }

  async function handleCreateVlan() {
    const error = checkErrorForCreateVlan({
      withVlan: true,
      location,
      vlanName,
      ipAddressScope,
      ipAddressScopeCustom,
      netmask,
      gateway,
      intl,
    });

    if (error) {
      return setError(error);
    } else {
      setError(false);
    }

    setLoading(true);
    await ajax("/network/createVLAN", {
      userIDToWork: userIDToWork?.value,
      gateway: gateway.value,
      ipAddressScope: ipAddressScope.value,
      ipAddressScopeCustom,
      ipv: ipv.value,
      location: location.value,
      netmask: netmask.value,
      type,
      vlanName,
    });

    await getVLANs();
    setLoading(false);

    alert(
      intl.formatMessage({ id: "networks.add-private" }),
      intl.formatMessage({ id: "networks.add-private-created" })
    );

    setLocation(false);
    setVlanName("");
    setIpAddressScope(null);
    setIpAddressScopeCustom("");
    setNetmask(null);
    setGateway(null);
    setSubnet("-");
  }

  function handleAssignIpsModalOpen(vlan, subnet) {
    setSelectedVlan(vlan);
    setSelectedSubnet(subnet);
    setIsAssignIpsModalOpen(true);
  }

  function handleAssignIpsModalClosed() {
    setIsAssignIpsModalOpen(false);
  }

  function handleUserSelectorModalOpen() {
    setIsUserSelectorModalOpen(true);
  }

  function handleUserSelectorModalClosed(user) {
    setUserIDToWork(user);
    setIsUserSelectorModalOpen(false);
  }

  if (!user.current_parent && user.registerStep !== -1) {
    return (
      <Box>
        <Link
          href="/[lang]/my-cloud/create-new-server"
          to={`/${lang}/my-cloud/create-new-server`}
        >
          <FormattedMessage id="general.complete-registration-first" />
        </Link>
      </Box>
    );
  }

  return (
    <WithRole permission="networks.see">
      <HeaderWithNavs title={intl.formatMessage({ id: "networks.title" })}>
        {type === "lan" && [SUPER_ADMIN, WHITELABEL].includes(user.role) && (
          <Box className={styles.box}>
            <IconButton
              color="light-purple"
              onClick={handleUserSelectorModalOpen}
            >
              {userIDToWork?.label || user.email}
            </IconButton>
          </Box>
        )}

        <div className={styles.wrapper}>
          {isAllowed("networks.vlan") && (
            <Box
              className={styles.boxWrapper}
              expanded={
                <div className={styles.expandedWrapper}>
                  <AddIpAddressScopeModalBody
                    withVlan
                    location={location}
                    setLocation={setLocation}
                    vlanName={vlanName}
                    setVlanName={setVlanName}
                    ipAddressScope={ipAddressScope}
                    netmask={netmask}
                    gateway={gateway}
                    setGateway={setGateway}
                    setSubnet={setSubnet}
                    ipAddressScopeCustom={ipAddressScopeCustom}
                    setIpAddressScopeCustom={setIpAddressScopeCustom}
                    setIpAddressScope={setIpAddressScope}
                    setNetmask={setNetmask}
                    subnet={subnet}
                    ipv={ipv.value}
                    error={error}
                  />

                  <div className={styles.buttonWrapper}>
                    <IconButton
                      disabled={loading}
                      color="light-purple"
                      onClick={handleCreateVlan}
                    >
                      <FormattedMessage id="general.create" />
                    </IconButton>
                  </div>
                </div>
              }
            >
              {(isOpen) => (
                <div className={styles.boxText}>
                  {isOpen ? <MinusSvg /> : <PlusSvg />}
                  <FormattedMessage
                    id={
                      type === "lan"
                        ? "networks.add-private"
                        : "networks.add-public"
                    }
                  />
                </div>
              )}
            </Box>
          )}

          {type === "lan" && (
            <Box
              expanded={
                <div className={styles.howToDescription}>
                  <hr />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "networks.how-to-description",
                      }),
                    }}
                  ></div>
                </div>
              }
            >
              {(isOpen) => (
                <div className={styles.boxText}>
                  {isOpen ? <MinusSvg /> : <PlusSvg />}
                  <FormattedMessage id="networks.how-to" />
                </div>
              )}
            </Box>
          )}

          <div className={styles.activeNetworks}>
            <FormattedMessage id="networks.active-networks" />
            {type === "wan" && (
              <CustomReactSelect
                className={styles.select}
                value={ipv}
                onChange={(item) => setIpv(item)}
                options={ipvOptions}
              />
            )}
          </div>

          <CollapseTable headers={tableHeader} items={tableItems} />
        </div>

        <AddIpAddressScopeModal
          vlan={selectedVlan}
          ipv={ipv.value}
          isOpen={isAddIpAddressScopeModalOpen}
          onClose={handleAddIpAddressScopeModalClosed}
        />

        <AssignIpsModal
          vlan={selectedVlan}
          subnet={selectedSubnet}
          isOpen={isAssignIpsModalOpen}
          onClose={handleAssignIpsModalClosed}
          getVLANs={getVLANs}
          vlans={vlans}
          userIDToWork={userIDToWork}
          type={type}
        />

        <UserSelectorModal
          isOpen={isUserSelectorModalOpen}
          onClose={handleUserSelectorModalClosed}
        />

        <UserSelectorModal
          isOpen={isUserSelectorModal2Open}
          onClose={handleUserSelectorModal2Closed}
        />
      </HeaderWithNavs>
    </WithRole>
  );
}

MyCloudNetworks.propTypes = {
  type: PropTypes.oneOf(["lan", "wan"]),
};

export default MyCloudNetworks;
