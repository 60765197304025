import styles from "./security-groups.module.scss";

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import { ReactComponent as HelpSvg } from "../../../../components/svgs/help.svg";
import { ReactComponent as ExternalLinkSvg } from "../../../../components/svgs/external-link.svg";
import { ReactComponent as CopySvg } from "../../../../components/svgs/copy.svg";
import { ReactComponent as EditSvg } from "../../../../components/svgs/edit-purple.svg";
import { ReactComponent as TrashSvg } from "../../../../components/svgs/trash-purple.svg";
import { ReactComponent as ActiveSvg } from "../../../../components/svgs/active.svg";
import { ReactComponent as DisableSvg } from "../../../../components/svgs/disable.svg";
import HeaderWithNavs from "../../../../components/cloud-layout/headers-with-navs";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  usePrompt,
  useUser,
} from "../../../../utils/hooks";
import IconButton from "../../../../components/icon-button";
import Checkbox from "../../../../components/checkbox";
import CustomMenu from "../../../../components/custom-menu";
import CustomMenuItem from "../../../../components/custom-menu/item";
import BasicTable from "../../../../components/basic-table";
import SimpleBadge from "../../../../components/simple-badge";
import CustomText from "../../../../components/custom-text";
import AddNewRuleModal from "../../../../components/modals/add-new-rule";
import { WithRole } from "../../../../components/with-role";
import {} from "../../../../utils";
import { getFirewallPageNavItems } from "../../../../utils/firewall";
import Spinner from "../../../../components/spinner";

export default function FirewallSecurityGroups() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const prompt = usePrompt();
  const confirm = useConfirm();
  const alert = useAlert();
  const user = useUser();

  const [groups, setGroups] = useState(null);
  const [groupRules, setGroupRules] = useState({});
  const [openedGroups, setOpenedGroups] = useState({});
  const [isInstancesMenuOpen, setIsInstancesMenuOpen] = useState(false);
  const [isAddNewRuleModalOpen, setIsAddNewRuleModalOpen] = useState(false);
  const [
    isNetworkInterfaceTooltipOpen,
    setIsNetworkInterfaceTooltipOpen,
  ] = useState(false);

  const [domains, setDomains] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(false);
  const [selectedRule, setSelectedRule] = useState(false);

  const [userIDToWork, setUserIDToWork] = useState(null);

  const [loading, setLoading] = useState(false);

  const fetchDomains = useCallback(async () => {
    if (!user._id) {
      return;
    }

    const data = await ajax(`/servers/getAllDomainsUserToWork`, {
      userIDToWork: userIDToWork?.value,
    });

    const domains = data.domains?.filter(
      (domain) =>
        domain.status === "Active" &&
        (userIDToWork?.value || domain.user_id === user._id)
    );

    setDomains(domains);
  }, [ajax, userIDToWork?.value, user]);

  const fetchSecurityGroups = useCallback(async () => {
    const data = await ajax("/firewall/getSecurityGroups", {
      userIDToWork: userIDToWork?.value,
    });

    setGroups(data.groups);
  }, [ajax, userIDToWork?.value]);

  useEffect(() => {
    fetchSecurityGroups();
    fetchDomains();
  }, [fetchSecurityGroups, fetchDomains]);

  async function fetchRulesOfGroup(group) {
    const data = await ajax("/firewall/getRulesOfGroup", {
      groupID: group.id,
      userIDToWork: userIDToWork?.value,
    });

    groupRules[group.id] = data.rules;

    setGroupRules({ ...groupRules });
  }

  function handleItemClicked(group, index) {
    openedGroups[group.id] = !openedGroups[group.id];
    setOpenedGroups({ ...openedGroups });

    groups[index].domains = [...domains];
    groups[index].domains.forEach((domain) => {
      domain.assigned = domain.security_groups
        ? domain.security_groups.includes(group.id)
        : false;
      domain.assignedInit = domain.assigned;

      Object.keys(domain.proxmox_nets_firewall_status).forEach((num) => {
        domain[`net${num}`] = domain.proxmox_nets_firewall_status[num];
        domain[`net${num}Init`] = domain.proxmox_nets_firewall_status[num];
      });
    });

    delete groupRules[group.id];
    setGroupRules({ ...groupRules });

    fetchRulesOfGroup(groups[index]);
  }

  async function handleAddNewFirewallClicked() {
    await prompt({
      title: intl.formatMessage({ id: "firewall.add-security-group.title" }),
      message: intl.formatMessage({
        id: "firewall.add-security-group.message",
      }),
      beforeClose: async (name) => {
        name = name ? name.trim() : false;

        if (name) {
          await ajax("/firewall/addNewSecurityGroup", {
            edit: false,
            name,
            userIDToWork: userIDToWork?.value,
          });

          await fetchSecurityGroups();
        }
      },
    });
  }

  async function handleAddNewFirewallLinkClicked(e) {
    e.preventDefault();

    if (e.target.tagName === "A") {
      await handleAddNewFirewallClicked();
    }
  }

  function handleAddNewRuleClicked(group) {
    setSelectedGroup(group);
    setSelectedRule(false);
    setIsAddNewRuleModalOpen(true);
  }

  async function handleAddNewRuleModalClosed(status) {
    if (status) {
      await fetchRulesOfGroup(selectedGroup);
    }

    setIsAddNewRuleModalOpen(false);
  }

  function handleGroupDropdownMenuToggle(group) {
    group.isDropdownMenuOpen = !group.isDropdownMenuOpen;
    setGroups([...groups]);
  }

  async function handleEditGroupName(group) {
    let name = await prompt({
      title: intl.formatMessage({ id: "firewall.edit-security-group.title" }),
      message: intl.formatMessage({
        id: "firewall.edit-security-group.message",
      }),
      defaultText: group.name,
    });
    name = name ? name.trim() : false;

    if (name) {
      await ajax("/firewall/addNewSecurityGroup", {
        edit: group.id,
        name,
        userIDToWork: userIDToWork?.value,
      });

      await fetchSecurityGroups();
    }
  }

  async function handleRemoveGroupName(group) {
    if (group.rules > 0) {
      alert(
        intl.formatMessage({
          id: "firewall.remove-security-group-error.title",
        }),
        intl.formatMessage({
          id: "firewall.remove-security-group-error.message",
        })
      );
      return;
    }

    await confirm({
      title: intl.formatMessage({ id: "firewall.remove-security-group.title" }),
      message: intl.formatMessage(
        {
          id: "firewall.remove-security-group.message",
        },
        { name: group.name }
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax(`/firewall/removeSecurityGroup`, {
          groupID: group.id,
          userIDToWork: userIDToWork?.value,
        });

        await fetchSecurityGroups();
      },
    });
  }

  async function handleCopyRuleClicked(group, rule) {
    setLoading(true);

    await ajax("/firewall/copyRule", {
      groupID: group.id,
      rule,
      userIDToWork: userIDToWork?.value,
    });

    await fetchRulesOfGroup(group);

    setLoading(false);

    handleEditRuleClicked(group, rule);
  }

  function handleEditRuleClicked(group, rule) {
    setSelectedGroup(group);
    setSelectedRule(rule);
    setIsAddNewRuleModalOpen(true);
  }

  async function handleRemoveRuleClicked(key, group) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "firewall-security-groups.remove-rule.title",
      }),
      message: intl.formatMessage({
        id: "firewall-security-groups.remove-rule.content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);

    await ajax("/firewall/removeRule", {
      groupID: group.id,
      rulePos: key,
      userIDToWork: userIDToWork?.value,
    });

    await fetchRulesOfGroup(group);

    setLoading(false);
  }

  function handleAssignedClicked(e, domain) {
    domain.assigned = e.target.checked;

    Object.keys(domain.proxmox_nets_firewall_status).forEach((num) => {
      domain[`net${num}`] = domain.assigned;
    });

    setDomains([...domains]);
  }

  function handleAssignedNetClicked(e, domain, netNum) {
    domain[`net${netNum}`] = e.target.checked;
    setDomains([...domains]);
  }

  function handleSelectAllClicked() {
    domains.forEach((domain) => {
      domain.assigned = !domain.assigned;

      Object.keys(domain.proxmox_nets_firewall_status).forEach((num) => {
        domain[`net${num}`] = true;
      });
    });

    setDomains([...domains]);
  }

  function handleDeselectAllClicked() {
    domains.forEach((domain) => {
      domain.assigned = !domain.assigned;

      Object.keys(domain.proxmox_nets_firewall_status).forEach((num) => {
        domain[`net${num}`] = false;
      });
    });

    setDomains([...domains]);
  }

  async function handleUpdateClicked(group) {
    const changedDomains = domains.filter(
      (domain) => domain.assigned !== domain.assignedInit
    );

    domains.forEach((domain) => {
      if (domain.assigned !== domain.assignedInit) {
        changedDomains.push(domain);
      } else {
        Object.keys(domain.proxmox_nets_firewall_status).forEach((num) => {
          if (domain[`net${num}`] !== domain[`net${num}Init`]) {
            changedDomains.push(domain);
            return;
          }
        });
      }
    });

    setLoading(true);

    let outputPolicy = "ACCEPT";

    groupRules[group.id].forEach((item) => {
      if (item.action === "DROP" && item.type === "out") {
        outputPolicy = "DROP";
      }
    });

    await ajax(`/firewall/assignServersToSecurityGroup`, {
      groupID: group.id,
      domains: changedDomains,
      userIDToWork: userIDToWork?.value,
      outputPolicy,
    });

    setLoading(false);
  }

  function getStaticDropDirection(group) {
    let inExists = false;
    let outExists = false;

    groupRules[group.id].forEach((item) => {
      if (item.type === "in") {
        inExists = true;
      }
      if (item.type === "out") {
        outExists = true;
      }
    });

    return `${inExists ? "in" : ""}${inExists && outExists ? "/" : ""}${
      outExists ? "out" : ""
    }`;
  }

  const withUserSelector = useMemo(
    () => ({
      className: styles.box,
      userIDToWork,
      setUserIDToWork,
    }),
    [userIDToWork]
  );

  const firewallPageNavItems = useMemo(
    () => getFirewallPageNavItems("security-groups", intl, lang, userIDToWork),
    [intl, lang, userIDToWork]
  );

  return (
    <WithRole permission="servers.firewall">
      <HeaderWithNavs
        title={intl.formatMessage({ id: "firewall.title" })}
        withUserSelector={withUserSelector}
        navItems={firewallPageNavItems}
      >
        <div className={styles.wrapper}>
          <div className={styles.actions}>
            <ButtonDropdown
              className={styles.purple}
              isOpen={isInstancesMenuOpen}
              toggle={() => setIsInstancesMenuOpen(!isInstancesMenuOpen)}
            >
              <DropdownToggle caret>
                <FormattedMessage id="firewall-security-groups.show-all" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>All</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>

            <IconButton
              color="purple"
              icon="plus"
              onClick={handleAddNewFirewallClicked}
            >
              <FormattedMessage id="firewall-security-groups.add-new-firewall" />
            </IconButton>
          </div>

          <div className={styles.listHeader}>
            <div className={styles.item}>
              <Checkbox />
            </div>
            <div className={`${styles.item} ${styles.name}`}>
              <FormattedMessage id="firewall-security-groups.name" />
            </div>
            <div className={`${styles.item} ${styles.rules}`}>
              <FormattedMessage id="firewall-security-groups.rules" />
            </div>
            <div className={`${styles.item} ${styles.instancesApplied}`}>
              <FormattedMessage id="firewall-security-groups.instances-applied" />
            </div>
            <div className={styles.item}>&nbsp;</div>
          </div>

          {!groups && (
            <div className={styles.serverWrapper}>
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            </div>
          )}

          {groups?.length === 0 && (
            <div className={styles.serverWrapper}>
              <div
                onClick={handleAddNewFirewallLinkClicked}
                className={styles.item}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "firewall-security-groups.no-groups",
                  }),
                }}
              ></div>
            </div>
          )}

          {groups?.map((group, idx) => (
            <div
              key={idx}
              className={`${styles.serverWrapper} ${
                openedGroups[group.id] ? styles.isOpen : ""
              }`}
              onClick={() => handleItemClicked(group, idx)}
            >
              <div className={styles.item}>
                <div className={styles.checkboxWrapper}>
                  <Checkbox />
                </div>
                <div className={styles.name}>{group.name}</div>
                <div className={styles.rules}>{group.rules}</div>
                <div className={styles.instancesApplied}>{group.instances}</div>
                <div>
                  <div
                    className={styles.menuWrapper}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {group.instances === 0 && (
                      <SimpleBadge
                        color="light-background"
                        className={styles.badgeWrapper}
                      >
                        <FormattedMessage id="firewall-security-groups.unassaigned-firewall" />
                      </SimpleBadge>
                    )}
                    <div>
                      <CustomMenu
                        isOpen={group.isDropdownMenuOpen}
                        toggle={() => handleGroupDropdownMenuToggle(group)}
                      >
                        <CustomMenuItem
                          onClick={() => handleEditGroupName(group)}
                        >
                          <FormattedMessage id="general.edit" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() => handleRemoveGroupName(group)}
                        >
                          <FormattedMessage id="general.remove" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.hr}>
                <hr />
              </div>

              <div
                className={styles.tableWrapper}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={styles.rulesTable}>
                  <FormattedMessage
                    id="firewall-security-groups.rules-table"
                    values={{ number: group.rules }}
                  />
                </div>
                <BasicTable seperatorColor="light-background">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="firewall-security-groups.action" />
                      </th>
                      <th>
                        <FormattedMessage id="firewall-security-groups.direction" />
                      </th>
                      <th>
                        <FormattedMessage id="firewall-security-groups.source-ip" />
                      </th>
                      <th>
                        <FormattedMessage id="firewall-security-groups.protocol" />
                      </th>
                      <th>
                        <FormattedMessage id="firewall-security-groups.port" />
                      </th>
                      <th>
                        <FormattedMessage id="firewall-security-groups.status" />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!groupRules[group.id] && (
                      <tr>
                        <td colSpan={7}>
                          <div className="spinner-wrapper">
                            <Spinner />
                          </div>
                        </td>
                      </tr>
                    )}

                    {groupRules[group.id]?.length === 0 && (
                      <tr>
                        <td colSpan={7}>
                          <FormattedMessage id="general.no-rows" />
                        </td>
                      </tr>
                    )}

                    {groupRules[group.id]?.map((rule, key) => (
                      <tr key={key}>
                        <td
                          className={
                            rule.action === "ACCEPT"
                              ? styles.accept
                              : styles.drop
                          }
                        >
                          {rule.action === "ACCEPT" ? (
                            <FormattedMessage id="firewall-security-groups.accept" />
                          ) : (
                            <FormattedMessage id="firewall-security-groups.drop" />
                          )}
                        </td>
                        <td>{rule.type}</td>
                        <td className={styles.ipset}>{rule.source}</td>
                        <td>{rule.macro || rule.protoName}</td>
                        <td>{rule.dport}</td>
                        <td>{rule.enable ? <ActiveSvg /> : <DisableSvg />}</td>
                        <td className={styles.ruleActions}>
                          <CopySvg
                            className={loading ? styles.disabled : ""}
                            onClick={() => handleCopyRuleClicked(group, rule)}
                          />
                          <EditSvg
                            className={loading ? styles.disabled : ""}
                            onClick={() => handleEditRuleClicked(group, rule)}
                          />
                          <TrashSvg
                            className={loading ? styles.disabled : ""}
                            onClick={() => handleRemoveRuleClicked(key, group)}
                          />
                        </td>
                      </tr>
                    ))}

                    {groupRules[group.id]?.length > 0 && (
                      <tr>
                        <td className={styles.drop}>
                          <FormattedMessage id="firewall-security-groups.drop" />
                        </td>
                        <td>{getStaticDropDirection(group)}</td>
                        <td className={styles.ipset}>0.0.0.0/0</td>
                        <td>any</td>
                        <td>0 - 65535</td>
                        <td>
                          <ActiveSvg />{" "}
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </BasicTable>

                <div
                  className={styles.addNewRule}
                  onClick={() => handleAddNewRuleClicked(group)}
                >
                  <FormattedMessage id="firewall-security-groups.add-new-rule" />
                </div>
              </div>

              <div className={styles.hr} onClick={(e) => e.stopPropagation()}>
                <hr />
              </div>

              <div
                className={styles.instancesAppliedSection}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={styles.selectWrapper}>
                  <div className={styles.container}>
                    <div className={styles.title}>
                      <FormattedMessage id="firewall-security-groups.instances-applied" />
                    </div>
                    <div
                      className={styles.action}
                      onClick={handleSelectAllClicked}
                    >
                      <FormattedMessage id="general.select-all" />
                    </div>
                    <div
                      className={styles.action}
                      onClick={handleDeselectAllClicked}
                    >
                      <FormattedMessage id="general.deselect-all" />
                    </div>
                  </div>
                  <div className={styles.searchWrapper}>
                    <CustomText
                      placeholder={intl.formatMessage({
                        id: "firewall-security-groups.search-for-instance",
                      })}
                      icon="search"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.items}>
                  {group.domains &&
                    group.domains
                      .filter(
                        (domain) => !filter || domain.hostname.includes(filter)
                      )
                      .map((domain, key) => (
                        <div key={key} className={styles.item}>
                          <div className={styles.checkboxWrapper}>
                            <Checkbox
                              checked={domain.assigned}
                              onChange={(e) => handleAssignedClicked(e, domain)}
                            />
                            <span className={styles.spanWrapper}>
                              <span
                                onClick={() =>
                                  handleAssignedClicked(
                                    {
                                      target: { checked: !domain.assigned },
                                    },
                                    domain
                                  )
                                }
                              >
                                {domain.hostname}
                              </span>{" "}
                              <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={`/${lang}/my-cloud/servers/${domain._id}/overview`}
                              >
                                <ExternalLinkSvg />
                              </a>
                            </span>
                          </div>
                          {domain.assigned && (
                            <div className={styles.interfaces}>
                              <div
                                id="tooltip-network-interface"
                                className={styles.title}
                              >
                                <FormattedMessage id="firewall-security-groups.network-interface" />{" "}
                                <HelpSvg />
                              </div>

                              <Tooltip
                                placement="bottom"
                                isOpen={isNetworkInterfaceTooltipOpen}
                                target="tooltip-network-interface"
                                toggle={() =>
                                  setIsNetworkInterfaceTooltipOpen(
                                    !isNetworkInterfaceTooltipOpen
                                  )
                                }
                              >
                                <FormattedMessage id="firewall-security-groups.network-interface-tooltip" />
                              </Tooltip>

                              {Object.keys(
                                domain.proxmox_nets_firewall_status
                              ).map((netNum, key) => (
                                <div
                                  key={key}
                                  className={styles.checkboxWrapper}
                                >
                                  <Checkbox
                                    checked={domain[`net${netNum}`]}
                                    onChange={(e) =>
                                      handleAssignedNetClicked(
                                        e,
                                        domain,
                                        netNum
                                      )
                                    }
                                  />
                                  #{netNum}
                                  <SimpleBadge textColor="lighter-purple">
                                    {netNum === "0" ? "Public" : "Private"}
                                  </SimpleBadge>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                </div>
                <div>
                  <IconButton
                    disabled={loading}
                    color="light-purple"
                    onClick={() => handleUpdateClicked(group)}
                  >
                    <FormattedMessage id="general.apply-changes" />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>

        <AddNewRuleModal
          isOpen={isAddNewRuleModalOpen}
          onClose={handleAddNewRuleModalClosed}
          securityGroup={selectedGroup}
          selectedRule={selectedRule}
          userIDToWork={userIDToWork}
        />
      </HeaderWithNavs>
    </WithRole>
  );
}
