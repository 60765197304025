import styles from "./logs.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAjax, useUser } from "../../../utils/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import BasicTable from "../../../components/basic-table";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import {} from "../../../utils";
import Spinner from "../../../components/spinner";
import Pagination from "../../../components/pagination";
import { format } from "date-fns";

const maxRowsInPage = 100;

export default function AccountLogs() {
  const ajax = useAjax();
  const user = useUser();
  const intl = useIntl();

  const [logs, setLogs] = useState(null);
  const [page, setPage] = useState(0);

  const getLogs = useCallback(async () => {
    const data = await ajax("/logs/get");

    setLogs(data.logs);
  }, [ajax]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  function renderLog(log) {
    switch (log.log_type) {
      case "LOG_TYPE_USER_REGISTER":
        return intl.formatMessage({ id: "account-logs.register" });
      case "LOG_TYPE_USER_LOGIN":
        return intl.formatMessage({ id: "account-logs.login" });
      case "LOG_TYPE_ROLE_TEMPLATE_CREATED":
        return intl.formatMessage(
          { id: "account-logs.role-template-created" },
          { name: log.data.roleName, b: (arr) => `<b>${arr[0]}</b>` }
        );
      case "LOG_TYPE_ROLE_TEMPLATE_EDITED":
        return intl.formatMessage(
          { id: "account-logs.role-template-edited" },
          { name: log.data.roleName, b: (arr) => `<b>${arr[0]}</b>` }
        );
      case "LOG_TYPE_ROLE_TEMPLATE_REMOVED":
        return intl.formatMessage(
          { id: "account-logs.role-template-removed" },
          { name: log.data.roleName, b: (arr) => `<b>${arr[0]}</b>` }
        );
      case "LOG_TYPE_CREATED_NEW_USER":
        return intl.formatMessage(
          { id: "account-logs.created-new-user" },
          {
            email: log.data.email,
            roleName: log.data.roleName,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_REMOVED_USER":
        return intl.formatMessage(
          { id: "account-logs.removed-user" },
          {
            email: log.data.email,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_EDIT_USER":
        return intl.formatMessage(
          { id: "account-logs.edit-user" },
          {
            email: log.data.email,
            roleName: log.data.roleName,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_UPDATE_DETAILS":
        return intl.formatMessage({ id: "account-logs.update-details" });
      case "LOG_TYPE_CHANGE_PHONE_NUMBER":
        return intl.formatMessage(
          { id: "account-logs.change-phone-number" },
          {
            phone: log.data.phone,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_CHANGE_EMAIL":
        return intl.formatMessage(
          { id: "account-logs.change-email" },
          {
            email: log.data.email,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_PASSWORD_UPDATED":
        return intl.formatMessage({ id: "account-logs.password-updated" });
      case "LOG_TYPE_2FA_ACTIVATED":
        return intl.formatMessage({ id: "account-logs.2fa-activated" });
      case "LOG_TYPE_2FA_DISABLED":
        return intl.formatMessage({ id: "account-logs.2fa-disabled" });
      case "LOG_TYPE_2FA_NEW_DEVICE":
        return intl.formatMessage({ id: "account-logs.2fa-new-device" });
      case "LOG_TYPE_RESET_ALL_SERVERS_PASSWORD":
        return intl.formatMessage({
          id: "account-logs.reset-all-servers-password",
        });
      case "LOG_TYPE_UPDATE_AUTO_LOGOUT_MINUTES":
        return intl.formatMessage(
          {
            id: "account-logs.update-auto-logout-minutes",
          },
          { minutes: log.data.minutes }
        );
      case "LOG_TYPE_ADD_INVOICES_EMAIL":
        return intl.formatMessage(
          { id: "account-logs.add-invoices-email" },
          { email: log.data.email, b: (arr) => `<b>${arr[0]}</b>` }
        );
      case "LOG_TYPE_REMOVE_INVOICES_EMAIL":
        return intl.formatMessage(
          { id: "account-logs.remove-invoices-email" },
          { email: log.data.email, b: (arr) => `<b>${arr[0]}</b>` }
        );
      case "LOG_TYPE_UPDATE_CREDIT_CARD":
        return intl.formatMessage(
          { id: "account-logs.update-credit-card" },
          {
            cardType: log.data.cardType,
            last4Digits: log.data.last4Digits,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_SET_CARD_AS_PRIMARY":
        return intl.formatMessage(
          { id: "account-logs.set-card-as-primary" },
          {
            cardType: log.data.cardType,
            last4Digits: log.data.last4Digits,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_REMOVE_CREDIT_CARD":
        return intl.formatMessage(
          { id: "account-logs.remove-credit-card" },
          {
            cardType: log.data.cardType,
            last4Digits: log.data.last4Digits,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_SUBMIT_TICKET":
        return intl.formatMessage(
          { id: "account-logs.submit-ticket" },
          {
            ticket_number: log.data.ticket_number,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_ADD_ALIAS":
        return intl.formatMessage(
          { id: "account-logs.firewall-add-alias" },
          {
            name: log.data.name,
            ip: log.data.ip,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_REMOVE_ALIAS":
        return intl.formatMessage(
          { id: "account-logs.firewall-remove-alias" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_EDIT_ALIAS":
        return intl.formatMessage(
          { id: "account-logs.firewall-edit-alias" },
          {
            fromName: log.data.fromName,
            toName: log.data.toName,
            fromIP: log.data.fromIP,
            toIP: log.data.toIP,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_ADD_IPSET":
        return intl.formatMessage(
          { id: "account-logs.firewall-add-ipset" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_REMOVE_IPSET":
        return intl.formatMessage(
          { id: "account-logs.firewall-remove-ipset" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_EDIT_IPSET":
        return intl.formatMessage(
          { id: "account-logs.firewall-edit-ipset" },
          {
            fromName: log.data.fromName,
            toName: log.data.toName,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_ADD_SECURITY_GROUP":
        return intl.formatMessage(
          {
            id: "account-logs.firewall-add-security-group",
          },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_EDIT_SECURITY_GROUP":
        return intl.formatMessage(
          {
            id: "account-logs.firewall-edit-security-group",
          },
          {
            fromName: log.data.fromName,
            toName: log.data.toName,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_REMOVE_SECURITY_GROUP":
        return intl.formatMessage(
          {
            id: "account-logs.firewall-remove-security-group",
          },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_ADD_RULE":
        return intl.formatMessage(
          { id: "account-logs.firewall-add-rule" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_EDIT_RULE":
        return intl.formatMessage(
          { id: "account-logs.firewall-edit-rule" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_REMOVE_RULE":
        return intl.formatMessage(
          { id: "account-logs.firewall-remove-rule" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_FIREWALL_ASSIGN_SERVERS":
        return intl.formatMessage(
          {
            id: "account-logs.firewall-assign-servers",
          },
          {
            name: log.data.name,
            totalDomains: log.data.totalDomains,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_NETWORK_ADD_VLAN":
        return intl.formatMessage(
          { id: "account-logs.network-add-vlan" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_NETWORK_DELETE_VLAN":
        return intl.formatMessage(
          { id: "account-logs.network-delete-vlan" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_NETWORK_ADD_SUBNET":
        return intl.formatMessage(
          { id: "account-logs.network-add-subnet" },
          {
            name: log.data.name,
            ipAddressScope:
              log.data.ipAddressScope || log.data.ipAddressScopeCustom,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_NETWORK_DELETE_SUBNET":
        return intl.formatMessage(
          { id: "account-logs.network-delete-subnet" },
          {
            name: log.data.name,
            ipAddress: log.data.ipAddress,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_NETWORK_CHANGE_GATEWAY":
        return intl.formatMessage(
          {
            id: "account-logs.network-change-gateway",
          },
          {
            oldGateway: log.data.oldGateway,
            newGateway: log.data.newGateway,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_NETWORK_ATTACH_IP_TO_SERVER":
        return intl.formatMessage(
          {
            id: "account-logs.network-attach-ip-to-server",
          },
          {
            hostname: log.data.hostname,
            ip: log.data.ip,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_NETWORK_REMOVE_IP_FROM_SERVER":
        return intl.formatMessage(
          {
            id: "account-logs.network-remove-ip-from-server",
          },
          {
            ip: log.data.ip,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_CREATE_GROUP":
        return intl.formatMessage(
          { id: "account-logs.create-group" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_EDIT_GROUP":
        return intl.formatMessage(
          { id: "account-logs.edit-group" },
          {
            fromName: log.data.fromName,
            toName: log.data.toName,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_REMOVE_GROUP":
        return intl.formatMessage(
          { id: "account-logs.remove-group" },
          {
            name: log.data.name,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_ASSIGN_SERVERS_TO_GROUP":
        return intl.formatMessage(
          {
            id: "account-logs.assign-servers-to-group",
          },
          {
            name: log.data.name,
            totalServers: log.data.totalServers,
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
      case "LOG_TYPE_CREATE_NEW_SERVER":
        return intl.formatMessage(
          { id: "account-logs.create-new-server" },
          {
            hostname: log.data.hostname,
            location: log.data.location,
            image: log.data.image,
            cpu: log.data.cpu,
            ram_mb: log.data.ram_mb,
            ssd_gb: log.data.ssd_gb,
            additional_ssd_gb: log.data.additional_ssd_gb
              ? `, additional disk=<b>${log.data.additional_ssd_gb}</b>`
              : "",
            backup: log.data.backup ? ", <b>backup</b>" : "",
            b: (arr) => `<b>${arr[0]}</b>`,
          }
        );
    }

    return `${log.log_type} ${JSON.stringify(log.data)}`;
  }

  const logsFiltered = useMemo(
    () =>
      logs
        ? logs.slice(page * maxRowsInPage, page * maxRowsInPage + maxRowsInPage)
        : [],
    [logs, page]
  );

  if (user.current_parent) {
    return (
      <Box>
        <FormattedMessage id="general.not-allowed-under-parent" />
      </Box>
    );
  }

  return (
    <WithRole permission="account.logs">
      <div className={styles.wrapper}>
        <h2 className={styles.mainTitle}>
          <FormattedMessage id="account-logs.title" />
        </h2>

        <hr />

        <Pagination
          page={page}
          setPage={setPage}
          totalRows={logsFiltered.length}
          maxRowsInPage={maxRowsInPage}
        />

        <br />

        <Box className={styles.boxWrapper}>
          <BasicTable>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="account-logs.created_at" />
                </th>
                <th>
                  <FormattedMessage id="account-logs.action" />
                </th>
                <th>
                  <FormattedMessage id="account-logs.ip" />
                </th>
              </tr>
            </thead>
            <tbody>
              {!logsFiltered && (
                <tr>
                  <td colSpan={3}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {logsFiltered?.length === 0 && (
                <tr>
                  <td colSpan={3}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {logsFiltered?.map((log, key) => (
                <tr key={key}>
                  <td>{format(new Date(log.created_at), "d/M/y HH:mm")}</td>
                  <td dangerouslySetInnerHTML={{ __html: renderLog(log) }}></td>
                  <td>{log.user_ip}</td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </Box>

        <Pagination
          page={page}
          setPage={setPage}
          totalRows={logsFiltered.length}
          maxRowsInPage={maxRowsInPage}
        />
      </div>
    </WithRole>
  );
}
