import styles from "./two-factor-authentication.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useAjax,
  useAlert,
  useInitialDataFetching,
  useLang,
} from "../../utils/hooks";
import LoginLayout from "../../components/login-layout";
import TextField from "../../components/textfield";
import IconButton from "../../components/icon-button";
import Checkbox from "../../components/checkbox";
import KeyDetector from "../../components/key-detector";

let timerID;

export default function TwoFactorAuthentication() {
  const router = useHistory();
  const intl = useIntl();
  const lang = useLang();
  const alert = useAlert();
  const initialDataFetching = useInitialDataFetching();
  const { fingerprint } = useSelector((state) => ({
    fingerprint: state.settings.fingerprint,
  }));
  const ajax = useAjax();

  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [userIdToConnect, setUserIdToConnect] = useState(null);
  const [quickLoginToken, setQuickLoginToken] = useState(null);
  const [code, setCode] = useState("");
  const [rememberDevice, setRememberDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const checkQuickLogin = useCallback(async () => {
    if (!quickLoginToken || !userIdToConnect) {
      return;
    }

    const data = await ajax("/users/checkQuickLogin", {
      userIdToConnect,
      quickLoginToken,
      fingerprint,
    });

    if (data.result === "success") {
      if (data.userid) {
        if (await initialDataFetching(true)) {
          router.push(`/${lang || "en"}/my-cloud/servers`);
        }

        return;
      } else if (data.status === "rejected") {
        await alert(
          intl.formatMessage({
            id: "two-factor-authentication.quick-login-rejected.title",
          }),
          intl.formatMessage({
            id: "two-factor-authentication.quick-login-rejected.content",
          })
        );
        return router.push(`/${lang}/login`);
      }
    } else {
      clearInterval(timerID);
    }
  }, [
    ajax,
    quickLoginToken,
    userIdToConnect,
    fingerprint,
    lang,
    router,
    alert,
    intl,
    initialDataFetching,
  ]);

  useEffect(() => {
    if (!window.p1 || !window.p2 || !window.p3 || !window.p4) {
      delete window.p1;
      delete window.p2;
      delete window.p3;
      delete window.p4;

      setTimeout(() => {
        router.push(`/${lang}/login`);
      }, 1);

      return;
    }

    setEmail(window.p1);
    setPassword(window.p2);
    setUserIdToConnect(window.p3);
    setQuickLoginToken(window.p4);

    delete window.p1;
    delete window.p2;
    delete window.p3;
    delete window.p4;
  }, [lang, router]);

  useEffect(() => {
    if (!quickLoginToken || !userIdToConnect) {
      return;
    }

    timerID = setInterval(checkQuickLogin, 500);

    return () => {
      clearInterval(timerID);
    };
  }, [checkQuickLogin, quickLoginToken, userIdToConnect]);

  useEffect(() => {
    if (email && email.includes("@dreamvps.com")) {
      setRememberDevice(false);
    }
  }, [email]);

  async function handleVerifyClicked() {
    const _code = code.trim();

    setError(false);

    if (!_code) {
      return setError("general.empty-code");
    }

    setLoading(true);
    const data = await ajax(`/users/login2FA`, {
      email,
      password,
      code,
      rememberDevice,
      fingerprint,
    });

    if (data.result === "success") {
      if (await initialDataFetching(true)) {
        router.push(`/${lang || "en"}/my-cloud/servers`);
      }
    } else {
      setError("general.wrong-code");
      setLoading(false);
    }
  }

  function handleLoginWithSmsClicked() {
    window.p1 = email;
    window.p2 = password;
  }

  return (
    <LoginLayout>
      <div className={styles.twoFactorAuthentication}>
        <div className={`mb8 ${styles.bold}`}>
          <FormattedMessage id="two-factor-authentication.title" />
        </div>
        <div className={`mb28 ${styles.normal}`}>
          <FormattedMessage id="two-factor-authentication.content" />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleVerifyClicked}>
          <TextField
            icon="lock"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={intl.formatMessage({
              id: "two-factor-authentication.code-placeholder",
            })}
          />
        </KeyDetector>
        {error && (
          <div className={`mb28 ${styles.errorWrapper}`}>
            <FormattedMessage id={error} />
          </div>
        )}
        <div className="mb28">
          <IconButton
            disabled={loading}
            icon="arrow"
            onClick={handleVerifyClicked}
            color="purple"
            className={styles.verifyButton}
          >
            <FormattedMessage id="two-factor-authentication.verify" />
          </IconButton>
        </div>
        <div className="mb28">
          <Checkbox
            label="two-factor-authentication.remember"
            checked={rememberDevice}
            onChange={(e) => setRememberDevice(e.target.checked)}
            disabled={email && email.includes("@dreamvps.com")}
          />
        </div>
        <div className="mb28">
          <Link
            href={`/[lang]/login-sms`}
            to={`/${lang}/login-sms`}
            onClick={handleLoginWithSmsClicked}
          >
            <FormattedMessage id="two-factor-authentication.login-via-sms" />
          </Link>
        </div>

        <div className={styles.backWrapper}>
          <Link href={`/[lang]/login`} to={`/${lang}/login`}>
            <FormattedMessage id="forgot-password.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
