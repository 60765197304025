import styles from "./departments.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import BasicTable from "../../../components/basic-table";
import { useAjax, useAlert, usePrompt } from "../../../utils/hooks";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import IconButton from "../../../components/icon-button";
import DeleteDepartmentsModal from "../../../components/modals/delete-departments";
import AttachSupportersModal from "../../../components/modals/attach-supporters";
import {} from "../../../utils";

export default function Departments() {
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();
  const prompt = usePrompt();

  const [departments, setDepartments] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [
    isDeleteDepartmentsModalOpen,
    setIsDeleteDepartmentsModalOpen,
  ] = useState(false);
  const [
    isAttachSupportersModalOpen,
    setIsAttachSupportersModalOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchDepartments = useCallback(async () => {
    const data = await ajax(`/tickets/getDepartments`);

    setDepartments(data.departments);
  }, [ajax]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments]);

  async function handleCreateNewDepartmentClicked() {
    let name = await prompt({
      title: intl.formatMessage({ id: "departments.create.title" }),
      message: intl.formatMessage({ id: "departments.create.content" }),
    });

    name = name?.trim();

    if (!name) {
      return;
    }

    if (departments.find((d) => d.name === name)) {
      await alert(
        intl.formatMessage({ id: "departments.name-exists-title" }),
        intl.formatMessage({ id: "departments.name-exists-content" })
      );

      return;
    }

    setLoading(true);
    await ajax(`/tickets/createDepartment`, {
      name,
    });

    await fetchDepartments();
    setLoading(false);
  }

  function handleDropDownToggle(department) {
    department.dropdownOpen = !department.dropdownOpen;
    setDepartments([...departments]);
  }

  async function handleDeleteDepartmentsMenuClicked(department) {
    setSelectedDepartment(department);
    setIsDeleteDepartmentsModalOpen(true);
  }

  function handleAttachSupportersMenuClicked(department) {
    setSelectedDepartment(department);
    setIsAttachSupportersModalOpen(true);
  }

  async function handleAttachSupportersModalClosed(state) {
    if (state) {
      await fetchDepartments();
    }

    setIsAttachSupportersModalOpen(false);
  }

  async function handleDeleteDepartmentsModalClosed(state) {
    if (state) {
      await fetchDepartments();
    }

    setIsDeleteDepartmentsModalOpen(false);
  }

  return (
    <WithRole permission="admin.tickets.manage-departments">
      <LayoutMainAction
        title={intl.formatMessage({ id: "departments.main-title" })}
      >
        <div className={styles.wrapper}>
          <Box>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="departments.department-name" />
                  </th>
                  <th>
                    <FormattedMessage id="departments.supporters" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {departments?.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {departments?.map((department, idx) => (
                  <tr key={idx}>
                    <td>{department.name}</td>
                    <td>{department.supporters.length}</td>
                    <td>
                      <CustomMenu
                        isOpen={department.dropdownOpen}
                        toggle={() => handleDropDownToggle(department)}
                      >
                        <CustomMenuItem
                          onClick={() =>
                            handleAttachSupportersMenuClicked(department)
                          }
                        >
                          <FormattedMessage id="departments.attach-supporters" />
                        </CustomMenuItem>
                        <CustomMenuItem
                          onClick={() =>
                            handleDeleteDepartmentsMenuClicked(department)
                          }
                        >
                          <FormattedMessage id="general.delete" />
                        </CustomMenuItem>
                      </CustomMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>

            <IconButton
              color="light-purple"
              onClick={handleCreateNewDepartmentClicked}
              disabled={loading}
            >
              <FormattedMessage id="general.create" />
            </IconButton>
          </Box>
        </div>

        <DeleteDepartmentsModal
          isOpen={isDeleteDepartmentsModalOpen}
          onClose={handleDeleteDepartmentsModalClosed}
          department={selectedDepartment}
        />

        <AttachSupportersModal
          isOpen={isAttachSupportersModalOpen}
          onClose={handleAttachSupportersModalClosed}
          department={selectedDepartment}
        />
      </LayoutMainAction>
    </WithRole>
  );
}
