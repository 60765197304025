import styles from "./auto-logout.module.scss";

import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useAlert, useUser } from "../../utils/hooks";
import CustomReactSelect from "../custom-react-select";
import IconButton from "../icon-button";

export default function AutoLogout() {
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();
  const user = useUser();

  const [minutes, setMinutes] = useState(null);

  const [loading, setLoading] = useState(false);

  const options = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "auto-logout.5min" }),
        value: 5,
      },
      {
        label: intl.formatMessage({ id: "auto-logout.10min" }),
        value: 10,
      },
      {
        label: intl.formatMessage({ id: "auto-logout.30min" }),
        value: 30,
      },
      {
        label: intl.formatMessage({ id: "auto-logout.1hour" }),
        value: 60,
      },
      {
        label: intl.formatMessage({ id: "auto-logout.2hours" }),
        value: 120,
      },
    ],
    [intl]
  );

  useEffect(() => {
    if (!user._id || minutes) {
      return;
    }

    if (user.auto_logout_minutes) {
      setMinutes(
        options.find((minute) => minute.value === user.auto_logout_minutes)
      );
    } else {
      setMinutes(options[0]);
    }
  }, [options, user, minutes]);

  async function handleSaveClicked() {
    setLoading(true);
    const data = await ajax(`/users/updateAutoLogoutMinutes`, {
      minutes: minutes.value,
    });
    setLoading(false);

    if (data.result === "success") {
      await alert(
        intl.formatMessage({ id: "auto-logout.saved.title" }),
        intl.formatMessage({ id: "auto-logout.saved.content" })
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: "auto-logout.description" }),
        }}
      ></div>

      <div className={styles.selectWrapper}>
        <CustomReactSelect
          instanceId="auto-logout"
          options={options}
          value={minutes}
          onChange={(value) => setMinutes(value)}
        />
      </div>

      <div className={styles.buttonsWrapper}>
        <IconButton
          disabled={loading}
          color="light-purple"
          onClick={handleSaveClicked}
        >
          <FormattedMessage id="general.save" />
        </IconButton>
      </div>
    </div>
  );
}
