import styles from "./payment.module.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import Box from "../../../../../components/box";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useAjax,
  useAlert,
  useServer,
  useWLDVPS,
} from "../../../../../utils/hooks";
import IconButton from "../../../../../components/icon-button";
import {
  currencySymbols,
  getSymbolsForReactSelect,
} from "../../../../../utils/billing";
import CustomText from "../../../../../components/custom-text";
import CustomReactSelect from "../../../../../components/custom-react-select";
import { WithRole } from "../../../../../components/with-role";
import Checkbox from "../../../../../components/checkbox";
import {} from "../../../../../utils";
import BasicTable from "../../../../../components/basic-table";
import Spinner from "../../../../../components/spinner";

const currencySymbolsMapped = getSymbolsForReactSelect();

export default function ServerPayment() {
  const server = useServer();
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();
  const wldvps = useWLDVPS();

  const payEveryOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    24,
    36,
    48,
    60,
  ].map((num) => ({
    label: `${num} ${
      num === 1
        ? intl.formatMessage({ id: "general.month" })
        : intl.formatMessage({ id: "general.months" })
    }`,
    value: num,
  }));
  const payEveryOptionsRef = useRef(payEveryOptions);

  const payDayOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
  ].map((num) => ({
    label: num,
    value: num,
  }));
  const payDayOptionsRef = useRef(payDayOptions);

  const paymentTypeOptions = [
    { label: intl.formatMessage({ id: "general.hourly" }), value: "hourly" },
    { label: intl.formatMessage({ id: "general.monthly" }), value: "monthly" },
  ];
  const paymentTypeOptionsRef = useRef(paymentTypeOptions);

  const [currency, setCurrency] = useState(currencySymbolsMapped[0]);
  const [fixedPrice, setFixedPrice] = useState("0");
  const [nextPayDay, setNextPayDay] = useState(new Date());
  const [payDay, setPayDay] = useState(payDayOptions[0]);
  const [payEvery, setPayEvery] = useState(payEveryOptions[0]);
  const [paymentType, setPaymentType] = useState(paymentTypeOptions[0]);
  const [isFirstMonth, setIsFirstMonth] = useState(false);
  const [freeBandwidth, setFreeBandwidth] = useState(false);
  const [freeBandwidthUpTo, setFreeBandwidthUpTo] = useState(0);
  const [simulatedHourlyCronItems, setSimulatedHourlyCronItems] = useState(
    null
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!server) {
      return;
    }

    const serverPayment = wldvps ? server.whitelabel_payment : server.payment;

    setCurrency(
      currencySymbolsMapped.find(
        (item) => item.value === serverPayment.currency
      )
    );
    setFixedPrice(serverPayment.fixed_price?.toString() || "0");
    setNextPayDay(new Date(serverPayment.next_pay_day));
    setPayDay(
      payDayOptionsRef.current.find(
        (item) => item.value === serverPayment.payDay
      )
    );
    setPayEvery(
      payEveryOptionsRef.current.find(
        (item) => item.value === serverPayment.payEvery
      )
    );
    setPaymentType(
      paymentTypeOptionsRef.current.find(
        (item) => item.value === server.payment.payment_type
      )
    );
    setIsFirstMonth(serverPayment.is_first_month);
    setFreeBandwidth(serverPayment.free_bandwidth);
    setFreeBandwidthUpTo(serverPayment.free_bandwidth_up_to);
  }, [server, wldvps]);

  const runHourlyCron = useCallback(async () => {
    if (!server || server.payment.payment_type !== "hourly") {
      return;
    }

    const data = await ajax("/billing/simulateHourlyCron", {
      userID: server.user_id,
      serverID: server._id,
    });

    if (data.result === "success" && data.items[0]) {
      data.items[0].data = data.items[0].data.filter((item) => item.amount > 0);

      setSimulatedHourlyCronItems(data.items[0].data);
    }
  }, [ajax, server]);

  useEffect(() => {
    runHourlyCron();
  }, [runHourlyCron]);

  async function handleSaveClicked() {
    const _fixedPrice = parseFloat(fixedPrice);
    let _freeBandwidthUpTo = parseFloat(freeBandwidthUpTo);

    if (!freeBandwidth) {
      _freeBandwidthUpTo = 0;
    }

    setError(false);

    if (!Number.isFinite(_fixedPrice)) {
      return setError(
        intl.formatMessage({ id: "server-payment.wrong-fixed-price" })
      );
    }
    if (!Number.isFinite(_freeBandwidthUpTo)) {
      return setError(
        intl.formatMessage({ id: "server-payment.wrong-free-traffic-number" })
      );
    }

    setLoading(true);
    await ajax("/admin/saveServerPayment", {
      currency: currency.value,
      fixedPrice: _fixedPrice,
      nextPayDay,
      payDay: payDay.value,
      payEvery: payEvery.value,
      paymentType: paymentType.value,
      isFirstMonth,
      freeBandwidth,
      freeBandwidthUpTo: _freeBandwidthUpTo,
      serverID: server._id,
    });
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "server-payment.save-server-payment.title" }),
      intl.formatMessage({ id: "server-payment.save-server-payment.content" })
    );
  }

  const simulatedHourlyCronItemsTotal = useMemo(
    () =>
      simulatedHourlyCronItems?.reduce(
        (total, item) => total + item.amount * item.quantity,
        0
      ),
    [simulatedHourlyCronItems]
  );

  return (
    <WithRole permission="admin.servers.payment">
      <div className={styles.wrapper}>
        <Box>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.fixed-price" />
            </span>
            <CustomText
              value={fixedPrice}
              onChange={(e) => setFixedPrice(e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.currency" />
            </span>
            <CustomReactSelect
              instanceId="server-payment-currency"
              options={currencySymbolsMapped}
              value={currency}
              onChange={(item) => setCurrency(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.payment-type" />
            </span>
            <CustomReactSelect
              instanceId="server-payment-payment-type"
              options={paymentTypeOptions}
              value={paymentType}
              onChange={(item) => setPaymentType(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.pay-every" />
            </span>
            <CustomReactSelect
              instanceId="create-addon-modal-pay-every"
              options={payEveryOptions}
              value={payEvery}
              onChange={(item) => setPayEvery(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              <FormattedMessage id="server-payment.pay-day" />
            </span>
            <CustomReactSelect
              instanceId="create-addon-modal-pay-day"
              options={payDayOptions}
              value={payDay}
              onChange={(item) => setPayDay(item)}
            />
          </div>
          <div className={styles.row}>
            <span>
              {server?.payment.payment_type === "monthly" ? (
                <FormattedMessage id="server-payment.next-pay-day" />
              ) : (
                <FormattedMessage id="server-payment.last-pay-day" />
              )}
            </span>
            <DatePicker
              wrapperClassName="select"
              selected={nextPayDay}
              onChange={(date) => setNextPayDay(date)}
              placeholderText={`${intl.formatMessage({
                id: "general.select",
              })}...`}
              showTimeSelect={server?.payment.payment_type === "hourly"}
              dateFormat={
                server?.payment.payment_type === "monthly"
                  ? "dd/MM/yyyy"
                  : "dd/MM/yyyy HH:mm"
              }
            />
          </div>
          <div className={styles.row}>
            <Checkbox
              label="server-payment.is-first-month"
              checked={isFirstMonth}
              onChange={(e) => setIsFirstMonth(e.target.checked)}
            />
          </div>
          <div className={styles.row}>
            <Checkbox
              label="server-payment.free-traffic"
              checked={freeBandwidth}
              onChange={(e) => setFreeBandwidth(e.target.checked)}
            />
            {freeBandwidth && (
              <div className={styles.freeBandwidthWrapper}>
                <div className={styles.text}>
                  <FormattedMessage id="general.up-to" tagName="span" />{" "}
                  <CustomText
                    value={freeBandwidthUpTo}
                    onChange={(e) => setFreeBandwidthUpTo(e.target.value)}
                  />
                  TB
                </div>
                <div className={styles.description}>
                  <FormattedMessage id="server-payment.free-traffic-description" />
                </div>
              </div>
            )}
          </div>

          {error && <div className={`error ${styles.error}`}>{error}</div>}

          <IconButton
            disabled={loading}
            color="light-purple"
            onClick={handleSaveClicked}
          >
            <FormattedMessage id="general.save" />
          </IconButton>
        </Box>

        {server?.payment.payment_type === "hourly" && (
          <Box
            title={intl.formatMessage({ id: "server-payment.next-payment" })}
          >
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.name" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.description" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.amount" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.quantity" />
                  </th>
                  <th>
                    <FormattedMessage id="server-payment.next-payment.total" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {!simulatedHourlyCronItems && (
                  <tr>
                    <td colSpan={5}>
                      <div className="spinner-wrapper">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}

                {simulatedHourlyCronItems?.length === 0 && (
                  <tr>
                    <td colSpan={5}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {simulatedHourlyCronItems?.map((item, key) => (
                  <tr key={key}>
                    <td>{item.name}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    </td>
                    <td>
                      {currencySymbols[server.payment.currency]}
                      {parseFloat(item.amount).toFixed(3)}
                    </td>
                    <td>{parseFloat(item.quantity).toFixed(3)}</td>
                    <td>
                      {currencySymbols[server.payment.currency]}
                      {parseFloat(item.amount * item.quantity).toFixed(3)}
                    </td>
                  </tr>
                ))}

                {simulatedHourlyCronItems && (
                  <tr>
                    <td colSpan={4}></td>
                    <td>
                      {currencySymbols[server.payment.currency]}
                      {simulatedHourlyCronItemsTotal.toFixed(3)}
                    </td>
                  </tr>
                )}
              </tbody>
            </BasicTable>
          </Box>
        )}
      </div>
    </WithRole>
  );
}
