import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import PrivateNetworking from "../../create-new-server/private-networking";
import { useAjax } from "../../../utils/hooks";
import { validateIPaddress } from "../../../utils/networks";

function PrivateNetworkingModal({ server, isOpen, onClose }) {
  const ajax = useAjax();
  const intl = useIntl();

  const [vlans, setVlans] = useState([]);
  const [selectedVlan, setSelectedVlan] = useState(null);
  const [vlanName, setVlanName] = useState("");
  const [ipAddressScope, setIpAddressScope] = useState(null);
  const [ipAddressScopeCustom, setIpAddressScopeCustom] = useState("");
  const [netmask, setNetmask] = useState(null);
  const [gateway, setGateway] = useState(null);
  const [selectedIP, setSelectedIP] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setVlanName("");
      setIpAddressScope(null);
      setIpAddressScopeCustom("");
      setNetmask(null);
      setGateway(null);
      setSelectedIP(null);
    }
  }, [isOpen]);

  async function handleSaveClicked() {
    const _vlanName = vlanName.trim();

    setError(false);

    if (!selectedVlan) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-nic" })
      );
    } else if (!ipAddressScope) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-cidr" })
      );
    } else if (
      ipAddressScope.value === "custom" &&
      !validateIPaddress(ipAddressScopeCustom)
    ) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-ip-address" })
      );
    }

    if (ipAddressScope.value === "custom") {
      const ipArr = ipAddressScopeCustom.split(".");
      if (!["10", "172", "192"].includes(ipArr[0])) {
        return setError(
          intl.formatMessage({
            id: "private-networking-modal.ip-address-scope-custom-wrong-number",
          })
        );
      } else if (ipArr[0] === "172" && parseInt(ipArr[1]) < 16) {
        return setError(
          intl.formatMessage({
            id: "private-networking-modal.ip-address-scope-custom-wrong-ip-172",
          })
        );
      } else if (ipArr[0] === "192" && parseInt(ipArr[1]) < 168) {
        return setError(
          intl.formatMessage({
            id: "private-networking-modal.ip-address-scope-custom-wrong-ip-192",
          })
        );
      }
    }

    if (!netmask) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-netmask" })
      );
    } else if (!_vlanName) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-vlan-name" })
      );
    } else if (!selectedIP) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-ip" })
      );
    } else if (!gateway) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.no-gateway" })
      );
    } else if (selectedIP.value === gateway.value) {
      return setError(
        intl.formatMessage({ id: "private-networking-modal.ip-equal-gateway" })
      );
    }

    let data;

    setLoading(true);
    if (selectedVlan.value === "NEW LAN") {
      data = await ajax(`/network/createVLAN`, {
        userIDToWork: server.user_id,
        serverID: server._id,
        vlanName: _vlanName,
        ipAddressScope: ipAddressScope.value,
        ipAddressScopeCustom,
        netmask: netmask.value,
        gateway: gateway.value,
        selectedIP: selectedIP.value,
        location: server.location,
        type: "lan",
      });
    } else {
      const vlan = vlans.find((vlan) => vlan._id === selectedVlan.value);
      const subnet = vlan.subnets.find(
        (subnet) => subnet.ipAddress === ipAddressScope.value
      );

      data = await ajax(`/network/attachIpToServer`, {
        serverID: server._id,
        ip: selectedIP.value,
        subnetID: subnet._id,
        type: "lan",
      });
    }
    setLoading(false);

    onClose(data.result === "success");
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="private-networking-modal.header" />
      </ModalHeader>
      <ModalBody>
        <PrivateNetworking
          vlans={vlans}
          setVlans={setVlans}
          selectedVlan={selectedVlan}
          setSelectedVlan={setSelectedVlan}
          location={server?.location}
          vlanName={vlanName}
          setVlanName={setVlanName}
          ipAddressScope={ipAddressScope}
          setIpAddressScope={setIpAddressScope}
          ipAddressScopeCustom={ipAddressScopeCustom}
          setIpAddressScopeCustom={setIpAddressScopeCustom}
          netmask={netmask}
          setNetmask={setNetmask}
          gateway={gateway}
          setGateway={setGateway}
          selectedIP={selectedIP}
          setSelectedIP={setSelectedIP}
          server={server}
        />

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleSaveClicked}
        >
          <FormattedMessage id="general.save" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

PrivateNetworkingModal.propTypes = {
  server: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PrivateNetworkingModal;
