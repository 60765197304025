import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccountPermissionsCreateNewRole from ".";
import { useAjax } from "../../../../../utils/hooks";

export default function AccountPermissionsEditRole() {
  const { id } = useParams();
  const ajax = useAjax();

  const [role, setRole] = useState(null);

  const getRole = useCallback(async () => {
    const data = await ajax("/roles/get", { roleID: id });

    if (data.result === "success") {
      setRole(data.role);
    }
  }, [ajax, id]);

  useEffect(() => {
    getRole();
  }, [getRole]);

  return <AccountPermissionsCreateNewRole editRole={role} />;
}
