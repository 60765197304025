import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import Checkbox from "../../checkbox";
import { useAjax, useServer } from "../../../utils/hooks";

function TakeSnapshotModal({ isOpen, onClose }) {
  const intl = useIntl();
  const ajax = useAjax();
  const server = useServer();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [withRAM, setWithRAM] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setName("");
      setDescription("");
      setWithRAM(false);
      setLoading(false);
      setError(false);
    }
  }, [isOpen]);

  async function handleTakeClicked() {
    const _name = name.trim();

    setError(false);

    if (!_name.match(/^[a-zA-Z]{1,}[a-zA-Z0-9_]{1,}[a-zA-Z0-9_]*$/g)) {
      return setError(
        intl.formatMessage({ id: "take-snapshot-modal.wrong-name" })
      );
    } else if (
      _name.includes("scheduled_snapshot") ||
      _name.includes("system_snapshot") ||
      _name.includes("autodaily")
    ) {
      return setError(
        intl.formatMessage({ id: "take-snapshot-modal.illegal-name" })
      );
    }

    setLoading(true);
    await ajax("/snapshots/create", {
      serverID: server._id,
      name,
      description,
      withRAM,
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="take-snapshot-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <CustomText
            name={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.name" })}
          />
        </div>
        <div className={styles.row}>
          <Checkbox
            label="take-snapshot-modal.include-ram"
            checked={withRAM}
            onChange={() => setWithRAM(!withRAM)}
          />
        </div>
        <div>
          <CustomText
            description={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.description" })}
          />
        </div>

        {error && (
          <div
            className={`error ${styles.error}`}
            dangerouslySetInnerHTML={{ __html: error }}
          ></div>
        )}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleTakeClicked}
        >
          <FormattedMessage id="take-snapshot-modal.action" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}
TakeSnapshotModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TakeSnapshotModal;
