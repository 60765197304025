import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactCountryFlag from "react-country-flag";
import { useIntl } from "react-intl";
import { countries } from "../../utils/countries";

const options = Object.keys(countries).map((k) => ({
  value: countries[k],
  label: (
    <div className={styles.item}>
      <ReactCountryFlag countryCode={countries[k]} />
      <span className={styles.text}>{k}</span>
    </div>
  ),
}));

function CountrySelector({
  value,
  onChange,
  disabled,
  countriesFilter = false,
}) {
  const intl = useIntl();

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!value) {
      return setSelected(false);
    }

    setSelected(options.find((o) => o.value === value));
  }, [value]);

  function getOptions() {
    if (Array.isArray(countriesFilter)) {
      return options.filter((o) => countriesFilter.includes(o.value));
    }

    return options;
  }

  return (
    <Select
      instanceId="country-selector"
      value={selected}
      onChange={onChange}
      placeholder={intl.formatMessage({ id: "general.select-country" })}
      className={`${styles.countrySelectorWrapper} ${
        disabled ? styles.disabled : ""
      }`}
      options={getOptions()}
    />
  );
}

CountrySelector.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  countriesFilter: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

export default CountrySelector;
