import styles from "./overview.module.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../../../../components/icon-button";
import { useAjax, useServer, useRoles } from "../../../../../utils/hooks";
import Checkbox from "../../../../../components/checkbox";
import UserSelectorModal from "../../../../../components/modals/user-selector";
import {} from "../../../../../utils";

export default function AdminOptions() {
  const server = useServer();
  const ajax = useAjax();
  const intl = useIntl();
  const { isAllowed } = useRoles();

  const [isUserSelectorModalOpen, setIsUserSelectorModalOpen] = useState(false);

  const [ping, setPing] = useState(false);
  const [running, setRunning] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (server) {
      setPing(server.ping);
      setRunning(server.running);
      setIsWorking(server.isWorking);
    }
  }, [server]);

  async function toggleOption(field) {
    setLoading(true);

    await ajax("/servers/toggle", {
      field,
      serverID: server._id,
    });

    if (field === "ping") {
      setPing(!ping);
    }
    if (field === "running") {
      setRunning(!running);
    }
    if (field === "isWorking") {
      setIsWorking(!isWorking);
    }

    setLoading(false);
  }

  async function handleUserSelectorModalClosed(state) {
    if (state) {
      await ajax("/servers/changeServerOwner", {
        serversIDs: [server._id],
        userID: state.value,
      });
    }

    setIsUserSelectorModalOpen(false);
  }

  if (!server) {
    return null;
  }

  return (
    <>
      <div className={styles.title}>
        <div className={styles.bold}>
          <FormattedMessage id="server-admin-options.title" />
        </div>
      </div>

      <div className={styles.adminOptions}>
        {isAllowed("admin.servers.change-owner") && (
          <div className={styles.owner}>
            <div className={styles.email}>{server.userEmail}</div>
            <IconButton
              color="light-purple"
              disabled={loading}
              onClick={() => setIsUserSelectorModalOpen(true)}
            >
              <FormattedMessage id="server-admin-options.change-server-owner" />
            </IconButton>
          </div>
        )}

        {isAllowed("super-admin.servers.manage") && (
          <div>
            <Checkbox
              disabled={loading}
              label="server-admin-options.has-agent"
              onChange={() => toggleOption("ping")}
              checked={ping}
            />
            <Checkbox
              disabled={loading}
              label="server-admin-options.running"
              onChange={() => toggleOption("running")}
              checked={running}
            />
            <Checkbox
              disabled={loading}
              label="server-admin-options.is-working"
              onChange={() => toggleOption("isWorking")}
              checked={isWorking}
            />
          </div>
        )}
      </div>

      <UserSelectorModal
        title={intl.formatMessage({
          id: "server-admin-options.change-server-owner-title",
        })}
        content={intl.formatMessage(
          {
            id: "server-admin-options.change-server-owner-content",
          },
          { oldEmail: server.userEmail, b: (arr) => `<b>${arr[0]}</b>` }
        )}
        isOpen={isUserSelectorModalOpen}
        onClose={handleUserSelectorModalClosed}
      />
    </>
  );
}
