import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import IconButton from "../../icon-button";

function BiReportListModal({ title, isOpen, onClose, list }) {
  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>{title}</ModalHeader>
      <ModalBody>
        {list?.map((item, key) => (
          <div key={key}>
            <a href={item.url} target="_blank" rel="noreferrer">
              {item.title}
            </a>
            {item.additionalText}
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.close" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

BiReportListModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  list: PropTypes.array,
};

export default BiReportListModal;
