import styles from "./index.module.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import CustomReactSelect from "../../custom-react-select";
import IconButton from "../../icon-button";
import { useAjax } from "../../../utils/hooks";

function ChangeServerGatewayModal({ isOpen, onClose, server, gateway }) {
  const ajax = useAjax();
  const intl = useIntl();

  const serverRef = useRef(server);
  serverRef.current = server;

  const [ips, setIps] = useState([]);
  const [ip, setIp] = useState(null);

  const [loading, setLoading] = useState(false);

  const getIpsOfServer = useCallback(async () => {
    if (!serverRef.current) {
      return;
    }

    const data = await ajax("/network/getIpsOfServer", {
      serverID: serverRef.current._id,
    });

    const ips = data.ips
      .filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.gateway === thing.gateway)
      )
      .map((ip) => ({
        label: ip.gateway,
        value: ip.gateway,
        ip: ip.ip,
      }));

    ips.unshift({
      label: intl.formatMessage({ id: "general.none" }),
      value: null,
    });

    setIps(ips);

    setIp(ips.find((ip) => ip.value === gateway) || ips[0]);
  }, [ajax, intl, gateway]);

  useEffect(() => {
    if (isOpen) {
      getIpsOfServer();
    }
  }, [getIpsOfServer, isOpen]);

  async function handleSaveClicked() {
    setLoading(true);
    await ajax("/network/setGateway", {
      serverID: server._id,
      ip: ip.ip,
      gateway: ip.value,
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="change-server-gateway-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div>
          <CustomReactSelect
            options={ips}
            value={ip}
            onChange={(item) => setIp(item)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleSaveClicked}
        >
          <FormattedMessage id="general.save" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

ChangeServerGatewayModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  server: PropTypes.object,
  gateway: PropTypes.string,
};

export default ChangeServerGatewayModal;
