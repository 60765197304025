import styles from "./index.module.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IconButton from "../../icon-button";
import { useIntl } from "react-intl";
import CustomText from "../../custom-text";
import Spinner from "../../spinner";
import { sanitize } from "../../../utils";

function PromptModal({
  isOpen,
  onClose,
  title,
  message,
  defaultText,
  button1 = {},
  button2 = {},
  acceptOnlyValue = false,
  textType = "text",
  beforeClose = null,
  placeholder = null,
}) {
  const intl = useIntl();

  const [text, setText] = useState("");
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setText(defaultText || "");
    setError(false);
  }, [defaultText]);

  async function handleActionButtonClicked() {
    setError(false);

    if (typeof acceptOnlyValue === "string" && text !== acceptOnlyValue) {
      return setError(true);
    }
    if (typeof acceptOnlyValue === "function") {
      const { status, reason } = acceptOnlyValue(text);

      if (!status) {
        return setError(reason);
      }
    }

    if (typeof beforeClose === "function") {
      setLoading(true);
      await beforeClose(text);
      setLoading(false);
    }

    onClose(text);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(null)}
      size="lg"
    >
      <ModalHeader className={styles.header} toggle={() => onClose(null)}>
        {title}
      </ModalHeader>
      <ModalBody className={styles.body}>
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(message) }}
          className={styles.text}
        ></div>
        <div>
          <CustomText
            value={text}
            type={textType}
            onChange={(e) => setText(e.target.value)}
            placeholder={placeholder}
          />
        </div>
        {error && (
          <div className={`error ${styles.error}`}>
            {typeof error === "boolean" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    { id: "prompt-modal.error" },
                    {
                      value: acceptOnlyValue,
                      b: (arr) => `<b>${arr[0]}</b>`,
                    }
                  ),
                }}
              ></div>
            )}

            {typeof error === "string" && error}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div className={styles.buttons}>
          <IconButton
            disabled={loading}
            icon={button1.icon || false}
            color={button1.color || "green"}
            onClick={() => onClose(null)}
            textColor={button1.textColor || false}
          >
            {button1.text || intl.formatMessage({ id: "general.close" })}
          </IconButton>
          <IconButton
            disabled={loading}
            icon={button2.icon || false}
            color={button2.color || "green"}
            onClick={handleActionButtonClicked}
            textColor={button2.textColor || false}
            href={button2.href}
            as={button2.as}
          >
            {!loading &&
              (button2.text || intl.formatMessage({ id: "general.ok" }))}
            {loading && <Spinner />}
          </IconButton>
        </div>
      </ModalFooter>
    </Modal>
  );
}

PromptModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  defaultText: PropTypes.string,
  button1: PropTypes.object,
  button2: PropTypes.object,
  acceptOnlyValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.func,
  ]),
  textType: PropTypes.string,
  beforeClose: PropTypes.func,
  placeholder: PropTypes.string,
};

export default PromptModal;
