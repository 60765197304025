import { useCallback, useEffect } from "react";
import { useAjax } from "../../utils/hooks";

function WithUser({ userID, setUser, reload, children }) {
  const ajax = useAjax();

  const getUser = useCallback(async () => {
    const data = await ajax("/admin/getUser", { userID });

    if (data.result === "success") {
      setUser(data.user);
    }
  }, [ajax, setUser, userID]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (reload) {
      reload.do = () => getUser();
    }
  }, [getUser, reload]);

  return children;
}

export default WithUser;
