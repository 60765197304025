import styles from "./emails.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import { useAjax, useAlert, usePrompt, useUser } from "../../../utils/hooks";
import CustomText from "../../../components/custom-text";
import TinyEditor from "../../../components/tiny-editor";
import CustomReactSelect from "../../../components/custom-react-select";
import { getFullName, SUPER_ADMIN } from "../../../utils/user";
import Checkbox from "../../../components/checkbox";
import Pagination from "../../../components/pagination";
import IconButton from "../../../components/icon-button";
import EmailLoadTemplateModal from "../../../components/modals/email-load-template";
import { getAllWLDVPSForSelect } from "../../../utils/wldvps";
import {} from "../../../utils";

const maxRowsInPage = 5;

export default function ManagementEmails() {
  const intl = useIntl();
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();
  const prompt = usePrompt();

  const [users, setUsers] = useState(false);
  const [usersFiltered, setUsersFiltered] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [whitelabel, setWhitelabel] = useState(false);
  const [whitelabels, setWhitelabels] = useState([]);
  const [search, setSearch] = useState("");
  const [loadedTemplate, setLoadedTemplate] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [
    isEmailLoadTemplateModalOpen,
    setIsEmailLoadTemplateModalOpen,
  ] = useState(false);

  const [page, setPage] = useState(0);

  const fetchUsers = useCallback(async () => {
    const data = await ajax(`/users/getAllUsers`, {
      filter: { email: 1, firstName: 1, lastName: 1 },
    });

    setUsers(data.users);
  }, [ajax]);

  const filterUsers = useCallback(() => {
    setUsersFiltered(
      users.filter(
        (user) =>
          user.whitelabel === whitelabel.value &&
          ((user.email && user.email.includes(search)) ||
            (user.firstName && user.firstName.includes(search)) ||
            (user.lastName && user.lastName.includes(search)))
      )
    );
  }, [users, whitelabel, search]);

  useEffect(() => {
    fetchUsers();

    if (user.whitelabelSettings) {
      const whitelabels = getAllWLDVPSForSelect(user.whitelabelSettings);
      setWhitelabels(whitelabels);
      setWhitelabel(whitelabels[0]);
    }
  }, [fetchUsers, user.whitelabelSettings]);

  useEffect(() => {
    if (users) {
      filterUsers();
    }
  }, [filterUsers, users]);

  async function handleSaveTemplateClicked() {
    let name = await prompt({
      title: intl.formatMessage({ id: "management-emails.save-template" }),
      message: intl.formatMessage({
        id: "management-emails.save-template.content",
      }),
      defaultText: loadedTemplate?.subject,
    });

    name = name?.trim();

    if (!name) {
      return;
    }

    setLoading(true);
    await ajax(`/emails/saveTemplate`, {
      name,
      subject,
      content,
    });
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "management-emails.save-template" }),
      intl.formatMessage({ id: "management-emails.save-template.success" })
    );
  }

  async function handleSendClicked() {
    setError(false);

    const userIds = Object.keys(selectedUsers);

    if (subject.trim() === "") {
      return setError(
        intl.formatMessage({ id: "management-emails.empty-subject" })
      );
    } else if (content.trim() === "") {
      return setError(
        intl.formatMessage({ id: "management-emails.empty-content" })
      );
    } else if (!userIds.length) {
      return setError(intl.formatMessage({ id: "management-emails.no-users" }));
    }

    setLoading(true);
    await ajax(`/emails/send`, {
      userIds,
      subject,
      html: content,
    });
    setLoading(false);

    await alert(
      intl.formatMessage({ id: "management-emails.sent-title" }),
      intl.formatMessage({ id: "management-emails.sent-content" })
    );
  }

  function handleUserSendEmailToggle(user) {
    selectedUsers[user._id] = !selectedUsers[user._id];
    setSelectedUsers({ ...selectedUsers });
  }

  function handleSelectAllUsers(state) {
    usersFiltered.forEach((user) => {
      selectedUsers[user._id] = state;
    });

    setSelectedUsers({ ...selectedUsers });
  }

  function handleEmailLoadTemplateModalClosed(template) {
    if (template) {
      setSubject(template.subject);
      setContent(template.content);
      window.tinyMCE.activeEditor.setContent(template.content);
      setLoadedTemplate(template);
    }

    setIsEmailLoadTemplateModalOpen(false);
  }

  return (
    <WithRole permission="admin.emails.manage">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "management-emails.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box>
            <div className={styles.row}>
              <span>
                <FormattedMessage id="general.subject" />
              </span>
              <CustomText
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <IconButton
                color="light-purple"
                onClick={() => setIsEmailLoadTemplateModalOpen(true)}
              >
                <FormattedMessage id="management-emails.load-template" />
              </IconButton>
            </div>

            <div className={styles.tinyEditorWrapper}>
              <TinyEditor
                onChange={(content) => setContent(content)}
                height={300}
              />
            </div>

            {user.role === SUPER_ADMIN && (
              <div className={styles.row}>
                <span>
                  <FormattedMessage id="management-emails.users-to-send" />
                </span>
                <CustomReactSelect
                  instanceId="management-emails-users-to-send"
                  options={whitelabels}
                  value={whitelabel}
                  onChange={(option) => setWhitelabel(option)}
                />
              </div>
            )}

            <div className={styles.header}>
              <div className={styles.selectors}>
                <span onClick={() => handleSelectAllUsers(true)}>
                  <FormattedMessage id="general.select-all" />
                </span>
                <span onClick={() => handleSelectAllUsers(false)}>
                  <FormattedMessage id="general.deselect-all" />
                </span>
              </div>

              <div>
                <input
                  className={styles.filterInput}
                  placeholder={intl.formatMessage({
                    id: "general.filter...",
                  })}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>

            {usersFiltered
              ?.slice(
                page * maxRowsInPage,
                page * maxRowsInPage + maxRowsInPage
              )
              .map((user, key) => (
                <div key={key}>
                  <Checkbox
                    label={
                      <span>
                        {user.email} | {getFullName(user)}
                      </span>
                    }
                    checked={!!selectedUsers[user._id]}
                    onClick={() => handleUserSendEmailToggle(user)}
                    readOnly
                  />
                </div>
              ))}

            {usersFiltered && (
              <Pagination
                page={page}
                setPage={setPage}
                totalRows={usersFiltered.length}
                maxRowsInPage={maxRowsInPage}
              />
            )}

            {error && <div className={`error ${styles.error}`}>{error}</div>}

            <div className={styles.buttons}>
              <IconButton
                disabled={loading}
                color="light-purple"
                onClick={handleSaveTemplateClicked}
              >
                <FormattedMessage id="management-emails.save-template" />
              </IconButton>
              <IconButton
                color="light-purple"
                onClick={handleSendClicked}
                disabled={loading}
              >
                <FormattedMessage id="general.send" />
              </IconButton>
            </div>
          </Box>
        </div>

        <EmailLoadTemplateModal
          isOpen={isEmailLoadTemplateModalOpen}
          onClose={handleEmailLoadTemplateModalClosed}
        />
      </LayoutMainAction>
    </WithRole>
  );
}
