import styles from "./index.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { AutoSizer, List } from "react-virtualized";
import { useHistory } from "react-router-dom";
import LayoutMainAction from "../../../../../components/layout-main-action";
import {
  useAjax,
  useAlert,
  useLang,
  useUser,
} from "../../../../../utils/hooks";
import Box from "../../../../../components/box";
import CustomText from "../../../../../components/custom-text";
import Checkbox from "../../../../../components/checkbox";
import IconButton from "../../../../../components/icon-button";
import { SUPER_ADMIN, WHITELABEL } from "../../../../../utils/user";
import {} from "../../../../../utils";

function AccountPermissionsCreateNewRole({ editRole }) {
  const user = useUser();
  const intl = useIntl();
  const ajax = useAjax();
  const alert = useAlert();
  const lang = useLang();
  const router = useHistory();

  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState({});
  const [servers, setServers] = useState({});

  const [domains, setDomains] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAllServers = useCallback(async () => {
    if (domains) {
      return;
    }

    setLoading(true);
    const data = await ajax(`/servers/getAllDomains`);
    setLoading(false);

    if (data.result === "success") {
      setDomains(data.domains);
    }
  }, [ajax, domains]);

  useEffect(() => {
    getAllServers();
  }, [getAllServers]);

  useEffect(() => {
    if (editRole) {
      setRoleName(editRole.name);
      setPermissions(editRole.permissions);
      setServers(editRole.servers);
    }
  }, [editRole]);

  function handleToggleRole(role) {
    permissions[role] = !permissions[role];
    setPermissions({ ...permissions });
  }

  function handleToggleServer(serverID) {
    servers[serverID] = !servers[serverID];
    setServers({ ...servers });
  }

  function handleToggleAllServer(state) {
    const servers = {};

    domains.forEach((domain) => {
      servers[`server.${domain._id}`] = state;
    });

    setServers({ ...servers });
  }

  function handleToggleAllPermissions(state) {
    const permissions = {};

    roles.user.forEach((section) => {
      section.permissions.forEach((permission) => {
        permissions[permission] = state;
      });
    });

    if (user.role === WHITELABEL || user.role === SUPER_ADMIN) {
      roles.whitelabel.forEach((section) => {
        section.permissions.forEach((permission) => {
          permissions[permission] = state;
        });
      });
    }

    if (user.role === SUPER_ADMIN) {
      roles["super-admin"].forEach((section) => {
        section.permissions.forEach((permission) => {
          permissions[permission] = state;
        });
      });
    }

    setPermissions({ ...permissions });
  }

  async function handleAddClicked() {
    setError(false);

    const _roleName = roleName ? roleName.trim() : false;

    if (!_roleName) {
      return setError(
        intl.formatMessage({
          id: "account-permissions-create-new-role.missing-name",
        })
      );
    }

    Object.keys(permissions).forEach((k) => {
      if (!permissions[k]) {
        delete permissions[k];
      }
    });

    Object.keys(servers).forEach((k) => {
      if (!servers[k]) {
        delete servers[k];
      }
    });

    setPermissions({ ...permissions });
    setServers({ ...servers });

    setLoading(true);

    let data;
    if (editRole) {
      data = await ajax("/roles/edit", {
        roleID: editRole._id,
        roleName,
        servers,
        permissions,
      });
    } else {
      data = await ajax("/roles/create", {
        roleName,
        servers,
        permissions,
      });
    }

    setLoading(false);

    if (data.result === "success") {
      router.push(`/${lang}/account/permissions/manage-roles`);
    } else {
      await alert(
        intl.formatMessage({ id: "edit-user-roles-modal.title" }),
        intl.formatMessage({ id: "general.general-error" })
      );
    }
  }

  const smallButton = useMemo(
    () => ({
      title: intl.formatMessage({
        id: "account-permissions-create-new-role.back",
      }),
      href: "/[lang]/account/permissions/manage-roles",
      as: `/${lang}/account/permissions/manage-roles`,
    }),
    [intl, lang]
  );

  const roles = useMemo(
    () => ({
      user: [
        {
          title: "general",
          permissions: ["general.tasks", "clients.real-time-list"],
        },
        {
          title: "servers",
          permissions: [
            "servers.all-servers-access",
            "servers.list",
            "servers.start-stop-reset",
            "servers.reinstall-destroy",
            "servers.create",
            "servers.clone",
            "servers.scheduler",
            "servers.console",
            "servers.firewall",
            "servers.edit-details",
            "servers.change-dns",
            "servers.create-delete-snapshots",
            "servers.rollback-snapshot",
            "servers.change-plan",
            "servers.change-os",
            "servers.change-password",
            "servers.add-additional-ipv4",
            "servers.manage-groups",
            "servers.addons",
            "products.list",
            "servers.change-payment",
            "servers.change-gateway",
            "servers.rdns",
            "servers.boot-order",
            "servers.backups",
            "account.emails-history",
            "account.logs",
          ],
        },
        {
          title: "networks",
          permissions: [
            "networks.see",
            "networks.vlan",
            "networks.subnet",
            "networks.ips",
          ],
        },
        {
          title: "tickets",
          permissions: ["tickets.see", "tickets.reply", "tickets.submit"],
        },
        {
          title: "billing",
          permissions: [
            "billing.invoices",
            "billing.credit-card",
            "billing.bandwidth-form",
          ],
        },
      ],
      whitelabel: [
        {
          title: "whitelabel",
          permissions: [
            "admin.products.manage",
            "admin.products.add-edit",
            "admin.products.our-prices",
            "admin.addons.create",
            "admin.invoices.multi-charge",
            "admin.invoices.set-as-paid",
            "admin.invoices.refund",
            "admin.coupons.manage",
            "admin.bandwidth-form.update",
            "admin.clients.add",
            "admin.clients.edit",
            "admin.clients.all",
            "admin.clients.create-login-link",
            "admin.clients.manage-files",
            "admin.clients.manage-other-products",
            "admin.billing.create-invoice",
            "admin.billing.delete-invoice",
            "admin.billing.export-invoice",
            "admin.servers.notes",
            "admin.servers.payment",
            "admin.servers.change-owner",
            "admin.networks.change-vlan-owner",
            "super-admin.can-bypass-payment",
            "admin.system-products.manage-prices",
            "admin.tickets.manage-departments",
            "admin.emails.manage",
          ],
        },
      ],
      "super-admin": [
        {
          title: "super-admin",
          permissions: [
            "super-admin.vat-report",
            "super-admin.leads-report",
            "super-admin.blacklist",
            "super-admin.jobs-manager",
            "super-admin.proxomx-settings",
            "super-admin.nodes-status",
            "super-admin.logs.list",
            "super-admin.servers.manage",
            "super-admin.clients.advance-changes",
            "super-admin.networks.external-ips",
            "super-admin.whitelabel-settings",
            "super-admin.iso-page",
            "super-admin.manage-credits",
            "super-admin.monthly-cron",
            "super-admin.hourly-cron",
            "super-admin.unpaid-customers",
            "super-admin.change-payment-form",
            "super-admin.gmail-blacklist",
            "super-admin.flashyapp",
            "super-admin.bi-reports",
            "super-admin.bandwidth-form",
          ],
        },
      ],
    }),
    []
  );

  function renderSection(type) {
    return roles[type].map((section, key) => (
      <div key={key} className={styles.sectionWrapper}>
        <div className={styles.sectionTitle}>
          <FormattedMessage id={`edit-user-roles-modal.${section.title}`} />
        </div>
        <div className={styles.sectionContent}>
          {section.permissions.map((permission, key) => (
            <Checkbox
              key={key}
              checked={permissions[permission] || false}
              onChange={() => handleToggleRole(permission)}
              readOnly
              label={`edit-user-roles-modal.${permission}`}
            />
          ))}
        </div>
      </div>
    ));
  }

  return (
    <LayoutMainAction
      title={intl.formatMessage(
        {
          id: editRole
            ? "account-permissions-create-new-role.edit-title"
            : "account-permissions-create-new-role.title",
        },
        {
          name: editRole ? editRole.name : "",
        }
      )}
      smallButton={smallButton}
    >
      <div className={styles.wrapper}>
        <Box>
          <div className={styles.mainTitle}>
            <FormattedMessage id="account-permissions-create-new-role.select-servers" />
          </div>

          <div className={styles.selectors}>
            <span onClick={() => handleToggleAllServer(true)}>
              <FormattedMessage id="general.select-all" />
            </span>
            <span onClick={() => handleToggleAllServer(false)}>
              <FormattedMessage id="general.deselect-all" />
            </span>
          </div>

          {domains && !permissions["servers.all-servers-access"] && (
            <div className={styles.domainsBox}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    width={width}
                    height={190}
                    rowCount={domains.length}
                    rowHeight={36}
                    rowRenderer={({ key, index, style }) => (
                      <div
                        key={key}
                        style={style}
                        className={styles.domainWrapper}
                      >
                        <Checkbox
                          checked={
                            servers[`server.${domains[index]._id}`] || false
                          }
                          onChange={() =>
                            handleToggleServer(`server.${domains[index]._id}`)
                          }
                          readOnly
                          label={<>{domains[index].hostname}</>}
                        />
                      </div>
                    )}
                  />
                )}
              </AutoSizer>
            </div>
          )}
        </Box>
        <Box>
          <div className={styles.mainTitle}>
            <FormattedMessage
              id={
                editRole
                  ? "account-permissions-create-new-role.edit-role"
                  : "account-permissions-create-new-role.add-new-role"
              }
            />
          </div>
          <div className={styles.roleNameWrapper}>
            <FormattedMessage
              id="account-permissions-create-new-role.name-of-role"
              tagName="span"
            />
            <CustomText
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </div>

          <div className={`${styles.selectors} ${styles.together}`}>
            <span onClick={() => handleToggleAllPermissions(true)}>
              <FormattedMessage id="general.select-all" />
            </span>
            <span onClick={() => handleToggleAllPermissions(false)}>
              <FormattedMessage id="general.deselect-all" />
            </span>
          </div>

          {renderSection("user")}

          {[WHITELABEL, SUPER_ADMIN].includes(user.role) &&
            renderSection("whitelabel")}

          {[WHITELABEL, SUPER_ADMIN].includes(user.role) &&
            renderSection("super-admin")}

          <hr />
          {error && <div className={`error ${styles.error}`}>{error}</div>}
          <div>
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleAddClicked}
            >
              <FormattedMessage
                id={
                  editRole
                    ? "general.save"
                    : "account-permissions-create-new-role.add-new-role"
                }
              />
            </IconButton>
          </div>
        </Box>
      </div>
    </LayoutMainAction>
  );
}

AccountPermissionsCreateNewRole.propTypes = {
  editRole: PropTypes.object,
};

export default AccountPermissionsCreateNewRole;
