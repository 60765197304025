import { currencySymbols } from "./billing";

export const USER = "user";
export const WHITELABEL = "whitelabel";
export const SUPER_ADMIN = "super-admin";

export const getFullName = (user) => {
  if (!user.firstName || user.firstName.trim() === "") {
    return user.email;
  }

  return `${user.firstName} ${user.lastName}`;
};

export const getInitials = (user) => {
  if (!user.firstName || user.firstName.trim() === "") {
    return "@";
  }

  return `${user.firstName[0]}${user.lastName[0]}`;
};

export const isSuperAdmin = (user) => {
  return user.role === "super-admin";
};

export const userCurrency = (user) => {
  return currencySymbols[user.currency];
};

export const getSuspendedReason = (reason) => {
  switch (reason) {
    case "USER_STATUS_SUSPENDED_SCAM":
      return "scam";
    case "USER_STATUS_SUSPENDED_SPAM":
      return "spam";
    case "USER_STATUS_SUSPENDED_ATTACK":
      return "attack";
    case "USER_STATUS_SUSPENDED_ABUSE":
      return "abuse";
    case "USER_STATUS_SUSPENDED_PAYMENT_ISSUE":
      return "payment-issue";
    default:
      return "general-issue";
  }
};
