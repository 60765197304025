import styles from "./alias.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import HeaderWithNavs from "../../../../components/cloud-layout/headers-with-navs";
import Box from "../../../../components/box";
import BasicTable from "../../../../components/basic-table";
import CustomMenu from "../../../../components/custom-menu";
import CustomMenuItem from "../../../../components/custom-menu/item";
import { useAjax, useConfirm, useLang } from "../../../../utils/hooks";
import IconButton from "../../../../components/icon-button";
import {} from "../../../../utils";
import { getFirewallPageNavItems } from "../../../../utils/firewall";
import AddNewAliasModal from "../../../../components/modals/add-new-alias";
import Spinner from "../../../../components/spinner";

export default function FirewallAlias() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const confirm = useConfirm();

  const [aliases, setAliases] = useState(null);
  const [isAddNewAliasModalOpen, setIsAddNewAliasModalOpen] = useState(false);
  const [selectedAlias, setSelectedAlias] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});

  const [userIDToWork, setUserIDToWork] = useState(null);

  const getAliases = useCallback(async () => {
    const data = await ajax("/firewall/getAliases", {
      userIDToWork: userIDToWork?.value,
    });

    setAliases(data.aliases);
  }, [ajax, userIDToWork?.value]);

  useEffect(() => {
    getAliases();
  }, [getAliases]);

  function handleAddClicked() {
    setSelectedAlias(false);
    setIsAddNewAliasModalOpen(true);
  }

  async function handleAddNewAliasModalClosed(state) {
    if (state) {
      await getAliases();
    }

    setIsAddNewAliasModalOpen(false);
  }

  function handleDropDownToggle(idx) {
    openDropdowns[idx] = !openDropdowns[idx];
    setOpenDropdowns({ ...openDropdowns });
  }

  function handleEditClicked(ipset) {
    setSelectedAlias(ipset);
    setIsAddNewAliasModalOpen(true);
  }

  async function handleRemoveClicked(alias) {
    await confirm({
      title: intl.formatMessage({ id: "firewall.remove.title" }),
      message: intl.formatMessage(
        { id: "firewall.remove.content" },
        { name: alias.name }
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/firewall/removeAlias", {
          aliasID: alias._id,
          userIDToWork: userIDToWork?.value,
        });

        await getAliases();
      },
    });
  }

  const withUserSelector = useMemo(
    () => ({
      className: styles.box,
      userIDToWork,
      setUserIDToWork,
    }),
    [userIDToWork]
  );

  const firewallPageNavItems = useMemo(
    () => getFirewallPageNavItems("alias", intl, lang, userIDToWork),
    [intl, lang, userIDToWork]
  );

  return (
    <HeaderWithNavs
      title={intl.formatMessage({ id: "firewall.title" })}
      navItems={firewallPageNavItems}
      withUserSelector={withUserSelector}
    >
      <Box className={styles.wrapper}>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="firewall-alias.name" />
              </th>
              <th>
                <FormattedMessage id="firewall-alias.ip" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!aliases && (
              <tr>
                <td colSpan={3}>
                  <div className="spinner-wrapper">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}

            {aliases?.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <FormattedMessage id="general.no-rows" />
                </td>
              </tr>
            )}

            {aliases?.map((alias, idx) => (
              <tr key={idx}>
                <td className={styles.name}>{alias.name}</td>
                <td className={styles.ip}>{alias.ip}</td>
                <td>
                  <CustomMenu
                    isOpen={openDropdowns[idx]}
                    toggle={() => handleDropDownToggle(idx)}
                  >
                    <CustomMenuItem onClick={() => handleEditClicked(alias)}>
                      <FormattedMessage id="general.edit" />
                    </CustomMenuItem>
                    <CustomMenuItem onClick={() => handleRemoveClicked(alias)}>
                      <FormattedMessage id="general.remove" />
                    </CustomMenuItem>
                  </CustomMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
        <div className={styles.buttonWrapper}>
          <IconButton color="light-purple" onClick={handleAddClicked}>
            <FormattedMessage id="firewall-alias.add" />
          </IconButton>
        </div>
      </Box>

      <AddNewAliasModal
        isOpen={isAddNewAliasModalOpen}
        onClose={handleAddNewAliasModalClosed}
        edit={selectedAlias}
        userIDToWork={userIDToWork}
      />
    </HeaderWithNavs>
  );
}
