import styles from "./index.module.scss";

import React, { useCallback, useState, useEffect } from "react";
import {
  getDaysInMonth,
  differenceInDays,
  endOfMonth,
  format,
  fromUnixTime,
} from "date-fns";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useServer, useAjax, useUser, useRoles } from "../../../utils/hooks";
import { currencySymbols } from "../../../utils/billing";
import Checkbox from "../../checkbox";

function CreateServerFromBackupModal({ backup, isOpen, onClose }) {
  const server = useServer();
  const { isAllowed } = useRoles();

  const user = useUser();
  const intl = useIntl();
  const ajax = useAjax();

  const [hourlyPriceOn, setHourlyPriceOn] = useState(false);
  const [hourlyPriceOff, setHourlyPriceOff] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(false);
  const [firstMonthPayment, setFirstMonthPayment] = useState(false);
  const [bypassPayment, setBypassPayment] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchPrice = useCallback(async () => {
    if (!server) {
      return;
    }

    const data = await ajax(`/billing/getServerPrice`, {
      serverData: server,
      userID: server.user_id,
    });

    setHourlyPriceOn(data.hourlyPriceOff);
    setHourlyPriceOff(data.hourlyPriceOn);
    setMonthlyPrice(data.monthlyPrice);

    const curDate = new Date();
    const daysInMonth = getDaysInMonth(curDate);
    const daysLeft = differenceInDays(endOfMonth(curDate), curDate) - 2;

    setFirstMonthPayment((data.monthlyPrice / daysInMonth) * daysLeft);
  }, [ajax, server]);

  useEffect(() => {
    fetchPrice();
  }, [fetchPrice]);

  async function handleTakeClicked() {
    setLoading(true);

    const data = await ajax(`/backups/createNewServerFromBackup`, {
      serverID: server._id,
      name: backup.volid,
      bypassPayment,
    });

    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    }
  }

  if (!server) {
    return null;
  }

  return (
    <Modal
      size="lg"
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="create-server-from-backup-modal.title" />
      </ModalHeader>
      {backup && (
        <ModalBody className={styles.wrapper}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: "create-server-from-backup-modal.description1",
                },
                {
                  paymentType: `<span>${server.payment.payment_type}</span>`,
                  name: `<span>${format(
                    fromUnixTime(backup.ctime),
                    "d/M/y HH:mm"
                  )}</span>`,
                }
              ),
            }}
          ></div>
          <br />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "create-server-from-backup-modal.description2",
              }),
            }}
          ></div>

          {server.payment.payment_type === "hourly" &&
            hourlyPriceOn &&
            hourlyPriceOff && (
              <div className={styles.box}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id: "create-server-from-backup-modal.info-box-hourly",
                      },
                      {
                        poweredOn: `<span>${
                          currencySymbols[user.currency]
                        }${hourlyPriceOn.toFixed(3)}/hr</span>`,
                        poweredOff: `<span>${
                          currencySymbols[user.currency]
                        }${hourlyPriceOff.toFixed(3)}/hr</span>`,
                        bandwidth: user.free_hourly_bandwidth
                          ? ""
                          : "Bandwidth usage of VPS is charged at <span>$0.01 per GB</span>",
                      }
                    ),
                  }}
                ></div>
              </div>
            )}

          {server.payment.payment_type === "monthly" &&
            firstMonthPayment &&
            monthlyPrice && (
              <div className={styles.box}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id: "create-server-from-backup-modal.info-box-monthly",
                      },
                      {
                        firstMonthPayment: `<b>${
                          currencySymbols[user.currency]
                        }${firstMonthPayment.toFixed(2)}</b>`,
                        monthlyPrice: `<b>${
                          currencySymbols[user.currency]
                        }${monthlyPrice.toFixed(2)}</b>`,
                      }
                    ),
                  }}
                ></div>
              </div>
            )}
        </ModalBody>
      )}
      <ModalFooter>
        {isAllowed("super-admin.can-bypass-payment") && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="general.bypass-payment"
              checked={bypassPayment}
              onChange={() => setBypassPayment(!bypassPayment)}
            />
          </div>
        )}

        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleTakeClicked}
        >
          <FormattedMessage id="create-server-from-backup-modal.action" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}
CreateServerFromBackupModal.propTypes = {
  backup: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateServerFromBackupModal;
