import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { setDate } from "date-fns";
import DatePicker from "react-datepicker";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax, useRoles, useUser } from "../../../utils/hooks";
import {
  currencySymbols,
  getSymbolsForReactSelect,
} from "../../../utils/billing";
import CustomReactSelect from "../../custom-react-select";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";
import Checkbox from "../../checkbox";

const currencySymbolsMapped = getSymbolsForReactSelect();

const dayNumbers = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
].map((number, idx) => ({
  label: number,
  value: number,
  key: idx,
}));

function CreateOtherProductModal({
  isOpen,
  onClose,
  user,
  editOtherProduct,
  whitelabel,
}) {
  const intl = useIntl();
  const ajax = useAjax();
  const { isAllowed } = useRoles();
  const currentUser = useUser();

  const payEveryOptions = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    24,
    36,
    48,
    60,
  ].map((num) => ({
    label: `${num} ${
      num === 1
        ? intl.formatMessage({ id: "general.month" })
        : intl.formatMessage({ id: "general.months" })
    }`,
    value: num,
  }));
  const payEveryOptionsRef = useRef(payEveryOptions);

  const [product, setProduct] = useState(null);
  const [description, setDescription] = useState("");
  const [adminDescription, setAdminDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [payEvery, setPayEvery] = useState(null);
  const [payDay, setPayDay] = useState(null);
  const [nextPayDay, setNextPayDay] = useState(null);
  const [isFirstMonth, setIsFirstMonth] = useState(false);
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState(null);
  const [products, setProducts] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getProducts = useCallback(async () => {
    const data = await ajax("/products/getAll");

    if (data.result === "success") {
      setProducts(
        data.products
          .filter((product) => product.type === "product")
          .map((product) => ({
            label: product.name,
            value: product,
          }))
      );
    }
  }, [ajax]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (!product || editOtherProduct) {
      return;
    }

    setPrice(product.value.price);
    setCurrency(
      currencySymbolsMapped.find(
        (currency) => currency.value === product.value.currency
      )
    );
  }, [product, editOtherProduct]);

  useEffect(() => {
    if (!user || !isOpen) {
      return;
    }

    if (editOtherProduct) {
      setProduct(products.find((p) => p.label === editOtherProduct.name));
      setDescription(editOtherProduct.description);
      setAdminDescription(editOtherProduct.admin_description);
      setPrice(editOtherProduct.price);
      setCurrency(
        currencySymbolsMapped.find((c) => c.value === editOtherProduct.currency)
      );

      setQuantity(editOtherProduct.quantity);
      setPayEvery(
        payEveryOptionsRef.current.find(
          (item) => editOtherProduct.payEvery === item.value
        )
      );
      setPayDay(
        dayNumbers.find(
          (dayNumber) => editOtherProduct.payDay === dayNumber.value
        )
      );
      setNextPayDay(new Date(editOtherProduct.next_pay_day));
      setIsFirstMonth(editOtherProduct.is_first_month);
    } else {
      setProduct(null);
      setDescription("");
      setAdminDescription("");
      setPrice("");
      setCurrency(currencySymbolsMapped[1]);
      setQuantity("");
      setPayEvery(payEveryOptionsRef.current[0]);

      setPayDay(
        dayNumbers.find((dayNumber) => dayNumber.value === user.payDay)
      );

      setNextPayDay(setDate(new Date(), user.payDay));
      setIsFirstMonth(false);
    }

    setError(false);
    setLoading(false);
  }, [editOtherProduct, isOpen, user, products]);

  async function handleCreateClicked() {
    const _description = description?.trim();
    const _adminDescription = adminDescription?.trim();
    const _quantity = parseFloat(quantity);
    const _price = parseFloat(price);

    if (!product) {
      return setError(
        intl.formatMessage({ id: "create-other-product-modal.wrong-product" })
      );
    } else if (!_quantity) {
      return setError(
        intl.formatMessage({ id: "create-other-product-modal.wrong-quantity" })
      );
    } else if (!payEvery) {
      return setError(
        intl.formatMessage({ id: "create-other-product-modal.wrong-pay-every" })
      );
    } else if (!payDay) {
      return setError(
        intl.formatMessage({ id: "create-other-product-modal.wrong-pay-day" })
      );
    } else if (!nextPayDay) {
      return setError(
        intl.formatMessage({
          id: "create-other-product-modal.wrong-next-pay-day",
        })
      );
    } else if (!_price && _price !== 0) {
      return setError(
        intl.formatMessage({ id: "create-other-product-modal.wrong-price" })
      );
    } else if (!currency) {
      return setError(
        intl.formatMessage({ id: "create-other-product-modal.wrong-currency" })
      );
    }

    setLoading(true);
    await ajax(
      editOtherProduct ? `/users/editOtherProduct` : `/users/addOtherProduct`,
      {
        name: product.value.name,
        description: _description,
        adminDescription: _adminDescription,
        userID: user._id,
        quantity: _quantity,
        payEvery: payEvery.value,
        payDay: payDay.value,
        nextPayDay,
        isFirstMonth,
        price: _price,
        currency: currency.value,
        selectedOtherProductID: editOtherProduct?._id,
        whitelabelToUse: whitelabel,
      }
    );
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage
          id={
            editOtherProduct
              ? "create-other-product-modal.title-edit"
              : "create-other-product-modal.title"
          }
        />
      </ModalHeader>
      <ModalBody>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.product-name" />
          </span>
          <CustomReactSelect
            options={products}
            value={product}
            onChange={(item) => setProduct(item)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.product-price" />
          </span>
          <CustomText
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />

          {product && [SUPER_ADMIN, WHITELABEL].includes(currentUser.role) && (
            <div className={styles.prices}>
              {product.value.price && product.value.currency && (
                <div>
                  <FormattedMessage
                    id="create-other-product-modal.original-price"
                    values={{
                      price: `${currencySymbols[product.value.currency]}${
                        product.value.price
                      }`,
                    }}
                  />
                </div>
              )}
              {isAllowed("admin.products.our-prices") &&
                product.value.our_price &&
                product.value.our_currency && (
                  <div>
                    <FormattedMessage
                      id="create-other-product-modal.our-price"
                      values={{
                        price: `${currencySymbols[product.value.our_currency]}${
                          product.value.our_price
                        }`,
                      }}
                    />
                  </div>
                )}
            </div>
          )}
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.price-currency" />
          </span>
          <CustomReactSelect
            options={currencySymbolsMapped}
            value={currency}
            onChange={(item) => setCurrency(item)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.product-description" />
          </span>
          <CustomText
            type="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {[SUPER_ADMIN, WHITELABEL].includes(currentUser.role) && (
          <div className={styles.row}>
            <span>
              <FormattedMessage id="create-other-product-modal.product-admin-description" />
            </span>
            <CustomText
              type="textarea"
              value={adminDescription}
              onChange={(e) => setAdminDescription(e.target.value)}
            />
          </div>
        )}

        <br />

        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.product-quantity" />
          </span>
          <CustomText
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.pay-every" />
          </span>
          <CustomReactSelect
            instanceId="create-other-product-modal-pay-every"
            options={payEveryOptions}
            value={payEvery}
            onChange={(option) => setPayEvery(option)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.pay-day" />
          </span>
          <CustomReactSelect
            instanceId="create-other-product-modal-pay-day"
            options={dayNumbers}
            value={payDay}
            onChange={(option) => setPayDay(option)}
          />
        </div>
        <div className={styles.row}>
          <span>
            <FormattedMessage id="create-other-product-modal.next-pay-day" />
          </span>
          <DatePicker
            wrapperClassName="select"
            selected={nextPayDay}
            onChange={(date) => setNextPayDay(date)}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className={styles.row}>
          <Checkbox
            label="create-other-product-modal.is-first-month"
            checked={isFirstMonth}
            onChange={(e) => setIsFirstMonth(e.target.checked)}
          />
        </div>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleCreateClicked}
        >
          <FormattedMessage
            id={editOtherProduct ? "general.save" : "general.create"}
          />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

CreateOtherProductModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  editOtherProduct: PropTypes.object,
  whitelabel: PropTypes.string,
};

export default CreateOtherProductModal;
