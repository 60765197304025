import styles from "./index.module.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import validator from "validator";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import { useAjax, useUser } from "../../../utils/hooks";

function EmailVerificationModal({ isOpen, onClose }) {
  const user = useUser();
  const ajax = useAjax();
  const intl = useIntl();

  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setEmail("");
    setError(false);
    setLoading(false);
    setEmailSent(false);
  }, []);

  async function handleChangeClicked() {
    if (user.email === email) {
      return setError(
        intl.formatMessage({ id: "email-verification-modal.your-email" })
      );
    } else if (!validator.isEmail(email)) {
      return setError(intl.formatMessage({ id: "general.wrong-email" }));
    }

    setLoading(true);
    const data = await ajax("/users/changeEmail", { email });
    setLoading(false);

    if (data.result === "success") {
      setEmailSent(true);
    } else {
      setError(
        intl.formatMessage({ id: "email-verification-modal.email-exists" })
      );
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="email-verification-modal.title" />
      </ModalHeader>
      <ModalBody>
        {!emailSent && (
          <>
            <div className={styles.row}>
              <FormattedMessage
                id="email-verification-modal.content"
                tagName="span"
              />
              <CustomText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {error && <div className="error">{error}</div>}
          </>
        )}

        {emailSent && (
          <FormattedMessage id="email-verification-modal.email-sent" />
        )}
      </ModalBody>
      <ModalFooter>
        {!emailSent && (
          <>
            <IconButton
              disabled={loading}
              color="purple"
              onClick={handleChangeClicked}
            >
              <FormattedMessage id="general.change" />
            </IconButton>
            <IconButton
              disabled={loading}
              color="text"
              onClick={() => onClose(false)}
            >
              <FormattedMessage id="general.cancel" />
            </IconButton>
          </>
        )}

        {emailSent && (
          <IconButton
            disabled={loading}
            color="purple"
            onClick={() => onClose(false)}
          >
            <FormattedMessage id="general.close" />
          </IconButton>
        )}
      </ModalFooter>
    </Modal>
  );
}

EmailVerificationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EmailVerificationModal;
