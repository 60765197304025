import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax } from "../../../utils/hooks";
import AddIpAddressScopeModalBody, { checkErrorForCreateVlan } from "./body";

function AddIpAddressScopeModal({ vlan, ipv, isOpen, onClose }) {
  const intl = useIntl();
  const ajax = useAjax();

  const [ipAddressScope, setIpAddressScope] = useState(null);
  const [ipAddressScopeCustom, setIpAddressScopeCustom] = useState("");
  const [netmask, setNetmask] = useState(null);
  const [gateway, setGateway] = useState(null);
  const [subnet, setSubnet] = useState("-");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIpAddressScope(null);
    setIpAddressScopeCustom("");
    setNetmask(null);
    setGateway(null);
    setSubnet("-");
  }, [isOpen]);

  async function handleAddClicked() {
    const error = checkErrorForCreateVlan({
      ipAddressScope,
      ipAddressScopeCustom,
      netmask,
      gateway,
      intl,
    });

    if (error) {
      return setError(error);
    } else {
      setError(false);
    }

    setLoading(true);
    await ajax("/network/createSubnet", {
      gateway: gateway.value,
      ipAddressScope: ipAddressScope.value,
      ipAddressScopeCustom,
      netmask: netmask.value,
      vlanID: vlan._id,
    });
    setLoading(false);

    onClose(true);
  }

  return (
    <Modal isOpen={isOpen} toggle={() => onClose(false)} size="lg">
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-ip-address-scope-modal.title" />
      </ModalHeader>
      <ModalBody>
        <AddIpAddressScopeModalBody
          ipAddressScope={ipAddressScope}
          netmask={netmask}
          setGateway={setGateway}
          setSubnet={setSubnet}
          ipAddressScopeCustom={ipAddressScopeCustom}
          setIpAddressScopeCustom={setIpAddressScopeCustom}
          setIpAddressScope={setIpAddressScope}
          setNetmask={setNetmask}
          subnet={subnet}
          ipv={ipv}
          error={error}
        />
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleAddClicked}
        >
          <FormattedMessage id="general.add" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddIpAddressScopeModal.propTypes = {
  vlan: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  ipv: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddIpAddressScopeModal;
