import styles from "./about-to-expire-cc.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithRole } from "../../../components/with-role";
import LayoutMainAction from "../../../components/layout-main-action";
import { FormattedMessage, useIntl } from "react-intl";
import { useAjax, useLang } from "../../../utils/hooks";
import {
  addYears,
  differenceInMonths,
  format,
  parse,
  startOfMonth,
} from "date-fns";
import { getFullName } from "../../../utils/user";
import BasicTable from "../../../components/basic-table";
import Box from "../../../components/box";
import {} from "../../../utils";

export default function BillingAboutToExpireCC() {
  const intl = useIntl();
  const ajax = useAjax();
  const lang = useLang();

  const [users, setUsers] = useState(null);

  const fetchAllUsers = useCallback(async () => {
    const data = await ajax(`/users/getAllUsers`, {
      filter: {
        email: 1,
        firstName: 1,
        lastName: 1,
        registerStep: 1,
        pay_with: 1,
        creditCards: 1,
      },
    });

    setUsers(data.users);
  }, [ajax]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const filteredUsers = useMemo(
    () =>
      users
        ?.filter((user) => {
          if (user.registerStep !== -1 || user.pay_with !== "credit-card") {
            return false;
          }

          const creditCard = user.creditCards.find((cc) => cc.primary);

          if (!creditCard) {
            user.expireDate = addYears(new Date(), -100);
            user.noCC = true;
            return true;
          }

          const curDate = startOfMonth(new Date());
          const cardDate = startOfMonth(
            parse(
              `${creditCard.cardMonth}${creditCard.cardYear}`,
              "Myy",
              curDate
            )
          );

          if (differenceInMonths(cardDate, curDate) <= 1) {
            user.expireDate = cardDate;
            user.noCC = false;
            return true;
          }

          return false;
        })
        .sort((a, b) => b.expireDate - a.expireDate),
    [users]
  );

  return (
    <WithRole permission="super-admin.vat-report">
      <LayoutMainAction
        title={intl.formatMessage({
          id: "billing-about-to-expire-cc.main-title",
        })}
      >
        <div className={styles.wrapper}>
          <Box>
            <BasicTable>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="billing-about-to-expire-cc.email" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-about-to-expire-cc.name" />
                  </th>
                  <th>
                    <FormattedMessage id="billing-about-to-expire-cc.date" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <FormattedMessage id="general.no-rows" />
                    </td>
                  </tr>
                )}

                {filteredUsers?.map((user, idx) => (
                  <tr key={idx}>
                    <td>
                      <a
                        href={`/${lang}/clients/clients-list/${user._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {user.email}
                      </a>
                    </td>
                    <td>{getFullName(user)}</td>
                    <td>
                      {!user.noCC
                        ? format(user.expireDate, "M/y")
                        : intl.formatMessage({
                            id: "billing-about-to-expire-cc.no-credit-card",
                          })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          </Box>
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
