import styles from "./invoices.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { addYears, endOfYear, format, startOfYear } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { ReactComponent as LongArrowLeft } from "../../../components/svgs/long-arrow-left.svg";
import Navigator from "../../../components/navigator";
import Box from "../../../components/box";
import BasicTable from "../../../components/basic-table";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  usePrompt,
  useRoles,
  useUser,
  useWLDVPS,
} from "../../../utils/hooks";
import {} from "../../../utils";
import { getInvoiceAmountFormatted } from "../../../utils/billing";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import { WithRole } from "../../../components/with-role";
import Pagination from "../../../components/pagination";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Checkbox from "../../../components/checkbox";
import RefundInvoiceModalModal from "../../../components/modals/refund-invoice";
import IconButton from "../../../components/icon-button";
import Spinner from "../../../components/spinner";
import { useDispatch } from "react-redux";
import { setActivityLogState } from "../../../store/settings";
import { getAllWLDVPS, getServerUrl } from "../../../utils/wldvps";
import InvoicesEmails from "../../../components/invoices-emails";
import { getSocket } from "../../../utils/globals";

const maxRowsInPage = 100;

export default function BillingInvoices() {
  const intl = useIntl();
  const ajax = useAjax();
  const confirm = useConfirm();
  const alert = useAlert();
  const prompt = usePrompt();
  const user = useUser();
  const lang = useLang();
  const wldvps = useWLDVPS();
  const dispatch = useDispatch();
  const { isAllowed } = useRoles();
  const socket = getSocket();

  const [selectedNav, setSelectedNav] = useState("unpaid");
  const [invoices, setInvoices] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoicesIds, setSelectedInvoicesIds] = useState({});
  const [filteredInvoices, setFilteredInvoices] = useState(null);
  const [userFilter, setUserFilter] = useState("");
  const [backToClient, setBackToClient] = useState(null);

  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);

  const [invoiceDropdownOpen, setInvoiceDropdownOpen] = useState({});
  const [whitelabel, setWhitelabel] = useState("all");
  const [whitelabels, setWhitelabels] = useState([]);
  const [isWhitelabelDropdownOpen, setIsWhitelabelDropdownOpen] = useState(
    false
  );
  const [isRefundInvoiceModalOpen, setIsRefundInvoiceModalOpen] = useState(
    false
  );

  const [fromDateFilter, setFromDateFilter] = useState(
    addYears(startOfYear(new Date()), -1)
  );
  const [toDateFilter, setToDateFilter] = useState(endOfYear(new Date()));

  const [navigatorItems, setNavigatorItems] = useState([]);

  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const navigatorItems = [
      {
        title: intl.formatMessage({ id: "billing-invoices.unpaid" }),
        selected: selectedNav === "unpaid",
        onClick: () => setSelectedNav("unpaid"),
      },
    ];

    if ([SUPER_ADMIN, WHITELABEL].includes(user.role)) {
      navigatorItems.push(
        {
          title: intl.formatMessage({
            id: "billing-invoices.unpaid-credit-card",
          }),
          selected: selectedNav === "unpaid-credit-card",
          onClick: () => setSelectedNav("unpaid-credit-card"),
        },
        {
          title: intl.formatMessage({
            id: "billing-invoices.unpaid-bank-transfer",
          }),
          selected: selectedNav === "unpaid-bank-transfer",
          onClick: () => setSelectedNav("unpaid-bank-transfer"),
        },
        {
          title: intl.formatMessage({ id: "billing-invoices.unpaid-masav" }),
          selected: selectedNav === "unpaid-masav",
          onClick: () => setSelectedNav("unpaid-masav"),
        },
        {
          title: intl.formatMessage({ id: "billing-invoices.unpaid-paypal" }),
          selected: selectedNav === "unpaid-paypal",
          onClick: () => setSelectedNav("unpaid-paypal"),
        },
        {
          title: intl.formatMessage({ id: "billing-invoices.unpaid-payoneer" }),
          selected: selectedNav === "unpaid-payoneer",
          onClick: () => setSelectedNav("unpaid-payoneer"),
        }
      );
    }

    navigatorItems.push(
      {
        title: intl.formatMessage({ id: "billing-invoices.paid" }),
        selected: selectedNav === "paid",
        onClick: () => setSelectedNav("paid"),
      },
      {
        title: intl.formatMessage({ id: "billing-invoices.refunded" }),
        selected: selectedNav === "refunded",
        onClick: () => setSelectedNav("refunded"),
      },
      {
        title: intl.formatMessage({ id: "billing-invoices.all" }),
        selected: selectedNav === "all",
        onClick: () => setSelectedNav("all"),
      }
    );

    setNavigatorItems(navigatorItems);
  }, [intl, selectedNav, user.role]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const filter = urlSearchParams.get("filter");

    if (filter) {
      setUserFilter(filter);
      setBackToClient(filter);
    }
  }, []);

  const getInvoices = useCallback(async () => {
    const data = await ajax("/billing/getInvoices");

    if (data.result === "success") {
      setInvoices(data.invoices);
    }
  }, [ajax]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  useEffect(() => {
    if (!socket || user.role !== SUPER_ADMIN) {
      return;
    }

    socket.on("update-invoices", getInvoices);

    return () => {
      socket.off("update-invoices", getInvoices);
    };
  }, [getInvoices, socket, user.role]);

  useEffect(() => {
    if (!invoices) {
      return;
    }

    let filteredInvoices = invoices.filter(
      (invoice) =>
        (user.role !== SUPER_ADMIN ||
          whitelabel === "all" ||
          (invoice.userWhitelabel === whitelabel &&
            invoice.userRole !== WHITELABEL) ||
          (!whitelabel && invoice.userRole === WHITELABEL)) &&
        new Date(invoice.created_at) >= fromDateFilter &&
        new Date(invoice.created_at) <= toDateFilter &&
        (selectedNav === "all" ||
          (selectedNav === "unpaid" && invoice.status === "unpaid") ||
          (selectedNav === "refunded" && invoice.status === "refunded") ||
          (selectedNav === "paid" && invoice.status === "paid") ||
          (selectedNav === "unpaid-credit-card" &&
            invoice.pay_with === "credit-card" &&
            invoice.status === "unpaid") ||
          (selectedNav === "unpaid-bank-transfer" &&
            invoice.pay_with === "bank-transfer" &&
            invoice.status === "unpaid") ||
          (selectedNav === "unpaid-masav" &&
            invoice.pay_with === "masav" &&
            invoice.status === "unpaid") ||
          (selectedNav === "unpaid-paypal" &&
            invoice.pay_with === "paypal" &&
            invoice.status === "unpaid") ||
          (selectedNav === "unpaid-payoneer" &&
            invoice.pay_with === "payoneer" &&
            invoice.status === "unpaid"))
    );

    if (userFilter) {
      filteredInvoices = filteredInvoices.filter(
        (invoice) =>
          invoice.invoice_number === userFilter ||
          invoice.userEmail.toLowerCase().includes(userFilter.toLowerCase()) ||
          invoice.userFirstName
            .toLowerCase()
            .includes(userFilter.toLowerCase()) ||
          invoice.userLastName.toLowerCase().includes(userFilter.toLowerCase())
      );
    }

    setFilteredInvoices(filteredInvoices);
  }, [
    user.role,
    fromDateFilter,
    invoices,
    selectedNav,
    userFilter,
    toDateFilter,
    whitelabel,
  ]);

  useEffect(() => {
    if (user.whitelabelSettings) {
      const whitelabels = getAllWLDVPS(user.whitelabelSettings);

      setWhitelabels(whitelabels);
      setWhitelabel(whitelabels[0]);
    }
  }, [user.whitelabelSettings]);

  useEffect(() => {
    setSelectedInvoicesIds({});
  }, [selectedNav]);

  function getInvoiceUrl(invoice, type) {
    let url = invoice[`url_${type}`];

    if (!url) {
      url = invoice[`url_deal`];
    }

    // if (
    //   url &&
    //   !wldvps &&
    //   !url?.includes("icount") &&
    //   !url?.includes("greeninvoice")
    // ) {
    //   url = `${getServerUrl()}${url.replace(/^.*\/\/[^/]+/, "")}`;
    // }

    return url;
  }

  function formatDate(date) {
    try {
      const retDate = format(new Date(date), "d/M/y");
      return retDate;
    } catch (err) {
      return "-";
    }
  }

  function handleInvoiceDropdownToggle(key) {
    invoiceDropdownOpen[key] = !invoiceDropdownOpen[key];
    setInvoiceDropdownOpen({ ...invoiceDropdownOpen });
  }

  async function handlePayWithCreditCardClicked(invoice) {
    await confirm({
      title: intl.formatMessage({
        id: "billing-invoices.pay-with-credit-card-title",
      }),
      message: intl.formatMessage(
        { id: "billing-invoices.pay-with-credit-card-content" },
        {
          amount: getInvoiceAmountFormatted(invoice),
          invoiceNumber: invoice.invoice_number,
        }
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        setLoading(true);
        const data = await ajax("/credit-card/payInvoice", {
          invoiceNumber: invoice.invoice_number,
        });
        setLoading(false);

        if (data.result === "success") {
          await getInvoices();
        } else {
          await alert(
            intl.formatMessage({
              id: "billing-invoices.pay-with-credit-card-title",
            }),
            data.message
          );
        }
      },
    });
  }

  function handleWhitelabelDropdownToggle() {
    setIsWhitelabelDropdownOpen(!isWhitelabelDropdownOpen);
  }

  function handleCheckboxChanged(invoiceID) {
    if (!invoiceID) {
      const allInvoicesSelected = areAllInvoicesSelected();

      const selectedInvoicesIds = filteredInvoices.reduce((object, item) => {
        object[item._id] = !allInvoicesSelected;
        return object;
      }, {});

      setSelectedInvoicesIds(selectedInvoicesIds);
    } else {
      selectedInvoicesIds[invoiceID] = !selectedInvoicesIds[invoiceID];
      setSelectedInvoicesIds({ ...selectedInvoicesIds });
    }
  }

  function areAllInvoicesSelected() {
    return (
      filteredInvoices &&
      Object.keys(selectedInvoicesIds).filter((k) => selectedInvoicesIds[k])
        .length === filteredInvoices.length
    );
  }

  function handleRefundMenuClicked(invoice) {
    setSelectedInvoice(invoice);
    setIsRefundInvoiceModalOpen(true);
  }

  async function handleRefundInvoiceModalClosed(state) {
    if (state) {
      await getInvoices();
    }

    setIsRefundInvoiceModalOpen(false);
  }

  async function handleSetPaidMenuClicked(invoice) {
    if (invoice.pay_with === "paypal") {
      await prompt({
        title: intl.formatMessage({ id: "billing-invoices.set-as-paid.title" }),
        message: intl.formatMessage({
          id: "billing-invoices.set-as-paid.content-paypal",
        }),
        beforeClose: async (transactionNumber) => {
          if (!transactionNumber) {
            return;
          }

          await setPaidMenuAfter(invoice, transactionNumber);
        },
      });
    } else {
      await confirm({
        title: intl.formatMessage({ id: "billing-invoices.set-as-paid.title" }),
        message: intl.formatMessage({
          id: "billing-invoices.set-as-paid.content",
        }),
        beforeClose: async (state) => {
          if (state !== "button2") {
            return;
          }

          await setPaidMenuAfter(invoice);
        },
      });
    }
  }

  async function setPaidMenuAfter(invoice, transactionNumber = null) {
    setLoading(true);
    const data = await ajax(`/credit-card/setInvoiceAsPaid`, {
      invoiceID: invoice._id,
      transactionNumber,
    });
    setLoading(false);

    if (data.result === "success") {
      await getInvoices();
    } else {
      if (data.message === "you_must_specify_banktransfer_account") {
        await alert(
          intl.formatMessage({ id: "billing-invoices.set-as-paid.title" }),
          intl.formatMessage({
            id: "billing-invoices.set-as-paid-failed-missing-bank-account",
          })
        );
      } else {
        await alert(
          intl.formatMessage({ id: "billing-invoices.set-as-paid.title" }),
          intl.formatMessage(
            { id: "billing-invoices.set-as-paid-failed" },
            { message: data.message }
          )
        );
      }
    }
  }

  async function handleChargeInvoicesClicked() {
    const invoicesIds = Object.keys(selectedInvoicesIds)
      .filter((key) => selectedInvoicesIds[key])
      .map((id) => id);

    let error = false;

    invoicesIds.forEach((invoiceID) => {
      const invoice = invoices.find((inv) => inv._id === invoiceID);

      if (invoice.pay_with !== "credit-card") {
        error = true;
      }
    });

    if (error) {
      return alert(
        intl.formatMessage({ id: "billing-invoices.charge.title" }),
        intl.formatMessage({ id: "billing-invoices.charge.error" })
      );
    }

    const state = await confirm({
      title: intl.formatMessage({ id: "billing-invoices.charge.title" }),
      message: intl.formatMessage(
        { id: "billing-invoices.charge.confirm" },
        { total: invoicesIds.length }
      ),
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/credit-card/payManyInvoice", { invoicesIds });

    dispatch(setActivityLogState(true));
  }

  async function handleMergeInvoiceClicked() {
    const usersIds = [];

    const invoicesIds = Object.keys(selectedInvoicesIds)
      .filter((key) => selectedInvoicesIds[key])
      .map((id) => id);

    invoicesIds.forEach((invoiceID) => {
      const invoice = invoices.find((inv) => inv._id === invoiceID);

      if (!usersIds.includes(invoice.user_id)) {
        usersIds.push(invoice.user_id);
      }
    });

    if (usersIds.length === 0) {
      return;
    }

    if (usersIds.length > 1) {
      return alert(
        intl.formatMessage({ id: "billing-invoices.merge.title" }),
        intl.formatMessage({ id: "billing-invoices.merge.error-too-many" })
      );
    }

    const state = await confirm({
      title: intl.formatMessage({ id: "billing-invoices.merge.title" }),
      message: intl.formatMessage(
        {
          id: "billing-invoices.merge.verify",
        },
        { total: invoicesIds.length }
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(false);
    setSelectedInvoicesIds({});

    const data = await ajax(`/billing/mergeInvoices`, {
      invoicesIds,
    });

    await getInvoices();
    setLoading(true);

    if (data.result !== "success") {
      await alert(
        intl.formatMessage({ id: "billing-invoices.merge.title" }),
        intl.formatMessage({ id: "billing-invoices.merge.error-general" })
      );
    }
  }

  async function handleDeleteInvoiceClicked(invoice) {
    const invoicesIds = Object.keys(selectedInvoicesIds)
      .filter((key) => selectedInvoicesIds[key])
      .map((id) => id);

    if (!invoice && invoicesIds.length === 0) {
      return;
    }

    const state = await confirm({
      title: intl.formatMessage({ id: "billing-invoices.delete.title" }),
      message: intl.formatMessage(
        {
          id: invoice
            ? "billing-invoices.delete.content"
            : "billing-invoices.delete.content-multi",
        },
        { total: invoicesIds.length }
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    if (invoice) {
      await ajax(`/admin/deleteInvoice`, {
        invoicesIds: [invoice._id],
      });
    } else {
      await ajax(`/admin/deleteInvoice`, {
        invoicesIds,
      });
    }

    await getInvoices();

    setLoading(false);

    setSelectedInvoicesIds({});
  }

  async function handleDuplicateInvoiceClicked(invoice) {
    setLoading(true);
    await ajax(`/admin/duplicateInvoice`, {
      invoiceID: invoice._id,
    });

    await getInvoices();
    setLoading(false);
  }

  function renderInvoices() {
    if (!filteredInvoices) {
      return (
        <tr>
          <td colSpan={[SUPER_ADMIN, WHITELABEL].includes(user.role) ? 8 : 6}>
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          </td>
        </tr>
      );
    }

    if (filteredInvoices.length === 0) {
      return (
        <tr>
          <td colSpan={[SUPER_ADMIN, WHITELABEL].includes(user.role) ? 8 : 6}>
            <FormattedMessage id="general.no-rows" />
          </td>
        </tr>
      );
    }

    return filteredInvoices
      .slice(page * maxRowsInPage, page * maxRowsInPage + maxRowsInPage)
      .map((invoice, key) => (
        <tr key={key}>
          {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
            <>
              <td>
                <Checkbox
                  checked={selectedInvoicesIds[invoice._id] || false}
                  onChange={() => handleCheckboxChanged(invoice._id)}
                />
              </td>
              <td>
                <a
                  href={`/${lang}/clients/clients-list/${invoice.user_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {invoice.userEmail}
                </a>
              </td>
            </>
          )}
          <td>
            <a
              href={getInvoiceUrl(
                invoice,
                invoice.status === "unpaid"
                  ? "deal"
                  : invoice.status === "paid"
                  ? "invrec"
                  : "refund"
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {invoice.invoice_number}
            </a>
          </td>
          <td>{formatDate(invoice.created_at)}</td>
          <td>{getInvoiceAmountFormatted(invoice)}</td>
          <td>{invoice.pay_with}</td>
          <td className={styles.status}>{invoice.status}</td>
          <td>
            <CustomMenu
              isOpen={invoiceDropdownOpen[key]}
              toggle={() => handleInvoiceDropdownToggle(key)}
            >
              {invoice.status === "paid" &&
                [SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                  <CustomMenuItem
                    onClick={() => handleRefundMenuClicked(invoice)}
                  >
                    <FormattedMessage id="billing-invoices.refund" />
                  </CustomMenuItem>
                )}

              {invoice.status === "unpaid" &&
                isAllowed("admin.invoices.set-as-paid") && (
                  <CustomMenuItem
                    onClick={() => handleSetPaidMenuClicked(invoice)}
                  >
                    <FormattedMessage id="billing-invoices.set-as-paid" />
                  </CustomMenuItem>
                )}

              {invoice.status === "unpaid" &&
                invoice.pay_with === "masav" &&
                isAllowed("admin.billing.create-invoice") && (
                  <CustomMenuItem
                    href={`${getServerUrl()}/billing/download-masav-file?invoice_id=${
                      invoice._id
                    }`}
                  >
                    <FormattedMessage id="invoices.download-masav-file" />
                  </CustomMenuItem>
                )}

              {invoice.status === "unpaid" &&
                invoice.pay_with === "credit-card" &&
                isAllowed("billing.invoices") && (
                  <CustomMenuItem
                    onClick={() => handlePayWithCreditCardClicked(invoice)}
                  >
                    <FormattedMessage id="billing-invoices.pay-with-credit-card" />
                  </CustomMenuItem>
                )}

              {(invoice.status === "paid" || invoice.status === "refunded") &&
                invoice.url_invrec && (
                  <CustomMenuItem
                    href={getInvoiceUrl(invoice, "invrec")}
                    target="_blank"
                  >
                    <FormattedMessage id="billing-invoices.open-receipt-tax" />
                  </CustomMenuItem>
                )}

              <CustomMenuItem
                href={getInvoiceUrl(invoice, "deal")}
                target="_blank"
              >
                <FormattedMessage id="billing-invoices.open-deal" />
              </CustomMenuItem>

              {invoice.status === "refunded" && (
                <CustomMenuItem
                  href={getInvoiceUrl(invoice, "refund")}
                  target="_blank"
                >
                  <FormattedMessage id="billing-invoices.open-refund" />
                </CustomMenuItem>
              )}

              {invoice.status === "unpaid" &&
                isAllowed("admin.billing.create-invoice") && (
                  <CustomMenuItem
                    href="/[lang]/billing/create-new-invoice"
                    as={`/${lang}/billing/create-new-invoice?edit=${invoice._id}`}
                  >
                    <FormattedMessage id="billing-invoices.edit-invoice" />
                  </CustomMenuItem>
                )}

              {isAllowed("super-admin.billing.duplicate-invoices") && (
                <CustomMenuItem
                  onClick={() => handleDuplicateInvoiceClicked(invoice)}
                >
                  <FormattedMessage id="billing-invoices.duplicate-invoice" />
                </CustomMenuItem>
              )}

              {isAllowed("admin.billing.export-invoice") && (
                <CustomMenuItem
                  href={`${serverUrl}/billing/export?invoice_id=${invoice._id}`}
                  target="_blank"
                >
                  <FormattedMessage id="billing-invoices.export-invoice" />
                </CustomMenuItem>
              )}

              {invoice.status === "unpaid" &&
                isAllowed("admin.billing.delete-invoice") && (
                  <CustomMenuItem
                    color="red"
                    onClick={() => handleDeleteInvoiceClicked(invoice)}
                  >
                    <FormattedMessage id="billing-invoices.delete-invoice" />
                  </CustomMenuItem>
                )}
            </CustomMenu>
          </td>
        </tr>
      ));
  }

  const selectedInvoices = useMemo(
    () =>
      Object.keys(selectedInvoicesIds).filter(
        (key) => selectedInvoicesIds[key]
      ),
    [selectedInvoicesIds]
  );

  const invoicesObjects = useMemo(
    () => invoices?.filter((invoice) => selectedInvoices.includes(invoice._id)),
    [invoices, selectedInvoices]
  );

  function getActionsItems() {
    if (!invoicesObjects) {
      return (
        <DropdownItem key={6}>
          <FormattedMessage id="general.no-actions" />
        </DropdownItem>
      );
    }

    const masavInvoices = [];
    let canCharge = true;
    let canMerge = true;
    let canDelete = true;

    for (let i = 0; i < invoicesObjects.length; i++) {
      if (invoicesObjects[i].pay_with === "masav") {
        masavInvoices.push(invoicesObjects[i]);
      }

      if (invoicesObjects[i].status === "paid") {
        canCharge = false;
        canMerge = false;
        canDelete = false;
      }
    }

    let createdAts = [];
    if (masavInvoices.length > 0) {
      createdAts = masavInvoices.map((invoice) =>
        format(new Date(invoice.created_at), "d/M/y")
      );
      createdAts = [...new Set(createdAts)];
    }

    const items = [];

    if (createdAts.length === 1) {
      items.push(
        <DropdownItem key={1}>
          <a
            className={styles.masavLink}
            href={`${getServerUrl()}/billing/download-masav-file?invoice_id=${selectedInvoices.join(
              ","
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage
              id="billing-invoices.actions.download-multi-masav"
              values={{
                total: selectedInvoices.length,
              }}
            />
          </a>
        </DropdownItem>
      );
    }

    if (
      isAllowed("admin.invoices.multi-charge") &&
      selectedInvoices.length > 0 &&
      canCharge
    ) {
      items.push(
        <DropdownItem key={2} onClick={() => handleChargeInvoicesClicked()}>
          <FormattedMessage
            id="billing-invoices.actions.charge"
            values={{
              total: selectedInvoices.length,
            }}
          />
        </DropdownItem>
      );
    }

    if (selectedInvoices.length > 1 && canMerge) {
      items.push(
        <DropdownItem key={3} onClick={() => handleMergeInvoiceClicked()}>
          <FormattedMessage
            id="billing-invoices.actions.merge"
            values={{
              total: selectedInvoices.length,
            }}
          />
        </DropdownItem>
      );
    }

    if (selectedInvoices.length > 0 && canDelete) {
      items.push(
        <DropdownItem key={4} onClick={() => handleDeleteInvoiceClicked()}>
          <FormattedMessage
            id="billing-invoices.actions.delete"
            values={{
              total: selectedInvoices.length,
            }}
          />
        </DropdownItem>
      );
    }

    if (items.length === 0) {
      return (
        <DropdownItem key={5}>
          <FormattedMessage id="general.no-actions" />
        </DropdownItem>
      );
    }

    return items;
  }

  const serverUrl = useMemo(() => getServerUrl(), []);

  return (
    <WithRole permission="billing.invoices">
      <div className={styles.wrapper}>
        {backToClient && (
          <div className={styles.backWrapper}>
            <Link
              href="/[lang]/clients/clients-list/[id]"
              to={`/${lang}/clients/clients-list/${backToClient}`}
            >
              <LongArrowLeft />{" "}
              <FormattedMessage
                id="billing-invoices.back-to-client"
                values={{ email: backToClient }}
              />
            </Link>
          </div>
        )}

        <h1 className={styles.mainTitle}>
          <FormattedMessage tagName="span" id="billing-invoices.main-title" />

          {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
            <>
              <IconButton
                disabled={loading}
                color="ghost"
                href={`${serverUrl}/billing/export?whitelabel=${whitelabel}&fromDateFilter=${fromDateFilter}&toDateFilter=${toDateFilter}&selectedNav=${selectedNav}&userFilter=${userFilter}`}
                target="_blank"
              >
                <FormattedMessage id="billing-invoices.export-invoices" />
              </IconButton>
              <IconButton
                disabled={loading}
                color="green"
                href="/[lang]/billing/create-new-invoice"
                as={`/${lang}/billing/create-new-invoice?filter=${userFilter}`}
              >
                <FormattedMessage id="billing-invoices.create-new-invoice" />
              </IconButton>
            </>
          )}
        </h1>

        <hr />

        <div className={styles.navigatorWrapper}>
          <Navigator items={navigatorItems} styleMode={2} />
        </div>

        <div className={styles.header}>
          {!wldvps && (
            <div>
              <FormattedMessage id="clients-list.whitelabel" />
              <ButtonDropdown
                className={styles.ghost}
                isOpen={isWhitelabelDropdownOpen}
                toggle={handleWhitelabelDropdownToggle}
              >
                <DropdownToggle caret>
                  {whitelabel === "all" ? (
                    <FormattedMessage id="general.all" />
                  ) : (
                    whitelabel || "DreamVPS"
                  )}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => setWhitelabel("all")}>
                    <FormattedMessage id="general.all" />
                  </DropdownItem>
                  {whitelabels.map((whitelabel, key) => (
                    <DropdownItem
                      key={key}
                      onClick={() => setWhitelabel(whitelabel)}
                    >
                      {whitelabel || "DreamVPS"}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          )}

          <div className={styles.filterInputWrapper}>
            <input
              className={styles.filterInput}
              placeholder={intl.formatMessage({ id: "general.filter..." })}
              value={userFilter}
              onChange={(e) => setUserFilter(e.target.value)}
            />
          </div>

          <div className={styles.ranges}>
            <span className={styles.range}>
              <FormattedMessage id="billing-invoices.table.range" />
            </span>
            <DatePicker
              wrapperClassName="text"
              selected={fromDateFilter}
              onChange={(date) => setFromDateFilter(date)}
              placeholderText={`${intl.formatMessage({
                id: "general.select",
              })}...`}
              dateFormat="dd/MM/yyyy"
            />
            <span>-</span>
            <DatePicker
              wrapperClassName="text"
              selected={toDateFilter}
              onChange={(date) => setToDateFilter(date)}
              placeholderText={`${intl.formatMessage({
                id: "general.select",
              })}...`}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <div className={styles.header}>
          <div className={styles.actions}>
            <ButtonDropdown
              className={styles.purple}
              isOpen={isActionDropdownOpen}
              toggle={() => setIsActionDropdownOpen(!isActionDropdownOpen)}
            >
              <DropdownToggle caret>
                <FormattedMessage id="billing-invoices.actions.title" />
              </DropdownToggle>
              <DropdownMenu right>{getActionsItems()}</DropdownMenu>
            </ButtonDropdown>
          </div>

          <div className={styles.total}>
            <FormattedMessage
              id="billing-invoices.total-invoices"
              values={{ total: filteredInvoices?.length || 0 }}
            />
          </div>

          {filteredInvoices && (
            <Pagination
              page={page}
              setPage={setPage}
              totalRows={filteredInvoices.length}
              maxRowsInPage={maxRowsInPage}
            />
          )}
        </div>

        <Box className={styles.invoicesWrapper}>
          <BasicTable layout="auto">
            <thead>
              <tr>
                {[SUPER_ADMIN, WHITELABEL].includes(user.role) && (
                  <>
                    <th>
                      <Checkbox
                        checked={!!areAllInvoicesSelected()}
                        onChange={() => handleCheckboxChanged()}
                      />
                    </th>
                    <th>
                      <FormattedMessage id="billing-invoices.table.email" />
                    </th>
                  </>
                )}
                <th>
                  <FormattedMessage id="billing-invoices.table.invoice" />
                </th>
                <th>
                  <FormattedMessage id="billing-invoices.table.invoice-date" />
                </th>
                <th>
                  <FormattedMessage id="billing-invoices.table.total" />
                </th>
                <th>
                  <FormattedMessage id="billing-invoices.table.payment-method" />
                </th>
                <th>
                  <FormattedMessage id="billing-invoices.table.status" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderInvoices()}</tbody>
          </BasicTable>
        </Box>

        {filteredInvoices && (
          <Pagination
            page={page}
            setPage={setPage}
            totalRows={filteredInvoices.length}
            maxRowsInPage={maxRowsInPage}
          />
        )}

        <InvoicesEmails userEmail={userFilter} />
      </div>

      <RefundInvoiceModalModal
        isOpen={isRefundInvoiceModalOpen}
        onClose={handleRefundInvoiceModalClosed}
        invoice={selectedInvoice}
      />
    </WithRole>
  );
}
