import styles from "./index.module.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import isValidDomain from "is-valid-domain";
import IconButton from "../../icon-button";
import CustomText from "../../custom-text";
import Counter from "../../counter";
import {
  useAjax,
  useRoles,
  useServers,
  useUser,
  useWLDVPS,
} from "../../../utils/hooks";
import { getDaysInMonth, differenceInDays, endOfMonth } from "date-fns";
import { getTotalActiveServers } from "../../../utils/servers";
import { userCurrency } from "../../../utils/user";
import { useSelector } from "react-redux";
import Checkbox from "../../checkbox";
import { getClosestPrice, getRelevantAddons } from "../../../utils/billing";

function CloneServerModalComponent({ server, isOpen, onClose }) {
  const intl = useIntl();
  const ajax = useAjax();
  const user = useUser();
  const wldvps = useWLDVPS();

  const serverRef = useRef(server);
  serverRef.current = server;

  const servers = useServers();
  const { isAllowed } = useRoles();

  const { tasks } = useSelector((state) => ({ tasks: state.tasks }));

  const limit = user.serversLimit - getTotalActiveServers(servers, tasks);

  const [hostnames, setHostnames] = useState([""]);

  const [hourlyPriceOn, setHourlyPriceOn] = useState(false);
  const [hourlyPriceOff, setHourlyPriceOff] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(false);
  const [firstMonthPayment, setFirstMonthPayment] = useState(false);
  const [bypassPayment, setBypassPayment] = useState(false);

  const [cpu, setCpu] = useState(null);
  const [ram_mb, setRamMB] = useState(null);
  const [ssd_gb, setSsdGB] = useState(null);
  const [additional_ssd_gb, setAdditionalSsdGb] = useState(null);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPrice = useCallback(async () => {
    if (!cpu || !ram_mb || !ssd_gb) {
      return;
    }

    const data = await ajax(`/billing/getServerPrice`, {
      userID: serverRef.current.user_id,
      serverData: {
        _id: serverRef.current._id,
        image: serverRef.current.image,
        cpu,
        ram_mb,
        ssd_gb,
        additional_ssd_gb,
        backup: serverRef.current.backup,
      },
      addons: getRelevantAddons(serverRef.current, wldvps),
    });

    setHourlyPriceOn(data.hourlyPriceOn);
    setHourlyPriceOff(data.hourlyPriceOff);
    setMonthlyPrice(data.monthlyPrice + data.addonsPrice);

    const curDate = new Date();
    const daysInMonth = getDaysInMonth(curDate);
    const daysLeft = differenceInDays(endOfMonth(curDate), curDate) + 1;

    setFirstMonthPayment((data.monthlyPrice / daysInMonth) * daysLeft);
  }, [ajax, additional_ssd_gb, cpu, ram_mb, ssd_gb, wldvps]);

  useEffect(() => {
    if (isOpen) {
      setHostnames([""]);

      setCpu(serverRef.current.cpu);
      setRamMB(serverRef.current.ram_mb);
      setSsdGB(serverRef.current.ssd_gb);
      setAdditionalSsdGb(serverRef.current.additional_ssd_gb);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchPrice();
  }, [fetchPrice]);

  async function handleTakeClicked() {
    setError(false);

    for (let i = 0; i < hostnames.length; i++) {
      if (!isValidDomain(hostnames[i])) {
        return setError(
          intl.formatMessage({ id: "clone-server-modal.wrong-hostname" })
        );
      }
    }

    setLoading(true);
    await ajax(`/servers/clone`, {
      serverID: server._id,
      hostnames,
      ipType: 4,
      cpu,
      ram_mb,
      ssd_gb,
      additional_ssd_gb,
    });
    setLoading(false);

    onClose(true);
  }

  function handleCounterChanged(num) {
    hostnames.length = num;

    for (let i = 0; i < hostnames.length; i++) {
      hostnames[i] = hostnames[i] || "";
    }

    setHostnames([...hostnames]);
  }

  function handleHostnameChanged(k, e) {
    hostnames[k] = e.target.value;
    setHostnames([...hostnames]);
  }

  function handleCounterClicked(type, oldValue, setValue, newValue) {
    const price = getClosestPrice(
      newValue,
      user.productPrices[type],
      newValue > oldValue ? "up" : "down"
    );

    if (
      (type === "ssd_gb" && price.value < server.ssd_gb) ||
      (type === "additional_ssd_gb" && price.value < server.additional_ssd_gb)
    ) {
      return;
    }

    if (server.image.includes("Windows")) {
      if (type === "ram_mb" && price.value < 2048) {
        return;
      }

      if (type === "ssd_gb" && price.value < 30) {
        return;
      }
    }

    setValue(price.value);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="clone-server-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.contentWrapper}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                { id: "clone-server-modal.content" },
                {
                  serverName: server.hostname,
                  b: (arr) => `<b>${arr[0]}</b>`,
                }
              ),
            }}
          ></div>
          <div className={styles.counterWrapper}>
            <Counter
              count={hostnames.length}
              setCount={handleCounterChanged}
              max={limit}
            />
            <div className={styles.limit}>
              <span className={styles.bold}>
                <FormattedMessage id="general.limit" />:
              </span>{" "}
              {limit}
            </div>
          </div>
        </div>

        <div className={styles.inputs}>
          {hostnames.map((hostname, key) => (
            <Fragment key={key}>
              <div className={styles.serverNameInput}>
                <CustomText
                  placeholder={intl.formatMessage(
                    {
                      id: "clone-server-modal.server-number",
                    },
                    { number: key + 1 }
                  )}
                  value={hostname}
                  onChange={(e) => handleHostnameChanged(key, e)}
                />
              </div>
            </Fragment>
          ))}
        </div>

        <div className={styles.plan}>
          <div className={styles.section}>
            <span className={styles.title}>
              <FormattedMessage id="clone-server-modal.cpu" />
            </span>
            <Counter
              count={cpu}
              setCount={(value) =>
                handleCounterClicked("cpu", cpu, setCpu, value)
              }
            />
          </div>

          <div className={styles.section}>
            <span className={styles.title}>
              <FormattedMessage id="clone-server-modal.ram" />
            </span>
            <Counter
              render={ram_mb / 1024}
              count={ram_mb}
              setCount={(value) =>
                handleCounterClicked("ram_mb", ram_mb, setRamMB, value)
              }
              delta={1024}
              extraText="GB"
            />
          </div>

          <div className={styles.section}>
            <span className={styles.title}>
              <FormattedMessage id="clone-server-modal.disk" />
            </span>
            <Counter
              count={ssd_gb}
              setCount={(value) =>
                handleCounterClicked("ssd_gb", ssd_gb, setSsdGB, value)
              }
              extraText="GB"
            />
          </div>

          <div className={styles.section}>
            <span className={styles.title}>
              <FormattedMessage id="clone-server-modal.additional-disk" />
            </span>

            {additional_ssd_gb > 0 && (
              <Counter
                count={additional_ssd_gb}
                setCount={(value) =>
                  handleCounterClicked(
                    "additional_ssd_gb",
                    additional_ssd_gb,
                    setAdditionalSsdGb,
                    value
                  )
                }
                extraText="GB"
              />
            )}

            {additional_ssd_gb === 0 && (
              <div onClick={() => setAdditionalSsdGb(10)}>
                <IconButton color="ghost">
                  <FormattedMessage id="general.add" />
                </IconButton>
              </div>
            )}
          </div>
        </div>

        {server.payment.payment_type === "monthly" && firstMonthPayment && (
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                { id: "clone-server-modal.details-monthly" },
                {
                  currency: userCurrency(user),
                  firstMonthPayment: (
                    firstMonthPayment * hostnames.length
                  ).toFixed(2),
                  monthlyPrice: monthlyPrice.toFixed(2),
                  b: (arr) => `<b>${arr[0]}</b>`,
                }
              ),
            }}
          ></div>
        )}

        {server.payment.payment_type === "hourly" &&
          hourlyPriceOn &&
          hourlyPriceOff && (
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: "clone-server-modal.details-hourly" },
                  {
                    currency: userCurrency(user),
                    hourlyPriceOn: hourlyPriceOn.toFixed(3),
                    hourlyPriceOff: hourlyPriceOff.toFixed(3),
                    b: (arr) => `<b>${arr[0]}</b>`,
                  }
                ),
              }}
            ></div>
          )}

        {error && <div className={`error ${styles.error}`}>{error}</div>}
      </ModalBody>
      <ModalFooter>
        {isAllowed("super-admin.can-bypass-payment") && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="general.bypass-payment"
              checked={bypassPayment}
              onChange={() => setBypassPayment(!bypassPayment)}
            />
          </div>
        )}

        <IconButton
          disabled={loading}
          color="purple"
          onClick={handleTakeClicked}
        >
          <FormattedMessage id="general.clone" />
        </IconButton>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

CloneServerModalComponent.propTypes = {
  server: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

function CloneServerModal(props) {
  return <CloneServerModalComponent {...props} />;
}

export default React.memo(
  CloneServerModal,
  (prev, next) => prev.isOpen === next.isOpen
);
