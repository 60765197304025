import styles from "./index.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IconButton from "../../icon-button";
import { useIntl } from "react-intl";
import { sanitize } from "../../../utils";

function AlertModal({ isOpen, onClose, title, message, button = {} }) {
  const intl = useIntl();

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose("modal")}
    >
      <ModalHeader className={styles.header} toggle={() => onClose("modal")}>
        {title}
      </ModalHeader>
      <ModalBody className={styles.body}>
        {typeof message === "object" && message}
        {typeof message !== "object" && (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(message),
            }}
          ></div>
        )}
      </ModalBody>
      <ModalFooter>
        <IconButton
          icon={button.icon || false}
          color={button.color || "green"}
          onClick={() => onClose("button")}
          textColor={button.textColor || false}
        >
          {button.text || intl.formatMessage({ id: "general.close" })}
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.any,
  button: PropTypes.object,
};

export default AlertModal;
