import styles from "./change-plan.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import Plan from "../../../../../../components/create-new-server/plan";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../../../../../components/icon-button";
import {
  useAjax,
  useAlert,
  useConfirm,
  useLang,
  useRoles,
  useServer,
  useUser,
} from "../../../../../../utils/hooks";
import { userCurrency } from "../../../../../../utils/user";
import { differenceInDays, endOfMonth, getDaysInMonth } from "date-fns";
import { dataLayerPush } from "../../../../../../utils";
import { useHistory } from "react-router-dom";
import Checkbox from "../../../../../../components/checkbox";
import SimpleBadge from "../../../../../../components/simple-badge";
import { WithRole } from "../../../../../../components/with-role";
import { WINDOWS10_IMAGE } from "../../../../../../utils/servers";
import Box from "../../../../../../components/box";
import Spinner from "../../../../../../components/spinner";
import { getSocket } from "../../../../../../utils/globals";

export default function ServerSettingsChangePlan() {
  const intl = useIntl();
  const server = useServer();
  const user = useUser();
  const ajax = useAjax();
  const router = useHistory();
  const lang = useLang();
  const alert = useAlert();
  const confirm = useConfirm();
  const { isAllowed } = useRoles();
  const socket = getSocket();

  const [prevPrice, setPrevPrice] = useState(false);
  const [nextPrice, setNextPrice] = useState(false);

  const [cpu, setCpu] = useState(0);
  const [ram_mb, setRamMB] = useState(0);
  const [ssd_gb, setSsdGB] = useState(0);
  const [additional_ssd_gb, setAdditionalSsdGb] = useState(0);
  const [paymentType, setPaymentType] = useState("monthly");
  const [backup, setBackup] = useState(false);

  const [priceHourlyBackup, setPriceHourlyBackup] = useState(false);
  const [monthlyBackupPrice, setMonthlyBackupPrice] = useState(false);

  const [restartServer, setRestartServer] = useState(false);
  const [bypassPayment, setBypassPayment] = useState(false);

  const [loading, setLoading] = useState(false);
  const [restarted, setRestarted] = useState(false);
  const [changing, setChanging] = useState(false);

  function handlePlanChanged() {
    setChanging(false);
  }

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("plan-changed", handlePlanChanged);

    return () => {
      socket.off("plan-changed", handlePlanChanged);
    };
  }, [socket]);

  const getServerPrice = useCallback(async () => {
    if (!server) {
      return;
    }

    setLoading(true);

    const dataPrev = await ajax(`/billing/getServerPrice`, {
      serverData: {
        image: server.image,
        cpu: server.cpu,
        ram_mb: server.ram_mb,
        ssd_gb: server.ssd_gb,
        additional_ssd_gb: server.additional_ssd_gb,
        backup: server.backup,
      },
      userID: server.user_id,
    });

    const dataNext = await ajax(`/billing/getServerPrice`, {
      serverData: {
        image: server.image,
        cpu: cpu || server.cpu,
        ram_mb: ram_mb || server.ram_mb,
        ssd_gb: ssd_gb || server.ssd_gb,
        additional_ssd_gb,
        backup: backup || false,
      },
      userID: server.user_id,
    });

    if (dataPrev.result === "error" || dataNext.result === "error") {
      return;
    }

    setMonthlyBackupPrice(dataNext.monthlyBackupPrice);
    setPriceHourlyBackup(dataNext.hourlyBackupPrice);

    if (server.payment.payment_type === "hourly") {
      setPrevPrice({
        on: dataPrev.hourlyPriceOn,
        off: dataPrev.hourlyPriceOff,
      });
      setNextPrice({
        on: dataNext.hourlyPriceOn,
        off: dataNext.hourlyPriceOff,
      });
    } else {
      setPrevPrice(dataPrev.monthlyPrice);
      setNextPrice(dataNext.monthlyPrice);
    }

    setLoading(false);
  }, [additional_ssd_gb, ajax, cpu, ram_mb, server, ssd_gb, backup]);

  useEffect(() => {
    if (server) {
      setCpu(server.cpu);
      setRamMB(server.ram_mb);
      setSsdGB(server.ssd_gb);
      setAdditionalSsdGb(server.additional_ssd_gb);
      setPaymentType(server.payment.payment_type);
      setBackup(server.backup);
    }
  }, [server]);

  useEffect(() => {
    getServerPrice();
  }, [getServerPrice]);

  function prepareDataToSendObject() {
    const dataToSend = {
      serverID: server._id,
      restartServer,
    };

    if (server.cpu !== cpu) {
      dataToSend.cpu = cpu;
    }
    if (server.ram_mb !== ram_mb) {
      dataToSend.ram_mb = ram_mb;
    }
    if (server.ssd_gb !== ssd_gb) {
      dataToSend.ssd_gb = ssd_gb;
    }

    if (server.additional_ssd_gb > 0 && additional_ssd_gb === 0) {
      dataToSend.additional_ssd_gb = "REMOVE";
    } else if (server.additional_ssd_gb !== additional_ssd_gb) {
      dataToSend.additional_ssd_gb = additional_ssd_gb;
    }

    if (server.backup !== backup) {
      dataToSend.backup = backup;
    }

    return dataToSend;
  }

  function getPrice(num, toFixed = 2) {
    if (!num || num === 0) {
      return (
        <div className={styles.priceFree}>
          <FormattedMessage id="general.free" />
        </div>
      );
    }

    num = num.toFixed(toFixed);

    return (
      <div
        className={styles.price}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage(
            {
              id: "additional-services.monthly-price",
            },
            { price: `<b>${num}</b>`, currency: "<b>$</b>" }
          ),
        }}
      ></div>
    );
  }

  async function handleChangePlanClicked() {
    if (server.qemuStatus.status === "running" && !restartServer) {
      const state = await confirm({
        title: intl.formatMessage({
          id: "additional-services.change-plan.title",
        }),
        message: intl.formatMessage({
          id: "additional-services.change-plan.content",
        }),
      });

      if (state !== "button2") {
        return;
      }
    }

    const dataToSend = prepareDataToSendObject();

    dataToSend.bypassPayment = bypassPayment;

    setLoading(true);
    const data = await ajax(`/servers/changePlan`, dataToSend);

    if (data.result === "success") {
      setChanging(true);

      if (server.payment.payment_type === "monthly") {
        const daysInMonth = getDaysInMonth(new Date());
        const daysLeft =
          differenceInDays(endOfMonth(new Date()), new Date()) + 1;
        const chargeImmediatelyPrice =
          ((nextPrice - prevPrice) / daysInMonth) * daysLeft;

        dataLayerPush({
          event: "change-plan",
          transactionId: data.jobID,
          transactionTotal: parseFloat(chargeImmediatelyPrice.toFixed(3)),
        });
      }
    } else {
      if (data.message === "snapshots-exists") {
        await alert(
          intl.formatMessage({ id: "server-settings-change-plan.title" }),
          intl.formatMessage({
            id: `server-settings-change-plan.content.snapshots-exists`,
          })
        );

        router.push(`/${lang}/my-cloud/servers/${server._id}/snapshots`);
      }

      setLoading(false);
    }
  }

  async function handleResetClicked() {
    setRestarted(true);

    await ajax("/servers/action", {
      serversIds: [server._id],
      actionType: "reset",
      isSoft: false,
    });
  }

  function renderChangePlanButton() {
    if (server.payment.payment_type === "hourly") {
      return renderChangePlanButtonHourly();
    } else {
      return renderChangePlanButtonMonthly();
    }
  }

  function renderChangePlanButtonHourly() {
    if (
      !prevPrice ||
      !nextPrice ||
      typeof prevPrice.on !== "number" ||
      typeof nextPrice.on !== "number"
    ) {
      return null;
    }

    return (
      <div className={styles.action}>
        {isAllowed("super-admin.can-bypass-payment") && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="general.bypass-payment"
              checked={bypassPayment}
              onChange={() => setBypassPayment(!bypassPayment)}
            />
          </div>
        )}

        <IconButton
          onClick={handleChangePlanClicked}
          disabled={loading || server.isWorking}
          icon="arrow"
          color="green"
        >
          <FormattedMessage id="general.save-changes" />
        </IconButton>

        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: "server-details.server-settings.hourly-price" },
              {
                oldPriceOn: prevPrice.on.toFixed(3),
                newPriceOn: nextPrice.on.toFixed(3),
                oldPriceOff: prevPrice.off.toFixed(3),
                newPriceOff: nextPrice.off.toFixed(3),
                currency: userCurrency(user),
                b: (arr) => `<b>${arr[0]}</b>`,
              }
            ),
          }}
        ></div>
      </div>
    );
  }

  function renderChangePlanButtonMonthly() {
    if (typeof prevPrice !== "number" || typeof nextPrice !== "number") {
      return null;
    }

    const daysInMonth = getDaysInMonth(new Date());
    const daysLeft = differenceInDays(endOfMonth(new Date()), new Date()) + 1;

    const chargeImmediatelyPrice =
      ((nextPrice - prevPrice) / daysInMonth) * daysLeft;

    const dataToSend = prepareDataToSendObject();

    if (Object.keys(dataToSend).length <= 2) {
      return null;
    }

    return (
      <div className={styles.action}>
        {isAllowed("super-admin.can-bypass-payment") && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="general.bypass-payment"
              checked={bypassPayment}
              onChange={() => setBypassPayment(!bypassPayment)}
            />
          </div>
        )}

        {server.qemuStatus.status === "running" && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="server-settings-change-plan.restart-server"
              checked={restartServer}
              onChange={(e) => setRestartServer(e.target.checked)}
            />
          </div>
        )}

        <IconButton
          onClick={handleChangePlanClicked}
          disabled={loading || server.isWorking}
          icon="arrow"
          color="green"
        >
          <FormattedMessage id="general.save-changes" />
        </IconButton>

        {nextPrice - prevPrice > 0 && (
          <div>
            <FormattedMessage id="server-settings-change-plan.immediately-price" />
            :{" "}
            <b>
              {userCurrency(user)}
              {chargeImmediatelyPrice.toFixed(2)}
            </b>
          </div>
        )}

        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              { id: "server-details.server-settings.monthly-price" },
              {
                oldPrice: prevPrice.toFixed(2),
                newPrice: nextPrice.toFixed(2),
                currency: userCurrency(user),
                b: (arr) => `<b>${arr[0]}</b>`,
              }
            ),
          }}
        ></div>
      </div>
    );
  }

  function renderContent() {
    if (changing) {
      return (
        <Box className={styles.changingWrapper}>
          {`${intl.formatMessage({ id: "general.changing" })}...`}
          <Spinner />
        </Box>
      );
    }

    if (
      server?.pending_changes &&
      Object.keys(server.pending_changes).length > 0
    ) {
      return (
        <Box>
          <div className={styles.pendingChangesText}>
            <FormattedMessage id="server-details.server-settings.already-pending-changes" />
          </div>
          <IconButton
            onClick={handleResetClicked}
            disabled={restarted}
            color="light-purple"
          >
            <FormattedMessage id="general.restart-server" />
          </IconButton>
        </Box>
      );
    }

    if (
      server &&
      (!server.pending_changes ||
        Object.keys(server.pending_changes).length === 0)
    ) {
      return (
        <>
          <Plan
            withTitle={false}
            image={server.image}
            cpu={cpu}
            setCpu={setCpu}
            ram_mb={ram_mb}
            setRamMB={setRamMB}
            ssd_gb={ssd_gb}
            setSsdGB={setSsdGB}
            additional_ssd_gb={additional_ssd_gb}
            setAdditionalSsdGb={setAdditionalSsdGb}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            hidePaymentType={true}
            setIsNextButtonDisabled={() => {}}
            ssd_gbMin={server.ssd_gb}
            additional_ssd_gbMin={server.additional_ssd_gb}
            withDeals={false}
          />

          {server.image !== WINDOWS10_IMAGE && (
            <div className={styles.section}>
              <div className={styles.sectionTitle}>
                <FormattedMessage id="additional-services.backups" />
              </div>
              <div className={styles.box} onClick={() => setBackup(!backup)}>
                <div className={styles.flex}>
                  <div
                    className={styles.checkboxWrapper}
                    style={{ pointerEvents: "none" }}
                  >
                    <Checkbox checked={backup} readOnly />
                  </div>
                  <div className={styles.infoWrapper}>
                    <div className={styles.infoWrapperSection}>
                      <span className={styles.infoTitle}>
                        <FormattedMessage id="additional-services.backups.title" />
                      </span>
                    </div>
                    <div className={styles.infoContent}>
                      <FormattedMessage id="additional-services.backups.content" />
                    </div>
                  </div>
                  {priceHourlyBackup || monthlyBackupPrice ? (
                    getPrice(
                      paymentType === "hourly"
                        ? priceHourlyBackup
                        : monthlyBackupPrice,
                      3
                    )
                  ) : (
                    <SimpleBadge className={styles.simpleBadgeWrapper}>
                      <FormattedMessage id="general.recommended" />
                    </SimpleBadge>
                  )}
                </div>
              </div>
            </div>
          )}

          {renderChangePlanButton()}
        </>
      );
    }

    return null;
  }

  return (
    <WithRole permission="servers.change-plan">
      <div className={styles.wrapper}>{renderContent()}</div>
    </WithRole>
  );
}
