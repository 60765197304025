import styles from "./widgets.module.scss";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { FormattedMessage } from "react-intl";
import { useLang, useServers } from "../../utils/hooks";

export default function Widgets() {
  const lang = useLang();
  const servers = useServers();

  const [serversToShow, setServersToShow] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);

  useEffect(() => {
    if (!servers) {
      return;
    }

    const serversToShow = servers
      .filter((server) => server.status === "Active" && server.running)
      .map((server) => ({
        ...server,
        prec: ((server.proxmox_cpu / server.proxmox_cpus || 0) * 100).toFixed(
          2
        ),
      }))
      .sort((a, b) => {
        if (a.prec === b.prec) {
          return 0;
        }

        return a.prec > b.prec ? 1 : -1;
      })
      .slice(0, 5);

    setServersToShow(serversToShow);

    if (!selectedServer && serversToShow[0]) {
      setSelectedServer(serversToShow[0]);
    }
  }, [servers, selectedServer]);

  function handleServerToShowChanged(e) {
    const selectedServer = serversToShow.find(
      (server) => server._id === e.target.value
    );

    setSelectedServer(selectedServer);
  }

  if (serversToShow.length === 0) {
    return null;
  }

  return (
    <div className={styles.widgetsWrapper}>
      <div className={`mb16 ${styles.usageWidget}`}>
        <Input type="select" onChange={handleServerToShowChanged}>
          {serversToShow.map((server, key) => (
            <option key={key} value={server._id}>
              {server.hostname}
            </option>
          ))}
        </Input>

        <div>
          <Link
            href="/[lang]/my-cloud/servers/[id]/overview"
            to={`/${lang}/my-cloud/servers/${selectedServer._id}/overview`}
          >
            <CircularProgressbar
              className={styles.circularProgressbar}
              value={selectedServer.prec}
              text={`${selectedServer.prec}%`}
              strokeWidth={4}
              styles={buildStyles({
                strokeLinecap: "round",
                textSize: "24px",
                pathColor: `rgba(103, 218, 93, ${selectedServer.prec})`,
                textColor: "#67DA5D",
                trailColor: "rgb(223, 225, 249)",
                backgroundColor: "#3e98c7",
              })}
            />
          </Link>
        </div>

        <div className={styles.details}>
          <div className={styles.usage}>
            <FormattedMessage id="general.avg-usage" />
          </div>
          <div className={styles.type}>CPU</div>
        </div>
      </div>

      {/* <div className={`mb16 ${styles.upgradeWidget}`}>
        <TopMission />
        <span className={styles.upgrade}>
          <FormattedMessage id="widgets.upgrade" />
        </span>
        <span className={styles.amount}>50 GB</span>
        <span className={styles.price}>
          <span>
            $2.5 / <FormattedMessage id="general.month" />
          </span>
          <LongArrowRight />
        </span>
      </div> */}
    </div>
  );
}
