import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../../icon-button";
import { useAjax, useConfirm } from "../../../utils/hooks";
import BasicTable from "../../basic-table";

function EmailLoadTemplateModal({ isOpen, onClose }) {
  const intl = useIntl();
  const ajax = useAjax();
  const confirm = useConfirm();

  const [templates, setTemplates] = useState(null);

  const [loading, setLoading] = useState(false);

  const fetchTemplates = useCallback(async () => {
    const data = await ajax(`/emails/getTemplates`);

    setTemplates(data.templates);
  }, [ajax]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  async function handleRemoveTemplateMenuClicked(template) {
    const state = await confirm({
      title: intl.formatMessage({
        id: "email-load-template-modal.delete.title",
      }),
      message: intl.formatMessage(
        { id: "email-load-template-modal.delete.content" },
        { name: template.subject }
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax(`/emails/removeTemplate`, {
      name: template.name,
    });

    await fetchTemplates();
    setLoading(false);
  }

  function handleUseTemplateClicked(template) {
    onClose(template);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="lg"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="email-load-template-modal.title" />
      </ModalHeader>
      <ModalBody>
        <BasicTable>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="email-load-template-modal.template-name" />
              </th>
              <th style={{ width: "85px" }}></th>
              <th style={{ width: "100px" }}></th>
            </tr>
          </thead>
          <tbody>
            {templates?.map((template, key) => (
              <tr key={key}>
                <td>{template.subject}</td>
                <td>
                  <IconButton
                    color="light-purple"
                    onClick={() => handleUseTemplateClicked(template)}
                  >
                    <FormattedMessage id="general.use" />
                  </IconButton>
                </td>
                <td>
                  <IconButton
                    color="light-purple"
                    onClick={() => handleRemoveTemplateMenuClicked(template)}
                  >
                    <FormattedMessage id="general.delete" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </BasicTable>
      </ModalBody>
      <ModalFooter>
        <IconButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

EmailLoadTemplateModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EmailLoadTemplateModal;
