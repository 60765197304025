import React from "react";
import { Switch, Route } from "react-router-dom";
import ServerLayout from "../../../components/server-layout";
import FirewallAlias from "./firewall/alias";
import FirewallIpSet from "./firewall/ip-set";
import FirewallSecurityGroups from "./firewall/security-groups";
import MyCloudGroups from "./groups";
import MyCloudNetworks from "./networks";
import ExternalsIps from "./networks/externals-ips";
import MyCloudProducts from "./products";
import MyCloudServers from "./servers";
import MyCloudServerRouting from "./servers/[id]";

export default function MyCloudRouting() {
  return (
    <Switch>
      <Route
        exact
        path={["/", "/:lang", "/:lang/my-cloud", "/:lang/my-cloud/servers"]}
      >
        <MyCloudServers />
      </Route>
      <Route exact path="/:lang/my-cloud/products">
        <MyCloudProducts />
      </Route>
      <Route exact path="/:lang/my-cloud/groups">
        <MyCloudGroups />
      </Route>
      <Route exact path="/:lang/my-cloud/networks">
        <MyCloudNetworks />
      </Route>
      <Route exact path="/:lang/my-cloud/networks/wan">
        <MyCloudNetworks type="wan" />
      </Route>
      <Route exact path="/:lang/my-cloud/firewall/security-groups">
        <FirewallSecurityGroups />
      </Route>
      <Route exact path="/:lang/my-cloud/networks/externals-ips">
        <ExternalsIps />
      </Route>
      <Route exact path="/:lang/my-cloud/firewall/alias">
        <FirewallAlias />
      </Route>
      <Route exact path="/:lang/my-cloud/firewall/ip-set">
        <FirewallIpSet />
      </Route>

      <Route path="/:lang/my-cloud/servers/:id">
        <ServerLayout>
          <MyCloudServerRouting />
        </ServerLayout>
      </Route>
    </Switch>
  );
}
