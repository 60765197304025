import styles from "./security.module.scss";

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Box from "../../../components/box";
import SecurityPassword from "../../../components/security/password";
import Sessions from "../../../components/security/sessions";
import ResetAllServersPassword from "../../../components/security/reset-all-servers-password";
import AutoLogout from "../../../components/security/auto-logout";
import TwoFactorAuthentication from "../../../components/security/two-factor-authentication";
import { SUPER_ADMIN, WHITELABEL } from "../../../utils/user";
import { useUser } from "../../../utils/hooks";
import {} from "../../../utils";

export default function AccountSecurity() {
  const intl = useIntl();
  const user = useUser();

  if (user.current_parent) {
    return (
      <Box>
        <FormattedMessage id="general.not-allowed-under-parent" />
      </Box>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.mainTitle}>
        <FormattedMessage id="account-security.main-title" />
      </h1>

      <hr />

      <Box
        className={styles.boxWrapper}
        title={intl.formatMessage({ id: "account-security.password" })}
      >
        <SecurityPassword />
      </Box>

      <Box
        className={styles.boxWrapper}
        title={intl.formatMessage({
          id: "account-security.two-factor-authentication",
        })}
      >
        <TwoFactorAuthentication />
      </Box>

      <Box
        className={styles.boxWrapper}
        title={intl.formatMessage({
          id: "account-security.auto-logout-settings",
        })}
      >
        <AutoLogout />
      </Box>

      {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
        <Box
          className={styles.boxWrapper}
          title={intl.formatMessage({
            id: "account-security.reset-all-servers-password",
          })}
        >
          <ResetAllServersPassword />
        </Box>
      )}

      {![SUPER_ADMIN, WHITELABEL].includes(user.role) && (
        <Box
          className={styles.boxWrapper}
          title={intl.formatMessage({ id: "account-security.sessions" })}
        >
          <Sessions />
        </Box>
      )}
    </div>
  );
}
