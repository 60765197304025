import styles from "./emails-histroy.module.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAjax, useUser } from "../../../utils/hooks";
import { FormattedMessage } from "react-intl";
import BasicTable from "../../../components/basic-table";
import Box from "../../../components/box";
import { WithRole } from "../../../components/with-role";
import { format } from "date-fns";
import Spinner from "../../../components/spinner";
import Pagination from "../../../components/pagination";
import ShowEmailContentModal from "../../../components/modals/show-email-content";

const maxRowsInPage = 100;

export default function AccountEmailsHistory() {
  const ajax = useAjax();
  const user = useUser();

  const [logs, setLogs] = useState(null);
  const [page, setPage] = useState(0);
  const [selectedLog, setSelectedLog] = useState(null);

  const [
    isShowEmailContentModalOpen,
    setIsShowEmailContentModalOpen,
  ] = useState(false);

  const getEmailsHistory = useCallback(async () => {
    const data = await ajax("/emails/getHistory");

    setLogs(data.logs);
  }, [ajax]);

  useEffect(() => {
    getEmailsHistory();
  }, [getEmailsHistory]);

  function handleShowContentClicked(log) {
    setSelectedLog(log);
    setIsShowEmailContentModalOpen(true);
  }

  function handleShowEmailContentModalClosed() {
    setIsShowEmailContentModalOpen(false);
  }

  const logsFiltered = useMemo(
    () =>
      logs
        ? logs.slice(page * maxRowsInPage, page * maxRowsInPage + maxRowsInPage)
        : [],
    [logs, page]
  );

  if (user.current_parent) {
    return (
      <Box>
        <FormattedMessage id="general.not-allowed-under-parent" />
      </Box>
    );
  }

  return (
    <WithRole permission="account.emails-history">
      <div className={styles.wrapper}>
        <h2 className={styles.mainTitle}>
          <FormattedMessage id="account-emails-history.title" />
        </h2>

        <hr />

        <Pagination
          page={page}
          setPage={setPage}
          totalRows={logsFiltered.length}
          maxRowsInPage={maxRowsInPage}
        />

        <br />

        <Box className={styles.boxWrapper}>
          <BasicTable>
            <thead>
              <tr>
                <th style={{ width: "100px" }}>#</th>
                <th>
                  <FormattedMessage id="account-emails-history.subject" />
                </th>
                <th>
                  <FormattedMessage id="account-emails-history.content" />
                </th>
                <th>
                  <FormattedMessage id="account-emails-history.sent-at" />
                </th>
                <th>
                  <FormattedMessage id="account-emails-history.read-at" />
                </th>
              </tr>
            </thead>
            <tbody>
              {!logsFiltered && (
                <tr>
                  <td colSpan={5}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {logsFiltered?.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {logsFiltered?.map((log, key) => (
                <tr key={key}>
                  <td style={{ width: "50px" }}>
                    {key + 1 + logsFiltered.length * page}
                  </td>
                  <td>{log.subject}</td>
                  <td>
                    {log.content ? (
                      <a href="#" onClick={() => handleShowContentClicked(log)}>
                        <FormattedMessage id="account-emails-history.show-content" />
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{format(new Date(log.created_at), "d/M/y HH:mm")}</td>
                  <td>
                    {log.read ? (
                      format(new Date(log.read), "d/M/y HH:mm")
                    ) : (
                      <FormattedMessage id="general.never" />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        </Box>

        <Pagination
          page={page}
          setPage={setPage}
          totalRows={logsFiltered.length}
          maxRowsInPage={maxRowsInPage}
        />

        <ShowEmailContentModal
          log={selectedLog}
          isOpen={isShowEmailContentModalOpen}
          onClose={handleShowEmailContentModalClosed}
        />
      </div>
    </WithRole>
  );
}
