import styles from "./step3.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import CryptoJS from "crypto-js";
import cardValidator from "card-validator";
import { useStripe } from "@stripe/react-stripe-js";
import { ReactComponent as CVCSvg } from "../../svgs/cvc.svg";
import { ReactComponent as SslSecurePaymentSvg } from "../../svgs/ssl-secure-payment.svg";
import { ReactComponent as CardsSvg } from "../../svgs/cards.svg";
import CustomText from "../../custom-text";
import IconButton from "../../icon-button";
import { useAjax, useInitialDataFetching, useUser } from "../../../utils/hooks";

function CompleteRegistrationModalStep3({
  location,
  image,
  cpu,
  ram_mb,
  ssd_gb,
  additional_ssd_gb,
  backup,
  privateNetworking,
  paymentType,
  hostnames,
  password,
  tag,
  group,
  ipType,
  bypassPayment,
  attachServerToClient,
  selectedFreeDays,
  publicNetworking,
  promotionCode,
  securityGroup,
  onNext,
}) {
  const intl = useIntl();
  const ajax = useAjax();
  const user = useUser();
  const fetchInitialData = useInitialDataFetching();
  const stripe = useStripe();

  const [creditCard, setCreditCard] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [cvv, setCVV] = useState("");

  const [sessionId, setSessionId] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCheckoutSession = useCallback(async () => {
    const data = await ajax(`/stripe/createStripeCheckoutSession`, {
      userID: user._id,
      redirect: window.location.href,
      params: {
        location,
        image,
        cpu,
        ram_mb,
        ssd_gb,
        additional_ssd_gb,
        backup,
        privateNetworking,
        paymentType,
        hostnames,
        password,
        tag,
        group,
        ipType,
        bypassPayment,
        attachServerToClient,
        selectedFreeDays,
        publicNetworking,
        promotionCode,
        securityGroup,
      },
    });

    if (data.result === "success") {
      setSessionId(data.sessionID);
    }
  }, [
    ajax,
    user._id,
    additional_ssd_gb,
    attachServerToClient,
    backup,
    bypassPayment,
    cpu,
    group,
    hostnames,
    image,
    ipType,
    location,
    password,
    paymentType,
    privateNetworking,
    promotionCode,
    publicNetworking,
    ram_mb,
    securityGroup,
    selectedFreeDays,
    ssd_gb,
    tag,
  ]);

  useEffect(() => {
    if (user.payment_gateway === "stripe") {
      fetchCheckoutSession();
    }
  }, [user.payment_gateway, fetchCheckoutSession]);

  async function handleCheckoutRedirectClicked() {
    await stripe.redirectToCheckout({
      sessionId,
    });
  }

  useEffect(() => {
    if (user.registerStep === -1) {
      onNext();
    }
  }, [onNext, user.registerStep]);

  async function handleCompleteClicked() {
    setError(false);

    const monthYearArr = monthYear.split("/");
    const month = monthYearArr[0];
    const year = monthYearArr[1];

    const cardValidatorNumber = cardValidator.number(creditCard);
    const cardValidatorMonth = cardValidator.expirationMonth(month);
    const cardValidatorYear = cardValidator.expirationYear(year);
    const cardValidatorCVV = cardValidator.cvv(cvv);

    if (!cardValidatorNumber.isValid && creditCard.length !== 9) {
      return setError("complete-registration-modal.credit-card-number-wrong");
    } else if (!cardValidatorMonth.isValid) {
      return setError("complete-registration-modal.credit-card-month-wrong");
    } else if (!cardValidatorYear.isValid) {
      return setError("complete-registration-modal.credit-card-year-wrong");
    } else if (!cardValidatorCVV.isValid) {
      return setError("complete-registration-modal.credit-card-cvv-wrong");
    }

    setLoading(true);
    const { token } = await ajax("/credit-card/prepareUpdateCreditCard");

    const details = CryptoJS.AES.encrypt(
      `${creditCard}_${cvv}_${month}_${year}`,
      token
    ).toString();

    const data = await ajax(`/credit-card/updateCreditCard`, {
      details,
    });
    setLoading(false);

    if (data.result === "success") {
      fetchInitialData();
    } else {
      setError("general.general-error");
    }
  }

  function handleMonthYearChanged(e) {
    const lengthBefore = monthYear.length;
    let newMonthYear = e.target.value;

    if (lengthBefore === 1 && newMonthYear.length === 2) {
      newMonthYear = `${newMonthYear}/`;
    }
    if (lengthBefore === 3 && newMonthYear.length === 2) {
      newMonthYear = newMonthYear.substr(0, 1);
    }

    setMonthYear(newMonthYear);
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.desc}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: "complete-registration-modal.credit-card-desc",
          }),
        }}
      ></div>

      {user.payment_gateway !== "stripe" && (
        <>
          <div className={styles.fields}>
            <div className={styles.box}>
              <div className={styles.text}>
                <FormattedMessage id="general.card-number" />
              </div>
              <div>
                <CustomText
                  placeholder="xxxx xxxx xxxx xxxx"
                  value={creditCard}
                  onChange={(e) => setCreditCard(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.text}>
                <FormattedMessage id="general.valid-through" />
              </div>
              <div>
                <CustomText
                  placeholder="MM/YY"
                  maxLength={5}
                  value={monthYear}
                  onChange={handleMonthYearChanged}
                />
              </div>
            </div>
          </div>

          <div className={styles.fields}>
            <div className={`${styles.box} ${styles.boxSec}`}>
              <div className={styles.text}>
                <FormattedMessage id="general.security-code" />
              </div>
              <div>
                <CustomText
                  placeholder="CVC"
                  value={cvv}
                  onChange={(e) => setCVV(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.text}>&nbsp;</div>
              <div className={styles.cvcWrapper}>
                <CVCSvg />
                <span className={styles.whereIsCVC}>
                  <FormattedMessage id="complete-registration-modal.where-is-cvc" />
                </span>
              </div>
            </div>
            {/* <div className={`${styles.box} ${styles.boxCoupon}`}>
              <div className={styles.text}>
                <span>
                  <FormattedMessage id="general.coupon-code" />
                </span>
                <HelpSvg />
              </div>
              <div>
                <CustomText
                  placeholder={intl.formatMessage({
                    id: "complete-registration-modal.insert-coupon-code",
                  })}
                />
              </div>
            </div> */}
          </div>
        </>
      )}

      {error && (
        <div className="error">
          <FormattedMessage id={error} />
        </div>
      )}

      <hr />

      <div className={styles.footer}>
        <div>
          <div className={styles.sslSecurePaymentSvg}>
            <SslSecurePaymentSvg />
          </div>
          <div className={styles.cards}>
            <CardsSvg />
          </div>
        </div>
        <div className={styles.completeButton}>
          {user.payment_gateway !== "stripe" && (
            <IconButton
              disabled={loading}
              icon="arrow"
              color="green"
              onClick={handleCompleteClicked}
            >
              <FormattedMessage id="general.complete" />
            </IconButton>
          )}
          {user.payment_gateway === "stripe" && (
            <IconButton
              disabled={loading}
              color="light-purple"
              onClick={handleCheckoutRedirectClicked}
            >
              <FormattedMessage id="general.add" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}

CompleteRegistrationModalStep3.propTypes = {
  onNext: PropTypes.func,
  location: PropTypes.string,
  image: PropTypes.string,
  cpu: PropTypes.number,
  ram_mb: PropTypes.number,
  ssd_gb: PropTypes.number,
  additional_ssd_gb: PropTypes.number,
  backup: PropTypes.bool,
  privateNetworking: PropTypes.object,
  paymentType: PropTypes.string,
  hostnames: PropTypes.array,
  password: PropTypes.string,
  tag: PropTypes.string,
  group: PropTypes.string,
  ipType: PropTypes.string,
  bypassPayment: PropTypes.bool,
  attachServerToClient: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedFreeDays: PropTypes.number,
  publicNetworking: PropTypes.bool,
  promotionCode: PropTypes.string,
  securityGroup: PropTypes.bool,
};

export default CompleteRegistrationModalStep3;
