import React, { useCallback, useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Fingerprint2 from "fingerprintjs2";
import Login from "./pages/[lang]/login";
import Register from "./pages/[lang]/register";
import ConfirmPassword from "./pages/[lang]/confirm-password";
import LoginEmailVerification from "./pages/[lang]/email-verification";
import ForgotPassword from "./pages/[lang]/forgot-password";
import LoginWithSMS from "./pages/[lang]/login-sms";
import TwoFactorAuthentication from "./pages/[lang]/two-factor-authentication";
import { useAjax, useWebSocket } from "./utils/hooks";
import { getSocket } from "./utils/globals";
import {
  setAlertModalData,
  setConfirmModalData,
  setFingerPrint,
  setPromptModalData,
  setWLDVPS,
} from "./store/settings";
import { differenceInMonths } from "date-fns";
import { updateUserDetails } from "./store/user";
import AlertModal from "./components/modals/alert";
import ConfirmModal from "./components/modals/confirm";
import PromptModal from "./components/modals/prompt";
import ChangeUserPasswordModal from "./components/modals/change-user-password";
import CloudLayout from "./components/cloud-layout";
import CreateNewServer from "./pages/[lang]/my-cloud/create-new-server";
import SupportMyTickets from "./pages/[lang]/support/my-tickets";
import SupportOpenNewTicket from "./pages/[lang]/support/open-new-ticket";
import SupportViewTicket from "./pages/[lang]/support/my-tickets/ticket_number";
import BillingInvoices from "./pages/[lang]/billing/invoices";
import BillingManageCreditCard from "./pages/[lang]/billing/manage-credit-card";
import BandwidthForm from "./pages/[lang]/billing/bandwidth-form";
import AccountDetails from "./pages/[lang]/account/account-details";
import AccountPermissions from "./pages/[lang]/account/permissions";
import AccountSecurity from "./pages/[lang]/account/security";
import AccountOnlineUser from "./pages/[lang]/account/online-users";
import AccountEmailsHistory from "./pages/[lang]/account/emails-histroy";
import AccountLogs from "./pages/[lang]/account/logs";
import AccountPermissionsManageRoles from "./pages/[lang]/account/permissions/manage-roles";
import AccountPermissionsCreateNewRole from "./pages/[lang]/account/permissions/create-new-role";
import AccountPermissionsEditRole from "./pages/[lang]/account/permissions/create-new-role/[id]";
import MyCloudRouting from "./pages/[lang]/my-cloud";
import ResetPasswordToken from "./pages/[lang]/reset-password";
import Departments from "./pages/[lang]/support/departments";
import SupportGmailTickets from "./pages/[lang]/support/gmail-blacklist";
import BillingCreateNewInvoice from "./pages/[lang]/billing/create-new-invoice";
import BillingLeadsReport from "./pages/[lang]/billing/leads-report";
import BillingVatReport from "./pages/[lang]/billing/vat-report";
import BillingCoupons from "./pages/[lang]/billing/coupons";
import BillingAboutToExpireCC from "./pages/[lang]/billing/about-to-expire-cc";
import BillingMonthlyCron from "./pages/[lang]/billing/monthly-cron";
import BillingHourlyCron from "./pages/[lang]/billing/hourly-cron";
import BillingChangePaymentForm from "./pages/[lang]/billing/change-payment-form";
import BillingUnpaidCustomers from "./pages/[lang]/billing/unpaid-customers";
import Calculator from "./pages/[lang]/billing/calculator";
import ManagementProducts from "./pages/[lang]/management/products";
import ManagementProductsAndPrices from "./pages/[lang]/management/servers-and-prices";
import ManagementISO from "./pages/[lang]/management/iso";
import ManagementJobsManager from "./pages/[lang]/management/jobs-manager";
import ManagementProxmoxSettings from "./pages/[lang]/management/proxmox-settings";
import ManagementNodesStatus from "./pages/[lang]/management/nodes-status";
import ManagementBlacklist from "./pages/[lang]/management/blacklist";
import ManagementWhitelabel from "./pages/[lang]/management/whitelabel";
import ManagementEmails from "./pages/[lang]/management/emails";
import ClientsList from "./pages/[lang]/clients/clients-list";
import ClientsOnlineUser from "./pages/[lang]/clients/online-users";
import AccountBiReports from "./pages/[lang]/clients/bi-reports";
import ClientsListRouting from "./pages/[lang]/clients/clients-list/[id]";
import LoadingPage from "./components/loading-page";

let updateLastSeenTimer;

function App() {
  const router = useHistory();
  const dispatch = useDispatch();
  const ajax = useAjax();
  const { connect } = useWebSocket();

  const { alertModalData, confirmModalData, promptModalData } = useSelector(
    (state) => ({
      alertModalData: state.settings.alertModalData,
      confirmModalData: state.settings.confirmModalData,
      promptModalData: state.settings.promptModalData,
    })
  );

  const [fetchedInitialData, setFetchedInitialData] = useState(false);
  const [
    isChangeUserPasswordModalOpen,
    setIsChangeUserPasswordModalOpen,
  ] = useState(false);

  // function handleRouteChange(url) {
  //   const socket = getSocket();

  //   if (socket) {
  //     socket.emit("update-real-time", { url });
  //   }
  // }

  useEffect(() => {
    function handleMouseMove() {
      if (updateLastSeenTimer) {
        return;
      }

      updateLastSeenTimer = setTimeout(() => {
        const socket = getSocket();

        if (socket) {
          socket.emit("update-real-time", {});
        }

        updateLastSeenTimer = null;
      }, 1000);
    }

    window.document.addEventListener("mousemove", handleMouseMove);

    // router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      window.document.removeEventListener("mousemove", handleMouseMove);

      // router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        Fingerprint2.get((components) => {
          dispatch(setFingerPrint(components));
        });
      });
    } else {
      setTimeout(function () {
        Fingerprint2.get((components) => {
          dispatch(setFingerPrint(components));
        });
      }, 500);
    }
  }, [dispatch]);

  const fetchInitialData = useCallback(async () => {
    if (!router) {
      return;
    }

    try {
      const data = await ajax(`/initialData`);

      if (data.result === "success") {
        if (
          differenceInMonths(
            new Date(),
            new Date(data.passwordLastTimeChanged)
          ) >= 3
        ) {
          setIsChangeUserPasswordModalOpen(true);
        }

        dispatch(updateUserDetails(data));

        connect();

        if (router.location.pathname === "/en/login") {
          router.push("/en/my-cloud/servers");
        } else {
          router.push(router.location.pathname);
        }

        return setFetchedInitialData(true);
      } else {
        //
      }
    } catch (err) {
      //
    }

    if (
      router.location.pathname !== "/en/reset-password" &&
      router.location.pathname !== "/en/confirm-password" &&
      router.location.pathname !== "/en/email-verification" &&
      router.location.pathname !== "/en/forgot-password" &&
      router.location.pathname !== "/en/login-sms" &&
      router.location.pathname !== "/en/login" &&
      router.location.pathname !== "/en/register" &&
      router.location.pathname !== "/en/two-factor-authentication"
    ) {
      router.push("/en/login");
    }

    setFetchedInitialData(true);
  }, [dispatch, ajax, connect, router]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    dispatch(setWLDVPS(process.env.REACT_APP_WHITELABEL || null));
  }, [dispatch]);

  function handleAlertClosed(closedVia) {
    dispatch(
      setAlertModalData({
        button1: alertModalData.button1,
        button2: alertModalData.button2,
        isOpen: false,
      })
    );
    alertModalData.resolve(closedVia);
  }

  function handleConfirmClosed(closedVia) {
    dispatch(
      setConfirmModalData({
        button1: confirmModalData.button1,
        button2: confirmModalData.button2,
        isOpen: false,
      })
    );
    confirmModalData.resolve(closedVia);
  }

  function handlePromptClosed(closedVia) {
    dispatch(
      setPromptModalData({
        button1: promptModalData.button1,
        button2: promptModalData.button2,
        isOpen: false,
      })
    );
    promptModalData.resolve(closedVia);
  }

  function handleChangeUserPasswordModalClosed() {
    setIsChangeUserPasswordModalOpen(false);
  }

  if (!fetchedInitialData) {
    return <LoadingPage />;
  }

  return (
    <div>
      <Switch>
        <Route exact path="/:lang/reset-password">
          <ResetPasswordToken />
        </Route>
        <Route exact path="/:lang/confirm-password">
          <ConfirmPassword />
        </Route>
        <Route exact path="/:lang/email-verification">
          <LoginEmailVerification />
        </Route>
        <Route exact path="/:lang/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/:lang/login-sms">
          <LoginWithSMS />
        </Route>
        <Route exact path="/:lang/two-factor-authentication">
          <TwoFactorAuthentication />
        </Route>
        <Route exact path="/:lang/register">
          <Register />
        </Route>
        <Route exact path="/:lang/login">
          <Login />
        </Route>

        <Route exact path="/:lang/my-cloud/create-new-server">
          <CreateNewServer />
        </Route>

        <Route exact path="/:lang/support/my-tickets">
          <CloudLayout>
            <SupportMyTickets />
          </CloudLayout>
        </Route>

        <Route exact path="/:lang/clients/clients-list">
          <CloudLayout>
            <ClientsList />
          </CloudLayout>
        </Route>
        <Route path="/:lang/clients/clients-list/:id">
          <CloudLayout>
            <ClientsListRouting />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/clients/online-users">
          <CloudLayout>
            <ClientsOnlineUser />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/clients/bi-reports">
          <CloudLayout>
            <AccountBiReports />
          </CloudLayout>
        </Route>

        <Route exact path="/:lang/support/my-tickets/:ticket_number">
          <CloudLayout>
            <SupportViewTicket />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/support/open-new-ticket">
          <CloudLayout>
            <SupportOpenNewTicket />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/support/departments">
          <CloudLayout>
            <Departments />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/support/gmail-blacklist">
          <CloudLayout>
            <SupportGmailTickets />
          </CloudLayout>
        </Route>

        <Route exact path="/:lang/billing/invoices">
          <CloudLayout>
            <BillingInvoices />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/manage-credit-card">
          <CloudLayout>
            <BillingManageCreditCard />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/bandwidth-form">
          <CloudLayout>
            <BandwidthForm />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/invoices">
          <CloudLayout>
            <BillingInvoices />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/create-new-invoice">
          <CloudLayout>
            <BillingCreateNewInvoice />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/leads-report">
          <CloudLayout>
            <BillingLeadsReport />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/vat-report">
          <CloudLayout>
            <BillingVatReport />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/coupons">
          <CloudLayout>
            <BillingCoupons />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/about-to-expire-cc">
          <CloudLayout>
            <BillingAboutToExpireCC />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/monthly-cron">
          <CloudLayout>
            <BillingMonthlyCron />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/hourly-cron">
          <CloudLayout>
            <BillingHourlyCron />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/change-payment-form">
          <CloudLayout>
            <BillingChangePaymentForm />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/unpaid-customers">
          <CloudLayout>
            <BillingUnpaidCustomers />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/calculator">
          <CloudLayout>
            <Calculator />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/invoices">
          <CloudLayout>
            <BillingInvoices />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/billing/invoices">
          <CloudLayout>
            <BillingInvoices />
          </CloudLayout>
        </Route>

        <Route
          exact
          path={["/:lang/account", "/:lang/account/account-details"]}
        >
          <CloudLayout>
            <AccountDetails />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/permissions">
          <CloudLayout>
            <AccountPermissions />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/permissions/manage-roles">
          <CloudLayout>
            <AccountPermissionsManageRoles />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/permissions/create-new-role">
          <CloudLayout>
            <AccountPermissionsCreateNewRole />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/permissions/create-new-role/:id">
          <CloudLayout>
            <AccountPermissionsEditRole />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/security">
          <CloudLayout>
            <AccountSecurity />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/online-users">
          <CloudLayout>
            <AccountOnlineUser />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/emails-histroy">
          <CloudLayout>
            <AccountEmailsHistory />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/account/logs">
          <CloudLayout>
            <AccountLogs />
          </CloudLayout>
        </Route>

        <Route exact path="/:lang/management/products">
          <CloudLayout>
            <ManagementProducts />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/servers-and-prices">
          <CloudLayout>
            <ManagementProductsAndPrices />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/iso">
          <CloudLayout>
            <ManagementISO />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/jobs-manager">
          <CloudLayout>
            <ManagementJobsManager />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/proxmox-settings">
          <CloudLayout>
            <ManagementProxmoxSettings />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/nodes-status">
          <CloudLayout>
            <ManagementNodesStatus />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/blacklist">
          <CloudLayout>
            <ManagementBlacklist />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/whitelabel">
          <CloudLayout>
            <ManagementWhitelabel />
          </CloudLayout>
        </Route>
        <Route exact path="/:lang/management/emails">
          <CloudLayout>
            <ManagementEmails />
          </CloudLayout>
        </Route>

        <Route path={["/", "/:lang", "/:lang/my-cloud"]}>
          <CloudLayout>
            <MyCloudRouting />
          </CloudLayout>
        </Route>
      </Switch>

      {alertModalData && (
        <AlertModal
          isOpen={alertModalData.isOpen}
          onClose={handleAlertClosed}
          title={alertModalData.title}
          message={alertModalData.message}
          button={alertModalData.button}
        />
      )}

      {confirmModalData && (
        <ConfirmModal
          isOpen={confirmModalData.isOpen}
          onClose={handleConfirmClosed}
          title={confirmModalData.title}
          message={confirmModalData.message}
          button1={confirmModalData.button1}
          button2={confirmModalData.button2}
          beforeClose={confirmModalData.beforeClose}
        />
      )}

      {promptModalData && (
        <PromptModal
          isOpen={promptModalData.isOpen}
          onClose={handlePromptClosed}
          title={promptModalData.title}
          message={promptModalData.message}
          defaultText={promptModalData.defaultText}
          button1={promptModalData.button1}
          button2={promptModalData.button2}
          acceptOnlyValue={promptModalData.acceptOnlyValue}
          textType={promptModalData.textType}
          beforeClose={promptModalData.beforeClose}
          placeholder={promptModalData.placeholder}
        />
      )}

      <ChangeUserPasswordModal
        isOpen={isChangeUserPasswordModalOpen}
        onClose={handleChangeUserPasswordModalClosed}
      />
    </div>
  );
}

export default App;
