import styles from "./index.module.scss";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import { stripTags } from "../../utils";

function TinyEditor({ onChange, onInit, initialValue = "", height = 500 }) {
  const [width, setWidth] = useState(null);

  const wrapperRef = useRef();

  useEffect(() => {
    setWidth(
      wrapperRef.current.parentElement.getBoundingClientRect().width - 60
    );
  }, []);

  function handleEditorInput() {
    const content = stripTags(window.tinyMCE.activeEditor.getContent());

    if (content.length === 1) {
      if (content[0] >= "א" && content[0] <= "ת") {
        if (
          window.tinyMCE.activeEditor.dom.doc.querySelector("#tinymce p")
            .dir !== "rtl"
        ) {
          window.tinyMCE.activeEditor.execCommand("mceDirectionRTL");
        }
      } else {
        console.log("mceDirectionLTR");
        window.tinyMCE.activeEditor.execCommand("mceDirectionLTR");
      }
    }
  }

  function renderEditor() {
    if (!width) {
      return null;
    }

    return (
      <Editor
        apiKey="rlhgs2sgu0a403agh5bp7jms4enr93vyy8bgp2o6xxourkaz"
        initialValue={initialValue}
        init={{
          height,
          width,
          menubar: false,
          plugins: [
            "advlist autolink lists link image imagetools charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount directionality",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ltr rtl | bullist numlist outdent indent | removeformat | image | codesample | help",
          setup: function (editor) {
            editor.on("input", handleEditorInput);
          },
        }}
        onEditorChange={onChange}
        onInit={onInit}
      />
    );
  }

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {renderEditor()}
    </div>
  );
}

TinyEditor.propTypes = {
  onChange: PropTypes.func,
  onInit: PropTypes.func,
  initialValue: PropTypes.string,
  height: PropTypes.number,
  directionality: PropTypes.string,
};

export default TinyEditor;
