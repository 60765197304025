import styles from "./index.module.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { format, fromUnixTime } from "date-fns";
import IconButton from "../../icon-button";
import Checkbox from "../../checkbox";
import { useAjax, useServer } from "../../../utils/hooks";

function RestoreFromBackupModal({ backup, isOpen, onClose }) {
  const ajax = useAjax();
  const server = useServer();

  const [turnOnAfter, setTurnOnAfter] = useState(false);

  async function handleRestoreClicked() {
    await ajax("/backups/restore", {
      serverID: server._id,
      name: backup.volid,
      turnOnAfter,
    });

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="restore-from-backup-modal.title" />
      </ModalHeader>
      {backup && (
        <ModalBody>
          <div className={styles.title}>
            {format(fromUnixTime(backup.ctime), "d/M/y HH:mm")}
          </div>
          <div className={styles.description}>
            <FormattedMessage id="restore-from-backup-modal.description" />
          </div>
          <div className={`${styles.checkbox} ${styles.first}`}>
            <Checkbox
              label="rollback-snapshot-modal.turn-on-after"
              checked={turnOnAfter}
              onChange={() => setTurnOnAfter(!turnOnAfter)}
            />
          </div>
        </ModalBody>
      )}
      <ModalFooter>
        <IconButton color="purple" onClick={handleRestoreClicked}>
          <FormattedMessage id="general.restore" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

RestoreFromBackupModal.propTypes = {
  backup: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RestoreFromBackupModal;
