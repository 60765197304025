import styles from "./change-os.module.scss";

import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Box from "../../../../../../components/box";
import ServerImage from "../../../../../../components/create-new-server/image";
import IconButton from "../../../../../../components/icon-button";
import {
  useAjax,
  useServer,
  useConfirm,
  useLang,
  useUser,
  useRoles,
  useAlert,
  useWLDVPS,
  usePrevious,
} from "../../../../../../utils/hooks";
import { userCurrency } from "../../../../../../utils/user";
import { getDaysInMonth, differenceInDays, endOfMonth } from "date-fns";
import CpanelLicense from "../../../../../../components/create-new-server/cpanel-license";
import { dataLayerPush, noop } from "../../../../../../utils";
import Checkbox from "../../../../../../components/checkbox";
import { WithRole } from "../../../../../../components/with-role";

export default function ServerSettingsChangeOS() {
  const intl = useIntl();
  const lang = useLang();
  const ajax = useAjax();
  const confirm = useConfirm();
  const alert = useAlert();
  const router = useHistory();
  const user = useUser();
  const wldvps = useWLDVPS();
  const server = useServer();
  const { isAllowed } = useRoles();

  const [bypassPayment, setBypassPayment] = useState(false);

  const [image, setImage] = useState(false);
  const prevImage = usePrevious(image);

  const [prevPrice, setPrevPrice] = useState(false);
  const [nextPrice, setNextPrice] = useState(false);

  const [selectedAddons, setSelectedAddons] = useState({});

  const [loading, setLoading] = useState(false);

  const fetchServerPrice = useCallback(async () => {
    if (!image || !server) {
      return;
    }

    const dataPrev = await ajax(`/billing/getServerPrice`, {
      serverData: {
        image: server.image,
        cpu: server.cpu,
        ram_mb: server.ram_mb,
        ssd_gb: server.ssd_gb,
        additional_ssd_gb: server.additional_ssd_gb,
        backup: server.backup,
      },
      userID: server.user_id,
      addons: server.addons
        .filter((addon) => addon.whitelabel === wldvps)
        .map((addon) => addon.addon_id),
    });

    const dataNext = await ajax(`/billing/getServerPrice`, {
      serverData: {
        image: image.value,
        cpu: server.cpu,
        ram_mb: server.ram_mb,
        ssd_gb: server.ssd_gb,
        additional_ssd_gb: server.additional_ssd_gb,
        backup: server.backup,
      },
      userID: server.user_id,
      addons: Object.keys(selectedAddons),
    });

    if (dataPrev.result === "error" || dataNext.result === "error") {
      return;
    }

    if (server.payment.payment_type === "hourly") {
      setPrevPrice({
        on: dataPrev.hourlyPriceOn,
        off: dataPrev.hourlyPriceOff,
      });
      setNextPrice({
        on: dataNext.hourlyPriceOn,
        off: dataNext.hourlyPriceOff,
      });
    } else {
      setPrevPrice(dataPrev.monthlyPrice + dataPrev.addonsPrice);
      setNextPrice(dataNext.monthlyPrice + dataNext.addonsPrice);
    }
  }, [ajax, wldvps, image, selectedAddons, server]);

  useEffect(() => {
    fetchServerPrice();
  }, [fetchServerPrice, image]);

  async function handleChangeOsClicked() {
    const state = await confirm({
      title: intl.formatMessage({
        id: "server-settings-change-plan.confirm-title",
      }),
      message: intl.formatMessage({
        id: "server-settings-change-plan.confirm-content",
      }),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    const data = await ajax(`/servers/changeOS`, {
      userID: server.user_id,
      serverID: server._id,
      os: image.value,
      bypassPayment,
      addons: Object.keys(selectedAddons).filter((k) => selectedAddons[k]),
    });
    setLoading(false);

    if (data.result === "success") {
      if (server?.payment.payment_type === "monthly") {
        const daysInMonth = getDaysInMonth(new Date());
        const daysLeft =
          differenceInDays(endOfMonth(new Date()), new Date()) + 1;
        const chargeImmediatelyPrice =
          ((nextPrice - prevPrice) / daysInMonth) * daysLeft;

        dataLayerPush({
          event: "change-os",
          transactionId: data.jobID,
          transactionTotal: parseFloat(chargeImmediatelyPrice.toFixed(3)),
        });
      }

      router.push(`/${lang}/my-cloud/servers`);
    } else {
      await alert(
        intl.formatMessage({ id: "server-settings-change-os.action.title" }),
        data.message
      );
    }
  }

  function renderAction() {
    if (
      !server ||
      !image ||
      (server.payment.payment_type === "monthly" &&
        (typeof prevPrice !== "number" || typeof nextPrice !== "number")) ||
      (server.payment.payment_type === "hourly" &&
        (!prevPrice ||
          !nextPrice ||
          typeof prevPrice.on !== "number" ||
          typeof nextPrice.on !== "number")) ||
      image.value === server.image
    ) {
      return null;
    }

    if (
      image.os.toLowerCase().includes("windows") &&
      (server.ram_mb <= 1024 || server.ssd_gb <= 20)
    ) {
      return (
        <Box>
          <div className="error">
            <FormattedMessage id="server-settings-change-plan.windows-error" />
          </div>
        </Box>
      );
    }

    return server.payment.payment_type === "hourly"
      ? renderConfirmButtonHourly()
      : renderConfirmButtonMonthly();
  }

  function renderConfirmButtonHourly() {
    return (
      <Box>
        <div className={styles.action}>
          <div
            className={styles.warning}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "server-settings-change-os.warning",
              }),
            }}
          ></div>

          {isAllowed("super-admin.can-bypass-payment") && (
            <div className={styles.checkboxWrapper}>
              <Checkbox
                label="general.bypass-payment"
                checked={bypassPayment}
                onChange={() => setBypassPayment(!bypassPayment)}
              />
            </div>
          )}

          <div>
            <IconButton
              disabled={loading || server.isWorking}
              icon="arrow"
              color="green"
              onClick={handleChangeOsClicked}
            >
              <FormattedMessage id="general.save-changes" />
            </IconButton>
          </div>

          {prevPrice.on !== nextPrice.on && (
            <div
              className={styles.price}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: "server-details.server-settings.hourly-price" },
                  {
                    oldPriceOn: prevPrice.on.toFixed(3),
                    newPriceOn: nextPrice.on.toFixed(3),
                    oldPriceOff: prevPrice.off.toFixed(3),
                    newPriceOff: nextPrice.off.toFixed(3),
                    currency: userCurrency(user),
                    b: (arr) => `<b>${arr[0]}</b>`,
                  }
                ),
              }}
            ></div>
          )}
        </div>
      </Box>
    );
  }

  function renderConfirmButtonMonthly() {
    const daysInMonth = getDaysInMonth(new Date());
    const daysLeft = differenceInDays(endOfMonth(new Date()), new Date()) + 1;

    const chargeImmediatelyPrice =
      ((nextPrice - prevPrice) / daysInMonth) * daysLeft;

    return (
      <div className={styles.action}>
        {isAllowed("super-admin.can-bypass-payment") && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="general.bypass-payment"
              checked={bypassPayment}
              onChange={() => setBypassPayment(!bypassPayment)}
            />
          </div>
        )}

        <div>
          <IconButton
            disabled={loading || server.isWorking}
            icon="arrow"
            color="green"
            onClick={handleChangeOsClicked}
          >
            <FormattedMessage id="general.save-changes" />
          </IconButton>
        </div>

        {nextPrice - prevPrice > 0 && (
          <div className={styles.price}>
            <FormattedMessage id="server-settings-change-plan.immediately-price" />
            :{" "}
            <b>
              {userCurrency(user)}
              {chargeImmediatelyPrice.toFixed(2)}
            </b>
            {prevPrice !== nextPrice && (
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    {
                      id: "server-settings-change-os.monthly-price",
                    },
                    {
                      oldPrice: prevPrice.toFixed(2),
                      newPrice: nextPrice.toFixed(2),
                      currency: userCurrency(user),
                      b: (arr) => `<b>${arr[0]}</b>`,
                    }
                  ),
                }}
                id="server-settings-change-os.monthly-price"
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <WithRole permission="servers.change-os">
      <div className={styles.wrapper}>
        <Box className={styles.box}>
          <div className={styles.title}>
            <FormattedMessage id="server-settings-change-os.title" />
          </div>
          <ServerImage
            image={image}
            prevImage={prevImage}
            setImage={setImage}
            selectedAddons={selectedAddons}
            setSelectedAddons={setSelectedAddons}
            setCpu={noop}
            setRamMB={noop}
            setSsdGB={noop}
            setAdditionalSsdGb={noop}
            setBackup={noop}
            showCustomIso={false}
            currentImage={server?.image}
          />
        </Box>

        {image && image.value.toLowerCase().includes("cpanel") && (
          <CpanelLicense
            selectedAddons={selectedAddons}
            setSelectedAddons={setSelectedAddons}
          />
        )}

        {renderAction()}
      </div>
    </WithRole>
  );
}
