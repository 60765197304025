import styles from "./index.module.scss";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { format, getDate, startOfDay } from "date-fns";
import IconButton from "../../icon-button";
import { useAjax, useLang, useWLDVPS } from "../../../utils/hooks";
import BasicTable from "../../basic-table";
import Spinner from "../../spinner";
import Checkbox from "../../checkbox";
import { getPrice } from "../../../utils/billing";
import { readableBytes } from "../../../utils/servers";
import Radio from "../../radio";

function AddServersToInvoiceModal({ isOpen, onClose, user }) {
  const ajax = useAjax();
  const lang = useLang();
  const wldvps = useWLDVPS();
  const intl = useIntl();

  const [servers, setServers] = useState(null);
  const [serversTypeToShow, setServersTypeToShow] = useState("monthly");
  const [filteredServers, setFilteredServers] = useState(null);
  const [selectedServers, setSelectedServers] = useState({});
  const [selectedIncludeBandwith, setSelectedIncludeBandwith] = useState({});
  const [selectedIsFirstMonth, setSelectedIsFirstMonth] = useState({});

  const getServers = useCallback(async () => {
    const data = await ajax(`/admin/getServers`, {
      userID: user.value,
      withBandwidth: true,
      withTracking: true,
      withoutTerminated: true,
    });

    if (data.result === "success") {
      setServers(data.servers);
    }
  }, [ajax, user]);

  useEffect(() => {
    if (isOpen) {
      setServers(null);
      setFilteredServers(null);
      setSelectedServers({});
      setSelectedIncludeBandwith({});
      setSelectedIsFirstMonth({});

      getServers();
    }
  }, [isOpen, getServers]);

  useEffect(() => {
    if (servers) {
      const filteredServers = servers.filter(
        (server) =>
          (serversTypeToShow === "monthly" &&
            server.payment.payment_type === "monthly" &&
            server.status === "Active") ||
          (serversTypeToShow === "hourly" &&
            server.payment.payment_type === "hourly") ||
          serversTypeToShow === "both"
      );

      setFilteredServers(filteredServers);

      const selectedServers = {};
      const selectedIsFirstMonth = {};
      const currentDay = getDate(new Date());

      filteredServers.forEach((server) => {
        const serverPayment = wldvps
          ? server.whitelabel_payment
          : server.payment;

        if (
          server.payment.payment_type === "monthly" &&
          serverPayment.is_first_month
        ) {
          selectedIsFirstMonth[server._id] = server;
          selectedServers[server._id] = server;
        }

        if (
          (server.payment.payment_type === "monthly" &&
            serverPayment.payDay === currentDay &&
            startOfDay(new Date()) >=
              startOfDay(new Date(serverPayment.next_pay_day))) ||
          (server.payment.payment_type === "hourly" &&
            startOfDay(new Date(serverPayment.next_pay_day)) <=
              startOfDay(
                new Date(server.tracking[server.tracking.length - 1].created_at)
              ))
        ) {
          selectedServers[server._id] = server;
        }
      });

      setSelectedIsFirstMonth(selectedIsFirstMonth);
      setSelectedServers(selectedServers);
    }
  }, [servers, wldvps, serversTypeToShow]);

  function handleServerCheckboxChanged(server) {
    if (server) {
      if (selectedServers[server._id]) {
        delete selectedServers[server._id];
      } else {
        selectedServers[server._id] = server;
      }

      return setSelectedServers({ ...selectedServers });
    }

    if (Object.keys(selectedServers).length === filteredServers.length) {
      setSelectedServers({});
    } else {
      filteredServers.forEach((server) => {
        selectedServers[server._id] = server;
      });

      setSelectedServers({ ...selectedServers });
    }
  }

  function handleIncludeBandwidthCheckboxChanged(server) {
    if (server) {
      if (selectedIncludeBandwith[server._id]) {
        delete selectedIncludeBandwith[server._id];
      } else {
        selectedIncludeBandwith[server._id] = server;
      }

      return setSelectedIncludeBandwith({ ...selectedIncludeBandwith });
    }

    if (
      Object.keys(selectedIncludeBandwith).length === filteredServers.length
    ) {
      setSelectedIncludeBandwith({});
    } else {
      filteredServers.forEach((server) => {
        selectedIncludeBandwith[server._id] = server;
      });

      setSelectedIncludeBandwith({ ...selectedIncludeBandwith });
    }
  }

  function handleIsFirstMonthCheckboxChanged(server) {
    if (server) {
      if (selectedIsFirstMonth[server._id]) {
        delete selectedIsFirstMonth[server._id];
      } else {
        selectedIsFirstMonth[server._id] = server;
      }

      return setSelectedIsFirstMonth({ ...selectedIsFirstMonth });
    }

    if (Object.keys(selectedIsFirstMonth).length === filteredServers.length) {
      setSelectedIsFirstMonth({});
    } else {
      filteredServers.forEach((server) => {
        selectedIsFirstMonth[server._id] = server;
      });

      setSelectedIsFirstMonth({ ...selectedIsFirstMonth });
    }
  }

  function handleAddClicked() {
    onClose({
      selectedServers,
      selectedIsFirstMonth,
      selectedIncludeBandwith,
    });
  }

  function handleSortServers(a, b) {
    if (a.status === b.status) {
      return 0;
    }

    return a.status > b.status ? 1 : -1;
  }

  function renderRadios() {
    const selectedMonthly = Object.keys(selectedServers).reduce(
      (total, key) =>
        total +
        (selectedServers[key].payment.payment_type === "monthly" ? 1 : 0),
      0
    );

    const selectedHourly = Object.keys(selectedServers).reduce(
      (total, key) =>
        total +
        (selectedServers[key].payment.payment_type === "hourly" ? 1 : 0),
      0
    );

    return (
      <div className={styles.radios}>
        <div>
          <Radio
            label={
              <div>
                <FormattedMessage id="general.monthly" />
                {selectedMonthly ? ` (${selectedMonthly})` : ""}
              </div>
            }
            checked={serversTypeToShow === "monthly"}
            onChange={() => setServersTypeToShow("monthly")}
          />
        </div>
        <div>
          <Radio
            label={
              <div>
                <FormattedMessage id="general.hourly" />
                {selectedHourly ? ` (${selectedHourly})` : ""}
              </div>
            }
            checked={serversTypeToShow === "hourly"}
            onChange={() => setServersTypeToShow("hourly")}
          />
        </div>
        <div>
          <Radio
            label={
              <div>
                <FormattedMessage id="general.both" />
                {selectedMonthly || selectedHourly
                  ? ` (${selectedMonthly + selectedHourly})`
                  : ""}
              </div>
            }
            checked={serversTypeToShow === "both"}
            onChange={() => setServersTypeToShow("both")}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <FormattedMessage id="add-servers-to-invoice-modal.title" />
      </ModalHeader>
      <ModalBody>
        {renderRadios()}
        <div>
          <BasicTable layout="auto">
            <thead>
              <tr>
                <th style={{ width: "50px" }}>
                  <Checkbox
                    checked={
                      Object.keys(selectedServers).length ===
                      filteredServers?.length
                    }
                    onChange={() => handleServerCheckboxChanged()}
                  />
                </th>
                <th>
                  <FormattedMessage id="add-servers-to-invoice-modal.hostname" />
                </th>
                <th>
                  <FormattedMessage id="add-servers-to-invoice-modal.status" />
                </th>
                <th style={{ width: "250px" }}>
                  <FormattedMessage id="add-servers-to-invoice-modal.details" />
                </th>
                <th style={{ width: "220px" }}>
                  <FormattedMessage id="add-servers-to-invoice-modal.payment" />
                </th>
                <th>
                  <Checkbox
                    label="add-servers-to-invoice-modal.traffic"
                    checked={
                      Object.keys(selectedIncludeBandwith).length ===
                      filteredServers?.length
                    }
                    onChange={() => handleIncludeBandwidthCheckboxChanged()}
                  />
                </th>
                <th>
                  <Checkbox
                    checked={
                      Object.keys(selectedIsFirstMonth).length ===
                      filteredServers?.length
                    }
                    onChange={() => handleIsFirstMonthCheckboxChanged()}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {!filteredServers && (
                <tr>
                  <td colSpan={14}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {filteredServers?.length === 0 && (
                <tr>
                  <td colSpan={14}>
                    <FormattedMessage id="general.no-rows" />
                  </td>
                </tr>
              )}

              {filteredServers?.sort(handleSortServers).map((server, key) => {
                const serverPayment = wldvps
                  ? server.whitelabel_payment
                  : server.payment;

                return (
                  <tr key={key}>
                    <td>
                      <Checkbox
                        checked={!!selectedServers[server._id]}
                        onChange={() => handleServerCheckboxChanged(server)}
                      />
                    </td>
                    <td>
                      <a
                        href={`/${lang}/my-cloud/servers/${server._id}/overview`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {server.hostname}
                      </a>
                    </td>
                    <td>{server.status}</td>
                    <td style={{ padding: "10px 0" }}>
                      <div>image: {server.image}</div>
                      <div>cpu: {server.cpu}</div>
                      <div>ram: {server.ram_mb}MB</div>
                      <div>
                        disk: {server.ssd_gb}GB{" "}
                        {server.additional_ssd_gb > 0 &&
                          `+${server.additional_ssd_gb}GB`}
                      </div>
                    </td>
                    <td style={{ padding: "10px 0" }}>
                      <div>
                        {getPrice(server, false, wldvps)} -{" "}
                        {server.payment.payment_type}
                        {server.fixedPrice && " [Fixed price]"}
                      </div>
                      {server.payment.payment_type === "monthly" && (
                        <>
                          <div>
                            pay every: {serverPayment.payEvery}{" "}
                            <FormattedMessage id="general.months" />
                          </div>
                          <div>
                            pay day: {serverPayment.payDay}
                            th
                          </div>
                        </>
                      )}
                      <div>
                        {server.payment.payment_type === "monthly"
                          ? "next pay day:"
                          : "last pay day:"}{" "}
                        {format(
                          startOfDay(new Date(serverPayment.next_pay_day)),
                          server.payment.payment_type === "hourly"
                            ? "d/M/y HH:mm"
                            : "d/M/y"
                        )}
                      </div>
                    </td>
                    <td>
                      <Checkbox
                        checked={!!selectedIncludeBandwith[server._id]}
                        onChange={() =>
                          handleIncludeBandwidthCheckboxChanged(server)
                        }
                        label={
                          <span>
                            {readableBytes(server.unpaidBandwidth)}{" "}
                            {serverPayment.free_bandwidth
                              ? intl.formatMessage(
                                  {
                                    id:
                                      serverPayment.free_bandwidth_up_to === 0
                                        ? "add-servers-to-invoice-modal.free-traffic"
                                        : "add-servers-to-invoice-modal.free-traffic-up-to",
                                  },
                                  { number: serverPayment.free_bandwidth_up_to }
                                )
                              : ""}
                          </span>
                        }
                      />
                    </td>
                    <td>
                      {server.payment.payment_type === "monthly" && (
                        <Checkbox
                          checked={!!selectedIsFirstMonth[server._id]}
                          onChange={() =>
                            handleIsFirstMonthCheckboxChanged(server)
                          }
                          label={"add-servers-to-invoice-modal.is-first-month"}
                        />
                      )}
                      {server.payment.payment_type === "hourly" && "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </BasicTable>
        </div>
      </ModalBody>
      <ModalFooter>
        <IconButton color="purple" onClick={handleAddClicked}>
          <FormattedMessage id="general.add" />
        </IconButton>
        <IconButton color="text" onClick={() => onClose(false)}>
          <FormattedMessage id="general.cancel" />
        </IconButton>
      </ModalFooter>
    </Modal>
  );
}

AddServersToInvoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default AddServersToInvoiceModal;
